import { BlueButton, Columns, Column, Rows, Row } from "../../Utils/Styling";
import * as MdIcons from "react-icons/md"
import { useCallback, useEffect, useState } from "react";
import { Modal, ModalContent, ModalToggleButton } from "../../Utils/Modal";
import { NameInputPopup } from "./NameInput";
import { checkbox } from "../../../../Components/DataTable/SelectHandler"
import { Searchbar } from "../../../../Constants/StyledComponents";
import { IntrusiveCheckboxButton, CheckboxButton } from "../../../../Components/CheckboxButton/CheckboxButton";
import { TableComponent, RowsContainer, Pagination } from "../../../../Components/TableComponent/TableComponent";
import PrimaryCTAButton, { buttonColor } from "../../../../Components/PrimaryCTAButton/PrimaryCTAButton";
import TrashButton from "../../../../Components/TrashButton/TrashButton";
import { useRecoilValue, useRecoilState } from "recoil"
import { activeAnnotationSessionAtom, annotationGroupsAtom, annotationsAtom, selectedAnnotationGroupAtom } from "../../../../Pages/Data/Visualize/DataReview/Atoms/Annotations"
import { useAnnotationService } from "../../../../Hooks/useAnnotationService";
import { authUpdates, useAuthProvider } from "../../../../Providers/AuthProvider";
import { useOnMount } from "../../../../Hooks/useOnMount";
import { currentPatientFileInfoAtom } from "../../../../Pages/Data/Visualize/DataReview/Atoms/PatientFile";
import { DataSource } from "../../../../Pages/Data/Visualize/DataReview/Types/DataSource";

const LOAD_STATE = {
    NONE: "none",
    SOME: "some",
    ALL: "all"
}

export function AnnotationGroupTable() {
    const { loadAnnotationGroups, unloadAnnotationGroups, deleteAnnotationGroups, createAnnotationGroup } = useAnnotationService()
    const { dataSourceMap } = useRecoilValue(currentPatientFileInfoAtom)
    const dataObjectId = dataSourceMap.get(DataSource.CURRENT_PATIENT)
    
    // all the annotation sessions
    const annotationGroups = useRecoilValue(annotationGroupsAtom)

    // all the annotation sessions from useAnnotationGroups() along with the CheckboxButton component to handle selecting. This useState will be used to populate the Annotation Groups Table.
    const [annotationGroupsTableData, setAnnotationGroupsTableData] = useState([])
    const [annotationGroupsTableColumns, setAnnotationGroupsTableColumns] = useState([])

    const renderedAnnotations = useRecoilValue(annotationsAtom)
    // the one annotation session displayed in "AnnotationGroupEditor", on the right hand
    const [selectedAnnotationGroup, setSelectedAnnotationGroup] = useRecoilState(selectedAnnotationGroupAtom)

    // the current write in session, the new annotations created by user will be add to this one
    const [currentAnnotationGroup, setCurrentAnnotationGroup] = useRecoilState(activeAnnotationSessionAtom)
    // the sessions which are selected with checkboxes on the left side of each row
    const [selectedAnnotationGroups, setSelectedAnnotationGroups] = useState([])

    const authProvider = useAuthProvider()

    useOnMount(() => {
        authProvider.update(authUpdates.TOKEN, authUpdates.PERMISSIONS)
    })

    const getGroupLoadedState = useCallback((group) => {
        const groupAnnotations = Object.values(renderedAnnotations).filter(annotation => annotation.group_id === group.id)
        const { nannotations: totalAnnotations } = group

        switch (groupAnnotations.length) {
            case 0:
                return LOAD_STATE.NONE
            case totalAnnotations:
                return LOAD_STATE.ALL
            default:
                return LOAD_STATE.SOME
        }
    }, [renderedAnnotations])

    const getGroupLoadedIcon = useCallback((group) => {
        const loadState = getGroupLoadedState(group)
        switch (loadState) {
            case LOAD_STATE.NONE:
                return null
            case LOAD_STATE.SOME:
                return <MdIcons.MdHorizontalRule size={14} />
            case LOAD_STATE.ALL:
                return <MdIcons.MdCheck size={18} />
            default:
                return null
        }
    }, [getGroupLoadedState])

    useEffect(() => {
        if ((!annotationGroups) || annotationGroups.length === 0) {
            return
        }

        let columnsLabels = {
            preselected: {
                label: <IntrusiveCheckboxButton
                    state={annotationGroups.length === selectedAnnotationGroups.length}
                    action={(nextState) => {
                        if (nextState) {
                            setSelectedAnnotationGroups(annotationGroups)
                        } else {
                            setSelectedAnnotationGroups([])
                        }
                    }}
                    checkboxSize={16}
                />,
                type: "checkbox",
                sortable: false,
                width: "47px",
                maxWidth: "47px",
                center: true
            },
            group_name: { label: "Name", type: "string", center: false, width: "130px", sortable: true },
            nannotations: { label: "Annotations", type: "string", center: false, width: "140px", sortable: true },
            timestamp: { label: "Date created", type: "string", center: false, width: "220px", sortable: true },
            loaded: { label: "Loaded", type: "boolean", center: true, width: "20px", sortable: true }
        }

        let columnData = []
        for (let elem of Object.keys(columnsLabels)) {
            columnData.push({
                name: columnsLabels[elem]['label'],
                selector: row => row[elem],
                sortable: columnsLabels[elem]['sortable'],
                center: columnsLabels[elem]['center'],
                minWidth: columnsLabels[elem]['width'] ? columnsLabels[elem]['width'] : true,
                maxWidth: columnsLabels[elem]['maxWidth'] ? columnsLabels[elem]['maxWidth'] : "10000px",
                attr: elem
            })
        }
        setAnnotationGroupsTableColumns(columnData)

        let tableData = annotationGroups?.map(row => {
            let entry = {
                'checkbox': (<MdIcons.MdCheckBox size={16} />)
            }

            for (let column of Object.keys(columnsLabels)) {
                if (columnsLabels[column]["type"] === "checkbox") {
                    entry['preselected'] = 
                    <CheckboxButton
                        state={selectedAnnotationGroups.includes(row)}
                        action={(nextState) => onCheckAnnotationSession(row, nextState)}
                        checkboxSize={16}
                    />
                } else if (columnsLabels[column]["type"] === "string") {
                    if (row[column] === null || row[column] === "") {
                        entry[column] = ""
                    } else {
                        entry[column] = `${row[column]}`
                    }
                } else if (columnsLabels[column]["type"] === "boolean") {
                    entry[column] = getGroupLoadedIcon(row)
                } else {
                    entry[column] = row[column]
                }
            }
            return entry
        })
        setAnnotationGroupsTableData(tableData)

    }, [annotationGroups, selectedAnnotationGroups, renderedAnnotations, getGroupLoadedIcon])

    function onCheckAnnotationSession(session, nextState) {
        if (nextState) {
            setSelectedAnnotationGroups(prevState => [...prevState, session])
        } else {
            setSelectedAnnotationGroups(prevState => prevState.filter(annotationGroup => annotationGroup !== session))
        }
    }

    function handleOnRowClickSelectedAnnotationGroup(row) {
        let selected = annotationGroups.filter((group) => group.group_name === row.group_name)[0]
        setSelectedAnnotationGroup(selected)
    }

    const canLoad = selectedAnnotationGroups.filter(group => group.nannotations > 0 && getGroupLoadedState(group) !== LOAD_STATE.ALL).length > 0
    const canUnload = selectedAnnotationGroups.filter(group => getGroupLoadedState(group) !== LOAD_STATE.NONE).length > 0



    const handleDelete = () => {
        const ids = selectedAnnotationGroups.map(group => group.id)
        const deleteCurrentAnnotationGroup = currentAnnotationGroup && ids.includes(currentAnnotationGroup.id)
        const deleteSelectedAnnotationGroup = selectedAnnotationGroup && ids.includes(selectedAnnotationGroup.id)

        if (deleteCurrentAnnotationGroup) { // the current written-in session get deleted
            setCurrentAnnotationGroup(annotationGroups.filter(group => !ids.includes(group.id))[0])
        }
        if (deleteSelectedAnnotationGroup) { // the session which displayed on the right side will be deleted
            setSelectedAnnotationGroup(null)
        }
        deleteAnnotationGroups(selectedAnnotationGroups).then(() => setSelectedAnnotationGroups([]))
    }

    const handleLoad = () => {
        loadAnnotationGroups(selectedAnnotationGroups.map(group => group.id))
    }

    const handleUnload = () => {
        unloadAnnotationGroups(selectedAnnotationGroups.map(group => group.id))
    }

    const [annotationSearchQuery, setAnnotationSearchQuery] = useState('')

    function checkAnnotationSearch(val) {
        if (annotationSearchQuery === "") {
            return val
        } else if (val.group_name.toLowerCase().includes(annotationSearchQuery.toLowerCase())) {
            return val
        } else if (String(val.nannotations).toLowerCase().includes(annotationSearchQuery.toLowerCase())) {
            return val
        } else if (String(val.timestamp).toLowerCase().includes(annotationSearchQuery.toLowerCase())) {
            return val
        }
    }

    return (
        <Rows gap="5px" style={{ height: "fit-content", minHeight: "97%", overflowX: "unset", border: "0.5px solid #F0F0F0", borderRadius: "6px", margin: "7px 0", padding: "5px" }}>
            <Columns gap="5px" style={{ height: "40px" }}>
                <Column style={{ position: "relative", marginLeft: "5px" }}>
                    <Modal>
                        <div onClick={() => {
                            new Promise(resolve => setTimeout(resolve, 50)).then(
                                // focus the input field automatically, save the user's time
                                _ => {
                                    document.getElementById("input-New Session Name").focus();
                                    document.getElementById("input-New Session Name").select()
                                }
                            ).catch(e => console.error(e))
                        }}>
                            <BlueButton as={ModalToggleButton} disabled={!authProvider.permissions?.create_annotation_group} style={{ opacity: !authProvider.permissions?.create_annotation_group ? 0.5 : 1, pointerEvents: !authProvider.permissions?.create_annotation_group ? 'none' : 'auto' }}>
                                Create session
                            </BlueButton>
                        </div>
                        <ModalContent>
                            <NameInputPopup
                                label="New Session Name"
                                // validate={Annotations.validateGroupName}
                                onSubmit={(newSessionName) => {
                                    createAnnotationGroup(newSessionName, dataObjectId).then(newAnnotationGroup => {
                                        const newCheckBox = checkbox(newAnnotationGroup.id)
                                        if (newCheckBox) {
                                            newCheckBox.click()
                                            newCheckBox.click()
                                        }
                                    })
                                }}
                            />
                        </ModalContent>
                    </Modal>
                </Column>

                <Column style={{ position: "relative" }}>
                    <Modal>
                        <TrashButton
                            disabled={// not allowed the user to deleted the last session
                                selectedAnnotationGroups.length === 0 || selectedAnnotationGroups.length === annotationGroups.length || !authProvider.permissions?.delete_annotation_group
                            }
                            onClickFxn={() => { handleDelete() }}
                        />
                    </Modal>
                </Column>

                <Column width="360px">
                    <Searchbar className="ui left icon input" style={{ width: "100%" }}>
                        <input type="text" onChange={event => { setAnnotationSearchQuery(event.target.value) }} placeholder="Search" /> <i aria-hidden="true" class="search icon" />
                    </Searchbar>
                </Column>

                <Column style={{ position: "relative" }}>
                    <PrimaryCTAButton
                        buttonText="Load"
                        onClickFxn={handleLoad}
                        buttonStyle={{ height: "34px", padding: "0px 10px 0px 10px", marginRight: "0px" }}
                        disabled={!canLoad}
                        color={buttonColor.blueFill}
                    />
                </Column>

                <Column style={{ position: "relative" }}>
                    <PrimaryCTAButton
                        buttonText="Unload"
                        onClickFxn={handleUnload}
                        color={buttonColor.redFill}
                        buttonStyle={{ height: "34px", padding: "0px 10px 0px 10px", marginRight: "0px" }}
                        disabled={!canUnload}
                    />
                </Column>

            </Columns>
            <Row>
                <TableComponent
                    data={annotationGroupsTableData.filter((val) => {
                        return checkAnnotationSearch(val)
                    })}
                    uniRef="group_name"
                >
                    <div style={{ position: "relative" }}>
                        <RowsContainer
                            columns={annotationGroupsTableColumns}
                            onRowClicked={(e) => handleOnRowClickSelectedAnnotationGroup(e)}
                        />
                        <Pagination />
                    </div>
                </TableComponent>
            </Row>
        </Rows>
    )
}