import { RefObject, useEffect } from "react"
import ResizeObserver from 'resize-observer-polyfill';

type ResizeCallback = (entry: ResizeObserverEntry) => void

export function useOnResize<T extends HTMLElement>(ref: RefObject<T>, callback: ResizeCallback): void {
	useEffect(() => {
		const element = ref.current

		if (!element) return

		const resizeObserver = new ResizeObserver(entries => {
			for (const entry of entries) {
				callback(entry)
			}
		})

		resizeObserver.observe(element)

		// Cleanup
		return () => {
			resizeObserver.disconnect()
		}
	}, [ref, callback])
}
