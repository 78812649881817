

export function ordinal_suffix_of(i) {
    var j = i % 10,
        k = i % 100;
    if (j == 1 && k != 11) {
        return i + "st";
    }
    if (j == 2 && k != 12) {
        return i + "nd";
    }
    if (j == 3 && k != 13) {
        return i + "rd";
    }
    return i + "th";
}

export function humanReadableSize(bytesize){
    let humanSize = ""
    let size = parseFloat(bytesize)
    let sizeName= ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    if (size === 0) {
        humanSize = "0 B"
    } else if (!size) {
        humanSize = "undefined"
    } else {
        const sizeNameIndex = Math.floor(Math.log(size) / Math.log(1024))
        const currentSizePower = Math.pow(1024, sizeNameIndex);
        const currentConvertedSize = (size / currentSizePower).toFixed(2)
        humanSize = `${currentConvertedSize} ${sizeName[sizeNameIndex]}`
    }
    return humanSize
}

export function formatDuration(duration) {
    if (!duration) {
        return 'Unknown'
    }

    let durationSeconds = duration / 1000 // Convert milliseconds to seconds

    const days = Math.floor(durationSeconds / 86400);
    durationSeconds %= 86400;
    const hours = Math.floor(durationSeconds / 3600);
    durationSeconds %= 3600;
    const minutes = Math.floor(durationSeconds / 60);

    return `${days} Day${days > 1 || days === 0 ? 's' : ''} ${hours} Hour${hours > 1 || hours === 0 ? 's' : ''} ${minutes} Minute${minutes > 1 || minutes === 0 ? 's' : ''}`
}

/**
 * 
 * @param {Date} date Date Object
 * @param {String} timezone 
 * @example America/New_York
 * @returns Formatted Date
 * @example 28 Nov 2017 - 2:45 PM
 */
export function localizeDate(date, timezone) {
    // Use the provided timezone, defaulting to the system's timezone if not provided
    const timeZone = timezone || Intl.DateTimeFormat().resolvedOptions().timeZone

    // Format the month with the specified timezone
    const month = new Intl.DateTimeFormat('en-US', { timeZone, month: 'short' }).format(date)

    // Extract other date components with respect to the timezone
    const options = { timeZone, year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: true }
    const formatter = new Intl.DateTimeFormat('en-US', options)
    const parts = formatter.formatToParts(date)

    // Extract the individual components from the formatted parts
    const day = parts.find(part => part.type === 'day').value
    const year = parts.find(part => part.type === 'year').value
    let hours = parts.find(part => part.type === 'hour').value
    const minutes = parts.find(part => part.type === 'minute').value
    const ampm = parts.find(part => part.type === 'dayPeriod').value

    // Formatting hours to 12-hour format (if needed)
    hours = hours % 12 || 12

    return `${day} ${month} ${year} - ${hours}:${minutes} ${ampm}`
}