import { EnterElement, ScaleBand, Selection } from "d3"
import { HeatmapTraceConfigJSON, getHeatmapTraceId } from "../../../../Types/Trace"
import { D3OneToManyRenderable } from "../../../D3/D3OneToManyRenderable"
import { ReactCallbacks } from "../../../../Types/ReactCallbacks"
import { D3HeatmapTraceLegend } from "./D3HeatmapTraceLegend"

export type D3HeatmapGraphLegendConfig = {
    traces: HeatmapTraceConfigJSON[]
    xOffset: number
    yScale: ScaleBand<any>
}

export class D3HeatmapGraphLegend extends D3OneToManyRenderable<SVGGElement, D3HeatmapGraphLegendConfig, HeatmapTraceConfigJSON> {
    private d3TraceLegends = new Map<string, D3HeatmapTraceLegend>()

    constructor(root: SVGGElement, config: D3HeatmapGraphLegendConfig, className: string, reactCallbacks: ReactCallbacks<any>) {
        super(root, config, className, reactCallbacks)
        this.mount()
    }

    protected updateDerivedState() {
		this.config.traces.forEach(trace => this.d3TraceLegends.get(this.datumIdentifier(trace))?.updateConfig({ trace, yScale: this.config.yScale }))
	}

    protected datumIdentifier(datum: HeatmapTraceConfigJSON): string {
        return getHeatmapTraceId(datum)
    }

    protected getConfigs(): HeatmapTraceConfigJSON[] {
        return this.config.traces
    }

    protected enter(newElements: Selection<EnterElement, HeatmapTraceConfigJSON, Element, any>): Selection<SVGGElement, HeatmapTraceConfigJSON, Element, any> {
        const legend = newElements
            .append("g")
            .attr("class", this.className)
        
        legend.each((config, index, nodes) => this.createChildren(config, index, nodes))

        return legend
    }

    protected update(updatedElements: Selection<SVGGElement, HeatmapTraceConfigJSON, Element, any>): Selection<SVGGElement, HeatmapTraceConfigJSON, Element, any> {
        this.config.traces.forEach(trace => this.d3TraceLegends.get(getHeatmapTraceId(trace))?.render())
        return updatedElements
    }

    protected exit(exitedElements: Selection<SVGGElement, HeatmapTraceConfigJSON, Element, any>): void {
        exitedElements.each(config => this.d3TraceLegends.delete(this.datumIdentifier(config)))
        exitedElements.remove()
    }
    
    protected createChildren(trace: HeatmapTraceConfigJSON, index: number, nodes: ArrayLike<SVGGElement>): void {
        this.d3TraceLegends.set(getHeatmapTraceId(trace), new D3HeatmapTraceLegend(nodes[index], { trace, yScale: this.config.yScale }, "d3-heatmap-trace-legend", this.reactCallbacks))
    }
}
