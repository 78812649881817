import { ScaleLinear, scaleLinear } from "d3";
import { MarginedBoundingBox } from "../../../../Types/MarginedBoundingBox";
import { Offset } from "../../../../Types/Offset";
import { ScatterPlotReactCallbacks } from "../../../../Types/ReactCallbacks";
import { ScatterPlotConfig } from "../../../../Types/ScatterPlot";
import { TraceDataConfig } from "../../../../Types/Trace";
import { D3TimeBasedVisualization } from "../../D3TimeBasedVisualization";
import { D3ScatterPlotConfigurationBuilder } from "./D3ScatterPlotConfigurationBuilder";
import { D3ScatterPlotRenderer } from "./D3ScatterPlotRenderer";
import { D3ScatterPlotPointConfig } from "../../../../Types/ScatterPlot";
import { ModalityDataSource } from "../../../../Types/ModalityDataSource";
import { ManualDownsampledPageManager, ManualDownsampledPageManagerConfig } from "../../../../Data/ManualDownsampledPageManager";
import { DataSource } from "../../../../Types/DataSource";

export class D3ScatterPlot extends D3TimeBasedVisualization<ScatterPlotConfig, ScatterPlotReactCallbacks, D3ScatterPlotRenderer, ManualDownsampledPageManager> {
    private margins: Offset = { top: 30, left: 60, bottom: 100, right: 50}
    public boundingBox: MarginedBoundingBox
    public xScale: ScaleLinear<any, any, any> = scaleLinear()
    public yScale: ScaleLinear<any, any, any> = scaleLinear()
    public data: D3ScatterPlotPointConfig[] = []

    constructor(root: HTMLDivElement, config: ScatterPlotConfig, pageManager: ManualDownsampledPageManager, reactCallbacks: ScatterPlotReactCallbacks) {
        super(root, config, pageManager, reactCallbacks)
        this.boundingBox = new MarginedBoundingBox(config.dimensions, this.margins)

        this.mount(new D3ScatterPlotRenderer(this, new D3ScatterPlotConfigurationBuilder(this), 'd3-scatter-plot'))
    }

    // We don't care about this because we will never annotate a Scatter Plot
    public getVisibleTraces(): TraceDataConfig[] {
        return []
    }

    public getModalityDataSources(): ModalityDataSource[] {
        const modalityDataSources = [
            {
                modality: this.config.xAxisConfig.modality,
                dataObjectId: this.reactCallbacks.dataSourceMap.get(DataSource.CURRENT_PATIENT) as number
            },
            {
                modality: this.config.yAxisConfig.modality,
                dataObjectId: this.reactCallbacks.dataSourceMap.get(DataSource.CURRENT_PATIENT) as number
            }
        ]

        return modalityDataSources
    }


    // PROTECTED

    protected renderPage(): void {
        this.renderer?.renderPage()
    }

    public onTimelineSliderDragEnd = () => {
		this.timeSeriesPageManager?.clearQueueAndLoad()
		this.updateLinkedWindows({ autoScale: true })
        this.renderer?.viewTimesChanged()
        this.renderer?.yAxis?.render()
        this.renderer?.xAxis?.render()
	}

    protected updateDerivedState(): void {
        this.boundingBox.setDimensions(this.config.dimensions)
        this.config.viewScale.range([0, this.boundingBox.width])
        this.xScale.range([0, this.boundingBox.width])
        
        this.xScale.domain([this.config.xAxisConfig.minValue, this.config.xAxisConfig.maxValue])

        this.yScale.range([this.boundingBox.height, 0])
        
        this.yScale.domain([this.config.yAxisConfig.minValue, this.config.yAxisConfig.maxValue])

        this.renderer?.updateChildren()
    }

    public getModalities(): string[] {
        return [this.config.xAxisConfig.modality, this.config.yAxisConfig.modality]
    }

    public onYAxisDrag = () => {
        this.renderer?.onYAxisDrag()
    }

    public onXAxisDrag = () => {
        this.renderer?.onXAxisDrag()
    }

    public onYAxisAutoScale = () => {
        this.renderer?.onYAxisAutoScale()
    }

    public onXAxisAutoScale = () => {
        this.renderer?.onXAxisAutoScale()
    }

    public getPageManagerConfig = (): ManualDownsampledPageManagerConfig => {
		return {
			patientId: this.config.patientId,
			windowId: this.config.id,
			viewScale: this.config.viewScale,
			fileScale: this.config.fileScale,
			modalityDataSources: this.getModalityDataSources(),
			resamplingPeriodSeconds: this.config.resamplingPeriodSeconds,
			timeZone: this.config.timeZone,
			patientIsAdmitted: this.config.patientIsAdmitted,
		}
	}
}