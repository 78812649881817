import { ScaleTime, Selection, EnterElement } from "d3"
import { ModalityGraphGroupReactCallbacks } from "../../../../Types/ReactCallbacks"
import { TimeSeriesPageManager } from "../../../../Data/TimeSeriesPageManager"
import { PageRectangle, RenderStrategy, TraceConfig } from "../../../../Types/Trace"
import { ModalityPage } from "../../../../Data/ModalityPage"
import { D3OneToOneRenderable } from "../../../D3/D3OneToOneRenderable"
import { LineRenderStrategy } from "./D3LineRenderStrategy"
import { HeatmapRenderStrategy } from "./D3HeatmapRenderStrategy"
import { TraceRenderStrategy } from "./RenderStrategy"
import { CompositeTimeSeriesData, getTimeSeriesData } from "../../../../Data/TimeSeriesData"

export class D3Trace extends D3OneToOneRenderable<SVGGElement, SVGForeignObjectElement, TraceConfig, ModalityGraphGroupReactCallbacks> {
	private context?: CanvasRenderingContext2D
	private pageManager: TimeSeriesPageManager<ModalityPage>
	private previousCanvasSnapshot: { startDate: Date; endDate: Date; bitmap: ImageBitmap } | null = null
	private offscreenCanvas: OffscreenCanvas
	private offscreenCanvasContext: OffscreenCanvasRenderingContext2D | null
	private offscreenXScale: ScaleTime<any, any, any>
	private pageRectangle: PageRectangle = { x: 0, y: 0, width: 0, height: 0 }
	private renderStrategy: TraceRenderStrategy
	private renderCacheKey: string

	public dataObjectId: number

	constructor(root: SVGGElement, config: TraceConfig, pageManager: TimeSeriesPageManager<ModalityPage>, reactCallbacks: ModalityGraphGroupReactCallbacks) {
		super(root, config, "d3-trace", reactCallbacks)
		this.pageManager = pageManager

		const width = config.xScale.range()[1]
		const height = this.getHeight()
		this.offscreenCanvas = new OffscreenCanvas(width, height)
		this.offscreenXScale = config.xScale.copy().range([0, width])
		this.renderStrategy = this.getRenderStrategy()
		this.offscreenCanvasContext = this.offscreenCanvas.getContext("2d")

		this.renderStrategy
			.getOffscreenRenderer({ xScale: this.offscreenXScale, yScale: this.config.yScale })
			.context(this.offscreenCanvasContext)

		this.dataObjectId = reactCallbacks.dataSourceMap.get(config.dataSource) ?? Infinity
		this.renderCacheKey = this.getRenderCacheKey()

		this.updateDerivedState()
		this.render()
	}

	public renderPage = (page: ModalityPage | undefined) => {
		if (!page || !this.context) {
			return
		}

		const { x, y, width, height } = this.getPageRectangle(page.startTime, page.endTime)
		this.context.clearRect(x, y, width, height)

		// Check if there is a fresh cached render that is up-to-date
		const cachedTraceRender = page.renderCache.get(this.renderCacheKey)

		if (cachedTraceRender && !cachedTraceRender.dirty) {
			const bitmap = cachedTraceRender.bitmap

			if (this.context.canvas.width === bitmap.width && this.context.canvas.height === bitmap.height) {
				this.context.drawImage(bitmap, x, y)
				return
			}
		}
		
		// If not, use the data to redraw
		const dataObjectId = this.reactCallbacks.dataSourceMap.get(this.config.dataSource) ?? Infinity
		const traceData = page.data.get(dataObjectId)?.get(this.config.dataKey)

		if (traceData !== undefined) {
			this.offscreenXScale.domain([page.startTime, page.endTime]).range([0, width])

			if (this.config.isCompositePart) {
				traceData.data = (traceData as CompositeTimeSeriesData).data[this.config.compositeIndex]
			}

			// If there is no data, still put the cached render in the render cache 
			// so we won't draw a gray rectangle until we reload this page.
			if (traceData.data.length === 0) {
				page.updateRenderCache(this.renderCacheKey, { bitmap: this.offscreenCanvas.transferToImageBitmap(), dirty: false, edges: [] })
				return
			}

			const timeSeriesData = getTimeSeriesData(traceData, this.config)

			const edges = this.renderStrategy.renderTimeSeriesData(timeSeriesData, page, this.renderStrategy.getOffscreenRenderer({ xScale: this.offscreenXScale, yScale: this.config.yScale }))
			const bitmap = this.offscreenCanvas.transferToImageBitmap()
			page.updateRenderCache(this.renderCacheKey, { bitmap, dirty: false, edges })
			this.context.drawImage(bitmap, x, y)

			if (this.renderStrategy instanceof LineRenderStrategy) {
				this.renderStrategy.fillInPageGaps()
			}

		} else if (!page.loaded) {
			this.context.fillStyle = "lightgray"
			this.context.fillRect(x, y, width, height)
		}
	}

	public isRescaling = () => this.previousCanvasSnapshot !== null

	public rescale = () => {
		if (this.context && this.previousCanvasSnapshot) {
			this.pageManager.getAllLoadedPages().forEach(page => {
				page?.renderCache.delete(this.getRenderCacheKey())
			})

			this.context.clearRect(0, 0, this.context.canvas.width, this.context.canvas.height)
			const { x, y, width, height } = this.getPageRectangle(this.previousCanvasSnapshot.startDate, this.previousCanvasSnapshot.endDate)
			this.context.drawImage(this.previousCanvasSnapshot.bitmap, x, y, width, height)
		}
	}

	public render = () => {
		if (this.previousCanvasSnapshot) {
			this.rescale()
			return
		}

		super.render()
	}

	public redraw = () => {
		this.pageManager.getPagesInView().forEach(page => {
			const cachedRender = page?.renderCache.get(this.renderCacheKey)

			if (cachedRender) {
				cachedRender.dirty = true
			}
		})

		this.renderStrategy.render()
	}

	public takeSnapshot = async () => {
		if (this.context) {
			const bitmap = await createImageBitmap(this.context.canvas)
			const [startDate, endDate] = this.config.xScale.domain()
			this.previousCanvasSnapshot = { startDate, endDate, bitmap }
		}
	}

	public clearSnapshot = () => {
		this.previousCanvasSnapshot = null
	}

	protected getRenderCacheKey = () => {
		return this.renderStrategy.getRenderCacheKey()
	}

	public getPageRectangle = (startTime: Date | number, endTime: Date | number) => {
		const x1 = this.config.xScale(startTime)
		const x2 = this.config.xScale(endTime)
		const width = x2 - x1
		const height = this.getHeight()
		// rounding helps the render cache be extra fast but reduces quality
		this.pageRectangle.x = x1
		this.pageRectangle.width = width
		this.pageRectangle.height = height

		return this.pageRectangle
	}

	protected updateDerivedState = () => {
		let width = this.config.xScale.range()[1]
		let height = this.getHeight()

		if (width <= 0 || height <= 0) {
			return
		}

		this.offscreenCanvas.width = width
		this.offscreenCanvas.height = height

		this.pageManager.getAllLoadedPages().forEach(page => {
			page?.renderCache.delete(this.getRenderCacheKey())
		})

		this.renderStrategy.updateConfig(this.config)
		this.renderCacheKey = this.getRenderCacheKey()
	}

	protected enter = (newTrace: Selection<EnterElement, any, any, any>): Selection<SVGForeignObjectElement, any, any, any> => {
		const foreignObject = newTrace.append("foreignObject").attr("class", this.className)
		const canvas = foreignObject.append("xhtml:canvas") as Selection<HTMLCanvasElement, any, any, any>

		const width = this.config.xScale?.range()[1] - this.config.xScale?.range()[0]
		const height = this.getHeight()

		foreignObject.attr("width", width).attr("height", height)
		canvas.attr("width", width).attr("height", height)

		const canvasNode = canvas.node()

		if (canvasNode != null) {
			this.context = canvasNode.getContext("2d") ?? undefined
			this.renderStrategy.getDirectRenderer({ xScale: this.config.xScale, yScale: this.config.yScale }).context(this.context)
		}

		this.renderStrategy.render()

		return foreignObject
	}

	protected update = (updatedForeignObject: Selection<any, any, any, any>): Selection<any, any, any, any> => {
		const updatedCanvas = updatedForeignObject.select("canvas")

		const width = this.config.xScale?.range()[1] - this.config.xScale?.range()[0]
		const height = this.getHeight()

		updatedForeignObject.attr("width", width).attr("height", height)
		updatedCanvas.attr("width", width).attr("height", height)

		this.renderStrategy.render()

		return updatedForeignObject
	}

	private getRenderStrategy(): TraceRenderStrategy {
		switch(this.config.renderStrategy) {
			case RenderStrategy.HEATMAP:
				return new HeatmapRenderStrategy(this.pageManager, this, this.config)
			case RenderStrategy.LINE: 
			default:
				return new LineRenderStrategy(this.pageManager, this, this.config)
		}
	}

	private getHeight(): number {
		const [y1, y2] = this.config.yScale.range()
		return Math.abs(y1 - y2)
	}
}
