import { Scrollbar } from "../../Constants/StyledComponents"

export default function ModalScrollbar({ children }) {
	const divider = <hr style={{ border: "1px solid #293241", minWidth: "820px", margin: 0 }} />
	return (
		<>
			{divider}
			<Scrollbar style={{ paddingLeft: "32px", paddingRight: "32px", overflowY: "auto", overflowX: "hidden", maxHeight: "100%", height: "100%", paddingBottom: "15px" }}>
				{children}
			</Scrollbar>
			{divider}
		</>
	)
}
