import styled from 'styled-components'

export function DisplayDataTable({ data, columns, modalities }) {
    return <div style={{width: "100%", padding: "10px"}}>
            <table style={{width: "100%"}}>
                <thead>
                    <tr>
                        {columns.map(header => <TableHeader key={header}>{header}</TableHeader>)}
                    </tr>
                </thead>
                <tbody>
                    {modalities.map(modality => {
                        const modalityData = data[modality]
                        return <tr key={modality}>
                            <th>{modality}</th>
                            {modalityData
                                ? modalityData.map((value, dataIndex) => (
                                    <TableCell key={`${modality}-${columns[dataIndex+1]}`}>
                                        {value}
                                    </TableCell>))
                                : <TableCell>Loading...</TableCell>
                            }
                        </tr>
                    })}
                </tbody>
            </table>
        </div>
}

const TableHeader = styled.th`
    white-space: nowrap;
    border-bottom: 1px solid #CCC;
    padding: 4px 8px;
`

const TableCell = styled.td`
    padding: 4px 8px;
`