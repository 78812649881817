import { useState, useEffect, useRef } from "react"
import styled from 'styled-components';
import "@fontsource/source-sans-pro"
import * as MdIcons from "react-icons/md"
import { DEFAULTS } from "../../../Constants/Formatting"
import FilterButton from "../../../Components/FilterButton/FilterButton"
import { MainTable } from "../../../Components/DataTable/Table"
import {columns, rows} from "../data";
import TrashButton from "../../../Components/TrashButton/TrashButton";

// PipelineShare: component that renders the PipelineShare page
// Inputs:: 
// 		- params (dict):
// Output:
//		- None
const PipelineShare = (props) => {

	// page update hook
	const [, setPipelineShareUpdate] = useState([])
	function updatePipelineShare() {
		setPipelineShareUpdate([])
	}

    // only perform these actions once
	useEffect(() => {
		window.addEventListener("resize", updatePipelineShare)
		return () => {
			window.removeEventListener("resize", updatePipelineShare)
		}
	}, [])

	// filter modal
	let filterModalRef = useRef();
	let filterButtonRef = useRef();

	function renderFilterModal() {
		if (document.getElementById("filterModal").style.display !== "none") {
			document.getElementById("filterModal").style.display = "none";
			document.getElementById("filterGrayOverlay").style.display = "none";
			document.getElementById("sideBarNav").style.position = "absolute";
			document.getElementById("sideBarNav").style.overflow = "auto";
			document.body.style.overflow="auto";
		} else {
			document.getElementById("filterModal").style.display = "block";
			document.getElementById("filterGrayOverlay").style.display = "block";
			// document.getElementById("sideBarNav").style.position = "fixed";
			document.getElementById("sideBarNav").style.overflow = "none";
			document.body.style.overflow="hidden";
		}
	}

	return (
		<div style={{background: "#F8F8F8", maxHeight: `${window.innerHeight - 74}px`}}>
            <HeaderGap />
			<div style={{
				width: "100%",
				height: "100%",
				color: DEFAULTS.TEXT_COLOR,
				background: "#F8F8F8",
				paddingLeft: "35px",
				paddingRight: "35px",
				display: "block",
				marginTop: '20px'
				}}>
				<ButtonHeader >
					<SharePipelineButton ><MdIcons.MdSend size={18} style={{marginTop: "-3px"}}/> Share Pipeline</SharePipelineButton>
					<TrashButton buttonStyle={{marginRight: "22%", flex: "0.1"}}/>
					<Searchbar className="ui left icon input">
						<input type="text" placeholder="Search"/> <i aria-hidden="true" class="search icon" />
						<button >Search</button>
					</Searchbar>
					<div style={{ marginLeft: "8px", position: "relative"}}>
						{FilterButton(() => {renderFilterModal()})} 
					</div>
				</ButtonHeader>
				<div>
					<MainTable
					columns={columns}
					data={rows}
					selectableRows
					onSelectedRowsChange={undefined}
					clearSelectedRows={undefined}
					pagination/>
				</div>
			</div>
        </div>
	)
}

const HeaderGap = styled.div`
    background-color: white;
	height: 47px;
`;

const ButtonHeader = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding-bottom: 15px;
	position: relative;
`;

const SharePipelineButton = styled.button`
	width: auto;
	height: 34px;
	background: #207dea;
	border-radius: 6px;
	font-family: "Source Sans Pro";
	font-style: normal;
	font-weight: 600;
	line-height: 150%;
	color: #ffffff;
	border: none;
	padding: 4px 8px;
	margin-right: 8px;
	white-space: nowrap;
	flex: 0.25;
`;

const Searchbar = styled.div`
	width: 40%;
	height: 34px;
	background: #ffffff;
	border: 0.5px solid #d9d9d9;
	box-sizing: border-box;
	box-shadow: 0px 0px 3px rgba(99, 191, 244, 0.15);
	border-radius: 6px;
	overflow-y: hidden;
	flex: 2;
	button {
		width: 80px;
		height: 34px;
		background: #207dea;
		border-radius: 0px 6px 6px 0px;
		border: none;
		font-family: "Source Sans Pro";
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 150%;
		color: #ffffff;
		cursor: pointer;
		padding-bottom: 3.5px;
	}
`;

export default PipelineShare;

