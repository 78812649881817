import { useCallback, useState } from "react"

/**
 * @name useRequest
 * @category Hooks
 * 
 * @description
 * Custom Hook that manages the storage and dispatching of a HTTP request. 
 * 
 * The first return is a state variable that will hold the most recent response from the endpoint.
 * 
 * The second return is a boolean that tells you whether the response is loaded or not
 * 
 * The third return is a function used to send the HTTP request. It will take in the same arguments as the requestBuilder input function.
 *
 * @param {string} URL       
 * The link of the request
 * @param {string} responseType    
 * The response type (e.g JSON)
 * @param {function(any)} requestBuilder  
 * A function that fills in the request info
 * 
 * @returns {[any, any, function(any):void]}
 *
 * @example
 * const [data, loaded, getData] = useEndpoint('http://service.info/get_data', 'JSON', 
 * param => ({
        method: "POST",
        headers: {
            'content-type': 'application/json', 
        },
        body: JSON.stringify({
            foo: param
        })
    }))

    useEffect(() => {
        getData('param1')
    }, [])

    useEffect(() => {
        if (loaded) {
            console.log(data)
        }
    }, [data, loaded])
 * 
 */
export function useRequest (URL, responseType, requestBuilder) {
    const [response, setResponse] = useState(null)
    const [loaded, setLoaded] = useState(false)

    const [loading, setLoading] = useState(false)

    const dispatchRequest = useCallback(async(...args) => {
        let response
        if (loading) return
        setLoading(true)
        try {
            response = await fetch(URL, requestBuilder(...args))
        } catch (e) {
            setLoading(false)
            console.error(`[🌐] Network error (${e.name}) retrieving ${URL}: ${e.message}`)
            throw e
        }

        if (!response.ok) {
            const error_info = await response.text()
            console.error(`[🌐] Endpoint failed:`, error_info)
            setLoading(false)
            throw new Error(error_info)
        }

        let ret
        switch (responseType) {
            case 'JSON':
                ret = await response.json()
                break
            case 'TEXT':
                ret = await response.text()
                break
            default:
                ret = await response.text()
        }

        setResponse(ret)
        setLoaded(true)
        setLoading(false)
        return ret
    }, [URL, loading, responseType, requestBuilder])


    return [response, loaded, dispatchRequest]
}