import { list_of_type, NODE_OUTPUT_TYPES } from "../../Constants/NodeOutputTypes"
import Connection from "./Connection"
import { ConnectionsDict } from "./Models"

const COLORS = ["#000000","#00FF00","#0000FF","#FF0000","#01FFFE","#FFA6FE",
                "#FFDB66","#006401","#010067","#007DB5","#FF00F6","#FFEEE8","#774D00"]
const CURVENESS_MIN = 0.3
const CURVENESS_INCREMENT = 0.2

export default class ConnectionManager {
    static updateConnectionStyles(connection: Connection): void {
        connection.color = COLORS[connection.order]
        connection.curveness = CURVENESS_MIN + CURVENESS_INCREMENT * connection.order
        connection.updateId()
    }

    static deleteConnection(connection: Connection, connections: ConnectionsDict): ConnectionsDict  {
        const connectionsCopy = {...connections}

        // Delete the connection
        connectionsCopy[connection.source.id] = connectionsCopy[connection.source.id].filter(c => c.id !== connection.id)

        // Find the connections that targeted this same id and update their styles
        Object.values(connectionsCopy).forEach(connectionList => {
            connectionList.forEach(c => {
                if (c.target.id === connection.target.id && c.order > connection.order) {
                    c.order -= 1
                    this.updateConnectionStyles(c)
                }
            })
        })
    
        
        return connectionsCopy
    }

    static addConnection(connection: Connection, connections: ConnectionsDict): ConnectionsDict {
        const connectionsCopy = {...connections}

        connection.order = Object.values(connections)
            .map(connectionList => connectionList.filter(c => c.target.id === connection.target.id).length)
            .reduce((a, b) => a+b, 0)

        this.updateConnectionStyles(connection)

        if (!connectionsCopy[connection.source.id]) {
            connectionsCopy[connection.source.id] = [connection]
        } else {
            connectionsCopy[connection.source.id].push(connection)
        }

        return connectionsCopy
    }

    static getNextConnections(connection: Connection, connections: ConnectionsDict) {
        return connections["node" + connection.target.getNodeId() + "_output"]
    }

    static getPreviousNodeIds(nodeId: string, connections: ConnectionsDict): number[][] {
        const nodeConnectionsByTargetId: Map<String, Connection[]> = new Map()

        // Find all of the connections that target this node, separate by target id.
        Object.values(connections).forEach((connectionList: Connection[]) => {
            connectionList.forEach((c: Connection) => {
                if (c.target.id.includes(`${nodeId}_`)){
                    if (!nodeConnectionsByTargetId.get(c.target.id)) {
                        nodeConnectionsByTargetId.set(c.target.id, [c])
                    } else {
                        nodeConnectionsByTargetId.get(c.target.id)?.push(c)
                    }
                }
            })
        })

        // Sort by the connection order
        Object.keys(nodeConnectionsByTargetId).forEach(key => {
            const newConnections = nodeConnectionsByTargetId.get(key)?.sort((c1: Connection, c2: Connection) => c1.order - c2.order) ?? []
            nodeConnectionsByTargetId.set(key, newConnections)
        })

        const arraysToZip: Connection[][] = Object.values(nodeConnectionsByTargetId)

        // https://stackoverflow.com/questions/22015684/how-do-i-zip-two-arrays-in-javascript
        const zip = (...arr: Connection[][]) => Array(Math.max(...arr.map(a => a.length))).fill(0).map((_,i) => arr.map(a => a[i]));

        // Translate the data into a List of lists of node ids
        if (arraysToZip.length > 0) {
            if (arraysToZip.every((arr: Connection[]) => arr.length === arraysToZip[0].length)) {
                return zip(...arraysToZip)
                        .map(connectionList => connectionList.sort((a, b) => a.target.getInputIndex() - b.target.getInputIndex())
                        .map(c => c.source.getNodeId()))
            } else {
                console.warn(`Connections were unbalanced - There must be the same number of connections to each of ${nodeId}'s inputs`)
                return []
            }
        }

        return []
    }

    static deleteAllConnectionsForNode(nodeIdKey: string, connections: ConnectionsDict): ConnectionsDict {

        Object.values(connections).forEach(connectionList => {
            connectionList.forEach(c => {
                if (c.source.id.includes(`${nodeIdKey}_`) || c.target.id.includes(`${nodeIdKey}_`)) {
                    connections = ConnectionManager.deleteConnection(c, connections)
                }
            })
        })

        delete connections[nodeIdKey + "_output"]
        
        return connections
    }

    static connectionTypesAreCompatible(sourceType: string, targetType: string) {
        return (
            (sourceType === targetType)

            ||  (sourceType === NODE_OUTPUT_TYPES.TIME_SERIES 
                && targetType === NODE_OUTPUT_TYPES.LIST_OF_VALUES)

            || (list_of_type(sourceType) === targetType)

            || (sourceType === "any" || targetType === "any")
        )
    }
}