import Box from "@mui/material/Box"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import { useState, useEffect } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import { FRONTEND_LINKS } from "../../../Constants/FrontendLinks"
import { MobergFontSize } from "../../../Components/MobergFont/MobergFont"

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	}
}

export default function UserTabs() {
	const navigate = useNavigate()
	const location = useLocation()

	// Derive the tab value from the current location
	const getCurrentTabValue = () => {
		if (location.pathname === FRONTEND_LINKS.SUBPAGES.ADMIN.SUBPAGES.USER_MANAGEMENT.MAIN) {
			return 0
		} else if (location.pathname === FRONTEND_LINKS.SUBPAGES.ADMIN.SUBPAGES.USER_MANAGEMENT.SUBPAGES.ROLES_AND_PERMISSIONS.MAIN) {
			return 1
		}
		return 0 // default value
	}

	const [value, setValue] = useState(getCurrentTabValue())

	useEffect(() => {
		// Update the tab value whenever the location changes
		setValue(getCurrentTabValue())
	}, [location])

	const handleChange = (event, newValue) => {
		setValue(newValue)
		if (newValue === 0) {
			navigate(FRONTEND_LINKS.SUBPAGES.ADMIN.SUBPAGES.USER_MANAGEMENT.MAIN)
		} else if (newValue === 1) {
			navigate(FRONTEND_LINKS.SUBPAGES.ADMIN.SUBPAGES.USER_MANAGEMENT.SUBPAGES.ROLES_AND_PERMISSIONS.MAIN)
		}
	}

	const tabStyles = {
		textTransform: "none",
		fontSize: MobergFontSize.REGULAR,
	}

	return (
		<Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
			<Tab label="Users" {...a11yProps(0)} sx={{ marginLeft: '30px', ...tabStyles }} disableRipple />
			<Tab label="Roles & Permissions" {...a11yProps(1)} sx={{ ...tabStyles }} disableRipple />
		</Tabs>
	)
}
