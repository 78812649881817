import { Dimensions } from "../../../../../Providers/DimensionsProvider"
import { ImagePageManagerConfig, ImageTimeSeriesPageManager } from "./ImageTimeSeriesPageManager"

export type TitlesAndLabels = {
	titles: ArrayBuffer
	labels: ArrayBuffer
}

type RequestedData = {
	panel: string | null
	height: number
	width: number
}

type TitlesAndLabelsCache = {
	panel: string | null
	data: TitlesAndLabels
	dimensions: Dimensions
}

export class PersystPageManager<ConfigType extends ImagePageManagerConfig=ImagePageManagerConfig> extends ImageTimeSeriesPageManager<ConfigType> {
	private requestedData: RequestedData | null = null

	private titlesAndLabelsCache: TitlesAndLabelsCache = {
		panel: null,
		data: {
			titles: new ArrayBuffer(0),
			labels: new ArrayBuffer(0),
		},
		dimensions: {
			height: 0,
			width: 0,
		},
	}

	public onTitlesAndLabelsLoaded: () => void = () => {}

    public resetTitlesAndLabels = () => {
        this.titlesAndLabelsCache = {
			panel: null,
			data: {
				titles: new ArrayBuffer(0),
				labels: new ArrayBuffer(0),
			},
			dimensions: {
				height: 0,
				width: 0,
			},
		}
    }

	public getTitlesAndLabels = (height: number, width: number, panel: string): TitlesAndLabels | null => {
		// Since we are working with images, it makes no sense to do half pixels.
		height = Math.round(height)
		width = Math.round(width)
		
		if (this.titlesAndLabelsCache.dimensions.height === height 
			&& this.titlesAndLabelsCache.dimensions.width === width
			&& this.titlesAndLabelsCache.panel === panel) {
			return this.titlesAndLabelsCache.data
		}

		if (this.requestedData
			&& this.requestedData.height === height 
			&& this.requestedData.width === width 
			&& this.requestedData.panel === panel) {
			return null // We are waiting for the previous request to finish
		}

        this.requestTitlesAndLabels(height, width, panel)

        return null
	}

    protected requestTitlesAndLabels(height: number, width: number, panel: string): void {}

	protected setRequestDimensions(requestDimensions: RequestedData | null) {
		this.requestedData = requestDimensions
	}

	protected setTitlesAndLabelsCache(newCache: TitlesAndLabelsCache) {
		this.titlesAndLabelsCache = newCache
	}
}
