import React from 'react';
import { Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ErrorBar, Scatter, ComposedChart, ReferenceDot } from 'recharts';
import Label from '../Label/Label'

function calcPolynomial(x, coeffs) {
    var out = 0
    for (let i in coeffs) {
        out += coeffs[i] * x ** (coeffs.length - i - 1)
    }
    return(out)
}

function CPPOpt ({subcomponentKey='0', CPPOpt=0, CPPBin=[], PRxBin=[], PRxStandardDeviations=[], parabolaCoefficients=[], weight=0}) {
    let data = []

    for (let i in CPPBin) {
        data.push({
            'CPP': CPPBin[i],
            'CPP-PRx': PRxBin[i],
            'PRx_std': PRxStandardDeviations[i],
        })
    }

    let cppopt = 0
    let prxopt = 2
    const minCPP = Math.trunc(Math.min(...CPPBin))
    const errorBarMins = PRxBin.map((prx, index) => prx - PRxStandardDeviations[index])
    const errorBarMaxes = PRxBin.map((prx, index) => prx + PRxStandardDeviations[index])
    const minX = Math.min(40, minCPP)
    const minY = Math.min(0, ...errorBarMins)
    const maxY = Math.max(1, ...errorBarMaxes)

    for (let cpp = minX; cpp <= 150; cpp++) {
        let prx = calcPolynomial(cpp, parabolaCoefficients)

        if (prx < minY || prx > maxY) {
            continue
        }

        data.push({
            'CPP': cpp,
            'Fitted Curve': prx,
            'PRx_std': 0,
        })

        if (prx < prxopt) {
            prxopt = prx
            cppopt = cpp
        }
    }

    return (
        <div id={subcomponentKey} style={{fontSize: "12", marginTop: "0.1in"}}>
            <div style={{ marginBottom: "0.15in"}}>
                <div>
                    <div style={{display: "inline-block"}}>
                        <ComposedChart 
                            // width={700}
                            width={350}
                            // height={700}
                            height={350}
                            data={data}
                            margin={{
                                top: 20,
                                // right: 100,
                                right: 20,
                                // left: 100,
                                left: 20,
                                bottom: 50,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            
                            <XAxis label={{ value: 'Cerebral Perfusion Pressure', angle: 0, position: 'bottom', offset: 30 }} type="number" dataKey="CPP" name="cpp" unit="mmHg" domain={[40, 120]} ticks={[40, 60, 80, 100, 120]} />
                            <YAxis label={{ value: 'Pressure-Reactivity Index (PRx)', angle: -90, position: 'left', offset: 60, style: { textAnchor: 'middle' }}} type="number" dataKey="CPP-PRx" name="prx" unit="" domain={[0, 1]} ticks={[0, 0.25, 0.5, 0.75, 1]}/>

                            <Tooltip />
                            <Legend payload={[{ value: 'CPP-PRx', type: 'scatter', id: 'PRx' }, { value: 'Fitted Curve', type: 'line', id: 'Fitted Curve', color: '#8884d8' }, { value: 'CPPopt', type: 'circle', color: 'red' }]}  />

                            <Scatter type="monotone" dataKey="CPP-PRx" stroke="#82ca9d" > 
                                <ErrorBar dataKey="PRx_std" width={8} strokeWidth={1} fill="blue" direction="y" />
                            </Scatter>
                            <Line type="dashed" dataKey="Fitted Curve" stroke='#8884d8' dot={false} />
                            <ReferenceDot id="cppopt" x={cppopt} y={prxopt} r={5} fill="red" /> 
                            {/* label={{ value: 'CPPOpt', angle: 0, position: 'bottom', offset: 20 }} */}
                        </ComposedChart>
                    </div>
                    
                    <div style={{display: "inline-block", width: "300px"}}>
                        {/* <Label size={5} text={"Start OB: " + "15-Sep-2018"}/> */}
                        <Label size={5} text={`CPPOpt: ${Math.trunc(1e1*cppopt)/1e1}`}/>
                        <Label size={5} text={`Weight: ${Math.trunc(1e3*weight)/1e3}`}/>
                        {/* <div style={{marginTop: 10}}>
                            <Table width={600} columns={[['Value', '33.33%', undefined], ['Measurement', '33.33%', undefined], ['Time', '33.33%', undefined]]} data={[['ICP', 23, "NA"], ['MAP', 'NA', "NA"], ['CPP', 54, "NA"], ['CPPOpt', 67, "NA"], ['Maximum Weight', 0.71896, "NA"], ['Delta CPP (CPPOpt - CPP)', 13, "NA"]]}/>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CPPOpt

