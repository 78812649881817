import React from "react";
import { Link } from "react-router-dom";
import { FRONTEND_LINKS } from "../../Constants/FrontendLinks";

export default function InactivityPage(props) {

    return (
        <div style={{marginLeft: "-250px", textAlign: "center", paddingTop: `${window.innerHeight / 3}px`}}>
            <div style={{color: 'black'}}>
                <h1 style={{fontFamily: "Montserrat", fontStyle: "normal", fontWeight: "700", fontSize: "40px"}}>
                    Session Timeout
                </h1>
                <br/>
                <h2 style={{fontFamily: "Montserrat", fontStyle: "normal", fontWeight: "600", fontSize: "25px", marginTop: "-25px"}}>
                    You have been logged out due to inactivity.
                </h2>
            </div>
                <Link to={FRONTEND_LINKS.MAIN} onClick={() => props.setNavPage(true)}> 
                    <div style={{color: 'black'}}>
                        <p style={{font: "Montserrat", fontSize: "16px", color: "#207DEA"}}>
                            Click here to log in
                        </p>
                    </div>
                </Link>
        </div>
    );

}