import { UploadFilesModal } from "../../../../Constants/StyledComponents"
import FormConstructor from "../../../../Components/Form/FormConstructor"
import Accordion from "../../../../Components/Accordion/Accordion"
import ModalHeader from "../../../../Components/ModalHeader/ModalHeader"
import { useForm } from "react-hook-form"
import { useEffect, useState } from "react"
import { useEndpointProvider } from '../../../../Providers/EndpointProvider';
import { useLocation, useNavigate } from "react-router-dom";
import { FRONTEND_LINKS } from '../../../../Constants/FrontendLinks';
import { Scrollbar } from "../../../../Constants/StyledComponents"
import { useBackendLinksProvider } from "../../../../Providers/BackendLinksProvider"
import { admissionDischargeQuestions, GCSQuestions, bestGCSQuestions, worstGCSQuestions } from "../../../../Constants/FormQuestions"

const FormViewModal = ({ formResponseJson }) => {
    const locationURL = useLocation();
    const query = new URLSearchParams(locationURL.search);
    const UID = query.get("patient_id")
    const { LINKS } = useBackendLinksProvider()
    const [patientInfo, setPatientInfo] = useState(0)
    const getPatient = async (patientID) => {
        let body = { patient_primary_key: patientID }
        return endpointProvider.post(LINKS.DATA.PROFILING.GET_PATIENT, body)
    }
    const endpointProvider = useEndpointProvider();
    const navigate = useNavigate();

    useEffect(() => {
        const validateURLParams = async () => {
            try {
                let patient = await getPatient(UID);
                setPatientInfo(patient);
            } catch (error) {
                let errorMessage = UID ? `Patient id=${UID} does not exist.` : 'No patient has been selected.';
                alert(errorMessage);
                navigate(FRONTEND_LINKS.SUBPAGES.DATA.SUBPAGES.VISUALIZE.MAIN)
            }
        }
        validateURLParams();
    }, [UID]);

    const generateValues = (formResponseJson) => {
        const defaultValues = {};

        for (const key in formResponseJson) {
            defaultValues[key] = formResponseJson[key].value;
        }
        return defaultValues;
    };

    const { control } = useForm({
        values: generateValues(formResponseJson),
    })

    const accordionData = {
        'Admission & Discharge': {
            'label': "Admission & Discharge",
            'innerContent': <FormConstructor control={control} questions={admissionDischargeQuestions} defaultValues={formResponseJson} disabledAll={true} />,
        },
        'GCS on admission': {
            'label': "GCS on admission",
            'innerContent': <FormConstructor control={control} questions={GCSQuestions} defaultValues={formResponseJson} disabledAll={true} />
        },
        'Best GCS during admission': {
            'label': "Best GCS during admission",
            'innerContent': <FormConstructor control={control} questions={bestGCSQuestions} defaultValues={formResponseJson} disabledAll={true} />
        },
        'Worst GCS during admission': {
            'label': "Worst GCS during admission",
            'innerContent': <FormConstructor control={control} questions={worstGCSQuestions} defaultValues={formResponseJson} disabledAll={true} />
        },
    }

    let uploadHeaderText = ` Patient ${patientInfo?.patient_id} at ${patientInfo?.site_name}`

    let handleOverflowUploadHeaderText = uploadHeaderText.length > 75 ? `Patient ${patientInfo?.patient_id.length > 15 ? patientInfo?.patient_id.slice(0, 15) + "..." : patientInfo?.patient_id} at ${patientInfo?.site?.name.length > 40 ? patientInfo?.site_name.slice(0, 40) + "..." : patientInfo?.site_name}` : uploadHeaderText

    return (
        <div>
            <UploadFilesModal>
                <ModalHeader headerText={handleOverflowUploadHeaderText} />
                <Scrollbar style={{ paddingTop: "50px", paddingLeft: "50px", paddingRight: "50px", paddingBottom: "50px", height: "700px", maxHeight: "700px", overflowY: "auto", overflowX: "hidden" }}>
                    <div style={{ display: 'flex' }}>
                        <h1 style={{ marginBottom: "10px", color: "#207DEA" }}>Demographics</h1>
                    </div>

                    <Accordion data={accordionData} />
                </Scrollbar>
            </UploadFilesModal>
        </div>
    )
}

export default FormViewModal;