export const USER_INPUT_TYPES = {
	TEXT_INPUT: "TEXT_INPUT",
    PATIENT_INPUT: "PATIENT_INPUT",
    NUMBER_INPUT: "NUMBER_INPUT",
    CHECKBOX_INPUT: "CHECKBOX_INPUT",
    MULTICHECKBOX_INPUT: "MULTICHECKBOX_INPUT",
    DROPDOWN_INPUT: "DROPDOWN_INPUT",
	MULTI_SELECTION_DROPDOWN_INPUT: "MULTI_SELECTION_DROPDOWN_INPUT",
    DURATION_INPUT: "DURATION_INPUT",
    MAX_MIN_INPUT: "MAX_MIN_INPUT",
    LIST_INPUT: "LIST_INPUT",
}