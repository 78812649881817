import { anyValuesInArray } from "../../../../../../../../Computation/Arrays";
import { Annotation } from "../../../../../../../../Managers/VisualizationManager/Variables/Annotations";
import { EEGMontageGraph } from "../../../../Types/MontageGraph";
import { AnnotationGeometry } from "./AnnotationGeometry";
import { GraphSpecificAnnotationGeometryGenerator } from "./GraphSpecificAnnotationGeometryGenerator";
import { ScaleTime } from "d3";

export class EEGMontageAnnotationGeometryGenerator extends GraphSpecificAnnotationGeometryGenerator {
    modalitySpecificGeometry(annotation: Annotation, graph: EEGMontageGraph, xScale: ScaleTime<any, any, any>): (AnnotationGeometry | null)[] {
        const channelPairs = graph.channelPairs;
        const channelHeight = graph.height / channelPairs.length;
        const annotationChannels = annotation.modalities
            .filter(name => name.startsWith("EEG_"))
            .map(modalityName => modalityName.substring(4))

        return channelPairs.map((channelPair: string[], index: number) => {
            if (anyValuesInArray(annotationChannels, channelPair)) {
                return this.createGeometry(annotation, graph, xScale, {y: index * channelHeight, height: channelHeight})
            }
            return null
        })
    }

    generate(annotation: Annotation, graph: EEGMontageGraph, xScale: ScaleTime<any, any, any>): AnnotationGeometry | (AnnotationGeometry | null)[] {
        // if annotation.modalities is ["EEG"] it means this annotation shows up on any EEG displays.
        if (annotation.isModalitySpecific() && !annotation.modalities.includes("EEG")) {
            return this.modalitySpecificGeometry(annotation, graph, xScale)
        }

        return this.createGeometry(annotation, graph, xScale, { height: graph.height })
    }
}