import React, { useContext } from 'react';
import styled from "styled-components";
import * as MdIcons from "react-icons/md";
import { MdOutlineKeyboardAlt } from "react-icons/md"
import { VisualizationContext } from '../Visualization';
import { analysesUpdates, useAnalysesProvider } from '../../../Providers/AnalysesProvider';
import { useModalProvider } from '../../../Providers/ModalProvider';
import { useEndpointProvider } from '../../../Providers/EndpointProvider';
import ConfirmationModal from '../../../Components/ConfirmationModal/ConfirmationModal';
import { HotkeysModal } from './Modals/HotkeysModal';
import { useSetRecoilState, useRecoilValue, useRecoilState } from 'recoil';
import { layoutGroupsAtom, selectedLayoutGroupAtom } from '../../../Pages/Data/Visualize/DataReview/Atoms/Layout';
import { useOnMount } from '../../../Hooks/useOnMount';
import { currentlyActiveVisualizationAreaAtom } from '../../../Pages/Data/Visualize/DataReview/Atoms/Visualizations';
import { MobergButton, MobergButtonShape, MobergButtonVariant } from '../../../Components/MobergButton/MobergButton';
import { MobergTheme } from '../../../Components/MobergThemes/MobergColors';
import { MobergIconSize } from '../../../Components/MobergIcon/MobergIcon';
import { TbTrademark } from "react-icons/tb"
import { useBackendLinksProvider } from '../../../Providers/BackendLinksProvider';

/**
 * @name ToolBar
 * @category Visualization
 * @subcategory Components
 * 
 * @description The toolbar
 * 
 * @component
 * @param {*} props 
 */
function ToolBar() {
	const analysesProvider = useAnalysesProvider()
	const endpointProvider = useEndpointProvider()
	const { close, createModal } = useModalProvider()
	const setActiveVisualizationArea = useSetRecoilState(currentlyActiveVisualizationAreaAtom)
	const [, setSelectedLayoutGroup] = useRecoilState(selectedLayoutGroupAtom)
	const { LINKS } = useBackendLinksProvider()
	const layoutGroups = useRecoilValue(layoutGroupsAtom)

	useOnMount(() => {
		analysesProvider.update(analysesUpdates.ANCILLARY_FILTER)
		setSelectedLayoutGroup(layoutGroups[0])

		try {
			setActiveVisualizationArea(layoutGroups[0].layouts[0].areas[0].area)
		} catch { }
	})

	const { selectedFile, closeVisualization, updateHotkeyConfig, resetHotkeyConfig, patient_id, siteName, dataObjectID } = useContext(VisualizationContext)

	function renderUnloadFile() {
		if (dataObjectID === undefined) {
			alert('dataObjectID undefined')
			return;
		}

		createModal(<ConfirmationModal
			title={"Unload Session?"}
			description={"Only unload the session if you are finished reviewing. This allows disk space for other sessions."}
			confirmButton={{
				text: "Unload session and close",
				theme: MobergTheme.RED,
				variant: MobergButtonVariant.FILLED,
				onClick: () => unload(dataObjectID)
			}}
			alternativeButton={{
				text: "Keep session and close",
				theme: MobergTheme.BLUE,
				variant: MobergButtonVariant.FILLED,
				onClick: () => { }
			}}
			afterClose={(isCancel) => {
				if (!isCancel) {
					close().then(() => closeVisualization())
				}
			}}
		/>)
	}

	async function unload(data_object_id) {
		let body = {
			'data_object_ids': [data_object_id]
		}
		return endpointProvider.post(LINKS.ADMIN.PREFETCH.UNLOAD, body)
	}

	function renderHotkeysModal() {
		createModal(<HotkeysModal
			escClose={false}
			clickOutsideClose={false}
			updateHotkeyConfig={updateHotkeyConfig}
			resetHotkeyConfig={resetHotkeyConfig}
		/>)
	}

	const buildHeaderItem = (label, value) => {
		return (
			<div style={{ display: "flex", fontFamily: 'Montserrat', fontStyle: 'normal', fontSize: '16px', gap: "8px" }}>
				<span style={{ fontWeight: '700' }}> {label} </span>
				<span style={{ fontWeight: '400' }}> {value} </span>
			</div>
		)
	}

	return (
		<>
			<ButtonPageHeader id="buttonPageHeader">
				<Scrollbar id='toolbar' style={{ overflowX: 'auto' }}>
					<div style={{ display: 'flex', alignItems: "center", justifyContent: 'space-between', padding: "4px 8px", gap: "32px", whiteSpace: "nowrap" }}>

						{/* Left Elements */}
						<div style={{ flex: 1, display: "flex", alignItems: "center", gap: "32px" }}>
							<div style={{ display: "flex", gap: "8px" }}>
								<span>
									<MdIcons.MdCloud size={24} margin="20px" style={{ color: '#0097EE' }} />
								</span>

								<div>
									<div style={{ fontStyle: 'normal', fontFamily: 'Montserrat', fontWeight: '700', fontSize: '20px', color: "#0097EE" }}>
										Moberg CONNECT
									</div>
									<div style={{ display: "flex", marginTop: "-4px" }}>
										<div style={{ fontStyle: 'normal', fontFamily: 'Montserrat', fontSize: '12px', color: "gray", paddingTop: "2px" }}>
											Powered by InfoMap
										</div>
										<span style={{ display: "flex", alignItems: "start" }}>
											<TbTrademark size={MobergIconSize.SMALL} color={"gray"} />
										</span>
									</div>
								</div>
							</div>

							{buildHeaderItem("Site:", siteName)}
							{buildHeaderItem("Patient:", patient_id)}
							{buildHeaderItem("File:", selectedFile.obj_name)}
						</div>

						{/* Right Elements */}
						<div style={{ display: "flex", alignItems: "center" }}>
							<MobergButton shape={MobergButtonShape.SQUARE} theme={MobergTheme.BLUE} onClick={renderHotkeysModal}>
								<MdOutlineKeyboardAlt size={MobergIconSize.LARGE} />
							</MobergButton>

							<MobergButton shape={MobergButtonShape.SQUARE} onClick={renderUnloadFile}>
								<MdIcons.MdOutlineClose size={MobergIconSize.LARGE} />
							</MobergButton>
						</div>
					</div>

				</Scrollbar>
			</ButtonPageHeader>
		</>
	)
}

const ButtonPageHeader = styled.div`
	background: #FFFFFF;
	position: sticky;
	top: 0;
	z-index: 2;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
`

const Scrollbar = styled.div`
	scrollbar-width: thin;
	::-webkit-scrollbar {
		display: block;
		width: 1px;
		color: #313A4A;
	}
	::-webkit-scrollbar-track {
		background: #BEC4CF;
		width: 1px;
		border-radius: 2px;
	}
`;

export default ToolBar;
