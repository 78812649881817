import React, { useEffect, useState } from 'react'
import MUITable from '../../MUITable/MUITable'
import ModalHeader from '../../ModalHeader/ModalHeader'
import { useEndpointProvider } from '../../../Providers/EndpointProvider'
import { InfoMessage } from '../../../Constants/StyledComponents'
import { humanReadableSize } from '../../../Computation/utilFunctions'
import { useBackendLinksProvider } from '../../../Providers/BackendLinksProvider'

function ViewFileModal({ patient = {}, file_path = '' }) {
    const endpointProvider = useEndpointProvider()
    const { LINKS } = useBackendLinksProvider()

    const maxFileSize = 209715200 // 200 MB

    const [fileTableToolbarProps, setFileTableToolbarProps] = useState({
        createButton: { isShow: false },
        deleteButton: { isShow: false },
        filterButton: { isShow: false },
        exportButton: { isShow: false },
        searchInput: { isShow: true },
        columnButton: { isShow: true },
    })
    const [tableColumns, setTableColumns] = useState([])
    const [fileSize, setFileSize] = useState(0)

    useEffect(() => {
        async function fetchData() {
            const body = {
                'project_id': patient?.project_id,
                'patient_id': patient?.patient_key,
                'file_path': file_path
            }

            const res = await endpointProvider.post(LINKS.DATA.UPLOAD.GET_CSV_COLUMNS, body)

            const columns = res?.columns?.map(column => {
                return { field: column.trim(), headerName: column.trim(), visible: true, description: column.trim(), width: 'max-content', flex: 1, sortable: res?.file_size <= maxFileSize && column.trim().length > 0 }
            })
            setTableColumns(columns)

            setFileSize(res?.file_size)

            if (res?.file_size > maxFileSize) {
                setFileTableToolbarProps({
                    createButton: { isShow: false },
                    deleteButton: { isShow: false },
                    filterButton: { isShow: false },
                    exportButton: { isShow: false },
                    searchInput: { isShow: false },
                    columnButton: { isShow: true },
                })
            }
        }

        fetchData()
    }, [])

    const queryProps = {
        queryKey: "file_content",
        endpoint: LINKS.DATA.UPLOAD.GET_FILE_CONTENT,
        body: {
            'project_id': patient?.project_id,
            'patient_id': patient?.patient_key,
            'file_path': file_path
        }
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <ModalHeader headerText={file_path?.split('/')?.at(-1)} />

            {fileSize > maxFileSize && (
                <InfoMessage style={{ margin: '0px 30px' }}>
                    <p>
                        <strong>Info! </strong>
                        Search and sort features are disabled due to the file being larger than {humanReadableSize(maxFileSize)}.
                    </p>

                </InfoMessage>
            )}

            <MUITable columns={tableColumns} tableToolbarProps={fileTableToolbarProps} {...queryProps} isCheckboxSelection={false} isRowSelectable={() => false} />
        </div>
    )
}

export default ViewFileModal