import React from "react"
import { BaseVisualizationConfigureModal } from "../../../Modals/BaseVisualizationConfigureModal"
import { persystEEGAtom } from "../../../../Atoms/PersystWindow"
import { useEditWindow } from "../../../Modals/useEditWindow"
import { PersystEEGWindowJSON } from "../../../../Types/PersystWindow"
import { WAVEFORM_WINDOW_TIME_PRESETS } from "../../../../../../../../Managers/VisualizationManager/Viewport/Components/XAxis"
import { MobergDropdown } from "../../../../../../../../Moberg"
import { ElementConfigureDiv } from "../../../../../../../../Constants/StyledComponents"

type ConfigurePersystModalProps = {
	windowId: string
	layoutId: string
}

export const ConfigurePersystEEGModal = (props: ConfigurePersystModalProps) => {
	const atom = persystEEGAtom({ layoutId: props.layoutId, windowId: props.windowId })
	const { editedConfig, saveChanges, updateProperty } = useEditWindow<PersystEEGWindowJSON>({ recoilState: atom, windowId: props.windowId, layoutId: props.layoutId })

	return (
		<BaseVisualizationConfigureModal layoutId={props.layoutId} windowId={props.windowId} title={"Configure Persyst"} saveChanges={saveChanges}>
			<div style={{ height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
				<ElementConfigureDiv style={{ width: '300px' }}>
					Initial Page Size

					<MobergDropdown
						selectedValue={editedConfig?.viewDuration}
						onChange={value => updateProperty("viewDuration", parseInt(value as string))}
						options={WAVEFORM_WINDOW_TIME_PRESETS
							.filter(preset => preset.label !== "Page Size")
							.map(preset => ({ label: preset.label, value: preset.time }))}
					/>
				</ElementConfigureDiv>
			</div>
		</BaseVisualizationConfigureModal>
	)
}
