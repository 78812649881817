import { NODE_OUTPUT_TYPES } from "../../NodeOutputTypes";

export const PEARSON = {
    INPUTS: [],
    DISPLAY_NAME: "Pearson Statistic",
    PRODUCT_LEVEL: "Standard",
    DESCRIPTION: "The Pearson correlation coefficient measures the linear relationship between two datasets.",
    KEYWORDS: ["pearson", "linear", "regression", "statistic"],
    TYPE: 'METRICS',
    DATA_INPUT_TYPES: [NODE_OUTPUT_TYPES.LIST_OF_VALUES, NODE_OUTPUT_TYPES.LIST_OF_VALUES],
    DATA_OUTPUT_TYPE: NODE_OUTPUT_TYPES.VALUE
}