import { createContext, useContext, useState, useEffect } from "react";
import { useEndpointProvider } from "./EndpointProvider";
import { LINKS } from '../Constants/BackendLinks'
import { useStudiesProvider } from "./StudiesProvider";
import { useAuthProvider } from "./AuthProvider";
import { UpdateDispatcher } from "./UpdateDispatcher";


const PatientsContext = createContext()

export const patientUpdates = {
    PATIENTS_FILTERS: "patients_filters",
    PATIENT_VISUALIZE: "patients_visualize"
}

export const PatientsProvider = ({ children }) => {  
    console.log("PROVIDER RENDER: PATIENTS")

    const [patients, setPatients] = useState([])
    const [filters, setFilters] = useState([])
    const [visualizePatients, setVisualizePatients] = useState([])

    const endpointProvider = useEndpointProvider()
    const studiesProvider = useStudiesProvider()
    const authProvider = useAuthProvider()

    async function update(...updates) {
        const initState = {
            [patientUpdates.PATIENTS_FILTERS]: {data: patients, filters},
            [patientUpdates.PATIENT_VISUALIZE]: {data: patients, filters},
        }

        const dispatcher = new UpdateDispatcher(updates, initState, patientUpdates)

        dispatcher.dispatch(patientUpdates.PATIENTS_FILTERS,
            () => endpointProvider.post(LINKS.DATA.PROFILING.GET_PATIENTS, {study_id: studiesProvider.selectedStudyId}),
            data => {
                setPatients(data?.data)
                setFilters(data?.filters)
        }).catch(e => alert(e))

        dispatcher.dispatch(patientUpdates.PATIENT_VISUALIZE,
            () => endpointProvider.post(LINKS.DATA.PROFILING.GET_PATIENTS_VISUALIZE, {study_id: studiesProvider.selectedStudyId}),
            data => {
                setVisualizePatients(data?.data)
                setFilters(data?.filters)
        }).catch(e => alert(e))
    }

    // Make sure we don't persist any data between logins.
    useEffect(() => {
        if (!authProvider.userIsLoggedIn && (patients.length > 0 || filters.length > 0)) {
            setPatients([])
            setFilters([])
            setVisualizePatients([])
        }
    }, [authProvider, filters.length, patients.length, visualizePatients.length])

    return (
        <PatientsContext.Provider value={{ patients, filters, visualizePatients, update }}>
            {children}
        </PatientsContext.Provider>
    )
}

export const usePatientsProvider = () => useContext(PatientsContext)