import React, { useRef, useState } from "react"
import { LayoutGroup } from "../../Pages/Data/Visualize/DataReview/Types/Layout"
import { MobergButton, MobergButtonVariant, MobergColumn, MobergShortTextInput, MobergTheme } from "../../Moberg"
import { useOnMount } from "../../Hooks/useOnMount"
import { useLayoutService } from "../../Hooks/useLayoutService"
import { useAsyncTask } from "../../Hooks/useAsyncTask"
import { MobergMenuController } from "../../Hooks/useMobergMenu"

type RenameDisplayGroupProps = {
    menu: MobergMenuController
    displayGroup: LayoutGroup
}

export const RenameDisplayGroup: React.FC<RenameDisplayGroupProps> = ({ menu, displayGroup }) => {
    const inputRef = useRef<HTMLInputElement>(null)
    const { modifyLayoutGroup } = useLayoutService()
	const [name, setName] = useState<string>(displayGroup.name)
    const renameTask = useAsyncTask(() => {
		const trimmed = name.trim()

		if (trimmed === displayGroup.name || trimmed === "") {
			return new Promise(resolve => resolve(null))
		}

		return modifyLayoutGroup(displayGroup.id, { ...displayGroup, name: trimmed })
	})

    const submit = () => {
		if (renameTask.isWaiting) {
			return // don't allow submit while the task is running!
		}

		inputRef.current?.blur() // re-enable hotkeys if the input did not lose focus

		renameTask.run().then(() => {
			menu.close()
		})
	}
    
    useOnMount(() => {
		inputRef.current?.focus()
	})

    return (
        <MobergColumn gap="16px" horizontalAlign="end">
			<MobergShortTextInput 
				ref={inputRef}
				label={"Rename"} 
				limit={50} 
				defaultValue={displayGroup.name}
				onChange={name => setName(name)}
				onSubmit={submit}
			/>

			<MobergButton 
				theme={MobergTheme.BLUE}
				variant={MobergButtonVariant.FILLED}
				onClick={submit}
				disabled={renameTask.isWaiting}
			> 
			OK
			</MobergButton>
		</MobergColumn>
    )
}