import { ChromePicker } from "react-color"
import { ModalityConfigurationDiv } from "../../../../Constants/StyledComponents"
import React, { useContext, useEffect, useRef, useState } from "react"
import { MobergRow, MobergDropdown, MobergButton, MobergButtonShape, MobergIconSize, MobergTheme, MobergButtonVariant, MobergInputLabel } from "../../../../Moberg"
import { CompositePartTraceConfigJSON, LineTraceConfigJSON, RenderStrategy, TraceConfigJSON } from "../../../../Pages/Data/Visualize/DataReview/Types/Trace"
import { MdClose, MdSettings } from "react-icons/md"
import { ConfigureWindowModalContext } from "./ConfigureWindowModal"
import { useModalProvider } from "../../../../Providers/ModalProvider"
import { EditAnalysisModal } from "../../../../Pages/Data/Visualize/DataReview/Components/Modals/EditAnalysis/EditAnalysisModal"
import { OnDemandAnalysis } from "../../../../Pages/Data/Visualize/DataReview/Types/AnalysisDetails"

export const LineGraphModalities: React.FC = () => {
	const { traceOptions, currentGraph, updateGraphProperty, removeModalityAt } = useContext(ConfigureWindowModalContext)
	const [colorPickerTraceIndex, setColorPickerTraceIndex] = useState<number>()
	const colorPickerBlockRefs = useRef<Map<number, HTMLDivElement | null>>(new Map())
	const colorPickerRef = useRef<HTMLDivElement | null>(null)
	const { createModal } = useModalProvider()

	function addModality() {
		updateGraphProperty("traces", (previous: TraceConfigJSON[]) => {
			return [
				...previous,
				{
					id: `trace-${new Date(Date.now()).toISOString()}`,
					...traceOptions[0].value,
					renderStrategy: RenderStrategy.LINE,
					color: "#000",
				},
			]
		})
	}

	const updateTraceAt = (index: number, config: Partial<TraceConfigJSON>) => {
		const newTraces: TraceConfigJSON[] = [...(currentGraph?.traces ?? [])]

		const newTrace = {
			...newTraces[index],
			...config,
		} as TraceConfigJSON

		if (config.isCompositePart && config.compositeIndex !== undefined) {
			(newTrace as CompositePartTraceConfigJSON).compositeIndex = config.compositeIndex
		}

		// This optional property should be removed if the the modality changed and the new trace does not have an analysis.
		if (config.name && config.onDemandAnalysis === undefined) {
			newTrace.onDemandAnalysis = undefined
		}

		newTraces[index] = newTrace

		updateGraphProperty("traces", newTraces)
	}

	const handleColorChange = (traceIndex: number, color: string) => {
		const newTraces = [...(currentGraph?.traces ?? [])] as LineTraceConfigJSON[]
		newTraces[traceIndex] = { ...newTraces[traceIndex], color }
		updateGraphProperty("traces", newTraces)
	}

	function toggleColorPicker(event: any, index: number) {
		const node = colorPickerRef.current

		if (node?.style.display === "block" && index === colorPickerTraceIndex) {
			node.style.display = "none"
			return
		}

		setColorPickerTraceIndex(index)

		if (node) {
			node.style.display = "block"
			node.style.top = `${event.target.getBoundingClientRect().bottom}px`
			node.style.left = `${event.target.getBoundingClientRect().right}px`
		}
	}

	function addColorPickerBlockRef(node: HTMLDivElement | null, index: number) {
		colorPickerBlockRefs.current.set(index, node)
	}

	function traceAnalysisArgumentsChanged(index: number, newArgs: OnDemandAnalysis) {
		updateTraceAt(index, {
			onDemandAnalysis: newArgs,
			dataKey: JSON.stringify(newArgs) // we have to be able to uniquely identify analytic traces by the same name, but with different calculations.
		})
	}

	useEffect(() => {
		const clickHandler = (event: any) => {
			let clickedOutside = true

			colorPickerBlockRefs.current.forEach(node => {
				if (node?.contains(event.target)) {
					clickedOutside = false
				}
			})

			if (colorPickerRef.current?.contains(event.target)) {
				clickedOutside = false
			}

			if (clickedOutside && colorPickerRef.current) {
				colorPickerRef.current.style.display = "none"
			}
		}

		document.addEventListener("click", clickHandler)
		return () => document.removeEventListener("click", clickHandler)
	})

	return (
		<ModalityConfigurationDiv>
			<div style={{ display: "grid", gridTemplateColumns: "auto auto auto 1fr", columnGap: "32px", rowGap: "8px" }}>

				<MobergInputLabel text={"Name"} />
				<MobergInputLabel text={"Color"} />
				<MobergInputLabel text={"Calculation"} />
				<MobergInputLabel text={""} />

				{currentGraph?.traces?.map((trace, index) => {
					return (
						<>
							<MobergDropdown
								options={traceOptions}
								onChange={traceConfig => updateTraceAt(index, traceConfig)}
								selectedValue={trace}
								equals={(a, b) => a.name === b.name && a.dataSource === b.dataSource}
								width={175}
							/>

							<MobergRow expand={false}>
								<div
									onClick={event => toggleColorPicker(event, index)}
									style={{
										width: "25px",
										height: "25px",
										backgroundColor: (trace as LineTraceConfigJSON).color ?? "#000",
										border: "1px solid #000",
										borderRadius: "25px",
										cursor: "pointer",
									}}
									ref={ref => addColorPickerBlockRef(ref, index)}
								/>
							</MobergRow>

							<MobergButton
								disabled={trace.onDemandAnalysis === undefined}
								tooltip={trace.onDemandAnalysis 
									? "Edit the calculation of " + trace.onDemandAnalysis.analytic 
									: "This modality is a raw signal."}
								shape={MobergButtonShape.SQUARE}
								onClick={() => {
									if (trace.onDemandAnalysis?.analytic) {
										createModal(<EditAnalysisModal 
											analysis={trace.onDemandAnalysis}
											onChange={(newArgs: OnDemandAnalysis) => traceAnalysisArgumentsChanged(index, newArgs)}
										/>)
									}
								}}
							>
								<MdSettings size={MobergIconSize.REGULAR} />
							</MobergButton>

							<MobergButton shape={MobergButtonShape.SQUARE} onClick={() => removeModalityAt(index)} style={{ padding: "6px" }}>
								<MdClose size={MobergIconSize.REGULAR} />
							</MobergButton>
						</>
					)
				})}

				<MobergButton onClick={addModality} theme={MobergTheme.BLUE} variant={MobergButtonVariant.OUTLINE} style={{ width: "175px" }}>
					Add modality
				</MobergButton>
			</div>

			<div id={`ColorPickerWindow`} ref={colorPickerRef} style={{ position: "fixed", display: "none" }}>
				<ChromePicker
					color={colorPickerTraceIndex !== undefined ? (currentGraph?.traces[colorPickerTraceIndex] as LineTraceConfigJSON | undefined)?.color : "#000"}
					onChange={color => {
						if (colorPickerTraceIndex !== undefined) {
							handleColorChange(colorPickerTraceIndex, color.hex)
						}
					}}
				/>
			</div>
		</ModalityConfigurationDiv>
	)
}
