import { USER_INPUT_TYPES } from "../../UserInputTypes"
import { NODE_OUTPUT_TYPES } from "../../NodeOutputTypes"

export const BIN_VALUES = {
    INPUTS: [
        {
            DISPLAY_NAME: "Min",
            VARIABLE_NAME: "min",
            TYPE: USER_INPUT_TYPES.NUMBER_INPUT,
            DEFAULT_VALUE: 0,
            DESCRIPTION: "The minimum value. If any value is less, it will be filtered out.",
        },
        {
            DISPLAY_NAME: "Max",
            VARIABLE_NAME: "max",
            TYPE: USER_INPUT_TYPES.NUMBER_INPUT,
            DEFAULT_VALUE: 100,
            DESCRIPTION: "The maximum value. If any value is greater, it will be filtered out.",
        },
        {
            DISPLAY_NAME: "Step",
            VARIABLE_NAME: "step",
            TYPE: USER_INPUT_TYPES.NUMBER_INPUT,
            DEFAULT_VALUE: 10,
            DESCRIPTION: "The size of each bin.",
        },
        {
            DISPLAY_NAME: "Method",
            VARIABLE_NAME: "method",
            TYPE: USER_INPUT_TYPES.DROPDOWN_INPUT,
            DEFAULT_VALUE: "median",
            OPTIONS: ["mean", "median", "middle", "floor", "ceiling"],
            DESCRIPTION: "The binning method. Mean sets all values in each bin to the mean of the bin. Median sets all values in each bin to the median of the bin. Middle sets all values to the halfway point between the bottom and the top of the bin. Floor rounds every value down to the bottom of the bin. Ceiling rounds every value up to the top of the bin.",
        },
        {
            DISPLAY_NAME: "Minimum Data Percentage",
            VARIABLE_NAME: "exclude_if_less",
            TYPE: USER_INPUT_TYPES.NUMBER_INPUT,
            DEFAULT_VALUE: 0.05,
            DESCRIPTION: "If the amount of data in the bin is less than the specified percentage, exclude all the values in the bin.",
        },
        
    ],
    DISPLAY_NAME: "Bin Values",
    PRODUCT_LEVEL: "Standard",
    DESCRIPTION: "Organize values into different bins and filter out unwanted data.",
    KEYWORDS: ["bin", "values", "organize"],
    DATA_INPUT_TYPES: [NODE_OUTPUT_TYPES.LIST_OF_VALUES],
    DATA_OUTPUT_TYPE: NODE_OUTPUT_TYPES.LIST_OF_VALUES
}