import { Column, Columns, ModalHeader, ModalDiv } from "../../Utils/Styling";
import * as MdIcons from "react-icons/md";
import { AnnotationGroupTable } from "../Components/AnnotationGroupTable";
import { AnnotationGroupEditor } from "../Components/AnnotationGroupEditor";
import { useModalProvider } from "../../../../Providers/ModalProvider";
import React from "react";
import { MobergButton, MobergButtonShape,MobergButtonVariant} from "../../../../Components/MobergButton/MobergButton";
import { MobergIconSize } from "../../../../Components/MobergIcon/MobergIcon";
export function AnnotationSessionManagerModal() {
    const { close } = useModalProvider()
    
    return (
        <ModalDiv style={{ height: "80vh", width: "90vw"}}>
            <ModalHeader style={{ paddingBottom: "0px", height: "50px", minHeight: "20px" }}>
                Load Annotations
                <div
                style={{ position: "absolute", right: "3px", top: "3px", padding: 0, }}
                >
                    <MobergButton
                        shape={MobergButtonShape.SQUARE} onClick={() => close()}>
                        <MdIcons.MdOutlineClose size={MobergIconSize.LARGE} />
                    </MobergButton>
                </div>
            </ModalHeader>

            <Columns spacing="10px" style={{ padding: "0px", height: "90%" }}>
                <Column basis="56%" shrink="1" style={{ margin: "0px 6px", overflowY: "scroll" }}>
                    <AnnotationGroupTable />
                </Column>

                <Column basis="44%" shrink="1" style={{ margin: "0px 6px", overflowY: "scroll" }}>
                    <AnnotationGroupEditor />
                </Column>
            </Columns>
        </ModalDiv>
    )
}