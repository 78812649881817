import * as d3 from 'd3'

/**
 * @name splitSelection
 * @function
 * @category Visualization
 * @subcategory Repository
 * @subcategory d3
 * 
 * @description
 * Takes in a d3 selection and returns an array of individual element selections
 * 
 * @param {import("d3").Selection} selection
 * 
 * @return {Array<import("d3").Selection>}
 */
export function splitSelection(selection) {
    return [...selection].map(d3.select)
}

/**
 * @name saveToDatum
 * @function
 * @category Visualization
 * @subcategory Repository
 * @subcategory d3
 * 
 * @description 
 * When used with selection.each, binds each DOM element to its data, making the data binding 'two-way'
 */
export function saveToDatum(key) {
    return function (d, i, nodes) {
        d[key] = d3.select(this)
    }
}

/**
 * @name clearBrush
 * @function
 * @category Visualization
 * @subcategory Repository
 * @subcategory d3
 * 
 * @description 
 * Removes the currently existing brush from an svg (does not clear the brush behavior, however)
 */
export function clearBrush (selection) {
    const null_brush = d3.brushX()
        .extent([
            [0, 0],
            [0, 0]
        ])
    
    null_brush.move(selection, null)
    return selection
}
