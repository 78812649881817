export function list_of_type(type) {
    return `list_of_type_${type}`
}

export const NODE_OUTPUT_TYPES = {
    TIME_SERIES: "time_series",
    INTERVALS: "intervals",
    VALUE: "value",
    COORDINATES: "coordinates",
    DICTIONARY: "dictionary",
    LIST_OF_TIME_SERIES: list_of_type("time_series"),
    LIST_OF_INTERVALS: list_of_type("intervals"),
    LIST_OF_VALUES: list_of_type("value"),
    LIST_OF_COORDINATES: list_of_type("coordinates"),
    LIST_OF_DICTIONARIES: list_of_type("dictionary")
}