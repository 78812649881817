export const VISUALIZATION_NODES = {
    HISTOGRAM: {
        DATA_INPUT_TYPES: ["time_series"],
        DATA_OUTPUT_TYPE: "dictionary",
        INPUTS: [
            {
                DISPLAY_NAME: "Min",
                VARIABLE_NAME: "min",
                TYPE: "NUMBER_INPUT",
                DEFAULT_VALUE: 0,
                DESCRIPTION: "The minimum cutoff value.",
                ADVANCED_INPUT: true
            },
            {
                DISPLAY_NAME: "Max",
                VARIABLE_NAME: "max",
                TYPE: "NUMBER_INPUT",
                DEFAULT_VALUE: 100,
                DESCRIPTION: "The maximum cutoff value.",
                ADVANCED_INPUT: true
            },
            {
                DISPLAY_NAME: "Step",
                VARIABLE_NAME: "step",
                TYPE: "NUMBER_INPUT",
                DEFAULT_VALUE: 1,
                DESCRIPTION: "The size of each bin of the histogram.",
                ADVANCED_INPUT: true
            }
        ],
        DISPLAY_NAME: "Histogram",
        DESCRIPTION: "Create segments for a univariate histogram, return the bins as a dictionary.",
        PRODUCT_LEVEL: "Standard",
        KEYWORDS: ["histogram", "plot"]
    },
    // "2D_PLOT": {
    //     INPUTS: [],
    //     DATA_INPUT_TYPES: ["time_series"],
    //     DISPLAY_NAME: "2D Plot",
    //     DESCRIPTION: "",
    //     PRODUCT_LEVEL: "Standard"
    // },
    // "SCATTER": {
    //     "INPUTS": [],
    //     DISPLAY_NAME: "Scatter Plot",
    //     DESCRIPTION: "",
    //     PRODUCT_LEVEL: "Standard"
    // },
}