import { Selection, EnterElement } from "d3"
import { D3OneToOneRenderable } from "./D3OneToOneRenderable"

export type D3ImageOverlayConfig = {
    x: number,
    y: number
	height: number
    width: number
	imageData: ArrayBuffer
}

export class D3ImageOverlay extends D3OneToOneRenderable<SVGGElement, SVGImageElement, D3ImageOverlayConfig> {
	private objectURL?: string

	protected enter(newElements: Selection<EnterElement, D3ImageOverlayConfig, any, any>): Selection<SVGImageElement, D3ImageOverlayConfig, SVGGElement, any> {
		const image = newElements
			.append("image")
			.attr("class", this.className)
            .attr("x", this.config.x)
			.attr("y", this.config.y)
			.attr("width", this.config.width)
			.attr("height", this.config.height)
			.attr("href", this.objectURL ?? "")
			.attr("opacity", this.config.imageData.byteLength > 0 ? 1 : 0)

		return image
	}

	protected update(updatedElements: Selection<SVGImageElement, D3ImageOverlayConfig, any, any>): Selection<SVGImageElement, D3ImageOverlayConfig, SVGGElement, any> {
		return updatedElements
            .attr("x", this.config.x)
			.attr("y", this.config.y)
			.attr("width", this.config.width)
			.attr("height", this.config.height)
			.attr("href", this.objectURL ?? "")
			.attr("opacity", this.config.imageData.byteLength > 0 ? 1 : 0)
	}

	protected updateDerivedState(): void {
		this.objectURL = URL.createObjectURL(new Blob([this.config.imageData]))
	}
}
