import React, { useRef, useState } from "react"
import { MdDriveFileRenameOutline } from "react-icons/md"
import { useRecoilValue } from "recoil"
import { MobergMenuController, useMobergMenu } from "../../../Hooks/useMobergMenu"
import { MobergMenuItem, MobergIconSize, MobergButton, MobergTheme, MobergButtonVariant, MobergSubMenuBoundary, MobergColumn } from "../../../Moberg"
import { Layout } from "../../../Pages/Data/Visualize/DataReview/Types/Layout"
import { useLayoutService } from "../../../Hooks/useLayoutService"
import { selectedLayoutGroupAtom } from "../../../Pages/Data/Visualize/DataReview/Atoms/Layout"
import { useAsyncTask } from "../../../Hooks/useAsyncTask"
import { MobergShortTextInput } from "../../../Components/MobergInput/MobergShortTextInput"
import { useOnMount } from "../../../Hooks/useOnMount"

type RenameLayoutMenuItemProps = {
	parentMenu: MobergMenuController
	layout: Layout
	disabled?: boolean
	tooltip?: string
}

type RenameSubMenuProps = {
	parentMenu: MobergMenuController
	layout: Layout
	menu: MobergMenuController
}

const RenameComponent: React.FC<RenameSubMenuProps> = ({ parentMenu, menu, layout }) => {
	const { modifyLayoutGroup } = useLayoutService()
	const selectedLayoutGroup = useRecoilValue(selectedLayoutGroupAtom)
	const [name, setName] = useState<string>("")
	const inputRef = useRef<HTMLInputElement>(null)

	const renameLayout = () => {
		if (!selectedLayoutGroup) {
			return new Promise(resolve => resolve(null))
		}

		let newName = name

		if (name.trim() === "") {
			return new Promise(resolve => resolve(null))
		}

		const newLayoutGroup = {
			...selectedLayoutGroup,
			layouts: selectedLayoutGroup?.layouts.map(l => {
				if (l.id === layout.id) {
					return {...l, name: newName}
				}

				return l
			})
		}

		return modifyLayoutGroup(selectedLayoutGroup.id, newLayoutGroup)
	}

	const renameTask = useAsyncTask(renameLayout)

	const submit = () => {
		if (renameTask.isWaiting) {
			return // don't allow submit while the task is running!
		}

		inputRef.current?.blur() // re-enable hotkeys if the input did not lose focus

		renameTask.run().then(() => {
			menu.close()
			parentMenu.close()
		})
	}

	useOnMount(() => {
		inputRef.current?.focus()
	})

	return (
		<MobergColumn gap="16px" horizontalAlign="end">
			<MobergShortTextInput 
				ref={inputRef}
				label={"Rename"} 
				limit={25} 
				defaultValue={layout.name}
				onChange={name => setName(name)}
				onSubmit={submit}
			/>

			<MobergButton 
				theme={MobergTheme.BLUE}
				variant={MobergButtonVariant.FILLED}
				onClick={submit}
				disabled={renameTask.isWaiting}
			> 
			OK
			</MobergButton>
		</MobergColumn>
	)
}

export const RenameLayoutMenuItem: React.FC<RenameLayoutMenuItemProps> = ({ parentMenu, layout, disabled, tooltip }) => {
	const renameMenu = useMobergMenu({ placement: "right-end" })

	return (
		<MobergMenuItem onClick={event => renameMenu.open(event)} disabled={disabled} tooltip={tooltip}>
			<MdDriveFileRenameOutline size={MobergIconSize.REGULAR} />
			Rename

			<MobergSubMenuBoundary>
				<renameMenu.MobergMenu style={{ marginLeft: "2px", padding: "16px" }}>
					<RenameComponent parentMenu={parentMenu} menu={renameMenu} layout={layout} />
				</renameMenu.MobergMenu>
			</MobergSubMenuBoundary>

		</MobergMenuItem>
	)
}
