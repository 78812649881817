import ModalHeader from "../../../../Components/ModalHeader/ModalHeader";
import { ModalFooter, Scrollbar } from "../../../../Constants/StyledComponents";
import styled from "styled-components";
import { useModalProvider } from "../../../../Providers/ModalProvider";
import { useState } from "react";
import { LINKS } from "../../../../Constants/BackendLinks";
import { useEndpointProvider } from "../../../../Providers/EndpointProvider";
import { useEffect } from "react";
import loadingSvg from "../../../Whole/smartneuro.svg"
import ModalFooterButton, { buttonColor } from "../../../../Components/ModalFooterButton/ModalFooterButton";

const ViewUserActionModal = ({columns2labels, userEmail, filterRef, filterButtonRef}) => {
    const { close } = useModalProvider()
    const endpointProvider = useEndpointProvider()

    async function getActionFromEmail(email) {
        let body = {
            email: email,
        }

        return endpointProvider.post(LINKS.ADMIN.USER_MANAGEMENT.USERS.GET_ACTION_BY_EMAIL, body)
    }

    const [actionData, setActionData] = useState({})
    const [actionsDataFilter, setActionsDataFilter] = useState({})

    useEffect(() => {
        if (!userEmail) return
        getActionFromEmail(userEmail).then((data) => {
            setActionData(data['data'])
            setActionsDataFilter(data['data'])
        }).catch((e) => {
            alert(e)
        })
    }, [userEmail])



    return (
        <ActionsModal>
            <ModalHeader headerText={"View User Actions"}/>
            <Scrollbar style={{paddingLeft: "32px", paddingRight: "32px", height: "615px", maxHeight: "615px", overflowX: "hidden", overflowY: "scroll"}}>
                {(Object.keys(actionData).length) > 0 ? 
                null
                // <TableLayoutComponent columns2labels={columns2labels} data={actionData} dataFilter={actionsDataFilter} editable={false} selectRows={false} handleTrashButton={null} handleExportCSVButton={null} filterRef={filterRef} filterButtonRef={filterButtonRef} primaryButtonRef={null} handlePrimaryButton={null} components={{primary:false, trashcan:false, export:false, viewmore:false}}/>
                : 
                <img src={loadingSvg} alt="loading" style={{width: "300px", position: "absolute", marginLeft: "220px", marginTop: "150px"}}/>}
                
            </Scrollbar>
            <hr style={{border: "1px solid #B3B3B3", width: "100%"}}/>
            <ModalFooter style={{height: "50px"}}>
                <ModalFooterButton
                    buttonText="Cancel"
                    onClickFxn={() => close()}
                    color={buttonColor.blueFill}
                />
            </ModalFooter>


        </ActionsModal>
    )
}

const ActionsModal = styled.div`
	width: 820px;
	height: auto;
	background: #ffffff;
	border-radius: 6px;
	z-index: 100;
	h3 {
		font-family: "Montserrat";
		font-style: normal;
		font-weight: 700;
		font-size: 20px;
		line-height: 150%;
		color: #293241;
		text-align: center;
		margin-top: 8px;
		margin-bottom: -4px;
	}
	h2 {
		font-family: "Source Sans Pro";
		font-style: normal;
		font-weight: 700;
		font-size: 21px;
		line-height: 150%;
		color: #293241;
		margin-top: 5px;
	}
	h1 {
		font-family: "Source Sans Pro";
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 150%;
		color: #293241;
	}
	textarea {
		background: #ffffff;
		border: 1px solid #ababab;
		box-sizing: border-box;
		border-radius: 6px;
		padding: 16px;
		width: 756px;
		height: 100px;
		margin-top: -5px;
		::placeholder {
			font-family: "Source Sans Pro";
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 150%;
			color: #9f9f9f;
			margin-top: -200px !important;
		}
	}
`;

export default ViewUserActionModal;

{/* <ActionsModal
    id="viewActionsButtonModal"
    ref={listActionsButtonModalRef}
    style={{
        left: props.sidebar
            ? `${(window.innerWidth - 1170) / 2 + 250}px`
            : `${(window.innerWidth - 960) / 2 + 40}px`,
        display: "none",
        top: `${(window.innerHeight - 776)/3}px`,
        position: "fixed",
        width: "920px",
        maxHeight: `${window.innerHeight - 170}px`
    }}
>
    <h3>View User Actions</h3>
    <hr
        style={{
            border: "1px solid #B3B3B3",
            width: "920px",
        }}
    />
    <Scrollbar id="inviteUserModalScrollableContentRef"
        style={{
            paddingLeft: "32px",
            paddingRight: "32px",
            maxHeight: "615px",
            overflowY: "scroll",
            overflowX: "hidden",
        }}
    >
        
        {actionsData?(<TableLayoutComponent columns2labels={columns2labels} data={actionsData}  dataFilter={actionsDataFilter} editable={false} selectRows={false} handleTrashButton={null} handleExportCSVButton={null} filterRef={filterRef} filterButtonRef={filterButtonRef} primaryButtonRef={null} handlePrimaryButton={null} components={{primary:false, trashcan:false, export:false, viewmore:false}}/>):null}


    </Scrollbar>
    <hr
        style={{
            border: "1px solid #B3B3B3",
            width: "820px",
            marginLeft: "auto",
            marginRight: "auto",
        }}
    />
    <div
        style={{
            paddingLeft: "35px",
            paddingBottom: "15px",
        }}
    >							
        <ModalFooterButton
            onClick={cancelDomainSettingsModal}
            style={{
                background: "#FFFFFF",
                color: "#207DEA",
            }}
        >
            Cancel
        </ModalFooterButton>
    </div>
</ActionsModal> */}