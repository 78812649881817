import * as React from "react"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import IconButton from "@mui/material/IconButton"
import CloseIcon from "@mui/icons-material/Close"
import Typography from "@mui/material/Typography"
import ErrorIcon from "@mui/icons-material/Error"
import Snackbar from "@mui/material/Snackbar"
import Box from "@mui/material/Box"

import { useTableContext } from "../../Providers/TableProvider"

export default function Toast() {
	const { toastProps, setToastProps } = useTableContext()

	const handleClose = (event, reason) => {
		if (reason === "clickaway") {
			return
		}

		setToastProps({
			...toastProps,
			isOpen: false,
		})
	}

	const STATUS_ICONS = {
		success: <CheckCircleIcon color="success" />,
		error: <ErrorIcon color="error" />,
	}

	const message = (
		<Box display="flex" alignItems="center">
			{STATUS_ICONS[toastProps.severity]}
			<Typography variant="body1" color="textPrimary" marginLeft={1} sx={{ fontSize: "1.3rem" }}>
				{toastProps.message}
			</Typography>
		</Box>
	)

	const action = (
		<IconButton
			size="small"
			aria-label="close"
			color="inherit"
			onClick={handleClose}
			sx={{
				color: "black",
				"&:hover": {
					backgroundColor: "rgba(0, 0, 0, 0.1)",
				},
			}}
		>
			<CloseIcon fontSize="small" />
		</IconButton>
	)

	return (
		<Snackbar
			open={toastProps.isOpen}
			autoHideDuration={6000}
			onClose={handleClose}
			message={message}
			action={action}
			ContentProps={{
				style: { backgroundColor: "#fff" },
			}}
		/>
	)
}
