import { Selection, EnterElement } from "d3"
import { SdDetectionReactCallbacks } from "../../../../Types/ReactCallbacks"
import { D3EEGCanvas, EEGCanvasConfig } from "../../EEGMontage/D3/D3EEGCanvas"
import { EEGTimeSeriesPageManager } from "../../../../Data/EEGTimeSeriesPageManager"
import { EEGPage } from "../../../../Data/EEGPage"
import { D3OneToManyRenderable } from "../../../D3/D3OneToManyRenderable"

export type EEGCanvasWrapperConfig = {
	canvases: EEGCanvasConfig[]
}

export class D3EEGCanvasesWrapper extends D3OneToManyRenderable<SVGGElement, EEGCanvasWrapperConfig, EEGCanvasConfig, SdDetectionReactCallbacks> {
	private pageManager: EEGTimeSeriesPageManager

    // Public
    renderPage = (page: EEGPage) => {
        this.eegCanvases.forEach(canvas => canvas.renderPage(page))
    }

	// Children
	private eegCanvases: Map<string, D3EEGCanvas> = new Map()

	constructor(root: SVGGElement, config: EEGCanvasWrapperConfig, pageManager: EEGTimeSeriesPageManager, reactCallbacks: SdDetectionReactCallbacks) {
		super(root, config, "d3-modality-eeg-canvas-wrapper", reactCallbacks)
		this.pageManager = pageManager
		this.mount()
	}

	protected datumIdentifier(datum: EEGCanvasConfig): string | number {
		return datum.graphId
	}

	protected getConfigs(): EEGCanvasConfig[] {
		return this.config.canvases
	}

	protected enter(newElements: Selection<EnterElement, EEGCanvasConfig, SVGGElement, any>): Selection<SVGGElement, EEGCanvasConfig, SVGGElement, any> {
		const containers = newElements.append("g").attr("class", this.className)
		containers.each(this.createChildren)
		return containers
	}

	protected update = (updatedElements: Selection<any, any, any, any>): Selection<any, any, any, any> => {
		updatedElements.each(this.updateChildren)
		return updatedElements
	}

	protected createChildren = (eegCanvasConfig: EEGCanvasConfig, index: number, nodes: ArrayLike<SVGGElement>) => {
		const root = nodes[index]
		this.eegCanvases.set(eegCanvasConfig.graphId, new D3EEGCanvas(root, eegCanvasConfig, this.pageManager, this.reactCallbacks))
	}

	protected updateChildren = (eegCanvasConfig: EEGCanvasConfig) => {
		this.eegCanvases.get(eegCanvasConfig.graphId)?.updateConfig(eegCanvasConfig)
	}
}
