import { ModalityDataSource } from "../Types/ModalityDataSource";
import { PageType } from "../Types/PageType";
import { ModalityPageProperties, Page } from "./Page";
import { TimeSeriesData } from "./TimeSeriesData";

type ManualDownsampledPageProperties = ModalityPageProperties & {
    resamplingPeriodSeconds: number
}

export class ManualDownsampledPage extends Page<TimeSeriesData, ManualDownsampledPageProperties> {
    private resamplingPeriodSeconds: number = 0

    getType(): PageType {
        return PageType.MANUAL_DOWNSAMPLE
    }

    socketEventName = () => "render_modalities_with_sampling"

    requestData(batchedDataSources: Map<number, ModalityDataSource[]>) {
		if (this.getDataQuerySocket === null) {
            return
        }
        
        const socket = this.getDataQuerySocket(this.socketId)

		batchedDataSources.forEach((modalities, dataObjectId) => {
			socket.emit(this.socketEventName(), this.patientId, dataObjectId, this.id, modalities, this.startTime + this.timeZoneOffsetMs, this.endTime + this.timeZoneOffsetMs, this.resamplingPeriodSeconds, this.patientIsAdmitted)
		})
	}
}