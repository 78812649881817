import React from "react"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import { useModalProvider } from "../../../../../../../../Providers/ModalProvider"
import { editedLayoutGroupAtom, selectedLayoutAtom, selectedLayoutGroupAtom, uneditedLayoutGroupAtom } from "../../../../Atoms/Layout"
import { SCATTER_PLOT_SAMPLING_PRESETS, WINDOW_TIME_PRESETS } from "../../../../../../../../Managers/VisualizationManager/Viewport/Components/XAxis"
import { VisualizationToolbar } from "../../../React/VisualizationToolbar"
import { ScatterPlotConfigsAtom } from "../../../../Atoms/ScatterPlot"
import { ConfigureScatterPlotModal } from "../../../Modals/ConfigureScatterPlotModal"
import { MobergDropdown, MobergDropdownSize } from "../../../../../../../../Moberg"

type ScatterPlotWindowSettingsProps = {
	windowId: string
}

export function ScatterPlotWindowSettings(props: ScatterPlotWindowSettingsProps) {
	const { createModal } = useModalProvider()
	const setUneditedLayoutGroup = useSetRecoilState(uneditedLayoutGroupAtom)
	const setEditedLayoutGroup = useSetRecoilState(editedLayoutGroupAtom)
	const selectedLayoutGroup = useRecoilValue(selectedLayoutGroupAtom)
	const selectedLayout = useRecoilValue(selectedLayoutAtom)

    const [scatterPlotConfig, setScatterPlotConfig] = useRecoilState(ScatterPlotConfigsAtom({ layoutId: selectedLayout?.id as string, windowId: props.windowId }))
	const [viewStart, viewEnd] = scatterPlotConfig.viewScale.domain()
	const viewDuration = viewEnd.getTime() - viewStart.getTime()

	function renderConfigureModal() {
		if (!selectedLayout) {
			console.error("Couldn't render the modal because a layout was not selected")
			return
		}

		setUneditedLayoutGroup(selectedLayoutGroup)
		setEditedLayoutGroup(selectedLayoutGroup)

		createModal(<ConfigureScatterPlotModal windowId={props.windowId} layoutId={selectedLayout.id} />)
	}

	const visualizationSpecificSettings = (<>
		<MobergDropdown 
			label={"Resample"}
			selectedValue={scatterPlotConfig.resamplingPeriodSeconds}
			size={MobergDropdownSize.SMALL} 
			onChange={value => setScatterPlotConfig(previous => ({ ...previous, resamplingPeriodSeconds: parseInt(value as string) }))}
			options={SCATTER_PLOT_SAMPLING_PRESETS.filter(preset => preset.label !== "Page Size").map(preset => ({ label: preset.label, value: preset.time }))} />
	</>)

	return <VisualizationToolbar
		atomValue={scatterPlotConfig}
		setAtom={setScatterPlotConfig}
		renderConfigureModal={renderConfigureModal}
		viewDuration={viewDuration}
		timePresetOptions={WINDOW_TIME_PRESETS}
		visualizationSpecificSettings={visualizationSpecificSettings}
	/>
}