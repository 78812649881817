import { EnterElement, ScaleLinear, Selection } from "d3";
import { D3OneToOneRenderable } from "./D3OneToOneRenderable";
import { round } from "lodash";

export type D3PointConfig = {
    color: string
    size: number
    x: number
    y: number
    xScale: ScaleLinear<any, any, any>
    yScale: ScaleLinear<any, any, any>
    clipPathId: string
    showCoordinates?: "above" | "below"
    transitionDuration?: number
}

export class D3Point extends D3OneToOneRenderable<SVGGElement, SVGGElement, D3PointConfig> {
    private labelClassName = "d3-point-label"
    private circleClassName = "d3-point-circle"

    protected enter = (newElements: Selection<EnterElement, D3PointConfig, any, any>): Selection<SVGGElement, D3PointConfig, SVGGElement, any> => {
        const labelOffset = this.getLabelOffset()
        const circleX = this.config.xScale(this.config.x)
        const circleY = this.config.yScale(this.config.y)
        
        // create the container
        const container = newElements
            .append("g")
            .attr("clip-path", `url(#${this.config.clipPathId})`)
            .attr("class", this.className)
        
        // create the circle
        container
            .append("circle")
            .attr("class", this.circleClassName)
            .attr("cx", circleX)
            .attr("cy", circleY)
            .attr("r", this.config.size)
            .attr("fill", this.config.color)

        // create the label
        container
            .append("text")
            .attr("text-anchor", "middle")
            .attr("fill", this.config.color)
            .attr("class", this.labelClassName)
            .attr("transform", `translate(${ circleX + labelOffset.x}, ${circleY + labelOffset.y})`)
            .text(`(${round(this.config.x, 2)}, ${round(this.config.y, 2)})`)

        return container
    }

    protected update = (updatedElements: Selection<SVGGElement, D3PointConfig, any, any>): Selection<SVGGElement, D3PointConfig, SVGGElement, any> => {
        const labelOffset = this.getLabelOffset()
        const circleX = this.config.xScale(this.config.x)
        const circleY = this.config.yScale(this.config.y)

        const container = updatedElements
        const animatedContainer = container.transition().duration(this.config.transitionDuration ?? 0)

        // update the circle
        animatedContainer.select("." + this.circleClassName)
            .attr("cx", circleX)
            .attr("cy", circleY)

        // update the label
        animatedContainer.select("." + this.labelClassName)
            .attr("transform", `translate(${ circleX + labelOffset.x}, ${circleY + labelOffset.y})`)
            .text(`(${round(this.config.x, 2)}, ${round(this.config.y, 2)})`)

        return container
    }

    private getLabelOffset = () => {
        switch (this.config.showCoordinates) {
            case "above": return { x: 0, y: -20}
            case "below":
            default: return { x: 0, y: 20 }
        }
    }

}