import { USER_INPUT_TYPES } from "../UserInputTypes"

export const SOURCE_NODES = {
    DESCRIPTION: "Using an intelligent data API, data can be pulled from different sources in order to be pushed through pre-built or customized Pipelines.",
    // "HDF5IBM": {
    //     "INPUTS": [
    //         {
    //             DISPLAY_NAME: "Patient File",
    //             VARIABLE_NAME: "patient_file",
    //             TYPE: "PATIENT_INPUT",
    //             DEFAULT_VALUE: "0002/1241/Patient_2014_May_22__23_21_57_190777.h5",
    //             DESCRIPTION: "This field specifies which patient file or group of patient files should be queries from the respective source: \ne.g. data/01/1467/Patient_2018_Feb_16__11_17_45_545152.h5, data/01/1467/, or data/01/1467/Patient_2018*", 
    //             ADVANCED_INPUT: true
    //         },
    //         {
    //             DISPLAY_NAME: "Start Time (s)",
    //             VARIABLE_NAME: "start_time",
    //             TYPE: "NUMBER_INPUT",
    //             DEFAULT_VALUE: {"seconds": 1},
    //             DESCRIPTION: "This field specifies the beginning time point from which data will begin across all modalities.",
    //             ADVANCED_INPUT: true,
    //         },
    //         {
    //             DISPLAY_NAME: "End Time (s)",
    //             VARIABLE_NAME: "end_time",
    //             TYPE: "NUMBER_INPUT",
    //             DEFAULT_VALUE: 1e20,
    //             DESCRIPTION: "This field specifies the final time point from which data will begin across all modalities.",
    //             ADVANCED_INPUT: false
    //         },
    //         {
    //             DISPLAY_NAME: "Modalities",
    //             VARIABLE_NAME: "modalities",
    //             TYPE: "MODALITY_INPUT",
    //             DEFAULT_VALUE: {},
    //             DESCRIPTION: "This field specifies the modalities that will be queries from the chosen patient files. \nIf the modality is not present in a patient file, then... .",
    //             ADVANCED_INPUT: false
    //         },
    //         {
    //             DISPLAY_NAME: "Verbose",
    //             VARIABLE_NAME: "verbose",
    //             TYPE: "CHECKBOX_INPUT",
    //             DEFAULT_VALUE: false,
    //             DESCRIPTION: "This field specifies whether activity of this module will be logged to on the server's file system.",
    //             ADVANCED_INPUT: true
    //         }	
    //     ],
    //     DISPLAY_NAME: "IBM HDF5 Module",
    //     DESCRIPTION: "Patient data can be pulled from IBM Cloud services by specifying the file path, start time, end time, and modalities.",
    //     PRODUCT_LEVEL: "Standard",
    //     KEYWORDS: [
    //                 "IBM",
    //                 "CLOUD",
    //                 "SOURCE",
    //                 "HDF5"
    //             ]	
    // },
    // "HDF5AWS": {
    //     "INPUTS": [
    //         {
    //             DISPLAY_NAME: "Patient File",
    //             VARIABLE_NAME: "patient_file",
    //             TYPE: "TEXT_INPUT",
    //             DEFAULT_VALUE: "data/01/1467/Patient_2018_Feb_16__11_17_45_545152.h5",
    //             DESCRIPTION: "This field specifies which patient file or group of patient files should be queries from the respective source: \ne.g. data/01/1467/Patient_2018_Feb_16__11_17_45_545152.h5, data/01/1467/, or data/01/1467/Patient_2018*",
    //             ADVANCED_INPUT: false	
    //         },
    //         {
    //             DISPLAY_NAME: "Start Time (s)",
    //             VARIABLE_NAME: "start_time",
    //             TYPE: "NUMBER_INPUT",
    //             DEFAULT_VALUE: 0,
    //             DESCRIPTION: "This field specifies the beginning time point from which data will begin across all modalities.",
    //             ADVANCED_INPUT: true
    //         },
    //         {
    //             DISPLAY_NAME: "End Time (s)",
    //             VARIABLE_NAME: "end_time",
    //             TYPE: "NUMBER_INPUT",
    //             DEFAULT_VALUE: 3600,
    //             DESCRIPTION: "This field specifies the final time point from which data will begin across all modalities.",
    //             ADVANCED_INPUT: true
    //         },
    //         {
    //             DISPLAY_NAME: "Modalities",
    //             VARIABLE_NAME: "modalities",
    //             TYPE: "DROPDOWN_INPUT",
    //             DEFAULT_VALUE: "",
    //             DESCRIPTION: "This field specifies the modalities that will be queries from the chosen patient files. \nIf the modality is not present in a patient file, then... .",
    //             ADVANCED_INPUT: false
    //         },
    //         {
    //             DISPLAY_NAME: "Verbose",
    //             VARIABLE_NAME: "verbose",
    //             TYPE: "CHECKBOX_INPUT",
    //             DEFAULT_VALUE: false,
    //             DESCRIPTION: "This field specifies whether activity of this module will be logged to on the server's file system.",
    //             ADVANCED_INPUT: true
    //         }	
    //     ],
    //     DISPLAY_NAME: "AWS HDF5 Module",
    //     DESCRIPTION: "Patient data can be pulled from AWS Cloud by specifying the file path, start time, end time, and modalities.",
    //     PRODUCT_LEVEL: "Standard",
    //     KEYWORDS: [
    //                 "AWS",
    //                 "CLOUD",
    //                 "SOURCE",
    //                 "HDF5"
    //             ]	
    // },
    // "HDF5GOOGLE": {
    //     "INPUTS": [
    //         {
    //             DISPLAY_NAME: "Patient File",
    //             VARIABLE_NAME: "patient_file",
    //             TYPE: "TEXT_INPUT",
    //             DEFAULT_VALUE: "data/01/1467/Patient_2018_Feb_16__11_17_45_545152.h5",
    //             DESCRIPTION: "This field specifies which patient file or group of patient files should be queries from the respective source: \ne.g. data/01/1467/Patient_2018_Feb_16__11_17_45_545152.h5, data/01/1467/, or data/01/1467/Patient_2018*",
    //             ADVANCED_INPUT: false
    //         },
    //         {
    //             DISPLAY_NAME: "Start Time (s)",
    //             VARIABLE_NAME: "start_time",
    //             TYPE: "NUMBER_INPUT",
    //             DEFAULT_VALUE: 0,
    //             DESCRIPTION: "This field specifies the beginning time point from which data will begin across all modalities.",
    //             ADVANCED_INPUT: true
    //         },
    //         {
    //             DISPLAY_NAME: "End Time (s)",
    //             VARIABLE_NAME: "end_time",
    //             TYPE: "NUMBER_INPUT",
    //             DEFAULT_VALUE: 3600,
    //             DESCRIPTION: "This field specifies the final time point from which data will begin across all modalities.",
    //             ADVANCED_INPUT: true
    //         },
    //         {
    //             DISPLAY_NAME: "Modalities",
    //             VARIABLE_NAME: "modalities",
    //             TYPE: "DROPDOWN_INPUT",
    //             DEFAULT_VALUE: "",
    //             DESCRIPTION: "This field specifies the modalities that will be queries from the chosen patient files. \nIf the modality is not present in a patient file, then... .",
    //             ADVANCED_INPUT: false
    //         },
    //         {
    //             DISPLAY_NAME: "Verbose",
    //             VARIABLE_NAME: "verbose",
    //             TYPE: "CHECKBOX_INPUT",
    //             DEFAULT_VALUE: false,
    //             DESCRIPTION: "This field specifies whether activity of this module will be logged to on the server's file system.",
    //             ADVANCED_INPUT: true
    //         }	
    //     ],
    //     DISPLAY_NAME: "Google HDF5 Module",
    //     DESCRIPTION: "Patient data can be pulled from Google Cloud by specifying the file path, start time, end time, and modalities.",
    //     PRODUCT_LEVEL: "Standard",
    //     KEYWORDS: [
    //                 "IBM",
    //                 "CLOUD",
    //                 "SOURCE",
    //                 "AWS"
    //             ]	
    // },
    "HDF5LOCAL": {
        DATA_OUTPUT_TYPE: "time_series",
        "INPUTS": [
            {
                DISPLAY_NAME: "Patient File",
                VARIABLE_NAME: "patient_file",
                TYPE: USER_INPUT_TYPES.TEXT_INPUT,
                DEFAULT_VALUE: "data/01/1467/Patient_2018_Feb_16__11_17_45_545152.h5",
                DESCRIPTION: "This field specifies which patient file or group of patient files should be queries from the respective source: \ne.g. data/01/1467/Patient_2018_Feb_16__11_17_45_545152.h5, data/01/1467/, or data/01/1467/Patient_2018*",
                ADVANCED_INPUT: false
            },
            {
                DISPLAY_NAME: "Duration Settings",
                VARIABLE_NAME: "duration_settings",
                TYPE: USER_INPUT_TYPES.DURATION_INPUT,
                DEFAULT_VALUE: {"hours": 3},
                DESCRIPTION: "This field specifies the beginning time point from which data will begin across all modalities.",
            },
            {
                DISPLAY_NAME: "Modalities",
                VARIABLE_NAME: "modalities",
                TYPE: USER_INPUT_TYPES.MULTI_SELECTION_DROPDOWN_INPUT,
                OPTIONS: ["ABP_na", "ICP_Mean", "Resp_na", "ECG_II", "EEG_C3", "EEG_C4", "EEG_Cz"],
                DEFAULT_VALUE: ["ABP_na", "ICP_Mean"],
                DESCRIPTION: "This field specifies the modalities that will be queries from the chosen patient files. \nIf the modality is not present in a patient file, then... .",
            }
        ],
        DISPLAY_NAME: "Data Source",
        DESCRIPTION: "Patient data can be pulled from the local file system by specifying the file path, start time, end time, and modalities.",
        PRODUCT_LEVEL: "Standard",
        KEYWORDS: [
                    "LOCAL",
                    "SOURCE",
                    "HDF5"
                ]	
    },
    // "FHIR": {
    //     "INPUTS": [
    //         {
    //             DISPLAY_NAME: "FHIR Username",
    //             VARIABLE_NAME: "fhi_user",
    //             TYPE: "TEXT_INPUT",
    //             DEFAULT_VALUE: "fhi_user",
    //             DESCRIPTION: "This field specifies the FHIR username for the EMR database.",
    //             ADVANCED_INPUT: true
    //         }, 
    //         {
    //             DISPLAY_NAME: "FHIR Password",
    //             VARIABLE_NAME: "fhir_pass",
    //             TYPE: "TEXT_INPUT",
    //             DEFAULT_VALUE: "fhir_pass",
    //             DESCRIPTION: "This field specifies the FHIR password for the EMR database.",
    //             ADVANCED_INPUT: true
    //         }, 
    //         {
    //             DISPLAY_NAME: "FHIR Server IP",
    //             VARIABLE_NAME: "fhir_server_ip",
    //             TYPE: "TEXT_INPUT",
    //             DEFAULT_VALUE: "0.0.0.0",
    //             ADVANCED_INPUT: true
    //         }, 
    //         {
    //             DISPLAY_NAME: "Patient ID",
    //             VARIABLE_NAME: "patient_id",
    //             TYPE: "TEXT_INPUT",
    //             DEFAULT_VALUE: "1778425ec25-f5a4b9af-442c-417d-bd01-2a84e7b071da",
    //             DESCRIPTION: "This field specifies patient IDs in the EMR database.",
    //             ADVANCED_INPUT: false
    //         },
    //         {
    //             DISPLAY_NAME: "Verbose",
    //             VARIABLE_NAME: "verbose",
    //             TYPE: "CHECKBOX_INPUT",
    //             DEFAULT_VALUE: false,
    //             DESCRIPTION: "This field specifies whether activity of this module will be logged to on the server's file system.",
    //             ADVANCED_INPUT: true
    //         }	
    //     ],
    //     DISPLAY_NAME: "FHIR Module",
    //     DESCRIPTION: "Patient data in the EMR (electronic medical record) can be pulled from EPIC through FHIR by specifying the FHIR username, password, server IP, and list of patient IDs.",
    //     PRODUCT_LEVEL: "Standard Plus",
    //     KEYWORDS: [
    //                 "CLOUD",
    //                 "SOURCE"
    //             ]	

    // }
}