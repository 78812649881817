import { useState, useEffect } from "react"
import Popover from 'react-bootstrap/Popover';
import * as MdIcons from 'react-icons/md'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { useEndpointProvider } from "../../../../../Providers/EndpointProvider";
import ModalHeader from "../../../../../Components/ModalHeader/ModalHeader";
import { ModalFooter } from "../../../../../Constants/StyledComponents";
import { UploadStepHeader } from "../../../../../Constants/StyledComponents";
import CircularCheckboxComponent from "../../../../../Components/CircularCheckbox/CircularCheckbox";
import { useUploadProcessProvider } from "../../../../../Providers/UploadProcessProvider";
import { UploadFilesModal, InfoButtonLabel, ModalTextInput, SelectExistingPatientDiv, PatientIDValidationMessage, HiddenButton } from "../../../../../Constants/StyledComponents";
import { useSitesProvider } from "../../../../../Providers/SitesProvider";
import MUITable from "../../../../../Components/MUITable/MUITable"
import { MobergButton } from "../../../../../Components/MobergButton/MobergButton";
import { MobergTheme } from "../../../../../Components/MobergThemes/MobergColors";
import { MobergButtonVariant } from "../../../../../Components/MobergButton/MobergButton";
import { MobergButtonShape } from "../../../../../Components/MobergButton/MobergButton";
import { formatDuration } from "../../../../../Computation/utilFunctions";
import { useGridApiRef } from "@mui/x-data-grid";
import { useBackendLinksProvider } from "../../../../../Providers/BackendLinksProvider";

const patientColumns = [
	{ field: "patient_id", flex: 0.4, headerName: "Patient ID", minWidth: 100, visible: true },
	{
		field: "cns_duration",
		flex: 1,
		headerName: "Moberg monitoring duration",
		visible: true,
		valueGetter: params => formatDuration(params.row.cns_duration),
	},
	{ field: "medication_count", flex: 0.4, headerName: "No. medications", visible: true },
]

const UploadPatientSite = ({ setToPrevWindow, setToNextWindow }) => {
	const endpointProvider = useEndpointProvider()
	const sitesProvider = useSitesProvider()
	const uploadProcessProvider = useUploadProcessProvider()
	const tableRef = useGridApiRef()
	const { LINKS } = useBackendLinksProvider()

	const handleBack = () => {
		setToPrevWindow(true)
	}

	const handleNext = () => {
		setToNextWindow(true)
	}

	String.prototype.toHHMMSS = function () {
		var secs = this / 1e6
		var sec_num = parseInt(secs, 10) // don't forget the second param'
		var days = Math.floor(sec_num / (3600 * 24))
		var hours = Math.floor((sec_num - days * 3600 * 24) / 3600)
		var minutes = Math.floor((sec_num - days * 3600 * 24 - hours * 3600) / 60)
		// var seconds = sec_num - (days * 3600 * 24) - (hours * 3600) - (minutes * 60);

		return `${days} Days ${hours} Hours ${minutes} Minutes`
	}

	/**
	 * This function is called when the user selects a patientID from the table
	 * If the user selects a patientID that is different from the previously selected site, the user is prompted to confirm but only when they have select a file in the later steps
	 */
	function onCheckPatient(patient, isClicked) {
		uploadProcessProvider.resetExistingTableData()
		if (uploadProcessProvider.patientID !== patient) {
			if (!uploadProcessProvider.checkFoldersIsEmpty()) {
				if (window.confirm("Modifying existing site ID will remove your previously selected upload files. Do you want to continue?")) {
					uploadProcessProvider.resetTableData()
					uploadProcessProvider.resetUploadFolders()
					uploadProcessProvider.resetDirectoryNames()
				} else return
			}
		}
		uploadProcessProvider.setPatientID(isClicked ? patient.patient_id : "")
		uploadProcessProvider.setPatientPrimaryKey(isClicked ? patient.id : "")
	}

	/**
	 * The following useStates handle validation for inputting a new Patient ID
	 * patientIDAvailable is true when the patient ID is available
	 */
	const [patientIDAvailable, setPatientIDAvailable] = useState(false)
	const [patientIDExists, setPatientIDExists] = useState(false)
	const [patientIDError, setPatientIDError] = useState(false)
	const [checkingPatientID, setCheckingPatientID] = useState(false)
	const [validationMessageType, setValidationMessageType] = useState("")
	const [validationMessageContent, setValidationMessageContent] = useState("")

	async function checkPatientIdentifier(body) {
		return endpointProvider.post(LINKS.DATA.UPLOAD.CHECK_PATIENT_IDENTIFIER, body)
	}

	/**
	 * This function checks if the new patient ID is valid
	 */
	useEffect(() => {
		if (!uploadProcessProvider.patientID && !patientIDError && !patientIDAvailable && !patientIDExists) {
			return
		}
		if (String(uploadProcessProvider.patientID).length === 0 || uploadProcessProvider.patientID === null || uploadProcessProvider.patientID === "") {
			setValidationMessageContent("This field is required.")
			setPatientIDExists(false)
			setPatientIDAvailable(false)
			setCheckingPatientID(false)
			setPatientIDError(true)
			return
		} else if (String(uploadProcessProvider.patientID).length !== 4) {
			setValidationMessageType("Error! ")
			setValidationMessageContent("Patient ID must be 4 digits.")
			setPatientIDExists(false)
			setPatientIDAvailable(false)
			setCheckingPatientID(false)
			setPatientIDError(true)
			return
		} else {
			setValidationMessageType("Warning! ")
			setValidationMessageContent("Checking Patient ID...")
			setPatientIDError(false)
			setPatientIDAvailable(false)
			setPatientIDExists(false)
			setCheckingPatientID(true)
		}
		checkPatientIdentifier({ site_id: uploadProcessProvider.selectedSitePrimaryKey, project_id: uploadProcessProvider.selectedProjectPrimaryKey, patient_identifier: uploadProcessProvider.patientID })
			.then(data => {
				if (data["exist"]) {
					setValidationMessageType("Warning! ")
					setValidationMessageContent(`This Patient ID already exists in Site ${uploadProcessProvider.selectedSiteID}.`)
					setPatientIDError(false)
					setPatientIDAvailable(false)
					setCheckingPatientID(false)
					setPatientIDExists(true)
					return
				} else {
					setValidationMessageType("")
					setValidationMessageContent("This Patient ID is available.")
					setPatientIDError(false)
					setPatientIDExists(false)
					setCheckingPatientID(false)
					setPatientIDAvailable(true)
					return
				}
			})
			.catch(e => {
				alert(e)
				return false
			})
	}, [uploadProcessProvider.patientID])

	const popoverTopText = {
		existingPatients: `Select the patient ID of an existing patient in Site ${uploadProcessProvider.selectedSiteID}.`,
		newPatient: "Enter the patient ID of the new patient you would like to add.",
	}
	function getPopoverTop(labelname) {
		return (
			<Popover id="Popover-trigger-hover-focus" style={{ width: "228px" }} positionleft={75}>
				<p style={{ fontFamily: "Source Sans Pro", fontStyle: "normal", fontWeight: "400", fontSize: "12px", color: "#5F6775", paddingLeft: "3px" }}>
					{popoverTopText[labelname]}
				</p>
			</Popover>
		)
	}

	/**
	 * The following functions handle the circular checkboxes for selecting existing patients or uploading a new patient
	 * It is going to uncheck an already checked checkbox and check the one that was clicked
	 * @returns
	 */
	function handleExistingPatientsCheckbox() {
		if (!uploadProcessProvider.checkFoldersIsEmpty()) {
			if (window.confirm("Modifying existing site ID will remove your previously selected upload files. Do you want to continue?")) {
				uploadProcessProvider.resetTableData()
				uploadProcessProvider.resetUploadFolders()
				uploadProcessProvider.resetDirectoryNames()
			} else return
		}
		uploadProcessProvider.setExistingPatientsCheckedProvider(true)
		if (uploadProcessProvider.newPatientCheckedProvider) {
			uploadProcessProvider.setNewPatientCheckedProvider(!uploadProcessProvider.newPatientCheckedProvider)
		} else {
			uploadProcessProvider.setNewPatientCheckedProvider(uploadProcessProvider.newPatientCheckedProvider)
		}
		uploadProcessProvider.setPatientID("")
	}

	function handleNewPatientCheckbox() {
		if (!uploadProcessProvider.checkFoldersIsEmpty()) {
			if (window.confirm("Modifying existing site ID will remove your previously selected upload files. Do you want to continue?")) {
				uploadProcessProvider.resetTableData()
				uploadProcessProvider.resetUploadFolders()
				uploadProcessProvider.resetDirectoryNames()
			} else return
		}
		uploadProcessProvider.resetTableData()
		uploadProcessProvider.resetUploadFolders()
		uploadProcessProvider.resetDirectoryNames()
		uploadProcessProvider.setNewPatientCheckedProvider(true)
		if (uploadProcessProvider.existingPatientsCheckedProvider) {
			uploadProcessProvider.setExistingPatientsCheckedProvider(!uploadProcessProvider.existingPatientsCheckedProvider)
		} else {
			uploadProcessProvider.setExistingPatientsCheckedProvider(uploadProcessProvider.existingPatientsCheckedProvider)
		}
		uploadProcessProvider.setPatientID("")
	}

	/**
	 * This function return user to the existing Patient ID that is duplicate with the new Patient ID that they created
	 */
	function handleNavigationToExistingPatient() {
		uploadProcessProvider.setNewPatientCheckedProvider(!uploadProcessProvider.newPatientCheckedProvider)
		uploadProcessProvider.setExistingPatientsCheckedProvider(!uploadProcessProvider.existingPatientsCheckedProvider)

		if (tableRef?.current) {
			const rows = tableRef?.current?.getRowModels()
			rows.forEach((value) => {
				if (value.patient_id === uploadProcessProvider?.patientID) {
					setSelectedRows([value.id])
					uploadProcessProvider?.setPatientPrimaryKey([value.id])
				}
			})
		}

	}

	const queryProps = {
		queryKey: "project_patients",
		endpoint: LINKS.DATA.UPLOAD.GET_PROJECT_PATIENTS_BY_SITE,
		body: { project_id: uploadProcessProvider.selectedProjectPrimaryKey, site_id: uploadProcessProvider.selectedSitePrimaryKey },
	}
	const [selectedRows, setSelectedRows] = useState(uploadProcessProvider?.patientPrimaryKey === '' ? [] : [uploadProcessProvider?.patientPrimaryKey])
	const preselectedProps = { selectedRows, setSelectedRows }

	return (
		<>
			<UploadFilesModal>
				<ModalHeader headerText="Select a Patient" resetCache={uploadProcessProvider.resetCache} />
				<div style={{ paddingTop: "15px", paddingLeft: "47px", paddingRight: "47px", paddingBottom: "55px", height: "600px", flex: 1 }}>
					<div style={{ marginBottom: "24px" }}>
						<UploadStepHeader>2. Choose an existing patient or upload a new patient</UploadStepHeader>
					</div>

					<div style={{ marginLeft: "auto", marginRight: "auto", textAlign: "center" }}>
						<p style={{ color: "#5F6775 !important", fontWeight: "600", marginBottom: "5px", marginTop: "-15px" }}>Site {uploadProcessProvider.selectedSiteName} | Project {uploadProcessProvider.selectedProjectName}</p>
					</div>

					<div
						id="circularCheckboxContent"
						style={{ width: "390px", height: "24px", marginLeft: "243px", marginRight: "auto", display: "inline", marginTop: "10px" }}
					>
						<CircularCheckboxComponent
							style={{ color: uploadProcessProvider.existingPatientsCheckedProvider ? "#207DEA" : "#B6B6B6" }}
							checked={uploadProcessProvider.existingPatientsCheckedProvider}
							onChangeFxn={handleExistingPatientsCheckbox}
							checkboxLabel={"Existing Patients"}
						/>
						<OverlayTrigger id="overlay-trigger" trigger={["hover", "focus"]} placement="top" overlay={getPopoverTop("existingPatients")}>
							<InfoButtonLabel onClick={e => e.preventDefault()}>
								<MdIcons.MdInfoOutline size={18} style={{ float: "right", color: "#207DEA", marginLeft: "2px", marginBottom: "-4px", marginRight: "24px" }} />
							</InfoButtonLabel>
						</OverlayTrigger>

						<CircularCheckboxComponent
							style={{ color: uploadProcessProvider.newPatientCheckedProvider ? "#207DEA" : "#B6B6B6" }}
							checked={uploadProcessProvider.newPatientCheckedProvider}
							onChangeFxn={handleNewPatientCheckbox}
							checkboxLabel={"Upload a New Patient"}
						/>
						<OverlayTrigger id="overlay-trigger" trigger={["hover", "focus"]} placement="top" overlay={getPopoverTop("newPatient")}>
							<InfoButtonLabel onClick={e => e.preventDefault()}>
								<MdIcons.MdInfoOutline size={18} style={{ float: "right", color: "#207DEA", marginLeft: "2px", marginBottom: "-4px" }} />
							</InfoButtonLabel>
						</OverlayTrigger>
					</div>

					<div
						id="existingPatientsContentDiv"
						style={{
							marginTop: "25px",
							display: uploadProcessProvider.existingPatientsCheckedProvider ? "flex" : "none",
							flexDirection: 'column',
							width: "100%",
							marginLeft: "auto",
							marginRight: "auto",
							maxHeight: '500px',
							overflow: 'auto'
						}}
					>
						<MUITable columns={patientColumns} disableMultiSelect={onCheckPatient} disableSelectAll={true} preselectedProps={preselectedProps} {...queryProps} style={{ padding: '10px' }} tableRef={tableRef} />
					</div>

					<div
						id="newPatientContentDiv"
						style={{
							marginTop: "25px",
							display: uploadProcessProvider.newPatientCheckedProvider ? "block" : "none",
							width: "700px",
							marginLeft: "auto",
							marginRight: "auto",
							textAlign: "center",
						}}
					>
						<div style={{ position: "relative", width: "415px", marginLeft: "auto", marginRight: "auto", textAlign: "center" }}>
							<ModalTextInput
								placeholder="Enter a new Patient ID"
								onKeyPress={event => {
									if (!/[0-9]/.test(event.key)) {
										event.preventDefault()
									}
								}}
								maxLength={4}
								onChange={e => {
									uploadProcessProvider.setPatientID(e.target.value)
								}}
								style={{
									border: patientIDAvailable
										? "2px solid #CCCCCC"
										: patientIDExists || checkingPatientID
											? "2px solid #EEA10C"
											: patientIDError
												? "2px solid #E54E58"
												: "2px solid #CCCCCC",
								}}
								value={uploadProcessProvider.patientID}
							/>

							{patientIDAvailable ? (
								<MdIcons.MdOutlineCheckCircleOutline
									size={30}
									style={{ position: "absolute", right: "15px", top: "12px", color: "#0FA95F", display: patientIDAvailable ? "block" : "none" }}
								/>
							) : patientIDExists || checkingPatientID ? (
								<MdIcons.MdOutlineErrorOutline
									size={30}
									style={{
										position: "absolute",
										right: "15px",
										top: "12px",
										color: "#EEA10C",
										display: patientIDExists || checkingPatientID ? "block" : "none",
									}}
								/>
							) : patientIDError ? (
								<MdIcons.MdOutlineErrorOutline
									size={30}
									style={{ position: "absolute", right: "15px", top: "12px", color: "#E54E58", display: patientIDError ? "block" : "none" }}
								/>
							) : null}
						</div>

						<PatientIDValidationMessage
							style={{
								display: patientIDError ? "block" : patientIDExists || checkingPatientID ? "block" : patientIDAvailable ? "block" : "none",
								color: patientIDAvailable ? "#0FA95F" : patientIDExists || checkingPatientID ? "#EEA10C" : patientIDError ? "#E54E58" : null,
							}}
						>
							<strong>{validationMessageType}</strong>
							{validationMessageContent}
						</PatientIDValidationMessage>

						<SelectExistingPatientDiv style={{ display: patientIDExists ? "block" : "none" }}>
							<p>
								Would you like to select Patient <strong>{uploadProcessProvider.patientID}</strong> at Site <strong>{uploadProcessProvider.selectedSiteID}</strong>?
							</p>
							<button onClick={() => { handleNavigationToExistingPatient() }}>
								Select
							</button>
						</SelectExistingPatientDiv>
					</div>


				</div>

				<hr style={{ border: "1px solid #B3B3B3", width: "100%", margin: '0px auto' }} />
				<ModalFooter>
					{sitesProvider.userSites?.length !== 1 ? (
						<MobergButton
							theme={MobergTheme.BLUE}
							variant={MobergButtonVariant.OUTLINE}
							shape={MobergButtonShape.WIDE}
							onClick={handleBack}
						>
							Back
						</MobergButton>
					) : (
						<HiddenButton />
					)}
					<MobergButton
						theme={MobergTheme.BLUE}
						variant={MobergButtonVariant.FILLED}
						shape={MobergButtonShape.WIDE}
						onClick={handleNext}
						disabled={!((uploadProcessProvider.existingPatientsCheckedProvider && uploadProcessProvider.patientID) || (uploadProcessProvider.newPatientCheckedProvider && patientIDAvailable))}
					>
						Next
					</MobergButton>
				</ModalFooter>
			</UploadFilesModal>
		</>
	)
}

export default UploadPatientSite
