import React from 'react'
import styled from "styled-components"
import MUITable from '../../Components/MUITable/MUITable';
import { useLocation, Link } from 'react-router-dom';
import { useEndpointProvider } from '../../Providers/EndpointProvider';
import { useState } from 'react';
import { FRONTEND_LINKS } from '../../Constants/FrontendLinks';
import { MobergButton, MobergButtonShape, MobergIconSize, MobergTheme } from '../../Moberg';
import { MdDownload, MdKeyboardArrowLeft } from 'react-icons/md';
import { useOnMount } from '../../Hooks/useOnMount';
import { useMobergTheme } from '../../Providers/MobergThemeProvider';
import { useBackendLinksProvider } from '../../Providers/BackendLinksProvider';

export function Webinar() {
	const theme = useMobergTheme()
	const endpointProvider = useEndpointProvider();
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const id = searchParams.get('id');
	const [downloadingStatus, setDownloadingStatus] = useState({});
	const [webinar, setWebinar] = useState({})
	const { LINKS } = useBackendLinksProvider()

	async function initiateDownload(bucketID, filename, rowId) {
		const body = { upload_id: bucketID, filename };
		setDownloadingStatus(prevStatus => ({ ...prevStatus, [rowId]: 'loading' }));

		endpointProvider
			.post(LINKS.DATA.UPLOAD.DOWNLOAD_WEBINAR_FILES, body, { responseType: 'blob' })
			.then(async (response) => {
				const url = window.URL.createObjectURL(response);
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', filename);
				document.body.appendChild(link);
				link.click();
				link.parentNode.removeChild(link);
				window.URL.revokeObjectURL(url);
				setDownloadingStatus(prevStatus => ({ ...prevStatus, [rowId]: 'completed' }));

			})
			.catch((error) => {
				setDownloadingStatus(prevStatus => {
					const newStatus = { ...prevStatus };
					delete newStatus[rowId];
					return newStatus;
				});
			});
	}

	useOnMount(() => {
		endpointProvider.post(LINKS.RESOURCES.WEBINARS.GET_WEBINAR, { webinar_id: Number(id) })
			.then(webinar => setWebinar(webinar))
			.catch(err => console.error(err))
	})

	const columns = [
		{ field: 'filename', headerName: 'File name', flex: 1 },
		{ field: 'size', headerName: 'Size', flex: 1 },
		{
			field: 'download',
			headerName: 'Download',
			flex: 0.3,
			renderCell: (params) => (
				<div style={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "center" }}>
					<MobergButton
						onClick={() => { initiateDownload(params.row.bucket_id, params.row.cloud_filename, params.row.id) }}
						theme={MobergTheme.BLUE}
						shape={MobergButtonShape.SQUARE}
						disabled={downloadingStatus[params.row.id] === 'loading'}
						style={{ padding: "4px" }}
					>
						<MdDownload size={MobergIconSize.REGULAR} />
					</MobergButton>
				</div>
			),
		},
	];

	const tableToolbarProps = {
		createButton: { isShow: false },
		deleteButton: { isShow: false },
		filterButton: { isShow: false },
		exportButton: { isShow: false },
		searchInput: { isShow: false },
	}

	const queryProps = {
		endpoint: LINKS.RESOURCES.WEBINARS.GET_WEBINAR_FILES,
		queryKey: "webinar_files",
		body: { "webinar_id": Number(id) }
	}

	return (
		<div style={{ padding: "30px" }}>
			<SubheaderLink color={theme.colors.main} >
				<h2>
					<Link to={FRONTEND_LINKS.SUBPAGES.RESOURCES.WEBINARS.MAIN}>
						<MdKeyboardArrowLeft size={20} /> Webinars
					</Link>
				</h2>
			</SubheaderLink>

			<p style={{ fontSize: "16px", fontFamily: "Montserrat", fontWeight: "600" }}>
				{webinar.title}
			</p>

			<iframe
				src={webinar.embed_link}
				title='Webinar Preview'
				allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
				allowFullScreen
				style={{ width: "100%", height: "500px", border: "none", pointerEvents: "auto" }}
			/>

			<p style={{ fontSize: "14px", fontFamily: "Montserrat", marginTop: '20px', fontWeight: '500', }}>{webinar.description}</p>

			<SourceLink href={webinar.source_link} color={theme.colors.main} target="_blank" rel="noopener noreferrer">Watch the full webinar here</SourceLink>

			<div style={{ marginTop: "16px" }}>
				<MUITable
					{...queryProps}
					columns={columns}
					isRowSelectable={() => false}
					isCheckboxSelection={false}
					disableHighlightRows={true}
					style={{ padding: "0" }}
					tableToolbarProps={tableToolbarProps} />
			</div>
		</div>
	)
}

const SubheaderLink = styled.button`
	background: none;
	border: none;
	padding: 10px 0;
	h2 {
		font-family: "Source Sans Pro";
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 150%;
		color: ${props => props.color};
	}
`

const SourceLink = styled.a`
	color: ${props => props.color};
	font-size: 14px;
	font-family: "Montserrat";
	margin-top: 10px;
	font-weight: 500;
`