import MUITable from "../../../Components/MUITable/MUITable"
import { useModalProvider } from "../../../Providers/ModalProvider"
import { UploadProcessProvider } from "../../../Providers/UploadProcessProvider"
import UploadWindow from "./UploadsSubpages/UploadWindow"
import { useNavigate } from "react-router-dom"
import { FRONTEND_LINKS } from "../../../Constants/FrontendLinks"
import { useState, useEffect } from "react"
import * as MdIcons from "react-icons/md"
import React from "react"
import { useEndpointProvider } from "../../../Providers/EndpointProvider"
import { InfoMessage } from "../../../Constants/StyledComponents"
import ModifyPatientModal from "./ModifyPatientModal"
import { humanReadableSize, localizeDate } from "../../../Computation/utilFunctions"
import styled from "styled-components"
import { useAuthProvider } from "../../../Providers/AuthProvider"
import { useBackendLinksProvider } from "../../../Providers/BackendLinksProvider"

export default function DataVisualize() {
	const { createModal } = useModalProvider()
	const navigate = useNavigate()
	const endpointProvider = useEndpointProvider()
	const authProvider = useAuthProvider()
	const { LINKS } = useBackendLinksProvider()

	async function handleSelectedRow(selectedRows) {
		navigate(`${FRONTEND_LINKS.SUBPAGES.DATA.SUBPAGES.VISUALIZE.SUBPAGES.PATIENTS.MAIN}?patient_id=${selectedRows.patient_key}`, { state: selectedRows })
	}

	const columns = [
		{
			field: "patient_identifier", flex: 0.2, headerName: "Patient", minWidth: 100, visible: true, renderCell: params => {
				return (
					<Linkbutton
						title={params.row.patient_id}
						onClick={() => {
							handleSelectedRow(params.row)
						}}
					>
						<strong>
							{params.row.patient_id}
						</strong>
					</Linkbutton>
				)
			},
		},
		{ field: "site_id", flex: 0.4, valueGetter: params => `${params.row.site_identifier || ""}: ${params.row.site_name || ""}`, headerName: "Site", minWidth: 80, visible: true },
		{ field: "project_id", flex: 0.4, valueGetter: params => `${params.row.project_identifier || ""}: ${params.row.project_name || ""}`, headerName: "Project", minWidth: 100, visible: true },
		{
			field: "data_file_size",
			flex: 0.15,
			headerName: "File size",
			valueGetter: params => humanReadableSize(Number(params.row.data_file_size)),
			minWidth: 80,
			visible: true
		},
		{
			field: "status",
			flex: 0.20,
			headerName: "Files loaded",
			minWidth: 100,
			visible: true,
			valueGetter: params => params.row.status ? params.row.status : 'Unknown'
		},
		{ field: "annotation_count", flex: 0.25, headerName: "No. annotations", valueGetter: params => params.row.annotation_count, minWidth: 140, visible: true },
		{ field: "start_time", flex: 0.4, headerName: "Start time", minWidth: 160, visible: true, valueGetter: (params) => params.row.start_time ? localizeDate(new Date(params.row.start_time), params.row.timezone[0]) : 'Unknown' },
		{ field: "end_time", flex: 0.4, headerName: "End time", visible: true, minWidth: 160, valueGetter: (params) => params.row.end_time ? localizeDate(new Date(params.row.end_time), params.row.timezone[0]) : "Unknown" },
		// get monitoring time by subtracting start time from end time in days, hours, minutes, seconds format
		{
			field: "total_monitoring_time",
			flex: 0.4,
			headerName: "Total monitoring time",
			valueGetter: params => {
				if (!params.row.total_monitoring_time) {
					return 'Unknown'
				}

				const total_monitoring_time_seconds = params.row.total_monitoring_time / 1000 // Convert milliseconds to seconds
				var days = Math.floor(total_monitoring_time_seconds / (3600 * 24))
				var hours = Math.floor((total_monitoring_time_seconds - (days * 3600 * 24)) / 3600)
				var minutes = Math.floor((total_monitoring_time_seconds - (days * 3600 * 24) - (hours * 3600)) / 60)


				return `${days} Day${days > 1 || days === 0 ? 's' : ''} ${hours} Hour${hours > 1 || hours === 0 ? 's' : ''} ${minutes} Minute${minutes > 1 || minutes === 0 ? 's' : ''}`
			},
			visible: true,
			minWidth: 180
		},
		{
			field: "timestamp",
			flex: 0.4,
			headerName: "Most recent upload",
			visible: true,
			minWidth: 180,
			valueGetter: (params) => localizeDate(new Date(params.row.timestamp))
		},
		{
			field: "first_name",
			flex: 0.4,
			headerName: "Uploaded by",
			visible: true,
			minWidth: 180,
			valueGetter: params => `${params.row.first_name} ${params.row.last_name}`
		},
	]

	function renderUploadModal() {
		createModal(
			<UploadProcessProvider>
				<UploadWindow escClose={false} clickOutsideClose={false} />
			</UploadProcessProvider>
		)
	}

	async function getPrefetchInventory() {
		let body = {}
		return endpointProvider.post(LINKS.ADMIN.PREFETCH.GET_PREFETCH_INVENTORY, body)
	}

	async function getPrefetchQueue() {
		let body = {}
		return endpointProvider.post(LINKS.ADMIN.PREFETCH.GET_PREFETCH_QUEUE, body)
	}

	const [currentInventorySize, setCurrentInventorySize] = useState("loading...")
	const [maxInventorySize, setMaxInventorySize] = useState("loading...")
	const [refreshInfoMessage, setRefreshInfoMessage] = useState(false)

	const [showCurrentPrefetchMessage, setShowCurrentPrefetchMessage] = useState(false)
	const [currentlyPrefetchingFile, setCurrentlyPrefetchingFile] = useState()
	const [currentlyPrefetchingFileName, setCurrentlyPrefetchingFileName] = useState()

	const [prefetchQueueData, setPrefetchQueueData] = useState([]) // holds all files that are waiting in queue to be loaded/prefetched

	const [prefetchInventoryData, setPrefetchInventoryData] = useState([]) //holds all files that are currently loaded/prefetched

	useEffect(() => {
		const fetchData = async () => {
			const inventoryData = await getPrefetchInventory()
			setPrefetchInventoryData(inventoryData)

			const queueData = await getPrefetchQueue()
			setPrefetchQueueData(queueData)
		}

		fetchData()
	}, [refreshInfoMessage])

	useEffect(() => {
		if (!prefetchQueueData.directories || prefetchQueueData.directories?.length === 0) {
			setShowCurrentPrefetchMessage(false)
			return
		} else {
			let displayedName = prefetchQueueData.directories[0].name.split("/")
			setCurrentlyPrefetchingFileName(displayedName[displayedName.length - 1])
			setCurrentlyPrefetchingFile(prefetchQueueData.directories[0])
			setShowCurrentPrefetchMessage(true)
		}
	}, [prefetchQueueData])

	useEffect(() => {
		if (!prefetchInventoryData || !prefetchQueueData.directories || Object.keys(prefetchInventoryData).length === 0) {
			return
		}

		let currentSize = parseFloat(prefetchInventoryData.current_size)
		let maxSize = parseFloat(prefetchInventoryData.max_total_size)
		let total_current_size = prefetchQueueData.directories.reduce((accumulator, currentValue) => parseFloat(accumulator) + parseFloat(currentValue.size), 0) + currentSize
		let currentInventorySize = humanReadableSize(total_current_size)
		setCurrentInventorySize(currentInventorySize)
		let maxInventorySize = humanReadableSize(maxSize)
		setMaxInventorySize(maxInventorySize)
	}, [prefetchInventoryData, prefetchQueueData])

	async function renderModifyPatientModal(patientID) {
		createModal(<ModifyPatientModal escClose={false} clickOutsideClose={false} currentPatientID={patientID} style={{ width: "780px" }} />)
	}

	const patientTableToolbarProps = {
		createButton: { title: "Upload patient", isShow: true, onClick: renderUploadModal, disabled: !authProvider.permissions.upload_data },
		deleteButton: { isShow: false },
		filterButton: { title: "Filter", isShow: false },
		exportButton: { isShow: false },
		searchInput: { isShow: true },
		columnButton: { isShow: true },
	}

	const queryProps = {
		queryKey: "patients",
		endpoint: LINKS.DATA.PROFILING.GET_PATIENTS,
	}

	return (
		<div style={{ backgroundColor: "#f8f8f8" }}>
			<div style={{ padding: '10px 30px 0px' }}>
				<InfoMessage style={{ display: 'flex', justifyContent: 'space-between' }}>
					<p><strong>Info! </strong> {currentInventorySize} of patient data has been reserved out of {maxInventorySize}. {showCurrentPrefetchMessage ? `Currently loading file ${currentlyPrefetchingFileName + " for patient ID " + currentlyPrefetchingFile['patient_id'] + " at site " + currentlyPrefetchingFile['site'] + " ..."} (${currentlyPrefetchingFile.percent_loaded.toFixed(2)}% complete)` : ""}</p>

					<MdIcons.MdRefresh size={18}
						style={{ right: "10px", cursor: "pointer", color: "#207DEA" }}
						onClick={() => { setRefreshInfoMessage(!refreshInfoMessage) }}
					/>
				</InfoMessage>
			</div>
			<MUITable columns={columns} tableToolbarProps={patientTableToolbarProps} {...queryProps} handleOnRowClick={handleSelectedRow} isRowSelectable={() => false} isCheckboxSelection={false} />
		</div>
	)
}


const Linkbutton = styled.button`
	background: none!important;
	border: none;
	padding: 0!important;
	/*optional*/
	font-family: arial, sans - serif;
	/*input has OS specific font-family*/
	color: #0097EE;
	text-decoration: underline;
	cursor: pointer;
`