import { D3TimelineConfig } from "../../../D3/Timeline/D3Timeline";
import { ConfigurationBuilder } from "../../D3ConfigurationBuilder"
import { D3ScatterPlot } from "./D3ScatterPlot";
import { D3ScatterPlotCanvasConfig } from "./D3ScatterPlotCanvas";
import { ScatterPlotXAxisConfig } from "./D3ScatterPlotXAxis";
import { ScatterPlotYAxisConfig } from "./D3ScatterPlotYAxis";

export class D3ScatterPlotConfigurationBuilder extends ConfigurationBuilder<D3ScatterPlot> {
    public getXAxisConfig = (): ScatterPlotXAxisConfig => ({
        boundingBox: this.visualization.boundingBox,
        scale: this.visualization.xScale,
		stepSize: this.visualization.config.xAxisConfig.stepSize,
		xAxisConfig: this.visualization.config.xAxisConfig,
		onDrag: () => this.visualization.onXAxisDrag(),
		onDoubleClick: () => this.visualization.onXAxisAutoScale()
    })

    public getYAxisConfig = (): ScatterPlotYAxisConfig => ({
        scale: this.visualization.yScale,
		stepSize: this.visualization.config.yAxisConfig.stepSize,
		yAxisConfig: this.visualization.config.yAxisConfig,
		onDrag: () => this.visualization.onYAxisDrag(),
		onDoubleClick: () => this.visualization.onYAxisAutoScale(),
    })

    public getTimelineConfig = (): D3TimelineConfig => {
        const timelineUpdateTimes = (start: number, end: number) => {
			this.visualization.viewTimesChanged(start, end)
			this.visualization.updateLinkedWindows()
		}

		return {
			id: this.visualization.config.id,
			viewScale: this.visualization.config.viewScale,
			fileScale: this.visualization.config.fileScale,
			width: this.visualization.boundingBox.width,
			annotations: [],
			playbackSpeed: this.visualization.config.playbackSpeed,
			currentTimelineController: this.visualization.config.timelineController,
			isLinked: this.visualization.config.isLinked,
			timeZone: this.visualization.config.timeZone,
			onDrag: this.visualization.onTimelineSliderDrag,
			onDragEnd: this.visualization.onTimelineSliderDragEnd,
			updateViewTimes: timelineUpdateTimes,
			goToStart: this.visualization.goToStart,
			goToEnd: this.visualization.goToEnd,
			goToNextPage: this.visualization.goToNextPage,
			goToPreviousPage: this.visualization.goToPreviousPage,
			liveModeEnabled: this.visualization.config.liveModeEnabled
		}
    }

	public getScatterPlotCanvasConfig = (): D3ScatterPlotCanvasConfig => ({
		data: this.visualization.data,
		yScale: this.visualization.yScale,
		xScale: this.visualization.xScale
	})
}