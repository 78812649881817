import { useCallback } from "react"
import { GridActionsCellItem } from "@mui/x-data-grid"
import { useEndpointProvider } from "../../../Providers/EndpointProvider"
import { useModalProvider } from "../../../Providers/ModalProvider"
import CreateSiteModals from "./Subcomponents/CreateSiteModals"
import ModifySiteModals from "./Subcomponents/ModifySiteModal"
import MUITable from "../../../Components/MUITable/MUITable"
import EyeIcon from "../../../Components/Icons/EyeIcon"
import { MdOutlineModeEdit } from "react-icons/md"
import { useBackendLinksProvider } from "../../../Providers/BackendLinksProvider"

export default function Sites() {
	const { createModal } = useModalProvider()
	const endpointProvider = useEndpointProvider()
	const { LINKS } = useBackendLinksProvider()

	function renderSiteModal() {
		createModal(<CreateSiteModals style={{ height: "80%" }} />)
	}

	const handleClick = useCallback((row, viewMode) => {
		createModal(<ModifySiteModals style={{ height: "80%" }} row={row} viewMode={viewMode} />)
	}, [createModal])

	const columns = [
		{ field: "identifier", flex: 0.4, headerName: "Site ID", minWidth: 100, visible: true },
		{ field: "name", flex: 1, headerName: "Site name", minWidth: 120, visible: true },
		{ field: "description", flex: 1, headerName: "Description", minWidth: 110, visible: true },
		{ field: "location", flex: 1, headerName: "Location", minWidth: 120, visible: true },
		{ field: "user_count", flex: 0.4, headerName: "No. users", valueGetter: params => params.row.user_count, minWidth: 100, visible: true },
		{ field: "patient_count", flex: 0.4, headerName: "No. patients", valueGetter: params => params.row.patient_count, minWidth: 120, visible: true },
		{
			field: "actions",
			flex: 0.4,
			headerName: "Actions",
			type: "actions",
			getActions: params => [
				<GridActionsCellItem icon={<EyeIcon />} label="View" onClick={() => handleClick(params.row, true)} showInMenu />,
				<GridActionsCellItem icon={<MdOutlineModeEdit size={20} />} label="Edit" onClick={() => handleClick(params.row)} showInMenu />,
			],
			visible: true,
			minWidth: 100
		},
	]

	const deleteSites = async site_ids => endpointProvider.post(LINKS.ADMIN.SITES.DELETE_SITES, { site_ids })
	const siteTableToolbarProps = {
		createButton: { title: "Create site", isShow: true, onClick: renderSiteModal },
		deleteButton: {
			title: "Delete",
			isShow: true,
			onSuccessMessage: "Sites deleted successfully",
			invalidateQueryKey: "sites",
			onClick: site_ids => deleteSites(site_ids),
		},
		filterButton: { title: "Filter", isShow: false },
		exportButton: { isShow: false },
		searchInput: { isShow: true },
		columnButton: { isShow: true },
		nameForMultipleRows: "sites"
	}

	const queryProps = {
		queryKey: "sites",
		endpoint: LINKS.ADMIN.SITES.GET_SITES,
	}

	return (
		<MUITable style={{ padding: "30px 30px" }} columns={columns} tableToolbarProps={siteTableToolbarProps} {...queryProps} />
	)
}