import * as MdIcons from "react-icons/md";
import PIPELINE_STRUCTURE from "../../../../../Constants/NodeStructures";
import { ToolbarItem } from "./ToolbarItem";


const ToolBar = ({ addNodeToCanvas }) => {

    function buildToolbarItem(type, label, subtype, iconBuildFn) {
        return (
            <ToolbarItem key={`${label}_${subtype}`}>
                {iconBuildFn({onClick: () => {addNodeToCanvas(type, subtype)}})}
                <div>{label}</div>
            </ToolbarItem>
        )
    }

    const sourceItems = Object.keys(PIPELINE_STRUCTURE.SOURCE)
        .filter(key => key !== "DESCRIPTION")
        .map(subtype => {
            const item = PIPELINE_STRUCTURE.SOURCE[subtype]
            return buildToolbarItem("SOURCE", item.DISPLAY_NAME, subtype, (props) => <MdIcons.MdSource {...props} size={18}/>)
        })

    const dataOrganizerItems = Object.keys(PIPELINE_STRUCTURE.DATA_ORGANIZER)
        .filter(key => key !== "DESCRIPTION")
        .map(subtype => {
            const item = PIPELINE_STRUCTURE.DATA_ORGANIZER[subtype]
            return buildToolbarItem("DATA_ORGANIZER", item.DISPLAY_NAME, subtype, (props) => <MdIcons.MdMediation {...props} size={18}/>)
        })

    const analyticItems = Object.keys(PIPELINE_STRUCTURE.ANALYTIC)
        .filter(key => key !== "DESCRIPTION")
        .map(subtype => {
            const item = PIPELINE_STRUCTURE.ANALYTIC[subtype]
            return buildToolbarItem("ANALYTIC", item.DISPLAY_NAME, subtype, (props) => <MdIcons.MdOutlineAnalytics {...props} size={18}/>)
        })

    const visualizationItems = Object.keys(PIPELINE_STRUCTURE.VISUALIZATION)
        .filter(key => key !== "DESCRIPTION")
        .map(subtype => {
            const item = PIPELINE_STRUCTURE.VISUALIZATION[subtype]
            return buildToolbarItem("VISUALIZATION", item.DISPLAY_NAME, subtype, (props) => <MdIcons.MdAreaChart {...props} size={18}/>)
        })

    return (
        <div style={{display: "flex"}}>
            <div style={{ height: "200px", overflow: "auto", padding: "20px"}}>
                {sourceItems}
            </div>
            <div style={{ height: "200px", overflow: "auto", padding: "20px"}}>
                {dataOrganizerItems}
            </div>
            <div style={{ height: "200px", overflow: "auto", padding: "20px"}}>
                {analyticItems}
            </div>
            <div style={{ height: "200px", overflow: "auto", padding: "20px"}}>
                {visualizationItems}
            </div>
        </div>
    )

}

export default ToolBar