import React, { createContext, useContext, useMemo } from 'react'
import { useBackendLinksProvider } from './BackendLinksProvider'
import { Socket, io } from 'socket.io-client'

type SocketContextValue = {
    getDataQuerySocket: (id?: string) => Socket
    closeDataQuerySocketConnections: () => void
}

const SocketContext = createContext<SocketContextValue>({} as SocketContextValue)

export const SocketProvider: React.FC = ({ children }) => {
    const { LINKS } = useBackendLinksProvider()

    const value = useMemo(() => {
        const sockets = new Map<string, Socket>()

        const getDataQuerySocket = (id = "default"): Socket => {
            let socket = sockets.get(id)

            if (!socket) {
                const url = id === "prefetch" ? LINKS.QUERY_PREFETCH : LINKS.QUERY
                socket = io(url, { transports: ["websocket"] })
                sockets.set(id, socket)

                socket.on("error", error => {
                    console.error("Socket error:", error)
                })
            }

            if (socket.disconnected) {
                socket.connect()
            }

            return socket
        }

        const closeDataQuerySocketConnections = () => {
            sockets.forEach(socket => socket.disconnect())
            sockets.clear()
        }

        // Return the functions so they are accessible in the context
        return { getDataQuerySocket, closeDataQuerySocketConnections }
    }, [LINKS])

    return <SocketContext.Provider value={value}>
        {children}
    </SocketContext.Provider>
}

export const useSocketProvider = () => useContext(SocketContext)