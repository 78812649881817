import { useModalProvider } from '../../../../../Providers/ModalProvider';
import { ConfirmationModalContainer, ConfirmationModalContent} from '../../../../../Constants/StyledComponentsConfirmationModals';
import * as MdIcons from 'react-icons/md';
import { useHotkeysProvider } from '../../../../../Providers/HotkeysProvider';
import ConfirmationModalButton, { buttonColor } from '../../../../../Components/ConfirmationModalButton/ConfirmationModalButton';

const ResetToDefaultModal = () => {
    const hotkeysProvider = useHotkeysProvider()
    const { close } = useModalProvider()

    function handleResetToDefault() {
        hotkeysProvider.setResetToDefault(true)
        close()
    }

    return (
        <ConfirmationModalContainer>
            <ConfirmationModalContent>
                <MdIcons.MdHistory size={81} style={{color: "#AEB7C6", marginBottom: "20px"}}/>
                <h1 style={{fontFamily: 'Montserrat', fontStyle: "normal", fontWeight: "700", fontSize: "24px", lineHeight: "110%", color: "#000000"}}>Reset to default?</h1>
                <p>All changes made will be reset to the default annotation configurations. This action can not be undone.</p>
            </ConfirmationModalContent>

            <ConfirmationModalButton
                buttonText="Yes, reset to default"
                onClickFxn={() => {handleResetToDefault()}}
                color={buttonColor.blueFill}
            />
            <ConfirmationModalButton
                buttonText='Cancel'
                color={buttonColor.blueOutline}
                onClickFxn={() => {close()}}
            />

        </ConfirmationModalContainer>
    )
}


export default ResetToDefaultModal;