import { GraphType } from "../../../../../../../../Enums/GraphType"
import { Annotation } from "../../../../../../../../Managers/VisualizationManager/Variables/Annotations"
import { AnnotationGeometry } from "./AnnotationGeometry"
import { EEGMontageAnnotationGeometryGenerator } from "./EEGMontageAnnotationGeometryGenerator"
import { ModalityGraphAnnotationGeometryGenerator } from "./ModalityGraphAnnotationGeometryGenerator"
import { EEGMontageGraph } from "../../../../Types/MontageGraph"
import { ModalityGraph } from "../../../../Types/ModalityGraph"
import { GraphConfig } from "../../../../Types/Graph"
import { ScaleTime } from "d3"
import { MarginedBoundingBox } from "../../../../Types/MarginedBoundingBox"

export class AnnotationGeometryGenerator {
	montageGeometryGenerator: EEGMontageAnnotationGeometryGenerator
	modalityGraphGeometryGenerator: ModalityGraphAnnotationGeometryGenerator
	private type: GraphType

	constructor(type: GraphType) {
		this.type = type
		this.montageGeometryGenerator = new EEGMontageAnnotationGeometryGenerator()
		this.modalityGraphGeometryGenerator = new ModalityGraphAnnotationGeometryGenerator()
		this.type = type
	}

	fullHeightGeometry(annotation: Annotation, boundingBox: MarginedBoundingBox, xScale: ScaleTime<any, any, any>): AnnotationGeometry {
		return {
			x: xScale(annotation.start_time),
			y: boundingBox.y,
			width: Math.max(xScale(annotation.end_time) - xScale(annotation.start_time), 3),
			height: boundingBox.height,
			color: annotation.color,
			opacity: annotation.opacity,
			annotation: annotation,
		}
	}

	generate(annotation: Annotation, boundingBox: MarginedBoundingBox, xScale: ScaleTime<any, any, any>, graphs: GraphConfig[]): AnnotationGeometry | AnnotationGeometry[] {
		if (annotation.isModalitySpecific()) {

			return graphs.flatMap((graph: GraphConfig) => {
				switch (this.type) {
					case GraphType.EEG_MONTAGE:
						return this.montageGeometryGenerator.generate(annotation, graph as EEGMontageGraph, xScale)
					default:
						return this.modalityGraphGeometryGenerator.generate(annotation, graph as ModalityGraph, xScale)
				}
			}).filter((geom: AnnotationGeometry | null): geom is AnnotationGeometry => geom != null)
		}

		return this.fullHeightGeometry(annotation, boundingBox, xScale)
	}
}
