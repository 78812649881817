import { atomFamily } from "recoil"
import { LayoutWindowId } from "../Types/LayoutWindowId"
import { HistogramConfig } from "../Components/Visualizations/Histogram/D3/D3Histogram"
import { getDefaultVisualizationAtom } from "./Visualizations"

export const histogramConfigAtom = atomFamily<HistogramConfig, LayoutWindowId>({
    key: "HistogramConfig",
    default: () => ({
        ...getDefaultVisualizationAtom(),
        countMax: 100,
        binMinimum: 10,
        binMaximum: 25,
        binSize: 5,
        modalityConfigs: [],
    })
})
