import { EEGMontageConfig, EEGMontageConfigJSON } from "../Types/EEGMontageConfig"
import { EEG_LOW_FILTERS, EEG_HIGH_FILTERS, EEG_NOTCH_FILTERS, EEG_SENSITIVITY } from "../Types/EEG"
import { LayoutWindowId } from "../Types/LayoutWindowId"
import { atomFamily, atom } from "recoil";
import { EEGMontage } from "../Types/EEG";
import { getDefaultAnnotationVisualizationAtom } from "./Visualizations";

export const DEFAULT_EEG_JSON: EEGMontageConfigJSON = {
	viewDuration: 30 * 1000,
	playbackSpeed: 1,
	montage: null,
	LFF: EEG_LOW_FILTERS["1Hz"],
	HFF: EEG_HIGH_FILTERS["30Hz"],
	notch: EEG_NOTCH_FILTERS["60Hz"],
	sensitivityMicroVolts: EEG_SENSITIVITY["10 uV"],
	isLinked: false,
}

export const eegMontageConfigAtom = atomFamily<EEGMontageConfig, LayoutWindowId>({
	key: "eegMontageConfig",
	default: () => ({
		...getDefaultAnnotationVisualizationAtom(),
		montage: null,
		montageMapping: new Map(),
		LFF: EEG_LOW_FILTERS["1Hz"],
		HFF: EEG_HIGH_FILTERS["30Hz"],
		notch: EEG_NOTCH_FILTERS["60Hz"],
		sensitivityMicroVolts: EEG_SENSITIVITY["10 uV"],
	}),
})

export const eegMontagesAtom = atom<EEGMontage[]>({
	key: "eegMontages",
	default: []
})
