import { USER_INPUT_TYPES } from "../../UserInputTypes"
import { NODE_OUTPUT_TYPES } from "../../NodeOutputTypes"

export const GROWING_WINDOW_DATA_SPLITTER = {
    INPUTS: [
        {
            DISPLAY_NAME: "Grow Duration",
            VARIABLE_NAME: "grow_duration",
            TYPE: USER_INPUT_TYPES.DURATION_INPUT,
            DEFAULT_VALUE: {"seconds": 1},
            DESCRIPTION: "The size that the window will grow each iteration.",
        },
        {
            DISPLAY_NAME: "Reverse Direction",
            VARIABLE_NAME: "reverse_direction",
            TYPE: USER_INPUT_TYPES.CHECKBOX_INPUT,
            DEFAULT_VALUE: false,
            DESCRIPTION: "If False, start the growing window at the beginning. If True, start the growing window at the end.",
        },
    ],
    DISPLAY_NAME: "Growing Window Data Splitter",
    PRODUCT_LEVEL: "Standard",
    DESCRIPTION: "Split the time series into pieces, using a growing window.",
    KEYWORDS: ["data", "split", "growing"],
    DATA_INPUT_TYPES: [NODE_OUTPUT_TYPES.TIME_SERIES],
    DATA_OUTPUT_TYPE: NODE_OUTPUT_TYPES.TIME_SERIES
}