// Be careful to use DataTable, it doesn't provide explicit API
// to control the selection behaviors. Anytime you want to delete
// a row which has already been selected, you should unselect it
// first. DataTable itself would not automatically delete the row
// from selected row in its state. That might raise unpredictable
// exception. If you are not going to delete all the selected rows,
// while you can use clearSelectedRows, then please manually call click()
// on clickCheckBox to release the selection before delete the row.

// get Select-All-Checkbox By tableId
export const selectAllCheckbox = (tableId) => document.getElementById(tableId)?.children[0]?.children[0]?.children[0]?.children[0]?.children[0]?.children[0]?.children[0]?.children[0]

export const firstCheckbox = (tableId) => document.getElementById(tableId)?.children[0]?.children[0]?.children[0]?.children[0]?.children[1]?.children[0]?.children[0]?.children[0]

// get Checkbox By rowId. Reminder: This method is not stable!
export const checkbox = (rowId) => document.getElementById(`row-${rowId}`)?.children[0]?.children[0]

export const rowIds = (tableId) => [...((document.getElementById(tableId)?.children[0]?.children[0]?.children[0]?.children[0]?.children[1]?.children)??[])].map(row => parseInt(row.id.slice(4)))

// export const rows = document.getElementById(tableId)?.children[0]?.children[0]?.children[0]?.children[0]?.children[1].children].map(row => row?.children[0]?.children[0]).map(checkbox=>checkbox.click())