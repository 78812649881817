import { useEffect, useState, useRef, useCallback, useMemo } from 'react'

// A static state that can be shared across all ModalController instances
class ModalControllerState {
    static numberOfDisplayingModals = 0
}

/** 
 * @name ModalController
 * 
 * A controller that will set up click handlers, key listeners,
 * set up a backdrop, and display the content in a modal.
 * 
 * 
 * @param {ref} contentRef          The reference to the div to be displayed in the modal
 * @param {ref} openRef             The reference to the button which will open the modal
 * @param {ref} closeRef            The reference to the button which will close the modal
 * @param {string} modalStyle       What type of modal to display 
 * @param {function} onToggle       Do something when the modal opens or closes
 * @param {boolean} closeOnEscape   Whether pressing the 'escape' key will close the modal or not
 * @param {ref[]} closeRefs
 * @param {ref[]} validationCloseRefs
 * @param {string} id
 * @param {boolean} clickOutsideToClose Whether clicking outside the modal will close the modal or not
 * @example 
 * const openRef = useRef()
 * const closeRef = useRef()
 * const contentRef = useRef()
 * return (<>
 * 
 *     <button ref={openRef}>Click me to open the modal</button>
 * 
 *     <div ref={contentRef} style={{display: "none"}}>
 *          Hello World
 *          <button ref={closeRef}>Click me to close the modal</button>
 *     </div>
 * 
 *     <ModalController 
 *          contentRef={contentRef}
 *          openRef={openRef}
 *          closeRef={closeRef}
 *          onToggle={info => console.log(info)}
 *          modalStyle={'fullscreen'}>
 *          closeOnEscape={true}
 *      </ModalController>
 * </>)
 */
export const ModalController = (props) => {
    const [layer, setLayer] = useState(0)
    const [isOpen, setIsOpen] = useState(false)
    const backgroundRef = useRef()

    const { contentRef, openRef, closeRefs, closeRef, onToggle, validationCloseRefs, id, openRefs } = props

    const modalStyle = props.modalStyle ?? "fullheight"
    const closeOnEscape = props.closeOnEscape ?? false
    // const closeRefs = props.closeRefs ?? []
    const clickOutsideToClose = props.clickOutsideToClose ?? true

    const alwaysOpenCallback = useCallback(() => true, [])
    const propsModalShouldOpenCallback = useCallback(() => props.modalShouldOpen(), [props])
    const modalShouldOpenCallback = props.modalShouldOpen ? propsModalShouldOpenCallback : alwaysOpenCallback

    const contentZIndex = (2*layer) + 100

    const modalStyles = useMemo(() => {
        return {
            fullscreen: {
                inset: "15px 11.5px 11.5px 15px",
                width: "", // Override width
                height: "" // Override height
            },
            fullheight: {
                top: `${15 * layer}px`,
                bottom: `${11.5 * layer}px`,
                width: "fit-content",
                height: "", // Override height
            },
            large: {
                top: `${15 * layer}px`, // Prevent hitting top of screen
                bottom: `${11.5 * layer}px`, // Prevent hitting bottom of screen
                width: "fit-content",
                height: "", // Override height
                maxHeight: "825px"
            },
            small: {
                top: `${15 * layer}px`, // Prevent hitting top of screen
                bottom: `${11.5 * layer}px`, // Prevent hitting bottom of screen
                width: "566px",
                height: "", // Override height
                maxHeight: "510px"
            },
            none: {
                display: 'none'
            }
        }
    }, [layer])

    const backgroundStyles = useMemo(() => {
        return {
        background: "#808080",
        opacity: 0.8,
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: contentZIndex - 1,
    }}, [contentZIndex])

    const contentStyles = useMemo(() => {
        return {
            position: "fixed",
            display: "block",
            background: "#FFF",
            overflowY: "auto",
            overscrollBehavior: "contain",
            zIndex: contentZIndex,
            borderRadius: "6px"
        }
    }, [contentZIndex])

    const close = useCallback(() => {
        setIsOpen(false)
        ModalControllerState.numberOfDisplayingModals -= 1
        contentRef.current.style.display = "none"

        if (onToggle) {
            onToggle({isOpen: false})
        }
    }, [contentRef, onToggle])

    const open = useCallback(() => {
        if (modalShouldOpenCallback()) {
            ModalControllerState.numberOfDisplayingModals += 1
            const currentLayer = ModalControllerState.numberOfDisplayingModals
            setLayer(currentLayer)
            setIsOpen(true)
    
            if (onToggle) {
                onToggle({ isOpen: true, close })
            }
        }
       
    }, [onToggle, modalShouldOpenCallback, close])

    const onKeyDown = useCallback((event) => {
        if (layer !== ModalControllerState.numberOfDisplayingModals) { return }

		if (event.keyCode === 27 && closeOnEscape) {
			close()
		}
    }, [close, closeOnEscape, layer])
        

    // Setup event listeners only if the refs change, or the open and close functions change 
    useEffect(() => {
        if (openRef?.current) {
            openRef.current.onclick = open
        }

        if (openRefs?.length > 0) {
            console.log("ModalController: Set 'open' on openRefs onclick")
            openRefs.forEach(openRef => {
                if (openRef?.current) {
                    openRef.current.onclick = open
                }
            })
        }

        // Supports an older version of ModalController where 'closeRefs' wasn't a thing.
        if (closeRef?.current) {
            closeRef.current.onclick = close
        }


        if (closeRefs?.length > 0) {
            closeRefs.forEach(closeRef => {
                if (closeRef?.current) {
                    closeRef.current.onclick = close
                }
            })
        }

        if (validationCloseRefs?.length > 0) {
            validationCloseRefs.forEach(validationCloseRef => {
                validationCloseRef.ref.current.onclick = () => {
                    if (validationCloseRef.validate()) {
                        close()
                    }
                }
            })
        }
    }, [close, closeRefs, closeRef, open, openRef, validationCloseRefs, openRefs])
    
    // Setup and teardown key listeners only on open and close
    useEffect(() => {
        if (isOpen) {
            document.addEventListener("keydown", onKeyDown)

            return () => {
                document.removeEventListener("keydown", onKeyDown)
            }
        }
    }, [isOpen, onKeyDown])

    // Update the styles whenever the ModalController re-renders
    useEffect(() => {
        if (!(backgroundRef.current && contentRef.current)) { return }

        // ModalContent
        Object.assign(contentStyles, modalStyles[modalStyle])
        Object.assign(contentRef.current.style, contentStyles)

        // Background
        Object.assign(backgroundRef.current.style, backgroundStyles)

        if (modalStyle === "fullheight") {
            contentRef.current.style.left = 
                `${(backgroundRef.current.clientWidth - contentRef.current.clientWidth) / 2}px`
        } else if (modalStyle !== "fullscreen" && modalStyle !== "fullheight") {
            contentRef.current.style.left = 
                `${(backgroundRef.current.clientWidth - contentRef.current.clientWidth) / 2}px`

            contentRef.current.style.top = 
            `${(backgroundRef.current.clientHeight - contentRef.current.clientHeight) / 2}px`
        }
    })

    return (
        isOpen 
            ? <div 
                ref={backgroundRef} 
                styles={backgroundStyles} 
                onClick={ clickOutsideToClose ? close : () => {}} /> 
            : null
    )
}

