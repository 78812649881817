export type KeyboardShortcut = {
	id: string
	action: string
	deletable: boolean
	section: string
	tab: string
	triggers: [string | null, string | null]
	data?: any
}

export const hotkeyActions = {
	// Any Mode
	ENTER_ANNOTATE_MODE: "enter annotate_mode", // Navigate to the Annotate tab
	ENTER_CONFIGURE_MODE: "enter configure mode", // Navigate to the Configure tab

	// Annotate Mode
	CANCEL_ANNOTATION: "cancel annotation", // Clear the selection and marker for the current long annotation
	NEXT_PAGE: "next page",
	PREVIOUS_PAGE: "previous page",
	UPDATE_CUSTOM_ANNOTATION_MARKERS: "update custom annotation markers",
	ZOOM_OUT: "zoom out",
	ZOOM_IN: "zoom in",
	PLAY_PAUSE: "play pause",
}

export const hotkeyTabs = {
	GENERAL: "General",
	ANNOTATIONS: "Annotations",
}

// Sections in order of appearance on the page
export const hotkeySections = {
	NAVIGATION: "Navigation",
	BASIC: "Basic",
	TOOLS: "Tools",
	DATA_REPORTING: "Data Reporting",
	CUSTOM_ANNOTATIONS: "Custom Annotations",
}

export const triggerTypes = {
	PRIMARY: "Primary",
	SECONDARY: "Secondary",
}
