import { atomFamily } from "recoil"
import { ModalityGraphGroupConfigJSON, ModalityGraphGroupConfig } from "../Types/ModalityGraphGroup"
import { LayoutWindowId } from "../Types/LayoutWindowId"
import { RenderStrategy } from "../Types/Trace"
import { getDefaultAnnotationVisualizationAtom } from "./Visualizations"

export const DEFAULT_TIME_SERIES_GROUP_JSON: ModalityGraphGroupConfigJSON = {
	viewDuration: 60 * 1000,
	graphs: [{
        min: 0,
        max: 100,
        name: "Default graph",
        traces: [],
        renderStrategy: RenderStrategy.LINE
    }],
	playbackSpeed: 1,
}

export const modalityGraphGroupConfigAtom = atomFamily<ModalityGraphGroupConfig, LayoutWindowId>({
    key: "modalityGraphGroups",
    default: () => ({
        ...getDefaultAnnotationVisualizationAtom(),
        graphs: [],
        hideEmptyGraphs: false,
        patientModalities: [],
    })
})
