import { useState } from "react"
import DropdownInput from "./DropdownInput"
import NumberInput from "./NumberInput"

function getAbbreviation(name) {
    switch(name) {
        case "hours": return "hrs"
        case "seconds": return "s"
        case "minutes": return "min"
        case "milliseconds": return "ms"
        case "microseconds": return "us"
        default: return name
    }
}

export default function DurationInput({ nodeId, config, initialValue, onChange }) {
    const initNumberValue = Object.values(initialValue)[0] 
        ?? Object.values(config.DEFAULT_VALUE)[0] 
        ?? 0

    let initDropdownValue = Object.keys(initialValue)[0] 
        ?? Object.keys(config.DEFAULT_VALUE)[0] 
        ?? "seconds"

    initDropdownValue = getAbbreviation(initDropdownValue)

    const [numberInputValue, setNumberInputValue] = useState(initNumberValue)
    const [dropdownInputValue, setDropdownInputValue] = useState(initDropdownValue)

    const dropdownInputConfig = {
        ...config,
        DISPLAY_NAME: "",
        DEFAULT_VALUE: "seconds",
        OPTIONS: ["hours", "minutes", "seconds"]
    }

    const onNumberInputChange = ({nodeId, arg, value}) => {
        setNumberInputValue(value)
        onChange({nodeId, arg, value: {[dropdownInputValue]: value}})
    }

    const onDropdownInputChange = ({nodeId, arg, value}) => {
        setDropdownInputValue(value)
        onChange({nodeId, arg, value: {[value]: numberInputValue}})
    }

    return (
        <div style={{display: "flex"}}>
            <NumberInput
                nodeId={nodeId}
                config={config}
                initialValue={numberInputValue}
                onChange={onNumberInputChange}>
            </NumberInput>

            <DropdownInput 
                nodeId={nodeId} 
                config={dropdownInputConfig}
                initialValue={dropdownInputValue}
                onChange={onDropdownInputChange}>
            </DropdownInput>
        </div>
    )
}
