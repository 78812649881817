import VisualizationManager from '../../VisualizationManager';


// checks if two intervals intersect
export const intersects = (x1, y1, x2, y2) => (y1 >= x2) && (y2 >= x1)


export const array_intersection = (arr1, arr2) => arr1.filter(x => arr2.includes(x));


// 'clips off' the ends of (start, end) that fall outside (clip_start, clip_end)
export function clip_interval (start, end, clip_start, clip_end) {
    if (start >= clip_end) return [clip_end, clip_end]
    if (end < clip_start) return [clip_start, clip_start]
    const start_ret = start <= clip_start ? clip_start : start;
    const end_ret = end >= clip_end ? clip_end : end;

    return [start_ret, end_ret]
}


export function windowXtoTimestamp(x) {
    return (VisualizationManager.graph_time())*(x)/ VisualizationManager.width + VisualizationManager.graph_start()
}


export function timestampToWindowX(timestamp) {
    return (timestamp - VisualizationManager.graph_start())*VisualizationManager.width /(VisualizationManager.graph_time())
}


export function timestampToTimelineX(t) {
    return ((t - VisualizationManager.file_start) / VisualizationManager.file_time()) * VisualizationManager.width
}


export function add(arr, item) {
    if (!arr.includes(item)) arr.push(item)
}

export function merge (target, source) {
    source.forEach(item => add(target, item));
}


export function remove(arr, item) {
    return arr.filter(i => i !== item)
}


export const hexToRGB = (hex, alpha) => {
    var r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
        return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
        return "rgb(" + r + ", " + g + ", " + b + ")";
    }
}

export function updateStateObj(state, setState, key, value) {
    const newState = {...state, [key]: value}
    setState(newState)
    return newState
}

export function arraysEqual(a, b) {
	if (!Array.isArray(a) || !Array.isArray(b)) return false
	return (a.every(item => b.includes(item)) && b.every(item => a.includes(item)))
}

export function continuousArrToTimestamps(continuous_array) {
    const segments = continuous_array.map((segment) => {
        const start_timestamp = segment[2] / 1000
        const end_timestamp = segment[3] / 1000
        const time = end_timestamp - start_timestamp
        const num_pts = segment[1] - segment[0]
        const timestamp_arr = []
        for (let i = 0; i < num_pts; i++) 
            timestamp_arr.push(start_timestamp + (time * i) / (num_pts))
        return timestamp_arr
    })
    const out = []
    for (const seg of segments){
        out.push(...seg)
        out.push(null)
    }
        
    return out
}

const BST = require('red-black-bst');
export function pageDataToBST (pageData) {
    const bst = new BST()
    for (let i = 0; i < pageData.length; i++) {
        if (pageData[i]) bst.put(pageData[i].timestamp, i)
    }
    return bst
}

export const bool_obj_to_arr = obj => Object.keys(obj).filter(k => obj[k])

export function splitModalities (modalities) {
    const EEG = Object.keys(modalities).filter(modality => modality.includes('EEG') && modalities[modality] === 'SampleSeries' && modality !== 'EEG_Composite')
    const Numerics = (Object.keys(modalities).filter(modality => modalities[modality] === 'Numeric'))
    const Waveform = (Object.keys(modalities).filter(modality => (modalities[modality] !== 'Numeric') && (!modality.includes('EEG'))))
    return [EEG, Numerics, Waveform]
}

export function splitMedications (medications) {
    const infusion = Object.keys(medications).filter(medication => medications[medication] === 'Infusion')
    const bolus = Object.keys(medications).filter(medication => medications[medication] === 'Bolus')
    return [infusion, bolus]
}

export function splitHotkeys (hotkeys) {
    const actionHotkeys = hotkeys.filter(hotkey => hotkey['type'] === 'Action')
    const annotationHotkeys = hotkeys.filter(hotkey => hotkey['type'] === 'Annotation')
    return [actionHotkeys, annotationHotkeys]
}


// lcg
function* idGeneratorFunction(seed) {
    const mod = 2147483648
    const multiplier = 1103515245
    const increment = 12345
    
    let x = seed
    while (true) {
        x = (x*multiplier + increment) % mod
        yield x
    }
}

/**
 * Simple function that generates ids
 */
export class idGenerator {
    constructor () {
        this.generator = idGeneratorFunction(Math.floor(Math.random()*100))
    }
    newID () {
        return this.generator.next().value.toString()
    }
}

function escapeHTML(unsafe)
{
    return unsafe
         .replace(/&/g, "&amp;")
         .replace(/</g, "&lt;")
         .replace(/>/g, "&gt;")
         .replace(/"/g, "&quot;")
         .replace(/'/g, "&#039;");
 }


export function createHighlightJSPage (code, language) {
    const escaped = escapeHTML(code)

    const out = `<!DOCTYPE html>
    <html>
    <style>
        pre {
            background: #eee;
            padding-bottom: 2em;
        }
    </style>
        <body>
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/highlight.js/11.5.0/styles/default.min.css">
            <script src="https://cdnjs.cloudflare.com/ajax/libs/highlight.js/11.5.0/highlight.min.js"></script>
            <script>hljs.initHighlightingOnLoad();</script>
            <div class="container">
                <pre>
                    <code class="${language}">
                        ${escaped}
                    </code>
                </pre>
            </div>
        </body> 
    </html>
    `
    return out
}


export function px (n) { return `${n}px` }

export function openAsPageInNewTab(pageContent) {
    let encoded = encodeURIComponent(pageContent); 
    let a = document.createElement(`a`);
    a.target = `_blank`;
    a.href = `data:text/html;charset=utf-8,${encoded}`;
    a.style.display = `none`;
    document.body.appendChild(a); // We need to do this,
    a.click();                    // so that we can do this,
    document.body.removeChild(a); // after which we do this.
}

export function invertedObject (obj){
    const ret = {};
    for(var key in obj){
      ret[obj[key]] = key;
    }
    return ret;
}

export function objectToFunction (obj) {
    const mapping = {...obj}
    return x => mapping[x]
}

export function identity (x) {return x}


export function useComputedState (state, setState, func, inv) {
    return [func(state), (val) => setState(inv(val))]
}

export function moveItemUp (item, array) {
    const indx = array.indexOf(item)
    if (indx <= 0) return array

    const next_array = [...array]
    next_array[indx-1] = item
    next_array[indx] = array[indx-1]

    return next_array
}

export function moveItemDown (item, array) {
    const indx = array.indexOf(item)
    if (indx >= array.length-1) return array

    const next_array = [...array]
    next_array[indx+1] = item
    next_array[indx] = array[indx+1]

    return next_array
}


/**
 * @name reverseIterator
 * @function
 * @category Visualization
 * @subcategory Repository
 * 
 * @description Takes in an array and returns an iterator that traverses the array in reverse. Saves computation by avoiding explicitly constructing a new reversed array
 * @param {Array} array 
 * @returns {Symbol.iterator}
 */
export function reverseIterator(array) {
    return {
        *[Symbol.iterator](){
             for (let i = array.length-1; i >= 0; i--) 
                 yield array[i]
        }
    }
}


export function parse_translate(translate) {
    return translate.match(/\((.*?)\)/)[0].slice(1, -1).split(',').map(parseFloat)
}

export function translate(x, y) {
    return `translate(x, y)`
}