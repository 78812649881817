import { scaleBand, scaleLinear } from "d3";
import { TimeSeriesPageManager } from "../../../../Data/TimeSeriesPageManager";
import { CPPOptPlotConfig } from "../../../../Types/CPPOptPlot";
import { MarginedBoundingBox } from "../../../../Types/MarginedBoundingBox";
import { Offset } from "../../../../Types/Offset";
import { CPPOptPlotReactCallbacks } from "../../../../Types/ReactCallbacks";
import { LEFT_MARGIN, RIGHT_MARGIN, TOP_MARGIN } from "../../Constants";
import { D3TimeBasedVisualization } from "../../D3TimeBasedVisualization";
import { D3CPPOptPlotConfigurationBuilder } from "./D3CPPOptPlotConfigurationBuilder";
import { D3CPPOptPlotRenderer } from "./D3CPPOptPlotRenderer";
import { range } from "lodash";
import { ModalityDataSource } from "../../../../Types/ModalityDataSource";
import { Dimensions } from "../../../../../../../../Providers/DimensionsProvider";
import { ModalityPage } from "../../../../Data/ModalityPage";
import { DataSource } from "../../../../Types/DataSource";
import { TraceDataConfig } from "../../../../Types/Trace";

export class D3CPPOptPlot extends D3TimeBasedVisualization<CPPOptPlotConfig, CPPOptPlotReactCallbacks, D3CPPOptPlotRenderer, TimeSeriesPageManager<ModalityPage>> {
    public boundingBox: MarginedBoundingBox
    public xScaleBand = scaleBand()
    public xScaleLinear = scaleLinear()
    public yScale = scaleLinear().domain([-1, 1])

    private binMinimum = 42.5
    private binMaximum = 117.5
    private binSize = 5
    private bins: number[] = []

    private margins: Offset = { top: TOP_MARGIN, left: LEFT_MARGIN, bottom: 75, right: RIGHT_MARGIN }

    constructor(root: HTMLDivElement, config: CPPOptPlotConfig, pageManager: TimeSeriesPageManager<any>, reactCallbacks: CPPOptPlotReactCallbacks) {
        super(root, config, pageManager, reactCallbacks)
        this.bins = range(this.binMinimum - this.binSize/2, this.binMaximum + 3*this.binSize/2, this.binSize)
        this.boundingBox = new MarginedBoundingBox(this.getGraphDimensions(config.dimensions), this.margins)
        this.mount(new D3CPPOptPlotRenderer(this, new D3CPPOptPlotConfigurationBuilder(this), "d3-cppopt-plot"))
    }

    public getVisibleTraces(): TraceDataConfig[] {
        return []
    }

    protected getModalityDataSources(): ModalityDataSource[] {
        return [{
            modality: "CPPOpt,Composite,SampleSeries",
            dataObjectId: this.reactCallbacks.dataSourceMap.get(DataSource.CPPOPT_ANALYSIS) as number
        }]
    }

    public onYAxisDragStart = () => {
        this.renderer?.onYAxisDragStart()
    }

    public onYAxisDrag = () => {
		this.renderer?.onYAxisDrag()
	}

    public onYAxisDragEnd = () => {
        this.renderer?.onYAxisDragEnd()
    }

    public autoScale = () => {
        this.yScale.domain([-1, 1])
        this.renderer?.render()
    }
    
    public getXScaleLabels() {
        return this.bins.slice(0, -1).map((bin, index) => `${bin}-${this.bins[index + 1]}`)
    }
    
    protected renderPage(page: ModalityPage): void {
        this.renderer?.renderPage(page)
    }

    protected updateDerivedState(): void {
        const graphBoundingBoxDimensions = this.getGraphDimensions(this.config.dimensions)
        this.boundingBox.setDimensions(graphBoundingBoxDimensions)

        // The view scale only controls the number of points that we render. 
        // So, setting a minimum of 1440 points here, which is full resolution for a modality sampled once per minute at a page duration of 1 day. 
        this.config.viewScale.range([0, Math.max(this.boundingBox.width, 1440)])

        this.yScale.range([this.boundingBox.height, 0])

        this.xScaleBand
            .domain(this.getXScaleLabels())
            .range([0, this.boundingBox.width])

        const firstBin = this.bins[0]
        const lastBin = this.bins[this.bins.length - 1]

        this.xScaleLinear
            .domain([firstBin, lastBin])
            .range([0, this.boundingBox.width])

        this.renderer?.updateChildren()
    }

    public getGraphDimensions(dimensions: Dimensions) {
        const timelineHeight = 90
        const tableHeight = 160

        return { width: dimensions.width, height: (dimensions.height - timelineHeight - tableHeight) }
    }

}