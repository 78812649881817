import React, { useContext, useRef, } from "react"
import { DimensionsContext, DimensionsProvider } from "../../../../../../../../Providers/DimensionsProvider"
import { D3EEGMontage } from "../D3/D3EEGMontage"
 import { useRecoilState, useRecoilValue } from "recoil"
import { currentPatientFileInfoAtom } from "../../../../Atoms/PatientFile"
import { eegMontageConfigAtom } from "../../../../Atoms/EEGMontage"
import { annotationsAtom } from "../../../../Atoms/Annotations"
import { Annotation } from "../../../../../../../../Managers/VisualizationManager/Variables/Annotations"
import { useInProgressAnnotation } from "../../TimeSeriesGraphGroup/React/useInProgressAnnotation"
import { EEGWindowSettings } from "../../../../../../../../Managers/VisualizationManager/WindowSettings/EEGWindowSettings"
import { EEGMontageConfig, EEGMontageConfigJSON } from "../../../../Types/EEGMontageConfig"
import { selectedLayoutIdAtom } from "../../../../Atoms/Layout"
import { useD3KeyboardShortcuts } from "../../../../Hooks/useD3KeyboardShortcuts"
import { hotkeyActions } from "../../../../Types/KeyboardShortcut"
import { useD3CheckReloadData } from "../../../../Hooks/useD3CheckReloadData"
import { EEGTimeSeriesPageManager } from "../../../../Data/EEGTimeSeriesPageManager"
import { pageManagerRegistry } from "../../../../Data/PageManagerRegistry"
import { viewScaleRegistry } from "../../../../Data/ViewScaleRegistry"
import { useD3UpdateVisibleTraces } from "../../../../Hooks/useD3UpdateVisibleTraces"
import { VisualizationComponent } from "../../../../VisualizationComponentFactory"
import { fileScaleRegistry } from "../../../../Data/FileScaleRegistry"
import { useValidateEEGMontage } from "../../../../Hooks/useValidateEEGMontage"
import { useLayoutService } from "../../../../../../../../Hooks/useLayoutService"
import { useD3Controller } from "../../../../Hooks/useD3Controller"
import { useSocketProvider } from "../../../../../../../../Providers/SocketProvider"

type EEGMontageProps = {
	json: EEGMontageConfigJSON
	area: string
}

export function EEGMontageVisualization(props: EEGMontageProps) {

	return (
		<div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
			<div style={{ flex: 0 }}>
				<EEGWindowSettings visualizationArea={props.area} />
			</div>
			<div style={{ flex: 1 }}>
				<DimensionsProvider>
					<EEGMontageD3Wrapper {...props} />
				</DimensionsProvider>
			</div>
		</div>
	)
}

function EEGMontageD3Wrapper(props: EEGMontageProps) {
	const dimensions = useContext(DimensionsContext)
	const { inProgressAnnotation } = useInProgressAnnotation(props.area)
    const domNode = useRef<HTMLDivElement>(null)
    const { fileStartDate, fileEndDate, montageMapping } = useRecoilValue(currentPatientFileInfoAtom)
	const { getDataQuerySocket } = useSocketProvider()

	const annotations = useRecoilValue<Annotation[]>(annotationsAtom)
	const layoutId = useRecoilValue(selectedLayoutIdAtom)
	const visualizationId = { layoutId: layoutId as string, windowId: props.area }
	const atom = eegMontageConfigAtom(visualizationId)

	const { modifyDatabaseWindowProperty } = useLayoutService()

	const [config, setRootConfig] = useRecoilState(atom)

	const viewScale = viewScaleRegistry.get(visualizationId, VisualizationComponent.EEG_MONTAGE, { fileStartDate, fileEndDate, viewDuration: props.json.viewDuration })
	const [viewStart, viewEnd] = viewScale.domain()
	const viewDuration = viewEnd.getTime() - viewStart.getTime()

	const d3Montage = useD3Controller<D3EEGMontage, EEGMontageConfig, EEGMontageConfigJSON, EEGTimeSeriesPageManager>({
		atom: atom,
		nodeRef: domNode,
		pageManager: pageManagerRegistry.get(visualizationId, VisualizationComponent.EEG_MONTAGE, new EEGTimeSeriesPageManager(), getDataQuerySocket),
		initProps: props.json,
		props: {
			id: props.area,
			viewScale,
			fileScale: fileScaleRegistry.get(visualizationId, VisualizationComponent.EEG_MONTAGE, fileStartDate, fileEndDate),
			annotations,
			inProgressAnnotation,
			montageMapping
		},
		d3ControllerConstructor(node, config, pageManager, callbacks) {
			return new D3EEGMontage(node, config, pageManager, callbacks)
		}
	})

	useD3CheckReloadData({
		d3Controller: d3Montage,
		clearDataIfChanges: {
			viewDuration,
			LFF: config.LFF,
			HFF: config.HFF,
			channels: config.montage?.channels.map(({ first, second }) => `${first}-${second}`),
			notch: config.notch,
			dimensions
		},
		clearRenderCacheIfChanges: {
			sensitivityMicroVolts: config.sensitivityMicroVolts,
		}
	})

	useD3KeyboardShortcuts({
		d3Controller: d3Montage,
		windowId: props.area,
		shortcuts: [
			hotkeyActions.NEXT_PAGE,
			hotkeyActions.PREVIOUS_PAGE,
			hotkeyActions.UPDATE_CUSTOM_ANNOTATION_MARKERS,
			hotkeyActions.CANCEL_ANNOTATION,
			hotkeyActions.PLAY_PAUSE
		]
	})

	useD3UpdateVisibleTraces({
		d3Controller: d3Montage,
		windowId: props.area
	})

    useValidateEEGMontage({
        montage: config.montage,
        updateMontage: montage => {
			if (config.hasInitialized) {
				// Update the window state
				setRootConfig(previous => ({ ...previous, montage }))

				// Update the layout
				modifyDatabaseWindowProperty("montage", visualizationId.layoutId, visualizationId.windowId, montage)
			}
		}
    })

	return <div ref={domNode} style={{ height: "100%" }} />
}

