import React from 'react'
import { MobergButton, MobergButtonShape, MobergButtonVariant } from '../MobergButton/MobergButton'
import { MdOutlineError } from "react-icons/md"
import { MobergTheme } from '../MobergThemes/MobergColors'
import { useModalProvider } from '../../Providers/ModalProvider'
import { MobergFontSize, MobergFontFamily } from '../MobergFont/MobergFont'

function ErrorPopupModal({ title='', errorMessage='', onClose = () => { } }) {
    const { close } = useModalProvider()

    return (
        <div style={{ textAlign: "center", padding: "20px", width: "450px" }}>
            <MdOutlineError size={81} style={{ color: "#AEB7C6", marginBottom: "5px" }} />

            <h1 style={{ fontFamily: "Montserrat", fontStyle: "normal", fontWeight: "700", fontSize: "24px", lineHeight: "110%", color: "#000000" }}> {title} </h1>

            <p style={{ textAlign: "center", padding: "5px 15px" }}> {errorMessage} </p>

            <div style={{ display: "flex", flexDirection: "column", gap: "14px" }}>
                <MobergButton
                    shape={MobergButtonShape.FULL_WIDTH}
                    fontSize={MobergFontSize.LARGE}
                    theme={MobergTheme.BLUE}
                    variant={MobergButtonVariant.FILLED}
                    onClick={() => { onClose(); close() }}
                    style={{ height: "55px", fontFamily: MobergFontFamily.HEADER }}>
                    Close
                </MobergButton>
            </div>
        </div>
    )
}

export default ErrorPopupModal