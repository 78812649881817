import React from "react"
import { useSetRecoilState, useRecoilValue, useRecoilState } from "recoil"
import { CPPOPT_WINDOW_TIME_PRESETS } from "../../../../../../../../Managers/VisualizationManager/Viewport/Components/XAxis"
import { useModalProvider } from "../../../../../../../../Providers/ModalProvider"
import { uneditedLayoutGroupAtom, editedLayoutGroupAtom, selectedLayoutGroupAtom, selectedLayoutAtom } from "../../../../Atoms/Layout"
import { VisualizationToolbar } from "../../../React/VisualizationToolbar"
import { cppOptPlotConfigsAtom } from "../../../../Atoms/CPPOpt"
import { CPPOptPlotConfigureModal } from "./CPPOptPlotConfigurationModal"

type CPPOptToolbarProps = {
	windowId: string
}

export const CPPOptToolbar = (props: CPPOptToolbarProps) => {
    const { createModal } = useModalProvider()
	const setUneditedLayoutGroup = useSetRecoilState(uneditedLayoutGroupAtom)
	const setEditedLayoutGroup = useSetRecoilState(editedLayoutGroupAtom)
	const selectedLayoutGroup = useRecoilValue(selectedLayoutGroupAtom)
	const selectedLayout = useRecoilValue(selectedLayoutAtom)

	const [cppoptPlotConfig, setCPPOptPlotConfig] = useRecoilState(cppOptPlotConfigsAtom({ layoutId: selectedLayout?.id as string, windowId: props.windowId }))
	const [viewStart, viewEnd] = cppoptPlotConfig.viewScale.domain()
	const viewDuration = viewEnd.getTime() - viewStart.getTime()

	function renderConfigureModal() {
		if (!selectedLayout) {
			console.error("Couldn't render the modal because a layout was not selected")
			return
		}

		setUneditedLayoutGroup(selectedLayoutGroup)
		setEditedLayoutGroup(selectedLayoutGroup)
		createModal(<CPPOptPlotConfigureModal windowId={props.windowId} layoutId={selectedLayout.id} />)
	}

	return <VisualizationToolbar
		atomValue={cppoptPlotConfig}
		setAtom={setCPPOptPlotConfig}
		renderConfigureModal={renderConfigureModal}
		viewDuration={viewDuration}
		timePresetOptions={CPPOPT_WINDOW_TIME_PRESETS}
	/>
}
