import { ScaleTime, ScaleLinear, ScaleBand } from "d3"
import { DataSource } from "./DataSource"
import { ColorSpectrum } from "./ColorSpectrum"
import { OnDemandAnalysis } from "./AnalysisDetails"

export type BaseTraceConfigJSON = {
	id: string
	dataKey: string
	dataSource: DataSource
	name: string
	units: string
	onDemandAnalysis?: OnDemandAnalysis
}

// Some traces are part of composite data types. EEG, CPPOPT, etc.
// So, we need to provide the index of the composite data that they belong to.
export type CompositePartTraceConfigJSON = BaseTraceConfigJSON & {
	isCompositePart: true
	compositeIndex: number
}

// If the trace is not a composite part, then it does not have a composite index.
export type NonCompositePartTraceConfig = BaseTraceConfigJSON & {
	isCompositePart: false
}

export type TraceDataConfig = (CompositePartTraceConfigJSON | NonCompositePartTraceConfig)

export type SolidColorTraceDataConfig = TraceDataConfig & WithSolidColor

export type TraceConfigJSON = LineTraceConfigJSON | HeatmapTraceConfigJSON

export type TraceConfig = LineTraceConfig | HeatmapTraceConfig

type BaseTraceConfig = {
	graphId: string
	xScale: ScaleTime<any, any, any>
}

export type LineTraceConfig = LineTraceConfigJSON & BaseTraceConfig & {
	yScale: ScaleLinear<any, any, any>
}

export type HeatmapTraceConfig = HeatmapTraceConfigJSON & BaseTraceConfig & {
	yScale: ScaleBand<any>
}

export type PageRectangle = {
	x: number
	y: number
	width: number
	height: number
}

export enum RenderStrategy {
	LINE = "line",
	HEATMAP = "heatmap",
	IMAGE = "image",
	FILL = "fill"
}

export type TraceOption = {
    label: string
    value: TraceConfigJSON
    highlight: boolean
}

export type LineTraceConfigJSON = TraceDataConfig & WithSolidColor & {
	renderStrategy: RenderStrategy.LINE
	color: string
}

export type HeatmapTraceConfigJSON = TraceDataConfig & WithColorSpectrum & {
	renderStrategy: RenderStrategy.HEATMAP
	lowerBound: number
	upperBound: number
}

export const getHeatmapTraceId = (config: HeatmapTraceConfigJSON) => (
	`${config.dataKey}-${config.dataSource}-${config.colorSpectrum}-${config.lowerBound}-${config.upperBound}`
)

export type WithSolidColor = { color: string }

export type WithColorSpectrum = { colorSpectrum: ColorSpectrum }
