import styled, { css } from "styled-components";

const BLUE = "#207DEA"
const RED = "#E64E58"
const WHITE = "#FFFFFF"

export const Columns = styled.div`
    display: flex;
	flex-direction: row;
    height: ${props => props.height ?? 'auto'};
    column-gap: ${props => props.spacing ?? props.gap ?? '0'};
    padding: ${props => props.spacing ?? '0'};
    justify-content: ${props => props.justifyContent ?? 'flex-start'};
    align-items: ${props => props.alignItems ?? 'normal'};

    flex-grow: ${props => props.grow ?? '0'};
    flex-shrink: ${props => props.shrink ?? '1'};
    flex-basis: ${props => props.basis ?? 'auto'};
`

export const Column = styled.div`
    flex-grow: ${props => props.grow ?? '0'};
    flex-shrink: ${props => props.shrink ?? '1'};
    flex-basis: ${props => props.width ?? props.basis ?? 'auto'};
    width: ${props => props.width ?? 'auto'};
`

export const Rows = styled.div`
    display: flex;
	flex-direction: column;
    width: ${props => props.width ?? 'auto'};
    row-gap: ${props => props.spacing ?? props.gap ?? '0'};
    padding: ${props => props.spacing ?? '0'};
    justify-content: ${props => props.justifyContent ?? 'flex-start'};
    align-items: ${props => props.alignItems ?? 'center'}

    flex-grow: ${props => props.grow ?? '0'};
    flex-shrink: ${props => props.shrink ?? '1'};
    flex-basis: ${props => props.basis ?? 'auto'};
`

export const Row = styled.div`
    flex-grow: ${props => props.grow ?? '0'};
    flex-shrink: ${props => props.shrink ?? '1'};
    flex-basis: ${props => props.height ?? props.basis ?? 'auto'};
    height: ${props => props.height ?? 'auto'};
`

const SharedButtonStyle = css`
    height: 34px;
    padding: 0px 10px 0px 10px;
    box-shadow: 0 0 3px 0 rgba(99, 191, 244, 0.15);
 
	border-radius: 6px;
    

	font-family: 'Source Sans Pro';
	font-style: normal;
	font-weight: 600;
	line-height: 150%;
    white-space: nowrap;
`

export const WhiteButton = styled.button`
    ${SharedButtonStyle}
    border: solid 0.5px #B6B6B6;
    background: #FFFFFF;
	color: #293241;
`;

export const BlueButton = styled.button`
    ${SharedButtonStyle}
	background: ${props => props.$inverted ? WHITE : BLUE};    
	color: ${props => props.$inverted ? BLUE : WHITE };
    border: ${props => props.$inverted ? `solid 2px ${BLUE}` : "none" };
    &:hover {
        background: ${props => props.$inverted ? "#207DEA" : "linear-gradient(0deg, rgba(0, 0, 0, 0.30) 0%, rgba(0, 0, 0, 0.30) 100%), #207DEA"};
        color: ${props => props.$inverted ? WHITE : WHITE };
    }
`;

export const RedButton = styled.button`
    ${SharedButtonStyle}
	background: ${props => props.$inverted ? WHITE : RED};    
	color: ${props => props.$inverted ? RED : WHITE };
    border: ${props => props.$inverted ? `solid 2px ${RED}` : "none" };
    &:hover {
        background: ${props => props.$inverted ? "#e64e58" : "linear-gradient(0deg, rgba(0, 0, 0, 0.30) 0%, rgba(0, 0, 0, 0.30) 100%), #e64e58"};
        color: ${props => props.$inverted ? WHITE : WHITE };
    }
`;

export const IconTextButton = styled.button`
    background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	outline: inherit;
    cursor: pointer;
`

const HeaderTextStyle = css`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 150%;
    color: #293241;
`

export const ModalHeader = styled.div`
    position: sticky;
    background: #FFF;
    top: 0;
    zIndex: 1000;
    text-align: center;
    width: 100%;
    padding: 15px;
    border-bottom: 1px solid #B3B3B3;

    ${HeaderTextStyle}
`

export const ModalDiv = styled.div`
    z-index: 10;
    position: fixed;
    width: ${props => props.width ?? '1300px'};
    left: 50%;
    top: ${props => props.top ?? '100px'};
    transform: translate(-50%, 0);
    background: #FFFFFF;
    border-radius: 6px;
    overflow-y: auto;
    overscrol-behavior: contain;
`

export const PopupDiv = styled.div`
    z-index: 999;
    position: absolute;
    padding: 15px;
    width: ${props => props.width ?? '300px'};
    height: ${props => props.height ?? '300px'};
    left: 0px;
    top: 120%;
    background: #FFFFFF;
    border: solid 1px #B3B3B3;
    border-radius: 6px;
`