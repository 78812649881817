import { atomFamily } from "recoil";
import { LayoutWindowId } from "../Types/LayoutWindowId";
import { CPPOptPlotConfig } from "../Types/CPPOptPlot";
import { getDefaultVisualizationAtom } from "./Visualizations";

export const cppOptPlotConfigsAtom = atomFamily<CPPOptPlotConfig, LayoutWindowId>({
    key: "cppoptPlotConfigs",
    default: () => ({
        ...getDefaultVisualizationAtom(),
    })
})
