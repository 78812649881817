import { createContext, useContext, useCallback } from "react";
import { useAuthProvider } from "./AuthProvider";
import { useWorkspacesProvider } from "./WorkspacesProvider";

const EndpointContext = createContext()

// Allow a post request to be made from above the EndpointContext.
// Because EndpointProvider cannot be used, this method cannot be a callback.
// DO NOT USE THIS FUNCTION if the React component already has access to EndpointProvider.
// This is a special exception, intended for use by StudiesProvider and AuthProvider.
export const postWithDependencyInjection = async (authToken, workspaceName, ENDPOINT, body=undefined, errorCodeHandlerMap=new Map()) => {
    if (ENDPOINT.REQUIRES_TOKEN && !authToken) {
        return new Promise((_, reject) => reject('This endpoint requires a token and there is not one present or the user is not logged in.'))
    }    
    
    if (!body) {
        body = {}
    }

    if (!Object.keys(body).includes("workspace_name")) {
        body["workspace_name"] = workspaceName
    }

    // TODO: Remove this once all endpoints are updated to use workspace_name instead of parent_study_id
    const paramString = `?parent_study_id=${workspaceName}`
    const fullFetch = `${ENDPOINT.LINK}${paramString}`

    return fetch(fullFetch, {
        method: "POST",
        cache: "no-cache",
        credentials: "same-origin", // TODO: Remove?
        headers: {
            "content-type": "application/json",
            Authorization: authToken,
        },
        body: JSON.stringify(body)
    }).then(async response => {
        if (!response.ok) {
            if (errorCodeHandlerMap.has(response.status)) {
                const listener = errorCodeHandlerMap.get(response.status)

                let payload, errorMessage

                switch(ENDPOINT.ERROR_RESPONSE_TYPE) {
                    case "json":
                        payload = JSON.parse(await response.text())
                        errorMessage = payload.errorMessage
                        break
                    case "text":
                    default:
                        payload = await response.text()
                        errorMessage = payload
                }

                listener(payload)
                
                return new Promise((_, reject) => reject(errorMessage))
            }

            return new Promise(async (_, reject) => reject(await response.text()))
        }
        
        if (ENDPOINT.OUTPUT_TYPE === 'json') {
            return await response.json()
        } else if (ENDPOINT.OUTPUT_TYPE === 'text') {
            return await response.text()
        } else if (ENDPOINT.OUTPUT_TYPE === 'file') {
            return await response.blob();
        }

        return response
    }).catch(err => {
        return new Promise((_, reject) => reject(err))
    })
}

export const getWithDependencyInjection = async (authToken, workspaceName, ENDPOINT, params = {}) => {
    params["parent_study_id"] = workspaceName // support legacy endpoints

    if (!Object.keys(params).includes("workspace_name")) {
        params["workspace_name"] = workspaceName
    }

    const paramString = `?${Object.entries(params).map(([param, value]) => `${param}=${value}`).join("&")}`
    const fullFetch = `${ENDPOINT.LINK}${paramString}`

    const buildRequest = () => fetch(fullFetch, {
        method: "GET",
        headers: {
            Authorization: authToken
        },
    })

    const handleSuccess = async response => {
        if (!response.ok) {
            return new Promise(async (_, reject) => reject(await response.text()))
        }

        if (ENDPOINT.OUTPUT_TYPE === 'json') {
            return await response.json()
        } else if (ENDPOINT.OUTPUT_TYPE === 'text') {
            return await response.text()
        }

        return response
    }

    return buildRequest().then(handleSuccess)
}

export const EndpointProvider = ({ children }) => {
    console.log("PROVIDER RENDER: ENDPOINT")

    const authProvider = useAuthProvider()
    const workspaceProvider = useWorkspacesProvider()

    const post = useCallback(async (ENDPOINT, body = {}, params) => {
        if (ENDPOINT.REQUIRES_TOKEN && (!authProvider.token || !authProvider.userIsLoggedIn)) {
            return new Promise((_, reject) => reject('This endpoint requires a token and there is not one present or the user is not logged in.'))
        } 
        return postWithDependencyInjection(authProvider.token, workspaceProvider.selectedWorkspace, ENDPOINT, body, params)
    }, [authProvider, workspaceProvider.selectedWorkspace])
 
    const get = useCallback(async (ENDPOINT, params = {}) => {
        if (ENDPOINT.REQUIRES_TOKEN && (!authProvider.token || !authProvider.userIsLoggedIn)) {
            return new Promise((_, reject) => reject('This endpoint requires a token and there is not one present or the user is not logged in.'))
        } 
        return getWithDependencyInjection(authProvider.token, workspaceProvider.selectedWorkspace, ENDPOINT, params)
    }, [authProvider.token, authProvider.userIsLoggedIn, workspaceProvider.selectedWorkspace])

    return (
        <EndpointContext.Provider value={{ post, get }}>
            {children}
        </EndpointContext.Provider>
    )
}

export const useEndpointProvider = () => {
    return useContext(EndpointContext)
}