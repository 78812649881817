import VisualizationManager, { VIZ_MODES } from './VisualizationManager';
import * as d3 from 'd3'
import { GraphType } from "../../Enums/GraphType"
import { EEGMontageChannelSpecificRenderObject } from "./Rendering/Interactable/EEGMontageAnnotations"

import { intersects, timestampToWindowX, hexToRGB, throttle, windowXtoTimestamp} from './Utils/Repository';

function handleAnnotationClick(annotation) {
	return e => {
		e.stopPropagation()
		VisualizationManager?.setMode?.(VIZ_MODES.ANNOTATE)
		VisualizationManager?.setAnnotationModalOpen?.(true)
		VisualizationManager?.setSelectedAnnotation(annotation)
	}
}
class PointAnnotationRenderObj {
	constructor (overlay, annotation) {
		this.annotation = annotation
		this.overlay = overlay

		this.rect = overlay.annotationLayer.append('rect')
			.call(overlay.drag_behavior)
			.attr("pointer-events", "auto")
			.style("opacity", 0)
			.style('cursor', 'pointer')
			.on("click", handleAnnotationClick(this.annotation))
			.on('mouseover', () => {
				this.annotation_line.style('stroke-width', 2)
				this.fo.style('display', 'block')
			})
			.on('mouseleave', () => {
				this.annotation_line.style('stroke-width', 1)
				this.fo.style('display', 'none')
			})
			.on('mousemove', throttle(overlay.updateHoverLine.bind(overlay), 30))

		this.fo = overlay.annotationLayer.append('foreignObject')
			.style('display', 'none')

		this.tooltip = this.fo.append('xhtml:div')
            .style('color', "#5A6679")
			.style('font-family', 'sans-serif')
			.style('font-size', '20px')
			.style('font-weight', 'bold')
			.style('margin-left', '7px')
			.style('margin-right', '15px')
			.style('margin-top', '7px')
			.style('overflow-wrap', 'break-word')
			

		this.annotation_line = overlay.annotationLayer.append("line")
			.style("stroke-width", 1)

		this.update()
	}

	remove() {
		this.rect.remove()
		this.fo.remove()
		this.tooltip.remove()
		this.annotation_line.remove()
	}

	update() {
		const x_axis_height = VisualizationManager.xAxis.height() ?? 0
		const start_pixel = timestampToWindowX(this.annotation.start_time)
		this.rect
			.attr("x", start_pixel-12)
			.attr("y", 20)
			.attr("width", 24)
			.attr("height", this.overlay.height()-x_axis_height/2)

		this.fo 
			.attr("x", start_pixel)
			.attr("y", 20)
			.attr("width", 150)
			.attr("height", 150)
			.style('background', `linear-gradient(${hexToRGB(this.annotation.color, 0.3 + this.annotation.opacity)} 0%, rgba(0, 0, 0, 0) 100%) 100%`)

		this.tooltip.html(`<p>${this.annotation.text}</p>`) 

		this.annotation_line
			.attr("x1", start_pixel)
			.attr("y1", 20)
			.attr("x2", start_pixel)
			.attr("y2", 99999)
			.style("stroke", this.annotation.color)
	}
}

export class ModalitySpecificRenderObj {
	constructor (overlay, annotation) {
		this.annotation = annotation
		this.modalities = [...annotation.modalities].filter(m => VisualizationManager.selectedModalities.includes(m))
		this.overlay = overlay

		this.modality_rects = {}
		this.fos = {}
		this.tooltips = {}

		for (const modality of this.modalities) {
			const fos = this.fos
			const modality_rect = overlay.annotationLayer.append('rect')
				.call(overlay.drag_behavior)
				.attr("class", "annotationrect")
				.attr("pointer-events", "auto")
				.style('cursor', 'pointer')
				.on("click", handleAnnotationClick(this.annotation))
				.on('mouseover', (e) => {
					Object.values(fos).map(fo=>fo.style('display', 'block'))
				})
				.on('mousemove', throttle(overlay.updateHoverLine.bind(overlay), 30))
				.on('mouseleave', (e) => {
					Object.values(fos).map(fo=>fo.style('display', 'none'))
				})

			const fo = overlay.annotationLayer.append('foreignObject')
				.style('display', 'none')

			const tooltip = fo.append('xhtml:div')
				.style('color', "#5A6679")
				.style('font-family', 'sans-serif')
				.style('font-size', '20px')
				.style('font-weight', 'bold')
				.style('margin-left', '15px')
				.style('margin-top', '7px')
				.style('margin-right', '15px')
				.style('overflow-wrap', 'break-word')

			this.modality_rects[modality] = modality_rect
			this.fos[modality] = fo
			this.tooltips[modality] = tooltip
		}
	}

	remove() {
		Object.values(this.modality_rects).map(rect => rect.remove())
		Object.values(this.fos).map(fo => fo.remove())
		Object.values(this.tooltips).map(tooltip => tooltip.remove())
	}

	update() {
		const to_add = this.annotation.modalities.filter(m => !this.modalities.includes(m)).filter(m => VisualizationManager.selectedModalities.includes(m))
		const to_remove = this.modalities.filter(m => !this.annotation.modalities.includes(m)).filter(m => VisualizationManager.selectedModalities.includes(m))

		for (const modality of to_add) {
			const fos = this.fos
			const modality_rect = this.overlay.annotationLayer.append('rect')
				.call(this.overlay.drag_behavior)
				.attr("class", "annotationrect")
				.attr("pointer-events", "auto")
				.style('cursor', 'pointer')
				.on("click", handleAnnotationClick(this.annotation))
				.on('mouseover', (e) => {
					Object.values(fos).map(fo=>fo.style('display', 'block'))
				})
				.on('mousemove', throttle(this.overlay.updateHoverLine.bind(this.overlay), 30))
				.on('mouseleave', (e) => {
					Object.values(fos).map(fo=>fo.style('display', 'none'))
				})

			const fo = this.overlay.annotationLayer.append('foreignObject')
				.style('display', 'none')

			const tooltip = fo.append('xhtml:div')
				.style('color', "#5A6679")
				.style('font-family', 'sans-serif')
				.style('font-size', '20px')
				.style('font-weight', 'bold')
				.style('margin-left', '15px')
				.style('margin-top', '7px')
				.style('margin-right', '15px')
				.style('overflow-wrap', 'break-word')

			this.modality_rects[modality] = modality_rect
			this.fos[modality] = fo
			this.tooltips[modality] = tooltip
		}

		for (const modality of to_remove) {
			delete this.modality_rects[modality]
			delete this.fos[modality]
			delete this.tooltips[modality]
		}

		this.modalities = [...this.annotation.modalities].filter(m => VisualizationManager.selectedModalities.includes(m))
		const start_pixel = timestampToWindowX(this.annotation.start_time)
		const end_pixel = timestampToWindowX(this.annotation.end_time)
		for (const modality of this.modalities) {
			const graphAreas = this.overlay.graphAreasObj()
			const [start_y, end_y] = graphAreas[modality] 
			const graph_height = end_y - start_y

			const modality_rect = this.modality_rects[modality]
			const fo = this.fos[modality]
			const tooltip = this.tooltips[modality]
			modality_rect
				.attr("x", start_pixel)
				.attr("y", start_y)
				.attr("width", end_pixel - start_pixel)
				.attr("height", graph_height)
				.attr("fill", this.annotation.color)
				.style("opacity", this.annotation.opacity)

			fo
				.attr("x", start_pixel)
				.attr("y", start_y)
				.attr("width", end_pixel - start_pixel)
				.attr("height", graph_height)
				.style('border', `2px solid ${this.annotation.color}`)
				.style('background', `linear-gradient(${hexToRGB(this.annotation.color, 0.3)} 0%, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0) 70%, ${hexToRGB(this.annotation.color, 0.3)}) 100%`)
				
			tooltip.html(`<p>${this.annotation.text}</p>`) 

		}
	}
}



class WholeAnnotationRenderObj {
	constructor (overlay, annotation) {
		this.annotation = annotation
		this.overlay = overlay

		this.rect = overlay.annotationLayer.append('rect')
			.call(overlay.drag_behavior)
			.attr("pointer-events", "auto")
			.style('cursor', 'pointer')
			.on("click", handleAnnotationClick(this.annotation))
			.on('mouseover', () => {
				this.fo.style('display', 'block')
			})
			.on('mouseleave', () => {
				this.fo.style('display', 'none')
			})
			.on('mousemove', throttle(overlay.updateHoverLine.bind(overlay), 30))


		this.fo = overlay.annotationLayer.append('foreignObject')
			.style('display', 'none')
			
		
		this.tooltip = this.fo.append('xhtml:div')
            .style('color', "#5A6679")
			.style('font-family', 'sans-serif')
			.style('font-size', '20px')
			.style('font-weight', 'bold')
			.style('margin-left', '15px')
			.style('margin-top', '7px')
			.style('margin-right', '15px')
			.style('overflow-wrap', 'break-word')
	}

	remove() {
		this.rect.remove()
		this.fo.remove()
		this.tooltip.remove()
	}

	update() {
		const x_axis_height = VisualizationManager.xAxis.height() ?? 0
		const start_pixel = timestampToWindowX(this.annotation.start_time)
		const end_pixel = timestampToWindowX(this.annotation.end_time)

		this.rect
			.attr("x", start_pixel)
			.attr("y", x_axis_height/2)
			.attr("width", end_pixel - start_pixel)
			.attr("height", this.overlay.height()-x_axis_height/2)
			.attr("fill", this.annotation.color)
			.style("opacity", this.annotation.opacity)

		this.fo 
			.attr("x", start_pixel)
			.attr("y", x_axis_height/2)
			.attr("width", end_pixel - start_pixel)
			.attr("height", this.overlay.height()-x_axis_height/2)
			.style('border', `2px solid ${this.annotation.color}`)
			.style('background', `linear-gradient(${hexToRGB(this.annotation.color, 0.3)} 0%, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0) 70%, ${hexToRGB(this.annotation.color, 0.3)}) 100%`)

		this.tooltip.html(`<p>${this.annotation.text}</p>`) 
	}
}

class AnnotationRenderObject {
	constructor (overlay, annotation) {
		this.annotation = annotation
		if (annotation.isPointAnnotation()) {
			this.type = 'POINT'
			this.stuff = new PointAnnotationRenderObj(overlay, annotation)
		} else if (annotation.isModalitySpecific()) {
			this.type = 'MODALITY'
			this.stuff = new ModalitySpecificRenderObj(overlay, annotation)
		} else {
			this.type = 'WHOLE'
			this.stuff = new WholeAnnotationRenderObj(overlay, annotation)
		}
	}
	render() {
		this.stuff?.update()
	}
	remove() {
		this.stuff?.remove()
	}
}

export default class Overlay {
    constructor (svg) {

        this.svg = svg

		this.x = 0
		
		this.annotationLayer = this.svg.append('g')

		this.annotationRenderObjects = {}

		this.linesLayer = this.svg.append('g')
		createTopBottomLines.call(this, this.linesLayer)

		this.hoverLine = this.linesLayer.append("line")
			.attr("class", "vertical_line")
			.attr("x1", 0)
			.attr("y1", -99999)
			.attr("x2", 0)
			.attr("y2", 99999)
			.attr("stroke", "black")
			.attr("stroke-width", "0")
			.attr('stroke-dasharray', '7,7')
		
		this.hoverlineVisible = true
		this.hoverlineFrozen = false

		this.selected_timestamp_line = this.linesLayer.append("line")
			.attr("class", "vertical_line")
			.attr("x1", 0)
			.attr("y1", -99999)
			.attr("x2", 0)
			.attr("y2", 99999)
			.attr("stroke", "green")
			.attr("stroke-width", "0")

		this.foreignObj = this.svg.append('foreignObject')
			.attr("x", 0)
			.attr("y", 40)
			.attr("width", 200)
			.attr("height", 200)
			.style('border', '0.5px solid #b6b6b6')
			.style('box-sizing', 'border-box')
			.style('border-radius', '6px')
			.style('background-color', '#FFFFFF')
			.style('display', 'none')
			.style('pointer-events', 'auto')
			.style('font-family', 'Source Sans Pro')
			.style('padding', '10px 10px 10px 10px')

		this.dropdown = this.foreignObj.append('xhtml:div')
			.style('color', 'black')
			.style('font-family', 'sans-serif')
			.style('font-size', '20px')
			.style('font-weight', 'bold')
			.style('overflow-wrap', 'break-word')

		this.drag_behavior = d3.drag()
			.on("drag", (e) => {
				if (e.shiftKey) return
				if (!e.sourceEvent.buttons) {
					e.on("drag", null);
					return   
				}

				const d_ms = e.dx / VisualizationManager.width * VisualizationManager.window_time * 1000
				VisualizationManager.moveGraphTimes(-d_ms)
				this.updateSelectedTimestampLine()
				VisualizationManager.timeline.updateSliderPosition()

				if (VisualizationManager.mode === "annotate_mode") {
					this.updateHoverLine(e)
				}

				if (VisualizationManager.mode === "configure_mode") {
					this.updateHoverLine(e)
				} 
			})
			
		
    }
	updateVisibleAnnotations() {
		const annotations_array = Object.values(VisualizationManager.annotations)
		const visible_new = annotations_array.filter(annotation => intersects(annotation.start_time, annotation.end_time, VisualizationManager.graph_start(), VisualizationManager.graph_end())).map(a => a.id)
		console.log(visible_new)
		const visible_old = Object.keys(this.annotationRenderObjects)

		const to_add = visible_new.filter(a => !visible_old.includes(a))
		const to_remove = visible_old.filter(a => !visible_new.includes(a))

		to_add.forEach(id => this.annotationRenderObjects[id] = new AnnotationRenderObject(this, VisualizationManager.annotations[id]))
		
		to_remove.forEach(id => {
			this.annotationRenderObjects[id].remove()
			delete this.annotationRenderObjects[id]
		})

		const to_remake = []
		for (const annotation_id in this.annotationRenderObjects) {
			const annotation = VisualizationManager.annotations[annotation_id]
			const render_obj = this.annotationRenderObjects[annotation_id]
			if (render_obj.type === 'POINT' && !annotation.isPointAnnotation()) {
				to_remake.push(annotation_id)
			}
			if (render_obj.type === 'MODALITY' && !annotation.isModalitySpecific()) {
				to_remake.push(annotation_id)
			}
			if (render_obj.type === 'WHOLE' && (annotation.isPointAnnotation() || annotation.isModalitySpecific())) {
				to_remake.push(annotation_id)
			}
		}

		to_remake.forEach(id => {
			this.annotationRenderObjects[id].remove()
			delete this.annotationRenderObjects[id]
			this.annotationRenderObjects[id] = new AnnotationRenderObject(this, VisualizationManager.annotations[id])
		})
	}

	renderVisibleAnnotations() {
		for (const annotation_id in this.annotationRenderObjects) {
			this.annotationRenderObjects[annotation_id].render()
		}
	}

	height = () => this.svg.node().getBoundingClientRect().height

    showHoverLine() {
		this.hoverLine.attr("stroke-width", 2)
	}
	hideHoverLine() {
		this.hoverLine.attr("stroke-width", 0)
	}
	updateHoverLine(e) {
		if (this.hoverlineFrozen) return;

		if (!this.hoverlineVisible) {
			this.hideHoverLine()
			return;
		}
		const x = d3.pointer(e)[0]
		VisualizationManager.timeline.renderHoverLine(windowXtoTimestamp(x))
		this.x = x
		if (VisualizationManager.mode === "annotate_mode") {
			this.hoverLine
				.attr("stroke", "red")
				.attr('stroke-dasharray', 'none')
		}
		if (VisualizationManager.mode === "configure_mode") {
			this.hoverLine
				.attr("class", "timestampHoverLine")
				.attr("stroke", "black")
				.attr('stroke-dasharray', '7, 7')
		}
		if (x <= VisualizationManager.width && x >= 0) {
			this.showHoverLine()
			this.hoverLine
				.attr("x1", x)
				.attr("x2", x)
		} else {
			this.hideHoverLine()
		}
	}

	clipHoverLine(y1, y2) {
		this.hoverLine
			.attr("y1", y1)
        	.attr("y2", y2)
	}

	showSelectedTimestampLine() {
		this.selected_timestamp_line.attr("stroke-width", "1")
	}

	hideSelectedTimestampLine() {
		this.selected_timestamp_line.attr("stroke-width", "0")
	}

	updateSelectedTimestampLine() {
		const x = VisualizationManager.selected_timestamp_xpos()
		
		VisualizationManager.timeline.rerenderTimestampLine()
		if (x <= VisualizationManager.width && x >= 0) {
			this.showSelectedTimestampLine()
			this.selected_timestamp_line
				.attr("x1", x)
				.attr("x2", x)
			VisualizationManager.forAllGraphs(graph => graph.updateSelectedTimestampValue(x))
		} else {
			this.hideSelectedTimestampLine()
		}

		if (VisualizationManager?.start_cursor_time)
		{
			const cursor_start_x = timestampToWindowX(VisualizationManager?.start_cursor_time)
			if (cursor_start_x <= VisualizationManager.width && cursor_start_x >= 0) {
				this?.cursor_start_line
					?.attr("x1", cursor_start_x)
					.attr("x2", cursor_start_x)
					.attr("stroke-width", "1")
			} else {
				this?.cursor_start_line
					?.attr("stroke-width", "0")
			}
		}
		

	}
	
	graphAreasArr() {
		let current = 0
		if (VisualizationManager.xAxis) current = VisualizationManager.xAxis.height()
		const arr = []
		for (const linegraph of VisualizationManager.graphsArray()) {
			const graph_height = linegraph.svg_height()
			arr.push([current, current+graph_height])
			current += graph_height
		}
		return arr
	}

	graphAreasObj() {
		const entries = this.graphAreasArr().map((area, index) => [VisualizationManager.graphsOrder[index], area])
		return Object.fromEntries(entries)
	}
	graphBounds = (label) => this.graphAreasObj()[label]

	addStartCursorLine (x) {
		this.linesLayer.call(verticalLine, x, 'red', 'cursor_start_line')
		this.cursor_start_line = d3.select('.cursor_start_line')
	}
	addEndCursorLine(x){
		this.linesLayer.call(verticalLine, x, "red", 'cursor_end_line')
		this.cursor_end_line = d3.select('.cursor_end_line')
	}

	removeStartCursorLine () {
		this.cursor_start_line.remove()
	}
	removeEndCursorLine() {
		this.cursor_end_line.remove()
	}

	svg_width = () => this.svg.node().getBoundingClientRect().width
	svg_height = () => this.svg.node().getBoundingClientRect().height
}

function verticalLine (selection, x, stroke, className='vertical_line') {
	const line = selection.append("line")
		.attr("class", className)
		.attr("x1", x)
		.attr("y1", -99999)
		.attr("x2", x)
		.attr("y2", 99999)
		.attr("stroke", stroke)
	return line
}

function createTopBottomLines (svg) {
    this.top_line = svg.append("line")
        .attr("x1", 0)
        .attr("y1", 30)
        .attr("x2", VisualizationManager.width)
        .attr("y2", 30)
		.attr("class", "hoverline")
        .style("stroke", VisualizationManager.horizontal_line.color)
        .style("stroke-width", 0)

    this.bot_line = svg.append("line")
        .attr("x1", 0)
        .attr("y1", 100)
        .attr("x2", VisualizationManager.width)
        .attr("y2", 100)
		.attr("class", "hoverline")
        .style("stroke", VisualizationManager.horizontal_line.color)
        .style("stroke-width", 0)
        
	this.topBottomLinesVisible = (visibility) => {
		this.top_line.style("stroke-width", visibility ? 2 : 0)
		this.bot_line.style("stroke-width", visibility ? 2 : 0)
	}

	this.updateTopBottomLines = (top_y, bottom_y) => {
		this.top_line
			.attr("y1", top_y)
        	.attr("y2", top_y)
		this.bot_line
			.attr("y1", bottom_y)
        	.attr("y2", bottom_y)
	}
}