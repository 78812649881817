import { useState } from 'react';

export default function NumberInput({ nodeId, config, initialValue, onChange }) {

    const [value, setValue] = useState(initialValue ?? config.DEFAULT_VALUE ?? 0)

    return (
        <div style={{display: "flex"}}>
            <div style={{paddingRight: "8px"}}>
                {config.DISPLAY_NAME}
            </div>
            
            <div style={{border: "1px solid gray"}}>
                <input
                    type="number"
                    placeholder={config.DEFAULT_VALUE}
                    onChange={(e) => {
                        let newValue = parseFloat(e.target.value)
                        newValue = isNaN(newValue) ? "" : newValue

                        setValue(newValue)
                        if (onChange) {
                            onChange({nodeId, arg: config.VARIABLE_NAME, value: newValue})
                        }
                    }}
                    style={{
                        borderStyle: "none",
                        maxWidth: "40px",
                        width: "fit-content",
                    }}
                    value={value}
                />
            </div>
        </div>
    )
}