import React from 'react'
import { MobergButton, MobergButtonShape } from '../MobergButton/MobergButton'
import { MobergTheme } from '../MobergThemes/MobergColors'
import { MobergButtonVariant } from '../MobergButton/MobergButton'
import { MobergIconSize } from '../MobergIcon/MobergIcon'
import { OptionsIcon } from "../../Icons/OptionsIcon"

function CustomColumnVisibilityButton({ openColumnsPanel }) {

    return (
        <MobergButton
            theme={MobergTheme.BLUE}
            variant={MobergButtonVariant.CLEAR}
            shape={MobergButtonShape.SQUARE}
            onClick={openColumnsPanel}>
            <OptionsIcon size={MobergIconSize.LARGE} />
        </MobergButton>
    )
}

export default CustomColumnVisibilityButton