import { useState, useEffect } from "react"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm } from "react-hook-form"
import * as yup from "yup"
import BasicTextField from "../../../../Components/TextField/BasicTextField"
import { useEndpointProvider } from "../../../../Providers/EndpointProvider"
import ModalHeader from "../../../../Components/ModalHeader/ModalHeader"
import { useModalProvider } from "../../../../Providers/ModalProvider"
import { useTableContext } from "../../../../Providers/TableProvider"
import MUITable from "../../../../Components/MUITable/MUITable"
import GridContainer from "../../../../Components/TextField/GridContainer"
import ModalScrollbar from "../../../../Components/ModalScrollbar/ModalScrollbar"
import TableWrapper from "../../../../Components/TextField/TableWrapper"
import { ModalFooter } from "../../../../Constants/StyledComponents"
import { MobergButton, MobergButtonShape, MobergButtonVariant } from "../../../../Components/MobergButton/MobergButton"
import { MobergTheme } from "../../../../Components/MobergThemes/MobergColors"
import { useBackendLinksProvider } from "../../../../Providers/BackendLinksProvider"

const userColumns = [
	{ field: "email", flex: 1, headerName: "Email", visible: true },
	{ field: "first_name", flex: 0.5, headerName: "First name", visible: true },
	{ field: "last_name", flex: 0.5, headerName: "Last name", visible: true },
	{
		field: "is_verified",
		flex: 0.3,
		headerName: "Verified",
		renderCell: params => {
			return params.value ? <CheckCircleIcon color="success" /> : null
		},
		headerAlign: "center",
		align: "center",
		visible: true
	},
]

function CreateSiteModals() {
	const endpointProvider = useEndpointProvider()
	const { setToastProps } = useTableContext()
	const { close } = useModalProvider()
	const queryClient = useQueryClient()
	const { LINKS } = useBackendLinksProvider()

	const [selectedRows, setSelectedRows] = useState([])

	const [errorMessage, setErrorMessage] = useState()

	const errorCodeHandlerMap = new Map()
	errorCodeHandlerMap.set(409, json => setErrorMessage(json.errorMessage))

	const createSite = async body => endpointProvider.post(LINKS.ADMIN.SITES.CREATE_SITE, body, errorCodeHandlerMap)
	const createSiteMutation = useMutation({
		mutationFn: body => createSite(body),
		onSuccess: () => {
			setToastProps({ isOpen: true, severity: "success", message: "Site created successfully." })
			close()
		},
		onSettled: () => {
			queryClient.invalidateQueries("sites")
		},
	})
	const onSubmit = async data => {
		if (selectedRows.length === 0) {
			setErrorMessage("Please select at least one user.")
			return
		}
		createSiteMutation.mutate({
			...data,
			user_ids: selectedRows,
		})
	}

	const queryProps = {
		queryKey: "users",
		endpoint: LINKS.ADMIN.USER_MANAGEMENT.USERS.GET_USERS,
	}
	const preselectedProps = { selectedRows, setSelectedRows }

	const schema = yup
		.object({
			name: yup.string().required("Name is required."),
			identifier: yup.string().required("Site ID is required.").length(4, "Site ID must be exactly 4 characters long."),
			location: yup.string().required("Location is required."),
		})
		.required()
	const { control, handleSubmit, formState: { errors } } = useForm({
		resolver: yupResolver(schema),
		defaultValues: { name: "", identifier: "", location: "", description: "" },
	})

	useEffect(() => {
		if (Object.keys(errors).length > 0) {
			const error = errors[Object.keys(errors)[0]]
			if (Array.isArray(error)) {
				error.forEach(e => {
					if (e) {
						setErrorMessage(e?.message)
					}
				})
			} else {
				setErrorMessage(error?.message)
			}
		}
	}, [errors])

	return (
		<>
			<ModalHeader headerText="Create site" />
			<ModalScrollbar>
				<div style={{ maxWidth: "800px" }}>
					<h2>Site Details</h2>
					<GridContainer>
						<BasicTextField inputId="create-site-name-id" label="Name" placeholder="Test Site" autoFocus={true} control={control} name="name" numberColumn={6} />
						<BasicTextField inputId="create-site-site-id-id" label="Site ID" placeholder="0000" control={control} name="identifier" numberColumn={6} customHandleOnChange={true} />
						<BasicTextField inputId="create-site-location-id" label="Location" placeholder="11 E 22nd St" control={control} name="location" numberColumn={6} />
						<BasicTextField
							inputId="create-site-description-id"
							label="Description"
							placeholder="Write description"
							control={control}
							name="description"
							numberColumn={6}
							required={false}
						/>
						<TableWrapper inputId="create-site-users-id" label="Users">
							<MUITable columns={userColumns} preselectedProps={preselectedProps} {...queryProps} />
						</TableWrapper>
					</GridContainer>
				</div>
			</ModalScrollbar>
			<ModalFooter style={{ justifyContent: "space-between" }}>
				<h1 style={{ color: "#E54E58" }}>{errorMessage ? errorMessage : null}</h1>
				<MobergButton theme={MobergTheme.BLUE} variant={MobergButtonVariant.FILLED} shape={MobergButtonShape.WIDE} onClick={handleSubmit(onSubmit)}>
					Submit
				</MobergButton>
			</ModalFooter>
		</>
	)
}

export default CreateSiteModals