import { NODE_OUTPUT_TYPES } from "../../NodeOutputTypes";

export const ARGMIN = {
    INPUTS: [],
    DISPLAY_NAME: "Argmin",
    PRODUCT_LEVEL: "Standard",
    DESCRIPTION: "Find the x value associated with the minimum y value",
    KEYWORDS: ["argmin", "minimum", "min"],
    TYPE: 'METRICS',
    DATA_INPUT_TYPES: [NODE_OUTPUT_TYPES.LIST_OF_VALUES, NODE_OUTPUT_TYPES.LIST_OF_VALUES],
    DATA_OUTPUT_TYPE: NODE_OUTPUT_TYPES.VALUE
}