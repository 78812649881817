import React from "react"
import { MdKeyboardArrowLeft } from "react-icons/md"
import { useNavigate } from "react-router-dom"
import { FRONTEND_LINKS } from "../../../../../Constants/FrontendLinks"
import { MobergButton, MobergFontSize, MobergIconSize, MobergTheme } from "../../../../../Moberg"

export const PatientsLink = () => {
    const navigate = useNavigate()

    return (
        <MobergButton onClick={() => navigate(FRONTEND_LINKS.SUBPAGES.DATA.SUBPAGES.VISUALIZE.MAIN)} 
            theme={MobergTheme.BLUE} 
            fontSize={MobergFontSize.LARGE}
            style={{ fontWeight: "400", whiteSpace: "nowrap", gap: "0px", paddingLeft: "8px" }} >
            <MdKeyboardArrowLeft size={MobergIconSize.REGULAR} />
            Patients
        </MobergButton>
    )
}
