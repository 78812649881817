import styled from 'styled-components';
import * as MdIcons from 'react-icons/md';
import { useModalProvider } from "../../../Providers/ModalProvider";
import ModalHeader from "../../../Components/ModalHeader/ModalHeader";
import AccountSettingsModal from '../../../Components/PageHeader/AccountSettingsModal';
import { useState, useEffect } from 'react';
import { useEndpointProvider } from '../../../Providers/EndpointProvider';
import { useAuthProvider } from '../../../Providers/AuthProvider';
import { BackButtonDiv, NormalText, InfoMessage, ErrorMessage } from '../../../Constants/StyledComponents';
import ModalFooterButton, { buttonColor } from '../../../Components/ModalFooterButton/ModalFooterButton';
import { useBackendLinksProvider } from '../../../Providers/BackendLinksProvider';

const ChangePassword = (props) => {
    const endpointProvider = useEndpointProvider()
    const authProvider = useAuthProvider()
    const { openNext } = useModalProvider()
    const { LINKS } = useBackendLinksProvider()
    const [currentPassword, setCurrentPassword] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [confirmNewPassword, setConfirmNewPassword] = useState("")
    const [showPassword, setShowPassword] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [showErrorMessage, setShowErrorMessage] = useState(false)
    const [disableChangePassword, setDisableChangePassword] = useState(true)

    function changePassword(password) {
        let body = {
            new_password: password,
            password: currentPassword
        }

        return endpointProvider.post(LINKS.ACCOUNT.CHANGE_PASSWORD, body)
    }

    async function handleChangePassword(e) {
        if (e) {
            e.preventDefault()
        }
        changePassword(confirmNewPassword).then((data) => {
            alert(data)
            setNewPassword('')
            setConfirmNewPassword('')
            authProvider.logout()
        }).catch((e) => {
            alert(e);
        })
    }

    useEffect(() => {
        const specialChars = /[`!@$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        if (!currentPassword && !newPassword && !confirmNewPassword) {
            setDisableChangePassword(true)
            setShowErrorMessage(false)
        } else if (!currentPassword) {
            setDisableChangePassword(true)
            setErrorMessage("You must enter your current password.")
            setShowErrorMessage(true)
        } else if (!newPassword) {
            setDisableChangePassword(true)
            setErrorMessage("You must enter a new password.")
            setShowErrorMessage(true)
        } else if (!confirmNewPassword) {
            setDisableChangePassword(true)
            setErrorMessage("You must confirm your new password.")
            setShowErrorMessage(true)
        } else if (newPassword !== confirmNewPassword) {
            setDisableChangePassword(true)
            setErrorMessage("Passwords do not match.")
            setShowErrorMessage(true)
        } else if (newPassword === currentPassword) {
            setDisableChangePassword(true)
            setErrorMessage("New password must be different than current password.")
            setShowErrorMessage(true)
        } else if (!specialChars.test(newPassword)) {
            setDisableChangePassword(true)
            setErrorMessage("Password must contain at least one special character.")
            setShowErrorMessage(true)
        } else if (newPassword.includes('#')) {
            setDisableChangePassword(true)
            setErrorMessage("Passwords may not contain a hashtag.")
            setShowErrorMessage(true)
        } else if ((!(/[0-9]/.test(newPassword)))) {
            setDisableChangePassword(true)
            setErrorMessage("Password must contain at least one number.")
            setShowErrorMessage(true)
        } else if (!(String(newPassword).length >= 8)) {
            setDisableChangePassword(true)
            setErrorMessage("Password must be at least 8 characters long.")
            setShowErrorMessage(true)
        }
        else {
            setShowErrorMessage(false)
            setDisableChangePassword(false)
        }
    }, [confirmNewPassword, currentPassword, newPassword])

    function renderAccountSettingsModal() {
        openNext(<AccountSettingsModal
            escClose={false}
            clickOutsideClose={false}
        />)
    }


    return (
        <div style={{ background: "#FFFFFF", width: "820px", height: "776px", display: "block", paddingTop: "20px", paddingBottom: "188px" }}>
            <ModalHeader headerText='Account Settings' />

            <BackButtonDiv onClick={() => { renderAccountSettingsModal() }}>
                <MdIcons.MdOutlineArrowRightAlt size={32} style={{ marginRight: "5px", marginTop: "-4px", transform: "rotate(180deg)" }} />
                <NormalText>Back</NormalText>
            </BackButtonDiv>

            <div id="ChangePasswordModalContent" style={{ width: "820px", height: "auto", marginTop: "20px", paddingLeft: "250px", paddingRight: "250px", display: "inline-grid" }}>
                <div style={{ width: "320px", height: "auto", textAlign: "center", padding: "0px", marginBottom: "25px" }}>
                    <SubheaderText style={{ position: "relative" }}>Change Password</SubheaderText>
                    <InfoMessage style={{ height: "auto", marginTop: "8px" }}><p><strong>Info! </strong>You will be logged out after changing your password.</p></InfoMessage>
                </div>

                <div style={{ width: "320px", height: "400px", padding: "0px" }}>
                    <NormalTextBold>Current Password</NormalTextBold>
                    <ModalTextInput placeholder="Password" onChange={(e) => setCurrentPassword(e.target.value)} type={showPassword ? "text" : ((String(currentPassword).length === 0) ? "text" : "password")} onCopy={(e) => e.preventDefault()} onPaste={(e) => e.preventDefault()} />

                    <NormalTextBold>New Password</NormalTextBold>
                    <ModalTextInput placeholder="New Password" onChange={(e) => setNewPassword(e.target.value)} type={showPassword ? "text" : ((String(newPassword).length === 0) ? "text" : "password")} onCopy={(e) => e.preventDefault()} onPaste={(e) => e.preventDefault()} />

                    <NormalTextBold>Confirm New Password</NormalTextBold>
                    <ModalTextInput placeholder="New Password" onChange={(e) => setConfirmNewPassword(e.target.value)} type={showPassword ? "text" : ((String(confirmNewPassword).length === 0) ? "text" : "password")} onCopy={(e) => e.preventDefault()} onPaste={(e) => e.preventDefault()} />

                    <div style={{ marginTop: "-10px", marginBottom: "5px" }}>
                        <input id="showPasswordCheckbox" type="checkbox"
                            onChange={() => { setShowPassword(!showPassword) }} >
                        </input>
                        <label for="showPasswordCheckbox" style={{ marginLeft: "4px" }}>Show Password</label>
                    </div>

                    <ModalFooterButton
                        buttonText="Change Password"
                        color={buttonColor.blueFill}
                        disabled={disableChangePassword}
                        buttonStyle={{ position: "absolute", marginLeft: "60px" }}
                        onClickFxn={(e) => { handleChangePassword(e) }}
                    />

                    <ErrorMessage style={{ display: showErrorMessage ? "block" : "none", height: "fit-content", marginTop: "50px", textAlign: "center" }}>
                        <p><strong>Warning! </strong>{errorMessage}</p>
                    </ErrorMessage>

                </div>
            </div>
        </div>
    )
}

const SubheaderText = styled.text`
    font-family: 'Montserrat';
    font-style: normal;
    font-size: 16px;
    font-weight: 700;
    color: #293241;
    line-height: 150%;
    position: absolute;
`;

const NormalTextBold = styled.text`
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #293241;
    line-height: 150%;
`;

const ModalTextInput = styled.input`
    background: none;
    border: 1px solid #ABABAB;
    box-sizing: border-box;
    border-radius: 6px;
    width: 304px;
    height: 56px;
    padding: 16px;
	color: #000000;
	margin-top: 5px;
	margin-bottom: 30px;
    ::placeholder {
        font-family: 'Source Sans Pro';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #ABABAB;
    }
    //TO DO: consider making input boxes 320px wide to make everything look more centered
`;

export default ChangePassword;