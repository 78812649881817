import _ from 'lodash'
import ReactDOM from "react-dom";

class Change {
    constructor(data, callback) {
        this.data = data
        this.callback = callback
    }

    complete() {
        this.callback(this.data)
    }
}

export class UpdateDispatcher {
    constructor(updates, defaultState, validUpdates) {
        this.updates = [...new Set(updates.filter(update => Object.values(validUpdates).includes(update)))] // string[]
        this.state = JSON.parse(JSON.stringify(defaultState)) // {any: any}
        this.changes = {} // {str: Change}
        this.updatesReturned = 0 // int: number of updates that have returned so far, even if they haven't changed
    }

    // If the desired update is in the updates, then run the get function to get the value, store the result
    async dispatch(updateKey, get, onChange, onError=undefined, retryCount=2) {
        if (this.updates.map(u => u.toLowerCase()).includes(updateKey.toLowerCase())) {
            try {
                const result = await get()
                console.log(`UPDATE RETURNED: ${updateKey}`, {result})
                if (!_.isEqualWith(this.state[updateKey], result, ignoreArrayOrderCustomizer)) {
                    // keep the value returned by this function in the current cumulative state.
                    // This allows us to get the return value and cancel the dispatcher if we need to.
                    this.state[updateKey] = result  
                    this.changes[updateKey] = new Change(result, onChange)
                }
                this.updatesReturned += 1
                if (this.updatesReturned === this.updates.length) { // got all the updates we asked for.
                    this.complete()
                }
            } catch (error) {
                if (retryCount > 0) {
                    return this.dispatch(updateKey, get, onChange, onError, retryCount-1)
                }
                 
                this.cancel(error)

                if (onError) {
                    onError(error)
                } else {
                    throw error
                }
            }
        }
    }

    // Cancels any subsequent dispatched updates by maintaining the function signature but removing all functionality
    cancel(reason="") {
        console.log("UPDATE DISPATCHER CANCELLED. Reason:", reason)
        this.dispatch = (_, __, ___, ____=undefined) => 
            new Promise((resolve, _) => resolve(null))
        this.complete = () => null
    }

    complete() {
        console.log("COMPLETING UPDATE", this.updates)
        console.log("CHANGES", this.changes)
        ReactDOM.unstable_batchedUpdates(() => 
            Object.values(this.changes).forEach(change => change.complete())
        )
    }
}

function ignoreArrayOrderCustomizer(obj1, obj2) {
    if (Array.isArray(obj1) && Array.isArray(obj2)) {
        const sort1 = obj1.sort((a, b) => JSON.stringify(a).localeCompare(JSON.stringify(b)))
        const sort2 = obj2.sort((a, b) => JSON.stringify(a).localeCompare(JSON.stringify(b)))
        return _.isEqual(sort1, sort2)
    }
}