import React, { createContext, useContext, useState } from "react"
import { postWithDependencyInjection } from "./EndpointProvider"
import { useAuthProvider } from "./AuthProvider"
import { useOnMount } from "../Hooks/useOnMount"

type EnvironmentVariables = {
	CNS: boolean
	DISABLE_CHECK_INACTIVITY: boolean
	ENCRYPT: boolean
	FORM: boolean
	LIVE_REVIEW_ALLOWED: boolean
	MEDICATIONS: boolean
	NATUS: boolean
	PHI_WARNING: boolean
	RESOURCES_TAB: boolean
	TERMS_AND_CONDITION: boolean
	QUERY_ORIGIN_URL: string
	QUERY_PREFETCH_ORIGIN_URL: string
	IAM_ORIGIN_URL: string
	UPLOAD_ORIGIN_URL: string
	MESS_ORIGIN_URL: string
}

type EnvironmentVariablesContextValue = {
	environmentVariables: EnvironmentVariables
	deployment: string
	asDevelopmentUrl: (url: string) => string
}

export enum Deployment {
	DEVELOPMENT = "DEVELOPMENT",
	CLOUD = "CLOUD",
	CLINICAL = "CLINICAL"
}

const EnvironmentVariablesContext = createContext<EnvironmentVariablesContextValue>({} as any)

export const EnvironmentVariablesProvider: React.FC = ({ children }) => {
	const authProvider = useAuthProvider()
	const [environmentVariables, setEnvironmentVariables] = useState<EnvironmentVariables>()
	const deployment = window.env.REACT_APP_DEPLOYMENT ?? Deployment.CLOUD

	let IAM_URL = window.env.REACT_APP_IAM_ORIGIN_URL

	if (IAM_URL && deployment === Deployment.DEVELOPMENT) {
		IAM_URL = asDevelopmentUrl(IAM_URL)
	}

	useOnMount(() => {
		postWithDependencyInjection(authProvider?.token, window.env.REACT_APP_WORKSPACE_NAME, { LINK: `${IAM_URL}/v1/workspace/get_workspace_env`, OUTPUT_TYPE: "json" })
			.then((environment: EnvironmentVariables) => setEnvironmentVariables(environment))
			.catch(error => alert(`There was an unrecoverable error in the application. ${error}`))
	})

	function asDevelopmentUrl(url: string) {
		const urlObj = new URL(url)
		urlObj.hostname = "localhost"
		const urlString = urlObj.toString()
		return urlString.slice(0, urlString.length - 1) // There is an extra slash at the end.
	}

	if (!environmentVariables) {
		return null
	}

	return (
		<EnvironmentVariablesContext.Provider value={{ environmentVariables, deployment, asDevelopmentUrl }}>
			{children}
		</EnvironmentVariablesContext.Provider>
	)
}

export const useEnvironmentVariablesProvider = () => useContext(EnvironmentVariablesContext)
