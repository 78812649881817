import { useCallback, useState } from "react"

export enum DurationUnit {
	NANOSECONDS = "nanoseconds",
	MICROSECONDS = "microseconds",
	MILLISECONDS = "milliseconds",
	SECONDS = "seconds",
	MINUTES = "minutes",
	HOURS = "hours",
	DAYS = "days",
}

type useDurationProps = {
	value: number
	units: DurationUnit
}

type useDurationAPI = [MobergDuration, (value: number, units: DurationUnit) => void]

type MobergDuration = {
	value: number
	units: DurationUnit
	milliseconds: number
}

export const getDurationUnitRange = (start: DurationUnit, end: DurationUnit): DurationUnit[] => {
	const values = Object.values(DurationUnit)
	const lowerIndex = values.indexOf(start)
	const upperIndex = values.indexOf(end)

	if (lowerIndex === -1 || upperIndex === -1 || lowerIndex > upperIndex) {
		throw new Error("Invalid duration bounds provided.")
	}

	return values.slice(lowerIndex, upperIndex + 1)
}

export const toMilliseconds = (value: number, units: DurationUnit): number => {
	switch (units) {
		case DurationUnit.NANOSECONDS:
			return value / 1_000_000
		case DurationUnit.MICROSECONDS:
			return value / 1000
		case DurationUnit.MILLISECONDS:
			return value
		case DurationUnit.SECONDS:
			return value * 1000
		case DurationUnit.MINUTES:
			return value * 1000 * 60
		case DurationUnit.HOURS:
			return value * 1000 * 60 * 60
		case DurationUnit.DAYS:
			return value * 1000 * 60 * 60 * 24
		default:
			throw new Error("Duration units were not valid: " + units)
	}
}

export const fromMilliseconds = (milliseconds: number, validUnits: DurationUnit[]): MobergDuration => {
	let value = Infinity
    let autoUnits = validUnits[0]

	const mapping = new Map<DurationUnit, number>([
		[DurationUnit.NANOSECONDS, 1_000_000],
		[DurationUnit.MICROSECONDS, 1000],
		[DurationUnit.MILLISECONDS, 1],
		[DurationUnit.SECONDS, 1 / 1000],
		[DurationUnit.MINUTES, 1 / (1000 * 60)],
		[DurationUnit.HOURS, 1 / (1000 * 60 * 60)],
		[DurationUnit.DAYS, 1 / (1000 * 60 * 60 * 24)],
	])

    const filteredMapping = new Map<DurationUnit, number>()

    validUnits.forEach(units => {
        const conversion = mapping.get(units)

        if (conversion) {
            filteredMapping.set(units, conversion)
        }
    })

	for (const [units, ] of filteredMapping) {
		const conversion = filteredMapping.get(units)

		if (!conversion) {
			throw new Error("invalid duration units: " + units)
		}

		const potentialValue = milliseconds * conversion

		if (potentialValue < 1) {
			break
		}

		value = Math.round(100 * potentialValue) / 100
		autoUnits = units
	}

	return {
		value,
		units: autoUnits,
		milliseconds,
	}
}

export const useDuration = ({ value, units }: useDurationProps): useDurationAPI => {
	const [currentValue, setValue] = useState<number>(value)
	const [currentUnits, setUnits] = useState<DurationUnit>(units)

	const updateDuration = useCallback((value: number, units: DurationUnit) => {
		setValue(value)
		setUnits(units)
	}, [])

	const duration: MobergDuration = {
		value: currentValue,
		units: currentUnits,
		milliseconds: toMilliseconds(currentValue, currentUnits),
	}

	return [duration, updateDuration]
}

export const getFormattedDuration = (duration: MobergDuration) => {
	let units = duration.units.toString()

	if (duration.value === 1) {
		units = duration.units.slice(0, duration.units.length-1)
	}

	return `${duration.value} ${units}`
}