import styled from "styled-components"
import { useMobergMenu } from "../../Hooks/useMobergMenu"
import { EEGMontage, MontageType } from "../../Pages/Data/Visualize/DataReview/Types/EEG"
import { MobergAnimationTiming, MobergAnimationCurve } from "../MobergAnimation/MobergAnimation"
import React, { useRef, useState, MouseEvent, useContext } from "react"
import { MobergFontFamily } from "../MobergFont/MobergFont"
import { MobergTheme, MobergThemeColors, getColors } from "../MobergThemes/MobergColors"
import { MobergRow } from "../MobergLayout/MobergRow"
import { MobergSubMenuBoundary } from "../MobergMenu/MobergSubMenuBoundary"
import { RenameEEGMontage } from "./RenameEEGMontage"
import { CopyEEGMontage } from "./CopyEEGMontage"
import { MobergButton, MobergButtonShape } from "../MobergButton/MobergButton"
import { MdDelete, MdDriveFileRenameOutline, MdOutlineContentCopy, MdOutlineSettings } from "react-icons/md"
import { MobergIconSize } from "../MobergIcon/MobergIcon"
import { useModalProvider } from "../../Providers/ModalProvider"
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal"
import { useEEGMontageService } from "../../Hooks/useEEGMontageService"
import MontageModal from "../../Pages/Data/Visualize/EEG/MontageModal"
import { EEGDropdownContext } from "./DataReviewEEGMontageDropdown"

type EEGMontageDropdownRowProps = {
    eegMontage: EEGMontage
}

type SectionRowProps = {
	colors: MobergThemeColors
	active: boolean
}

type DisappearProps = {
	isEditing: boolean
}

export const EEGMontageDropdownRow: React.FC<EEGMontageDropdownRowProps> = ({ eegMontage }) => {
    const rowRef = useRef<HTMLDivElement>(null)
    const { createModal } = useModalProvider()
	const { deleteEEGMontage } = useEEGMontageService()
    const menu = useMobergMenu({ placement: "right-start", anchorRef: rowRef })
    const { menu:parentMenu, currentMontage, updateCurrentMontage } = useContext(EEGDropdownContext)
    const [menuContent, setMenuContent] = useState<string>()
    const isActive = currentMontage?.id === eegMontage.id
    const isEditing = menu.isOpen
	const isEditable = eegMontage.type !== MontageType.STANDARD

    const handleDelete = () => {
		parentMenu.setClickOutsideClose(false)

		createModal(
			<ConfirmationModal
				title={"Delete montage?"}
				description={`Are you sure you want to permanently delete ${eegMontage.name}? This action cannot be undone. All EEG displays using this montage will be affected.`}
				confirmButton={{
					text: "Yes, delete",
					theme: MobergTheme.RED,
					onClick: () => deleteEEGMontage(eegMontage.id)
				}}
				afterClose={() => {
					parentMenu.setClickOutsideClose(true)
				}}
			/>,
			{ zIndex: 10001 }
		)
	}

	const handleConfigure = () => {
		parentMenu.setClickOutsideClose(false)

		createModal(
			<MontageModal item={eegMontage}/>,
			{ zIndex: 10001, afterClose: () => parentMenu.setClickOutsideClose(true) }
		)
	}
    
    const getMenuContent = () => {
        switch (menuContent) {
            case "rename": return <RenameEEGMontage eegMontage={eegMontage} menu={menu} />
            case "copy": return <CopyEEGMontage eegMontage={eegMontage} menu={menu} />
            default:
                return null
        }
    }

    const handleMenuOpen = (content: string) => {
        menu.open()
        setMenuContent(content)
    }

    const handleSwitchEEGMontage = (event: MouseEvent<HTMLDivElement>) => {
		event.stopPropagation() // prevents the click from closing the menu. Recoil state updates differently than other React state.
		updateCurrentMontage(eegMontage)
	}

    const buildActionButton = (icon: JSX.Element, action: () => any, key: string) => {
		return (
			<MobergButton 
				onClick={action}
				shape={MobergButtonShape.SQUARE}
				theme={isActive ? MobergTheme.ON_BLUE : MobergTheme.ON_GRAY}
				active={isEditing && menuContent?.toLowerCase() === key.toLowerCase()}
				tooltip={key}
				style={{ padding: "5px" }}
			>
				{icon}
			</MobergButton>
		)
	}

    return (
		<Disappear ref={rowRef} isEditing={isEditing}>
			<div style={{ paddingRight: "8px" }}>
				<StyledRow key={eegMontage.id} active={isActive || isEditing} colors={isActive ? getColors(MobergTheme.BLUE) : getColors(MobergTheme.BLACK)}>
					<MobergRow horizontalAlign="space-between">
						<div style={{ padding: "0 16px", display: "flex", flex: 1, alignItems: "center", height: "48px" }} onClick={handleSwitchEEGMontage}>
							{eegMontage.name}
						</div>

						<div className="disappear">
							<MobergRow horizontalAlign="right">
								{isEditable && buildActionButton(<MdOutlineSettings size={MobergIconSize.REGULAR} />, handleConfigure, "Configure")}
								{isEditable && buildActionButton(<MdDriveFileRenameOutline size={MobergIconSize.REGULAR} />, () => handleMenuOpen("rename"), "Rename")}
								{buildActionButton(<MdOutlineContentCopy size={MobergIconSize.REGULAR} />, () => handleMenuOpen("copy"), "Copy")}
								{isEditable && buildActionButton(<MdDelete size={MobergIconSize.REGULAR} />, handleDelete, "Delete")}
							</MobergRow>
						</div>
					</MobergRow>
				</StyledRow>
			</div>

            <MobergSubMenuBoundary>
                <menu.MobergMenu style={{ padding: "16px", marginLeft: "10px"}}>
                    {getMenuContent()}
                </menu.MobergMenu>
            </MobergSubMenuBoundary>
		</Disappear>
	)
}

const Disappear = styled.div<DisappearProps>`
	.disappear {
		transition: opacity ${MobergAnimationTiming.VERY_FAST} ${MobergAnimationCurve.EASE_IN};
		opacity: ${({ isEditing }) => isEditing ? 1 : 0};
	}

	&:hover .disappear {
		opacity: 1;
	}
`

const StyledRow = styled.div<SectionRowProps>`
	display: flex;
	align-items: center;
	font-family: ${MobergFontFamily.REGULAR};
	font-weight: bold;
	border-radius: 6px;
	cursor: pointer;
	justify-content: space-between;
	transition: background-color ${MobergAnimationTiming.VERY_FAST} ${MobergAnimationCurve.EASE_IN};
	padding-right: 8px;

	background-color: ${({ colors, active }) => (active ? colors.noFill.active : "unset")};

	&:hover {
		background-color: ${({ colors }) => colors.noFill.active};
	}
`