import { Selection, EnterElement, ScaleLinear } from "d3";
import { D3OneToManyRenderable } from "../../../D3/D3OneToManyRenderable";
import { ReactCallbacks } from "../../../../Types/ReactCallbacks";
import { D3ErrorBar } from "./D3ErrorBar";

export type D3ErrorBarsConfig = {
    xScale: ScaleLinear<any, any, any>
    yScale: ScaleLinear<any, any, any>
    bars: ErrorBarConfig[]
    clipPathId: string,
    transitionDuration?: number
}

type ErrorBarConfig = {
    x: number
    y: number | null
    error: number | null
}

export class D3ErrorBars extends D3OneToManyRenderable<SVGGElement, D3ErrorBarsConfig, ErrorBarConfig> {
    private d3ErrorBars: Map<string | number, D3ErrorBar> = new Map()

    constructor(root: SVGGElement, config: D3ErrorBarsConfig, reactCallbacks: ReactCallbacks<any>) {
        super(root, config, "d3-error-bars", reactCallbacks)
        this.mount()
    }

    protected datumIdentifier(datum: ErrorBarConfig): string | number {
        return datum.x
    }

    protected getConfigs(): ErrorBarConfig[] {
        return this.config.bars
    }

    protected enter(newElements: Selection<EnterElement, ErrorBarConfig, Element, any>): Selection<SVGGElement, ErrorBarConfig, Element, any> {
        const containers = newElements.append("g")
            .attr("clip-path", `url(#${this.config.clipPathId})`)
            .attr("class", this.className)
            .style("opacity", 0)

        const animatedContainers = containers.transition().duration(this.config.transitionDuration ?? 0)
        animatedContainers.style("opacity", 1)

		containers.each((config, index, nodes) => this.createChildren(config, index, nodes))

        this.renderChildren()

		return containers
    }

    protected update(updatedElements: Selection<SVGGElement, ErrorBarConfig, Element, any>): Selection<SVGGElement, ErrorBarConfig, Element, any> {
        this.renderChildren()
		return updatedElements
	}

	protected exit(exitedElements: Selection<SVGGElement, ErrorBarConfig, SVGGElement, any>): void {
		exitedElements
            .transition()
            .duration(this.config.transitionDuration ?? 0)
            .style("opacity", 0)
            .attr("transform", `translate(0, 40)`)
            .end()
            .then(() => {
                exitedElements.each(bar => this.d3ErrorBars.delete(this.datumIdentifier(bar))).remove()
            })
	}

	protected createChildren = (bar: ErrorBarConfig, index: number, nodes: ArrayLike<SVGGElement>) => {
		this.d3ErrorBars.set(this.datumIdentifier(bar), new D3ErrorBar(nodes[index], this.getD3BarConfig(bar), "d3-error-bar", this.reactCallbacks))
	}

    protected updateDerivedState = () => {
        this.config.bars.forEach(bar => {
            this.d3ErrorBars.get(this.datumIdentifier(bar))?.updateConfig(this.getD3BarConfig(bar))
        })
    }

    private getD3BarConfig = (bar: ErrorBarConfig) => ({
        ...bar, 
        yScale: this.config.yScale, 
        xScale: this.config.xScale, 
        transitionDuration: this.config.transitionDuration
    })

    private renderChildren = () => {
        this.config.bars.forEach(bar => this.d3ErrorBars.get(this.datumIdentifier(bar))?.render())
    }
}