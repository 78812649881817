import { ANALYTIC_NODES } from "./Nodes/AnalyticNodes/AnalyticNodes"
import { DATA_ORGANIZER_NODES } from "./Nodes/DataOrganizerNodes/DataOrganizerNodes"
import { SOURCE_NODES } from "./Nodes/SourceNodes"
import { LOGIC_NODES } from "./Nodes/LogicNodes"
import { USER_INPUT_TYPES } from "./UserInputTypes"
import { VISUALIZATION_NODES } from "./Nodes/VisualizationNodes"

export const NODE_TYPES = {
    SOURCE: "SOURCE",
    ANALYTIC: "ANALYTIC",
	DATA_ORGANIZER: "DATA_ORGANIZER",
    VISUALIZATION: "VISUALIZATION",
}

export const NODE_DEFINITIONS = {
	...NODE_TYPES,
	...USER_INPUT_TYPES,
    DISPLAY_NAME: "DISPLAY_NAME",
    VARIABLE_NAME: "VARIABLE_NAME",
    DEFAULT_VALUE: "DEFAULT_VALUE",
    TYPE: "TYPE",
    INPUTS: "INPUTS",
    DESCRIPTION: "DESCRIPTION",
    GET_MODALITIES: "GET_MODALITIES",
    KEYWORDS: "KEYWORDS",
	SITE_ID: "SITE_ID",
	PATIENT_ID: "PATIENT_ID",
	PATIENT_FILE: "PATIENT_FILE",
    ADVANCED_INPUT: "ADVANCED_INPUT",
    PRODUCT_LEVEL: "PRODUCT_LEVEL",
}


const PIPELINE_STRUCTURE = {
    SOURCE: SOURCE_NODES,
	DATA_ORGANIZER: DATA_ORGANIZER_NODES,
    LOGIC: LOGIC_NODES,
	ANALYTIC: ANALYTIC_NODES,
	VISUALIZATION: VISUALIZATION_NODES
}

export default PIPELINE_STRUCTURE;
