import { useState, useRef } from 'react'
import { DragAndDropCanvas } from './DragAndDropCanvas'
import Pipeline from "../../../../../Managers/PipelineManager/Pipeline"
import ToolBar from './ToolBar'
import Node from "../../../../../Managers/PipelineManager/Node"
import { ModalController } from "../../../../../Controllers/ModalController"
import styled from "styled-components";
import * as MdIcons from "react-icons/md";

export const CreatePipelineModal = ({ openRef }) => {
	let Source = [
		{'group': 'source', 'label': '', 'icons': [<MdIcons.MdSource size={18}/>]}
	]

	let Analytics = [
		{'group': 'analytics', 'label': '', 
		'icons': [<MdIcons.MdOutlineAnalytics size={18}/>]}
	]

	let MachineLearning = [
		{'group': 'machine learning', 'label': '', 
		'icons': [<MdIcons.MdStackedLineChart size={18}/>]}
	]

	let Epoch = [
		{'group': 'epoch', 'label': '', 
		'icons': [<MdIcons.MdInsertChartOutlined size={18}/>, <MdIcons.MdInsertChartOutlined size={18}/>]
		}
	]

	let Visualize = [
		{'group': 'visualize', 'label': '', 
		'icons': [<MdIcons.MdStackedLineChart size={18}/>,  <MdIcons.MdAreaChart size={18}/>]}
	]
	const headerIconButtons = [...Source, ...Analytics, ...MachineLearning, ...Epoch, ...Visualize]
	const headerTabNames = ["Source", "Analytics", "Machine Learning", "Epoch", "Visualize"]

	const [selectedTab, setSelectedTab] = useState("Source")
	const closeButtonRef = useRef()
	const contentRef = useRef()
	const [canvasViewBoxPosition, setCanvasViewBoxPosition] = useState({x: 0, y: 0})

    const [state, setState] = useState({
		nodes: {},
		connections: {},
		performanceOptions: {
			updateConnectionsOnDrag: true
		}
	  }
	)

	function toggleXarrowsRenderOnDrag(prevState) {
		const prevStateCopy = {...prevState}
		prevStateCopy.performanceOptions.updateConnectionsOnDrag = !prevStateCopy.performanceOptions.updateConnectionsOnDrag
		return prevStateCopy
	}

	function getNextNodeId() {
		const sortedIntegerNodeIds = Object.keys(state.nodes)
			.map(nodeId => parseInt(nodeId.split("node")[1]))
			.sort((a, b) => a - b)

		let i = 0
		while (i < sortedIntegerNodeIds.length && sortedIntegerNodeIds[i] === i) {
			i++
		}

		return i
	}

	function addNodeToCanvas(type, subtype) {
		setState(prevState => {
			const prevStateCopy = {...prevState}
			const nextNodeId = getNextNodeId()
			const {x, y} = canvasViewBoxPosition
			prevStateCopy.nodes["node" + nextNodeId] = 
				new Node(nextNodeId, type, subtype, [x + 50, y + 50]).exportStructure()
				
			return prevStateCopy
		})
	}

	return (<>
		{/* Modal Content Wrapper */}
		<div ref={contentRef} style={{display: "none"}}>

			{/* Header */}
			<div id="tabPageHeader" style={{background: "#FFFFFF", position: "sticky", top: 0, zIndex: 1}}>
				<div id="tabButtonDiv" style={{display: "inline-flex", marginLeft: "40px", marginTop: "14px", marginBottom: "-8px"}}>
					{headerTabNames.map(tab => (
						<TabButton 
							key={tab}
							colorchange={tab === selectedTab} 
							underlined={tab === selectedTab} 
							onClick={() => {setSelectedTab(tab)}}>
							<p id={`TabHeader_${tab}`}>{tab}</p>
						</TabButton>
					))}
				</div>

				<button ref={closeButtonRef} style={{right: "14px", marginTop: "14px", position: "absolute", border: "none", background: "#fff"}}>
					<MdIcons.MdCloseFullscreen size={22} />
				</button>

				<hr style={{border: "1px solid #DDDDDD"}}/>

				<div id="createPipelineIconButtonHeader" style={{display: "inline-flex", marginLeft: "40px"}}>
					{headerIconButtons
						.filter(button => button.group.toLowerCase() === selectedTab.toLowerCase())
						.map((button, button_index) => (
							<div key={button_index} style={{display: "inline-flex", marginRight: "16px"}}>
								{button.label ? <p style={{marginRight: "6px"}}>{button.label}</p> : null}
								{button.icons.map((icon, icon_index) => <IconButton key={`${button.group}_${button_index}_${icon_index}`}><p>{icon}</p></IconButton>)}
							</div>
						))
					}
				</div>
			</div>

			{/* Body */}
			<div style={{padding: "20px"}}>
				<ToolBar addNodeToCanvas={addNodeToCanvas}></ToolBar>
				<DragAndDropCanvas props={{state, setState, setCanvasViewBoxPosition}}/>

				<button onClick={() => console.log(JSON.stringify(Pipeline.fromState(state).jsonifyPipeline()))}>
					Export Pipeline JSON
				</button>
				<p>
					<input type="checkbox" onClick={() => setState(toggleXarrowsRenderOnDrag)}/>
					Performance Mode
				</p>
			</div>
        </div>

						
		<ModalController 
			modalStyle={'fullscreen'} 
			contentRef={contentRef} 
			openRef={openRef} 
			closeRef={closeButtonRef}
		/>
	</>)
}

const TabButton = styled.button`
	margin-right: 24px;
	width: auto;
	height: 24px;
	font-family: 'Source Sans Pro';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 150%;
	color: #5A6679;
	background: none;
	border: none;
	cursor: pointer;
	p {
		padding-bottom: 13px;
		border-bottom: ${({ underlined }) =>
        underlined ? "3px solid #0097ee" : "none"};
		color: ${({ colorchange }) => (colorchange ? "#0097ee" : "#5A6679")}
	}
`;

const IconButton = styled.button`
	width: 26px;
	height: 26px;
	border-radius: 3px;
	background: #EFF1F4;
	border: none;
	margin-right: 8px;
	cursor: pointer;
	margin-top: -4px;
	p {
		color: #000000;
	}
`;