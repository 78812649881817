import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import * as MdIcons from "react-icons/md";
import { MobergButton } from '../MobergButton/MobergButton';

function PermissionToggle({ permission, onToggle, disabled }) {
	const handleChange = () => { onToggle(permission.name) }

	return (
		<PermissionContainer>
			<PermissionLabel style={{ cursor: disabled ? 'auto' : 'pointer' }}>
				<PermissionInfo>
					<PermissionName>{permission.name}</PermissionName>
					<PermissionDescription>{permission.description}</PermissionDescription>
				</PermissionInfo>
				<PermissionSwitch>
					<PermissionInput
						type="checkbox"
						checked={permission.enabled}
						onChange={handleChange}
						disabled={disabled}
					/>
					<PermissionSlider style={{ cursor: disabled ? 'auto' : 'pointer' }} />
				</PermissionSwitch>
			</PermissionLabel>
		</PermissionContainer>
	)
}

export default function PermissionGroup({ group, onToggle, onSelectAllToggle, isSearch, disabled }) {
	const [isOpen, setIsOpen] = useState(false);
	const [selectAll, setSelectAll] = useState(group.permissions.every(permission => permission.enabled))

	useEffect(() => {
		// Update the selectAll state whenever the group's permissions change
		setSelectAll(group.permissions.every(permission => permission.enabled))
	}, [group.permissions])

	const toggleDropdown = () => { setIsOpen(!isOpen) }

	const handleSelectAllToggle = () => {
		setSelectAll(!selectAll)
		onSelectAllToggle(group.group, !selectAll)
	}

	const handleToggle = (permissionName) => { onToggle(group.group, permissionName) }

	return (
		<GroupContainer>
			<GroupHeader onClick={toggleDropdown}>
				{group.group.replace(/_/g, " ").replace(/^./, group.group[0].toUpperCase())}
				<MobergButton>
					<MdIcons.MdKeyboardArrowDown />
				</MobergButton>
			</GroupHeader>
			{isOpen && (
				<div>
					{!isSearch &&
						<PermissionContainer>
							<PermissionLabel style={{ cursor: disabled ? 'auto' : 'pointer' }}>
								<PermissionInfo>
									<PermissionName>Select All</PermissionName>
									<PermissionDescription>Toggle to select/unselect all permissions</PermissionDescription>
								</PermissionInfo>
								<PermissionSwitch>
									<PermissionInput
										type="checkbox"
										checked={selectAll}
										onChange={handleSelectAllToggle}
										disabled={disabled}
										style={{ cursor: disabled ? 'auto' : 'pointer' }}
									/>
									<PermissionSlider style={{ cursor: disabled ? 'auto' : 'pointer' }} />
								</PermissionSwitch>
							</PermissionLabel>
						</PermissionContainer>
					}
					{group.permissions.map(permission => (
						<PermissionToggle
							key={permission.name}
							permission={permission}
							onToggle={handleToggle}
							disabled={disabled}
						/>
					))}
				</div>
			)}
		</GroupContainer>
	);
}

const GroupContainer = styled.div`
  width: 100%;
  margin-bottom: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  background: #fff; /* Assuming you want a white background as in the image */
`;

const PermissionContainer = styled.div`
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PermissionLabel = styled.label`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px 20px;
  background-color: #f4f6f8;
  border-radius: 6px;
`;

const PermissionInfo = styled.div`
  font-size: 16px;
`;

const PermissionName = styled.div`
	font-family: "Source Sans Pro", sans-serif;
	font-weight: 600;
	font-size: 18px;
	line-height: 150%;
	color: #293241
`;

const PermissionDescription = styled.div`
	font-family: "Source Sans Pro", sans-serif;
	font-weight: 400;
	font-size: 14px;
	line-height: 150%;
	color: #293241;
	max-width: 580px;
`;

const PermissionSwitch = styled.label`
  position: relative;
  display: inline-block;
  width: 40px; // Smaller width
  height: 22px; // Smaller height
`;

const PermissionInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`;

const PermissionSlider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 22px; // Adjusted to match the new height
  &::before {
    position: absolute;
    content: "";
    height: 16px; // Slightly smaller than before
    width: 16px; // Slightly smaller than before
    left: 3px; // Adjusted for centering
    bottom: 3px; // Adjusted for centering
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }
  ${PermissionInput}:checked + & {
    background-color: #2196F3;
  }
  ${PermissionInput}:checked + &::before {
    transform: translateX(18px); // Adjusted for the new size
  }
`;

const GroupHeader = styled.div`
  	background-color: #f4f6f8;
  	padding: 10px 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
  	cursor: pointer;
  	border-bottom: 1px solid #ddd;
	font-family: "Source Sans Pro", sans-serif;
	font-weight: 600;
	font-size: 19px;
	line-height: 150%;
	color: #293241;
`;