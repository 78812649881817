const NatusFilterFunction = async (filenames, files) => {

    let allFiles = []
    let validFilenames = filenames

    let validFiles = Object.keys(files).map((i) => { if (validFilenames.includes(files[i].name)) { return files[i] }}).filter((validFilename) => { return validFilename !== undefined })

    allFiles = validFiles
    
    const natusDirs = (files) => {
        const regex = /(?<=Xxxxxxx~ Xxxxxsample~ data_)[^.\s]*/;
        let last = null;
        
        for (let i = 0; i < files.length; i++) {
          const file = files[i].name;
          if (file.includes('_001')) {
            continue;
          }
          const name = file.split('/').pop();
          if (name.startsWith('Xxxxxxx~ Xxxxx')) {
            const signature = name.match(regex)[0];
            if (last === null || last !== signature) {
              last = signature;
              const objName = `Natus_${signature}`;
              const filepath = file.slice(0, file.lastIndexOf('/'));
              
                return {
                    name: objName,
                    filepath: filepath,
                    files: [file],
                    type: 'Natus',
                    valid: true,
                    error: null,
                };
            }
          }
        }
      };
    
    /*if(natusDirs(files) === undefined){
            alert('Error: This directory does not follow the Natus standard structure.')
            return
    }*/

    let filteredInputData = {
        allFiles: allFiles
    }

    return filteredInputData
}

export default NatusFilterFunction