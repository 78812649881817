import { ImagePage } from "../../../../Data/ImagePage"
import { ImagePageManagerConfig } from "../../../../Data/ImageTimeSeriesPageManager"
import { PersystPageManager, TitlesAndLabels } from "../../../../Data/PersystPageManager"
import { MarginedBoundingBox } from "../../../../Types/MarginedBoundingBox"
import { Offset } from "../../../../Types/Offset"
import { PersystEEGWindowConfig } from "../../../../Types/PersystWindow"
import { D3TimeBasedVisualization } from "../../D3TimeBasedVisualization"
import { D3PersystEEGWindowRenderer } from "./D3PersystEEGWindowRenderer"
import { D3PersystEEGWindowConfigurationBuilder } from "./D3PersystEEGWindowConfigurationBuilder"
import { PersystEEGReactCallbacks } from "../../../../Types/ReactCallbacks"
import { LEFT_MARGIN, RIGHT_MARGIN } from "../../Constants"
import { ModalityDataSource } from "../../../../Types/ModalityDataSource"
import { TraceDataConfig } from "../../../../Types/Trace"
import { DataSource } from "../../../../Types/DataSource"

export class D3PersystEEGWindow extends D3TimeBasedVisualization<PersystEEGWindowConfig, PersystEEGReactCallbacks, D3PersystEEGWindowRenderer, PersystPageManager> {
	public boundingBox: MarginedBoundingBox
	private margins: Offset = { top: 30, left: LEFT_MARGIN, bottom: 90, right: RIGHT_MARGIN }

	public overlayBoundingBox: MarginedBoundingBox
	public overlayMargins = { top: 0, left: 0, bottom: 0, right: 0 }

	constructor(root: HTMLDivElement, config: PersystEEGWindowConfig, pageManager: PersystPageManager, reactCallbacks: PersystEEGReactCallbacks) {
		super(root, config, pageManager, reactCallbacks)
		this.boundingBox = new MarginedBoundingBox(config.dimensions, this.margins)
		this.overlayBoundingBox = new MarginedBoundingBox(config.dimensions, this.overlayMargins)

		this.mount(new D3PersystEEGWindowRenderer(this, new D3PersystEEGWindowConfigurationBuilder(this), "d3-persyst-eeg-window"))
	}

	public getVisibleTraces(): TraceDataConfig[] {
		console.error("Persyst EEG visible traces not implemented.")
		return [] // This is based off of the Montage. Should get this info from Persyst.
	}

	public getPageHeight(): number {
		return this.overlayBoundingBox.height
	}

	public getTitlesAndLabels(): TitlesAndLabels | null {
		if (this.renderer) {
			this.timeSeriesPageManager.onTitlesAndLabelsLoaded = this.renderer.onTitlesAndLabelsLoaded
		}

		return this.timeSeriesPageManager.getTitlesAndLabels(this.boundingBox.height, LEFT_MARGIN - 20, this.config.montage?.name ?? "")
	}

	public resetTitlesAndLabels = () => {
		this.timeSeriesPageManager.resetTitlesAndLabels()
		this.getTitlesAndLabels()
	}

	public getOverlay = () => this.renderer?.overlay

	public getLastHoveredDate = () => this.renderer?.getLastHoveredDate()

	public getPageManagerConfig = (): ImagePageManagerConfig => {
		return {
			patientId: this.config.patientId,
			windowId: this.config.id,
			viewScale: this.config.viewScale,
			fileScale: this.config.fileScale,
			modalityDataSources: this.getModalityDataSources(),
			height: this.overlayBoundingBox.height,
			timeZone: this.config.timeZone,
			patientIsAdmitted: this.config.patientIsAdmitted
		}
	}

	protected renderPage(page: ImagePage): void {
		this.renderer?.renderPage(page)
	}

	protected getModalityDataSources(): ModalityDataSource[] {
		return [{
			modality: this.config.montage?.name ?? "", 
			dataObjectId: this.reactCallbacks.dataSourceMap.get(DataSource.CURRENT_PATIENT) as number,
		}]
	}

	protected updateDerivedState(): void {
		this.boundingBox.setDimensions(this.config.dimensions)
		this.overlayBoundingBox.setDimensions({ width: this.boundingBox.width, height: this.boundingBox.height })
		this.config.viewScale.range([0, this.boundingBox.width])
	}

}
