import React, { MouseEvent } from "react"
import { ModalityGraph } from "../../../../Pages/Data/Visualize/DataReview/Types/ModalityGraph"
import { MobergButton, MobergHoverRow, MobergIconSize, MobergText, MobergTheme } from "../../../../Moberg"
import { MdClose } from "react-icons/md"

type GraphToggleButtonProps = {
	graph: ModalityGraph
	isActive: boolean
	toggleCurrentGraph: (graph: ModalityGraph) => void
	removeGraph: (graph: ModalityGraph) => void
}

export const GraphToggleButton: React.FC<GraphToggleButtonProps> = ({ graph, isActive, toggleCurrentGraph, removeGraph }) => {
	const handleDeleteGraph = (event: MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation()
		removeGraph(graph)
	}

	return (
		<MobergHoverRow theme={isActive ? MobergTheme.BLUE : MobergTheme.BLACK} active={isActive} onClick={() => toggleCurrentGraph(graph)} style={{ padding: "0 8px" }}>
			<div style={{ flex: 1, padding: "9px 16px", whiteSpace: "nowrap", minWidth: "220px" }}>
                <MobergText>
                    {graph.name}
                </MobergText>
			</div>

			<MobergButton theme={isActive ? MobergTheme.ON_BLUE : MobergTheme.ON_GRAY} onClick={handleDeleteGraph} style={{ padding: "2px" }}>
				<MdClose size={MobergIconSize.REGULAR} />
			</MobergButton>
		</MobergHoverRow>
	)
}
