import { WINDOW_TIME_PRESETS } from '../Viewport/Components/XAxis';
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import { useModalProvider } from '../../../Providers/ModalProvider';
import { ConfigureWindowModal } from "../../VisualizationManager/ToolBar/Modals/ConfigureWindowModal"
import { modalityGraphGroupConfigAtom } from '../../../Pages/Data/Visualize/DataReview/Atoms/ModalityGraphGroup';
import { selectedLayoutAtom, selectedLayoutGroupAtom, selectedLayoutIdAtom } from '../../../Pages/Data/Visualize/DataReview/Atoms/Layout';
import { uneditedLayoutGroupAtom } from '../../../Pages/Data/Visualize/DataReview/Atoms/Layout';
import { editedLayoutGroupAtom } from '../../../Pages/Data/Visualize/DataReview/Atoms/Layout';
import { MobergButton, MobergButtonShape } from '../../../Components/MobergButton/MobergButton';
import { MobergIconSize } from '../../../Components/MobergIcon/MobergIcon';
import { VisualizationToolbar } from '../../../Pages/Data/Visualize/DataReview/Components/React/VisualizationToolbar';
import { MobergTheme } from '../../../Components/MobergThemes/MobergColors';
import { MdOutlineUnfoldLessDouble } from 'react-icons/md';

export function WindowSettings({ visualizationArea }) {
    const { createModal } = useModalProvider()
    const setUneditedLayoutGroup = useSetRecoilState(uneditedLayoutGroupAtom)
	const setEditedLayoutGroup = useSetRecoilState(editedLayoutGroupAtom)
    const layoutId = useRecoilValue(selectedLayoutIdAtom)
    const [timeSeriesGroup, setTimeSeriesGroup] = useRecoilState(modalityGraphGroupConfigAtom({layoutId, windowId: visualizationArea}))
    const graphsHidden = timeSeriesGroup.hideEmptyGraphs

    const selectedLayout = useRecoilValue(selectedLayoutAtom)
    const selectedLayoutGroup = useRecoilValue(selectedLayoutGroupAtom)

    const [viewStart, viewEnd] = timeSeriesGroup.viewScale.domain()
    const viewDuration = viewEnd.getTime() - viewStart.getTime()

    function renderConfigureWindowModal() {
        if (!selectedLayout) {
			console.error("Couldn't render the modal because a layout was not selected")
			return
		}

		setUneditedLayoutGroup(selectedLayoutGroup)
		setEditedLayoutGroup(selectedLayoutGroup)

        createModal(<ConfigureWindowModal 
			escClose={false}
			clickOutsideClose={false}
            windowId={visualizationArea}
            layoutId={selectedLayout.id}
        />)
    }

    function toggleEmptyGraphs() {
        setTimeSeriesGroup(previous => ({...previous, hideEmptyGraphs: !previous.hideEmptyGraphs}))
    }

    const actionButtons = (<>
        <MobergButton 
            tooltip={"Show/hide graphs that will always be blank"}
            onClick={toggleEmptyGraphs}
            shape={MobergButtonShape.SQUARE}
            active={graphsHidden}
            theme={graphsHidden ? MobergTheme.BLUE : MobergTheme.BLACK}>
            <MdOutlineUnfoldLessDouble size={MobergIconSize.SMALL}/>
        </MobergButton>
    </>
    )

    return(
        <VisualizationToolbar 
            visualizationId={{ layoutId, windowId: visualizationArea }}
            viewDuration={viewDuration}
            timePresetOptions={WINDOW_TIME_PRESETS}
            atomValue={timeSeriesGroup}
            setAtom={setTimeSeriesGroup}
            actionButtons={actionButtons}
            renderConfigureModal={renderConfigureWindowModal}
        />
    )
}

export default WindowSettings;