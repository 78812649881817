import React from "react"
import { PatientsLink } from "./PatientsLink"
import { Scrollbar } from "../../../../../Constants/StyledComponents"

type SubHeaderProps = {
    startSlotChildren?: Element | Element[]
    endSlotChildren?: Element | Element[]
    patient?: {
        patient_id: string
        site_name: string
        site_identifier: string
    }
}

export const SubHeader: React.FC<SubHeaderProps> = ({ patient, startSlotChildren, endSlotChildren }) => {
    
    return (
        <Scrollbar style={{ padding: "16px 0", alignItems: 'center', display: 'flex', justifyContent: 'space-between', gap: "16px", width: "100%", fontFamily: "Source Sans Pro", fontSize: "16px", overflowX: "auto" }}>
            <div style={{ flex: 1, display: "flex", gap: "32px", alignItems: "center" }}>
                <PatientsLink />
                {startSlotChildren}
            </div>

            <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
                <strong> {patient?.site_identifier}: {patient?.site_name} / Patient {patient?.patient_id} </strong>
            </div>

            <div style={{ flex: 1, display: "flex", alignItems: "center", justifyContent: "end" }}>
                {endSlotChildren}
            </div>
        </Scrollbar>
    )
}
