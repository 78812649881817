import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil"
import { activeAnnotationSessionAtom, inProgressAnnotationAtom } from "../../../../Atoms/Annotations"
import { Annotation } from "../../../../../../../../Managers/VisualizationManager/Variables/Annotations"
import { InProgressAnnotation } from "../../../../Types/Annotations"
import { useAnnotationService } from "../../../../../../../../Hooks/useAnnotationService"

export const useInProgressAnnotation = (visualizationArea: string) => {
	const { createAnnotation } = useAnnotationService()
	const cancelAnnotation = useResetRecoilState(inProgressAnnotationAtom(visualizationArea))
    const [inProgressAnnotation, setAnnotation] = useRecoilState(inProgressAnnotationAtom(visualizationArea))
    const currentAnnotationSession = useRecoilValue(activeAnnotationSessionAtom)

    async function finishAnnotation(endDate: Date): Promise<Annotation> {
		let { startDate } = inProgressAnnotation

		if (!startDate || !endDate) {
			throw new Error("Could not create the annotation. Invalid start or end date.")
		}

		if (!currentAnnotationSession) {
			throw new Error("Can't finish the annotation because no annotation group is selected.")
		}

		if (endDate < startDate) {
			let temp = endDate
			endDate = startDate
			startDate = temp
		}

		const annotation: Annotation = Annotation.fromConfig({
			...inProgressAnnotation, 
			start_time: startDate.getTime(),
			end_time: endDate.getTime()
		})

		return await createAnnotation(annotation)
	}

	const updateAnnotation = (keyPressed: string, newAnnotationData: InProgressAnnotation, lastHoveredDate: Date) => {
		if (inProgressAnnotation.startDate && keyPressed === inProgressAnnotation.keyPressed) {
			finishAnnotation(lastHoveredDate)
			setAnnotation(prev => ({ ...prev, ...newAnnotationData, keyPressed, startDate: null, endDate: null }))
		} else {
			setAnnotation(prev => ({ ...prev, ...newAnnotationData, keyPressed, startDate: lastHoveredDate, endDate: null }))
		}
	}

    return { inProgressAnnotation, updateAnnotation, cancelAnnotation }
}