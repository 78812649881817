import React, { Dispatch, MutableRefObject, SetStateAction, createContext, useContext, useMemo, useState } from "react"
import { SortableElement, SortableContainer, SortStartHandler } from "react-sortable-hoc"
import { EEGChannel } from "../../../../Types/EEG"
import { MobergColumn } from "../../../../../../../../Moberg"
import { ChannelListElement } from "./ChannelListElement"
import { Scrollbar } from "../../../../../../../../Constants/StyledComponents"

type SortableChannelsContextProps = {
	selectChannel: (channel: EEGChannel) => void
	updateAlias: (index: number, alias: string) => void
	selectedChannels: EEGChannel[]
	sortingChannelId: string | undefined
}

export const SortableChannelsContext = createContext<SortableChannelsContextProps>({} as any)

type SortEndEvent = {
	oldIndex: number
	newIndex: number
}

type SortableChannelProps = {
	channel: EEGChannel
	channelIndex: number
}

type SortableChannelsListProps = {
	channels: EEGChannel[]
}

type SortableChannelsProps = {
	selectedChannels: EEGChannel[]
	channels: EEGChannel[]
	containerRef: MutableRefObject<HTMLDivElement>
	setChannels: Dispatch<SetStateAction<EEGChannel[]>>
	setSelectedChannels: Dispatch<SetStateAction<EEGChannel[]>>
}

function getChannelId(channel: EEGChannel) {
	return `${channel.first}-${channel.second}`
}

export const SortableChannels = ({ channels, selectedChannels, containerRef, setChannels, setSelectedChannels }: SortableChannelsProps) => {
	const [sortingChannelId, setSortingChannelId] = useState<string>()

	const beforeSort: SortStartHandler = sort => {
		setSortingChannelId(getChannelId(channels[sort.index]))
	}

	const onSortEnd = ({ oldIndex, newIndex }: SortEndEvent) => {
		handleSortEnd(oldIndex, newIndex)
		setSortingChannelId("")
	}

	const handleSortEnd = (oldIndex: number, newIndex: number) => {
		setChannels(previous => {
			const newChannels = [...previous]
			const [element] = newChannels.splice(oldIndex, 1)
			newChannels.splice(newIndex, 0, element)

			return newChannels
		})
	}

	const channelContext = useMemo(() => {
		const updateAlias = (index: number, alias: string) => {
			if (index === undefined) {
				return
			}

			setChannels(previous => {
				const copy = [...previous]
				copy[index].alias = alias
				return copy
			})
		}

		const selectChannel = (channel: EEGChannel) => {
			if (selectedChannels.includes(channel)) {
				setSelectedChannels(previous => previous.filter(c => c !== channel))
			} else {
				setSelectedChannels(previous => [...previous, channel])
			}
		}

		return { selectChannel, updateAlias, selectedChannels, sortingChannelId }
	}, [selectedChannels, setChannels, setSelectedChannels, sortingChannelId])

	return (
		<SortableChannelsContext.Provider value={channelContext}>
			<SortableChannelList
				axis={"y"}
				helperContainer={containerRef.current}
				useDragHandle={true}
				pressDelay={0}
				channels={channels}
				updateBeforeSortStart={beforeSort}
				onSortEnd={onSortEnd}
			/>
		</SortableChannelsContext.Provider>
	)
}

const SortableChannel = SortableElement(({ channel, channelIndex }: SortableChannelProps) => {
	const { sortingChannelId } = useContext(SortableChannelsContext)

	return (
		<li style={{ listStyleType: "none", whiteSpace: "nowrap", margin: "0 8px", background: "#fff" }}>
			<ChannelListElement channel={channel} channelIndex={channelIndex} isActive={false} isDragging={sortingChannelId === getChannelId(channel)} />
		</li>
	)
})

const SortableChannelList = SortableContainer(({ channels }: SortableChannelsListProps) => {
	return (
		<Scrollbar style={{ height: "450px" }}>
			<MobergColumn>
				{channels.map((channel: EEGChannel, index: number) => (
					<SortableChannel key={`${channel.first}-${channel.second}`}
						index={index} // for sorting with React-Sortable-HOC
						channelIndex={index} // passed through as a prop for child components
						channel={channel}
					/>
				))}
			</MobergColumn>
		</Scrollbar>
	)
})
