import { MobergMenuController } from "../../Hooks/useMobergMenu"
import { EEGMontage, MontageType } from "../../Pages/Data/Visualize/DataReview/Types/EEG"
import {
	MobergButton,
	MobergButtonVariant,
	MobergColumn,
	MobergDropdown,
	MobergFontFamily,
	MobergFontSize,
	MobergInputLabel,
	MobergRow,
	MobergShortTextInput,
	MobergTheme,
} from "../../Moberg"
import React, { useRef, useState } from "react"
import { MobergText } from "../MobergHeader/MobergHeader"
import { useAsyncTask } from "../../Hooks/useAsyncTask"
import { useOnMount } from "../../Hooks/useOnMount"
import { useWorkspacesProvider } from "../../Providers/WorkspacesProvider"
import { useEEGMontageService } from "../../Hooks/useEEGMontageService"
import { useAuthProvider } from "../../Providers/AuthProvider"

type CopyEEGMontageProps = {
    menu: MobergMenuController
    eegMontage: EEGMontage
}

export const CopyEEGMontage: React.FC<CopyEEGMontageProps> = ({ menu, eegMontage }) => {
    const defaultName = eegMontage.name + " (copy)"
	const [name, setName] = useState(defaultName)
    const [destination, setDestination] = useState<MontageType>(MontageType.PERSONAL)
    const inputRef = useRef<HTMLInputElement>(null)
	const { createEEGMontage } = useEEGMontageService()
    const { selectedWorkspace } = useWorkspacesProvider()
	const authProvider = useAuthProvider()
    
    const copyEEGMontageTask = useAsyncTask(() => {
		const copy = { ...eegMontage }
		copy.name = name.trim() === "" ? defaultName : name
		copy.type = destination
		copy.userId = authProvider.currentUser?.id || ""
		return createEEGMontage(copy)
	})

    const submit = () => {
		if (copyEEGMontageTask.isWaiting) {
			return // don't allow submit while the task is running!
		}

		inputRef.current?.blur() // re-enable hotkeys if the input did not lose focus

		copyEEGMontageTask.run().then(() => {
			menu.close()
		})
	}

	useOnMount(() => inputRef.current?.focus())

    return (
		<MobergColumn gap="16px" horizontalAlign="start">
			<MobergText fontSize={MobergFontSize.X_LARGE} fontFamily={MobergFontFamily.HEADER} fontWeight="bold">
				Make a copy
			</MobergText>

			<MobergShortTextInput ref={inputRef} label={"Name"} limit={50} onChange={name => setName(name)} onSubmit={submit} defaultValue={defaultName} />

			<MobergRow>
				<MobergColumn gap="8px">
					<MobergInputLabel text={"Access"} />

					<MobergDropdown
						selectedValue={destination}
						onChange={newDestination => setDestination(newDestination)}
						options={[
							{ label: "Personal", value: MontageType.PERSONAL },
							{ label: `Shared (${selectedWorkspace})`, value: MontageType.SHARED },
						]}
					/>
				</MobergColumn>
			</MobergRow>

			<MobergRow horizontalAlign="right" gap="16px">
				{ copyEEGMontageTask.isWaiting && (
					<MobergText> Please wait... </MobergText>
				)}
				
				<MobergButton theme={MobergTheme.BLUE} variant={MobergButtonVariant.FILLED} onClick={submit} disabled={copyEEGMontageTask.isWaiting}>
					OK
				</MobergButton>
			</MobergRow>
		</MobergColumn>
	)
}