import { useState, useEffect, useRef, useCallback } from 'react';
import { BROWSE_PAGE, DEFAULTS, NAVBAR, REVIEW_MMREPORTS } from '../../../../Constants/Formatting';
import { HTMLInputField, HTMLCheckboxField, HTMLCheckboxFieldGrid, HTMLSectionTitle, InputField, HTMLDatetimeLocalField, HTMLDropdownField, HTMLNumberField, HTMLRadioField, HTMLRangeField, HTMLTimeField, HTMLTextAreaField, HTMLTableField} from "../../../../Constants/MMReportStructures/MMReportStructures";
import { LINKS } from '../../../../Constants/BackendLinks'
import _ from "lodash";

import styled from 'styled-components';
import * as MdIcons from "react-icons/md";

import React from 'react';
import ModifySubcomponentModal from './ModifySubcomponentModal';
import ComponentLibraryModal from './ComponentLibraryModal';
import CPPOpt from '../../../../Components/CPPOpt/CPPOpt';
import ScatterPlot from '../../../../Components/ScatterPlot/ScatterPlot'
import Histogram from '../../../../Components/Histogram/Histogram'
import { useReactToPrint } from 'react-to-print'
import Pagination from '../../../../Components/Pagination/Pagination'

// import { data, jsonData } from './../treeData'

import './CreateReports.css'
import 'antd/dist/antd.css';
import { Tree } from 'antd';
import { DownOutlined } from '@ant-design/icons'
import { useStudiesProvider } from '../../../../Providers/StudiesProvider';
import { useEndpointProvider } from '../../../../Providers/EndpointProvider';
const { TreeNode } = Tree

const CreateReports = ({props, name, id}) => {
    const endpointProvider = useEndpointProvider()

	const createComponentModalButtonRef = useRef()
	const modifyComponentModalButtonRef = useRef()
	const modifySubcomponentModalButtonRef = useRef()
	const componentLibraryModalButtonRef = useRef()
	const [refreshComponent, setRefreshComponent] = useState([])
	const [selectedComponent1, setSelectedComponent1] = useState([])
	const [currentPage, setCurrentPage] = useState(1)
	const [selectedElementKey, setSelectedElementKey] = useState()
	const [sComponent, setSComponent] = useState()
	const [sSComponent, setSSComponent] = useState()
	const [pageMappings, setPageMappings] = useState([])

	// API DEFINITION

    function createComponent(component) {
        let body = {
            component: component
        }
        
        return endpointProvider.post(LINKS.REPORTING.MULTIMODAL_REPORTS.REPORT_TEMPLATES.EXPORT_REPORT_TEMPLATES, body)
    }

    function modifyComponent(component) {
        let body = {
            component: component
        }
        
        return endpointProvider.post(LINKS.REPORTING.MULTIMODAL_REPORTS.COMPONENTS.MODIFY_COMPONENT, body)
    }

    const getComponents = useCallback(async (report_template_name) => {
        let body = {
            report_template_name: report_template_name
        }
        
        return endpointProvider.post(LINKS.REPORTING.MULTIMODAL_REPORTS.COMPONENTS.GET_COMPONENTS, body)
    }, [])

    
    function deleteComponent(component_id) {
        let body = {
            component_id: component_id
        }
        
        return endpointProvider.post(LINKS.REPORTING.MULTIMODAL_REPORTS.COMPONENTS.DELETE_COMPONENT, body)
    }

    function modifySubcomponent(subcomponent) {
        let body = {
            subcomponent: subcomponent
        }
        
        return endpointProvider.post(LINKS.REPORTING.MULTIMODAL_REPORTS.SUBCOMPONENTS.MODIFY_SUBCOMPONENT, body)
    }

    function createSubcomponent(subcomponent) {
        let body = {
            subcomponent: subcomponent
        }
        
        return endpointProvider.post(LINKS.REPORTING.MULTIMODAL_REPORTS.SUBCOMPONENTS.CREATE_SUBCOMPONENT, body)
    }

    function deleteSubcomponent(subcomponent_id) {
        let body = {
            subcomponent_id: subcomponent_id
        }
        
        return endpointProvider.post(LINKS.REPORTING.MULTIMODAL_REPORTS.SUBCOMPONENTS.DELETE_SUBCOMPONENT, body)
    }

	// REPORTS HIERARCHY FUNCTIONALITY STARTS HERE
	///////////////////////////////////////////////////////////////////////////////////////////////////////

	const [selectedComponentLength, setSelectedComponentLength] = useState(0)
	const [selectedComponentKey, setSelectedComponentKey] = useState()
	const [selectedComponent, setSelectedComponent] = useState()
    const expandedKeys = useState(['0-0', '0-5'])
    const [gData, setGData] = useState([])
    const [fixedGData, setFixedGData] = useState([])
	const [JSXElements, setJSXElements] = useState([])

	const sortObject = function(ObjectList, pos) {
		ObjectList.sort((a,b) => (parseInt(a.key.split('-').at(pos)) > parseInt(b.key.split('-').at(pos))) ? 1 : ((parseInt(b.key.split('-').at(pos)) > parseInt(a.key.split('-').at(pos))) ? -1 : 0))
		return ObjectList
	}

	// useEffect(() => {
	// 	getComponents(name).then((data) => {
	// 		data = sortObject(data, 0)
	// 		data.forEach(component => {
	// 			component.children = sortObject(component.children, -1)
	// 		})
	// 		setGData(data)
	// 		setFixedGData(JSON.parse(JSON.stringify(data)))
	// 	}).catch(e => {
	// 		alert(e)
	// 	})
		
	// }, [getComponents, name, refreshComponent])

    const onDragEnter = (info) => {
        console.log(info)
    }
    
    const onDrop = (info) => {
        const dropKey = info.node.key;
        const dragKey = info.dragNode.key;
        const dropPos = info.node.pos.split('-')
        const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1])

        const loop = (data, key, callback) => {
            data.forEach((item, index, arr) => {
                if (item.key === key) {
                    callback(item, index, arr)
                    return;
                }
                if (item.children) {
                    loop(item.children, key, callback)
                }
            })
        }
        
        const data = [...gData] //find dragObject

        let dragObj;
        loop(data, dragKey, (item, index, arr) => {
            arr.splice(index, 1)
            dragObj = item
        });

        if (dropPosition === 0) {
            // loop(data, dropKey, item => {
            //     // eslint-disable-next-line no-param-reassign
            //     item.children = item.children || [];
            //     item.children.unshift(dragObj)
            // });
			// alert("Error: Subcomponents can only be placed inside components.")
			return;
        } else {
            let ar;
            let i;
            loop(data, dropKey, (item, index, arr) => {
                ar = arr;
                i = index;
            });
            if (dropPosition === -1) {
                ar.splice(i, 0, dragObj)
            } else {
                ar.splice(i + 1, 0, dragObj)
            }
        }
        setGData(data)
    }

    function renderRecursiveTreeStructure(data) {
        return Object.entries(data).map(([elem, value]) => {
            const string = String(value.key)

            if (!value.children) {
                return(
					<TreeNode id={string} title={value.title} key={value.key} style={{background: "#FFFFFF", whiteSpace: "nowrap", height: "29px", cursor: "pointer", fontFamily: "Source Sans Pro", fontStyle: "normal", fontWeight: "600", fontSize: "16px", color: "#5A6679"}}/>
                )
            } else {
                let childComponents = Object.values(value.children)
                return( 
					<TreeNode id={string} title={value.title} key={value.key} style={{background: "#FFFFFF", whiteSpace: "nowrap", height: "29px", cursor: "pointer", fontFamily: "Source Sans Pro", fontStyle: "normal", fontWeight: "600", fontSize: "16px", color: "#5A6679"}}>
                            {renderRecursiveTreeStructure(childComponents)}
                    </TreeNode>
                )
            }
        })
    }


	const [isSubcomponent, setIsSubcomponent] = useState(false)
	const hierarchySettingsDivRef = useRef()
	const onSelect = (component) => {
		setSelectedComponentLength(component.length)
		setSelectedComponentKey(component[0])
		setSelectedComponent(component[0])
		setSelectedElementKey(component[0])
		

		if (component[0]) {
			if (parseInt(component[0].split("-")[1]) === 0) {
				setIsSubcomponent(false)
			} else {
				setIsSubcomponent(true)
			}
		}

		if (hierarchySettingsDivRef.current) {
			if ((hierarchySettingsDivRef.current.style.display !== "none") && (component.length === 0)) {
				document.getElementById("hierarchySettingsDiv").style.display = "none"
				hierarchySettingsDivRef.current.style.display = "none"
			}
			else {
				hierarchySettingsDivRef.current.style.left =`${x}px`
				hierarchySettingsDivRef.current.style.top = `${y - 145}px`
				hierarchySettingsDivRef.current.style.display = "block"
			}
		}
    }

	const [x, setX] = useState(250)
    const [y, setY] = useState(0)

	document.addEventListener('click', printMousePos, true);
	function printMousePos(e) {
		setY(e.pageY)
		const cursorY= e.pageY;
	}

	// TOOLBAR BUTTON HANDLERS

	function handleDeleteComponent(data, componentKey) {
		if (data.length === 0) return
		if (!componentKey) return
		const copyData = JSON.parse(JSON.stringify(data))
		const primaryKey = parseInt(componentKey.split('-').at(0))
		const secondaryKey = parseInt(componentKey.split('-').at(-1))
		secondaryKey === 0 ? copyData.splice(primaryKey, 1) : copyData[primaryKey].children.splice(secondaryKey -1, 1)
		updateKey(copyData)
		setGData(copyData)
	}

	function addNewComponent() {
		// let newGData = Array.from(gData)
		const newGData = JSON.parse(JSON.stringify(gData))
		let highestKey = -1
		Object.entries(newGData).map(([key, value]) => {
			highestKey = Math.max(parseInt(value['key'].split("-")[0],10), highestKey)
		})
		let newKey = `${highestKey +1}-0`
		let newComponentData = {"key": newKey, "title": `New Component ${highestKey +1}`, "input_type": "HTMLSectionTitle", "children": [], "category": "Category", "height": 32.42}
		newGData.push(newComponentData)
		setGData(newGData)
	}

	function addNewSubcomponent(componentKey) {
		const newGData = JSON.parse(JSON.stringify(gData))
		const selectedComponent = newGData.find(({key}) => key === componentKey)
		const newSecondaryKey = selectedComponent.children.length +1
		const newSubcomponentKey = `${componentKey.at(0)}-${newSecondaryKey}`
		const newSubcomponent = {"category": "HTMLInputField", "description": "", "height": 39.39,  "input_type": "HTMLInputField", "key": newSubcomponentKey, "option": {"answer": {"x": " "}}, "page_break": false, "title": "New subcomponent"}
		selectedComponent.children.push(newSubcomponent)
		setGData(newGData)
	}

	async function deleteComponentOrSubcomponent(deleteType, deleteIdSet) {
		let promiseList = []
		deleteIdSet.delete(undefined)
		deleteIdSet.forEach(id => {
			promiseList.push(deleteType === 'component' ? deleteComponent(id).catch(e => {alert(e)}) : deleteSubcomponent(id).catch(e => {alert(e)}))
		})
		await Promise.all(promiseList)
	}

	function insertCopyComponent(componentList, selectedKey) {
		const copySelectedComponent = JSON.parse(JSON.stringify(componentList[selectedKey]))
		delete copySelectedComponent.id
		copySelectedComponent.children.forEach(subcomponent => {
			delete subcomponent.id
			copySelectedComponent.id || delete subcomponent._component_id
		})
		componentList.push(copySelectedComponent)
	}

	function insertCopySubcomponent(componentList, selectedPrimaryKey, selectedSecondaryKey) {
		const copySelectedComponent = componentList[selectedPrimaryKey]
		const copySelectedSubcomponent = JSON.parse(JSON.stringify(copySelectedComponent.children[selectedSecondaryKey -1]))
		delete copySelectedSubcomponent.id
		copySelectedComponent.id || delete copySelectedSubcomponent._component_id
		copySelectedComponent.children.push(copySelectedSubcomponent)
	}

	function copyComponent() {
		if (selectedComponentKey === undefined) return
		const copyGData = JSON.parse(JSON.stringify(gData))

		const selectedPrimaryKey = parseInt(selectedComponentKey.at(0))
		const selectedSecondaryKey = parseInt(selectedComponentKey.at(-1))

		selectedSecondaryKey === 0 ? insertCopyComponent(copyGData, selectedPrimaryKey) : insertCopySubcomponent(copyGData, selectedPrimaryKey, selectedSecondaryKey)

		updateKey(copyGData)
		setGData(copyGData)
	}

	function updateKey(data) {
		data.forEach((val, key) => {
			let secondaryKey = 0
			// update key
			val['key'] = `${key}-${secondaryKey}`

			// add report_template_id
			if (!("_report_template_id" in val)) {
				val["_report_template_id"] = id;
			}

			// process subcomponent
			if (val.children.length > 0) {
				const subcomponents = [...val.children]
				subcomponents.forEach((element, index) => {
					element['key'] = `${key}-${index +1}`
				})
			}
		})
	}

	function populateComponentIdsAndSubcomponentIds(componentList, componentIdSet, subcomponentIdSet) {
		componentList.forEach(component => {
			componentIdSet.add(component.id)
			component.children.forEach(subcomponent => {
				subcomponentIdSet.add(subcomponent.id)
			})
		})
	}

	function populateDeleteComponentIdsAndDeleteSubcomponentIds(componentList, componentIdSet, subcomponentIdSet, deleteComponentIdSet, deleteSubcomponentIdSet) {
		componentList.forEach(component => {
			if (componentIdSet.has(component.id)) {
				component.children.forEach(subcomponent => {
					if (!(subcomponentIdSet.has(subcomponent.id))) {
						deleteSubcomponentIdSet.add(subcomponent.id)
					}
				})
			} else {
				deleteComponentIdSet.add(component.id)
			}
		})
	}

	function handleCreateAndModifySubcomponent(parentComponent, promiseList) {
		const parentComponentId = parentComponent.id
		parentComponent.children.forEach(subcomponent => {
			subcomponent._component_id = parentComponentId
			promiseList.push("id" in subcomponent ? modifySubcomponent(subcomponent).catch(e => {alert(e)}) : createSubcomponent(subcomponent).catch(e => {alert(e)}))
		})
	}

	function handleModifyComponent(component, promiseList) {
		promiseList.push(modifyComponent(component).catch(e => {alert(e)}))
		handleCreateAndModifySubcomponent(component, promiseList)
	}

	function handleCreateComponent(component, promiseList) {
		promiseList.push(createComponent(component).catch(e => {alert(e)}))
	}

	async function handleCreateAndModifyComponent(componentList) {
		const promises = []
		componentList.forEach(component => {
			"id" in component ? handleModifyComponent(component, promises) : handleCreateComponent(component, promises)
		})
		await Promise.all(promises)
	}

	async function handleSave() {
		const copyGData = JSON.parse(JSON.stringify(gData))
		updateKey(copyGData)
		const copyFixedGData = JSON.parse(JSON.stringify(fixedGData))
		handleAddPageBreak(copyGData)
		
		const componentIds = new Set()
		const subcomponentIds = new Set()
		populateComponentIdsAndSubcomponentIds(copyGData, componentIds, subcomponentIds)

		const deleteComponentIds = new Set()
		const deleteSubcomponentIds = new Set()
		populateDeleteComponentIdsAndDeleteSubcomponentIds(copyFixedGData, componentIds, subcomponentIds, deleteComponentIds, deleteSubcomponentIds)

		let promises = [
			deleteComponentOrSubcomponent('subcomponent', deleteSubcomponentIds), 
			deleteComponentOrSubcomponent('component', deleteComponentIds),
			handleCreateAndModifyComponent(copyGData)
		]

		await Promise.all(promises)

		setGData(copyGData)
		setRefreshComponent([])
	}

	// RIGHT-SIDE RENDER FUNCTIONS
	function renderComponentHTML(input_type, title=null, option={}, modality=null) {
		switch(input_type) {
			case 'HTMLInputField':
				return <HTMLInputField question={title} answer={option.answer}/>
			case 'HTMLCheckboxField':
				return <HTMLCheckboxField question={title} answer={option}/>
			case 'HTMLCheckboxFieldGrid':
				return <HTMLCheckboxFieldGrid grid_dictionary={option.answer}/>
			case 'HTMLPlot':
				return <CPPOpt/>
			case 'HTMLScatterPlot':
				return <ScatterPlot/>
			case 'HTMLHistogramPlot':
				return <Histogram/>
			case 'HTMLDatetimeLocalField':
				return <HTMLDatetimeLocalField question={title} answer={option}/>
			case 'HTMLDropdownField':
				return <HTMLDropdownField question={title} answer={option}/>
			case 'HTMLNumberField':
				return <HTMLNumberField question={title} answer={option}/>
			case 'HTMLRadioField':
				return <HTMLRadioField question={title} answer={option}/>
			case 'HTMLRangeField':
				return <HTMLRangeField question={title} answer={option}/>
			case 'HTMLTimeField':
				return <HTMLTimeField question={title} answer={option.answer}/>
			case 'HTMLTextAreaField':
				return <HTMLTextAreaField question={title}/>
			case 'HTMLTableField':
				return <HTMLTableField question={title} answer={option}/>
			default:
		}
	}

	// function renderReportSubcomponent(input_type, title, option, key) {
	// 	let className = "subcomponent"
	// 	if (title === "Checkbox Grid") className = "subcomponent grid"
	// 	else if (title === "Observations") className = "subcomponent observations"
	// 	else if (title === "Correlation") className = "subcomponent correlation"
	// 	return (<div id={title} className={className} key={key}>
	// 		{renderComponentHTML(input_type, title, option)}
	// 	</div>)
	// }

	// function renderReportComponent(componentObj) {
	// 	const componentName = componentObj.title.replaceAll("_", " ")
	// 	let subcomponents = JSON.parse(JSON.stringify(componentObj.children))
	
	// 	return (<div id={componentObj.title} key={componentObj.key}><HTMLSectionTitle title={componentName}/>{subcomponents.map((subcomponentObj) => renderReportSubcomponent(subcomponentObj.input_type, subcomponentObj.title, subcomponentObj.option, subcomponentObj.key)
	// 	)}</div>)
	// }

	function handleAddPageBreak(data) {
		const fixHeight = 550
		let currentHeight = 140
		data.forEach(component => {
			if (component.height < fixHeight - currentHeight) {
				currentHeight += component.height
				component['page_break'] = false
			} else {
				currentHeight = component.height
				component['page_break'] = true
			}

			if (component.children) {
				component.children.forEach(subcomponent => {
					if (subcomponent.height < fixHeight - currentHeight) {
						currentHeight += subcomponent.height
						subcomponent['page_break'] = false
					} else {
						currentHeight = subcomponent.height
						subcomponent['page_break'] = true
					}
				})
			}
		})
	}

	// useEffect(() => {
	// 	handleAddPageBreak(gData)
	// }, [gData, handleAddPageBreak])

	useEffect(() => {
		if (!selectedElementKey) return
		const copyGData = JSON.parse(JSON.stringify(gData))
		const primaryKey = parseInt(selectedElementKey.split('-').at(0))
		const secondaryKey = parseInt(selectedElementKey.split('-').at(-1))
		secondaryKey === 0 ? setSComponent(copyGData.find(({key}) => key === selectedElementKey)) : setSSComponent(copyGData[primaryKey].children.find(({key}) => key === selectedElementKey))
	}, [selectedElementKey])

	// function renderReport() {
	// 	// const componentObjs = JSON.parse(JSON.stringify(gData))
	// 	return (<>
	// 		<div id={name}>
	// 			<h1 style={{marginBottom: "0.5in", borderBottom: "1px solid #000"}}>{name}</h1>
    //             <HTMLInputField question={"Record ID"} answer={{x: ''}} />
	// 			{/* {componentObjs.map((componentObj) => renderReportComponent(componentObj))} */}
	// 			{pdfReport}
	// 		</div>
	// 	</>)
	// }

	const getPageStyle = () => {

		return `
			@page {
				size: 130mm 115mm;
			}

			@media print {
				.pagebreak {
					page-break-before: always;
				}
			}
		`}

	const returnMappings = (data) => {
		let currentPage = 1
		const mappingObj = {}
		data.forEach(component => {
			if (component.page_break) currentPage += 1
			mappingObj[component.key] = currentPage
			component.children.forEach(subcomponent => {
				if (subcomponent.page_break) currentPage += 1
				mappingObj[subcomponent.key] = currentPage
			})
		})
		return mappingObj
	}

	const returnJSXElements = (data) => {
		const elementArr = [
			[
				<h1 style={{marginBottom: "0.5in", borderBottom: "1px solid #000"}}>{name}</h1>, 
				<HTMLInputField question={"Record ID"} answer={{x: ''}} />
			]
		]

		data.forEach(component => {
			const HTMLComponent = <HTMLSectionTitle title={component.title}/>
			component.page_break ? elementArr.push([<div className="pagebreak" />, HTMLComponent]) : elementArr.at(-1).push(HTMLComponent)
			component.children.forEach(subcomponent => {
				const HTMLSubcomponent = renderComponentHTML(subcomponent.input_type, subcomponent.title, subcomponent.option)
				subcomponent.page_break ? elementArr.push([<div className="pagebreak" />, HTMLSubcomponent]) : elementArr.at(-1).push(HTMLSubcomponent)
			})
		})

		return elementArr
	}

	

	useEffect(() => {
		// setCopyGData(gData)
		const deepCopyGData = JSON.parse(JSON.stringify(gData))

		updateKey(deepCopyGData)
		handleAddPageBreak(deepCopyGData)
		setJSXElements(returnJSXElements(deepCopyGData))
		setPageMappings(returnMappings(deepCopyGData))
	}, [gData])

	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	function areTwoObjectListsSame(list1, list2) {
		return JSON.stringify(list1) === JSON.stringify(list2)
	}

	const [isActive, setIsActive] = useState(false);
	const [isSelected, setIsSelected] = useState(false);

	useEffect(() => {
		const areTwoListsSame = areTwoObjectListsSame(gData, fixedGData)
		areTwoListsSame ? setIsActive(false) : setIsActive(true)
		areTwoListsSame && setSelectedComponentKey(undefined)
	}, [gData, fixedGData])

	useEffect(() => {
		selectedComponentKey === undefined ? setIsSelected(false) : setIsSelected(true)
	})	

	function getSelectedComponent(components, selectedComponentKey) {
		const selectedComponent = components.find(({key}) => key === selectedComponentKey)
		const copySelectedComponent = selectedComponent ? JSON.parse(JSON.stringify(selectedComponent)) : {}
		return copySelectedComponent
	}

	useEffect(() => {
		const component = getSelectedComponent(gData, selectedComponentKey)
		setSelectedComponent1(component)
	}, [gData, selectedComponentKey])

	useEffect(() => {
		if (selectedElementKey === undefined) {
			setCurrentPage(1)
		} else {
			setCurrentPage(pageMappings[selectedElementKey])
		}

	}, [selectedElementKey])


	const currentComponents = JSXElements.at(currentPage -1)

	const updateGData = newData => {
		setGData(newData)
	}

	const getPageMargins = () => {
		return `@page { margin: 10px 10px 10px 10px !important; }`;
	  };
	const componentRef = useRef();
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
		documentTitle: 'test-export-subcomponent'
	})

	const reportingToolbar = (
		<ReportingToolbar style={{width: `${(window.innerWidth / 2) - 70}px`}}>
			<button 
				onClick={() => {addNewComponent()}}
				style={{width: "fit-content", border: "none", background: "none", marginTop: "-1px"}}>
				<MdIcons.MdAdd size={20} style={{marginRight: "4px", cursor: "pointer"}}/>
			</button>
			<button 
			ref={componentLibraryModalButtonRef}
				style={{width: "fit-content", border: "none", background: "none", marginTop: "-1px"}}>
				<MdIcons.MdFolderOpen size={20} style={{marginRight: "4px", cursor: "pointer"}}/>
			</button>
			<MdIcons.MdDelete 
				id="createReportDeleteButton" 
				onClick={() => handleDeleteComponent(gData, selectedComponentKey)} 
				size={20} 
				disabled={!isSelected}
				style={{
					marginRight: "4px", 
					cursor: isSelected ? "pointer" : "default",
					color: isSelected ? "#000" : "#B6B6B6"
				}}
			/>
			<MdIcons.MdContentCopy 
				size={20} 
				disabled={!isSelected}
				style={{
					marginRight: "8px", 
					cursor: isSelected ? "pointer" : "default",
					color: isSelected ? "#000" : "#B6B6B6"
				}} 
				onClick={() => {copyComponent()}}/>
		</ReportingToolbar>
	)

	const scrollbar = (
		<Scrollbar style={{display: "block", padding: "15px 0px", maxHeight: "700px", overflowY: "scroll"}}>
			<div style={{position: "relative"}}>
				<Tree 
					className="draggable-tree"
					defaultExpandedKeys={expandedKeys}
					defaultExpandParent={true}
					draggable
					onDragEnter={onDragEnter}
					onDrop={onDrop}
					showLine
					// treeData = {gData}
					showIcon={{
						showLeafIcon: false,
					}}
					switcherIcon={
						<div style={{height: "21px", width: "130%", borderRadius: "6px", border: "1px solid #000000",  borderRight: "6px solid transparent", background: "#FFFFFF"}}>
							<DownOutlined/>
						</div>
					}
					onSelect={onSelect}
					style={{height: "auto", padding: "15px 15px"}}>
					{renderRecursiveTreeStructure(gData)}
				</Tree>

				{/* <SettingsDiv id="hierarchySettingsDiv" ref={hierarchySettingsDivRef} style={{display: "block"}}>
					Settings
					<div>
						<button 
						ref={modifySubcomponentModalButtonRef}
						>Click here to modify</button>
					</div>
				</SettingsDiv> */}
				<SettingsDiv id="hierarchySettingsDiv" ref={hierarchySettingsDivRef} style={{display: "none"}}>
							Settings
							{isSubcomponent ? 
							<div>
								<SettingsDivButton 
								ref={modifySubcomponentModalButtonRef}
								>Modify Subcomponent</SettingsDivButton>
							</div>
							:
							<div>
								<SettingsDivButton 
									onClick={() => {addNewSubcomponent(selectedComponentKey)}}
									>Add Subcomponent</SettingsDivButton>
							</div>
							}
						</SettingsDiv>
			</div>

			<ModifySubcomponentModal openRef={modifySubcomponentModalButtonRef} props={props} gData={gData} updateGData={updateGData} selectedSubcomponent={sSComponent}/>
			<ComponentLibraryModal openRef={componentLibraryModalButtonRef} selectedComponent={sComponent}/>
		</Scrollbar>
	)

	const leftSideContent = (
		<div style={{
			backgroundColor: DEFAULTS.SECONDARY_BOX_BACKGROUND,
			// width: "18rem",
			width: `${(window.innerWidth / 2)}px`, height: "auto"}}>
			<div style={{display: "block", width: `${(window.innerWidth / 2)}px`, paddingTop: "10px"}}>

				<div style={{display: "inline-flex"}}>
					{reportingToolbar}

					<SaveButton 
						id="saveReportButton" 
						disabled={!isActive} 
						onClick={async () => {await handleSave()}}
						style={{
							color: isActive ? '#ffffff' : '#293241', 
							background: isActive ? '#207dea' : '#f4f6f8',
							border: isActive ? "2px solid #207dea" : "none"
						}}
						>
							Save
					</SaveButton>
				</div>

				{scrollbar}
			</div>
		</div>
	)

	const rightSideContent = (
		<div id={"report-viewer"} style={{minHeight: "11in", paddingTop: "0.3in", paddingBottom: "0.5in", paddingLeft: "0.5in", paddingRight: "0.5in", backgroundColor: "#FFF", fontFamily: "Times New Roman", display: "block", width: `${(window.innerWidth / 2)}px`}}>
			<button onClick={handlePrint}>Print this out</button>
			{currentComponents}
			<div style={{ display: "none" }}>
				<div ref={componentRef}>
					<style>{getPageStyle()}</style>
					<style>{getPageMargins()}</style>
					{JSXElements}
					
				</div>
			</div>
			<Pagination pages={JSXElements} change_state={paginate} state_parameter={undefined}/>
		</div>
	)

	const pageContent = (
		<div style={{
			display: "flex", 
			justifyContent: "space-around", 
			// marginTop: "1%", 
			// marginBottom: "1%"
		}}>
			{leftSideContent}			
			{rightSideContent}
		</div>
	)

	return pageContent
}

const SettingsDiv = styled.div`	
	width: 265px;	
	background: #ffffff;	
	border: 3px solid #207DEA;	
	padding: 10px;	
	text-align: center;	
	font-weight: 600;	
	font-size: 16px;	
	color: #000000;	
	font-family: 'Source Sans Pro';	
	position: absolute;	
	height: auto;	
	:before {	
		position: absolute;	
		content: "";	
		width: 0px;	
		height: 0px;	
		border-left: 10px solid transparent;	
		border-right: 10px solid #207DEA;	
		border-top: 10px solid #207DEA;	
		border-bottom: 10px solid transparent;	
		left: -21px;	
		top: 0px;	
	}`;

const Scrollbar = styled.div`
		scrollbar-width: thin;
		::-webkit-scrollbar {
  			display: block;
			width: 5px;
			color: #313A4A;
		}
		::-webkit-scrollbar-track {
			background: #BEC4CF;
			width: 5px;
			border-radius: 2px;
		}
`;

const ReportingToolbar = styled.div`
	position: sticky;
	height: 31px;
	background: #FFFFFF;
	border-radius: 4px;
	display: inline-flex;
	// display: block;
	padding: 6px 8px;
	margin-left: 10px;
	margin-right: 10px;
	// bottom: 0px;
	input {
		background: none;
		border: 0.5px solid #B6B6B6;
		border-radius: 4px;
		color: #000000;
		width: ${(window.innerWidth / 2) - 144}px;
		margin-right: 4px;
		height: 26px;
		margin-top: -3px;
		padding-left: 8px;
		padding-right: 8px;
		cursor: text;
	}
`;

const SaveButton = styled.button`
	width: 55px;
	height: 34px;
	background: #207dea;
	border-radius: 6px;
	font-family: 'Source Sans Pro';
	font-style: normal;
	font-weight: 600;
	line-height: 150%;
	color: #FFFFFF;
	border: none;
	padding: 4px 8px;
	margin-right: 8px;
	white-space: nowrap;
	flex: 0.25;
`;

const SettingsDivButton = styled.button`
	width: fit-content;
	height: 34px;
	background: #207dea;
	border-radius: 6px;
	font-family: 'Source Sans Pro';
	font-style: normal;
	font-weight: 600;
	line-height: 150%;
	color: #FFFFFF;
	border: none;
	padding: 4px 8px;
	margin-right: 8px;
	white-space: nowrap;
	flex: 0.25;
`;


export default CreateReports;

