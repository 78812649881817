import styled from "styled-components";
import * as MdIcons from "react-icons/md";

function TrashButton({ onClickFxn, disabled = false, buttonStyle = {} }) {
    return (<>
        <TrashButtonContainer
            onClick={onClickFxn ? onClickFxn : null}
            disabled={disabled}
            style={{ ...buttonStyle }}
        >
            <MdIcons.MdDelete size={20} />
        </TrashButtonContainer>
    </>)
}

export const TrashButtonContainer = styled.button`
    width: 34px;
    height: 34px;
    background: #e64e58;
    border-radius: 6px;
    border: none;
    color: #FFFFFF;
    opacity: ${(props) => props.disabled ? "0.3" : "1"};
    pointerEvents: ${(props) => props.disabled ? "none" : "all"};
    cursor: ${(props) => props.disabled ? "default" : "pointer"};
    &:not(:disabled):hover {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.30) 0%, rgba(0, 0, 0, 0.30) 100%), #e64e58
    }
`;

export default TrashButton;