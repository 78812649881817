import { Selection, EnterElement } from "d3"
import { HistogramReactCallbacks } from "../../../../Types/ReactCallbacks"
import { D3OneToManyRenderable } from "../../../D3/D3OneToManyRenderable"
import { ANIMATION_TIME } from "./D3HistogramRenderer"

export type D3HistogramBarGroupConfig = {
	binKey: string
	x: number
	bars: D3HistogramBarConfig[]
	boundingBoxHeight: number,
	clipPathId: string
}

export type D3HistogramBarConfig = {
	dataKey: string
	color: string
	x: number
	width: number
	height: number
	maxHeight: number
}

export class D3HistogramBarGroup extends D3OneToManyRenderable<SVGRectElement, D3HistogramBarGroupConfig, D3HistogramBarConfig, HistogramReactCallbacks> {

	constructor(root: SVGGElement, config: D3HistogramBarGroupConfig, reactCallbacks: HistogramReactCallbacks) {
		super(root, config, "d3-histogram-bar-group", reactCallbacks)
		this.mount()
	}

	protected datumIdentifier(datum: D3HistogramBarConfig): string | number {
		return datum.dataKey
	}

	protected getConfigs(): D3HistogramBarConfig[] {
		return this.config.bars
	}

	protected enter(newElements: Selection<EnterElement, D3HistogramBarConfig, SVGGElement, any>): Selection<SVGRectElement, D3HistogramBarConfig, SVGGElement, any> {
		const bars = newElements
			.append("rect")
			.attr("class", this.className)
			.attr("transform", config => `translate(${config.x}, ${this.config.boundingBoxHeight - config.height})`)
			.attr("fill", config => config.color)
			.attr("height", config => config.height === 0 ? 0 : config.maxHeight)
			.attr("width", config => config.width)

		return bars
	}

	protected update(updatedElements: Selection<SVGRectElement, D3HistogramBarConfig, SVGGElement, any>): Selection<SVGRectElement, D3HistogramBarConfig, SVGGElement, any> {
		const bars = updatedElements
			.attr("fill", config => config.color)
			.attr("width", config => config.width)

		bars.transition()
			.duration(ANIMATION_TIME)
			.attr("transform", config => `translate(${config.x}, ${this.config.boundingBoxHeight - Math.min(config.maxHeight, config.height)})`)
			.attr("height", config => config.height === 0 ? 0 : config.maxHeight) // locks bars to the bottom of the screen when screen height increases.

		return bars
	}
}
