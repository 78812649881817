import React, { useEffect } from 'react';
import { Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Scatter, ComposedChart, Bar, Cell } from 'recharts';
import Label from '../Label/Label'
import { DEFAULTS } from '../../Constants/Formatting'
// import Timeline from "../../Timeline/Timeline";
import * as d3 from 'd3'


function Histogram ({subcomponentKey, id, start_time, end_time}) {

    useEffect(() => {
        d3.selectAll(".visualizations.group." + id).remove()
        let visDiv = document.getElementById("Timeline_" + id)
        let timeline_preview_svg = d3.select(visDiv).append("svg")
            .attr("class", "visualizations group " + id)
            .attr("width", "100%")
            .attr("height", "50px")
            .attr("display", "block")
            .style("order", 9999)
        // var timeline_svg = new Timeline(timeline_preview_svg, 500, 0, 2000, start_time, end_time)
    }, [])


    let data = []

    const colors = ["#1f77b4", "#ff7f0e"]
    const group_names = ['< 15', '>= 15']

    let modality_label = 'Intracranial Pressure'

    let median = 11.01
    let stdev = 3.692
    // let dose = 0.0

    // Time in Condition Range
    // let ticr = 0.3472

    for (let i=0; i<=25; i++) {
        data.push({
            'valuex': i,
            'frequency': 8 * Math.random() + 5,
            'group': i < 15 ? 0 : 1
        })
    }

    let groups = data.map(value => {return value['group']})

    // return (<div style={{backgroundColor: "white", padding: 40, justifyContent: 'center', alignItems: 'center', textAlign: 'center', display: 'flex'}}>
    //   {/* <ResponsiveContainer width={'99%'} height={"100%"}> */}
    //     <div>
    //         <ComposedChart 
    //             width={500}
    //             height={500}
    //             data={data}
    //             margin={{
    //                 top: 20,
    //                 right: 100,
    //                 left: 100,
    //                 bottom: 50,
    //             }}
    //         >
    //             <CartesianGrid strokeDasharray="3 3" />
                
    //             <XAxis label={{ value: modality_label, angle: 0, position: 'bottom', offset: 30 }} type="number" dataKey="valuex" name="valuex" unit="mmHg" domain={[0, 25]} ticks={[0, 5, 10, 15, 20, 25]} />
    //             <YAxis label={{ value: 'Percentage of Total Time', angle: -90, position: 'left', offset: 20, style: { textAnchor: 'middle' }}} type="number" dataKey="frequency" name="frequency" unit="%" domain={[0, 15]} ticks={[0, 5, 10, 15]} />

    //             <Tooltip />
    //             <Legend payload={ Array.from(new Set(groups)).map((group, index) => {return({value: group_names[index], type: 'square', id: 'duration', color: colors[index]})})}  />

    //             <Bar dataKey="frequency" fill="#8884d8" >
    //                 {
    //                     groups.map((entry, index) => (
    //                         <Cell key={`cell-${index}`} fill={colors[entry]} />
    //                     ))
    //                 }
    //             </Bar>
    //         </ComposedChart>
    //         {/* <Label size={3} text={"TICR " + ticr + '%'}/>
    //         <Label size={3} text={"Dose " + dose}/> */}
    //         <Label size={3} text={"Median " + median}/>
    //         <Label size={3} text={"Std Dev " + stdev}/>
    //         <div id={"Timeline_" + id} className="noselect" style={{
    //             marginTop: DEFAULTS.PADDING,
    //         }}></div>
    //     </div>
        
    //     {/* </ResponsiveContainer> */}
    // </div>
    // )

    return (
        <div id={subcomponentKey} style={{borderBottom: "1px solid #000", fontSize: "12", marginTop: "0.1in"}}>
            <p>Histogram</p>
            <div style={{ marginBottom: "0.15in"}}>
                <div>
                    <div style={{display: "inline-block"}}>
                        <ComposedChart 
                            width={500}
                            height={500}
                            data={data}
                            margin={{
                                top: 20,
                                // right: 100,
                                right: 20,
                                // left: 100,
                                left: 40,
                                bottom: 50,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            
                            <XAxis label={{ value: modality_label, angle: 0, position: 'bottom', offset: 30 }} type="number" dataKey="valuex" name="valuex" unit="mmHg" domain={[0, 25]} ticks={[0, 5, 10, 15, 20, 25]} />
                            <YAxis label={{ value: 'Percentage of Total Time', angle: -90, position: 'left', offset: 20, style: { textAnchor: 'middle' }}} type="number" dataKey="frequency" name="frequency" unit="%" domain={[0, 15]} ticks={[0, 5, 10, 15]} />

                            <Tooltip />
                            <Legend payload={ Array.from(new Set(groups)).map((group, index) => {return({value: group_names[index], type: 'square', id: 'duration', color: colors[index]})})}  />

                            <Bar dataKey="frequency" fill="#8884d8" >
                                {
                                    groups.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={colors[entry]} />
                                    ))
                                }
                            </Bar>
                        </ComposedChart>
                    </div>
                    <div style={{display: "inline-block", width: "150px", textAlign: "right"}}>
                        <Label size={5} text={"Median " + median}/>
                        <Label size={5} text={"Std Dev " + stdev}/>
                        <div id={"Timeline_" + id} className="noselect" style={{
                        marginTop: DEFAULTS.PADDING,
                        }}></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Histogram

