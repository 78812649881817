import { NODE_OUTPUT_TYPES } from "../../NodeOutputTypes"

export const SUBTRACT_TIME_SERIES = {
    INPUTS: [],
    DISPLAY_NAME: "Subtract Time Series",
    PRODUCT_LEVEL: "Standard",
    DESCRIPTION: "Subtract one time series from another. If the time series are different lengths, the one with less data will be upsampled.",
    KEYWORDS: ["subtract", "sub"],
    TYPE: 'METRICS',
    DATA_INPUT_TYPES: [NODE_OUTPUT_TYPES.TIME_SERIES, NODE_OUTPUT_TYPES.TIME_SERIES],
    DATA_OUTPUT_TYPE: NODE_OUTPUT_TYPES.TIME_SERIES
}