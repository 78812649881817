import { EnterElement, ScaleBand, Selection, axisBottom } from "d3"
import { ReactCallbacks } from "../../../../Types/ReactCallbacks"
import { D3OneToOneRenderable } from "../../../D3/D3OneToOneRenderable"
import { MarginedBoundingBox } from "../../../../Types/MarginedBoundingBox"

export type HistogramXAxisConfig = {
    boundingBox: MarginedBoundingBox
    scale: ScaleBand<any>
    label?: string
}

export class D3HistogramXAxis extends D3OneToOneRenderable<SVGGElement, SVGGElement, HistogramXAxisConfig> {
    private axisClassName: string = "d3-histogram-x-axis"

    constructor(root: SVGGElement, config: HistogramXAxisConfig, reactCallbacks: ReactCallbacks<any>) {
        super(root, config, "d3-histogram-x-axis-container", reactCallbacks)
        this.render()
    }

    protected enter(newElements: Selection<EnterElement, HistogramXAxisConfig, any, any>): Selection<SVGGElement, HistogramXAxisConfig, SVGGElement, any> {
        // Create the Axis Group
        const axisGroup = newElements
            .append("g")
            .attr("class", this.className)
            .attr("transform", `translate(0, ${this.config.boundingBox.height})`)

        // Calculate tick values if necessary
        const tickValues = this.shouldLimitTicks() ? this.calculateTickValues() : null

        const axis = axisBottom(this.config.scale)
        if (tickValues) axis.tickValues(tickValues)

        axisGroup.append("g")
            .attr("class", this.axisClassName)
            .call(axis)
            .style("user-select", "none") // disables highlighting the ticks

        if (tickValues) {
            axisGroup.selectAll(".tick text")
                .style("transform", "translate(0px, 0px)")
                .style("text-anchor", "middle")
        } else {
            axisGroup.selectAll(".tick text")
                .style("transform", "translate(0px, 8px)")
                .style("text-anchor", "middle")
        }

        return axisGroup
    }

    protected update(updatedElements: Selection<SVGGElement, HistogramXAxisConfig, any, any>): Selection<SVGGElement, HistogramXAxisConfig, SVGGElement, any> {
        const axisGroup = updatedElements.attr("transform", `translate(0, ${this.config.boundingBox.height})`)

        // Calculate tick values if necessary
        const tickValues = this.shouldLimitTicks() ? this.calculateTickValues() : null

        // Redraw the axis
        const axis = axisBottom(this.config.scale)
        if (tickValues) axis.tickValues(tickValues)

        const d3Axis = axisGroup.select("." + this.axisClassName) as Selection<SVGGElement, any, any, any>
        d3Axis.call(axis)

        if (tickValues) {
            axisGroup.selectAll(".tick text")
                .style("transform", "translate(0px, 0px)")
                .style("text-anchor", "middle")
        } else {
            axisGroup.selectAll(".tick text")
                .style("transform", "translate(0px, 8px)")
                .style("text-anchor", "middle")
        }

        return updatedElements
    }

    private shouldLimitTicks(): boolean {
        const scaleDomain = this.config.scale.domain()
        const tickCount = scaleDomain.length
        const maxTicks = Math.floor(this.config.boundingBox.width / 50)
        return tickCount > maxTicks
    }

    private calculateTickValues(): any[] {
        const scaleDomain = this.config.scale.domain()
        const tickCount = Math.floor(this.config.boundingBox.width / 50)
        const tickStep = Math.ceil(scaleDomain.length / tickCount)
        
        return scaleDomain.filter((_, index) => index % tickStep === 0)
    }
}
