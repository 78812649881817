import { USER_INPUT_TYPES } from "../../UserInputTypes"
import { NODE_OUTPUT_TYPES } from "../../NodeOutputTypes"

export const SLIDING_WINDOW_DATA_SPLITTER = {
    INPUTS: [
        {
            DISPLAY_NAME: "Window size",
            VARIABLE_NAME: "window_size",
            TYPE: USER_INPUT_TYPES.DURATION_INPUT,
            DEFAULT_VALUE: {"seconds": 1},
            DESCRIPTION: "The size that the window will grow each iteration.",
        },
        {
            DISPLAY_NAME: "Overlap Percent (%)",
            VARIABLE_NAME: "overlap_percent",
            TYPE: USER_INPUT_TYPES.NUMBER_INPUT,
            DEFAULT_VALUE: 0,
            DESCRIPTION: "The percentage, between 0 and 100, that each window will overlap the previous window. Use 0 if no overlap is desired.",
        },
        {
            DISPLAY_NAME: "Keep Partial Windows",
            VARIABLE_NAME: "keep_partial_windows",
            TYPE: USER_INPUT_TYPES.CHECKBOX_INPUT,
            DEFAULT_VALUE: false,
            DESCRIPTION: "If True, and there is not enough data to fill in the last sliding window, keep it. If False, discard it.",
        },
    ],
    DISPLAY_NAME: "Sliding Window Data Splitter",
    PRODUCT_LEVEL: "Standard",
    DESCRIPTION: "Split a time series into pieces, using a sliding, fixed-size window.",
    KEYWORDS: ["data", "split", "sliding"],
    DATA_INPUT_TYPES: [NODE_OUTPUT_TYPES.TIME_SERIES],
    DATA_OUTPUT_TYPE: NODE_OUTPUT_TYPES.TIME_SERIES
}