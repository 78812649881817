export const releaseData = {'CONNECT V 1.0.0.0': {'bugfixes': {},
        'changes': {},
        'features': {'Account Management': [{'description': 'When a new user is invited to a CONNECT workspace, they can create an account that will be used across all CONNECT deployments within the scope of the network.', 'name': 'Create Account'},
                {'description': 'The user may delete their accounts from the CONNECT user database. This will remove their account from all associated workspaces.', 'name': 'Delete Account'},
                {'description': 'The user may change the email associated with their account.', 'name': 'Change Email'},
                {'description': 'If a user forgets their password, they may create a new one through the email associated with their account.', 'name': 'Forgot Password'},
                {'description': 'The user may change the password associated with their account.', 'name': 'Change Password'},
                {'description': 'The user may log in to CONNECT. They are provided with a token that controls their permissions from within the system. This is stored in their browser session.', 'name': 'Login'},
                {'description': 'The user may log out of CONNECT. The issued token will be removed from their browser session.', 'name': 'Logout'},
                {'description': 'The user will be alerted if they enter incorrect credentials when logging into CONNECT.', 'name': 'Incorrect Credentials'},
                {'description': 'The user may leave any of the workspaces that are associated with for a given CONNECT deployment.', 'name': 'Leave Workspace'},
                {'description': 'The user may leave any of the sites that are associated with for a given CONNECT deployment.', 'name': 'Leave Site'},
                {'description': 'The user may audit their current roles and permissions. ', 'name': 'Audit Permissions'}],
        'Analytic Pipelines': [],
        'Cloud and Storage': [{'description': 'All data uploaded by the user is uploaded to a common data repository.', 'name': 'Data Repository'}],
        'Data Converter': [{'description': 'The data converter will identify gaps in time series data using the known sampling frequency of the measurements.', 'name': 'Gap Identification'},
                {'description': 'The data converter will convert data from disparate sources into a common HDF5 file structure for each patient.', 'name': 'Data Conversion'},
                {'description': 'Data containing PHI will undergo a de-identification process locally before it is uploaded by the user.', 'name': 'De-Identification'},
                {'description': 'The converter comes with companion documentation outlining the structure of the HDF5 files.', 'name': 'File Structure Documentation'}],
        'Data Profiling': [{'description': 'The user may create patient groups.', 'name': 'Create Patient Group'},
                {'description': 'The user may search for patient groups by name using a free text search.', 'name': 'Search Patient Group'},
                {'description': 'The user delete patient groups.', 'name': 'Delete Patient Group'},
                {'description': 'The user may modify the metadata of a group and change the assigned patients.', 'name': 'Modify Patient Group'},
                {'description': 'The user may add a patient to a group.', 'name': 'Add Patient to Patient Group'},
                {'description': 'The user may remove a patient from a group.', 'name': 'Remove Patient from Patient Group'},
                {'description': 'The user may search for patients by name using a free text search and by site ids and patient ids.', 'name': 'Search Patients'},
                {'description': 'The user may download HDF5 files from a patient.', 'name': 'Download HDF5 File'}],
        'Data Query': [{'description': 'The user may access an API endpoint to retrieve the intersection or union of modalities across a set of patients.', 'name': 'Get Modalities Endpoint'},
                {'description': 'The user may access an API endpoint to retrieve the patients from a particular site and study.', 'name': 'Get Patients Endpoint'},
                {'description': 'The user may access an API endpoint to retrieve the modality start and end times for patients from a particular site and study.', 'name': 'Get Modality Times Endpoint'},
                {'description': 'The user may access an API endpoint to retrieve the modality percentages for patients from a particular site and study.', 'name': 'Get Modality Percentages Endpoint'},
                {'description': 'The user may access an API endpoint to retrieve the uploads from a particular site and study.', 'name': 'Get Uploads Endpoint'},
                {'description': 'The user may access an API endpoint to retrieve the patient files for patients from a particular site and study.', 'name': 'Get Patient Files Endpoint'},
                {'description': 'The user may access an API endpoint to retrieve annotation groups from a particular patient.', 'name': 'Get Annotation Groups Endpoint'},
                {'description': 'The user may access an API to retrieve the directory structure from a particular patient.', 'name': 'Get Directory Structure Endpoint'},
                {'description': 'The user may access an API to retrieve the patient file timestamps from patients from a particular site and study.', 'name': 'Get Patient File Timestamps Endpoint'},
                {'description': 'The user may access an API to retrieve the annotations from annotation groups from a particular patient.', 'name': 'Get Annotations Endpoint'},
                {'description': 'The user may access an API to save annotations to an annotation group from a particular patient.', 'name': 'Save Annotations Endpoint'}],
        'Database': [{'description': 'User data is stored in a centralized persistent relational database.', 'name': 'User Database'},
                {'description': 'The user table contains fields and relationships relevant to user-level information.', 'name': 'User Table'},
                {'description': 'The role table contains fields and relationships relevant to role-level information.', 'name': 'User Role Table'},
                {'description': 'The token table contains fields and relationships relevant to token-level information.', 'name': 'User Token Table'},
                {'description': 'The token table contains fields and relationships relevant to token-level information.', 'name': 'User Salt Table'},
                {'description': 'The workspace table contains fields and relationships relevant to workspace-level information.', 'name': 'User Workspace Table'},
                {'description': 'The site table contains fields and relationships relevant to site-level information.', 'name': 'User Site Table'},
                {'description': 'The permission table contains fields and relationships relevant to permission-level information.', 'name': 'User Permission Table'},
                {'description': 'The mapping table contains fields and relationships relevant to mapping-level information.', 'name': 'User Mapping Table'},
                {'description': 'The upload table contains fields and relationships relevant to upload-level information.', 'name': 'User Upload Table'},
                {'description': 'The upload message table contains fields and relationships relevant to upload message-level information.', 'name': 'User UploadMessage Table'},
                {'description': 'Patient data is stored in a persistent relational database that is specific to each CONNECT deployment.', 'name': 'Patient Table'},
                {'description': 'The workspace table contains fields and relationships relevant to workspace-level information.', 'name': 'Patient Workspace Table'},
                {'description': 'The site table contains fields and relationships relevant to site-level information.', 'name': 'Patient Site Table'},
                {'description': 'The bucket table contains fields and relationships relevant to bucket-level information.', 'name': 'Patient Bucket Table'},
                {'description': 'The data file table contains fields and relationships relevant to data file-level information.', 'name': 'Patient DataFile Table'},
                {'description': 'The data directory table contains fields and relationships relevant to data directory-level information.', 'name': 'Patient DataDirectory Table'},
                {'description': 'The annotation group table contains fields and relationships relevant to annotation group-level information.', 'name': 'Patient AnnotationGroup Table'},
                {'description': 'The annotation table contains fields and relationships relevant to annotation-level information.', 'name': 'Patient Annotation Table'},
                {'description': 'The source device table contains fields and relationships relevant to source device-level information.', 'name': 'Patient SourceDevice Table'},
                {'description': 'The source device table contains fields and relationships relevant to source device-level information.', 'name': 'Patient Upload Table'},
                {'description': 'The upload message table contains fields and relationships relevant to source upload message-level information.', 'name': 'Patient UploadMessage Table'},
                {'description': 'The MD5 table contains fields and relationships relevant to source MD5-level information.', 'name': 'Patient MD5 Table'}],
        'Email Service Software': [{'description': 'A centralized email service (MESS) controls all outgoing email communication to users.', 'name': 'Moberg Email Service Software'},
                {'description': 'MESS sends emails to users.', 'name': 'Send Email'},
                {'description': 'The user will receive an email with a secure link to reset their password when they request it.', 'name': 'Reset Password Email'},
                {'description': 'The user will receive an email confirmation if their password has been changed.', 'name': 'Change Password Confirmation Email'},
                {'description': 'The user will receive an email confirmation if their email has been chagned.', 'name': 'Change Email Confirmation Email'},
                {'description': 'The user will receive an email notification about the progress of an upload.', 'name': 'Upload Notification Email'},
                {'description': 'The user will receive an email with a secure link to join a workspace.', 'name': 'Workspace Invitation Email'},
                {'description': 'The user will receive an email with a secure link to create an account if they are invited to a workspace for the first time.', 'name': 'Create Account Email'},
                {'description': 'The user will receive an email confirmation when an account has been created with their email.', 'name': 'Create Account Confirmation Email'}],
        'Frontend Framework': [{'description': 'The user will interact with tabular data displayed in a table component with a common look and feel.', 'name': 'Table Component'}],
        'Harmonization': [{'description': 'We have compiled reference material from an extensive literature search of required modality metadata. This work will eventually be integrated with an ontology to standardized uploaded metadata.', 'name': 'Harmonization Modality Metadata'}],
        'Identity and Access Management': [{'description': 'Roles are listed under the Roles & Permissions tab in the Admin User Management page with metadata such as the roleâ\x80\x99s name, its description, the number of users assigned to that role, and the number of permissions assigned to that role.', 'name': 'List Roles'},
                {'description': 'The user may create a role with associated metadata and change the associated permissions.', 'name': 'Create Role'},
                {'description': 'The user may delete a role.', 'name': 'Delete Role'},
                {'description': 'The user may modify the metadata of a role and change the associated permissions.', 'name': 'Modify Role'},
                {'description': 'The user may clone a role to create a copy of it.', 'name': 'Clone Role'},
                {'description': 'The user may search for roles by name using a free text search.', 'name': 'Search Roles'},
                {'description': 'The user may export roles and their metadata to a CSV file and PDF file.', 'name': 'Export Roles'},
                {'description': "Permissions are listed when creating or modifying a role with metadata such as the permission's name and its description.", 'name': 'List Permissions'},
                {'description': 'The user may search for permissions by name or description using a free text search.', 'name': 'Search Permissions'},
                {'description': 'The user may invite another user to their workspace by indicating the userâ\x80\x99s email. They can also indicate the workspaces, sites, and roles associated with the new user.', 'name': 'Invite User'},
                {'description': 'The user may remove users from their workspace. This will not delete their accounts.', 'name': 'Remove User'},
                {'description': 'The user may modify the metadata of another user such as their associated workspaces, sites, and roles.', 'name': 'Modify User'},
                {'description': 'Users are listed under the User tab under the Admin User Management page with metadata such as the userâ\x80\x99s email, their first name, last name, and whether their account has been verified (or whether an account has been created with that email in the CONNECT system by the user).', 'name': 'List Users'},
                {'description': 'The user may search for other users by email using a free test search.', 'name': 'Search Users'},
                {'description': 'The user may export other users and their metadata to a CSV file or PDF file.', 'name': 'Export Users'},
                {'description': 'All actions performed in CONNECT are authenticated using the token issued on login or from the browserâ\x80\x99s session. This includes visiting pages as well as rendering certain buttons.', 'name': 'Action Authentication'},
                {'description': 'The user may assign a user to a role when modifying a user.', 'name': 'Assign User to Role'},
                {'description': 'The user may unassign a user from a role when modifying a user.', 'name': 'Unassign User to Role'},
                {'description': 'The user may add a user to a site when modifying a user or modifying a site.', 'name': 'Add User to Site'},
                {'description': 'The user may remove a user from a site when modifying a user or modifying a site.', 'name': 'Remove User from Site'},
                {'description': 'The user may add a user to a workspace when modifying a user or modifying a workspace.', 'name': 'Add User to Workspace'},
                {'description': 'The user may remove a user from a workspace when modifying a user or modifying a workspace.', 'name': 'Remove User from Workspace'}],
        'Medical Record': [],
        'Monitoring': [{'description': 'The user may reference a modality legend.', 'name': 'Modality Legend'},
                {'description': 'The user may reference a medication legend.', 'name': 'Medication Legend'},
                {'description': 'The user may reference values for the selected modalities and medications at a particular time point.', 'name': 'Timeline Values'},
                {'description': 'The user may toggle whether an annotation is applied for a specific modality or for the entire file.', 'name': 'Modality-Specific Annotation Toggle'},
                {'description': 'The user may create an annotation at a single point.', 'name': 'Point Annotation'},
                {'description': 'The user may click and drag to select a region to annotate.', 'name': 'Click and Drag Annotation Selection'},
                {'description': 'The user may select a start point and a stop point to annotate a region.', 'name': 'Start and Stop Annotation Selection'},
                {'description': 'The user may hover over a medication tracing to see its details.', 'name': 'Medication Hover Text'},
                {'description': 'The user may hover over an annotation to see its details.', 'name': 'Annotation Hover Text'},
                {'description': 'The user may hover over a modality to see its details.', 'name': 'Modality Hover Text'},
                {'description': 'The user may change the time-axis scale. This can be done by shift-clicking and dragging into an area to zoom into that region or by expanding or contracting the timeline annotation preview. The user may also double-click the timeline annotation preview to zoom out completely.', 'name': 'Change Time-Axis Scale'},
                {'description': 'The user may translate the time-axis.', 'name': 'Translate Time-Axis'},
                {'description': 'The user may translate the data-axis.', 'name': 'Translate Data-Axis'},
                {'description': 'The user may change the size of the graphs in view.', 'name': 'Set Graph Size'},
                {'description': 'The user may change the order of the graphs in view.', 'name': 'Set Graph Order'},
                {'description': 'The user may control the currently selected mode using the toolbar.', 'name': 'Toolbar'},
                {'description': 'The user may interact with an annotation preview below the graphs.', 'name': 'Timeline Annotation Preview'},
                {'description': "The current position of the user's mouse will show a vertical trace.", 'name': 'Timeline Cursor'},
                {'description': 'The user may select which modalities to view. ', 'name': 'Modality Selection'}],
        'Reporting': [],
        'Software Development Kit': [],
        'Trial Management': [{'description': 'Uploads are listed under the Admin Uploads page with metadata such as site ID, patient ID, upload date and time, user, and status.', 'name': 'List Uploads'},
                {'description': 'Workspaces are listed under the Admin Workspaces page with metadata such as workspaceâ\x80\x99s ID, its name, title, type, and start date.', 'name': 'List Workspaces'},
                {'description': 'Buckets are listed under the Admin Buckets page with the bucketâ\x80\x99s name and the associated workspace.', 'name': 'List Buckets'},
                {'description': 'Sites are listed under the Admin Sites page with metadata such as the siteâ\x80\x99s name, its ID, address, and description.', 'name': 'List Sites'},
                {'description': 'The user may create a new site with associated metadata and assign users. ', 'name': 'Create Site'},
                {'description': 'The user may delete a site.', 'name': 'Delete Site'},
                {'description': 'The user may modify the metadata of a site and change the assigned users.', 'name': 'Modify Site'},
                {'description': 'The user may create a workspace with associated metadata and assign users.', 'name': 'Create Workspace'},
                {'description': 'The user may delete a workspace.', 'name': 'Delete Workspace'},
                {'description': 'The user may modify the metadata of a workspace and change the assigned users.', 'name': 'Modify Workspace'},
                {'description': 'The user may create a bucket with associated metadata and assign a workspace.', 'name': 'Create Bucket'},
                {'description': 'The user may delete a bucket.', 'name': 'Delete Bucket'},
                {'description': 'The user may modify the metadata of a bucket and change the assigned workspace', 'name': 'Modify Bucket'},
                {'description': 'The user may add a bucket to a workspace either when modifying a workspace or modifying a bucket.', 'name': 'Add Bucket to Workspace'},
                {'description': 'The user may remove a bucket from a workspace either when modifying a workspace or modifying a bucket.', 'name': 'Remove Bucket from Workspace'},
                {'description': 'The user may remove a site from a workspace either when modifying a workspace or modifying a site.', 'name': 'Remove Site from Workspace'},
                {'description': 'The user may add a site to a workspace either when modifying a workspace or modifying a site.', 'name': 'Add Site to Workspace'},
                {'description': 'The user may export workspaces and their metadata to a CSV file or PDF file.', 'name': 'Export Workspaces'},
                {'description': 'The user may search for workspaces by name using a free text search.', 'name': 'Search Workspaces'},
                {'description': 'The user may export sites and their metadata to a CSV file or PDF file.', 'name': 'Export Sites'},
                {'description': 'The user may search for sites by name using a free text search.', 'name': 'Search Sites'},
                {'description': 'The user may export buckets and their metadata to a CSV file or PDF file.', 'name': 'Export Buckets'},
                {'description': 'The user may search for buckets by name using a free text search.', 'name': 'Search Buckets'}],
        'Upload Portal': [{'description': 'The user can upload data on the Data Uploads page by clicking the Upload New Patient button in the upper right of the page.', 'name': 'Upload Data'},
                {'description': 'When uploading data, the user needs to specify a site and patient id to associate with the uploaded data.', 'name': 'Site and Patient ID Specification'},
                {'description': 'The user can attach files and directories to each upload in their respective categories.', 'name': 'Add File/Directory'},
                {'description': 'When an upload is processed, the user will receive notifications updating them about the progress (UPLOAD INITIATED, PENDING, CONVERTING, SUCCESS, FAILED).', 'name': 'Email Notifications'},
                {'description': 'If a medication file is uploaded, the user will need to select or create a mapping from the local column headers of the file to the harmonized data columns in the CONNECT database.', 'name': 'Medication Mapping'},
                {'description': 'The user may search for uploads by site id and patient id using a free text search.', 'name': 'Search Uploads'},
                {'description': 'The user may export uploads and their metadata to a CSV file or PDF file.', 'name': 'Export Uploads'},
                {'description': 'The user can upload CNS archives.', 'name': 'Upload CNS Archives'},
                {'description': 'The user can upload medication files.', 'name': 'Upload Medication Files'},
                {'description': 'When a user uploads CNS directories, they will be validated according to the CNS standard structure. Only files that satisfy the structure will be queued for upload.', 'name': 'Validate CNS Directory'},
                {'description': 'During an upload, a progress bar will track how many files have been uploaded. The upload process is composed of two steps. The first step generates a unique hash for each file to verify the content of the file, and the second step moves the files to a common data repository.', 'name': 'Upload Progress'}],
        'User Interface': [{'description': 'The user will interact with a login page to sign on to CONNECT. This is the first screen they see when visiting the application.', 'name': 'Login Page'},
                {'description': 'If the user visits a page that does not exist, a helpful Page Not Found page will be displayed to help them redirect to the correct content of CONNECT.', 'name': 'Page Not Found Page'},
                {'description': 'While CONNECT is fetching data from the backend, a loading page will be displayed to the user to indicate that the application is working on a background process. ', 'name': 'Loading Page'},
                {'description': 'The user may interact with a left-handed navigation sidebar to move through the main pages of CONNECT.', 'name': 'Navigation Bar'},
                {'description': 'The user may interact with the Data Trials page, which will display trial-level data about patient enrollment. This page contains multi-tabular displays that allow the user to ask questions about the patients that were uploaded and their metadata.', 'name': 'Data Trials Page'},
                {'description': 'The user may interact with the Data Visualize page, which organizes the uploaded patient data and allows the user to visualize the time series data that was uploaded. ', 'name': 'Data Visualize Page'},
                {'description': 'The user will interact with the Patient Group Profiling page, which organizes patient groups and allows the user to create, delete, modify, export, and search patient groups.', 'name': 'Patient Group Profiling Page'},
                {'description': 'The user will interact with the Data Upload page, which organizes previous uploads and allows the user to upload new patient data.', 'name': 'Data Upload Page'},
                {'description': 'The user may interact with the Admin User Management page, which organizes users and allows the user to invite, remove, modify, export, and search users within their CONNECT deployment.', 'name': 'Admin User Management Page'},
                {'description': 'The user may interact with the Admin Roles and Permissions page, which organizes roles and allows the user to create, delete, modify, export, and search roles within their CONNECT deployment.', 'name': 'Admin Roles and Permissions Page'},
                {'description': 'The user may interact with the Admin Sites page, which organizes sites and allows the user to create, delete, modify, export, and search sites within their CONNECT deployment.', 'name': 'Admin Sites Page'},
                {'description': 'The user may interact with the Admin Uploads page, which organizes uploads and allows the user to export and search uploads within their CONNECT deployment. ', 'name': 'Admin Uploads Page'},
                {'description': 'The user may interact with the Admin Workspace page, which organizes workspaces and allows the user to create, delete, modify, export, and search workspaces within their CONNECT deployment.', 'name': 'Admin Workspace Page'},
                {'description': 'The user may interact with the Admin Buckets page, which organizes buckets and allows the user to create, delete, modify, export, and search buckets within their CONNECT deployment.', 'name': 'Admin Buckets Page'}]},
        'name': 'Foundation Release',
        'posted': 'May 31, 2022',
        'summary': 'Initial release with primary Trials, Upload, Profiling, and Admin capabilities'}}