import React from 'react'
import MUITable from "../../Components/MUITable/MUITable"
import { useNavigate } from "react-router-dom"
import { FRONTEND_LINKS } from '../../Constants/FrontendLinks'

export function Webinars() {

    const navigate = useNavigate()

    const columns = [
        {
            field: 'webinar',
            headerName: 'Webinar name',
            flex: 1,
            renderCell: (params) => (
                <span
                    style={{ cursor: 'pointer', color: '#0097EE', textDecoration: 'underline' }}
                    onClick={() => navigate(`${FRONTEND_LINKS.SUBPAGES.RESOURCES.WEBINARS.SUBPAGES.VIEW.MAIN}?id=${params.row.id}`)}
                >
                    {params.value}
                </span>
            ),
        },
        { field: 'date', headerName: 'Date', flex: 0.3 },
    ]

    let rows = [
        { id: 0, webinar: 'Case Studies in Neurocritical Care AI: Data Exploration through Multimodal Animation', date: 'November 16, 2023' },
        { id: 1, webinar: 'Case Studies in Neurocritical Care AI: Predicting 6-month GOS-E from ICP and Brain Oxygenation', date: 'December 18, 2023' },
    ]

    
    return (
        <MUITable columns={columns} tableData={rows} style={{ paddingTop: "16px" }} isRowSelectable={() => false} isCheckboxSelection={false} />
    )

}
