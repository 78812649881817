import { NODE_OUTPUT_TYPES } from "../../NodeOutputTypes";
import { USER_INPUT_TYPES } from "../../UserInputTypes";

export const WEIGHTED_MEAN = {
    INPUTS: [
        {
            DISPLAY_NAME: "Invert Weights",
            VARIABLE_NAME: "invert_weights",
            TYPE: USER_INPUT_TYPES.CHECKBOX_INPUT,
            DEFAULT_VALUE: false,
            DESCRIPTION: "If true, the a low weight would be valued more strongly than a high weight.",
        },
    ],
    DISPLAY_NAME: "Weighted Mean",
    PRODUCT_LEVEL: "Standard",
    DESCRIPTION: "Find the weighted mean given a list of values and weights",
    KEYWORDS: ["weight", "mean", "average"],
    TYPE: 'METRICS',
    DATA_INPUT_TYPES: [NODE_OUTPUT_TYPES.LIST_OF_VALUES, NODE_OUTPUT_TYPES.LIST_OF_VALUES],
    DATA_OUTPUT_TYPE: NODE_OUTPUT_TYPES.VALUE
}