import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { authUpdates, useAuthProvider } from '../../Providers/AuthProvider'
import { useIdleTimer } from 'react-idle-timer'
import { FRONTEND_LINKS } from '../../Constants/FrontendLinks'
import TimeoutModal from './TimeoutModal'
import { useEndpointProvider } from '../../Providers/EndpointProvider';
import { useUploadIntoQueueProvider } from '../../Providers/UploadIntoQueueProvider';
import { useOnMount } from "../../Hooks/useOnMount"
import { useModalProvider } from '../../Providers/ModalProvider'
import { useBackendLinksProvider } from "../../Providers/BackendLinksProvider"
import { useEnvironmentVariablesProvider } from '../../Providers/EnvironmentVariablesProvider'

export const RequireAuth = (props) => {
    const authProvider = useAuthProvider()
    const endpointProvider = useEndpointProvider()
    const uploadIntoQueueProvider = useUploadIntoQueueProvider()
    const { createModal, close } = useModalProvider()
    const { LINKS } = useBackendLinksProvider()
    const environmentVariablesProvider = useEnvironmentVariablesProvider()

    const navigate = useNavigate()

    useEffect(() => { // inside a useEffect because we need to wait for the provider to render.
        if (!authProvider.userIsLoggedIn) {
            navigate(FRONTEND_LINKS.MAIN)
        } else {
            authProvider.update(authUpdates.TOKEN, authUpdates.PERMISSIONS, authUpdates.CURRENT_USER)
        }
    })

    async function updateToken() {
        return endpointProvider.post(LINKS.ACCOUNT.UPDATE_TOKEN_EXPIRE_TIME)
    }

    let INACTIVITY_MODAL_DELAY_TIME = 20 * 60 * 1000; // Milliseconds until the inactivty modal pop-ups.
    let INACTIVITY_MODAL_DISPLAY_TIME = 2 * 1000 * 60; // Milliseconds that the modal will remain open. 

    let CHECK_ACTIVITY_TIME = 1 * 1000 * 60; // Milliseconds between checking that the user is idle. If the user is not idle, it will refresh the token. At this point, 

    let ENDPOINT_DELAY_TIME = 5 * 1000; // Milliseconds of delay for the API to return a correct token. This is here to check whether there's sufficient time to obtain a refresh token before the token expires.

    let INTERVAL_TIME = 1 * 1000; // Milliseconds for interval that will check whether user has been in an idle state for longer than INACTIVITY_MODAL_DELAY_TIME since the token was created using the 

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isIdle, setIsIdle] = useState(false)

    const [tokenStartTime, setTokenStartTime] = useState()
    const [TokenEndTime, setTokenEndTime] = useState()

    useOnMount(() => {
        async function setTokenTimes() {
            const res = await updateToken()

            setTokenStartTime(res?.time_created)
            setTokenEndTime(res?.time_expires)
        }

        setTokenTimes()
    })

    useEffect(() => {
        props.setNavPage(true)
    }, [])

    useEffect(() => {

        let closeModalTime = tokenStartTime + INACTIVITY_MODAL_DELAY_TIME + INACTIVITY_MODAL_DISPLAY_TIME;
        let tokenEndTime = TokenEndTime;

        if (closeModalTime + ENDPOINT_DELAY_TIME > tokenEndTime) {
            alert('Your token will likely expire before it can be refreshed. Please either increase the length of time the token is valid for (iam.iam.utils.auth.TOKEN_DURATION) or decrease the length of the modal timers (INACTIVITY_MODAL_DELAY_TIME + INACTIVITY_MODAL_DISPLAY_TIME at src/Pages/Error/RequireAuth.js).')
            authProvider.logout()
            uploadIntoQueueProvider.setOpenQueue(false);
        }

        if (INACTIVITY_MODAL_DELAY_TIME < CHECK_ACTIVITY_TIME) {
            alert('The time alloted for the inactivity modal to display is not long enough to determine whether the user is not idle. Please adjust the assocaited variables (INACTIVITY_MODAL_DELAY_TIME or CHECK_ACTIVITY_TIME at src/Pages/Error/RequireAuth.js).')
            authProvider.logout()
            uploadIntoQueueProvider.setOpenQueue(false);
        }

    }, [CHECK_ACTIVITY_TIME, ENDPOINT_DELAY_TIME, INACTIVITY_MODAL_DELAY_TIME, INACTIVITY_MODAL_DISPLAY_TIME, authProvider])

    // TODO: replace with better solution later... someone could easily programmatically set this to true and then they would be "ACTIVE" all the time 
    const handleOnActive = () => {
        if (authProvider.enableCheckActivity) {
            console.log('ACTIVE')
            localStorage.setItem('idle', false)
            setIsIdle(false)
        }
    }

    const handleOnIdle = () => {
        if (authProvider.enableCheckActivity) {
            console.log('IDLE')
            localStorage.setItem('idle', true)
            setIsIdle(true)
        }
    }

    // Remove Modal when button pressed
    const removeModal = () => {
        updateToken().then(async (data) => {
            console.log('checkActiveInterval: refresh token')
            setTokenStartTime(data['time_created']);
            setTokenEndTime(data['time_expires']);
            close()
            setIsModalOpen(false)
        }).catch((e) => {
            alert(e)
        })
    }

    useEffect(() => {
        // This interval will check every INTERVAL_TIME seconds to see whether the current time is past showModalTime or past closeModalTime

        if (environmentVariablesProvider.environmentVariables.DISABLE_CHECK_INACTIVITY) return

        const checkInactiveInterval = setInterval(() => {
            if (authProvider.enableCheckActivity) {
                let nowDate = Date.now()

                let showModalTime = tokenStartTime + INACTIVITY_MODAL_DELAY_TIME;
                let closeModalTime = tokenStartTime + INACTIVITY_MODAL_DELAY_TIME + INACTIVITY_MODAL_DISPLAY_TIME;

                // console.log('checkInactiveInterval: check activity')
                // console.log(`nowDate:        ${nowDate}`)
                // console.log(`showModalTime:  ${showModalTime}`)
                // console.log(`closeModalTime: ${closeModalTime}`)

                if (nowDate > showModalTime && nowDate < closeModalTime) {
                    if (!isModalOpen) {
                        createModal(<TimeoutModal
                            style={{ maxWidth: '90%', width: '450px' }}
                            escClose={false}
                            clickOutsideClose={false}
                            removeModal={removeModal}
                            logoutTime={tokenStartTime + INACTIVITY_MODAL_DELAY_TIME + INACTIVITY_MODAL_DISPLAY_TIME}
                        />)
                        setIsModalOpen(true)
                    }
                } else if (nowDate >= closeModalTime) {
                    authProvider.logout()
                    uploadIntoQueueProvider.setOpenQueue(false)
                    navigate(FRONTEND_LINKS.SUBPAGES.INACTIVE.MAIN)
                }
            }

        }, INTERVAL_TIME)

        return () => clearInterval(checkInactiveInterval)
    }, [INACTIVITY_MODAL_DELAY_TIME, INACTIVITY_MODAL_DISPLAY_TIME, INTERVAL_TIME, authProvider, navigate, tokenStartTime, TokenEndTime, isModalOpen])

    useEffect(() => {
        // return
        // This interval will check every CHECK_ACTIVITY_TIME milliseconds to see whether the user is still active. If yes, it will call a refresh token

        // TODO: repalce with function reference... that didn't seem to work
        const checkActiveInterval = setInterval(() => {
            // console.log('hit checkactiveinterval')
            let localIsIdle = localStorage.getItem('idle')

            if (localIsIdle === undefined) {
                localIsIdle = false
                localStorage.setItem('idle', false)
            } else {
                localIsIdle = localIsIdle === 'true'
            }

            // console.log(`isIdle: ${localIsIdle}`)

            if (!localIsIdle) {
                updateToken().then(async (data) => {

                    // console.log('checkActiveInterval: refresh token')
                    // console.log(data)

                    setTokenStartTime(data['time_created']);
                    setTokenEndTime(data['time_expires']);
                }).catch((e) => {
                    alert(e)
                })
            }
        }, CHECK_ACTIVITY_TIME)

        return () => clearInterval(checkActiveInterval)
    }, [isIdle, authProvider, CHECK_ACTIVITY_TIME, updateToken])

    // This timer is to check whether the user has been idle
    useIdleTimer({
        timeout: CHECK_ACTIVITY_TIME, // Seconds (s) to check that the user is active
        onIdle: handleOnIdle, // What happens when the user is idle
        onActive: handleOnActive, // What happens when the user is active
    })

    useEffect(() => {
        if (!authProvider.userIsLoggedIn) {
            authProvider.logout()
            uploadIntoQueueProvider.setOpenQueue(false);
            navigate(FRONTEND_LINKS.MAIN)
        }
    }, [authProvider])

    return (<>
        {authProvider.userIsLoggedIn ? props.children : null}
    </>)
}

