import React, { useEffect, useState } from 'react'
import Pagination from "@mui/material/Pagination"
import TextField from "@mui/material/TextField"

export default function CustomPagination(props) {
	const { paginationModel, setPaginationModel, totalRowCount } = props
	const [pageInput, setPageInput] = useState(paginationModel.page + 1)

	const count = Math.ceil(totalRowCount / paginationModel.pageSize)
	const startRow = paginationModel.page * paginationModel.pageSize + 1
	const endRow =
		(paginationModel.page + 1) * paginationModel.pageSize > totalRowCount
			? totalRowCount
			: (paginationModel.page + 1) * paginationModel.pageSize

	const handleChange = (event, value) => {
		setPaginationModel({
			...paginationModel,
			page: value - 1,
		})
		setPageInput(value)
	}

	if (paginationModel.page > 0 && paginationModel.page > count - 1) {
		setPaginationModel({
			...paginationModel,
			page: count - 1,
		})
	}

	const handlePageInputChange = (event) => {
		const inputPage = parseInt(event.target.value, 10)
		setPageInput(inputPage)
	}

	const handlePageInputBlur = () => {
		if (isNaN(pageInput) || pageInput < 1 || pageInput > count) {
			setPageInput(paginationModel.page + 1)
			return
		}

		setPaginationModel({
			...paginationModel,
			page: pageInput - 1,
		})
		setPageInput(pageInput)
	}

	const handlePageInputKeyPress = (event) => {
		if (event.key === 'Enter') {
			handlePageInputBlur();
		}
	}

	useEffect(() => {
		setPageInput(paginationModel.page + 1)
	}, [paginationModel])

	return (
		<div style={{ display: "flex", alignItems: "center" }}>
			<div style={{ fontSize: '16px', fontFamily: 'Source Sans Pro', marginBottom: '2px' }}>
				{endRow === 0 ? 0 : startRow}-{endRow} of {totalRowCount}
			</div>
			<Pagination
				color="primary"
				page={paginationModel.page + 1}
				count={count}
				onChange={handleChange}
				showFirstButton
				showLastButton
			/>
			<TextField
				label="Go to Page"
				variant="outlined"
				type="number"
				value={pageInput}
				onChange={handlePageInputChange}
				onBlur={handlePageInputBlur}
				onKeyPress={handlePageInputKeyPress}
				inputProps={{
					style: { width: "55px", height: '5px', textAlign: 'center' },
				}}
			/>
		</div>
	)
}
