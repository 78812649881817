import { useState, useEffect, useCallback, useRef } from "react"
import styled from 'styled-components';
import "@fontsource/source-sans-pro"
import * as MdIcons from "react-icons/md"
import { DEFAULTS } from "../../../Constants/Formatting"
import { MainTable } from "../../../Components/DataTable/Table"
import { CreatePipelineModal } from "./SubComponents/CreatePipelineModal/CreatePipelineModal";
import { CreatePipelineModalRedesign } from "./SubComponents/CreatePipelineModalRedesign/CreatePipelineModalRedesign"
import { useStudiesProvider } from '../../../Providers/StudiesProvider';
import { useEndpointProvider } from '../../../Providers/EndpointProvider';
import { analysesUpdates, useAnalysesProvider } from "../../../Providers/AnalysesProvider";
import { LINKS } from '../../../Constants/BackendLinks';
import TrashButton from "../../../Components/TrashButton/TrashButton";
import { ButtonHeader } from "../../../Constants/StyledComponents";

// PipelineCreate: component that renders the PipelineCreate page
// Inputs:: 
// 		- params (dict):
// Output:
//		- None
const PipelineCreate = () => {
	const [, setPipelineCreateUpdate] = useState([])
	const studiesProvider = useStudiesProvider()
	const endpointProvider = useEndpointProvider()
	const analysesProvider = useAnalysesProvider()
	
	function updatePipelineCreate() {
		setPipelineCreateUpdate([])
	}

	// only perform these actions once
	useEffect(() => {
		window.addEventListener("resize", updatePipelineCreate)
		return () => {
			window.removeEventListener("resize", updatePipelineCreate)
		}
	}, [])

	useEffect(() => {
		analysesProvider.update(analysesUpdates.ANCILLARY_FILTER)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

	const createPipelineButtonRef = useRef()

    const enterDefault = useCallback((event) => {
		if (event.keyCode === 13) {
			event.preventDefault();
		}
	}, []);

	useEffect(() => {
		document.addEventListener("keydown", enterDefault);
		return () => {
			document.removeEventListener("keydown", enterDefault);
		};
	}, [enterDefault]);

	const [createPipelineSearchQuery, setCreatePipelineSearchQuery] = useState('')

	function checkPipelineSearch(val) {
		// Uncomment when correct columns names are chosen
		// if (createPipelineSearchQuery === "") {
		// 	return val
		// } else if (val.analysis_name.toLowerCase().includes(createPipelineSearchQuery.toLowerCase())) {
		// 	return val
		// } else if (val.created_by.toLowerCase().includes(createPipelineSearchQuery.toLowerCase())) {
		// 	return val
		// } else if (val.version.toLowerCase().includes(createPipelineSearchQuery.toLowerCase())) {
		// 	return val
		// }
		if (createPipelineSearchQuery === "") {
			return val
		} else if (val.description.toLowerCase().includes(createPipelineSearchQuery.toLowerCase())) {
			return val
		} else if (val.name.toLowerCase().includes(createPipelineSearchQuery.toLowerCase())) {
			return val
		} else if (val.version.toLowerCase().includes(createPipelineSearchQuery.toLowerCase())) {
			return val
		}
	}

	const createPipelineRedesignButtonRef = useRef()

	const [analysesTablePending, setAnalysesTablePending] = useState(true)
	const [analysesTableData, setAnalysesTableData] = useState([])
	const [analysesTableColumns, setAnalysesTableColumns] = useState([])
	const [analysesPDFTableData, setAnalysesPDFTableData] = useState([])
	const [analysesPDFTableColumns, setAnalysesPDFTableColumns] = useState([])

	const [toggleAnalysesClearRows, setToggleAnalysesClearRows] = useState(false)

	// This will grab data for analysis table
	useEffect(() => {
		if (analysesProvider.analyses?.length === 0) return
        setAnalysesTablePending(true)

		// let columns2labels = {
		// 	analysis_name: { label: "Analysis Name", type: "string", center: false },
		// 	created_by: { label: "Created By", type: "string", center: false },
		// 	versions: { label: "Version", type: "string", center: false },
		// 	public: { label: "Public", type: "checkbox", center: true },
		// }

		let columns2labels = {
			id: { label: "ID", type: "string", center: false },
			name: { label: "Name", type: "string", center: false },
			version: { label: "Version", type: "string", center: false },
			description: { label: "Description", type: "string", center: false },
		}

		let columnData = []
		let pdfColumnData = []

		for (let elem of Object.keys(columns2labels)) {
			columnData.push({
				name: columns2labels[elem]['label'],
				selector: row => row[elem],
				sortable: true,
                center: columns2labels[elem]['center']
			})
            pdfColumnData.push({
                name: columns2labels[elem]['label'],
				selector: row => row[elem],
            })
		}
		setAnalysesTableColumns(columnData)
        setAnalysesPDFTableColumns(pdfColumnData)

		let tableData = analysesProvider.analyses?.map(row => {
			let entry = {}
			let pdfEntry = {}
			for (let column of Object.keys(row)) {
				if (columns2labels[column] && columns2labels[column]["type"] === "checkbox") {
					if (row[column]) {
						entry[column] = (<MdIcons.MdOutlineCheckBox size={18} />)
					} else {
						entry[column] = (<MdIcons.MdOutlineCheckBoxOutlineBlank size={18} />)
					}
					pdfEntry[column] = row[column]
				} else if (columns2labels[column] && columns2labels[column]["type"] === 'string') {
					if (row[column] === null) {
						entry[column] = ""
						pdfEntry[column] = `${row[column]}`
					} else {
						entry[column] = `${row[column]}`
						pdfEntry[column] = `${row[column]}`
					}
				} else {
                    entry[column] = row[column]
                }
			}
			return entry
		})

		const sortedData = [...tableData].sort((a, b) => (a.email < b.email ? -1 : 1))

		setAnalysesTableData(sortedData)
		setAnalysesPDFTableData(sortedData)
        setAnalysesTablePending(false)
	}, [analysesProvider.analyses])

	const [analysesTrash, setAnalysesTrash] = useState([])

	function updateSelectedAnalysisSelectedRows({selectedRows}) {
		let newAnalysesTrash = (selectedRows.map((analysis) => {return analysis.id}))
		setAnalysesTrash(newAnalysesTrash)
		console.log('trash', newAnalysesTrash)
	}

	function handleDeleteAnalysis() {
		let body = {
			study_id: studiesProvider.selectedStudyId,
			analysis_ids: analysesTrash
		}

		setToggleAnalysesClearRows(!toggleAnalysesClearRows)

		return endpointProvider.post(LINKS.PIPELINES.CREATE.DELETE_ANALYSIS, body)
	}

	return (
		<div style={{background: "#F8F8F8", maxHeight: `${window.innerHeight - 74}px`}}>
            <HeaderGap />
			<div style={{
				width: "100%",
				height: "100%",
				color: DEFAULTS.TEXT_COLOR,
				background: "#F8F8F8",
				paddingLeft: "35px",
				paddingRight: "35px",
				display: "block"				}}>
				<ButtonHeader style={{paddingBottom: "10px"}}>
					<CreatePipelineButton ref={createPipelineRedesignButtonRef
						// createPipelineButtonRef
						} onClick={() => { /* createAnalysis('a', 'a', 'a', 'true') */ }} >
						<MdIcons.MdModeEdit size={18} style={{marginTop: "-3px"}}/>
						Create Analysis
					</CreatePipelineButton>
					<TrashButton
						onClickFxn={handleDeleteAnalysis()}
						disabled={!(analysesTrash.length > 0)}
						buttonStyle={{marginRight: "22%", flex: "0.1"}}
					/>
					<Searchbar className="ui left icon input">
						<input type="text" placeholder="Search" onChange={event => {setCreatePipelineSearchQuery(event.target.value)}}/> <i aria-hidden="true" className="search icon" />
						<button onClick={() => {setCreatePipelineSearchQuery(createPipelineSearchQuery)}}>Search</button>
					</Searchbar>
				</ButtonHeader>

				<div>
					<MainTable
					columns={analysesTableColumns}
					data={analysesTableData}
					selectableRows
					onSelectedRowsChange={updateSelectedAnalysisSelectedRows}
					clearSelectedRows={toggleAnalysesClearRows}
					pagination/>
				</div>

				{/* Modals */}
				<CreatePipelineModal openRef={createPipelineButtonRef} />
				<CreatePipelineModalRedesign openRef={createPipelineRedesignButtonRef}/>
			</div>
        </div>
	)

}

const HeaderGap = styled.div`
    background-color: white;
	height: 47px;
`;

const CreatePipelineButton = styled.button`
	width: auto;
	height: 34px;
	background: #207dea;
	border-radius: 6px;
	font-family: "Source Sans Pro";
	font-style: normal;
	font-weight: 600;
	line-height: 150%;
	color: #ffffff;
	border: none;
	padding: 4px 8px;
	margin-right: 8px;
	white-space: nowrap;
	flex: 0.25;
`;

const Searchbar = styled.div`
	width: 40%;
	height: 34px;
	background: #ffffff;
	border: 0.5px solid #d9d9d9;
	box-sizing: border-box;
	box-shadow: 0px 0px 3px rgba(99, 191, 244, 0.15);
	border-radius: 6px;
	overflow-y: hidden;
	flex: 2;
	button {
		width: 80px;
		height: 34px;
		background: #207dea;
		border-radius: 0px 6px 6px 0px;
		border: none;
		font-family: "Source Sans Pro";
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 150%;
		color: #ffffff;
		cursor: pointer;
		padding-bottom: 3.5px;
	}
`;

export default PipelineCreate;
