import { useSetRecoilState, useRecoilValue, RecoilState } from "recoil"
import { useLayoutService } from "../../../../../../Hooks/useLayoutService"
import { uneditedLayoutGroupAtom, editedLayoutGroupAtom } from "../../Atoms/Layout"

type useEditWindowProps = {
	recoilState: RecoilState<any>
	layoutId: string
	windowId: string
}

// This gives us Intellisense for nested keys, for example: eegConfig.options.sensitivity
type NestedKeys<T> = T extends object ? { [K in keyof T]: K extends string ? `${K}` | `${K}.${NestedKeys<T[K]>}` : never }[keyof T] : never

export function useEditWindow<JSONType>(props: useEditWindowProps) {
	const setConfig = useSetRecoilState(props.recoilState)
	const setUneditedLayoutGroup = useSetRecoilState(uneditedLayoutGroupAtom)
	const editedLayoutGroup = useRecoilValue(editedLayoutGroupAtom)

	const currentLayout = editedLayoutGroup?.layouts.find(layout => layout.id === props.layoutId)
	const currentWindow = currentLayout?.areas.find(window => window.area === props.windowId)
	const editedConfig = currentWindow?.props as JSONType

	const { updateWindowProperty, updateWindowProps, modifyLayoutGroup } = useLayoutService()

	const updateProperty = (property: NestedKeys<JSONType>, newValue: any) => {
		updateWindowProperty(property, props.layoutId, props.windowId, newValue)
	}

	const updateProps = (newJSON: JSONType) => {
		updateWindowProps<JSONType>(newJSON, props.layoutId, props.windowId)
	}

	const saveChanges = async () => {
		if (!editedLayoutGroup) {
			return
		}

		await modifyLayoutGroup(editedLayoutGroup.id, editedLayoutGroup)
		setUneditedLayoutGroup(editedLayoutGroup)
		setConfig((previous: JSONType) => ({ ...previous, ...editedConfig }))
	}

	return { editedConfig, updateProps, updateProperty, saveChanges }
}
