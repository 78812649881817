import React, { CSSProperties } from "react"

type MobergColumnProps = {
	expand?: boolean
	gap?: string | number
	horizontalAlign?: string
	verticalAlign?: string
	style?: CSSProperties
}

export const MobergColumn: React.FC<MobergColumnProps> = ({ children, expand=false, gap, horizontalAlign, verticalAlign, style }) => {
	return (
		<div style={{ height: expand ? "100%" : "unset", display: "flex", flexDirection: "column", justifyContent: verticalAlign, alignItems: horizontalAlign, gap, ...style }}>
			{children}
		</div>
	)
}
