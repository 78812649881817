import { debounce, throttle } from "lodash"

// Allows us to put a performance cap on the render function, if our render is particularly expensive. 
// This is not for rendering every frame. For that, use requestAnimationFrame.

// Instead, this is best paired with a render function that is computationally expensive, and uses a CSS animation or D3 transition
// instead of performing the computation on every frame.
export const throttledRender = (renderFunction: (...args: any[]) => any, throttleTime: number): ((...args: any[]) => any) => {

    const throttled = throttle(renderFunction, throttleTime)
    const debounced = debounce(renderFunction, 2 * throttleTime)

    // This function, created inside another function is called a closure.
    const render = (...args: any[]) => {
        throttled(args)
        debounced(args)
    }

    return render
}