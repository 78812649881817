import React from 'react'
import { analysesUpdates, useAnalysesProvider } from '../../../Providers/AnalysesProvider';
import styled from "styled-components";
import { selectedLayoutGroupAtom } from '../../../Pages/Data/Visualize/DataReview/Atoms/Layout';
import { useRecoilValue } from "recoil"
import { useOnMount } from '../../../Hooks/useOnMount'
import { useAsyncTask } from "../../../Hooks/useAsyncTask"
import { MobergBoxShadow, MobergButton, MobergButtonShape, MobergIconSize, MobergRow } from '../../../Moberg'
import { MdAdd } from 'react-icons/md';
import { useLayoutService } from "../../../Hooks/useLayoutService"
import { SortableTabs } from "./SortableTabs"
import { DataReviewDisplayGroupsDropdown } from '../../../Components/DataReviewDropdownMenu/DataReviewDisplayGroupsDropdown';

function Footer() {
	const analysesProvider = useAnalysesProvider()
	const selectedLayoutGroup = useRecoilValue(selectedLayoutGroupAtom)
	const { addLayoutToLayoutGroup } = useLayoutService()
	const addLayoutTask = useAsyncTask(() => addLayoutToLayoutGroup(selectedLayoutGroup))

	useOnMount(() => {
		analysesProvider.update(analysesUpdates.ANCILLARY_FILTER)
	})

	const hasMaxDisplays = selectedLayoutGroup?.layouts.length > 9

	return (<>
		<div style={{ boxShadow: MobergBoxShadow.REGULAR, padding: "8px 16px" }}>
			<MobergRow gap="16px">
				<DataReviewDisplayGroupsDropdown />

				<HorizontalScrollBar style={{ overflowX: "auto", overflowY: "hidden", whiteSpace: "nowrap" }}>
					<SortableTabs />
				</HorizontalScrollBar>

				{selectedLayoutGroup && (
					<MobergButton
						shape={MobergButtonShape.SQUARE}
						onClick={addLayoutTask.run}
						disabled={addLayoutTask.isWaiting || hasMaxDisplays}
						tooltip={hasMaxDisplays ? "There cannot be more than 10 different displays." : ""}
					>
						<MdAdd size={MobergIconSize.REGULAR} />
					</MobergButton>
				)}
			</MobergRow>
		</div>
	</>)
}

const HorizontalScrollBar = styled.div`
	::-webkit-scrollbar {
		display: block;
		height: 8px;
		color: #313a4a;
		border-radius: 2px;
	}
	
	::-webkit-scrollbar-track {
		background: #bec4cf;
		border-radius: 2px;
	}
`;

export default Footer;