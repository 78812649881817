import React, { CSSProperties } from "react"
import { MobergFontFamily, MobergFontSize } from "../../Moberg"

type MobergInputLabelProps = {
	text: string
	style?: CSSProperties
}

export const MobergInputLabel: React.FC<MobergInputLabelProps> = ({ text, style }) => {
	return (
		<div
			style={{
				fontFamily: MobergFontFamily.REGULAR,
				fontSize: MobergFontSize.LARGE,
				fontWeight: "bold",
				...style
			}}
		>
			{text}
		</div>
	)
}
