import { createTheme } from 'react-data-table-component'

export const primary = {
  table: {
	style: {
	  color: "transparent",
      width: "auto",
	  backgroundColor: "transparent"
	}
  },
  tableWrapper: {
	style: {
	  display: "table",
	  borderRadius: "3px"
      
	}
  },
  rows: {
	style: {
	  color: "#293241",
	  fontFamily: "Source Sans Pro",
	  fontStyle: "normal",
	  fontWeight: "400",
	  fontSize: "14px",
      width: "auto",
	  lineHeight: "150%"
	},
    selectedHighlightStyle: {
        // use nth-of-type(n) to override other nth selectors
        '&:nth-of-type(n)': {
            color: "#207DEA",
            backgroundColor: "#E5F5FD",
        },
    },
    highlightOnHoverStyle: {
        color: "#207DEA",
        backgroundColor: "#E5F5FD",
        transitionDuration: '0.15s',
        transitionProperty: 'background-color',
        outlineStyle: 'solid',
        outlineWidth: '1px',
    },
  },
  header: {
	style: {
	  height: "40px",
	  borderCollapse: "separate",
	  margin: "0 0 8px",
	  padding: "0 50.8px 0 16px",
	  borderRadius: "3px",
	  width: "auto",
	  fontFamily: "Source Sans Pro",
	  fontStyle: "normal",
	  fontWeight: "600",
	  fontSize: "16px",
	  lineHeight: "150%"
	}
  },
  headRow: {
	style: {
	  height: "40px",
	  fontFamily: "Source Sans Pro",
	  fontStyle: "normal",
	  fontWeight: "600",
	  fontSize: "16px",
	  lineHeight: "150%",
	  margin: "0 0 8px",
	  borderRadius: "3px"
	}
  },
  headCells: {
	style: {
	  borderRight: "0.5px solid #CCCCCC",
      
	}
  },
  cells: {
	style: {
        padding: "0 16px 0 16px",
        borderRight: "0.5px solid #CCCCCC"
	}
  },
  pagination: {
	style: {
	  height: '36px',
	},
  }
};

export const secondary = {
  table: {
	borderCollapse: "collapse !important",
    border: "1px solid #B6B6B6",
    borderRadius: "3px !important"
  },
  tableWrapper: {
	style: {
        display: "table",
        border: "1px solid #B6B6B6",
        boxSizing: "border-box",
        borderRadius: "3px"
	}
  },

  rows: {
	style: {
	  color: "#293241",
	  fontFamily: "Source Sans Pro",
	  fontStyle: "normal",
	  fontWeight: "400",
	  fontSize: "14px",
	  lineHeight: "150%",
	  minHeight: '32px',
      '&:not(:last-of-type)': {
        borderBottomStyle: 'none',
        borderBottomWidth: '1px',
        borderBottomColor: 'none',
    },
	}
  },
  header: {
	style: {
	  height: "24px",
	  borderCollapse: "separate",
	  width: "auto",
	  fontFamily: "Source Sans Pro",
	  '&:not(:last-of-type)': {
        borderRight: "1px solid #B6B6B6",
	  },
	  fontStyle: "normal",
	  fontWeight: "600",
	  fontSize: "16px",
	}
  },
  headRow: {
	style: {
	  height: "24px",
	  fontFamily: "Source Sans Pro",
	  fontStyle: "normal",
	  fontWeight: "600",
	  fontSize: "16px",
	  borderCollapse: "separate",
	  borderRadius: "3px",
	  minHeight: '32px',
      '&:not(:last-of-type)': {
        borderRight: "1px solid #B6B6B6",
	  },
	}
  },
  headCells: {
	style: {
		'&:not(:last-of-type)': {
			borderRight: "1px solid #B6B6B6",
			},
		textAlign: "left"
	}
  },
  cells: {
	style: {
        padding: "0 50.8px 0 16px",
	    '&:not(:last-of-type)': {
			borderRight: "1px solid #B6B6B6",
		  },
        textAlign: "left"
	}
  },
  pagination: {
	style: {
	  color: "transparent",
	  fontSize: "13px",
	  minHeight: "56px",
	  backgroundColor: "transparent"
	}
  }
};

export const analysis = {
	table: {
	  borderCollapse: "collapse !important",
	  border: "1px solid #B6B6B6",
	  borderRadius: "3px !important",
	  fontSize: '11px'
	},
	tableWrapper: {
	  style: {
		  display: "table",
		  border: "1px solid #B6B6B6",
		  boxSizing: "border-box",
		  borderRadius: "3px",
		  fontSize: '11px'
	  }
	},
  
	rows: {
		style: {
			color: "#293241",
			fontFamily: "Source Sans Pro",
			fontStyle: "normal",
			fontWeight: "400",
			fontSize: "11px",
			lineHeight: "150%",
			width: '100%',
			'&:not(:last-of-type)': {
			borderBottomStyle: 'none',
			borderBottomWidth: '1px',
			borderBottomColor: 'none',
		},
	  }
	},
	header: {
	  style: {
		height: "24px",
		borderCollapse: "separate",
		width: "fit-content",
		fontFamily: "Source Sans Pro",
		borderRight: "1px solid #B6B6B6",
		fontStyle: "normal",
		fontWeight: "600",
		fontSize: "11px"
	  }
	},
	headRow: {
	  style: {
		height: "24px",
		fontFamily: "Source Sans Pro",
		fontStyle: "normal",
		fontWeight: "600",
		fontSize: "11px",
		borderCollapse: "separate",
		borderRadius: "3px",
		// borderRight: "1px solid #B6B6B6"
	  }
	},
	headCells: {
	  style: {
		borderRight: "1px solid #B6B6B6",
		display: 'flex',
		justifyContent: 'center'
		// textAlign: "left",
	  }
	},
	cells: {
		style: row => ({
			padding: "0 16px 0 16px",
			borderRight: "1px solid #B6B6B6",
			display: 'flex',
			justifyContent: 'center',
			backgroundColor: row.toggleSelected ? 'aqua' : 'inherit'
		})
	},
  };

export const progress = {
    progressBar: {
        backgroundColor: '#DBDBDB',
        borderColor: 'none',
        color: '#63C785',
        width: "157px",
        borderRadius: '9.5px'
    }
};