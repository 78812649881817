import React, { CSSProperties, forwardRef } from "react"

type MobergRowProps = {
	children?: React.ReactNode
	expand?: boolean
	wrap?: boolean
	gap?: string | number
	horizontalAlign?: string
	verticalAlign?: string
	style?: CSSProperties
}

export const MobergRow = forwardRef<HTMLDivElement, MobergRowProps>(({ children, gap, wrap, expand=true, verticalAlign = "center", horizontalAlign = "start", style }, ref) => {
	return (
		<div ref={ref} style={{ width: expand ? "100%" : "unset", display: "flex", gap, flexWrap: wrap ? "wrap" : "unset", alignItems: verticalAlign, justifyContent: horizontalAlign, ...style }}>
			{children}
		</div>
	)
})
