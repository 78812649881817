import { createContext, useContext, useState, useCallback, useEffect } from "react";
import { LINKS } from "../Constants/BackendLinks";
import { useAuthProvider } from "./AuthProvider";
import { postWithDependencyInjection } from "./EndpointProvider";
import { UpdateDispatcher } from "./UpdateDispatcher";

// TODO: This file is obsolete. Delete at the earliest convenience.

var PARENT_STUDY_ID = process.env.REACT_APP_PARENT_STUDY_ID

const StudiesContext = createContext()

export const studyUpdates = {
    STUDIES_FILTERS: "studies_filters",
    HAS_BUCKET: "selectedStudyHasBucket"
}

export const StudiesProvider = ({ children }) => {
    const authProvider = useAuthProvider()
    const [selectedStudyId, setSelectedStudyId] = useState(PARENT_STUDY_ID) 
    const [studies, setStudies] = useState([])
    const [filters, setFilters] = useState({})
    const [selectedStudyHasBucket, setSelectedStudyHasBucket] = useState(false)

    console.log("PROVIDER RENDER: STUDIES", {
        selectedStudyId,
        studies,
        filters,
        selectedStudyHasBucket
    })

    async function update(...updates) {
        const initState = {
            [studyUpdates.STUDIES_FILTERS]: {data: studies, filters},
            [studyUpdates.HAS_BUCKET]: selectedStudyHasBucket
        }
        const dispatcher = new UpdateDispatcher(updates, initState, studyUpdates)
        console.log("UPDATE STUDIES PROVIDER", updates)

        dispatcher.dispatch(studyUpdates.HAS_BUCKET,
            () => postWithDependencyInjection(authProvider.token, PARENT_STUDY_ID, LINKS.DATA.UPLOAD.CHECK_STUDIES_HAVE_BUCKET, 
                { study_ids: [selectedStudyId] }),
            data => setSelectedStudyHasBucket(data.length > 0))

        dispatcher.dispatch(studyUpdates.STUDIES_FILTERS,
            () => postWithDependencyInjection(authProvider.token, PARENT_STUDY_ID, LINKS.ADMIN.STUDIES.GET_STUDIES, 
                { study_ids: [selectedStudyId] }, 
                { return_ancillaries: true }),
            data => {
                setStudies(data['data'])
                setFilters(data['filters'])
            })
    }

    const deleteStudy = useCallback(async (study_id) => {
        return postWithDependencyInjection(authProvider.token, PARENT_STUDY_ID, LINKS.ADMIN.STUDIES.DELETE_STUDY, {study_id})
    }, [authProvider.token])

    const createStudy = useCallback(async (name, study_type, study_id, start_date) => {
        const body = {name, study_type, study_id, start_date, parent_study_id: PARENT_STUDY_ID}

        const response = await postWithDependencyInjection(authProvider.token, PARENT_STUDY_ID, LINKS.ADMIN.STUDIES.CREATE_STUDY, body)

        setStudies(prevStudies => {
            return {...prevStudies, [study_id]: {study_id, selected: false}}
        })

        return response;

    }, [authProvider.token])

    // Make sure we don't persist any data between logins.
    useEffect(() => {
        if (!authProvider.userIsLoggedIn && studies.length > 0) {
            setStudies([])
        }
    }, [authProvider.userIsLoggedIn, studies])

    return (
        <StudiesContext.Provider value={{parentStudyId: PARENT_STUDY_ID, selectedStudyId, studies, filters, selectedStudyHasBucket, setSelectedStudyId, update, createStudy, deleteStudy}}>
            {children}
        </StudiesContext.Provider>
    )
}

export const useStudiesProvider = () => {
    return useContext(StudiesContext)
}