import { useState, useCallback, useEffect } from 'react';
import "../TableComponent/styles.css";
import DataTable from "react-data-table-component";
import React from "react";
import "bootstrap/dist/js/bootstrap.bundle.js";
import "bootstrap/dist/css/bootstrap.css";
import styled from "styled-components";
import * as MdIcons from 'react-icons/md';
import { primary, secondary, analysis } from "../TableComponent/styles";
import { PaginationController, PaginationComponent } from "./Pagination"
import { rowIds } from "./SelectHandler"
import { useMemo } from 'react';


function getNumberOfPages(rowCount, rowsPerPage) {
    return rowCount > 0 ? Math.ceil(rowCount / rowsPerPage) : 1
}

function BuildTable(props, tableConstructor) {
    // statics of the table
    const [tableId,] = useState(props.id ?? `DenseTable-${Math.random()}-${Date.now()}`)
    // const [uniqueRef,] = useState(props.uniqueRef ?? "id")
    const [rowsEachPage,] = useState(props.rowsPerPage ?? 10) // default is 10

    // variables need to be remembered across rerenders
    const [totalRows, setTotalRows] = useState(0)
    const [currentPageNum, setCurrentPageNum] = useState(1) // start from 1 but not 0
    const [selectedRowIds, setSelectedRowIds] = useState([])
    // const [clearSelectedRows, setClearSelectedRows] = useState(props.clearSelectedRows??false)
    const [preSelectInited, setPreSelectInited] = useState(props.selectableRowSelected ? false : true)

    // variables re-calculated each rerender
    const maxPageNum = getNumberOfPages(props.data.length, rowsEachPage)
    // const displayedRows = props.data.slice((currentPageNum-1)*rowsEachPage, currentPageNum*rowsEachPage)

    const [toggledClearRows, setToggleClearRows] = useState(false)

    // create id for each row, so later we can track them
    // const [data, setData] = useState([])
    const resetData = () => {
        // const newData = props.data.map( 
        //     (row, index) => ({
        //         ...row,
        //         id: index,
        //         _id_: row["id"]
        //     })
        // )
        // setData(newData)
        setToggleClearRows(!toggledClearRows)
    }

    // wrap the onRowClicked method
    const onRowClicked = useCallback((clickedRow) => {
        const _clickedRow = { ...clickedRow, row_id: clickedRow.id }
        if (clickedRow._id_ !== undefined) { _clickedRow.id = clickedRow._id_ }
        delete _clickedRow._id_
        // console.log("[Table] click row:", _clickedRow)
        if (props.onRowClicked) {
            props.onRowClicked(_clickedRow)
        }
    }, [])

    // wrap the onSelectedRowsChange method, only allowed to select the displayed ones
    const onSelectedRowsChange = useCallback(({ selectedRows, force = false }) => {
        if (props.onSelectedRowsChange) {
            // // only allow to select the displayed rows
            // const displayedRowIds = rowIds(tableId)
            // const displayedselectedRows = selectedRows.filter(row => displayedRowIds.includes(row.id))

            if (selectedRows.length !== selectedRowIds.length || force) {
                const _selectedRows = selectedRows.map(row => {
                    const _row = { ...row, row_id: row.id }
                    if (row._id_ !== undefined) { _row.id = row._id_ }
                    delete _row._id_
                    return _row
                })
                setSelectedRowIds(_selectedRows.map(r => r.row_id))
                console.log("[Table] onSelectedRowsChange:", _selectedRows)

                // if (props.onSelectedRowsChange) {
                props.onSelectedRowsChange({ selectedRows: _selectedRows })
            }
        }
    }, [tableId, selectedRowIds.length, props])

    const selectedRowsHandler = useCallback(({ selectedRows }) => {
        const _selectedRows = selectedRows.map(row => {
            const _row = { ...row, row_id: row.id }
            if (row._id_ !== undefined) { _row.id = row._id_ }
            delete _row._id_
            return _row
        })
        if (props.onSelectedRowsChange) {
            props.onSelectedRowsChange({ selectedRows: _selectedRows })
        }
    }, [props])

    // unselect all the selected rows
    const unSelectAll = useCallback(() => {
        onSelectedRowsChange({ selectedRows: [] }) // update outside
        // console.log("[Table] unSelectAll")
    }, [onSelectedRowsChange])


    const selectAll = useCallback(() => {
        const ids = rowIds(tableId)
        const rows = props.data.filter(row => ids.includes(row.id))
        onSelectedRowsChange({ selectedRows: rows })
        console.log("[Table] selectAll")
    }, [props.data, onSelectedRowsChange, tableId])

    const [sortButtonInit, setSortButtonInit] = useState(false)

    // overwrite the behavior of select-all checkbox
    useEffect(() => { // not used anymore
        // let currentSelectAllCheckbox = selectAllCheckbox(tableId)

        //  // if VDOM doesn't render the table, currentSelectAllCheckbox is undefined
        //  // if DOM doesn't render the table, .offsetParent is null
        // if (currentSelectAllCheckbox !== undefined && currentSelectAllCheckbox.offsetParent !== null) {
        //     // all the executions below can produce effect only when the table is in DOM(visible)
        //     const newSelectAllCheckbox = currentSelectAllCheckbox.cloneNode(true)
        //     const doSelectSome = selectedRowIds.length !== 0
        //     const displayedRowIds = rowIds(tableId)

        //     if ((doSelectSome) && (displayedRowIds.length === selectedRowIds.length)) {
        //         newSelectAllCheckbox.onclick = unSelectAll
        //         newSelectAllCheckbox.checked = true
        //         newSelectAllCheckbox.indeterminate = false
        //         // console.log("checkbox: selected")
        //     }

        //     else if ((doSelectSome) && (displayedRowIds.length > selectedRowIds.length)){
        //         newSelectAllCheckbox.onclick = selectAll
        //         newSelectAllCheckbox.checked = false
        //         newSelectAllCheckbox.indeterminate = true
        //         // console.log("checkbox: semi-selected")
        //     }

        //     else if (!doSelectSome) { // noRowSelect: selectedRows.length = 0
        //         newSelectAllCheckbox.onclick = selectAll
        //         newSelectAllCheckbox.checked = false
        //         newSelectAllCheckbox.indeterminate = false
        //         // console.log("checkbox: unselected")
        //     } else {
        //         console.log("selected rows more than displayed rows!!!", selectedRowIds, displayedRowIds)
        //     }

        //     // replace the checkbox
        //     currentSelectAllCheckbox.parentNode.replaceChild(newSelectAllCheckbox, currentSelectAllCheckbox);
        // }
    }, [onSelectedRowsChange, selectedRowIds, unSelectAll, selectAll, tableId]) // run every rerender

    useEffect(() => {
        if (!sortButtonInit) {
            const header = document.getElementById(tableId)?.children[0]?.children[0]?.children[0]?.children[0]?.children[0].children[0]
            if (header !== undefined && header.offsetParent !== null && header.children.length > 0) {
                [...header.children].slice(1).forEach(col => {
                    const sortButton = col.children[0]
                    if (sortButton) {
                        sortButton.addEventListener(
                            "click",
                            () => { console.log("[Table] reorder"); onSelectedRowsChange({ selectedRows: [], force: true }) }
                        )
                    }
                });
                setSortButtonInit(true)
            }
        }
    }, [sortButtonInit, tableId, onSelectedRowsChange])

    // shared by PaginationController and PaginationComponent
    const switchPage = useCallback((newPageIndex) => {
        if (props.selectableRows) { unSelectAll() }
        setCurrentPageNum(newPageIndex)
        // console.log("[Table] switchPage:", newPageIndex)
    }, [props.selectableRows, unSelectAll])

    // handle the change in total-rows(props.data.length), it could be adding new row(s) or deletion
    useEffect(() => {
        if (props.data.length !== totalRows) {
            setTotalRows(props.data.length)
            resetData()
            switchPage(1)
            if (totalRows !== 0) {
                unSelectAll()
            }
        }

        if (props.selectableRowSelected && !preSelectInited) {
            const preSelectedRows = props.data.filter(props.selectableRowSelected)
            if (preSelectedRows.length > 0) {
                onSelectedRowsChange({ selectedRows: preSelectedRows })
                setPreSelectInited(true)
                console.log("[Table] preselected rows init:", preSelectedRows)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.data, totalRows]) // do not add extra dependency for we don't really need them

    useEffect(() => {
        resetData()
    }, [props.data])

    useEffect(() => { // Reminder
        if (props.selectableRows && !props.onSelectedRowsChange) {
            console.warn(`[${tableId}] is selectable. \nHowever, there is no [onSelectedRowsChange]`
                + " attribute provided. \nProgram will never be able to track the selected rows!")
        }
    }, [])

    const columns = useMemo(() => props.columns.map((col) => {
        return {
            ...col,
            selector: !(col.selector instanceof Function) ? col.selector :
                (row) => {
                    const _row = { ...row, row_id: row.id }
                    if (row._id_ !== undefined) { _row.id = row._id_ }
                    delete _row._id_
                    return col.selector(_row)
                }
        }
    }), [props.columns])

    const args = {
        ...props,
        data: props.data,
        columns,
        paginationComponent: PaginationController,
        paginationComponentOptions: {
            rowsEachPage: rowsEachPage,
            currentPageNum: currentPageNum,
            switchPage: switchPage,
            maxPageNum: maxPageNum,
            // add args here for further usage...
        },
        clearSelectedRows: toggledClearRows,
    }
    if (props.selectableRows) {
        args.selectableRows = props.selectableRows
        // args.selectableRowSelected = row => selectedRowIds.includes(row.id) // the row.id here is the row_id
        args.onSelectedRowsChange = selectedRowsHandler
    }
    if (props.onRowClicked) {
        args.onRowClicked = onRowClicked
    }


    return (<>
        <HorizontalScrollContainer id={tableId} style={props.wrapperStyle}>
            <HorizontalScrollContent>
                {tableConstructor(args)}
            </HorizontalScrollContent>
        </HorizontalScrollContainer>
        <PaginationComponent
            currentPageNum={currentPageNum}
            maxPageNum={maxPageNum}
            switchPage={switchPage}
        />
    </>)
}


MainTable.defaultProps = {
    paginate: true,
    selectableRows: true,
    customStyles: primary,
    defaultSortFieldID: 1
}


function MainTable(props) {
    const buildCallback = (props) => (
        <DataTable
            columns={props.columns}
            data={props.data}
            defaultSortFieldID={props.defaultSortFieldID}
            sortIcon={<MdIcons.MdUnfoldMore style={{ color: "#207DEA" }} />}
            pagination={props.pagination ?? props.paginate}
            selectableRows={props.selectableRows ?? props.selectRows}
            selectableRowsSingle={props.selectableRowsSingle}
            selectableRowSelected={props.selectableRowSelected}
            selectableRowsHighlight
            onRowClicked={props.onRowClicked}
            onSelectedRowsChange={props.onSelectedRowsChange}
            clearSelectedRows={props.clearSelectedRows}
            highlightOnHover
            pointerOnHover
            dense
            customStyles={props.customStyles ?? secondary}
            theme="main"
            progressPending={props.progressPending}
            conditionalRowStyles={props.conditionalRowStyles}
            selectableRowsNoSelectAll={props.selectableRowsNoSelectAll}
            selectableRowsPreSelectedField={props.selectableRowsPreSelectedField}
            paginationComponent={props.paginationComponent}
            paginationComponentOptions={props.paginationComponentOptions}
        />
    );
    return BuildTable(props, buildCallback)
}

DenseTable.defaultProps = {
    paginate: true,
    expandRows: false,
    defaultSortFieldID: 1
};

function DenseTable(props) {
    const buildCallback = (props) => (
        <DataTable
            columns={props.columns}
            data={props.data}
            defaultSortFieldID={props.defaultSortFieldID}
            sortIcon={<MdIcons.MdUnfoldMore style={{ color: "#207DEA" }} />}
            selectableRows={props.selectableRows}
            onRowClicked={props.onRowClick}
            expandableRows={props.expandRows}
            expandableRowsComponent={props.expandComponent}
            dense
            striped
            persistTableHead
            customStyles={secondary}
            responsive
            conditionalRowStyles={props.conditionalRowStyles}
            progressPending={props.progressPending}
            sortFunction={props.sortFunction}
            pagination
            paginationComponent={props.paginationComponent}
            paginationComponentOptions={props.paginationComponentOptions}
        />
    );
    return BuildTable(props, buildCallback)
}

function AnnotationTable(props) {
    return (
        <HorizontalScrollDiv
            style={{ borderRadius: "3px", width: "89%", marginRight: "13px", marginLeft: "13px", marginTop: "15px", maxHeight: '73%', position: 'absolute', right: '3%', cursor: 'pointer' }}>
            <DataTable
                columns={props.columns}
                data={structuredClone(props.data)}
                defaultSortFieldID={props.defaultSortFieldID}
                sortIcon={<MdIcons.MdUnfoldMore style={{ color: "#207DEA" }} />}
                // pagination
                // paginationComponent={Pagination}
                onRowClicked={props.onRowClicked}
                expandableRows={props.expandRows}
                expandableRowsComponent={props.expandComponent}
                dense
                striped
                persistTableHead
                customStyles={analysis}
                responsive
                conditionalRowStyles={props.conditionalRowStyles}
                progressPending={props.progressPending}
                sortFunction={props.sortFunction}
            />
        </HorizontalScrollDiv>
    );
}

const HorizontalScrollDiv = styled.div`
    overflow-x: auto;
	::-webkit-scrollbar {
		display: block;
		height: 4px;
		color: #313a4a;
		border-radius: 2px;
	}
	::-webkit-scrollbar-track {
		background: #bec4cf;
		height: 4px;
		border-radius: 2px;
	}
`;


const HorizontalScrollContainer = styled.div`
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // width: inherit;
    overflow-x: scroll;
    overflow-y: visible;

    ::-webkit-scrollbar {
    display: block;
    width: 5px;
    height: 5px;
    color: #fff0;
    }
    ::-webkit-scrollbar-track {
    background: #bfbfbf3d;
    border-radius: 3px;
    }
    ::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
    }
    ::-webkit-scrollbar-thumb {
    border-radius: 3px;
    }
    ::-webkit-scrollbar-thumb:hover {
    background: #86868680; 
    }
`;

const HorizontalScrollContent = styled.div`
    width: 100%;
`


export { MainTable, DenseTable, AnnotationTable };