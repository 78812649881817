export const columns = [
    {
        name: "Report Name",
        selector: (row) => row.report_name,
        sortable: true
    },
    {
        name: "Created By",
        selector: (row) => row.created_by,
        sortable: true
    },
    {
        name: "Versions",
        selector: (row) => row.version,
        sortable: true
    }
];

export const rows = [
      {
    report_name: "Report Name",
    created_by: "Alisha Augustin",
    version: "1"
    },
        {
        report_name: "Report Name",
        created_by: "Alisha Augustin",
        version: "2"
        },
        {
        report_name: "Report Name",
        created_by: "Alisha Augustin",
        version: "3"
        },
        {
        report_name: "Report Name",
        created_by: "Alisha Augustin",
        version: "1"
        },
        {
        report_name: "Report Name",
        created_by: "Alisha Augustin",
        version: "1"
        },
        {
        report_name: "Report Name",
        created_by: "Alisha Augustin",
        version: "1"
        },
        {
        report_name: "Report Name",
        created_by: "Alisha Augustin",
        version: "1"
        },
        {
        report_name: "Report Name",
        created_by: "Alisha Augustin",
        version: "1"
        },
        {
        report_name: "Report Name",
        created_by: "Alisha Augustin",
        version: "1"
        },
        {
        report_name: "Report Name",
        created_by: "Alisha Augustin",
        version: "1"
        },
        {
        report_name: "Report Name",
        created_by: "Alisha Augustin",
        version: "1"
        },
        {
        report_name: "Report Name",
        created_by: "Alisha Augustin",
        version: "1"
        },
        {
        report_name: "Report Name",
        created_by: "Alisha Augustin",
        version: "1"
        }
];