import { atom } from "recoil";
import { KeyboardShortcut, triggerTypes } from "../Types/KeyboardShortcut";

export const hotkeysAtom = atom({
    key: "hotkeys",
    default: [] as KeyboardShortcut[],
    effects: [
        ({ onSet }) => onSet(value => console.log("hotkeys", value))
    ]
})

export const hotkeysEnabledAtom = atom<boolean>({
    key: "hotkeysEnabled",
    default: true
})

export function updateHotkeyTrigger(config: KeyboardShortcut, triggerType: string, newTrigger: string) {
    switch(triggerType) {
        case triggerTypes.PRIMARY:
            config.triggers[0] = newTrigger
            break;
        case triggerTypes.SECONDARY:
            config.triggers[1] = newTrigger
            break;
        default:
            throw new Error("Bad trigger type: " +  triggerType)
    }

    return config
}

export const loadHotkeysFromConfigs = (hotkeyConfigs: KeyboardShortcut[]) => {
    return hotkeyConfigs.map((config: KeyboardShortcut, index: number) => {

        if (!config.id) {
            config.id = `TEMP-${Math.random()}-${Date.now()}}-${index}`
        }

        // For custom annotations. The action handler needs a reference to the hotkey id
        if (config.data) {
            config.data.id = config.id
        } 

        config.triggers.length = 2
        for (let i = 0; i < 2; i++) {
            if (config.triggers[i] === undefined) {
                config.triggers[i] = null
            }
        }
        return config
    })
}
