import React, { createContext, useContext } from "react"
import { Deployment, useEnvironmentVariablesProvider } from "./EnvironmentVariablesProvider"
import { LINKS, SubDomainKey, TEMPLATE_SUBDOMAINS } from "./BackendLinks"

type BackendLinksContextValue = {
	LINKS: typeof LINKS
}

const BackendLinksContext = createContext<BackendLinksContextValue>({} as any)

export const BackendLinksProvider: React.FC = ({ children }) => {
	const environmentVariablesProvider = useEnvironmentVariablesProvider()
	const { environmentVariables, asDevelopmentUrl } = environmentVariablesProvider

	const SUBDOMAINS = {
		QUERY: environmentVariables?.QUERY_ORIGIN_URL ?? "",
		QUERY_PREFETCH: environmentVariables?.QUERY_PREFETCH_ORIGIN_URL ?? "",
		IAM: environmentVariables?.IAM_ORIGIN_URL ?? "",
		MESS: environmentVariables?.MESS_ORIGIN_URL ?? "",
		UPLOAD: environmentVariables?.UPLOAD_ORIGIN_URL ?? "",
	}

	// Find the actual subdomain that should be used to replace the template subdomain
	const replaceLink = (link: string, subDomains: typeof TEMPLATE_SUBDOMAINS): string => {
		const entry = Object.entries(subDomains).find(([subDomainKey]) => link.startsWith(TEMPLATE_SUBDOMAINS[subDomainKey as SubDomainKey]))

		if (!entry) {
			return link
		}

		const [subDomainKey, subDomain] = entry
		const template = TEMPLATE_SUBDOMAINS[subDomainKey as SubDomainKey]

		return link.replace(template, subDomain)
	}

	// Parse all of the backend links according to the environment subdomains
	const parseLinks = (links: Record<string, any>, subdomains: typeof TEMPLATE_SUBDOMAINS) => {
		if (window.env.REACT_APP_DEPLOYMENT === Deployment.DEVELOPMENT) {
			Object.entries(subdomains).forEach(([key, subDomain]) => {
				subdomains[key as SubDomainKey] = asDevelopmentUrl(subDomain)
			})
		}

		if (links === null || typeof links !== "object") {
			return
		}

		Object.entries(links).forEach(([key, value]) => {
			if (typeof value === "string") {
				links[key] = replaceLink(value, subdomains)
			} else if (typeof value === "object") {
				parseLinks(value, subdomains)
			}
		})
	}

	const backendLinks = structuredClone(LINKS)
	parseLinks(backendLinks, SUBDOMAINS)

	return (
		<BackendLinksContext.Provider value={{ LINKS: backendLinks }}>
			{children}
		</BackendLinksContext.Provider>
	)
}

export const useBackendLinksProvider = () => useContext(BackendLinksContext)
