import MUITable from "../../../Components/MUITable/MUITable"
import { useModalProvider } from "../../../Providers/ModalProvider"
import CreateProjectModal from "./Subcomponents/CreateProjectModal"
import ModifyProjectModal from "./Subcomponents/ModifyProjectModal"
import { useEndpointProvider } from "../../../Providers/EndpointProvider"
import EyeIcon from "../../../Components/Icons/EyeIcon"
import { MdOutlineModeEdit } from "react-icons/md"
import { useBackendLinksProvider } from "../../../Providers/BackendLinksProvider"
import { GridActionsCellItem } from "@mui/x-data-grid"

export default function Studies() {
	const { createModal, close } = useModalProvider()
	const endpointProvider = useEndpointProvider()
	const { LINKS } = useBackendLinksProvider()

	const handleClick = (row, viewMode = false) => {
		createModal(<ModifyProjectModal style={{ height: "80%" }} close={close} row={row} escClose={false} clickOutsideClose={false} viewMode={viewMode} />)
	}

	const columns = [
		{ field: "identifier", flex: 0.4, headerName: "Project ID", minWidth: 90, visible: true },
		{ field: "name", flex: 0.4, headerName: "Name", minWidth: 70, visible: true },
		{ field: "bucket_name", flex: 0.4, headerName: "Repository", minWidth: 115, visible: true },
		{ field: "user_count", flex: 0.4, headerName: "No. users", valueGetter: params => params.row.user_count, minWidth: 110, visible: true },
		{ field: "patient_count", flex: 0.4, headerName: "No. patients", valueGetter: params => params.row.patient_count, minWidth: 110, visible: true },
		{
			field: "actions",
			flex: 0.4,
			headerName: "Actions",
			minWidth: 120,
			type: "actions",
			getActions: params => [
				<GridActionsCellItem icon={<EyeIcon />} label="View" onClick={() => handleClick(params.row, true)} showInMenu />,
				<GridActionsCellItem icon={<MdOutlineModeEdit size={20} />} label="Edit" onClick={() => handleClick(params.row)} showInMenu />,
			],
			visible: true,
		},
	]

	function renderProjectModal() {
		createModal(<CreateProjectModal style={{ height: "80%" }} close={close} escClose={false} clickOutsideClose={false} />)
	}

	const studyTableToolbarProps = {
		createButton: { title: "Create project", isShow: true, onClick: renderProjectModal },
		deleteButton: {
			title: "Delete",
			isShow: true,
			onSuccessMessage: "Projects deleted successfully",
			invalidateQueryKey: "projects",
			onClick: project_ids => endpointProvider.post(LINKS.ADMIN.PROJECTS.DELETE_PROJECTS, { project_ids }),
		},
		viewMoreButton: {
			title: "View more",
			isShow: true,
		},
		filterButton: { title: "Filter", isShow: false },
		exportButton: { isShow: false },
		searchInput: { isShow: true },
		columnButton: { isShow: true },
		nameForMultipleRows: "projects"
	}

	const queryProps = {
		queryKey: "projects",
		endpoint: LINKS.ADMIN.PROJECTS.GET_PROJECTS,
	}

	return (
		<MUITable columns={columns} tableToolbarProps={studyTableToolbarProps} {...queryProps} />
	)
}
