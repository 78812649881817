import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { DEFAULTS } from "../../../Constants/Formatting.js";
import { LoginInput } from "../../../Components/Input/Input";
import styled from "styled-components";
import "@fontsource/montserrat";
import { FRONTEND_LINKS } from "../../../Constants/FrontendLinks.js";
import { useEndpointProvider } from "../../../Providers/EndpointProvider.js";
import * as MdIcons from 'react-icons/md';
import { BackButtonDiv, NormalText, InfoMessage } from "../../../Constants/StyledComponents";
import { useBackendLinksProvider } from "../../../Providers/BackendLinksProvider";
import { useWorkspacesProvider } from "../../../Providers/WorkspacesProvider"

const ForgotPassword = (props) => {
	const endpointProvider = useEndpointProvider()
	const { LINKS } = useBackendLinksProvider()
	const selectedWorkspace = useWorkspacesProvider()
	const [email, setEmail] = useState('');
	const [disableSendEmailButton, setDisableSendEmailButton] = useState(true)
	const [showEmailInfoMessage, setShowEmailInfoMessage] = useState(false)
	let navigate = useNavigate();

	useEffect(() => {
		// auth.logout()
		props.setSidebar(false)
		props.setNavPage(false)
	}, [])


	const resetPassword = useCallback((email) => {
		let body = {
			email: email
		}

		return endpointProvider.post(LINKS.LOGIN.RESET_PASSWORD, body)
	}, [endpointProvider])

	function handleSendEmail() {
		setDisableSendEmailButton(true)
		setShowEmailInfoMessage(true)
	}

	function resetPage() {
		setDisableSendEmailButton(false)
		setShowEmailInfoMessage(false)
	}

	function handleResetPassword(e) {
		if (e) {
			e.preventDefault()
		}

		if (email === '' || !email.includes('@')) {
			alert('Enter a valid email.')
			return
		}

		handleSendEmail()
		resetPassword(email).then((text) => {
			alert("Please check your email to reset your password.")
			navigate(FRONTEND_LINKS.MAIN)
		}).catch(e => {
			alert(e)
			resetPage()
		})
	}

	function handleBackToLogin() {
		navigate(FRONTEND_LINKS.MAIN);
	}

	useEffect(() => {
		if (email.length > 0 && (email.includes('@'))) {
			setDisableSendEmailButton(false)
		} else {
			setDisableSendEmailButton(true)
		}
	}, [email])

	return (
		<Container1 style={{ background: `${selectedWorkspace === "CURINGCOMA" ? "#F4F6F9" : "#EECFF"}` }}>
			<BackButtonDiv onClick={() => { handleBackToLogin() }}>
				<MdIcons.MdOutlineArrowRightAlt size={32} style={{ cursor: "pointer", marginRight: "5px", marginTop: "-4px", transform: "rotate(180deg)" }} />
				<NormalText>Back</NormalText>
			</BackButtonDiv>
			<Wrapper>
				<Container2 top={`${window.innerHeight / 2 - 120}px`} left={`${(window.innerWidth - 303) / 2}px`}>
					<Form>
						<h1>Forgot Password</h1>
						<div style={{ display: "inline-flex", height: "16px", marginBottom: "14px" }}>
							<h2>EMAIL</h2><p style={{ color: "#EA3D3D", fontSize: "30px", marginLeft: "4px", marginTop: "9px" }}>*</p>
						</div>
						<LoginInput
							type="email"
							placeholder="Email"
							value={email || ""}
							onChange={(e) => setEmail(e.target.value)}
						/>
						<button
							style={{ background: "#ee6c4e", color: "#FFFFFF", pointerEvents: disableSendEmailButton ? "none" : "all", opacity: disableSendEmailButton ? "0.3" : "1", cursor: disableSendEmailButton ? "default" : "pointer" }}
							onClick={(e) => { handleResetPassword(e) }}>Send email</button>
						<InfoMessage style={{ width: "874px", display: showEmailInfoMessage ? "flex" : "none", marginLeft: "auto", marginRight: "auto", marginTop: "8px" }}><p><strong>Info!</strong> Email is sending...</p></InfoMessage>
					</Form>
				</Container2>
			</Wrapper>
		</Container1>
	);
};

const Container1 = styled.div`
	background: #eefcff;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
`;

const Wrapper = styled.div`
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	width: 100%;
	height: 100%;
	display: flex;
`;

const Form = styled.form`
	display: flex;
	width: 100%;
	width: 304px;
	height: 441px;
	top: 284px;
	flex-direction: column;
	/* align-items: center; */
	overflow: hidden;
	/* align-content: center; */
	h1 {
		margin-bottom: 0;
		margin-left: 3.5px;
		color: #293241;
		font-family: "Montserrat";
		font-style: normal;
		font-weight: 700;
		font-size: 32px;
		line-height: 39px;
	}
	h2 {
		font-family: "Montserrat";
		font-weight: 550;

		// width: 304px;
		width: fit-content;
		font-size: 14px;
		color: #293241;
		padding: 0;
		margin: 0;
		margin-top: 15px;
		margin-left: 6px;
	}
	button {
		width: 100%;
		max-width: 350px;
		min-width: 250px;
		height: 40px;
		border: none;
		margin: 1rem 0;
		margin-top: 0;
		box-shadow: 0px 14px 9px -15px rgba(0, 0, 0, 0.25);
		border-radius: 8px;
		background-color: #ee6c4e;
		color: #fff;
		font-weight: 600;
		cursor: pointer;
		transition: all 0.2s ease-in;
		&:hover {
			transform: translateY(-3px);
		}
	}
`;

const Container2 = styled.div`
	width: 100%;
	position: absolute;
	width: 284px;
	height: 39px;
	/* left: 604px; */
	/* top: 273px; */
	 left:  ${({ left }) => (left)};
    top:  ${({ top }) => (top)}; 
	padding: ${DEFAULTS.CELL_PADDING};
	align-items: center;
	align-content: center;
`;

export default ForgotPassword;

