export const CreatePipelineTableColumns = [
    {
        name: "Metric",
        selector: (row) => row.metric,
        sortable: true
    },
    {
        name: "Created By",
        selector: (row) => row.created_by,
        sortable: true
    },
    {
        name: "Last Modified",
        selector: (row) => row.last_modified,
        sortable: true
    },
    {
        name: "Versions",
        selector: (row) => row.versions,
        sortable: true
    }
]

export const CreatePipelineTableRows = [
    {
        metric: "CPPOpt",
        created_by: "John Doe",
        last_modified: "August 8, 2022",
        versions: "12"
    },
    {
        metric: "ECG",
        created_by: "Jake Doe",
        last_modified: "August 22, 2022",
        versions: "1"
    },
    {
        metric: "CPPOpt 2",
        created_by: "John Doe 2",
        last_modified: "August 10, 2022",
        versions: "5"
    }
]