import { TimeBasedVisualizationConfigJSON } from "./TimeBasedVisualizationConfig"
import { TraceDataConfig, WithSolidColor } from "./Trace"

export type HistogramJSON = TimeBasedVisualizationConfigJSON & {
    modalityConfigs: HistogramModalityConfig[]
    binMinimum: number
    binMaximum: number
    binSize: number
}

export const DEFAULT_HISTOGRAM_JSON: HistogramJSON = {
    modalityConfigs: [],
    binMinimum: 0,
    binMaximum: 100,
    binSize: 10,
    viewDuration: 60000,
    playbackSpeed: 1
}

export type HistogramModalityConfig = TraceDataConfig & WithSolidColor
