import { PageType } from "../Types/PageType"
import { Page } from "./Page"
import { CompositeTimeSeriesData, TimeSeriesData } from "./TimeSeriesData"

export class ModalityPage extends Page<TimeSeriesData | CompositeTimeSeriesData> {
    cleanUpRenderedData(renderedData: ImageBitmap): void {
        renderedData.close()
    }
    
    getType(): PageType {
        return PageType.MODALITY
    }

}