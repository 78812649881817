import { MobergButton, MobergButtonVariant, MobergButtonShape } from "../MobergButton/MobergButton";
import { MobergFontSize } from "../MobergFont/MobergFont";
import { MobergTheme } from "../MobergThemes/MobergColors";

export const buttonColor = Object.freeze({
    blueFill : "blue-fill",
    blueOutline: "blue-outline",
    redFill: "red-fill",
    redOutline: "red-outline"
})

function ConfirmationModalButton({ buttonText="", onClickFxn, disabled=false, buttonStyle={}, color=buttonColor.blueFill }) {
    let theme, variant

    // This is just to prevent me having to go change this in a million different places.
    switch (color) {
        case buttonColor.blueFill:
            theme = MobergTheme.BLUE
            variant = MobergButtonVariant.FILLED
            break
        case buttonColor.blueOutline:
            theme = MobergTheme.BLUE
            variant = MobergButtonVariant.OUTLINE
            break
        case buttonColor.redFill:
            theme = MobergTheme.RED
            variant = MobergButtonVariant.FILLED
            break
        case buttonColor.redOutline:
            theme = MobergTheme.RED
            variant = MobergButtonVariant.OUTLINE
            break
        default:
    }

    return(<>
        <MobergButton onClick={onClickFxn ? onClickFxn : null} disabled={disabled} theme={theme} variant={variant} shape={MobergButtonShape.FULL_WIDTH}
            fontSize={MobergFontSize.LARGE} style={{marginTop: "18px", height: "55px"}}>
            {buttonText}
        </MobergButton>
    </>)
}

export default ConfirmationModalButton;