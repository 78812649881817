import MUITable from "../../../../Components/MUITable/MUITable"
import { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { Visualization } from '../../../../Managers/VisualizationManager/Visualization';
import styled from 'styled-components';
import "@fontsource/source-sans-pro";
import * as MdIcons from 'react-icons/md';
import '../../../../index.css';
import { FRONTEND_LINKS } from '../../../../Constants/FrontendLinks';
import { authUpdates, useAuthProvider } from '../../../../Providers/AuthProvider';
import { useEndpointProvider } from '../../../../Providers/EndpointProvider';
import { useModalProvider } from '../../../../Providers/ModalProvider';
import { MobergButton, MobergButtonShape, MobergButtonVariant } from '../../../../Components/MobergButton/MobergButton';
import { MobergTheme } from '../../../../Components/MobergThemes/MobergColors';
import { MobergIconSize } from '../../../../Components/MobergIcon/MobergIcon';
import { MobergDropdown } from '../../../../Components/MobergDropdown/MobergDropdown';
import { useOnMount } from "../../../../Hooks/useOnMount";
import { localizeDate } from "../../../../Computation/utilFunctions";
import SubpageTabs from "../../../../Components/SubpageTabs/SubpageTabs";
import { useRecoilState } from "recoil";
import { currentPatientFileInfoAtom } from "../DataReview/Atoms/PatientFile";
import { AllUploadsPending } from "./Components/AllUploadsPending";
import { SubHeader } from "./Components/SubHeader";
import { useBackendLinksProvider } from "../../../../Providers/BackendLinksProvider";
import { useEnvironmentVariablesProvider } from "../../../../Providers/EnvironmentVariablesProvider";

const prefetchId = Math.floor(Math.random() * 10000)

function determineSeverity(percentage) {
	if (percentage > 80) {
		return 'green'
	} else if (percentage < 25) {
		return 'red'
	} else {
		return 'yellow'
	}
}

// Are start_time and end_time below correct in timezone?
const modalityColumns = [
	{ field: "variable", flex: 0.4, headerName: "Variable", visible: true, minWidth: 120 },
	{ field: "min_start_time", flex: 0.4, headerName: "Start time", valueGetter: params => localizeDate(new Date(params.row.min_start_time), params.row.timezone), visible: true, minWidth: 170 },
	{ field: "max_end_time", flex: 0.4, headerName: "End time", valueGetter: params => localizeDate(new Date(params.row.max_end_time), params.row.timezone), visible: true, minWidth: 170 },
	{
		field: "time_recorded",
		flex: 0.4,
		headerName: "Time recorded",
		valueGetter: params => {
			const time_recorded = params.row.time_recorded.split(",")
			return `${time_recorded[0]} Day${time_recorded[0] > 1 || time_recorded[0] === 0 ? 's' : ''} ${time_recorded[1]} Hour${time_recorded[1] > 1 || time_recorded[1] === 0 ? 's' : ''} ${time_recorded[2]} Minute${time_recorded[2] > 1 || time_recorded[2] === 0 ? 's' : ''}`
		},
		visible: true,
		minWidth: 180
	},
	{
		field: "percentage",
		flex: 0.4,
		headerName: "Percentage (%)",
		visible: true,
		minWidth: 190,
		valueGetter: (params) => `${parseFloat(params.row.percent_recorded).toFixed(2)}%`,
		cellClassName: (params) => `progress-${determineSeverity(params.row.percent_recorded)}`
	},
]

function toHHMMSS(time) {
	var sec_num = parseInt(time, 10); // don't forget the second param'
	var days = Math.floor(sec_num / (3600 * 24))
	var hours = Math.floor((sec_num - (days * 3600 * 24)) / 3600);
	var minutes = Math.floor((sec_num - (days * 3600 * 24) - (hours * 3600)) / 60);
	var seconds = sec_num - (days * 3600 * 24) - (hours * 3600) - (minutes * 60);

	return `${days} Day${days > 1 || days === 0 ? 's' : ''} ${hours} Hour${hours > 1 || hours === 0 ? 's' : ''} ${minutes} Minute${minutes > 1 || minutes === 0 ? 's' : ''}`
}

const VisualizePatient = () => {
	let navigate = useNavigate()
	const authProvider = useAuthProvider()
	const endpointProvider = useEndpointProvider()
	const location = useLocation()
	const { createModal } = useModalProvider()
	const [patientFileInfo, setPatientFileInfo] = useRecoilState(currentPatientFileInfoAtom)
	const { LINKS } = useBackendLinksProvider()

	const query = new URLSearchParams(location.search);
	const UID = query.get("patient_id")
	const [patientFiles, setPatientFiles] = useState([])
	const [patient, setPatient] = useState(location?.state)
	const [allUploadsPending, setAllUploadsPending] = useState(false)


	// Set upload pending if none of them are complete
	const checkUploadsPending = () => endpointProvider.get(LINKS.DATA.UPLOAD.GET_PATIENT_UPLOADS, { patient_id: UID })
		.then(uploads => setAllUploadsPending(
			uploads
			&& uploads.length > 0
			&& uploads.filter(upload => upload.status === "SUCCESS").length === 0
		))
		.catch(err => console.error(err))

	useOnMount(() => {
		authProvider.update(authUpdates.TOKEN)
		checkUploadsPending()
	})

	useEffect(() => {
		async function getPatient() {
			let body = {
				patient_primary_key: UID,
			}

			if (UID === undefined) {
				alert(`patient_id === undefined`)
				navigate(FRONTEND_LINKS.SUBPAGES.DATA.SUBPAGES.VISUALIZE.MAIN)
			}

			try {
				const patientData = await endpointProvider.post(LINKS.DATA.PROFILING.GET_PATIENT, body)
				return patientData
			} catch (error) {
				alert(`Patient id=${UID} does not exist.`)
				navigate(FRONTEND_LINKS.SUBPAGES.DATA.SUBPAGES.VISUALIZE.MAIN)
			}
		}

		if (!patient || Object.keys(patient).length === 0) {
			getPatient().then(patientData => {
				setPatient(patientData)
			})
		}
	}, [UID])

	/**
	* 
	* @returns 
	*/
	function downloadHDF5File(patientFile) {
		let body = {
			patient_file_uid: patientFile,
		}

		return endpointProvider.post(LINKS.DATA.PROFILING.DOWNLOAD_HDF5_FILE, body)
	}

	/**
	 *
	 */
	const handleDownloadHDF5File = useCallback(
		patientFile => {
			downloadHDF5File(patientFile)
				.then(blob => {
					return URL.createObjectURL(blob)
				})
				.then(href => {
					Object.assign(document.createElement("a"), {
						href,
						download: patientFile.split("/")[2],
					}).click()
				})
				.catch(e => {
					alert(e)
				})
		},
		[downloadHDF5File]
	)

	// getPatientStartEndTimestamps:
	// Inputs:
	// 		-
	// Output:
	// 		- res (array):
	async function getPatientStartEndTimestamps(patient_id) {
		let body = {
			patient_ids: [patient_id],
		}

		return endpointProvider.post(LINKS.DATA.PROFILING.GET_PATIENT_FILE_START_END_TIMESTAMPS, body)
	}

	async function getPatientFileType(patient_id) {
		let body = {
			patient_id: [patient_id],
		}

		return endpointProvider.post(LINKS.DATA.PROFILING.GET_PATIENT_FILE_TYPE, body)
	}

	function renderVizModal() {
		const modalOptions = { dataReviewHotkeysEnabled: true }

		createModal(
			<Visualization
				handleDownloadHDF5File={handleDownloadHDF5File}
				patientID={patient?.patient_key}
				patient_id={patient?.patient_id}
				siteName={patient?.site_name}
				dataObjectID={selectedFile.obj_id}
				selectedFile={selectedFile}
				onClose={onClose}
				prefetchID={prefetchId}
			/>,
			modalOptions
		)
	}

	const [totalMonitoringTime, setTotalMonitoringTime] = useState()
	const [monitoringStartTime, setMonitoringStartTime] = useState()
	const [monitoringEndTime, setMonitoringEndTime] = useState()
	const [totalPatientStay, setTotalPatientStay] = useState()
	const [admissionTime, setAdmissionTime] = useState()
	const [dischargeTime, setDischargeTime] = useState()

	useEffect(() => {
		if (UID === undefined) return

		getPatientFileType(UID).then(data => {
			const viewableFiles = data.filter(file => file.obj_type === "CNS" | file.obj_type === "Natus" | file.obj_type === "EDF")
			setPatientFiles(viewableFiles)
		})

		getPatientStartEndTimestamps(UID)
			.then(data => {
				if (Object.keys(data).length === 0 && data.constructor === Object) return
				let admissionTime = Infinity
				let dischargeTime = -1
				let totalPatientStay = 0
				let monitoringStartTime = Infinity
				let monitoringEndTime = -1
				let totalMonitoringTime = 0
				let timezone
				for (const key in data) {
					timezone = data[key].timezone
					if (data[key].admission_time < admissionTime) {
						admissionTime = data[key].admission_time
					}
					if (data[key].discharge_time > dischargeTime) {
						dischargeTime = data[key].discharge_time
					}
					if (data[key].monitoring_start_time < monitoringStartTime) {
						monitoringStartTime = data[key].monitoring_start_time
					}
					if (data[key].monitoring_end_time > monitoringEndTime) {
						monitoringEndTime = data[key].monitoring_end_time
					}
				}
				totalPatientStay = dischargeTime - admissionTime
				totalMonitoringTime = monitoringEndTime - monitoringStartTime
				let startDate = localizeDate(new Date(admissionTime), timezone)
				setMonitoringStartTime(localizeDate(new Date(monitoringStartTime)), timezone)
				setAdmissionTime(startDate)
				let endDate = localizeDate(new Date(dischargeTime), timezone)
				setMonitoringEndTime(localizeDate(new Date(monitoringEndTime)), timezone)
				setDischargeTime(endDate)
				let formattedTotalTime = toHHMMSS(String(totalPatientStay / 1e3))
				setTotalMonitoringTime(toHHMMSS(String(totalMonitoringTime / 1e3)))
				setTotalPatientStay(formattedTotalTime)
			})
			.catch(e => {
				alert(e)
			})
	}, [UID])

	function handleClickViewData() {
		renderVizModal()
	}

	function refreshPatientFiles() {
		getPatientFileType(UID).then(data => {
			const viewableFiles = data.filter(file => file.obj_type === "CNS" | file.obj_type === "Natus" | file.obj_type === "EDF")
			setPatientFiles(viewableFiles)
		})
	}

	function onClose() {
		refreshPatientFiles()
	}

	const handleSelectedFileChanged = useCallback(file => {
		setSelectedFile(file)
		setPatientFileInfo(previous => ({ ...previous, dataObjectId: file.obj_id }))
	}, [setPatientFileInfo])

	const [selectedFile, setSelectedFile] = useState(patientFiles?.length > 0 ? patientFiles[0] : undefined)

	useEffect(() => {
		// The reason that we have to check if the patient files includes the selected file is because of object equality and immutability.
		// When we update the patientFiles from the database, they are brand new objects and have different locations in memory. Equals operator fails.
		// We have to re-set the selected file.
		if ((!selectedFile || !patientFiles?.includes(selectedFile)) && patientFiles?.length > 0) {
			handleSelectedFileChanged(patientFiles[0])
		}
	}, [handleSelectedFileChanged, patientFiles, selectedFile])

	const queryProps = {
		queryKey: "patientModalities",
		endpoint: LINKS.DATA.UPLOAD.GET_MODALITY_TIME_BY_PATIENT,
		body: { project_id: patient?.project_id, site_id: patient?.site_id, patient_id: patient?.patient_key },
	}

	const MainBody = () => {
		return (
			<div style={{ padding: "0 32px" }}>
				<SubHeader patient={patient}
					endSlotChildren={patientFiles?.length > 0 && (
						<div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: "10px", padding: "16px 0" }}>
							<MobergButton theme={MobergTheme.BLUE} shape={MobergButtonShape.SQUARE} onClick={refreshPatientFiles}>
								<MdIcons.MdRefresh size={MobergIconSize.REGULAR} />
							</MobergButton>

							<MobergDropdown
								selectedValue={selectedFile}
								onChange={handleSelectedFileChanged}
								options={(patientFiles ?? []).sort((a, b) => a.obj_name.localeCompare(b.obj_name)).map(file => ({ label: `${file.obj_name} - ${file.preload_status.status}`, value: file }))}
							/>

							<MobergButton
								theme={MobergTheme.BLUE}
								variant={MobergButtonVariant.FILLED}
								tooltip={!authProvider.permissions?.visualize_data ? 'You do not have permission for this action' : ''}
								onClick={handleClickViewData}
								disabled={!selectedFile || !authProvider.permissions?.visualize_data}>
								View data
							</MobergButton>
						</div>
					)}>
				</SubHeader>

				<TimeRecordsDiv style={{ marginTop: '18px' }}>
					<div style={{ marginRight: '40px' }}>
						<p>
							Total Patient Stay: <strong>{totalPatientStay ?? "unknown"}</strong>
						</p>
						<p>
							Total Monitoring Time: <strong>{totalMonitoringTime ?? "unknown"}</strong>
						</p>
					</div>

					<div style={{ marginRight: '40px' }}>
						<p>
							Admission Time: <strong>{admissionTime ?? "unknown"}</strong>
						</p>
						<p>
							Monitoring Start Time: <strong>{monitoringStartTime ?? "unknown"}</strong>
						</p>
					</div>

					<div>
						<p>
							Discharge Time: <strong>{dischargeTime ?? "unknown"}</strong>
						</p>
						<p>
							Monitoring End Time: <strong>{monitoringEndTime ?? "unknown"}</strong>
						</p>
					</div>
				</TimeRecordsDiv>

				<MUITable columns={modalityColumns} isCheckboxSelection={false} {...queryProps} dense="compact" style={{ padding: '0px' }} isRowSelectable={() => false} />
			</div>
		)
	}

	useEffect(() => {
		if (patient === undefined) {
			return
		}

		const body = {
			"patient_id": patient?.patient_key
		}

		endpointProvider.post(LINKS.VISUALIZE.GET_ADMISSION_INFO, body).then(result => {
			setPatientFileInfo(previous => ({...previous, isAdmitted: result.is_admitted }))
		})
	}, [LINKS, endpointProvider, patient, setPatientFileInfo])

	return (
		<div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
			<div style={{ flex: 0 }}>
				<SubpageTabs UID={UID} patient={patient} currentTab={'Data'} />
			</div>

			<div style={{ flex: 1 }}>
				{allUploadsPending && !patientFileInfo.isAdmitted
					? <AllUploadsPending checkUploadsPending={checkUploadsPending} patient={patient} />
					: <MainBody />
				}
			</div>
		</div>
	)
}

const TimeRecordsDiv = styled.div`
	background: #f8f8f8;
	width: auto;
	height: auto;
	display: flex;
	position: relative;
	margin-bottom: 20px;

	p {
		font-family: "Source Sans Pro";
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 150%;
		color: #293241;
		// display: inline-block;
		// margin-right: 81px;
		width: auto;
		position: relative;
		margin: 0;
	}
`

export default VisualizePatient