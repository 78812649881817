import { useState, useEffect } from "react";
import { TextTerm, AgreementLabel, ModalFooter } from "../../../Constants/StyledComponents";
import { MobergButton } from "../../../Components/MobergButton/MobergButton";
import { MobergTheme } from "../../../Components/MobergThemes/MobergColors";
import { MobergButtonVariant } from "../../../Components/MobergButton/MobergButton";
import { MobergButtonShape } from "../../../Components/MobergButton/MobergButton";
import styled from "styled-components";
import { useNavigate } from 'react-router-dom';
import { useAuthProvider } from "../../../Providers/AuthProvider"
import { FRONTEND_LINKS } from '../../../Constants/FrontendLinks';
import { MobergBoxShadow } from '../../../Components/MobergThemes/MobergStyles'
import { useMobergTheme } from "../../../Providers/MobergThemeProvider";


export const WebinarTerms = (props) => {
    const theme = useMobergTheme()
    const [isAgreementChecked, setIsAgreementChecked] = useState(false);
    const authProvider = useAuthProvider()

    // This kinda sucks. Todo: eventually fix this setNavPage jank.
    // Must be inside useEffect because we can't call the parent state setter while rendering this component.
    useEffect(() => {
        props.setNavPage(false)
    })

    const handleNext = () => {
        props.setNavPage(true)
        props.setSidebar(true)
        navigate(FRONTEND_LINKS.SUBPAGES.DATA.SUBPAGES.VISUALIZE.MAIN)
    }

    const handleCheckboxChange = () => {
        setIsAgreementChecked(!isAgreementChecked);
    };

    const navigate = useNavigate();
    const handleLogout = (e) => {

        props.setSidebar(false)
        props.setNavPage(false)
        authProvider.logout()
        navigate(FRONTEND_LINKS.MAIN)
    }

    return (
        <Container>
            <PageContainer>
                <h1>Terms & Conditions</h1>
                <hr />
                <div>
                    <TextTerm style={{ marginLeft: "16px", marginRight: "16px" }}>

                        <p>Welcome to Moberg Analytics CONNECT (“Service”), a cloud-based platform for healthcare data management. By accessing or using the Service, you agree to be bound by these Terms and Conditions (“Terms”).</p>
                        <h3>Definitions</h3>
                        <p>“User” means any individual or organization accessing or using the Service.</p>
                        <p>“Download” means electronic transmission of data and code samples to the User or organization from the Service.</p>

                        <h3>Conditions and Responsibilities</h3>
                        <p>This data is being distributed to you personally under the terms of Data Use Agreements between Moberg Analytics and the data-owning institutions. This data may only be used privately for individual educational purposes. You are forbidden to re-share this data with any third party or to publicize any resultant work that utilizes the data without first entering into a Data Use Agreement with the data-owning institution. For more information, please email info@moberganalytics.com. </p>

                    </TextTerm>
                </div>
                <hr />
                <ModalFooter>
                    <AgreementLabel>
                        <input type="checkbox" checked={isAgreementChecked}
                            onChange={handleCheckboxChange}
                        />
                        <div style={{ color: "red" }}>
                            I have read and understand the Webinar Terms. <span>*</span>
                        </div>
                    </AgreementLabel>

                    <MobergButton
                        theme={MobergTheme.BLUE}
                        variant={MobergButtonVariant.FILLED}
                        shape={MobergButtonShape.WIDE}
                        onClick={handleNext}
                        disabled={!isAgreementChecked}
                    >
                        Next
                    </MobergButton>
                </ModalFooter>

            </PageContainer>
            <StyledLink onClick={handleLogout} color={theme.colors.main}>
                Back to log in
            </StyledLink>
        </Container>
    )
}

export default WebinarTerms;

const Container = styled.div`
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    inset: 0px;
`;


const PageContainer = styled.div`
    width: 60%;
    padding: 20px;
    background-color: white; 
    border-radius: 10px; 
    box-shadow: ${MobergBoxShadow.REGULAR}; 
`;


const StyledLink = styled.a`
    margin-top: 20px;
    text-decoration: none;
    font-size: 16px;
    color: ${props => props.color};
`;