import React, { useContext } from "react"
import { MdSettings, MdClose } from "react-icons/md"
import { MobergButton, MobergTheme, MobergButtonVariant, MobergButtonShape, MobergIconSize, MobergColumn, MobergHeader, MobergFontSize, MobergDropdown } from "../../../../Moberg"
import { WINDOW_TIME_PRESETS } from "../../Viewport/Components/XAxis"
import { useMobergMenu } from "../../../../Hooks/useMobergMenu"
import { ConfigureWindowModalContext } from "./ConfigureWindowModal"
import { ModalityGraphGroupConfigJSON } from "../../../../Pages/Data/Visualize/DataReview/Types/ModalityGraphGroup"

export const ConfigureWindowModalHeader: React.FC = () => {
    const { currentWindow, updateDisplayPageSize, closeModal } = useContext(ConfigureWindowModalContext)
	const { open, MobergMenu } = useMobergMenu({ placement: "bottom-end" })

	return (
		<div style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: "5px" }}>
			<div style={{ width: "40px" }} />

			<h3 style={{ flex: 1, margin: 0, fontFamily: "Montserrat", fontSize: "20px", fontStyle: "normal", color: "#293241", padding: "0 20px" }}> {"Configure Graphs"} </h3>

			<MobergButton theme={MobergTheme.BLACK} variant={MobergButtonVariant.CLEAR} shape={MobergButtonShape.SQUARE} onClick={open}>
				<MdSettings size={MobergIconSize.LARGE} />
			</MobergButton>

			<MobergButton theme={MobergTheme.BLACK} variant={MobergButtonVariant.CLEAR} shape={MobergButtonShape.SQUARE} onClick={closeModal}>
				<MdClose size={MobergIconSize.LARGE} />
			</MobergButton>

			<MobergMenu style={{ padding: "16px" }}>
				<MobergColumn gap="4px" horizontalAlign="center">
					<MobergHeader fontSize={MobergFontSize.LARGE}>Window Settings</MobergHeader>

					<div style={{ width: "100%", margin: "8px 0", borderTop: "1px solid lightgray" }} />

					<MobergDropdown
                        label={"Initial Page Size"}
                        selectedValue={(currentWindow?.props as ModalityGraphGroupConfigJSON | undefined)?.viewDuration}
                        onChange={value => updateDisplayPageSize(parseInt(value))}
                        options={WINDOW_TIME_PRESETS
                            .filter(preset => preset.label !== "Page Size")
                            .map(preset => ({ label: preset.label, value: preset.time }))}
                    />   
				</MobergColumn>
			</MobergMenu>
		</div>
	)
}
