import { USER_INPUT_TYPES } from "../../UserInputTypes"

export const MODALITY_FILTER = {
    INPUTS: [
        {
            DISPLAY_NAME: "Modality",
            VARIABLE_NAME: "modality",
            TYPE: USER_INPUT_TYPES.TEXT_INPUT,
            DEFAULT_VALUE: "",
            DESCRIPTION: "Filters modalities by a particular keyword. Prevents unnecessary processing of irrelevant modalities.",
        },
        {
            DISPLAY_NAME: "Keep",
            VARIABLE_NAME: "keep",
            TYPE: USER_INPUT_TYPES.CHECKBOX_INPUT,
            DEFAULT_VALUE: true,
            DESCRIPTION: "If true, keep modalities with the given keyword, otherwise, exclude them.",
        },
    ],
    DISPLAY_NAME: "Modality Filter",
    PRODUCT_LEVEL: "Standard",
    DESCRIPTION: "Extract pieces of a time series based on the ends of interval data.",
    KEYWORDS: ["filter", "modality"],
    DATA_INPUT_TYPES: ["any"],
    DATA_OUTPUT_TYPE: "any"
}