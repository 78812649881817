import React, { useLayoutEffect, useRef } from "react"
import { ColorSpectrum, getSpectrumInterpolator } from "../../../../Pages/Data/Visualize/DataReview/Types/ColorSpectrum"
import { select } from "d3"

type SpectrumSampleProps = {
	colorSpectrum: ColorSpectrum
}

export const SpectrumSample: React.FC<SpectrumSampleProps> = ({ colorSpectrum }) => {
	const ref = useRef<SVGSVGElement>(null)
	const width = 100
	const height = 25
	const interpolator = getSpectrumInterpolator(colorSpectrum)

	useLayoutEffect(() => {
		if (ref.current) {
			const svg = select(ref.current)
			svg.selectAll("*").remove() // Clear SVG to prevent duplication on re-render

			const numRects = width
			const rectWidth = width / numRects

			Array.from({ length: numRects }, (_, i) =>
				svg
					.append("rect")
					.attr("x", i * rectWidth)
					.attr("y", 0)
					.attr("width", rectWidth)
					.attr("height", height)
					.attr("fill", interpolator(i / (numRects - 1)))
			)
		}
	}, [width, height, interpolator])

	return <svg ref={ref} width={width} height={height} />
}
