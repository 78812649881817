import { NodeComponent } from './Node.js'
import { NodeConnectionPoint } from "./NodeConnectionPoint"
import Draggable from 'react-draggable';
import { useXarrow } from 'react-xarrows';
import { memo, useRef } from 'react';
import PIPELINE_STRUCTURE from '../../../../../Constants/NodeStructures.js';
import ConnectionManager from "../../../../../Managers/PipelineManager/ConnectionManager.ts"


export const DraggableNode = memo(({props}) => {
  const { id, coords, state, setState, parentHeight, parentWidth, setBackgroundDraggingEnabled } = props
  const type = props.type?.toUpperCase()
  const subtype = props.subtype?.toUpperCase()
  const reRenderXarrows = useXarrow()
  const nodeRef = useRef(null);
  const nodeConfig = PIPELINE_STRUCTURE[type][subtype]

  function onDrag(event, ui) {
    if (state.performanceOptions.updateConnectionsOnDrag) {
      reRenderXarrows()
    }
  }

  function deleteNodeAndConnections(nodeId) {
    setState(prevState => {
      const prevStateCopy = {...prevState}
      delete prevStateCopy.nodes[nodeId]

      prevStateCopy.connections = ConnectionManager.deleteAllConnectionsForNode(nodeId, prevStateCopy.connections)
      return prevStateCopy
    })
  }

  function updateNodePosition(nodeId, x, y) {
    setState(prevState => {
      const prevStateCopy = {...prevState}
      prevStateCopy.nodes[nodeId].coords = [x, y]
      return prevStateCopy
    })
  }

  function onStop(event, ui) {
    updateNodePosition(ui.node.id, ui.x, ui.y)
    reRenderXarrows()
  }

  function handleAuxClick(event, nodeId) {
    event.preventDefault()
    deleteNodeAndConnections(nodeId)
    setBackgroundDraggingEnabled(true)
  }

  return (
  <Draggable 
    defaultPosition={{x: coords[0], y: coords[1]}} 
    nodeRef={nodeRef} 
    onDrag={onDrag} 
    onStop={onStop} 
    handle={".handle"} 
    bounds={{left: 0, top: 0, right: parentWidth - nodeRef.current?.clientWidth, bottom: parentHeight - nodeRef.current?.clientHeight}}>
    <div id={id} ref={nodeRef} style={{position: "absolute", display: "flex", width: "fit-content", cursor: "auto"}}
      onMouseEnter={() => setBackgroundDraggingEnabled(false)} 
      onMouseLeave={() => setBackgroundDraggingEnabled(true)} 
      onAuxClick={e => handleAuxClick(e, id) }>

      {/* Input Connection Points */}
      <div style={{display: "flex", padding: "20px 0", flexDirection: "column", justifyContent: nodeConfig.DATA_INPUT_TYPES && nodeConfig.DATA_INPUT_TYPES.length === 1 ? "center" : "space-around", zIndex: "1"}}>
        {nodeConfig.DATA_INPUT_TYPES?.map((input, index) => 
          <NodeConnectionPoint 
            key={`${id}_input_${index}`} 
            props={{id: `${id}_input_${index}`, type: input, state, setState }}> 
          </NodeConnectionPoint>
        )}
      </div>
  
      {/* Main Node Content */}
      <div style={{zIndex: "2"}}>
        <NodeComponent nodeId={id} type={type} subtype={subtype} coords={coords} state={state} setState={setState}/>
      </div>

      {/* Output Connection Point */}
      <div style={{display: "flex", alignItems: "center", justifyContent: "center", zIndex: "1"}}>
        <NodeConnectionPoint 
          key={`${id}_output`} 
          props={{id: `${id}_output`, type: nodeConfig.DATA_OUTPUT_TYPE, isOutput: true, state, setState}}>
        </NodeConnectionPoint>
      </div>

    </div>
  </Draggable>)
})
