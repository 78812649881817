const LOGGING = true

/** Keeps a running list of past times of max length. Adding new times will replace the oldest time */
export class MovingAverage {
    constructor(N) {
        this.head = 0;
        this.samples = [];
        this.N = N;
    }

    get average() {
        return this.samples.reduce((a, b) => a + b, 0) / this.samples.length;
    }

    get length() {
        return this.samples.length;
    }

    log(time) {
        this.samples[this.head] = time;
        this.head += 1;
        this.head %= this.N;
    }
}

/** Basic Stopwatch class */
export class Stopwatch {
    constructor() {
        this.previous_time = 0;
        this.current_time = 0;
    }
    /** @returns {number} The time between now and the previous time clicked */
    click() {
        this.previous_time = this.current_time;
        this.current_time = performance.now();
        return this.time;
    }
    /** @type {number} The most recently recorded time between successive clicks */
    get time() {
        return this.current_time - this.previous_time;
    }
}

export class Profiler {
    constructor(N, name, logEvery=50) {
        this.data = new MovingAverage(N);
        this.stopwatch = new Stopwatch();
        this.name = name;
        this.logEvery = logEvery;
    }
    start() {
        this.stopwatch.click();
    }
    end() {
        this.log(this.stopwatch.click())
    }
    log(t) {
        this.data.log(t);
        if (this.data.head % this.logEvery === 0) 
            this.consoleLog()
    }
    consoleLog() {
        if (LOGGING) console.log(`[⏱️] ${this.name} average time (N=${this.data.length}): ${this.data.average} ms`);
    }
}
