import { ModalHeaderDiv, ModalHeaderText } from '../../Constants/StyledComponents';
import * as MdIcons from "react-icons/md";
import { useModalProvider } from '../../Providers/ModalProvider';
import { MobergButton, MobergButtonShape } from '../MobergButton/MobergButton';
import { MobergIconSize } from '../MobergIcon/MobergIcon';

function ModalHeader({headerText, hrStyle={}, buttonStyle={}, closeFxn, resetCache=null }) {
    const { close } = useModalProvider()
    function handleClose(){
        close()
        if (resetCache) resetCache()
    }

    return (
        <ModalHeaderDiv>
            <div style={{ width: "100%" }}>
                <ModalHeaderText style={{whiteSpace: "nowrap", padding: "5px"}}>{headerText}</ModalHeaderText>
            </div>
            
            <MobergButton shape={MobergButtonShape.SQUARE} style={{ position: "absolute", top: "6px", right: "6px"}} onClick={closeFxn ? closeFxn : handleClose} >
                <MdIcons.MdOutlineClose size={MobergIconSize.LARGE} />
            </MobergButton>
        </ModalHeaderDiv>
	)
}

export default ModalHeader
