import React, { useState } from "react";
import {Text, Image, View, Font } from '@react-pdf/renderer';

import times from '../../Components/Fonts/times.ttf'
import times_bold from '../../Components/Fonts/times-bold.ttf'
import wingdings from '../../Components/Fonts/wingdings.ttf'
import { data } from "../../Pages/Reporting/MultimodalReports/treeData"
import { Radio, RadioGroup, FormControlLabel, Slider, TextField} from '@mui/material';

// Fonts
Font.register({family: 'Times', fonts: [
    {src: times},
    {src: times_bold, fontWeight: "bold"}
]});

Font.register({
    family: "wingdings",
    src: wingdings
})

// Functions


// conditionalField: a function that will display child components when target value(s) are selected
// Inputs: 
// 		- base_component: the main html/pdf component
//      - child_components: an array of child components that will display when target value is pressed
//      - target_value: an array of strings that represent target values that will display the child components
// Output:
//      - an array of components to display
function conditionalField(base_component, child_components=[], target_value=[]) {
    let components = []
    if (base_component.props !== undefined) {
        components.push(base_component)
        for (let key of Object.keys(base_component.props.options)) {
            if (target_value.includes(key) && base_component.props.options[key] === true) {
                components = components.concat(child_components)
                break
            }
        }
    } else {
        for (let key of Object.keys(base_component)) {
            if (key === target_value && base_component[key] === true) {
                components = components.concat(child_components)
                break
            }
        }
    }
    return components
}


// Components


// ComponentTitle: a component title
// Inputs: 
// 		- title: a string value of the title
// Output:
//      - a title component
export function ComponentTitle({title}) {
    return (<>
        <View wrap={false} style={{fontSize: "13", fontFamily: "Times", fontWeight: "bold", backgroundColor: "#e8e4e4", borderBottom: "1px solid #000"}}>
            <Text>{title}</Text>
        </View>
    </>)
}
export function HTMLSectionTitle({componentKey, title}) {
    return (
        <div id={componentKey} style={{fontSize: "20px", fontWeight: "bold", backgroundColor: "#e8e4e4", borderBottom: "1px solid #000",}}>
            {title}
        </div>
    )
}

// InputField: a question field
// Inputs: 
// 		- question: a string value of the question
//      - answer: a string value of the answer
// Output:
//      - a question field component
export function InputField({question, answer}) {
    return (<>
        <View wrap={false} style={{borderBottom: "1px solid #000", fontSize: "11", fontFamily: "Times", marginTop: "0.1in", flexDirection: "row", justifyContent:"space-between"}}>
            <Text style={{maxWidth: "40%"}}>{question}</Text>
            <View style={{marginBottom: "0.15in", marginRight: "10%", minWidth: "35%", maxWidth: "35%"}}>
                <Text style={{borderBottom: "1px solid #000"}}>{answer['x']}</Text>
            </View>
        </View>
    </>)
}
export function HTMLInputField({subcomponentKey, question, answer}) {
    // eslint-disable-next-line no-unused-vars
    const [fieldUpdate, setFieldUpdate] = useState([]);
    function updateField() {
        setFieldUpdate([]);
    }
    return (
        <div id={subcomponentKey} style={{borderBottom: "1px solid #000", fontSize: "12", marginTop: "0.1in", display: "flex", justifyContent:"space-between"}}>
            <p style={{maxWidth: "40%"}}>{question}</p>
            <div style={{marginBottom: "0.15in", marginRight: "9.25%",}}>
                <input style={{ width: "2.65in", borderTop: "1px solid transparent", borderLeft: "1px solid transparent", borderRight: "1px solid transparent", borderBottom: "1px solid #000", backgroundColor: "#F0F0F0"}} type="text" defaultValue={answer['x']} onChange={(e) => {answer['x'] = e.target.value; updateField()}} readOnly/>
            </div>
        </div>
    )
}

export function HTMLDatetimeLocalField({subcomponentKey, question, answer}) {
    return (
        <div id={subcomponentKey} style={{borderBottom: "1px solid #000", fontSize: "12", marginTop: "0.1in", display: "flex", justifyContent:"space-between"}}>
            <p style={{maxWidth: "40%"}}>{question + ' (' + answer.requiredValues.timezone + ')'}</p>
            <input style={{ width: "2.65in", backgroundColor: "#F0F0F0", marginBottom: "0.15in", marginRight: "9.25%", borderTop: "1px solid transparent", borderLeft: "1px solid transparent", borderRight: "1px solid transparent", borderBottom: "1px solid #000"}} type="datetime-local" name="input-time"/>
        </div>
    )
}


export function HTMLDropdownField({subcomponentKey, question, answer}) {
    return (
        <div id={subcomponentKey} style={{borderBottom: "1px solid #000", fontSize: "12", marginTop: "0.1in", display: "flex", justifyContent:"space-between"}}>
            <p style={{maxWidth: "40%"}}>{question}</p>
            <select style={{ width: "2.65in", backgroundColor: "#F0F0F0", marginBottom: "0.15in", marginRight: "9.25%", borderTop: "1px solid transparent", borderLeft: "1px solid transparent", borderRight: "1px solid transparent", borderBottom: "1px solid #000"}} name="input-options">
                {/* <option value="">--Please choose an option--</option> */}
                {[...answer.requiredValues, ...answer.optionalValues].map((element) => (<option value={element.toLowerCase()}>{element}</option>))}
            </select>
        </div>
    )
}

export function HTMLNumberField({subcomponentKey, question, answer}) {
    return (
        <div id={subcomponentKey} style={{borderBottom: "1px solid #000", fontSize: "12", marginTop: "0.1in", display: "flex", justifyContent:"space-between"}}>
            <p style={{maxWidth: "40%"}}>{question}</p>
            <input style={{ width: "2.65in", backgroundColor: "#F0F0F0", marginBottom: "0.15in", marginRight: "9.25%", borderTop: "1px solid transparent", borderLeft: "1px solid transparent", borderRight: "1px solid transparent", borderBottom: "1px solid #000"}} type="number" name="input-number"/>
        </div>
    )
}

export function HTMLRadioField({subcomponentKey, question, answer}) {

    return (
        <div id={subcomponentKey} style={{borderBottom: "1px solid #000", fontSize: "12", marginTop: "0.1in", display: "flex", justifyContent:"space-between"}}>
            <p id={question}>{question}</p>
            <RadioGroup
                aria-labelledby={question}
                row
                name="radio-buttons-group"
                sx={{width: "2.65in", marginBottom: "0.15in", marginRight: "9.25%"}}
            >
                {[...answer.requiredValues, ...answer.optionalValues].map((element) => (
                    <FormControlLabel value={element} control={<Radio />} label={element} />
                    ))}
            </RadioGroup>
        </div>
    )
}

export function HTMLTextAreaField({subcomponentKey, question}) {
    return (
        <div id={subcomponentKey} style={{borderBottom: "1px solid #000", fontSize: "12", marginTop: "0.1in", display: "flex", justifyContent:"space-between"}}>
            <p>{question}</p>
            <TextField id="filled-basic" variant="filled" multiline sx={{width: "2.65in", marginBottom: "0.15in", marginRight: "9.25%"}}/>
        </div>
    )
}

export const HTMLTableField = ({subcomponentKey, question, answer}) => {

    const renderRow = (isHeader) => (
        <tr style={{border: "0.5px solid #ccc"}}>
            {[...Array(parseInt(answer.requiredValues.numCol))].map(() => 
                isHeader 
                    ? (<th style={{border: "0.5px solid #ccc", background: "#f0f0f0", height: "1in"}}> </th>) 
                    : <td style={{border: "0.5px solid #ccc", height: "1in"}}> </td>
                )}
        </tr>
    )

    const renderBody = () => (
        <div id={subcomponentKey}>
            {[...Array(answer.requiredValues.isHeader ? parseInt(answer.requiredValues.numRow) : parseInt(answer.requiredValues.numRow) -1)].map(() => renderRow(false))}
        </div>
    )
        
    return (
        <div style={{borderBottom: "1px solid #000", fontSize: "12", marginTop: "0.1in"}}>
            <p>{question}</p>
            <div style={{ marginBottom: "0.15in", marginRight: "9.25%" }}>
                <div style={{display: "block"}}>
                    <table style={{border: "0.5px solid #ccc", width: "100%", height: "3in"}}>
                        {renderRow(answer.requiredValues.isHeader)}
                        {renderBody()}
                    </table>
                </div>
            </div>
        </div>
    )
}

export function HTMLRangeField({subcomponentKey, question, answer}) {
    const [initialRange, setInitialRange] = useState([answer.requiredValues.initialMin, answer.requiredValues.initialMax])
    const updateRange = (event, val) => {
        setInitialRange(val)
    }

    const range = [answer.requiredValues.rangeMin, answer.requiredValues.rangeMax]
    const mark = [
        {
            value: range[0],
            label: range[0]
        },
        {
            value: range[1],
            label: range[1]
        }
    ]
    return (
        <div id={subcomponentKey} style={{borderBottom: "1px solid #000", fontSize: "12", marginTop: "0.1in", display: "flex", justifyContent:"space-between"}}>
            <p style={{maxWidth: "40%"}}>{question}</p>
            <div style={{ width: "2.65in", marginBottom: "0.15in", marginRight: "9.25%" }}>
                <Slider 
                    min={range[0]}
                    max={range[1]}
                    step={answer.requiredValues.step}
                    value={initialRange}
                    onChange={updateRange}
                    valueLabelDisplay="auto"
                    marks={mark}
                />
            </div>
        </div>
    )
}

export function HTMLTimeField({subcomponentKey, question, answer}) {
    return (
        <div id={subcomponentKey} style={{borderBottom: "1px solid #000", fontSize: "12", marginTop: "0.1in", display: "flex", justifyContent:"space-between"}}>
            <p style={{maxWidth: "40%"}}>{question}</p>
            <input style={{ width: "2.65in", backgroundColor: "#F0F0F0", marginBottom: "0.15in", marginRight: "9.25%", borderTop: "1px solid transparent", borderLeft: "1px solid transparent", borderRight: "1px solid transparent", borderBottom: "1px solid #000"}} type="time" name={answer.name}/>
        </div>
    )
}
// CheckboxField: a checkbox field
// Inputs: 
// 		- question: a string value of the question
//      - options: a dictionary with keys equal to string value of an option and the value is null
// Output:
//      - a checkbox field component
export function CheckboxField({question, options={}}) {
    // displayList: shows checkbox input options
    // Inputs: 
    // 		- options: the options parameter of CheckboxField
    // Output:
    //      - checkbox inputs
    function displayList(options) {
        // Inputs: options
        // Function: Shows the bullet points and labels inside options
        return (<>{Object.keys(options).map((key) => {
            if (options[key] === true) {
                return (<>
                    <View wrap={false} key={key} style={{flexDirection: "row"}}>
                        <Text style={{fontFamily: "wingdings", marginRight: "2px"}}>n</Text>
                        <Text>{key}</Text>
                    </View>
                </>)
            } else {
                return (<>
                    <View wrap={false} key={key} style={{flexDirection: "row"}}>
                        <Text style={{fontFamily: "wingdings", marginRight: "2px"}}>q</Text>
                        <Text>{key}</Text>
                    </View>
                </>)
            }
        })}</>)
    }

    return (<>
        <View wrap={false} style={{borderBottom: "1px solid #000", fontSize: "11", fontFamily: "Times", marginTop: "0.1in", flexDirection: "row"}}>
            <Text style={{width: "40%"}}>{question}</Text>
            <View style={{marginBottom: "0.1in", flexDirection: "column", marginLeft: "15%"}}>
                {displayList(options)}
            </View>
        </View>
    </>)
}
export function HTMLCheckboxField({subcomponentKey, question, answer}) {
    return (
        <>
            <div id={subcomponentKey} style={{borderBottom: "1px solid #000", fontSize: "12", marginTop: "0.1in", display: "flex"}}>
                <p style={{width: "40%"}}>{question}</p>
                <div style={{marginBottom: "0.1in", display: "block", marginLeft: "15%"}}>
                    {[...answer.requiredValues, ...answer.optionalValues].map((val, index) => (
                        <label key={val + String(index)} style={{display: "flex"}}>
                            <input type={"checkbox"} />
                            {val}
                        </label>
                    ))}
                </div>
            </div>
        </>
    )
}

// CheckboxFieldGrid: a checkbox grid
// Inputs: 
// 		- grid dictionary: a dictionary with grid labels and its values
// Output:
//      - a checkbox grid
export function CheckboxFieldGrid({grid_dictionary={}}) {
    // displayBullets: renders the bullets of a checkbox grid and either leaves it empty or fills it in
    // Inputs:
    // 		- label: a string value of the label
    //      - number: a string value of the column number
    //      - grid_dictionary: a dictionary with keys representing the grid label and value either null or a boolean
    // Output:
    //      - a filled in or open bullet
    function displayBullets(label, number, grid_dictionary) {
        if (grid_dictionary[label + number] === true) {
            if (["Not reported 1", "Not reported 2", "Not reported 3"].includes(label)) {
                grid_dictionary['checkbox_fields'][label + number] = true
            }
            return (<><Text style={{fontFamily: "wingdings", marginBottom: "5"}}>n</Text></>)
        } else {
            return (<><Text style={{fontFamily: "wingdings", marginBottom: "5"}}>q</Text></>)
        }
    }

    // displayColumns: renders the grid columns
    // Inputs:
    // 		- grid_dictionary: a dictionary of the grid values
    // Output:
    //      - a column with a label and matching bullet
    function displayColumns(grid_dictionary) {
        if (grid_dictionary !== undefined) {
            return (<>{grid_dictionary['labels'].map((label, index) => {
                return (<>
                    <View wrap={false} key={index.toString()} style={{flexDirection: "column", alignItems: "center"}}>
                        <Text style={{margin: "5"}}>{label}</Text>
                        {displayBullets(label, " 1", grid_dictionary)}
                        {displayBullets(label, " 2", grid_dictionary)}
                        {displayBullets(label, " 3", grid_dictionary)}
                    </View>
                </>)
            })}</>)
        }
    }

    return (<>
        <View style={{flexDirection: "row", fontSize: "9", fontFamily: "Times", borderBottom: "1px solid #000"}}>
            <View style={{marginTop: "5"}}>
                <Text style={{marginBottom: "5"}}> </Text>
                <Text style={{marginBottom: "5"}}>Direct correlation with:</Text>
                <Text style={{marginBottom: "5"}}>INDIRECT correlation with:</Text>
                <Text style={{marginBottom: "0.25in"}}>INDEPENDENT of:</Text>
            </View>
            {displayColumns(grid_dictionary)}
        </View>
    </>)
}
export function HTMLCheckboxFieldGrid({subcomponentKey, grid_dictionary={}}) {
    const [, setFieldUpdate] = useState([]);
    function updateField() {
        setFieldUpdate([]);
    }
    // Inputs: grid_dictionary
    // Function: Displays a checkbox grid
    function displayBullets(label, number, grid_dictionary) {
        // Inputs: label, number, grid_dictionary
        // Function: Checks the value of each bullet and either fills in or leave empty those bullet points
        if (grid_dictionary[label + number] === true && ["Not reported 1", "Not reported 2", "Not reported3"].includes(label) && grid_dictionary['checkbox_fields'] !== undefined) {
            grid_dictionary['checkbox_fields'][label + number] = true
        } else if (grid_dictionary[label + number] === false && ["Not reported 1", "Not reported 2", "Not reported3"].includes(label) === false && grid_dictionary['checkbox_fields'] !== undefined) {
            grid_dictionary['checkbox_fields'][label + number] = false
        }

        return (<input type={"checkbox"} checked={grid_dictionary[label + number]} onChange={() => {grid_dictionary[label + number] = !grid_dictionary[label + number]; updateField()}} style={{display: "block"}}/>)
    }

    function displayColumns(grid_dictionary) {
        // Inputs: grid_ductionary
        // Function: Creates a number of columns in the grid with bullet points following
        if (grid_dictionary !== undefined) {
            return (<>{grid_dictionary['labels'].map((label) => {
                return (<>
                    <div key={label}>
                        <p style={{margin: "1"}}>{label}</p>
                        <div style={{display: "flex", flexDirection: "column", alignItems: "center", height: "65%", justifyContent: "space-around"}}>
                            {displayBullets(label, " 1", grid_dictionary)}
                            {displayBullets(label, " 2", grid_dictionary)}
                            {displayBullets(label, " 3", grid_dictionary)}
                        </div>
                        
                    </div>
                </>)
            })}</>)
        }
    }

    return (<>
        <div style={{display: "flex", fontSize: "14px", borderBottom: "1px solid #000"}}>
            <div style={{marginTop: "5"}}>
                <br/>
                <br/>
                <p style={{marginBottom: "5"}}>Direct correlation with:</p>
                <p style={{marginBottom: "5"}}>INDIRECT correlation with:</p>
                <p style={{marginBottom: "0.25in"}}>INDEPENDENT of:</p>
            </div>
            <div style={{display: "flex", justifyContent: "space-evenly", width: "100%"}}>
                {displayColumns(grid_dictionary)}
            </div>
        </div>
    </>)
}

// Table: a basic table with headers and rows
// Inputs: 
// 		- headers: an array representing the table headers
//      - rows: an array of arrays representing the table cells
// Output:
//      - a table component
// export function HTMLTableField({headers=[], rows=[[],[]]}) {
//     const TableStyles = {
//         column: {
//             width: "25%",
//             borderRight: "1px solid #000",
//             borderBottom: "1px solid #000"
//         },
//         cell: {
//             marginLeft: "0.05in",
//             marginTop: "0.05in"
//         },
//         header : {
//             fontFamily: "Times",
//             fontWeight: "bold",
//             margin: "auto",
//             marginTop: "0.05in"
//         }
//     }

//     // renderTableHeader: a function that will render cells for the table header
//     // Inputs: 
// 	//      - row_data: an array of values for the row
//     // Output:
//     //      - a series of cells for the table header
//     function renderTableHeader(row_data) {
//         return (<>{row_data.map((cell) => {
//             return (<>
//                 <View key={cell} style={TableStyles.column}>
//                     <Text style={TableStyles.header}>{cell}</Text>
//                 </View>
//             </>)
//         })}</>)
//     }

//     // renderTableRows: a function that will render rows for the table
//     // Inputs: 
// 	//      - row_data: an array of values for the row
//     // Output:
//     //      - a series of cells for the table row
//     function renderTableRows(row_data) {
//         for (let row of row_data) {
//             return (<>{row.map((cell) => {
//                 return (<>
//                     <View key={row} style={TableStyles.column}>
//                         <Text style={TableStyles.cell}>{cell}</Text>
//                     </View>
//                 </>)
//             })}</>)
//         }
//     }

//     return (<>
//         <View style={{borderTop: "1px solid #000", borderLeft: "1px solid #000"}}>
//             {renderTableHeader(headers)}
//             {renderTableRows(rows)}
//         </View>
//     </>)
// }


// Modded Components

// ObservatonsField: a component that displays the "other observations" checkbox/inputfield components
// Inputs: 
// 		- yes_no_dict: a dictionary with yes and no values
//      - observations: a dictionary representing the input value of the observations input field in the form of {'x': ''}
// Output:
//      - a conditionalfield function
export function ObservationsField({yes_no_dict={}, observations={}}) {
    return (<>
        {conditionalField(<CheckboxField question={"Were there other observations that were not reflected in the above questions?"} options={yes_no_dict}/>, <InputField question={"Copy/paste any observations here:"} answer={observations}/>, "Yes")}
    </>)
}
export function HTMLObservationsField({yes_no_dict={}, observations={}}) {
    return (<>
        {conditionalField(<HTMLCheckboxField question={"Were there other observations that were not reflected in the above questions?"} options={yes_no_dict}/>, <HTMLInputField question={"Copy/paste any observations here:"} answer={observations}/>, "Yes")}
    </>)
}

// CorrelationField: a checkbox displaying correlations and displays an input field if the user selects "Other"
// Inputs: 
// 		- dict: a dictionary with options yes and no for the checkboxfield component
//      - answer: a dictionary representing the value of answer in the form of {'x': ''}
//      - modality: the string value of the modality
// Output:
//      - a conditionalfield function
function CorrelationField({dict, answer, modality}) {
    // Inputs: dict, answer, modality
    // Function: Returns a checkbox with correlated values of a specified modality
    return (<>
        {conditionalField(<CheckboxField question={`Did the ${modality} correlate in any way with any of the following?`} options={dict}/>, <InputField question={`Correlation with ${modality} if 'Other':`} answer={answer}/>, "Other")}
    </>)
}
export function HTMLCorrelationField({dict, answer, modality}) {
    return (<>
        {conditionalField(<HTMLCheckboxField question={`Did the ${modality} correlate in any way with any of the following?`} options={dict}/>, <HTMLInputField question={`Correlation with ${modality} if 'Other':`} answer={answer}/>, "Other")}
    </>)
}

// HTMLCheckboxFieldGridConditions: returns the specific questions if the user selects "other" in a checkbox grid
// Inputs: 
// 		- grid_dictionary: a dictionary representing the checkbox grid's structure
// Output:
//      - input fields
function HTMLCheckboxFieldGridConditions({grid_dictionary={}}) {
    // Inputs: grid_dictionary
    // Function: Makes input fields for the checkbox grid if "other" is selected
    // eslint-disable-next-line array-callback-return
    return (<>{Object.keys(grid_dictionary['checkbox_fields']).map((field) => {
        if (grid_dictionary['checkbox_fields'][field] === true) {
            if (field === "Not reported 1") {
                return (
                    <HTMLInputField key={field} question={"If other DIRECT relationship, specify:"}/>
                )
            }
            if (field === "Not reported 2") {
                return (
                    <HTMLInputField key={field} question={"If other INDIRECT relationship, specify:"}/>
                )
            }
            if (field === "Not reported 3") {
                return (
                    <HTMLInputField key={field} question={"If INDEPENDENT of something else, specify:"}/>
                )
            }
        }
    })}</>)
}



// ReturnPDFComponents: 
// Inputs: 
// 		- component_name: the string value of the component name
//      - questions: an array of strings representing each question
//      - question_dict: a dictionary with keys of questions and values of the PDF component
// Output:
//      - the subcomponents of the component
function ReturnPDFComponents(component_name, questions, question_dict, checkbox_dict) {
    if (questions !== undefined) {
        // eslint-disable-next-line array-callback-return
        return (<>{questions.map((question) => {
            if (Object.keys(checkbox_dict).includes(component_name + ":" + question) && checkbox_dict[component_name + ":" + question] !== true) {
                return(<>
                    {question_dict[question]}
                </>)
            }
        })}</>)
    }
}




// "Q0": {
//     'type': 'INPUTFIELD',
//     'question': 'Average ICP (mmHg)(enter range if no single average given)',
//     'answer': ''
// }

// an array of selectable components. custom components can be appended to this array
const COMPONENTS = ["HEADER_STYLE_1", "REPORT_SUMMARY", "REPORT_SPECIFICS", "ICP", "ICP_FLOW", "ICP_METABOLISM", "CPP", "CPP_FLOW", "CPP_METABOLISM", "HEART_RATE", "PRX_CPPOPT", "PBTO2", "PBTO2_FLOW", "PBTO2_METABOLISM", "RCBF", "RCBF_FLOW", "RCBF_METABOLISM", "TEMPERATURE", "CEREBRAL_MICRODIALYSIS", "PUPILOMETRY", "DEPTH_EEG", "SCALP_EEG", "MODALITY_GRAPHS", "Pediatric_Multimodal_Neuromonitoring_Report"]


// abbreviated versions of subcomponents
export const SUBCOMPONENT_LABELS = {
    "HEADER_STYLE_1": {
        "Record ID": "Record ID"
    },
    "Pediatric_Multimodal_Neuromonitoring_Report": {
        "History": "History",
        "Indication": "Indication",
        "Technical Summary": "Technical Summary", 
        "Recording Period": "Recording Period"
    },
    "REPORT_SUMMARY": {
        "Best GCS TOTAL during MMM Reporting period?": "GCS Total",
        "What was the Interpretation/Impression of autoregulatory function?": "Interpretation/Impression",
        "Command following during the MMM reporting period?": "Command",
    },
    "REPORT_SPECIFICS": {
        "Which devices were included in this report?": "Included devices",
        "Did any device malfuntion during this period? (Device pulled, dislodged, broke)": "Malfunctioned devices"
    },
    "ICP": {
        "What was the ICP waveform morphology?": "ICP Waveform Morphology", 
        "Average ICP (mmHg)(enter range if no single average given)": "Average ICP",
        "Percent time spent above ICP 22 mmHg (%)": "Time spent above ICP (22 mmHg)",
        "Number of 'very brief ICP spikes/elevations.' < 1 min": "Very brief ICP spikes (<1 mins)",
        "Number of 'brief ICP spikes/elevations.' = 1-15 mins": "Brief ICP spikes (1-15 mins)",
        "Number of 'intermediate ICP spikes/elevations.' > 15 mins": "Intermediate ICP spikes (>15 mins)",
        "Were B-waves (20-80 seconds oscillations) present?": "B-Waves present",
        "Were C-waves (5-12 seconds oscillations) present?": "C-Waves present"
    },
    "ICP_FLOW": {

    },
    "ICP_METABOLISM": {
        "What was the difference (mmHg) between EVD and ICP values?": "EVD - ICP difference"
    },
    "CPP": {
        "Average CPP (mmHg)(enter range if no single average given)": "Average CPP",
        "Percent time spent below CPP 60 mmHg (%)": "Time spent below CPP (60 mmHg)"
    },
    "CPP_FLOW": {
    },
    "CPP_METABOLISM": {
    },
    "HEART_RATE": {
        "Average HR (bpm) (enter range if no single average given)": "Average heartrate",
        "Percent time spent above HR 100 bpm (%)": "Percent time spent above HR (100 bpm)",
        "Percent time spent below HR 60 bpm (%)": "Percent time spent below HR (60 bpm)",
    },
    "PRX_CPPOPT": {
        "Were there any peaks in the PRx reported?": "PRx peaks",
        "Did PRx shift during the reporting period?": "PRx shift",
        "What was the reported CPPopt (mmHg)?": "Reported CPPopt",
        "Were either an 'optimum CPP' or the limits of autoregulation reported?": "Reported optimum CPP",
        "Did the CPPopt shift during the reporting period?": "CPPopt shift"
    },
    "PBTO2": {
        "Command following during the MMM reporting period?": "Command",
        "Were either an O2 or FiO2 Challenge or Pbto2 variability documented?": "Documented variability or challenge"
    },
    "PBTO2_FLOW": {
    },
    "PBTO2_METABOLISM": {
        "What was the lowest ETCO2 value?": "Lowest ETCO2",
        "What was the highest ETCO2 value?": "Highest ETCO2",
    },
    "RCBF": {
        "Was there any HYPERemia?": "Hyperemia",
        "Was there any ISCHEMIA (decrease in rCBF to < 8 ml/100g/min)?": "Ischemia",
        "Was there any OLIGEMIA (decrease in rCBF to 8-15 ml/100g/min)?": "Oligemia",
        "Where there vascomotor oscillations?": "Vascomotor oscillations"
    },
    "RCBF_FLOW": {
    },
    "RCBF_METABOLISM": {
        "What were the reported K values (range)?": "Reported K values",
        "Did the K value shift during the reporting period?": "K value shift"
    },
    "TEMPERATURE": {
        "Percent time spent above brain temperature 38.4C (%)": "Percent time spent above brain temperature (38.4C)",
        "Percent time spent above systemic or core temperature 38.4C (%)": "Percent time spent above systemic or core temperature (38.4C)"
    },
    "CEREBRAL_MICRODIALYSIS": {
        "What was the lowest CMD glucose?": "Lowest CMD glucose",
        "What was the highest CMD glucose?": "Highest CMD glucose",
        "What was the lowest SERUM glucose?": "Lowest SERUM glucose",
        "What was the brain/serum glucose ratio?": "Brain/Serum glucose ratio",
        "What was the lowest glutamate?": "Lowest glutamate",
        "What was the highest glutamate?": "Lowest glutamate",
        "What was the lowest glycerol?": "Lowest glycerol",
        "What was the highest glycerol?": "Highest glycerol",
        "Were there any peaks/troughs?": "Peaks/troughs",
    },
    "PUPILOMETRY": {
        "What was the lowest NPi on the LEFT?": "Lowest NPi Left",
        "What was the highest NPi on the LEFT?": "Highest NPi Left",
        "What was the lowest NPi on the RIGHT?": "Highest NPi Right",
        "What was the highest NPi on the RIGHT?": "Highest NPi Right",
        "What was the maximum inter-eye difference?": "Max inter-eye difference",
        "Did the NPi values CHANGE during the reporting period?": "Changed NPi values",
        "Observations": "Observations",
    },
    "DEPTH_EEG": {
        "What was the depth EEG background?": "Depth EEG background",
        "Were there superimposed faster frequencies reported?": "Superimposed faster frequencies",
        "Were there any periodic or quasi-periodic discharges?": "Periodic/Quasi-periodic discharges",
        "Were there rhythmic delta patterns (including +R)?": "Rhythmic delta patterns",
        "Were either PD or RDA only reported to be stimulus-induced?": "PD/RDA",
        "Were there any seizures reported on death?": "Seizures on death",
        "Were there any spreading depolarizations reported on depth?": "Spreading depolarizations on death",
        "If Sz/PD were present, were these also seen on the scalp EEG?": "Sz/PD present",
        "Was there reactivity on the depth electrode?": "Reactivity",
        "Were there sleep transients during the depth electrode recording?": "Sleep transients"
    },
    "SCALP_EEG": {
        "What was the scalp EEG background?": "Scalp EEG background",
        "Were there superimposed faster frequencies reported?": "Superimposed frequencies",
        "Were there any periodic or quasi-periodic discharges?": "Periodic/Quasi-periodic discharges",
        "Were there rhythmic delta patterns (including +R)?": "Rhythmic delta patterns",
        "Were either PD or RDA only reported to be stimulus-induced?": "PD/RDA",
        "Were there any seizures reported on scalp EEG?": "Seizures",
        "Were there any spreading depressions or depolarizations reported on scalp?": "Spreading depolarizations",
        "If Sz/PD were present, were these also seen on the scalp EEG?": "Sz/PD present",
        "Was there reactivity on the scalp eeg?": "Reactivity",
        "Were there sleep transients during the depth electrode recording?": "Sleep transients",
    },
}

// list of all subcomponents per question when first rendered
export const SUBCOMPONENTS = {}


export default COMPONENTS
