import { Selection, EnterElement, select, BaseType } from "d3"
import { ReactCallbacks } from "../../Types/ReactCallbacks"

export abstract class D3OneToOneRenderable<
        RootElementType extends BaseType, 
        ElementType extends BaseType, 
        ConfigType, 
        ReactCallbacksType = ReactCallbacks<any>
    > {
    protected root: RootElementType
    protected config: ConfigType
    protected className: string
    protected reactCallbacks: ReactCallbacksType

    // These callbacks help us avoid allocating extra memory when performing renders
    private data = Array(1)
    private handleEnterBound: (newElements: Selection<EnterElement, ConfigType, any, any>) => Selection<ElementType, ConfigType, RootElementType, any>
    private handleUpdateBound: (updatedElements: Selection<ElementType, ConfigType, any, any>) => Selection<ElementType, ConfigType, RootElementType, any>
    private exitBound: (exitedElements: Selection<any, any, RootElementType, ConfigType>) => void

    constructor(root: RootElementType, config: ConfigType, className: string, reactCallbacks: ReactCallbacksType) {
        this.root = root
        this.config = config
        this.className = className
        this.reactCallbacks = reactCallbacks

        // Binding the function to "this" allows us to use "this" inside of the enter, update, and exit functions
        // and have it actually mean the class instead of the D3 datum.
        // Binding the function also has significant performance benefits over using anonymous functions.
        this.handleEnterBound = this.handleEnter.bind(this)
        this.handleUpdateBound = this.handleUpdate.bind(this)
        this.exitBound = this.exit.bind(this)
    }

    public updateConfig(config: ConfigType) {
		this.config = config
        this.updateDerivedState()
		this.render()
	}
    
    public render() {
        this.data[0] = this.config
        
        select<RootElementType, unknown>(this.root)
            .selectAll<any, any>("." + this.className)
            .data(this.data)
            .join(this.handleEnterBound, this.handleUpdateBound, this.exitBound)
    }

    protected updateDerivedState() {}

    protected abstract enter(newElements: Selection<EnterElement, ConfigType, any, any>): Selection<ElementType, ConfigType, RootElementType, any>
    protected abstract update(updatedElements: Selection<ElementType, ConfigType, any, any>): Selection<ElementType, ConfigType, RootElementType, any>

    protected exit(exitedElements: Selection<any, any, RootElementType, ConfigType>): void {
        exitedElements.remove()
    } 

    private handleEnter(newElements: Selection<EnterElement, ConfigType, any, any>): Selection<ElementType, ConfigType, RootElementType, any> {
        if (newElements.nodes().length === 0) {
            // Technically, enter still gets called even if there are no nodes entering the screen.
            // To avoid confusion, this will prevent "enter" from being called when nothing enters the screen.
            return newElements as any
        }

        return this.enter(newElements)
    }

    private handleUpdate(updatedElements: Selection<ElementType, ConfigType, any, any>): Selection<ElementType, ConfigType, RootElementType, any> {
        if (updatedElements.nodes().length === 0) {
            // Technically, update still gets called even if there are no nodes updating on the screen.
            // To avoid confusion, this will prevent "update" from being called when nothing is updating on the screen
            return updatedElements
        }

        return this.update(updatedElements) 
    }
}
