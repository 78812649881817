import React from "react"
import { useCallback } from "react"
import { useEffect, useReducer, useRef, useState } from "react"


/**
 * Custom Hook 
 * [tabs, tabsIndex, selectedTab, noneSelected, selectTab, deselectTabs, addTab, deleteTab, renameTab]
 * @returns {[object.<string>, array.<string>, string, boolean, function(string):void, function():void, function(string):void, function(string):void, function(string, string): boolean]}
 */
export function useTabs (noneValue='', selectOnAdd=true) {

	const [tabs, setTabs] = useState([])
    const [selectedTab, setSelectedTab] = useState(noneValue)
    const [noneSelected, setNoneSelected] = useState(true)
    
    function selectTab (tab) {
        setSelectedTab(tab)
        setNoneSelected(false)
    }

    function deselectTabs () {
        setSelectedTab(noneValue)
        setNoneSelected(true)
    }

    function addTab (tab) {
        setTabs(prev => prev.includes(tab) ? [...prev] : [...prev, tab])
        if (selectOnAdd) selectTab(tab)
    }

    function deleteTab (tab) {
        setTabs(prev => [...prev].filter(t => t !== tab))
        if (tab === selectedTab) deselectTabs()
    }

    function renameTab(tab, newtab) {
        setTabs(prev => {
            const next = [...prev]
            next[prev.indexOf(tab)] = newtab
            return next
        })
        setSelectedTab(newtab)
    }

    function mapTabs (func) {
        return tabs.map(func)
    }

    return {
        tabs: tabs,
        selected: selectedTab,
        noneSelected: noneSelected,
        add: addTab,
        delete: deleteTab,
        rename: renameTab,
        select: selectTab,
        deselectAll: deselectTabs,
        map: mapTabs
    }
}

const booleanObjectReducer = (state, action) => {
    const nextState = {...state}
    switch (action.type){
        case 'set':
            nextState[action.key] = action.value
            break
        case 'flip':
            nextState[action.key] = !nextState[action.key]
            break
        case 'allTrue':
            Object.keys(nextState).forEach(key => nextState[key] = true)
            break
        case 'allFalse':
            Object.keys(nextState).forEach(key => nextState[key] = false)
            break
        case 'invert':
            Object.keys(nextState).forEach(key => nextState[key] = !nextState[key])
            break
        case 'reset':
            return action.keys.reduce((obj, key) => ({ ...obj, [key]: nextState[key] ?? false}), {})
        default:
    }
    return nextState
}

/**
 * @returns {[any, function()]}
 */
export function useBooleanObject (keys) {
    const [state, dispatch] = useReducer(booleanObjectReducer, keys.reduce((obj, key) => ({ ...obj, [key]: false}), {}))
    useEffect(() => {
        dispatch({type: 'reset', keys: keys})
    }, [keys])
    return [state, dispatch]
}

/**
 * Higher order custom hook that creates state variables that are always processed by some function
 * 
 * @example
 * const useUppercaseString = createWrappedState(value => value.toUpperCase())
 * 
 * function myComponent (props) {
 *     const [textInput, setTextInput] = useUppercaseString('initial text')
 *     return (
 *     <div>
 *         <input onChange={e => setTextInput(e.target.value)} />
 *     </div>
 *     )
 * }
 * @param {function(any):any} wrapper 
 * @returns {hook}
 */
export function createWrappedState (wrapper) {
    return function useHook(initialValue) {
        const [state, setState] = useState(wrapper(initialValue))
    
        const setter = useCallback(value => {
            setState(wrapper(value))
        },[setState])

        return [state, setter]
    }
}

/**
 * 
 * @param {} value 
 * @returns 
 */
export function useConstant (value) {
    const [constant, setConstant] = useState(value)
    return constant
}