export let DEFAULTS = {
    TIMELINE_BACKGROUND_COMPLETE: "#207DEA",
    TIMELINE_BACKGROUND_INCOMPLETE: "#E0E0E0",
    INDEX_BACKGROUND_COMPLETE: "#207DEA",
    INDEX_BACKGROUND_INCOMPLETE: "#FCFCFC",
    BUTTON_BACKGROUND: "#88DEFF",
    BUTTON_BACKGROUND_HOVER: "#AAFFFF",
    BUTTON_BACKGROUND_CLICK: "#66BCDD",
    BUTTON_BORDER: "2px solid #000000",

    TEXT_COLOR: "#363636",
    TEXT_SIZE: 12,

    BORDER: "1px solid #000000",
    BORDER_HIGHLIGHTED: "5px solid #FFFFFF",
    BORDER_HIGHLIGHTED_RED: "10px solid #FF695E",
    BORDER_HIGHLIGHTED_GREEN: "10px solid #73D160",
    HIGHLIGHT_BORDER: "2px solid #000000",
    PRIMARY_BOX_BACKGROUND: "#F5F5F5",
    PRIMARY_BOX_BACKGROUND_WHITE: "#F5F5F5",
    SECONDARY_BOX_BACKGROUND: "#E4E4E4",

    TABLE_HEAD_BACKGROUND: "#ACD6FF",
    TABLE_PRIMARY_BACKGROUND: "#E1F0FF",
    TABLE_PRIMARY_BACKGROUND_BUTTON: "#BCEDFF",
    TABLE_PRIMARY_BACKGROUND_HOVER: "#CCFDFF",
    TABLE_PRIMARY_BACKGROUND_CLICK: "#9CCDDF",
    TABLE_SECONDARY_BACKGROUND: "#CAD7E3",
    TABLE_SECONDARY_BACKGROUND_BUTTON: "#A4D4E0",
    TABLE_SECONDARY_BACKGROUND_HOVER: "#B4E4E0",
    TABLE_SECONDARY_BACKGROUND_CLICK: "#84B4C0",
    CELL_PADDING: 3,

    SIDE_BAR_PRIMARY: "#C9D6DC",
    SIDE_BAR_SECONDARY: "#AABDC5",

    ALTERNATE_BAR_BACKGROUND: "#A5B0B5",

    PADDING: 10,
    HEADER_HEIGHT: 20,
    CELL_HEIGHT: 20,

    FALSE: "#FF695E",
    TRUE: "#73D160",
}

export const BUTTON_STYLING = Object.freeze({
    WHITE: "#FFFFFF",

    RED: "#e64e58",
    RED_BORDER: "2px solid #e64e58",

    RED_FILL_HOVER: "#C53030",
    RED_FILL_ACTIVE: "#9B2C2C",

    RED_OUTLINE_HOVER: "#FFF5F5",
    RED_OUTLINE_ACTIVE: "#FED7D7",

    BLUE: "#207DEA",
    BLUE_BORDER: "2px solid #207DEA",

    BLUE_FILL_HOVER: "#1966BE",
    BLUE_FILL_ACTIVE: "#1658a4",

    BLUE_OUTLINE_HOVER: "#EBF8FF",
    BLUE_OUTLINE_ACTIVE: "#BEE3F8",

    FONT: 'Source Sans Pro'
})

export let LOGIN = {
    FORM_WIDTH: '280px',

}

export let NAVBAR = {
    HEIGHT: 30,
    TOP_HEIGHT: 30,
    BOTTOM_HEIGHT: 0,
    BACKGROUND_COLOR_TOP_GREY: "#272B34",
    BACKGROUND_COLOR_BOTTOM_GREY: "#34363C",
}

export let NODE_FORMAT = {
    WIDTH: 360,
    TITLE_HEIGHT: 32,
    PADDING: 3,
    BORDER: 1,
    INPUT_WIDTH: -1,
    INPUT_BOX_WIDTH: 60,
    EDIT_TAB_HEIGHT: 50,

    INPUT_TYPE_SIZE: 50,

    TEXT_COLOR: DEFAULTS.TEXT_COLOR,
    DOT_COLOR: "#FFFFFF",

    SOURCE_PRIMARY: "#FFBFBF",
    SOURCE_SECONDARY: "#FFE6E6",
    ANALYTIC_PRIMARY: "#B2F6C9",
    ANALYTIC_SECONDARY: "#E2FFEC",
    ANALYTIC_TERTIARY: "#3BF683",
    VISUALIZATION_PRIMARY: "#F2FFB9",
    VISUALIZATION_SECONDARY: "#F9FFE1",
}
NODE_FORMAT.INPUT_WIDTH = NODE_FORMAT.WIDTH - (2 * NODE_FORMAT.PADDING) - (2 * NODE_FORMAT.BORDER)


export let PIPELINE_DISPLAY = {
    ROOM_WIDTH: 4000,
    ROOM_HEIGHT: 4000,

    GRIDBOX_WIDTH: 20,
    GRIDBOX_HEIGHT: 20,

    SIDEBAR_WIDTH: 400,
}


export let UPLOAD_DATA = {
    ALL_UPLOADS_HEADER_HEIGHT: 40,
    LEFT_PANNEL_OPEN: '25%',
}

export let UPLOAD_PAGE = {
    FILE_PANEL_BACKGROUND: "#C9D6DC",
    FILE_PANEL_WIDTH: 500,
    FILE_PANEL_PADDING: 20,

    FILE_BUTTON: "#F5F5F5",
    FILE_BUTTON_HEIGHT: 75,
    FILE_BUTTON_TEXT: DEFAULTS.TEXT_COLOR,
    FILE_BUTTON_BORDER: DEFAULTS.BUTTON_BORDER,
    FILE_BUTTON_BACKGROUND: DEFAULTS.BUTTON_BACKGROUND,
    FILE_BUTTON_BACKGROUND_HOVER: DEFAULTS.BUTTON_BACKGROUND_HOVER,
    FILE_BUTTON_BACKGROUND_CLICK: DEFAULTS.BUTTON_BACKGROUND_CLICK,

    FILES_UPLOADED_HEIGHT: 40,

    UPLOAD_BUTTON_HEIGHT: 70,
    UPLOAD_BUTTON_BACKGROUND: DEFAULTS.BUTTON_BACKGROUND,
    UPLOAD_BUTTON_TEXT: DEFAULTS.TEXT_COLOR,
    UPLOAD_BUTTON_WIDTH: 130,
    UPLOAD_BUTTON_BORDER: DEFAULTS.BUTTON_BORDER,

    FIELD_SITE_ID_WIDTH: 145,
    FIELD_PATIENT_ID_WIDTH: 145,

    ADDITIONAL_DATA_BUTTON_WIDTH: 250,
    ADDITIONAL_DATA_BACK_HEIGHT: 50,

    FILE_LIST_BACKGROUND: "#AABDC5"
}

export let BROWSE_PAGE = {
    PATIENTS_PANEL_BACKGROUND: "#C9D6DC",

    LEFT_PANNEL_OPEN: '25%',
    LEFT_PANNEL_CLOSED: '3%',

    MODALITY_TABLE_HEIGHT: "25%",
    PATIENT_UID_TABLE_HEIGHT: "25%",
    GROUP_TABLE_HEIGHT: "25%",

    CATEGORY_HEIGHT: 30,

}

export let VISUALIZATIONS = {
    GRAPH_HEIGHT: 100,
    GRAPH_PADDING: { TOP: 10, RIGHT: 30, LEFT: 40, BOTTOM: 10 },
    TEXT_WIDTH: 150,
    X_AXIS_HEIGHT: 20,

    PIE_RADIUS: 50,
    TEXT_SPACING: 70
}


export let REVIEW_MMREPORTS = {
    BACKGROUND_COLOR_TRENDS: '#adbbc2',

    LEFT_PANEL_HEIGHT: window.innerHeight - NAVBAR.HEIGHT - 32,
    DISPLAY_SPACE_HEIGHT: window.innerHeight - ((2 * NAVBAR.HEIGHT) + 2),
}

