import { memo, useEffect } from 'react'
import { useDrag, useDrop} from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'
import Connection from '../../../../../Managers/PipelineManager/Connection.ts'
import ConnectionManager from '../../../../../Managers/PipelineManager/ConnectionManager.ts'
import { NodeOutputTypeColors } from './NodeOutputTypeColors'

export const NodeConnectionPoint = memo(({props}) => {
    const {id, type, isOutput, state, setState} = props
    const size = 23

    let margin = {marginRight: `-${size/2}px`}

    if (isOutput) {
        margin = {marginLeft: `-${size/2}px`}
    }
    
    const [, drag, preview] = useDrag(
        () => ({
          type: `connection_point`,
          item: {id, type, isOutput},
          collect: (monitor) => ({
            isDragging: monitor.isDragging(),
          }),
        }),
        [id, type, state, setState, isOutput],
    )

    const [{ canDrop }, drop] = useDrop(
        () => ({
            accept: `connection_point`,
            canDrop: (item) => connectionIsValid(item),
            drop: (item) => handleDrop(item),
            collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
            }),
        }),
        [type, isOutput, id, connectionIsValid, handleDrop],
    )

    useEffect(() => {
        preview(getEmptyImage(), { captureDraggingState: true })
      }, [preview])

    function connectingInputToOutput(otherConnectionPoint) {
        return !otherConnectionPoint.isOutput && isOutput
    }

    function connectingOutputToInput(otherConnectionPoint) {
        return otherConnectionPoint.isOutput && !isOutput
    }

    function getConnectionSourceId(otherConnectionPoint) {
        return isOutput ? id : otherConnectionPoint.id
    }

    function getConnectionTargetId(otherConnectionPoint) {
        return isOutput ? otherConnectionPoint.id : id
    }

    function connectionWillCreateAnInfiniteLoop(sourceId, targetId) {
        const connection = new Connection(sourceId, targetId)

        if (connection.source.getNodeId() === connection.target.getNodeId()) {
            return true
        }

        const nextConnections = ConnectionManager.getNextConnections(connection, state.connections)

        if (!nextConnections) {
            return false
        }

        return nextConnections
                .map(connection => connectionWillCreateAnInfiniteLoop(sourceId, connection.target.id))
                .some(result => result === true)
    }


    function connectionIsValid(otherConnectionPoint) {
        const sourceId = getConnectionSourceId(otherConnectionPoint)
        const targetId = getConnectionTargetId(otherConnectionPoint)

        const sourceType = (sourceId === id) ? type : otherConnectionPoint.type
        const targetType = (targetId === id) ? type : otherConnectionPoint.type

        if (!ConnectionManager.connectionTypesAreCompatible(sourceType, targetType)) {
            return false
        }

        return (connectingInputToOutput(otherConnectionPoint) || connectingOutputToInput(otherConnectionPoint)) 
            && !connectionWillCreateAnInfiniteLoop(sourceId, targetId)
    }

    function handleDrop(otherConnectionPoint) {
        const sourceId = getConnectionSourceId(otherConnectionPoint)
        const targetId = getConnectionTargetId(otherConnectionPoint)

        setState(prevState => {
            const prevStateCopy = {...prevState}
            prevStateCopy.connections = ConnectionManager.addConnection(new Connection(sourceId, targetId), prevStateCopy.connections)
            return prevStateCopy
        })
    }

    return <div id={id} ref={ canDrop ? drop : drag} title={type} style={{
        height: `${size}px`,
        width: `${size}px`,
        background: canDrop ? "lightgreen" : "black", //NodeOutputTypeColors.getColorFromType(type),
        borderRadius: "50%",
        ...margin}}>
    </div>
})