export enum MobergFontSize {
    X_SMALL = "0.75rem",
    SMALL = "0.875rem",
    REGULAR = "1rem",
    LARGE = "1.125rem",
    X_LARGE = "1.25rem",
    XX_LARGE = "1.5rem",
}

export enum MobergFontFamily {
    REGULAR = "Source Sans Pro",
    HEADER = "Montserrat"
}