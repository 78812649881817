import PIPELINE_STRUCTURE, { NODE_DEFINITIONS } from '../../Constants/NodeStructures';

/**
 * Class representing a single node as part of a Pipeline
 * The node will contain a list of Inputs
 */
export default class Node {
    /**
     * Construct a Node
     * @constructor
     * @param {number} id - The integer id of the node
     * @param {String} type - The type of input can be either ["SOURCE", "ANALYTIC", "VISUALIZATION"]
     * @param {String} subtype - The specific node underneath the above category
     * @param {Array} position - An array containg [x, y] to represent the position in the pipeline display
     */
    constructor(id, type, subtype, position=[0,0]) {
        this.id                 = id
        this.type           	= type
        this.subtype            = subtype
        this.inputs         	= []
        this.position       	= position
        this.display_name   	= ""
        this.next           	= []
        this.previous       	= []
        this.export_id      	= -1
        this.token          	= ''
		this.shared_information = {}

        this.setDefaultStructure()
    }


    /**
     * Sets the node to have inputs with default values as it should be initialized
     * This method is responsible for setting up all of the inputs for the node
     * @param {String} type - The type of input can be either ["SOURCE", "ANALYTIC", "VISUALIZATION"]
     * @param {String} subtype - The specific node underneath the above category
     */
    setDefaultStructure(type=this.type, subtype=this.subtype) {
        type = type.toUpperCase()
        subtype = subtype.toUpperCase()

        const structure = PIPELINE_STRUCTURE[type][subtype]
        this.display_name = structure[NODE_DEFINITIONS.DISPLAY_NAME]
        this.inputs = structure[NODE_DEFINITIONS.INPUTS]
    }


    /**
     * This will be called before a node is removed from a pipeline and will remove all conections to other nodes
     */
    delete() {
        for (let i of this.next) {
            i.removePrevious(this)
        }
        for (let i of this.previous) {
            i.removeNext(this)
        }
    }


    /**
     * Remove a connection to a node store in its array of next nodes
     * @param {Node} next_node - This node will remove it's connection to next_node
     */
    removeNext(next_node) {
        let i = this.next.indexOf(next_node)
        this.next.splice(i, 1)
    }


    /**
     * Remove a connection to a node store in its array of previous nodes
     * @param {Node} previous_node - This node will remove it's connection to previous_node
     */
    removePrevious(previous_node) {
        let i = this.previous.indexOf(previous_node)
        this.previous.splice(i, 1)
    }

    
    /**
     * This class will be used to export the structure of the node and list all attributes
     * @returns {dictionary} - The structure of the node including all variables and inputs
     */
    exportStructure() {
        let output = {
            "type": this.type,
            "subtype": this.subtype,
            "name": this.display_name,
            "coords": this.position,
            "args": {}
        }

        if (this.type === "SOURCE") {
            output["args"] = {"token": this.token}
        }

        for (let config of this.inputs) {
            output["args"][config.VARIABLE_NAME] = config.VALUE ?? config.DEFAULT_VALUE
        }
        
        return output
    }


    /**
     * This class will be used to export the architecture of the node and how it connects to other nodes
     * @returns {dictionary} - The architecture of the node representing it's connection to other nodes
     */
    exportArchitecture() {
        if (this.type === NODE_DEFINITIONS.SOURCE)
            return undefined
        
        let output = {
            inputs: this.previous
        }

        return output
    }
}