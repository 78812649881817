import React, { useRef, useState } from "react"
import { MobergMenuController } from "../../Hooks/useMobergMenu"
import { MobergButton, MobergButtonVariant, MobergColumn, MobergRow, MobergShortTextInput, MobergTheme } from "../../Moberg"
import { EEGMontage } from "../../Pages/Data/Visualize/DataReview/Types/EEG"
import { useAsyncTask } from "../../Hooks/useAsyncTask"
import { useEEGMontageService } from "../../Hooks/useEEGMontageService"
import { MobergText } from "../MobergHeader/MobergHeader"
import { useOnMount } from "../../Hooks/useOnMount"

type RenameEEGMontageProps = {
	menu: MobergMenuController
	eegMontage: EEGMontage
}

export const RenameEEGMontage: React.FC<RenameEEGMontageProps> = ({ menu, eegMontage }) => {
	const inputRef = useRef<HTMLInputElement>(null)
	const { modifyEEGMontage } = useEEGMontageService()
	const [name, setName] = useState<string>(eegMontage.name)

	const renameTask = useAsyncTask(() => {
		const trimmed = name.trim()

		if (trimmed === eegMontage.name || trimmed === "") {
			return new Promise(resolve => resolve(null))
		}

		return modifyEEGMontage({ ...eegMontage, name: trimmed })
	})

	const submit = () => {
		if (renameTask.isWaiting) {
			return // don't allow submit while the task is running!
		}

		inputRef.current?.blur() // re-enable hotkeys if the input did not lose focus

		renameTask.run().then(() => {
			menu.close()
		})
	}

	useOnMount(() => inputRef.current?.focus())

	return (
		<MobergColumn gap="16px" horizontalAlign="end">
			<MobergShortTextInput
				ref={inputRef}
				label={"Rename"}
				limit={50}
				defaultValue={eegMontage.name}
				onChange={name => setName(name)} onSubmit={submit}
			/>

			<MobergRow horizontalAlign="right" gap="16px">
				{renameTask.isWaiting && (
					<MobergText> Please wait... </MobergText>
				)}

				<MobergButton theme={MobergTheme.BLUE} variant={MobergButtonVariant.FILLED} onClick={submit} disabled={renameTask.isWaiting}>
					OK
				</MobergButton>
			</MobergRow>
		</MobergColumn>
	)
}
