import { useState } from 'react';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import dayjs from 'dayjs';
import { MobergDropdown } from '../MobergDropdown/MobergDropdown';
import { MobergButton, MobergButtonShape } from '../MobergButton/MobergButton';
import { MdClose } from 'react-icons/md';
import { MobergIconSize } from '../MobergIcon/MobergIcon';
const ariaLabel = { 'aria-label': 'description' };


export default function FilterForm(props) {
    const { formIndex, localQueryOptions, setLocalQueryOptions, onClose } = props;

    const [columns, setColumns] = useState("id");
    const handleChangeColumns = (value) => setColumns(value);

    const [operator, setOperator] = useState("contains");
    const handleChangeOperator = (value) => setOperator(value);
    const handleOnChange = (event) => {
        setLocalQueryOptions({
            ...localQueryOptions,
            items: localQueryOptions.items.map((item, index) => {
                if (index === formIndex) {
                    return {
                        ...item,
                        value: event.target.value
                    }
                }
                return item
            })
        })
    }

    const handleOnClick = () => {
        // Set timeout is needed to make sure that we have time to check whether the clicked element
        // was inside the container before it gets removed.
        setTimeout(() => {
            const localQueryOptionsItemsLength = localQueryOptions.items.length
            if (localQueryOptionsItemsLength === 1) {
                onClose()
                return
            }
    
            setLocalQueryOptions(previous => {
                return {
                    ...previous,
                    items: [...previous.items].filter((item, index) => index !== formIndex)
                }
            })
        })
    }

    const [value, setValue] = useState(dayjs('2022-04-17T15:30'));

    return (
        <div style={{ 'display': 'flex', alignItems: "center", gap: "16px", padding: "8px", justifyContent: "space-between" }}>
            <MobergDropdown
                selectedValue={columns}
                onChange={handleChangeColumns}
                options={[
                    { label: "id", value: "id" },
                    { label: "name", value: "name" }
                ]}
            />

            <MobergDropdown
                selectedValue={operator}
                onChange={handleChangeOperator}
                options={[
                    { label: "contains", value: "contains" },
                    { label: "equals", value: "equals" }
                ]}
            />

            <FormControl variant="standard" sx={{ m: 1, minWidth: 120, margin: 0 }}>
                <Input placeholder="Filter value" inputProps={ariaLabel} onChange={handleOnChange} style={{padding: "4px 8px"}} />
                {/* <DateTimePicker
                    value={value}
                    onChange={(newValue) => setValue(newValue)}
                /> */}
            </FormControl>

            <div style={{ display: "flex", alignItems: "center" }}>
                <MobergButton shape={MobergButtonShape.SQUARE} onClick={handleOnClick}>
                    <MdClose size={MobergIconSize.REGULAR} />
                </MobergButton>
            </div>
        </div>
    )
}
