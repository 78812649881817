import { useState } from "react"

export default function TextInput({ nodeId, config, initialValue, onChange }) {
    const [value, setValue] = useState(initialValue ?? "")

    return (
        <div style={{display: "flex"}}>
            <div style={{paddingRight: "8px"}}>
                {config.DISPLAY_NAME}
            </div>

            <input
                type="text"
                placeholder={config.DEFAULT_VALUE ?? ""}
                onChange={(e) => {
                    setValue(e.target.value)
                    if (onChange) {
                        onChange({nodeId, arg: config.VARIABLE_NAME, value: e.target.value})
                    }
                }}
                style={{
                    border: "1px solid gray", 
                }}
                value={value}
            />
        </div>
    )
}