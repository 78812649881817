import { Selection, ScaleLinear, scaleLinear, EnterElement, extent } from "d3"
import { D3ValueAxis } from "../../../D3/D3ValueAxis"
import { D3TracesWrapper } from "./D3TracesWrapper"
import { D3ModalityGraphLegend } from "./D3ModalityGraphLegend"
import { D3HorizontalLines } from "../../../D3/D3HorizontalLines"
import { D3VerticalLines } from "../../../D3/D3VerticalLines"
import { ModalityGraphGroupReactCallbacks, } from "../../../../Types/ReactCallbacks"
import { TimeSeriesPageManager } from "../../../../Data/TimeSeriesPageManager"
import { ModalityGraphConfig, ModalityGraphConfigWithDerivedTraces } from "../../../../Types/Graph"
import { D3OneToOneRenderable } from "../../../D3/D3OneToOneRenderable"
import { ModalityPage } from "../../../../Data/ModalityPage"
import { LineTraceConfigJSON } from "../../../../Types/Trace"
import { getSignal } from "../../../../Data/TimeSeriesData"

export class D3ModalityGraph extends D3OneToOneRenderable<SVGGElement, SVGGElement, ModalityGraphConfig, ModalityGraphGroupReactCallbacks> {
	private yScale: ScaleLinear<any, any, any>
	private pageManager: TimeSeriesPageManager<ModalityPage>
	private graph: ModalityGraphConfigWithDerivedTraces 

	// Children
	private yAxis?: D3ValueAxis
	private tracesWrapper?: D3TracesWrapper
	private legend?: D3ModalityGraphLegend
	private horizontalLines?: D3HorizontalLines
	private verticalLines?: D3VerticalLines

	constructor(root: SVGGElement, config: ModalityGraphConfig, pageManager: TimeSeriesPageManager<ModalityPage>, reactCallbacks: ModalityGraphGroupReactCallbacks) {
		super(root, config, "d3-modality-graph", reactCallbacks)
		this.pageManager = pageManager

		const { min, max, height } = this.config
		
		this.yScale = scaleLinear()
			.domain([min, max])
			.range([height ?? 0, 0])

		this.graph = {} as ModalityGraphConfigWithDerivedTraces

		this.updateDerivedState()
		this.render()
	}

	protected updateDerivedState(): void {
		this.graph = this.getDerivedGraph() as any
		this.updateChildren()
	}

	public renderPage = (page: ModalityPage) => {
		this.tracesWrapper?.renderPage(page)
	}

	public viewTimesChanged = () => {
		this.verticalLines?.render()
		this.tracesWrapper?.render()
	}

	public rescale = () => {
		this.horizontalLines?.render()
		this.verticalLines?.render()
		this.tracesWrapper?.rescale()
	}

	public takeSnapshot = () => {
		this.tracesWrapper?.takeSnapshot()
	}

	public clearSnapshot = () => {
		this.tracesWrapper?.clearSnapshot()
	}

	public isRescaling = (): boolean => {
		return this.tracesWrapper?.isRescaling() ?? false
	}

	public getDerivedGraph = () => {
		const { min, max, height } = this.config
		this.yScale.domain([min, max]).range([height ?? 0, 0])
		return {...this.config, traces: this.config.traces.map(traceConfig => ({ ...traceConfig, id: traceConfig.id ?? new Date(Date.now()).toISOString(), graphId: this.config.id, xScale: this.config.xScale, yScale: this.yScale }))}
	}

	protected enter = (newGraph: Selection<EnterElement, any, any, any>): Selection<SVGGElement, any, any, any> => {
		const graphGroup = newGraph.append("g").attr("class", this.className).attr("transform", `translate(0, ${this.config.offset})`)
		graphGroup.each(this.createChildren)
		return graphGroup
	}

	protected update = (updatedGraphs: Selection<any, any, any, any>): Selection<any, any, any, any> => {
		updatedGraphs.attr("transform", `translate(0, ${this.config.offset})`)
		this.renderChildren()
		return updatedGraphs
	}

	protected createChildren = (config: ModalityGraphConfig, index: number, nodes: ArrayLike<SVGGElement>) => {
		const root = nodes[index]
		this.yAxis = new D3ValueAxis(root, this.getAxisConfig(), this.reactCallbacks)
		this.horizontalLines = new D3HorizontalLines(root, { yScale: this.yScale, width: this.config.width })
		this.verticalLines = new D3VerticalLines(root, { xScale: this.config.xScale, height: this.config.height })
		this.tracesWrapper = new D3TracesWrapper(root, { traces: this.graph.traces }, this.pageManager, this.reactCallbacks)
		this.legend = new D3ModalityGraphLegend(root, { traces: this.graph.traces as LineTraceConfigJSON[], width: this.config.width }, this.reactCallbacks)
	}

	protected updateChildren = () => {
		this.yAxis?.updateConfig(this.getAxisConfig())
		this.tracesWrapper?.updateConfig({ traces: this.graph.traces })
		this.legend?.updateConfig({ traces: this.graph.traces as LineTraceConfigJSON[], width: this.config.width })
		this.horizontalLines?.updateConfig({yScale: this.yScale, width: this.config.width})
		this.verticalLines?.updateConfig({xScale: this.config.xScale, height: this.config.height})
	}

	private renderChildren = () => {
		this.yAxis?.render()
		this.tracesWrapper?.render()
		this.legend?.render()
		this.horizontalLines?.render()
		this.verticalLines?.render()
	}

	private onValueAxisDrag = () => {
		this.horizontalLines?.render()
		this.verticalLines?.render()
		this.tracesWrapper?.redraw()
	}

	private onValueAxisDragEnd = () => {
		const [min, max] = this.yScale.domain()

		this.reactCallbacks.setRootConfig(previous => (
			{
				...previous,
				graphs: previous.graphs.map(graph => {
					if (graph.id === this.config.id) {
						return {...graph, min, max}
					}
					return graph
				})
			})
		)
	}

	private getAxisConfig = () => ({
		scale: this.yScale, 
		graphId: this.config.id, 
		width: this.config.width,
		onDrag: this.onValueAxisDrag,
		onDoubleClick: this.autoScale,
		onDragEnd: this.onValueAxisDragEnd
	})

	public autoScale = () => {
		const allExtentValues: number[] = []
		const traces = this.graph.traces

		this.pageManager.getAllLoadedPages().forEach(page => {
			const extentPerTrace = traces
				.flatMap(trace => {
					const dataObjectId = this.reactCallbacks.dataSourceMap.get(trace.dataSource) ?? Infinity
					const timeSeriesData = page.data.get(dataObjectId)?.get(trace.dataKey)

					if (!timeSeriesData) {
						return []
					}
					
					const signal = getSignal(timeSeriesData, trace)
					return extent(signal)
				})
				.filter(p => p !== undefined) as number[]

			allExtentValues.push(...extentPerTrace)
		})

		let [min, max] = extent(allExtentValues)

		// explicitly check for undefined because 0 would return false
		if (min !== undefined && max !== undefined) {

			if (min === max) {
				min -= 0.001
			}

			this.reactCallbacks.setRootConfig(previous => (
				{
					...previous,
					graphs: previous.graphs.map(graph => {
						if (graph.id === this.config.id) {
							return {...graph, min: min as number, max: max as number}
						}

						return graph
					})
				})
			)

			this.tracesWrapper?.redraw()
		}
	}
}
