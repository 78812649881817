import { useRecoilValue, useSetRecoilState } from "recoil"
import { currentlyActiveVisualizationAreaAtom } from "../Atoms/Visualizations"
import { D3TimeBasedVisualization } from "../Components/Visualizations/D3TimeBasedVisualization"
import { visibleTracesAtom } from "../Atoms/Traces"
import { useEffect } from "react"

type useD3UpdateVisibleTracesProps = {
    d3Controller?: D3TimeBasedVisualization<any, any, any, any>
    windowId: string
}

// Updates the traces that are visible on the visualization window that was most recently active
export const useD3UpdateVisibleTraces = (props: useD3UpdateVisibleTracesProps) => {
    const activeVisualizationArea = useRecoilValue(currentlyActiveVisualizationAreaAtom)
    const setVisibleTraces = useSetRecoilState(visibleTracesAtom)

    useEffect(() => {
        if (activeVisualizationArea === props.windowId) {
            setVisibleTraces(props.d3Controller?.getVisibleTraces() ?? [])
        }
    })
}
