import React from "react"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"

import { useModalProvider } from "../../../../../../../../Providers/ModalProvider"
import { editedLayoutGroupAtom, selectedLayoutAtom, selectedLayoutGroupAtom, uneditedLayoutGroupAtom } from "../../../../Atoms/Layout"
import { PERSYST_TRENDS_WINDOW_TIME_PRESETS } from "../../../../../../../../Managers/VisualizationManager/Viewport/Components/XAxis"
import { persystTrendsAtom } from "../../../../Atoms/PersystWindow"
import { VisualizationToolbar } from "../../../React/VisualizationToolbar"
import { ConfigurePersystTrendsModal } from "./ConfigurePersystTrendsModal"
import { MobergDropdown, MobergDropdownSize, MobergDropdownValue } from "../../../../../../../../Components/MobergDropdown/MobergDropdown"

type PersystWindowSettingsProps = {
	windowId: string
}

export function PersystTrendsWindowSettings(props: PersystWindowSettingsProps) {
	const { createModal } = useModalProvider()
	const setUneditedLayoutGroup = useSetRecoilState(uneditedLayoutGroupAtom)
	const setEditedLayoutGroup = useSetRecoilState(editedLayoutGroupAtom)
	const selectedLayoutGroup = useRecoilValue(selectedLayoutGroupAtom)
	const selectedLayout = useRecoilValue(selectedLayoutAtom)

	const [persystConfig, setPersystConfig] = useRecoilState(persystTrendsAtom({ layoutId: selectedLayout?.id as string, windowId: props.windowId }))
	const [viewStart, viewEnd] = persystConfig.viewScale.domain()
	const viewDuration = viewEnd.getTime() - viewStart.getTime()

	function renderConfigureModal() {
		if (!selectedLayout) {
			console.error("Couldn't render the modal because a layout was not selected")
			return
		}

		setUneditedLayoutGroup(selectedLayoutGroup)
		setEditedLayoutGroup(selectedLayoutGroup)
		createModal(<ConfigurePersystTrendsModal windowId={props.windowId} layoutId={selectedLayout.id} />)
	}

	const artifactReductionChanged = (value: MobergDropdownValue) => {
		setPersystConfig(previous => ({
			...previous,
			artifactReductionEnabled: value as boolean
		}))
	}

	const artifactReductionOptions = [
		{ label: "On", value: true },
		{ label: "Off", value: false }
	]

	const currentArtifactReductionSetting = artifactReductionOptions.find(option => option.value === persystConfig.artifactReductionEnabled)

	const visualizationSpecificSettings = (
		<MobergDropdown 
			label={"Artifact Reduction"}
			selectedValue={currentArtifactReductionSetting?.value ?? ""}
			onChange={artifactReductionChanged}
			size={MobergDropdownSize.SMALL}
			options={artifactReductionOptions.map(preset => ({ label: preset.label, value: preset.value }))}
		/>
	)

	return <VisualizationToolbar 
		atomValue={persystConfig}
		setAtom={setPersystConfig}
		renderConfigureModal={renderConfigureModal}
		viewDuration={viewDuration} 
		timePresetOptions={PERSYST_TRENDS_WINDOW_TIME_PRESETS}
		visualizationSpecificSettings={visualizationSpecificSettings}
	/>
}
