import { select, Selection, EnterElement } from "d3"
import { ReactCallbacks } from "../../Types/ReactCallbacks"

export abstract class D3OneToManyRenderable<ElementType extends Element, ConfigType, DatumType, ReactCallbacksType = ReactCallbacks<any>> {
    protected root: Element
    protected config: ConfigType
    protected className: string
    protected reactCallbacks: ReactCallbacksType

    protected abstract datumIdentifier(datum: DatumType): number | string
    protected abstract getConfigs(): DatumType[]

    // These callbacks help us avoid allocating extra memory when performing renders
    private boundEnter?: (newElements: Selection<EnterElement, DatumType, Element, any>) => Selection<ElementType, DatumType, Element, any>
    private boundUpdate?: (updatedElements: Selection<ElementType, DatumType, Element, any>) => Selection<ElementType, DatumType, Element, any>
    private boundExit?: (exitedElements: Selection<any, any, any, any>) => void

    constructor(root: Element, config: ConfigType, className: string, reactCallbacks: ReactCallbacksType) {
        this.root = root
        this.config = config
        this.className = className
        this.reactCallbacks = reactCallbacks
    }

    protected mount() {
        // Binding the function to "this" allows us to use "this" inside of the enter, update, and exit functions
        // and have it actually mean the class instead of the D3 datum.
        // Binding the function also has significant performance benefits over using anonymous functions.
        this.boundEnter = this.enter.bind(this)
        this.boundUpdate = this.update.bind(this)
        this.boundExit = this.exit.bind(this)
        this.updateDerivedState()
        this.render()
    }

    public updateConfig = (config: ConfigType) => {
		this.config = config
        this.updateDerivedState()
		this.render()
	}
    
    public render() {
        if (!this.boundEnter) {
            throw new Error("Component was not mounted. Don't forget to call 'mount' in the child class constructor")
        }

        select(this.root)
			.selectAll<ElementType, DatumType>("." + this.className)
			.data(this.getConfigs(), this.datumIdentifier)
			.join(this.boundEnter, this.boundUpdate, this.boundExit)
    }
    
    protected updateDerivedState() {}

    protected abstract enter(newElements: Selection<EnterElement, DatumType, Element, any>): Selection<ElementType, DatumType, Element, any>
	protected abstract update(updatedElements: Selection<ElementType, DatumType, Element, any>): Selection<ElementType, DatumType, Element, any>

    protected exit(exitedElements: Selection<ElementType, DatumType, Element, any>): void {
        exitedElements.remove()
    } 
}