import { BIN_VALUES } from "./BinValuesNode"
import { EVENT_RESPONSE_DATA_SPLITTER } from "./EventResponseDataSplitterNode"
import { GROWING_WINDOW_DATA_SPLITTER } from "./GrowingWindowDataSplitterNode"
import { MODALITY_FILTER } from "./ModalityFilterNode"
import { SLIDING_WINDOW_DATA_SPLITTER } from "./SlidingWindowDataSplitterNode"


export const DATA_ORGANIZER_NODES = {
    BIN_VALUES,
    EVENT_RESPONSE_DATA_SPLITTER,
    GROWING_WINDOW_DATA_SPLITTER,
    MODALITY_FILTER,
    SLIDING_WINDOW_DATA_SPLITTER,
}