import { useEffect } from "react"
import { EEGMontage, MontageType } from "../Types/EEG"
import { eegMontagesAtom } from "../Atoms/EEGMontage"
import { useRecoilValue } from "recoil"
import { isEqual } from "lodash"

type useValidateEEGMontageProps = {
    montage: EEGMontage | null
    updateMontage: (montage: EEGMontage | null) => void
	defaultMontageId?: number
}

export const useValidateEEGMontage = ({ montage, updateMontage, defaultMontageId=1 }: useValidateEEGMontageProps) => {
    const eegMontages = useRecoilValue(eegMontagesAtom)

    // Check to see if the montage still exists in the database, or if any properties changed.
    // If a valid EEG montage exists, use the updated properties. If not, use a default. If there are no defaults, use null.
	useEffect(() => {
		const foundMontage = eegMontages.find(m => m.id === montage?.id)

		let newMontage: EEGMontage | undefined | null = undefined

		if (!foundMontage) {
			// Use one of the available montages as a default
			const standardMontages = eegMontages.filter(m => m.type === MontageType.STANDARD)
			const defaultMontage = eegMontages.find(m => m.id === defaultMontageId)

			if (defaultMontage) {
				newMontage = defaultMontage
			} else if (standardMontages.length > 0) {
				newMontage = standardMontages[0]
			} else if (eegMontages.length > 0) {
				newMontage = eegMontages[0]
			} else {
				newMontage = null
			}
			
		} else if (foundMontage && !isEqual(foundMontage, montage)) {
			// Update the current montage with the one from the database
			newMontage = foundMontage
		}
		
		if (newMontage !== undefined && !isEqual(montage, newMontage)) { // null is not the same thing as undefined
			updateMontage(newMontage as (EEGMontage | null))
		}
		
	}, [defaultMontageId, eegMontages, montage, updateMontage])
}
