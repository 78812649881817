import { atomFamily } from "recoil"
import { LayoutWindowId } from "../Types/LayoutWindowId"
import { PersystEEGWindowConfig, PersystTrendsWindowConfig } from "../Types/PersystWindow"
import { getDefaultAnnotationVisualizationAtom } from "./Visualizations"

export const persystTrendsAtom = atomFamily<PersystTrendsWindowConfig, LayoutWindowId>({
	key: "persystTrends",
	default: {
		...getDefaultAnnotationVisualizationAtom(),
		artifactReductionEnabled: false
	},
})

export const persystEEGAtom = atomFamily<PersystEEGWindowConfig, LayoutWindowId>({
	key: "persystEEG",
	default: () => ({
		...getDefaultAnnotationVisualizationAtom(),
		montage: null
	}),
})
