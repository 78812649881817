import styled from "styled-components";
import { MobergFontFamily } from "../Moberg";

export const ModifyRowIcon = styled.button`
    width: auto;
    height: 34px;
    background: transparent;
    border-radius: 6px;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    color: #000000;
    border: none;
    padding: 4px 8px;
    margin-right: 8px;
    white-space: nowrap;
    flex: 0.25;
`;

export const Searchbar = styled.div`
    width: 50%;
    height: 34px;
    background: #FFFFFF;
    border: 0.5px solid #d9d9d9;
    box-sizing: border-box;
    box-shadow: 0px 0px 3px rgba(99, 191, 244, 0.15);
    border-radius: 6px;
    overflow-y: hidden;
    flex: 2;
    button {
        width: 80px;
        height: 34px;
        background: #207dea;
        border-radius: 0px 6px 6px 0px;
        border: none;
        font-family: 'Source Sans Pro';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        color: #FFFFFF;
        cursor: pointer;
    }
`;

export const GrayOverlay = styled.div`
    background: #000;
    opacity: 0.5;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    height: 100%;
    display: none;
`;

export const Scrollbar = styled.div`
    max-height: 100vh;
    overflow: auto;
    scrollbar-width: thin;
    ::-webkit-scrollbar {
            display: block;
            width: 5px;
            color: #313A4A;
        }
    ::-webkit-scrollbar-track {
            background: #BEC4CF;
            width: 5px;
            border-radius: 2px;
        }
`;

export const InfoButton = styled.button`
    border: none;
    background: none;
    border: none;
    color: #207dea;
    cursor: pointer;
    margin-top: 18px;
    width: fit-content;
    margin-right: 5px;
    margin-top: 0px;
    // margin-bottom: 7px;
`;

export const ModalDropdownButton = styled.button`
    cursor: pointer;
    display: inline;
    flex-direction: row;
    border: solid 0.5px #FFFFFF;
    background-color: #FFFFFF;
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: #293241;
`;

export const ModalDropdownTableContainer = styled.div`
    border: 2px solid #cccccc;
    box-sizing: border-box;
    border-radius: 5px;
    background: #ffffff;
    width: 100%;
    height: auto;
    padding: 10px;
`;

export const PageSubHeader = styled.div`
    display: flex;
    width: 100%;
    height: 47px;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    background: #ffffff;
    padding-top: 10px;
    margin-bottom: 20px;
    button {
        font-family: "Source Sans Pro";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        display: inline-flex;
        margin-left: 45px;
        border: solid 0.5px #ffffff;
        background: #ffffff;
        cursor: pointer;
    }
`;

export const TableButton = styled.button`
    width: auto;
    height: 34px;
    background: #ffffff;
    border: solid 0.5px #ffffff;
    font-size: 16px;
    display: inline-flex;
    margin-right: -25px;
    color: ${({colorchange}: any) => (colorchange ? "#0097ee" : "#6C757C")};
    p {
        padding-bottom: 10px;
        border-bottom: ${({underlined}: any) =>
            underlined ? "2px solid #0097ee" : "none"};
    }
`;

export const ButtonHeader = styled.div`
    padding-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 15px;
    position: relative;
`;

export const InfoMessage = styled.div`
    background: rgba(32, 125, 234, 0.2);
    min-height: 30px;
    padding: 8px 8px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    p {
        color: rgb(32, 125, 234);
        margin: 0;
    }
    display: flex;
    align-items: center;
`;

export const WarningMessage = styled.div`
	background: rgba(238, 161, 12, 0.2);
	height: 30px;
	padding: 8px 8px;
	border-radius: 6px;
	p {
		color: rgb(238, 161, 12)
	}
    display: flex;
    align-items: center;
`;

export const ErrorMessage = styled.div`
	background: rgba(230, 78, 88, 0.2);
	height: 30px;
	padding: 8px 8px;
	border-radius: 6px;
	p {
		color: rgb(230, 78, 88)
	}
    display: flex;
    align-items: center;
`;

export const SuccessMessage = styled.div`
	background: rgba(15, 169, 95, 0.2);
	height: 30px;
	padding: 8px 8px;
	border-radius: 6px;
	p {
		color: rgb(15, 169, 95)
	}
    display: flex;
    align-items: center;
`;

export const ModalHeaderDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0;
    white-space: pre-line;
`;

export const ModalHeaderText = styled.h3`
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    flex: 1;
`;

export const ModalFooter = styled.div`
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
`;

export const ModalFooterButton = styled.button`
    background: none;
    display: inline-flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    padding: 6px 32px;
    margin-top: 0px;
    width: 114px;
    height: 40px;
    border-radius: 6px;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    border: 2px solid #207dea;
`;


export const UploadStepHeader = styled.text`
    font-family: "Source Sans Pro";
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;

`;

export const BackButtonDiv = styled.div`
    //For Back Arrow icon, use this below code:
    //<MdIcons.MdOutlineArrowRightAlt size={32} style={{marginRight: "5px", marginTop: "-4px", transform: "rotate(180deg)"}}/>
    //For "Back" text, use the NormalText styled component below
    display: inline-block;
    padding: 20px;
    margin-top: -15px;
    cursor: pointer;
`;

export const NormalText = styled.text`
    //NormalText is used for standard text, descriptions, etc. This would be used for all text except titles, bolded text, headers, and subheaders.
    position: absolute;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400px;
    font-size: 16px;
    color: #293241;
    line-height: 150%;
`;

export const DataTypeContentContainer = styled.div`
    background: rgba(239, 241, 244, 0.75);
    max-height: 420px;
    height: fit-content;
`;

export const TableHeaderText = styled.div`
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: #207DEA;
`;

export const TableHeaderContainer = styled.div`
    position: relative;
    width: inherit;
    display: flex;
`;

export const UploadButton = styled.button`
    width: 210px;
    height: 35px;
    background: #207DEA;
    border-radius: 5px;
    position: absolute;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #FFFFFF;
    border: none;
    pointer: cursor;
    right: 0px;
    padding-top: 4px;
    padding-right: 4px;
`;

export const UploadFilesModal = styled.div`
    width: 970px;
    height: 776px;
    overflow-x: hidden;
    background: #FFFFFF;
    border-radius: 6px;
    border: none;
    z-index: 950;
    display: flex;
    flex-direction: column;
    h3 {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 150%;
        color: #293241;
        text-align: center;
        padding-top: 10px;
    }
    h2 {
        font-family: 'Source Sans Pro';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        color: #293241;
    }
    p {
        font-family: 'Source Sans Pro';
        font-weight: 400;
        font-style: normal;
        font-size: 16px;
        line-height: 120%;
        color: #5F6775;
        margin-bottom: 0px;
    }
`;

export const HiddenButton = styled.button`
    visibility: hidden;
    pointer-events: none;
    height: 0;
    width: 0;
    padding: 0;
    margin: 0;
    border: none;
`;

export const InfoButtonLabel = styled.button`
    border: none;
    background: none;
    border: none;
    color: #207dea;
    cursor: pointer;
    margin-top: 18px;
    width: fit-content;
    margin-right: 5px;
    margin-top: 0px;
    margin-bottom: 7px;
`;

export const GrayDescriptionText = styled.div`
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #5F6775;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
`;

export const UploadDescription = styled.div`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 23px;
    color: gray;
`;

export const PercentUploaded = styled.div`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 64px;
    text-align: center;
`;

export const ModalTextInput = styled.input`
    background:  #FFFFFF;
    box-sizing: border-box;
    border-radius: 5px;
    width: 415px;
    height: 56px;
    padding: 16px;
    color: #000000;
    ::placeholder {
        font-family: 'Source Sans Pro';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: "#B6B6B6";
    }
`;

export const PatientIDValidationMessage = styled.div`
    font-family: "Source Sans Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-left: auto;
    margin-right: auto;
    line-height: 18px;
    text-align: center;
    margin-top: 15px;
`;

export const SelectExistingPatientDiv = styled.div`
    width: 258px;
    height: 115px;
    background: #FFFFFF;
    box-shadow: 0px 5px 25px rgba(9, 44, 76, 0.16);
    border-radius: 5px;
    padding: 20px 22px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    p {
        font-family: "Source Sans Pro";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #293241;
    }
    button {
        padding: 8px 32px;
        width: 213px;
        height: 34px;
        border-radius: 6px;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 118%;
        color: #FFFFFF;
        border: 2px solid #207dea;
        cursor: pointer;
        background: #207DEA;
        margin-top: 6px;
    }
`;


export const DataSummaryDiv = styled.div`
    display: flex;
    width: 400px;
    height: 65px;
    background: #FFFFFF;
    border-radius: 6px;
    box-shadow: 0px 6px 32px rgba(9, 44, 76, 0.16);
    padding: 15px 30px;
    position: relative;
    margin-bottom: 35px;
    align-items: center;
    h2 {
        font-family: 'Source Sans Pro';
        font-style: normal;
        font-weight: 700;
        font-size: 19px !important;
        color: #293241;
        text-align: left;
    }
    p {
        font-family: 'Source Sans Pro';
        font-style: normal;
        font-weight: 400;
        font-size: 17px;
        line-height: 195%;
        text-align: right;
        color: #207DEA;
        position: absolute;
        right: 30px;
        
    }
`;

export const AgreementLabel = styled.label`
    display: flex;
    align-items: center;
    cursor: pointer;

    & input[type="checkbox"] {
    margin-right: 10px;
    cursor: pointer;
    }
`;

export const TextTerm = styled.div`
    left: 649px;
    top: 160px;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #000000 !important;
    h2 {
        font-family: 'Source Sans Pro';
        font-weight: 700 !important;
        font-size: 16px;
        color: #000000 !important;
        line-height: 150% !important;
    }
    p {
        font-family: 'Source Sans Pro';
        font-weight: 400;
        font-size: 16px;
        color: #000000 !important;
        line-height: 150% !important;
    }
    li {
        font-family: 'Source Sans Pro';
        font-weight: 400;
        font-size: 16px;
        color: #000000 !important;
        margin-bottom: 20px;
        line-height: 150% !important;
    }
    ol {
        list-style: none;
        counter-reset: custom-counter;
    }

    ol li:before {
        content: counter(custom-counter, lower-alpha) ". ";
        counter-increment: custom-counter;
        margin-right: 0.5em;
    }
`;

export const SearchFieldDiv = styled.div`
	width: 100%;
	margin-bottom: 0px !important;
	input {
		height: 34px !important;
	}
`; //used to style the SearchField for the new TableComponent

export const ElementConfigureDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px 0px 30px;
    margin-bottom: 16px;
    font-family: ${MobergFontFamily.REGULAR};
`

export const RequiredField = styled.div`
    display: flex;
    align-items: center;
    
    :after {
        content: "*";
        color: red;
    }
`

export const VisualizationInput = styled.input`
    height: 30px;
    // flex-grow: 1;
    margin-left: 20px;
    border-radius: 5px;
    border: 1px solid #5A6679;
    width: 76.5%;
    padding: 0px 5px;

    :focus {
        outline: none;
    }
`

export const ModalityConfigurationDiv = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 0 16px;
    justify-content: space-between;
`;

