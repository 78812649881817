import { createTheme } from "@mui/material/styles";

const baseStyles = {
    border: 'none',
    "& .MuiDataGrid-withBorderColor": {
        border: "none"
    },
    "& .MuiDataGrid-columnHeader": {
        fontSize: "17px",
        backgroundColor: 'white',
        "&:focus": {
            outline: "none",
        },
        "& .MuiDataGrid-columnHeaderTitle": {
            fontWeight: "bold",
            color: "black",
            fontFamily: 'Source Sans Pro'
        },
        "&:hover": {
            "& .MuiDataGrid-columnHeaderTitle": {
                opacity: 0.7
            }
        }
    },
    '& .MuiDataGrid-columnsContainer': {
        marginBottom: '20px',
    },
    "& .MuiDataGrid-cell": {
        color: "#293241",
        background: 'white',
        fontFamily: 'Source Sans Pro',
        userSelect: 'auto',
        "&:focus": {
            outline: "none",
        },
        borderRight: "1px solid #ccc",
        fontSize: '15px'
    },
    "& .MuiDataGrid-cellCheckbox:focus-within": {
        outline: "none",
    },
    "& .MuiDataGrid-cell--withRenderer:focus-within": {
        outline: "none",
    },
    "& .MuiDataGrid-cell:focus": {
        outline: "none"
    },
    "& .MuiDataGrid-columnHeader:focus": {
        outline: 'none'
    },
    "& .MuiDataGrid-toolbarContainer": {
        justifyContent: "space-between",
        marginBottom: '30px',
        padding: "0px",
        "& .MuiButtonBase-root": {
            background: '#207DEA',
            color: 'white',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '4px 8px',
            fontFamily: 'Source Sans Pro',
            fontWeight: 600,
            fontStyle: 'normal',
            lineHeight: '150%',
            borderRadius: '6px',
            "& .MuiButton-startIcon": {
                display: 'none'
            }
        },
    },
    "& .MuiDataGrid-cellContent": {
        userSelect: 'text'
    },
    "& .MuiDataGrid-iconSeparator": {
        display: 'none'
    },
    "& .MuiDataGrid-virtualScroller": {
        '&::-webkit-scrollbar': {
            width: '1px', // Adjust the scrollbar width as needed	
            display: 'block',
            color: '#313a4a',
            borderRadius: '2px'
        },
        '&::-webkit-scrollbar-thumb': {
            background: 'gray', // Color of the scrollbar thumb	
            borderRadius: '6px', // Rounded corners of the thumb	
        },
        '&::-webkit-scrollbar-thumb:hover': {
            background: '#5F6267', // Color of the scrollbar thumb on hover	
        },
        '&::-webkit-scrollbar-track': {
            background: '#bec4cf',
            height: '1px',
            borderRadius: '2px'
        },
    },
    "& .MuiDataGrid-columnsPanelRow": {
        display: "none !important"
    },
    "& .MuiDataGrid-panel": {
        borderRadius: '6px'
    },
    "& .MuiDataGrid-footerContainer": {
        marginTop: '10px'
    },
    "& .progress-green": {
        background: '#D4EDDA !important'
    },
    "& .progress-red": {
        background: '#F8D7DA !important'
    },
    "& .progress-yellow": {
        background: '#FFFDD0 !important'
    }
}

const CustomTheme = createTheme({
    components: {
        MuiDataGrid: {
            styleOverrides: {
                root: ({ ownerState }) => {
                    if (ownerState && ownerState.density && ownerState.density === 'compact') {
                        return {
                            ...baseStyles,
                            "& .MuiDataGrid-columnHeader": {
                                borderTop: '1px solid #ccc',
                                borderRight: '1px solid #ccc',
                                borderBottom: '1px solid #ccc',
                                background: 'white',
                                fontSize: "17px",
                                cursor: 'pointer',
                                "&:focus": {
                                    outline: "none",
                                },
                                "& .MuiDataGrid-columnHeaderTitle": {
                                    fontWeight: "bold",
                                    color: "black",
                                    fontFamily: 'Source Sans Pro'
                                },
                                "&:hover": {
                                    "& .MuiDataGrid-columnHeaderTitle": {
                                        opacity: 0.7
                                    }
                                }
                            },
                            '& .MuiDataGrid-columnHeader:first-of-type': {
                                borderLeft: '1px solid #ccc'
                            },
                            "& .MuiDataGrid-cell:first-of-type": {
                                borderLeft: '1px solid #ccc',
                            },
                            '& .MuiDataGrid-row:last-of-type .MuiDataGrid-cell': {
                                borderBottom: "1px solid #ccc !important",
                            },
                            "& .MuiDataGrid-row:nth-of-type(odd) .MuiDataGrid-cell": {
                                background: "#FAFAFA",
                            },
                            "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
                                display: "none"
                            }
                        }
                    } else {
                        return {
                            ...baseStyles,
                            "& .MuiDataGrid-columnHeader": {
                                marginBottom: "20px",
                                paddingTop: '10px',
                                background: 'white',
                                fontSize: "17px",
                                backgroundColor: 'white',
                                cursor: 'pointer',
                                "&:focus": {
                                    outline: "none",
                                },
                                "& .MuiDataGrid-columnHeaderTitle": {
                                    fontWeight: "bold",
                                    color: "black",
                                    fontFamily: 'Source Sans Pro'
                                },
                                "&:hover": {
                                    "& .MuiDataGrid-columnHeaderTitle": {
                                        opacity: 0.7
                                    }
                                },
                            },
                            '& .MuiDataGrid-columnHeader:not(:last-of-type)': {
                                borderRight: '1px solid #ccc'
                            },
                            "& .MuiDataGrid-cell": {
                                borderBottom: "1px solid #ebebeb",
                                borderRight: "1px solid #ccc",
                                background: 'white',
                                userSelect: 'auto',
                                color: "#293241",
                                fontFamily: 'Source Sans Pro',
                                fontSize: '15px'
                            },
                            "& .MuiDataGrid-cell:last-child": {
                                borderRight: "none"
                            },
                            "& .MuiDataGrid-row:hover": {
                                background: "#e8f4fc",
                                "& .MuiDataGrid-cell": {
                                    background: "#e8f4fc", // Set cell background color on hover
                                    color: "#207DEA"
                                },
                            },
                            '& .MuiDataGrid-row:last-of-type .MuiDataGrid-cell': {
                                borderBottom: 'none',
                            },
                            '& .MuiDataGrid-row:first-of-type .MuiDataGrid-cell': {
                                borderTop: 'none',
                            },
                            "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
                                display: ownerState?.disableSelectAll ? 'none' : 'flex'
                            },
                            "& .MuiDataGrid-columnHeaderCheckbox": {
                                pointerEvents: ownerState?.disableSelectAll ? 'none' : 'auto'
                            }
                        }
                    }
                },
            }
        },
        MuiInput: {
            styleOverrides: {
                root: {
                    background: 'white',
                    border: '1px solid #ccc',
                    borderRadius: '3px',
                    flex: 1,
                    height: '34px',
                    transition: 'border 0.3s', // Add a transition for a smoother change
                    '&:has(.MuiInputBase-input:focus)': {
                        border: '1px solid black'
                    },
                    '& .MuiInputAdornment-root': {
                        paddingLeft: '5px'
                    },
                    '& .MuiInputBase-input:focus': {
                        color: 'black'
                    },
                    '& .MuiInputBase-input:not(:focus)': {
                        color: '#666666'
                    },
                }
            }
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    "& ::-webkit-outer-spin-button, & ::-webkit-inner-spin-button": {
                        WebkitAppearance: "none",
                        margin: 0,
                    },
                    "& [type=number]": {
                        MozAppearance: "textfield",
                    }
                }
            }
        },
        MuiTooltip: {
            styleOverrides: {
                popper: {
                    zIndex: 10000
                }
            }
        }
    }
})

export default CustomTheme