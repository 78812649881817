import React, { createContext, useContext } from "react"
import { VisualizationComponent } from "../../../VisualizationComponentFactory"
import { Layout } from "../../../Types/Layout"
import { MobergDropdown, MobergDropdownValue } from "../../../../../../../Components/MobergDropdown/MobergDropdown"

type VisualizationSelectorContextProps = {
	currentLayoutId: string
	selectedLayout: Layout | undefined
	updateSelectedLayout(layout: Layout): void
}

export const VisualizationSelectorContext = createContext<VisualizationSelectorContextProps>({} as VisualizationSelectorContextProps)

export type VisualizationSelectorProps = {
	windowId: string
}

export const VisualizationSelector = (props: VisualizationSelectorProps) => {
	const { selectedLayout, updateSelectedLayout } = useContext(VisualizationSelectorContext)
	const window = selectedLayout?.areas.find(window => window.area === props.windowId)

	const options = [
		{
			componentId: VisualizationComponent.CPPOPT_PLOT,
			label: "CPPOpt Plot",
		},
		{
			componentId: VisualizationComponent.EEG_MONTAGE,
			label: "EEG Montage",
		},
		{
			componentId: VisualizationComponent.HISTOGRAM,
			label: "Histogram",
		},
		{
			componentId: VisualizationComponent.PERSYST_TRENDS,
			label: "Persyst Trends",
		},
		{
			componentId: VisualizationComponent.PERSYST_EEG,
			label: "Persyst EEG",
		},
		{
			componentId: VisualizationComponent.SD_DETECTION,
			label: "SD Detection",
		},
		{
			componentId: VisualizationComponent.TIME_SERIES_GROUP,
			label: "Time Series Group",
		},
		{
			componentId: VisualizationComponent.SCATTER_PLOT,
			label: "Scatter Plot"
		}
	]

	const selectedWindowType = window?.componentId ?? VisualizationComponent.PLACEHOLDER

	function handleClick(componentId: MobergDropdownValue) {

		if (!selectedLayout) {
			return
		}

		const updatedLayout = {
			...selectedLayout,
			areas: selectedLayout?.areas.map(window => {
				if (window.area === props.windowId) {
					return {
						area: window.area,
						componentId: componentId as string,
						props: {}
					}
				}
				return window
			})
		}

		updateSelectedLayout(updatedLayout)
	}

	return (
		<div
			style={{
				display: "flex",
				border: "1px solid #dedede",
				borderRadius: "10px",
				alignItems: "center",
				justifyContent: "center",
				width: "100%",
				height: "100%",
				gap: "10px",
			}}
		>
			<MobergDropdown 
				selectedValue={selectedWindowType} 
				onChange={handleClick} 
				options={options.map(option => ({ label: option.label, value: option.componentId }))}
			/>

		</div>
	)
}
