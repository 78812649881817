import { ScaleTime } from "d3";
import { Annotation } from "../../../../../../../../Managers/VisualizationManager/Variables/Annotations";
import { GraphConfig } from "../../../../Types/Graph";
import { AnnotationGeometry } from "./AnnotationGeometry";

type AnnotationGeometryCreationOptions = {
    y?: number,
    height?: number,
}


export abstract class GraphSpecificAnnotationGeometryGenerator {
    abstract generate(annotation: Annotation, graph: GraphConfig, xScale: ScaleTime<any, any, any>): null | AnnotationGeometry | (AnnotationGeometry | null)[]

    createGeometry(annotation: Annotation, graph: GraphConfig, xScale: ScaleTime<any, any, any>, options?: AnnotationGeometryCreationOptions): AnnotationGeometry {
        return {
            x: xScale(annotation.start_time),
            y: graph.offset + (options?.y ?? 0),
            width: Math.max(xScale(annotation.end_time) - xScale(annotation.start_time), 3),
            height: options?.height ?? graph.height,
            color: annotation.color,
            opacity: annotation.opacity,
            annotation: annotation,
        } as AnnotationGeometry
    }
}