import { useRef, useState, useEffect } from "react"
import { ModalController } from "../../../../Controllers/ModalController"
import '../../../../index.css'
import styled from 'styled-components'
import "@fontsource/source-sans-pro"
import * as MdIcons from 'react-icons/md'
import ModalFooterButton, { buttonColor } from "../../../../Components/ModalFooterButton/ModalFooterButton"

export default function ComponentLibraryModal ({ openRef, selectedComponent }) {
    const contentRef= useRef()
    const closeRef = useRef()

    const [tableSubheaderValues, setTableSubheaderValues] = useState([]);
    const [tableSubheaderOrder, setTableSubheaderOrder] = useState([]);

	const [reportSubheaderValues, setReportSubheaderValues] = useState([])
	const [reportSubheaderOrder, setReportSubheaderOrder] = useState([])
	
    const [currentSelectedComponent, setCurrentSelectedComponent] = useState()
    const [componentTitle, setComponentTitle] = useState("")
    const [componentDescription, setComponentDescription] = useState("")

    const isFirstRender = useRef(true);
    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return; 
        }
        setCurrentSelectedComponent(selectedComponent)
    },
    [selectedComponent])

	useEffect(() => {
		let categoryHeaders = ["General", "Analytics", "Graphs", "Integrations"]
		setTableSubheaderOrder(categoryHeaders)

		let categoryHeadersValues = Object.assign({},
			...categoryHeaders.map((header) => {
				return { [header]: false}
			}))
		setTableSubheaderValues({...categoryHeadersValues, ["General"]: true})

		let reportHeaders = ["Header 1", "Header 2"]
		setReportSubheaderOrder(reportHeaders)
		let reportHeadersValues = Object.assign({},
			...reportHeaders.map((header) => {
				return { [header]: false}
			}))
		setReportSubheaderValues({reportHeadersValues, ["Header 1"]: true})
	}, [])


    function updateTableSubheaderValues(table, value) {
        if (tableSubheaderValues[table]) {
            return;
        }
        let newTableSubheaderValues = Object.assign(
            {},
            ...Object.keys(tableSubheaderValues).map((table) => {
                return { [table]: false };
            })
        );
        setTableSubheaderValues({ ...newTableSubheaderValues, [table]: value });

        if (!tableSubheaderOrder.includes(table)) {
            setTableSubheaderOrder(
                Array.prototype.concat(tableSubheaderOrder, table)
            );

        }
    }

    useEffect(() => {
        // const el = document.getElementById('modifyComponentName');
        // el.value = currentSelectedComponent?.title;
        // document.getElementById("modifyComponentName").value = currentSelectedComponent?.title;
        setComponentTitle(currentSelectedComponent ? currentSelectedComponent.title : "")
        setComponentDescription(currentSelectedComponent ? currentSelectedComponent.description : "")
    },
    [currentSelectedComponent])

    // function resetModifyComponentName() {
        // const el = document.getElementById('modifyComponentName');
        // el.value = currentSelectedComponent?.title;
        // console.log(document.getElementById('modifyComponentName').value)
    // }

    
    return (<>
        {/* <div style={{display: "none"}} ref={contentRef}> */}
            {/* <ModifyComponentModalContent style={{width: "800px", padding: "15px 30px"}}> */}
            <ModifyComponentModalContent style={{display: "none"}} ref={contentRef}>
                <div style={{background: "#FFFFFF", position: "sticky"}}>
                <h3 style={{fontFamily: 'Montserrat'}}>Component Library</h3>
                <hr style={{border: "1px solid #B3B3B3", width: "800px", marginLeft: "-30px"}}/>
                </div>

                {/* <div style={{display: "inline-flex"}}>
                <h1>Name</h1><h2 style={{color:"red", fontSize: "24px", marginLeft: "4px"}}>*</h2>
                </div> */}
                <div style={{display: 'flex', alignItems: 'center', marginBottom: '0.5em'}}>
                <h1>Name <span style={{color:"red"}}>*</span></h1>
                </div>
                <input id="modifyComponentName" type="text" value={componentTitle} style={{marginBottom: "20px"}}/>

                <h1>Description</h1>
                <input type="text" style={{marginBottom: "30px"}}/>
                <hr style={{border: "1px solid #B3B3B3", width: "800px", marginLeft: "-30px"}}/>

                <div style={{display: "inline-flex"}}>
                <HorizontalScrollBar style={{
                    flex: "0 1 auto",
                    display: "inline-flex",
                    overflowX: "auto",
                    overflowY: "hidden",
                    marginBottom: "-2px",
                    whiteSpace: "nowrap",
                    maxWidth: "620px",
                    width: "620px"}}>
                    {tableSubheaderOrder.map((table, i) => {
                        return (
                            <TableTab style={{background: "none"}}>
                                <TableButton id={`TableButton_${table}`} colorchange={tableSubheaderValues[table]} underlined={tableSubheaderValues[table]} style={{fontSize: "16px", fontStyle: "normal", fontWeight: "600"}}
                                onClick={(e) => {
                                    switch (e.detail) {
                                        case 1:
                                            updateTableSubheaderValues(table, !tableSubheaderValues[table]);
                                            break;
                                        default:
                                            return;
                                    }
                                }}>
                                    <p>{table}</p>
                                    </TableButton>
                                </TableTab>
                            );
                        })}
				</HorizontalScrollBar>
                
                <div style={{right: "0px", display: "inline-flex", marginLeft: "25px"}}>
                <h1 style={{color: "#293241"}}>Preview</h1>
                <MdIcons.MdOutlineToggleOff size={36} color="#B3B3B3" style={{marginTop: "-6px", marginLeft: "6px", cursor: "pointer"}}/>
                </div>
                </div>


                <hr style={{border: "1px solid #B3B3B3", width: "800px", marginLeft: "-30px", marginTop: "1px"}}/>

                <div style={{height: "300px", background: "#EFF1F4", marginTop: "30px", marginBottom: "35px", borderRadius: "6px"}}/>

                <div style={{display: "inline-flex", background: "#FFFFFF", position: "sticky"}}>
                    <ModalFooterButton 
                        buttonText="Save"
                        color={buttonColor.blueFill}
                        buttonStyle={{width: "114px", marginRight: "5px"}}
                    />

                    <ModalFooterButton 
                        ref={closeRef}
                        buttonText="Cancel"
                        color={buttonColor.blueOutline}
                        buttonStyle={{width: "114px", marginRight: "5px"}}
                    />
                    <ModalFooterButton 
                        buttonText="Delete"
                        color={buttonColor.redFill}
                        style={{marginLeft: "390px", marginRIght: "5px", width: "114px"}}
                    />
                </div>
            </ModifyComponentModalContent>
        {/* </div> */}
        
        <ModalController contentRef={contentRef} openRef={openRef} modalStyle={"large"} closeOnEscape={true} closeRef={closeRef}/>
   </>)
}

const ModifyComponentModalContent = styled.div`
    width: 800px;
    padding: 15px 30px;
    h3 {
        color: #293241;
        font-weight: 700;
        font-size: 20px;
        font-style: normal;
        line-height: 150%;
        text-align: center;
    }
    h1 {
        font-family: 'Source Sans Pro';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        color: #293241;
    }
    input {
        border: 1px solid #ABABAB;
        border-radius: 6px;
        color: #000000;
        width: 740px;
        height: 50px;
        padding: 8px 16px;
        ::placeholder {
			top: calc(50% - 19px/2);
			color: #9f9f9f;
			font-size: 16px;
			font-weight: 400;
			font-family: 'Source Sans Pro';
			line-height: 120%;
    }
`;

const HorizontalScrollBar = styled.div`
	::-webkit-scrollbar {
		display: block;
		height: 5px;
		color: #313a4a;
		border-radius: 2px;
	}
	::-webkit-scrollbar-track {
		background: #bec4cf;
		height: 5px;
		border-radius: 2px;
	}
`;

const TableTab = styled.div`
	width: auto;
	height: 34px;
	background: none;
	border: solid 0.5px #ffffff;
	font-size: 16px;
	display: inline-flex;
	margin-right: 35px;
	/* padding-left: -35px; */
	
`;

const TableButton = styled.button`
    z-index: 1000;
	width: auto;
	height: 34px;
    background: none;
	border: solid 0.5px #ffffff;
	font-size: 16px;
	display: inline-flex;
	margin-right: 8px;
	color: ${({colorchange}) => (colorchange ? "#0097ee" : "#6C757C")};
	p {
		padding-bottom: 6px;
		border-bottom: ${({underlined}) => underlined ? "4px solid #0097ee" : "none"};
	}
`;
