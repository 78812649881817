import ReactDOM from "react-dom";
import { createContext, useContext, useState } from "react";
import Cookies from "js-cookie"
import { postWithDependencyInjection } from "./EndpointProvider";
import { UpdateDispatcher } from "./UpdateDispatcher";
import { useBackendLinksProvider } from "./BackendLinksProvider";

const WORKSPACE_NAME = window.env.REACT_APP_WORKSPACE_NAME
const AuthContext = createContext()

export const authUpdates = {
    TOKEN: "token",
    PERMISSIONS: "permissions",
    CURRENT_USER: "currentUser",
    USER_ROLES: "userRoles",
}

export const AuthProvider = ({ children }) => {
    const [token, setTokenState] = useState(Cookies.get("token"))
    const [permissions, setPermissions] = useState()
    const [currentUser, setCurrentUser] = useState()
    const [userRoles, setUserRoles] = useState()
    const { LINKS } = useBackendLinksProvider()
    // the above useStates holds the assigned projects, sites, and roles for the current user in the current Workspace

    const userIsLoggedIn = token !== undefined && token !== ""

    const [enableCheckActivity, setEnableCheckActivity] = useState(true)

    console.log("PROVIDER RENDER: AUTH", {
        token,
        permissions,
        currentUser,
        userIsLoggedIn,
        userRoles,
    })

    const logout = () => {
        clearToken()
        sessionStorage.removeItem('currentUploads')
        if (!userIsLoggedIn) {
            return
        }
        setPermissions()
    }

    async function update(...updates) {
        console.log("UPDATE AUTH PROVIDER", updates)

        const initState = {
            [authUpdates.TOKEN]: token,
            [authUpdates.PERMISSIONS]: permissions,
            [authUpdates.CURRENT_USER]: currentUser,
            [authUpdates.USER_ROLES]: userRoles,
        }

        const dispatcher = new UpdateDispatcher(updates, initState, authUpdates)

        await dispatcher.dispatch(authUpdates.TOKEN,
            () => token ?? Cookies.get("token"),
            token => setToken(token),
            () => logout())

        // This is an example of how to cancel the dispatcher early, if needed.
        if (!dispatcher.state[authUpdates.TOKEN]) {
            logout()
            dispatcher.cancel("token was undefined.")
        }

        dispatcher.dispatch(authUpdates.PERMISSIONS,
            () => postWithDependencyInjection(dispatcher.state[authUpdates.TOKEN], WORKSPACE_NAME, LINKS.ACCOUNT.CHECK_TOKEN,
                { study_ids: [WORKSPACE_NAME] }).then(data => data?.permissions),
            updatedPermissions => setPermissions(updatedPermissions),
            e => {
                logout()
            })

        dispatcher.dispatch(authUpdates.CURRENT_USER,
            () => postWithDependencyInjection(dispatcher.state[authUpdates.TOKEN], WORKSPACE_NAME, LINKS.ACCOUNT.GET_CURRENT_USER),
            updatedCurrentUser => setCurrentUser(updatedCurrentUser))

        dispatcher.dispatch(authUpdates.USER_ROLES,
            () => postWithDependencyInjection(dispatcher.state[authUpdates.TOKEN], WORKSPACE_NAME, LINKS.ADMIN.USER_MANAGEMENT.USERS.GET_USER_ROLES),
            updatedUserRoles => setUserRoles(updatedUserRoles))
    }

    function clearToken() {
        if (Cookies.get("token")) {
            Cookies.remove("token")
        }
        setTokenState(undefined)
    }

    function setToken(_token, options) {
        setTokenState(_token)
        Cookies.set("token", _token, options ? options : {})
    }

    async function login(email, password) {
        const data = await postWithDependencyInjection(undefined, WORKSPACE_NAME, LINKS.LOGIN.GET_TOKEN, { permissions: ['login'], email, password })

        if (Object.values(data.permissions).every(element => element === false)) {
            throw new Error('You do not have permission to access CONNECT.')
        }

        const res = await postWithDependencyInjection(data?.token, WORKSPACE_NAME, LINKS.LOGIN.CHECK_ENV, { permissions: ['login'], email })

        const errors = []
        Object.keys(res).forEach(command => {
            if (res[command] !== 'Valid') {
                errors.push(command)
            }
        })

        if (errors.length > 0) {
            throw new Error(`The following validations failed: ${errors}.`)
        }

        // If we don't batch the updates, it causes extra re-renders.
        // For some reason, React does not do this automatically..
        ReactDOM.unstable_batchedUpdates(async () => {
            setToken(data.token, { expires: -1 })
            setPermissions(data.permissions)
            setEnableCheckActivity(true)
        })
    }

    return (
        <AuthContext.Provider value={{ token, permissions, setPermissions, currentUser, userRoles, userIsLoggedIn, update, logout, login, authUpdates, setToken, enableCheckActivity, setEnableCheckActivity }}>
            {children}
        </AuthContext.Provider>
    )
}

export const useAuthProvider = () => {
    return useContext(AuthContext)
}

