import { Selection, EnterElement, select, ScaleTime, drag, D3DragEvent } from "d3"
import { D3OneToOneRenderable } from "../../../D3/D3OneToOneRenderable"
import { MarginedBoundingBox } from "../../../../Types/MarginedBoundingBox"
import { ReactCallbacks } from "../../../../Types/ReactCallbacks"
import { InProgressAnnotation } from "../../../../Types/Annotations"

export type D3GraphsOverlayConfig = {
	boundingBox: MarginedBoundingBox
	svg: SVGSVGElement | null
	svgOffsetX: number
    viewScale: ScaleTime<any, any, any>
	clipPathId: string
	inProgressAnnotation: InProgressAnnotation
	liveModeEnabled: boolean
    onWheel? (wheelEvent: WheelEvent): void
	onDrag? (dragEvent: D3DragEvent<SVGRectElement, any, any>): void
}

export class D3GraphsOverlay extends D3OneToOneRenderable<SVGGElement, SVGGElement, D3GraphsOverlayConfig, ReactCallbacks<any>> {
	private rectOverlayClassName: string = "d3-rect-overlay"
	private hoverlineClassName: string = "d3-hoverline"
	private inProgressAnnotationClassName: string = "d3-in-progress-annotation"
	private lastHoveredX: number = 0
	private isWindowActive = false

	constructor(root: SVGGElement, config: D3GraphsOverlayConfig, reactCallbacks: ReactCallbacks<any>) {
		super(root, config, "d3-hover-overlay-group", reactCallbacks)
		this.render()
	}

	public getLastHoveredDate = () => {
		return this.config.viewScale.invert(this.lastHoveredX)
	}

	enter = (newOverlays: Selection<EnterElement, any, any, any>) => {
		const overlay = newOverlays
			.append("g")
			.attr("class", this.className)
			.attr("transform", `translate(${this.config.boundingBox.x}, ${this.config.boundingBox.y})`)

		overlay
			.append("rect")
			.attr("class", this.rectOverlayClassName)
			.attr("width", this.config.boundingBox.width)
			.attr("height", this.config.boundingBox.height)
			.attr("fill", "transparent")
			.style("cursor", this.config.liveModeEnabled ? "default" : "hand")
			.on("mouseenter", this.onHover)
			.on("mousemove", this.onHover)
			.on("mouseleave", () => this.onMouseLeave())
            .on("wheel", (event) => {
				event.preventDefault()
				
                if (this.config.onWheel && !this.config.liveModeEnabled) {
                    this.config.onWheel(event)
                }
            })
			.call(drag<SVGRectElement, any, any>().on("drag", this.onDrag))

		overlay
			.append("line")
			.attr("class", this.hoverlineClassName)
			.attr("opacity", 0)
			.attr("y2", this.config.boundingBox.height)
			.attr("stroke", this.config.inProgressAnnotation.color)
			.attr("pointer-events", "none")

		overlay
			.append("rect")
			.attr("clip-path", `url(#${this.config.clipPathId})`)
			.attr("class", this.inProgressAnnotationClassName)
			.attr("height", this.config.boundingBox.height)
			.attr("fill", this.config.inProgressAnnotation.color)
			.attr("pointer-events", "none")

		return overlay
	}

	update = (updatedOverlays: Selection<any, any, any, any>) => {
		updatedOverlays
			.select("." + this.rectOverlayClassName)
			.attr("width", this.config.boundingBox.width)
			.attr("height", this.config.boundingBox.height)
			.style("cursor", this.config.liveModeEnabled ? "default" : "hand")

		updatedOverlays
			.select("." + this.hoverlineClassName)
			.attr("stroke", this.config.inProgressAnnotation.color)

		this.updateInProgressAnnotation(this.lastHoveredX)
		this.hoverlineMoved(this.lastHoveredX)

		return updatedOverlays
	}

	private hoverlineMoved = (x: number): void => {		
		const hoverline = select(this.root)
			.select("." + this.hoverlineClassName)

		hoverline
			.attr("x1", x)
			.attr("x2", x)
            .attr("y2", this.config.boundingBox.height)
	}

	private getMouseXFromMouseEvent(event: MouseEvent) {
		const point = this.transformSVGCoordinateToScreenCoordinate(event)
		return point.x - this.config.svgOffsetX
	}

	onHover = (event: MouseEvent) => {
		if (event.buttons > 0) {
			// The user is clicking or dragging, not really hovering.
			return
		}

		this.isWindowActive = true

		const mouseX = this.getMouseXFromMouseEvent(event)
		this.showHoverline()
		this.hoverlineMoved(mouseX)
		this.updateInProgressAnnotation(mouseX)
		this.lastHoveredX = mouseX
	}

	onDrag = (event: D3DragEvent<SVGRectElement, any, any>) => {
		const mouseX = this.getMouseXFromMouseEvent(event.sourceEvent)

		// We want to disable panning during live mode because the view should be locked to the end of the display.
		if (this.config.liveModeEnabled) {
			this.hoverlineMoved(mouseX)
			this.lastHoveredX = mouseX
			return
		}

		this.hideHoverline()
		this.hoverlineMoved(mouseX)

		if (this.config.onDrag) {
			this.config.onDrag(event)
		}
	}

	onMouseLeave = () => {
		this.hideHoverline()
		this.hideInProgressAnnotation()
		this.isWindowActive = false
	} 

	private showHoverline = () => {
		select(this.root)
			.select("." + this.hoverlineClassName)
			.attr("opacity", 1)
	}

	private hideHoverline = () => {
		select(this.root)
			.select("." + this.hoverlineClassName)
			.attr("opacity", 0)
	}

	private hideInProgressAnnotation = () => {
		select(this.root)
			.select("." + this.inProgressAnnotationClassName)
			.attr("fill", "transparent")
	}

	private updateInProgressAnnotation(mouseX: number) {
		const inProgressAnnotation = select(this.root)
			.select("." + this.inProgressAnnotationClassName)

		if (!this.config.inProgressAnnotation.startDate) {
			this.hideInProgressAnnotation()
			return
		}

		const startDateX = this.config.viewScale(this.config.inProgressAnnotation.startDate)
		const startX = Math.min(startDateX, mouseX)
		const endX = Math.max(startDateX, mouseX)
		const width = endX - startX

		inProgressAnnotation
			.attr("x", startX)
			.attr("width", width)
			.attr("fill", this.config.inProgressAnnotation.color)
			.attr("opacity", this.isWindowActive ? this.config.inProgressAnnotation.opacity : 0)
	}

	private transformSVGCoordinateToScreenCoordinate = (event: MouseEvent) => {
		// https://dev.to/netsi1964/screen-coordinates-to-svg-coordinates-3k0l
		if (this.config.svg === null) {
			return { x: 0, y: 0 }
		}

		const { clientX, clientY } = event
		let point = this.config.svg.createSVGPoint()
		point.x = clientX
		point.y = clientY
		return point.matrixTransform(this.config.svg.getScreenCTM()?.inverse())
	}
}
