import { createContext, useContext, useState, useEffect } from "react";
import { useEndpointProvider } from "./EndpointProvider";
import { LINKS } from '../Constants/BackendLinks'
import { useStudiesProvider } from "./StudiesProvider";
import { useAuthProvider } from "./AuthProvider";
import { UpdateDispatcher } from "./UpdateDispatcher";
import VisualizationManager from "../Managers/VisualizationManager/VisualizationManager";


const AnalysesContext = createContext()

export const analysesUpdates = {
    ANCILLARY_FILTER: "ancillary_filter"
}

export const AnalysesProvider = ({ children }) => {  
    const [analyses, setAnalyses] = useState([])
    const [filters, setFilters] = useState({})

    console.log("PROVIDER RENDER: ANALYSES",
    {analyses, filters})

    const endpointProvider = useEndpointProvider()
    const studiesProvider = useStudiesProvider()
    const authProvider = useAuthProvider()

    async function update(...updates) {
        const initState = {
            [analysesUpdates.ANCILLARY_FILTER]: { data: analyses, filters },
        }

        const dispatcher = new UpdateDispatcher(updates, initState, analysesUpdates)

        dispatcher.dispatch(analysesUpdates.ANCILLARY_FILTER,
            () => new Promise((resolve, reject) => resolve({ data: customAnalyses, filters: {} })),
            // () => endpointProvider.post(LINKS.PIPELINES.CREATE.GET_ANALYSES, { study_id: studiesProvider.selectedStudyId }),
            data => {
                setAnalyses(data?.data)
                setFilters(data?.filters)
            })
    }

    function getAnalysisJSON(id) {
        const analysis = analyses.find(a => a.id === id)

        if (!analysis) {
            throw new Error(`Analysis not found! ID: ${id}`)
        }

        return analysis
    }

    function getAnalysisName(id) {
        return getAnalysisJSON(id)["pipeline_json"]["name"]
    }

    function getAvailableTraces(id) {
        return getAnalysisJSON(id)["exports"]
    }

	function getDisplayJSON(id) {
		return getAnalysisJSON(id)["display_json"]
	}

	async function runAnalysisForDisplay(analysisId, display, 
		{
			patientFileName=VisualizationManager.file_name,
			startTime=VisualizationManager.file_start,
			endTime=VisualizationManager.file_end,
		}) {

		display.setLoading(true)

		return run({ analysisId, patientFileName, startTime, endTime })
			.then(() => {
				display.setLoading(false)
				display.fullConfigLoad()
				display.renderDenseTable()
			})
			.catch(error => {
				display.setLoading(false)
				display.setErroring(true, error)
			})
	}

    // Runs an analysis. Assumes one source node (so this might be an issue if we're trying to use multiple source nodes...).
    async function run({ analysisId, patientFileName, startTime, endTime, modalities, clearCache=false, writeH5=true }) {
		// This should be substituted for ISO format because it's getting very annoying to keep track of this.
		startTime *= 1e3
		endTime *= 1e3

        const analysisJSON = getAnalysisJSON(analysisId)

        analysisJSON["patient_file_uid"] = patientFileName // This doesn't have to be here.
        analysisJSON["clear_cache"] = clearCache

        if (analysisJSON["multiprocess_epoching_args"]) {
            analysisJSON["multiprocess_epoching_args"]["start_time_micros"] = startTime
            analysisJSON["multiprocess_epoching_args"]["end_time_micros"] = endTime
        }

        const nodes = analysisJSON["pipeline_json"]["nodes"]

        Object.values(nodes).forEach(node => {
            if (node["type"].toLowerCase() === "source" && node["subtype"].toLowerCase() === "hdf5_local") {
                if (!node["args"]) {
                    node["args"] = {}
                }

                if (modalities) {
                    node["args"]["modalities"] = modalities
                }

                node["args"] = {
                    ...node["args"],
                    patient_file_uids: [patientFileName],
                    start_time: startTime,
                    end_time: endTime
                }
            }
        })

		let endpoint;

		if (writeH5) {
			endpoint = LINKS.PIPELINES.EXECUTE_AND_WRITE_H5
		} else {
			endpoint = LINKS.PIPELINES.EXECUTE
		}

		return await endpointProvider.post(endpoint, analysisJSON)
        
    }

    // Make sure we don't persist any data between logins.
    useEffect(() => {
        if (!authProvider.userIsLoggedIn && analyses.length > 0) {
            setAnalyses([])
        }
    }, [authProvider, analyses.length])

    return (
        <AnalysesContext.Provider value={{ analyses, filters, run, runAnalysisForDisplay, getAnalysisName, getAvailableTraces, getDisplayJSON, update }}>
            {children}
        </AnalysesContext.Provider>
    )
}

export const useAnalysesProvider = () => useContext(AnalysesContext)

const customAnalyses = [
	{
		id: "0",
		exports: ["CPP_na"],
		display_json: {
			name: "Window 1",
			graphs: [
				{
					name: "Graph 1",
					traces: {
						CPP_na: {
							name: "CPP",
							color: "black",
							hdf5_path: "waves/CPP_na",
						},
					},
					ymin: 0,
					ymax: 140,
					height: 200,
					type: "Line"
				},
			],
			window_size: "1 hr",
		},
		pipeline_json: {
			name: "CPP Analysis",
			description: "Easy CPP Pipeline",
			version: "1.0.0",
			nodes: {
				node1: {
					type: "SOURCE",
					subtype: "HDF5_LOCAL",
					name: "Source Data",
					args: {
						modalities: ["ABP_na", "ICP_na"],
					},
				},
				node3: {
					type: "DATA_ORGANIZER",
					subtype: "MODALITY_FILTER",
					name: "ABP",
					args: {
						modality: "ABP_na",
					},
				},
				node4: {
					type: "DATA_ORGANIZER",
					subtype: "MODALITY_FILTER",
					name: "ICP",
					args: {
						modality: "ICP_na",
					},
				},
				node5: {
					type: "ANALYTIC",
					subtype: "SUBTRACT_TIME_SERIES",
					name: "CPP_na",
					args: {
						// "export": "CPP_na",
						taxonomic_method: "replace",
					},
				},
			},
			architecture: {
				node3: {
					inputs: [[1]],
				},
				node4: {
					inputs: [[1]],
				},
				node5: {
					inputs: [[3, 4]],
				},
			},
		},
	},
	{
		id: "1",
		exports: ["ABP_na", "ICP_na", "PRx_na"],
		display_json: {
			name: "Window 1",
			graphs: [
				{
					name: "Graph 1",
					traces: {
						PRx_na: {
							name: "PRx",
							hdf5_path: "waves/PRx_na",
						},
					},
					ymin: -1,
					ymax: 1,
					height: 50,
					type: "Heatmap",
				},
				{
					name: "Graph 3",
					traces: {
						PRx_na: {
							name: "PRx",
							color: "black",
							hdf5_path: "waves/PRx_na",
						},
					},
					ymin: -1,
					ymax: 1,
					height: 200,
					type: "Area",
				},
				{
					name: "Graph 2",
					traces: {
						ABP_na: {
							name: "ABP",
							color: "black",
							hdf5_path: "waves/ABP_na",
						},
					},
					ymin: 40,
					ymax: 300,
					height: 60,
					type: "Line"
				},
				{
					name: "Graph 3",
					traces: {
						ICP_na: {
							name: "ICP",
							color: "black",
							hdf5_path: "waves/ICP_na",
						},
					},
					ymin: 10,
					ymax: 112,
					height: 60,
					type: "Line"
				},
			],
			window_size: "1 hr",
		},
		pipeline_json: {
			name: "PRx Analysis",
			description: "Easy PRx Pipeline",
			version: "1.0.0",
			nodes: {
				node1: {
					type: "SOURCE",
					subtype: "HDF5_LOCAL",
					name: "Source Data",
					args: {
						modalities: ["ABP_na", "ICP_na"],
					},
				},
				node2: {
					type: "DATA_ORGANIZER",
					subtype: "RESAMPLE",
					name: "resample",
					args: {
						duration: { seconds: 1 },
					},
				},
				node3: {
					type: "ANALYTIC",
					subtype: "ROLLING_ANALYTIC",
					name: "PRx_na",
					args: {
						analytic: "pearson",
						window_size: { minutes: 5 },
						delta: { seconds: 10 },
						// "export": "PRx_na",
						taxonomic_method: "replace",
					},
				},
				node4: {
					type: "DATA_ORGANIZER",
					subtype: "MODALITY_FILTER",
					args: {
						modality: "ABP_na",
					},
				},
				node5: {
					type: "DATA_ORGANIZER",
					subtype: "MODALITY_FILTER",
					args: {
						modality: "ICP_na",
					},
				},
			},
			architecture: {
				node2: {
					inputs: [[1]],
				},
				node3: {
					inputs: [[2]],
				},
				node4: {
					inputs: [[1]],
				},
				node5: {
					inputs: [[1]],
				},
			},
		},
	},
	{
		id: "2",
		exports: ["CPPOpt"],
		multiprocess_epoching_args: {
			epoching_type: "sliding",
			window_size: { hours: 8 },
            offset: { hours: -6 },
			delta: { minutes: 10 },
		},
		display_json: {
			name: "Window 1",
			graphs: [
				{
					name: "Graph 1",
					traces: {
						CPPOpt: {
							name: "CPPOpt",
							color: "black",
							hdf5_path: "waves/CPPOpt",
						},
					},
					ymin: 40,
					ymax: 120,
					height: 160,
					type: "Line"
				},
			],
			window_size: "8 hr",
		},
		pipeline_json: {
			name: "CPPOPT Analysis",
			description: "This is a test pipeline.",
			version: "30.0.0",
			nodes: {
				node1: {
					type: "SOURCE",
					subtype: "HDF5_LOCAL",
					coords: [0, 0],
					name: "SOURCE DATA",
					args: {
						modalities: ["ABP_na", "ICP_na"],
					},
				},
				node2: {
					type: "DATA_ORGANIZER",
					subtype: "RESAMPLE",
					name: "ABP Resampled",
					args: {
						duration: { seconds: 10 },
						// "show_plot": true
					},
				},
				node3: {
					type: "DATA_ORGANIZER",
					subtype: "MODALITY_FILTER",
					name: "ABP",
					args: {
						modality: "ABP",
					},
				},
				node4: {
					type: "DATA_ORGANIZER",
					subtype: "MODALITY_FILTER",
					name: "ICP",
					args: {
						modality: "ICP",
					},
				},
				node5: {
					type: "ANALYTIC",
					subtype: "SUBTRACT_TIME_SERIES",
					name: "CPP",
				},
				node6: {
					type: "ANALYTIC",
					subtype: "OUTLIER_CLAMP",
					name: "remove extreme outliers",
					args: {
						reject_threshold: 3.0,
						min: 0,
						// "show_plot": true
					},
				},
				node7: {
					type: "ANALYTIC",
					subtype: "DESPIKE",
					name: "remove local spikes for CPP",
					args: {
						cleanup_duration: { minutes: 4 },
						reject_threshold: 3.0,
						// "show_plot": true,
						// "show_cleaning_process": true
					},
				},
				node99: {
					type: "ANALYTIC",
					subtype: "DESPIKE",
					name: "remove local spikes for ABP, ICP",
					args: {
						cleanup_duration: { minutes: 4 },
						reject_threshold: 3.0,
						// "show_plot": rue
						// "show_cleaning_process": rue
					},
				},
				node8: {
					type: "ANALYTIC",
					subtype: "ROLLING_ANALYTIC",
					name: "PRx",
					args: {
						analytic: "pearson",
						window_size: { minutes: 5 },
						delta: { seconds: 10 },
						// "compensate_delay": true,
						// "show_plot": true
					},
				},
				node9: {
					type: "DATA_ORGANIZER",
					subtype: "RESAMPLE",
					name: "Resample",
					args: {
						duration: { minutes: 1 },
						// "show_plot": true
					},
				},
				node10: {
					type: "DATA_ORGANIZER",
					subtype: "GROWING_WINDOW_DATA_SPLITTER",
					name: "grow",
					args: {
						grow_duration: { minutes: 1 },
						min_size: { hours: 2 },
						reverse_direction: true,
					},
				},
				node11: {
					type: "DATA_ORGANIZER",
					subtype: "GROWING_WINDOW_DATA_SPLITTER",
					name: "grow",
					args: {
						grow_duration: { minutes: 1 },
						min_size: { hours: 2 },
						reverse_direction: true,
					},
				},
				node13: {
					type: "DATA_ORGANIZER",
					subtype: "BIN_COORDINATES",
					name: "Binned CPP",
					args: {
						x_config: {
							min: 40,
							max: 120,
							step: 5,
							method: "middle",
							minimum_bin_data_pct: 0.02,
							minimum_cross_axis_span: 0.2,
							maximum_cross_axis_error: 0.5,
							cross_axis_collapse_method: "mean",
						},
						minimum_included_data_pct: 0.5,
					},
				},
				node14: {
					type: "ANALYTIC",
					subtype: "POLYNOMIAL_FIT",
					name: "poly fit",
					args: {
						degree: 2,
						// "show_plot": true
					},
				},
				node15: {
					type: "ANALYTIC",
					subtype: "POLYNOMIAL_MINIMIZE_DYNAMIC_BOUNDS",
					name: "Chunk CPP OPT",
					args: {
						window_min: 40,
						window_max: 120,
						// "export": "CPPOPTs",
						// "show_plot": true
					},
				},
				node16: {
					type: "ANALYTIC",
					subtype: "MAX",
					name: "max Binned CPP",
				},
				node17: {
					type: "ANALYTIC",
					subtype: "MIN",
					name: "min Binned CPP",
				},
				node18: {
					type: "LOGIC",
					subtype: "VALUE_COMPARISON",
					name: "check CPP Opt lower bound",
					args: {
						comparator: "<",
					},
				},
				node19: {
					type: "LOGIC",
					subtype: "VALUE_COMPARISON",
					name: "check CPP Opt upper bound",
					args: {
						comparator: "<",
					},
				},
				node22: {
					type: "LOGIC",
					subtype: "LOGICAL_OPERATION",
					name: "check CPP Opt in bounds",
					args: {
						operation: "and",
					},
				},
				node24: {
					type: "DATA_ORGANIZER",
					subtype: "SWITCH",
					name: "Parabola goodness weight",
					args: {
						replace_values: [0.1, 0.9],
						default: 0.3,
					},
				},
				node25: {
					type: "ANALYTIC",
					subtype: "MAX",
					name: "max PRx",
				},
				node26: {
					type: "ANALYTIC",
					subtype: "MIN",
					name: "min PRx",
				},
				node27: {
					type: "LOGIC",
					subtype: "IN_REGION",
					name: "PRx too low",
					args: {
						threshold: -0.3,
						direction: "<",
					},
				},
				node28: {
					type: "LOGIC",
					subtype: "IN_REGION",
					name: "PRx too high",
					args: {
						threshold: 0.6,
						direction: ">",
					},
				},
				node29: {
					type: "LOGIC",
					subtype: "LOGICAL_OPERATION",
					name: "Check PRx bounds",
					args: {
						operation: "or",
					},
				},
				node30: {
					type: "DATA_ORGANIZER",
					subtype: "SWITCH",
					name: "Filter out bad PRx",
					args: {
						replace_values: [0],
						default: 1,
					},
				},
				node31: {
					type: "ANALYTIC",
					subtype: "MULTIPLY_VALUES",
					name: "CPP OPT Weight",
					args: {
						baseline: 6.65, // 0.9 * e^2
						// "export": "CPPOPT_Weights"
					},
				},
				node32: {
					type: "ANALYTIC",
					subtype: "WEIGHTED_MEAN",
					name: "CPPOpt",
					args: {
						taxonomic_method: "replace",
					},
				},
				node33: {
					type: "ANALYTIC",
					subtype: "POLYNOMIAL_CONCAVITY",
					name: "Check bad fit shape",
					args: {
						desired_concavity: "down",
					},
				},
				// "node34": {
				// 	"type": "ANALYTIC",
				// 	"subtype": "DURATION",
				// 	"name": "chunk duration",
				// 	"args": {
				// 		"unit": "hours"
				// 	}
				// },
				// "node35": {
				// 	"type": "ANALYTIC",
				// 	"subtype": "CLAMPED_VALUE",
				// 	"name": "normalized duration",
				// 	"args": {
				// 		"method": "negative_exponential",
				// 		"max": 0.9
				// 	}
				// },
				node36: {
					type: "ANALYTIC",
					subtype: "CLAMPED_VALUE",
					name: "normalized duration",
					args: {
						method: "negative_exponential",
					},
				},
			},
			architecture: {
				node2: {
					inputs: [[6]],
				},
				node99: {
					inputs: [[2]],
				},
				node3: {
					inputs: [[99]],
				},
				node4: {
					inputs: [[99]],
				},
				node5: {
					inputs: [[3, 4]],
				},
				node6: {
					inputs: [[1]],
				},
				node7: {
					inputs: [[5]],
				},
				node8: {
					inputs: [[3, 4]],
				},
				node9: {
					inputs: [[7]],
				},
				node10: {
					inputs: [[9]],
				},
				node11: {
					inputs: [[8]],
				},
				node13: {
					inputs: [[10, 11]],
				},
				node14: {
					inputs: [[13, 13]],
					output_indexes: [[0, 1]],
				},
				node15: {
					inputs: [[14, 17, 16]],
					output_indexes: [[0, 0, 0]],
				},
				node16: {
					inputs: [[13]],
					output_indexes: [[0]],
				},
				node17: {
					inputs: [[13]],
					output_indexes: [[0]],
				},
				node18: {
					inputs: [[17, 15]],
				},
				node19: {
					inputs: [[15, 16]],
				},
				node22: {
					inputs: [[18, 19]],
				},
				node24: {
					inputs: [[33, 22]],
				},
				node25: {
					inputs: [[13]],
					output_indexes: [[1]],
				},
				node26: {
					inputs: [[13]],
					output_indexes: [[1]],
				},
				node27: {
					inputs: [[25]],
				},
				node28: {
					inputs: [[26]],
				},
				node29: {
					inputs: [[27, 28]],
				},
				node30: {
					inputs: [[29]],
				},
				node31: {
					inputs: [[24, 36, 30]], // [[24, 36, 30, 35]]
				},
				node32: {
					inputs: [[15, 31]],
				},
				node33: {
					inputs: [[14, 15]],
					output_indexes: [[0, 0]],
				},
				// "node34": {
				// 	"inputs": [[11]]
				// },
				// "node35": {
				// 	"inputs": [[34]],
				// },
				node36: {
					inputs: [[14]],
					output_indexes: [[1]],
				},
			},
		},
	},
	{
		id: "3",
		pipeline_json: {
			name: "CPPOPT Chart Stats",
			description: "Pipeline for getting the chart data for CPPOpt",
			version: "30.0.0",
			nodes: {
				node1: {
					type: "SOURCE",
					subtype: "HDF5_LOCAL",
					coords: [0, 0],
					name: "SOURCE DATA",
					args: {
						modalities: ["ABP_na", "ICP_na"],
						patient_file_uids: ["0000/8011/0000_8011_2017-11-28_19_45.h5"],
					},
				},
				node2: {
					type: "DATA_ORGANIZER",
					subtype: "RESAMPLE",
					name: "ABP Resampled",
					args: {
						duration: { seconds: 10 },
						// "show_plot": true
					},
				},
				node3: {
					type: "DATA_ORGANIZER",
					subtype: "MODALITY_FILTER",
					name: "ABP",
					args: {
						modality: "ABP",
					},
				},
				node4: {
					type: "DATA_ORGANIZER",
					subtype: "MODALITY_FILTER",
					name: "ICP",
					args: {
						modality: "ICP",
					},
				},
				node5: {
					type: "ANALYTIC",
					subtype: "SUBTRACT_TIME_SERIES",
					name: "CPP",
				},
				node6: {
					type: "ANALYTIC",
					subtype: "OUTLIER_CLAMP",
					name: "remove extreme outliers",
					args: {
						reject_threshold: 4.0,
						min: 0,
						// "show_plot": true
					},
				},
				node7: {
					type: "ANALYTIC",
					subtype: "DESPIKE",
					name: "remove local spikes for CPP",
					args: {
						cleanup_duration: { minutes: 4 },
						reject_threshold: 3.0,
						// "show_plot": true,
						// "show_cleaning_process": true
					},
				},
				node99: {
					type: "ANALYTIC",
					subtype: "DESPIKE",
					name: "remove local spikes for ABP, ICP",
					args: {
						cleanup_duration: { minutes: 4 },
						reject_threshold: 3.0,
						// "show_plot": rue
						// "show_cleaning_process": rue
					},
				},
				node8: {
					type: "ANALYTIC",
					subtype: "ROLLING_ANALYTIC",
					name: "PRx",
					args: {
						analytic: "pearson",
						window_size: { minutes: 5 },
						delta: { minutes: 1 },
						// "compensate_delay": true,
						// "show_plot": true
					},
				},
				node9: {
					type: "DATA_ORGANIZER",
					subtype: "RESAMPLE",
					name: "Resample",
					args: {
						duration: { minutes: 1 },
						// "show_plot": true
					},
				},
				node10: {
					type: "DATA_ORGANIZER",
					subtype: "GROWING_WINDOW_DATA_SPLITTER",
					name: "grow",
					args: {
						grow_duration: { minutes: 1 },
						min_size: { hours: 2 },
						reverse_direction: true,
					},
				},
				node11: {
					type: "DATA_ORGANIZER",
					subtype: "GROWING_WINDOW_DATA_SPLITTER",
					name: "grow",
					args: {
						grow_duration: { minutes: 1 },
						min_size: { hours: 2 },
						reverse_direction: true,
					},
				},
				node13: {
					type: "DATA_ORGANIZER",
					subtype: "BIN_COORDINATES",
					name: "Binned CPP",
					args: {
						x_config: {
							min: 0,
							max: 120,
							step: 5,
							method: "middle",
							minimum_bin_data_pct: 0.02,
							minimum_cross_axis_span: 0.2,
							maximum_cross_axis_error: 0.5,
							cross_axis_collapse_method: "mean",
						},
						minimum_included_data_pct: 0.5,
					},
				},
				node14: {
					type: "ANALYTIC",
					subtype: "POLYNOMIAL_FIT",
					name: "PolyFit",
					args: {
						degree: 2,
						// "show_plot": true
					},
				},
				node15: {
					type: "ANALYTIC",
					subtype: "POLYNOMIAL_MINIMIZE_DYNAMIC_BOUNDS",
					name: "Chunk CPP OPT",
					args: {
						window_min: 40,
						window_max: 120,
						// "show_plot": true
					},
				},
				node16: {
					type: "ANALYTIC",
					subtype: "MAX",
					name: "max Binned CPP",
				},
				node17: {
					type: "ANALYTIC",
					subtype: "MIN",
					name: "min Binned CPP",
				},
				node18: {
					type: "LOGIC",
					subtype: "VALUE_COMPARISON",
					name: "check CPP Opt lower bound",
					args: {
						comparator: "<",
					},
				},
				node19: {
					type: "LOGIC",
					subtype: "VALUE_COMPARISON",
					name: "check CPP Opt upper bound",
					args: {
						comparator: "<",
					},
				},
				node22: {
					type: "LOGIC",
					subtype: "LOGICAL_OPERATION",
					name: "check CPP Opt in bounds",
					args: {
						operation: "and",
					},
				},
				node24: {
					type: "DATA_ORGANIZER",
					subtype: "SWITCH",
					name: "Parabola goodness weight",
					args: {
						replace_values: [0.1, 0.9],
						default: 0.3,
					},
				},
				node25: {
					type: "ANALYTIC",
					subtype: "MAX",
					name: "max PRx",
				},
				node26: {
					type: "ANALYTIC",
					subtype: "MIN",
					name: "min PRx",
				},
				node27: {
					type: "LOGIC",
					subtype: "IN_REGION",
					name: "PRx too low",
					args: {
						threshold: -0.3,
						direction: "<",
					},
				},
				node28: {
					type: "LOGIC",
					subtype: "IN_REGION",
					name: "PRx too high",
					args: {
						threshold: 0.6,
						direction: ">",
					},
				},
				node29: {
					type: "LOGIC",
					subtype: "LOGICAL_OPERATION",
					name: "Check PRx bounds",
					args: {
						operation: "or",
					},
				},
				node30: {
					type: "DATA_ORGANIZER",
					subtype: "SWITCH",
					name: "Filter out bad PRx",
					args: {
						replace_values: [0],
						default: 1,
					},
				},
				node31: {
					type: "ANALYTIC",
					subtype: "MULTIPLY_VALUES",
					name: "CPP OPT Weight",
					args: {
						baseline: 6.65, // 0.9 * e^2
					},
				},
				node32: {
					type: "ANALYTIC",
					subtype: "WEIGHTED_MEAN",
					name: "FINAL_CPPOPT",
					args: {
						taxonomic_method: "replace",
					},
				},
				node33: {
					type: "ANALYTIC",
					subtype: "POLYNOMIAL_CONCAVITY",
					name: "Check bad fit shape",
					args: {
						desired_concavity: "down",
					},
				},
				node36: {
					type: "ANALYTIC",
					subtype: "CLAMPED_VALUE",
					name: "normalized duration",
					args: {
						method: "negative_exponential",
					},
				},
				node37: {
					type: "ANALYTIC",
					subtype: "ARGMAX",
					name: "max weight index",
				},
				node38: {
					type: "DATA_ORGANIZER",
					subtype: "MODALITY_FILTER_BY_INDEX",
					name: "Max Weight",
					args: {
						export: "Max Weight",
						taxonomic_method: "replace", // Replace allowed bc we can guarantee there is only one modality.
					},
				},
				node39: {
					type: "DATA_ORGANIZER",
					subtype: "MODALITY_FILTER_BY_INDEX",
					name: "Parabola Coefficients",
					args: {
						export: "Parabola Coefficients",
						taxonomic_method: "replace", // Replace allowed bc we can guarantee there is only one modality.
					},
				},
				node40: {
					type: "DATA_ORGANIZER",
					subtype: "MODALITY_FILTER_BY_INDEX",
					name: "Binned CPP X",
					args: {
						export: "Binned CPP X",
						taxonomic_method: "replace", // Replace allowed bc we can guarantee there is only one modality.
					},
				},
				node41: {
					type: "DATA_ORGANIZER",
					subtype: "MODALITY_FILTER_BY_INDEX",
					name: "Binned CPP Y",
					args: {
						export: "Binned CPP Y",
						taxonomic_method: "replace", // Replace allowed bc we can guarantee there is only one modality.
					},
				},
				node42: {
					type: "DATA_ORGANIZER",
					subtype: "MODALITY_FILTER_BY_INDEX",
					name: "PRx Error Bars",
					args: {
						export: "PRx Error Bars",
						taxonomic_method: "replace", // Replace allowed bc we can guarantee there is only one modality.
					},
				},
			},
			architecture: {
				node2: {
					inputs: [[6]],
				},
				node99: {
					inputs: [[2]],
				},
				node3: {
					inputs: [[99]],
				},
				node4: {
					inputs: [[99]],
				},
				node5: {
					inputs: [[3, 4]],
				},
				node6: {
					inputs: [[1]],
				},
				node7: {
					inputs: [[5]],
				},
				node8: {
					inputs: [[3, 4]],
				},
				node9: {
					inputs: [[7]],
				},
				node10: {
					inputs: [[9]],
				},
				node11: {
					inputs: [[8]],
				},
				node13: {
					inputs: [[10, 11]],
				},
				node14: {
					inputs: [[13, 13]],
					output_indexes: [[0, 1]],
				},
				node15: {
					inputs: [[14, 17, 16]],
					output_indexes: [[0, 0, 0]],
				},
				node16: {
					inputs: [[13]],
					output_indexes: [[0]],
				},
				node17: {
					inputs: [[13]],
					output_indexes: [[0]],
				},
				node18: {
					inputs: [[17, 15]],
				},
				node19: {
					inputs: [[15, 16]],
				},
				node22: {
					inputs: [[18, 19]],
				},
				node24: {
					inputs: [[33, 22]],
				},
				node25: {
					inputs: [[13]],
					output_indexes: [[1]],
				},
				node26: {
					inputs: [[13]],
					output_indexes: [[1]],
				},
				node27: {
					inputs: [[25]],
				},
				node28: {
					inputs: [[26]],
				},
				node29: {
					inputs: [[27, 28]],
				},
				node30: {
					inputs: [[29]],
				},
				node31: {
					inputs: [[24, 36, 30]],
				},
				node32: {
					inputs: [[15, 31]],
				},
				node33: {
					inputs: [[14, 15]],
					output_indexes: [[0, 0]],
				},
				node36: {
					inputs: [[14]],
					output_indexes: [[1]],
				},
				node37: {
					inputs: [[31]],
				},
				node38: {
					inputs: [[31, 37]],
				},
				node39: {
					inputs: [[14, 37]],
					output_indexes: [[0, 0]],
				},
				node40: {
					inputs: [[13, 37]],
					output_indexes: [[0, 0]],
				},
				node41: {
					inputs: [[13, 37]],
					output_indexes: [[1, 0]],
				},
				node42: {
					inputs: [[13, 37]],
					output_indexes: [[2, 0]],
				},
			},
		},
	},
]