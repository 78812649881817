import * as MdIcons from "react-icons/md";
import { useState } from "react"
import styled from "styled-components";

// see the below example of accordionData for expected inputs for this component.
// the "description" field is OPTIONAL
// USAGE NOTES:
// const accordionData = {
// 		'mobergData' : {
// 			'label' : "Moberg Data",
// 			'innerContent': <UploadSummaryData label={"Moberg Data"}/>,
//           'description' : "1 Directory, 15 GB"
// 		},
// 		'natusEEG' : {
// 			'label' : "Natus",
// 			'innerContent' : <UploadSummaryData label={"Natus"}/>
// 		},
// 		'medications' : {
// 			'label' : 'Medications',
// 			'innerContent' : <UploadSummaryData label={"Medications"}/>
// 		}
// 	}

const Accordion = ({data, itemError}) => {
	const [expanded, setExpanded] = useState([]);
  
	const handleDropdownClick = (modality) => {
		setExpanded((prevExpandedList) => {
			return {...prevExpandedList, 
				[modality]: !prevExpandedList[modality]
			}
		});
	};

	function checkIfRequired(data) {
		const questions = data?.innerContent?.props?.questions
	
		if (questions) {
			for (const question of questions) {
				if (Array.isArray(question)) {
					for (const q of question) {
						if (q.required) {
							return true // Return as soon as a required question is found
						}
					}
				} else {
					if (question.required) {
						return true // Return as soon as a required question is found
					}
				}
			}
		}
	
		return false // Return false if no required question is found
	}		

	const headerKeys = Object.keys(data)
	return (
		<DropdownWrapper id='DropdownWrapper'>
			{Object.keys(data).map((headerName, index) => {
				const { label, innerContent } = data[headerName];
				const isHeaderExpanded = expanded[label];
				const isNotLast = index !== headerKeys.length - 1;
				const description = data[headerName].description;
                const isErrorHeader = itemError ? itemError.includes(label) : false;
				return (
					<div key={label}>
					<Header
						selected={isHeaderExpanded}
						onClick={() => handleDropdownClick(label)}
						isNotLast={isNotLast}
                        isErrorHeader={isErrorHeader}
                    >
						{isHeaderExpanded ? (
							<MdIcons.MdPlayArrow size={18} style={{marginRight: "12px", marginLeft: "12px", marginTop: "3px", transform: "rotate(90deg)", color: "#FFFFFF"}}/>
						) : (
							<MdIcons.MdPlayArrow size={18} style={{marginRight: "12px", marginLeft: "12px", marginTop: "4px", color: isErrorHeader ? "#FFFFFF" : "#AEB7C6"}}/>
						)}
						{label}

						<HeaderDescription selected={isHeaderExpanded} required={checkIfRequired(data[headerName])}>
							{description ? description : ""}
						</HeaderDescription>
					</Header>
					{isHeaderExpanded &&
						innerContent
					}
					</div>
				);
			})}
  		</DropdownWrapper>
  );
}

const DropdownWrapper = styled.div`
	display: flex;
	flex-direction: column;
	border-radius: 8px;
	overflow: hidden;
	border: ${(props) => ( props.isErrorHeader ? "1px solid #E54E58" : "1px solid #BDBDBD")};
    border-bottom: 1px solid #BDBDBD;
`;
  
const Header = styled.div`
	display: flex;
	justify-content: left;
	align-items: auto;
	height: 55px;
	padding: 16px;
	background-color: ${(props) => (props.isErrorHeader ? "#E54E58" : props.selected ? "#207DEA" : "#FFFFFF")};
	cursor: pointer;
	position: relative;
	z-index: 1;
	flex-direction: row;
	font-family: 'Source Sans Pro';
	font-size: 18px;
	font-weight: 700;
	font-style: normal;
	color: ${(props) => (props.isErrorHeader ? "#FFFFFF" : props.selected ? "#FFFFFF": "#207DEA")};
    border-bottom: ${(props) => (props.selected ? "1px solid #BDBDBD":  "none" )};
	${(props) => props.isNotLast && `border-bottom: 1px solid #BDBDBD;`}
	&:hover {
		background-color: ${(props) => (props.isErrorHeader ? "#E54E58" : props.selected ? "#207DEA" : "#F5F5F5")};
		color: ${(props) => (props.isErrorHeader ? "#FFFFF" : props.selected ? "#FFFFFF" : "#207DEA")};
	}
`;

const HeaderDescription = styled.div`
	position: absolute;
	font-family: 'Source Sans Pro';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 150%;
	right: 25px;
	color:${(props) => (props.selected ? "#FFFFFF" : "#BDBDBD")};

	${(props) => props.required && `
        &::after {
            content: '*';
            color: #E54E58;
            font-style: italic;
            width: 100%;
            text-align: center;
            margin-left: 5px;
			font-size: 20px;
        }
    `}
`;

export default Accordion;
