import { InfoMessage } from "../../../Constants/StyledComponents"
import styled from "styled-components"
import { useNavigate } from "react-router-dom"
import { FRONTEND_LINKS } from '../../../Constants/FrontendLinks';
import { useModalProvider } from "../../../Providers/ModalProvider";

function PostModifyModal({siteID, patientID}) {
    const navigate = useNavigate()
    const { createModal, close } = useModalProvider

    function handleReturnToVisualizePage() {
        close()
    }

    function handleViewProgress() {
		let newSelectedRowName = `${siteID}/${patientID}`
		navigate(`${FRONTEND_LINKS.SUBPAGES.DATA.SUBPAGES.UPLOAD.SUBPAGES.PATIENT.MAIN}?patient_uid=${newSelectedRowName}`)
    }

    function handleModifyAgain() {
        close()
    }

    return(<>
        <InfoMessage id="ModifySuccessMessage" style={{background: 'rgba(15, 169, 95, 0.2)', color: 'rgb(15, 169, 95)', textAlign: 'center', fontSize: '16px', width: 'max-content', marginLeft: 'auto', marginRight: 'auto'}}>
            <strong>Success!</strong> Modify Initiated!
        </InfoMessage>

        <div style={{display: 'flex', justifyContent: 'space-evenly', padding: '20px'}}>
            <PostModifyModalButton style={{width: 'fit-content'}} onClick={() => {handleReturnToVisualizePage()}}>
                Return To Visualize Page
            </PostModifyModalButton>

            <PostModifyModalButton style={{width: 'fit-content'}} onClick={() => {handleViewProgress()}}>
                View Progress
            </PostModifyModalButton>

            <PostModifyModalButton style={{width: 'fit-content'}} onClick={() => {handleModifyAgain()}}>
                Modify Again
            </PostModifyModalButton>
        </div>
    
    </>)
}

export default PostModifyModal;

const PostModifyModalButton = styled.div`
	display: inline-flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 6px 32px;
	margin-right: 5px;
	width: 114px;
	height: 40px;
	border-radius: 6px;
	font-family: 'Source Sans Pro';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 150%;
	border: 2px solid #207dea;
	cursor: pointer;
`;