import React, { useContext } from "react"
import { EEGChannel } from "../../../../Types/EEG"
import { useMobergMenu } from "../../../../../../../../Hooks/useMobergMenu"
import styled from "styled-components"
import { MobergRow, MobergButton, MobergTheme, MobergButtonShape, MobergIconSize, MobergAnimationTiming, MobergAnimationCurve, getColors } from "../../../../../../../../Moberg"
import { ChannelRename } from "./ChannelRename"
import { MdDragIndicator, MdEdit } from "react-icons/md"
import { SortableChannelsContext } from "./SortableChannels"
import { SortableHandle } from "react-sortable-hoc"

type ChannelListElementProps = {
	channel: EEGChannel
	channelIndex: number
	isActive: boolean
	isDragging: boolean
}

type DraggableItemProps = {
	isSelected: boolean
	isEditing: boolean
}

type DisappearProps = {
	alwaysVisible: boolean
}

export const ChannelListElement: React.FC<ChannelListElementProps> = ({ channel, channelIndex }) => {
	const { updateAlias, selectChannel, selectedChannels } = useContext(SortableChannelsContext)
	const menu = useMobergMenu({ placement: "right-start", zIndex: 10002 })
	const handleConfirmRename = (newAlias: string) => {
		menu.close().then(() => {
			updateAlias(channelIndex, newAlias)
		})
	}

	return (
		<div>
			<Disappear alwaysVisible={menu.isOpen}>
				<DraggableItem isSelected={selectedChannels.includes(channel)} isEditing={menu.isOpen}>
					<MobergRow style={{ paddingLeft: "8px", flex: 1 }}>
						<span
							style={{ cursor: "pointer" }}
							onClick={event => {
								event.stopPropagation()
								selectChannel(channel)
							}}
						>
							{channel.alias && `${channel.alias}: `}
							{`${channel.first} - ${channel.second}`}
						</span>

						<div className="disappear" onClick={(event: any) => menu.open(event)} style={{ padding: "0 8px" }}>
							<MobergButton
								theme={selectedChannels.includes(channel) ? MobergTheme.ON_BLUE : MobergTheme.ON_LIGHT_BLUE}
								shape={MobergButtonShape.SQUARE}
								active={menu.isOpen}
								style={{ padding: "5px" }}
							>
								<MdEdit size={MobergIconSize.SMALL} />
							</MobergButton>
						</div>

						<div
							style={{ flex: 1, opacity: 0, height: "30px", cursor: "pointer" }}
							onClick={event => {
								event.stopPropagation()
								selectChannel(channel)
							}}
						>
							-
						</div>

						<DragHandle />
					</MobergRow>
				</DraggableItem>
			</Disappear>

			{/* MobergMenu can only display content. It cannot handle state changes. */}
			<menu.MobergMenu style={{ marginLeft: "4px", padding: "16px" }}>
				<ChannelRename handleConfirmRename={handleConfirmRename} channel={channel} />
			</menu.MobergMenu>
		</div>
	)
}

const DragHandle = SortableHandle(() => (
	<Handle>
		<MdDragIndicator size={MobergIconSize.SMALL} />
	</Handle>
))

const Handle = styled.div`
	height: 32px;
	display: flex;
	align-items: center;
	padding: 0 8px;
	cursor: grab;

	&:active {
		cursor: grabbing;
	}
`

const Disappear = styled.div<DisappearProps>`
	.disappear {
		transition: opacity ${MobergAnimationTiming.VERY_FAST} ${MobergAnimationCurve.EASE_IN};
		opacity: ${({ alwaysVisible }) => (alwaysVisible ? 1 : 0)};
	}

	&:hover .disappear {
		opacity: 1;
	}
`

const DraggableItem = styled.div<DraggableItemProps>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 35px;
    border-bottom: 1px solid #E5E5E5;
    margin: 0;
    border-radius: 5px;
    transition all ${MobergAnimationTiming.FAST} ${MobergAnimationCurve.EASE_IN};

    &:hover {
        background: ${props => (props.isSelected ? getColors(MobergTheme.BLUE).noFill.active : getColors(MobergTheme.BLUE).noFill.hover)};
        border-radius: 5px;
        border-bottom: 1px solid transparent;
    }

    background: ${props => {
		if (props.isSelected) {
			return getColors(MobergTheme.BLUE).noFill.active
		}

		if (props.isEditing) {
			return getColors(MobergTheme.BLUE).noFill.hover
		}

		return "transparent"
	}};
`
