import { useRecoilValue } from "recoil"
import { useEffect } from "react"
import { currentPatientFileInfoAtom } from "../Atoms/PatientFile"
import { useSocketProvider } from "../../../../../Providers/SocketProvider"

export const SYNC_TIME_UPDATE_PERIOD_MILLISECONDS = 1000

// Request the current time from the server.
export const useSyncTime = () => {
	const { getDataQuerySocket } = useSocketProvider()
	const { isAdmitted } = useRecoilValue(currentPatientFileInfoAtom)

	useEffect(() => {
		if (!isAdmitted) {
			return
		}

		const socket = getDataQuerySocket()
		const intervalId = setInterval(() => socket.emit("sync_time"), SYNC_TIME_UPDATE_PERIOD_MILLISECONDS)

		return () => {
			clearTimeout(intervalId)
		}
	}, [getDataQuerySocket, isAdmitted])
}
