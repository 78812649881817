
import { ModalFooter } from "../../../../Constants/StyledComponents";
import React, { useState } from 'react';
import { editedLayoutGroupAtom, madeChangesToEditedLayoutGroupAtom, uneditedLayoutGroupAtom } from "../../../../Pages/Data/Visualize/DataReview/Atoms/Layout";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import { useModalProvider } from "../../../../Providers/ModalProvider";
import { useLayoutService } from "../../../../Hooks/useLayoutService";
import { LayoutTemplateSelector } from "./LayoutTemplateSelector"
import ConfirmationModal from "../../../../Components/ConfirmationModal/ConfirmationModal"
import { MobergButton, MobergButtonShape, MobergButtonVariant } from "../../../../Components/MobergButton/MobergButton";
import { MobergTheme } from "../../../../Components/MobergThemes/MobergColors";
import { ModalTitleAndCloseButton } from "../../../../Pages/Data/Visualize/DataReview/Components/Modals/ModalTitleAndCloseButton";
import { MobergFontSize } from "../../../../Components/MobergFont/MobergFont";
import { MobergRow } from "../../../../Moberg";

export function ConfigureLayoutModal({ layoutId }) {
    const { createModal, close } = useModalProvider()
    const madeChanges = useRecoilValue(madeChangesToEditedLayoutGroupAtom)
    const [editedLayoutGroup,] = useRecoilState(editedLayoutGroupAtom)
    const setUneditedLayoutGroup = useSetRecoilState(uneditedLayoutGroupAtom)
    const { modifyLayoutGroup } = useLayoutService()
    const currentLayout = editedLayoutGroup?.layouts.find(layout => layout.id === layoutId)
    const [waitingForNetwork, setWaitingForNetwork] = useState(false)
    const [error, setError] = useState()

    function saveChanges() {
        setWaitingForNetwork(true)
        setUneditedLayoutGroup(editedLayoutGroup)
        modifyLayoutGroup(editedLayoutGroup.id, editedLayoutGroup)
            .then(close)
            .catch(err => {
                setError(err)
                setWaitingForNetwork(false)
            })
    }

    function closeConfigurationModal() {
        if (madeChanges) {
            createModal(<ConfirmationModal
                title={"Discard Changes?"}
                description={'You have unsaved changes. Are you sure you want to close without saving?'}
                confirmButton={{ text: "Close without saving", theme: MobergTheme.RED }} 
                afterClose={close}
            />)
        } else {
            close()
        }
	}

    return (<div style={{ height: "90vh", width: "70vw", display: "flex", flexDirection: "column" }}>
        <ModalTitleAndCloseButton title={"Edit Display"} closeFunction={closeConfigurationModal} />

        <hr style={{ height: '0px', border: '1px solid #B3B3B3', margin: 0 }} />

        <div style={{ display: 'flex', flex: 1, width: "100%"}}>
            <LayoutTemplateSelector currentLayout={currentLayout} />
        </div>

        <hr style={{ height: '0px', border: '1px solid #B3B3B3', margin: 0 }} />

        {!waitingForNetwork && error && (
            <div style={{ width: "100%", textAlign: "center", color: "red" }}> {error} </div>
        )}

        <ModalFooter>
            <MobergRow gap="8px" horizontalAlign="right">
                {waitingForNetwork && (
                    <span> Please wait... </span>
                )}

                {!waitingForNetwork && error && (
                    <span style={{ color: "red" }}> {error} </span>
                )}

                <MobergButton disabled={!madeChanges} theme={MobergTheme.BLUE} variant={MobergButtonVariant.FILLED} shape={MobergButtonShape.WIDE} fontSize={MobergFontSize.LARGE} 
                    onClick={saveChanges}>
                    Save
                </MobergButton>
            </MobergRow>
        </ModalFooter>

    </div>)
}
