import { ScaleTime } from "d3"

export function getClampedStartAndEnd(startTime: number, endTime: number, fileScale: ScaleTime<any, any, any>): { start: number; end: number } {
	let newStartTime = startTime
	let newEndTime = endTime

	const [recordingStartDate, recordingEndDate] = fileScale.domain()
	const recordingStartTime = recordingStartDate.getTime()
	const recordingEndTime = recordingEndDate.getTime()
	const viewDuration = endTime - startTime
	const totalRecordingTime = recordingEndTime - recordingStartTime

	if (newEndTime > recordingEndTime) {
		newEndTime = recordingEndTime
		newStartTime = newEndTime - viewDuration
	} else if (totalRecordingTime < viewDuration && newStartTime < recordingEndTime - viewDuration) {
		newEndTime = recordingEndTime
		newStartTime = newEndTime - viewDuration
	} else if (totalRecordingTime >= viewDuration && newStartTime < recordingStartTime) {
		newStartTime = recordingStartTime
		newEndTime = newStartTime + viewDuration
	}

	return { start: newStartTime, end: newEndTime }
}
