import { useState, useEffect } from "react"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm } from "react-hook-form"
import * as yup from "yup"
import BasicTextField from "../../../../Components/TextField/BasicTextField"
import { useEndpointProvider } from "../../../../Providers/EndpointProvider"
import ModalHeader from "../../../../Components/ModalHeader/ModalHeader"
import { useModalProvider } from "../../../../Providers/ModalProvider"
import { useTableContext } from "../../../../Providers/TableProvider"
import MUITable from "../../../../Components/MUITable/MUITable"
import GridContainer from "../../../../Components/TextField/GridContainer"
import TableWrapper from "../../../../Components/TextField/TableWrapper"
import { ModalFooter } from "../../../../Constants/StyledComponents"
import ModalScrollbar from "../../../../Components/ModalScrollbar/ModalScrollbar"
import { MobergButton, MobergButtonShape, MobergButtonVariant } from "../../../../Components/MobergButton/MobergButton"
import { MobergTheme } from "../../../../Components/MobergThemes/MobergColors"
import { useBackendLinksProvider } from "../../../../Providers/BackendLinksProvider"

const userColumns = [
	{ field: "email", flex: 1, headerName: "Email", visible: true },
	{ field: "first_name", flex: 0.5, headerName: "First name", visible: true },
	{ field: "last_name", flex: 0.5, headerName: "Last name", visible: true },
	{
		field: "is_verified",
		flex: 0.3,
		headerName: "Verified",
		renderCell: params => {
			return params.value ? <CheckCircleIcon color="success" /> : null
		},
		headerAlign: "center",
		align: "center",
		visible: true
	},
]

function ModifySiteModals({ row, viewMode }) {
	const endpointProvider = useEndpointProvider()
	const { setToastProps } = useTableContext()
	const { close } = useModalProvider()
	const queryClient = useQueryClient()
	const { LINKS } = useBackendLinksProvider()

	const [selectedRows, setSelectedRows] = useState(viewMode ? [] : row.user_ids)
	const [errorMessage, setErrorMessage] = useState()

	const modifySite = async body => endpointProvider.post(LINKS.ADMIN.SITES.MODIFY_SITE, body)
	const modifySiteMutation = useMutation({
		mutationFn: body => modifySite(body),
		onError: error => {
			setToastProps({ isOpen: true, severity: "error", message: error.message })
		},
		onSuccess: () => {
			setToastProps({ isOpen: true, severity: "success", message: "Site successfully modified." })
		},
		onSettled: () => {
			queryClient.invalidateQueries("sites")
		},
	})
	const onSubmit = async data => {
		if (selectedRows.length === 0) {
			setErrorMessage("Please select at least one user.")
			return
		}
		delete data.identifier
		modifySiteMutation.mutate({
			...data,
			site_id: row.id,
			user_ids: selectedRows,
		})
		close()
	}

	const queryProps = {
		queryKey: "users",
		endpoint: viewMode ? LINKS.ADMIN.USER_MANAGEMENT.USERS.GET_USERS_BY_SITE : LINKS.ADMIN.USER_MANAGEMENT.USERS.GET_USERS,
		body: viewMode ? { site_id: row.id } : {},
	}
	const preselectedProps = { selectedRows, setSelectedRows }

	const schema = yup
		.object({
			name: yup.string().required("Name is required."),
			location: yup.string().required("Location is required."),
		})
		.required()
	const { control, handleSubmit, formState: { errors } } = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			name: row.name,
			identifier: row.identifier,
			location: row.location,
			description: row.description,
		},
	})

	useEffect(() => {
		if (Object.keys(errors).length > 0) {
			const error = errors[Object.keys(errors)[0]]
			if (Array.isArray(error)) {
				error.forEach(e => {
					if (e) {
						setErrorMessage(e?.message)
					}
				})
			} else {
				setErrorMessage(error?.message)
			}
		}
	}, [errors])

	return (
		<>
			<ModalHeader headerText={viewMode ? "View Site" : "Modify Site"} />
			<ModalScrollbar>
				<div style={{ maxWidth: "800px" }}>
					<h2>Site Details</h2>
					<GridContainer>
						<BasicTextField inputId="create-site-name-id" label="Name" placeholder="Test Site" autoFocus={true} control={control} name="name" numberColumn={6} disabled={viewMode} />
						<BasicTextField inputId="create-site-site-id-id" label="Site ID" placeholder="0000" control={control} name="identifier" disabled={true} numberColumn={6} />
						<BasicTextField inputId="create-site-location-id" label="Location" placeholder="11 E 22nd St" control={control} name="location" numberColumn={6} disabled={viewMode} />
						<BasicTextField
							inputId="create-site-description-id"
							label="Description"
							placeholder="Write description"
							control={control}
							name="description"
							numberColumn={6}
							disabled={viewMode}
						/>
						<TableWrapper inputId="modify-site-users-id" label="Users">
							<MUITable columns={userColumns} {...queryProps} preselectedProps={preselectedProps} isRowSelectable={() => !viewMode} isCheckboxSelection={!viewMode} />
						</TableWrapper>
					</GridContainer>
				</div>
			</ModalScrollbar>

			{!viewMode && (
				<ModalFooter style={{ justifyContent: "space-between" }}>
					<h1 style={{ color: "#E54E58" }}>{errorMessage ? errorMessage : null}</h1>
					<MobergButton theme={MobergTheme.BLUE} variant={MobergButtonVariant.FILLED} shape={MobergButtonShape.WIDE} onClick={handleSubmit(onSubmit)}>
						Submit
					</MobergButton>
				</ModalFooter>
			)}
		</>
	)
}

export default ModifySiteModals