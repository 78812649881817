import { useState, useEffect, useRef } from 'react';
import { ModalController } from "../../../../Controllers/ModalController";
import styled from 'styled-components';
import { LINKS } from '../../../../Constants/BackendLinks'
import * as MdIcons from "react-icons/md";
import { useStudiesProvider } from "../../../../Providers/StudiesProvider"
import { useEndpointProvider } from '../../../../Providers/EndpointProvider';

export default function CreateDetailReportModal({openRef, props, close, handleRefreshTable}) {
    const studiesProvider = useStudiesProvider()
    const endpointProvider = useEndpointProvider()

    const closeRef = useRef()
    const contentRef = useRef()
    let newSubheaderRef = useRef();
    let newReportSubheaderRef = useRef()
    const [newReportSubheaderVal, setNewReportSubheaderVal] = useState(0)
    const [reportSubheaderOrder, setReportSubheaderOrder] = useState([])
    const [reportSubheaderValues, setReportSubheaderValues] = useState([])
    const [newSubheaderVal, setNewSubheaderVal] = useState(0);
    const [tableConfig, setTableConfig] = useState([]);
    const [tableSubheaderOrder, setTableSubheaderOrder] = useState([]);
    const [tableSubheaderValues, setTableSubheaderValues] = useState([]);
    
    function deleteTableSubheaderValues(table) {
        if (tableSubheaderOrder.length === 1) {
            alert("Please create a new category before deleting this one.");
            return;
        }
        let indx = tableSubheaderOrder.indexOf(table);

        document.getElementById(`NewSubheader_${table}`).style.display = "none";
        document.getElementById(`TableButton_${table}`).style.display = "flex";
        document.getElementById(`NewSubheader_${table}`).value = "";

        setTableSubheaderOrder(
            Array.prototype.concat(
                tableSubheaderOrder.slice(0, indx),
                tableSubheaderOrder.slice(indx + 1)
            )
        );

        setTableSubheaderValues(
            Object.assign(
                {},
                ...Object.keys(tableSubheaderValues).map((table_, i) => {
                    if (table_ !== table) {
                        return {
                            [table_]:
                                (i === 0 &&
                                    indx !== 0 &&
                                    tableSubheaderValues[
                                    tableSubheaderOrder[indx]
                                    ]) ||
                                (i === 1 &&
                                    indx === 0 &&
                                    tableSubheaderValues[
                                    tableSubheaderOrder[indx]
                                    ]) ||
                                tableSubheaderValues[tableSubheaderOrder[i]],
                        };
                    }
                })
            )
        );
    }

    function updateTableSubheaderValues(table, value) {
        if (tableSubheaderValues[table]) {
            return;
        }
        let newTableSubheaderValues = Object.assign(
            {},
            ...Object.keys(tableSubheaderValues).map((table) => {
                return { [table]: false };
            })
        );
        setTableSubheaderValues({ ...newTableSubheaderValues, [table]: value });

        if (!tableSubheaderOrder.includes(table)) {
            setTableSubheaderOrder(
                Array.prototype.concat(tableSubheaderOrder, table)
            );

            setTableConfig({ ...tableConfig, [table]: createTableConfig() });
        }
    }

    function closeEditMultimodalReportModal() {
		if (document.getElementById("createMultimodalReportModal").style.display !== "none") {
			(document.getElementById("createMultimodalReportModal").style.display = "none");
			// (document.getElementById("vizGrayOverlay").style.display = "none");
		}
	}

    function createTableConfig () {
        const data_variables_list = ["Site", "Modalities", "Days"];
        const time_options_list = ["last week", "last month"];
        const modality_return_list = ['ICP', 'PbtO2', 'MAP', 'Temp']

        let data_variable = Object.assign(
            {},
            ...data_variables_list.map((data_variable) => {
                return { [data_variable]: false };
            })
        );
        data_variable.Site = true

        let time_option = Object.assign(
            {},
            ...time_options_list.map((time_option, i) => {
                if (i === 0) {
                    return { [time_option]: true };
                } else {
                    return { [time_option]: false };
                }
            })
        );

        return {
            data_variable: data_variable,
            time_option: time_option,
        }
    }

    function updateReportSubheaderValues(table, value) {
		if (reportSubheaderValues[table]) {
			return;
		}
		let newReportSubheaderValues = Object.assign({},
			...Object.keys(reportSubheaderValues).map((table) => {
				return { [table] : false}
			}))
		setReportSubheaderValues({...newReportSubheaderValues, [table]: value})

		if (!reportSubheaderOrder.includes(table)) {
			setReportSubheaderOrder(
				Array.prototype.concat(reportSubheaderOrder, table)
			)
		}
	}

    function deleteReportSubheaderValues(table) {
		if (reportSubheaderOrder.length === 1) {
			alert("Please create a new component before deleting this one.");
			return;
		}
		let indx = reportSubheaderOrder.indexOf(table);

        document.getElementById(`NewReportSubheader_${table}`).style.display = "none";
        document.getElementById(`ReportSubheader_${table}`).style.display = "flex";
        document.getElementById(`NewReportSubheader_${table}`).value = "";

		setReportSubheaderOrder(
            Array.prototype.concat(
                reportSubheaderOrder.slice(0, indx),
                reportSubheaderOrder.slice(indx + 1)
            )
        )

		setReportSubheaderValues(
            Object.assign(
                {},
                ...Object.keys(reportSubheaderValues).map((table_, i) => {
                    if (table_ !== table) {
                        return {
                            [table_]:
                                (i === 0 &&
                                    indx !== 0 &&
                                    reportSubheaderValues[
                                    reportSubheaderOrder[indx]
                                    ]) ||
                                (i === 1 &&
                                    indx === 0 &&
                                    reportSubheaderValues[
                                    reportSubheaderOrder[indx]
                                    ]) ||
                                reportSubheaderValues[reportSubheaderOrder[i]],
                        };
                    }
                })
            )
        )
	}

    const handleRefreshReportTable = handleRefreshTable

    async function createReport(study_id, report_name) {
        let body = {
            study_id: study_id,
            name: report_name,
        }

        return endpointProvider.post(LINKS.REPORTING.MULTIMODAL_REPORTS.REPORT_TEMPLATES.CREATE_REPORT_TEMPLATE, body)
	}

    function handleCreateReport() {
		let report_name = document.getElementById("ReportNameInput").value
		let study_id = studiesProvider.selectedStudyId

		createReport(study_id, report_name).then((res) => {
			handleRefreshReportTable()
		}).catch(e => {
            console.log(e)
        })
	}
    

    function validateCreateReportInputs() {
        let report_name = document.getElementById("ReportNameInput").value
        if (report_name === "") {
            alert('Report name cannot be empty')
            return false
        } else {
            handleCreateReport()
            return true
        }
    }

    useEffect(() => {
		let categoryHeaders = ["General", "Analytics", "Graphs", "Integrations"]
		setTableSubheaderOrder(categoryHeaders)

		let categoryHeadersValues = Object.assign({},
			...categoryHeaders.map((header) => {
				return { [header]: false}
			}))
		setTableSubheaderValues({...categoryHeadersValues, ["General"]: true})

		// let reportHeaders = ["Header 1", "Header 2"]
		let reportHeaders = []
		setReportSubheaderOrder(reportHeaders)
		let reportHeadersValues = Object.assign({},
			...reportHeaders.map((header) => {
				return { [header]: false}
			}))
		setReportSubheaderValues({reportHeadersValues, ["Header 1"]: true})
	}, [])

    return (<>
        <CreateDetailReportModalContent ref={contentRef} style={{display: "none"}}>
            <div id="createMultimodalReportModal" style={{position: "fixed", top: "0px", left: "5px", right: "5px", width: `${window.innerWidth - 10}px`, zIndex: "9999", background: "#FFFFFF", border: "1px solid black", height: `${window.innerHeight - 10}px`}}>
                        <div>
                            <div style={{ background: "none", display: "inline-flex", marginLeft: "40px", marginTop: "14px", marginBottom: "-40px"}}>
                                <PageSubHeader style={{ width: "100%",paddingLeft: "0px",}}>
                                    <HorizontalScrollBar style={{
                                            flex: "0 1 auto",
                                            width: "100%",
                                            display: "inline-flex",
                                            overflowX: "auto",
                                            overflowY: "hidden",
                                            marginBottom: "-2px",
                                            // marginLeft: "40px",
                                            whiteSpace: "nowrap",
                                            maxWidth: `${(window.innerWidth / 2) - 75}px`,
                                            background: "none"
                                        }}>
                                        {tableSubheaderOrder.map((table, i) => {
                                            return (
                                                <TableTab style={{background: "none"}}>
                                                    <TableButton id={`TableButton_${table}`} colorchange={tableSubheaderValues[table]} underlined={tableSubheaderValues[table]} style={{fontSize: "16px", fontStyle: "normal", fontWeight: "600"}}
                                                    onClick={(e) => {
                                                        switch (e.detail) {
                                                            case 1:
                                                                updateTableSubheaderValues(table, !tableSubheaderValues[table]);
                                                                break;
                                                            case 2:
                                                                let tableSubheaders =
                                                                    document.querySelectorAll('[id^="NewSubheader"]');
                                                                for (let tableSubheader of tableSubheaders) {
                                                                    tableSubheader.style.display = "none";
                                                                    document.getElementById(`TableButton_${tableSubheader.id.split("_")[1]}`).style.display = "flex";
                                                                }
                                                                document.getElementById(`NewSubheader_${table}`).style.display = "flex";
                                                                document.getElementById(`TableButton_${table}`).style.display = "none";
                                                                break;
                                                            default:
                                                                return;
                                                        }
                                                    }}>
                                                        <p>{table}</p>
                                                        </TableButton>
                                                        <input
                                                            id={`NewSubheader_${table}`}
                                                            ref={newSubheaderRef}
                                                            type="text"
                                                            placeholder="New Category"
                                                            onKeyDown={(e) => {
                                                                if (e.keyCode === 13) {
                                                                    var newKey = document.getElementById(`NewSubheader_${table}`).value;
                                                                    if (Object.keys(tableSubheaderValues).includes(newKey)) {
                                                                        alert("Category name already exists.");
                                                                        return;
                                                                    }
                                                                    if (newKey === "") {
                                                                        alert("Category name cannot be blank");
                                                                        return;
                                                                    }
                                                                    let indx = tableSubheaderOrder.indexOf(table);
                                                                    setTableSubheaderOrder(Array.prototype.concat(tableSubheaderOrder.slice(0, indx),newKey,tableSubheaderOrder.slice(indx + 1)));
                                                                    let newTableSubheaderValues =
                                                                        Object.assign(
                                                                            {},
                                                                            ...Object.keys(
                                                                                tableSubheaderValues
                                                                            ).map((table_) => {
                                                                                if (table_ !== table) {
                                                                                    return {
                                                                                        [table_]: false,
                                                                                    };
                                                                                }
                                                                            })
                                                                        );
                                                                    setTableSubheaderValues({
                                                                        ...newTableSubheaderValues,
                                                                        [newKey]: true,
                                                                    });
                                                                    let newTableConfig = Object.assign(
                                                                        {},
                                                                        ...Object.keys(tableConfig)
                                                                            .map((table_) => {
                                                                                if (table_ === table) {
                                                                                    return {
                                                                                        [newKey]: tableConfig[table],
                                                                                    };
                                                                                } else {
                                                                                    return {
                                                                                        [table_]: tableConfig[table_],
                                                                                    };
                                                                                }
                                                                            })
                                                                    );
                                                                    setTableConfig(newTableConfig)
                                                                    document.getElementById(
                                                                        `NewSubheader_${table}`
                                                                    ).style.display = "none";
                                                                    document.getElementById(
                                                                        `NewSubheader_${table}`
                                                                    ).value = "";
                                                                    document.getElementById(
                                                                        `TableButton_${table}`
                                                                    ).style.display = "flex";
                                                                }
                                                            }}
                                                            style={{
                                                                display: "none",
                                                                borderRadius: "6px",
                                                                paddingLeft: "5px",
                                                                border: "solid 0.5px #A7A7A7",
                                                                fontFamily: "Source Sans Pro",
                                                                width: "110px",
                                                                marginBottom: "8px",
                                                                marginRight: "4px"
                                                            }}
                                                        /> 
                                                        <button
                                                            style={{
                                                                color: "#6C757C",
                                                                marginTop: "4px",
                                                                paddingLeft: "0px",
                                                            }}
                                                            onClick={(e) => {
                                                                deleteTableSubheaderValues(table);
                                                            }}
                                                        >
                                                            <MdIcons.MdClose />
                                                        </button>
                                                    </TableTab>
                                                );
                                            })}
                                        </HorizontalScrollBar>
                                        <button
                                            style={{
                                                color: "#0097ee",
                                                marginLeft: "20px",
                                                width: "20px",
                                                paddingRight: "0px",
                                                fontSize: 24,
                                                marginTop: "-5px", right: `${(window.innerWidth / 2) - 10}px`, position: "fixed"
                                            }}
                                            onClick={(e) => {
                                                updateTableSubheaderValues(
                                                    `New Category ${newSubheaderVal}`,
                                                    !tableSubheaderValues[
                                                    `New Category ${newSubheaderVal}`
                                                    ]
                                                );
                                                setNewSubheaderVal(newSubheaderVal + 1);
                                            }}
                                        >
                                            +
                                        </button>
                                    </PageSubHeader>
                                </div>
                            <MdIcons.MdCloseFullscreen onClick={() => {closeEditMultimodalReportModal()}} size={22} style={{right: "14px", marginTop: "14px", position: "absolute", cursor: "pointer"}}/>

                            <hr style={{border: "1px solid #DDDDDD", marginTop: "8px"}}/>
                            <HorizontalScrollBar style={{
                                flex: "0 1 auto",
                                width: "100%",
                                display: "inline-flex",
                                overflowX: "auto",
                                overflowY: "hidden",
                                marginBottom: "-2px",
                                marginLeft: "40px",
                                whiteSpace: "nowrap",
                                maxWidth: `${(window.innerWidth / 2) - 75}px`,
                                background: "none"
                            }}>
                                {reportSubheaderOrder.map((table, i) => {
                                    return (
                                        <TableTab style={{background: "none", border: "none"}}>
                                            <TableButton id={`ReportSubheader_${table}`} colorchange={reportSubheaderValues[table]} underlined={reportSubheaderValues[table]} style={{border: "none", background: "none", fontSize: "16px", fontStyle: "normal", fontWeight: "600"}}
                                            onClick={(e) => {
                                            switch (e.detail) {
                                                case 1: 
                                                    updateReportSubheaderValues(table, !reportSubheaderValues[table]);
                                                break;
                                                case 2:
                                                    let reportSubheaders =
                                                        document.querySelectorAll('[id^="NewReportSubheader"]');
                                                    for (let reportSubheader of reportSubheaders) {
                                                        reportSubheader.style.display = "none";
                                                        document.getElementById(`ReportSubheader_${reportSubheader.id.split("_")[1]}`).style.display = "flex";
                                                    }
                                                    document.getElementById(`NewReportSubheader_${table}`).style.display = "flex";
                                                    document.getElementById(`ReportSubheader_${table}`).style.display = "none";
                                                    break;
                                                default:
                                                    break;
                                            }
                                        }}>
                                                <p style={{paddingBottom: "7px"}}>{table}</p>
                                            </TableButton>
                                            <input 
                                                id={`NewReportSubheader_${table}`} 
                                                ref={newReportSubheaderRef}
                                                type="text"
                                                placeholder="New Component"
                                                onKeyDown={(e) => {
                                                    if (e.keyCode === 13) {
                                                        var newKey = document.getElementById(`NewReportSubheader_${table}`).value;
                                                        if (Object.keys(reportSubheaderValues).includes(newKey)) {
                                                            alert("Component name already exists.");
                                                            return;
                                                        }
                                                        if (newKey === "") {
                                                            alert("Component name cannot be blank");
                                                            return;
                                                        }
                                                        let indx = reportSubheaderOrder.indexOf(table);
                                                        setReportSubheaderOrder(Array.prototype.concat(reportSubheaderOrder.slice(0, indx), newKey, reportSubheaderOrder.slice(indx + 1)));
                                                        let newReportSubheaderValues = 
                                                            Object.assign(
                                                                {},
                                                                ...Object.keys(
                                                                    reportSubheaderValues
                                                                ).map((table_) => {
                                                                    if (table_ !== table) {
                                                                        return {
                                                                            [table_]: false,
                                                                        };
                                                                    }
                                                                })
                                                            );
                                                        setReportSubheaderValues({...newReportSubheaderValues, [newKey]: true})
                                                        document.getElementById(`NewReportSubheader_${table}`).style.display = "none";
                                                        document.getElementById(`NewReportSubheader_${table}`).value = "";
                                                        document.getElementById(`ReportSubheader_${table}`).style.display = "flex";
                                                    }
                                                }}
                                                style={{
                                                    display: "none",
                                                    borderRadius: "6px",
                                                    paddingLeft: "5px",
                                                    border: "solid 0.5px #A7A7A7",
                                                    fontFamily: "Source Sans Pro",
                                                    width: "125px",
                                                    marginBottom: "8px",
                                                    marginRight: "4px"
                                                }}
                                            />
                                            <button
                                                style={{color: "#6C757C", marginTop: "-12px", paddingLeft: "0px", background: "none", border: "none"}}
                                                onClick={(e) => {
                                                    deleteReportSubheaderValues(table);
                                                }}>
                                                <MdIcons.MdClose />
                                            </button>
                                        </TableTab>
                                    )
                                })}
                            </HorizontalScrollBar>
                            <button
                                            style={{color: "#0097ee", marginLeft: "20px", width: "20px", paddingRight: "0px", fontSize: 24, marginTop: "-5px", background: "none", border: "none"
                                            }}
                                            onClick={(e) => {
                                                updateReportSubheaderValues(
                                                    `New Component ${newReportSubheaderVal}`,
                                                    !tableSubheaderValues[
                                                    `New Component ${newReportSubheaderVal}`
                                                    ]
                                                );
                                                setNewReportSubheaderVal(newReportSubheaderVal + 1);
                                            }}
                                        >
                                            +
                            </button>

                            <div id="multimodalReportModalContent" style={{display: "block", background: "#F8F8F8", maxHeight: `${window.innerHeight - 125}px`, overflowY: "scroll", paddingLeft: "40px", paddingRight: "40px", paddingTop: "20px"}}>
                                
                                {/* <CreateReports /> */}
                            </div>


                        </div>
                    </div>
        </CreateDetailReportModalContent>

        <ModalController
            openRef={openRef}
            closeRef={closeRef}
            contentRef={contentRef}
            closeOnEscape={true}
            onToggle={info => {
                if (!info.isOpen) {
                    close()
                }
            }}
            modalShouldOpen={validateCreateReportInputs}
        >
        </ModalController>
    </>)
}

const CreateDetailReportModalContent = styled.div`
`;

const PageSubHeader = styled.div`
	display: flex;
	width: 100%;
	height: 47px;
	padding-left: 50px;
	padding-right: 50px;
	overflow-x: auto;
	overflow-y: hidden;
	white-space: nowrap;
	background: #ffffff;
	padding-top: 10px;
	margin-bottom: 25px;
	button {
		font-family: "Source Sans Pro";
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		display: inline-flex;
		border: solid 0.5px #ffffff;
		background: #ffffff;
		cursor: pointer;
	}
`;

const HorizontalScrollBar = styled.div`
	::-webkit-scrollbar {
		display: block;
		height: 5px;
		color: #313a4a;
		border-radius: 2px;
	}
	::-webkit-scrollbar-track {
		background: #bec4cf;
		height: 5px;
		border-radius: 2px;
	}
`;

const TableTab = styled.div`
	width: auto;
	height: 34px;
	background: none;
	border: solid 0.5px #ffffff;
	font-size: 16px;
	display: inline-flex;
	margin-right: 35px;
	/* padding-left: -35px; */
	
`;

const TableButton = styled.button`
	width: auto;
	height: 34px;
    background: #ffffff;
	border: solid 0.5px #ffffff;
	font-size: 16px;
	display: inline-flex;
	margin-right: 28px;
	color: ${({colorchange}) => (colorchange ? "#0097ee" : "#6C757C")};
	p {
		padding-bottom: 10px;
		border-bottom: ${({underlined}) =>
			underlined ? "2px solid #0097ee" : "none"};
	}
`;