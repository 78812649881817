import { NODE_OUTPUT_TYPES } from "../../NodeOutputTypes";
import { USER_INPUT_TYPES } from "../../UserInputTypes";

export const POLYNOMIAL_FIT = {
    INPUTS: [
        {
            DISPLAY_NAME: "Degree",
            VARIABLE_NAME: "degree",
            TYPE: USER_INPUT_TYPES.NUMBER_INPUT,
            DEFAULT_VALUE: 2,
            DESCRIPTION: "The degree of polynomial to use for fitting the data",
        },
    ],
    DISPLAY_NAME: "Polynomial Fit",
    PRODUCT_LEVEL: "Standard",
    DESCRIPTION: "Fit data using a polynomial and get the coefficients.",
    KEYWORDS: ["poly", "fit", "polynomial", "regression"],
    TYPE: 'METRICS',
    DATA_INPUT_TYPES: [NODE_OUTPUT_TYPES.LIST_OF_VALUES, NODE_OUTPUT_TYPES.LIST_OF_VALUES],
    DATA_OUTPUT_TYPE: NODE_OUTPUT_TYPES.LIST_OF_VALUES
}