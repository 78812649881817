import { Selection, EnterElement, ScaleTime } from "d3"
import { TimeSeriesPageManager } from "../../Data/TimeSeriesPageManager"
import { ReactCallbacks } from "../../Types/ReactCallbacks"
import { D3OneToManyRenderable } from "./D3OneToManyRenderable"

type NotLoadedRegionsConfig = {
    height: number    
	scale: ScaleTime<any, any, any>
    clipPathId?: string
}

export class D3NotLoadedRegionsWrapper extends D3OneToManyRenderable<SVGGElement, NotLoadedRegionsConfig, [number, number]> {
    private pageManager: TimeSeriesPageManager<any>
    private unloadedRegions: Array<[number, number]> = []

	constructor(root: SVGGElement, config: NotLoadedRegionsConfig, pageManager: TimeSeriesPageManager<any>, reactCallbacks: ReactCallbacks<any>) {
        super(root, config, "d3-not-loaded-region", reactCallbacks)
        this.pageManager = pageManager
        this.mount()
	}

	enter(newElements: Selection<EnterElement, any, any, any>): Selection<any, any, any, any> {
		const regions = newElements
            .append("rect")
            .attr("height", this.config.height)
            .attr("rx", 2)
            .attr("fill", "lightgray")
            .attr("class", this.className)
			.attr("x", (region: [number, number]) => this.config.scale(region[0]))
			.attr("width", (region: [number, number]) => {
                const width = Math.abs(this.config.scale(region[1]) - this.config.scale(region[0]))
                return isNaN(width) ? 0 : width
            })
			.attr("height", this.config.height)
            .attr("pointer-events", "none")

        if (this.config.clipPathId) {
			regions.attr("clip-path", `url(#${this.config.clipPathId})`)
        }

		return regions
	}

	update(updatedElements: Selection<any, any, any, any>): Selection<any, any, any, any> {
		return updatedElements
            .attr("x", (region: [number, number]) => this.config.scale(region[0]))
            .attr("width", (region: [number, number]) => {
                const width = Math.abs(this.config.scale(region[1]) - this.config.scale(region[0]))
                return isNaN(width) ? 0 : width
            })
            .attr("height", this.config.height)
	}

    public updateNotLoadedRegions(): void {
        this.unloadedRegions = this.pageManager.getUnloadedRegions()
        this.render()
    }

    protected datumIdentifier(datum: [number, number]): string | number {
        return datum[0]
    }

    protected getConfigs(): [number, number][] {
        return this.unloadedRegions
    }
}
