import { scaleBand, scaleLinear } from "d3";
import { MarginedBoundingBox } from "../../../../Types/MarginedBoundingBox";
import { Offset } from "../../../../Types/Offset";
import { HistogramReactCallbacks } from "../../../../Types/ReactCallbacks";
import { D3TimeBasedVisualization } from "../../D3TimeBasedVisualization";
import { D3HistogramConfigurationBuilder } from "./D3HistogramConfigurationBuilder";
import { D3HistogramRenderer } from "./D3HistogramRenderer";
import { TimeBasedVisualizationConfig } from "../../../../Types/TimeBasedVisualizationConfig";
import { range } from "lodash";
import { TimeSeriesPageManager } from "../../../../Data/TimeSeriesPageManager";
import { ModalityPage } from "../../../../Data/ModalityPage";
import { LEFT_MARGIN, RIGHT_MARGIN } from "../../Constants";
import { ModalityDataSource } from "../../../../Types/ModalityDataSource";
import { HistogramModalityConfig } from "../../../../Types/Histogram";
import { TraceDataConfig } from "../../../../Types/Trace";

export interface HistogramConfig extends TimeBasedVisualizationConfig {
    modalityConfigs: HistogramModalityConfig[]
    countMax: number
    binMinimum: number
    binMaximum: number
    binSize: number
}

export class D3Histogram extends D3TimeBasedVisualization<HistogramConfig, HistogramReactCallbacks, D3HistogramRenderer, TimeSeriesPageManager<any>> {
    private margins: Offset = { top: 30, left: LEFT_MARGIN, bottom: 110, right: RIGHT_MARGIN }

    public yScale = scaleLinear()
    public xScale = scaleBand().padding(0.1)
    public boundingBox: MarginedBoundingBox

    constructor(root: HTMLDivElement, config: HistogramConfig, pageManager: TimeSeriesPageManager<any>, reactCallbacks: HistogramReactCallbacks) {
        super(root, config, pageManager, reactCallbacks)
        this.boundingBox = new MarginedBoundingBox(config.dimensions, this.margins)

        this.mount(new D3HistogramRenderer(this, new D3HistogramConfigurationBuilder(this), "d3-histogram"))
    }

    // PUBLIC

    public getModalityDataSources(): ModalityDataSource[] {
        return this.config.modalityConfigs.map(config => ({
            modality: config.name,
            dataObjectId: this.reactCallbacks.dataSourceMap.get(config.dataSource) ?? Infinity,
        }))
    }

    public getXScaleLabels() {
        const bins = range(this.config.binMinimum, this.config.binMaximum + this.config.binSize, this.config.binSize)
        return bins.slice(0, -1).map((bin, index) => `${bin}-${bins[index + 1]}`)
    }

    public autoScale = () => {
        this.renderer?.autoScale()
    }

    public onYAxisDrag = () => {
        this.renderer?.onYAxisDrag()
    }

    public getVisibleTraces(): TraceDataConfig[] {
        return []
    }

    // PROTECTED

    protected renderPage(page: ModalityPage): void {
        this.renderer?.renderPage(page)
    }

    protected updateDerivedState(): void {
        this.boundingBox.setDimensions(this.config.dimensions)
        this.yScale.range([this.boundingBox.height, 0]).domain([0, this.config.countMax])
        this.xScale
            .domain(this.getXScaleLabels())
            .range([0, this.boundingBox.width])

        // Because we are not rendering anything on the screen, we can choose what ever level of resolution that we want.
        // We just need to make sure that it is a reasonable amount of data.
        this.config.viewScale.range([0, Math.max(this.boundingBox.width, 1000)])
        this.renderer?.updateChildren()
    }
}