import { useState, useEffect } from 'react';
import UploadTerm from './UploadWindows/UploadTerm';
import UploadSiteDetail from './UploadWindows/UploadSiteDetail';
import UploadPatientSite from './UploadWindows/UploadPatientSite';
import UploadDataPatient from './UploadWindows/UploadDataPatient';
import UploadSummaryPatient from './UploadWindows/UploadSummaryPatient';
import UploadLoading from './UploadWindows/UploadLoading';
import UploadAddedToQueue from './UploadWindows/UploadAddedToQueue';
import UploadFormEntry from './UploadWindows/UploadFormEntry';
import { useUploadQueueProvider } from '../../../../Providers/UploadQueueProvider';
import ProgressBar from './/UploadWindows/Components/UploadWindowsProgressBar'
import { useUploadIntoQueueProvider } from '../../../../Providers/UploadIntoQueueProvider';
import { useSitesProvider } from '../../../../Providers/SitesProvider';
import { useUploadProcessProvider } from '../../../../Providers/UploadProcessProvider';
import { useEnvironmentVariablesProvider } from '../../../../Providers/EnvironmentVariablesProvider';

const UploadWindow = ({
}) => {
    // The data structure here is the doubly linked list use to traverse between different components
    const [toNextWindow, setToNextWindow] = useState(false);
    const [toPrevWindow, setToPrevWindow] = useState(false);
    const uploadQueueProvider = useUploadQueueProvider()
    const uploadIntoQueueProvider = useUploadIntoQueueProvider();
    const sitesProvider = useSitesProvider();
    const uploadProcessProvider = useUploadProcessProvider();
    const environmentVariablesProvider = useEnvironmentVariablesProvider()

    // Setting up the node for each component

    const UploadAddedToQueueNode = {
        value: <UploadAddedToQueue setToNextWindow={setToNextWindow} setToPrevWindow={setToPrevWindow} setOpenQueue={(uploadQueueProvider.queueLength === 1 && (uploadQueueProvider.queueStatus === "Hashing" || uploadQueueProvider.queueStatus === "Done" || uploadQueueProvider.queueStatus === "Uploading")) ? uploadIntoQueueProvider.setOpenLoading : uploadIntoQueueProvider.setOpenLoading(false)} />,
        previous: null,
        next: null,
        name: "UploadAddedToQueue",
        order: "6"
    }

    const UploadLoadingNode = {
        value: <UploadLoading setToNextWindow={setToNextWindow} setToPrevWindow={setToPrevWindow} />,
        previous: null,
        next: null,
        name: "UploadLoading",
        order: "5"
    }

    const UploadSummaryPatientNode = {
        value: <UploadSummaryPatient
            setToNextWindow={setToNextWindow}
            setToPrevWindow={setToPrevWindow} />,
        previous: null,
        next: null,
        name: "UploadSummaryPatient",
        order: "4"
    }

    const UploadDataPatientNode = {
        value: <UploadDataPatient setToNextWindow={setToNextWindow} setToPrevWindow={setToPrevWindow} />,
        previous: null,
        next: null,
        name: "UploadDataPatient",
        order: "3"
    }

    const UploadFormEntryNode = {
        value: <UploadFormEntry setToNextWindow={setToNextWindow} setToPrevWindow={setToPrevWindow} />,
        previous: null,
        next: null,
        name: "UploadFormEntry",
        order: ""
    }

    const UploadPatientSiteNode = {
        value: <UploadPatientSite setToPrevWindow={setToPrevWindow} setToNextWindow={setToNextWindow} />,
        previous: null,
        next: null,
        name: "UploadPatientSite",
        order: "2"
    }

    const UploadSiteDetailNode = {
        value: <UploadSiteDetail setToNextWindow={setToNextWindow} setToPrevWindow={setToPrevWindow} />,
        previous: null,
        next: null,
        name: "UploadSiteDetail",
        order: "1"
    }

    const UploadTermNode = {
        value: <UploadTerm setToNextWindow={setToNextWindow} />,
        previous: null,
        next: null,
        name: "UploadTerm",
        order: "0"
    }

    //Setting up the next and previous nodes for each window
    UploadTermNode.next = UploadSiteDetailNode;
    UploadSiteDetailNode.previous = UploadTermNode;
    UploadSiteDetailNode.next = UploadPatientSiteNode;
    UploadPatientSiteNode.previous = UploadSiteDetailNode;
    UploadPatientSiteNode.next = UploadDataPatientNode;
    UploadDataPatientNode.previous = UploadPatientSiteNode;
    UploadDataPatientNode.next = UploadSummaryPatientNode;
    UploadSummaryPatientNode.previous = UploadDataPatientNode;
    UploadSummaryPatientNode.next = UploadAddedToQueueNode;
    UploadAddedToQueueNode.previous = UploadSummaryPatientNode
    UploadAddedToQueueNode.next = UploadLoadingNode;
    UploadLoadingNode.previous = UploadAddedToQueueNode;

    // Setting up the default node and the total number of steps for the progress bar
    let totalStepState = 4;
    let defaultNode = UploadTermNode;

    if (environmentVariablesProvider.environmentVariables.FORM) {
        UploadFormEntryNode.order = "3"
        UploadSummaryPatientNode.order = "4"
        totalStepState = 4;
        UploadPatientSiteNode.next = UploadFormEntryNode;
        UploadFormEntryNode.previous = UploadPatientSiteNode;
        UploadFormEntryNode.next = UploadSummaryPatientNode;
        UploadSummaryPatientNode.previous = UploadFormEntryNode;
    }

    // Case where there is only one site and the data is encrypted    
    if (sitesProvider.userSites?.length === 1 && environmentVariablesProvider.environmentVariables.ENCRYPT) {
        if (environmentVariablesProvider.environmentVariables.FORM) {
            totalStepState = 4;
            UploadPatientSiteNode.order = "1";
            UploadFormEntryNode.order = "2";
            UploadSummaryPatientNode.order = "3";
        }
        UploadPatientSiteNode.order = "1";
        UploadDataPatientNode.order = "2";
        UploadSummaryPatientNode.order = "3";
        totalStepState = 3;
        defaultNode = UploadPatientSiteNode;
        const site = sitesProvider.userSites[0];
        uploadProcessProvider.setSelectedSiteID(site['site_id'])
        uploadProcessProvider.setSelectedSitePrimaryKey(site['id'])
        uploadProcessProvider.setSelectedSiteName(site['name'])
        uploadProcessProvider.setCurrentWindow(UploadPatientSiteNode.name);
    }
    // Case where there is more than one site and the data is encrypted
    else if (sitesProvider.userSites?.length !== 1 && environmentVariablesProvider.environmentVariables.ENCRYPT) {
        defaultNode = UploadSiteDetailNode;
        uploadProcessProvider.setCurrentWindow(UploadSiteDetailNode.name);
    }
    // Case where there is one site and the data is not encrypted
    else if (sitesProvider.userSites?.length === 1 && !environmentVariablesProvider.environmentVariables.ENCRYPT) {
        if (environmentVariablesProvider.environmentVariables.FORM) {
            totalStepState = 3;
            UploadPatientSiteNode.order = "1";
            UploadFormEntryNode.order = "2";
            UploadSummaryPatientNode.order = "3";
        }
        else {
            totalStepState = 3;
            UploadPatientSiteNode.order = "1";
            UploadDataPatientNode.order = "2";
            UploadSummaryPatientNode.order = "3";
        }
        const site = sitesProvider.userSites[0];
        uploadProcessProvider.setSelectedSiteID(site['site_id'])
        uploadProcessProvider.setSelectedSitePrimaryKey(site['id'])
        uploadProcessProvider.setSelectedSiteName(site['name'])
        uploadProcessProvider.setCurrentWindow(UploadTermNode.name);
        UploadTermNode.next = UploadPatientSiteNode;
        UploadPatientSiteNode.previous = UploadTermNode;
    }

    // The current window
    const [currentComponent, setCurrentComponent] = useState(defaultNode);

    // To open the Loading page from the queue
    useEffect(() => {
        if (uploadIntoQueueProvider.openLoading === true) {
            setCurrentComponent(UploadLoadingNode)
        }
        uploadIntoQueueProvider.setOpenLoading(false)
    }, [uploadIntoQueueProvider.openLoading])

    // Switch between the different components
    useEffect(() => {
        if (toNextWindow === true) {
            setCurrentComponent(currentComponent.next);
            uploadProcessProvider.setCurrentWindow(currentComponent.next.name);
        }
        if (toPrevWindow === true) {
            setCurrentComponent(currentComponent.previous);
            uploadProcessProvider.setCurrentWindow(currentComponent.previous.name);
        }
        setToPrevWindow(false);
        setToNextWindow(false);
    }, [toNextWindow, toPrevWindow])


    return (
        <>
            {(currentComponent.name !== 'UploadLoading' && currentComponent.name !== 'UploadAddedToQueue' && currentComponent.name !== 'UploadTerm') && <ProgressBar currentStep={currentComponent.order} totalStep={totalStepState} />}
            <div style={{ marginTop: "5px" }}>
                {currentComponent.value}
            </div>
        </>
    );
}

export default UploadWindow;
