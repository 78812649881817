import { debounce } from "lodash";
import { PersystEEGLabelsSocketResponse } from "../Types/SocketResponse";
import { ImagePage } from "./ImagePage";
import { PersystEEGImagePage } from "./PersystEEGImagePage";
import { PersystPageManager } from "./PersystPageManager";
import { ModalityDataSource } from "../Types/ModalityDataSource";

export class PersystEEGImagePageManager extends PersystPageManager {
	getPageMaker = () => {
		return () => new PersystEEGImagePage()
	}

    protected getNewPage(startTime: number, endTime: number, width: number, height: number, patientId: string, modalityDataSources: ModalityDataSource[], timeZoneOffsetMs: number, socketId: string, patientIsAdmitted: boolean): ImagePage {
        const page = new PersystEEGImagePage()

		page.updateProperties({ 
			startTime, 
			endTime, 
			width, 
			height, 
			patientId, 
			modalityDataSources, 
			timeZoneOffsetMs,
			socketId, 
			patientIsAdmitted,
			getDataQuerySocket: this.getDataQuerySocket
		})

		return page
    }

	// Debounce allows us to call this function a lot and not worry about sending a butt ton of requests.
	protected requestTitlesAndLabels = debounce((height: number, width: number, montage: string) => {
		this.setRequestDimensions({ height, width, panel: montage })
		const eventName = "render_persyst_eeg_labels"

		if (this.getDataQuerySocket === null) {
			return
		}
		
		const socket = this.getDataQuerySocket(this.config.windowId)

		const listener = (event: PersystEEGLabelsSocketResponse) => {
			if (event.height !== height || event.width !== width) {
				socket.off(eventName, listener)
				return
			}

			const data = {
				titles: new ArrayBuffer(0),
				labels: event.image,
			}

			this.setTitlesAndLabelsCache({
				panel: montage,
				dimensions: { height, width },
				data: data,
			})

			socket.off(eventName, listener)
			this.setRequestDimensions(null)
			this.onTitlesAndLabelsLoaded()
		}

		socket.on(eventName, listener)
		socket.emit(eventName, this.config.patientId, this.config.modalityDataSources[0].dataObjectId, 1, montage, width, height)
	}, 200)
}
