import React from "react";
import { useController } from "react-hook-form";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { DateTimePicker } from "./DateTimePicker";
import { styled as MuiStyled } from "@mui/material/styles"
import styled from "styled-components";
import dayjs from "dayjs";

const CustomCalendar = MuiStyled(DateCalendar)(({ theme, error, changeDisabledTheme }) => ({
	".Mui-disabled": {
		borderColor: changeDisabledTheme ? "#000000" : undefined,
	},
	'& input:disabled': {
		WebkitTextFillColor: changeDisabledTheme ? "#000000" : undefined
	},

	margin: 0,
	".MuiPickersCalendarHeader-root": {
		margin: 0,
		padding: 0,
	},

}));

const CalendarContainer = styled.div({
});

export function CalendarPicker(props) {
	const { name, control, label, inputId, disabled = false, changeDisabledTheme } = props;
	const {
		field: { ref, value, ...inputProps },
		fieldState: { error },
	} = useController({
		name,
		control,
	})

	return (
		<CalendarContainer>
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<h1>{label}</h1>
				{!changeDisabledTheme && <CustomCalendar
					id={inputId}
					disabled={disabled}
					{...inputProps}
					error={!!error}
					ref={ref}
					value={value ? dayjs(value) : props.value}
					changeDisabledTheme={changeDisabledTheme}
				/>}
				<DateTimePicker
					control={control}
					value={value ? value : props.value}
					changeDisabledTheme={changeDisabledTheme}
					name={name}
					disabled={disabled}
				/>
			</LocalizationProvider>
		</CalendarContainer>
	);
}

