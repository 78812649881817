import React, { MouseEventHandler, MouseEvent } from "react"
import { createComponent } from "../../VisualizationComponentFactory"
import { currentlyActiveVisualizationAreaAtom } from "../../Atoms/Visualizations"
import { useRecoilCallback, useSetRecoilState } from "recoil"
import styled from "styled-components"
import { MobergBoxShadow } from "../../../../../../Components/MobergThemes/MobergStyles"

export type GridAreaProps = {
	area: string
	componentId: string
	props?: {}
	shadow?: boolean
}

export const GridArea = ({ componentId, area, props, shadow }: GridAreaProps) => {
	const setActiveWindow = useSetRecoilState(currentlyActiveVisualizationAreaAtom)

	const updateActiveWindow = useRecoilCallback(({ snapshot }) => async () => {
		const activeWindow = await snapshot.getPromise(currentlyActiveVisualizationAreaAtom)

		if (activeWindow !== area) {
			setActiveWindow(area)
		}
	}, [])

	const onMouseMove: MouseEventHandler<HTMLDivElement> = (event: MouseEvent) => {
		if (event.buttons > 0) {
			return // the user is clicking or dragging.
		}

		updateActiveWindow()
	}

	return (
		<StyledGridArea style={{ gridArea: area, boxShadow: shadow ? MobergBoxShadow.LOW : "none" }} onMouseMove={onMouseMove}>
			{createComponent(componentId, area, props)}
		</StyledGridArea>
	)
}

const StyledGridArea = styled.div`
	min-width: 0;
	min-height: 0;
	border-radius: 6px;
	background: #fff;
`
