import { useState, useEffect } from "react"
import ModalHeader from "../../../../../Components/ModalHeader/ModalHeader";
import { ModalFooter } from "../../../../../Constants/StyledComponents";
import { useUploadProcessProvider } from "../../../../../Providers/UploadProcessProvider";
import { TextTerm, UploadFilesModal, AgreementLabel } from "../../../../../Constants/StyledComponents";
import { MobergButton } from "../../../../../Components/MobergButton/MobergButton";
import { MobergTheme } from "../../../../../Components/MobergThemes/MobergColors";
import { MobergButtonVariant } from "../../../../../Components/MobergButton/MobergButton";
import { MobergButtonShape } from "../../../../../Components/MobergButton/MobergButton";

export const UploadTerm = ({ setToNextWindow }) => {
    const uploadProcessProvider = useUploadProcessProvider();

    const [isAgreementChecked, setIsAgreementChecked] = useState(false);

    const handleNext = () => {
        setToNextWindow(true);
    }

    useEffect(() => {
        setIsAgreementChecked(uploadProcessProvider.termsAndConditionsChecked)
    }, [uploadProcessProvider.termsAndConditionsChecked])

    return (<>
        <UploadFilesModal>
            <ModalHeader headerText='Terms & Conditions' resetCache={uploadProcessProvider.resetCache} />
            <div id="uploadFilesModalScrollableContentRef" style={{ paddingTop: "15px", paddingLeft: "47px", paddingRight: "47px", paddingBottom: "0px", height: "600px", maxHeight: "650px", overflowY: "scroll", flex: 1 }}>
                <TextTerm>
                    <p style={{ marginBottom: "20px" }}>Welcome to Moberg Analytics CONNECT (“Service”), a cloud-based platform for healthcare data management. By accessing or using the Service, you agree to be bound by these Terms and Conditions (“Terms”).</p>

                    <h2>Definitions</h2>
                    <p style={{ marginBottom: "20px" }}>“Protected Health Information (PHI)” is any individually identifiable health information as defined by the Health Insurance Portability and Accountability Act of 1996 (HIPAA).</p>
                    <p style={{ marginBottom: "20px" }}>“Personally Identifiable Information (PII)” is any information about an individual that can be used to distinguish or trace an individual's identity, such as name, address, or SSN; and any other information that is linked or linkable to an individual, such as medical, financial, and employment information.</p>
                    <p style={{ marginBottom: "20px" }}>“User” means any individual or organization accessing or using the Service.</p>
                    <p style={{ marginBottom: "20px" }}>“Upload” means electronic transmission of data from the individual or organization into the Service.</p>

                    <h2>Conditions and Responsibilities</h2>
                    <ol>
                        <li>The User is responsible for ensuring all PHI and PII has been removed, de-identified, or sufficiently encrypted so that it can no longer be classified as PHI or PII before Upload to the Service.</li>
                        <li>The User is solely responsible for any harm or damage caused by failure to remove PHI or PII from Uploaded data. The user further agrees to hold Moberg Analytics harmless for any such damage.</li>
                    </ol>
                </TextTerm>
            </div>
            <hr style={{ border: "1px solid #B3B3B3", width: "100%", margin: '0px auto' }} />
            <ModalFooter>
                <AgreementLabel>
                    <input type="checkbox" checked={isAgreementChecked}
                        onChange={uploadProcessProvider.updateTermsAndConditionsChecked}
                        style={{ width: "18px", height: "18px" }} />
                    <div style={{ color: isAgreementChecked ? "#000000" : "#E54E58", fontSize: "16px", fontFamily: "Source Sans Pro", fontWeight: "400" }}>
                        I have read and understand the Upload Terms. <span style={{ color: "red", display: isAgreementChecked ? "none" : "inline-flex", fontSize: "22px", position: "absolute" }}>*</span>
                    </div>
                </AgreementLabel>

                <MobergButton
                    theme={MobergTheme.BLUE}
                    variant={MobergButtonVariant.FILLED}
                    shape={MobergButtonShape.WIDE}
                    onClick={handleNext}
                    disabled={!isAgreementChecked}
                >
                    Next
                </MobergButton>
            </ModalFooter>
        </UploadFilesModal>
    </>
    )
}

export default UploadTerm;