import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useGridApiContext } from "@mui/x-data-grid"
import SearchIcon from "@mui/icons-material/Search"
import { Checkbox, Input, InputAdornment } from "@mui/material"
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import { MobergButton } from "../MobergButton/MobergButton"
import { MobergTheme } from '../MobergThemes/MobergColors'
import { Popper } from '@material-ui/core'
import { MobergBoxShadow } from '../MobergThemes/MobergStyles'
import { MobergFontFamily, MobergFontSize } from '../MobergFont/MobergFont'

function CustomColumnVisibilityMenu({ setPopoverRef, isOpen, anchorEl }) {
    const apiRef = useGridApiContext()
    const [searchValue, setSearchValue] = useState("")

    function toggleColumnVisibility(column) {
        apiRef.current.setColumnVisibility(column.field, !apiRef.current.getColumn(column.field).visible)
        apiRef.current.getColumn(column.field).visible = !apiRef.current.getColumn(column.field).visible
    }

    function showAllColumns() {
        apiRef.current.getAllColumns().forEach(column => {
            apiRef.current.setColumnVisibility(column.field, true)
            apiRef.current.getColumn(column.field).visible = true
        })
    }

    // Prevents an issue where the Menu is not actually un-rendered, only hidden from the dom.
    // This may be fixed if we create a popover provider or renderer.
    useEffect(() => {
        if (!isOpen && searchValue !== "") {
            setSearchValue("")
        }
    }, [searchValue, isOpen])

    return (
        <Popper key={"DataGridColumnsPanel"} placement="bottom-start" open={isOpen} anchorEl={anchorEl} style={{ zIndex: 10000 }}>
            <ColumnVisibilityMenuContainer ref={setPopoverRef}>
                <div style={{ fontFamily: MobergFontFamily.HEADER, fontSize: MobergFontSize.X_LARGE }}> Columns </div>
                <div style={{ display: "flex", gap: "8px", alignItems: "center"}}>
                    <Input
                        id='MUI-Search-Bar'
                        startAdornment={
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        }
                        placeholder="Search"
                        onChange={e => { setSearchValue(e.target.value) }}
                        disableUnderline={true}
                    />

                    <div>
                        <MobergButton onClick={() => showAllColumns()} theme={MobergTheme.BLUE}>
                            Show all
                        </MobergButton>
                    </div>
                </div>

                <div>
                    <FormGroup>
                        {apiRef.current.getAllColumns().filter(col => col.field !== '__check__').filter(col => col.headerName.toLowerCase().includes(searchValue)).map(column => {
                            return (
                                <FormControlLabel
                                    key={column.headerName}
                                    control={
                                        <Checkbox
                                            id={column.headerName}
                                            checked={column.visible}
                                            onChange={() => toggleColumnVisibility(column)}
                                        />}
                                    label={column.headerName}
                                    sx={{ width: 'fit-content' }}
                                />
                            )
                        })}

                    </FormGroup>
                </div>

            </ColumnVisibilityMenuContainer>
        </Popper>
    )
}

export default CustomColumnVisibilityMenu

const ColumnVisibilityMenuContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    min-width: 300px;
    padding: 15px;
    background: white;
    border-radius: 6px;
    opacity: 0;
    transform: scale(0.95);
    transform-origin: top left;
    box-shadow: ${MobergBoxShadow.REGULAR};
    gap: 16px;
`
