import styled from "styled-components";
import { BUTTON_STYLING } from "../../Constants/Formatting";
import { MobergAnimationCurve, MobergAnimationTiming } from "../MobergAnimation/MobergAnimation"

// USAGE NOTES (color):
// blueFill (DEFAULT) = modal footer button with blue background and white text
// blueOutline = modal footer button with white background and blue border/text
// redFill = modal footer button with red background and white text
// redOutline = modal footer button with white background and red border/text 

export const buttonColor = Object.freeze({
    blueFill : "blue-fill",
    blueOutline: "blue-outline",
    redFill: "red-fill",
    redOutline: "red-outline"
})

function ModalFooterButton({ buttonText="", onClickFxn, disabled=false, buttonStyle={}, color=buttonColor.blueFill, hoverText="" }) {
    return(<>
    <ModalFooterButtonContainer
        onClick={onClickFxn ? onClickFxn : null}
        disabled={disabled}
        style={buttonStyle}
        color={color}
        title={hoverText}
    >
        {buttonText}
    </ModalFooterButtonContainer>
    </>)
}

export const ModalFooterButtonContainer = styled.button`
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: fit-content;
    height: 40px;
    transition: all ${MobergAnimationTiming.FAST} ${MobergAnimationCurve.EASE_IN};
    background: ${(props) => props.color === buttonColor.redFill 
        ? BUTTON_STYLING.RED 
        : props.color === buttonColor.redOutline 
        ? BUTTON_STYLING.WHITE 
        : props.color === buttonColor.blueOutline 
        ? BUTTON_STYLING.WHITE 
        : BUTTON_STYLING.BLUE};
    color: ${(props) => props.color === buttonColor.redFill 
        ? BUTTON_STYLING.WHITE 
        : props.color === buttonColor.redOutline 
        ? BUTTON_STYLING.RED 
        : props.color === buttonColor.blueOutline 
        ? BUTTON_STYLING.BLUE 
        : BUTTON_STYLING.WHITE};
    border: ${(props) => props.color === buttonColor.redFill 
        ? "none" 
        : props.color === buttonColor.redOutline 
        ? BUTTON_STYLING.RED_BORDER 
        : props.color === buttonColor.blueOutline 
        ? BUTTON_STYLING.BLUE_BORDER
        : "none"};
    border-radius: 6px;
    font-family: ${BUTTON_STYLING.FONT};
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    padding: 6px 32px;
    white-space: nowrap;
    &:hover:enabled {
        background: ${(props) => props.color === buttonColor.redFill 
            ? BUTTON_STYLING.RED_FILL_HOVER 
            : props.color === buttonColor.redOutline 
            ? BUTTON_STYLING.RED_OUTLINE_HOVER 
            : props.color === buttonColor.blueOutline 
            ? BUTTON_STYLING.BLUE_OUTLINE_HOVER 
            : BUTTON_STYLING.BLUE_FILL_HOVER
        };
    }
    &:active:enabled {
        background: ${(props) => props.color === buttonColor.redFill 
            ? BUTTON_STYLING.RED_FILL_ACTIVE 
            : props.color === buttonColor.redOutline 
            ? BUTTON_STYLING.RED_OUTLINE_ACTIVE
            : props.color === buttonColor.blueOutline 
            ? BUTTON_STYLING.BLUE_OUTLINE_ACTIVE
            : BUTTON_STYLING.BLUE_FILL_ACTIVE
        };
    }
    &:disabled {
        cursor: default;
        opacity: 0.3; 
    }
`;

export default ModalFooterButton;