import React from "react"
import styled, { css } from "styled-components"
import { MobergButtonProps, StyledButtonProps } from "./Types"
import { MobergFontFamily, MobergFontSize } from "../MobergFont/MobergFont"
import { MobergAnimationCurve, MobergAnimationTiming } from "../MobergAnimation/MobergAnimation"
import { MobergTheme, getColors } from "../MobergThemes/MobergColors"

export enum MobergButtonVariant {
	FILLED = "filled",
	OUTLINE = "outline",
	CLEAR = "clear",
}

export enum MobergButtonShape {
	SQUARE = "square",
	RECTANGLE = "rectangle",
	WIDE = "wide",
	FULL_WIDTH = "full_width",
}

export const MobergButton: React.FC<MobergButtonProps> = React.forwardRef<HTMLButtonElement | null, MobergButtonProps>(({ children, theme, variant, shape, fontSize, tooltip, onClick, disabled, style, active }, ref) => {
	return (
		<div>
			{/* Wrapping the button in a div protects it from being stretched in flex containers. */}
			<BaseButton
				disabled={disabled}
				theme={theme ?? MobergTheme.BLACK}
				variant={variant ?? MobergButtonVariant.CLEAR}
				shape={shape ?? MobergButtonShape.RECTANGLE}
				fontSize={fontSize ?? MobergFontSize.REGULAR}
				active={active ?? false}
				title={tooltip}
				onClick={onClick}
				style={style}
				ref={ref}
			>
				{children}
			</BaseButton>
		</div>
	)
})

const getButtonStyles = css<StyledButtonProps>`
	${({ theme, variant, active }) => {
		const colors = getColors(theme)

		switch (variant) {
			case MobergButtonVariant.FILLED:
				return css`
					background: ${colors.main};
					border: 2px solid ${colors.main};
					color: white;
					&:hover:enabled {
						border-color: ${colors.hover};
						background: ${colors.hover};
					}
					&:active:enabled {
						border-color: ${colors.active};
						background: ${colors.active};
					}
				`
			case MobergButtonVariant.OUTLINE:
				return css`
					background: transparent;
					border: 2px solid ${colors.main};
					color: ${colors.main};
					&:hover:enabled {
						background: ${colors.noFill.hover};
					}
					&:active:enabled {
						background: ${colors.noFill.active};
					}
				`
			case MobergButtonVariant.CLEAR:
				return css`
					background: ${active ? colors.noFill.active : "transparent"};
					border: 2px solid transparent;
					color: ${colors.main};
					&:hover:enabled {
						border-color: ${active ? colors.noFill.active : colors.noFill.hover};
						background: ${active ? colors.noFill.active : colors.noFill.hover};
					}
					&:active:enabled {
						border-color: ${colors.noFill.active};
						background: ${colors.noFill.active};
					}
				`
			default:
				return ""
		}
	}}
`

const getButtonPaddingFromShape = (shape?: MobergButtonShape) => {
	switch(shape) {
		case MobergButtonShape.WIDE: return "7px 32px"
		case MobergButtonShape.SQUARE: return "7px"
		case MobergButtonShape.RECTANGLE:
		default:
			return "7px 16px"
	}
}

const getButtonWidthFromShape = (shape?: MobergButtonShape) => {
	switch(shape) {
		case MobergButtonShape.FULL_WIDTH: return "100%"
		default:
			return ""
	}
}

const BaseButton = styled.button<StyledButtonProps>`
	display: flex;
	align-items: center;
	gap: 8px;
	justify-content: center;
	border: none;
	background: transparent;
	white-space: nowrap;
	font-style: normal;
	font-weight: bold;
	font-size: ${({ fontSize }) => fontSize};
	font-family: ${MobergFontFamily.REGULAR};
	width: ${({ shape }) => getButtonWidthFromShape(shape)};
	padding: ${({ shape }) => getButtonPaddingFromShape(shape)};
	border-radius: 6px;
	transition: all ${MobergAnimationTiming.FAST} ${MobergAnimationCurve.EASE_IN};

	${getButtonStyles}

	&:disabled {
		opacity: 0.3;
	}
`
