import { Box, Typography } from "@mui/material"
import { MobergButton, MobergButtonVariant } from "../MobergButton/MobergButton"
import { MobergTheme } from "../MobergThemes/MobergColors"


export default function ErrorMessage(props) {
	const { refetch } = props
	return (
		<Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" style={{ minHeight: "80vh" }}>
			<Typography variant="h6" color="textPrimary">
				Something went wrong. Please try again!
			</Typography>
			<MobergButton variant={MobergButtonVariant.FILLED} theme={MobergTheme.BLUE} onClick={refetch} style={{ marginTop: "20px" }}>
				Try Again
			</MobergButton>
		</Box>
	)
}
