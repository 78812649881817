import { ModalityDataSource } from "../Types/ModalityDataSource"
import { ImagePage, ImagePageProperties } from "./ImagePage"
import { PersystTrendsImagePageManager } from "./PersystTrendsImagePageManager"

type PersystTrendsImagePageProperties = ImagePageProperties & {
	pageManager: PersystTrendsImagePageManager
}

export class PersystTrendsImagePage extends ImagePage<PersystTrendsImagePageProperties> {
	private pageManager: PersystTrendsImagePageManager = new PersystTrendsImagePageManager()

    socketEventName = () => "render_persyst_trend"

    requestData(batch: Map<number, ModalityDataSource[]>): void {
		if (this.getDataQuerySocket === null) {
			return
		}
		
		const socket = this.getDataQuerySocket(this.socketId)
        
		batch.forEach((modalities, dataObjectId) => {
			socket.emit(
				this.socketEventName(), 
				this.patientId, 
				dataObjectId, 
				this.id,
				modalities[0],
				this.startTime,
				this.endTime,
				this.width,
				this.height,
				this.pageManager.isArtifactReductionEnabled()
			)
		})
    }
}