export const filterType = {
    uniqueValues: "unique_values",
    // arg: {type: "unique_values", attr: attributeName, name: columnName}
    // --> {type: "unique_values", attr: attributeName, name: columnName, values: {uni1: true, uni2: false, ...}}

    timestampBefore: "timestamp_before",
    // arg: {type: "timestamp_before", attr: attributeName, name: columnName}
    // --> {type: "timestamp_before", attr: attributeName, name: columnName, max: maxTimestamp, timestamp: timestamp}

    timestampAfter: "timestamp_after",
    // arg: {type: "timestamp_after", attr: attributeName, name: columnName}
    // --> {type: "timestamp_after", attr: attributeName, name: columnName, min: minTimestamp, timestamp: timestamp}

    timerangeCompare: "timerange_compare"
    // arg: {type: "timerange_compare", attr: attributeName, name: columnName}
    // --> {type: "timerange_compare", attr: attributeName, name: columnName,
    // operator: Greater/Less/Equal, timerange: {Days: n-days, Hours: n-hours, Minutes: n-minutes}}
}  
// --> generate a filterRules pipeline. filterFunctions = {attrName1: ({attrName1})=>..., attrName2: ({attrName2})=>..., ...}

export const CompareOperator = {
    greater: "Greater", // >= , greater than or equal
    less: "Less",  // <= , less than or equal
    // equal: "Equal"
}

export const datetimeToTimestamp = (datetimeString) => { // '28 Nov 2017 02:45 PM GMT-5' --> 1511898300000
    let timestamp = Date.parse(datetimeString)
    return timestamp
}

export const buildGetRows = (data, uniqueRef, displayedColumns) => {
    const _getData = ({pageIndex=1, rowsPerPage=data.length, orderBy, orderDirection, search, filter}) => 
    new Promise(
        (resolve, reject) => {
            if (data.length === 0) {
                resolve({
                    data: [], // only provide rows on current page,
                    uni_ref: uniqueRef,
                    page_index: 1,
                    total_pages: 1
                })
            }

            const startRowIndex = rowsPerPage * (pageIndex - 1)
            const endRowIndex = rowsPerPage * pageIndex
            let newRows = data

            // filter data based on search
            if (search) {
                const { columns, keywords } = search
                if (keywords !== "") {
                    const containKeywords = (row) => 0 < columns.filter(
                        attr => String(row[attr]).toLowerCase().includes(keywords.toLowerCase())
                    ).length
                    newRows = newRows.filter(containKeywords)
                }
            }

            // filter data based on filter rules
            if (filter) {
                const filterPipes = filter.map(config => {
                    switch (config.type) {
                        case filterType.uniqueValues:
                            return row => config.values.includes(row[config.attr])
                        case filterType.timestampBefore:
                            return (typeof(data[0][config.attr]) === "number")
                                ? row => row[config.attr] <= config.timestamp
                                : row => datetimeToTimestamp(row[config.attr]) <= config.timestamp
                        case filterType.timestampAfter:
                            return (typeof(data[0][config.attr]) === "number")
                                ? row => row[config.attr] >= config.timestamp
                                : row => datetimeToTimestamp(row[config.attr]) >= config.timestamp
                        case filterType.timerangeCompare:
                            switch (config.operator) {
                                case CompareOperator.greater:
                                    return row => row[config.attr] >= config.timerange
                                case CompareOperator.less:
                                    return row => row[config.attr] <= config.timerange
                                default:
                                        return null
                            }
                        default:
                            return null
                    }
                })
                .filter(c => c !== null)
                const pipeline = filterPipes.reduce((f1, f2) => row => f1(row) && f2(row), () => true)
                // console.log("filterPipes", filterPipes, newRows, newRows.map(pipeline))
                newRows = newRows.filter(pipeline)
            }           
            
            // sort data based on orderBy, sortDirection
            if (orderBy) {
                let displayedColumnsCopy = displayedColumns
                let selectedColumn = displayedColumnsCopy.filter(col => col.attr === orderBy)
                if (selectedColumn[0]['sortBy']) {
                    orderBy = selectedColumn[0]['sortBy']
                } // the 'sortBy' attribute should be used when defining a table column when the column data that is sorted is different than the column the user clicks
                const getComparedAttr = r => r[orderBy]
                const compare = (rowA, rowB) => {
                    if (getComparedAttr(rowA) < getComparedAttr(rowB)) {
                        return (orderDirection==="asc") ? -1 : 1
                    } else if (getComparedAttr(rowA) > getComparedAttr(rowB)) {
                        return (orderDirection==="asc") ? 1 : -1
                    } else {
                        return 0
                    }
                }
                newRows = newRows.sort(compare)
            }

            resolve({
                data: newRows.slice(startRowIndex, endRowIndex), // only provide rows on current page,
                uni_ref: uniqueRef,
                page_index: pageIndex,
                total_pages: Math.max(Math.ceil(newRows.length / rowsPerPage), 1)
            })
        }
    )
    return _getData
}