import { useState } from "react"

export default function DropdownInput({ nodeId, config, initialValue, onChange }) {

	if (!config.OPTIONS) {
		config.OPTIONS = ["--"]
	}

	const [value, setValue] = useState(initialValue ?? config.DEFAULT_VALUE ?? config.OPTIONS[0])

    return (
		<div style={{display: "flex"}}>
			<div style={{paddingRight: "8px"}}>
				{config.DISPLAY_NAME}
			</div>

			<select style={{maxWidth: "150px", width: "fit-content", border: "1px solid gray"}}
			onChange={(e) => {
				setValue(e.target.value)
				if (onChange) {
					onChange({nodeId, arg: config.VARIABLE_NAME, value: e.target.value})
				}
			}} 
			value={value}>
				{config.OPTIONS.map((option, index) => 
					<option key={option + "_" + index}>{option}</option>)}
			</select>
		</div>
    )
}