import { UploadFilesModal, ModalFooter, UploadDescription } from "../../../../../Constants/StyledComponents";
import loadingBrainGray from "../../../../../Constants/Graphics/loadingbrain-gray.svg"
import ModalHeader from "../../../../../Components/ModalHeader/ModalHeader";
import { useUploadQueueProvider } from "../../../../../Providers/UploadQueueProvider";
import { useEffect } from "react";
import { useModalProvider } from "../../../../../Providers/ModalProvider";
import UploadLoading from "./UploadLoading";
import { MobergButton } from "../../../../../Components/MobergButton/MobergButton";
import { MobergTheme } from "../../../../../Components/MobergThemes/MobergColors";
import { MobergButtonVariant } from "../../../../../Components/MobergButton/MobergButton";
import { MobergButtonShape } from "../../../../../Components/MobergButton/MobergButton";

const UploadAddedToQueue = () => {
    const uploadQueueProvider = useUploadQueueProvider()
    const { openNext, close } = useModalProvider()

    useEffect(() => {
        if (uploadQueueProvider.queueLength === 1 && uploadQueueProvider.queueStatus !== "InQueue") {
            uploadQueueProvider.setSelectedSiteID(uploadQueueProvider.queuedSiteID)
            uploadQueueProvider.setPatientID(uploadQueueProvider.queuedPatientID)
            openNext(<UploadLoading
                escClose={false}
                clickOutsideClose={false}
            />)
        }
    }, [uploadQueueProvider.queueLength, uploadQueueProvider.queueStatus])

    function handleHide() {
        close()
    }

    return (
        <UploadFilesModal>
            <ModalHeader headerText='Upload in Queue' />
            <div style={{ paddingTop: "108px", paddingLeft: "63px", paddingRight: "63px", paddingBottom: "146px", height: "600px", marginTop: "18px", flex: 1 }}>
                <div style={{ display: "inline-flex", marginLeft: "180px", marginRight: "auto", textAlign: "center", width: "600px", marginTop: "100px" }}>
                    <img src={loadingBrainGray} alt="uploadInQueue" style={{ width: "133px", marginRight: "-90px" }} />
                    <div style={{ marginLeft: "10px", marginRight: "auto", textAlign: "left", width: "415px", paddingTop: "15px" }}>
                        <UploadDescription>
                            Upload for Patient {uploadQueueProvider.queuedPatientID} at {uploadQueueProvider.queuedSiteName} has been added to the queue.
                        </UploadDescription>
                    </div>
                </div>
            </div>
            <hr style={{ border: "1px solid #B3B3B3", width: "100%", margin: '0px auto' }} />
            <ModalFooter style={{ justifyContent: 'right' }}>
                <MobergButton
                    theme={MobergTheme.BLUE}
                    variant={MobergButtonVariant.OUTLINE}
                    shape={MobergButtonShape.WIDE}
                    onClick={() => handleHide()}
                >
                    Close
                </MobergButton>
            </ModalFooter>
        </UploadFilesModal>
    )
}

export default UploadAddedToQueue;