import React, { useEffect } from "react"
import { BiSolidHourglassBottom } from "react-icons/bi"
import { MobergFontSize } from "../../../../../Moberg"
import { SubHeader } from "./SubHeader";

type AllUploadsPendingProps = {
    checkUploadsPending: () => Promise<any>
    patient?: any
}

export const AllUploadsPending = ({ checkUploadsPending, patient }: AllUploadsPendingProps) => {

    useEffect(() => {
        const intervalId = setInterval(checkUploadsPending, 30 * 1000)
        return () => clearInterval(intervalId)
    })

    return (
        <div style={{ padding: "0 32px", height: "100%", display: "flex", flexDirection: "column"}}>
            <div>
                <SubHeader patient={patient} />
            </div>

            <div style={{ flex: 1, display: "flex", flexDirection: "column",  width: "100%", alignItems: "center", justifyContent: "center" }}>
                <div style={{ display: "flex", gap: "32px", flexDirection: "column", justifyContent: "center" }}>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <BiSolidHourglassBottom size={"200px"} color={"lightgray"}/>
                    </div>

                    <div style={{ fontSize: MobergFontSize.XX_LARGE}}>Waiting for upload to complete...</div>
                </div>
            </div>
        </div>
    )
}
