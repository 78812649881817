import React, { useRef, useState } from "react"
import { LayoutGroup } from "../../Pages/Data/Visualize/DataReview/Types/Layout"
import { MobergMenuController } from "../../Hooks/useMobergMenu"
import { useLayoutService } from "../../Hooks/useLayoutService"
import { useAsyncTask } from "../../Hooks/useAsyncTask"
import { useOnMount } from "../../Hooks/useOnMount"
import {
	MobergButton,
	MobergButtonVariant,
	MobergColumn,
	MobergDropdown,
	MobergFontFamily,
	MobergFontSize,
	MobergInputLabel,
	MobergRow,
	MobergShortTextInput,
	MobergTheme,
} from "../../Moberg"
import { useWorkspacesProvider } from "../../Providers/WorkspacesProvider"
import { MobergText } from "../MobergHeader/MobergHeader"

type CopyDisplayGroupProps = {
	displayGroup: LayoutGroup
	menu: MobergMenuController
}

export const CopyDisplayGroup: React.FC<CopyDisplayGroupProps> = ({ displayGroup, menu }) => {
	const defaultName = displayGroup.name + " (copy)"
	const [name, setName] = useState(defaultName)
	const [destination, setDestination] = useState<string>("Personal")
	const inputRef = useRef<HTMLInputElement>(null)
	const { copyLayoutGroup } = useLayoutService()
	const { selectedWorkspace } = useWorkspacesProvider()

	const copyLayoutTask = useAsyncTask(() => {
		const copy = { ...displayGroup }
		copy.name = name.trim() === "" ? defaultName : name
		return copyLayoutGroup(copy, destination)
	})

	const submit = () => {
		if (copyLayoutTask.isWaiting) {
			return // don't allow submit while the task is running!
		}

		inputRef.current?.blur() // re-enable hotkeys if the input did not lose focus

		copyLayoutTask.run().then(() => {
			menu.close()
		})
	}

	useOnMount(() => inputRef.current?.focus())

	return (
		<MobergColumn gap="16px" horizontalAlign="start">
			<MobergText fontSize={MobergFontSize.X_LARGE} fontFamily={MobergFontFamily.HEADER} fontWeight="bold">
				Make a copy
			</MobergText>

			<MobergShortTextInput ref={inputRef} label={"Name"} limit={50} onChange={name => setName(name)} onSubmit={submit} defaultValue={defaultName} />

			<MobergRow>
				<MobergColumn gap="8px">
					<MobergInputLabel text={"Access"} />

					<MobergDropdown
						selectedValue={destination}
						onChange={newDestination => setDestination(newDestination as string)}
						options={[
							{ label: "Personal", value: "Personal" },
							{ label: `Shared (${selectedWorkspace})`, value: selectedWorkspace },
						]}
					/>
				</MobergColumn>
			</MobergRow>

			<MobergRow horizontalAlign="right">
				<MobergButton theme={MobergTheme.BLUE} variant={MobergButtonVariant.FILLED} onClick={submit} disabled={copyLayoutTask.isWaiting}>
					OK
				</MobergButton>
			</MobergRow>
		</MobergColumn>
	)
}
