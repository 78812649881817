import { ScaleTime, scaleTime } from "d3"
import { LayoutWindowId } from "../Types/LayoutWindowId"
import { getClampedStartAndEnd } from "../Components/Visualizations/clamping"

type ViewScaleDefaults = {
    fileStartDate: Date
    fileEndDate: Date
    viewDuration: number | undefined
}

type ViewScaleEntry = {
	viewScale: ScaleTime<number, number, never>
	componentId: string
}

// Holds references to view scales for Data Review.
class ViewScaleRegistry {
	private registry: Map<string, ViewScaleEntry> = new Map()

	get = (key: LayoutWindowId, componentId: string, defaults: ViewScaleDefaults): ScaleTime<number, number, never> =>  {
		const entry = this.registry.get(this.mapKey(key))

		// LayoutWindowId accounts for the specific window across different tabs.
		// We also check componentId because different components in the same physical location have different time scale restrictions.
		if (entry?.viewScale && entry?.componentId === componentId) {
			return entry.viewScale
		}

        const { fileStartDate, fileEndDate, viewDuration=60_000 } = defaults
		const startTime = fileStartDate.getTime()
		const { start, end } = getClampedStartAndEnd(startTime, startTime + viewDuration, scaleTime().domain([fileStartDate, fileEndDate]))
        
		const defaultEntry: ViewScaleEntry = {
			viewScale: scaleTime().domain([start, end]),
			componentId
		}

		this.registry.set(this.mapKey(key), defaultEntry)

		return defaultEntry.viewScale
	}

	set = (key: LayoutWindowId, componentId: string, viewScale: ScaleTime<number, number, never>) => {
		this.registry.set(this.mapKey(key), { viewScale, componentId})
	}

	// Allows all memory to be cleaned up by the garbage collector.
	clear = () => {
		this.registry.clear()
	}

	// Using Objects as Map keys can result in undefined behavior because it uses reference equality for comparison.
	// So, we turn the object into a string which can be checked for equality.
	private mapKey = (key: LayoutWindowId): string => `${key.layoutId}-${key.windowId}`
}

export const viewScaleRegistry = new ViewScaleRegistry()
