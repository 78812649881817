import { select, Selection, EnterElement, ScaleLinear } from "d3"
import { Renderable } from "../../Types/Renderable"

export type D3HorizontalLinesConfig = {
    yScale: ScaleLinear<any, any, any>
    width: number
}

export class D3HorizontalLines implements Renderable {
	private root: SVGGElement
    private config: D3HorizontalLinesConfig
	private className = "d3-horizontal-lines"

	constructor(root: SVGGElement, config: D3HorizontalLinesConfig) {
		this.root = root
        this.config = config

        const rootNode = select(root)
            .append("g")
            .attr("class", this.className)
            .node()

        if (rootNode != null) {
            this.root = rootNode
        }

		this.render()
	}

	public updateConfig = (config: D3HorizontalLinesConfig) => {
        this.config = config
		this.render()
	}

	public render = () => {
		select(this.root)
			.selectAll("line")
			.data(this.config.yScale.ticks(4))
			.join(
				newElements => this.enter(newElements),
				updatedElements => this.update(updatedElements),
				exitedElements => exitedElements.remove()
			)
	}

	private enter = (newElements: Selection<EnterElement, number, any, any>): Selection<any, any, any, any> => {
        const lines = newElements.append("line")
            .attr("y1", value => this.config.yScale(value))
            .attr("y2", value => this.config.yScale(value))
            .attr("x2", this.config.width)
            .attr("stroke", "lightgray")
            .attr("stroke-opacity", 0.7)

		return lines
	}

	private update = (updatedHorizontalLines: Selection<any, any, any, any>): Selection<any, any, any, any> => {
		return updatedHorizontalLines
            .attr("y1", value => this.config.yScale(value))
            .attr("y2", value => this.config.yScale(value))
            .attr("x2", this.config.width)
	}
}
