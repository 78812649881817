import React, { useState } from 'react';
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { MobergFontSize, MobergFontFamily } from '../../Moberg';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    )
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

function MUITabs({ tabs = [] }) {
    const [value, setValue] = useState(0)

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const tabStyles = {
        textTransform: "none",
        fontSize: MobergFontSize.REGULAR,
        
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange}>
                    {tabs.map((tab, index) => {
                        return <Tab label={tab.name} {...a11yProps(index)} sx={{ ...tabStyles }} disableRipple />
                    })}
                </Tabs>
            </Box>

            {tabs.map((tab, index) => {
                return <CustomTabPanel value={value} index={index}>
                    {tab.content}
                </CustomTabPanel>
            })}
        </Box>
    )
}

export default MUITabs