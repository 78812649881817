import { ModalFooter } from "../../../../Constants/StyledComponents";
import ModalHeader from "../../../../Components/ModalHeader/ModalHeader";
import { useModalProvider } from "../../../../Providers/ModalProvider";
import loadingSvg from "../../../Whole/smartneuro.svg";
import { useEndpointProvider } from "../../../../Providers/EndpointProvider";
import { useEffect, useState } from "react";
import { Scrollbar } from "../../../../Constants/StyledComponents";
import ModalFooterButton, { buttonColor } from "../../../../Components/ModalFooterButton/ModalFooterButton";
import { useBackendLinksProvider } from "../../../../Providers/BackendLinksProvider"

const LogFileModal = ({ body }) => {
    const { close } = useModalProvider()
    const endpointProvider = useEndpointProvider()
    const [fileContents, setFileContents] = useState('')
    const { LINKS } = useBackendLinksProvider()

    useEffect(() => {
        endpointProvider.post(LINKS.DATA.UPLOAD.GET_LOG_FILES, body).then(data => {
            setFileContents(data)
        }).catch(() => {
            setFileContents('No Logs Available')
        })
    }, [])

    return (
        <div style={{ height: "670px", background: "#FFFFFF", overflowX: "hidden", display: 'flex', flexDirection: 'column' }}>
            <ModalHeader headerText="Log File Preview" />

            <Scrollbar
                style={{ marginBottom: "15px", marginTop: "25px", padding: "10px", marginLeft: "30px", marginRight: "30px", height: "500px", maxWidth: '850px', minWidth: '850px', boxShadow: "0 2px 8px rgba(0,0,0,0.25)", background: "#FFFFFF", overflowY: "scroll" }}
            >
                {fileContents.length > 0 ?
                    <pre style={{ whiteSpace: 'pre-wrap', margin: 0, fontSize: '1em' }}>
                        {fileContents}
                    </pre>
                    :
                    <div>
                        <div style={{ color: 'black', display: "block", textAlign: "center", marginTop: "50px" }}>
                            <img src={loadingSvg} alt="loading" style={{ width: "300px" }} />
                            <h2 style={{ fontFamily: "Montserrat", fontStyle: "normal", fontWeight: "700", fontSize: "25px", marginTop: "10px" }}>Loading...</h2>
                        </div>
                    </div>
                }
            </Scrollbar>

            <ModalFooter>
                <ModalFooterButton
                    buttonText="Close"
                    onClickFxn={() => { close() }}
                    color={buttonColor.blueOutline}
                    buttonStyle={{ width: "114px", flex: "0" }}
                />

            </ModalFooter>
        </div>
    )
}

export default LogFileModal