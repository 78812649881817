import React from "react"
import { ModalityGraphGroup } from "./Components/Visualizations/TimeSeriesGraphGroup/React/ModalityGraphGroup"
import { EEGMontageVisualization } from "./Components/Visualizations/EEGMontage/React/EEGMontage"
import { VisualizationSelector } from "./Components/Visualizations/VisualizationSelector/VisualizationSelector"
import { VisualizationPlaceholder, VisualizationPlaceholderProps } from "./Components/Visualizations/VisualizationPlaceholder/VisualizationPlaceholder"
import { ConfigureWindowModal } from "../../../../Managers/VisualizationManager/ToolBar/Modals/ConfigureWindowModal"
import { ConfigureEEGModal } from "./Components/Modals/ConfigureEEGModal"
import { EEGMontageConfigJSON } from "./Types/EEGMontageConfig"
import { SdDetectionJSON } from "./Types/SdDetection"
import { SdDetection } from "./Components/Visualizations/SDDetection/React/SdDetection"
import { ConfigureSDDetectionModal } from "./Components/Modals/ConfigureSdDetectionModal"
import { ModalityGraphGroupConfigJSON } from "./Types/ModalityGraphGroup"
import { Histogram } from "./Components/Visualizations/Histogram/React/Histogram"
import { HistogramJSON } from "./Types/Histogram"
import { getDefaultVisualizationProps } from "./Atoms/Visualizations"
import { PersystEEGWindowJSON, PersystTrendsWindowJSON } from "./Types/PersystWindow"
import { PersystEEGWindow } from "./Components/Visualizations/PersystEEG/React/PersystEEGVisualization"
import { ConfigurePersystTrendsModal } from "./Components/Visualizations/PersystTrends/React/ConfigurePersystTrendsModal"
import { ConfigurePersystEEGModal } from "./Components/Visualizations/PersystEEG/React/ConfigurePersystEEGModal"
import { PersystTrendsWindow } from "./Components/Visualizations/PersystTrends/React/PersystTrendsVisualization"
import { ConfigureHistogramModal } from "./Components/Modals/ConfigureHistogramModal"
import { ScatterPlot } from "./Components/Visualizations/ScatterPlot/React/ScatterPlot"
import { ScatterPlotJSON } from "./Types/ScatterPlot"
import { ConfigureScatterPlotModal } from "./Components/Modals/ConfigureScatterPlotModal"
import { CPPOptPlot } from "./Components/Visualizations/CPPOpt/React/CPPOptPlot"
import { CPPOptPlotJSON } from "./Types/CPPOptPlot"
import { CPPOptPlotConfigureModal } from "./Components/Visualizations/CPPOpt/React/CPPOptPlotConfigurationModal"

export enum VisualizationComponent {
	CPPOPT_PLOT = "cppopt_plot",
	TIME_SERIES_GROUP = "time_series_group",
	EEG_MONTAGE = "eeg_montage",
	HISTOGRAM = "histogram",
	PERSYST_TRENDS = "persyst_trends",
	PERSYST_EEG = "persyst_eeg",
	PLACEHOLDER = "placeholder",
	SD_DETECTION = "sd_detection",
	VISUALIZATION_SELECTOR = "visualization_selector",
	SCATTER_PLOT = 'scatter_plot'
}

export function createComponent(componentType: string, windowId: string, props?: {}) {
	if (props === undefined) {
		props = {}
	}

	props = { ...getDefaultVisualizationProps(componentType), ...props }

	switch (componentType?.toLowerCase()) {
		case VisualizationComponent.TIME_SERIES_GROUP:
			return <ModalityGraphGroup json={props as ModalityGraphGroupConfigJSON} area={windowId} />
		case VisualizationComponent.EEG_MONTAGE:
			return <EEGMontageVisualization json={props as EEGMontageConfigJSON} area={windowId} />
		case VisualizationComponent.PLACEHOLDER:
			return <VisualizationPlaceholder {...(props as VisualizationPlaceholderProps)} />
		case VisualizationComponent.VISUALIZATION_SELECTOR:
			return <VisualizationSelector windowId={windowId} />
		case VisualizationComponent.SD_DETECTION:
			return <SdDetection json={props as SdDetectionJSON} windowId={windowId} />
		case VisualizationComponent.HISTOGRAM:
			return <Histogram json={props as HistogramJSON} windowId={windowId} />
		case VisualizationComponent.PERSYST_TRENDS:
			return <PersystTrendsWindow json={props as PersystTrendsWindowJSON} windowId={windowId} />
		case VisualizationComponent.PERSYST_EEG:
			return <PersystEEGWindow json={props as PersystEEGWindowJSON} windowId={windowId} />
		case VisualizationComponent.SCATTER_PLOT:
			return <ScatterPlot json={props as ScatterPlotJSON} windowId={windowId} />
		case VisualizationComponent.CPPOPT_PLOT:
			return <CPPOptPlot json={props as CPPOptPlotJSON} windowId={windowId} />
		default:
			return null
	}
}

export function getVisualizationEditor(componentId: string, layoutId: string, windowId: string) {
	switch (componentId) {
		case VisualizationComponent.TIME_SERIES_GROUP:
			return <ConfigureWindowModal style={{ minWidth: "800px", maxWidth: "1000px" }} layoutId={layoutId} windowId={windowId} />
		case VisualizationComponent.EEG_MONTAGE:
			return <ConfigureEEGModal layoutId={layoutId} windowId={windowId} />
		case VisualizationComponent.SD_DETECTION:
			return <ConfigureSDDetectionModal layoutId={layoutId} windowId={windowId} />
		case VisualizationComponent.PERSYST_TRENDS:
			return <ConfigurePersystTrendsModal layoutId={layoutId} windowId={windowId} />
		case VisualizationComponent.PERSYST_EEG:
			return <ConfigurePersystEEGModal layoutId={layoutId} windowId={windowId} />
		case VisualizationComponent.HISTOGRAM:
			return <ConfigureHistogramModal layoutId={layoutId} windowId={windowId} />
		case VisualizationComponent.SCATTER_PLOT:
			return <ConfigureScatterPlotModal layoutId={layoutId} windowId={windowId} />
		case VisualizationComponent.CPPOPT_PLOT:
			return <CPPOptPlotConfigureModal layoutId={layoutId} windowId={windowId} />
		default:
			throw new Error("Couldn't find an editor for " + componentId)
	}
}
