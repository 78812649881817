import React from 'react'
import styled from "styled-components";
import { DEFAULTS } from "../../Constants/Formatting";

/**
 * Function used to make a default input
 * @param {String} type - A string representing the type of input. Examples include text (by default) and number
 * @param {String} placeholder - A string value representing the placeholder of the input box
 * @param {Expression} onChange - An expression that will run when the user fills in the input field
 * @param {Variable} default_value - A variable representing the default value of the input
 * @param {String} width - A string value representing the width of the input box
 * @param {String} height - A string value representing the height of the input box
 * @param {String} font_size - A string value representing the font size of the input box
 */
function Input({type="text", placeholder="", onChange, default_value="", width="auto", height="auto", step=0.1, font_size=DEFAULTS.TEXT_SIZE, style={
	border: DEFAULTS.BORDER,
	fontSize: font_size,
	width: width,
	padding: "0.65em",
	height: height,
	outline: "none"
}}) {

	// JSX Object containing the input
	return (
	<>
		<input style={style}
		type={type} step={type === 'number' ? step : undefined} placeholder={placeholder} onChange={onChange} defaultValue={default_value}/>
	</>
	)
}


const LoginInput = ({ type, placeholder, onChange, disabled, value, id, onCopy, onPaste }) => {
  if (id !== undefined){
    return (
      <Container>
        <StyledInput id={id}
          disabled={disabled}
          placeholder={placeholder && placeholder}
          type={type ? type : "text"}
          onChange={onChange}
          value={value}
          required
          onCopy={onCopy}
          onPaste={onPaste}
        />
      </Container>
    );
  }
  return (
    <Container>
      <StyledInput disabled={disabled}
        placeholder={placeholder && placeholder}
        type={type ? type : "text"}
        onChange={onChange}
        value={value}
        required
        id={id}
        onCopy={onCopy}
        onPaste={onPaste}
      />
    </Container>
  );
};

const StyledInput = styled.input`
  width: 80%;
  border: none;
  margin: 0.5rem 0;
  background: #FFFFFF;
  border: 2px solid #CCCCCC;
  box-sizing: border-box;
  border-radius: 6px;
  width: 304px;
  height: 56px;
  margin: 8px 0 24px;
  padding: 16px 0px 16px 16px;
  border-radius: 6px;
  border: solid 1px #ababab;
  background-color: #fff;
  transition: all 0.2s ease-in;
  color: #293241;
  user-select: all;
  &:hover {
    transform: translateY(-3px);
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;




export {LoginInput};
export default Input;
