import React from 'react';
import { Switch, FormControlLabel } from '@mui/material';
import PropTypes from 'prop-types';

function MobergToggle(props) {
    const {
        checked = false,
        onChange,
        color = 'primary',
        name,
        disabled = false,
        label,
        style,
        labelPlacement = 'end', // Default value set to 'end'
    } = props;

    const containerStyle = {
        marginLeft: '10px',
        marginRight: '10px',
        ...style,
    };

    return (
        <FormControlLabel
            control={
                <Switch
                    checked={checked}
                    onChange={onChange}
                    color={color}
                    name={name}
                    disabled={disabled}
                />
            }
            label={label}
            labelPlacement={labelPlacement}
            style={containerStyle}
        />
    );
}

MobergToggle.propTypes = {
    checked: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    color: PropTypes.oneOf(['primary', 'secondary', 'default', 'error', 'info', 'success', 'warning']),
    name: PropTypes.string,
    disabled: PropTypes.bool,
    label: PropTypes.node,
    style: PropTypes.object,
    labelPlacement: PropTypes.oneOf(['end', 'start', 'top', 'bottom']),
};

export default MobergToggle;