export type SubDomainKey = keyof typeof TEMPLATE_SUBDOMAINS

// We want Intellisense for backend links. In order to do that, we have to have a type. 
// Maintaining a separate type for this massive thing is annoying and unnecessary, so we can use the object as a type.
// However, we can't use this object as a type without giving it a value first.
// So, we store template strings in the place of the subdomains and parse them later according to the environment.

export const TEMPLATE_SUBDOMAINS = {
	QUERY: "QUERY_DOMAIN",
	QUERY_PREFETCH: "QUERY_PREFETCH_DOMAIN",
	IAM: "IAM_DOMAIN",
	MESS: "MESS_DOMAIN",
	UPLOAD: "UPLOAD_DOMAIN",
}

export const LINKS = Object.freeze({
	QUERY: TEMPLATE_SUBDOMAINS.QUERY,
	QUERY_PREFETCH: TEMPLATE_SUBDOMAINS.QUERY_PREFETCH,
	LOGIN: {
		GET_TOKEN: {
			LINK: `${TEMPLATE_SUBDOMAINS.IAM}/user/get_token`,
			OUTPUT_TYPE: "json",
			REQUIRES_TOKEN: false,
		},
		RESET_PASSWORD: {
			LINK: `${TEMPLATE_SUBDOMAINS.MESS}/account/reset_password`,
			OUTPUT_TYPE: "text",
			REQUIRES_TOKEN: false,
		},
		CREATE_USER: {
			LINK: `${TEMPLATE_SUBDOMAINS.IAM}/user/create_user`,
			OUTPUT_TYPE: "text",
			REQUIRES_TOKEN: true,
		},
		DELETE_USER: {
			LINK: `${TEMPLATE_SUBDOMAINS.IAM}/user/delete_user`,
			OUTPUT_TYPE: "text",
			REQUIRES_TOKEN: true,
		},
		CHANGE_PASSWORD: {
			LINK: `${TEMPLATE_SUBDOMAINS.IAM}/user/change_password`,
			OUTPUT_TYPE: "text",
			REQUIRES_TOKEN: true,
		},
		CHECK_ENV: {
			LINK: `${TEMPLATE_SUBDOMAINS.UPLOAD}/v1/env/check_env`,
			OUTPUT_TYPE: "json",
			REQUIRES_TOKEN: true,
		},
		GET_WORKSPACE_ENV: {
			LINK: `${TEMPLATE_SUBDOMAINS.IAM}/v1/workspace/get_workspace_env`,
			OUTPUT_TYPE: "json",
			REQUIRES_TOKEN: true,
		},
		OAUTH_LOGIN:{
			LINK: `${TEMPLATE_SUBDOMAINS.IAM}/v1/user/oauth_login`,
			OUTPUT_TYPE: "json",
			REQUIRES_TOKEN: false
		}
	},
	DATA: {
		TRIALS: {
			GET_MODALITIES_UNION: {
				LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/patient/get_modalities_union`,
				OUTPUT_TYPE: "json",
				REQUIRES_TOKEN: true,
			},
			GET_MODALITIES_UNION_V2: {
				LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/v1/patient/get_modalities_union`,
				OUTPUT_TYPE: "json",
				REQUIRES_TOKEN: true,
			},
			GET_MEDICATION_UNION: {
				LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/patient/get_medication_union`,
				OUTPUT_TYPE: "json",
				REQUIRES_TOKEN: true,
			},
			GET_MODALITIES_INTERSECTION: {
				LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/patient/get_modalities_intersection`,
				OUTPUT_TYPE: "json",
				REQUIRES_TOKEN: true,
			},
			GET_MEDICATION_INTERSECTION: {
				LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/patient/get_medication_intersection`,
				OUTPUT_TYPE: "json",
				REQUIRES_TOKEN: true,
			},
		},
		PROFILING: {
			GET_PATIENT_FILE_TYPE: {
				LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/patient/get_patient_file_type`,
				OUTPUT_TYPE: "json",
				REQUIRES_TOKEN: true,
			},
			GET_PATIENT_FILE_START_END_TIMESTAMPS: {
				LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/patient/get_patient_file_start_end_timestamps`,
				OUTPUT_TYPE: "json",
				REQUIRES_TOKEN: true,
			},
			GET_PATIENT_FILE_DURATION: {
				LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/patient/get_patient_file_duration`,
				OUTPUT_TYPE: "json",
				REQUIRES_TOKEN: true,
			},
			GET_ANNOTATION_GROUPS: {
				LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/v1/annotation/get_annotation_groups`,
				OUTPUT_TYPE: "json",
				REQUIRES_TOKEN: true,
			},
			DOWNLOAD_HDF5_FILE: {
				LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/hdf5/download_hdf5_file`,
				OUTPUT_TYPE: "file",
				REQUIRES_TOKEN: true,
			},
			CHECK_HDF5_FILE: {
				LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/hdf5/check_hdf5_file`,
				OUTPUT_TYPE: "json",
				REQUIRES_TOKEN: true,
			},
			GET_PATIENTS: {
				LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/v1/patient/get_patients`,
				OUTPUT_TYPE: "json",
				REQUIRES_TOKEN: true,
			},
			GET_PATIENTS_VISUALIZE: {
				LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/patient/get_patients_visualize`,
				OUTPUT_TYPE: "json",
				REQUIRES_TOKEN: true,
			},
			LOAD_EMAIL_FROM_TOKEN: {
				LINK: `${TEMPLATE_SUBDOMAINS.IAM}/user/load_email_from_token`,
				OUTPUT_TYPE: "json",
				REQUIRES_TOKEN: true,
			},
			GET_PATIENT_GROUPS: {
				LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/group/get_patient_groups`,
				OUTPUT_TYPE: "json",
				REQUIRES_TOKEN: true,
			},
			MODIFY_PATIENT_GROUP: {
				LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/group/create_patient_group`,
				OUTPUT_TYPE: "text",
				REQUIRES_TOKEN: true,
			},
			DELETE_PATIENT_GROUP: {
				LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/group/delete_patient_group`,
				OUTPUT_TYPE: "text",
				REQUIRES_TOKEN: true,
			},
			ADD_PATIENT_TO_GROUP: {
				LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/group/add_patient_to_group`,
				OUTPUT_TYPE: "text",
				REQUIRES_TOKEN: true,
			},
			REMOVE_PATIENT_FROM_GROUP: {
				LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/group/remove_patient_from_group`,
				OUTPUT_TYPE: "text",
				REQUIRES_TOKEN: true,
			},
			CREATE_ANNOTATION_GROUP: {
				LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/v1/annotation/create_annotation_group`,
				OUTPUT_TYPE: "text",
				REQUIRES_TOKEN: true,
			},
			RENAME_ANNOTATION_GROUP: {
				LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/v1/annotation/rename_annotation_group`,
				OUTPUT_TYPE: "text",
				REQUIRES_TOKEN: true,
			},
			DELETE_ANNOTATION_GROUP: {
				LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/annotation/delete_annotation_group`,
				OUTPUT_TYPE: "text",
				REQUIRES_TOKEN: true,
			},
			DELETE_ANNOTATION_GROUPS: {
				LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/v1/annotation/delete_annotation_groups`,
				OUTPUT_TYPE: "text",
				REQUIRES_TOKEN: true,
			},
			CLONE_ANNOTATION_GROUP: {
				LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/annotation/clone_annotation_group`,
				OUTPUT_TYPE: "text",
				REQUIRES_TOKEN: true,
			},
			CLONE_ANNOTATION: {
				LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/annotation/clone_annotation`,
				OUTPUT_TYPE: "text",
				REQUIRES_TOKEN: true,
			},
			EXPORT_ANNOTATIONS: {
				LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/annotation/export_annotations`,
				OUTPUT_TYPE: "file",
				REQUIRES_TOKEN: true,
			},
			GET_ANNOTATIONS: {
				LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/v1/annotation/get_annotations`,
				OUTPUT_TYPE: "json",
				REQUIRES_TOKEN: true,
			},
			DELETE_ANNOTATIONS: {
				LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/v1/annotation/delete_annotations`,
				OUTPUT_TYPE: "text",
				REQUIRES_TOKEN: true,
			},
			ADD_ANNOTATION: {
				LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/v1/annotation/add_annotation`,
				OUTPUT_TYPE: "text",
				REQUIRES_TOKEN: true,
			},
			MODIFY_ANNOTATION: {
				LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/v1/annotation/modify_annotation`,
				OUTPUT_TYPE: "text",
				REQUIRES_TOKEN: true,
			},
			GET_LAYOUTS: {
				LINK: `${TEMPLATE_SUBDOMAINS.IAM}/v1/layout/get_layouts`,
				OUTPUT_TYPE: "json",
				REQUIRES_TOKEN: true,
			},
			CREATE_LAYOUT: {
				LINK: `${TEMPLATE_SUBDOMAINS.IAM}/v1/layout/create_layout`,
				OUTPUT_TYPE: "json",
				REQUIRES_TOKEN: true,
			},
			DELETE_LAYOUT: {
				LINK: `${TEMPLATE_SUBDOMAINS.IAM}/v1/layout/delete_layout`,
				OUTPUT_TYPE: "json",
				REQUIRES_TOKEN: true,
			},
			MODIFY_LAYOUT: {
				LINK: `${TEMPLATE_SUBDOMAINS.IAM}/v1/layout/modify_layout`,
				OUTPUT_TYPE: "json",
				REQUIRES_TOKEN: true,
			},
			RENAME_LAYOUT: {
				LINK: `${TEMPLATE_SUBDOMAINS.IAM}/v1/layout/modify_layout`,
				OUTPUT_TYPE: "json",
				REQUIRES_TOKEN: true,
			},
			GET_PATIENT: {
				LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/v1/patient/get_patient`,
				OUTPUT_TYPE: "json",
				REQUIRES_TOKEN: true,
			},
			PERSYST: {
				TREND_PANELS: {
					LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/persyst/persyst_api_panels`,
					OUTPUT_TYPE: "json",
					REQUIRES_TOKEN: true,
				},
				EEG_MONTAGES: {
					LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/persyst/all_montage_definitions`,
					OUTPUT_TYPE: "json",
					REQUIRES_TOKEN: true,
				},
			},
			GET_PATIENTS_FORM_DIRECTORY: {
				LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/v1/form/get_patient_forms`,
				OUTPUT_TYPE: "json",
				REQUIRES_TOKEN: true,
			},
			GET_PATIENTS_MODAL: {
				LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/v1/form/get_patient_modal`,
				OUTPUT_TYPE: "json",
				REQUIRES_TOKEN: true,
			},
		},
		UPLOAD: {
			GET_PROJECT_USER_SITES: {
				LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/v1/site/get_project_user_sites`,
				OUTPUT_TYPE: "json",
				REQUIRES_TOKEN: true,
			},
			GET_USER_PROJECTS: {
				LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/v1/project/get_user_projects`,
				OUTPUT_TYPE: "json",
				REQUIRES_TOKEN: true,
			},
			GET_PROJECT_PATIENTS_BY_SITE: {
				LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/v1/patient/get_project_patients_by_site`,
				OUTPUT_TYPE: "json",
				REQUIRES_TOKEN: true,
			},
			EXPORT_UPLOADS: {
				LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/upload/export_uploads`,
				OUTPUT_TYPE: "file",
				REQUIRES_TOKEN: true,
			},
			START_UPLOAD: {
				LINK: `${TEMPLATE_SUBDOMAINS.UPLOAD}/v1/upload/start_upload`,
				OUTPUT_TYPE: "json",
				REQUIRES_TOKEN: true,
			},
			FINISH_UPLOAD: {
				LINK: `${TEMPLATE_SUBDOMAINS.UPLOAD}/v1/upload/finish_upload`,
				OUTPUT_TYPE: "json",
				REQUIRES_TOKEN: true,
			},
			CREATE_UPLOAD: {
				LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/v1/upload/create_upload`,
				OUTPUT_TYPE: "json",
				REQUIRES_TOKEN: true,
			},
			CREATE_FILE_MULTIPART_UPLOAD: {
				LINK: `${TEMPLATE_SUBDOMAINS.UPLOAD}/upload/create_file_multipart_upload`,
				OUTPUT_TYPE: "json",
				REQUIRES_TOKEN: true,
			},
			MODIFY_UPLOAD: {
				LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/v1/upload/modify_upload`,
				OUTPUT_TYPE: "json",
				REQUIRES_TOKEN: true,
			},
			CANCEL_UPLOAD: {
				LINK: `${TEMPLATE_SUBDOMAINS.UPLOAD}/upload/cancel_upload`,
				OUTPUT_TYPE: "text",
				REQUIRES_TOKEN: true,
			},
			DELETE_CANCELED_UPLOAD: {
				LINK: `${TEMPLATE_SUBDOMAINS.UPLOAD}/v1/upload/delete_canceled_upload`,
				OUTPUT_TYPE: "text",
				REQUIRES_TOKEN: true,
			},
			UPDATE_MD5_FORM: {
				LINK: `${TEMPLATE_SUBDOMAINS.UPLOAD}/upload/update_md5_form`,
				OUTPUT_TYPE: "json",
				REQUIRES_TOKEN: true,
			},
			DELETE_UPLOAD: {
				LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/upload/delete_upload`,
				OUTPUT_TYPE: "json",
				REQUIRES_TOKEN: true,
			},
			GET_DIRECTORY_STRUCTURE: {
				LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/patient/get_directory_structure`,
				OUTPUT_TYPE: "json",
				REQUIRES_TOKEN: true,
			},
			CREATE_PATIENT_DIRS: {
				LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/patient/create_patient_dirs`,
				OUTPUT_TYPE: "json",
				REQUIRES_TOKEN: true,
			},
			GET_EXISTING_PATIENTS: {
				LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/patient/get_existing_patients`,
				OUTPUT_TYPE: "json",
				REQUIRES_TOKEN: true,
			},
			GET_EXISTING_DATA_FOR_DATATYPE: {
				LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/v1/patient/get_existing_data_for_datatype`,
				OUTPUT_TYPE: "json",
				REQUIRES_TOKEN: true,
			},
			GET_PATIENT_DATA_OBJECTS: {
				LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/v1/patient/get_patient_data_objects`,
				OUTPUT_TYPE: "json",
				REQUIRES_TOKEN: true,
			},
			GET_PATIENT_FILE_MODALITY_TIMES: {
				LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/patient/get_patient_file_modality_times`,
				OUTPUT_TYPE: "json",
				REQUIRES_TOKEN: true,
			},
			GET_MODALITY_TIME_BY_PATIENT: {
				LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/v1/patient/get_modality_time_by_patient`,
				OUTPUT_TYPE: "json",
				REQUIRES_TOKEN: true,
			},
			GET_PATIENT_FILE_MODALITY_PERCENTAGES: {
				LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/patient/get_patient_file_modality_percentages`,
				OUTPUT_TYPE: "json",
				REQUIRES_TOKEN: true,
			},
			GET_PATIENT_FILE_MODALITY_START_END_TIMES: {
				LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/patient/get_patient_modality_start_end_times`,
				OUTPUT_TYPE: "json",
				REQUIRES_TOKEN: true,
			},
			GET_PATIENT_FILE_TIMES: {
				LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/patient/get_patient_file_times`,
				OUTPUT_TYPE: "json",
				REQUIRES_TOKEN: true,
			},
			GET_MODALITIES_UNION: {
				LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/patient/get_modalities_union`,
				OUTPUT_TYPE: "json",
				REQUIRES_TOKEN: true,
			},
			MODIFY_PATIENT: {
				LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/patient/modify_patient`,
				OUTPUT_TYPE: "json",
				REQUIRES_TOKEN: true,
			},
			GET_PATIENT_DIR_FILES: {
				LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/patient/get_patient_dir_files`,
				OUTPUT_TYPE: "json",
				REQUIRES_TOKEN: true,
			},
			COPY_FILES: {
				LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/patient/copy_files`,
				OUTPUT_TYPE: "json",
				REQUIRES_TOKEN: true,
			},
			DELETE_FILES: {
				LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/patient/delete_files`,
				OUTPUT_TYPE: "json",
				REQUIRES_TOKEN: true,
			},
			MOVE_FILES: {
				LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/patient/move_files`,
				OUTPUT_TYPE: "json",
				REQUIRES_TOKEN: true,
			},
			DELETE_TEMP_FILES: {
				LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/patient/delete_temp_files`,
				OUTPUT_TYPE: "json",
				REQUIRES_TOKEN: true,
			},
			COPY_TEMP_FILES: {
				LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/patient/copy_temp_files`,
				OUTPUT_TYPE: "json",
				REQUIRES_TOKEN: true,
			},
			MOVE_TEMP_FILES: {
				LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/patient/move_temp_files`,
				OUTPUT_TYPE: "json",
				REQUIRES_TOKEN: true,
			},
			CREATE_USER_MAPPING: {
				LINK: `${TEMPLATE_SUBDOMAINS.IAM}/mapping/create_user_mapping`,
				OUTPUT_TYPE: "json",
				REQUIRES_TOKEN: true,
			},
			SET_USER_MAPPING: {
				LINK: `${TEMPLATE_SUBDOMAINS.IAM}/mapping/set_user_mapping`,
				OUTPUT_TYPE: "text",
				REQUIRES_TOKEN: true,
			},
			DELETE_USER_MAPPING: {
				LINK: `${TEMPLATE_SUBDOMAINS.IAM}/mapping/delete_user_mapping`,
				OUTPUT_TYPE: "text",
				REQUIRES_TOKEN: true,
			},
			GET_USER_MAPPINGS: {
				LINK: `${TEMPLATE_SUBDOMAINS.IAM}/mapping/get_user_mappings`,
				OUTPUT_TYPE: "json",
				REQUIRES_TOKEN: true,
			},
			MODIFY_USER_MAPPING: {
				LINK: `${TEMPLATE_SUBDOMAINS.IAM}/mapping/modify_user_mapping`,
				OUTPUT_TYPE: "text",
				REQUIRES_TOKEN: true,
			},
			CHECK_PATIENT_ID: {
				LINK: `${TEMPLATE_SUBDOMAINS.UPLOAD}/upload/check_patient_id`,
				OUTPUT_TYPE: "json",
				REQUIRES_TOKEN: true,
			},
			CHECK_PATIENT_IDENTIFIER: {
				LINK: `${TEMPLATE_SUBDOMAINS.UPLOAD}/v1/upload/check_patient_identifier`,
				OUTPUT_TYPE: "json",
				REQUIRES_TOKEN: true,
			},
			GET_UPLOAD_MESSAGES: {
				LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/upload/get_upload_messages`,
				OUTPUT_TYPE: "json",
				REQUIRES_TOKEN: true,
			},
			GET_UPLOAD_PROGRESS: {
				LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/upload/get_upload_progress`,
				OUTPUT_TYPE: "json",
				REQUIRES_TOKEN: true,
			},
			GET_PRESIGNED_URLS: {
				LINK: `${TEMPLATE_SUBDOMAINS.UPLOAD}/upload/generate_presigned_urls`,
				OUTPUT_TYPE: "json",
				REQUIRES_TOKEN: true,
			},
			PUSH_TO_CLOUD: {
				LINK: `${TEMPLATE_SUBDOMAINS.UPLOAD}/upload/push_to_cloud`,
				OUTPUT_TYPE: "json",
				REQUIRES_TOKEN: true,
			},
			GET_PATIENT_UPLOADS: {
				LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/v1/upload/get_patient_uploads`,
				OUTPUT_TYPE: "json",
				REQUIRES_TOKEN: true,
			},
			CHECK_MD5_HASH: {
				LINK: `${TEMPLATE_SUBDOMAINS.UPLOAD}/v1/upload/check_md5_hash`,
				OUTPUT_TYPE: "json",
				REQUIRES_TOKEN: true,
			},
			CHECK_CANCEL_STATUS: {
				LINK: `${TEMPLATE_SUBDOMAINS.UPLOAD}/v1/upload/check_cancel_status`,
				OUTPUT_TYPE: "json",
				REQUIRES_TOKEN: true,
			},
			UPLOAD_MAPPING: {
				LINK: `${TEMPLATE_SUBDOMAINS.UPLOAD}/upload/upload_mapping`,
				OUTPUT_TYPE: "json",
				REQUIRES_TOKEN: true,
			},
			GET_LOG_FILES: {
				LINK: `${TEMPLATE_SUBDOMAINS.UPLOAD}/upload/get_log_files`,
				OUTPUT_TYPE: "text",
				REQUIRES_TOKEN: true,
			},
			EXPORT_LOG: {
				LINK: `${TEMPLATE_SUBDOMAINS.UPLOAD}/upload/download_log_file`,
				OUTPUT_TYPE: "json",
				REQUIRES_TOKEN: true,
			},
			DOWNLOAD_WEBINAR_FILES: {
				LINK: `${TEMPLATE_SUBDOMAINS.UPLOAD}/upload/download_webinar_files`,
				OUTPUT_TYPE: "file",
				REQUIRES_TOKEN: true,
			},
			CHECK_CNS_FILES: {
				LINK: `${TEMPLATE_SUBDOMAINS.UPLOAD}/upload/check_cns_files`,
				OUTPUT_TYPE: "json",
				REQUIRES_TOKEN: true,
			},
			UPDATE_MD5_IV: {
				LINK: `${TEMPLATE_SUBDOMAINS.UPLOAD}/upload/update_md5_iv`,
				OUTPUT_TYPE: "json",
				REQUIRES_TOKEN: true,
			},
			UPLOAD_FAILED_NOTIFICATION: {
				LINK: `${TEMPLATE_SUBDOMAINS.MESS}/upload/upload_failed_notification`,
				OUTPUT_TYPE: "text",
				REQUIRES_TOKEN: true,
			},
			GET_FILE_CONTENT: {
				LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/v1/patient/get_file_content`,
				OUTPUT_TYPE: "json",
				REQUIRES_TOKEN: true,
			},
			DOWNLOAD_FILE: {
				LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/v1/patient/download_file`,
				OUTPUT_TYPE: "file",
				REQUIRES_TOKEN: true,
			},
			GET_CSV_COLUMNS: {
				LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/v1/patient/get_csv_columns`,
				OUTPUT_TYPE: "json",
				REQUIRES_TOKEN: true,
			},
		},
	},
	VISUALIZE: {
		EEG: {
			GET_MONTAGE_MAPPING: {
				LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/v1/eeg_montage/get_montage_mapping`,
				OUTPUT_TYPE: "json",
				REQUIRES_TOKEN: true,
			},
			GET_EEG_MONTAGES: {
				LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/v1/eeg_montage/get_eeg_montages`,
				OUTPUT_TYPE: "json",
				REQUIRES_TOKEN: true,
			},
			CREATE_EEG_MONTAGE: {
				LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/v1/eeg_montage/create_eeg_montage`,
				OUTPUT_TYPE: "json",
				REQUIRES_TOKEN: true,
			},
			DELETE_EEG_MONTAGE: {
				LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/v1/eeg_montage/delete_eeg_montage`,
				OUTPUT_TYPE: "text",
				REQUIRES_TOKEN: true,
			},
			MODIFY_EEG_MONTAGE: {
				LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/v1/eeg_montage/modify_eeg_montage`,
				OUTPUT_TYPE: "text",
				REQUIRES_TOKEN: true,
			},
		},
		GET_ADMISSION_INFO: {
			LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/v1/patient/get_admission_info`,
			OUTPUT_TYPE: "json",
			REQUIRES_TOKEN: true
		},
	},
	ADMIN: {
		USER_MANAGEMENT: {
			USERS: {
				GET_USERS: {
					LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/v1/user/get_workspace_users`,
					OUTPUT_TYPE: "json",
					REQUIRES_TOKEN: true,
				},
				GET_USERS_BY_PROJECT: {
					LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/v1/user/get_workspace_users_by_project`,
					OUTPUT_TYPE: "json",
					REQUIRES_TOKEN: true,
				},
				GET_USERS_BY_SITE: {
					LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/v1/user/get_workspace_users_by_site`,
					OUTPUT_TYPE: "json",
					REQUIRES_TOKEN: true,
				},
				DELETE_USERS: {
					LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/v1/user/delete_users`,
					OUTPUT_TYPE: "text",
					REQUIRES_TOKEN: true,
				},
				GET_USERS_FILTER: {
					LINK: `${TEMPLATE_SUBDOMAINS.IAM}/user/get_users_filter`,
					OUTPUT_TYPE: "json",
					REQUIRES_TOKEN: true,
				},
				EXPORT_USERS: {
					LINK: `${TEMPLATE_SUBDOMAINS.IAM}/user/export_users`,
					OUTPUT_TYPE: "file",
					REQUIRES_TOKEN: true,
				},
				INVITE_USER: {
					LINK: `${TEMPLATE_SUBDOMAINS.IAM}/study/invite_user`,
					OUTPUT_TYPE: "text",
					REQUIRES_TOKEN: true,
				},
				INVITE_USERS: {
					LINK: `${TEMPLATE_SUBDOMAINS.IAM}/v1/user/invite_users`,
					OUTPUT_TYPE: "text",
					REQUIRES_TOKEN: true,
				},
				MODIFY_USER: {
					LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/v1/user/modify_workspace_user`,
					OUTPUT_TYPE: "text",
					REQUIRES_TOKEN: true,
				},
				REMOVE_USER: {
					LINK: `${TEMPLATE_SUBDOMAINS.IAM}/v1/study/remove_user_study`,
					OUTPUT_TYPE: "text",
					REQUIRES_TOKEN: true,
				},
				REMOVE_USERS: {
					LINK: `${TEMPLATE_SUBDOMAINS.IAM}/v1/study/remove_users_from_study`,
					OUTPUT_TYPE: "text",
					REQUIRES_TOKEN: true,
				},
				SET_USER_SITES: {
					LINK: `${TEMPLATE_SUBDOMAINS.IAM}/user/set_user_sites`,
					OUTPUT_TYPE: "text",
					REQUIRES_TOKEN: true,
				},
				SET_USER_ROLES: {
					LINK: `${TEMPLATE_SUBDOMAINS.IAM}/user/set_user_roles`,
					OUTPUT_TYPE: "text",
					REQUIRES_TOKEN: true,
				},
				GET_ROLES: {
					LINK: `${TEMPLATE_SUBDOMAINS.IAM}/v1/role/get_roles`,
					OUTPUT_TYPE: "json",
					REQUIRES_TOKEN: true,
				},
				GET_ROLES_BY_USER: {
					LINK: `${TEMPLATE_SUBDOMAINS.IAM}/v1/role/get_roles_by_user`,
					OUTPUT_TYPE: "json",
					REQUIRES_TOKEN: true,
				},
				GET_USER_BY_ID: {
					LINK: `${TEMPLATE_SUBDOMAINS.IAM}/user/get_user_by_id`,
					OUTPUT_TYPE: "json",
					REQUIRES_TOKEN: true,
				},
				GET_USER_BY_EMAIL: {
					LINK: `${TEMPLATE_SUBDOMAINS.IAM}/user/get_user_by_email`,
					OUTPUT_TYPE: "json",
					REQUIRES_TOKEN: true,
				},
				GET_ACTION_BY_EMAIL: {
					LINK: `${TEMPLATE_SUBDOMAINS.IAM}/user/get_action_by_email`,
					OUTPUT_TYPE: "json",
					REQUIRES_TOKEN: true,
				},
				GET_ALL_ACTIONS: {
					LINK: `${TEMPLATE_SUBDOMAINS.IAM}/v1/user/get_all_users_actions`,
					OUTPUT_TYPE: "json",
					REQUIRES_TOKEN: true,
				},
				GET_ASSIGNED_USER_ROLES: {
					LINK: `${TEMPLATE_SUBDOMAINS.IAM}/user/get_assigned_user_roles`,
					OUTPUT_TYPE: "json",
					REQUIRES_TOKEN: true,
				},
				GET_ASSIGNED_USER_SITES: {
					LINK: `${TEMPLATE_SUBDOMAINS.IAM}/user/get_assigned_user_sites`,
					OUTPUT_TYPE: "json",
					REQUIRES_TOKEN: true,
				},
				GET_ASSIGNED_USER_PROJECTS: {
					LINK: `${TEMPLATE_SUBDOMAINS.IAM}/user/get_assigned_user_projects`,
					OUTPUT_TYPE: "json",
					REQUIRES_TOKEN: true,
				},
				GET_TOKENS: {
					LINK: `${TEMPLATE_SUBDOMAINS.IAM}/user/get_tokens`,
					OUTPUT_TYPE: "json",
					REQUIRES_TOKEN: true,
				},
				GET_DOMAINS: {
					LINK: `${TEMPLATE_SUBDOMAINS.IAM}/v1/domain/get_domains`,
					OUTPUT_TYPE: "json",
					REQUIRES_TOKEN: true,
				},
				ADD_DOMAIN: {
					LINK: `${TEMPLATE_SUBDOMAINS.IAM}/v1/domain/add_domain`,
					OUTPUT_TYPE: "text",
					REQUIRES_TOKEN: true,
				},
				REMOVE_DOMAINS: {
					LINK: `${TEMPLATE_SUBDOMAINS.IAM}/v1/domain/remove_domains`,
					OUTPUT_TYPE: "text",
					REQUIRES_TOKEN: true,
				},
				GET_RESTRICT_EMAIL_DOMAINS: {
					LINK: `${TEMPLATE_SUBDOMAINS.IAM}/study/get_restrict_email_domains`,
					OUTPUT_TYPE: "json",
					REQUIRES_TOKEN: true,
				},
				SET_RESTRICT_EMAIL_DOMAINS: {
					LINK: `${TEMPLATE_SUBDOMAINS.IAM}/v1/workspace/set_restrict_email_domains`,
					OUTPUT_TYPE: "json",
					REQUIRES_TOKEN: true,
				},
				GET_DOMAIN_RESTRICTION: {
					LINK: `${TEMPLATE_SUBDOMAINS.IAM}/v1/workspace/get_domain_restriction`,
					OUTPUT_TYPE: "json",
					REQUIRES_TOKEN: true,
				},
			},
			ROLES_AND_PERMISSIONS: {
				GET_ROLES: {
					LINK: `${TEMPLATE_SUBDOMAINS.IAM}/role/get_roles`,
					OUTPUT_TYPE: "json",
					REQUIRES_TOKEN: true,
				},
				GET_PERMISSIONS: {
					LINK: `${TEMPLATE_SUBDOMAINS.IAM}/v1/role/get_permissions`,
					OUTPUT_TYPE: "json",
					REQUIRES_TOKEN: true,
				},
				GET_ROLE_PERMISSIONS: {
					LINK: `${TEMPLATE_SUBDOMAINS.IAM}/role/get_role_permissions`,
					OUTPUT_TYPE: "json",
					REQUIRES_TOKEN: true,
				},
				CREATE_ROLE: {
					LINK: `${TEMPLATE_SUBDOMAINS.IAM}/v1/role/create_role`,
					OUTPUT_TYPE: "text",
					REQUIRES_TOKEN: true,
				},
				DELETE_ROLES: {
					LINK: `${TEMPLATE_SUBDOMAINS.IAM}/v1/role/delete_roles`,
					OUTPUT_TYPE: "text",
					REQUIRES_TOKEN: true,
				},
				MODIFY_ROLE: {
					LINK: `${TEMPLATE_SUBDOMAINS.IAM}/v1/role/modify_role`,
					OUTPUT_TYPE: "text",
					REQUIRES_TOKEN: true,
				},
				CLONE_ROLE: {
					LINK: `${TEMPLATE_SUBDOMAINS.IAM}/role/clone_role`,
					OUTPUT_TYPE: "json",
					REQUIRES_TOKEN: true,
				},
				SET_ROLE_PERMISSIONS: {
					LINK: `${TEMPLATE_SUBDOMAINS.IAM}/role/set_role_permissions`,
					OUTPUT_TYPE: "text",
					REQUIRES_TOKEN: true,
				},
				EXPORT_ROLES: {
					LINK: `${TEMPLATE_SUBDOMAINS.IAM}/role/export_roles`,
					OUTPUT_TYPE: "file",
					REQUIRES_TOKEN: true,
				},
				EXPORT_PERMISSIONS: {
					LINK: `${TEMPLATE_SUBDOMAINS.IAM}/user/export_permissions`,
					OUTPUT_TYPE: "file",
					REQUIRES_TOKEN: true,
				},
				GET_USER_ROLES: {
					LINK: `${TEMPLATE_SUBDOMAINS.IAM}/role/get_user_roles`,
					OUTPUT_TYPE: "json",
					REQUIRES_TOKEN: true,
				},
			},
		},
		WORKSPACES: {
			GET_WORKSPACES_BY_USER: {
				LINK: `${TEMPLATE_SUBDOMAINS.IAM}/v1/workspace/get_workspaces_by_user`,
				OUTPUT_TYPE: "json",
				REQUIRES_TOKEN: true,
			},
			GET_BUCKET: {
                LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/v1/bucket/get_bucket`,
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true
            },
		},
		PROJECTS: {
			GET_PROJECTS: {
				LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/v1/project/get_projects`,
				OUTPUT_TYPE: "json",
				REQUIRES_TOKEN: true,
			},
			GET_PROJECTS_BY_USER: {
				LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/v1/project/get_projects_by_user`,
				OUTPUT_TYPE: "json",
				REQUIRES_TOKEN: true,
			},
			CREATE_PROJECT: {
				LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/v1/project/create_project`,
				OUTPUT_TYPE: "text",
				REQUIRES_TOKEN: true,
				ERROR_RESPONSE_TYPE: "json",
			},
			DELETE_PROJECTS: {
				LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/v1/project/delete_projects`,
				OUTPUT_TYPE: "text",
				REQUIRES_TOKEN: true,
			},
			MODIFY_PROJECT: {
				LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/v1/project/modify_project`,
				OUTPUT_TYPE: "text",
				REQUIRES_TOKEN: true,
			},
		},
		PREFETCH: {
			GET_PREFETCH_INVENTORY: {
				LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/prefetch/get_prefetch_inventory`,
				OUTPUT_TYPE: "json",
				REQUIRES_TOKEN: true,
			},
			GET_PREFETCH_QUEUE: {
				LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/prefetch/get_prefetch_queue`,
				OUTPUT_TYPE: "json",
				REQUIRES_TOKEN: true,
			},
			UNLOAD: {
				LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/prefetch/unload`,
				OUTPUT_TYPE: "json",
				REQUIRES_TOKEN: true,
			},
		},
		SITES: {
			GET_SITES: {
				LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/v1/site/get_workspace_sites`,
				OUTPUT_TYPE: "json",
				REQUIRES_TOKEN: true,
			},
			GET_SITES_BY_USER: {
				LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/v1/site/get_workspace_sites_by_user`,
				OUTPUT_TYPE: "json",
				REQUIRES_TOKEN: true,
			},
			EXPORT_SITES: {
				LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/site/export_sites`,
				OUTPUT_TYPE: "file",
				REQUIRES_TOKEN: true,
			},
			CREATE_SITE: {
				LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/v1/site/create_workspace_site`,
				OUTPUT_TYPE: "text",
				REQUIRES_TOKEN: true,
				ERROR_RESPONSE_TYPE: "json",
			},
			DELETE_SITE: {
				LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/site/delete_site`,
				OUTPUT_TYPE: "text",
				REQUIRES_TOKEN: true,
			},
			DELETE_SITES: {
				LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/v1/site/delete_sites`,
				OUTPUT_TYPE: "text",
				REQUIRES_TOKEN: true,
			},
			MODIFY_SITE: {
				LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/v1/site/modify_workspace_site`,
				OUTPUT_TYPE: "text",
				REQUIRES_TOKEN: true,
			},
		},
		BUCKETS: {
			GET_BUCKET: {
                LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/v1/bucket/get_bucket`,
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true
            },
		}
	},
	ACCOUNT: {
		CHANGE_PASSWORD: {
			LINK: `${TEMPLATE_SUBDOMAINS.IAM}/user/change_password`,
			OUTPUT_TYPE: "text",
			REQUIRES_TOKEN: true,
		},
		CHANGE_EMAIL: {
			LINK: `${TEMPLATE_SUBDOMAINS.IAM}/user/change_email`,
			OUTPUT_TYPE: "text",
			REQUIRES_TOKEN: true,
		},
		CHECK_TOKEN: {
			LINK: `${TEMPLATE_SUBDOMAINS.IAM}/user/check_token`,
			OUTPUT_TYPE: "json",
			REQUIRES_TOKEN: true,
		},
		GET_CURRENT_USER: {
			LINK: `${TEMPLATE_SUBDOMAINS.IAM}/user/get_current_user`,
			OUTPUT_TYPE: "json",
			REQUIRES_TOKEN: true,
		},
		UPDATE_TOKEN_EXPIRE_TIME: {
			LINK: `${TEMPLATE_SUBDOMAINS.IAM}/user/update_token_expire_time`,
			OUTPUT_TYPE: "json",
			REQUIRES_TOKEN: true,
		},
		MODIFY_USER: {
			LINK: `${TEMPLATE_SUBDOMAINS.IAM}/user/modify_user`,
			OUTPUT_TYPE: "text",
			REQUIRES_TOKEN: true,
		},
		GET_KEYBOARD_SHORTCUTS: {
			LINK: `${TEMPLATE_SUBDOMAINS.IAM}/keyboard_shortcut/get_keyboard_shortcuts`,
			OUTPUT_TYPE: "json",
			REQUIRES_TOKEN: true,
		},
		UPDATE_KEYBOARD_SHORTCUTS: {
			LINK: `${TEMPLATE_SUBDOMAINS.IAM}/keyboard_shortcut/update_keyboard_shortcuts`,
			OUTPUT_TYPE: "json",
			REQUIRES_TOKEN: true,
		},
		RESET_KEYBOARD_SHORTCUTS: {
			LINK: `${TEMPLATE_SUBDOMAINS.IAM}/keyboard_shortcut/reset_keyboard_shortcuts`,
			OUTPUT_TYPE: "json",
			REQUIRES_TOKEN: true,
		},
	},
	RESOURCES: {
		WEBINARS: {
			GET_WEBINAR_FILES: {
				LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/v1/resources/webinars/get_webinar_files`,
				OUTPUT_TYPE: "json",
				REQUIRES_TOKEN: true,
			},
			GET_WEBINAR: {
				LINK: `${TEMPLATE_SUBDOMAINS.QUERY}/v1/resources/webinars/get_webinar`,
				OUTPUT_TYPE: "json",
				REQUIRES_TOKEN: true,
			},
		},
	},
	WORKSPACE: {
		GET_LOGIN_ATTEMPTS: {
			LINK: `${TEMPLATE_SUBDOMAINS.IAM}/v1/workspace/get_login_attempts`,
			OUTPUT_TYPE: 'json',
			REQUIRES_TOKEN: true
		},
	}
})
