import { useState, useEffect, useCallback, useRef, useLayoutEffect } from "react"
import { DEFAULTS } from "../../../Constants/Formatting";
import styled from "styled-components"
import "@fontsource/source-sans-pro"
import * as MdIcons from "react-icons/md"
import Checkbox from "react-custom-checkbox"
import "../../../index.css"
import { LINKS } from "../../../Constants/BackendLinks";
import 'semantic-ui-css/semantic.min.css'
import { DenseTable } from "../../../Components/DataTable/Table"
import { studyUpdates, useStudiesProvider } from "../../../Providers/StudiesProvider";
import { useEndpointProvider } from "../../../Providers/EndpointProvider";
import { siteUpdates, useSitesProvider } from "../../../Providers/SitesProvider";
import { modalityUpdates, useModalitiesProvider } from "../../../Providers/ModalitiesProvider";

const DataTrials = (props) => {
    const studiesProvider = useStudiesProvider()
    const sitesProvider = useSitesProvider()
    const endpointProvider = useEndpointProvider()
    const modalitiesProvider = useModalitiesProvider()

    // Update Providers to see if data changed remotely.
    // // Only run once, because each update makes a network call.
    useEffect(() => {
        modalitiesProvider.update(modalityUpdates.MODALITIES)
        sitesProvider.update(siteUpdates.SITES_FILTERS)
        studiesProvider.update(studyUpdates.STUDIES_FILTERS)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []) // MUST BE EMPTY. PLEASE DO NOT CHANGE IT.

    const [studyIds, setStudyIds] = useState(studiesProvider.studies.map(study => study.study_id))
    const [sites, setSites] = useState(sitesProvider.sites)
    const [modalities, setModalities] = useState(modalitiesProvider.modalities)

    const [enrollmentData, setEnrollmentData] = useState(false)
    const [enrollmentColumns, setEnrollmentColumns] = useState([])
    const [enrollmentRows, setEnrollmentRows] = useState([])
    const [pending, setPending] = useState(false);
    const [tableConfig, setTableConfig] = useState([]);
    const [tableSubheaderValues, setTableSubheaderValues] = useState([]);
    const [tableSubheaderOrder, setTableSubheaderOrder] = useState([]);
    const [tableSelected, setTableSelected] = useState("");

    const bool_obj_to_arr = obj => Object.keys(obj).filter(k => obj[k])

    const getEnrollment = useCallback((ancillary_filter, site_filter, modality_filter, npatients, modalities_return, days, category_filter) => {

        if (!studiesProvider.selectedStudyId) {
            return new Promise((reject, _) => reject())
        }

        let body = {
            study_ids: [studiesProvider.selectedStudyId, ...ancillary_filter],
            site_ids: site_filter,
            modalities: modality_filter,
            npatients,
            modalities_return,
            days,
            categories: category_filter,
        }

        return endpointProvider.post(LINKS.DATA.TRIALS.GET_ENROLLMENT, body)
        
    }, [endpointProvider, studiesProvider.selectedStudyId])

    function updateTableConfigValue (table, dict, key, value) {
        setTableConfig({
            ...tableConfig,
            [table]: {
                ...tableConfig[table],
                [dict]: {
                    ...tableConfig[table][dict],
                    [key]: value,
                },
            },
        });
    }

    function updateTimeOptionCheckboxValues(table, time_option, value) {
        if (!value) {
            document.getElementById("timeOptionDropdown").style.display =
                "none";
            setTimeOptionIcon(more);
            return;
        }
        let new_time_option = {};
        for (let time_option of Object.keys(tableConfig[tableSelected]["time_option"])) {
            new_time_option[time_option] = false;
        }

        let newTableConfig = {
            ...tableConfig,
            [table]: {
                ...tableConfig[table],
                time_option: new_time_option,
            },
        };

        setTableConfig({
            ...newTableConfig,
            [table]: {
                ...newTableConfig[table],
                time_option: {
                    ...newTableConfig[table]["time_option"],
                    [time_option]: value,
                },
            },
        });
        if (time_option === "last week" && value) {
            timeOption.style.marginLeft = "168px";
            timeOption.style.display = "none";
            setTimeOptionIcon(more);
        }
        if (time_option === "last month" && value) {
            timeOption.style.marginLeft = "177px";
            timeOption.style.display = "none";
            setTimeOptionIcon(more);
        }
    }

    useEffect(() => {
        setTableSelected(bool_obj_to_arr(tableSubheaderValues)[0])
        
    }, [tableSubheaderValues, tableSubheaderOrder])

    useEffect(() => {
        props.setNavPage(true)
    })


    function updateTableSubheaderValues(table, value) {
        if (tableSubheaderValues[table]) {
            return;
        }
        let newTableSubheaderValues = Object.assign(
            {},
            ...Object.keys(tableSubheaderValues).map((table) => {
                return { [table]: false };
            })
        );
        setTableSubheaderValues({ ...newTableSubheaderValues, [table]: value });

        if (!tableSubheaderOrder.includes(table)) {
            setTableSubheaderOrder(
                Array.prototype.concat(tableSubheaderOrder, table)
            );

            setTableConfig({ ...tableConfig, [table]: createTableConfig() });
        }
    }

    function deleteTableSubheaderValues(table) {
        if (tableSubheaderOrder.length === 1) {
            alert("Please create a new table before deleting this one.");
            return;
        }
        let indx = tableSubheaderOrder.indexOf(table);

        document.getElementById(`NewSubheader_${table}`).style.display = "none";
        document.getElementById(`TableButton_${table}`).style.display = "flex";
        document.getElementById(`NewSubheader_${table}`).value = "";

        setTableSubheaderOrder(
            Array.prototype.concat(
                tableSubheaderOrder.slice(0, indx),
                tableSubheaderOrder.slice(indx + 1)
            )
        );

        setTableSubheaderValues(
            Object.assign(
                {},
                ...Object.keys(tableSubheaderValues).map((table_, i) => {
                    if (table_ !== table) {
                        return {
                            [table_]:
                                (i === 0 &&
                                    indx !== 0 &&
                                    tableSubheaderValues[
                                    tableSubheaderOrder[indx]
                                    ]) ||
                                (i === 1 &&
                                    indx === 0 &&
                                    tableSubheaderValues[
                                    tableSubheaderOrder[indx]
                                    ]) ||
                                tableSubheaderValues[tableSubheaderOrder[i]],
                        };
                    }
                })
            )
        );
    }


    function createTableConfig () {
        const data_variables_list = ["Site", "Modalities", "Days"];
        const time_options_list = ["last week", "last month"];
        const modality_return_list = ['ICP', 'PbtO2', 'MAP', 'Temp']

        let site_filter = Object.assign(
            {},
            ...sites.map((site) => {
                return { [site.site_id]: false };
            })
        );

        let ancillary_filter = Object.assign(
            {},
            ...studyIds.map((study_id) => {
                return { [study_id]: false };
            })
        );

        let modality_filter = Object.assign(
            {},
            ...modalities.map((modality) => {
                return { [modality]: false };
            })
        );

        let modality_return = Object.assign(
            {},
            ...modalities.map((modality) => {
                return { [modality]: modality_return_list.includes(modality) };
            })
        );

        let data_variable = Object.assign(
            {},
            ...data_variables_list.map((data_variable) => {
                return { [data_variable]: false };
            })
        );
        data_variable.Site = true

        let time_option = Object.assign(
            {},
            ...time_options_list.map((time_option, i) => {
                if (i === 0) {
                    return { [time_option]: true };
                } else {
                    return { [time_option]: false };
                }
            })
        );

        return {
            site_filter: site_filter,
            ancillary_filter: ancillary_filter,
            modality_filter: modality_filter,
            modality_return: modality_return,
            data_variable: data_variable,
            time_option: time_option,
        }
    }

    // only perform these actions once
    useEffect(() => {
        if (!studyIds || !sites || !modalities) return

        let tables = ["Table 1"];

        setTableSubheaderValues(
            Object.assign(
                {},
                ...tables.map((table, i) => {
                    if (i === 0) {
                        return { [table]: true };
                    } else {
                        return { [table]: false };
                    }
                })
            )
        );
        setTableSubheaderOrder(tables);

        let table_config = Object.assign(
            {},
            ...tables.map((table, i) => {
                return {
                    [table]: createTableConfig(),
                };
            })
        );
        setTableConfig(table_config);
    }, [studyIds, sites, modalities]);



    const [newSubheaderVal, setNewSubheaderVal] = useState(0);
    const more = (
        <MdIcons.MdExpandMore
            size={23}
            style={{ margin: "-7px 0px", cursor: "pointer" }}
        />
    );
    const less = (
        <MdIcons.MdExpandLess
            size={23}
            style={{ margin: "-7px 0px", cursor: "pointer" }}
        />
    );
    const [timeOptionIcon, setTimeOptionIcon] = useState(
        <MdIcons.MdExpandMore
            size={23}
            style={{ margin: "-7px 0px", cursor: "pointer" }}
        />
    );

    var timeOption = document.getElementById("timeOptionDropdown");
    function renderTimeOptionToggle() {
        if (
            timeOption.style.display !== "none" &&
            timeOption.style.display !== ""
        ) {
            timeOption.style.display = "none";
            setTimeOptionIcon(more);
        } else {
            timeOption.style.display = "block";
            timeOption.style.position = "absolute";
            timeOption.style.zIndex = 1000;
            setTimeOptionIcon(less);
        }
    }

    const escTimeOptionClose = useCallback((event) => {
        if (event.keyCode === 27) {
            document.getElementById("timeOptionDropdown").style.display =
                "none";
            setTimeOptionIcon(more);
        }
    });

    useEffect(() => {
        document.addEventListener("keydown", escTimeOptionClose);
        return () => {
            document.removeEventListener("keydown", escTimeOptionClose);
        };
    }, [escTimeOptionClose]);

    let timeOptionRef = useRef();
    let timeOptionButtonRef = useRef();

    useEffect(() => {
        let handler = (event) => {
            if (
                !timeOptionRef.current?.contains(event.target) &&
                !timeOptionButtonRef.current?.contains(event.target)
            ) {
                document.getElementById("timeOptionDropdown").style.display =
                    "none";
                setTimeOptionIcon(more);
            }
        };

        document.addEventListener("mousedown", handler);
        return () => {
            document.removeEventListener("mousedown", handler);
        };
    });

    useEffect(() => {
        let handler = (event) => {
            if (
                !timeOptionRef.current?.contains(event.target) &&
                !timeOptionButtonRef.current?.contains(event.target)
            ) {
                document.getElementById("timeOptionDropdown").style.display =
                    "none";
                setTimeOptionIcon(more);
            }
        };

        document.addEventListener("mousedown", handler);
        return () => {
            document.removeEventListener("mousedown", handler);
        };
    });

    var addModalityReturnModal = document.getElementById("AddModalityReturnModal");
    function renderAddModalityReturnModal() {
        if (addModalityReturnModal.style.display !== "none") {
            addModalityReturnModal.style.display = "none";
        } else {
            addModalityReturnModal.style.display = "block";
        }
    }

    const enterDefault = useCallback((event) => {
        if (event.keyCode === 13) {
            event.preventDefault();
        }
    }, []);

    const escSubheaderClose = useCallback((event) => {
        if (event.keyCode === 27) {
            let originalTableSubheaders = document.querySelectorAll(
                '[id^="NewSubheader"]'
            );
            for (let originalTableSubheader of originalTableSubheaders) {
                originalTableSubheader.style.display = "none";
                document.getElementById(
                    `TableButton_${originalTableSubheader.id.split("_")[1]}`
                ).style.display = "flex";
            }
        }
    }, []);

    useEffect(() => {
        document.addEventListener("keydown", escSubheaderClose);
        return () => {
            document.removeEventListener("keydown", escSubheaderClose);
        };
    }, [escSubheaderClose]);

    useEffect(() => {
        document.addEventListener("keydown", enterDefault);
        return () => {
            document.removeEventListener("keydown", enterDefault);
        };
    }, [enterDefault]);

    let modalitiesReturnButtonRef = useRef();

    useEffect(() => {
        let handler = (event) => {
            if (
                !modalitiesReturnButtonRef.current.contains(event.target)
            ) {
                document.getElementById("AddModalityReturnModal").style.display =
                    "none";
            }
        };

        document.addEventListener("mousedown", handler);
        return () => {
            document.removeEventListener("mousedown", handler);
        };
    }, []);

    // const escSubheaderClose = useCallback((event) => {
    // 	if (event.keyCode ===27) {
    // 		newSubheaderRef.style.display = "none";
    // 	}
    // }, [])

    // useEffect(() => {
    // 	document.addEventListener("keydown", escSubheaderClose);
    // 	return() => {
    // 		document.removeEventListener("keydown", escSubheaderClose);
    // 	}
    // }, [escSubheaderClose])

    const customSort = (rows, selector, direction) => {
        const fake_headers = rows.filter(row => row.fakeHeader)
        const no_fake_headers = rows.filter(row => !row.fakeHeader)
        return fake_headers.concat(no_fake_headers.sort((rowA, rowB) => {
        // use the selector function to resolve your field names by passing the sort comparitors

        let comparison = 0;

        if (rowA[selector] > rowB[selector]) {
            comparison = 1;
        } else if (rowA[selector] < rowB[selector]) {
            comparison = -1;
        }
        return direction === 'desc' ? comparison * -1 : comparison;
        }));
    };

    const DAYS = [1, 2, 3, 4, 5]
    useEffect(() => {
        const config = tableConfig[tableSelected]

        if (config) {
            const ancillary_filter = bool_obj_to_arr(config.ancillary_filter)
            const site_filter = bool_obj_to_arr(config.site_filter)
            const modality_filter = bool_obj_to_arr(config.modality_filter)
            const modality_return = bool_obj_to_arr(config.modality_return)

            const site = config.data_variable.Site
            const variables = config.data_variable.Modalities
            const days = config.data_variable.Days
            
            const columnData = []
            const categories = ["site", "modalities"]

            const columns2labels = {}
            if (site) {
                columns2labels.site = { label: "Site", type: "string" }
                columns2labels.subjects = { label: "Subjects", type: "string" }
            }
            if (variables) {
                for (const modality of modality_return)
                    columns2labels[modality] = { label: modality, type: "modality" }
            }
            if (days) {
                categories.push("days")
            }

            setPending(true)
            getEnrollment(ancillary_filter, site_filter, modality_filter, 1, modality_return, DAYS, categories).then(data => {
                setEnrollmentData(data)
                setPending(false)
            }).catch(e => {
                alert(e)
            })
            for (let elem of Object.keys(columns2labels)) {
                const col = {
                    name: columns2labels[elem].label,
                    selector: row => row[elem],
                    sortable: true,
                    conditionalCellStyles: [
                        {
                            when: row => row.fakeHeader,
                            style: {
                                fontWeight: "bold",
                                borderBottomStyle: 'solid',
                                borderBottomWidth: "1px",
                                borderBottomColor: "rgb(225,225,225)",
                                display: "flex",
                                flexDirection: "row-reverse"
                            }
                        }
                    ]
                }

                if (elem === "subjects") {
                    col.conditionalCellStyles = [
                        {
                            when: row => row.fakeHeader,
                            style: {
                                fontWeight: "bold",
                                borderBottomStyle: 'solid',
                                borderBottomWidth: "1px",
                                borderBottomColor: "rgb(225,225,225)",
                                display: "flex",
                                flexDirection: "row-reverse",
                                padding: "10px",
                            }
                        }
                    ]
                }
                if (elem === "site") {
                    col.conditionalCellStyles = [
                        {
                            when: row => row.fakeHeader,
                            style: {
                                fontWeight: "bold",
                                borderBottomStyle: 'solid',
                                borderBottomWidth: "1px",
                                borderBottomColor: "rgb(225,225,225)",
                                display: "flex",
                                flexDirection: "row-reverse",
                                padding: "10px",
                            }
                        }
                    ]
                }

                if (columns2labels[elem].type === 'modality') {
                    col.width = days ? "160px": "120px"
                    col.cell = row => (<ModalityCell modalityData={row[elem]} threshold={row.threshold/2}/>)
                    col.style= {padding: "0px"}
    
                    if (days) col.sortable = false
                }
                columnData.push(col)
            }

            setEnrollmentColumns(columnData)

            if (variables) {
                document.getElementById('AddModalityReturn').style.display = "flex"
            } else {
                document.getElementById('AddModalityReturn').style.display = "none"
            }
        }
	}, [tableConfig, tableSelected])
    
    useEffect(() => {
        if (!enrollmentData) return

        const rows = []
        if (tableConfig[tableSelected].data_variable.Days && tableConfig[tableSelected].data_variable.Modalities) {
            const daysHeader = {}
            if ('modalities' in enrollmentData){
                const modalities = Object.keys(tableConfig[tableSelected].modality_return)
                for (const modality of modalities) {
                    daysHeader[modality] = {"1": 1, "2": 2, "3": 3, "4": 4, "5": 5}
                }
            }
            daysHeader.fakeHeader = true
            daysHeader.site = ""
            daysHeader.subjects = "> Days"
            daysHeader.threshold = 10
            rows.push(daysHeader)
        }

        rows.push(...Object.entries(enrollmentData.sites).map(kv => {
            const entry = {}
            const site = kv[0];
            const subjects = kv[1];
            if ('modalities' in enrollmentData){
                const modalities = enrollmentData.modalities[site]
                for (const modality in modalities) {
                    entry[modality] = modalities[modality]
                }
            }
            

            entry.site = site
            entry.subjects = subjects
            entry.threshold = subjects
            return entry
        }))

        //rows.push(...(new Array(Math.ceil(rows.length / 10)*10-rows.length).fill({})))

		setEnrollmentRows(rows)
    }, [enrollmentData, studiesProvider.selectedStudyId])

        function useWindowSize() {
            const [size, setSize] = useState(0);
            useLayoutEffect(() => {
                function updateSize() {
                    setSize(window.innerHeight);
                }
                window.addEventListener('resize', updateSize);
                updateSize();
                return () => window.removeEventListener('resize', updateSize);
            }, []);
            return size;
        }

    const size = useWindowSize()

    const tableButtonRefs = useRef([])
    function addNewTableButtonRef(newRef) {
        if (!tableButtonRefs.current.includes(newRef) && (newRef !== null)) {
            tableButtonRefs.current.push(newRef)
        }
    }

    const newSubheaderInputRefs = useRef([])
    function addNewSubheaderInputRef(newRef) {
        if (!newSubheaderInputRefs.current.includes(newRef) && (newRef !== null)) {
            newSubheaderInputRefs.current.push(newRef)
        }
    }

    useEffect(() => {
        function handleOutsideClick(event) {
            for(let ref of newSubheaderInputRefs.current) {
                for (let tableButton of tableButtonRefs.current) {
                    if (ref && !ref.contains(event.target) && document.getElementById(ref.id) && document.getElementById(ref.id).style.display !== "none" && tableButton && !tableButton.contains(event.target) && document.getElementById(tableButton.id) && document.getElementById(tableButton.id).style.display === "none") {
                        document.getElementById(ref.id).style.display = "none"
                        document.getElementById(tableButton.id).style.display = "flex"
                    }
                }
            }
        } document.addEventListener("click", handleOutsideClick)
        return () => document.removeEventListener("click", handleOutsideClick)
    }, [newSubheaderInputRefs])

    return (<div>
        <div style={{background: "#F8F8F8", maxHeight: `${size - 100}px`}}>
            <PageBody
                style={{
                    overflowX: "hidden",
                    height: size,
                    color: DEFAULTS.TEXT_COLOR,
                    background: "#F8F8F8",
                    paddingLeft: "40px",
                    display: "block",
                    overflowY: "auto",
                    maxHeight: `${size - 100}px`
                }}
            >
                <div
                    style={{
                        marginLeft: "-35px",
                        background: "#FFFFFF",
                    }}
                >
                    <PageSubHeader
                        style={{
                            width: "100%",
                            paddingLeft: "0px",
                        }}
                    >
                        <HorizontalScrollBar
                            style={{
                                flex: "0 1 auto",
                                width: "100%",
                                display: "inline-flex",
                                overflowX: "auto",
                                overflowY: "hidden",
                                marginBottom: "-2px",
                                marginLeft: "40px",
                                whiteSpace: "nowrap",
                            }}
                        >
                            {tableSubheaderOrder.map((table, i) => {
                                return (
                                    <TableTab>
                                        <TableButton id={`TableButton_${table}`} ref={addNewTableButtonRef} colorchange={tableSubheaderValues[table]} underlined={tableSubheaderValues[table]}
                                            onClick={(e) => {
                                                switch (e.detail) {
                                                    case 1:
                                                        updateTableSubheaderValues(table, !tableSubheaderValues[table]);
                                                        break;
                                                    case 2:
                                                        let tableSubheaders =
                                                            document.querySelectorAll('[id^="NewSubheader"]');
                                                        for (let tableSubheader of tableSubheaders) {
                                                            tableSubheader.style.display = "none";
                                                            document.getElementById(`TableButton_${tableSubheader.id.split("_")[1]}`).style.display = "flex";
                                                        }
                                                        document.getElementById(`NewSubheader_${table}`).style.display = "flex";
                                                        document.getElementById(`TableButton_${table}`).style.display = "none";
                                                        break;
                                                    default:
                                                        return;
                                                }
                                            }}
                                        >
                                            <p>{table}</p>
                                        </TableButton>
                                        <input
                                            id={`NewSubheader_${table}`}
                                            ref={addNewSubheaderInputRef}
                                            type="text"
                                            placeholder="New Table"
                                            onKeyDown={(e) => {
                                                if (e.keyCode === 13) {
                                                    var newKey = document.getElementById(`NewSubheader_${table}`).value;
                                                    if (Object.keys(tableSubheaderValues).includes(newKey)) {
                                                        alert("Table already exists.");
                                                        return;
                                                    }
                                                    if (newKey === "") {
                                                        alert("Table name cannot be blank");
                                                        return;
                                                    }
                                                    let indx = tableSubheaderOrder.indexOf(table);
                                                    setTableSubheaderOrder(Array.prototype.concat(tableSubheaderOrder.slice(0, indx),newKey,tableSubheaderOrder.slice(indx + 1)));
                                                    let newTableSubheaderValues =
                                                        Object.assign(
                                                            {},
                                                            ...Object.keys(
                                                                tableSubheaderValues
                                                            ).map((table_) => {
                                                                if (table_ !== table) {
                                                                    return {
                                                                        [table_]: false,
                                                                    };
                                                                }
                                                            })
                                                        );
                                                    setTableSubheaderValues({
                                                        ...newTableSubheaderValues,
                                                        [newKey]: true,
                                                    });
                                                    let newTableConfig = Object.assign(
                                                        {},
                                                        ...Object.keys(tableConfig)
                                                            .map((table_) => {
                                                                if (table_ === table) {
                                                                    return {
                                                                        [newKey]: tableConfig[table],
                                                                    };
                                                                } else {
                                                                    return {
                                                                        [table_]: tableConfig[table_],
                                                                    };
                                                                }
                                                            })
                                                    );
                                                    setTableConfig(newTableConfig)
                                                    document.getElementById(
                                                        `NewSubheader_${table}`
                                                    ).style.display = "none";
                                                    document.getElementById(
                                                        `NewSubheader_${table}`
                                                    ).value = "";
                                                    document.getElementById(
                                                        `TableButton_${table}`
                                                    ).style.display = "flex";
                                                }
                                            }}
                                            style={{
                                                display: "none",
                                                borderRadius: "6px",
                                                paddingLeft: "5px",
                                                border: "solid 0.5px #A7A7A7",
                                                fontFamily: "Source Sans Pro",
                                                width: "85px",
                                                marginBottom: "8px",
                                                marginRight: "4px"
                                            }}
                                        /> 
                                        <button
                                            style={{
                                                color: "#6C757C",
                                                marginTop: "4px",
                                                paddingLeft: "0px",
                                            }}
                                            onClick={(e) => {
                                                deleteTableSubheaderValues(table);
                                            }}
                                        >
                                            <MdIcons.MdClose />
                                        </button>
                                    </TableTab>
                                );
                            })}
                        </HorizontalScrollBar>
                        <button
                            style={{
                                color: "#0097ee",
                                marginLeft: "20px",
                                width: "20px",
                                paddingRight: "0px",
                                fontSize: 24,
                            }}
                            onClick={(e) => {
                                updateTableSubheaderValues(
                                    `New Table ${newSubheaderVal}`,
                                    !tableSubheaderValues[
                                    `New Table ${newSubheaderVal}`
                                    ]
                                );
                                setNewSubheaderVal(newSubheaderVal + 1);
                            }}
                        >
                            +
                        </button>
                    </PageSubHeader>
                </div>
                <div
                    style={{
                        background: "#FFFFFF",
                        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                        borderRadius: "3px",
                        padding: "27px",
                        position: "relative",
                        marginRight: "35px",
                    }}
                >
                    <div style={{ display: "none", justifyContent: "space-between", position: "relative"}}>
                        <div id="AddModalityReturn" style={{display: "none", zIndex: "1000"}}>
                            <ModalitiesReturnButton
                                    ref={modalitiesReturnButtonRef}
                                    onClick={() => {renderAddModalityReturnModal();}}
                                    class="MdIcons.MdFilterAlt"
                                > Select Tabulated Modalities
                            </ModalitiesReturnButton>
                            <AddModalityReturnModal
                                id="AddModalityReturnModal"
                                style={{
                                    display: "none",
                                    position: "absolute",
                                    top: "10px",
                                    right: "0px"
                                }}
                            >
                            </AddModalityReturnModal>
                        </div>

                    </div>
                    <div>
                        <PatientNumber>
                            <h1>Total Patient Enrollment</h1>
                            {enrollmentData?.total ?? 0}
                            {/* <h2>
                                <MdIcons.MdArrowUpward/>
                            </h2>
                            <p>
                                {tableConfig[tableSelected] && String(bool_obj_to_arr(tableConfig[tableSelected].time_option)[0])}{" "}
                                <WeekMonthButton
                                    ref={timeOptionButtonRef}
                                    id="weekMonthText"
                                    onClick={renderTimeOptionToggle}
                                    class="MdIcons.MdExpandMore"
                                >
                                    {timeOptionIcon}{" "}
                                </WeekMonthButton>
                            </p>{" "} */}
                        </PatientNumber>
                        <TimeOptionDropdown
                            ref={timeOptionRef}
                            id="timeOptionDropdown"
                        >
                            {tableConfig[tableSelected] &&
                                Object.keys(tableConfig[tableSelected].time_option)
                                .map((time_option) => {
                                    return (
                                        <button onClick={(e) => {updateTimeOptionCheckboxValues(tableSelected,time_option,true);}}>{time_option}</button>
                                    );
                                })}
                        </TimeOptionDropdown>
                    </div>
                    <div style={{display: "flex", marginTop:"20px"}}>
                        <div style={{ width: "166px", display: 'none' }}>
                        <DataVariablesText>Table Structure</DataVariablesText>
                            {tableConfig[tableSelected] &&
                                Object.keys(tableConfig[tableSelected].data_variable).map((data_variable) => {
                                    return (
                                        <Checkbox
                                            icon={<div style={{width: "100%", height:"100%", display: "flex",flex: 1,backgroundColor: "#207DEA",alignSelf: "stretch"}}>
                                                    <MdIcons.MdCheck
                                                        color="#FFFFFF"
                                                        size={14}
                                                    />
                                                </div>}
                                            name="variables-checkboxes"
                                            checked={tableConfig[tableSelected].data_variable[data_variable]}
                                            borderColor="#AEB7C6"
                                            cursor="pointer"
                                            style={{ marginBottom: 10 }}
                                            containerStyle={{ cursor: "pointer" }}
                                            labelStyle={{
                                                marginLeft: 10,
                                                marginBottom: 10,
                                                color: "#293241",
                                                fontSize: "16px",
                                                fontFamily: "Source Sans Pro",
                                            }}
                                            label={data_variable}
                                            onChange={(checked) => updateTableConfigValue(tableSelected, 'data_variable', data_variable, checked)}
                                        />
                                    );
                                })}
                        </div>
                        <div style= {{flex: "1 0 auto"}}>
                            <DenseTable
                                columns={enrollmentColumns}
                                data={enrollmentRows}
                                progressPending={pending}
                                sortFunction={customSort}
                            />
                        </div>
                    </div>
                </div>
            </PageBody>
        </div>
    </div>);
};

function ModalityCell(props) {
    if (typeof props.modalityData === 'object'){
        return <div style={{display: "flex", width: "100%", height: "100%", position:"relative"}}>
            {Object.values(props.modalityData).map(num => <NumBox num={num} threshold={props.threshold} style={{flex: "1 0 auto"}}/>)}
        </div>
    } else {
        return <div style={{width: "100%", height: "100%", position:"relative"}}>
        <NumBox num={props.modalityData} threshold={props.threshold}/>
        </div>
    }
}

const interpolateColor = (x, y, percentage) => {
    return {
        r: Math.round(y.r*percentage + x.r*(1-percentage)),
        g: Math.round(y.g*percentage + x.g*(1-percentage)),
        b: Math.round(y.b*percentage + x.b*(1-percentage)),
    }
}

const rgbToHex = (color) => "#"+color.r.toString(16).padStart(2,'0')+color.g.toString(16).padStart(2, '0')+color.b.toString(16).padStart(2,'0')

function NumBox(props) {
    let percentage = props.num < props.threshold ? props.num/props.threshold : 1.0
    percentage = percentage > 0 ? percentage : 0
    const full = {r: 185, g: 221, b: 168}
    const zero = {r: 255, g: 255, b: 255}
    const bgColor = rgbToHex(interpolateColor(zero, full, percentage))
    return (
        <div style={{height: "100%", display: "flex", alignItems:"center", backgroundColor: bgColor}}>
            <ModalityNumberBox>{props.num}</ModalityNumberBox>
        </div>
    )
}

const ModalityNumberBox = styled.div`
    width: 32px;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
`

const ModalitiesReturnButton = styled.button`
	width: 200px;
	height: 34px;
	display: inline-flex;
	position: relative;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 0px 0px;
	border-radius: 6px;
	box-shadow: 0 0 3px 0 rgba(99, 191, 244, 0.15);
	border: solid 0.5px #b6b6b6;
	background-color: #fff;
	font-family: "Source Sans Pro";
	font-weight: 600;
	font-size: 16px;
	color: #293241;
	cursor: pointer;
	margin-bottom: 7px;
`;

const PatientNumber = styled.p`
	font-family: "Montserrat", sans-serif;
	font-style: bold;
	font-weight: 700;
	font-size: 24px;
	line-height: 150%;
	color: #293241;
	display: inline;
	h1 {
		position: abolute;
		height: 23px;
		left: 301px;
		top: 229px;
		font-family: "Source Sans Pro";
		font-style: normal;
		font-weight: 600;
		font-size: 15px;
		line-height: 150%;
		color: #293241;
		margin-top: 22px;
		margin-bottom: 0px;
        overflow-x: visible;
	}
	h2 {
		font-family: "Source Sans Pro";
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 150%;
		color: #0fa95f;
		display: inline;
	}
	p {
		font-family: "Source Sans Pro";
		font-style: normal;
		font-weight: 700;
		font-size: 14px;
		line-height: 150%;
		color: #0fa95f;
		display: inline;
	}
`;

const DataVariablesText = styled.p`
	width: 99px;
	height: 24px;
	left: 304px;
	top: 304px;
	font-family: "Source Sans Pro";
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 150%;
    white-space: nowrap;
	color: #293241;
	margin-top: 12px;
	margin-bottom: 15px;
`;

const AddModalityReturnModal = styled.div`
    width: 150px;
	height: auto;
	background: #ffffff;
	border: 0.5px solid #b6b6b6;
	box-sizing: border-box;
	box-shadow: 0px 0px 3px rgba(99, 191, 244, 0.15);
	border-radius: 6px;
	padding: 10px 10px 10px 10px;
	position: absolute;
    margin-top: 32px;
    z-index: 1000;
	h2 {
		font-family: "Source Sans Pro";
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 150%;
		color: #000000;
		display: inline;
	}
`;

const PageSubHeader = styled.div`
	display: flex;
	width: 100%;
	height: 47px;
	padding-left: 50px;
	padding-right: 50px;
	overflow-x: auto;
	overflow-y: hidden;
	white-space: nowrap;
	background: #ffffff;
	padding-top: 10px;
	margin-bottom: 25px;
	button {
		font-family: "Source Sans Pro";
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		display: inline-flex;
		border: solid 0.5px #ffffff;
		background: #ffffff;
		cursor: pointer;
	}
`;

const TableTab = styled.div`
	width: auto;
	height: 34px;
	background: #ffffff;
	border: solid 0.5px #ffffff;
	font-size: 16px;
	display: inline-flex;
	margin-right: 35px;
	/* padding-left: -35px; */
	
`;

const TableButton = styled.button`
	width: 100%;
	height: 34px;
	font-size: 16px;
    margin-right: 4px;
    color: ${({ colorchange }) => (colorchange ? "#0097ee" : "#6C757C")};
	p {
		padding-bottom: 10px;
		border-bottom: ${({ underlined }) =>
        underlined ? "2px solid #0097ee" : "none"};
	}
`;

const WeekMonthButton = styled.button`
	display: inline;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 0px 0px;
	border-radius: 6px;
	box-shadow: 0 0 0 0;
	border: solid 0.5px #b6b6b6;
	background-color: #fff;
	font-family: "Source Sans Pro";
	cursor: pointer;
`;
const TimeOptionDropdown = styled.div`
	width: 90px;
	height: auto;
	background: #ffffff;
	border: 0.5px solid #b6b6b6;
	box-sizing: border-box;
	box-shadow: 0px 0px 3px rgba(99, 191, 244, 0.15);
	border-radius: 6px;
	text-align: center;
	margin-left: 100px;
	margin-top: -3px;
	display: none;
	button {
		font-family: "Source Sans Pro";
		font-style: normal;
		font-weight: 400;
		font-size: 12px;
		line-height: 150%;
		color: #000000;
		background-color: #ffffff;
		border: solid 0.5px #ffffff;
		cursor: pointer;
	}
`;
const HorizontalScrollBar = styled.div`
	::-webkit-scrollbar {
		display: block;
		height: 4px;
		color: #313a4a;
		border-radius: 2px;
	}
	::-webkit-scrollbar-track {
		background: #bec4cf;
		height: 4px;
		border-radius: 2px;
	}
`;

const PageBody = styled.div`
	/* ::-webkit-scrollbar {
  			display: block;
			width: 7px;
			color: #313A4A;
	}
	::-webkit-scrollbar-track {
			background: #BEC4CF;
			width: 7px;
			border-radius: 2px;
	} */
`;

export default DataTrials;
