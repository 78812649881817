import "./Whole.css"

import { useCallback, useEffect, useState } from "react"
import { Navigate, Routes, Route } from "react-router-dom"
import Sidebar from "../../Components/SideBar/Sidebar"

import ForgotPassword from "../Account/ForgotPassword/ForgotPassword"
import CreateAccount from "../Account/CreateAccount/CreateAccount"
import ChangePassword from "../Account/ChangePassword/ChangePassword"

import Login from "../Account/Login/Login"

import DataTrials from "../Data/DataTrials/DataTrials"
import UploadedForm from "../Data/Visualize/UploadedForms/UploadedForm"
import UploadHistorySubpage from "../Data/Visualize/UploadsSubpages/UploadHistorySubpage"
import PatientFiles from "../Data/Visualize/Subpages/PatientFiles"

import DataVisualize from "../Data/Visualize/DataVisualize"
import VisualizePatient from "../Data/Visualize/Subpages/VisualizePatient"
import PatientGroup from "../Data/Visualize/PatientGroup"

import PipelineCreate from "../Pipelines/PipelineCreate/PipelineCreate"
import PipelineExecute from "../Pipelines/PipelineExecute/PipelineExecute"
import PipelineShare from "../Pipelines/PipelineShare/PipelineShare"

import MultimodalReports from "../Reporting/MultimodalReports/MultimodalReports"
import RunReports from "../Reporting/MultimodalReports/RunReports"

import UserManagement from "../Admin/Users/UserManagement"
import RolesAndPermissions from "../Admin/Users/RolesAndPermissions"
import Projects from "../Admin/Projects/Projects"
import Sites from "../Admin/Sites/Sites"

import PageHeader from "../../Components/PageHeader/PageHeader"

import ErrorPage from "../Error/ErrorPage"
import { RequireAuth } from "../Error/RequireAuth"

import loadingSvg from "./smartneuro.svg"
import InactivityPage from "../Error/InactivityPage"
import UserManagementSubpage from "../Admin/Users/Subpage/UserManagementSubpage"

import ReleaseChangelogs from "./ReleaseChangelogs"
import { authUpdates, useAuthProvider } from "../../Providers/AuthProvider"
import { FRONTEND_LINKS } from "../../Constants/FrontendLinks"
import { useUploadIntoQueueProvider } from "../../Providers/UploadIntoQueueProvider"
import UploadQueue from "../Data/Visualize/UploadsSubpages/UploadQueue"
import { useModalProvider } from "../../Providers/ModalProvider"
import UploadWindow from "../Data/Visualize/UploadsSubpages/UploadWindow"
import { UploadProcessProvider } from "../../Providers/UploadProcessProvider"
import Toast from "../../Components/Toast/Toast"
import CuringComaLogin from "../Account/CuringComaLogin/CuringComaLogin"
import { useOnMount } from "../../Hooks/useOnMount"
import WebinarTerms from "../Account/Login/WebinarTerms"
import { Webinar } from "../Resources/Webinar"
import { Webinars } from "../Resources/Webinars"
import { useEnvironmentVariablesProvider } from "../../Providers/EnvironmentVariablesProvider"

import { Scrollbar } from "../../Constants/StyledComponents"
import UserTabs from "../../Pages/Admin/Users/UserTabs"
import { useWorkspacesProvider } from "../../Providers/WorkspacesProvider"

function Whole() {
    const uploadIntoQueueProvider = useUploadIntoQueueProvider();
    const [sidebar, setSidebarState] = useState(true)
    const [navPage, setNavPageState] = useState(true)
    const { createModal } = useModalProvider()
    const environmentVariablesProvider = useEnvironmentVariablesProvider()
    const { selectedWorkspace } = useWorkspacesProvider()

    const setSidebar = useCallback(
        newSidebarState => {
            if (newSidebarState !== sidebar) {
                setSidebarState(newSidebarState)
            }
        },
        [sidebar]
    )

    const setNavPage = useCallback(
        newNavPageState => {
            if (newNavPageState !== navPage) {
                setNavPageState(newNavPageState)
            }
        },
        [navPage]
    )

    function renderUploadModal() {
        createModal(
            <UploadProcessProvider>
                <UploadWindow escClose={false} clickOutsideClose={false} />
            </UploadProcessProvider>
        )
    }

    useEffect(() => {
        if (uploadIntoQueueProvider.openLoading === true) {
            renderUploadModal()
        }
    }, [uploadIntoQueueProvider.openLoading])

    const authProvider = useAuthProvider()

    useOnMount(() => {
        authProvider.update(authUpdates.TOKEN, authUpdates.PERMISSIONS)
    })

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            sessionStorage.removeItem('currentUploads')
        }

        window.addEventListener('beforeunload', handleBeforeUnload)

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload)
        }
    }, [])

    return authProvider.userIsLoggedIn &&
        (authProvider.permissions === undefined || Object.keys(authProvider.permissions).length === 0) ?
        (
            <div style={{
                color: 'black',
                display: "block",
                textAlign: "center",
                marginTop: `${window.innerHeight / 4}px`
            }}>
                <img src={loadingSvg} alt="loading" style={{ width: "300px" }} />
                <h2 style={{
                    fontFamily: "Montserrat",
                    fontStyle: "normal",
                    fontWeight: "700",
                    fontSize: "25px",
                    marginTop: "10px"
                }}>
                    Loading Workspace...
                </h2>
            </div>
        ) : (
            <>
                {navPage &&
                    <Sidebar
                        sidebar={sidebar}
                        setSidebar={setSidebar}
                    />
                }


                <div id='HeaderAndPage' style={{ marginLeft: "250px", height: "100vh", display: "flex", flexDirection: "column" }}>
                    {navPage && authProvider.userIsLoggedIn && (<>
                        <PageHeader
                            setNavPage={setNavPage}
                            sidebar={sidebar}
                            setSidebar={setSidebar}
                        />

                        <Routes>
                            <Route path={`${FRONTEND_LINKS.SUBPAGES.ADMIN.SUBPAGES.USER_MANAGEMENT.MAIN}/*`} element={<UserTabs />} />
                            <Route path="*" element={null} />
                        </Routes>

                    </>)}

                    <Scrollbar style={{ flex: 1, display: "flex", alignItems: "center", background: navPage ? '#F8F8F8' : 'white', }}>
                        <div style={{ width: "100%", height: "100%" }}>
                            <Routes>
                                {<Route
                                    exact
                                    path="/"
                                    element={
                                        <div>
                                            {selectedWorkspace === "CURINGCOMA" ?
                                                <CuringComaLogin
                                                    setNavPage={setNavPage}
                                                />
                                                :
                                                <Login
                                                    setNavPage={setNavPage}
                                                />
                                            }
                                        </div>
                                    }
                                />
                                }
                                {<Route
                                    exact
                                    path={FRONTEND_LINKS.SUBPAGES.RELEASE_CHANGELOGS.MAIN}
                                    element={
                                        <ReleaseChangelogs
                                            setSidebar={setSidebar}
                                            setNavPage={setNavPage}
                                        />
                                    }
                                />
                                }
                                {true &&
                                    <Route
                                        path={FRONTEND_LINKS.SUBPAGES.FORGOT_PASSWORD.MAIN}
                                        element={
                                            <ForgotPassword
                                                setSidebar={setSidebar}
                                                setNavPage={setNavPage}
                                            />
                                        }
                                    />
                                }



                                {true &&
                                    <Route
                                        path={FRONTEND_LINKS.SUBPAGES.INACTIVE.MAIN}
                                        element={
                                            <InactivityPage setNavPage={setNavPage} />
                                        }
                                    />
                                }

                                {true &&
                                    <Route
                                        path={FRONTEND_LINKS.SUBPAGES.CREATE_ACCOUNT.MAIN}
                                        element={
                                            <CreateAccount
                                                setNavPage={setNavPage}
                                            />
                                        }
                                    />
                                }

                                {true &&
                                    <Route
                                        path={FRONTEND_LINKS.SUBPAGES.CHANGE_PASSWORD.MAIN}
                                        element={
                                            <ChangePassword
                                                setNavPage={setNavPage}
                                            />
                                        }
                                    />
                                }

                                {true && <Route path={FRONTEND_LINKS.SUBPAGES.DATA.MAIN} element={<Navigate to={FRONTEND_LINKS.SUBPAGES.DATA.SUBPAGES.SUMMARY.MAIN} />} />}

                                {authProvider.permissions?.access_data_trials && (
                                    <Route
                                        path={FRONTEND_LINKS.SUBPAGES.DATA.SUBPAGES.SUMMARY.MAIN}
                                        element={
                                            <RequireAuth permissions={["access_data_trials"]} setNavPage={setNavPage}>
                                                <DataTrials sidebar={sidebar} setNavPage={setNavPage} />/{" "}
                                            </RequireAuth>
                                        }
                                    />
                                )}

                                {authProvider.permissions?.access_data_visualize &&
                                    <Route
                                        path={FRONTEND_LINKS.SUBPAGES.DATA.SUBPAGES.VISUALIZE.SUBPAGES.GROUPS.MAIN}
                                        element={
                                            <RequireAuth
                                                permissions={['access_data_visualize']}
                                                setNavPage={setNavPage}
                                            >

                                                <PatientGroup
                                                    sidebar={sidebar}
                                                />
                                            </RequireAuth>
                                        }
                                    />
                                }
                                {
                                    authProvider.permissions?.access_data_upload_patient_subpage &&
                                    <Route
                                        path={FRONTEND_LINKS.SUBPAGES.DATA.SUBPAGES.FORMS.SUBPAGES.PATIENT.MAIN}
                                        element={
                                            <RequireAuth
                                                permissions={['access_data_upload_patient_subpage']}
                                                setNavPage={setNavPage}>
                                                <UploadedForm
                                                    sidebar={sidebar}
                                                    setSidebar={setSidebar}
                                                />
                                            </RequireAuth>
                                        }
                                    >
                                    </Route>
                                }
                                {authProvider.permissions?.access_data_upload_patient_subpage &&
                                    <Route
                                        path={FRONTEND_LINKS.SUBPAGES.DATA.SUBPAGES.UPLOAD.SUBPAGES.PATIENT.MAIN}
                                        element={
                                            <RequireAuth
                                                permissions={['access_data_upload_patient_subpage']}
                                                setNavPage={setNavPage}
                                            >
                                                <UploadHistorySubpage
                                                    sidebar={sidebar}
                                                    setSidebar={setSidebar}
                                                    canUpload={true}
                                                />
                                            </RequireAuth>
                                        }
                                    />
                                }
                                {authProvider.permissions?.access_data_upload_patient_subpage &&
                                    <Route
                                        path={FRONTEND_LINKS.SUBPAGES.DATA.SUBPAGES.FILES.SUBPAGES.PATIENT.MAIN}
                                        element={
                                            <RequireAuth
                                                permissions={['access_data_upload_patient_subpage']}
                                                setNavPage={setNavPage}
                                            >
                                                <PatientFiles
                                                    sidebar={sidebar}
                                                    setSidebar={setSidebar}
                                                />
                                            </RequireAuth>
                                        }
                                    />
                                }
                                {authProvider.permissions?.access_data_upload_patient_subpage &&
                                    <Route
                                        path={FRONTEND_LINKS.SUBPAGES.DATA.SUBPAGES.VISUALIZE.SUBPAGES.PATIENTS.MAIN}
                                        element={
                                            <RequireAuth
                                                permissions={['access_data_upload_patient_subpage']}
                                                setNavPage={setNavPage}
                                            >
                                                <VisualizePatient
                                                    sidebar={sidebar}
                                                    setSidebar={setSidebar}
                                                />
                                            </RequireAuth>
                                        }
                                    />
                                }

                                {authProvider.permissions?.access_data_visualize &&
                                    <Route
                                        path={FRONTEND_LINKS.SUBPAGES.DATA.SUBPAGES.VISUALIZE.MAIN}
                                        element={
                                            <RequireAuth
                                                permissions={['access_data_visualize']}
                                                setNavPage={setNavPage}
                                            >
                                                <DataVisualize
                                                    sidebar={sidebar}
                                                    setSidebar={setSidebar}
                                                    setNavPage={setNavPage}
                                                    navPage={navPage}
                                                    canUpload={true}
                                                />
                                            </RequireAuth>
                                        }
                                    />
                                }

                                {environmentVariablesProvider.environmentVariables.RESOURCES_TAB && authProvider.permissions?.access_data_visualize &&
                                    <Route
                                        path={FRONTEND_LINKS.SUBPAGES.RESOURCES.WEBINARS.SUBPAGES.VIEW.MAIN}
                                        element={
                                            <RequireAuth setNavPage={setNavPage}>
                                                <Webinar />
                                            </RequireAuth>
                                        }
                                    />
                                }

                                {environmentVariablesProvider.environmentVariables.RESOURCES_TAB && authProvider.permissions?.access_data_visualize &&
                                    <Route
                                        path={FRONTEND_LINKS.SUBPAGES.RESOURCES.WEBINARS.MAIN}
                                        element={
                                            <RequireAuth setNavPage={setNavPage}>
                                                <Webinars />
                                            </RequireAuth>
                                        }
                                    />
                                }

                                {environmentVariablesProvider.environmentVariables.TERMS_AND_CONDITION && authProvider.permissions?.access_data_visualize &&
                                    <Route
                                        path={FRONTEND_LINKS.SUBPAGES.TERMS_AND_CONDITIONS.MAIN}
                                        element={
                                            <RequireAuth setNavPage={setNavPage}>
                                                <WebinarTerms
                                                    navPage={navPage}
                                                    sidebar={sidebar}
                                                    setNavPage={setNavPage}
                                                    setSidebar={setSidebar}
                                                />
                                            </RequireAuth>
                                        }
                                    />
                                }

                                {authProvider.permissions?.access_pipeline_create &&
                                    <Route
                                        path={FRONTEND_LINKS.SUBPAGES.ANALYZE.MAIN}
                                        element={
                                            <Navigate
                                                to={FRONTEND_LINKS.SUBPAGES.ANALYZE.SUBPAGES.CREATE.MAIN}
                                            />
                                        }
                                    />
                                }

                                {authProvider.permissions?.access_pipeline_create &&
                                    <Route
                                        path={FRONTEND_LINKS.SUBPAGES.ANALYZE.SUBPAGES.CREATE.MAIN}
                                        element={
                                            <RequireAuth
                                                permissions={['access_pipeline_create']}
                                                setNavPage={setNavPage}
                                            >
                                                <PipelineCreate />
                                            </RequireAuth>
                                        }
                                    />
                                }

                                {authProvider.permissions?.access_pipeline_execute &&
                                    <Route
                                        path={FRONTEND_LINKS.SUBPAGES.ANALYZE.SUBPAGES.EXECUTE.MAIN}
                                        element={
                                            <RequireAuth
                                                permissions={['access_pipeline_execute']}
                                                setNavPage={setNavPage}
                                            >
                                                <PipelineExecute />
                                            </RequireAuth>
                                        }
                                    />
                                }

                                {authProvider.permissions?.access_pipeline_share &&
                                    <Route
                                        path={FRONTEND_LINKS.SUBPAGES.ANALYZE.SUBPAGES.SHARE.MAIN}
                                        element={
                                            <RequireAuth
                                                permissions={['access_pipeline_share']}
                                                setNavPage={setNavPage}
                                            >
                                                <PipelineShare />
                                            </RequireAuth>
                                        }
                                    />
                                }

                                {authProvider.permissions?.access_multimodal_reports &&
                                    <Route
                                        path={FRONTEND_LINKS.SUBPAGES.REPORTING.MAIN}
                                        element={
                                            <Navigate
                                                to={FRONTEND_LINKS.SUBPAGES.REPORTING.SUBPAGES.MULTIMODAL_REPORTS.MAIN}
                                            />
                                        }
                                    />
                                }

                                {authProvider.permissions?.access_multimodal_reports &&
                                    <Route
                                        path={FRONTEND_LINKS.SUBPAGES.REPORTING.SUBPAGES.MULTIMODAL_REPORTS.MAIN}
                                        element={
                                            <RequireAuth
                                                permissions={['access_multimodal_reports']}
                                                setNavPage={setNavPage}
                                            >
                                                <MultimodalReports />
                                            </RequireAuth>
                                        }
                                    />
                                }

                                {authProvider.permissions?.access_multimodal_reports &&
                                    <Route
                                        path={FRONTEND_LINKS.SUBPAGES.REPORTING.SUBPAGES.MULTIMODAL_REPORTS.SUBPAGES.RUN.MAIN}
                                        element={
                                            <RequireAuth
                                                permissions={['access_multimodal_reports']}
                                                setNavPage={setNavPage}
                                            >
                                                <RunReports sidebar={sidebar} />
                                            </RequireAuth>
                                        }
                                    />
                                }

                                {authProvider.permissions?.access_admin_user_management &&
                                    <Route
                                        path={FRONTEND_LINKS.SUBPAGES.ADMIN.MAIN}
                                        element={
                                            <Navigate
                                                to={FRONTEND_LINKS.SUBPAGES.ADMIN.SUBPAGES.USER_MANAGEMENT.MAIN}
                                            />
                                        }
                                    />
                                }

                                {authProvider.permissions?.access_admin_user_management &&
                                    <Route
                                        path={FRONTEND_LINKS.SUBPAGES.ADMIN.SUBPAGES.USER_MANAGEMENT.MAIN}
                                        element={
                                            <RequireAuth
                                                permissions={['access_admin_user_management']}
                                                setNavPage={setNavPage}
                                            >
                                                <UserManagement
                                                    sidebar={sidebar}
                                                    setSidebar={setSidebar}
                                                />
                                            </RequireAuth>
                                        }
                                    />
                                }

                                {authProvider.permissions?.access_admin_user_management &&
                                    <Route
                                        path={FRONTEND_LINKS.SUBPAGES.ADMIN.SUBPAGES.USER_MANAGEMENT.SUBPAGES.USERS.SUBPAGES.ACTIVITY.MAIN}
                                        element={
                                            <RequireAuth
                                                permissions={['access_admin_user_management']}
                                            >
                                                <UserManagementSubpage
                                                    sidebar={sidebar}
                                                    setSidebar={setSidebar}
                                                />
                                            </RequireAuth>
                                        }
                                    />
                                }

                                {authProvider.permissions?.access_admin_role_and_permissions &&
                                    <Route
                                        path={FRONTEND_LINKS.SUBPAGES.ADMIN.SUBPAGES.USER_MANAGEMENT.SUBPAGES.ROLES_AND_PERMISSIONS.MAIN}
                                        element={
                                            <RequireAuth
                                                permissions={['access_admin_role_and_permission']}
                                                setNavPage={setNavPage}
                                            >
                                                <RolesAndPermissions
                                                    sidebar={sidebar}
                                                    setSidebar={setSidebar}
                                                />
                                            </RequireAuth>
                                        }
                                    />
                                }

                                {authProvider.permissions?.access_admin_studies &&
                                    <Route
                                        path={FRONTEND_LINKS.SUBPAGES.ADMIN.SUBPAGES.STUDIES.MAIN}
                                        element={
                                            <RequireAuth
                                                permissions={['access_admin_studies']}
                                                setNavPage={setNavPage}
                                            >
                                                <Projects
                                                    sidebar={sidebar}
                                                />
                                            </RequireAuth>
                                        }
                                    />
                                }

                                {authProvider.permissions?.access_admin_sites &&
                                    <Route
                                        path={FRONTEND_LINKS.SUBPAGES.ADMIN.SUBPAGES.SITES.MAIN}
                                        element={
                                            <RequireAuth
                                                permissions={['access_admin_sites']}
                                                setNavPage={setNavPage}
                                            >
                                                <Sites
                                                    sidebar={sidebar}
                                                />
                                            </RequireAuth>
                                        }
                                    />
                                }

                                {true &&
                                    <Route
                                        path="*"
                                        element={
                                            <div>
                                                <ErrorPage
                                                    setNavPage={setNavPage}
                                                />
                                            </div>
                                        }
                                    />
                                }

                            </Routes>
                            {uploadIntoQueueProvider.openQueue && (
                                <div>
                                    <UploadQueue />
                                </div>
                            )}
                        </div>
                    </Scrollbar>

                </div>

                <Toast />
            </>
        )
}

export default Whole
