import styled from "styled-components";

export const Columns = styled.div`
    display: flex;
	flex-direction: row;
	width: 100%;
    height: ${props => props.height ?? 'auto'};
    column-gap: ${props => props.spacing ?? props.gap ?? '0'};
    padding: ${props => props.spacing ?? '0'};
    justify-content: ${props => props.justifyContent ?? 'flex-start'};
    align-items: ${props => props.alignItems ?? 'normal'};

    flex-grow: ${props => props.grow ?? '0'};
    flex-shrink: ${props => props.shrink ?? '1'};
    flex-basis: ${props => props.basis ?? 'auto'};
`

export const Column = styled.div`
    flex-grow: ${props => props.grow ?? '0'};
    flex-shrink: ${props => props.shrink ?? '1'};
    flex-basis: ${props => props.width ?? props.basis ?? 'auto'};
    width: ${props => props.width ?? 'auto'};
`
