import { Grid } from "@mui/material"

function GridContainer({ children }) {
	return (
		<Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
			{children}
		</Grid>
	)
}

export default GridContainer
