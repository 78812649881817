export type MobergThemeColors = {
	main: string
	hover: string
	active: string
	noFill: {
		hover: string
		active: string
	},
	link: {
		main: string
	}
}

export interface MobergThemeData {
	[theme: string]: MobergThemeColors
}

export enum MobergTheme {
	BLUE = "blue",
	RED = "red",
	BLACK = "black",
	ON_LIGHT_BLUE = "on_light_blue", // Where the background is hover blue, not active blue
	ON_BLUE = "on_blue", // A theme for elements where the background is blue
	ON_GRAY = "on_gray"
}

const themeData: MobergThemeData = Object.freeze({
	black: {
		main: "#222",
		hover: "#444",
		active: "#666",
		noFill: {
			hover: "#edf2f7",
			active: "#e2e8f0",
		},
		link: {
			main: "#222"
		}
	},
	blue: {
		main: "#207dea",
		hover: "#1966BE",
		active: "#1658a4",
		noFill: {
			hover: "#EBF8FF",
			active: "#BEE3F8",
		},
		link: {
			main: "#0097EE"
		}
	},
	red: {
		main: "#e64e58",
		hover: "#C53030",
		active: "#9B2C2C",
		noFill: {
			hover: "#FFF5F5",
			active: "#FED7D7",
		},
		link: {
			main: "#e64e58"
		}
	},
	on_blue: {
		main: "",
		hover: "",
		active: "",
		noFill: {
			hover: "#A4CCE1",
			active: "#92B5C8",
		},
		link: {
			main: "#c4c4c4"
		}
	},
	on_light_blue: {
		main: "",
		hover: "",
		active: "",
		noFill: {
			hover: "#D6E8F2",
			active: "#BDDAEA",
		},
		link: {
			main: "#c4c4c4"
		}
	},
	on_gray: {
		main: "",
		hover: "",
		active: "",
		noFill: {
			hover: "#D6DADF",
			active: "#BEC2C6",
		},
		link: {
			main: "#c4c4c4"
		}
	}
})

export const getColors = (theme: MobergTheme): MobergThemeColors => {
	// Maybe we will have other things besides color?
	return themeData[theme]
}
