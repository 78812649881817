import React from "react"
import { ModalFooter } from "../../../../../../Constants/StyledComponents"
import { madeChangesToEditedLayoutGroupAtom } from "../../Atoms/Layout"
import { useRecoilValue } from "recoil"
import { ModalTitleAndCloseButton } from "./ModalTitleAndCloseButton"
import { MobergButton, MobergButtonShape, MobergButtonVariant } from "../../../../../../Components/MobergButton/MobergButton"
import { MobergTheme } from "../../../../../../Components/MobergThemes/MobergColors"
import { MobergFontSize } from "../../../../../../Components/MobergFont/MobergFont"
import ConfirmationModal from "../../../../../../Components/ConfirmationModal/ConfirmationModal"
import { useModalProvider } from "../../../../../../Providers/ModalProvider"
import { MobergRow } from "../../../../../../Moberg"
import { useAsyncTask } from "../../../../../../Hooks/useAsyncTask"
import { MobergText } from "../../../../../../Components/MobergHeader/MobergHeader"

type BaseVisualizationConfigureModalProps = {
	layoutId: string
	windowId: string
	title: string
	saveChanges: () => Promise<any>
	children?: JSX.Element[] | JSX.Element
	escClose?: boolean
	clickOutsideClose?: boolean
}

export const BaseVisualizationConfigureModal = (props: BaseVisualizationConfigureModalProps) => {
	const madeChanges = useRecoilValue(madeChangesToEditedLayoutGroupAtom)
	const { createModal, close } = useModalProvider()
	const saveTask = useAsyncTask(props.saveChanges)

	function closeConfigurationModal() {
		if (madeChanges) {
			createModal(<ConfirmationModal
				title={"Discard Changes?"}
                description={'You have unsaved changes. Are you sure you want to close without saving?'}
                confirmButton={{ text: "Close without saving", theme: MobergTheme.RED, onClick: close }} 
                 />)
		} else {
			close()
		}
	}

	const handleSave = () => {
		saveTask.run()
			.then(close)
			.catch((e: any) => console.error(e))
	}

	return (
		<div style={{ minWidth: "800px", height: "800px", display: "flex", flexDirection: "column", fontSize: "16px" }}>
			<ModalTitleAndCloseButton title={props.title} closeFunction={closeConfigurationModal}/>

			<hr style={{ height: "0px", border: "1px solid #B3B3B3", margin: 0 }} />

			<div style={{ flex: 1 }}>{props.children}</div>
			
			<hr style={{ height: "0px", border: "1px solid #B3B3B3", margin: 0 }} />

			<ModalFooter style={{ flex: 0 }}>
				<MobergRow gap="8px" horizontalAlign="right">
					{saveTask.isWaiting && (
						<MobergText>Please wait...</MobergText>
					)}

					{saveTask.error && !saveTask.isWaiting && (
						<MobergText style={{ color: "red" }}> {saveTask.error} </MobergText> 
					)}

					<MobergButton disabled={!madeChanges || saveTask.isWaiting} theme={MobergTheme.BLUE} variant={MobergButtonVariant.FILLED} fontSize={MobergFontSize.LARGE} shape={MobergButtonShape.WIDE}
						onClick={handleSave}>
						Save
					</MobergButton>
				</MobergRow>
			</ModalFooter>
		</div>
	)
}
