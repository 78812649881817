import { useContext, useRef } from "react"
import { D3TimeBasedVisualization } from "../Components/Visualizations/D3TimeBasedVisualization"
import { DimensionsContext } from "../../../../../Providers/DimensionsProvider"

type UseD3CheckReloadDataProps = {
	d3Controller: D3TimeBasedVisualization<any, any, any, any> | undefined
	clearDataIfChanges: Record<string, any>
	clearRenderCacheIfChanges?: Record<string, any>
}

const handleReloadData = (prevConfig: React.MutableRefObject<string | number>, newConfig: string | number, reloadMethod: () => void) => {
	let reloaded = false

	if (newConfig !== prevConfig.current) {
		prevConfig.current = newConfig
		reloadMethod()
		reloaded = true
	}

	return reloaded
}

export const useD3CheckReloadData = ({ d3Controller, clearDataIfChanges, clearRenderCacheIfChanges }: UseD3CheckReloadDataProps) => {
	const dimensions = useContext(DimensionsContext)
	const previousFullDataReloadConfig = useRef("")
	const previousClearCacheReloadConfig = useRef("")

	// The visualization has not rendered yet. There is nothing to do.
	if (dimensions.height === 0 || dimensions.width === 0) {
		return 
	}

	const fullLoadConfig = JSON.stringify(clearDataIfChanges)
	const clearCacheConfig = JSON.stringify(clearRenderCacheIfChanges)

	// If this is the first time, no reload is necessary, just set the values for later comparison.
	if (previousFullDataReloadConfig.current === "" && previousClearCacheReloadConfig.current === "") {
		previousFullDataReloadConfig.current = fullLoadConfig
		previousClearCacheReloadConfig.current = clearCacheConfig
		return
	}

	const didFullClear = handleReloadData(previousFullDataReloadConfig, fullLoadConfig, () => d3Controller?.clearDataAndReload())

	// Prevent an unnecessary redraw if we can 
	if (!didFullClear) {
		handleReloadData(previousClearCacheReloadConfig, clearCacheConfig, () => d3Controller?.clearCacheAndRedraw())
	}
}
