import Modal from 'react-bootstrap/Modal';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import styled from 'styled-components'
import * as MdIcons from 'react-icons/md'
import { useState, useEffect } from 'react';
import ModalFooterButton, { buttonColor } from '../../../../Components/ModalFooterButton/ModalFooterButton';

function ModifyGroupModal({show, onHide, data, modifyData, selectedComponentKey}) {
    const headingStyle = {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "700",
        lineHeight: "150%",
        color: "#293241"
    }
    
    const [gData, setGData] = useState([])
    const [groupName, setGroupName] = useState("");
    const [groupDescription, setGroupDescription] = useState("");
    const [numRow, setNumRow] = useState("1");
    const [numCol, setNumCol] = useState("1");

    useEffect(() => {
        setGData(data)
    }, [data])

    useEffect(() => {
        const primaryKey = selectedComponentKey?.split("-")[0]
        if (primaryKey) {
            setGroupName(gData[primaryKey]?.title)
        }
    }, [gData, selectedComponentKey])

    const popoverTopText = {
        'groupName': 'The name of the group.',
        'groupDescription': 'A description of the group.',
    }

    function getPopoverTop(labelname) {
        return (
            <Popover id="Popover-trigger-hover-focus" style={{width: "228px"}} positionleft={75}>
                <p style={{fontFamily: "Source Sans Pro", fontStyle: "normal", fontWeight: "400", fontSize: "12px", color: "#5F6775", paddingLeft: "3px"}}>{popoverTopText[labelname]}</p>
            </Popover>
        )
    }

    const handleSaveModifyGroupModal = () => {
        const primaryKey = selectedComponentKey?.split("-")[0]
        const newGData = JSON.parse(JSON.stringify(gData))
        newGData[primaryKey].title = groupName
        modifyData(newGData)
        onHide()
    }

    return (
        <Modal
        show={show}
        onHide={onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        >
            <Modal.Header 
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
            }}
            >
                <Modal.Title>
                    <h3 style={headingStyle}>{"Rename Group"}</h3>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div style={{display: 'flex'}}>
                    <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={getPopoverTop('groupName')}> 
                    <InfoButton><MdIcons.MdInfoOutline size={18} style={{float: "right"}}/></InfoButton>
                    </OverlayTrigger>
                    <p 
                        style={{
                            marginTop: "-4px",
                            fontWeight: "600",
                            fontSize: "16px",
                            lineHeight: "150%",
                            color: "#293241",
                        }}
                    >
                        Name<span style={{color:"red"}}>*</span>
                    </p>
                </div>
                <ModalTextinput placeholder="Enter group name here" id="groupName" value={groupName} onChange={e => setGroupName(e.target.value)}/>

                <div style={{display: 'flex'}}>
                    <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={getPopoverTop('groupDescription')}> 
                    <InfoButton><MdIcons.MdInfoOutline size={18} style={{float: "right"}}/></InfoButton>
                    </OverlayTrigger>
                    <p 
                        style={{
                            marginTop: "-4px",
                            fontWeight: "600",
                            fontSize: "16px",
                            lineHeight: "150%",
                            color: "#293241",
                        }}
                    >
                        Description
                    </p>
                </div>
                <ModalTextinput placeholder="Enter the description of the group here" id="groupDescription" value={groupDescription} onChange={e => setGroupDescription(e.target.value)}/>

                <div style={{display: 'flex'}}>
                    <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={getPopoverTop('groupLayout')}> 
                    <InfoButton><MdIcons.MdInfoOutline size={18} style={{float: "right"}}/></InfoButton>
                    </OverlayTrigger>
                    <p 
                        style={{
                            marginTop: "-4px",
                            fontWeight: "600",
                            fontSize: "16px",
                            lineHeight: "150%",
                            color: "#293241",
                        }}
                    >
                        Layout
                    </p>
                </div>
                <div style={{display: "flex", width: "464px", gap: "20px", alignItems: "center", justifyContent: "center"}}>
                    <ModalTextinput placeholder="Row" id="numRow" value={numRow} onChange={e => setNumRow(e.target.value)}/>
                    <ModalTextinput placeholder="Column" id="numCol" value={numCol} onChange={e => setNumCol(e.target.value)}/>
                </div>

        </Modal.Body>
        <Modal.Footer>
            <div
                style={{
                    paddingLeft: "35px",
                    paddingBottom: "15px",
                }}
            >
                <ModalFooterButton
                    buttonText="Save"
                    color={buttonColor.blueFill}
                    buttonStyle={{marginRight: "5px", width: "114px"}}
                    onClickFxn={handleSaveModifyGroupModal}
                />
                <ModalFooterButton
                    buttonText="Cancel"
                    color={buttonColor.blueOutline}
                    buttonStyle={{marginRight: "5px", width: "114px"}}
                    onClickFxn={onHide}
                />
            </div>
        </Modal.Footer>
        </Modal>
    );
}

const InfoButton = styled.button`
    border: none;
    background: none;
    border: none;
    color: #207dea;
    cursor: pointer;
    margin-top: 18px;
    width: fit-content;
    margin-right: 5px;
    margin-top: 0px;
    margin-bottom: 7px;
`;

const ModalTextinput = styled.input`
	background:  ${({ disabled }) => (disabled ? "#F2F2F2" : "#ffffff")};
    border: 2px solid #CCCCCC;
    box-sizing: border-box;
    border-radius: 5px;
    width: 464px;
    height: 56px;
    padding: 16px;
	color: ${({ disabled }) => (disabled ? "#737373": "#000000")};
	margin-top: -5px;
	margin-bottom: 20px;
    ::placeholder {
        font-family: 'Source Sans Pro';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: "gray";
    }
`;

export default ModifyGroupModal;
