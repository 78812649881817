import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import { GridActionsCellItem } from "@mui/x-data-grid"
import { useCallback } from "react"
import MUITable from "../../../Components/MUITable/MUITable"
import { useModalProvider } from "../../../Providers/ModalProvider"
import InviteUserModal from "./Subcomponents/InviteUserModal"
import ModifyUserModal from "./Subcomponents/ModifyUserModal"
import EyeIcon from "../../../Components/Icons/EyeIcon"
import { MdOutlineModeEdit } from "react-icons/md"
import ViewDomainsModal from "./Subcomponents/ViewDomainsModal"
import { useEndpointProvider } from "../../../Providers/EndpointProvider"
import { useBackendLinksProvider } from "../../../Providers/BackendLinksProvider"

export default function UserManagement() {
	const { createModal, close } = useModalProvider()
	const endpointProvider = useEndpointProvider()
	const { LINKS } = useBackendLinksProvider()

	function inviteUserModal() {
		createModal(<InviteUserModal style={{ height: "80%" }} close={close} escClose={false} clickOutsideClose={false} />)
	}

	const handleClick = useCallback((row, viewMode) => {
		createModal(<ModifyUserModal style={{ height: "80%" }} close={close} row={row} escClose={false} clickOutsideClose={false} viewMode={viewMode} />)
	}, [])

	function renderViewDomains() {
		createModal(<ViewDomainsModal
			style={{ height: 'fit-content' }}
			escClose={false}
			clickOutsideClose={false}
		/>)
	}

	const columns = [
		{ field: "email", flex: 1, headerName: "Email", minWidth: 220, visible: true },
		{ field: "first_name", flex: 0.5, headerName: "First name", minWidth: 100, visible: true },
		{ field: "last_name", flex: 0.5, headerName: "Last name", minWidth: 100, visible: true },
		{ field: "site_count", flex: 0.3, headerName: "No. Sites", valueGetter: params => params.row.site_count, minWidth: 150, visible: true },
		{ field: "project_count", flex: 0.3, headerName: "No. projects", valueGetter: params => params.row.project_count, minWidth: 120, visible: true },
		{ field: "role_count", flex: 0.3, headerName: "No. roles", valueGetter: params => params.row.role_count, minWidth: 120, visible: true },
		{
			field: "is_verified",
			flex: 0.3,
			headerName: "Verified",
			renderCell: params => {
				return params.value ? <CheckCircleIcon color="success" /> : null
			},
			headerAlign: "center",
			align: "center",
			minWidth: 80,
			visible: true
		},
		{
			field: "actions",
			flex: 0.4,
			headerName: "Actions",
			type: "actions",
			getActions: params => [
				<GridActionsCellItem icon={<EyeIcon />} label="View" onClick={() => handleClick(params.row, true)} showInMenu />,
				<GridActionsCellItem icon={<MdOutlineModeEdit size={20} />} label="Edit" onClick={() => handleClick(params.row)} showInMenu />,
			],
			minWidth: 80,
			visible: true
		},
	]

	const deleteUsers = async user_ids => endpointProvider.post(LINKS.ADMIN.USER_MANAGEMENT.USERS.DELETE_USERS, { user_ids })
	const userTableToolbarProps = {
		createButton: { title: "Invite user", isShow: true, onClick: inviteUserModal },
		deleteButton: {
			title: "Delete",
			isShow: true,
			onSuccessMessage: "User deleted successfully.",
			invalidateQueryKey: "users",
			onClick: user_ids => deleteUsers(user_ids),
		},
		viewDomainsButton: {
			title: "View domains",
			isShow: true,
			onClick: renderViewDomains,
		},
		filterButton: { title: "Filter", isShow: false },
		exportButton: { isShow: false },
		searchInput: { isShow: true },
		columnButton: { isShow: true },
		nameForMultipleRows: "users"
	}

	const queryProps = {
		queryKey: "users",
		endpoint: LINKS.ADMIN.USER_MANAGEMENT.USERS.GET_USERS,
	}

	return (
		<MUITable columns={columns} tableToolbarProps={userTableToolbarProps} {...queryProps} />
	)
}
