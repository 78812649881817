import { D3DragEvent } from "d3"
import { TimeSeriesPageManager, TimeSeriesPageManagerConfig } from "../../Data/TimeSeriesPageManager"
import { Page } from "../../Data/Page"
import { allWindowsDispatch, linkedWindowsDispatch } from "../../Atoms/Visualizations"
import { ReactCallbacks } from "../../Types/ReactCallbacks"
import { MIN_WINDOW_TIME_MS } from "../D3/D3UTCAxis"
import { debounce } from "lodash"
import { LinkedWindowUpdateEvent, LinkedWindowUpdateOptions } from "../../Types/LinkedWindowInfo"
import { TimeBasedVisualizationConfig } from "../../Types/TimeBasedVisualizationConfig"
import { D3VisualizationRenderer } from "./D3VisualizationRenderer"
import { TraceDataConfig } from "../../Types/Trace"
import { WINDOW_TIME_PRESETS } from "../../../../../../Managers/VisualizationManager/Viewport/Components/XAxis"
import { ModalityDataSource } from "../../Types/ModalityDataSource"
import { getClampedStartAndEnd } from "./clamping"

type GoToEndOptions = {
	waitForTimelinesToStopPlaying: boolean
}

export abstract class D3TimeBasedVisualization<
	Config extends TimeBasedVisualizationConfig,
	Callbacks extends ReactCallbacks<Config>,
	Renderer extends D3VisualizationRenderer<any, any>,
	PageManager extends TimeSeriesPageManager<any, any>
> {
	public root: HTMLDivElement
	public config: Config
	public reactCallbacks: Callbacks
	public timeSeriesPageManager: PageManager

	protected renderer?: Renderer

	private previousUpdateTimestamp: number | null = null
	private latestSyncTime: number | null = null
	private animationFrameId: number | null = null
	private recoveryOffset: number = 0

	constructor(root: HTMLDivElement, config: Config, pageManager: PageManager, reactCallbacks: Callbacks) {
		this.root = root
		this.config = config
		this.reactCallbacks = reactCallbacks
		this.timeSeriesPageManager = pageManager

		// If the patient is live, get up to sync smoothly without waiting for the first sync from the server
		if (config.patientIsAdmitted) {
			this.latestSyncTime = new Date(Date.now()).getTime() + 1000
			this.animationFrameId = requestAnimationFrame(this.realTimeUpdateFrame)
		}
	}

	public abstract getVisibleTraces(): TraceDataConfig[]

	protected abstract renderPage(page: Page<any>): void
	protected abstract getModalityDataSources(): ModalityDataSource[]
	protected abstract updateDerivedState(): void

	// PUBLIC

	public render() {
		this.renderer?.render()
	}

	public liveEndDateUpdated(newEndTime: number) {
		if (this.animationFrameId !== null) {
			cancelAnimationFrame(this.animationFrameId)
		}

		const outOfSyncMilliseconds = newEndTime - this.config.fileScale.domain()[1].getTime()

		if (outOfSyncMilliseconds < 0.1) {
			this.recoveryOffset = 0
		} else {
			this.recoveryOffset = Math.max(-2, Math.min(2, outOfSyncMilliseconds))
		}

		this.latestSyncTime = newEndTime
		this.animationFrameId = requestAnimationFrame(this.realTimeUpdateFrame)
		this.timeSeriesPageManager.onEndDateUpdated(newEndTime)
	}

	public updateConfig(config: Config) {
		this.config = config
		this.updateDerivedState()
		this.timeSeriesPageManager.update(this.getPageManagerConfig(), this.onPageLoaded, this.onPageUnloaded)
		this.render()
	}

	public unmount() {
		this.timeSeriesPageManager.clearQueue()
		this.renderer?.timeline?.stop()

		if (this.animationFrameId !== null) {
			cancelAnimationFrame(this.animationFrameId)
		}

		linkedWindowsDispatch.on(`update.${this.config.id}`, null)
		allWindowsDispatch.on(`jump.${this.config.id}`, null)
		this.reactCallbacks.setLinkedWindowsTimelineController(null)
	}

	public clearDataAndReload = () => {
		this.timeSeriesPageManager.resetPages()
		this.updateConfig(this.config) // this looks a little silly, but it's needed because of d3 scale issues.
		this.timeSeriesPageManager.clearQueueAndLoad()
	}

	public clearCacheAndRedraw = () => {
		this.timeSeriesPageManager.getAllLoadedPages().forEach((page: Page<any>) => page.clearRenderCache())
		this.timeSeriesPageManager.clearQueueAndLoad()
	}

	public goToStart = () => {
		if (this.config.liveModeEnabled) {
			return
		}

		const { viewDuration } = this.getStartTimeEndTimeViewDuration()
		const [recordingStartDate,] = this.config.fileScale.domain()

		let newStart = recordingStartDate.getTime()
		let newEnd = newStart + viewDuration

		const { start, end } = getClampedStartAndEnd(newStart, newEnd, this.config.fileScale)

		this.viewTimesChanged(start, end)
		this.timeSeriesPageManager.clearQueueAndLoad()
		this.updateLinkedWindows()
	}

	public goToEnd = (options?: GoToEndOptions) => {
		const { viewDuration } = this.getStartTimeEndTimeViewDuration()
		const newEnd = this.config.fileScale.domain()[1].getTime()
		const newStart = newEnd - viewDuration

		const finishUpdate = () => {
			this.viewTimesChanged(newStart, newEnd)
			this.updateLinkedWindows()
			this.timeSeriesPageManager.clearQueueAndLoad()
		}

		// If we don't wait for any timelines to stop playing, they'll set the time back to what it was before the jump.
		// This is only a problem when jumping to the end.
		if (options?.waitForTimelinesToStopPlaying || options?.waitForTimelinesToStopPlaying === undefined) {
			setTimeout(finishUpdate, 100)
		} else {
			finishUpdate()
		}
	}

	public goToPreviousPage = () => {
		if (this.config.liveModeEnabled) {
			return
		}

		const { startTime, viewDuration } = this.getStartTimeEndTimeViewDuration()
		const [recordingStartDate, recordingEndDate] = this.config.fileScale.domain()
		const recordingStartTime = recordingStartDate.getTime()
		const recordingEndTime = recordingEndDate.getTime()
		const totalRecordingTime = recordingEndTime - recordingStartTime

		let newStart = startTime - viewDuration
		let newEnd = newStart + viewDuration

		if (totalRecordingTime < viewDuration && newStart < recordingStartTime) {
			newEnd = recordingEndTime
			newStart = newEnd - viewDuration
		} else if (totalRecordingTime >= viewDuration && newStart < recordingStartTime) {
			newStart = recordingStartTime
			newEnd = newStart + viewDuration
		}

		this.viewTimesChanged(newStart, newEnd)
		this.timeSeriesPageManager.clearQueueAndLoad()
		this.updateLinkedWindows()
	}

	public goToNextPage = () => {
		if (this.config.liveModeEnabled) {
			return
		}

		const { endTime, viewDuration } = this.getStartTimeEndTimeViewDuration()
		const newEnd = Math.min(this.config.fileScale.domain()[1].getTime(), endTime + viewDuration)
		const newStart = newEnd - viewDuration
		this.viewTimesChanged(newStart, newEnd)
		this.timeSeriesPageManager.clearQueueAndLoad()
		this.updateLinkedWindows()
	}

	public zoomIn = () => {
		const { startTime, viewDuration } = this.getStartTimeEndTimeViewDuration()
		const windowSizeOptionsLargeToSmall = [...this.getZoomOptions().filter(time => time > 0)].sort((a, b) => b - a)
		const nextSmallestWindowSize = windowSizeOptionsLargeToSmall.find(time => time < viewDuration) ?? windowSizeOptionsLargeToSmall[windowSizeOptionsLargeToSmall.length - 1]
		const zoomDelta = (viewDuration - nextSmallestWindowSize) / 2
		const newStart = Math.min(this.config.fileScale.domain()[1].getTime() - MIN_WINDOW_TIME_MS, startTime + zoomDelta)
		const newEnd = Math.max(newStart + MIN_WINDOW_TIME_MS, newStart + nextSmallestWindowSize)

		const { start, end } = getClampedStartAndEnd(newStart, newEnd, this.config.fileScale)

		// The React state update will handle everything for us.
		this.reactCallbacks.setRootConfig(previous => ({ ...previous, viewScale: this.config.viewScale.domain([start, end]) }))
	}

	public zoomOut = () => {
		const { startTime, endTime, viewDuration } = this.getStartTimeEndTimeViewDuration()
		const windowSizeOptionsSmallToLarge = [...this.getZoomOptions().filter(time => time > 0)].sort((a, b) => a - b)
		const nextBiggestWindowSize = windowSizeOptionsSmallToLarge.find(preset => preset > viewDuration)

		if (!nextBiggestWindowSize) {
			return
		}
		
		const zoomDelta = (nextBiggestWindowSize - viewDuration) / 2
		const { start, end } = getClampedStartAndEnd(startTime - zoomDelta, endTime + zoomDelta, this.config.fileScale) 

		// The React state update will handle everything for us.
		this.reactCallbacks.setRootConfig(previous => ({ ...previous, viewScale: this.config.viewScale.domain([start, end]) }))
	}

	public playPause = () => {
		this.renderer?.timeline?.playPause()
	}

	public updateLinkedWindows = (options?: LinkedWindowUpdateOptions) => {
		if (this.config.isLinked) {
			const event: LinkedWindowUpdateEvent = { startDate: this.config.viewScale.domain()[0], controller: this.config.id, options }
			linkedWindowsDispatch.call("update", undefined, event)
		}
	}

	public onPan = (event: D3DragEvent<SVGRectElement, any, any>) => {
		const [startDate, endDate] = this.config.viewScale.domain()

		const startTime = startDate.getTime()
		const endTime = endDate.getTime()
		const width = this.config.viewScale.range()[1]
		const offsetTime = (-event.dx * (endTime - startTime)) / width

		let newStartTime = startTime + offsetTime
		let newEndTime = endTime + offsetTime

		const { start, end } = getClampedStartAndEnd(newStartTime, newEndTime, this.config.fileScale)

		this.config.viewScale.domain([start, end])
		this.timeSeriesPageManager.requestLoad()

		requestAnimationFrame(() => {
			this.viewTimesChanged(start, end)
			this.updateLinkedWindows()
		})
	}

	public getPageManagerConfig = (): TimeSeriesPageManagerConfig => {
		return {
			patientId: this.config.patientId,
			windowId: this.config.id,
			viewScale: this.config.viewScale,
			fileScale: this.config.fileScale,
			modalityDataSources: this.getModalityDataSources(),
			timeZone: this.config.timeZone,
			patientIsAdmitted: this.config.patientIsAdmitted,
		}
	}

	public getStartTimeEndTimeViewDuration = () => {
		const [start, end] = this.config.viewScale.domain()
		const startTime = start.getTime()
		const endTime = end.getTime()
		const viewDuration = endTime - startTime
		return { startTime, endTime, viewDuration }
	}

	public onWheel = (event: WheelEvent) => {
		const [startDate, endDate] = this.config.viewScale.domain()
		const startTime = startDate.getTime()
		const endTime = endDate.getTime()
		const offset = ((-1 * event.deltaY) / 500) * (endTime - startTime)

		let newStartTime = startTime + offset
		let newEndTime = endTime + offset

		this.timeSeriesPageManager.requestLoad()
		this.onWheelStopped() // it's a debounced function so it only gets called after we stop wheeling.

		const { start, end } = getClampedStartAndEnd(newStartTime, newEndTime, this.config.fileScale)

		this.updateLinkedWindows()

		requestAnimationFrame(() => {
			this.viewTimesChanged(start, end)
		})
	}

	public onWheelStopped = debounce(() => {
		this.timeSeriesPageManager.clearQueueAndLoad()
	}, 100)

	// PROTECTED

	protected getZoomOptions = (): number[] => WINDOW_TIME_PRESETS.map(preset => preset.time)

	protected mount(renderer: Renderer) {
		this.renderer = renderer

		linkedWindowsDispatch.on(`update.${this.config.id}`, this.onLinkedWindowsUpdate)
		allWindowsDispatch.on(`jump.${this.config.id}`, this.onJumpToTime)

		this.updateDerivedState()

		this.timeSeriesPageManager.update(this.getPageManagerConfig(), this.onPageLoaded, this.onPageUnloaded)
	}

	protected anotherLinkedWindowIsPlaying = () => this.config.timelineController !== null && this.config.isLinked && this.config.timelineController !== this.config.id

	public viewTimesChanged = (startTime: number, endTime: number) => {
		const { viewDuration } = this.getStartTimeEndTimeViewDuration()
		const calculatedViewDuration = endTime - startTime

		if (viewDuration !== calculatedViewDuration) {
			// If the view duration has changed, we need to let React handle the update.
			// Otherwise, we end up reverting the duration back to the old duration.
			// This bug is caused by the updates from another component's timeline when it is playing.
			if (this.anotherLinkedWindowIsPlaying()) {
				return
			}
		}

		this.config.viewScale.domain([startTime, endTime])
		this.renderer?.viewTimesChanged()
		this.timeSeriesPageManager?.clearQueueAndLoad()
	}

	public onTimelineSliderDrag = () => {
		this.timeSeriesPageManager?.clearQueueAndLoad()
		this.renderer?.onTimelineSliderDrag()
		this.updateLinkedWindows()
	}

	public onTimelineSliderDragEnd = () => {
		this.timeSeriesPageManager.clearQueueAndLoad()
		this.updateLinkedWindows({ autoScale: true })
	}

	protected onJumpToTime = (event: LinkedWindowUpdateEvent) => {
		const { viewDuration } = this.getStartTimeEndTimeViewDuration()
		const { start, end } = getClampedStartAndEnd(event.startDate.getTime(), event.startDate.getTime() + viewDuration, this.config.fileScale)

		requestAnimationFrame(() => this.viewTimesChanged(start, end))
	}

	protected onLinkedWindowsUpdate = (event: LinkedWindowUpdateEvent) => {
		if (this.config.isLinked && event.startDate && event.controller !== this.config.id) {
			this.onJumpToTime(event)
		}
	}

	protected onPageLoaded = (page: Page<any>) => {
		page.renderCache.forEach(cachedRender => (cachedRender.dirty = true))
		const pagesInView = this.timeSeriesPageManager.getPagesInView()
		this.renderer?.timeline?.updateNotLoadedRegions()

		if (pagesInView.includes(page)) {
			this.renderPage(page)
		}
	}

	protected onPageUnloaded = (page: Page<any>) => {
		this.renderer?.timeline?.updateNotLoadedRegions()
	}

	private realTimeUpdateFrame = (timestamp: number) => {
		if (!this.latestSyncTime) {
			return
		}

		// If this is the first animation frame, jump to the latest timestamp
		if (!this.previousUpdateTimestamp) {
			const [start] = this.config.fileScale.domain()
			this.config.fileScale.domain([start, this.latestSyncTime])
			this.previousUpdateTimestamp = timestamp
		}

		// Calculate the amount of time it took since the last frame
		let deltaTime = timestamp - this.previousUpdateTimestamp

		// If we get ahead or behind, we can speed up or slow down to smoothly get back on track.
		deltaTime += this.recoveryOffset

		const [start, end] = this.config.fileScale.domain()
		const newEnd = end.getTime() + deltaTime

		// Update the file scale and re-render
		this.config.fileScale.domain([start, newEnd])
		this.renderer?.liveEndDateUpdated()
		this.previousUpdateTimestamp = timestamp
		this.animationFrameId = requestAnimationFrame(this.realTimeUpdateFrame)

		if (this.config.liveModeEnabled) {
			const [viewStart, viewEnd] = this.config.viewScale.domain()
			this.viewTimesChanged(newEnd - (viewEnd.getTime() - viewStart.getTime()), newEnd)
		}
	}
}
