import { NODE_OUTPUT_TYPES } from "../../NodeOutputTypes";

export const POLYNOMIAL_MINIMIZE = {
    INPUTS: [],
    DISPLAY_NAME: "Polynomial Minimize",
    PRODUCT_LEVEL: "Standard",
    DESCRIPTION: "Minimize a polynomial given a list of polynomial coefficients.",
    KEYWORDS: ["poly", "fit", "polynomial", "regression", "minimize"],
    TYPE: 'METRICS',
    DATA_INPUT_TYPES: [NODE_OUTPUT_TYPES.LIST_OF_VALUES],
    DATA_OUTPUT_TYPE: NODE_OUTPUT_TYPES.VALUE
}