import React, { useLayoutEffect, MouseEvent, useRef, useContext } from "react"
import { MobergColumn } from '../MobergLayout/MobergColumn'
import { Scrollbar } from '../../Constants/StyledComponents'
import { MobergButton, MobergButtonShape, MobergButtonVariant } from '../MobergButton/MobergButton'
import { MdAdd } from 'react-icons/md'
import { MobergTheme } from '../MobergThemes/MobergColors'
import { MobergIconSize } from '../MobergIcon/MobergIcon'
import { Empty } from "antd"
import { EEGMontageDropdownRow } from "./EEGMontageDropdownRow"
import { useRecoilValue } from "recoil"
import { eegMontagesAtom } from "../../Pages/Data/Visualize/DataReview/Atoms/EEGMontage"
import { useAsyncTask } from "../../Hooks/useAsyncTask"
import { useEEGMontageService } from "../../Hooks/useEEGMontageService"
import { useAuthProvider } from "../../Providers/AuthProvider"
import { EEGDropdownContext } from "./DataReviewEEGMontageDropdown"
import { useModalProvider } from "../../Providers/ModalProvider"
import MontageModal from "../../Pages/Data/Visualize/EEG/MontageModal"
import { EEGMontage, MontageType } from "../../Pages/Data/Visualize/DataReview/Types/EEG"

export const EEGMontageDropdownSection: React.FC = () => {
	const { menu, scrollTopRef, currentSection, updateCurrentMontage } = useContext(EEGDropdownContext)
    const scrollBarRef = useRef<HTMLDivElement>(null)
	const authProvider = useAuthProvider()
    const eegMontages = useRecoilValue(eegMontagesAtom)
    const relevantEEGMontages = eegMontages.filter(eegMontage => eegMontage.type?.toLowerCase() === currentSection.toLowerCase())
    const { createEEGMontage } = useEEGMontageService()
	const { createModal } = useModalProvider()

	const createEEGMontageTask = useAsyncTask(() => (
		createEEGMontage({ 
			id: new Date(Date.now()).getTime(),
			name: "New montage", 
			channels: [], 
			type: currentSection, 
			userId: authProvider.currentUser?.id ?? null, 
		})
	))

	const onScroll = (event: MouseEvent<HTMLDivElement>) => {
		scrollTopRef.current = event.currentTarget.scrollTop
	}

    const addEEGMontage = () => {
        createEEGMontageTask.run().then((eegMontage: EEGMontage) => {
			updateCurrentMontage(eegMontage)
			menu.setClickOutsideClose(false)

			createModal(
				<MontageModal item={eegMontage}/>,
				{ zIndex: 10001, afterClose: () => menu.setClickOutsideClose(true) }
			)
		})
    }

    // When the component updates, the scroll resets to the top, which is confusing to the user.
	// We want to set the scroll position before the first render to prevent screen jumping. 
	// useLayoutEffect blocks the render until this completes.
	useLayoutEffect(() => {
		if (scrollBarRef.current) {
			scrollBarRef.current.scrollTop = scrollTopRef.current
		}
	})

    return (
		<MobergColumn expand={true} gap="16px" verticalAlign="space-between" style={{ padding: "16px", paddingRight: "8px", borderRadius: "6px" }}>
			<Scrollbar ref={scrollBarRef} style={{ maxHeight: "360px" }} onScroll={onScroll}>
				{relevantEEGMontages.map(eegMontage => (
					<EEGMontageDropdownRow key={eegMontage.id} eegMontage={eegMontage} />
				))}

				{relevantEEGMontages.length === 0 && <Empty description={<p style={{ fontFamily: "Source Sans Pro", fontSize: "16px" }}> No EEG montages </p>} />}
			</Scrollbar>

			{currentSection !== MontageType.STANDARD && (
				<div style={{ paddingRight: "8px"}}>
					<MobergButton
						theme={MobergTheme.BLUE}
						shape={MobergButtonShape.FULL_WIDTH}
						variant={MobergButtonVariant.CLEAR}
						disabled={createEEGMontageTask.isWaiting}
						onClick={addEEGMontage}
						style={{ padding: "12px", justifyContent: "start" }}
					>
						<MdAdd size={MobergIconSize.REGULAR} />
						Add EEG montage
					</MobergButton>
				</div>
			)}
			
		</MobergColumn>
	)
}