import { useModalProvider } from '../../../../Providers/ModalProvider';
import * as MdIcons from 'react-icons/md'
import ConfirmationModalButton, { buttonColor } from '../../../../Components/ConfirmationModalButton/ConfirmationModalButton';
import { ConfirmationModalContainer, ConfirmationModalContent } from '../../../../Constants/StyledComponentsConfirmationModals';

function CancelEditAnnotationModal(props) {

    const { close } = useModalProvider()
    
    function handleCancelChanges() {
        props.cancelAction()
    }

    async function handleSaveChanges() {
        await props.saveAction()
    }


    return (
        <ConfirmationModalContainer style={{height: "445px"}}>
            <ConfirmationModalContent>
                <MdIcons.MdOutlineError size={81} style={{color: "#AEB7C6", marginBottom: "5px"}}/>
                <h1 style={{fontFamily: 'Montserrat', fontStyle: "normal", fontWeight: "700", fontSize: "24px", lineHeight: "110%", color: "#000000"}}>Cancel changes?</h1>
                <p>Your changes will not be saved. This action can not be undone.</p>
            </ConfirmationModalContent>

            <ConfirmationModalButton 
                buttonText="Cancel changes"
                color={buttonColor.redFill}
                onClickFxn={() => {handleCancelChanges()}}
            />
             <ConfirmationModalButton 
                buttonText="Save changes"
                disabled={props?.disableSave}
                color={buttonColor.blueFill}
                onClickFxn={() => {handleSaveChanges()}}
            />
            <ConfirmationModalButton 
                buttonText="No, keep editing"
                color={buttonColor.blueOutline}
                onClickFxn={() => {close()}}
            />
        </ConfirmationModalContainer>
    )
}


export default CancelEditAnnotationModal;