import { TraceDataConfig } from "../Types/Trace"

type TraceData = TimeSeriesData | CompositeTimeSeriesData

export type TimeSeriesData = {
    times: (number | undefined)[]
    data: Float32Array
}

export type CompositeTimeSeriesData = {
    times: (number | undefined)[]
    data: Float32Array[]
}

export function getSignal(traceData: TraceData, config: TraceDataConfig) {
    if (config.isCompositePart) {
        return (traceData as CompositeTimeSeriesData).data[config.compositeIndex]
    }

    return (traceData as TimeSeriesData).data
}

export function getTimeSeriesData(traceData: TimeSeriesData | CompositeTimeSeriesData, config: TraceDataConfig): TimeSeriesData {
    if (config.isCompositePart) {
        return { times: traceData.times, data: (traceData as CompositeTimeSeriesData).data[config.compositeIndex] }
    }

    return traceData as TimeSeriesData
}	
