import { EnterElement } from "d3"
import { D3EEGMontage } from "./D3EEGMontage"
import { Selection } from "d3"
import { D3VerticalLines } from "../../../D3/D3VerticalLines"
import { D3UTCAxis } from "../../../D3/D3UTCAxis"
import { D3EEGYAxis } from "./D3BandScaleAxis"
import { D3EEGCanvas } from "./D3EEGCanvas"
import { EEGMontageConfigurationBuilder } from "./D3EEGMontageConfigurationBuilder"
import { D3AnnotationsWrapper } from "../../../D3/D3Annotations"
import { D3GraphsOverlay } from "../../TimeSeriesGraphGroup/D3/D3GraphsOverlay"
import { D3Timeline } from "../../../D3/Timeline/D3Timeline"
import { EEGMontageConfig } from "../../../../Types/EEGMontageConfig"
import { D3VisualizationRenderer } from "../../D3VisualizationRenderer"
import { D3ClipPath } from "../../../D3/D3ClipPath"

export class EEGMontageRenderer extends D3VisualizationRenderer<D3EEGMontage, EEGMontageConfigurationBuilder> {
    private boundingBoxClassName: string = "d3-eeg-montage-bounding-box"
	private timelineContainerClassName: string = "d3-timeline-container"
	private timelineSpacing = 30

    // Children
    public xAxis?: D3UTCAxis
    public yAxis?: D3EEGYAxis
	public overlay?: D3GraphsOverlay
	public annotationsWrapper?: D3AnnotationsWrapper
	public eegCanvas?: D3EEGCanvas
	public verticalLines?: D3VerticalLines
	public graphClipPath?: D3ClipPath
	public overlayClipPath?: D3ClipPath
	
	public viewTimesChanged = () => {
		this.xAxis?.render()
		this.timeline?.viewTimesChanged()
		this.verticalLines?.render()
		this.eegCanvas?.render()
		this.annotationsWrapper?.render()
		this.overlay?.render()
	}

	public onTimelineSliderDrag = () => {
		requestAnimationFrame(() => {
			this.xAxis?.render()
			this.annotationsWrapper?.render()
			this.verticalLines?.render()
			this.eegCanvas?.render()
		})
	}

	public getLastHoveredDate = () => this.overlay?.getLastHoveredDate()

	// PROTECTED

	protected canRender(): boolean {
		return this.visualization.graphBoundingBox.width > 0 && this.visualization.graphBoundingBox.height > 0
	}

    protected enter = (newElements: Selection<EnterElement, any, any, any>) => {
		const svg = newElements
            .append("svg")
            .attr("class", this.className)
			.attr("width", "100%")
			.attr("height", "100%")

        const boundingBox = svg
			.append("g")
			.attr("class", this.boundingBoxClassName)
			.attr("transform", `translate(${this.visualization.graphBoundingBox.x}, ${this.visualization.graphBoundingBox.y})`)

        boundingBox.each((config, index, nodes) => this.createBoundingBoxChildren(config, index, nodes, svg.node() as SVGSVGElement))

		const timelineContainer = svg
			.append("g")
			.attr("class", this.timelineContainerClassName)
			.attr("transform", `translate(${this.visualization.graphBoundingBox.x}, ${this.visualization.graphBoundingBox.y + this.visualization.graphBoundingBox.height + this.timelineSpacing})`)

		timelineContainer.each(this.createTimeline)
		svg.each((config, i, nodes) => this.createClipPath(config, i, nodes))

		return svg
	}

	protected update = (updatedSVG: Selection<any, any, any, any>): Selection<any, any, any, any> => {
		const svg = updatedSVG

		const timelineContainer = svg.select("." + this.timelineContainerClassName)
		timelineContainer.attr("transform", `translate(${this.visualization.graphBoundingBox.x}, ${this.visualization.graphBoundingBox.y + this.visualization.graphBoundingBox.height + this.timelineSpacing})`)

		svg.each(() => this.updateChildren(svg.node()))

		return updatedSVG
	}

    // PRIVATE

	private createClipPath = (config: EEGMontageConfig, index: number, nodes: ArrayLike<SVGSVGElement>) => {
		const root = nodes[index]
		this.graphClipPath = new D3ClipPath(root, this.configBuilder.getGraphClipPathConfig(), this.visualization.reactCallbacks)
		this.overlayClipPath = new D3ClipPath(root, this.configBuilder.getOverlayClipPathConfig(), this.visualization.reactCallbacks)
	}

	private createBoundingBoxChildren = (config: EEGMontageConfig, index: number, nodes: ArrayLike<SVGGElement>, svg: SVGSVGElement) => {
        const root = nodes[index]
		this.verticalLines = new D3VerticalLines(root, this.configBuilder.getVerticalLinesConfig())
        this.xAxis = new D3UTCAxis(root, this.configBuilder.getXAxisConfig(), this.visualization.reactCallbacks)
        this.yAxis = new D3EEGYAxis(root, this.configBuilder.getYAxisConfig(), this.visualization.reactCallbacks)
		this.eegCanvas = new D3EEGCanvas(root, this.configBuilder.getEEGCanvasConfig(), this.visualization.timeSeriesPageManager, this.visualization.reactCallbacks)

		this.overlay = new D3GraphsOverlay(root, this.configBuilder.getOverlayConfig(svg), this.visualization.reactCallbacks)
		this.annotationsWrapper = new D3AnnotationsWrapper(root, this.configBuilder.getAnnotationsConfig(), this.visualization.reactCallbacks)
    }

	private createTimeline = (config: EEGMontageConfig, index: number, nodes: ArrayLike<SVGGElement>) => {
		const root = nodes[index]
		this.timeline = new D3Timeline(root, this.configBuilder.getTimelineConfig(), this.visualization.timeSeriesPageManager, this.visualization.reactCallbacks)
	}

    private updateChildren = (svg: SVGSVGElement) => {
        this.xAxis?.updateConfig(this.configBuilder.getXAxisConfig())
        this.yAxis?.updateConfig(this.configBuilder.getYAxisConfig())
		this.timeline?.updateConfig(this.configBuilder.getTimelineConfig())
		this.verticalLines?.updateConfig(this.configBuilder.getVerticalLinesConfig())
		this.overlay?.updateConfig(this.configBuilder.getOverlayConfig(svg))
		this.eegCanvas?.updateConfig(this.configBuilder.getEEGCanvasConfig())
		this.annotationsWrapper?.updateConfig(this.configBuilder.getAnnotationsConfig())
		this.graphClipPath?.updateConfig(this.configBuilder.getGraphClipPathConfig())
		this.overlayClipPath?.updateConfig(this.configBuilder.getOverlayClipPathConfig())
    }
}