import { ScaleLinear, ScaleTime } from "d3";
import { TimeBasedVisualizationConfig, TimeBasedVisualizationConfigJSON } from "./TimeBasedVisualizationConfig";

export interface ScatterPlotJSON extends TimeBasedVisualizationConfigJSON {
	autoScale: any;
    xAxisConfig: AxisConfig
    yAxisConfig: AxisConfig
    color: string
    shape: string
    size: number
    resamplingPeriodSeconds: number
}

export type AxisConfig = {
    modality: string
    minValue: number
    maxValue: number
    stepSize: number
}

export type D3SCatterPlotPointsConfig = {
    xScale: ScaleLinear<any, any, any>
    yScale: ScaleLinear<any, any, any>
    viewScale: ScaleTime<any, any, any>
    xAxisConfig: AxisConfig
    yAxisConfig: AxisConfig
    fileScale: ScaleTime<any, any, any>
}

export type D3ScatterPlotPointConfig = {
    color: string
    shape: string
    size: number
    x: number
    y: number
    timestamp: number | undefined
    xScale: ScaleLinear<any, any, any>
    yScale: ScaleLinear<any, any, any>
}

export interface ScatterPlotConfig extends TimeBasedVisualizationConfig{
    xAxisConfig: AxisConfig,
	yAxisConfig: AxisConfig,
    color: string,
    shape: string,
    size: number,
    autoScale: boolean
    resamplingPeriodSeconds: number
}

export const DEFAULT_SCATTER_PLOT_JSON: ScatterPlotJSON = {
    viewDuration: 60 * 1000,
    xAxisConfig: {
        modality: "ABP_na",
        minValue: 0,
        maxValue: 300,
        stepSize: 20
    },
    yAxisConfig: {
        modality: "ICP_na",
        minValue: 0,
        maxValue: 200,
        stepSize: 20
    },
    color: '#000000',
    shape: 'circle',
    size: 5,
    autoScale: true,
    resamplingPeriodSeconds: 60,
    playbackSpeed: 1
}