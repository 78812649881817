import React from "react"
import { ReactElement } from "react"

type GridProps = {
    template: string[][]
    children: ReactElement[] |  ReactElement
    gap?: string
    padding?: string
}

function convert2dArrayTemplateToCSS(gridTemplate: string[][]) {
    if (gridTemplate.length === 0) {
        throw new Error(`grid template was empty! ${gridTemplate}`)
    }

    if (!gridTemplate.every(row => row.length === gridTemplate[0].length)) {
        throw new Error(`grid template rows are not all the same length! ${gridTemplate}`)
    }
    
    return gridTemplate.map((row: string[]) => `'${row.join(' ')}'`).join(' ')
}

export const Grid = ({ template, children, gap="0.5rem", padding="0" }: GridProps) => {
    const gridTemplateAreas = convert2dArrayTemplateToCSS(template)

    return (
        <div style={{ height: "100%", display: "grid", gap, padding, gridTemplateAreas, gridAutoColumns: "1fr", gridAutoRows: "1fr" }}>
            {children}
        </div>
    )
}