import { useModalProvider } from "../../../Providers/ModalProvider";
import ModalHeader from "../../../Components/ModalHeader/ModalHeader";
import { useCallback } from 'react';
import { useEndpointProvider } from '../../../Providers/EndpointProvider';
import { useAuthProvider } from "../../../Providers/AuthProvider";
import { FRONTEND_LINKS } from '../../../Constants/FrontendLinks';
import { useNavigate } from 'react-router-dom';
import ModalFooterButton, { buttonColor } from '../../../Components/ModalFooterButton/ModalFooterButton';
import { useBackendLinksProvider } from "../../../Providers/BackendLinksProvider";

const ConfirmDeleteAccount = (props) => {
    const { close } = useModalProvider()
    const authProvider = useAuthProvider()
    const endpointProvider = useEndpointProvider()
    const { LINKS } = useBackendLinksProvider()

    const navigate = useNavigate();
    const handleLogout = (e) => {
        authProvider.logout()
        navigate(FRONTEND_LINKS.MAIN)
    }

    const deleteUserAccount = useCallback((email, password) =>
        endpointProvider.post(LINKS.LOGIN.DELETE_USER, { email, password })
        , [endpointProvider])

    async function handleDeleteAccount() {
        deleteUserAccount(authProvider.currentUser.email, props.password).then((data) => {
            handleLogout()
        }).catch(e => {
            alert(e)
            close()
        })
    }

    return (
        <div style={{ background: "#FFFFFF", width: "436px", height: "200px", display: "block", fontFamily: "Source Sans Pro", fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#000000" }}>
            <ModalHeader headerText="Confirm Account Deletion" />
            <div style={{ paddingLeft: "20px", paddingRight: "20px", marginTop: "10px" }}>
                <p>Are you sure you want to delete your account? This action cannot be undone.</p>

                <div style={{ display: "inline-flex", marginTop: "25px" }}>
                    <ModalFooterButton
                        buttonText="Cancel"
                        color={buttonColor.blueOutline}
                        buttonStyle={{ position: "absolute" }}
                        onClickFxn={() => { close() }}
                    />

                    <ModalFooterButton
                        buttonText="Delete"
                        color={buttonColor.redOutline}
                        buttonStyle={{ position: "absolute", marginLeft: "275px", paddingLeft: "35px" }}
                        onClickFxn={() => { handleDeleteAccount() }}
                    />
                </div>
            </div>
        </div>
    )
}

export default ConfirmDeleteAccount;