import { useState, useEffect, useRef } from 'react';
import { ModalController } from "../../../../Controllers/ModalController"
import styled from 'styled-components';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import * as MdIcons from "react-icons/md";
import { MainTable } from '../../../../Components/DataTable/Table';
import CreateDetailReportModal from "./CreateDetailReportModal"
import { useStudiesProvider } from '../../../../Providers/StudiesProvider';
import ModalFooterButton, { buttonColor } from '../../../../Components/ModalFooterButton/ModalFooterButton';

export default function CreateReportModal({ openRef, props, refreshTable}) {
	const studiesProvider = useStudiesProvider()

    const contentRef = useRef()
    const closeRef = useRef()
    const popoverTopText = {
        'report': 'The name of the report that is being created by the user.',
		'reportStudies': 'The information of available studies which can be used to assign report to'
    }
    const [assignReportStudiesIcon, setAssignReportStudiesIcon] = useState(showMore);
    const [studySearchQuery, setStudySearchQuery] = useState("");
    const [studyTableColumns, setStudyTableColumns] = useState([])
	const [studyTableData, setStudyTableData] = useState([])
    const [studyPending, setStudyPending] = useState(false)
    const [studyTableSelected, setStudyTableSelected] = useState(false);
    const [studyToggleCleared, setStudyToggleCleared] = useState(false);
    const [studyPreselect, setStudyPreselect] = useState([])
    
    let createDetailReportButtonRef = useRef()
	const closeCreateReportModalRef = useRef()


    function getPopoverTop(labelname) {
        return (
            <Popover id="Popover-trigger-hover-focus" style={{width: "228px"}} positionleft={75}>
                <p style={{fontFamily: "Source Sans Pro", fontStyle: "normal", fontWeight: "400", fontSize: "12px", color: "#5F6775", paddingLeft: "3px"}}>{popoverTopText[labelname]}</p>
            </Popover>
        )
    }

    function renderReportStudies() {
		if (document.getElementById("assignReportStudiesTable").style.display !== "none") {
			document.getElementById("assignReportStudiesTable").style.display = "none";
			setAssignReportStudiesIcon(showMore)
		} else {
			document.getElementById("assignReportStudiesTable").style.display = "block";
			setAssignReportStudiesIcon(showLess)
		}
	}

    function handleStudySearch(event) {
		setStudySearchQuery(event.target.value)
	}

    function updateStudiesTableSelected({selectedRows}) {
        setStudyTableSelected(selectedRows)
    }

    function renderCreateReportModal() {
		setStudyPreselect([])
		document.getElementById("ReportNameInput").value = "";
		document.getElementById("searchInput").value = "";
		setAssignReportStudiesIcon(showMore)
	}

    useEffect(() => {
		if (!studiesProvider.studies) return

		let columns2labels = {
			name: { label: "Name", type: "string" },
			study_id: { label: "Study ID", type: "string" },
			start_date: { label: "Start Date", type: "string" },
			study_type: { label: "Study Type", type: "string" }
		}

		let columnData = []

		for (let elem of Object.keys(columns2labels)) {
			columnData.push({
				name: columns2labels[elem]['label'],
				selector: elem,
				sortable: true,
			})
		}
		
		setStudyTableColumns(columnData)
		setStudyPending(true)
		let tableData = studiesProvider.studies?.map(row => {
			let entry = {}
			for (let column of Object.keys(columns2labels)) {
				if (columns2labels[column]["type"] === "checkbox") {
					if (row[column]) {
						entry[column] = (<MdIcons.MdOutlineCheckBox />)
					} else {
						entry[column] = (<MdIcons.MdOutlineCheckBoxOutlineBlank />)
					}
				} else if (columns2labels[column]["type"] === 'string') {
					entry[column] = row[column]
				}
			}
			entry.selected = studyPreselect.includes(row.study_id)
			entry.study_id = row.study_id
			return entry
		})
			
        tableData = tableData.filter((row) => {
			if (studySearchQuery === "") {
				return true
			} else if (row.name.toLowerCase().includes(studySearchQuery.toLowerCase())) {
				return true
			} else if (row.study_id.toLowerCase().includes(studySearchQuery.toLowerCase())) {
				return true
			} else if (row.start_date.toLowerCase().includes(studySearchQuery.toLowerCase())) {
				return true
			} else if (row.study_type.toLowerCase().includes(studySearchQuery.toLowerCase())) {
				return true
			}
			return false
		})

		setStudyTableData(tableData)
        setStudyPending(false)
	
	}, [studiesProvider.selectedStudyId, studyPreselect, studySearchQuery])

    return (<>
        
					<CreateReportModalContent
						ref={contentRef}
						style={{display: "none"}}
					>
						<h3>Create Report</h3>
						<hr
							style={{
								border: "1px solid #B3B3B3",
								width: "820px",
							}}
						/>
							<h2>Report Details</h2>
							
                            <div style={{display: 'flex', alignItems: 'center', marginLeft: '30px'}}>
                                <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={getPopoverTop('report')}> 
                                <InfoButton style={{marginBottom: "7px"}}><MdIcons.MdInfoOutline size={18} style={{float: "right"}}/></InfoButton>
                                </OverlayTrigger>
                                <h1 style={{ marginTop: "-7px" }}>Report Name <span style={{color:"red"}}>*</span></h1>
                            </div>
                            <ReportNameInput id={'ReportNameInput'} placeholder="Enter a name for report" />

							<div style={{display: 'flex'}}>
                                <h1 style={{marginTop: "16px", marginBottom: "10px", marginLeft: "30px"}}>
                                <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={getPopoverTop('reportStudies')}>
                                <InfoButton><MdIcons.MdInfoOutline size={18} style={{float: "right", marginBottom: "-3px"}}/></InfoButton>
                                </OverlayTrigger>
                                <ModalDropdownButton onClick={renderReportStudies}>Studies{assignReportStudiesIcon}</ModalDropdownButton>
                                </h1>
                            </div>

							<ModalDropdownTableContainer
								id="assignReportStudiesTable"
								style={{display: "none"}}
                            >
                                <ModalSearchBar className="ui left icon input" style={{flex: "1"}}>
									<input id="searchInput" type="text" onChange={handleStudySearch} placeholder="Search"/> <i aria-hidden="true" class="search icon"/>
								</ModalSearchBar>
                            <MainTable
								columns={studyTableColumns}
                                data={studyTableData}
								progressPending={studyPending}
								selectableRows
                                onSelectedRowsChange={updateStudiesTableSelected}
								selectableRowSelected={rowSelectCriteria}
								clearSelectedRows={studyToggleCleared}
								conditionalRowStyles={[
									{when: row=>row.name === "UNNAMED",
									style: {color: 'red'}}
								]}
							/>
							
						    </ModalDropdownTableContainer>

						<hr
							style={{
								border: "1px solid #B3B3B3",
								width: "724px",
								marginLeft: "auto",
								marginRight: "auto",
							}}
						/>
						<div
							style={{
								paddingLeft: "35px",
								paddingBottom: "15px",
							}}
						>
							<ModalFooterButton
								buttonText="Create"
								color={buttonColor.blueFill}
								buttonStyle={{width: "114px", marginRight: "5px"}}
                                ref={createDetailReportButtonRef}
							/>
							<ModalFooterButton
								buttonText="Cancel"
								color={buttonColor.blueOutline}
								buttonStyle={{width: "114px", marginRight: "5px"}}
								ref={closeRef}
							/>
                            
						</div>
                        
					</CreateReportModalContent>


        <ModalController 
            openRef={openRef}
            closeRef={closeRef}
            contentRef={contentRef}
            closeOnEscape={true}
            onToggle={info => {
                if (info.isOpen) {
					closeCreateReportModalRef.current = info.close
                    renderCreateReportModal()
                }
            }}
			>
        </ModalController>

		<CreateDetailReportModal openRef={createDetailReportButtonRef} props={props} close={closeCreateReportModalRef.current} handleRefreshTable={refreshTable}/>
    </>)
}

const CreateReportModalContent = styled.div`
width: 820px;
height: auto;
background: #ffffff;
border-radius: 6px;
z-index: 100;
h3 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 150%;
    color: #293241;
    text-align: center;
    margin-top: 8px;
    margin-bottom: -4px;
}
h2 {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 700;
    font-size: 21px;
    margin-left: 30px;
    line-height: 150%;
    color: #293241;
    margin-top: 5px;
}
h1 {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    color: #293241;
}
textarea {
    background: #ffffff;
    border: 1px solid #ababab;
    box-sizing: border-box;
    border-radius: 6px;
    padding: 16px;
    width: 756px;
    height: 100px;
    margin-top: -5px;
    ::placeholder {
        font-family: "Source Sans Pro";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #9f9f9f;
        margin-top: -200px !important;
    }
}
`;

const InfoButton = styled.button`
    border: none;
    background: none;
    border: none;
    color: #207dea;
    cursor: pointer;
    margin-top: 18px;
    width: fit-content;
    margin-right: 5px;
    margin-top: 0px;
    // margin-bottom: 7px;
`;

const ReportNameInput = styled.input`
	background:  ${({ disabled }) => (disabled ? "#F2F2F2" : "#ffffff")};
	border: 2px solid #cccccc;
	box-sizing: border-box;
	border-radius: 5px;
	width: 464px;
	height: 56px;
	padding: 16px;
	margin-top: -5px;
	margin-bottom: 20px;
    margin-left: 30px;
	color: ${({ disabled }) => (disabled ? "#737373": "#000000")};
	::placeholder {
		font-family: "Source Sans Pro";
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 150%;
		color: #9f9f9f;
	}
`;

const ModalDropdownButton = styled.button`
	cursor: pointer;
	display: inline;
	flex-direction: row;
	border: solid 0.5px #FFFFFF;
	background-color: #FFFFFF;
	font-family: "Source Sans Pro";
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 150%;
	color: #293241;
`;

const showMore = (
    <MdIcons.MdExpandMore
        size={20}
        style={{marginLeft: "10px", marginRight: "-20px", color: "#B6B6B6"}}
    />
);

const showLess = (
    <MdIcons.MdExpandLess
        size={20}
        style={{marginLeft: "10px", marginRight: "-20px", color: "#B6B6B6"}}
    />
);

const ModalDropdownTableContainer = styled.div`
	border: 2px solid #cccccc;
	box-sizing: border-box;
	border-radius: 5px;
	background: #ffffff;
	width: 97%;
	height: auto;
	padding: 10px;
`;

const ModalSearchBar = styled.div`
	width: 60%;
	height: 30px;
	background: #FFFFFF;
	border-radius: 6;
	box-sizing: border-box;
	overflow-y: hidden;
	overflow-x: hidden;
	margin-bottom: 10px;
`;

const rowSelectCriteria = (row) => {
	return row.selected;
};