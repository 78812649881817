import React, { useRef, useState } from "react"
import { MdOutlineContentCopy } from "react-icons/md"
import { MobergMenuController, useMobergMenu } from "../../../Hooks/useMobergMenu"
import { MobergMenuItem, MobergIconSize, MobergShortTextInput, MobergColumn, MobergSubMenuBoundary, MobergButton, MobergTheme, MobergButtonVariant } from "../../../Moberg"
import { Layout } from "../../../Pages/Data/Visualize/DataReview/Types/Layout"
import { useLayoutService } from "../../../Hooks/useLayoutService"
import { useAsyncTask } from "../../../Hooks/useAsyncTask"
import { selectedLayoutGroupAtom } from "../../../Pages/Data/Visualize/DataReview/Atoms/Layout"
import { useRecoilValue } from "recoil"
import { useOnMount } from "../../../Hooks/useOnMount"

type CopyLayoutMenuItemProps = {
	parentMenu: MobergMenuController
	layout: Layout
	disabled?: boolean
	tooltip?: string
}

type CopyComponentProps = {
	parentMenu: MobergMenuController
	layout: Layout
	menu: MobergMenuController
}

const CopyComponent: React.FC<CopyComponentProps> = ({ layout, menu, parentMenu }) => {
	const defaultName = layout.name + " (copy)"
	const selectedLayoutGroup = useRecoilValue(selectedLayoutGroupAtom)
	const [name, setName] = useState(defaultName)
	const inputRef = useRef<HTMLInputElement>(null)
	const { copyLayoutForLayoutGroup } = useLayoutService()

	const copyLayoutTask = useAsyncTask(() => {
		if (!selectedLayoutGroup) {
			return new Promise(resolve => resolve(null))
		}

		let newName = name

		if (name.trim() === "") {
			newName = defaultName
		}

		return copyLayoutForLayoutGroup(selectedLayoutGroup, layout.id, { name: newName })
	})

	const submit = () => {
		if (copyLayoutTask.isWaiting) {
			return // don't allow submit while the task is running!
		}

		inputRef.current?.blur() // re-enable hotkeys if the input did not lose focus

		copyLayoutTask.run().then(() => {
			menu.close()
			parentMenu.close()
		})
	}

	useOnMount(() => {
		inputRef.current?.focus()
	})

	return (
		<MobergColumn gap="16px" horizontalAlign="end">
			<MobergShortTextInput
				ref={inputRef}
				label={"Copy as"}
				limit={25}
				onChange={name => setName(name)}
				onSubmit={submit}
				defaultValue={defaultName}
			/>

			<MobergButton 
				theme={MobergTheme.BLUE}
				variant={MobergButtonVariant.FILLED}
				onClick={submit}
				disabled={copyLayoutTask.isWaiting}
			>
				OK
			</MobergButton>
			
		</MobergColumn>
	)
}

export const CopyLayoutMenuItem: React.FC<CopyLayoutMenuItemProps> = ({ parentMenu, layout, disabled, tooltip }) => {
	const copyMenu = useMobergMenu({ placement: "right-end" })

	return (
		<MobergMenuItem onClick={event => copyMenu.open(event)} disabled={disabled} tooltip={tooltip}>
			<MdOutlineContentCopy size={MobergIconSize.REGULAR} />
			Make a copy

			<MobergSubMenuBoundary>
				<copyMenu.MobergMenu style={{ marginLeft: "2px", padding: "16px" }}>
					<CopyComponent parentMenu={parentMenu} menu={copyMenu} layout={layout} />
				</copyMenu.MobergMenu>
			</MobergSubMenuBoundary>

		</MobergMenuItem>
	)
}
