import { Scrollbar, InfoMessage } from "../../../Constants/StyledComponents";
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { LINKS } from '../../../Constants/BackendLinks';
import ModifySubModal from "./ModifySubModal";
import ConfirmationModal from "../../../Components/ConfirmationModal/ConfirmationModal";
import { useModalProvider } from "../../../Providers/ModalProvider";
import { useStudiesProvider } from "../../../Providers/StudiesProvider";
import { useEndpointProvider } from "../../../Providers/EndpointProvider";
import * as MdIcons from "react-icons/md";
import ModalHeader from "../../../Components/ModalHeader/ModalHeader";
import { TableComponent, RowsContainer, Pagination } from "../../../Components/TableComponent/TableComponent";
import { buttonColor } from "../../../Components/ModalFooterButton/ModalFooterButton";

function ModifyPatientModal({ currentSiteID, currentPatientID }) {
	const { createModal, close, openNext } = useModalProvider()
	const studiesProvider = useStudiesProvider()
	const endpointProvider = useEndpointProvider()

	const [checked, setChecked] = useState([])

	function updateSelectedPatientFilesRows({ selectedRows }) {
		let checkedFiles = selectedRows.map(file => {
			return file.filename
		})
		setChecked(checkedFiles)
	}

	const [patientFilesTableData, setPatientFilesTableData] = useState([])
	const [patientFilesTableColumns, setPatientFilesTableColumns] = useState([])
	const [patientFilesTablePending, setPatientFilesTablePending] = useState(true)

	async function getPatientFiles() {
		let body = {
			// patient_id: patientID
		}

		return endpointProvider.post(LINKS.DATA.UPLOAD.GET_PATIENT_DIR_FILES, body)
	}

	async function getPatientFilesTableData() {
		let patient_files = await getPatientFiles().catch(e => {
			alert(e)
		})
		if (patient_files === undefined || patient_files.length === 0) return
		setPatientFilesTablePending(true)

		let columns2labels = {
			filename: { label: "Name", type: "string", center: false },
			filesize: { label: "Size", type: "string", center: false },
		}

		let columnData = []
		let pdfColumnData = []

		for (let elem of Object.keys(columns2labels)) {
			columnData.push({
				name: columns2labels[elem]["label"],
				selector: row => row[elem],
				sortable: true,
				center: columns2labels[elem]["center"],
				attr: elem,
			})
			pdfColumnData.push({
				name: columns2labels[elem]["label"],
				selector: row => row[elem],
			})
		}
		setPatientFilesTableColumns(columnData)

		let tableData = patient_files.map(row => {
			let entry = {}
			let pdfEntry = {}
			console.log("row", row)
			for (let column of Object.keys(row)) {
				// if (row.filename.endsWith('.h5')) {
				// 	setHDF5Name(row.filename)
				// 	setHDF5Size(row.filesize)
				// 	continue
				// }
				if (columns2labels[column] && columns2labels[column]["type"] === "checkbox") {
					if (row[column]) {
						entry[column] = <MdIcons.MdOutlineCheckBox size={18} />
					} else {
						entry[column] = <MdIcons.MdOutlineCheckBoxOutlineBlank size={18} />
					}
					pdfEntry[column] = row[column]
				} else if (columns2labels[column] && columns2labels[column]["type"] === "string") {
					if (row[column] === null) {
						entry[column] = ""
						pdfEntry[column] = `${row[column]}`
					} else {
						entry[column] = `${row[column]}`
						pdfEntry[column] = `${row[column]}`
					}
				} else {
					entry[column] = row[column]
				}
			}
			return entry
		})

		const sortedData = [...tableData].sort((a, b) => (a.email < b.email ? -1 : 1))
		const newSortedData = sortedData.filter(value => Object.keys(value).length !== 0)
		console.log(newSortedData)

		setPatientFilesTableData(newSortedData)
		setPatientFilesTablePending(false)
	}

	useEffect(() => {
		getPatientFilesTableData()
	}, [])

	function renderSelectPatientModal(move) {
		openNext(
			<ModifySubModal
				escClose={false}
				clickOutsideClose={false}
				move={move}
				currentPatientID={currentPatientID}
				currentSiteID={currentSiteID}
				files={checked}
				style={{ width: "780px", padding: "10px" }}
			/>
		)
	}

	useEffect(() => {
		if (checked.length > 0) {
			document.getElementById("moveButton").style.background = "rgb(32, 125, 234)"
			document.getElementById("moveButton").style.color = "rgb(255, 255, 255)"
			document.getElementById("moveButton").style.pointerEvents = "auto"
			document.getElementById("copyButton").style.background = "rgb(32, 125, 234)"
			document.getElementById("copyButton").style.color = "rgb(255, 255, 255)"
			document.getElementById("copyButton").style.pointerEvents = "auto"
			document.getElementById("deleteButton").style.background = "rgb(230, 78, 88)"
			document.getElementById("deleteButton").style.color = "rgb(255, 255, 255)"
			document.getElementById("deleteButton").style.pointerEvents = "auto"
		} else {
			document.getElementById("moveButton").style.background = "rgb(244, 246, 248)"
			document.getElementById("moveButton").style.color = "rgb(41, 50, 65)"
			document.getElementById("moveButton").style.pointerEvents = "none"
			document.getElementById("copyButton").style.background = "rgb(244, 246, 248)"
			document.getElementById("copyButton").style.color = "rgb(41, 50, 65)"
			document.getElementById("copyButton").style.pointerEvents = "none"
			document.getElementById("deleteButton").style.background = "rgb(244, 246, 248)"
			document.getElementById("deleteButton").style.color = "rgb(41, 50, 65)"
			document.getElementById("deleteButton").style.pointerEvents = "none"
		}
		for (const file of checked) {
			if (file.endsWith(".h5")) {
				document.getElementById("moveButton").style.background = "rgb(244, 246, 248)"
				document.getElementById("moveButton").style.color = "rgb(41, 50, 65)"
				document.getElementById("moveButton").style.pointerEvents = "none"
				document.getElementById("copyButton").style.background = "rgb(244, 246, 248)"
				document.getElementById("copyButton").style.color = "rgb(41, 50, 65)"
				document.getElementById("copyButton").style.pointerEvents = "none"
				document.getElementById("deleteButton").style.background = "rgb(244, 246, 248)"
				document.getElementById("deleteButton").style.color = "rgb(41, 50, 65)"
				document.getElementById("deleteButton").style.pointerEvents = "none"
			}
		}
	}, [checked])

	async function finishUpload(uploadID, siteID, patientID) {
		let body = {
			patient_id: patientID,
			upload_id: uploadID,
			study_id: studiesProvider.selectedStudyId,
			site_id: siteID,
			// 'medication_kws': JSON.stringify(nestedFileMappingDictionary),
			medication_kws: JSON.stringify({}),
			new_upload: true,
			modify_upload: true,
		}

		return endpointProvider.post(LINKS.DATA.UPLOAD.FINISH_UPLOAD, body)
	}

	async function handleDeleteTempFiles(patient_id, site_id, files, upload_id) {
		let body = {
			patient_id: patient_id,
			site_id: site_id,
			files: files,
			newUploadID: upload_id,
			study_id: studiesProvider.selectedStudyId,
		}
		console.log("body", body)

		return endpointProvider.post(LINKS.DATA.UPLOAD.DELETE_TEMP_FILES, body)
	}

	async function createUpload(study_id, upload_dir) {
		let body = {
			study_id: study_id,
			upload_dir: upload_dir,
		}

		return endpointProvider.post(LINKS.DATA.UPLOAD.CREATE_UPLOAD, body)
	}

	async function handleDeleteFiles(files) {
		let upload_dir = `${currentSiteID}/${currentPatientID}`
		let data = await createUpload(studiesProvider.selectedStudyId, upload_dir)
		let upload_id = data["upload_id"]

		let body = {
			current_patient_id: currentPatientID,
			current_site_id: currentSiteID,
			files: checked,
			study_id: studiesProvider.selectedStudyId,
		}

		console.log("upload_id", upload_id)
		console.log("checked Files", checked)
		console.log(currentSiteID, currentPatientID)
		await handleDeleteTempFiles(currentPatientID, currentSiteID, checked, upload_id).catch(e => {
			let body = {
				study_id: studiesProvider.selectedStudyId,
				upload_id: upload_id,
			}
			endpointProvider.post(LINKS.DATA.UPLOAD.DELETE_UPLOAD, body)
			throw e
		})

		await endpointProvider.post(LINKS.DATA.UPLOAD.DELETE_FILES, body).catch(e => {
			let body = {
				study_id: studiesProvider.selectedStudyId,
				upload_id: upload_id,
			}
			endpointProvider.post(LINKS.DATA.UPLOAD.DELETE_UPLOAD, body)
			throw e
		})

		await finishUpload(upload_id, currentSiteID, currentPatientID)

		setChecked([])

		return
	}

	function renderConfirmDeleteModal() {
		createModal(<ConfirmationModal 
			title={"Delete Files?"}
			description={"Are you sure you want to delete these files? This action cannot be undone."}
			confirmButton={{
				text: "Delete",
				color: buttonColor.redFill,
				onClick: handleDeleteFiles
			}}
		/>)
	}

	return (
		<>
			<ModalHeader headerText={`Modify Patient: ${currentSiteID}/${currentPatientID}`} />

			<Scrollbar
				id="uploadFilesModalScrollableContentRef"
				style={{ paddingTop: "15px", paddingLeft: "47px", paddingRight: "47px", paddingBottom: "10px", overflowY: "scroll" }}
			>
				<InfoMessage>
					<p>
						<strong>Info!</strong> Select the files and/or directories that you want to move, copy, or delete. HDF5 Files cannot be modified!
					</p>
				</InfoMessage>

				{/* <InfoMessage id='HDF5Message' style={{display: 'none'}} ><p><strong>HDF5 File</strong>: {HDF5Name} <strong style={{marginLeft: '290px'}} >Size</strong>: {HDF5Size}</p></InfoMessage>						 */}

				{/* <MainTable
                    selectableRowsNoSelectAll={false}
                    onSelectedRowsChange={updateSelectedPatientFilesRows}
                    selectableRowsSingle={false}
                    columns={patientFilesTableColumns}
                    data={patientFilesTableData}
                /> */}
				<TableComponent data={patientFilesTableData} uniRef="filename">
					<div style={{ position: "relative" }}>
						<RowsContainer columns={patientFilesTableColumns} onSelectedRowsChange={updateSelectedPatientFilesRows} />
						<Pagination />
					</div>
				</TableComponent>
			</Scrollbar>

			{/* <hr style={{ border: "1px solid #B3B3B3", width: '850px', margin: 'auto', marginBottom: '10px' }} /> */}
			<div style={{ display: "flex", justifyContent: "space-between", marginLeft: "14px", marginTop: "10px", marginBottom: "10px" }}>
				<div style={{ display: "flex", marginLeft: "33px", width: "-webkit-fill-available" }}>
					<MoveButton
						id="moveButton"
						style={{ padding: "6px 32px", width: "120px", height: "40px", background: "rgb(244, 246, 248)", color: "rgb(41, 50, 65)", pointerEvents: "none" }}
						onClick={() => {
							renderSelectPatientModal(true)
						}}
					>
						Move
					</MoveButton>

					<CopyButton
						id="copyButton"
						style={{ padding: "6px 32px", width: "120px", height: "40px", background: "rgb(244, 246, 248)", color: "rgb(41, 50, 65)", pointerEvents: "none" }}
						onClick={() => {
							renderSelectPatientModal(false)
						}}
					>
						Copy
					</CopyButton>
				</div>

				<DeleteButton
					id="deleteButton"
					style={{
						padding: "6px 32px",
						width: "114px",
						height: "40px",
						background: "rgb(244, 246, 248)",
						color: "rgb(41, 50, 65)",
						pointerEvents: "none",
						marginRight: "50px",
					}}
					onClick={() => {
						renderConfirmDeleteModal()
					}}
				>
					Delete
				</DeleteButton>
			</div>
		</>
	)
}

const MoveButton = styled.div`
	display: inline-flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: center;
	padding: 12px 32px;
	margin-right: 5px;
	margin-top: 0px;
	width: 10%;
	height: 45px;
	border-radius: 6px;
	font-family: "Source Sans Pro";
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 150%;
	// border: 2px solid #207dea;
	cursor: pointer;
`

const CopyButton = styled.div`
	display: inline-flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: center;
	padding: 12px 32px;
	margin-right: 5px;
	margin-top: 0px;
	width: 10%;
	height: 45px;
	border-radius: 6px;
	font-family: "Source Sans Pro";
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 150%;
	// border: 2px solid #207dea;
	cursor: pointer;
`

export default ModifyPatientModal

const DeleteButton = styled.div`
	display: inline-flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: center;
	padding: 12px 32px;
	margin-right: 5px;
	margin-top: 0px;
	width: 30%;
	height: 45px;
	border-radius: 6px;
	font-family: "Source Sans Pro";
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 150%;
	// border: 2px solid #207dea;
	cursor: pointer;
`
