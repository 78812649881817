import React, { useState, useEffect, useRef } from 'react'
import styled from "styled-components";
import bgSVG from "./Unionhead.svg"
import bgRect1 from "./Rect1.svg"
import bgRect2 from "./Rect2.svg"
import "@fontsource/montserrat";
import "@fontsource/montserrat/400.css";
import ModalHeader from '../../../../Components/ModalHeader/ModalHeader';
import { ModalFooter } from '../../../../Constants/StyledComponents';
import { useModalProvider } from '../../../../Providers/ModalProvider';
import { MdDelete } from 'react-icons/md';
import MobergToggle from '../../../../Components/MobergToggle/MobergToggle';
import { useEEGMontageService } from "../../../../Hooks/useEEGMontageService";
import { MobergRow, MobergButton, MobergButtonShape, MobergButtonVariant, MobergTheme, MobergFontSize, MobergIconSize, MobergDropdown, 
    MobergShortTextInput, getColors, MobergColumn  } from '../../../../Moberg';
import { Empty } from 'antd'
import { useAsyncTask } from "../../../../Hooks/useAsyncTask"
import { MobergText } from "../../../../Components/MobergHeader/MobergHeader"
import { SortableChannels } from "../DataReview/Components/Visualizations/EEGMontage/React/SortableChannels"

const MontageModal = ({ item={} }) => {
    const { close } = useModalProvider()
    const { modifyEEGMontage } = useEEGMontageService()
    const [montageName, setMontageName] = useState(item?.name || "");
    const [amplifier, setAmplifier] = useState("40 Input amp");
    const image = new Image();
    const image1 = new Image();
    const image2 = new Image();
    const [buttonOpacity, setButtonOpacity] = useState(1);
    const [transitionOpacity, setTransitionOpacity] = useState(1);
    const [lastClicked, setLastClicked] = useState(null);
    const amplifierOptions = [
        { value: "16 Input amp", label: "16 Input amp" },
        { value: "40 Input amp", label: "40 Input amp" },
    ];
    const red = getColors(MobergTheme.RED).main

    const saveTask = useAsyncTask(() => 
        modifyEEGMontage({
            ...item,
            name: montageName ? montageName : item?.name ?? "New Montage",
            channels: channelList,
        }).then(close)
    )

    const [isToggled, setIsToggled] = useState(false);

    const handleToggle = (event) => {
        setLastClicked(null)
        setIsToggled(event.target.checked)
    }

    image.src = bgSVG;
    image1.src = bgRect1;
    image2.src = bgRect2;


    const buttons16InputAmp = [
        { id: "Fp1", x: 515, y: 105, group: 'Internal' },
        { id: "Fp2", x: 635, y: 105, group: 'Internal' },
        { id: "F7", x: 425, y: 170, group: 'Internal' },
        { id: "F8", x: 725, y: 170, group: 'Internal' },
        { id: "F3", x: 495, y: 200, group: 'Internal' },
        { id: "F4", x: 655, y: 200, group: 'Internal' },
        { id: "Fz", x: 575, y: 210, group: 'Internal' },
        { id: "T7", x: 385, y: 295, group: 'Internal' },
        { id: "T8", x: 765, y: 295, group: 'Internal' },
        { id: "C3", x: 475, y: 295, group: 'Internal' },
        { id: "C4", x: 675, y: 295, group: 'Internal' },
        { id: "Cz", x: 575, y: 295, group: 'Internal' },
        { id: "Pz", x: 575, y: 380, group: 'Internal' },
        { id: "P3", x: 495, y: 390, group: 'Internal' },
        { id: "P4", x: 655, y: 390, group: 'Internal' },
        { id: "P7", x: 425, y: 415, group: 'Internal' },
        { id: "P8", x: 725, y: 415, group: 'Internal' },
        { id: "O1", x: 515, y: 480, group: 'Internal' },
        { id: "O2", x: 635, y: 480, group: 'Internal' },
        { id: "AVG", x: 805, y: 480, group: 'External' },
        { id: "DIF1", x: 75, y: 150, group: 'External', overflow: true },
        { id: "DIF2", x: 130, y: 150, group: 'External', overflow: true },
        { id: "DIF3", x: 75, y: 210, group: 'External', overflow: true },
    ];

    const buttons40InputAmp = [
        { id: "Fp1", x: 515, y: 105, group: 'Internal' },
        { id: "Fp2", x: 635, y: 105, group: 'Internal' },
        { id: "REF", x: 575, y: 135, group: 'External' },
        { id: "X1", x: 345, y: 135, group: 'External' },
        { id: "Y1", x: 805, y: 135, group: 'External' },
        { id: "F7", x: 425, y: 170, group: 'Internal' },
        { id: "F8", x: 725, y: 170, group: 'Internal' },
        { id: "F3", x: 495, y: 200, group: 'Internal' },
        { id: "F4", x: 655, y: 200, group: 'Internal' },
        { id: "Fz", x: 575, y: 210, group: 'Internal' },
        { id: "X2", x: 325, y: 210, group: 'External' },
        { id: "Y2", x: 825, y: 200, group: 'External' },
        { id: "T7", x: 385, y: 295, group: 'Internal' },
        { id: "T8", x: 765, y: 295, group: 'Internal' },
        { id: "C3", x: 475, y: 295, group: 'Internal' },
        { id: "C4", x: 675, y: 295, group: 'Internal' },
        { id: "Cz", x: 575, y: 295, group: 'Internal' },
        { id: "Pz", x: 575, y: 380, group: 'Internal' },
        { id: "P3", x: 495, y: 390, group: 'Internal' },
        { id: "P4", x: 655, y: 390, group: 'Internal' },
        { id: "P7", x: 425, y: 415, group: 'Internal' },
        { id: "P8", x: 725, y: 415, group: 'Internal' },
        { id: "O1", x: 515, y: 480, group: 'Internal' },
        { id: "O2", x: 635, y: 480, group: 'Internal' },
        { id: "AVG", x: 805, y: 480, group: 'External' },
        { id: "DIFF1+", x: 75, y: 150, group: 'External', overflow: true },
        { id: "DIFF2+", x: 130, y: 150, group: 'External', overflow: true },
        { id: "DIFF3+", x: 75, y: 210, group: 'External', overflow: true },
        { id: "DIFF4+", x: 130, y: 210, group: 'External', overflow: true },
        { id: "AUX1", x: 75, y: 345, group: 'External', overflow: true },
        { id: "AUX2", x: 130, y: 345, group: 'External', overflow: true },
        { id: "AUX3", x: 185, y: 345, group: 'External', overflow: true },
        { id: "AUX4", x: 75, y: 405, group: 'External', overflow: true },
        { id: "AUX5", x: 130, y: 405, group: 'External', overflow: true },
        { id: "AUX6", x: 185, y: 405, group: 'External', overflow: true },
        { id: "AUX7", x: 75, y: 465, group: 'External', overflow: true },
        { id: "AUX8", x: 130, y: 465, group: 'External', overflow: true },
        { id: "AUXREF", x: 185, y: 465, group: 'External', overflow: true },
    ];

    const [currentButtons, setCurrentButtons] = useState(amplifier === "16 Input amp" ? buttons16InputAmp : buttons40InputAmp);

    const isInternal = (channel) => {
        const firstButton = currentButtons.find(button => button.id === channel.first)
        const secondButton = currentButtons.find(button => button.id === channel.second)
        return firstButton?.group === "Internal" && secondButton?.group === "Internal"
    }

    const [channelList, setChannelList] = useState(item?.channels.map(channel => ({ ...channel, external: !isInternal(channel) })) || []);

    const internalPairs = channelList.filter(channel => !channel.external)
    const externalPairs = channelList.filter(channel => channel.external)

    const canvasRef = useRef(null);

    const drawBGImages = () => {
        return new Promise((resolve) => {
            const canvas = canvasRef.current;
            const context = canvas.getContext('2d');

            if (!context) {
                // context can be null
                return
            }

            let loadedCount = 0;

            const onImageLoaded = () => {
                loadedCount++;
                if ((amplifier === "40 Input amp" && loadedCount === 3) || (amplifier !== "40 Input amp" && loadedCount === 2)) {
                    resolve();
                }
            };

            image.onload = () => {
                context.clearRect(0, 0, canvas.width, canvas.height);
                context.drawImage(image, 325, 50, image.width, image.height * 1.02);
                onImageLoaded();
            };

            image1.onload = () => {
                context.drawImage(image1, 55, 130, image1.width, image1.height);
                context.font = "600 13px Montserrat";
                context.fillStyle = "#000000";
                context.fillText("Differential inputs", 60, 120);
                onImageLoaded();
            };

            if (amplifier === "40 Input amp") {
                image2.onload = () => {
                    context.drawImage(image2, 55, 325, image2.width, image2.height);
                    context.font = "600 13px Montserrat";
                    context.fillStyle = "#000000";
                    context.fillText("Auxiliary inputs", 60, 315);
                    onImageLoaded();
                };
            } else {
                onImageLoaded();
            }
        });
    };


    useEffect(() => {
        drawBGImages();
    }, []);


    useEffect(() => {
        setCurrentButtons(amplifier === "16 Input amp" ? buttons16InputAmp : buttons40InputAmp);
        drawBGImages();
    }, [amplifier]);

    const getNormalizedDirection = (point1, point2) => {
        const dx = point2.x - point1.x;
        const dy = point2.y - point1.y;
        const length = Math.sqrt(dx * dx + dy * dy);
        return {
            x: dx / length,
            y: dy / length
        };
    };

    const drawArrowhead = (context, x, y, dx, dy, size, isSelected) => {
        context.fillStyle = isSelected ? "#207DEA" : "#000000";
        context.shadowBlur = isSelected ? 5 : null;
        context.shadowColor = isSelected ? "#207DEA" : null;
        const angle = Math.atan2(dy, dx);
        context.beginPath();
        context.moveTo(x, y);
        context.lineTo(x - size * Math.cos(angle - Math.PI / 6), y - size * Math.sin(angle - Math.PI / 6));
        context.lineTo(x - size * Math.cos(angle + Math.PI / 6), y - size * Math.sin(angle + Math.PI / 6));
        context.closePath();
        context.fill();
        context.shadowBlur = null;
        context.shadowColor = null;
    };


    const drawInternalLines = (context, allPairs, selectedPairs = [], isHovered = false) => {
        allPairs.forEach(pair => {
            const isSelected = selectedPairs.some(sp => sp.first === pair.first && sp.second === pair.second);
            const startButton = currentButtons.find(btn => btn.id === pair.first);
            const endButton = currentButtons.find(btn => btn.id === pair.second);
            if (startButton && endButton) {
                if (startButton.group === "Internal" && endButton.group === "Internal") {
                    context.beginPath();
                    context.moveTo(startButton.x + 16.5, startButton.y + 16.5);
                    let controlPoint1, controlPoint2;
                    const xOffsetInitial = 40;
                    const yOffsetInitial = 40;
                    const distance = Math.sqrt(Math.pow(endButton.x - startButton.x, 2) + Math.pow(endButton.y - startButton.y, 2));
                    const maxDistance = 100;
                    const scaleFactor = Math.min(distance / maxDistance, 1);
                    const xOffset = xOffsetInitial * scaleFactor;
                    const yOffset = yOffsetInitial * scaleFactor;

                    if (Math.abs(startButton.x - endButton.x) > Math.abs(startButton.y - endButton.y)) {
                        if (startButton.x < endButton.x) {
                            controlPoint1 = { x: startButton.x + 16.5 + xOffset, y: startButton.y + 16.5 - yOffset };
                            controlPoint2 = { x: endButton.x + 16.5 - xOffset, y: endButton.y + 16.5 - yOffset };
                        } else {
                            controlPoint1 = { x: startButton.x + 16.5 - xOffset, y: startButton.y + 16.5 + yOffset };
                            controlPoint2 = { x: endButton.x + 16.5 + xOffset, y: endButton.y + 16.5 + yOffset };
                        }
                    } else {
                        if (startButton.y < endButton.y) {
                            controlPoint1 = { x: startButton.x + 16.5 + xOffset, y: startButton.y + 16.5 + yOffset };
                            controlPoint2 = { x: endButton.x + 16.5 + xOffset, y: endButton.y + 16.5 - yOffset };
                        } else {
                            controlPoint1 = { x: startButton.x + 16.5 - xOffset, y: startButton.y + 16.5 - yOffset };
                            controlPoint2 = { x: endButton.x + 16.5 - xOffset, y: endButton.y + 16.5 + yOffset };
                        }
                    }


                    const direction = getNormalizedDirection(controlPoint2, {
                        x: endButton.x + 16.5,
                        y: endButton.y + 16.5
                    });


                    const adjustedEndPoint = {
                        x: endButton.x + 16.5 - direction.x * 19.5,
                        y: endButton.y + 16.5 - direction.y * 19.5
                    };


                    context.bezierCurveTo(
                        controlPoint1.x, controlPoint1.y,
                        controlPoint2.x, controlPoint2.y,
                        adjustedEndPoint.x, adjustedEndPoint.y
                    );
                    context.strokeStyle = isSelected ? '#207DEA' : '#000000'
                    context.shadowBlur = isSelected ? 5 : null;
                    context.shadowColor = isSelected ? "#207DEA" : null;
                    context.lineWidth = 1;
                    context.stroke();
                    drawArrowhead(context, adjustedEndPoint.x, adjustedEndPoint.y, direction.x, direction.y, 10, isSelected);
                    context.shadowBlur = null;
                    context.shadowColor = null;
                }
            }
        });
    };






    const handleButtonClick = (buttonId, group) => {

        if (channelList.length > 21) {
            setLastClicked(null);
            return;
        }

        if (isToggled) {
            if (group === "Internal") {
                const newChannel = { first: buttonId, second: "REF" };
                newChannel.external = !isInternal(newChannel)

                if (!externalPairs.some(pair => pair.first === newChannel.first && pair.second === newChannel.second)) {
                    setChannelList(prev => [...prev, newChannel]);
                }
            }
        } else if (lastClicked) {
            if (lastClicked.id === buttonId) {
                setLastClicked(null);
                return;
            }

            const newChannel = { first: lastClicked.id, second: buttonId }
            newChannel.external = !isInternal(newChannel)

            if (lastClicked.group === "Internal" && group === "Internal") {
                if (!internalPairs.some(pair => pair.first === newChannel.first && pair.second === newChannel.second)) {
                    setChannelList(prev => [...prev, newChannel]);
                }
            } else if (!externalPairs.some(pair => pair.first === newChannel.first && pair.second === newChannel.second)) {
                setChannelList(prev => [...prev, newChannel]);
            }

            setLastClicked(null);
        } else {
            setLastClicked({ id: buttonId, group: group });
        }
    };


    const handleClearAll = () => {
        setChannelList([]);
        setSelectedItems([]);
    };

    const handleAmplifierChange = (newAmp) => {
        setTransitionOpacity(0);
        setTimeout(() => {
            setAmplifier(newAmp);
            setTransitionOpacity(1);
        }, 100);
    };

    const [selectedItems, setSelectedItems] = useState([]);

    useEffect(() => {
        (async () => {
            await drawBGImages();
            const canvas = canvasRef.current;
            const context = canvas.getContext('2d');

            if (!context) {
                // context can be null
                return
            }

            drawInternalLines(context, channelList, selectedItems);
        })();
    }, [channelList, selectedItems, externalPairs]);

    useEffect(() => {
        (async () => {
            await drawBGImages();
            const canvas = canvasRef.current;
            const context = canvas.getContext('2d');
            drawInternalLines(context, channelList, selectedItems);
        })();
    }, [currentButtons]);

    const handleDeleteSelected = () => {
        setChannelList(prev => prev.filter(pair => !selectedItems.includes(pair)));
        setSelectedItems([]);
    };

    const channelListContainerRef = useRef(null)

    return (
        <>
            <MontageModalContainer>
                <ModalHeader headerText="Edit Montage" />
                <MontageModalContent>
                    <LeftColumn>
                        <div
                            style={{
                                position: "absolute",
                                top: "60px",
                                left: "25px",
                                zIndex: "1000",
                                display: "flex",
                                flexDirection: "row",
                                gap: "10px",

                            }}
                        >
                            <MobergDropdown
                                options={amplifierOptions}
                                onChange={handleAmplifierChange}
                                selectedValue={amplifier}
                            />

                            <MobergToggle
                                checked={isToggled}
                                onChange={handleToggle}
                                color="primary"
                                label="REF"
                                labelPlacement="end"
                                style={{
                                    marginLeft: "10px",
                                    marginRight: "10px",
                                }}
                            />

                        </div>
                        <CanvasContainer>

                            <canvas ref={canvasRef} width="920" height="580" style={{ opacity: transitionOpacity, }} />
                            {currentButtons.map(button => (

                                <Button
                                    key={button.id}
                                    style={{
                                        left: button.x, top: button.y,
                                        fontSize: button.overflow ? "8.5px" : "12px",
                                        width: button.overflow ? "39px" : "35px",
                                        height: button.overflow ? "38px" : "35px"
                                    }}
                                    onClick={() => handleButtonClick(button.id, button.group)}
                                    isActive={lastClicked && lastClicked.id === button.id}
                                    isExternalPair={externalPairs.some(pair => pair.first === button.id || pair.second === button.id)}
                                    isSelected={selectedItems.some(item => item.first === button.id || item.second === button.id)}
                                    isRefPair={externalPairs.some(pair => (pair.first === "REF" && pair.second === button.id) || (pair.second === "REF" && pair.first === button.id) || (button.id === "REF"))}
                                    opacity={buttonOpacity}
                                    transitionOpacity={transitionOpacity}
                                >
                                    {button.id}

                                </Button>
                            ))}
                        </CanvasContainer>
                    </LeftColumn>
                    <RightColumn
                        style={{
                            borderRadius: "6px 0 0 0",
                            borderRight: "none"
                        }}
                    >
                        <FormContainer>
                            <div style={{ padding: "16px", paddingBottom: 0 }}>
                                <MobergShortTextInput
                                    label={"Montage name"}
                                    defaultValue={montageName}
                                    limit={50}
                                    placeholder="Longitudinal Bipolar"
                                    value={montageName}
                                    onChange={newName => setMontageName(newName)}
                                />
                            </div>
                            <div
                                style={{
                                    display: 'inline-flex',
                                    flexDirection: 'column',
                                    padding: "0 16px",
                                    paddingBottom: 0
                                }}>
                                <MobergRow horizontalAlign="space-between" style={{ paddingBottom: "8px" }}>
                                    <p>Channels</p>

                                    {channelList.length === 22 ? (<p
                                        style={{
                                            color: "red"
                                        }}
                                    >
                                        Max channels reached
                                    </p>
                                    )
                                        :
                                        (
                                            <p
                                                style={{
                                                    color: "gray"
                                                }}>
                                                {channelList.length}/22
                                            </p>
                                        )
                                    }


                                </MobergRow>
                                <ChannelListContainer ref={channelListContainerRef}>
                                    {channelList.length === 0 && (
                                        <MobergColumn verticalAlign="center" expand={true} style={{ fontFamily: 'Source Sans Pro' }}>
                                            <Empty description={
                                                <MobergColumn gap="64px">
                                                    <span style={{ fontSize: MobergFontSize.X_LARGE }} >No channels</span>
                                                    <span style={{ fontSize: MobergFontSize.REGULAR }}>Create a channel by clicking on two different inputs.</span>
                                                </MobergColumn>}
                                            />
                                        </MobergColumn>
                                    )}

                                    <SortableChannels 
                                        containerRef={channelListContainerRef}
                                        channels={channelList}
                                        setChannels={setChannelList}
                                        selectedChannels={selectedItems}
                                        setSelectedChannels={setSelectedItems}
                                    />

                                </ChannelListContainer>
                            </div>
                            <div
                                style={{
                                    borderTop: "1px solid #dedede", width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", padding: "0px 10px"
                                }}
                            >
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start" }}>
                                    <div style={{
                                        padding: "8px 0", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start", gap: "10px"
                                    }}>
                                        <MobergButton
                                            onClick={handleDeleteSelected}
                                            theme={MobergTheme.RED}
                                            shape={MobergButtonShape.SQUARE}
                                            variant={MobergButtonVariant.FILLED}
                                            disabled={selectedItems.length === 0}
                                        >
                                            <MdDelete size={MobergIconSize.REGULAR} />
                                        </MobergButton>

                                        {selectedItems.length > 0 && (
                                            <span style={{ color: red }}>
                                                {selectedItems.length} selected
                                            </span>
                                        )}
                                    </div>

                                </div>

                                <MobergButton 
                                    theme={MobergTheme.RED} 
                                    onClick={handleClearAll}
                                    disabled={channelList.length === 0}
                                >
                                    Remove all channels
                                </MobergButton>
                            </div>
                        </FormContainer>
                    </RightColumn>
                </MontageModalContent>

                <hr style={{ borderTop: "1px solid #999", margin: 0 }} />

                <ModalFooter>
                    <MobergButton
                        theme={MobergTheme.RED}
                        variant={MobergButtonVariant.OUTLINE}
                        fontSize={MobergFontSize.LARGE}
                        shape={MobergButtonShape.WIDE}
                        disabled={saveTask.isWaiting}
                        onClick={close}
                    >
                        Cancel
                    </MobergButton>

                    <MobergRow gap="8px" expand={false}>
                        { saveTask.isWaiting && (
                            <MobergText> Please wait... </MobergText>
                        )}

                        <MobergButton
                            theme={MobergTheme.BLUE}
                            variant={MobergButtonVariant.FILLED}
                            fontSize={MobergFontSize.LARGE}
                            shape={MobergButtonShape.WIDE}
                            disabled={saveTask.isWaiting}
                            onClick={saveTask.run}
                        >
                            Save
                        </MobergButton>
                    </MobergRow>
                </ModalFooter>
            </MontageModalContainer>
        </>
    )
}

export default MontageModal

const MontageModalContainer = styled.div`
    overflow: hidden;
    width: 1400px;
`;

const MontageModalContent = styled.div`
    padding: 0px;
    display: inline-flex;
    flex-direction: row;
    width: 100%;
`;

const LeftColumn = styled.div`
   
    display: flex;
    flex-direction: column;
    height: auto;
    width: 70%;
    background-color: #fff;
`;

const RightColumn = styled.div`
    background-color: #fff;
    border: 1px solid #dedede;
    border-bottom: none;
    margin-top: 4px; 
    width: 30%;
`;

const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
    p {
        color: #293241;
        font-family: 'Source Sans Pro';
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
        white-space: nowrap;
        margin: 0;
    }
    h2 {
        color: rgba(174,183,198,0.66);
        text-align: center;
        font-family: 'Source Sans Pro';
        font-size: 16px;
        font-style: italic;
        font-weight: 600;
    }
`;

const CanvasContainer = styled.div`
    position: relative;
    width: 650px;
    height: fit-content;
    scale: 1.1;
    margin-top: 20px;
    & > canvas {
    transition: opacity 1s ease-in-out;
}
`;

const Button = styled.button`
    position: absolute;
    border: ${props => {
        if (props.isSelected && props.isExternalPair) {
            return '1px solid black';
        }

        if (props.isRefPair) {
            return '1px solid black';
        }
        else if (props.isExternalPair && !props.isRefPair) {
            return '1px solid black';
        }
        else {
            return '1px solid #207DEA';
        }
    }};
    border-radius: 50%;

    width: 33px;
    height: 33px;
    background: white;
    color: #207DEA;
    font-weight: 700;
    font-family: "Montserrat";
    background: ${props => {
        if (props.isActive) {
            return '#207DEA';
        } else {
            return 'white';
        }

    }};
    color: ${props => props.isActive ? 'white' : "#207DEA"};
    outline: 2px solid transparent;
    transition: background-color 0.4s, color 0.4s, opacity 0.4s ease-in-out;
    opacity: ${props => props.opacity * props.transitionOpacity};
    cursor: pointer;
    &:hover {
        background: #69a7f0;
        color: white;
    }
`;

const ChannelListContainer = styled.div`
    box-shadow: 0px 0px 2.8330955505371094px 0px rgba(99, 191, 244, 0.15);
    width: 100%;
    border-radius: 6px;
    max-height: 520px;
    height: 450px;
`;

const ChannelListUL = styled.ul`
    list-style: none;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 520px;
    height: 450px;
    width: 100%;
    padding: 0px;
    margin: 0;
    scrollbar-width: thin;

    ::-webkit-scrollbar {
            display: block;
            width: 5px;
            color: #313A4A;
        }

    ::-webkit-scrollbar-track {
            background: #BEC4CF;
            width: 5px;
            border-radius: 2px;
        }

`;
