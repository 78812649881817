import React, { useState } from "react"
import { SortableElement, SortableContainer } from "react-sortable-hoc"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { MobergRow } from "../../../Moberg"
import { layoutGroupsAtom, selectedLayoutAtom, selectedLayoutGroupAtom } from "../../../Pages/Data/Visualize/DataReview/Atoms/Layout"
import { TabButton } from "./TabButton"
import { Layout } from "../../../Pages/Data/Visualize/DataReview/Types/Layout"
import { useLayoutService } from "../../../Hooks/useLayoutService"

type SortEndEvent = {
	oldIndex: number
	newIndex: number
}

type BeforeSortEvent = {
	index: number
}

type SortableTabButtonProps = {
    layout: Layout
	sortingLayoutId?: string
}

type SortableTabsProps = {
	sortingLayoutId?: string
    layouts: Layout[]
}

export const SortableTabs = () => {
	const [sortingLayoutId, setSortingLayoutId] = useState<string>()
	const setLayoutGroups = useSetRecoilState(layoutGroupsAtom)
	const { modifyLayoutGroup  } = useLayoutService()
	const selectedLayoutGroup = useRecoilValue(selectedLayoutGroupAtom) 

	const beforeSort = ({ index }: BeforeSortEvent) => {
		setSortingLayoutId(selectedLayoutGroup?.layouts[index].id)
	}

	const onSortEnd = ({ oldIndex, newIndex }: SortEndEvent) => {
		handleSortEnd(oldIndex, newIndex)
		setSortingLayoutId("")
	}
	
	const handleSortEnd = (oldIndex: number, newIndex: number) => {
		const newLayouts = [...(selectedLayoutGroup?.layouts ?? [])]
		const [element] = newLayouts.splice(oldIndex, 1);
		newLayouts.splice(newIndex, 0, element);
		
		const newLayoutGroup = {
			...selectedLayoutGroup,
			layouts: newLayouts
		}

		setLayoutGroups(previous => previous.map(layoutGroup => {
			if (layoutGroup.id === selectedLayoutGroup?.id) {
				const copy = {...layoutGroup}
				copy.layouts = newLayouts
				return copy
			}

			return layoutGroup
		}))

		return new Promise((resolve, reject) => {
			if (selectedLayoutGroup) {
				modifyLayoutGroup(selectedLayoutGroup.id, newLayoutGroup).then(resolve)
			} else {
				reject("No display group was selected.")
			}
		})
	}

	return <SortableTabList 
		axis={"x"} 
		pressDelay={100} 
		layouts={selectedLayoutGroup?.layouts ?? []}
		updateBeforeSortStart={beforeSort}
		onSortEnd={onSortEnd}
		sortingLayoutId={sortingLayoutId}
	/> 
}

const SortableTabButton = SortableElement(({ layout, sortingLayoutId }: SortableTabButtonProps) => {
	const selectedLayout = useRecoilValue(selectedLayoutAtom)

	return (
		<li style={{ listStyleType: "none", whiteSpace: "nowrap", zIndex: 10000, margin: "0 8px" }}>
			<TabButton 
				layout={layout}
				isActive={layout.id === selectedLayout?.id} 
				isDragging={sortingLayoutId === layout.id}
			/>
		</li>
	)
})

const SortableTabList = SortableContainer(({ layouts, sortingLayoutId }: SortableTabsProps) => {
	const selectedLayoutGroup = useRecoilValue(selectedLayoutGroupAtom) 

	return (
		<MobergRow>
			{layouts.map((layout: Layout, index: number) => (
				<SortableTabButton 
					key={`Tab_${selectedLayoutGroup?.id}_${layout.id}`}
					index={index} 
					layout={layout}
					sortingLayoutId={sortingLayoutId}
				/>)
			)}
		</MobergRow>
	)
})
