import styled from "styled-components";
import * as MdIcons from "react-icons/md";
import { HotKeySectionHeaders } from "../HotkeyEditRow/HotKeySectionHeaders";
import { HighlightOnHover } from "../HighlightOnHover/HighlightOnHover";
import { DEFAULT_ANNOTATION_PROPERTIES } from "../../Managers/VisualizationManager/Variables/Annotations";
import { AnnotationInfoSummary } from "./AnnotationInfoSummary"
import { hotkeySections } from "../../Pages/Data/Visualize/DataReview/Types/KeyboardShortcut";

export const Modes = {
    CREATE: "CREATE",
    EDIT: "EDIT"
}

export const CustomAnnotationHotkeys = ({ index, buildHotkeyEditRows, openCustomAnnotationModal, hotkeyConfigs }) => {

    const annotationConfigs = hotkeyConfigs.filter(
        config => config.section === hotkeySections.CUSTOM_ANNOTATIONS
        ).sort((a, b) => {
            const a_id = a.id.split("_")
            const b_id = b.id.split("_")
            if(a_id[a_id.length-2] === b_id[b_id.length-2]){
                return (parseInt(a_id[a_id.length-1]) > parseInt(b_id[b_id.length-1]) ? 1 : -1)
            }else{return (a_id[a_id.length-2] === "new" ? 1 : -1)}
        })

    function buildCustomAnnotationModalOpenButton(config) {
        config.data.id=config.id
        return (
            <HighlightOnHover style={{ cursor: "pointer", padding: "0 10px", marginLeft: -10}} key={config.id}>
                <div style={{border: "none", background: "transparent", height: "45px", width: "235px", padding: "10px 0px"}}
                    onClick={() => {openCustomAnnotationModal(config, Modes.EDIT)}}>
                    <AnnotationInfoSummary annotationInfo={config.data}/>
                </div>
            </HighlightOnHover>
        )
    }

    return <>
            <HotKeySectionHeaders index={index} title="Custom Annotations" />

            {buildHotkeyEditRows(annotationConfigs, annotationConfigs.map(buildCustomAnnotationModalOpenButton))}

            <IconButton onClick={
                () => {openCustomAnnotationModal({data: DEFAULT_ANNOTATION_PROPERTIES}, Modes.CREATE);}}>
                <MdIcons.MdAdd size={22} style={{color: "#5A6679", cursor: 'pointer'}}/>
            </IconButton>
    </>
}

const IconButton = styled.button`
	width: 26px;
    margin: 10px;
	height: 26px;
	border-radius: 3px;
	border: none;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #000000;
`;