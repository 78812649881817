import { ImagePage } from "../../../../Data/ImagePage"
import { TitlesAndLabels } from "../../../../Data/PersystPageManager"
import { PersystTrendsImagePageManager, PersystTrendsPageManagerConfig } from "../../../../Data/PersystTrendsImagePageManager"
import { DataSource } from "../../../../Types/DataSource"
import { MarginedBoundingBox } from "../../../../Types/MarginedBoundingBox"
import { ModalityDataSource } from "../../../../Types/ModalityDataSource"
import { Offset } from "../../../../Types/Offset"
import { PersystTrendsWindowConfig } from "../../../../Types/PersystWindow"
import { PersystTrendsReactCallbacks } from "../../../../Types/ReactCallbacks"
import { LEFT_MARGIN, RIGHT_MARGIN } from "../../Constants"
import { D3TimeBasedVisualization } from "../../D3TimeBasedVisualization"
import { D3PersystWindowConfigurationBuilder } from "./D3PersystTrendsWindowConfigurationBuilder"
import { D3PersystWindowRenderer } from "./D3PersystTrendsWindowRenderer"

export class D3PersystTrendsWindow extends D3TimeBasedVisualization<PersystTrendsWindowConfig, PersystTrendsReactCallbacks, D3PersystWindowRenderer, PersystTrendsImagePageManager> {
	public boundingBox: MarginedBoundingBox
	private margins: Offset = { top: 30, left: LEFT_MARGIN, bottom: 90, right: RIGHT_MARGIN }

	public overlayBoundingBox: MarginedBoundingBox
	public overlayMargins = { top: 0, left: 0, bottom: 0, right: 0 }

	constructor(root: HTMLDivElement, config: PersystTrendsWindowConfig, pageManager: PersystTrendsImagePageManager, reactCallbacks: PersystTrendsReactCallbacks) {
		super(root, config, pageManager, reactCallbacks)
		this.boundingBox = new MarginedBoundingBox(config.dimensions, this.margins)
		this.overlayBoundingBox = new MarginedBoundingBox(config.dimensions, this.overlayMargins)

		this.mount(new D3PersystWindowRenderer(this, new D3PersystWindowConfigurationBuilder(this), "d3-persyst-trends-window"))
	}

	public getPageHeight(): number {
		return this.overlayBoundingBox.height
	}

	public getTitlesAndLabels(): TitlesAndLabels | null {
		if (this.renderer) {
			this.timeSeriesPageManager.onTitlesAndLabelsLoaded = this.renderer.onTitlesAndLabelsLoaded
		}

		return this.timeSeriesPageManager.getTitlesAndLabels(this.boundingBox.height, this.boundingBox.width, "VsBaseline Comprehensive")
	}

	public resetTitlesAndLabels = () => {
		this.timeSeriesPageManager.resetTitlesAndLabels()
		this.getTitlesAndLabels()
	}

	public getOverlay = () => this.renderer?.overlay

	public getLastHoveredDate = () => this.renderer?.getLastHoveredDate()

	public getPageManagerConfig = (): PersystTrendsPageManagerConfig => {
		return {
			patientId: this.config.patientId,
			windowId: this.config.id,
			viewScale: this.config.viewScale,
			fileScale: this.config.fileScale,
			modalityDataSources: this.getModalityDataSources(),
			height: this.overlayBoundingBox.height,
			artifactReductionEnabled: this.config.artifactReductionEnabled,
			timeZone: this.config.timeZone,
			patientIsAdmitted: this.config.patientIsAdmitted
		}
	}

	public getVisibleTraces(): any {
		alert("NOT IMPLEMENTED!!")
	}

	protected renderPage(page: ImagePage): void {
		this.renderer?.renderPage(page)
	}

	protected getModalityDataSources(): ModalityDataSource[] {
		return [{
			modality: "VsBaseline Comprehensive",
			dataObjectId: this.reactCallbacks.dataSourceMap.get(DataSource.CURRENT_PATIENT) as number,
		}]
	}

	protected updateDerivedState(): void {
		this.boundingBox.setDimensions(this.config.dimensions)
		this.overlayBoundingBox.setDimensions({ width: this.boundingBox.width, height: this.boundingBox.height })
		this.config.viewScale.range([0, this.boundingBox.width])
	}

}
