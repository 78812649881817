import { USER_INPUT_TYPES } from "../../../../../Constants/UserInputTypes";
import CheckboxInput from "./CheckboxInput";
import DropdownInput from "./DropdownInput"
import DurationInput from "./DurationInput";
import MultiSelectionDropdownInput from "./MultiSelectionDropdownInput";
import NumberInput from "./NumberInput";
import TextInput from "./TextInput";
import UserInputWrapper from "./UserInputWrapper";

export default class UserInput {
    static fromConfig(config, key, props) {
        let input;

        if (!props) {
            props = {}
        }

        switch(config.TYPE) {
            case USER_INPUT_TYPES.NUMBER_INPUT:
                input = NumberInput({...props, config})
                break
            case USER_INPUT_TYPES.DROPDOWN_INPUT:
                input = DropdownInput({...props, config})
                break
            case USER_INPUT_TYPES.MULTI_SELECTION_DROPDOWN_INPUT:
                input = MultiSelectionDropdownInput({...props, config})
                break                
            case USER_INPUT_TYPES.DURATION_INPUT:
                input = DurationInput({...props, config})
                break
            case USER_INPUT_TYPES.CHECKBOX_INPUT:
                input = CheckboxInput({...props, config})
                break
            case USER_INPUT_TYPES.TEXT_INPUT:
                input = TextInput({...props, config})
                break
            default:
                return null
        }

        return <UserInputWrapper key={key}>{input}</UserInputWrapper>
    }
}