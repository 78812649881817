import { useModalProvider } from '../../../../../Providers/ModalProvider';
import { ConfirmationModalContainer, ConfirmationModalContent} from '../../../../../Constants/StyledComponentsConfirmationModals';
import * as MdIcons from 'react-icons/md';
import { useHotkeysProvider } from '../../../../../Providers/HotkeysProvider';
import ConfirmationModalButton, { buttonColor } from '../../../../../Components/ConfirmationModalButton/ConfirmationModalButton';

const DeleteKeyboardShortcutModal = () => {
    const hotkeysProvider = useHotkeysProvider()

    const { close } = useModalProvider()

    function handleDeleteAnnotation() {
        hotkeysProvider.setDeleteAnnotations(true)
        close()
    }

    return (
        <ConfirmationModalContainer>
            <ConfirmationModalContent>
                <MdIcons.MdOutlineError size={81} style={{color: "#AEB7C6", marginBottom: "20px"}}/>
                <h1 style={{fontFamily: 'Montserrat', fontStyle: "normal", fontWeight: "700", fontSize: "24px", lineHeight: "110%", color: "#000000"}}>Delete shortcut?</h1>
                <p>You are about to delete the selected annotation shortcut.</p>
                <p>This action can not be undone.</p>
            </ConfirmationModalContent>

            <ConfirmationModalButton
                buttonText="Yes, delete shortcut"
                color={buttonColor.redFill}
                onClickFxn={() => {handleDeleteAnnotation()}}
                buttonStyle={{marginTop: "18px"}}
            />

            <ConfirmationModalButton
                buttonText="Cancel"
                color={buttonColor.blueOutline}
                onClickFxn={() => {close()}}
                buttonStyle={{marginTop: "18px"}}
            />
        </ConfirmationModalContainer>
    )
}


export default DeleteKeyboardShortcutModal;