import { NODE_DEFINITIONS } from "../../Constants/NodeStructures"
import Node from "./Node"
import VisualizationManager from "../VisualizationManager/VisualizationManager"
import { LINKS } from '../../Constants/BackendLinks'
import ConnectionManager from "./ConnectionManager.ts"

/**
 * Class representing a single pipeline 
 * This will have a list of nodes
 */
export default class Pipeline {
    // name: string
    // description: string
    // version: string
    // nodes: Node[]
    // obj: Object
    // task_id: string
    // results: Object

    /**
     * Construct a pipeline
     * @constructor
     */
    constructor() {
        this.name           = "New Pipeline"
        this.description    = "Pipeline Description"
        this.version        = "1.0.0"
        this.nodes          = []
        this.obj            = {}
        this.task_id        = ""
        this.results        = {}
    }

    // Creates a Pipeline Object from the state of the PipelineCreate page
    static fromState(state) {
        console.log({state})
        const pipeline = new Pipeline()
        pipeline.nodes = Object.keys(state.nodes).map((nodeId) => {
            const integerNodeId = parseInt(nodeId.split("node")[1])
            const { type, subtype, coords } = state.nodes[nodeId]
            const node = new Node(integerNodeId, type, subtype, coords)

            // Ensures input values are captured when reading directly from a JSON state. 
            Object.keys(state.nodes[nodeId].args).forEach(arg => {
                node.inputs.forEach(config => {
                    if (config.VARIABLE_NAME === arg) {
                        config.VALUE = state.nodes[nodeId].args[arg]
                    }
                })
            })

            node.previous = ConnectionManager.getPreviousNodeIds(nodeId, state.connections)
            return node
        })

        return pipeline
    }

    // TODO: Broken (Node constructor got an ID parameter)
    // /**
    //  * Add a new node to the pipeline
    //  * @param {String} node_type - The type of input can be either ["SOURCE", "ANALYTIC", "VISUALIZATION"]
    //  * @param {String} subtype - The specific kind of node underneath the above category
    //  * @param {number[]} position - An array containg [x, y] to represent the new position of a node in the pipeline display
    //  */
    // addNode(node_type: string, subtype: string, position=[0,0]) {
    //     this.nodes.push(new Node(node_type, subtype, position))
    // }

    // TODO: Broken (index does not == nodeID)
    // /**
    //  * Delete a node from the pipeline
    //  * @param {Integer} id - Represents the index of a node in the nodes array
    //  */
    // deleteNode(id: number) {
    //     this.nodes = 
    // }

    
    // TODO: Broken (index does not == nodeID)
    // /**
    //  * Sets a node to a new position
    //  * @param {Integer} id - Represents the index of a node in the nodes array
    //  * @param {Array} position - An array containg [x, y] to represent the new position of a node in the pipeline display
    //  */
    // setNodePosition(id, position) {
    //     this.nodes[id].position = position
    // }

    // TODO: What's missing is an importPipeline function to load it in from a JSON format


    jsonifyPipeline() {
        let output = {
            "name": this.name,
            "description": this.description,
            "version": this.version,
            "nodes": {},
            "architecture": {}
        }

        // Fill out node structures and architectures
        this.nodes.forEach(node => {
            output["nodes"]["node" + node.id] = node.exportStructure()

            if (node.type.toUpperCase() !== NODE_DEFINITIONS.SOURCE) {
                output["architecture"]["node" + node.id] = node.exportArchitecture()
            } 
        })

        return output
    }


    savePipeline() {
        let output = this.jsonifyPipeline()
        console.log(output)

        fetch(`${LINKS.MAPAI.SAVE}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': ''
            },
            body: JSON.stringify({
                "pipeline": output,
                "name": this.name
            })})
        .then(response => {
            return response.json()
        })
        .then(data => {
            console.log(data)
        });
    }


    /**
     * Used to export the JSON formatted pipeline
     */
    executePipeline() {
        let output = this.jsonifyPipeline()

        // TODO Export output to backend instead of printing
        console.log(output)
        fetch(`${LINKS.MAPAI.EXECUTE}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json', 
                'Authorization': ''
            },
            body: JSON.stringify(output)
        }).then(response => {
            return response.json()
        }).then(data => {
            console.log(data["task_id"])
            this.task_id = data["task_id"]
            this.getPipelineStatus()
        });
    }

    getPipelineStatus() {

        let parent = this

        setTimeout(function() { 
            try {
                fetch(`${LINKS.MAPAI.STATUS}?task_id=${parent.task_id}`, {
                    headers: {
                        'Authorization': ''
                    }
                }).then(response => {
                    return response.json()
                }).then(data => {
                    console.log(data['status'])
                    if (data['status'] !== 'PENDING') {
                        return parent.getPipelineData()
                    } else {
                        return parent.getPipelineStatus()
                    }
                })
            } catch(e) {
                return e;
            }
        }, 3000);

    }

    getPipelineData() {

        let parent = this

        try {
            fetch(`${LINKS.MAPAI.DATA}?task_id=${this.task_id}`, {
                headers: {
                    'Authorization': ''
                }
            }).then(response => {
                return response.json()
            }).then(data => {
                console.log(data)
                parent.results = data['results']
                for (let result of Object.keys(data['results'])) {
                    // console.log(parent.results[result])
                    if (parent.results[result].type === 'interval') {
                        VisualizationManager.resultsToAnnotation(parent.results[result], true)
                    } 
                }
                return data
            })
        } catch(e) {
            return e;
        }
    }

    deletePipelineData() {
        try {
            fetch(`${LINKS.STATUS.DELETE}?task_id=${this.task_id}`, {
                    headers: {
                        'Authorization': ''
                    }
            }).then(response => {
                return response.json()
            }).then(data => {
                console.log(data)
            })
        } catch(e) {
            return e;
        }
    }
}

