import React, { createContext, useState, useContext } from "react"
import { MobergTheme, MobergThemeColors, getColors } from "../Moberg"

type MobergThemeContextType = {
	colors: MobergThemeColors
	setTheme: React.Dispatch<React.SetStateAction<MobergTheme>>
}

const MobergThemeContext = createContext<MobergThemeContextType>({} as MobergThemeContextType)

export const MobergThemeProvider: React.FC<MobergThemeContextType> = ({ children }) => {
	const [theme, setTheme] = useState<MobergTheme>(MobergTheme.BLUE)

	return <MobergThemeContext.Provider value={{colors: getColors(theme), setTheme}}>
        {children}
    </MobergThemeContext.Provider>
}

export const useMobergTheme = () => useContext(MobergThemeContext)
