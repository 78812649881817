import React from "react"
import { BaseVisualizationConfigureModal } from "../../../Modals/BaseVisualizationConfigureModal"
import { MobergDropdown } from "../../../../../../../../Moberg"
import { CPPOPT_WINDOW_TIME_PRESETS } from "../../../../../../../../Managers/VisualizationManager/Viewport/Components/XAxis"
import { cppOptPlotConfigsAtom } from "../../../../Atoms/CPPOpt"
import { useEditWindow } from "../../../Modals/useEditWindow"
import { CPPOptPlotJSON } from "../../../../Types/CPPOptPlot"
import { ElementConfigureDiv } from "../../../../../../../../Constants/StyledComponents"

type CPPOptPlotConfigureModalProps = {
	windowId: string
	layoutId: string
}

export const CPPOptPlotConfigureModal = (props: CPPOptPlotConfigureModalProps) => {
	const atom = cppOptPlotConfigsAtom({windowId: props.windowId, layoutId: props.layoutId})
	const { editedConfig, saveChanges, updateProperty } = useEditWindow<CPPOptPlotJSON>({ recoilState: atom, windowId: props.windowId, layoutId: props.layoutId })
    
	return (
		<BaseVisualizationConfigureModal layoutId={props.layoutId} windowId={props.windowId} title={"Configure"} saveChanges={saveChanges}>
			<div style={{ height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
				<ElementConfigureDiv style={{ width: '300px' }}>
					Initial Page Size

					<MobergDropdown
						selectedValue={editedConfig?.viewDuration}
						onChange={value => updateProperty("viewDuration", parseInt(value as string))}
						options={CPPOPT_WINDOW_TIME_PRESETS
							.filter(preset => preset.label !== "Page Size")
							.map(preset => ({ label: preset.label, value: preset.time }))}
					/>
				</ElementConfigureDiv>
			</div>
		</BaseVisualizationConfigureModal>
	)
}
