import React from "react"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import { LEFT_MARGIN, RIGHT_MARGIN } from "../../Constants"

export const CPPOptTable = () => {
	const columns = ["Value", "Average (Last Hour)", "Current Value"]

	const rows = [
		{ label: "CPP", averagePrevious1Hr: 60, units: "mmHg", currentValue: 54 },
		{ label: "CPPOpt", averagePrevious1Hr: 67, units: "mmHg", currentValue: 66 },
		{ label: "Weight", averagePrevious1Hr: 0.435, units: "", currentValue: 0.7704 },
		{ label: "dCPP", averagePrevious1Hr: -7, units: "mmHg", currentValue: -12 },
	]

	return (
		<TableContainer sx={{ paddingRight: `${RIGHT_MARGIN - 1}px`, paddingLeft: `${LEFT_MARGIN - 1}px` }}>
			<Table aria-label="simple table" size="small">
				<TableHead>
					<TableRow>
                        {columns.map((column, index) => (
							<TableCell key={column} sx={{ fontWeight: "bold", textAlign: "right",
								"&:first-child": { textAlign: "left", paddingLeft: 0 },
								"&:last-child": { paddingRight: 0 },
								}}>
								{column}
							</TableCell>
						))}
					</TableRow>
				</TableHead>
				<TableBody>
					{rows.map(row => (
						<TableRow key={row.label} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
							<TableCell component="th" scope="row" sx={{ paddingLeft: 0 }}>
								{row.label}
							</TableCell>
							<TableCell align="right">{row.averagePrevious1Hr} {row.units} </TableCell>
							<TableCell align="right" sx={{ paddingRight: 0 }}>{row.currentValue} {row.units} </TableCell>
						</TableRow>
                    ))}
				</TableBody>
			</Table>
		</TableContainer>
	)
}
