import React from "react"
import { MobergButton, MobergButtonShape } from "../MobergButton/MobergButton"

type MobergMenuItemProps = {
    disabled?: boolean
    tooltip?: string
    onClick: (event: any) => void
}

export const MobergMenuItem: React.FC<MobergMenuItemProps> = ({ children, disabled, tooltip, onClick }) => {
    return (
        <MobergButton shape={MobergButtonShape.FULL_WIDTH} onClick={onClick} disabled={disabled} tooltip={tooltip}
            style={{ justifyContent: "start", padding: "8px", paddingRight: "16px", borderRadius: 0, gap: "16px" }}>
            {children}
        </MobergButton>
    )
}