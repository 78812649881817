import React from "react"
import { OnDemandAnalysis } from "../../../Types/AnalysisDetails"
import { useModalProvider } from "../../../../../../../Providers/ModalProvider"
import { MobergButton, MobergButtonShape, MobergButtonVariant, MobergColumn, MobergHeader, MobergIconSize, MobergRow, MobergTheme } from "../../../../../../../Moberg"
import { MdClose } from "react-icons/md"
import { Scrollbar } from "../../../../../../../Constants/StyledComponents"
import { getAnalysisEditor } from "./AnalysisEditorFactory"

type EditAnalsysisModalProps = {
	analysis: OnDemandAnalysis
	onChange: (analysis: OnDemandAnalysis) => void
}

export const EditAnalysisModal: React.FC<EditAnalsysisModalProps> = ({ analysis, onChange }) => {
	const { close } = useModalProvider()

	return (
		<MobergColumn gap="32px" style={{ width: "70vh", maxHeight: "95vh", padding: "16px" }}>

			<MobergRow horizontalAlign="center">
				<MobergHeader>
					Edit PRx Calculation
				</MobergHeader>

				<MobergButton shape={MobergButtonShape.SQUARE} onClick={close} style={{ position: "absolute", top: "10px", right: "10px"}}>
					<MdClose size={MobergIconSize.REGULAR} />
				</MobergButton>
			</MobergRow>

			<Scrollbar style={{ flex: 1, padding: "0 16px" }}>
				{getAnalysisEditor(analysis, onChange)}
			</Scrollbar>

			<MobergRow horizontalAlign="right">
				<MobergButton
					variant={MobergButtonVariant.FILLED}
					theme={MobergTheme.BLUE}
					onClick={close}
				>
					OK
				</MobergButton>
			</MobergRow>

		</MobergColumn>
	)
}
