import React, { useEffect, createContext, useState, useMemo, useContext } from 'react';

// Create a context object
const UploadIntoQueueContext = createContext();

// Create a provider component
export const UploadIntoQueueProvider = ({ children }) => {

    const [uploadData, setUploadData] = useState()
    const [takenDirectoryNames, setTakenDirectoryNames] = useState([]);
    const [openQueue, setOpenQueue] = useState(false);
    const [openLoading, setOpenLoading] = useState(false);
    const [uploadID, setUploadID] = useState();
    const [folderID, setFolderID] = useState();
    const [form, setForm] = useState();

    const undoExistingValidation = (canceledDataID) => {
        setTakenDirectoryNames((prevDirectoryNames) =>
            prevDirectoryNames.filter((directory) => directory.id !== canceledDataID)
        );
    };

    return (
        <UploadIntoQueueContext.Provider value={{ uploadData, setUploadData, takenDirectoryNames, setTakenDirectoryNames, openQueue, setOpenQueue, openLoading, setOpenLoading, uploadID, setUploadID, undoExistingValidation, folderID, setFolderID, form, setForm}}>
        {children}
        </UploadIntoQueueContext.Provider>
    );
};

export const useUploadIntoQueueProvider = () => {
    return useContext(UploadIntoQueueContext)
}