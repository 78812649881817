import React from 'react';
import styled from 'styled-components';
import * as MdIcons from 'react-icons/md';
import loadingSpinner from '../../Constants/Graphics/loadingSpinner.svg'
import { DEFAULTS } from '../../Constants/Formatting';
import {useRecoilValue} from "recoil"
import { fileHashed, fileUploaded, totalHashFiles, totalUploadFiles, uploadFailed } from '../../recoil_state';


const VerticalTimeline = ({timelineData}) => {
// const sampleData = [
//   {
//     title: "Hashing",
//     status: "(1/2)",
//     description: "",
//     complete: false,
    //   progressStarted: true
//   },
//   {
//     title: "Uploading",
//     status: "(2/2)",
//     description: "(Hashing ABP, Syst, NumericQuality, Integer, IntelliVue, Data",
//     complete: false,
    //   progressStarted: false
//   }
// ]

// use sampleData as a guideline to the expected formatting for the uploadData prop
// any fields that are not required or blank can be left as an empty string ""

const stepInProgressIcon = <img src={loadingSpinner} alt="uploading" style={{width: "24px", marginRight:"-0.4px", marginTop: "1px"}}/>

const stepCompletedIcon = <MdIcons.MdCheck/>
const stepFailedIcon =  <MdIcons.MdClose/>

const recoilFileHashed = useRecoilValue(fileHashed)
const recoilFileUploaded = useRecoilValue(fileUploaded)
const recoilTotalHashFiles = useRecoilValue(totalHashFiles)
const recoilTotalUploadFiles = useRecoilValue(totalUploadFiles)
const recoilUploadFailed = useRecoilValue(uploadFailed)

return (
    <VerticalTimelineDiv>

        <TimelineItem backgroundColor={recoilUploadFailed ? "#E54E58" : (timelineData[0].complete ? DEFAULTS.TIMELINE_BACKGROUND_COMPLETE : DEFAULTS.TIMELINE_BACKGROUND_INCOMPLETE)}>
        <TimelineCircle style={{backgroundColor: recoilUploadFailed ? "#E54E58" : (timelineData[0].complete ? DEFAULTS.INDEX_BACKGROUND_COMPLETE : DEFAULTS.INDEX_BACKGROUND_INCOMPLETE)}} borderColor={recoilUploadFailed ? "#E54E58" :(timelineData[0].progressStarted ? `2px solid ${DEFAULTS.INDEX_BACKGROUND_COMPLETE}` : `2px solid ${DEFAULTS.TIMELINE_BACKGROUND_INCOMPLETE}`)}>
            {recoilUploadFailed ? stepFailedIcon :(timelineData[0].complete ? stepCompletedIcon : timelineData[0].progressStarted ? stepInProgressIcon : " ")}
        </TimelineCircle>
        <TimelineContent>
            <TimelineTitle style={{color: recoilUploadFailed ? "#E54E58" : (timelineData[0].complete ? DEFAULTS.INDEX_BACKGROUND_COMPLETE : "rgba(95, 103, 117, 0.5)")}}>
            {timelineData[0].title} {`${recoilFileHashed}/${recoilTotalHashFiles} files`}
            </TimelineTitle>
            <TimelineDescription
            style={{
                color: recoilUploadFailed
                ? "#E54E58"
                : timelineData[0].complete
                ? DEFAULTS.INDEX_BACKGROUND_COMPLETE
                : "rgba(95, 103, 117, 0.25)",
            }}
            >
            {timelineData[0].description}
            </TimelineDescription>
        </TimelineContent>
        </TimelineItem>

        <TimelineItem backgroundColor={recoilUploadFailed ? "#E54E58" :(timelineData[1].complete ? DEFAULTS.TIMELINE_BACKGROUND_COMPLETE : DEFAULTS.TIMELINE_BACKGROUND_INCOMPLETE)}>
        <TimelineCircle style={{backgroundColor: recoilUploadFailed ? "#E54E58" :(timelineData[1].complete ? DEFAULTS.INDEX_BACKGROUND_COMPLETE : DEFAULTS.INDEX_BACKGROUND_INCOMPLETE)}} borderColor={recoilUploadFailed ? "#E54E58" : (timelineData[1].progressStarted ? `2px solid ${DEFAULTS.INDEX_BACKGROUND_COMPLETE}` : `2px solid ${DEFAULTS.TIMELINE_BACKGROUND_INCOMPLETE}`)}>
            {recoilUploadFailed ? stepFailedIcon : (timelineData[1].complete ? stepCompletedIcon : timelineData[1].progressStarted ? stepInProgressIcon : " ")}
        </TimelineCircle>
        <TimelineContent>
            <TimelineTitle style={{color: recoilUploadFailed ? "#E54E58" : (timelineData[1].complete ? DEFAULTS.INDEX_BACKGROUND_COMPLETE : "rgba(95, 103, 117, 0.5)")}}>
            {timelineData[1].title} {`${recoilFileUploaded}/${recoilTotalUploadFiles} files`}
            </TimelineTitle>
            <TimelineDescription
            style={{
                color: recoilUploadFailed
                ? "#E54E58"
                : timelineData[1].complete
                ? DEFAULTS.INDEX_BACKGROUND_COMPLETE
                : "rgba(95, 103, 117, 0.25)",
            }}
            >
            {timelineData[1].description}
            </TimelineDescription>
        </TimelineContent>
        </TimelineItem>
    </VerticalTimelineDiv>
);
};

const VerticalTimelineDiv = styled.div`
display: flex;
flex-direction: column;
align-items: center;
margin-top: 40px;
`;

const TimelineItem = styled.div`
display: flex;
align-items: center;
width: 100%;
margin-bottom: 30px;
position: relative;

&:before {
    content: '';
    position: absolute;
    top: 29px;
    left: 15px;
    transform: translateX(-50%);
    width: 10px;
    height: 60px;
    background-color: ${props => props.backgroundColor || "#207DEA"};
}

&:last-child:before {
    display: none;
}
`;

const TimelineCircle = styled.div`
display: flex;
align-items: center;
justify-content: center;
width: 30px;
height: 30px;
border-radius: 50%;
border: ${props => props.borderColor || "2px solid #207DEA"};
background-color: #FCFCFC;
color: #fff;
font-size: 18px;
font-weight: bold;
z-index: 2;
`;

const TimelineContent = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
margin-left: 10px;
margin-top: -5px;
`;

const TimelineTitle = styled.h2`
margin: 0;
font-size: 16px;
font-family: 'Source Sans Pro';
font-style: normal;
font-weight: 700;
line-height: 24px;
`;

const TimelineDescription = styled.p`
margin: 0;
font-family: 'Source Sans Pro';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 19px;
max-width: 600px;
overflow-x: hidden;
text-overflow: ellipsis;
white-space: nowrap;
`;

export default VerticalTimeline;
