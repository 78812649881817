import React, { Dispatch, SetStateAction, createContext, useContext, useState } from "react"

type WorkspaceContextValue = {
	selectedWorkspace: string
	setSelectedWorkspace: Dispatch<SetStateAction<string>>
}

const WorkspacesContext = createContext<WorkspaceContextValue>({} as any)

export const WorkspacesProvider: React.FC = ({ children }) => {
	const [selectedWorkspace, setSelectedWorkspace] = useState(window.env.REACT_APP_WORKSPACE_NAME)

	return (
		<WorkspacesContext.Provider value={{ selectedWorkspace, setSelectedWorkspace }}>
			{children}
		</WorkspacesContext.Provider>
	)
}

export const useWorkspacesProvider = () => useContext(WorkspacesContext)
