import { useState, useEffect } from "react"
import styled from "styled-components"
import { LINKS } from "../../../Constants/BackendLinks"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Popover from "react-bootstrap/Popover"
import * as MdIcons from "react-icons/md"
import React from "react"
import { useStudiesProvider } from "../../../Providers/StudiesProvider"
import { useEndpointProvider } from "../../../Providers/EndpointProvider"
import { useSitesProvider } from "../../../Providers/SitesProvider"
import { InfoMessage } from "../../../Constants/StyledComponents"
import { useModalProvider } from "../../../Providers/ModalProvider"
import PostModifyModal from "./PostModifyModal"
import ModifyPatientModal from "./ModifyPatientModal"
import ModalHeader from "../../../Components/ModalHeader/ModalHeader"
import { TableComponent, RowsContainer, Pagination } from "../../../Components/TableComponent/TableComponent"

function ModifySubModal({ move, currentPatientID, currentSiteID, files }) {
	const sitesProvider = useSitesProvider()
	const { createModal, openNext, close } = useModalProvider()
	const endpointProvider = useEndpointProvider()
	const studiesProvider = useStudiesProvider()

	const popoverTopText = {
		patientID: "A four digit unique identifier corresponding to each patient.",
		siteID: "A four digit unique code corresponding to each site.",
	}

	function getPopoverTop(labelname) {
		return (
			<Popover id="Popover-trigger-hover-focus" style={{ width: "228px" }} positionleft={75}>
				<p style={{ fontFamily: "Source Sans Pro", fontStyle: "normal", fontWeight: "400", fontSize: "12px", color: "#5F6775", paddingLeft: "3px" }}>
					{popoverTopText[labelname]}
				</p>
			</Popover>
		)
	}

	const [siteID, setSiteID] = useState(undefined)
	const [patientID, setPatientID] = useState(undefined)

	useEffect(() => {
		setSiteID(currentSiteID)
		setPatientID(currentPatientID)
	}, [])

	const [checkPatientMessageType, setCheckPatientMessageType] = useState("Info! ")
	const [checkPatientMessage, setCheckPatientMessage] = useState("Checking if patient exists in Study Upload")

	useEffect(() => {
		if (patientID?.length !== 4) {
			setCheckPatientMessageType("Warning! ")
			setCheckPatientMessage("Patient ID must be 4 digits long")
			document.getElementById("checkExistingPatientIDMessage").style.background = "rgba(238, 161, 12, 0.2)"
			document.getElementById("checkExistingPatientIDMessage").style.color = "rgb(238, 161, 12)"
			document.getElementById("checkExistingPatientIDMessageDiv").style.display = "block"
		}

		if (siteID === undefined || patientID?.length !== 4 || files.length < 1) {
			document.getElementById("confirmButton").style.background = "rgb(244, 246, 248)"
			document.getElementById("confirmButton").style.color = "rgb(41, 50, 65)"
			document.getElementById("confirmButton").style.pointerEvents = "none"
		}
	}, [patientID, siteID])

	const [sitePending, setSitePending] = useState(false)
	const [selectedRows, setSelectedRows] = useState(false)
	const [sitesTableData, setSitesTableData] = useState([])
	const [sitesTableColumns, setSitesTableColumns] = useState([])

	useEffect(() => {
		if (sitesProvider.userSites?.length === 0) return

		let columns2labels = {
			name: { label: "Name", type: "string" },
			site_id: { label: "Site ID", type: "string" },
		}

		let columnData = []

		for (let elem of Object.keys(columns2labels)) {
			columnData.push({
				name: columns2labels[elem]["label"],
				selector: row => row[elem],
				sortable: true,
				attr: elem,
			})
		}

		setSitesTableColumns(columnData)
		setSitePending(true)

		let tableData = sitesProvider.userSites?.map(row => {
			let entry = {
				checkbox: <MdIcons.MdOutlineCheckBoxOutlineBlank />,
			}
			for (let column of Object.keys(columns2labels)) {
				if (columns2labels[column]["type"] === "checkbox") {
					if (row[column]) {
						entry[column] = <MdIcons.MdOutlineCheckBox />
					} else {
						entry[column] = <MdIcons.MdOutlineCheckBoxOutlineBlank />
					}
				} else if (columns2labels[column]["type"] === "string") {
					entry[column] = row[column]
				}
			}
			return entry
		})
		const sortedData = tableData.filter(row => row !== false)
		setSitesTableData(sortedData)
		setSitePending(false)
	}, [sitesProvider.userSites])

	function updateSelectedSiteRows({ selectedRows }) {
		setSelectedRows(selectedRows)
		if (selectedRows.length === 0) {
			setSiteID(undefined)
			return
		}
		setSiteID(selectedRows[0].site_id)
	}

	function renderModifyPatientModal() {
		openNext(<ModifyPatientModal escClose={false} clickOutsideClose={false} currentSiteID={siteID} currentPatientID={patientID} style={{ width: "780px" }} />)
	}

	function renderPostModifyModal() {
		createModal(<PostModifyModal escClose={false} clickOutsideClose={false} />)
	}

	async function createUpload(study_id, upload_dir) {
		let body = {
			study_id: study_id,
			upload_dir: upload_dir,
		}

		return endpointProvider.post(LINKS.DATA.UPLOAD.CREATE_UPLOAD, body)
	}

	async function finishUpload(uploadID, siteID, patientID) {
		let body = {
			patient_id: patientID,
			upload_id: uploadID,
			study_id: studiesProvider.selectedStudyId,
			site_id: siteID,
			// 'medication_kws': JSON.stringify(nestedFileMappingDictionary),
			medication_kws: JSON.stringify({}),
			new_upload: true,
			modify_upload: true,
		}

		return endpointProvider.post(LINKS.DATA.UPLOAD.FINISH_UPLOAD, body)
	}

	async function handleCopyTempFiles(patient_id, site_id, files, upload_id) {
		let body = {
			patient_id: currentPatientID,
			site_id: currentSiteID,
			target_patient: patient_id,
			target_site: site_id,
			files: files,
			newUploadID: upload_id,
			study_id: studiesProvider.selectedStudyId,
		}

		return endpointProvider.post(LINKS.DATA.UPLOAD.COPY_TEMP_FILES, body)
	}

	async function handleCopyFiles(patient_id, site_id, files) {
		let upload_dir = `${site_id}/${patient_id}`
		let data = await createUpload(studiesProvider.selectedStudyId, upload_dir)
		let upload_id = data["upload_id"]

		let body = {
			patient_id: patient_id,
			site_id: site_id,
			current_patient_id: currentPatientID,
			current_site_id: currentSiteID,
			files: files,
			study_id: studiesProvider.selectedStudyId,
		}

		await handleCopyTempFiles(patientID, siteID, files, upload_id).catch(e => {
			let body = {
				study_id: studiesProvider.selectedStudyId,
				upload_id: upload_id,
			}
			endpointProvider.post(LINKS.DATA.UPLOAD.DELETE_UPLOAD, body)
			throw e
		})

		// await endpointProvider.post(LINKS.DATA.UPLOAD.COPY_FILES, body).catch((e) => {
		// 	let body = {
		// 		study_id: studiesProvider.selectedStudyId,
		// 		upload_id: upload_id
		// 	}
		// 	endpointProvider.post(LINKS.DATA.UPLOAD.DELETE_UPLOAD, body)
		// 	alert(e)
		// 	throw e
		// })

		await finishUpload(upload_id, siteID, patientID)

		return
	}

	async function handleMoveTempFiles(patient_id, site_id, files, upload_id, upload_id2) {
		let body = {
			patient_id: currentPatientID,
			site_id: currentSiteID,
			target_patient: patient_id,
			target_site: site_id,
			files: files,
			uploadID1: upload_id,
			uploadID2: upload_id2,
			study_id: studiesProvider.selectedStudyId,
		}

		return endpointProvider.post(LINKS.DATA.UPLOAD.MOVE_TEMP_FILES, body)
	}

	async function handleMoveFiles(patient_id, site_id, files) {
		let upload_dir = `${currentSiteID}/${currentPatientID}`
		let data = await createUpload(studiesProvider.selectedStudyId, upload_dir)
		let upload_id = data["upload_id"]

		let upload_dir2 = `${site_id}/${patient_id}`
		let data2 = await createUpload(studiesProvider.selectedStudyId, upload_dir2)
		let upload_id2 = data2["upload_id"]

		let body = {
			patient_id: patient_id,
			site_id: site_id,
			current_patient_id: currentPatientID,
			current_site_id: currentSiteID,
			files: files,
			study_id: studiesProvider.selectedStudyId,
		}

		await handleMoveTempFiles(patientID, siteID, files, upload_id, upload_id2).catch(e => {
			let body = {
				study_id: studiesProvider.selectedStudyId,
				upload_id: upload_id,
			}
			endpointProvider.post(LINKS.DATA.UPLOAD.DELETE_UPLOAD, body)
			throw e
		})

		await endpointProvider.post(LINKS.DATA.UPLOAD.MOVE_FILES, body).catch(e => {
			let body = {
				study_id: studiesProvider.selectedStudyId,
				upload_id: upload_id,
			}
			endpointProvider.post(LINKS.DATA.UPLOAD.DELETE_UPLOAD, body)
			throw e
		})

		// Runs an upload job for directory that the file(s) are moved from
		finishUpload(upload_id, currentSiteID, currentPatientID)

		// Runs an upload job for directory that the file(s) are moved to
		finishUpload(upload_id2, site_id, patient_id)

		return
	}

	return (
		<>
			<ModalHeader headerText={""} hrStyle={{ minWidth: "unset" }} />

			<div style={{ marginLeft: "10px", paddingBottom: "20px" }}>
				<div style={{ display: "flex" }}>
					<OverlayTrigger trigger={["hover", "focus"]} placement="top" overlay={getPopoverTop("patientID")}>
						<InfoButtonLabel onClick={e => e.preventDefault()} style={{ marginBottom: "10px" }}>
							<MdIcons.MdInfoOutline size={18} style={{ float: "right" }} />
						</InfoButtonLabel>
					</OverlayTrigger>
					<Titles style={{ marginBottom: "10px", fontSize: "16px" }}>Patient ID: {currentPatientID}</Titles>
				</div>

				<div style={{ display: "flex" }}>
					<div style={{ display: "inline-flex" }}>
						<input
							id="patient-id"
							type="text"
							pattern="[0-9]*"
							maxLength={4}
							// value={patientID ? patientID : currentPatientID}
							onChange={e => setPatientID(v => (e.target.validity.valid ? e.target.value : v))}
							placeholder=""
							style={{
								// pointerEvents: preselectedPatientID ? "none" : "all",
								color: "#000000",
								width: "304px",
								height: "56px",
								borderRadius: "5px",
								border: "2px solid #CCCCCC",
								background: "#FFFFFF",
								paddingLeft: "20px",
								fontSize: "14px",
							}}
						/>
					</div>

					<div id="checkExistingPatientIDMessageDiv" style={{ display: "block", marginLeft: "25px", fontSize: "14px", width: "max-content", marginTop: "10px" }}>
						<InfoMessage style={{ color: "rgb(32, 125, 234)" }} id="checkExistingPatientIDMessage">
							<strong>{checkPatientMessageType}</strong>
							{checkPatientMessage}
						</InfoMessage>
					</div>
				</div>

				<div style={{ display: "flex" }}>
					<OverlayTrigger trigger={["hover", "focus"]} placement="top" overlay={getPopoverTop("siteID")}>
						<InfoButtonLabel onClick={e => e.preventDefault()} style={{ marginTop: "12px" }}>
							<MdIcons.MdInfoOutline size={18} style={{ float: "right" }} />
						</InfoButtonLabel>
					</OverlayTrigger>
					<Titles style={{ marginTop: "10px", fontSize: "16px" }}>Site ID: {currentSiteID}</Titles>
				</div>

				{/* <MainTable
                // paginationComponent={(e) => { e.preventDefault() }}
                progressPending={sitePending}
                selectableRowsNoSelectAll={true}
                onSelectedRowsChange={updateSelectedSiteRows}
                selectableRowsSingle={true}
                columns={sitesTableColumns}
                data={sitesTableData}
                // clearSelectedRows={siteToggleCleared}
            /> */}
				<TableComponent data={sitesTableData} uniRef="name">
					<div style={{ position: "relative" }}>
						<RowsContainer columns={sitesTableColumns} onSelectedRowsChange={updateSelectedSiteRows} />
						<Pagination />
					</div>
				</TableComponent>
			</div>

			<div style={{ display: "flex", justifyContent: "space-between" }}>
				<MoveButton
					id="backButton"
					style={{ padding: "10px", width: "20%", background: "rgb(32, 125, 234)", color: "rgb(255, 255, 255)" }}
					onClick={() => {
						renderModifyPatientModal()
					}}
				>
					Back
				</MoveButton>
				{move ? (
					<MoveButton
						id="confirmButton"
						style={{ padding: "10px", width: "20%", background: "rgb(244, 246, 248)", color: "rgb(41, 50, 65)", pointerEvents: "none" }}
						onClick={() => {
							handleMoveFiles(patientID, siteID, files)
							renderPostModifyModal()
						}}
					>
						Move
					</MoveButton>
				) : (
					<MoveButton
						id="confirmButton"
						style={{ padding: "10px", width: "20%", background: "rgb(244, 246, 248)", color: "rgb(41, 50, 65)", pointerEvents: "none" }}
						onClick={() => {
							handleCopyFiles(patientID, siteID, files)
							renderPostModifyModal()
						}}
					>
						Copy
					</MoveButton>
				)}
			</div>
		</>
	)
}

export default ModifySubModal

const InfoButtonLabel = styled.button`
	border: none;
	background: none;
	border: none;
	color: #207dea;
	cursor: pointer;
	margin-top: 18px;
	width: fit-content;
	margin-right: 5px;
	margin-top: 0px;
	margin-bottom: 7px;
`

const MoveButton = styled.div`
	display: inline-flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: center;
	padding: 12px 32px;
	margin-right: 5px;
	margin-top: 0px;
	width: 10%;
	height: 45px;
	border-radius: 6px;
	font-family: "Source Sans Pro";
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 150%;
	// border: 2px solid #207dea;
	cursor: pointer;
`

const Titles = styled.h2`
	font-family: "Source Sans Pro";
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 150%;
	color: #293241;
`
