import { createContext, useContext, useState, useEffect } from "react";
import { useEndpointProvider } from "./EndpointProvider";
import { LINKS } from '../Constants/BackendLinks'
import { useAuthProvider } from "./AuthProvider";
import { UpdateDispatcher } from "./UpdateDispatcher";


const BucketsContext = createContext()

export const bucketUpdates = {
    BUCKETS_FILTERS: "buckets_filters"
}

export const BucketsProvider = ({ children }) => {   
    const [buckets, setBuckets] = useState([])
    const [filters, setFilters] = useState({})

    console.log("PROVIDER RENDER: BUCKETS", {
        buckets,
        filters
    })

    const endpointProvider = useEndpointProvider()
    const authProvider = useAuthProvider()

    async function update(...updates) {
        const initState = {
            [bucketUpdates.BUCKETS_FILTERS]: { data: buckets, filters }
        }

        const dispatcher = new UpdateDispatcher(updates, initState, bucketUpdates)

        dispatcher.dispatch(bucketUpdates.BUCKETS_FILTERS,
            () => endpointProvider.post(LINKS.ADMIN.BUCKETS.GET_BUCKETS),
            data => {
                setBuckets(data?.data)
                setFilters(data?.filters)
        })
    }

    // Make sure we don't persist any data between logins.
    useEffect(() => {
        if (!authProvider.userIsLoggedIn && buckets.length > 0) {
            setBuckets([])
        }
    }, [authProvider, buckets.length])

    return (
        <BucketsContext.Provider value={{ buckets, filters, update }}>
            {children}
        </BucketsContext.Provider>
    )
}

export const useBucketsProvider = () => useContext(BucketsContext)