import { Selection, EnterElement } from "d3";
import { D3OneToOneRenderable } from "../../../D3/D3OneToOneRenderable";
import { ReactCallbacks } from "../../../../Types/ReactCallbacks";
import { D3LegendLabels } from "./D3LegendLabels";
import { SolidColorTraceDataConfig } from "../../../../Types/Trace";

export type D3ModalityGraphLegendConfig = {
    traces: SolidColorTraceDataConfig[]
    width: number
}

export class D3ModalityGraphLegend extends D3OneToOneRenderable<SVGGElement, SVGGElement, D3ModalityGraphLegendConfig> {
    private height = 20
    private legendLabels?: D3LegendLabels

    constructor(root: SVGGElement, config: D3ModalityGraphLegendConfig, reactCallbacks: ReactCallbacks<any>) {
        super(root, config, "d3-modality-graph-legend", reactCallbacks)
        this.render()
    }

    protected enter(newElements: Selection<EnterElement, D3ModalityGraphLegendConfig, any, any>): Selection<SVGGElement, D3ModalityGraphLegendConfig, any, any> {
        const legendContainer = newElements
            .append("g")
            .attr("class", this.className)
            .style("user-select", "none") // disables selecting the text by dragging
            
        const foreignObject = legendContainer.append("foreignObject")
            .attr("width", this.config.width)
            .attr("height", this.height)
            .attr("transform", `translate(0, -${this.height})`)
            .style('font-family', '"source-sans-pro", sans-serif')
            .style("font-weight", "bold")
            .style("font-size", 12)

        foreignObject.append<HTMLDivElement>("xhtml:div")
            .style("display", "flex")
            .style("gap", "15px")
            .each((config, index, nodes) => this.createChildren(config, index, nodes))
               
        return legendContainer
    }

    update = (updatedLegends: Selection<any, any, any, any>) => {
        this.legendLabels?.render()
        return updatedLegends
    }

    protected createChildren(config: D3ModalityGraphLegendConfig, index: number, nodes: ArrayLike<HTMLDivElement>): void {
        const root = nodes[index]
        this.legendLabels = new D3LegendLabels(root, { traces: this.config.traces }, this.reactCallbacks)
    }

    protected updateDerivedState(): void {
        this.legendLabels?.updateConfig({ traces: this.config.traces })
    }
}