import React, { useCallback } from "react"
import { BaseVisualizationConfigureModal } from "./BaseVisualizationConfigureModal"
import { ElementConfigureDiv } from "../../../../../../Constants/StyledComponents"
import { SdDetectionConfigAtom } from "../../Atoms/SdDetection"
import { useEditWindow } from "./useEditWindow"
import { SdDetectionJSON } from "../../Types/SdDetection"
import { EEG_HIGH_FILTERS, EEG_LOW_FILTERS, EEG_NOTCH_FILTERS, EEG_SENSITIVITY } from "../../Types/EEG"
import { SD_HIGH_FILTERS, SD_LOW_FILTERS, SD_NOTCH_FILTERS, SD_SENSITIVITY } from "../../Types/SpreadingDepolarization"
import { MobergDropdown, MobergDropdownSize } from "../../../../../../Components/MobergDropdown/MobergDropdown"
import { SD_DETECTION_WINDOW_TIME_PRESETS } from "../../../../../../Managers/VisualizationManager/Viewport/Components/XAxis"
import { SetterOrUpdater } from "recoil"
import { DataReviewEEGMontageDropdown } from "../../../../../../Components/EEGMontageDropdownMenu/DataReviewEEGMontageDropdown"
import { MobergColumn } from "../../../../../../Moberg"

type ConfigureSdDetectionModalProps = {
    windowId: string
    layoutId: string
}

export const ConfigureSDDetectionModal = (props: ConfigureSdDetectionModalProps) => {
    const atom = SdDetectionConfigAtom({ layoutId: props.layoutId, windowId: props.windowId })
    const { editedConfig, saveChanges, updateProperty, updateProps } = useEditWindow<SdDetectionJSON>({ recoilState: atom, windowId: props.windowId, layoutId: props.layoutId })

    const setSDConfig: SetterOrUpdater<SdDetectionJSON> = useCallback(newValueOrSetter => {
		if (newValueOrSetter instanceof Function) {
			updateProps(newValueOrSetter(editedConfig))
		} else {
			updateProps(newValueOrSetter)
		}
	}, [editedConfig, updateProps])
    
    return (
        <BaseVisualizationConfigureModal layoutId={props.layoutId} windowId={props.windowId} title={"Configure SD Detection"} saveChanges={saveChanges}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>

                <MobergColumn style={{ padding: "20px" }} horizontalAlign="center">
                    <MobergColumn style={{ padding: "20px", width: "400px" }} >
                        <ElementConfigureDiv>
                            Initial Page Size

                            <MobergDropdown
                                selectedValue={editedConfig?.viewDuration}
                                onChange={value => updateProperty("viewDuration", parseInt(value as string))}
                                options={SD_DETECTION_WINDOW_TIME_PRESETS
                                    .filter(preset => preset.label !== "Page Size")
                                    .map(preset => ({ label: preset.label, value: preset.time }))}
                            />
                        </ElementConfigureDiv>

                        <ElementConfigureDiv>
                            Montage

                            <DataReviewEEGMontageDropdown 
                                size={MobergDropdownSize.REGULAR}
                                placement={"right-start"}
                                currentMontage={editedConfig.montage}
                                updateCurrentMontage={montage => setSDConfig(previous => ({...previous, montage }))}
                            />
                        </ElementConfigureDiv>
                    </MobergColumn>
                </MobergColumn>

                <div style={{ display: "flex" }}>

                    <div style={{ borderRight: "1px solid gray", padding: "0px 20px" }}>
                        <h2> EEG Settings </h2>

                        <div style={{ padding: "20px", width: "400px" }}>
                            <ElementConfigureDiv>
                                LFF
                                <MobergDropdown
                                    selectedValue={editedConfig?.eegConfig?.LFF}
                                    onChange={value => updateProperty("eegConfig.LFF", parseFloat(value as string))}
                                    options={Object.entries(EEG_LOW_FILTERS).map(([label, value]) => ({ label, value }))}
                                />
                            </ElementConfigureDiv>

                            <ElementConfigureDiv>
                                HFF
                                <MobergDropdown
                                    selectedValue={editedConfig?.eegConfig?.HFF}
                                    onChange={value => updateProperty("eegConfig.HFF", parseFloat(value as string))}
                                    options={Object.entries(EEG_HIGH_FILTERS).map(([label, value]) => ({ label, value }))}
                                />
                            </ElementConfigureDiv>

                            <ElementConfigureDiv>
                                Notch Frequency
                                <MobergDropdown
                                    selectedValue={editedConfig?.eegConfig?.notch}
                                    onChange={value => updateProperty("eegConfig.notch", parseFloat(value as string))}
                                    options={Object.entries(EEG_NOTCH_FILTERS).map(([label, value]) => ({ label, value }))}
                                />
                            </ElementConfigureDiv>

                            <ElementConfigureDiv>
                                Sensitivity
                                <MobergDropdown
                                    selectedValue={editedConfig?.eegConfig?.sensitivityMicroVolts}
                                    onChange={value => updateProperty("eegConfig.sensitivityMicroVolts", parseFloat(value as string))}
                                    options={Object.entries(EEG_SENSITIVITY).map(([label, value]) => ({ label, value }))}
                                />
                            </ElementConfigureDiv>
                        </div>

                    </div>

                    <div style={{ padding: "0px 20px" }}>
                        <h2> SD Settings </h2>

                        <div style={{ padding: "20px", width: "400px" }}>

                            <ElementConfigureDiv>
                                LFF
                                <MobergDropdown
                                    selectedValue={editedConfig?.overlayEEGConfig?.LFF}
                                    onChange={value => updateProperty("overlayEEGConfig.LFF", parseFloat(value as string))}
                                    options={Object.entries(SD_LOW_FILTERS).map(([label, value]) => ({ label, value }))}
                                />
                            </ElementConfigureDiv>

                            <ElementConfigureDiv>
                                HFF
                                <MobergDropdown
                                    selectedValue={editedConfig?.overlayEEGConfig?.HFF}
                                    onChange={value => updateProperty("overlayEEGConfig.HFF", parseFloat(value as string))}
                                    options={Object.entries(SD_HIGH_FILTERS).map(([label, value]) => ({ label, value }))}

                                />
                            </ElementConfigureDiv>

                            <ElementConfigureDiv>
                                Notch Frequency
                                <MobergDropdown
                                    selectedValue={editedConfig?.overlayEEGConfig?.notch}
                                    onChange={value => updateProperty("overlayEEGConfig.notch", parseFloat(value as string))}
                                    options={Object.entries(SD_NOTCH_FILTERS).map(([label, value]) => ({ label, value }))}
                                />
                            </ElementConfigureDiv>

                            <ElementConfigureDiv>
                                Sensitivity
                                <MobergDropdown
                                    selectedValue={editedConfig?.overlayEEGConfig?.sensitivityMicroVolts}
                                    onChange={value => updateProperty("overlayEEGConfig.sensitivityMicroVolts", parseFloat(value as string))}
                                    options={Object.entries(SD_SENSITIVITY).map(([label, value]) => ({ label, value }))}
                                />
                            </ElementConfigureDiv>
                        </div>
                    </div>

                </div>
            </div>

        </BaseVisualizationConfigureModal>
    )
}
