import { useRef, useState, useEffect } from "react"
import { ModalController } from "../../../../Controllers/ModalController"
import '../../../../index.css'
import styled from 'styled-components'
import "@fontsource/source-sans-pro"
import * as MdIcons from 'react-icons/md'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import ModalFooterButton, { buttonColor } from "../../../../Components/ModalFooterButton/ModalFooterButton"

export default function ModifySubcomponentModal ({ openRef, props, gData, updateGData, selectedSubcomponent }) {
    const contentRef =  useRef()
    const saveCloseRef = useRef()
    const cancelCloseRef = useRef()
    

    const availableOptions = {
        "default": {"label": "--Select an option--", "answer": {}},
        "HTMLInputField": {"label": 'Input Field', "answer": {"answer": {"x": " "}}},
        "HTMLCheckboxField": {"label": 'Checkbox Field', "answer": {"options": {"Box1": null, "Box2": null}}},
        "HTMLCheckboxFieldGrid": {"label": 'Checkbox Field Grid', "answer": {"answer": {"labels": ["ABP", "CPP", "PbtO2e", "ETCO2", "SpO2/FiO2", "rCBF", "PRx", "Not reported"], "checkbox_fields": {"Not reported 1": null, "Not reported 2": null, "Not reported 3": null}}}},
        "HTMLPlot": {"label": 'Plot', "answer": {"answer": {"x": " "}}},
        "HTMLDatetimeLocalField": {"label": 'Datetime Local Field', "answer": {"answer": ["Option 1", "Option 2", "Option 3"]}},
        "HTMLDropdownField": {"label": 'Dropdown Field', "answer": {"answer": ["Option 1", "Option 2"]}},
        "HTMLNumberField": {"label": 'Number Field', "answer": {"answer": {"x": " "}}},
        "HTMLRadioField": {"label": 'Radio Field', "answer": {"answer": ["Value 1", "Value 2", "Value 3"]}},
        "HTMLRangeField": {"label": 'Range Field', "answer": {"answer": {"x": " "}}},
        "HTMLTimeField": {"label": 'Time Field', "answer": {"answer": {"name": "Time"}}},
        "HTMLTextAreaField": {"label": 'Text Area Field', "answer": {"answer": {"x": " "}}},
        "HTMLTableField": {"label": 'Table Field', "answer": {"answer": {"header": true, "numRow": 3, "numCol": 4}}},
    }

    const [subcomponentTitle, setSubcomponentTitle] = useState("")
    const [currentSelectedSubcomponent, setCurrentSelectedSubcomponent] = useState()
    

    const isFirstRender = useRef(true);
    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return; 
        }
        setCurrentSelectedSubcomponent(JSON.parse(JSON.stringify(selectedSubcomponent)))
    },
    [selectedSubcomponent])


    useEffect(() => {
        setSubcomponentTitle(currentSelectedSubcomponent ? currentSelectedSubcomponent.title : "")
        setSelectedOption(currentSelectedSubcomponent ? currentSelectedSubcomponent.input_type : "--Select an option--")
    },
    [currentSelectedSubcomponent])

    const [selectedOption, setSelectedOption] = useState("--Select an option--");
        
    const popoverTopText = {
        'subcomponentName': 'The name of the subcomponent.',
        'bucketID': 'A four digit unique code corresponding to each bucket.',
        'studyID': 'The site ID that would be assigned to the bucket.'
    }

    function getPopoverTop(labelname) {
        return (
            <Popover id="Popover-trigger-hover-focus" style={{width: "228px"}} positionleft={75}>
                <p style={{fontFamily: "Source Sans Pro", fontStyle: "normal", fontWeight: "400", fontSize: "12px", color: "#5F6775", paddingLeft: "3px"}}>{popoverTopText[labelname]}</p>
            </Popover>
        )
    }

    const getInitialData = (subcomponentObj, input_type) => {
        if (!subcomponentObj || subcomponentObj.input_type !== input_type) {
            switch (input_type) {
                case 'HTMLRadioField':
                    return {"requiredValues": ["", ""], "optionalValues": []}
                case 'HTMLCheckboxField':
                    return {'requiredValues': [""], 'optionalValues': []}
                case 'HTMLTableField':
                    return {'requiredValues': {numRow: 0, numCol: 0, isHeader: "No"}, 'optionalValues': {}}
                case 'HTMLRangeField':
                    return {'requiredValues': {"initialMin": 10, "initialMax": 20, "rangeMin": 0, "rangeMax": 100, "step": 1}, 'optionalValues': {}}
                case 'HTMLDropdownField':
                    return {'requiredValues': ["--Select an option--"], 'optionalValues': []}
                case 'HTMLDatetimeLocalField':
                    return {'requiredValues': {"timezone": "UTC"}, 'optionalValues': {}}
                case 'HTMLNumberField':
                    return {'requiredValues': {"minLimit": 0, "maxLimit": 100, "type": "Integer", "decimalPoint": 0}, 'optionalValues': {}}
                default:
            }
        } 
        return subcomponentObj.option
    }

    const [radioValues, setRadioValues] = useState(getInitialData(currentSelectedSubcomponent, 'HTMLRadioField'))
    const [checkboxValues, setCheckboxValues] = useState(getInitialData(currentSelectedSubcomponent, 'HTMLCheckboxField'))
    const [tableValues, setTableValues] = useState(getInitialData(currentSelectedSubcomponent, 'HTMLTableField'))
    const [rangeValues, setRangeValues] = useState(getInitialData(currentSelectedSubcomponent, 'HTMLRangeField'))
    const [dropdownValues, setDropdownValues] = useState(getInitialData(currentSelectedSubcomponent, 'HTMLDropdownField')) 
    const [datetimeLocalValues, setDatetimeLocalValues] = useState(getInitialData(currentSelectedSubcomponent, 'HTMLDatetimeLocalField')) 
    const [numberValues, setNumberValues] = useState(getInitialData(currentSelectedSubcomponent, 'HTMLNumberField')) 


    useEffect(() => {
        setRadioValues(getInitialData(currentSelectedSubcomponent, 'HTMLRadioField'))
        setCheckboxValues(getInitialData(currentSelectedSubcomponent, 'HTMLCheckboxField'))
        setTableValues(getInitialData(currentSelectedSubcomponent, 'HTMLTableField'))
        setRangeValues(getInitialData(currentSelectedSubcomponent, 'HTMLRangeField'))
        setDropdownValues(getInitialData(currentSelectedSubcomponent, 'HTMLDropdownField'))
        setDatetimeLocalValues(getInitialData(currentSelectedSubcomponent, 'HTMLDatetimeLocalField'))
    }, [currentSelectedSubcomponent])

    const handleChangeValues = (subcomponentValues, setValue, type, value, index) => {
        const copyObj = JSON.parse(JSON.stringify(subcomponentValues))
        copyObj[type][index] = value
        setValue(copyObj)
    }

    const addInputs = (subcomponentValues, setValues) => {
        const copyObj = JSON.parse(JSON.stringify(subcomponentValues))
        copyObj.optionalValues.push("")
        setValues(copyObj)
    }

    const removeInputs = (subcomponentValues, setValues, index) => {
        const copyObj = JSON.parse(JSON.stringify(subcomponentValues))
        copyObj.optionalValues.splice(index, 1)
        setValues(copyObj)
    }

    const renderRadioSettings = () => {
        return (
            <Scrollbar style={{display: "block", padding: "15px 0px", maxHeight: "360px", overflowY: "scroll"}}>
                {radioValues.requiredValues.map((value, index) => 
                    (
                        <div style={{display: "flex"}} key={`requiredRadio-${index}`}>
                            <ModalTextinput id={`requiredRadio-${index}`} placeholder="Enter a value here" name={`requiredRadio-${index}`} value={value} onChange={e => handleChangeValues(radioValues, setRadioValues, 'requiredValues', e.target.value, index)}/>
                            <MdIcons.MdAdd size={24} style={{cursor: "pointer", marginTop: "8px", marginLeft: "4px"}} color="#B6B6B6" onClick={() => addInputs(radioValues, setRadioValues)}/>
                        </div>
                    )
                )}

                {radioValues.optionalValues.map((value, index) =>
                    (
                        <div style={{display: "flex"}} key={`optionalRadio-${index}`}>
                            <ModalTextinput id={`optionalRadio-${index}`} placeholder="Enter a value here" name={`optionalRadio-${index}`} value={value} onChange={e => handleChangeValues(radioValues, setRadioValues, 'optionalValues', e.target.value, index)}/>
                            <MdIcons.MdClose size={24} style={{cursor: "pointer", marginTop: "8px", marginLeft: "4px"}} color="#B6B6B6" onClick={() => removeInputs(radioValues, setRadioValues, index)}/>
                        </div>
                    )
                )}
            </Scrollbar>
        )
    }

    const getInitialCheckboxGridData = (subcomponentObj) => {
        if (!subcomponentObj || subcomponentObj.input_type !== 'HTMLCheckboxFieldGrid') return ["", []]
        const checkboxGridLabels = subcomponentObj.option.answer.labels
        return [checkboxGridLabels[0], checkboxGridLabels.slice(1)]
    }

    const [firstCheckboxGridLabel, setFirstCheckboxGridLabel] = useState(getInitialCheckboxGridData()[0])
    const [optionalCheckboxGridLabels, setOptionalCheckboxGridLabels] = useState(getInitialCheckboxGridData()[1])
    
    useEffect(() => {
        setFirstCheckboxGridLabel(getInitialCheckboxGridData(currentSelectedSubcomponent)[0])
        setOptionalCheckboxGridLabels(getInitialCheckboxGridData(currentSelectedSubcomponent)[1])
    }, [currentSelectedSubcomponent])

    const addCheckboxGridLabel = () => {
        const copyOptionalCheckboxGridLabels = [...optionalCheckboxGridLabels]
        copyOptionalCheckboxGridLabels.push("")
        setOptionalCheckboxGridLabels(copyOptionalCheckboxGridLabels)
    }

    const removeCheckboxGridLabel = (index) => {
        const copyOptionalCheckboxGridLabels = [...optionalCheckboxGridLabels]
        copyOptionalCheckboxGridLabels.splice(index, 1)
        setOptionalCheckboxGridLabels(copyOptionalCheckboxGridLabels)
    }

    const handleChangeOptionalCheckboxGridLabels = (value, index) => {
        const copyOptionalCheckboxGridLabels = [...optionalCheckboxGridLabels]
        copyOptionalCheckboxGridLabels[index] = value
        setOptionalCheckboxGridLabels(copyOptionalCheckboxGridLabels)
    }


    const renderCheckboxGridSettings = () => {
        
        return (
            <Scrollbar style={{display: "block", padding: "15px 0px", maxHeight: "360px", overflowY: "scroll"}}>
                <div style={{display: "flex"}}>
                    <ModalTextinput placeholder="Enter a label here" name="checkboxLabel-1" onChange={e => setFirstCheckboxGridLabel(e.target.value)} value={firstCheckboxGridLabel}/>
                    <MdIcons.MdAdd size={24} style={{cursor: "pointer", marginTop: "8px", marginLeft: "4px"}} color="#B6B6B6" onClick={() => {addCheckboxGridLabel()}} />
                </div>

                {optionalCheckboxGridLabels.map(function(value, index) {
                    return (
                    <div style={{display: "flex"}} key={`optionalCheckboxGridLabel-${index}`}>
                        <ModalTextinput id={`optionalCheckboxGridLabel-${index}`} placeholder="Enter a label here" name={`optionalCheckboxGridLabel-${index}`} value={value} onChange={e => handleChangeOptionalCheckboxGridLabels(e.target.value, index)}/>
                        <MdIcons.MdClose size={24} style={{cursor: "pointer", marginTop: "8px", marginLeft: "4px"}} color="#B6B6B6" onClick={() => {removeCheckboxGridLabel(index)}}/>
                    </div>
                    )
                })}
            </Scrollbar>
        )
    }

    const renderCheckboxSettings = () => {
        
        return (
            <Scrollbar style={{display: "block", padding: "15px 0px", maxHeight: "360px", overflowY: "scroll"}}>
                <div style={{display: "flex"}}>
                    <ModalTextinput placeholder="Enter checkbox option here" name="requiredCheckbox" onChange={e => handleChangeValues(checkboxValues, setCheckboxValues, 'requiredValues', e.target.value, 0)} value={checkboxValues.requiredValues[0]}/>
                    <MdIcons.MdAdd size={24} style={{cursor: "pointer", marginTop: "8px", marginLeft: "4px"}} color="#B6B6B6" onClick={() => {addInputs(checkboxValues, setCheckboxValues)}} />
                </div>

                {checkboxValues.optionalValues.map(function(value, index) {
                    return (
                    <div style={{display: "flex"}} key={`optionalCheckbox-${index}`}>
                        <ModalTextinput id={`optionalCheckbox-${index}`} placeholder="Enter checkbox option here" name={`optionalCheckbox-${index}`} value={value} onChange={e => handleChangeValues(checkboxValues, setCheckboxValues, 'optionalValues', e.target.value, index)}/>
                        <MdIcons.MdClose size={24} style={{cursor: "pointer", marginTop: "8px", marginLeft: "4px"}} color="#B6B6B6" onClick={() => {removeInputs(checkboxValues, setCheckboxValues, index)}}/>
                    </div>
                    )
                })}
            </Scrollbar>
        )
    }

    const renderTableSettings = () => (
        <div>
            <div style={{display:"flex", alignItems:"center", justifyContent:"space-between", gap:"20px", marginBottom: "20px"}}>
                <p style={{fontSize: "16px", maxWidth: "40%", marginBottom: "0"}}>{"Number of rows"}</p>
                <input style={{width: "2.65in", marginRight: "20.25%"}} type="number" name="row-number" placeholder="Enter number of rows here" value={tableValues.requiredValues.numRow} onChange={e => handleChangeValues(tableValues, setTableValues, 'requiredValues', e.target.value, 'numRow')}/>
            </div>
            <div style={{display:"flex", alignItems:"center", justifyContent:"space-between", gap:"20px", marginBottom: "20px"}}>
                <p style={{fontSize: "16px", maxWidth: "40%", marginBottom: "0"}}>{"Number of columns"}</p>
                <input style={{width: "2.65in", marginRight: "20.25%"}} type="number" name="col-number" placeholder="Enter number of columns here" value={tableValues.requiredValues.numCol} onChange={e => handleChangeValues(tableValues, setTableValues, 'requiredValues', e.target.value, 'numCol')}/>
            </div>
            <div style={{display:"flex", alignItems:"center", justifyContent:"space-between", gap:"20px", marginBottom: "20px"}}>
                <p style={{fontSize: "16px", maxWidth: "40%", marginBottom: "0"}}>{"Do you want to have header"}</p>
                <select style={{width: "2.65in", height: "50px", marginRight: "20.25%"}} name="input-options" value={tableValues.requiredValues.isHeader} onChange={e => handleChangeValues(tableValues, setTableValues, 'requiredValues', e.target.value, 'isHeader')}>
                    {["No", "Yes"].map((element) => (<option value={element.toLowerCase()}>{element}</option>))}
                </select>
            </div>
        </div>
    )

    const renderRangeSettings = () => (
        <div>
            <div style={{display:"flex", alignItems:"center", gap:"50px", marginBottom: "20px"}}>
                <p style={{fontSize: "16px", maxWidth: "40%", marginBottom: "0", width: "90px"}}>{"Range"}</p>
                <div style={{display:"flex", alignItems:"center", gap:"5px"}}>
                    <input style={{width: "1in"}} type="number" name="range" placeholder="Enter a number here" value={rangeValues.requiredValues.rangeMin} onChange={e => handleChangeValues(rangeValues, setRangeValues, 'requiredValues', parseInt(e.target.value), 'rangeMin')}/>
                    <input style={{width: "1in"}} type="number" name="range" placeholder="Enter a number here" value={rangeValues.requiredValues.rangeMax} onChange={e => handleChangeValues(rangeValues, setRangeValues, 'requiredValues', parseInt(e.target.value), 'rangeMax')}/>
                </div>
            </div>

            <div style={{display:"flex", alignItems:"center", gap:"50px", marginBottom: "20px"}}>
                <p style={{fontSize: "16px", maxWidth: "40%", marginBottom: "0", width: "90px"}}>{"Initial Range"}</p>
                <div style={{display:"flex", alignItems:"center", gap:"5px"}}>
                    <input style={{width: "1in"}} type="number" name="initialRange" placeholder="Enter a number here" value={rangeValues.requiredValues.initialMin} onChange={e => handleChangeValues(rangeValues, setRangeValues, 'requiredValues', parseInt(e.target.value), 'initialMin')}/>
                    <input style={{width: "1in"}} type="number" name="initialRange" placeholder="Enter a number here" value={rangeValues.requiredValues.initialMax} onChange={e => handleChangeValues(rangeValues, setRangeValues, 'requiredValues', parseInt(e.target.value), 'initialMax')}/>
                </div>
            </div>

            <div style={{display:"flex", alignItems:"center", gap:"50px", marginBottom: "20px"}}>
                <p style={{fontSize: "16px", maxWidth: "40%", marginBottom: "0", width: "90px"}}>{"Step"}</p>
                <input style={{width: "1in"}} type="number" name="step" placeholder="Enter a number here" value={rangeValues.requiredValues.step} onChange={e => handleChangeValues(rangeValues, setRangeValues, 'requiredValues', parseInt(e.target.value), 'step')}/>
            </div>

        </div>
    )

    const renderDropdownSettings = () => {
        
        return (
            <Scrollbar style={{display: "block", padding: "15px 0px", maxHeight: "360px", overflowY: "scroll"}}>
                <div style={{display: "flex"}}>
                    <ModalTextinput placeholder="Enter dropdown option here" name="dropdownOption-1" onChange={e => handleChangeValues(dropdownValues, setDropdownValues, 'requiredValues', e.target.value, 0)} value={dropdownValues.requiredValues[0]}/>
                    <MdIcons.MdAdd size={24} style={{cursor: "pointer", marginTop: "8px", marginLeft: "4px"}} color="#B6B6B6" onClick={() => {addInputs(dropdownValues, setDropdownValues)}} />
                </div>

                {dropdownValues.optionalValues.map((value, index) => (
                    <div style={{display: "flex"}} key={`dropdownOption-${index}`}>
                    <ModalTextinput id={`dropdownOption-${index}`} placeholder="Enter dropdown option here" name={`dropdownOption-${index}`} value={value} onChange={e => handleChangeValues(dropdownValues, setDropdownValues, 'optionalValues', e.target.value, index)}/>
                    <MdIcons.MdClose size={24} style={{cursor: "pointer", marginTop: "8px", marginLeft: "4px"}} color="#B6B6B6" onClick={() => {removeInputs(dropdownValues, setDropdownValues, index)}}/>
                </div>
                ))}
            </Scrollbar>
        )
    }

    const renderDatetimeLocalSettings = () => (
        <div>
            <div style={{display:"flex", alignItems:"center", gap:"50px", marginBottom: "20px"}}>
                <p style={{fontSize: "16px", maxWidth: "40%", marginBottom: "0", width: "90px"}}>{"Timezone"}</p>
                <input style={{width: "2.65in"}} type="text" name="timezone" placeholder="Enter timezone here" value={datetimeLocalValues.requiredValues.timezone} onChange={e => handleChangeValues(datetimeLocalValues, setDatetimeLocalValues, 'requiredValues', e.target.value, 'timezone')}/>
            </div>
        </div>
    )

    const renderNumberSettings = () => (
        <div>
            <div style={{display:"flex", alignItems:"center", justifyContent:"space-between", gap:"20px", marginBottom: "20px"}}>
                <p style={{fontSize: "16px", maxWidth: "40%", marginBottom: "0"}}>{"Number Type"}</p>
                <ModalDropdown style={{width: "2.65in", marginRight: "20.25%"}} id="numberType" value={numberValues.requiredValues.type} onChange={e => handleChangeValues(numberValues, setNumberValues, 'requiredValues', e.target.value, 'type')}>
                    {['Integer', 'Floating-point'].map(o => (
                        <option key={`numberType-${o}`} value={o}>{o}</option>
                    ))}
                </ModalDropdown>
            </div>
            <div style={{display:"flex", alignItems:"center", justifyContent:"space-between", gap:"20px", marginBottom: "20px"}}>
                <p style={{fontSize: "16px", maxWidth: "40%", marginBottom: "0"}}>{"Decimal Point"}</p>
                <input style={{width: "2.65in", marginRight: "20.25%"}} type="number" name="number-decimal-point" placeholder="Enter a decimal point here" value={numberValues.requiredValues.decimalPoint} onChange={e => handleChangeValues(numberValues, setNumberValues, 'requiredValues', e.target.value, 'decimalPoint')}/>
            </div>
            <div style={{display:"flex", alignItems:"center", justifyContent:"space-between", gap:"20px", marginBottom: "20px"}}>
                <p style={{fontSize: "16px", maxWidth: "40%", marginBottom: "0"}}>{"Minimum Limit"}</p>
                <input style={{width: "2.65in", marginRight: "20.25%"}} type="number" name="number-min-limit" placeholder="Enter minimum limit here" value={numberValues.requiredValues.minLimit} onChange={e => handleChangeValues(numberValues, setNumberValues, 'requiredValues', e.target.value, 'minLimit')}/>
            </div>
            <div style={{display:"flex", alignItems:"center", justifyContent:"space-between", gap:"20px", marginBottom: "20px"}}>
                <p style={{fontSize: "16px", maxWidth: "40%", marginBottom: "0"}}>{"Maximum Limit"}</p>
                <input style={{width: "2.65in", marginRight: "20.25%"}} type="number" name="number-max-limit" placeholder="Enter maximum limit here" value={numberValues.requiredValues.maxLimit} onChange={e => handleChangeValues(numberValues, setNumberValues, 'requiredValues', e.target.value, 'maxLimit')}/>
            </div>
        </div>
    )


    const renderSettings = (inputType) => {
        switch (inputType) {
            case 'HTMLInputField':
                return (<div>None</div>)
            case 'HTMLCheckboxField':
                return renderCheckboxSettings()
            case 'HTMLCheckboxFieldGrid':
                return renderCheckboxGridSettings()
            case 'HTMLPlot':
                return (<div>None</div>)
            case 'HTMLScatterPlot':
                return (<div>None</div>)
            case 'HTMLHistogramPlot':
                return (<div>None</div>)
            case 'HTMLDatetimeLocalField':
                return renderDatetimeLocalSettings()
            case 'HTMLDropdownField':
                return renderDropdownSettings()
            case 'HTMLNumberField':
                return renderNumberSettings()
            case 'HTMLRadioField':
                return renderRadioSettings()
            case 'HTMLRangeField':
                return renderRangeSettings()
            case 'HTMLTimeField':
                return (<div>None</div>)
            case 'HTMLTextAreaField':
                return (<div>None</div>)
            case 'HTMLTableField':
                return renderTableSettings()
            default:
        }   
    }

    const getSubcomponentHeight = (inputType, subcomponentAnswer) => {
        switch(inputType) {
            case 'HTMLInputField':
                return 39.39
            case 'HTMLCheckboxField':
                return 54.6
            case 'HTMLCheckboxFieldGrid':
                return 196.93
            case 'HTMLPlot':
                return 440.72
            case 'HTMLScatterPlot':
                return 39.39
            case 'HTMLHistogramPlot':
                return 39.39
            case 'HTMLDatetimeLocalField':
                return 39.39
            case 'HTMLDropdownField':
                return 36.06
            case 'HTMLNumberField':
                return 39.39
            case 'HTMLRadioField':
                return 93.39
            case 'HTMLRangeField':
                return 71.39
            case 'HTMLTimeField':
                return 39.39
            case 'HTMLTextAreaField':
                return 68.39
            case 'HTMLTableField':
                let height = 0
                const rowHeight = 96
                height += subcomponentAnswer.requiredValues.numCol * rowHeight
                if (subcomponentAnswer.requiredValues.isHeader === true) height += rowHeight
                height += 0.89
                return height
            default:
        }
    }

    const updateSubcomponent = (subcomponentObj, newTitle, newInputType, newAnswer) => {
        subcomponentObj.title = newTitle
        subcomponentObj.input_type = newInputType
        subcomponentObj.height = getSubcomponentHeight(newInputType, newAnswer)
        subcomponentObj.option = newAnswer
    }

    

    const getSubcomponentSettings = (inputType) => {
        switch (inputType) {
            case 'HTMLCheckboxField':
                return checkboxValues
            case 'HTMLCheckboxFieldGrid':
                return {"answer": {"labels": [firstCheckboxGridLabel, ...optionalCheckboxGridLabels], "checkbox_fields": {"Not reported 1": null, "Not reported 2": null, "Not reported 3": null}}}
            case 'HTMLRadioField':
                return radioValues
            case 'HTMLDatetimeLocalField':
                return datetimeLocalValues
            case 'HTMLDropdownField':
                return dropdownValues
            case 'HTMLRangeField':
                return rangeValues
            case 'HTMLNumberField':
                return numberValues
            case 'HTMLTableField':
                return tableValues
            default:
        }
    }



    const handleSave = () => {
        const copyCurrentSelectedSubcomponent = JSON.parse(JSON.stringify(currentSelectedSubcomponent))
        updateSubcomponent(copyCurrentSelectedSubcomponent, subcomponentTitle, selectedOption, getSubcomponentSettings(selectedOption))
        setCurrentSelectedSubcomponent(copyCurrentSelectedSubcomponent)
        const key = copyCurrentSelectedSubcomponent.key
        const primaryKey = parseInt(key.split('-').at(0))
        const secondaryKey = parseInt(key.split('-').at(-1))
        const copyGData = JSON.parse(JSON.stringify(gData))
        copyGData[primaryKey].children[secondaryKey -1] = copyCurrentSelectedSubcomponent
        updateGData(copyGData)
        return true
    }

	return (<>
        <div style={{display: "none"}} ref={contentRef}>
            <CreateComponentModalContent style={{width: "800px", padding: "15px 30px"}}>
                <div style={{background: "#FFFFFF", position: "sticky"}}>
                <h3 style={{fontFamily: 'Montserrat'}}>Modify Subcomponent</h3>
                <hr style={{border: "1px solid #B3B3B3", width: "800px", marginLeft: "-30px"}}/>
                </div>

                <div style={{display: 'flex'}}>
                    <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={getPopoverTop('subcomponentName')}> 
                    <InfoButton><MdIcons.MdInfoOutline size={18} style={{float: "right"}}/></InfoButton>
                    </OverlayTrigger>
                    <h1 style={{marginTop: "-4px"}}>Name<span style={{color:"red"}}>*</span></h1>
                </div>
                    <ModalTextinput placeholder="Enter subcomponent name here" id="subcomponentName" value={subcomponentTitle} onChange={e => setSubcomponentTitle(e.target.value)}/>
                    <p>{subcomponentTitle}</p>

                <div style={{display: 'flex'}}>
                    <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={getPopoverTop('description')}> 
                    <InfoButton><MdIcons.MdInfoOutline size={18} style={{float: "right"}}/></InfoButton>
                    </OverlayTrigger>
                    <h1 style={{marginTop: "-4px"}}>Type</h1>
                </div>
                <ModalDropdown id="subcomponentType" value={selectedOption} onChange={e => setSelectedOption(e.target.value)}>
                    {Object.entries(availableOptions).map(o => (
                    <option key={o.at(0)} value={o.at(0)}>{o.at(1).label}</option>
                    ))}
                </ModalDropdown>
                    


                <div style={{display: "inline-flex"}}>
                <h1>Settings</h1>
                
                <div style={{right: "0px", display: "inline-flex", marginLeft: "600px"}}>
                <h1 style={{color: "#293241"}}>Preview</h1>
                <MdIcons.MdOutlineToggleOff size={36} color="#B3B3B3" style={{marginTop: "-6px", marginLeft: "6px", cursor: "pointer"}}/>
                </div>
                </div>

                {renderSettings(selectedOption)}

                <div style={{display: "inline-flex", background: "#FFFFFF", position: "absolute", bottom: "25px"}}>
                    <ModalFooterButton 
                        ref={saveCloseRef} 
                        buttonText="Save"
                        color={buttonColor.blueFill}
                        buttonStyle={{marginRight: "5px", width: "114px"}}
                    />
                    <ModalFooterButton 
                        ref={cancelCloseRef}
                        buttonText="Cancel"
                        color={buttonColor.blueOutline}
                        buttonStyle={{marginRight: "5px", width: "114px"}}
                    />
                </div>
            </CreateComponentModalContent>
        </div>
        
        <ModalController 
            id={"createComponent"}
            contentRef={contentRef} 
            openRef={openRef} 
            modalStyle={"large"} 
            closeOnEscape={true}
            validationCloseRefs={[
                {   
                    ref: saveCloseRef,
                    validate: handleSave
                },
                {
                    ref: cancelCloseRef,
                    validate: () => true
                }
            ]}
        >
        </ModalController>
   </>)
}

const CreateComponentModalContent = styled.div`
    width: 800px;
    padding: 15px 30px;
    h3 {
        color: #293241;
        font-weight: 700;
        font-size: 20px;
        font-style: normal;
        line-height: 150%;
        text-align: center;
    }
    h1 {
        font-family: 'Source Sans Pro';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        color: #293241;
    }
    input {
        border: 1px solid #ABABAB;
        border-radius: 6px;
        color: #000000;
        width: 740px;
        height: 50px;
        padding: 8px 16px;
        ::placeholder {
			top: calc(50% - 19px/2);
			color: #9f9f9f;
			font-size: 16px;
			font-weight: 400;
			font-family: 'Source Sans Pro';
			line-height: 120%;
    }
`;

const ModalDropdown = styled.select`
    background: #FFFFFF;
    border: 2px solid #CCCCCC;
    box-sizing: border-box;
    border-radius: 5px;
    width: 464px;
    height: 56px;
    padding: 16px;
	color: #000000;
	margin-top: -5px;
	margin-bottom: 25px;
    ::placeholder {
        font-family: 'Source Sans Pro';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: "gray";
    }
`;

const ModalTextinput = styled.input`
	background:  ${({ disabled }) => (disabled ? "#F2F2F2" : "#ffffff")};
    border: 2px solid #CCCCCC;
    box-sizing: border-box;
    border-radius: 5px;
    width: 464px;
    height: 56px;
    padding: 16px;
	color: ${({ disabled }) => (disabled ? "#737373": "#000000")};
	margin-top: -5px;
	margin-bottom: 20px;
    ::placeholder {
        font-family: 'Source Sans Pro';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: "gray";
    }
`;

const InfoButton = styled.button`
    border: none;
    background: none;
    border: none;
    color: #207dea;
    cursor: pointer;
    margin-top: 18px;
    width: fit-content;
    margin-right: 5px;
    margin-top: 0px;
    margin-bottom: 7px;
`;

const Scrollbar = styled.div`
		scrollbar-width: thin;
		::-webkit-scrollbar {
  			display: block;
			width: 5px;
			color: #313A4A;
		}
		::-webkit-scrollbar-track {
			background: #BEC4CF;
			width: 5px;
			border-radius: 2px;
		}
`;