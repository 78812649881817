import styled from 'styled-components';
import * as MdIcons from 'react-icons/md';
import { useModalProvider } from "../../../Providers/ModalProvider";
import ModalHeader from "../../../Components/ModalHeader/ModalHeader";
import AccountSettingsModal from '../../../Components/PageHeader/AccountSettingsModal';
import { useState, useEffect } from 'react';
import { useEndpointProvider } from '../../../Providers/EndpointProvider';
import { useAuthProvider } from "../../../Providers/AuthProvider";
import { BackButtonDiv, NormalText, InfoMessage, ErrorMessage } from '../../../Constants/StyledComponents';
import ModalFooterButton, { buttonColor } from '../../../Components/ModalFooterButton/ModalFooterButton';
import { useBackendLinksProvider } from '../../../Providers/BackendLinksProvider';

const ChangeEmail = (props) => {
    const authProvider = useAuthProvider()
    const endpointProvider = useEndpointProvider()
    const { openNext } = useModalProvider()
    const { LINKS } = useBackendLinksProvider()
    const [email, setEmail] = useState()
    const [confirmEmail, setConfirmEmail] = useState()
    const [password, setPassword] = useState("")
    const [showPassword, setShowPassword] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [showErrorMessage, setShowErrorMessage] = useState(false)
    const [disableChangeEmail, setDisableChangeEmail] = useState(true)

    function changeEmail(email) {
        let body = {
            new_email: email,
            password: password
        }

        return endpointProvider.post(LINKS.ACCOUNT.CHANGE_EMAIL, body)
    }

    async function handleChangeEmail(e) {
        if (e) {
            e.preventDefault()
        }
        changeEmail(confirmEmail).then((data) => {
            alert(data)
            setEmail('')
            setConfirmEmail('')
            authProvider.logout()
        }).catch((e) => {
            alert(e);
        })
    }

    useEffect(() => {
        if (!email && !confirmEmail && !password) {
            setDisableChangeEmail(true)
            setShowErrorMessage(false)
        } else if (!email) {
            setDisableChangeEmail(true)
            setErrorMessage("You must enter your email.")
            setShowErrorMessage(true)
        } else if (!confirmEmail) {
            setDisableChangeEmail(true)
            setErrorMessage("You must confirm your email.")
            setShowErrorMessage(true)
        } else if (!password) {
            setDisableChangeEmail(true)
            setErrorMessage("You must enter your password.")
            setShowErrorMessage(true)
        } else if ((email !== confirmEmail)) {
            setDisableChangeEmail(true)
            setErrorMessage("Emails do not match.")
            setShowErrorMessage(true)
        } else if (!email.includes('@')) {
            setDisableChangeEmail(true)
            setErrorMessage("You must enter a valid email.")
            setShowErrorMessage(true)
        } else if ((email === authProvider.currentUser.email) || (confirmEmail === authProvider.currentUser.email)) {
            setDisableChangeEmail(true)
            setErrorMessage('New email must be different than current email.')
            setShowErrorMessage(true)
        }
        else {
            setShowErrorMessage(false)
            setDisableChangeEmail(false)
        }
    }, [password, email, confirmEmail])

    function renderAccountSettingsModal() {
        openNext(<AccountSettingsModal
            escClose={false}
            clickOutsideClose={false}
        />)
    }


    return (
        <div style={{ background: "#FFFFFF", width: "820px", height: "776px", display: "block", paddingTop: "20px", paddingBottom: "188px" }}>
            <ModalHeader headerText='Account Settings' />

            <BackButtonDiv onClick={() => { renderAccountSettingsModal() }}>
                <MdIcons.MdOutlineArrowRightAlt size={32} style={{ marginRight: "5px", marginTop: "-4px", transform: "rotate(180deg)" }} />
                <NormalText>Back</NormalText>
            </BackButtonDiv>

            <div id="ChangeEmailModalContent" style={{ width: "820px", height: "auto", marginTop: "20px", paddingLeft: "250px", paddingRight: "250px", display: "inline-grid" }}>
                <div style={{ width: "320px", height: "auto", textAlign: "center", padding: "0px", marginBottom: "25px" }}>
                    <SubheaderText style={{ position: "relative" }}>Change Email</SubheaderText>
                    <InfoMessage style={{ height: "auto", marginTop: "8px" }}><p><strong>Info! </strong>You will be logged out after changing your email.</p></InfoMessage>
                </div>

                <div style={{ width: "320px", height: "400px", padding: "0px" }}>
                    <NormalTextBold>New Email</NormalTextBold>
                    <ModalTextInput placeholder="example@gmail.com" onChange={(e) => setEmail(e.target.value)} type="email" />

                    <NormalTextBold>Confirm New Email</NormalTextBold>
                    <ModalTextInput placeholder="example@gmail.com" onChange={(e) => setConfirmEmail(e.target.value)} type="email" />

                    <NormalTextBold>Password</NormalTextBold>
                    <ModalTextInput placeholder="Password" onChange={(e) => setPassword(e.target.value)} type={showPassword ? "text" : ((String(password).length === 0) ? "text" : "password")} value={password || ""} onCopy={(e) => e.preventDefault()} onPaste={(e) => e.preventDefault()} />
                    <div style={{ marginTop: "-10px", marginBottom: "5px" }}>
                        <input id="showPasswordCheckbox" type="checkbox"
                            onChange={() => { setShowPassword(!showPassword) }} >
                        </input>
                        <label for="showPasswordCheckbox" style={{ marginLeft: "4px" }}>Show Password</label>
                    </div>

                    <ModalFooterButton
                        buttonText="Change Email"
                        disabled={disableChangeEmail}
                        color={buttonColor.blueFill}
                        buttonStyle={{ position: "absolute", marginLeft: "73px" }}
                        onClickFxn={(e) => { handleChangeEmail(e) }}
                    />

                    <ErrorMessage id="errorMessage" style={{ display: showErrorMessage ? "block" : "none", height: "fit-content", marginTop: "50px" }}>
                        <p><strong>Warning! </strong>{errorMessage}</p>
                    </ErrorMessage>

                </div>
            </div>
        </div>
    )
}

const SubheaderText = styled.text`
    font-family: 'Montserrat';
    font-style: normal;
    font-size: 16px;
    font-weight: 700;
    color: #293241;
    line-height: 150%;
    position: absolute;
`;

const NormalTextBold = styled.text`
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #293241;
    line-height: 150%;
`;

const ModalTextInput = styled.input`
    background: none;
    border: 1px solid #ABABAB;
    box-sizing: border-box;
    border-radius: 6px;
    width: 304px;
    height: 56px;
    padding: 16px;
	color: #000000;
	margin-top: 5px;
	margin-bottom: 30px;
    ::placeholder {
        font-family: 'Source Sans Pro';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #ABABAB;
    }
    //TO DO: consider making input boxes 320px wide to make everything look more centered
`;

export default ChangeEmail;