import { Grid, InputLabel, Typography } from "@mui/material"

export default function TableWrapper(props) {
	const { inputId, label, numberColumn = 12 } = props

	return (
		<Grid item xs={numberColumn}>
			<InputLabel shrink htmlFor={inputId}>
				<Typography variant="body1" fontWeight="bold" sx={{ fontSize: "20px" }}>
					{label}
				</Typography>
			</InputLabel>

			{props.children}
		</Grid>
	)
}
