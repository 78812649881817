import { Socket, io } from "socket.io-client"
import { LINKS } from "../Constants/BackendLinks"

const sockets = new Map<string, Socket>()

export const getDataQuerySocket = (id = "default") => {
	let socket = sockets.get(id)

	if (!socket) {
		const url = id === "prefetch" ? LINKS.QUERY_PREFETCH : LINKS.QUERY
		socket = io(url, { transports: ["websocket"] })
		sockets.set(id, socket)

		socket.on("error", error => {
			console.error("Socket error:", error)
		})
	}

	if (socket.disconnected) {
		socket.connect()
	}

	return socket
}

export const closeDataQuerySocketConnections = () => {
	sockets.forEach(socket => socket.disconnect())
	sockets.clear()
}
