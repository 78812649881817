import { DefaultValue, atom, selector } from "recoil";
import { Layout, LayoutGroup } from "../Types/Layout";
import { isEqual } from "lodash";

// Keeps track of which layout Id we have selected for later retrieval.
export const selectedLayoutIdAtom = atom<string | null>({
    key: "selectedLayoutId",
    default: null
})

// Keeps track of the selected layout group id.
const selectedLayoutGroupIdAtom = atom<string | null>({
    key: "selectedLayoutGroupId",
    default: null
})

// The selected layout within the selected layout group. i.e, which tab we are viewing.
// It must be a layout in the selected layout group
export const selectedLayoutAtom = selector<Layout | null>({
    key: "selectedLayout",
    get: ({ get }) => {
        const selectedLayoutGroup = get(selectedLayoutGroupAtom)
        if (selectedLayoutGroup === null) {
            return null
        }

        const selectedLayoutId = get(selectedLayoutIdAtom)
        const foundLayout = selectedLayoutGroup.layouts.find(layout => layout && layout.id === selectedLayoutId)
        return foundLayout ? foundLayout : null
    },
    set: ({ set }, layout: Layout | null | DefaultValue) => {
        if (layout instanceof DefaultValue || layout === null) {
            set(selectedLayoutIdAtom, null)
        } else {
            set(selectedLayoutIdAtom, layout.id)
        }
    }
})

// This selector helps us sync changes to the selected layout and layout groups when we make changes to all the layouts
// i.e. when we modify or delete the selected layout, it will auto update instead of us having to do that manually.
export const selectedLayoutGroupAtom = selector<LayoutGroup | null>({
    key: "selectedLayoutGroup",
    get: ({ get }) => {
        const selectedLayoutGroupId = get(selectedLayoutGroupIdAtom)
        const foundLayoutGroup = get(layoutGroupsAtom).find(layout => layout && layout.id === selectedLayoutGroupId)
        return foundLayoutGroup ? foundLayoutGroup : null
    },
    set: ({ set }, layoutGroup: LayoutGroup | null | undefined | DefaultValue) => {
        if (layoutGroup instanceof DefaultValue || !layoutGroup) {
            set(selectedLayoutGroupIdAtom, null)
            set(selectedLayoutAtom, null)
        } else {
            set(selectedLayoutGroupIdAtom, layoutGroup.id)
            if (layoutGroup.layouts.length > 0) {
                set(selectedLayoutAtom, layoutGroup.layouts[0])
            }
        }
    }
})

// All of the layout groups loaded in for the user and the workspace.
export const layoutGroupsAtom = atom<LayoutGroup[]>({
    key: "layoutGroups",
    default: []
})

// Keeps track of the unedited state of the layout group so we have a state to return to if we need to cancel changes.
// This is needed to keep track of state between many different modals.
export const uneditedLayoutGroupAtom = selector<LayoutGroup | null>({
    key: "uneditedLayoutGroup",
    get: ({ get }) => get(uneditedLayoutGroupBase),
    set: ({ get, set }, newValue: DefaultValue | LayoutGroup | null) => {
        set(uneditedLayoutGroupBase, newValue)
        set(selectedLayoutAtom, get(selectedLayoutAtom))
    }
})

const uneditedLayoutGroupBase = atom<LayoutGroup | null>({
    key: "uneditedLayoutGroupBase",
    default: null
})

// Keeps track of the edited state of the layout group as we are switching around between configuration modals.
// This separates the editor and the actual state so that all changes can be batched when the user hits save.
export const editedLayoutGroupAtom = atom<LayoutGroup | null>({
    key: "editedLayoutGroup",
    default: null
})

// Whether or not we made changes to the layout group
export const madeChangesToEditedLayoutGroupAtom = selector({
    key: "madeChangesToEditedLayoutGroup",
    get: ({ get }) => {
        const unedited = get(uneditedLayoutGroupAtom)
        const edited = get(editedLayoutGroupAtom)

        return !isEqual(unedited, edited)
    }
})
