import styled from "styled-components"
import { useState, useEffect, useRef, useCallback, useLayoutEffect } from 'react'
import '../../index.css'
import "@fontsource/montserrat"
import * as MdIcons from 'react-icons/md'
import { useNavigate, useLocation, Link } from "react-router-dom"
import { releaseData } from "./ReleaseChangelogs.jsx"
import { FRONTEND_LINKS } from "../../Constants/FrontendLinks"

const ReleaseChangelogs = (props) => {
    useEffect(() => {
        props.setSidebar(false)
        props.setNavPage(false)
        document.getElementById("HeaderAndPage").style.marginLeft = "0px"
    }, [])

    const [selectedRelease, setSelectedRelease] = useState('') // currently displayed version release
    const [versionExists, setVersionExists] = useState(true)

    let navigate = useNavigate()
    const location = useLocation()
    const query = new URLSearchParams(location.search)
    const version = query.get("version")



    const handleChangelogURL = () => {
        let releaseKeys = Object.keys(releaseData)
        if (releaseKeys.includes(version)) {
            try {
                if (version.length !== 0) {
                    if (document.getElementById("changelogIndex")) {
                    document.getElementById("changelogIndex").style.display = "none"; 
                    }
                    setSelectedRelease(version);
                    if (document.getElementById("changelogInformation")) {
                    document.getElementById("changelogInformation").style.display = "block";

                    }
                }
            } catch (error) {
                navigate(FRONTEND_LINKS.SUBPAGES.RELEASE_CHANGELOGS.MAIN)
            }
        } else {
            setVersionExists(false)
            return;
        }
    }

    useEffect(() => {
        let urlString = window.location.href
        let paramString = urlString.split(/[?=]+/)[1];
        if (!paramString) {
            return
        }
        if (paramString !== "version") {
            setVersionExists(false)
            return;
        }

        if ((!version) || (version.length === 0)) {
            return
        } else {
            handleChangelogURL()
        }
    }, [])


    return (
        <div style={{background: "#F8F8F8", top: "0", left: "0", bottom: "0", right: "0", height: "auto"}}>
            <Scrollbar style={{maxHeight: `${window.innerHeight}px`}}>
            {versionExists ?
            <div>
            {props.userLoggedIn ? 
            <a id="returnToDataTrials" style={{textDecoration: "underline", color: "#0097EE", paddingTop: "30px", marginLeft: "30px", display: "block"}} href="/data/trials"><MdIcons.MdKeyboardBackspace /> Return to Data Trials</a>
            :
            <a id="returnToLogin" style={{textDecoration: "underline", color: "#0097EE", paddingTop: "30px", marginLeft: "30px", display: "block"}} href="/"><MdIcons.MdKeyboardBackspace /> Return to Login</a>
            }
            
            <div id="navigationBreadcrumbs" style={{width: "650px", marginLeft: "auto", marginRight: "auto", display: "block", marginTop: "20px", marginBottom: "-40px"}}>
                <div style={{display: "inline-flex"}}>
                    <p style={{color: "#0097EE", cursor: "pointer"}} onClick={() => {  navigate(FRONTEND_LINKS.SUBPAGES.RELEASE_CHANGELOGS.MAIN); setSelectedRelease(''); if (document.getElementById("changelogIndex")){document.getElementById("changelogIndex").style.display = "block"}; if (document.getElementById("changelogInformation")){document.getElementById("changelogInformation").style.display = "none"}}}>Release Changelogs</p>
                    {selectedRelease ?
                    <>
                    <p style={{color: "#000000"}}><MdIcons.MdKeyboardArrowRight/></p>
                    <p style={{color: "#0097EE"}}>{selectedRelease}</p>
                    </>
                    :
                    null
                    }
                </div>
                
            </div>
            <PageBody id="ReleaseChangelogsPageBody" >
                <div id="changelogIndex" style={{width: "650px", marginLeft: "auto", marginRight: "auto"}}>
                    <h1>Release Changelogs</h1>
                    <ul>
                        {Object.entries(releaseData).map(([key, value]) => {
                            return (<>
                                <li style={{color: "#0097EE", textDecoration: "underline", cursor: "pointer"}} onClick={() => {setSelectedRelease(key); 
                                navigate(`${FRONTEND_LINKS.SUBPAGES.RELEASE_CHANGELOGS.MAIN}?version=${key}`); 
                                document.getElementById("changelogIndex").style.display = "none"; 
                                if (document.getElementById("changelogInformation")) {
                                    document.getElementById("changelogInformation").style.display = "block"
                                } 
                               }}>{key}</li>
                            </>)
                        })}
                        
                    </ul>
                </div>

                <br/>
                {selectedRelease ? 
                <div id="changelogInformation" style={{width: "650px", marginLeft: "auto", marginRight: "auto"}}>
                    <h2>{selectedRelease}</h2>

                    <p>Please continue to report any new bugs at our<a target="_blank" rel="noopener noreferrer" style={{color: "#0097EE"}} href="https://moberganalytics.atlassian.net/servicedesk/customer/portal/3" > Bug Reporting Portal</a> and leave us your feedback at our<a target="_blank" rel="noopener noreferrer" style={{color: "#0097EE"}} href="https://moberganalytics.atlassian.net/servicedesk/customer/portal/3"> Customer Service Portal</a>.</p>
                    
                    <p><strong>Name: </strong> {releaseData[selectedRelease]['name']}</p>
                    <p><strong>Posted: </strong> {releaseData[selectedRelease]['posted']}</p>
                    <p><strong>Summary: </strong> {releaseData[selectedRelease]['summary']}</p>
                    <br/>

                    {Object.keys(releaseData[selectedRelease]['features']).length > 0 ?
                    <>
                    <h3>New Features:</h3>
                    {Object.entries(releaseData[selectedRelease]['features']).map(([key, value]) => {
                        if (releaseData[selectedRelease]['features'][key].length > 0) {
                            return (<>
                                <p style={{fontSize: "18px"}}><strong>{key}</strong></p>
                                {releaseData[selectedRelease]['features'][key].map(function(elem, index, arr) {
                                    return (
                                        <div>
                                        <p><strong>{elem['name']}</strong></p>
                                        <p>{elem['description']}</p>
                                        <br/>
                                        </div>
                                    )
                                })}
                            </>)
                        }
                        
                    })}</>
                    :
                    undefined}

                    {Object.keys(releaseData[selectedRelease]['bugfixes']).length > 0 ?
                    <>
                    <br/>
                    <h3>Bug Fixes: </h3>
                    {Object.entries(releaseData[selectedRelease]['bugfixes']).map(([key, value]) => {
                    if (releaseData[selectedRelease]['bugfixes'][key].length > 0) {
                        return (<>
                        <p style={{fontSize: "18px"}}><strong>{key}</strong></p>
                        {releaseData[selectedRelease]['bugfixes'][key].map(function(elem, index, arr) {
                                return (
                                    <div>
                                    <p><strong>{elem['name']}</strong></p>
                                    <p>{elem['description']}</p>
                                    <br/>
                                    </div>
                                )
                            })}
                        </>)
                    }
                    })}
                    </>
                    :
                    undefined
                    }

                    {Object.keys(releaseData[selectedRelease]['changes']).length > 0 ?
                    <>
                    <br/>
                    <h3>Bug Fixes: </h3>
                    {Object.entries(releaseData[selectedRelease]['changes']).map(([key, value]) => {
                    if (releaseData[selectedRelease]['changes'][key].length > 0) {
                        return (<>
                        <p style={{fontSize: "18px"}}><strong>{key}</strong></p>
                        {releaseData[selectedRelease]['changes'][key].map(function(elem, index, arr) {
                                return (
                                    <div>
                                    <p><strong>{elem['name']}</strong></p>
                                    <p>{elem['description']}</p>
                                    <br/>
                                    </div>
                                )
                            })}
                        </>)
                    }
                    })}
                    </>
                    :
                    undefined
                    }
                </div>
                :
                null
                }
            </PageBody>
            </div>

            :

            <div id="404NotFound" style={{marginLeft: "0px", textAlign: "center", paddingTop: `${window.innerHeight / 3}px`, height: `${window.innerHeight}px`}}>
                <div style={{color: 'black'}}>
                    <h1 style={{fontFamily: "Montserrat", fontStyle: "normal", fontWeight: "700", fontSize: "45px"}}>
                        404
                    </h1>
                    <br/>
                    <h2 style={{fontFamily: "Montserrat", fontStyle: "normal", fontWeight: "600", fontSize: "30px", marginTop: "-25px"}}>
                        Not Found
                    </h2>
                </div>
                    <Link to={FRONTEND_LINKS.SUBPAGES.RELEASE_CHANGELOGS.MAIN} onClick={() => props.setNavPage(true)}>
                        <div style={{color: 'black'}}>
                            <p style={{font: "Montserrat", fontSize: "16px", color: "#207DEA"}} onClick={() => {setSelectedRelease(''); navigate(FRONTEND_LINKS.SUBPAGES.RELEASE_CHANGELOGS.MAIN); setVersionExists(true)}} >
                                Click here to return to Release Changelogs.
                            </p>
                        </div>
                    </Link>
                    
                </div>
        }
        </Scrollbar>
        </div>
    )
}

const PageBody = styled.div`
    padding: 50px 50px;
    display: block;
    h1 {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        color: #000000;
    }
    h2 {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        color: #000000;
    }
    h3 {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        color: #000000;
    }
    li {
        font-family: Source Sans Pro;
        font-style: normal;
        color: #000000;
        font-size: 16px;
        width: fit-content;
    }
    p {
        font-family: Source Sans Pro;
        font-style: normal;
        color: #000000;
        font-size: 16px;
    }
`;

const Scrollbar = styled.div`
    overflow-y: scroll;
	scrollbar-width: thin;
	::-webkit-scrollbar {
        display: block;
        width: 8px;
        color: #313A4A;
	}
	::-webkit-scrollbar-track {
        background: #BEC4CF;
        width: 8px;
        border-radius: 2px;
	}
`;
export default ReleaseChangelogs;