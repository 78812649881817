import React, { useState, createContext, useContext } from "react"

const TableContext = createContext()

export function TableProvider({ children }) {
	const [toastProps, setToastProps] = useState({
		isOpen: false,
		severity: "success",
		message: "This is a success message!",
	})

	return <TableContext.Provider value={{ toastProps, setToastProps }}>{children}</TableContext.Provider>
}

export function useTableContext() {
	const context = useContext(TableContext)
	if (context === undefined) {
		throw new Error("useTableContext must be used within a TableProvider")
	}
	return context
}
