import { ImagePage, ImagePageProperties } from "./ImagePage"
import { TimeSeriesPageManager, TimeSeriesPageManagerConfig } from "./TimeSeriesPageManager"

export type ImagePageManagerConfig = TimeSeriesPageManagerConfig & {
	height: number
}

export class ImageTimeSeriesPageManager<ConfigType extends ImagePageManagerConfig=ImagePageManagerConfig> extends TimeSeriesPageManager<ImagePage, ConfigType> {
	getPageMaker(): () => ImagePage {
		return () => new ImagePage()
	}

	protected updatePageProperties(page: ImagePage<ImagePageProperties>, startTime: number, endTime: number, width: number, timeZoneOffsetMs: number): void {
		page.updateProperties({
			startTime,
			endTime,
			width,
			socketId: this.config.windowId,
			patientId: this.config.patientId,
			timeZoneOffsetMs: timeZoneOffsetMs,
			modalityDataSources: this.config.modalityDataSources,
			patientIsAdmitted: this.config.patientIsAdmitted,
			height: this.config.height,
			getDataQuerySocket: this.getDataQuerySocket
		})
	}

	// Images take up much less memory than actual time series data, so we can cache much more of it.
	protected getNumberOfCachedPages(): number {
		return 200
	}
}