import ModalHeader from "../../../../../Components/ModalHeader/ModalHeader";
import Accordion from "../../../../../Components/Accordion/Accordion";
import { useUploadProcessProvider } from "../../../../../Providers/UploadProcessProvider";
import { useUploadQueueProvider } from "../../../../../Providers/UploadQueueProvider";
import { useUploadIntoQueueProvider } from "../../../../../Providers/UploadIntoQueueProvider";
import { useModalProvider } from "../../../../../Providers/ModalProvider";
import { UploadFilesModal, DataSummaryDiv, AgreementLabel, ModalFooter, UploadStepHeader } from "../../../../../Constants/StyledComponents";
import UploadSummaryData from './UploadSummaryData';
import { useState } from 'react';
import { InfoMessage } from "../../../../../Constants/StyledComponents";
import { MobergButton } from "../../../../../Components/MobergButton/MobergButton";
import { MobergTheme } from "../../../../../Components/MobergThemes/MobergColors";
import { MobergButtonVariant } from "../../../../../Components/MobergButton/MobergButton";
import { MobergButtonShape } from "../../../../../Components/MobergButton/MobergButton";
import styled from "styled-components";
import { useOnMount } from "../../../../../Hooks/useOnMount";
import { calculateUploadSize } from "../UploadHelperFunctions";
import { useEnvironmentVariablesProvider } from "../../../../../Providers/EnvironmentVariablesProvider";

const UploadSummaryPatient = ({
    setToPrevWindow,
    setToNextWindow,
}) => {
    const uploadProcessProvider = useUploadProcessProvider()
    const uploadIntoQueueProvider = useUploadIntoQueueProvider()
    const uploadQueueProvider = useUploadQueueProvider()
    const environmentVariablesProvider = useEnvironmentVariablesProvider()

    let uploadHeaderText = `Summary for Patient ${uploadProcessProvider.patientID} at ${uploadProcessProvider.selectedSiteName}`

    let handleOverflowUploadHeaderText = uploadHeaderText.length > 75 ? `Summary for Patient ${uploadProcessProvider.patientID.length > 15 ? uploadProcessProvider.patientID.slice(0, 15) + "..." : uploadProcessProvider.patientID} at ${uploadProcessProvider.selectedSiteName.length > 40 ? uploadProcessProvider.selectedSiteName.slice(0, 40) + "..." : uploadProcessProvider.selectedSiteName}` : uploadHeaderText

    const handleBack = () => {
        setToPrevWindow(true);
    }

    /**
     * Filters an array of files and folders, removing empty folders and files with size 0.
     * Un-nest nested folder [[],[],[]] into a single array [].
     * @param {Array} folder - An array containing files and folders.
     * @returns {Array} - A filtered, flatten array containing files and non-empty folders.
     */
    const filterFiles = (folder) => {
        const flattenedFiles = folder.reduce((accumulator, current) => {
            return accumulator.concat(current);
        }, []);
        return flattenedFiles.filter((file) => {
            if (file.type === 'folder') {
                return file.children.length > 0;
            } else {
                return file.size > 0;
            }
        });
    }

    const id = Date.now();

    /**
     * This function creates and prepares an upload data object for later upload.
     * It collects various pieces of information and organizes them into an object then transfer them to a context provider for later transfer to the upload queue.
     */
    const createUploadData = () => {
        let uploadData = {
            cns: filterFiles(uploadProcessProvider.cnsFolders),
            medications: uploadProcessProvider.medicationsFiles,
            natus: filterFiles(uploadProcessProvider.natusFolders),
            patient_id: uploadProcessProvider.patientID,
            site_id: uploadProcessProvider.selectedSitePrimaryKey,
            project_id: uploadProcessProvider.selectedProjectPrimaryKey,
            id: id,
            cns_directory_names: uploadProcessProvider.cnsDirectoryNames.map(obj => obj.name),
            natus_directory_names: uploadProcessProvider.natusDirectoryNames.map(obj => obj.name),
            medications_file_names: uploadProcessProvider.medicationsFileNames.map(obj => obj.name)
        }

        uploadIntoQueueProvider.setUploadData(uploadData);
        uploadIntoQueueProvider.setForm(uploadProcessProvider.submittedForm)
        uploadData = null;
    }



    const [totalUploadSize, setTotalUploadSize] = useState('') // stores the upload size for all 3 data types (Moberg Data, Natus EEG, and Medications)
    const [mobergUploadMetric, setMobergUploadMetric] = useState('') // stores the upload size & number of directories for Moberg Data
    const [natusUploadMetric, setNatusUploadMetric] = useState('') // stores the upload size & number of directories for Natus EEG
    const [medicationsUploadMetric, setMedicationsUploadMetric] = useState('') // stores the upload size & number of files for Medications




    /**
     * This function creates and prepares an object containing the names of the directories that already exist in the upload queue.
     * It collects id, directory names and organizes them into an object then transfer them to a context provider for later validation within the queue.
     * This is used to prevent the user from uploading the same data twice.
     */
    const createExistingDirectoryNames = () => {
        const currentUploadsString = sessionStorage.getItem('currentUploads')
        const patient_id = uploadProcessProvider.patientID
        const site_id = uploadProcessProvider.selectedSitePrimaryKey
        const project_id = uploadProcessProvider.selectedProjectPrimaryKey

        const cns_directory_names = uploadProcessProvider.cnsDirectoryNames.map(obj => obj.name)
        const natus_directory_names = uploadProcessProvider.natusDirectoryNames.map(obj => obj.name)
        const medications_file_names = uploadProcessProvider.medicationsFileNames.map(obj => obj.name)

        if (currentUploadsString) {
            const currentUploads = JSON.parse(currentUploadsString)

            if (Object.keys(currentUploads).includes(`${site_id}-${project_id}-${patient_id}`)) {
                const currentDataObjects = currentUploads[`${site_id}-${project_id}-${patient_id}`]

                currentUploads[`${site_id}-${project_id}-${patient_id}`] = [...currentDataObjects, ...cns_directory_names, ...natus_directory_names, ...medications_file_names]
            } else {
                currentUploads[`${site_id}-${project_id}-${patient_id}`] = [...cns_directory_names, ...natus_directory_names, ...medications_file_names]
            }

            sessionStorage.setItem('currentUploads', JSON.stringify(currentUploads))
        } else {
            const currentUploads = {}

            currentUploads[`${site_id}-${project_id}-${patient_id}`] = [...cns_directory_names, ...natus_directory_names, ...medications_file_names]

            sessionStorage.setItem('currentUploads', JSON.stringify(currentUploads))
        }
    }

    /**
    * This `useEffect` is responsible for calculating and setting various upload metrics
    * based on table data provided by the `uploadProcessProvider` to render a "Summary".
    * Calculate the upload metric for a given table of files.
    * @param {Array} tableData - The table data representing files.
    * @returns {string} - A string representing the calculated upload metric.
    */
    useOnMount(() => {
        if (environmentVariablesProvider.environmentVariables.ENCRYPT || !environmentVariablesProvider.environmentVariables.PHI_WARNING) uploadProcessProvider.setRemovePHIAgreementChecked(true)

        // Calculate and set upload metrics for CNS, Natus, and Medications tables
        setMobergUploadMetric(calculateUploadSize(uploadProcessProvider.cnsTableData));
        setNatusUploadMetric(calculateUploadSize(uploadProcessProvider.natusTableData));
        setMedicationsUploadMetric(calculateUploadSize(uploadProcessProvider.medicationsTableData));
        // Calculate and set the total upload size metric for all tables combined
        setTotalUploadSize(calculateUploadSize([...uploadProcessProvider.cnsTableData, ...uploadProcessProvider.natusTableData, ...uploadProcessProvider.medicationsTableData]));
    })

    const { close } = useModalProvider()

    /**
     * This function initiates the upload process.
     * It sets the selected site ID and patient ID to the upload queue provider.
     * It creates and prepares an upload data object for later upload.
     * It creates and prepares an object containing the names of the directories that already exist in the upload queue.
     * It sets the upload queue to open and the upload summary to close.
     * It resets the upload process provider cache.
     * It sets the upload queue to close.
     * @returns {void}
     * @example
     **/
    function handleNext() {
        if (uploadQueueProvider.selectedSiteID === '' && uploadQueueProvider.patientID === '') {
            uploadQueueProvider.setSelectedSiteID(uploadProcessProvider.selectedSiteID)
            uploadQueueProvider.setSelectedSiteName(uploadProcessProvider.selectedSiteName)
            uploadQueueProvider.setPatientID(uploadProcessProvider.patientID)
            uploadQueueProvider.setSitePrimaryKey(uploadProcessProvider.selectedSitePrimaryKey)
            uploadQueueProvider.setProjectPrimaryKey(uploadProcessProvider.selectedProjectPrimaryKey)
        }
        uploadQueueProvider.setQueuedSiteID(uploadProcessProvider.selectedSiteID)
        uploadQueueProvider.setQueuedPatientID(uploadProcessProvider.patientID)
        uploadQueueProvider.setQueuedSiteName(uploadProcessProvider.selectedSiteName)
        createUploadData();
        createExistingDirectoryNames();
        environmentVariablesProvider.environmentVariables.FORM ? close() : setToNextWindow(true);
        uploadIntoQueueProvider.setOpenQueue(true);
        uploadProcessProvider.resetCache();
        uploadQueueProvider.setCloseQueue(false)
    }

    // Summary of the upload inputs from the user using the accordions
    const accordionData = {
        ...(!environmentVariablesProvider.environmentVariables.CNS || environmentVariablesProvider.environmentVariables.FORM
            ? {}
            : {
                'mobergData': {
                    'label': "Moberg Data",
                    'innerContent': <UploadSummaryData label={"Moberg Data"} />,
                    'description': mobergUploadMetric
                }
            }),
        ...(!environmentVariablesProvider.environmentVariables.NATUS
            ? {}
            : {
                'natusEEG': {
                    'label': "Natus",
                    'innerContent': <UploadSummaryData label={"Natus"} />,
                    'description': natusUploadMetric
                }
            }
        ),
        ...(!environmentVariablesProvider.environmentVariables.MEDICATIONS || environmentVariablesProvider.environmentVariables.FORM
            ? {}
            : {
                'medications': {
                    'label': 'Medications',
                    'innerContent': <UploadSummaryData label={"Medications"} />,
                    'description': medicationsUploadMetric
                }
            }),
        ...(!environmentVariablesProvider.environmentVariables.FORM
            ? {}
            : {
                'form': {
                    'label': 'Form',
                    'innerContent': <UploadSummaryData label={"Form"} />,
                }
            })
    }


    return (<>
        <div>
            <UploadFilesModal>
                <ModalHeader headerText={handleOverflowUploadHeaderText} resetCache={uploadProcessProvider.resetCache} />
                <div style={{ paddingTop: "15px", paddingLeft: "47px", paddingRight: "47px", paddingBottom: "0px", height: "600px", overflowY: "scroll", flex: 1 }}>
                    <div style={{ marginBottom: '24px' }} >
                        <UploadStepHeader>4. Confirm data upload</UploadStepHeader>
                    </div>

                    {environmentVariablesProvider.environmentVariables.CNS && uploadProcessProvider.cnsTableData.length > 0 && environmentVariablesProvider.environmentVariables.ENCRYPT && (
                        <InfoMessage style={{ marginBottom: '16px', marginTop: '-10px' }}>
                            <p style={{ color: 'rgb(32, 125, 234)' }}>
                                <strong>Info! </strong>The patient.info and admission.info files in your CNS directories will be encrypted on upload.
                            </p>
                        </InfoMessage>
                    )}

                    <div style={{ display: 'flex' }}>
                        <h1 style={{ marginBottom: "10px", color: "#207DEA" }}>Review Uploads</h1>
                    </div>

                    <Accordion data={accordionData} />

                    <div>
                        <h1 style={{ marginBottom: "10px", color: "#207DEA", marginTop: "10px" }}>Data Summary</h1>

                        <DataSummaryDiv>
                            <h2>Total size of upload:</h2>
                            <p>{totalUploadSize}</p>
                        </DataSummaryDiv>

                        {environmentVariablesProvider.environmentVariables.PHI_WARNING && <AgreementLabel>
                            <input type="checkbox" checked={uploadProcessProvider.removePHIAgreementChecked}
                                onChange={() => { uploadProcessProvider.setRemovePHIAgreementChecked(!uploadProcessProvider.removePHIAgreementChecked) }}
                                style={{ width: "18px", height: "18px" }} />
                            <ConfirmationDiv removePHIAgreementChecked={uploadProcessProvider.removePHIAgreementChecked}>
                                I confirm that I have removed all PHI from this data set.
                            </ConfirmationDiv>
                        </AgreementLabel>}
                    </div>
                </div>
                <hr style={{ border: "1px solid #B3B3B3", width: "100%", margin: '0px auto' }} />
                <ModalFooter>
                    <MobergButton
                        theme={MobergTheme.BLUE}
                        variant={MobergButtonVariant.OUTLINE}
                        shape={MobergButtonShape.WIDE}
                        onClick={handleBack}
                    >
                        Back
                    </MobergButton>

                    <MobergButton
                        theme={MobergTheme.BLUE}
                        variant={MobergButtonVariant.FILLED}
                        shape={MobergButtonShape.WIDE}
                        onClick={handleNext}
                        disabled={!uploadProcessProvider.removePHIAgreementChecked}
                    >
                        Upload
                    </MobergButton>
                </ModalFooter>
            </UploadFilesModal>
        </div>
    </>
    )
}

export default UploadSummaryPatient;

const ConfirmationDiv = styled.div`
    color: ${(props) => (props.removePHIAgreementChecked ? '#000000' : '#E54E58')};
    font-size: 16px;
    font-family: 'Source Sans Pro';
    font-weight: 400;
    position: relative;

    &:after {
        content: ${(props) => (props.removePHIAgreementChecked ? '""' : "'*'")};
        color: red;
        display: inline-flex;
        font-size: 22px;
        position: absolute;
        right: -10px; // Adjust as necessary
        top: 0; // Adjust as necessary
    }
`;