
import { useState, useContext, useEffect, useCallback } from 'react';
import { TableContext } from './TableContext'
import styled from "styled-components";
import DataTable from "react-data-table-component";
import * as MdIcons from "react-icons/md"
import { primary, secondary } from '../TableComponent/styles';


// RowsContainerComponent
export function RowsContainer({
    columns=[],
    selectableRows=false,
    onSelectedRowsChange=false,
    rowsPerPage=10,
    selectableRowDisabled=()=>false,
    onRowClicked=()=>{},
    wrapperStyle,
    theme="main",
    clearSelectedRows=false,
    overFlowYEnabled,
    isPreview=false,
    ...props
}) {
    const {
        setRowsPerPage,
        setDisplayedColumns,
        displayedRows,
        uniqueRef,
        setOrderBy,
        setOrderDirection,
        rowsContainerRef,
    } = useContext(TableContext)

    const [displayedRefs, setDisplayedRefs] = useState([])
    const [selectedRefs, setSelectedRefs] = useState([])
    const [toggledClearRows, setToggleClearRows] = useState(clearSelectedRows)

    const needSwitchToggle = toggledClearRows === clearSelectedRows
    useEffect(() => setToggleClearRows(clearSelectedRows), [setToggleClearRows, needSwitchToggle, clearSelectedRows])

    const _onSelectedRowsChange = useCallback(({selectedRows}) => {
        const noSelectChange = (selectedRows.length === selectedRefs.length) &&
            (selectedRows.filter(row =>
                (row[uniqueRef] === undefined) &&
                (!selectedRefs.includes(row[uniqueRef]))
            ).length === 0)
        if (!noSelectChange) {
            const displayedSelectedRows = selectedRows.filter(row => 
                (!row.mock) &&
                (row[uniqueRef] !== undefined) &&
                displayedRefs.includes(row[uniqueRef])
            )
            setSelectedRefs(displayedSelectedRows.map(row => row[uniqueRef]))
            if (onSelectedRowsChange) { onSelectedRowsChange({selectedRows: displayedSelectedRows}) }
        }
    }, [selectedRefs, uniqueRef, displayedRefs, onSelectedRowsChange])
    
    useEffect(() => {
        const newDisplayedRefs = displayedRows.map(row => row[uniqueRef]).filter(ref=> ref!==undefined)
        if (newDisplayedRefs.length !== displayedRefs.length ||
            newDisplayedRefs.filter(ref => !displayedRefs.includes(ref)).length > 0) {
            setDisplayedRefs(newDisplayedRefs)
            setToggleClearRows(prev => !prev)
            setSelectedRefs([])
            if (onSelectedRowsChange) { onSelectedRowsChange({selectedRows: []}) }
        }
    }, [displayedRows, displayedRefs, uniqueRef, onSelectedRowsChange])

    useEffect(() => {
        setRowsPerPage(rowsPerPage)
    }, [rowsPerPage, setRowsPerPage])
    
    useEffect(() => {
        setDisplayedColumns(columns)
    }, [columns, setDisplayedColumns])

    const onSort = useCallback((orderByColumn, orderDirection) => {
        setOrderBy(orderByColumn.attr)
        setOrderDirection(orderDirection)
    }, [setOrderBy, setOrderDirection])
    
    // When there is no data, mock a fake line, also change the selectors to prevent from error
    const hasSomeData = displayedRows.length > 0
    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        columns = columns.map(col=>({...col, selector: () => null}))
        const tableBody = rowsContainerRef.current?.children[0]?.children[0]?.children[0]?.children[0]?.children[0]?.children[1]

        if (tableBody?.children?.length >= 1) { // check if the row(s) are rendered or not
            tableBody.children[0].style.display = hasSomeData ? "" : "none"
        }
    }, [hasSomeData])
    
    let args = {
        ...props,
        data: hasSomeData ? displayedRows :
            [columns.map(col=>col.attr).reduce((a, v) => ({ ...a, [v]: null}), {mock: true})], // no data, mock a fake line
        columns,
        // selectableRowSelected: row => selectedRefs.includes(row[uniqueRef]), // disable pre-selection
        selectableRowDisabled: row => row.mock || selectableRowDisabled(row),
        onSort,
        sortServer:true, // disables the internal sorting
        onRowClicked: hasSomeData ? onRowClicked : ()=>{},
    }

    // turn on selection functionality when needed
    if (selectableRows || onSelectedRowsChange) {
        args = {
            ...args,
            selectableRows: true,
            onSelectedRowsChange: (selectableRows || onSelectedRowsChange) ? _onSelectedRowsChange : false,
            clearSelectedRows: toggledClearRows,
        }
    }

    // add settings based on the theme
    switch (theme) {
        case "main":
            args = {
                highlightOnHover: isPreview ? false :true,
                pointerOnHover: isPreview ? false :true,
                responsive: true,
                sortIcon: <MdIcons.MdUnfoldMore style={{color: "#207DEA" }} />,
                customStyles: primary,
                dense: true,
                ...args
            }
            break;
        case "dense": // need change
            args = {
                highlightOnHover: isPreview ? false : true,
                pointerOnHover: isPreview ? false : true,
                responsive: true,
                sortIcon: <MdIcons.MdUnfoldMore style={{color: "#207DEA" }} />,
                customStyles: secondary,
                ...args
            }
            break;
        default:
            break;
    }

    return (
        <VerticalContainer
            className={'VerticalContainer'}
            ref={rowsContainerRef}
            style={{wrapperStyle, overflowY: (hasSomeData && overFlowYEnabled) ? "scroll" : "hidden"}}
        >
            <HorizontalScrollContainer
                className={'HorizontalScrollContainer'}
            >

                <HorizontalScrollContent
                    className={'HorizontalScrollContent'}
                >
                
                    <DataTable {...args} />

                </HorizontalScrollContent>
            </HorizontalScrollContainer>

            {hasSomeData ? null : (
                <NoAvailableRowWarn>
                    There are no records to display
                </NoAvailableRowWarn>
            )}
        </VerticalContainer>
    )
}



const NoAvailableRowWarn = styled.h3`
    justify-content: center;
    display: flex;
    height: 50px;
    padding: 10px;
`

const VerticalContainer = styled.div`
    overflow-x: auto;
    
	::-webkit-scrollbar {
        width: 4px;
		display: block;
		height: 4px;
		color: #313a4a;
		border-radius: 2px;
	}
	::-webkit-scrollbar-track {
        width: 4px;
		background: #bec4cf;
		height: 4px;
		border-radius: 2px;
	}
`


const HorizontalScrollContent = styled.div`
    border-radius: 3px;
    width: inherit;
    overflow-x: auto;
    ::-webkit-scrollbar {
    display: block;
    }

`

const HorizontalScrollContainer = styled.div`
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%; // do not change it! it is for scroll bar
    overflow-x: auto;
    overflow-y: visible;

    *::-webkit-scrollbar {
    display: block;
    width: 5px;
    height: 5px;
    color: #fff0;
    }
    ::-webkit-scrollbar-track {
    background: #bfbfbf3d;
    border-radius: 3px;
    }
    ::-webkit-scrollbar-thumb {
    border-radius: 3px;
    }
    ::-webkit-scrollbar-thumb:hover {
    background: #86868680; 
    }
`;


