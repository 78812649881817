// Design for replacing the old DataTable, 2023/3/7, by Mike
import { createContext, useCallback, useContext, useEffect, useState, useRef  } from "react";
import { buildGetRows } from "./Static"

export const TableContext = createContext({
    useLocal: null, // true means fetch all the data to frontend while false means only request for the current page
    // LocalData: [], // all the rows stored in the front end. it will be empty when using remote endpoint
    uniqueRef: null, // cols = ["id", "name"], id is the unique reference, uniqueRef = "id"
    pageIndex: 1, // start from 1
    rowsPerPage: 10, // default value 10
    searchQuery: {columns: [], keywords: ""},
    filterRules: [],
    totalPages: 1,
    displayedColumns: [], // columns displayed on the rows container
    displayedRows: [], // columns displayed on current page
    setPageIndex: null,
    setRowsPerPage: null,
    setOrderBy: null,
    setOrderDirection: null,
    setSearchQuery: null,
    setFilterRules: null,
    setDisplayedColumns: null,
    getRows: null,
    rowsContainerRef: null,
    updateTableTimeout: null
})


// TableComponent
export function TableComponent({
    data=[], // one of [[data, uniqueRef], [data, getData]] is required
    getData: _getData, // use remote, if uniqueRef is not provided, getData must be provided
    uniRef:_uniRef=null, // if getData is provided, uniqueRef will be optional
    children
}) {
    const [useLocal, setUseLocal] = useState(_getData ? false : true)
    // const [LocalData, setLocalData] = useState(data) // LocalData == [] if using remote endpoint

    const [displayedRows, setDisplayedRows] = useState([])
    const [uniRef, setUniRef] = useState(_uniRef) // if using local, once uniqueRef init, it won't change later
    const [displayedColumns, setDisplayedColumns] = useState([])
    const [getRows, setGetRows] = useState(data && _getData ? ()=>_getData :
        ( // when using useState to store function, we have to use ()=>func
            data && _uniRef ? ()=>buildGetRows(data, _uniRef, displayedColumns) :
            null // Unexpected Case
        )
    )

    useEffect(() => {
        // async data update, update the data from getRows --> some effects --> data argument
        if ((_getData !== undefined) && uniRef && Array.isArray(data)) { // wait until the uniRef initialized
            const refsA = displayedRows.map(row=>row[uniRef])
            const refsB = data.map(row=>row[uniRef])
            if ((refsA.length !== refsB.length) || (refsA.filter(ref=>!refsB.includes(ref)).length !== 0)) {
                setDisplayedRows(data)
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uniRef, data]) // We don't need displayedRows as dependencies here

    useEffect(() => {
        if (_getData) { // use remote endpoint
            setGetRows(()=>_getData)
            setUseLocal(false)
            // setLocalData([])
        }
    }, [_getData])

    useEffect(() => { // use local data to build
        if ((!_getData) && data && _uniRef) { // "&& !_getData" is required
            setGetRows(()=>buildGetRows(data, _uniRef, displayedColumns))
            setUseLocal(true)
            // setLocalData(data)
        }
    }, [data, _uniRef, _getData, displayedColumns])

    const [pageIndex, setPageIndex] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(0) // 10 is the default value from react dataTable

    const [orderBy, setOrderBy] = useState(null) // {name, selector, ...}
    const [orderDirection, setOrderDirection] = useState("asc") // "asc" or "desc"

    const [searchQuery, setSearchQuery] = useState({columns: [], keywords: ""})
    const [filterRules, setFilterRules] = useState([])

    const [totalPages, setTotalPages] = useState(0)


    useEffect(() => setPageIndex(1), [searchQuery, filterRules])

    const updateTable = useCallback((res) => {
        const {
            data: _data,
            uni_ref,
            page_index,
            total_pages
        }= res

        if (Array.isArray(_data)){ // use Local data, if use remote, it can trigger change of data arg
            setDisplayedRows(_data)
        }
        if ((uni_ref !== undefined) && (uniRef !== uni_ref)){
            setUniRef(uni_ref) }
        if ((page_index !== undefined) && (pageIndex !== page_index)){
            setPageIndex(page_index) }
        if ((total_pages !== undefined) && (totalPages !== total_pages)){
            setTotalPages(total_pages) }
        
    }, [pageIndex, totalPages, uniRef])

    const setPending = useCallback((state) => {
        if (rowsContainerRef.current){
            rowsContainerRef.current.style.pointerEvents = state ? "none" : "auto";
            rowsContainerRef.current.style.opacity = state ? 0.5 : 1
        }
    }, [])

    // timeoutID == null means no pending,
    // if any argument change, update the table constant by calling getRows immediately
    const [timeoutID, setTimeoutID] = useState({current: null}) // fake useRef
    const updateTableTimeout = useCallback((timeout=0) => {
        if (timeoutID.current !== null) {
            clearTimeout(timeoutID.current)
            // console.log("clearTimeout", timeoutID.current)
        }
        if (timeout <= 0) {
            setTimeoutID({current: null}) // this one will trigger rerender
            // console.log("Timeout!")
        } else {
            const newTimeoutID = timeout <= 0
            ? null
            : setTimeout(
                () => {
                    if (timeoutID.current !== null) { // prevent to trigger extra rerender
                            timeoutID.current = null // this one will work immediately
                            setTimeoutID({current: null}) // this one will trigger rerender
                            // console.log("Timeout!")
                        }
                    },
                timeout
            )
            timeoutID.current = newTimeoutID // this won't raise rerender
            // console.log("setTimeout", timeoutID.current)
        }
    }, [timeoutID])
    
    const [needUpdate, setNeedUpdate] = useState(false)

    useEffect(() => {
        setNeedUpdate(true)
    }, [
        setNeedUpdate,
        getRows,
        pageIndex,
        rowsPerPage,
        orderBy,
        orderDirection,
        searchQuery,
        filterRules
    ])
    useEffect(() => {
        // console.log("triggered!")
        // setTimeout(() => setPending(false), 3000)
        if (needUpdate // some settings did change
            && (timeoutID.current === null) // no pending timeout
            && getRows) { // getRows is not undefined
            setPending(true)
            // console.log("getRows")
        // if (getRows) {
            getRows({
                pageIndex,
                rowsPerPage,
                orderBy,
                orderDirection,
                search:searchQuery,
                filter:filterRules,
            })
            .then(updateTable)
            .then(() => {
                setPending(false)
                setNeedUpdate(false)
            })
            // .then(() => console.log("updateTable"))
        }
        // setTimeout(()=> {
        //     getRows({
        //         pageIndex,
        //         rowsPerPage,
        //         orderBy,
        //         orderDirection,
        //         search:filterRules.search,
        //         filter:filterRules.filter,
        //     })
        //     .then(updateTable)
        //     .then(() => setPending(false))
        // }, 5000)
        

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        // updateTable, // change when data or getData argument change, we don't need it here! add it to the dependencies will raise one extra rerender
        // setPending, // never change
        timeoutID, // change when all the pending finished
        needUpdate, // change when the settings listed below change
        getRows, // do not comment this line
        // pageIndex,
        // rowsPerPage,
        // orderBy,
        // orderDirection,
        // searchQuery,
        // filterRules
    ])


    const rowsContainerRef = useRef()

    return (<TableContext.Provider value={{
        useLocal,
        // LocalData,
        uniqueRef: uniRef,
        pageIndex,
        rowsPerPage,
        searchQuery,
        filterRules,
        totalPages,
        displayedColumns,
        displayedRows,
        setPageIndex,
        setRowsPerPage,
        setOrderBy,
        setOrderDirection,
        setSearchQuery,
        setFilterRules,
        setDisplayedColumns,
        getRows,
        rowsContainerRef,
        updateTableTimeout
    }}>
        {children}
    </TableContext.Provider>)
}


export const useTableComponent = () => useContext(TableContext)