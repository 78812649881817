import { ScaleTime, Selection, EnterElement, ScaleBand, ScaleLinear, zip } from "d3"
import { seriesCanvasLine } from "d3fc"
import { EEGMontageReactCallbacks, SdDetectionReactCallbacks } from "../../../../Types/ReactCallbacks"
import { EEGTimeSeriesPageManager } from "../../../../Data/EEGTimeSeriesPageManager"
import { EEGPage } from "../../../../Data/EEGPage"
import { D3OneToOneRenderable } from "../../../D3/D3OneToOneRenderable"
import { CompositeTimeSeriesData } from "../../../../Data/TimeSeriesData"

export type EEGCanvasConfig = {
	graphId: string
	viewScale: ScaleTime<any, any, any>
	channelScale: ScaleBand<string>
	sensitivityScaleVoltsToPixels: ScaleLinear<any, any, any>
	eegModality: string | undefined
	dataObjectId: number
	color?: string
}

export class D3EEGCanvas extends D3OneToOneRenderable<SVGGElement, SVGForeignObjectElement, EEGCanvasConfig, EEGMontageReactCallbacks | SdDetectionReactCallbacks> {
	private offscreenRenderer = seriesCanvasLine()
	private context?: CanvasRenderingContext2D | null
	private pageManager: EEGTimeSeriesPageManager
	private offscreenCanvas: OffscreenCanvas
	private offscreenXScale: ScaleTime<any, any, any>
	private pageRectangle = { x: 0, y: 0, width: 0, height: 0 }

	constructor(root: SVGGElement, config: EEGCanvasConfig, pageManager: EEGTimeSeriesPageManager, reactCallbacks: EEGMontageReactCallbacks | SdDetectionReactCallbacks) {
		super(root, config, "d3-eeg-canvas", reactCallbacks)
		this.pageManager = pageManager

		const width = config.viewScale.range()[1]
		const height = config.channelScale.range()[1]
		this.offscreenCanvas = new OffscreenCanvas(width, height)

		this.offscreenXScale = config.viewScale.copy().range([0, width])

		this.offscreenRenderer = seriesCanvasLine()
			.xScale(this.offscreenXScale)
			.yScale(this.config.channelScale)
			.crossValue((p: [number, number]) => p[0])
			.mainValue((p: [number, number]) => p[1])
			.context(this.offscreenCanvas.getContext("2d", { willReadFrequently : true }))
			.decorate((context: CanvasRenderingContext2D) => {
				context.strokeStyle = this.config.color ?? "black"
			})

		this.render()
	}

	public renderPage = (page: EEGPage | undefined) => {
		if (!page || !this.context || !this.config.eegModality) {
			return
		}

		const { x, y, width, height } = this.getPageRectangle(page.startTime, page.endTime)
		this.context.clearRect(x, y, width, height)

		// Check if there is a cached render at the same resolution
		const cachedRender = page.renderCache.get(this.getRenderCacheKey())

		if (cachedRender && !cachedRender.dirty) {
			const bitmap = cachedRender.bitmap

			if (this.context.canvas.width === bitmap.width && this.context.canvas.height === bitmap.height) {
				this.context.drawImage(bitmap, x, y)
				return
			}
		}

		const montageData = page.data.get(this.config.dataObjectId)?.get(this.config.eegModality) as CompositeTimeSeriesData | undefined

		// If no data exists (page still loading), show gray.
		if (!page.loaded || !montageData) {
			this.context.fillStyle = "lightgray"
			this.context.fillRect(x, y, width, height)
			return
		}

		// If the data came back empty, we can't draw anything on the screen, but we want to show that the page loaded.
		if (montageData && (montageData.data.length === 0 || montageData.times.length === 0)) {
			return
		}

		this.config.channelScale.domain().forEach((channelPair, index) => {
			const traceData = zip(montageData.times, montageData.data[index])

			if (traceData && this.context) {
				this.offscreenXScale.domain([page.startTime, page.endTime]).range([0, width])

				const center = (this.config.channelScale(channelPair) as number) + this.config.channelScale.step() / 2
				const newRange = [this.config.sensitivityScaleVoltsToPixels.range()[0] + center, + this.config.sensitivityScaleVoltsToPixels.range()[1] + center]
				const newYScale = this.config.sensitivityScaleVoltsToPixels.copy().range(newRange)
				this.offscreenRenderer.yScale(newYScale)

				// Render to the offscreen canvas
				this.offscreenRenderer(traceData)
			}
		})

		const bitmap = this.offscreenCanvas.transferToImageBitmap()
		page.updateRenderCache(this.getRenderCacheKey(), { bitmap, dirty: false, edges: [] })
		this.context.drawImage(bitmap, x, y)
	}

	private getRenderCacheKey = () => `${this.config.graphId}-${this.config.eegModality}`

	private getPageRectangle = (startTime: Date | number, endTime: Date | number) => {
		const x1 = this.config.viewScale(startTime)
		const x2 = this.config.viewScale(endTime)
		const width = x2 - x1
		const height = this.config.channelScale.range()[1]

		this.pageRectangle.x = Math.round(x1)
		this.pageRectangle.width = width
		this.pageRectangle.height = height

		return this.pageRectangle
	}

	protected updateDerivedState = () => {
		this.offscreenCanvas.width = this.config.viewScale.range()[1]
		this.offscreenCanvas.height = this.config.channelScale.range()[1]
	}

	protected enter = (newTrace: Selection<EnterElement, any, any, any>): Selection<SVGForeignObjectElement, any, any, any> => {
		const foreignObject = newTrace.append("foreignObject").attr("class", this.className)
		const canvas = foreignObject.append("xhtml:canvas") as Selection<HTMLCanvasElement, any, any, any>

		const width = this.config.viewScale?.range()[1] - this.config.viewScale?.range()[0]
		const height = this.config.channelScale?.range()[1] - this.config.channelScale?.range()[0]

		foreignObject.attr("width", width).attr("height", height)
		canvas.attr("width", width).attr("height", height)

		const canvasNode = canvas.node()

		if (canvasNode != null) {
			this.context = canvasNode.getContext("2d") ?? undefined

			// Show a blank gray screen on the first render
			if (this.context) {
				this.context.fillStyle = "lightgray"
				this.context.fillRect(0, 0, width, height)
			}
		}

		this.pageManager.getPagesInView().forEach(page => this.renderPage(page))

		return foreignObject
	}

	protected update = (updatedForeignObject: Selection<any, any, any, any>): Selection<any, any, any, any> => {
		const updatedCanvas = updatedForeignObject.select("canvas")

		const width = this.config.viewScale?.range()[1] - this.config.viewScale?.range()[0]
		const height = this.config.channelScale?.range()[1] - this.config.channelScale?.range()[0]

		updatedForeignObject.attr("width", width).attr("height", height)
		updatedCanvas.attr("width", width).attr("height", height)

		this.pageManager.getPagesInView().forEach(page => this.renderPage(page))

		return updatedForeignObject
	}
}
