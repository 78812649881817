import ReactDOMServer from 'react-dom/server'
import * as MdIcons from "react-icons/md"

export const YAXIS_WIDTH = 60

export const XAXIS_HEIGHT = 40

export const DISPLAY_BORDER_WIDTH = 1
export const DISPLAY_PADDING = 5
export const SETTINGS_WIDTH = 60
export const LEGEND_WIDTH = 110
export const DISPLAY_BUTTONS_WIDTH = 40
export const TIMELINE_VIEWBOX_PADDING = 10

export const GRAPH_PADDING = 20

export const MIN_WINDOW_TIME = 2000
export const DEFAULT_PAGE_SIZE = 3600000

export const IconButtonStyle = `
	width: 30px;
	height: 30px;
	background: none;
	border: none;
	cursor: pointer;

	color: #000000;
`


export const UP_HTML = ReactDOMServer.renderToString(<MdIcons.MdOutlineKeyboardArrowUp size={18}/>)
export const DOWN_HTML = ReactDOMServer.renderToString(<MdIcons.MdOutlineKeyboardArrowDown size={18}/>)
export const CONFIGURE_HTML = ReactDOMServer.renderToString(<MdIcons.MdOutlineSettings size={18}/>)
export const CLOSE_HTML = ReactDOMServer.renderToString(<MdIcons.MdClose size={18}/>)
export const ADD_HTML = ReactDOMServer.renderToString(<MdIcons.MdAddCircleOutline size={30} />)
export const PLAY_HTML = ReactDOMServer.renderToString(<MdIcons.MdOutlinePlayCircle size={36}/>)
export const PAUSE_HTML = ReactDOMServer.renderToString(<MdIcons.MdPauseCircle size={36}/>)

