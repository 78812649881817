import React from 'react'

/**
 * Function used to make a default label
 * @param {String} id - A string for the label id (optional)
 * @param {Integer} size - An integer indicating the type of heading 1-6
 * @param {String} text - A string indicating the text content for the label
 * @param {Dictionary} style - A dictionary with CSS styles
 */
function Label({id="", size, text, style={margin: "0"}}) {
	
	// JSX Object containing the label
	if (size === 1) {
		return (<>
			<h1 id={id} style={style}>{text}</h1>
		</>)
	} else if (size === 2) {
		return (<>
			<h2 id={id} style={style}>{text}</h2>
		</>)
	} else if (size === 3) {
		return (<>
			<h3 id={id} style={style}>{text}</h3>
		</>)
	} else if (size === 4) {
		return (<>
			<h4 id={id} style={style}>{text}</h4>
		</>)
	} else if (size === 5) {
		return (<>
			<h5 id={id} style={style}>{text}</h5>
		</>)
	} else if (size === 6) {
		return (<>
			<h6 id={id} style={style}>{text}</h6>
		</>)
	}
}

export default Label;