import { useState, useEffect } from 'react';
import * as MdIcons from 'react-icons/md';
import { useNavigate, useLocation } from 'react-router-dom';
import "../../index.css";
import { useAuthProvider } from "../../Providers/AuthProvider"
import { useEndpointProvider } from '../../Providers/EndpointProvider';
import { FRONTEND_LINKS } from '../../Constants/FrontendLinks';
import AccountSettingsModal from './AccountSettingsModal';
import { useModalProvider } from '../../Providers/ModalProvider';
import { useUploadIntoQueueProvider } from '../../Providers/UploadIntoQueueProvider';
import { useUploadQueueProvider } from '../../Providers/UploadQueueProvider';
import { MobergButton, MobergButtonShape } from "../MobergButton/MobergButton";
import { MobergTheme } from '../MobergThemes/MobergColors';
import { MobergIconSize } from "../../Components/MobergIcon/MobergIcon";
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import { useBackendLinksProvider } from '../../Providers/BackendLinksProvider';

function PageHeader(props) {
    const authProvider = useAuthProvider()
    const endpointProvider = useEndpointProvider()
    const { createModal } = useModalProvider()
    const uploadIntoQueueProvider = useUploadIntoQueueProvider()
    const uploadQueueProvider = useUploadQueueProvider()
    const { LINKS } = useBackendLinksProvider()

    const location = useLocation()

    async function cancelUpload() {
        if (uploadIntoQueueProvider.uploadID) {
            let body = {
                upload_id: uploadIntoQueueProvider.uploadID,
            }
            return endpointProvider.post(LINKS.DATA.UPLOAD.CANCEL_UPLOAD, body)
        }
    }

    function logout() {
        uploadIntoQueueProvider.setOpenQueue(false)
        uploadIntoQueueProvider.undoExistingValidation(uploadIntoQueueProvider.folderID)
        uploadQueueProvider.setUploadInProgress(false)
        props.setSidebar(false)
        props.setNavPage(false)
        authProvider.logout()
        navigate(FRONTEND_LINKS.MAIN)
    }

    const navigate = useNavigate()
    const handleLogout = (e) => {
        // This means there is an ongoing upload. We need to confirm before we cancel the upload
        if (uploadQueueProvider.uploadInProgress) {
            createModal(<ConfirmationModal
                title={"Remove upload?"}
                description={`Are you sure you want to log out? Logging out will cancel your current upload and remove all uploads from queue.`}
                confirmButton={{ text: "Yes, log out", theme: MobergTheme.RED, onClick: cancelUpload }}
                cancelButton={{ text: "No, continue upload" }}
                afterClose={() => logout()}
            />)
        } else {
            logout()
        }

    }

    function renderAccountSettingsModal() {
        createModal(<AccountSettingsModal
            escClose={false}
            clickOutsideClose={false}
        />)
    }

    const [enablePageHeader, setEnablePageHeader] = useState(true)

    useEffect(() => {
        if (authProvider.userIsLoggedIn && location.pathname === '/inactive') {
            setEnablePageHeader(false)
        } else {
            setEnablePageHeader(true)
        }

        if (!authProvider.userIsLoggedIn) {
            setEnablePageHeader(false)
        }
    }, [authProvider.userIsLoggedIn])

    return (
        <div style={{ display: enablePageHeader ? "flex" : "none", justifyContent: "end", alignItems: "center", padding: "8px" }}>
            <p style={{
                marginBottom: "0px",
                fontFamily: "'Montserrat', sans-serif",
                fontStyle: "normal",
                fontSize: "16px",
                fontWeight: 700,
                lineHeight: "150%",
                color: "#6c757c",
                float: "left",
                padding: "8px"
            }}
            >
                {authProvider.currentUser?.email}
            </p>

            <MobergButton
                onClick={renderAccountSettingsModal} shape={MobergButtonShape.SQUARE} style={{ color: "#9fafbf" }}
            >
                <MdIcons.MdAccountCircle size={MobergIconSize.LARGE} />
            </MobergButton>

            <MobergButton
                onClick={handleLogout} shape={MobergButtonShape.SQUARE} style={{ color: "#9fafbf" }}
            >
                <MdIcons.MdLogout size={MobergIconSize.LARGE} />
            </MobergButton>
        </div>
    )
};

export default PageHeader;