import React, { useState, useEffect, useCallback } from "react"
import { useNavigate } from "react-router-dom"
import { DEFAULTS } from "../../../Constants/Formatting.js"
import { LoginInput } from "../../../Components/Input/Input"
import styled from "styled-components"
import "@fontsource/montserrat"
import { useAuthProvider } from "../../../Providers/AuthProvider.js"
import loadingSvg from "../../Whole/smartneuro.svg"
import { FRONTEND_LINKS } from '../../../Constants/FrontendLinks'
import bgImg from "./background.png"
import whiteCloud from "./moberg-whiteCloud.png"
import logoImg from "./moberg-logo.png"
import ErrorPopupModal from "../../../Components/ErrorPopupModal/ErrorPopupModal.js"
import { useModalProvider } from "../../../Providers/ModalProvider"
import { useEnvironmentVariablesProvider } from "../../../Providers/EnvironmentVariablesProvider"
import { BackendLinksProvider, useBackendLinksProvider } from '../../../Providers/BackendLinksProvider'
import { useEndpointProvider } from '../../../Providers/EndpointProvider'

function Login(props) {
	let navigate = useNavigate()

	const environmentVariablesProvider = useEnvironmentVariablesProvider()
	const authProvider = useAuthProvider()
	const { createModal } = useModalProvider()

	const [loading, setLoading] = useState(false)
	const [loadingText, setLoadingText] = useState("Loading Workspace...")

	const [email, setEmail] = useState()
	const [password, setPassword] = useState()
	const [showPassword, setShowPassword] = useState(false)

	const [isErrorModalOpen, setIsErrorModalOpen] = useState(false)

	const [, setLoginUpdate] = useState([])
    const { LINKS } = useBackendLinksProvider()
    const endpointProvider = useEndpointProvider()

	function updateLogin() {
		setLoginUpdate([])
	}
	useEffect(() => {
		props.setNavPage(false)
		window.addEventListener("resize", updateLogin)
		return () => {
			window.removeEventListener("resize", updateLogin)
		}
	})

	function getUrlParamJson(params){
		const paramJSON = {}
		let querySplit = params.split("&")
		for(let singleQuery of querySplit){
			let singleQuerySplit = singleQuery.split("=")
			paramJSON[singleQuerySplit[0]] = singleQuerySplit[1]
		}
		return paramJSON
	}

	const handleForgotPassword = (e) => {
		if (e) {
			e.preventDefault()
		}
		navigate(FRONTEND_LINKS.SUBPAGES.FORGOT_PASSWORD.MAIN)
	}

	const handleLogin = useCallback(async (e) => {
		if (e?.preventDefault) {
			e.preventDefault()
		}

		let _email = document.getElementById('emailInput').value
		let _password = document.getElementById('passwordInput').value

		if (_email === '' || _email === undefined || !_email.includes('@')) {
			alert('Enter an email.')
			return
		}

		if (_password === '') {
			alert('Enter a password.')
			return
		}
		setLoading(true)

		let isOAuth = false
		let paramJSON = getUrlParamJson(window.location.search.slice(1))
		if(paramJSON.hasOwnProperty("redirect_uri") 
			&& paramJSON.hasOwnProperty("state") 
			&& paramJSON.hasOwnProperty("client_id")){
				isOAuth = true; 
			}
		

		if(isOAuth){
			setLoadingText("Loading Jupyterhub...")
			let loginSuccess = true
			let response = {}
			try{
				response =  await endpointProvider.post(LINKS.LOGIN.OAUTH_LOGIN, {
					"email": _email,
					"password": _password
				})
			}
			catch(err){
				createModal(<ErrorPopupModal
					title={"There was a problem logging in"}
					errorMessage={err}
					onClose={()=>{setLoading(false); setIsErrorModalOpen(false)}}
				/>)
				return
			}
			if(!loginSuccess) return
			let code = response["code"]
			let redirectUri = decodeURIComponent(paramJSON["redirect_uri"]+`?state=${paramJSON['state']}&code=${code}`)
			window.location.replace(redirectUri)	
			return
		}
		else{
			setLoadingText("Loading Workspace...")
		}
		authProvider.login(_email, _password).then((data) => {
			setLoading(false)
			props.setNavPage(true)


			if (environmentVariablesProvider.environmentVariables.TERMS_AND_CONDITION) {
				navigate(FRONTEND_LINKS.SUBPAGES.TERMS_AND_CONDITIONS.MAIN)
			} else {
				navigate(FRONTEND_LINKS.SUBPAGES.DATA.SUBPAGES.VISUALIZE.MAIN)
			}

		}).catch((e) => {
			setIsErrorModalOpen(true)
			createModal(<ErrorPopupModal
				title={'There was a problem logging in'}
				errorMessage={`${e}`}
				onClose={() => { setLoading(false); setIsErrorModalOpen(false) }}
			/>)
		})
	})

	const enterLogin = useCallback((event) => {
		if (event.keyCode === 13) {
			handleLogin()
		}
	}, [handleLogin])

	useEffect(() => {
		if (!isErrorModalOpen) {
			document.addEventListener("keydown", enterLogin)
			return () => {
				document.removeEventListener("keydown", enterLogin)
			}
		}
	}, [enterLogin, isErrorModalOpen])


	if (loading) {
		return (
			<div style={{ color: 'black', display: "block", textAlign: "center", marginTop: `${window.innerHeight / 4}px`, marginLeft: "-250px" }}>
				<img src={loadingSvg} alt="loading" style={{ width: "300px" }} />
				<h2 style={{ fontFamily: "Montserrat", fontStyle: "normal", fontWeight: "700", fontSize: "25px", marginTop: "10px" }}>{loadingText}</h2>
			</div>
		)
	}
	return (
		<Container1>
			<Wrapper>
				<Container2 top={window.innerHeight} left={window.innerWidth}>
					<Form>
						<h1 style={{ marginLeft: "-245px" }}>EMAIL</h1>
						<LoginInput id="emailInput" type="email" placeholder="Email" value={email || ""} onChange={e => setEmail(e.target.value)} />

						<h1 style={{ marginLeft: "-205px" }}>PASSWORD</h1>
						<LoginInput id="passwordInput" type={showPassword ? "text" : ((String(password).length === 0) ? "text" : "password")} placeholder="Password" value={password || ""} onChange={e => setPassword(e.target.value)} onCopy={(e) => { if (!showPassword) { e.preventDefault() } }} />

						<button id="loginButton" type="button" onClick={(e) => { handleLogin(e) }}>LOG IN</button>

						<HBox style={{ justifyContent: "end", paddingRight: "15px", height: "35px" }}>
							<input id="showPasswordCheckbox" type="checkbox" style={{ margin: "1px 6px 0 6px" }} onChange={() => { setShowPassword(!showPassword) }} />
							Show password
						</HBox>

						<HBox>
							<div><ExternalLink onClick={(e) => { handleForgotPassword(e) }}>Forgot your password?</ExternalLink></div>
						</HBox>
					</Form>

					<WarningMessage><p><strong>CAUTION - Investigational device. </strong>Limited by Federal (or United States) law to investigational use.</p></WarningMessage>

					<HBox style={{ width: "545px" }}>
						<Claim top={window.innerHeight}>
							Copyright © {new Date().getFullYear()} Moberg Analytics, Inc. All Rights Reserved.
							&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
							<ExternalLink onClick={() => { window.open("https://moberganalytics.com/privacy/", "_blank") }}>Privacy</ExternalLink>
						</Claim>
					</HBox>

				</Container2>
				<Container3 offset={60} left={40}>
					<h2>Welcome to Moberg Analytics</h2>
					<img src={whiteCloud} alt=""></img>
					<h1>CONNECT</h1>
					<h3>The Only Data Pipeline Specifically for Neurocritical Care</h3>
					<button id="learnMoreButton" onClick={() => { window.open("https://moberganalytics.com/connect/", "_blank") }}>LEARN MORE</button>

				</Container3>
				<a href="https://moberganalytics.com/" rel="noreferrer" target="_blank"><Logo src={logoImg} alt="" top={window.innerHeight}></Logo></a>
			</Wrapper>
		</Container1>
	)
}

const Container1 = styled.div`
	background: #eefcff;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	min-height: 585px;
`;

const Wrapper = styled.div`
	background-image: url(${bgImg});
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	width: 100%;
	height: 100%;
	display: flex;
`;

const Logo = styled.img`
	padding: 1rem;
	position: absolute;
	left: 15px;
	bottom: 15px;

	@media (max-width: 770px) {
		display: none;
	}
	@media (max-height: 590px) {
		top: 495px;
	}
	&:hover {
		transform: translateY(-2px);
	}
`;

const Form = styled.form`
	padding: 21px;
	border-radius: 5px;
	border: 1px solid #C0C0C0;
	background: #FFFFFF;
	display: flex;
	width: 344px;
	height: 359px;
	top: 331px;
	flex-direction: column;
	align-items: center;
	overflow: hidden;
	h1 {
		font-family: "Montserrat";
		font-weight: 550;
		width: fit-content;
		font-size: 14px;
		color: #293241;
		padding: 0;
		margin: 0;
		text-align: left;
	}
	button {
		min-height: 40px;
		width: 304px;
		height: 40px;
		border: none;
		margin: -5px 0 15px;
		box-shadow: 0px 14px 9px -15px rgba(0, 0, 0, 0.25);
		border-radius: 6px;
		background-color: #ee6c4e;
		color: #fff;
		font-weight: 600;
		cursor: pointer;
		transition: all 0.2s ease-in;
		&:hover {
			transform: translateY(-3px);
		}
	}
`;

const Container2 = styled.div`
	position: relative;
	padding: ${DEFAULTS.CELL_PADDING};
	align-items: center;
	width: 545px;
	height: 565px;
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: start;

	@media (min-height: 730px) {
		top: ${({ top }) => `${top / 2 - 130 - 0.1 * top}px`};
	};
	@media (max-height: 730px) {
		top: ${({ top }) => `${top - 570}px`};
	};
	@media (max-height: 590px) {
		top: 20px;
	};
	
	@media (min-width: 1250px) {
		left: ${({ left }) => `${0.995 * left - 700}px`
	};
	}
	@media (max-width: 1250px) {
		left: ${({ left }) => `${-180 + 0.31 * left}px`
	};
	}
`;

const Container3 = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	@media (max-width: ${({ left }) => `${left + 1210}px`}) {
		display: none;
	}
	h2 {
		position: absolute;
		width: 510px;
		height: 102px;
		left: ${({ left }) => `${left + 0}px`};
		top: ${({ offset }) => `${offset + 8}px`};

		font-family: Montserrat;
		font-style: normal;
		font-weight: 700;
		font-size: 32px;
		line-height: 39px;

		color: #ffffff;

		@media (max-width: 900px) {
			display: none;
		}
	}
	h1 {
		position: absolute;
		width: 395px;
		height: 93px;
		left: ${({ left }) => `${left + 132}px`};
		top: ${({ offset }) => `${offset + 52}px`};
		font-family: Montserrat;
		font-style: normal;
		font-weight: bold;
		font-size: 76px;
		line-height: 93px;
		color: #97daff;
		text-shadow: 0px 2px 9px rgba(0, 0, 0, 0.25);
		@media (max-width: 900px) {
			display: none;
		}
	}
	h3 {
		position: absolute;
		width: 600px;
		height: 102px;
		left: ${({ left }) => `${left + 0}px`};
		top: ${({ offset }) => `${offset + 150}px`};

		font-family: Montserrat;
		font-style: normal;
		font-weight: 700;
		font-size: 32px;
		line-height: 39px;

		color: #ffffff;

		@media (max-width: 900px) {
			display: none;
		}
	}
	img {
		padding: 1rem;
		position: absolute;
		left: ${({ left }) => `${left - 10}px`};
		top: ${({ offset }) => `${offset + 47}px`};
		@media (max-width: 900px) {
			display: none;
		}
	}
	button {
		position: absolute;
		left: ${({ left }) => `${left + 0}px`};
		top: ${({ offset }) => `${offset + 230}px`};
		width: 147px;
		height: 34px;
		border: none;
		margin: 1rem 0;
		box-shadow: 0px 14px 9px -15px rgba(0, 0, 0, 0.25);
		border-radius: 8px;
		background-color: #ee6c4e;
		color: #fff;
		font-weight: 100;
		cursor: pointer;
		transition: all 0.2s ease-in;
		&:hover {
			transform: translateY(-3px);
		}
	}
`;

const WarningMessage = styled.div`
	font-family: "Source Sans Pro";
	background: #F0D9DE;
	height: 55px;
	padding: 8px 18px 8px 18px;
	border-radius: 6px;
	margin: 30px;
	width: 220px;
	text-align: center;
	p {
		font-size: 9px;
		color: #E54E58;
	}
`;
const HBox = styled.div`
	width: 325px;
	padding-bottom: 25px;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
		height: 20px;
	font-family: "Source Sans Pro";
	font-size: 14px;
	color: #5F6775;
	div {
		width: fit-content;
		height: fit-content;
	}
	button {
		color: #0097EE;
		margin-top: -9px;
		margin-right: 13px;
		width: 100px;
		height: 40px;
		border-radius: 8px;
		border: 1px solid #C0C0C0;
		background: #FFFFFF;
		}
`;

const ExternalLink = styled.a`
	font-family: "Source Sans Pro";
	color: #0097EE;
	text-decoration: none;
`;

const Claim = styled.div`
	font-family: "Source Sans Pro";
	font-size: 16px;
	color: #000000;
	width: 545px;
	@media (min-height: 730px) {
		position: fixed;
		bottom: 15px;
		};
	@media (max-height: 730px) {
		position: relative;
		top: -10px;
		};
 `;

export default Login;

