import ModalHeader from "../../../../Components/ModalHeader/ModalHeader"
import ModalScrollbar from "../../../../Components/ModalScrollbar/ModalScrollbar"
import GridContainer from "../../../../Components/TextField/GridContainer"
import BasicTextField from "../../../../Components/TextField/BasicTextField"
import PermissionGroup from "../../../../Components/PermissionGroup/PermissionGroup"
import { ModalFooter } from "../../../../Constants/StyledComponents"
import { MobergButton, MobergButtonShape, MobergButtonVariant, MobergTheme } from "../../../../Moberg"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useEndpointProvider } from "../../../../Providers/EndpointProvider"
import { useTableContext } from "../../../../Providers/TableProvider"
import { useModalProvider } from "../../../../Providers/ModalProvider"
import { useQuery } from "@tanstack/react-query"
import { Input, InputAdornment } from "@mui/material"
import SearchIcon from "@mui/icons-material/Search"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { useEffect, useState } from "react"
import { useBackendLinksProvider } from "../../../../Providers/BackendLinksProvider"

export default function ModifyRoleModal({ row, viewMode }) {
	const endpointProvider = useEndpointProvider()
	const { setToastProps } = useTableContext()
	const { close } = useModalProvider()
	const queryClient = useQueryClient()
	const { LINKS } = useBackendLinksProvider()

	const [errorMessage, setErrorMessage] = useState()

	const schema = yup
		.object({
			name: yup.string().required("Name is required."),
			description: yup.string().required("Description is required."),
		})
		.required()

	const { control, handleSubmit, formState: { errors } } = useForm({
		resolver: yupResolver(schema),
		defaultValues: { name: row.name, description: row.description },
	})

	const { data, isLoading } = useQuery({
		queryKey: ['permissions'],
		queryFn: () => endpointProvider.post(LINKS.ADMIN.USER_MANAGEMENT.ROLES_AND_PERMISSIONS.GET_PERMISSIONS, { role_id: row.id }),
	})

	const modifyRole = async body => endpointProvider.post(LINKS.ADMIN.USER_MANAGEMENT.ROLES_AND_PERMISSIONS.MODIFY_ROLE, body)
	const modifyRoleMutation = useMutation({
		mutationFn: body => modifyRole(body),
		onError: error => {
			setToastProps({ isOpen: true, severity: "error", message: error.message })
		},
		onSuccess: () => {
			setToastProps({ isOpen: true, severity: "success", message: "Role modified successfully." })
		},
		onSettled: () => {
			queryClient.invalidateQueries("roles")
		},
	})

	const [groups, setGroups] = useState(data ? data : [])
	const onSubmit = async data => {
		const selectedRowIds = groups.map(group => group.permissions.filter(permission => permission.enabled).map(permission => permission.id)).flat()

		if (selectedRowIds.length === 0) {
			setErrorMessage("Please select at least one permission.")
			return
		}

		modifyRoleMutation.mutate({
			role_id: row.id,
			name: data.name,
			description: data.description,
			permission_ids: selectedRowIds,
		})
		close()
	}

	useEffect(() => {
		setGroups(data ? data : [])
	}, [data])

	const handleToggle = (groupName, permissionName) => {
		const updatedGroups = groups.map(group => {
			if (group.group === groupName) {
				return {
					...group,
					permissions: group.permissions.map(permission => {
						if (permission.name === permissionName) return { ...permission, enabled: !permission.enabled }
						return permission
					})
				}
			}
			return group
		})

		setGroups(updatedGroups)
	}

	const handleSelectAllToggle = (groupName, selectAll) => {
		const updatedGroups = groups.map(group => {
			if (group.group === groupName) {
				return {
					...group,
					permissions: group.permissions.map(permission => ({
						...permission,
						enabled: selectAll
					}))
				}
			}
			return group
		})

		setGroups(updatedGroups)
	}

	const [isSearch, setIsSearch] = useState(false)

	const handleSearch = (event) => {
		const searchQuery = event.target.value.trim().toLowerCase()
		if (!searchQuery) {
			setIsSearch(false)
			setGroups(data || [])
			return
		} else {
			setIsSearch(true)
		}

		const updatedGroups = data.map(group => {
			const updatedPermissions = group.permissions.filter(permission => {
				return (
					permission.name.toLowerCase().includes(searchQuery) ||
					permission.description.toLowerCase().includes(searchQuery) ||
					permission.group.toLowerCase().includes(searchQuery)
				)
			})

			return updatedPermissions.length > 0 ? { ...group, permissions: updatedPermissions } : null
		}).filter(group => group !== null)

		setGroups(updatedGroups)
	}

	useEffect(() => {
		if (Object.keys(errors).length > 0) {
			const error = errors[Object.keys(errors)[0]]
			if (Array.isArray(error)) {
				error.forEach(e => {
					if (e) {
						setErrorMessage(e?.message)
					}
				})
			} else {
				setErrorMessage(error?.message)
			}
		}
	}, [errors])

	return (
		<>
			<ModalHeader headerText={viewMode ? 'View Role' : 'Modify Role'} />
			<ModalScrollbar>
				<div style={{ maxWidth: "800px" }}>
					<h2>Role Details</h2>
					<GridContainer>
						<BasicTextField
							inputId="create-role-name-id"
							label="Name"
							tooltip="The name of the role."
							placeholder="Admin"
							autoFocus={true}
							control={control}
							name="name"
							numberColumn={6}
							disabled={viewMode}
						/>
						<BasicTextField
							inputId="create-role-description-id"
							label="Description"
							tooltip="The description of the role."
							placeholder="Enter description"
							control={control}
							name="description"
							numberColumn={6}
							disabled={viewMode}
						/>
					</GridContainer>

					<h2>Permissions</h2>
					<div style={{ marginBottom: "8px" }}>
						<Input
							id='MUI-Search-Bar'
							startAdornment={
								<InputAdornment position="start">
									<SearchIcon />
								</InputAdornment>
							}
							placeholder="Search"
							onChange={handleSearch}
							disableUnderline={true}
						/>
					</div>
					{isLoading ? <div>Loading...</div> :
						groups.map(group => (
							<PermissionGroup
								key={group.group}
								group={group}
								onToggle={(groupName, permissionName) => handleToggle(groupName, permissionName)}
								onSelectAllToggle={handleSelectAllToggle}
								isSearch={isSearch}
								disabled={viewMode}
							/>
						))}
				</div>
			</ModalScrollbar>

			{!viewMode && (
				<ModalFooter style={{ justifyContent: "space-between" }}>
					<h1 style={{ color: "#E54E58" }}>{errorMessage ? errorMessage : null}</h1>
					<MobergButton theme={MobergTheme.BLUE} variant={MobergButtonVariant.FILLED} shape={MobergButtonShape.WIDE} onClick={handleSubmit(onSubmit)}>
						Submit
					</MobergButton>
				</ModalFooter>
			)}
		</>
	)
}