import React, { useState } from "react"
import { Popper } from "@material-ui/core"
import AddIcon from "@mui/icons-material/Add"
import FilterForm from "./FilterForm"
import FilterAltIcon from "@mui/icons-material/FilterAlt"
import { useTableContext } from "../../Providers/TableProvider"
import { MobergBoxShadow } from "../MobergThemes/MobergStyles"
import { MobergTheme } from "../MobergThemes/MobergColors"
import { MobergButton } from "../MobergButton/MobergButton"
import styled from "styled-components"
import { MobergFontFamily, MobergFontSize } from "../MobergFont/MobergFont"

const FilterPanel = (props) => {
	const { setQueryOptions } = useTableContext()

	const { open, onClose, anchorEl, setRef } = props

	const [localQueryOptions, setLocalQueryOptions] = useState({
		items: [{ id: "0", field: "id", operator: "contains", value: "" }],
		search_value: "",
	})

	const handleAddForm = () => {
		setLocalQueryOptions(previous => ({
			...previous,
			items: [...previous.items, { field: "id", operator: "contains", value: "", id: `${Math.random()}-${Math.random()}` }]
		}))
	}

	const handleApplyFilters = () => {
		setQueryOptions(localQueryOptions)
		onClose()
	}

	return (
		<Popper key={"DataGridFilterPanel"} placement="bottom-start" open={open} anchorEl={anchorEl} style={{ zIndex: 10000 }}>
			<PopperContent ref={setRef}>
				<h4 style={{ margin: 0, fontFamily: MobergFontFamily.HEADER, fontSize: MobergFontSize.X_LARGE }}>Filters</h4>

				<div>
					{localQueryOptions.items.map((item, index) => (
						<FilterForm key={item.id} formIndex={index} localQueryOptions={localQueryOptions} setLocalQueryOptions={setLocalQueryOptions} onClose={onClose} />
					))}
				</div>


				<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
					<div>
						<MobergButton theme={MobergTheme.BLUE} onClick={handleAddForm}>
							<AddIcon />
							<span>Add filter</span>
						</MobergButton>
					</div>

					<div>
						<MobergButton theme={MobergTheme.BLUE} onClick={handleApplyFilters}>
							<FilterAltIcon />
							<span>Apply filters</span>
						</MobergButton>
					</div>
				</div>
			</PopperContent>
		</Popper>
	)
}

const PopperContent = styled.div`
	background: white;
	box-shadow: ${MobergBoxShadow.REGULAR};
	border-radius: 6px;
	padding: 16px;
	opacity: 0;
	transform-origin: top left;
	transform: scale(0.95);
	display: flex;
	flex-direction: column;
	gap: 16px;
`

export default FilterPanel
