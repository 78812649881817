import { Tooltip, Box, FormControl, FormHelperText, InputLabel, Typography } from "@mui/material"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import { Grid } from "@mui/material"

export default function InputWrapper(props) {
	const { inputId, label, tooltip = null, children, error, numberColumn = 12, required = true } = props
	return (
		<Grid item xs={numberColumn}>
			<FormControl variant="standard" fullWidth>
				<InputLabel shrink htmlFor={inputId}>
					<Box display="flex" alignItems="center" gap={0.5}>
						{tooltip && (
							<Tooltip title={tooltip} arrow placement="top" PopperProps={{ style: { zIndex: 10000 } }}>
								<InfoOutlinedIcon color="info" fontSize="medium" />
							</Tooltip>
						)}
						<Typography variant="body1" fontWeight="bold" sx={{ fontSize: "20px" }}>
							{label} {required ? <span style={{ color: "red", fontStyle: 'italic', fontSize: '21px' }}>*</span> : null}
						</Typography>
					</Box>
				</InputLabel>
				{children}
			</FormControl>
		</Grid>
	)
}
