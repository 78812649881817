import { select } from "d3"
import { TIMELINE_VIEWBOX_PADDING } from "../Constants"

function formatDate(date) {
    return date.toLocaleDateString("en-us", {
        month: 'short',
        day: '2-digit',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        timeZoneName: 'short'
    })
}

export function hideTimelineTooltip(display) {
    select(`#timeline-tooltip-d${display.id}`).style("opacity", 0)
}

export function showTimelineTooltip(display) {
    select(`#timeline-tooltip-d${display.id}`).style("opacity", 1)
}

export function updateTimelineTooltipPosition(display, x) {
    const tooltip = select(`#timeline-tooltip-d${display.id}`)
    const tooltipWidth = tooltip.node()?.getBoundingClientRect().width

    const date = display.timelineScale.invert(x)
    const maxX = display.timelineScale.range()[1] + TIMELINE_VIEWBOX_PADDING - tooltipWidth / 2
    const minX = TIMELINE_VIEWBOX_PADDING

    tooltip
        .style("left", `${Math.max(minX, Math.min(x, maxX) - tooltipWidth / 2)}px`)
        .html(formatDate(date))
}

export function createTimelineTooltip(selection) {
    const height = 16

    selection
        .append("div")
        .attr("id", display => `timeline-tooltip-d${display.id}`)
        .style("pointer-events", "none")
        .style("opacity", 0)
        .style("word-wrap", "none")
        .style("top", `${-height}px`)
        .style("transition", "opacity 0.2s ease-in-out")
        .style("left", display => `${display.timelineScale((display.start_time + display.end_time) / 2 )}px`)
        .style("position", "absolute")
        .style("z-index", "100")
        .style("background", "white")
}