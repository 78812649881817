import { MutableRefObject, useContext, useEffect, useMemo, useRef, useState } from "react"
import { TimeBasedVisualizationConfig, TimeBasedVisualizationConfigJSON } from "../Types/TimeBasedVisualizationConfig"
import { currentPatientFileInfoAtom } from "../Atoms/PatientFile"
import { RecoilState, useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import { useModalProvider } from "../../../../../Providers/ModalProvider"
import { AnnotationReactCallbacks } from "../Types/ReactCallbacks"
import { selectedAnnotationAtom } from "../Atoms/Annotations"
import { linkedWindowsTimelineControllerAtom } from "../Atoms/Timeline"
import { D3TimeBasedVisualization } from "../Components/Visualizations/D3TimeBasedVisualization"
import { TimeSeriesPageManager } from "../Data/TimeSeriesPageManager"
import { DimensionsContext } from "../../../../../Providers/DimensionsProvider"
import { useOnMount } from "../../../../../Hooks/useOnMount"
import { ScaleTime } from "d3"
import { isEqual } from "lodash"
import { useD3SyncTime } from "./useD3SyncTime"

// Find the "missing" or "differently typed" properties from B when compared to A
type Difference<A, B> = Pick<B, { [K in keyof B]: K extends keyof A ? (A[K] extends B[K] ? never : K) : K }[keyof B]>

type RequiredVisualizationProps = {
	id: string
	viewScale: ScaleTime<any, any, any>
	fileScale: ScaleTime<any, any, any>
}

type DefaultVisualizationProps = {
	playbackSpeed: number
	liveModeEnabled: boolean
	isLinked: boolean
	hasInitialized: boolean
}

type SharedVisualizationProps = Omit<TimeBasedVisualizationConfig, keyof (RequiredVisualizationProps & DefaultVisualizationProps)>

type useD3ConfigProps<
	ControllerType extends D3TimeBasedVisualization<ConfigType, AnnotationReactCallbacks<ConfigType>, any, any>,
	ConfigType extends TimeBasedVisualizationConfig,
	JSONType extends TimeBasedVisualizationConfigJSON,
	PageManagerType extends TimeSeriesPageManager<any, any>
> = {
	atom: RecoilState<ConfigType>
	d3ControllerConstructor: (node: HTMLDivElement, config: ConfigType, pageManager: PageManagerType, callbacks: AnnotationReactCallbacks<ConfigType>) => ControllerType
	nodeRef: MutableRefObject<HTMLDivElement | null>
	props: Omit<Difference<JSONType, ConfigType>, keyof (SharedVisualizationProps & DefaultVisualizationProps)> // All properties in the config that are not set by JSON
	initProps: JSONType
	pageManager: PageManagerType
}

export const useD3Controller = <
	ControllerType extends D3TimeBasedVisualization<ConfigType, any, any, any>,
	ConfigType extends TimeBasedVisualizationConfig,
	JSONType extends TimeBasedVisualizationConfigJSON,
	PageManagerType extends TimeSeriesPageManager<any, any>
>({
	atom,
	nodeRef,
	props,
	initProps,
	pageManager,
	d3ControllerConstructor,
}: useD3ConfigProps<ControllerType, ConfigType, JSONType, PageManagerType>) => {
	const { dataSourceMap } = useRecoilValue(currentPatientFileInfoAtom)
	const [rootConfig, setRootConfig] = useRecoilState(atom)
	const { createModal } = useModalProvider()
	const setSelectedAnnotation = useSetRecoilState(selectedAnnotationAtom)
	const [timelineController, setLinkedWindowsTimelineController] = useRecoilState(linkedWindowsTimelineControllerAtom)
	const [d3Controller, setD3Controller] = useState<ControllerType | undefined>()
	const dimensions = useContext(DimensionsContext)
	const { patientId, timeZone, isAdmitted } = useRecoilValue(currentPatientFileInfoAtom)
	const previousD3Config = useRef<ConfigType>()

	// Functions and context that all D3 components may need access to.
	const reactCallbacks: AnnotationReactCallbacks<ConfigType> = useMemo(
		() => ({
			createModal,
			setRootConfig,
			setSelectedAnnotation,
			setLinkedWindowsTimelineController,
			dataSourceMap,
		}),
		[createModal, dataSourceMap, setLinkedWindowsTimelineController, setRootConfig, setSelectedAnnotation]
	)

	// Creates the D3 configuration needed to render the D3 component.
	const d3Config = useMemo(() => {
		const base = { ...rootConfig }

		if (!rootConfig.hasInitialized) {
			Object.assign(base, initProps)
			base.hasInitialized = true
		}

		// The shared visualization props are not known in the database, but are shared across all visualizations.
		const shared: SharedVisualizationProps = {
			dimensions,
			timelineController,
			patientId,
			timeZone,
			patientIsAdmitted: isAdmitted,
		}

		return { ...base, ...shared, ...props }
	}, [dimensions, initProps, isAdmitted, patientId, props, rootConfig, timeZone, timelineController])

	// This hook gets called the first time the React component renders on the screen.
	// It returns a function which will get called when the React component leaves the screen.
	useOnMount(() => {
		if (!nodeRef.current) {
			return
		}

		// If the patient is admitted, enable live mode and initialize the page manager with the correct state
		d3Config.liveModeEnabled = isAdmitted
		d3Config.isLinked = !isAdmitted

		const controller = d3ControllerConstructor(nodeRef.current, d3Config, pageManager, reactCallbacks)

		setD3Controller(controller)
		setRootConfig(d3Config)

		return () => {
			controller.unmount()
		}
	})

	// When the config changes, update the D3 component tree and re-render.
	useEffect(() => {
		if (d3Controller && dimensions.height > 0 && !isEqual(previousD3Config.current, d3Config)) {
			d3Controller.updateConfig(d3Config)
			setRootConfig(d3Config)
			previousD3Config.current = d3Config
		}
	}, [d3Config, d3Controller, dimensions.height, setRootConfig])

	useD3SyncTime({ d3Controller })

	return d3Controller
}
