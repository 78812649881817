import { Popper } from '@material-ui/core'
import { MobergBoxShadow } from '../MobergThemes/MobergStyles'
import styled from 'styled-components'
import { MobergTheme } from '../MobergThemes/MobergColors'
import { MobergButton, MobergButtonVariant } from "../MobergButton/MobergButton"
import { useModalProvider } from '../../Providers/ModalProvider'
import LoginAttemptsModal from '../../Pages/Admin/Projects/Subcomponents/LoginAttemptsModal'
import UserActionsModal from '../../Pages/Admin/Projects/Subcomponents/UserActionsModal'

function CustomViewMoreMenu({ setPopoverRef, isOpen, anchorEl, close }) {
    const {createModal} = useModalProvider()

    function renderLoginAttempts() {
        close()
        createModal(<LoginAttemptsModal 
			style={{height: 'fit-content'}}
			escClose={false}
			clickOutsideClose={false}
		/>)
    }

    function renderUserActions() {
        close()
        createModal(<UserActionsModal
            style={{height: 'fit-content'}}
            escClose={false}
            clickOutsideClose={false}
        />)
    }

    return (
        <Popper key={"DataGridViewMorePanel"} placement="bottom-start" open={isOpen} anchorEl={anchorEl} style={{ zIndex: 10000 }}>
            <ViewMoreMenuContainer ref={setPopoverRef}>
                <MobergButton variant={MobergButtonVariant.CLEAR} theme={MobergTheme.BLUE} onClick={renderLoginAttempts}>
                    View login attempts
                </MobergButton>
                <MobergButton variant={MobergButtonVariant.CLEAR} theme={MobergTheme.BLUE} onClick={renderUserActions}>
                    View user actions
                </MobergButton>
            </ViewMoreMenuContainer>
        </Popper>
    )
}

export default CustomViewMoreMenu

const ViewMoreMenuContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    min-width: 150px;
    padding: 8px;
    background: white;
    border-radius: 6px;
    opacity: 0;
    transform: scale(0.95);
    transform-origin: top left;
    box-shadow: ${MobergBoxShadow.REGULAR};
    gap: 16px;
`