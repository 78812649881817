/* eslint-disable no-unused-vars */
import { useState, useEffect, useCallback, useRef } from 'react';
import { DEFAULTS } from '../../../Constants/Formatting';
import CreateReports from './Subpages/CreateReports';
import _ from "lodash";
import { LINKS } from '../../../Constants/BackendLinks'
import { FRONTEND_LINKS } from '../../../Constants/FrontendLinks';
import styled from 'styled-components';
import * as MdIcons from "react-icons/md";
import * as FaIcons from "react-icons/fa";
import FilterButton from "../../../Components/FilterButton/FilterButton";
import { MainTable } from '../../../Components/DataTable/Table';
import { PDFDownloadLink } from "@react-pdf/renderer";
import {columns, rows} from "./data";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Link } from "react-router-dom"
import RunReportsModal from './SubComponents/RunReportModal';
import { useStudiesProvider } from '../../../Providers/StudiesProvider';
import { useAuthProvider } from '../../../Providers/AuthProvider';
import TrashButton from '../../../Components/TrashButton/TrashButton';

// MulitmodalReports: component that renders the MultimodalReports page
// Inputs:: 
// 		- params (dict):
// Output:
//		- None
const RunReports = (props) => {
	const studiesContext = useStudiesProvider()
	const authProvider = useAuthProvider()
	
	const [count,setCount]=useState(1)
	const[reportID,setReportID]=useState("")
	const[creatorID,setCreatorID]=useState("")
	const navigate = useNavigate()
	const { id } = useParams()
	const query = new URLSearchParams(useLocation().search)

	// page update hook
	const [multimodalReportsUpdate, setMultimodalReportsUpdate] = useState([])
	function updateMultimodalReports() {
		setMultimodalReportsUpdate([])
	}

    const [fileModalitiesCheckboxValues, setFileModalitiesCheckboxValues] = useState([])
    
    const [selectedPatient, setSelectedPatient] = useState()
    const [selectedPatientFile, setSelectedPatientFile] = useState()
    
    const [patientFileStartEndTimestamps, setPatientFileStartEndTimestamps] = useState([])

	const [currentModalities, setCurrentModalities] = useState([])
	// only perform these actions once
	/*useEffect(() => { 
		window.addEventListener("resize", updateMultimodalReports)
		MultimodalReportsManager.updateMultimodalReports()
		return () => {
			window.removeEventListener("resize", updateMultimodalReports)
		}
	}, [])*/


    const [searchQuery, setSearchQuery] = useState("");
    let filterModalRef = useRef();
	let filterButtonRef = useRef();
    let runReportModalRef = useRef();

	function renderFilterModal() {
		if (document.getElementById("multimodalFilterModal").style.display !== "none") {
			document.getElementById("multimodalFilterModal").style.display = "none";
			document.getElementById("multimodalFilterGrayOverlay").style.display = "none";
		} else {
			document.getElementById("multimodalFilterModal").style.display = "block";
			document.getElementById("multimodalFilterGrayOverlay").style.display = "block";
		}
	}
    const escFilterDropdownClose = useCallback((event) => {
		if (event.keyCode === 27) {
			document.getElementById("multimodalFilterModal").style.display = "none";
			document.getElementById("multimodalFilterGrayOverlay").style.display = "none";
		}
	}, []);
    useEffect(() => {
		document.addEventListener("keydown", escFilterDropdownClose);
		return () => {
			document.removeEventListener("keydown", escFilterDropdownClose);
		};
	}, [escFilterDropdownClose]);

    useEffect(() => {
		let handler = (event) => {
			if (
				!filterModalRef.current.contains(event.target) &&
				!filterButtonRef.current.contains(event.target) 
			) {
				document.getElementById("multimodalFilterModal").style.display = "none";
				document.getElementById("multimodalFilterGrayOverlay").style.display = "none";
				document.body.style.overflow="auto";
			}
		};
		//checkModalPop()
		document.addEventListener("mousedown", handler);
		return () => {
			document.removeEventListener("mousedown", handler);
		};
	}, []);

    const enterDefault = useCallback((event) => {
		if (event.keyCode === 13) {
			event.preventDefault();
		}
	}, []);

	useEffect(() => {
		document.addEventListener("keydown", enterDefault);
		return () => {
			document.removeEventListener("keydown", enterDefault);
		};
	}, [enterDefault]);    

    
	// renderSubpage: function that renders the subpages in QualityReports
	// Inputs:
	// 		- selected_tab (str): a string value indicating the selected tab
	// Output:
	//		- Subpage component
	// function renderSubpage(selected_tab) {
	// 	if (selected_tab === "Create Quality Reports") {
	// 		return (<CreateReports/>)
	// 	} else if (selected_tab === "Patients") {
	// 		return (<GenerateReports/>)
	// 	} else if (selected_tab === "Track Quality Reports") {
	// 		return (<TrackReports/>)
	// 	}
	// }


	// // bolding the selected tab
	// if (document.getElementById(MultimodalReportsManager.selected_tab)) {
	// 	document.getElementById("Create Quality Reports").style.textShadow = "none"
	// 	document.getElementById("Patients").style.textShadow = "none"
	// 	document.getElementById("Track Quality Reports").style.textShadow = "none"

	// 	document.getElementById(MultimodalReportsManager.selected_tab).style.textShadow = "1px 0px 0px black"
	// }

	function renderEditMultimodalModal() {
		if (document.getElementById("createMultimodalReportModal").style.display !== "none") {
			document.getElementById("createMultimodalReportModal").style.display = "none";
		} else {
			document.getElementById("createMultimodalReportModal").style.display = "block";
		}
	}

	function renderCreateMultimodalModal() {
		if (document.getElementById("createMultimodalReportModal").style.display !== "none") {
			document.getElementById("createMultimodalReportModal").style.display = "none";
		} else {
			document.getElementById("createMultimodalReportModal").style.display = "block";
		}
	}

	function renderRunReportModal() {
		if (document.getElementById("runReportButtonModal").style.display !== "none") {
			document.getElementById("runReportButtonModal").style.display = "none";
		} else {
			document.getElementById("runReportButtonModal").style.display = "table";
			document.getElementById("runReportButtonModal").style.zIndex = "999";
			document.getElementById("multimodalFilterGrayOverlay").style.display = "block";
			document.getElementById("sideBarNav").style.overflow = "none";
			document.body.style.overflow="hidden";

		}
	}

    function cancelRunReportModal() {
		if (document.getElementById("runReportButtonModal").style.display !== "none") {
			document.getElementById("runReportButtonModal").style.display = "none";
			document.getElementById("multimodalFilterGrayOverlay").style.display = "none";
			document.getElementById("sideBarNav").style.overflow = "auto";
			document.body.style.overflow="auto";
		}
	}


	// const [headerTabs, setHeaderTabs] = useState({})
	// const [headerTabsNames, setHeaderTabsNames] = useState([]) //array for indexing
	// const [headerIconButtons, setHeaderIconButtons] = useState([]) //all icons for tabs stored here
	// const [displayHeaderIconButtons, setDisplayHeaderIconButtons] = useState([]) //icons for currently clicked tab
	const [tableSubheaderValues, setTableSubheaderValues] = useState([]);
    const [tableSubheaderOrder, setTableSubheaderOrder] = useState([]);
	const [newSubheaderVal, setNewSubheaderVal] = useState(0);
	let newSubheaderRef = useRef();

	const [reportSubheaderValues, setReportSubheaderValues] = useState([])
	const [reportSubheaderOrder, setReportSubheaderOrder] = useState([])
	const [newReportSubheaderVal, setNewReportSubheaderVal] = useState(0)
	let newReportSubheaderRef = useRef()
	const [SubheaderValues, setSubheaderValues] = useState([]);
	const [SubheaderOrder, setSubheaderOrder] = useState([]);
	

	useEffect(() => {
		let categoryHeaders = ["General", "Analytics", "Graphs", "Integrations"]
		setTableSubheaderOrder(categoryHeaders)

		let categoryHeadersValues = Object.assign({},
			...categoryHeaders.map((header) => {
				return { [header]: false}
			}))
		setTableSubheaderValues({...categoryHeadersValues, ["General"]: true})

		let reportHeaders = ["Header 1", "Header 2"]
		setReportSubheaderOrder(reportHeaders)
		let reportHeadersValues = Object.assign({},
			...reportHeaders.map((header) => {
				return { [header]: false}
			}))
		setReportSubheaderValues({reportHeadersValues, ["Header 1"]: true})
	}, [])

	function createTableConfig () {
        const data_variables_list = ["Site", "Modalities", "Days"];
        const time_options_list = ["last week", "last month"];
        const modality_return_list = ['ICP', 'PbtO2', 'MAP', 'Temp']

        let data_variable = Object.assign(
            {},
            ...data_variables_list.map((data_variable) => {
                return { [data_variable]: false };
            })
        );
        data_variable.Site = true

        let time_option = Object.assign(
            {},
            ...time_options_list.map((time_option, i) => {
                if (i === 0) {
                    return { [time_option]: true };
                } else {
                    return { [time_option]: false };
                }
            })
        );

        return {
            data_variable: data_variable,
            time_option: time_option,
        }
    }
	const [tableConfig, setTableConfig] = useState([]);


	function closeEditMultimodalReportModal() {
		if (document.getElementById("createMultimodalReportModal").style.display !== "none") {
			(document.getElementById("createMultimodalReportModal").style.display = "none");
			// (document.getElementById("vizGrayOverlay").style.display = "none");
		}
	}

	function updateTableSubheaderValues(table, value) {
        if (tableSubheaderValues[table]) {
            return;
        }
        let newTableSubheaderValues = Object.assign(
            {},
            ...Object.keys(tableSubheaderValues).map((table) => {
                return { [table]: false };
            })
        );
        setTableSubheaderValues({ ...newTableSubheaderValues, [table]: value });

        if (!tableSubheaderOrder.includes(table)) {
            setTableSubheaderOrder(
                Array.prototype.concat(tableSubheaderOrder, table)
            );

            setTableConfig({ ...tableConfig, [table]: createTableConfig() });
        }
    }

	function updateReportSubheaderValues(table, value) {
		if (reportSubheaderValues[table]) {
			return;
		}
		let newReportSubheaderValues = Object.assign({},
			...Object.keys(reportSubheaderValues).map((table) => {
				return { [table] : false}
			}))
		setReportSubheaderValues({...newReportSubheaderValues, [table]: value})

		if (!reportSubheaderOrder.includes(table)) {
			setReportSubheaderOrder(
				Array.prototype.concat(reportSubheaderOrder, table)
			)
		}
	}

	function deleteTableSubheaderValues(table) {
        if (tableSubheaderOrder.length === 1) {
            alert("Please create a new category before deleting this one.");
            return;
        }
        let indx = tableSubheaderOrder.indexOf(table);

        document.getElementById(`NewSubheader_${table}`).style.display = "none";
        document.getElementById(`TableButton_${table}`).style.display = "flex";
        document.getElementById(`NewSubheader_${table}`).value = "";

        setTableSubheaderOrder(
            Array.prototype.concat(
                tableSubheaderOrder.slice(0, indx),
                tableSubheaderOrder.slice(indx + 1)
            )
        );

        setTableSubheaderValues(
            Object.assign(
                {},
                ...Object.keys(tableSubheaderValues).map((table_, i) => {
                    if (table_ !== table) {
                        return {
                            [table_]:
                                (i === 0 &&
                                    indx !== 0 &&
                                    tableSubheaderValues[
                                    tableSubheaderOrder[indx]
                                    ]) ||
                                (i === 1 &&
                                    indx === 0 &&
                                    tableSubheaderValues[
                                    tableSubheaderOrder[indx]
                                    ]) ||
                                tableSubheaderValues[tableSubheaderOrder[i]],
                        };
                    }
                })
            )
        );
    }

	function deleteReportSubheaderValues(table) {
		if (reportSubheaderOrder.length === 1) {
			alert("Please create a new component before deleting this one.");
			return;
		}
		let indx = reportSubheaderOrder.indexOf(table);

        document.getElementById(`NewReportSubheader_${table}`).style.display = "none";
        document.getElementById(`ReportSubheader_${table}`).style.display = "flex";
        document.getElementById(`NewReportSubheader_${table}`).value = "";

		setReportSubheaderOrder(
            Array.prototype.concat(
                reportSubheaderOrder.slice(0, indx),
                reportSubheaderOrder.slice(indx + 1)
            )
        )

		setReportSubheaderValues(
            Object.assign(
                {},
                ...Object.keys(reportSubheaderValues).map((table_, i) => {
                    if (table_ !== table) {
                        return {
                            [table_]:
                                (i === 0 &&
                                    indx !== 0 &&
                                    reportSubheaderValues[
                                    reportSubheaderOrder[indx]
                                    ]) ||
                                (i === 1 &&
                                    indx === 0 &&
                                    reportSubheaderValues[
                                    reportSubheaderOrder[indx]
                                    ]) ||
                                reportSubheaderValues[reportSubheaderOrder[i]],
                        };
                    }
                })
            )
        )
	}
	const [reportTablePending, setReportTablePending] = useState(true)
	const [reportTableColumns, setReportTableColumns] = useState([])
	const [reportPDFTableColumns, setReportPDFTableColumns] = useState([])
	const [reportTableData, setReportTableData] = useState([])
	const [reportPDFTableData, setReportPDFTableData] = useState([])
	const reportEditButtonRef = useRef()
	const [reportsUpdate, setReportsUpdate] = useState([])
	function updateReports() {
		setReportsUpdate([])
	}

	useEffect(() => {
		if (studiesContext.selectedStudyId === undefined || studiesContext.selectedStudyId === '') return
        setReportTablePending(true)

		let tables = ["Create", "Run", "Track"]

		setSubheaderValues(
			Object.assign(
				{},
				...tables.map((table, i) => {
					if (i === 0) {
						return { [table]: true }
					} else {
						return { [table]: false }
					}
				})
			)
		)

		setSubheaderOrder(tables)

		let columns2labels = {
			report_name: { label: "Report Name", type: "string", center: false },
			created_by: { label: "Created By", type: "string", center: false },
			version: { label: "Versions", type: "string", center: false }
		}

		let columnData = []
		let pdfColumnData = []

		for (let elem of Object.keys(columns2labels)) {
			columnData.push({
				name: columns2labels[elem]['label'],
				selector: elem,
				sortable: true,
                center: columns2labels[elem]['center']
			})
            pdfColumnData.push({
                name: columns2labels[elem]['label'],
				selector: elem,
            })
		}
		columnData.push({
			name: "Edit",
			selector: "edit",
			sortable: false,
            // compact: true,
            width: '100px'
		})
		setReportTableColumns(columnData)
        setReportPDFTableColumns(pdfColumnData)

		// TODO: Replace this when we can call API endpoint to get report information
		let data = rows
		let tableData = []
        let pdfTableData = []

		for (let row of data) {
			let entry = {
				'edit': <EditUserButton onClick={() => {renderEditMultimodalModal()}} ref={reportEditButtonRef}><MdIcons.MdEdit size={25} /></EditUserButton>
			}
			let pdfEntry = {}
			for (let column of Object.keys(columns2labels)) {
				if (columns2labels[column]["type"] === "checkbox") {
					if (row[column]) {
                        entry[column] = (<MdIcons.MdOutlineCheckBox size={18} />)
					} else {
                        entry[column] = (<MdIcons.MdOutlineCheckBoxOutlineBlank />)
					}
                    pdfEntry[column] = row[column]
				} else if (columns2labels[column]["type"] === 'string') {
                    if (row[column] === null) {
						entry[column] = ""
                        pdfEntry[column] = `${row[column]}`
					} else {
						entry[column] = `${row[column]}`
                        pdfEntry[column] = `${row[column]}`
					}
                }
			}
			tableData.push(entry)
            pdfTableData.push(pdfEntry)
		}
		const sortedData = [...tableData].sort((a, b) => (a.report_name < b.report_name ? -1 : 1))
		setReportTableData(sortedData)
		setReportPDFTableData(pdfTableData)
		setReportTablePending(false)

		window.addEventListener("resize", updateReports)
		return () => {
			window.removeEventListener("resize", updateReports)
		}
	}, [studiesContext.selectedStudyId, reportsUpdate])

	// async function getReports() {
        
    //     return rows;
    // }

	function updateSubheaderValues(table, value) {
		if (SubheaderValues[table]) {
			return;
		}
		let newSubheaderValues = Object.assign(
			{},
			...Object.keys(SubheaderValues).map((table) => {
				return { [table]: false };
			})
		);
		setSubheaderValues({ ...newSubheaderValues, [table]: value })

		if (!SubheaderOrder.includes(table)) {
			setSubheaderOrder(
				Array.prototype.concat(SubheaderOrder, table)
			);

			let new_table = {};
			for (let group of Object.keys(
				tableConfig[SubheaderOrder[0]]
			)) {
				new_table[group] = {};
				for (let e of Object.keys(
					tableConfig[SubheaderOrder[0]][group]
				)) {
					new_table[group][e] = false;
				}
			}

			setTableConfig({ ...tableConfig, [table]: new_table })
		}
	}

	const [selectedRows, setSelectedRows] = useState(false)
	function updateRows({selectedRows}) {
		setSelectedRows(selectedRows)
	}
	const [toggleCleared, setToggleCleared] = useState(false)

	async function removeReport(study_id, report_name) {
		// TODO: Fix this when we have API endpoint
        const response = await fetch(
            `${LINKS.ADMIN.USER_MANAGEMENT.USERS.REMOVE_USER}?parent_study_id=${studiesContext.parentStudyId}`,
            {
                method: "POST",
                cache: "no-cache",
                headers: {
                    "content-type": "application/json",
                    Authorization: authProvider.token,
                },
                body: JSON.stringify({
                    study_id: study_id,
                    report_name: report_name,
                }),
            }
        )
        return response
    }

	async function handleRemoveReports() {     
	    if (selectedRows.length) {
			let report_names = selectedRows.map(r => r.report_name)	
			await Promise.all(report_names.map(report_name => removeReport(studiesContext.selectedStudyId, report_name))).then((res) => {console.log(res)}).catch((e)=>alert(e))		
			updateReports()
			setSelectedRows(false)
			setToggleCleared(!toggleCleared)			
		} 
    }

	const [patientSearchQuery, setPatientSearchQuery] = useState("");
	const [siteSearchQuery, setSiteSearchQuery] = useState("");
	const [doubleSearchQuery, setDoubleSearchQuery] = useState(false);
	const [groupExistSearchQuery, setGroupExistSearchQuery] = useState("")

	function setSearchQueryStates(doubleSearchBoolean, siteSearch, patientSearch) {
		setDoubleSearchQuery(doubleSearchBoolean)
		setSiteSearchQuery(siteSearch)
		setPatientSearchQuery(patientSearch)
	}
	function handlePatientSearch(event) {
		if (event.target.value === undefined || event.target.value === null) return
		if (event.target.value.includes("/")) {
			// if double search is true, set site search value to the left of the "/" and patient search value to the right of the "/"
			setSearchQueryStates(true, event.target.value.split("/")[0], event.target.value.split("/")[1])
		} else {
			setSearchQueryStates(false, event.target.value, event.target.value)
		}
	}
	
    const runReportButtonRef = useRef()

	return (
		<div style={{background: "#F8F8F8", height: window.innerHeight}}>
            {/* <HeaderGap /> */}
				<div
					style={{
						marginLeft: "-15px",
						background: "#FFFFFF",
                        zIndex: "-9999"
					}}
				>
					<PageSubHeader
						style={{
							width: props.sidebar
								? `${window.innerWidth - 250}px`
								: `${window.innerWidth - 30}px`,
						}}
					>
						{SubheaderOrder.map((table, i) => {
							return (
								<>
									<TableButton

										id={`TableButton_${table}`}
										colorchange={
											SubheaderValues[table]
										}
										underlined={SubheaderValues[table]}
										onClick={() => {
											updateSubheaderValues(
												table,
												!SubheaderValues[table]
											);
										}}
										
									>
                                        {table === "Create" ? <Link to={FRONTEND_LINKS.SUBPAGES.REPORTING.SUBPAGES.MULTIMODAL_REPORTS.MAIN} style={{textDecoration: "none",color:"#6C757C",}}> <p>{table}</p></Link> :
							            <p>{table}</p> }
									</TableButton>
								</>
							);
						})}
					</PageSubHeader>
				</div>
			<div style={{
				width: "100%",
				height: "100%",
				color: DEFAULTS.TEXT_COLOR,
				background: "#F8F8F8",
				paddingLeft: "35px",
				paddingRight: "35px",
				display: "block",
				}}>
					
				<ButtonHeader >
					<RunReportButton ref={runReportButtonRef} ><FaIcons.FaPlay size={18} style={{marginTop: "-3px"}}/> Run Reports </RunReportButton>
					<TrashButton 
						onClickFxn={handleRemoveReports()}
						disabled={!selectedRows.length > 0}
						buttonStyle={{marginRight: "25%", flex: "0.1"}}
					/>
					<Searchbar className="ui left icon input">
						<input type="text" placeholder="Search" onChange={event => {setSearchQuery(event.target.value)}}/> <i aria-hidden="true" class="search icon" />
						<button onClick={()=> {setSearchQuery(searchQuery)}}>Search</button>
					</Searchbar>
					<div style={{ marginLeft: "8px", position: "relative"}} ref={filterButtonRef}>
						{FilterButton(() => {renderFilterModal()})} 
					</div>
				</ButtonHeader>
				<div>
					<MainTable
					columns={reportTableColumns}
					data={reportTableData.filter((val) => {
							if (searchQuery === "") {
								return val
							} else if (val.report_name.toLowerCase().includes(searchQuery.toLowerCase())) {
								return val
							} else if (val.created_by.toLowerCase().includes(searchQuery.toLowerCase())) {
								return val
							} else if (val.version.toLowerCase().includes(searchQuery.toLowerCase())) {
								return val
							}
                   		})}
                    // selectRows={false}
					selectableRows
					progressPending={reportTablePending}
                    onRowClicked={undefined}
					onSelectedRowsChange={updateRows}
					clearSelectedRows={toggleCleared}
					pagination/>
                </div>
				<GrayOverlay id="multimodalFilterGrayOverlay"/>
				<FilterButtonModal id="multimodalFilterModal" ref={filterModalRef} style={{left: props.sidebar
                        ? `${(window.innerWidth - 1070) / 2 + 250}px`
                        : `${(window.innerWidth - 850) / 2 + 40}px`, display: "none",
					top: `${(window.innerHeight - 566)/3}px`,
					position: "fixed"
					}}>
					<h3>Filter</h3>
					<hr style={{border: "1px solid #B3B3B3", width: "820px"}}/>
				</FilterButtonModal>
                <RunReportsModal openRef={runReportButtonRef} props={props}/>
				<div id="createMultimodalReportModal" style={{display: "none", position: "fixed", top: "0px", left: "5px", right: "5px", width: `${window.innerWidth - 10}px`, zIndex: "9999", background: "#FFFFFF", border: "1px solid black", height: `${window.innerHeight - 10}px`}}>
					<div>
						<div style={{ background: "none", display: "inline-flex", marginLeft: "40px", marginTop: "14px", marginBottom: "-40px"}}>
							<PageSubHeader style={{ width: "100%",paddingLeft: "0px",}}>
								<HorizontalScrollBar style={{
										flex: "0 1 auto",
										width: "100%",
										display: "inline-flex",
										overflowX: "auto",
										overflowY: "hidden",
										marginBottom: "-2px",
										// marginLeft: "40px",
										whiteSpace: "nowrap",
										maxWidth: `${(window.innerWidth / 2) - 75}px`,
										background: "none"
									}}>
									{tableSubheaderOrder.map((table, i) => {
										return (
											<TableTab style={{background: "none"}}>
												<TableButton id={`TableButton_${table}`} colorchange={tableSubheaderValues[table]} underlined={tableSubheaderValues[table]} style={{fontSize: "16px", fontStyle: "normal", fontWeight: "600"}}
												onClick={(e) => {
													switch (e.detail) {
														case 1:
															updateTableSubheaderValues(table, !tableSubheaderValues[table]);
															break;
														case 2:
															let tableSubheaders =
																document.querySelectorAll('[id^="NewSubheader"]');
															for (let tableSubheader of tableSubheaders) {
																tableSubheader.style.display = "none";
																document.getElementById(`TableButton_${tableSubheader.id.split("_")[1]}`).style.display = "flex";
															}
															document.getElementById(`NewSubheader_${table}`).style.display = "flex";
															document.getElementById(`TableButton_${table}`).style.display = "none";
															break;
														default:
															return;
													}
												}}>
													<p>{table}</p>
													</TableButton>
													<input
														id={`NewSubheader_${table}`}
														ref={newSubheaderRef}
														type="text"
														placeholder="New Category"
														onKeyDown={(e) => {
															if (e.keyCode === 13) {
																var newKey = document.getElementById(`NewSubheader_${table}`).value;
																if (Object.keys(tableSubheaderValues).includes(newKey)) {
																	alert("Category name already exists.");
																	return;
																}
																if (newKey === "") {
																	alert("Category name cannot be blank");
																	return;
																}
																let indx = tableSubheaderOrder.indexOf(table);
																setTableSubheaderOrder(Array.prototype.concat(tableSubheaderOrder.slice(0, indx),newKey,tableSubheaderOrder.slice(indx + 1)));
																let newTableSubheaderValues =
																	Object.assign(
																		{},
																		...Object.keys(
																			tableSubheaderValues
																		).map((table_) => {
																			if (table_ !== table) {
																				return {
																					[table_]: false,
																				};
																			}
																		})
																	);
																setTableSubheaderValues({
																	...newTableSubheaderValues,
																	[newKey]: true,
																});
																let newTableConfig = Object.assign(
																	{},
																	...Object.keys(tableConfig)
																		.map((table_) => {
																			if (table_ === table) {
																				return {
																					[newKey]: tableConfig[table],
																				};
																			} else {
																				return {
																					[table_]: tableConfig[table_],
																				};
																			}
																		})
																);
																setTableConfig(newTableConfig)
																document.getElementById(
																	`NewSubheader_${table}`
																).style.display = "none";
																document.getElementById(
																	`NewSubheader_${table}`
																).value = "";
																document.getElementById(
																	`TableButton_${table}`
																).style.display = "flex";
															}
														}}
														style={{
															display: "none",
															borderRadius: "6px",
															paddingLeft: "5px",
															border: "solid 0.5px #A7A7A7",
															fontFamily: "Source Sans Pro",
															width: "110px",
															marginBottom: "8px",
															marginRight: "4px"
														}}
													/> 
													<button
														style={{
															color: "#6C757C",
															marginTop: "4px",
															paddingLeft: "0px",
														}}
														onClick={(e) => {
															deleteTableSubheaderValues(table);
														}}
													>
														<MdIcons.MdClose />
													</button>
												</TableTab>
											);
										})}
									</HorizontalScrollBar>
									<button
										style={{
											color: "#0097ee",
											marginLeft: "20px",
											width: "20px",
											paddingRight: "0px",
											fontSize: 24,
											marginTop: "-5px", right: `${(window.innerWidth / 2) - 10}px`, position: "fixed"
										}}
										onClick={(e) => {
											updateTableSubheaderValues(
												`New Category ${newSubheaderVal}`,
												!tableSubheaderValues[
												`New Category ${newSubheaderVal}`
												]
											);
											setNewSubheaderVal(newSubheaderVal + 1);
										}}
									>
										+
									</button>
								</PageSubHeader>
							</div>
						<MdIcons.MdCloseFullscreen onClick={() => {closeEditMultimodalReportModal()}} size={22} style={{right: "14px", marginTop: "14px", position: "absolute", cursor: "pointer"}}/>

						<hr style={{border: "1px solid #DDDDDD", marginTop: "8px"}}/>
						<HorizontalScrollBar style={{
							flex: "0 1 auto",
							width: "100%",
							display: "inline-flex",
							overflowX: "auto",
							overflowY: "hidden",
							marginBottom: "-2px",
							marginLeft: "40px",
							whiteSpace: "nowrap",
							maxWidth: `${(window.innerWidth / 2) - 75}px`,
							background: "none"
						}}>
							{reportSubheaderOrder.map((table, i) => {
								return (
									<TableTab style={{background: "none", border: "none"}}>
										<TableButton id={`ReportSubheader_${table}`} colorchange={reportSubheaderValues[table]} underlined={reportSubheaderValues[table]} style={{border: "none", background: "none", fontSize: "16px", fontStyle: "normal", fontWeight: "600"}}
										onClick={(e) => {
										switch (e.detail) {
											case 1: 
												updateReportSubheaderValues(table, !reportSubheaderValues[table]);
											break;
											case 2:
												let reportSubheaders =
													document.querySelectorAll('[id^="NewReportSubheader"]');
												for (let reportSubheader of reportSubheaders) {
													reportSubheader.style.display = "none";
													document.getElementById(`ReportSubheader_${reportSubheader.id.split("_")[1]}`).style.display = "flex";
												}
												document.getElementById(`NewReportSubheader_${table}`).style.display = "flex";
												document.getElementById(`ReportSubheader_${table}`).style.display = "none";
												break;
											default:
												break;
										}
									}}>
											<p style={{paddingBottom: "7px"}}>{table}</p>
										</TableButton>
										<input 
											id={`NewReportSubheader_${table}`} 
											ref={newReportSubheaderRef}
											type="text"
											placeholder="New Component"
											onKeyDown={(e) => {
												if (e.keyCode === 13) {
													var newKey = document.getElementById(`NewReportSubheader_${table}`).value;
													if (Object.keys(reportSubheaderValues).includes(newKey)) {
														alert("Component name already exists.");
														return;
													}
													if (newKey === "") {
														alert("Component name cannot be blank");
														return;
													}
													let indx = reportSubheaderOrder.indexOf(table);
													setReportSubheaderOrder(Array.prototype.concat(reportSubheaderOrder.slice(0, indx), newKey, reportSubheaderOrder.slice(indx + 1)));
													let newReportSubheaderValues = 
														Object.assign(
															{},
															...Object.keys(
																reportSubheaderValues
															).map((table_) => {
																if (table_ !== table) {
																	return {
																		[table_]: false,
																	};
																}
															})
														);
													setReportSubheaderValues({...newReportSubheaderValues, [newKey]: true})
													document.getElementById(`NewReportSubheader_${table}`).style.display = "none";
													document.getElementById(`NewReportSubheader_${table}`).value = "";
													document.getElementById(`ReportSubheader_${table}`).style.display = "flex";
												}
											}}
											style={{
												display: "none",
												borderRadius: "6px",
												paddingLeft: "5px",
												border: "solid 0.5px #A7A7A7",
												fontFamily: "Source Sans Pro",
												width: "125px",
												marginBottom: "8px",
												marginRight: "4px"
											}}
										/>
										<button
											style={{color: "#6C757C", marginTop: "-12px", paddingLeft: "0px", background: "none", border: "none"}}
											onClick={(e) => {
												deleteReportSubheaderValues(table);
											}}>
											<MdIcons.MdClose />
										</button>
									</TableTab>
								)
							})}
						</HorizontalScrollBar>
						<button
										style={{color: "#0097ee", marginLeft: "20px", width: "20px", paddingRight: "0px", fontSize: 24, marginTop: "-5px", background: "none", border: "none"
										}}
										onClick={(e) => {
											updateReportSubheaderValues(
												`New Component ${newReportSubheaderVal}`,
												!tableSubheaderValues[
												`New Component ${newReportSubheaderVal}`
												]
											);
											setNewReportSubheaderVal(newReportSubheaderVal + 1);
										}}
									>
										+
						</button>
						

						{/* <div id="multimodalReportIconButtonHeader" style={{display: "inline-flex", marginLeft: "40px", marginTop: "-12px", marginBottom: "12px"}}>
							{Object.entries(displayHeaderIconButtons).map(([key, group]) => (
								Object.entries(group).map(([label, icon]) => {
									return (
										<div style={{display: "inline-flex", marginRight: "16px"}}> */}
											{/* <p style={{marginRight: "6px", display: 'none' ? icon['label'] === '' : 'block'}}>{icon['label']}</p> */}
											{/* {(icon['icons'].map((button) => (
												<IconButton><p>{button}</p></IconButton>
											)))}
										</div>
									)
								})
							))}
						</div> */}

						<div id="multimodalReportModalContent" style={{display: "block", background: "#F8F8F8", maxHeight: `${window.innerHeight - 125}px`, overflowY: "scroll", paddingLeft: "40px", paddingRight: "40px", paddingTop: "20px"}}>
							
							<CreateReports />
						</div>


					</div>
				</div>

                </div>
				</div>)
}


const HeaderGap = styled.div`
background-color: white;
	height: 47px;
`;

const ButtonHeader = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding-bottom: 15px;
	position: relative;
	padding-top: 20px;
`;

const RunReportButton = styled.button`
	width: 115px;
	height: 34px;
	background: #207dea;
	border-radius: 6px;
	font-family: 'Source Sans Pro';
	font-style: normal;
	font-weight: 600;
	line-height: 150%;
	color: #FFFFFF;
	border: none;
	padding: 4px 8px;
	margin-right: 8px;
	white-space: nowrap;
	flex: 0.25;
`;

const Searchbar = styled.div`
	width: 40%;
	height: 34px;
	background: #FFFFFF;
	border: 0.5px solid #d9d9d9;
	box-sizing: border-box;
	box-shadow: 0px 0px 3px rgba(99, 191, 244, 0.15);
	border-radius: 6px;
	overflow-y: hidden;
	overflow-x: hidden;
	flex: 2;
	button {
		width: 80px;
		height: 34px;
		background: #207dea;
		border-radius: 0px 6px 6px 0px;
		border: none;
		font-family: 'Source Sans Pro';
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 150%;
		color: #FFFFFF;
		cursor: pointer;
		padding-bottom: 3.5px;
	}
`;

// const RunReportModal = styled.div`
// 	width: 820px;
// 	// max-height: 776px;
// 	height: auto;
// 	background: #ffffff;
// 	border-radius: 6px;
// 	// top: 20px;
// 	z-index: 100;
// 	h3 {
// 		font-family: "Montserrat";
// 		font-style: normal;
// 		font-weight: 700;
// 		font-size: 20px;
// 		line-height: 150%;
// 		color: #293241;
// 		text-align: center;
// 		margin-top: 8px;
// 		margin-bottom: -4px;
// 	}
// 	h2 {
// 		font-family: "Source Sans Pro";
// 		font-style: normal;
// 		font-weight: 700;
// 		font-size: 21px;
//         margin-left: 30px;
// 		line-height: 150%;
// 		color: #293241;
// 		margin-top: 5px;
// 	}
// 	h1 {
// 		font-family: "Source Sans Pro";
// 		font-style: normal;
// 		font-weight: 600;
// 		font-size: 16px;
// 		line-height: 150%;
//         text-align: center;
// 		color: #293241;
// 	}
// 	textarea {
// 		background: #ffffff;
// 		border: 1px solid #ababab;
// 		box-sizing: border-box;
// 		border-radius: 6px;
// 		padding: 16px;
// 		width: 756px;
// 		height: 100px;
// 		margin-top: -5px;
// 		::placeholder {
// 			font-family: "Source Sans Pro";
// 			font-style: normal;
// 			font-weight: 400;
// 			font-size: 16px;
// 			line-height: 150%;
// 			color: #9f9f9f;
// 			margin-top: -200px !important;
// 		}
// 	}
// `;



const GrayOverlay = styled.div`
	background: #808080;
	opacity: .8;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 99;
	width: 100%;
	height: 100%;
	display: none;
`;

const FilterButtonModal = styled.div`
	// position: absolute;
	width: 820px;
	height: 566px;
	background: #ffffff;
	border-radius: 6px;
	top: 25px;
	z-index: 1000;
	display: none;
	h3 {
		font-family: "Montserrat";
		font-style: normal;
		font-weight: 700;
		font-size: 20px;
		line-height: 150%;
		color: #293241;
		text-align: center;
		margin-top: 8px;
		margin-bottom: -4px;
	}
`;

const PageSubHeader = styled.div`
	display: flex;
	width: 100%;
	height: 47px;
	padding-left: 50px;
	padding-right: 50px;
	overflow-x: auto;
	overflow-y: hidden;
	white-space: nowrap;
	background: #ffffff;
	padding-top: 10px;
	margin-bottom: 25px;
	button {
		font-family: "Source Sans Pro";
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		display: inline-flex;
		border: solid 0.5px #ffffff;
		background: #ffffff;
		cursor: pointer;
	}
`;
const TableTab = styled.div`
	width: auto;
	height: 34px;
	background: none;
	border: solid 0.5px #ffffff;
	font-size: 16px;
	display: inline-flex;
	margin-right: 35px;
	/* padding-left: -35px; */
	
`;

const HorizontalScrollBar = styled.div`
	::-webkit-scrollbar {
		display: block;
		height: 5px;
		color: #313a4a;
		border-radius: 2px;
	}
	::-webkit-scrollbar-track {
		background: #bec4cf;
		height: 5px;
		border-radius: 2px;
	}
`;

const TableButton = styled.button`
	width: auto;
	height: 34px;
    background: #ffffff;
	border: solid 0.5px #ffffff;
	font-size: 16px;
	display: inline-flex;
	margin-right: 28px;
	color: ${({colorchange}) => (colorchange ? "#0097ee" : "#6C757C")};
	p {
		padding-bottom: 10px;
		border-bottom: ${({underlined}) =>
			underlined ? "2px solid #0097ee" : "none"};
	}
`;

const EditUserButton = styled.button`
	width: auto;
	height: 34px;
	background: transparent;
	border-radius: 6px;
	font-family: 'Source Sans Pro';
	font-style: normal;
	font-weight: 600;
	line-height: 150%;
	color: #000000;
	border: none;
	padding: 4px 8px;
	margin-right: 8px;
	white-space: nowrap;
	flex: 0.25;
`;



export default RunReports;
