import styled from "styled-components";
import { BUTTON_STYLING } from "../../Constants/Formatting";

// USAGE NOTES (color):
// blueFill (DEFAULT) = primary button with blue background and white text
// blueOutline = primary button with white background and blue border/text
// redFill = primary button with red background and white text
// redOutline = primary button with white background and red border/text 

export const buttonColor = Object.freeze({
    blueFill : "blue-fill",
    blueOutline: "blue-outline",
    redFill: "red-fill",
    redOutline: "red-outline"
})

function PrimaryCTAButton({ buttonText = "", onClickFxn, disabled = false, buttonStyle = {}, color = "blue-fill", icon = null, reference = null, hoverText = "" }) {
    if (icon) {
        return (<>
            <PrimaryCTAButtonContainer
                onClick={onClickFxn ? onClickFxn : null}
                disabled={disabled}
                style={{ ...buttonStyle, paddingRight: '15px', fontSize: '14px', display: 'flex', alignItems: 'center' }}
                color={color}
                title={hoverText}
                ref={reference}
            >
                {icon}
                {buttonText}
            </PrimaryCTAButtonContainer>
        </>)
    } else {
        return (<>
            <PrimaryCTAButtonContainer
                onClick={onClickFxn ? onClickFxn : null}
                disabled={disabled}
                style={{ ...buttonStyle }}
                color={color}
                title={hoverText}
                ref={reference}
            >
                {buttonText}
            </PrimaryCTAButtonContainer>
        </>)
    }

}

export const PrimaryCTAButtonContainer = styled.button`
    width: auto;
    height: 34px;
    background: ${(props) => props.color === buttonColor.redFill 
        ? BUTTON_STYLING.RED
        : props.color === buttonColor.redOutline 
        ? BUTTON_STYLING.WHITE
        : props.color === buttonColor.blueOutline 
        ? BUTTON_STYLING.WHITE
        : BUTTON_STYLING.BLUE};
    color: ${(props) => props.color === buttonColor.redFill 
        ? BUTTON_STYLING.WHITE
        : props.color === buttonColor.redOutline 
        ? BUTTON_STYLING.RED
        : props.color === buttonColor.blueOutline 
        ? BUTTON_STYLING.BLUE 
        : BUTTON_STYLING.WHITE};
    border: ${(props) => props.color === buttonColor.redFill 
        ? "none" 
        : props.color === buttonColor.redOutline 
        ? BUTTON_STYLING.RED_BORDER
        : props.color === buttonColor.blueOutline 
        ? BUTTON_STYLING.BLUE_BORDER 
        : "none"};
    border-radius: 6px;
    font-family: ${BUTTON_STYLING.FONT};
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    padding: 4px 8px;
    margin-right: 8px;
    white-space: nowrap;
    opacity: ${(props) => props.disabled ? "0.3" : "1"};
    pointer-events: ${(props) => props.disabled ? "none" : "all"};
    cursor: ${(props) => props.disabled ? "default" : "pointer"};
    &:not(:disabled):hover {
        background: ${(props) => props.color === buttonColor.redFill
            ? BUTTON_STYLING.RED_FILL_HOVER
            : props.color === buttonColor.redOutline
            ? BUTTON_STYLING.RED
            : props.color === buttonColor.blueOutline
            ? BUTTON_STYLING.BLUE
            : BUTTON_STYLING.BLUE_FILL_HOVER};
        color: ${BUTTON_STYLING.WHITE};
    }
`;

export default PrimaryCTAButton;