import React from "react"
import { Analytic, OnDemandAnalysis, PRxAnalysis } from "../../../Types/AnalysisDetails"
import { EditPRx } from "./EditPRx"

export const getAnalysisEditor = (analysis: OnDemandAnalysis, onChange: (analysis: OnDemandAnalysis) => void) => {
    switch (analysis.analytic) {
        case Analytic.PRx: return <EditPRx initialState={analysis as PRxAnalysis} onChange={onChange} />
        default:
            throw new Error("Invalid analysis: " + analysis.analytic)
    }
}
