import React,{ useContext, useRef } from "react"
import { useRecoilValue } from "recoil"
import { D3ModalityGraphGroup } from "../D3/D3ModalityGraphGroup"
import { annotationsAtom } from "../../../../Atoms/Annotations"
import { DimensionsContext, DimensionsProvider } from "../../../../../../../../Providers/DimensionsProvider"
import { currentPatientFileInfoAtom } from "../../../../Atoms/PatientFile"
import { Annotation } from "../../../../../../../../Managers/VisualizationManager/Variables/Annotations"
import { useInProgressAnnotation } from "./useInProgressAnnotation"
import { hotkeyActions } from "../../../../Types/KeyboardShortcut"
import { WindowSettings } from "../../../../../../../../Managers/VisualizationManager/WindowSettings/WindowSettings"
import { modalityGraphGroupConfigAtom } from "../../../../Atoms/ModalityGraphGroup"
import { ModalityGraphGroupConfig, ModalityGraphGroupConfigJSON } from "../../../../Types/ModalityGraphGroup"
import { selectedLayoutIdAtom } from "../../../../Atoms/Layout"
import { useD3KeyboardShortcuts } from "../../../../Hooks/useD3KeyboardShortcuts"
import { useD3CheckReloadData } from "../../../../Hooks/useD3CheckReloadData"
import { pageManagerRegistry } from "../../../../Data/PageManagerRegistry"
import { viewScaleRegistry } from "../../../../Data/ViewScaleRegistry"
import { useD3UpdateVisibleTraces } from "../../../../Hooks/useD3UpdateVisibleTraces"
import { getVisibleGraphs } from "../visibleGraphs"
import { VisualizationComponent } from "../../../../VisualizationComponentFactory"
import { fileScaleRegistry } from "../../../../Data/FileScaleRegistry"
import { RenderStrategy } from "../../../../Types/Trace"
import { ModalityPageManager } from "../../../../Data/ModalityPageManager"
import { useD3Controller } from "../../../../Hooks/useD3Controller"
import { useSocketProvider } from "../../../../../../../../Providers/SocketProvider"

type ModalityGraphGroupProps = {
	json: ModalityGraphGroupConfigJSON
	area: string
}

export function ModalityGraphGroup(props: ModalityGraphGroupProps) {
	return (
		<div style={{height: "100%", display: "flex", flexDirection: "column"}} >
			<div style={{flex: 0}}>
				<WindowSettings visualizationArea={props.area} />
			</div>
			<div style={{flex: 1}}>
				<DimensionsProvider>
					<ModalityGraphGroupD3Wrapper {...props} />
				</DimensionsProvider>
			</div>
		</div>
	)
}

function ModalityGraphGroupD3Wrapper(props: ModalityGraphGroupProps) {
	// Providers
	const dimensions = useContext(DimensionsContext)
	const { getDataQuerySocket } = useSocketProvider()

	// Recoil State
	const { fileStartDate, fileEndDate, patientModalities } = useRecoilValue(currentPatientFileInfoAtom)
	const { inProgressAnnotation } = useInProgressAnnotation(props.area)
	const annotations = useRecoilValue<Annotation[]>(annotationsAtom)
	const layoutId = useRecoilValue(selectedLayoutIdAtom)
	const visualizationId = { layoutId: layoutId as string, windowId: props.area }
	const atom = modalityGraphGroupConfigAtom(visualizationId)
	const config = useRecoilValue(atom)

	const viewScale = viewScaleRegistry.get(visualizationId, VisualizationComponent.TIME_SERIES_GROUP, { fileStartDate, fileEndDate, viewDuration: props.json.viewDuration })

	// Local State
	const domNode = useRef(null)
	const visibleGraphs = getVisibleGraphs(props.json.graphs, patientModalities, config.hideEmptyGraphs)

	const [viewStart, viewEnd] = viewScale.domain()
	const viewDuration = viewEnd.getTime() - viewStart.getTime()

	const d3Controller = useD3Controller<D3ModalityGraphGroup, ModalityGraphGroupConfig, ModalityGraphGroupConfigJSON, ModalityPageManager>({
		atom,
		nodeRef: domNode,
		initProps: props.json,
		pageManager: pageManagerRegistry.get(visualizationId, VisualizationComponent.TIME_SERIES_GROUP, new ModalityPageManager(), getDataQuerySocket),
		props: {
			id: props.area,
			viewScale,
			fileScale: fileScaleRegistry.get(visualizationId, VisualizationComponent.TIME_SERIES_GROUP, fileStartDate, fileEndDate),
			hideEmptyGraphs: false,
			patientModalities,
			annotations,
			inProgressAnnotation,
			graphs: (config.graphs.length > 0 ? config.graphs : props.json.graphs ?? []).map((graph, index) => ({
				...graph, 
				id: index.toString(), 
				offset: 0, 
				height: 0, 
				width: 0, 
				xScale: viewScale
			})) ?? [],
		},
		d3ControllerConstructor(node, config, pageManager, callbacks) {
			return new D3ModalityGraphGroup(node, config, pageManager, callbacks)
		},
	})

	useD3UpdateVisibleTraces({
		d3Controller,
		windowId: props.area,
	})

	useD3KeyboardShortcuts({
		d3Controller,
		windowId: props.area,
		shortcuts: [
			hotkeyActions.NEXT_PAGE,
			hotkeyActions.PREVIOUS_PAGE,
			hotkeyActions.ZOOM_IN,
			hotkeyActions.ZOOM_OUT,
			hotkeyActions.UPDATE_CUSTOM_ANNOTATION_MARKERS,
			hotkeyActions.CANCEL_ANNOTATION,
			hotkeyActions.PLAY_PAUSE
		]
	})

	useD3CheckReloadData({
		d3Controller,
		clearDataIfChanges: {
			viewDuration,
			modalities: [...new Set(config.graphs?.flatMap(graph => graph.traces.map(trace => trace.dataKey)))].sort(),
		},
		clearRenderCacheIfChanges: {
			dimensions,
			visibleGraphs: visibleGraphs.length,
			traceColors: config.graphs?.flatMap(graph => graph.traces.map(trace => {
				switch(trace.renderStrategy) {
					case RenderStrategy.HEATMAP:
						return trace.colorSpectrum
					case RenderStrategy.LINE:
					default:
						return trace.color
				}
			})),
			traceRenderStrategies: config.graphs?.flatMap(graph => graph.traces.map(trace => trace.renderStrategy))
		},
	})

	// This is the last taste of React. From here, we use D3
	return <div ref={domNode} style={{ height: "100%" }} />
}
