import { NODE_OUTPUT_TYPES } from "../../NodeOutputTypes";

export const RMSD = {
    INPUTS: [],
    DISPLAY_NAME: "RMSD",
    PRODUCT_LEVEL: "Standard",
    DESCRIPTION: "Calculate the RMSD between two lists of values.",
    KEYWORDS: ["rmsd", "root", "mean", "square", "difference"],
    TYPE: 'METRICS',
    DATA_INPUT_TYPES: [NODE_OUTPUT_TYPES.LIST_OF_VALUES, NODE_OUTPUT_TYPES.LIST_OF_VALUES],
    DATA_OUTPUT_TYPE: NODE_OUTPUT_TYPES.VALUE
}