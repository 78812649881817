export enum MobergAnimationCurve {
    EASE_IN = "cubic-bezier(0.3, 0.2, 0.2, 1.0)",
    EASE_OUT = "cubic-bezier(0.5, 0.1, 0.4, 1.0)"
}

export enum MobergAnimationTiming {
    VERY_FAST = "100ms",
    FAST = "200ms",
    REGULAR = "350ms",
    SLOW = "500ms"
}

export enum MobergAnimationTimingMs {
    VERY_FAST = 100,
    FAST = 200,
    REGULAR = 350,
    SLOW = 500,
}