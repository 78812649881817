export enum Analytic {
    PRx = "prx",
}


// On-demand analytics are calculated along with the page request at request time.
export type OnDemandAnalysis = {
    calculationPeriodMs: number // How often we should calculate the analytic
    calculationWindowMs: number // How much data we need for the analytic
    analytic: Analytic // The analytic that we are calculating
}


export type PRxAnalysis = OnDemandAnalysis & {
    analytic: Analytic.PRx
    ABP: string,
    ICP: string
}
