import React, { useEffect, useRef, useState } from "react"
import { MdRadioButtonChecked, MdRadioButtonUnchecked } from "react-icons/md"
import { AnnotationGroup } from "../../../Types/Annotations"
import { authUpdates, useAuthProvider } from "../../../../../../../Providers/AuthProvider"
import { useOnMount } from "../../../../../../../Hooks/useOnMount"

export type SessionSelectorChangeEvent = {
	type: "create" | "existing"
	sessionId: string
	sessionName: string
}

type DefaultAnnotationSessionSelectorProps = {
	annotationSessions: AnnotationGroup[]
	onChange?: (event: SessionSelectorChangeEvent) => void
	validationError?: string
}

export type SessionOption = {
	type: "create" | "existing"
	label: string
}

const options: SessionOption[] = [
	{
		type: "existing",
		label: "Continue with an existing session",
	},
	{
		type: "create",
		label: "Create a new session",
	},
]

export const DefaultAnnotationSessionSelector = (props: DefaultAnnotationSessionSelectorProps) => {
	const [selectedOption, setSelectedOption] = useState(options[0])
	const [newSessionName, setNewSessionName] = useState("")
	const [selectedAnnotationSessionId, setSelectedAnnotationSessionId] = useState<string>(props.annotationSessions[0].id.toString())
	const previousEvent = useRef<SessionSelectorChangeEvent>()

	const authProvider = useAuthProvider()

	useOnMount(() => {
		authProvider.update(authUpdates.TOKEN, authUpdates.PERMISSIONS)
	})

	useEffect(() => {
		let event: SessionSelectorChangeEvent = {
			type: selectedOption.type,
			sessionId: "",
			sessionName: "",
		}

		switch (selectedOption.type) {
			case "create":
				event.sessionName = newSessionName.trim()
				break
			case "existing":
				event.sessionId = selectedAnnotationSessionId
				break
		}

		const changed = JSON.stringify(previousEvent.current) !== JSON.stringify(event)

		if (changed && props.onChange) {
			props.onChange(event)
			previousEvent.current = event
		}
	}, [newSessionName, props, selectedAnnotationSessionId, selectedOption])

	return (
		<div>
			<div>
				{options.map(option => {
					return (
						<div title={option.type === 'create' && !authProvider.permissions?.create_annotation_group ? 'You do not have permission for this action' : ''}>
							<button
								key={option.type}
								style={{ display: "flex", padding: "5px 0", gap: "8px", fontSize: "16px", border: "none", background: "none", cursor: option.type === 'create' && !authProvider.permissions?.create_annotation_group ? 'not-allowed' : 'pointer', alignItems: 'center' }}
								onClick={() => setSelectedOption(option)}
								id={`${option.type}-${!authProvider.permissions?.create_annotation_group}`}
								disabled={option.type === 'create' && !authProvider.permissions?.create_annotation_group}
							>
								<div style={{ opacity: option.type === 'create' && !authProvider.permissions?.create_annotation_group ? 0.2 : 1, display: 'flex' }}>{option.label === selectedOption.label ? <MdRadioButtonChecked size={20} color={"#207dea"} /> : <MdRadioButtonUnchecked size={20} />}</div>

								<div> 
									<p>
										{option.label}
									</p>
								</div>
							</button>
						</div>
					)
				})}
			</div>

			<div style={{ padding: "30px 0", width: "100%" }}>
				<div style={{ fontWeight: "bold", paddingBottom: "10px" }}> New annotations will be written to: </div>

				{selectedOption.type === "create" && (
					<div>
						<input
							type="text"
							value={newSessionName}
							onChange={event => setNewSessionName(event.target.value)}
							style={{ width: "100%", padding: "10px", border: "1px solid gray", borderRadius: "6px", height: '41px' }}
						/>
						<div style={{ color: "red" }}> {props.validationError ? props.validationError : ""} </div>
					</div>
				)}

				{selectedOption.type === "existing" && (
					<div>
						<select
							value={selectedAnnotationSessionId}
							onChange={event => setSelectedAnnotationSessionId(event.target.value)}
							style={{ width: "100%", padding: "10px", border: "1px solid gray", borderRadius: "6px", cursor: "pointer", height: '41px' }}
						>
							{props.annotationSessions.map(session => {
								return (
									<option key={session.id} value={session.id}>
										{session.group_name}
									</option>
								)
							})}
						</select>
					</div>
				)}
			</div>
		</div>
	)
}
