export const LOGIC_NODES = {
    	// DESCRIPTION: "These modules are responsible for dynamically changing the pipeline architecture based on data input from deciding whether a particular modality should be thrown out to changing the path of analysis based on the data.",
		// "EXCLUDEIF": {
		// 	"INPUTS": [
		// 		{
		// 			DISPLAY_NAME: "Modality to Exclude",
		// 			VARIABLE_NAME: "modality_name",
		// 			TYPE: "DROPDOWN_INPUT",
		// 			DEFAULT_VALUE: {
		// 				'ABP_Syst': false,
		// 				'ABP_Dias': false,
		// 				'ICP_na': true
		// 			},
		// 			DESCRIPTION: "This field specifies which modality might be excluded based on the input of another data stream.",
		// 			ADVANCED_INPUT: false
		// 		},
		// 		{
		// 			DISPLAY_NAME: "Criteria: Data",
		// 			VARIABLE_NAME: "exclusion_modality",
		// 			TYPE: "DROPDOWN_INPUT",
		// 			DEFAULT_VALUE: {
		// 				'ABP_Syst': false,
		// 				'ABP_Dias': false,
		// 				'ICP_na': false,
		// 				"ICP_quality": true
		// 			},
		// 			DESCRIPTION: "This field specifies which data stream will used to determine whether a particular modality will be discarded during analysis.",
		// 			ADVANCED_INPUT: false
		// 		},
		// 		{
		// 			DISPLAY_NAME: "Criteria: Direction",
		// 			VARIABLE_NAME: "exclusion_modality",
		// 			TYPE: "DROPDOWN_INPUT",
		// 			DEFAULT_VALUE: {
		// 				'<': true,
		// 				'<=': false,
		// 				'>': false,
		// 				'>=': false
		// 			},
		// 			DESCRIPTION: "This field specifies relative to the given threshold which direction will be sensitive to exclusion; i.e. less than (<) threshold implies exclusion.",
		// 			ADVANCED_INPUT: false
		// 		},
		// 		{
		// 			DISPLAY_NAME: "Criteria: Magnitude",
		// 			VARIABLE_NAME: "exclusion_magnitude",
		// 			TYPE: "NUMBER_INPUT",
		// 			DEFAULT_VALUE: 22,
		// 			DESCRIPTION: "This field specifies the threshold which will result in the exclusion of a particular modality.",
		// 			ADVANCED_INPUT: false
		// 		},
		// 		{
		// 			DISPLAY_NAME: "Criteria: Duration (s)",
		// 			VARIABLE_NAME: "exclusion_length",
		// 			TYPE: "NUMBER_INPUT",
		// 			DEFAULT_VALUE: 300,
		// 			DESCRIPTION: "This field specifies the duration of the met threshold that will result in the exclusion of a particular modality.",
		// 			ADVANCED_INPUT: false
		// 		},
		// 		{
		// 			DISPLAY_NAME: "Verbose",
		// 			VARIABLE_NAME: "verbose",
		// 			TYPE: "CHECKBOX_INPUT",
		// 			DEFAULT_VALUE: false,
		// 			DESCRIPTION: "This field specifies whether activity of this module will be logged to on the server's file system.",
		// 			ADVANCED_INPUT: true,
		// 	KEYWORDS: []	
		// 		}	
		// 	],
		// 	"OUTPUTS": [

		// 	],
		// 	DISPLAY_NAME: "ExcludeIf Module",
		// 	PRODUCT_LEVEL: "Standard Plus",
		// 	DESCRIPTION: "This module can exclude a particular data stream based on defined rules in its arguments.\nFor instance, ICP (intercranial pressure) can be excluded if its quality score (Criteria: Data = ICP quality) is below (Criteria: Direction: less than) a certain threshold of 80 (Criteria: Threshold = 80) for at least 300 consecutive seconds (Criteria: Duration (s) = 300 seconds)."
		// },
		// "SWITCHIF": {
		// 	"INPUTS": [
		// 		{
		// 			DISPLAY_NAME: "Modality to Exclude",
		// 			VARIABLE_NAME: "modality_name",
		// 			TYPE: "DROPDOWN_INPUT",
		// 			DEFAULT_VALUE: {
		// 				'Next-0': true,
		// 				'Next-1': false,
		// 				'Next-2': true
		// 			},
		// 			DESCRIPTION: "This field specifies which node might be skipped based on the input of another data stream.",
		// 			ADVANCED_INPUT: false
		// 		},
		// 		{
		// 			DISPLAY_NAME: "Criteria: Data",
		// 			VARIABLE_NAME: "exclusion_modality",
		// 			TYPE: "DROPDOWN_INPUT",
		// 			DEFAULT_VALUE: {
		// 				'ABP_Syst': false,
		// 				'ABP_Dias': false,
		// 				'ICP_na': true
		// 			},
		// 			DESCRIPTION: "This field specifies which data stream will used to determine whether a particular modality will be discarded during analysis.",
		// 			ADVANCED_INPUT: false
		// 		},
		// 		{
		// 			DISPLAY_NAME: "Criteria: Direction",
		// 			VARIABLE_NAME: "exclusion_modality",
		// 			TYPE: "DROPDOWN_INPUT",
		// 			DEFAULT_VALUE: {
		// 				'<': true,
		// 				'<=': false,
		// 				'>': false,
		// 				'>=': false
		// 			},
		// 			DESCRIPTION: "This field specifies relative to the given threshold which direction will be sensitive to exclusion; i.e. less than (<) threshold implies exclusion.",
		// 			ADVANCED_INPUT: false
		// 		},
		// 		{
		// 			DISPLAY_NAME: "Criteria: Magnitude",
		// 			VARIABLE_NAME: "exclusion_magnitude",
		// 			TYPE: "NUMBER_INPUT",
		// 			DEFAULT_VALUE: 22,
		// 			DESCRIPTION: "This field specifies the threshold which will result in the exclusion of a particular modality.",
		// 			ADVANCED_INPUT: false
		// 		},
		// 		{
		// 			DISPLAY_NAME: "Criteria: Duration",
		// 			VARIABLE_NAME: "exclusion_length",
		// 			TYPE: "NUMBER_INPUT",
		// 			DEFAULT_VALUE: 60,
		// 			DESCRIPTION: "This field specifies the duration of the met threshold that will result in the exclusion of a particular modality.",
		// 			ADVANCED_INPUT: false
		// 		},
		// 		{
		// 			DISPLAY_NAME: "Verbose",
		// 			VARIABLE_NAME: "verbose",
		// 			TYPE: "CHECKBOX_INPUT",
		// 			DEFAULT_VALUE: false,
		// 			DESCRIPTION: "This field specifies whether activity of this module will be logged to on the server's file system.",
		// 			ADVANCED_INPUT: true,
		// 	KEYWORDS: []	
		// 		}	
		// 	],
		// 	"OUTPUTS": [

		// 	],
		// 	DISPLAY_NAME: "SwitchIf Module",
		// 	PRODUCT_LEVEL: "Standard Plus",
		// 	DESCRIPTION: "This module can change the pipeline architecture based on defined rules in its arguments.\nFor instance, the second successor node may be skipped if ICP (intercranial pressure) is below (Criteria: Direction: less than) a certain threshold of 22 (Criteria: Threshold = 22) for at least 60 consecutive seconds (Criteria: Duration (s) = 60 seconds)."
		// }
}