import { atomFamily } from "recoil";
import { SdDetectionConfig, SdDetectionJSON } from "../Types/SdDetection";
import { EEG_SENSITIVITY } from "../Types/EEG";
import { LayoutWindowId } from "../Types/LayoutWindowId";
import { SD_SENSITIVITY } from "../Types/SpreadingDepolarization";
import { getDefaultAnnotationVisualizationAtom } from "./Visualizations";

export const DEFAULT_SD_DETECTION_JSON: SdDetectionJSON = {
    viewDuration: 60 * 60 * 1000,
    montage: null,
    eegConfig: {
        LFF: 1,
        HFF: 30,
        notch: 60,
        sensitivityMicroVolts: EEG_SENSITIVITY["50 uV"],
    },
    overlayEEGConfig: {
        LFF: 0.005,
        HFF: 0.5,
        notch: 60,
        sensitivityMicroVolts: SD_SENSITIVITY["440 uV"]
    },
    isLinked: false,
    playbackSpeed: 1
}

export const SdDetectionConfigAtom = atomFamily<SdDetectionConfig, LayoutWindowId>({
    key: "SdDetectionConfig",
    default: () => ({
        ...getDefaultAnnotationVisualizationAtom(),
        eegConfig: {
            LFF: 1,
            HFF: 30,
            notch: 60,
            sensitivityMicroVolts: EEG_SENSITIVITY["50 uV"],
        },
        overlayEEGConfig: {
            LFF: 0.005,
            HFF: 0.5,
            notch: 60,
            sensitivityMicroVolts: SD_SENSITIVITY["440 uV"]
        },
        montage: null,
        montageMapping: new Map(),
    })
})