import styled from 'styled-components'

const ProgressBar = ({ currentStep, totalStep }) => {
	// Progress Bar that has the same number of circles as the total number of pages and fill the circles as we traverse through the pages
	const steps = Array(totalStep).fill("");
	return (
		<Bar>
			<CirclesContainer>
				<LineLayer />
				{steps.map((_, index) => (
					<Circle key={index} active={index < currentStep} />
				))}
				<LineFiller currentStep={currentStep} totalStep={totalStep} />
			</CirclesContainer>
		</Bar>
	);
};

export default ProgressBar;

const Bar = styled.div`
	position: relative;
	height: 20px;
	width: 50%;
	margin: 0 auto;
	`;

const CirclesContainer = styled.div`
	margin-top : 10px;
	position: relative;
	top: 0;
	bottom: 0;
	left: -2%;
	right: -2%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	`;

const Circle = styled.span`
	width: 30px;
	height: 30px;
	border-radius: 50%;
	border: 3.5px solid ${({ active }) => (active ? "#207DEA" : "#e0e0de")};
	background-color: ${({ active }) => (active ? "#207DEA" : "#FFFFFF")};
	z-index: 3;
	`;

const LineFiller = styled.span`
	position: absolute;
	top: 6px;
	left: 0;
	width: ${({ currentStep, totalStep }) => ((currentStep - 1) / (totalStep - 1)) * 100}%;
	height: 7px;
	background-color: #207DEA;
	margin-top : 5px;
	z-index : 2;
	`;

const LineLayer = styled.span`
	position: absolute;
	top: 6px;
	left: 0;
	width: 100%;
	height: 7px;
	background-color: #e0e0de;
	margin-top : 5px;
	z-index : 1;
	`;