import { useRef, useState } from "react";
import { useModal, useModalEffect } from "../../Utils/Modal";
import { PopupDiv } from "../../Utils/Styling";
import styled from "styled-components";

export function NameInputPopup (props) {
    const { label, onSubmit=()=>{}, validate=() => true, defaultValue='' } = props
    const { close } = useModal("NameInputPopup")
    const inputRef = useRef()
    const [name, setName] = useState("")

    useModalEffect(isOpen => {
        if (!isOpen && inputRef.current) {
            inputRef.current.value = defaultValue
        }
    })
    const validateAndSubmit = (group_name) => {
        setName("")
        const valid = validate(group_name)
        if (valid instanceof Promise) { // asynchronous
            valid.then(v=>{
                if (v) {
                    close()
                    onSubmit(group_name)
                }
            })
        } else { // synchronous
            if (valid) {
                close()
                onSubmit(group_name)
            }
        }
    }

	const onKeyDown = (e) => {
        e.stopPropagation()
		if (e.keyCode === 13) {validateAndSubmit(e.target.value)}
	}

    return (
    <NameInputDiv>
        {label}
        <input ref={inputRef}
            id = {`input-${label}`}
            placeholder="Default"
            type="text"
            onKeyDown={onKeyDown}
            onChange={(e) => setName(e.target.value)}
            value={name}
        />
        <button onClick={()=>validateAndSubmit(name)}>Create</button>
    </NameInputDiv>)    
}

const NameInputDiv = styled(PopupDiv)`
    width: auto;
    height: auto; 
    display: flex;
    flex-direction: column;

    font-family: 'Source Sans Pro';
	font-style: normal;
    font-size: 16px;
	font-weight: 600;
	line-height: 150%;
    white-space: nowrap;
    input {
		height: 34px;
        margin: 0 -5px 5px -5px;
        border: solid 1px #0017333b;
        border-radius: 6px;
        padding: 0 7px;
    }
    button {
		width: 60px;
		height: 32px;
		background: #207dea;
		border-radius: 6px;
		border: none;
		font-family: 'Source Sans Pro';
		font-style: normal;
		font-weight: 600;
		font-size: 14px;
		line-height: 150%;
		color: #FFFFFF;
		cursor: pointer;
	}
`