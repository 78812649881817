import styled from 'styled-components';
import * as MdIcons from 'react-icons/md';
import { useModalProvider } from "../../../Providers/ModalProvider";
import ModalHeader from "../../../Components/ModalHeader/ModalHeader";
import AccountSettingsModal from '../../../Components/PageHeader/AccountSettingsModal';
import { useState, useEffect } from 'react';
import ConfirmDeleteAccount from './ConfirmDeleteAccount.js';
import { BackButtonDiv, NormalText, ErrorMessage } from '../../../Constants/StyledComponents';
import ModalFooterButton, { buttonColor } from '../../../Components/ModalFooterButton/ModalFooterButton';


const DeleteAccount = (props) => {
    const { openNext, createModal } = useModalProvider()
    const [currentPassword, setCurrentPassword] = useState("")
    const [confirmCurrentPassword, setConfirmCurrentPassword] = useState("")
    const [showPassword, setShowPassword] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [showErrorMessage, setShowErrorMessage] = useState(false)
    const [disableDeleteAccountButton, setDisableDeleteAccountButton] = useState(true)

    useEffect(() => {
        if (!currentPassword && !confirmCurrentPassword) {
            setDisableDeleteAccountButton(true)
            setShowErrorMessage(false)
        } else if (!currentPassword) {
            setDisableDeleteAccountButton(true)
            setErrorMessage("You must enter your password.")
            setShowErrorMessage(true)
        } else if (!confirmCurrentPassword) {
            setDisableDeleteAccountButton(true)
            setErrorMessage("You must confirm your password.")
            setShowErrorMessage(true)
        } else if (currentPassword !== confirmCurrentPassword) {
            setDisableDeleteAccountButton(true)
            setErrorMessage("Passwords do not match.")
            setShowErrorMessage(true)
        } else {
            setShowErrorMessage(false)
            setDisableDeleteAccountButton(false)
        }
    }, [currentPassword, confirmCurrentPassword])

    function renderAccountSettingsModal() {
        openNext(<AccountSettingsModal
            escClose={false}
            clickOutsideClose={false}
        />)
    }

    function renderConfirmDeleteAccountModal() {
        createModal(<ConfirmDeleteAccount
            escClose={false}
            clickOutsideClose={false}
            password={confirmCurrentPassword}
        />)
    }


    return (
        <div style={{background: "#FFFFFF", width: "820px", height: "776px", display: "block", paddingTop: "20px", paddingBottom: "188px"}}>
            <ModalHeader headerText='Account Settings'/>

            <BackButtonDiv onClick={() => {renderAccountSettingsModal()}}>
                <MdIcons.MdOutlineArrowRightAlt size={32} style={{marginRight: "5px", marginTop: "-4px", transform: "rotate(180deg)"}}/>
                <NormalText>Back</NormalText>
            </BackButtonDiv>
            
            <div id="DeleteAccountModalContent" style={{width: "820px", height: "auto", marginTop: "20px", paddingLeft: "250px", paddingRight: "250px", display: "inline-grid"}}>
                <div style={{width: "320px", height: "auto", textAlign: "center", padding: "0px", marginBottom: "25px"}}>
                    <SubheaderText style={{position: "relative"}}>Delete Account</SubheaderText>
                </div>

                <div style={{width: "320px", height: "400px", padding: "0px"}}>
                    <NormalTextBold>Password</NormalTextBold>
                    <ModalTextInput placeholder="Password" onChange={(e) => setCurrentPassword(e.target.value)} type={showPassword ? "text" : ((String(currentPassword).length === 0) ? "text" :"password")} value={currentPassword || ""} onCopy={(e) => e.preventDefault()} onPaste={(e) => e.preventDefault()}/>

                    <NormalTextBold>Confirm Password</NormalTextBold>
                    <ModalTextInput placeholder="Password" onChange={(e) => setConfirmCurrentPassword(e.target.value)} type={showPassword ? "text" : ((String(confirmCurrentPassword).length === 0) ? "text" :"password")} value={confirmCurrentPassword || ""} onCopy={(e) => e.preventDefault()} onPaste={(e) => e.preventDefault()}/>

                    <div style={{marginTop: "-10px", marginBottom: "5px"}}>
                        <input id="showPasswordCheckbox" type="checkbox"
                            onChange={() => {setShowPassword(!showPassword)}} >
                        </input>
                        <label for="showPasswordCheckbox" style={{marginLeft: "4px"}}>Show Password</label>
                    </div>

                    <ModalFooterButton
                        buttonText="Delete Account"
                        disabled={disableDeleteAccountButton}
                        onClickFxn={() => renderConfirmDeleteAccountModal()}
                        color={buttonColor.redFill}

                        buttonStyle={{marginLeft: "70px"}}
                    />

                    <ErrorMessage id="errorMessage" style={{display: showErrorMessage ? "block" : "none", height: "fit-content", marginTop: "30px", textAlign: "center"}}>
                        <p><strong>Warning! </strong>{errorMessage}</p>
                    </ErrorMessage>

                </div>
            </div>
        </div>
    )
}

const SubheaderText = styled.text`
    font-family: 'Montserrat';
    font-style: normal;
    font-size: 16px;
    font-weight: 700;
    color: #293241;
    line-height: 150%;
    position: absolute;
`;

const NormalTextBold = styled.text`
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #293241;
    line-height: 150%;
`;

const ModalTextInput = styled.input`
    background: none;
    border: 1px solid #ABABAB;
    box-sizing: border-box;
    border-radius: 6px;
    width: 304px;
    height: 56px;
    padding: 16px;
	color: #000000;
	margin-top: 5px;
	margin-bottom: 30px;
    ::placeholder {
        font-family: 'Source Sans Pro';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #ABABAB;
    }
    //TO DO: consider making input boxes 320px wide to make everything look more centered
`;

export default DeleteAccount;