import { DataTypeContentContainer } from "../../../../../Constants/StyledComponents"
import { useRef } from "react"
import { useEndpointProvider } from "../../../../../Providers/EndpointProvider"
import { useUploadProcessProvider } from "../../../../../Providers/UploadProcessProvider"
import MUITable from "../../../../../Components/MUITable/MUITable"
import { humanReadableSize } from "../../../../../Computation/utilFunctions"
import MUITabs from "../../../../../Components/MUITabs/MUITabs"
import { useGridApiRef } from "@mui/x-data-grid"
import { Scrollbar } from "../../../../../Constants/StyledComponents"
import { useBackendLinksProvider } from "../../../../../Providers/BackendLinksProvider"

const UploadAccordionFactory = ({ label, filterInput, fileType }) => {
	const dataColumns = [
		{ field: "name", flex: 1, headerName: "Name", visible: true },
		{ field: "upload_time", flex: 1, headerName: "Upload time", visible: true },
		{ field: "size", flex: 1, headerName: "Size", visible: true, valueGetter: params => humanReadableSize(params.row.size) },
	]

	const baseColumns = [
		{ field: "directoryName", flex: 1, headerName: "Name", visible: true, sortable: false },
		{ field: "fileSize", flex: 1, headerName: "Size", visible: true, sortable: false }
	]

	const cnsColumns = { field: "modalities", flex: 1, headerName: "Modalities", visible: true, sortable: false }
	const medicationsColumns = { field: "nMedications", flex: 1, headerName: "Medications", visible: true, sortable: false }

	let uploadColumns = [...baseColumns]

	if (label === "CNS") {
		uploadColumns.push(cnsColumns)
	} else if (label === 'Medications') {
		uploadColumns.push(medicationsColumns)
	}
	
	const endpointProvider = useEndpointProvider()
	const uploadProcessProvider = useUploadProcessProvider()
	const apiRef = useGridApiRef()
	const { LINKS } = useBackendLinksProvider()

	const labelLowerCase = label.split(" ")[0].toLowerCase()
	const setFilenamesToProvider = `set${label}${fileType === "folder" ? "DirectoryNames" : "FileNames"}`
	const existingDataTableData = uploadProcessProvider[`existing${label}DataTableData`]

	const fileOrFolderInput = useRef(null)

	function getDirectoryName(files) {
		if (fileType === "file") return files.name
		const firstItem = files[0]
		if (firstItem.type === "application/x-directory") {
			return getDirectoryName(firstItem.webkitRelativePath.split("/").slice(0, 2).join("/") + "/*")
		}
		return firstItem.webkitRelativePath.split("/")[0]
	}

	function getFilenames(files) {
		const filenames = Object.keys(files).map(i => {
			return files[i].name
		})
		return filenames
	}

	function handleOnClick() {
		if (fileOrFolderInput.current) {
			fileOrFolderInput.current.click()
		}

		uploadProcessProvider.setUploadingType(label)
	}

	async function handleFilesChange(e) {
		await updateInput(e)
		e.target.value = ""
	}

	/**
	 * This function take in the inputted file from user, validate it, and filter it according to the filterInput function
	 * It also set up the data for the table
	 */
	async function updateInput(e) {
		let files = undefined
		let newDirectoryName = {
			name: undefined,
			patient_id: uploadProcessProvider.patientID,
			site_id: uploadProcessProvider.selectedSiteID,
		}
		if (fileType === "folder") {
			files = e.target.files
			newDirectoryName.name = files[0].webkitRelativePath.split("/")[0]
		} else {
			files = e.target.files[0]
			newDirectoryName.name = files.name
		}

		const resetInput = () => {
			fileOrFolderInput.current.value = ""
		}

		if (uploadProcessProvider.existingPatientsCheckedProvider) {
			const body = {
				project_id: uploadProcessProvider.selectedProjectPrimaryKey,
				site_id: uploadProcessProvider.selectedSitePrimaryKey,
				patient_id: uploadProcessProvider.patientPrimaryKey,
			}

			const existingDataObjects = await endpointProvider.post(LINKS.DATA.UPLOAD.GET_PATIENT_DATA_OBJECTS, body)

			if (existingDataObjects.includes(newDirectoryName.name)) {
				alert(`This ${fileType === 'folder' ? 'directory' : 'file'} name has already been uploaded to this patient. Uploading it will override the ${fileType === 'folder' ? 'directory' : 'file'}.`)
				return
			}
		}

		const currentUploadsString = sessionStorage.getItem('currentUploads')
		if (currentUploadsString) {
			const patient_id = uploadProcessProvider.patientID
			const site_id = uploadProcessProvider.selectedSitePrimaryKey
			const project_id = uploadProcessProvider.selectedProjectPrimaryKey
			const currentUploads = JSON.parse(currentUploadsString)

			if (Object.keys(currentUploads).includes(`${site_id}-${project_id}-${patient_id}`)) {
				const currentDataObjects = currentUploads[`${site_id}-${project_id}-${patient_id}`]

				if (currentDataObjects.includes(newDirectoryName.name)) {
					alert(`This ${fileType === 'folder' ? 'directory' : 'file'} name is already in being upload or is in queue for this patient. Uploading it will override the ${fileType === 'folder' ? 'directory' : 'file'}.`)
				}
			}
		}

		if (fileType === "folder") {
			//Example : cns_directory_names in the uploadProcessProvider
			if (
				uploadProcessProvider[`${labelLowerCase}DirectoryNames`].some(
					directory => directory.name === newDirectoryName.name && directory.patient_id === newDirectoryName.patient_id && directory.site_id === newDirectoryName.site_id
				)
			) {
				resetInput()
				alert("This directory name has already been selected.")
				return
			}
		} else {
			//Example : medications_file_names in the uploadProcessProvider
			if (
				uploadProcessProvider[`${labelLowerCase}FileNames`].some(
					directory => directory.name === newDirectoryName.name && directory.patient_id === newDirectoryName.patient_id && directory.site_id === newDirectoryName.site_id
				)
			) {
				resetInput()
				alert("This file name has already been selected.")
				return
			}
		}

		if (!checkDirectoryName(newDirectoryName)) return

		const filenames = getFilenames(files)

		const filteredInputData = await filterInput(filenames, files, newDirectoryName.name, endpointProvider, LINKS)
		if (!filteredInputData) {
			resetInput()
			return
		}
		files = filteredInputData.allFiles
		if (!files) return

		const setInputToProvider = `set${label}${fileType === "folder" ? "Folders" : "Files"}` //Example : setCNSFolders or setMedicationsFiles in the uploadProcessProvider
		uploadProcessProvider[setInputToProvider](prevInput => [...prevInput, files])

		if (fileType === "folder") uploadProcessProvider[setFilenamesToProvider]([...uploadProcessProvider[`${labelLowerCase}DirectoryNames`], newDirectoryName])
		else uploadProcessProvider[setFilenamesToProvider]([...uploadProcessProvider[`${labelLowerCase}FileNames`], newDirectoryName])

		let size = parseFloat(Object.keys(files).reduce((partialSum, i) => partialSum + files[i].size, 0))
		if (fileType === "file") size = files.size
		let directorySize = humanReadableSize(size)

		let directoryName = getDirectoryName(files)
		let newFilesTableData = {
			directoryName: directoryName,
			fileSize: directorySize,
			...(filteredInputData.nmodalities !== undefined && { modalities: filteredInputData.nmodalities }), // In case this doesnt exist
			...(filteredInputData.directoryType !== undefined && { directoryType: filteredInputData.directoryType }), // In case this doesnt exist
			...(filteredInputData.nMedications !== undefined && { nMedications: filteredInputData.nMedications }), // In case this doesnt exist
		}

		const setTableData = `set${label}TableData` //Example : setCNSTableData or setMedicationsTableData in the uploadProcessProvider
		const tableDataProvider = `${labelLowerCase}TableData`
		uploadProcessProvider[setTableData]([...uploadProcessProvider[tableDataProvider], newFilesTableData])
	}

	function checkDirectoryName(directoryName) {
		if (!existingDataTableData) return true
		for (const data of existingDataTableData) {
			if (data.name === directoryName.name) {
				alert("This directory has already been uploaded.")
				return false
			}
		}
		return true
	}

	function deleteFolderByName(folderName) {
		uploadProcessProvider[`set${label}Folders`](prev =>
			prev.map(folderGroup => {
				return folderGroup.filter(folder => {
					const name = folder.webkitRelativePath.split("/")[0]
					return name !== folderName
				})
			})
		)
	}

	function deleteFileByName(fileName) {
		uploadProcessProvider[`set${label}Files`](prev => {
			return prev.filter(file => {
				const name = file.name
				return name !== fileName
			})
		})
	}

	/**
	 * This function handle the removal of selected files and folders across accordions
	 */
	function handleRemoveUploadedData() {
		const selectedRows = structuredClone(apiRef?.current?.getSelectedRows())

		selectedRows.forEach((fileToDelete, index) => {
			uploadProcessProvider[`set${label}TableData`](prevState => prevState.filter(item => item.directoryName !== fileToDelete.directoryName))
			if (fileType === "folder") {
				deleteFolderByName(fileToDelete.directoryName)
				uploadProcessProvider[`set${label}DirectoryNames`](prevState => prevState.filter(item => item.name !== fileToDelete.directoryName))
			} else {
				deleteFileByName(fileToDelete.directoryName)
				uploadProcessProvider[`set${label}FileNames`](prevState => prevState.filter(item => item.name !== fileToDelete.directoryName))
			}
		})
		apiRef?.current?.setRowSelectionModel([])
	}

	const dataQueryProps = {
		queryKey: label,
		endpoint: LINKS.DATA.UPLOAD.GET_EXISTING_DATA_FOR_DATATYPE,
		body: {
			project_id: uploadProcessProvider.selectedProjectPrimaryKey,
			site_id: uploadProcessProvider.selectedSitePrimaryKey,
			patient_id: uploadProcessProvider.patientPrimaryKey,
			data_type: label,
		},
	}

	const uploadTableToolbarProps = {
		createButton: { title: label === 'CNS' ? "+ Select Moberg CNS data" : "+ Select medication data", isShow: true, onClick: handleOnClick, fileType: fileType, label: label, onChange: handleFilesChange, inputRef: fileOrFolderInput },
		deleteButton: { isShow: true, onClick: handleRemoveUploadedData, disableSuccessToast: true, disableConfirmation: true },
		filterButton: { isShow: false },
		exportButton: { isShow: false },
		searchInput: { isShow: false },
		columnButton: { isShow: false },
		nameForMultipleRows: "directories"
	}

	return (
		<DataTypeContentContainer>
			<Scrollbar style={{ height: "380px", overflowY: "auto" }}>
				<MUITabs
					tabs={
						uploadProcessProvider.existingPatientsCheckedProvider
							? [
								{
									name: 'New',
									content: (
										<>
											<MUITable tableRef={apiRef} columns={uploadColumns} tableData={uploadProcessProvider[`${label.toLowerCase()}TableData`]} tableToolbarProps={uploadTableToolbarProps} />
										</>
									)
								},
								{
									name: 'Existing',
									content: (
										<>
											<MUITable tableRef={apiRef} columns={dataColumns} {...dataQueryProps} isCheckboxSelection={false} />
										</>
									)
								}
							]
							: [
								{
									name: 'New',
									content: (
										<>
											<MUITable tableRef={apiRef} columns={uploadColumns} tableData={uploadProcessProvider[`${label.toLowerCase()}TableData`]} tableToolbarProps={uploadTableToolbarProps} />
										</>
									)
								}
							]
					}
				/>
			</Scrollbar>
		</DataTypeContentContainer>
	)
}

export default UploadAccordionFactory
