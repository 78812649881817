/**
 * 
 */
export function debounce(func, timeout = 300){
    let timer
    return (...args) => {
        clearTimeout(timer)
        timer = setTimeout(() => { func.apply(this, args); }, timeout)
    };
}


// run func, then prevent it from running again within timeout
// returns throttled function
export function throttle(func, timeout = 300) {
    let throttled
    return function (...args) {
        if (throttled) return
        throttled = true
        func.apply(this, args)
        setTimeout(() => { throttled = false }, timeout)
    }
}


// combines behavior of throttle and debounce
export function throttleDebounce(func, timeout = 300) {
    let timer
    let throttled
    return (...args) => {
        clearTimeout(timer)
        timer = setTimeout(() => { func.apply(this, args); }, timeout)
        if (throttled) return
        throttled = true
        func.apply(this, args)
        setTimeout(() => { throttled = false }, timeout)
    }
}