import { useState, useEffect, useCallback, useRef } from 'react';
import styled from 'styled-components'
import Popover from 'react-bootstrap/Popover';
import * as MdIcons from 'react-icons/md'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { MainTable } from "../../../../Components/DataTable/Table";
import { ModalController } from "../../../../Controllers/ModalController";
import { useStudiesProvider } from '../../../../Providers/StudiesProvider';
import { patientUpdates, usePatientsProvider } from '../../../../Providers/PatientsProvider';
import { patientGroupsUpdates } from '../../../../Providers/PatientGroupsProvider';
import ModalFooterButton, { buttonColor } from '../../../../Components/ModalFooterButton/ModalFooterButton';

export default function RunReportsModal({ props, openRef }){
    const studiesProvider = useStudiesProvider()
    const patientsProvider = usePatientsProvider()
    const patientGroupsProvider = usePatientsProvider()

    const contentRef = useRef()
    const closeButtonRef = useRef()
    const [dataProfilingTableData, setDataProfilingTableData] = useState([])
	const [dataProfilingTableColumns, setDataProfilingTableColumns] = useState([])
    const [dataGroupTableData, setDataGroupTableData] = useState([])
	const [dataGroupTableColumns, setDataGroupTableColumns] = useState([])
    const [patientSearchQuery, setPatientSearchQuery] = useState("");
    const [groupSearchQuery, setGroupSearchQuery] = useState("");
	const [siteSearchQuery, setSiteSearchQuery] = useState("");
	const [doubleSearchQuery, setDoubleSearchQuery] = useState(false);
    const [currentUser, setCurrentUser] = useState("")
    const [patientsPreselect, setPatientsPreselect] = useState([])
	const [patientPending, setPatientPending] = useState([])
    const [groupsPreselect, setGroupsPreselect] = useState([])
	const [groupsPending, setGroupPending] = useState([])
	const [currentSelectedRowGroupName, setCurrentSelectedRowGroupName] = useState("")

    function getPopoverTop(labelname) {
        return (
            <Popover id="Popover-trigger-hover-focus" style={{width: "228px"}} positionleft={75}>
                <p style={{fontFamily: "Source Sans Pro", fontStyle: "normal", fontWeight: "400", fontSize: "12px", color: "#5F6775", paddingLeft: "3px"}}>{popoverTopText[labelname]}</p>
            </Popover>
        )
    }
    
    const popoverTopText = {
        'report': 'The name of the report that is being created by the user.',
        'reportPatients': 'The information of available patients which can be used to assign report to',
        'reportPatientGroups': 'The information of available patient groups which can be used to assign report to'
    }

    const showMore = useCallback(()=>(
		<MdIcons.MdExpandMore
			size={20}
			style={{
				marginLeft: "10px",
				marginRight: "-20px",
				color: "#B6B6B6",
			}}
		/>
	),[]);
	const showLess = (
		<MdIcons.MdExpandLess
			size={20}
			style={{
				marginLeft: "10px",
				marginRight: "-20px",
				color: "#B6B6B6",
			}}
		/>
	);

	const [groupsUpdate, setGroupsUpdate] = useState([])
	function updateGroups() {
		 setGroupsUpdate([])
	}

	const [patientsUpdate, setPatientsUpdate] = useState([])
	function updatePatients() {
		setPatientsUpdate([])
	}

	// get Patients data 
    useEffect(() => {
		if (studiesProvider.selectedStudyId === undefined || studiesProvider.selectedStudyId === '') return

		let columns1labels = {
			site_id: { label: "Site ID", type: "string" },
			patient_id: { label: "Patient ID", type: "string" },
		}

		let columnData = []

		for (let elem of Object.keys(columns1labels)) {
			columnData.push({
				name: columns1labels[elem]['label'],
				selector: elem,
				sortable: true,
                width: columns1labels[elem]['width']
			})
		}
		setDataProfilingTableColumns(columnData)
		setPatientPending(true)

        let currentSelectedRows = []
        if (selectedPatientsTableRows) {
            currentSelectedRows = selectedPatientsTableRows.map(row => `${row.site_id}/${row.patient_id}`)
        }

        let tableData = patientsProvider.patients?.map(row => {
            let entry = {}
                for (let column of Object.keys(columns1labels)) {
                    if (columns1labels[column]["type"] === 'string') {
                        if (row[column] === null) {
                            entry[column] = ""
                        } else {
                            entry[column] = `${row[column]}`
                        }
                    }
                }
                entry.selected = patientsPreselect.includes(`${row.site_id}/${row.patient_id}`) || currentSelectedRows.includes(`${row.site_id}/${row.patient_id}`)
                return entry
        })

        console.log('tableData:', tableData)
    
        tableData = tableData.filter((val) => {
            if (patientsPreselect.includes(`${val.site_id}/${val.patient_id}`) || currentSelectedRows.includes(`${val.site_id}/${val.patient_id}`)) {
                return val
            }
            if (doubleSearchQuery) {
                if (patientSearchQuery === "" && val.site_id.includes(siteSearchQuery)) {
                    return val
                } else if (siteSearchQuery === "" && val.patient_id.includes(patientSearchQuery)) {
                    return val
                } else if (val.site_id === siteSearchQuery && val.patient_id.startsWith(patientSearchQuery)) {
                    return val
                }  else {
                    return false
                }
            } else {
                if (patientSearchQuery === "" && siteSearchQuery === "") {
                    return val
                } else if (val.patient_id.includes(patientSearchQuery)) {
                    return val
                } else if (val.site_id.includes(siteSearchQuery)) {
                    return val
                } else {
                    return false
                } 
            }
        })

        setDataProfilingTableData(tableData)

        setPatientPending(false)

	}, [studiesProvider.selectedStudyId, patientsPreselect, doubleSearchQuery, patientSearchQuery, siteSearchQuery, patientsProvider.patients])

	// get Patient Group data 
    useEffect(() => {
		if (studiesProvider.selectedStudyId === undefined || studiesProvider.selectedStudyId === '') return

		let columns2labels = {
			site_id: { label: "Site ID", type: "string" },
			name: { label: "Patient Group", type: "string" },
		}

		let columnData = []

		for (let elem of Object.keys(columns2labels)) {
			columnData.push({
				name: columns2labels[elem]['label'],
				selector: elem,
				sortable: true,
                width: columns2labels[elem]['width']
			})
		}
		setDataGroupTableColumns(columnData)
		setGroupPending(true)

        let currentSelectedRows = []
        if (selectedGroupRows) {
            currentSelectedRows = selectedGroupRows.map(row => `${row.site_id}/${row.patient_id}`)
        }

        let tableData = patientGroupsProvider.patientGroups.map(row => {
            let entry = {}
                for (let column of Object.keys(columns2labels)) {
                    if (columns2labels[column]["type"] === 'string') {
                        if (row[column] === null) {
                            entry[column] = ""
                        } else {
                            entry[column] = `${row[column]}`
                        }
                    }
                }
                entry.selected = groupsPreselect.includes(`${row.site_id}/${row.name}`) || currentSelectedRows.includes(`${row.site_id}/${row.name}`)
                return entry
        })
    
        console.log('tableData:', tableData)

        tableData = tableData.filter((val) => {
            if (groupsPreselect.includes(`${val.site_id}/${val.name}`) || currentSelectedRows.includes(`${val.site_id}/${val.name}`)) {
                return val
            }
            if (doubleSearchQuery) {
                if (groupSearchQuery === "" && val.site_id.includes(siteSearchQuery)) {
                    return val
                } else if (siteSearchQuery === "" && val.name.includes(groupSearchQuery)) {
                    return val
                } else if (val.site_id === siteSearchQuery && val.patient_id.startsWith(groupSearchQuery)) {
                    return val
                }  else {
                    return false
                }
            } else {
                if (groupSearchQuery === "" && siteSearchQuery === "") {
                    return val
                } else if (val.patient_id.includes(groupSearchQuery)) {
                    return val
                } else if (val.site_id.includes(siteSearchQuery)) {
                    return val
                } else {
                    return false
                } 
            }
        })

        setDataGroupTableData(tableData)
        
        if (tableData.length || !patientGroupsProvider.patientGroups.length) {
            setGroupPending(false)
        }

	}, [studiesProvider.selectedStudyId, groupsPreselect, doubleSearchQuery, groupSearchQuery, siteSearchQuery, patientGroupsProvider.patientGroups])

	const [toggleGroupTableCleared, setToggleGroupTableCleared] = useState(false)
    const [togglePatientsCleared, setTogglePatientsCleared] = useState(false)
    const [selectedGroupRows, setSelectedGroupRows] = useState(false)
    const [selectedPatientsTableRows, setSelectedPatientsTableRows] = useState(false)

	function updateGroupRows({selectedRows}) {
		setSelectedGroupRows(selectedRows)
	}

    function updatePatientsTableRows({selectedRows}) {
        setSelectedPatientsTableRows(selectedRows)
 }
       
    
    function setSearchQueryStates(doubleSearchBoolean, siteSearch, patientSearch) {
		setDoubleSearchQuery(doubleSearchBoolean)
		setSiteSearchQuery(siteSearch)
		setPatientSearchQuery(patientSearch)
	}

	function handlePatientSearch(event) {
		if (event.target.value === undefined || event.target.value === null) return
		if (event.target.value.includes("/")) {
			// if double search is true, set site search value to the left of the "/" and patient search value to the right of the "/"
			setSearchQueryStates(true, event.target.value.split("/")[0], event.target.value.split("/")[1])
		} else {
			setSearchQueryStates(false, event.target.value, event.target.value)
		}
	}

    const [createGroupPatientsIcon, setCreateGroupPatientsIcon] = useState(showMore);
    function renderPatientsTable() {
		if (document.getElementById("PatientsTable").style.display !== "none") {
			document.getElementById("PatientsTable").style.display = "none";
			setCreateGroupPatientsIcon(showMore);
		} else {
			document.getElementById("PatientsTable").style.display = "block";
			setCreateGroupPatientsIcon(showLess);
		}
	}

    function renderGroupsTable() {
		if (document.getElementById("GroupPatientsTable").style.display !== "none") {
			document.getElementById("GroupPatientsTable").style.display = "none";
			setCreateGroupPatientsIcon(showMore);
		} else {
			document.getElementById("GroupPatientsTable").style.display = "block";
			setCreateGroupPatientsIcon(showLess);
		}
	}

        return(<>
            <RunReportsModalContainer
            //id="runReportButtonModal"
            ref={contentRef}
            style={{display: "none"}}>
            <h3>Run Report</h3>
            <hr
                style={{
                    border: "1px solid #B3B3B3",
                    width: "820px",
                }}
            />
                <h2>Report Details</h2>
                <Scrollbar
                style={{
                    paddingLeft: "32px",
                    paddingRight: "32px",
                    maxHeight: "615px",
                    overflowY: "scroll",
                    overflowX: "hidden",
                }}
            >
                <div style={{display: 'flex', alignItems: 'center', marginRight: '-16px'}}>
                    <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={getPopoverTop('report')}> 
                    <InfoButton style={{}}><MdIcons.MdInfoOutline size={18} style={{float: "right"}}/></InfoButton>
                    </OverlayTrigger>
                    <h1 style={{ marginTop: "-7px"}}>Report Name <span style={{color:"red"}}>*</span></h1>
                </div>
                <ReportNameInput id={'ReportNameInput'} placeholder="Enter a name for report" />
                <div style={{display: 'flex'}}>
                    <h1 style={{marginTop: "16px", marginBottom: "10px"}}>
                    <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={getPopoverTop('reportPatients')}> 
                    <InfoButton><MdIcons.MdInfoOutline size={18} style={{float: "right", marginBottom: "-3px"}}/></InfoButton>
                    </OverlayTrigger>
                    <ModalDropdownButton onClick={renderPatientsTable}>Patients{}</ModalDropdownButton>
                    </h1>
                </div>

                <ModalDropdownTableContainer
                    id="PatientsTable"
                    style={{display: "none"}}
                >

                <ModalSearchBar className="ui left icon input" style={{flex: "1"}}>
                    <input id="PaitentSearch" type="text" onChange={handlePatientSearch} placeholder="Search"/> <i aria-hidden="true" class="search icon"/>
                </ModalSearchBar>

                <MainTable
                    columns={dataProfilingTableColumns}
                    data={dataProfilingTableData}
                    progressPending={patientPending}
                    selectableRows
                    onSelectedRowsChange={updatePatientsTableRows}
                    selectableRowSelected={rowSelectCriteria}
                    clearSelectedRows={togglePatientsCleared}
                /> 
                </ModalDropdownTableContainer>
                <div style={{display: 'flex'}}>
                    <h1 style={{marginTop: "16px", marginBottom: "10px"}}>
                    <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={getPopoverTop('reportPatientGroups')}> 
                    <InfoButton><MdIcons.MdInfoOutline size={18} style={{float: "right", marginBottom: "-3px"}}/></InfoButton>
                    </OverlayTrigger>
                    <ModalDropdownButton onClick={renderGroupsTable}>Patient Groups{}</ModalDropdownButton>
                    </h1>
                </div>

                <ModalDropdownTableContainer
                    id="GroupPatientsTable"
                    style={{display: "none"}}
                >

                <ModalSearchBar className="ui left icon input" style={{flex: "1"}}>
                    <input id="GroupSearch" type="text" onChange={handlePatientSearch} placeholder="Search"/> <i aria-hidden="true" class="search icon"/>
                </ModalSearchBar>

                <MainTable
                    columns={dataGroupTableColumns}
                    data={dataGroupTableData}
                    progressPending={groupsPending}
                    selectableRows
                    onSelectedRowsChange={updatePatientsTableRows}
                    selectableRowSelected={rowSelectCriteria}
                    clearSelectedRows={togglePatientsCleared}
                />
                </ModalDropdownTableContainer>
            </Scrollbar>
            <hr
                style={{
                    border: "1px solid #B3B3B3",
                    width: "724px",
                    marginLeft: "auto",
                    marginRight: "auto",
                }}
            />
            <div
                style={{
                    paddingLeft: "35px",
                    paddingBottom: "15px",
                }}
            >
                <ModalFooterButton
                    buttonText="Run"
                    color={buttonColor.blueFill}
                    buttonStyle={{marginRight: "5px", width: "114px"}}
                    onClickFxn={undefined}
                />
                <ModalFooterButton
                    buttonText="Cancel"
                    ref={closeButtonRef}
                    color={buttonColor.blueOutline}
                    buttonStyle={{marginRight: "5px", width: "114px"}}
                />
            </div>
        </RunReportsModalContainer>

    <ModalController
            openRef={openRef}
            contentRef={contentRef}
            closeRef={closeButtonRef} 
            closeOnEscape={true}
            onToggle={info => {
                if (info.isOpen) {
                    // Update providers when open
                    patientsProvider.update(patientUpdates.PATIENTS_FILTERS)
                    patientGroupsProvider.update(patientGroupsUpdates.PATIENT_GROUPS_FILTERS)
                }
            }}>
     </ModalController>

</>)}

const rowSelectCriteria = (row) => {
	return row.selected;
};

const ModalDropdownTableContainer = styled.div`
	border: 2px solid #cccccc;
	box-sizing: border-box;
	border-radius: 5px;
	background: #ffffff;
	width: 97%;
	height: auto;
	padding: 10px;
`;

const InfoButton = styled.button`
    border: none;
    background: none;
    border: none;
    color: #207dea;
    cursor: pointer;
    margin-top: 18px;
    width: fit-content;
    margin-right: 5px;
    margin-top: 0px;
    margin-bottom: 7px;
`;
const ModalDropdownButton = styled.button`
	cursor: pointer;
	display: inline;
	flex-direction: row;
	border: solid 0.5px #FFFFFF;
	background-color: #FFFFFF;
	font-family: "Source Sans Pro";
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 150%;
	color: #293241;
`;

const ModalSearchBar = styled.div`
	width: 60%;
	height: 30px;
	background: #FFFFFF;
	border-radius: 6;
	box-sizing: border-box;
	overflow-y: hidden;
	overflow-x: hidden;
	margin-bottom: 10px;
`;

const Scrollbar = styled.div`
	height: auto;
	max-height: 615px;
	scrollbar-width: thin;
	::-webkit-scrollbar {
		display: block;
		width: 5px;
		color: #313a4a;
	}
	::-webkit-scrollbar-track {
		background: #bec4cf;
		width: 5px;
		border-radius: 2px;
	}
`;

const RunReportsModalContainer = styled.div`
	h3 {
		font-family: "Montserrat";
		font-style: normal;
		font-weight: 700;
		font-size: 20px;
		line-height: 150%;
		color: #293241;
		text-align: center;
		margin-top: 8px;
		margin-bottom: -4px;
	}
	h2 {
		font-family: "Source Sans Pro";
		font-style: normal;
		font-weight: 700;
		font-size: 21px;
		line-height: 150%;
		color: #293241;
        margin-left: 30px;
		margin-top: 16px;
        margin-bottom: 10px;
	}
	h1 {
		font-family: "Source Sans Pro";
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 150%;
        text-align: center;
		color: #293241;
	}
	textarea {
		background: #ffffff;
		border: 1px solid #ababab;
		box-sizing: border-box;
		border-radius: 6px;
		padding: 16px;
		width: 756px;
		height: 100px;
		margin-top: -5px;
		::placeholder {
			font-family: "Source Sans Pro";
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 150%;
			color: #9f9f9f;
			margin-top: -200px !important;
		}
	}
`;



const ReportNameInput = styled.input`
	background:  ${({ disabled }) => (disabled ? "#F2F2F2" : "#ffffff")};
	border: 2px solid #cccccc;
	box-sizing: border-box;
	border-radius: 5px;
	width: 464px;
	height: 56px;
	padding: 16px;
	margin-top: -5px;
	margin-bottom: 20px;
    margin-right: 16px;
	color: ${({ disabled }) => (disabled ? "#737373": "#000000")};
	::placeholder {
		font-family: "Source Sans Pro";
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 150%;
		color: #9f9f9f;
	}
`;
