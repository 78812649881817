import React, { CSSProperties, useState } from "react"
import { activeAnnotationSessionAtom, annotationGroupsAtom } from "../../../Atoms/Annotations"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { useAnnotationService } from "../../../../../../../Hooks/useAnnotationService"
import { MdCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md"
import { useModalProvider } from "../../../../../../../Providers/ModalProvider"
import { Scrollbar } from "../../../../../../../Constants/StyledComponents"
import { ModalTitleAndCloseButton } from "../ModalTitleAndCloseButton"
import { DefaultAnnotationSessionSelector, SessionSelectorChangeEvent } from "./DefaultAnnotationGroupSelector"
import { AnnotationGroup } from "../../../Types/Annotations"
import { MobergIconSize } from "../../../../../../../Components/MobergIcon/MobergIcon"
import { MobergButton, MobergButtonShape, MobergButtonVariant } from "../../../../../../../Components/MobergButton/MobergButton"
import { MobergTheme } from "../../../../../../../Components/MobergThemes/MobergColors"
import { MobergFontSize } from "../../../../../../../Components/MobergFont/MobergFont"
import { currentPatientFileInfoAtom } from "../../../Atoms/PatientFile"
import { DataSource } from "../../../Types/DataSource"

type AnnotationGroupLoaderProps = {
	style?: CSSProperties
}

export const AnnotationGroupLoader = (props: AnnotationGroupLoaderProps) => {
	const { close } = useModalProvider()
	const { createAnnotationGroup, loadAnnotationGroups, getAnnotationGroups } = useAnnotationService()
	const { dataSourceMap } = useRecoilValue(currentPatientFileInfoAtom)
	const setActiveAnnotationSession = useSetRecoilState(activeAnnotationSessionAtom)
	const [validationError, setValidationError] = useState<string>()
	const annotationSessions = useRecoilValue(annotationGroupsAtom)
	const [selectedAnnotationGroupIds, setSelectedAnnotationGroupIds] = useState(new Set<string>())
	const [isFetching, setIsFetching] = useState(false)
	const [newSessionName, setNewSessionName] = useState("")
	const [initialAnnotationSession, setInitialAnnotationSession] = useState<AnnotationGroup>()
	const numAnnotationsToLoad =
		[...selectedAnnotationGroupIds].map(id => annotationSessions.find(g => g.id.toString() === id.toString())?.nannotations ?? 0).reduce((a, b) => a + b, 0) +
		(initialAnnotationSession && !selectedAnnotationGroupIds.has(initialAnnotationSession.id.toString()) ? initialAnnotationSession.nannotations : 0)

	const isLoadButtonDisabled = isFetching

	const toggleAnnotationGroup = (annotationGroupId: string | number) => {
		setSelectedAnnotationGroupIds(set => {
			const copy = new Set(set)

			if (copy.has(annotationGroupId.toString())) {
				copy.delete(annotationGroupId.toString())
			} else {
				copy.add(annotationGroupId.toString())
			}

			return copy
		})
	}

	const formatAnnotationLabel = (numAnnotations: number) => {
		return numAnnotations === 1 ? "annotation" : "annotations"
	}

	const formIsValid = () => {
		// The session already exists. No validation needed.
		if (initialAnnotationSession) {
			return true
		}

		let validationError = undefined

		const cleaned = newSessionName.trim()

		if (!cleaned || cleaned === "") {
			validationError = "Session name cannot be empty"
		}

		// This is dumb because there's no reason why two annotation sessions can't have the same name.
		// We're handling it because the backend throws an error and it's nicer if we handle it on the frontend
		// instead of just alerting the error message.
		const foundSameName = annotationSessions.find(session => session.group_name === newSessionName)

		if (foundSameName) {
			validationError = "Session already exists with the same name."
		}

		if (validationError) {
			setValidationError(validationError)
			return false
		}

		return true
	}

	const handleLoad = async () => {
		if (!formIsValid()) {
			return
		}

		setIsFetching(true)

		let annotationGroupId: string | undefined = undefined

		if (!initialAnnotationSession) {
			const annotationGroup = await createAnnotationGroup(newSessionName, dataSourceMap.get(DataSource.CURRENT_PATIENT) as number)

			if (!annotationGroup) {
				return
			}

			annotationGroupId = annotationGroup.id.toString()
		}

		const idsToLoad = [...selectedAnnotationGroupIds]

		if (initialAnnotationSession) {
			idsToLoad.push(initialAnnotationSession.id.toString())
		}

		loadAnnotationGroups(idsToLoad)
			.then(() => {
				if (initialAnnotationSession) {
					setActiveAnnotationSession(initialAnnotationSession)
				} else {
					getAnnotationGroups().then((groups: AnnotationGroup[]) => {
						const foundAnnotationSession = groups.find(session => session.id.toString() === annotationGroupId)
						if (foundAnnotationSession) {
							setActiveAnnotationSession(foundAnnotationSession)
						}
					})
				}
			})
			.then(close)
			.catch(err => alert(err))
			.finally(() => setIsFetching(false))
	}

	const handleAnnotationSessionChanged = (event: SessionSelectorChangeEvent) => {
		if (validationError) {
			setValidationError(undefined)
		}

		switch (event.type) {
			case "create":
				setInitialAnnotationSession(undefined)
				setNewSessionName(event.sessionName)
				break
			case "existing":
				setInitialAnnotationSession(annotationSessions.find(session => session.id.toString() === event.sessionId.toString()))
				break
		}
	}

	const handleToggleAnnotationGroup = (group: AnnotationGroup) => {
		if (initialAnnotationSession && group.id.toString() === initialAnnotationSession.id.toString()) {
			return
		}

		toggleAnnotationGroup(group.id)
	}

	const getCheckboxColor = (group: AnnotationGroup) => {
		if (group.id.toString() === initialAnnotationSession?.id.toString()) {
			return "gray"
		}

		return "#207dea"
	}

	const getTextColor = (group: AnnotationGroup) => {
		if (group.id.toString() === initialAnnotationSession?.id.toString()) {
			return "gray"
		}

		return "#222"
	}

	const getCursor = (group: AnnotationGroup) => {
		if (group.id.toString() === initialAnnotationSession?.id.toString()) {
			return "default"
		}

		return "pointer"
	}

	return (
		<div style={{ display: "flex", flexDirection: "column", padding: "5px", height: "100%", position: "relative", minWidth: "550px" }}>
			<ModalTitleAndCloseButton title={"Annotation Setup"} closeFunction={close} />

			<div style={{ flex: 1, display: "flex", flexDirection: "column", padding: "20px", overflowY: "scroll", minHeight: 0 }}>

				<DefaultAnnotationSessionSelector onChange={handleAnnotationSessionChanged} annotationSessions={annotationSessions} validationError={validationError} />

				<div style={{ fontWeight: "bold", padding: "10px 0" }}> Load annotations from other sessions: </div>
				
				<Scrollbar style={{ flex: 1, overflowY: "scroll", minHeight: 0 }}>
					<div style={{ padding: "10px", paddingTop: 0 }}>
						{annotationSessions.map(group => {
							return (
								<div
									key={group.id.toString()}
									style={{ display: "flex", alignItems: "center", gap: "8px", padding: "10px 0", fontSize: "16px", cursor: getCursor(group), borderBottom: "1px solid #dedede" }}
									onClick={() => handleToggleAnnotationGroup(group)}
								>
									<div>
										{selectedAnnotationGroupIds.has(group.id.toString()) || initialAnnotationSession?.id.toString() === group.id.toString() ? (
											<MdCheckBox color={getCheckboxColor(group)} size={MobergIconSize.REGULAR} />
										) : (
											<MdCheckBoxOutlineBlank size={MobergIconSize.REGULAR} />
										)}
									</div>

									<span style={{ cursor: getCursor(group), color: getTextColor(group) }}>
										{group.group_name} ({group.nannotations} {formatAnnotationLabel(group.nannotations)})
									</span>
								</div>
							)
						})}
					</div>
				</Scrollbar>
			</div>

			<div style={{ flex: 0, display: "flex", justifyContent: "space-between", alignItems: "center", gap: "20px", padding: "10px 20px" }}>
				<div>
					{!isLoadButtonDisabled && (
						<div>
							This will load <span style={{ fontWeight: "bold" }}> {numAnnotationsToLoad}</span> {formatAnnotationLabel(numAnnotationsToLoad)}
						</div>
					)}
				</div>

				<div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
					{isFetching && (
						<div>Please wait...</div>
					)}

					<MobergButton 
						onClick={handleLoad} 
						disabled={isLoadButtonDisabled} 
						theme={MobergTheme.BLUE} 
						variant={MobergButtonVariant.FILLED} 
						fontSize={MobergFontSize.LARGE}
						shape={MobergButtonShape.WIDE}>
						{!initialAnnotationSession && !isLoadButtonDisabled ? "Create" : "Load"}
					</MobergButton>
				</div>

			</div>
		</div>
	)
}
