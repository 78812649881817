import { useState, useCallback, useRef, useEffect, RefObject, SetStateAction, Dispatch } from "react"
import Popper, { PopperPlacementType } from "@mui/material/Popper"
import { MobergAnimationCurve, MobergAnimationTiming } from "../Components/MobergAnimation/MobergAnimation"
import React from "react"
import { MobergBoxShadow } from "../Components/MobergThemes/MobergStyles"
import { CSSProperties } from "styled-components"
import { useOnMount } from "./useOnMount"
import { Modifier } from "@popperjs/core"

type usePopoverProps = {
	placement: PopperPlacementType
	anchorRef?: RefObject<any>
	clickOutsideClose?: boolean
	zIndex?: number

	// For unknown reasons, the menu will sometimes appear invisible because of render timing  and state change problems.
	// This fixes the "invisible menu" bug.
	tryToKeepMenuOpen?: boolean
}

type PopoverComponentProps = {
	style?: CSSProperties
}

export type MobergMenuController = {
	isOpen: boolean
	open: (event: React.MouseEvent<HTMLButtonElement>) => void
	close: () => void
	setClickOutsideClose: Dispatch<SetStateAction<boolean>>
	MobergMenu: React.FC<PopoverComponentProps>
}

export const useMobergMenu = ({ placement, anchorRef, zIndex, tryToKeepMenuOpen=false }: usePopoverProps) => {
	const [isOpen, setIsOpen] = useState(false) 
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(anchorRef?.current ? anchorRef.current : null)
	const popoverRef = useRef<HTMLDivElement>(null)
	const mouseDownTarget = useRef<any>(null)
	const isMounted = useRef<boolean>()
	const [clickOutsideClose, setClickOutsideClose] = useState<boolean>(true)

	const close = useCallback(async () => {
		const popover = popoverRef.current

		if (!popover) {
			return
		}

		popover.style.transition = `all ${MobergAnimationTiming.FAST} ${MobergAnimationCurve.EASE_OUT}`
		popover.style.opacity = "0"
		popover.style.transform = "scale(0.9)"

		await new Promise<void>(resolve => {
			popover.ontransitionend = () => {
				// Prevent this from being called more than once.
				popover.ontransitionend = null

				// By waiting a little bit after the animation to finishes, we can make sure the animation is smooth.
				setTimeout(() => {
					if (isMounted.current) {
						setIsOpen(false)
					}
					setTimeout(resolve) // wait for the Recoil State to update
				})
			}
		})
	}, [])

	const open = useCallback(
		(event?: React.MouseEvent<HTMLButtonElement>) => {
			if (isOpen) {
				close()
				return
			}

			setIsOpen(previous => {
				const newOpenState = !previous

				if (newOpenState) {
					setTimeout(() => {
						const popover = popoverRef.current

						if (popover) {
							popover.style.transition = `all ${MobergAnimationTiming.FAST} ${MobergAnimationCurve.EASE_IN}`
							popover.style.opacity = "1"
							popover.style.transform = "scale(1)"
						}
					})
				}

				return newOpenState
			})

			if (!anchorRef?.current) {
				setAnchorEl(event?.currentTarget ?? null)
			} else {
				setAnchorEl(anchorRef.current)
			}
		}, [anchorRef, close, isOpen])

	const MobergMenu: React.FC<PopoverComponentProps> = ({ children, style }) => {
		const getOrigin = (placement: string): string => {
			switch (placement) {
				case "top-start": return "bottom left"
				case "top-end": return "bottom right"
				case "top": return "bottom"
				case "left": return "center right"
				case "left-end": return "bottom right"
				case "left-start": return "top right"
				case "right": return "center left"
				case "right-end": return "bottom left"
				case "right-start": return "top left"
				case "bottom-end": return "top right"
				case "bottom": return "top"
				case "bottom-start": return "top left"
				default:
					return "bottom"
			}
		}

		const flipAnimationCorrection: Modifier<"flipAnimationCorrection", {}> = {
			name: "flipAnimationCorrection",
			enabled: true,
			phase: "beforeWrite",
			fn: ({ state }) => {
				if (state.placement !== state.options.placement) {
					// This means that the popper is flipped and we have to update the origin for the animation to look correct.
					if (popoverRef.current) {
						popoverRef.current.style.transformOrigin = getOrigin(state.placement)
					}
				}
			},
		}

		return (
			<Popper
				open={isOpen}
				placement={placement}
				anchorEl={anchorEl}
				style={{ zIndex: zIndex ?? 10000 }}
				modifiers={[
					{
						name: "flip",
						enabled: true,
					},
					{
						name: "preventOverflow",
						options: {
							altAxis: true // false by default
						},
					},
					flipAnimationCorrection
				]}
				onWheel={event => event.stopPropagation()}>
				<div
					style={{
						opacity: isOpen && popoverRef.current ? 1 : 0,
						transform: isOpen && popoverRef.current ? "scale(1)" : "scale(0.95)",
						transformOrigin: getOrigin(placement),
						transition: `scale ${MobergAnimationTiming.FAST} ${MobergAnimationCurve.EASE_IN}`,
						background: "white",
						borderRadius: "6px",
						boxShadow: MobergBoxShadow.REGULAR,
						padding: "8px",
						...style
					}}
					ref={popoverRef}
				>
					{children}
				</div>
			</Popper>
		)
	}

	useEffect(() => {
		const updateMouseDown = (event: MouseEvent) => (mouseDownTarget.current = event.target)

		const clickOutsideCloseHandler = (event: MouseEvent) => {
			if (clickOutsideClose
				&& popoverRef.current 
				&& anchorEl 
				&& !popoverRef.current.contains(event.target as Node)
				&& !anchorEl.contains(event.target as Node)
				&& !(mouseDownTarget.current instanceof HTMLInputElement)
			) {
				close()
			}

			mouseDownTarget.current = null
		}

		if (isOpen) {
			 // the third optional argument here is capture mode. This is important for HTML input focus detection.
			 // the capturing phase happens before the bubbling phase of events
			document.addEventListener("mousedown", updateMouseDown, true)
			document.addEventListener("click", clickOutsideCloseHandler)
		}

		return () => {
			document.removeEventListener("mousedown", updateMouseDown)
			document.removeEventListener("click", clickOutsideCloseHandler)
		}
	}, [anchorEl, isOpen, close, clickOutsideClose])

	// Manage mounted state to prevent state updates to unmounted components.
	useOnMount(() => {
		isMounted.current = true

		return () => {
			isMounted.current = false
		}
	})

	useEffect(() => {
		if (!tryToKeepMenuOpen) {
			return
		}

		// Wait for the menu to mount, if necessary.
		setTimeout(() => {
			// If the menu is open, but not visible (timing issue), make sure it is visible.
			if (isOpen && popoverRef.current && popoverRef.current.style.opacity === "0") {
				popoverRef.current.style.opacity = "1"
				popoverRef.current.style.transform = "scale(1)"
			}
		})
	})

	return { isOpen, open, close, setClickOutsideClose, MobergMenu }
}
