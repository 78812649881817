import { seriesCanvasLine } from "d3fc"
import { TimeSeriesData, getTimeSeriesData } from "../../../../Data/TimeSeriesData"
import { LineTraceConfig } from "../../../../Types/Trace"
import { TraceRenderStrategy, TraceRendererOptions } from "./RenderStrategy"
import { TimeSeriesPageManager } from "../../../../Data/TimeSeriesPageManager"
import { ModalityPage } from "../../../../Data/ModalityPage"
import { D3Trace } from "./D3Trace"

export class LineRenderStrategy implements TraceRenderStrategy {
	private directRenderer
	private offscreenRenderer
	private pageManager: TimeSeriesPageManager<ModalityPage>
	private config: LineTraceConfig
	private d3Trace: D3Trace
	private renderCacheKey: string = ""

	constructor(pageManager: TimeSeriesPageManager<ModalityPage>, d3Trace: D3Trace, config: LineTraceConfig) {
		this.config = config
		this.d3Trace = d3Trace
		this.pageManager = pageManager

		this.offscreenRenderer = seriesCanvasLine()
			.mainValue((p: [number, number]) => p[1])
			.crossValue((p: [number, number]) => p[0])

		this.directRenderer = seriesCanvasLine()
			.mainValue((p: [number, number]) => p[1])
			.crossValue((p: [number, number]) => p[0])

		this.updateRenderCacheKey()
	}
	
	public getRenderCacheKey(): string {
		return this.renderCacheKey
	}

	private updateRenderCacheKey = () => {
		const { graphId, dataKey, dataSource, color } = this.config as LineTraceConfig
		this.renderCacheKey = `${graphId}-${dataKey}-${dataSource}-${color}`
	}

	public updateConfig(traceConfig: LineTraceConfig) {
		this.config = traceConfig

		this.directRenderer
			.xScale(this.config.xScale)
			.yScale(this.config.yScale)
			.decorate((context: CanvasRenderingContext2D) => (context.strokeStyle = this.config.color))

		this.offscreenRenderer.decorate((context: CanvasRenderingContext2D) => (context.strokeStyle = this.config.color))
		this.updateRenderCacheKey()
	}

	public getDirectRenderer = (options?: TraceRendererOptions) => {
		if (options?.xScale) {
			this.directRenderer.xScale(options.xScale)
		}

		if (options?.yScale) {
			this.directRenderer.yScale(options.yScale)
		}

		return this.directRenderer
	}

	public getOffscreenRenderer = (options?: TraceRendererOptions) => {
		if (options?.xScale) {
			this.offscreenRenderer.xScale(options.xScale)
		}

		if (options?.yScale) {
			this.offscreenRenderer.yScale(options.yScale)
		}

		return this.offscreenRenderer
	}

	public render() {
		this.pageManager.getPagesInView().forEach(page => this.d3Trace.renderPage(page))
		this.fillInPageGaps()
	}

	public renderTimeSeriesData(data: TimeSeriesData, page: ModalityPage, renderer: (drawableData: Iterable<[(number | undefined), (number | undefined)]>) => void) {
		// If there is only one data point, just pretend that the value is constant.
		if (data.data.length === 1) {
			data = {
				data: new Float32Array([data.data[0], data.data[0]]),
				times: [page.startTime, page.endTime]
			}
		}
		
		renderer(this.drawableDataGenerator(data))

		// The edges can be used to redraw the gap between pages when the underlying data is unavailable
		const edges = [
			[data.times[0], data.data[0]], 
			[data.times[data.times.length - 1], data.data[data.data.length - 1]]
		]

		return edges as [number | undefined, number][]
	}

	public fillInPageGaps() {
		const pagesInView = this.pageManager.getPagesInView()

		if (this.d3Trace.isRescaling()) {
			return
		}

		// Get the pages
		const pages = [this.pageManager.getPreviousPage(pagesInView[0]), pagesInView[0], pagesInView[1], this.pageManager.getNextPage(pagesInView[1])]

		// For each gap, see if we have the cached edges for both
		const gapFills: Array<(number | undefined)[][] | undefined> = []
		
		pages.forEach((page, index) => {
			if (index === 0) {
				return
			}

			const previousRenderCache = pages[index - 1]?.renderCache.get(this.renderCacheKey)
			const renderCache = page?.renderCache.get(this.renderCacheKey)
			const start = previousRenderCache?.edges.at(-1)
			const end = renderCache?.edges.at(0)

			if (start && end) {
				gapFills.push([start, end])
			} else {
				gapFills.push(undefined)
			}
		})

		// If we have some gaps that are undefined, see if we can fill them with the page data.
		gapFills.forEach((gapFill, index) => {
			if (gapFill !== undefined) {
				return
			}

			let previousPageData = pages[index]?.data.get(this.d3Trace.dataObjectId)?.get(this.config.dataKey)
			let nextPageData = pages[index + 1]?.data.get(this.d3Trace.dataObjectId)?.get(this.config.dataKey)

			if (previousPageData && nextPageData) {
				previousPageData = getTimeSeriesData(previousPageData, this.config)
				nextPageData = getTimeSeriesData(nextPageData, this.config)
				gapFills[index] = this.calculateGapFill(previousPageData, nextPageData)
			}
		})

		// Render each gap fill
		gapFills.forEach(gapFill => {
			if (gapFill) {
				this.directRenderer(gapFill)
			}
		})
	}

	private calculateGapFill(firstPageData: TimeSeriesData | undefined, secondPageData: TimeSeriesData | undefined): (number | undefined)[][] {
		const drawStart = [firstPageData?.times.at(-1), firstPageData?.data.at(-1)]
		const drawEnd = [secondPageData?.times.at(0), secondPageData?.data.at(0)]
		return [drawStart, drawEnd]
	}

    // We can make this better by using an object pool so we are reusing arrays.
	private *drawableDataGenerator(timeSeriesData: TimeSeriesData): Generator<[number | undefined, number | undefined], void, undefined> {
		const length = timeSeriesData.data.length

		for (let i = 0; i < length; i++) {
			yield [timeSeriesData.times[i], timeSeriesData.data[i]]
		}
	}
}
