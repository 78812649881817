import React from "react"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import { histogramConfigAtom } from "../../../../Atoms/Histogram"
import { useModalProvider } from "../../../../../../../../Providers/ModalProvider"
import { editedLayoutGroupAtom, selectedLayoutAtom, selectedLayoutGroupAtom, uneditedLayoutGroupAtom } from "../../../../Atoms/Layout"
import { WINDOW_TIME_PRESETS } from "../../../../../../../../Managers/VisualizationManager/Viewport/Components/XAxis"
import { ConfigureHistogramModal } from "../../../Modals/ConfigureHistogramModal"
import { VisualizationToolbar } from "../../../React/VisualizationToolbar"

type HistogramWindowSettingsProps = {
	windowId: string
}

export function HistogramWindowSettings(props: HistogramWindowSettingsProps) {
	const { createModal } = useModalProvider()
	const setUneditedLayoutGroup = useSetRecoilState(uneditedLayoutGroupAtom)
	const setEditedLayoutGroup = useSetRecoilState(editedLayoutGroupAtom)
	const selectedLayoutGroup = useRecoilValue(selectedLayoutGroupAtom)
	const selectedLayout = useRecoilValue(selectedLayoutAtom)

	const [histogramConfig, setHistogramConfig] = useRecoilState(histogramConfigAtom({ layoutId: selectedLayout?.id as string, windowId: props.windowId }))
	const [viewStart, viewEnd] = histogramConfig.viewScale.domain()
	const viewDuration = viewEnd.getTime() - viewStart.getTime()

	function renderConfigureModal() {
		if (!selectedLayout) {
			console.error("Couldn't render the modal because a layout was not selected")
			return
		}

		setUneditedLayoutGroup(selectedLayoutGroup)
		setEditedLayoutGroup(selectedLayoutGroup)

		createModal(<ConfigureHistogramModal windowId={props.windowId} layoutId={selectedLayout.id} />)
	}

	return <VisualizationToolbar
		atomValue={histogramConfig}
		setAtom={setHistogramConfig}
		renderConfigureModal={renderConfigureModal}
		viewDuration={viewDuration}
		timePresetOptions={WINDOW_TIME_PRESETS}
	/>
}