import { ManualDownsampledPageManager } from "../../Data/ManualDownsampledPageManager"
import { TimeSeriesPageManager } from "../../Data/TimeSeriesPageManager"

// We can't just auto scale right away. We have to check to see if the data is loaded first.
// So, we create an interval which checks every so often, and auto-scales when the data loads in.
export const requestAutoScale = (pageManager: TimeSeriesPageManager<any> | ManualDownsampledPageManager, scaleFunction: CallableFunction, checkFrequency=50) => {
    const pagesInViewWhenRequestWasMade = [...pageManager.getPagesInView()]

    const interval = setInterval(() => {
        const currentPagesInView = pageManager.getPagesInView()

        // Cancel the auto scale if the window has moved before the auto scale could finish
        if (currentPagesInView.some((page, index) => page !== pagesInViewWhenRequestWasMade[index])) {
            clearInterval(interval)
            return
        }

        // Once all of the pages are loaded, do the auto scale.
        if (currentPagesInView.every(page => page?.loaded)) {
            scaleFunction()
            clearInterval(interval)
        }

    }, checkFrequency)
}