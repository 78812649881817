import styled from "styled-components";

import { Columns } from "../../Managers/VisualizationManager/Utils/Styling";
import { TabButton, useTabs } from "./Tabs";


export function TabSelector (props) {
	const { tabKeys = [], style={}, permission=null } = props
	const whiteBackground = props.whiteBackground === false ? props.whiteBackground : true

	return (
		<Columns height="34px" gap="20px" style={style}>
			{tabKeys.map(tabKey => <TabSelectorButton key={`tabSelector_${tabKey}`} tabKey={tabKey} whiteBackground={whiteBackground} permission={permission}/>)}
		</Columns>
	)
}

export function TabSelectorButton (props) {
    const { tabKey, whiteBackground, permission } = props
    const [selectedTab, setSelectedTab] = useTabs()
    return (
		<span title={!permission && tabKey === 'Annotations' ? 'You do not have permission for this action' : ''} style={{ cursor: !permission && tabKey === 'Annotations' ? 'not-allowed' : 'pointer' }}>
			<StyledTabButton as={TabButton} tabKey={tabKey} selected={selectedTab===tabKey} whiteBackground={whiteBackground} style={{ opacity: !permission && tabKey === 'Annotations' ? 0.5 : 1, pointerEvents: !permission && tabKey === 'Annotations' ? 'none' : 'auto' }}>
				{tabKey} 
			</StyledTabButton>
		</span>
		
    )
}

const StyledTabButton = styled.button`
	width: auto;
	margin-right: 10px;
	height: 34px;

    font-family: 'Source Sans Pro';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 150%;
	color: #5A6679;
	background: none;
	border: none;
	cursor: pointer;

	background: ${props => props.whiteBackground ? "#FFFFFF" : "rgb(248, 248, 248)"};
	border: none;

    padding-bottom: 8px;
	border-bottom: ${props => props.selected ? "3px solid #207DEA" : "none"};
	color: ${props => props.selected ? "#207DEA" : "#5A6679"};
`;
