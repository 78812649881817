import { useState, useEffect } from "react"
import { useForm } from "react-hook-form"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { MobergButton, MobergButtonVariant, MobergButtonShape, MobergFontSize, MobergTheme } from "../../../../Moberg"
import ModalHeader from "../../../../Components/ModalHeader/ModalHeader"
import { useEndpointProvider } from "../../../../Providers/EndpointProvider"
import ModalScrollbar from "../../../../Components/ModalScrollbar/ModalScrollbar"
import BasicTextField from "../../../../Components/TextField/BasicTextField"
import GridContainer from "../../../../Components/TextField/GridContainer"
import { ModalFooter } from "../../../../Constants/StyledComponents"
import TableWrapper from "../../../../Components/TextField/TableWrapper"
import MUITable from "../../../../Components/MUITable/MUITable"
import { useTableContext } from "../../../../Providers/TableProvider"
import { useModalProvider } from "../../../../Providers/ModalProvider"
import { useBackendLinksProvider } from "../../../../Providers/BackendLinksProvider"

const projectColumns = [
	{ field: "identifier", flex: 0.4, headerName: "Project ID", visible: true },
	{ field: "name", flex: 0.4, headerName: "Name", visible: true },
	{ field: "bucket_name", flex: 0.4, headerName: "Repository", visible: true },
	{ field: "user_count", flex: 0.4, headerName: "No. users", valueGetter: params => params.row.user_count, visible: true },
	{ field: "patient_count", flex: 0.4, headerName: "No. patients", valueGetter: params => params.row.patient_count, visible: true },
]

const siteColumns = [
	{ field: "identifier", flex: 0.4, headerName: "Site ID", visible: true },
	{ field: "name", flex: 1, headerName: "Site name", visible: true },
	{ field: "description", flex: 1, headerName: "Description", visible: true },
	{ field: "location", flex: 1, headerName: "Location", visible: true },
	{ field: "user_count", flex: 0.4, headerName: "No. users", visible: true, valueGetter: params => params.row.user_count },
	{ field: "patient_count", flex: 0.4, headerName: "No. patients", visible: true, valueGetter: params => params.row.patient_count },
]

const roleColumns = [
	{ field: "name", flex: 1, headerName: "Role Name", visible: true },
	{ field: "description", flex: 1, headerName: "Description", visible: true },
]

export default function ModifyUserModal({ row, viewMode }) {
	const endpointProvider = useEndpointProvider()
	const { setToastProps } = useTableContext()
	const { close } = useModalProvider()
	const queryClient = useQueryClient()
	const schema = yup.object({}).required()
	const [errorMessage, setErrorMessage] = useState()
	const { LINKS } = useBackendLinksProvider()

	const { control, handleSubmit, formState: { errors } } = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			email: row.email,
			first_name: row.first_name,
			last_name: row.last_name,
		},
	})

	const modifyUser = async body => endpointProvider.post(LINKS.ADMIN.USER_MANAGEMENT.USERS.MODIFY_USER, body)
	const modifyUserMutation = useMutation({
		mutationFn: body => modifyUser(body),
		onError: error => {
			setToastProps({ isOpen: true, severity: "error", message: error.message })
		},
		onSuccess: () => {
			setToastProps({ isOpen: true, severity: "success", message: "User successfully modified." })
		},
		onSettled: () => {
			queryClient.invalidateQueries("users")
		},
	})
	const onSubmit = async data => {
		if (selectedSiteRows.length === 0 || selectedRoleRows.length === 0 || selectedProjectRows.length === 0) {
			setErrorMessage("Please select at least one site, role and project.")
			return
		}
		modifyUserMutation.mutate({
			user_id: row.id,
			site_ids: selectedSiteRows,
			role_ids: selectedRoleRows,
			project_ids: selectedProjectRows,
		})
		close()
	}

	const siteQueryProps = {
		queryKey: "sites",
		endpoint: viewMode ? LINKS.ADMIN.SITES.GET_SITES_BY_USER : LINKS.ADMIN.SITES.GET_SITES,
		body: viewMode ? { user_id: row.id } : {},
	}
	const [selectedSiteRows, setSelectedSiteRows] = useState(viewMode ? [] : row.site_ids)
	const preselectedSiteProps = { selectedRows: selectedSiteRows, setSelectedRows: setSelectedSiteRows }

	const roleQueryProps = {
		queryKey: "roles",
		endpoint: viewMode ? LINKS.ADMIN.USER_MANAGEMENT.USERS.GET_ROLES_BY_USER : LINKS.ADMIN.USER_MANAGEMENT.USERS.GET_ROLES,
		body: viewMode ? { user_id: row.id } : {},
	}
	const [selectedRoleRows, setSelectedRoleRows] = useState(viewMode ? [] : row.role_ids)
	const preselectedRoleProps = { selectedRows: selectedRoleRows, setSelectedRows: setSelectedRoleRows }

	const projectQueryProps = {
		queryKey: "projects",
		endpoint: viewMode ? LINKS.ADMIN.PROJECTS.GET_PROJECTS_BY_USER : LINKS.ADMIN.PROJECTS.GET_PROJECTS,
		body: viewMode ? { user_id: row.id } : {},
	}
	const [selectedProjectRows, setSelectedProjectRows] = useState(viewMode ? [] : row.project_ids)
	const preselectedProjectProps = { selectedRows: selectedProjectRows, setSelectedRows: setSelectedProjectRows }

	useEffect(() => {
		if (Object.keys(errors).length > 0) {
			const error = errors[Object.keys(errors)[0]]
			if (Array.isArray(error)) {
				error.forEach(e => {
					if (e) {
						setErrorMessage(e?.message)
					}
				})
			} else {
				setErrorMessage(error?.message)
			}
		}
	}, [errors])

	return (
		<>
			<ModalHeader headerText={viewMode ? 'View User' : "Modify User"} />
			<ModalScrollbar>
				<div style={{ maxWidth: "800px" }}>
					<h2>User Details</h2>
					<GridContainer>
						<BasicTextField
							inputId="modify-user-email-id"
							label="Email"
							placeholder="admin@moberganalytics.com"
							control={control}
							name="email"
							disabled={true}
							numberColumn={4}
						/>
						<BasicTextField
							inputId="modify-user-first-name-id"
							label="First Name"
							placeholder="Moberg"
							control={control}
							name="first_name"
							disabled={true}
							numberColumn={4}
						/>
						<BasicTextField
							inputId="modify-user-last-name-id"
							label="Last Name"
							placeholder="Admin"
							control={control}
							name="last_name"
							disabled={true}
							numberColumn={4}
						/>

						<TableWrapper inputId="modify-user-projects-id" label="Projects" tooltip="The projects that the user will be invited to.">
							<MUITable columns={projectColumns} {...projectQueryProps} preselectedProps={preselectedProjectProps} isRowSelectable={() => !viewMode} isCheckboxSelection={!viewMode} />
						</TableWrapper>

						<TableWrapper inputId="modify-user-sites-id" label="Sites" tooltip="The sites that the user will be invited to.">
							<MUITable columns={siteColumns} {...siteQueryProps} preselectedProps={preselectedSiteProps} isRowSelectable={() => !viewMode} isCheckboxSelection={!viewMode} />
						</TableWrapper>

						<TableWrapper inputId="modify-user-roles-id" label="Roles" tooltip="The roles that the user will be invited to.">
							<MUITable columns={roleColumns} {...roleQueryProps} preselectedProps={preselectedRoleProps} isRowSelectable={() => !viewMode} isCheckboxSelection={!viewMode} />
						</TableWrapper>
					</GridContainer>
				</div>
			</ModalScrollbar>

			{!viewMode && (
				<ModalFooter style={{ justifyContent: 'space-between', alignItems: 'center' }}>
					<h1 style={{ color: '#E54E58' }}>{errorMessage}</h1>
					<MobergButton
						theme={MobergTheme.BLUE}
						variant={MobergButtonVariant.FILLED}
						onClick={handleSubmit(onSubmit)}
						shape={MobergButtonShape.WIDE}
						fontSize={MobergFontSize.LARGE}
					>
						Submit
					</MobergButton>
				</ModalFooter>
			)}
		</>
	)
}