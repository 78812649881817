import React, { createContext, useState, useContext} from 'react';

// Create a context object
const HotkeysContext = createContext();

// Create a provider component
export const HotkeysProvider = ({ children }) => {
    const [deleteAnnotations, setDeleteAnnotations] = useState(false)

    const [resetToDefault, setResetToDefault] = useState(false)

    const [deleteAndExit, setDeleteAndExit] = useState(false)

    const [saveAndExit, setSaveAndExit] = useState(false)

    const [cancelChanges, setCancelChanges] = useState(false)

    const [saveChanges, setSaveChanges] = useState(false)

    return (
        <HotkeysContext.Provider value={{deleteAnnotations, setDeleteAnnotations, resetToDefault, setResetToDefault, deleteAndExit, setDeleteAndExit, saveAndExit, setSaveAndExit, cancelChanges, setCancelChanges, saveChanges, setSaveChanges}}>
            {children}
        </HotkeysContext.Provider>
    )
}

export const useHotkeysProvider = () => {
    return useContext(HotkeysContext)
}