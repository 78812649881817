import { NODE_OUTPUT_TYPES } from "../../NodeOutputTypes"
import { USER_INPUT_TYPES } from "../../UserInputTypes"

export const EVENT_RESPONSE_DATA_SPLITTER = {
    INPUTS: [
        {
            DISPLAY_NAME: "Window Size",
            VARIABLE_NAME: "window_size",
            TYPE: USER_INPUT_TYPES.DURATION_INPUT,
            DEFAULT_VALUE: {"seconds": 1},
            DESCRIPTION: "The size of the window that will be extracted from the series.",
        },
        {
            DISPLAY_NAME: "Delay",
            VARIABLE_NAME: "delay",
            TYPE: USER_INPUT_TYPES.DURATION_INPUT,
            DEFAULT_VALUE: {"milliseconds": 0},
            DESCRIPTION: "The amount of time after the end of each interval to wait before extracting data.",
        },
    ],
    DISPLAY_NAME: "Event Response Data Splitter",
    PRODUCT_LEVEL: "Standard",
    DESCRIPTION: "Extract pieces of a time series based on the ends of interval data.",
    KEYWORDS: ["data", "split", "event", "response"],
    DATA_INPUT_TYPES: [NODE_OUTPUT_TYPES.TIME_SERIES, NODE_OUTPUT_TYPES.INTERVALS],
    DATA_OUTPUT_TYPE: NODE_OUTPUT_TYPES.TIME_SERIES
}
