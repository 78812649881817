import { useState, useEffect, useLayoutEffect, useRef } from 'react';
import styled from 'styled-components';
import "@fontsource/source-sans-pro";
import * as MdIcons from 'react-icons/md';
import '../../../../index.css';
import { Link, useLocation } from "react-router-dom";
import { DEFAULTS } from '../../../../Constants/Formatting';
import { useEndpointProvider } from '../../../../Providers/EndpointProvider';
import { MainTable } from '../../../../Components/DataTable/Table';
import { Searchbar, ButtonHeader, } from "../../../../Constants/StyledComponents";
import { useModalProvider } from '../../../../Providers/ModalProvider';
import ViewUserActionModal from './ViewUserActionsModal';
import { useBackendLinksProvider } from "../../../../Providers/BackendLinksProvider"

const UserManagementSubpage = (props) => {
	const { createModal } = useModalProvider()
	const { LINKS } = useBackendLinksProvider()
	const endpointProvider = useEndpointProvider()

	const query = new URLSearchParams(useLocation().search);
	const email = query.get("email")

	const [userEmail, setUserEmail] = useState(undefined)

	async function checkEmail(email) {
		let body = {
			email: email,
		}

		return endpointProvider.post(LINKS.ADMIN.USER_MANAGEMENT.USERS.GET_USER_BY_EMAIL, body)
	}

	async function userExists(email) {
		await checkEmail(email).then(data => {
			if (data.length) {
				return true
			}
			return false
		}).catch((e) => {
			alert(e)
			return false
		})
	}

	async function getTokenMeta(email) {
		let body = {
			email: email,
		}

		return endpointProvider.post(LINKS.ADMIN.USER_MANAGEMENT.USERS.GET_TOKENS, body)
	}


	const checkURLParams = () => {
		if (email?.length === 0) return
		setUserEmail(email)
		userToken(email)
	}

	useEffect(() => {
		checkURLParams();
	}, []);

	useEffect(() => {
		if (!userEmail) return
		getTokenMeta(userEmail).then((data) => {
			setTokenData(data)
		}).catch((e) => {
			alert(e)
		})
	}, [userEmail])


	async function userToken(email) {
		await getTokenMeta(email).then(async (data) => {
			let token = await data
			setTokenData(token)
		}).catch((e) => {
			alert(e)
		})
	}

	function useWindowSize() {
		const [size, setSize] = useState(0);
		useLayoutEffect(() => {
			function updateSize() {
				setSize(window.innerHeight);
			}
			window.addEventListener('resize', updateSize);
			updateSize();
			return () => window.removeEventListener('resize', updateSize);
		}, []);
		return size;
	}

	const size = useWindowSize()
	let filterRef = useRef();
	let filterButtonRef = useRef();

	let columns2labels = {
		permission_name: { label: "Permission", type: "string" },
		performed_at: { label: "Performed at", type: "string" },
	}

	function handleTokensSearch(event) {
		setTokenSearchQuery(event.target.value)
	}

	//////////////// TOKEN TINGS START BELOW (for main User Subpage table) ////////////////

	const [tokenTableData, setTokenTableData] = useState([])
	const [tokenTableColumns, setTokenTableColumns] = useState([])
	const [tokenDataPending, setTokenDataPending] = useState(true)
	const [tokenSearchQuery, setTokenSearchQuery] = useState("");
	const [tokenData, setTokenData] = useState(undefined)
	useEffect(() => {
		if (tokenData === undefined) return

		let columns2labels = {
			user: { label: "User", type: "string" },
			time_created: { label: "Beginning of Session", type: "string" },
			time_expired: { label: "End of Session", type: "string" },
		}

		let columnData = []

		for (let elem of Object.keys(columns2labels)) {
			columnData.push({
				name: columns2labels[elem]['label'],
				selector: row => row[elem],
				sortable: true,
			})
		}

		setTokenTableColumns(columnData)
		setTokenDataPending(true)
		let data = tokenData['data']

		let tableData = data.map(row => {
			let entry = {}
			for (let column of Object.keys(row)) {
				if (columns2labels[column] && columns2labels[column]["type"] === "checkbox") {
					if (row[column]) {
						entry[column] = (<MdIcons.MdOutlineCheckBox />)
					} else {
						entry[column] = (<MdIcons.MdOutlineCheckBoxOutlineBlank />)
					}
				} else if (columns2labels[column] && columns2labels[column]["type"] === 'string') {
					entry[column] = row[column]
				}
			}
			return entry
		})

		tableData = tableData.filter((row) => {
			if (tokenSearchQuery === "") {
				return true
			} else if (row.user.toLowerCase().includes(tokenSearchQuery.toLowerCase())) {
				return true
			} else if (row.time_created.toLowerCase().includes(tokenSearchQuery.toLowerCase())) {
				return true
			} else if (row.time_expired.toLowerCase().includes(tokenSearchQuery.toLowerCase())) {
				return true
			}
			return false
		})
		tableData.sort((a, b) => {
			var c = new Date(a.time_expired);
			var d = new Date(b.time_expired);
			return d - c
		})

		setTokenTableData(tableData)
		setTokenDataPending(false)

	}, [tokenData, tokenSearchQuery])

	function renderViewActionsModal() {
		createModal(
			<ViewUserActionModal
				escClose={false}
				clickOutsideClose={false}
				columns2labels={columns2labels}
				userEmail={userEmail}
				filterRef={filterRef}
				filterButtonRef={filterButtonRef}
			/>)
	}

	return (
		<>
			<HeaderGap />
			<div style={{ background: "#F8F8F8", height: size }}>
				<div style={{
					width: "100%",
					height: "100%",
					color: DEFAULTS.TEXT_COLOR,
					background: "#F8F8F8",
					paddingLeft: "35px",
					paddingRight: "35px",
					display: "block"
				}}>

					<div style={{ flex: "3" }} />
					<ButtonHeader>
						<div id="PageSubheader" style={{ marginLeft: "-5px" }}>
							<SubheaderLink >
								<h2>
									<Link to='/admin/user_management/'><MdIcons.MdKeyboardArrowLeft size={20} /> Users/</Link> <strong style={{ cursor: "default" }}>Activity/{email}</strong>
								</h2>
							</SubheaderLink>
						</div>
						<Searchbar className="ui left icon input">
							<input
								type="text"
								onChange={handleTokensSearch}
								placeholder="Search"
							/>{" "}
							<i aria-hidden="true" class="search icon" />
						</Searchbar>

						<div style={{ position: "relative" }}>
							<ActionsButton onClick={() => { renderViewActionsModal() }}>
								<MdIcons.MdSettings
									color={"#207DEA"}
									size={20}
									style={{ marginRight: "2px", marginBottom: "2px" }} />
								View User Actions
							</ActionsButton>
						</div>
					</ButtonHeader>

					<MainTable
						columns={tokenTableColumns}
						data={tokenTableData}
						progressPending={tokenDataPending}
						selectRows={false}
					/>
				</div>
			</div>
		</>
	)
}

const HeaderGap = styled.div`
background-color: white;
	height: 47px;
`;

const SubheaderLink = styled.button`
    width: auto;
    height: 24px;
    display: block;
    cursor: pointer;
    background: #f8f8f8;
    border: none;
    h2 {
        font-family: 'Source Sans Pro';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #207dea;
    }
`;

const ActionsButton = styled.button`
	width: 180px;
	height: 34px;
	background: #ffffff;
	border: 0.5px solid #b6b6b6;
	box-sizing: border-box;
	box-shadow: 0px 0px 3px rgba(99, 191, 244, 0.15);
	border-radius: 6px;
	font-family: "Source Sans Pro";
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 150%;
	color: #293241;
	margin-left: 8px;
	flex: 0.2;
	button {
		background: #ffffff;
		border: none;
		cursor: pointer;
	}
`;

export default UserManagementSubpage;

