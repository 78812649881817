import React, { MutableRefObject, useLayoutEffect, MouseEvent, useRef } from "react"
import { Scrollbar } from "../../Constants/StyledComponents"
import { layoutGroupsAtom, selectedLayoutGroupAtom } from "../../Pages/Data/Visualize/DataReview/Atoms/Layout"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { MobergButton, MobergButtonShape, MobergButtonVariant } from "../MobergButton/MobergButton"
import { MobergTheme } from "../MobergThemes/MobergColors"
import { MdAdd } from "react-icons/md"
import { MobergIconSize } from "../MobergIcon/MobergIcon"
import { useLayoutService } from "../../Hooks/useLayoutService"
import { useAsyncTask } from "../../Hooks/useAsyncTask"
import { DisplayGroupDropdownRow } from "./DisplayGroupDropdownRow"
import { MobergMenuController } from "../../Hooks/useMobergMenu"
import { Empty } from "antd"
import { MobergColumn } from "../MobergLayout/MobergColumn"
import { LayoutGroup } from "../../Pages/Data/Visualize/DataReview/Types/Layout"

type DisplayGroupDropdownSectionProps = {
	section: string
	menu: MobergMenuController
	scrollTopRef: MutableRefObject<number>
}

export const DisplayGroupDropdownSection: React.FC<DisplayGroupDropdownSectionProps> = ({ section, menu, scrollTopRef }) => {
	const displayGroups = useRecoilValue(layoutGroupsAtom)
	const relevantDisplayGroups = displayGroups.filter(displayGroup => displayGroup.type.toLowerCase() === section.toLowerCase())
	const { createLayoutGroup } = useLayoutService()
	const createDisplayGroupTask = useAsyncTask(() => createLayoutGroup(section))
	const setSelectedDisplayGroup = useSetRecoilState(selectedLayoutGroupAtom)
	const scrollBarRef = useRef<HTMLDivElement>(null)

	const addDisplayGroup = () => {
		createDisplayGroupTask.run().then((displayGroup: LayoutGroup) => {
			setSelectedDisplayGroup(displayGroup)
		})
	}

	const onScroll = (event: MouseEvent<HTMLDivElement>) => {
		scrollTopRef.current = event.currentTarget.scrollTop
	}
	
	// When the component updates, the scroll resets to the top, which is confusing to the user.
	// We want to set the scroll position before the first render to prevent screen jumping. 
	// useLayoutEffect blocks the render until this completes.
	useLayoutEffect(() => {
		if (scrollBarRef.current) {
			scrollBarRef.current.scrollTop = scrollTopRef.current
		}
	})

	return (
		<MobergColumn expand={true} gap="16px" verticalAlign="space-between" style={{ padding: "16px", paddingRight: "8px", borderRadius: "6px" }}>
			<Scrollbar ref={scrollBarRef} style={{ maxHeight: "360px" }} onScroll={onScroll}>
				{relevantDisplayGroups.map(displayGroup => (
					<DisplayGroupDropdownRow key={displayGroup.id} displayGroup={displayGroup} parentMenu={menu} />
				))}

				{relevantDisplayGroups.length === 0 && <Empty description={<p style={{ fontFamily: "Source Sans Pro", fontSize: "16px" }}> No display groups </p>} />}
			</Scrollbar>

			<div style={{ paddingRight: "8px"}}>
				<MobergButton
					theme={MobergTheme.BLUE}
					shape={MobergButtonShape.FULL_WIDTH}
					variant={MobergButtonVariant.CLEAR}
					disabled={createDisplayGroupTask.isWaiting}
					onClick={addDisplayGroup}
					style={{ padding: "12px", justifyContent: "start" }}
				>
					<MdAdd size={MobergIconSize.REGULAR} />
					Add display group
				</MobergButton>
			</div>
			
		</MobergColumn>
	)
}
