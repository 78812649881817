import { humanReadableSize } from "../../../../Computation/utilFunctions"


export const calculateUploadSize = (tableData) => {
    const sizeList = [];
    let sizeName = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    let numDirectories = tableData.length;

    if (numDirectories === 1) {
        numDirectories = numDirectories + " Directory";
    } else {
        numDirectories = numDirectories + " Directories";
    }

    // Calculate the total size of files in the table
    for (let file in tableData) {
        let size = parseFloat(tableData[file]['fileSize'].split(" ")[0]);
        let unit = tableData[file]['fileSize'].split(" ")[1];
        const power = sizeName.indexOf(unit);
        size = size * Math.pow(1024, power);
        sizeList.push(size);
    }
    
    // Calculate the total size in human-readable format
    let total = sizeList.length > 0 ? sizeList.reduce((runningTotalSize, currentValue) => runningTotalSize + currentValue) : 0;
    let totalSize = humanReadableSize(total)
    return `${numDirectories}, ${totalSize}`;
};
