import React, {useState, useEffect, useMemo} from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';
import { LINKS } from '../../../../Constants/BackendLinks'
import * as MdIcons from "react-icons/md";
import { MainTable } from '../../../../Components/DataTable/Table';
import {useStudiesProvider} from '../../../../Providers/StudiesProvider';
import { useEndpointProvider } from '../../../../Providers/EndpointProvider';
import CreateReportDetailsModalBootstrap from './CreateReportDetailsModalBootstrap';
import ModalFooterButton, { buttonColor } from '../../../../Components/ModalFooterButton/ModalFooterButton';

function CreateReportModalBootstrap({show, onHide, refreshTable, reportTemplateNames}) {
    const endpointProvider = useEndpointProvider()
    const headingStyle = {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "700",
        lineHeight: "150%",
        color: "#293241"
    }
    const showMore = (
        <MdIcons.MdExpandMore
            size={20}
            style={{marginLeft: "10px", marginRight: "-20px", color: "#B6B6B6"}}
        />
    );
    const showLess = (
        <MdIcons.MdExpandLess
            size={20}
            style={{marginLeft: "10px", marginRight: "-20px", color: "#B6B6B6"}}
        />
    );


    const studiesProvider = useStudiesProvider()
    const [studyTableColumns, setStudyTableColumns] = useState([])
    const [studyPending, setStudyPending] = useState(false)
    const [studyPreselect, setStudyPreselect] = useState([])
    const [studySearchQuery, setStudySearchQuery] = useState("");
    const [studyTableData, setStudyTableData] = useState([])
    const [studyTableSelected, setStudyTableSelected] = useState(false);
    const [studyToggleCleared, setStudyToggleCleared] = useState(false);
    const [displayStudyTable, setDisplayStudyTable] = useState(true);
    const [reportName, setReportName] = useState(null);
    const [createReportDetailsModalShow, setCreateReportDetailsModalShow] = useState(false);
    const [currentReportId, setCurrentReportId] = useState(null);
    const [isHoverCreateReport, setIsHoverCreateReport] = useState(false);
    const [isHoverCancelReport, setIsHoverCancelReport] = useState(false);

    useEffect(() => {
		if (!studiesProvider.studies) return

		let columns2labels = {
			name: { label: "Name", type: "string" },
			study_id: { label: "Study ID", type: "string" },
			start_date: { label: "Start Date", type: "string" },
			study_type: { label: "Study Type", type: "string" }
		}

		let columnData = []

		for (let elem of Object.keys(columns2labels)) {
			columnData.push({
				name: columns2labels[elem]['label'],
				selector: elem,
				sortable: true,
			})
		}
		
		setStudyTableColumns(columnData)
		setStudyPending(true)
		let tableData = studiesProvider.studies?.map(row => {
			let entry = {}
			for (let column of Object.keys(columns2labels)) {
				if (columns2labels[column]["type"] === "checkbox") {
					if (row[column]) {
						entry[column] = (<MdIcons.MdOutlineCheckBox />)
					} else {
						entry[column] = (<MdIcons.MdOutlineCheckBoxOutlineBlank />)
					}
				} else if (columns2labels[column]["type"] === 'string') {
					entry[column] = row[column]
				}
			}
			entry.selected = studyPreselect.includes(row.study_id)
			entry.study_id = row.study_id
			return entry
		})

        
			
        tableData = tableData.filter((row) => {
			if (studySearchQuery === "") {
				return true
			} else if (row.name.toLowerCase().includes(studySearchQuery.toLowerCase())) {
				return true
			} else if (row.study_id.toLowerCase().includes(studySearchQuery.toLowerCase())) {
				return true
			} else if (row.start_date.toLowerCase().includes(studySearchQuery.toLowerCase())) {
				return true
			} else if (row.study_type.toLowerCase().includes(studySearchQuery.toLowerCase())) {
				return true
			}
			return false
		})

		setStudyTableData(tableData)
        setStudyPending(false)
	
	}, [studiesProvider.selectedStudyId, studyPreselect, studySearchQuery, studiesProvider.studies])

    useEffect(() => {
        setStudyPreselect(studiesProvider.selectedStudyId)
    }, [studiesProvider.selectedStudyId])

    function updateStudiesTableSelected({selectedRows}) {
        setStudyTableSelected(selectedRows)
    }

    const rowSelectCriteria = useMemo(() => row => row.selected, []);

    const handleDisplayStudyTable = (e) => {
        e.preventDefault();
        displayStudyTable ? setDisplayStudyTable(false) : setDisplayStudyTable(true)
    };

    function handleStudySearch(event) {
		setStudySearchQuery(event.target.value)
	}

    const handleCloseCreateReportModal = () => {
        onHide();
        setReportName(null);
    }

    async function createReport(study_id, report_name) {
        let body = {
            study_id: study_id,
            name: report_name,
        }

        return endpointProvider.post(LINKS.REPORTING.MULTIMODAL_REPORTS.REPORT_TEMPLATES.CREATE_REPORT_TEMPLATE, body)
	}

    function handleCreateReport() {
        if (!reportName || reportTemplateNames.includes(reportName)) return
        createReport(studiesProvider.selectedStudyId, reportName)
            .then((res) => {
                handleCloseCreateReportModal()
                setCreateReportDetailsModalShow(true)
                refreshTable()
                setCurrentReportId(JSON.parse(res).id)
            })
            .catch(e => {
                alert("Fail to create a report. Try again!")
            })
    }
    
    function handleChangeReportName(event) {
        setReportName(event.target.value)
    }

    return (
        <>
        
            <Modal
                show={show}
                onHide={handleCloseCreateReportModal}
                size="lg"
                centered
                >
                <Modal.Header
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Modal.Title>
                        <h3 style={headingStyle}>Create Report</h3>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Form>
                    <h4 style={headingStyle}>Report Details</h4>
                    <Form.Group className="mb-3">
                        <Form.Label>
                            <h5>Report Name <span style={{color: "red"}}>*</span></h5>
                        </Form.Label>
                        <Form.Control 
                            size="lg" 
                            type="text" 
                            placeholder="Enter report name" 
                            onChange={handleChangeReportName}
                            required 
                            style={{
                                borderColor: (reportName === "" || reportTemplateNames.includes(reportName)) ? "#dc3545" : "#dadfe3", 
                                boxShadow: "none",
                            }}
                            />
                        <span 
                            style={{width: "100%", color: "#dc3545", marginTop: "0.25rem", fontSize: "0.875em", display: (reportName === "" || reportTemplateNames.includes(reportName)) ? "block" : "none"}}
                        >
                            Please provide a valid report template name.
                        </span>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>
                            <ModalDropdownButton onClick={handleDisplayStudyTable}><h5>Studies{displayStudyTable ? showLess : showMore}</h5></ModalDropdownButton>
                        </Form.Label>
                        <ModalDropdownTableContainer 
                            style={displayStudyTable ? {display: "block"} : {display: "none"}}
                        >
                            <ModalSearchBar className="ui left icon input" style={{flex: "1"}}>
                                <input 
                                    id="searchInput" 
                                    type="text" 
                                    placeholder="Search"
                                    onChange={handleStudySearch}
                                /> 
                                <i aria-hidden="true" class="search icon"/>
                            </ModalSearchBar>
                            <MainTable
                                columns={studyTableColumns}
                                data={studyTableData}
                                progressPending={studyPending}
                                selectableRows
                                onSelectedRowsChange={updateStudiesTableSelected}
                                selectableRowSelected={rowSelectCriteria}
                                clearSelectedRows={studyToggleCleared}
                                conditionalRowStyles={[
                                    {when: row=>row.name === "UNNAMED",
                                    style: {color: 'red'}}
                                ]}
                                />
                        </ModalDropdownTableContainer>
                    </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <div
                        style={{
                            paddingLeft: "35px",
                            paddingBottom: "15px",
                        }}
                    >
                        <ModalFooterButton
                            buttonText="Create"
                            disabled={(!reportName || reportTemplateNames.includes(reportName))}
                            color={buttonColor.blueFill}
                            buttonStyle={{width: "114px", marginRight: "5px"}}
                            onClickFxn={handleCreateReport}
                            onMouseEnter={() => {setIsHoverCreateReport(true)}}
                            onMouseLeave={() => {setIsHoverCreateReport(false)}}
                        />
                        <ModalFooterButton
                            buttonText="Cancel"
                            style={{width: "114px", marginRight: "5px"}}
                            disabled={isHoverCancelReport}
                            color={buttonColor.blueOutline}
                            onClick={handleCloseCreateReportModal}
                            onMouseEnter={() => {setIsHoverCancelReport(true)}}
                            onMouseLeave={() => {setIsHoverCancelReport(false)}}
                        />
                    </div>
                    
                </Modal.Footer>
            </Modal>
            

            <CreateReportDetailsModalBootstrap
                show={createReportDetailsModalShow}
                onHide={() => setCreateReportDetailsModalShow(false)}
                reportId={currentReportId}
                createReport={true}
            />
        </>
  )
}

const ModalSearchBar = styled.div`
	width: 60%;
	height: 30px;
	background: #FFFFFF;
	border-radius: 6;
	box-sizing: border-box;
	overflow-y: hidden;
	overflow-x: hidden;
	margin-bottom: 10px;
`;

const ModalDropdownTableContainer = styled.div`
	border: 2px solid #cccccc;
	box-sizing: border-box;
	border-radius: 5px;
	background: #ffffff;
	height: auto;
	padding: 10px;
`;

const ModalDropdownButton = styled.button`
	cursor: pointer;
	display: inline;
	flex-direction: row;
	border: solid 0.5px #FFFFFF;
	background-color: #FFFFFF;
	font-family: "Source Sans Pro";
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 150%;
	color: #293241;
`;

export default CreateReportModalBootstrap