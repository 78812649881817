import { EEGPage } from "./EEGPage";
import { TimeSeriesPageManager } from "./TimeSeriesPageManager";

export class EEGTimeSeriesPageManager extends TimeSeriesPageManager<EEGPage> {
	getPageMaker(): () => EEGPage {
		return () => new EEGPage()
	}

	protected getLoadBufferStartAndEnd() {
		const viewStartTime = this.config.viewScale.domain()[0].getTime()
		const viewEndTime = this.config.viewScale.domain()[1].getTime()
		const viewDuration = viewEndTime - viewStartTime
		const currentPageStartTime = this.currentPageStartTime()
		const end = currentPageStartTime + viewDuration + this.getNumberOfCachedPages() * viewDuration
		return [currentPageStartTime, end]
	}
}