class ConnectionPoint {
    id: string

    constructor(id: string) {
        this.id = id
    }

    getNodeId(): number {
        return parseInt(this.id.split("_")[0].split("node")[1])
    }

    getInputIndex(): number {
        return parseInt(this.id?.split("_input_")[1])
    }

}

export default class Connection {
    id = ""
    source: ConnectionPoint
    target: ConnectionPoint
    color: string
    order: number
    curveness: number

    constructor(sourceId: string, targetId: string, color: string="#000", curveness: number=0, order=0) {
        this.source = new ConnectionPoint(sourceId)
        this.target = new ConnectionPoint(targetId)
        this.order = order
        this.color = color
        this.curveness = curveness
        this.updateId()
    }

    updateId(): void {
        this.id = `conn_${this.source.id}_to_${this.target.id}_${this.color}`
    }
}