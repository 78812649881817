import { useRecoilCallback, useSetRecoilState } from "recoil"
import { modalsAtom } from "../Atoms/Modals"
import { ModalConfig, ModalOptions, closeAnimationOffset, closeTransition } from "../Types/Modals"
import { createRef, useCallback } from "react"


export const useModalProvider = () => {
	const setModals = useSetRecoilState(modalsAtom)

    // A Recoil callback allows us to read the recoil state when we call the function, 
    // but avoid subscribing to state changes. This allows us to close modals without re-rendering everything.
	const close = useRecoilCallback(
		({ snapshot }) =>
			async () => {
				const modals = snapshot.getLoadable(modalsAtom).getValue()

				if (modals.length === 0) {
					return
				}

				const { modalRef, overlayRef, options } = modals[modals.length - 1]

				const modal = modalRef.current
                const overlay = overlayRef.current

				if (!modal) {
					return
				}

				// Animate Modal closed
				modal.style.transition = closeTransition
				modal.style.opacity = "0"
				modal.style.marginTop = closeAnimationOffset

				// Animate Overlay closed
				if (overlay) {
					overlay.style.transition = closeTransition
					overlay.style.opacity = "0"
				}

				// Wait for the CSS transition to finish, setTimeout to wait, then update React state and resolve the promise.
				await new Promise<void>(resolve => {
					modal.ontransitionend = () => {
						// Prevent this from being called more than once.
						modal.ontransitionend = null

						// By waiting a little bit after the animation to finishes, we can make sure the animation is smooth.
						setTimeout(() => {
							setModals(prevModals => prevModals.slice(0, -1))
							setTimeout(() => {
								if (options?.afterClose) {
									options.afterClose()
								}

								resolve()
							}, 100) // wait for the Recoil State to update
						}, 100)
					}
				})
			},
		[]
	)

	const createModal = useCallback((component: JSX.Element, options?: ModalOptions) => {
		const defaultOptions = {
			animate: true,
			dataReviewHotkeysEnabled: false,
			zIndex: undefined
		}

		Object.assign(defaultOptions, options)

		const newModal: ModalConfig = {
			component,
			options: defaultOptions,
			modalRef: createRef(),
			overlayRef: createRef()
		}

		setModals(prevModals => [...prevModals, newModal])
	}, [setModals])

	const replaceLastModal = (previous: ModalConfig[], newModal: ModalConfig): ModalConfig[] => {
        const copy = [...previous]
        copy.pop()
        copy.push(newModal)
        return copy
    }

	const openNext = useRecoilCallback(({ snapshot }) => (component: JSX.Element, options?: ModalOptions) => {
		const modals = snapshot.getLoadable(modalsAtom).getValue()
        const modal = modals[modals.length-1].modalRef.current

        const defaultOptions = {
            animate: false,
            dataReviewHotkeysEnabled: false
        }

        if (!modal) {
            return
        }

		const newModal: ModalConfig = {
			component,
			options: {...defaultOptions, ...options},
			modalRef: createRef(),
			overlayRef: createRef()
		}

        if (options?.animate) {
            modal.style.transition = closeTransition
            modal.style.opacity = "0"
            modal.style.marginTop = closeAnimationOffset

            modal.ontransitionend = () => {
                setModals(previous => replaceLastModal(previous, newModal))
                modal.ontransitionend = null
            }

        } else {
            setModals(previous => replaceLastModal(previous, newModal))
        }
    })

	return { createModal, openNext, close }
}
