import { Selection, EnterElement } from "d3"
import { D3Histogram, HistogramConfig } from "./D3Histogram"
import { D3HistogramConfigurationBuilder } from "./D3HistogramConfigurationBuilder"
import { D3VisualizationRenderer } from "../../D3VisualizationRenderer"
import { D3HistogramYAxis } from "./D3HistogramYAxis"
import { D3HistogramXAxis } from "./D3HistogramXAxis"
import { D3Timeline } from "../../../D3/Timeline/D3Timeline"
import { D3HistogramBars } from "./D3HistogramBars"
import { ModalityPage } from "../../../../Data/ModalityPage"
import { throttledRender } from "../../../../rendering"
import { D3HorizontalLines } from "../../../D3/D3HorizontalLines"
import { D3ClipPath } from "../../../D3/D3ClipPath"
import { D3ModalityGraphLegend } from "../../TimeSeriesGraphGroup/D3/D3ModalityGraphLegend"

export const ANIMATION_TIME = 500

export class D3HistogramRenderer extends D3VisualizationRenderer<D3Histogram, D3HistogramConfigurationBuilder> {
	private boundingBoxClassName: string = "d3-histogram-bounding-box"
	private timelineContainerClassName: string = "d3-timeline-container"
	private timelineSpacing = 50

	// Children
	private yAxis?: D3HistogramYAxis
	private xAxis?: D3HistogramXAxis
	private bars?: D3HistogramBars
	private horizontalLines?: D3HorizontalLines
	private clipPath?: D3ClipPath
	private legend?: D3ModalityGraphLegend

	// PUBLIC

	public updateChildren() {
		this.yAxis?.updateConfig(this.configBuilder.getYAxisConfig())
		this.xAxis?.updateConfig(this.configBuilder.getXAxisConfig())
		this.timeline?.updateConfig(this.configBuilder.getTimelineConfig())
		this.bars?.updateConfig(this.configBuilder.getBarsConfig())
		this.horizontalLines?.updateConfig(this.configBuilder.getHorizontalLinesConfig())
		this.clipPath?.updateConfig(this.configBuilder.getClipPathConfig())
		this.legend?.updateConfig(this.configBuilder.getLegendConfig())
	}

	public viewTimesChanged = () => {
		this.timeline?.viewTimesChanged()
		this.renderBars()
	}

	public renderPage = (page: ModalityPage) => {
		this.bars?.renderPage(page)
		this.renderBars()
	}

	public onTimelineSliderDrag = () => {
		this.renderBars()
	}

	public onYAxisDrag = () => {
		this.horizontalLines?.render()
	}

	public autoScale = () => {
		const max = this.bars?.getMaximumBinCount()

		if (max) {
			this.visualization.reactCallbacks.setRootConfig(previous => ({ ...previous, countMax: max }))
		}
	}

	// PROTECTED

	protected canRender(): boolean {
		return this.visualization.boundingBox.height > 0 && this.visualization.boundingBox.width > 0
	}

	protected enter = (newElements: Selection<EnterElement, any, any, any>) => {
		const svg = newElements.append("svg").attr("class", this.className).attr("width", "100%").attr("height", "100%")

		const boundingBox = svg
			.append("g")
			.attr("class", this.boundingBoxClassName)
			.attr("transform", `translate(${this.visualization.boundingBox.x}, ${this.visualization.boundingBox.y})`)

		const timelineContainer = svg
			.append("g")
			.attr("class", this.timelineContainerClassName)
			.attr("transform", `translate(${this.visualization.boundingBox.x}, ${this.visualization.boundingBox.y + this.visualization.boundingBox.height + this.timelineSpacing})`)

		timelineContainer.each((config, index, nodes) => this.createTimeline(config, index, nodes))
		boundingBox.each((config, index, nodes) => this.createBoundingBoxChildren(config, index, nodes))
		svg.each((config, index, nodes) => this.createClipPath(config, index, nodes))

		return svg
	}

	private createBoundingBoxChildren(config: HistogramConfig, index: number, nodes: ArrayLike<SVGGElement>) {
		const root = nodes[index]
		this.yAxis = new D3HistogramYAxis(root, this.configBuilder.getYAxisConfig(), this.visualization.reactCallbacks)
		this.xAxis = new D3HistogramXAxis(root, this.configBuilder.getXAxisConfig(), this.visualization.reactCallbacks)
		this.horizontalLines = new D3HorizontalLines(root, this.configBuilder.getHorizontalLinesConfig())
		this.bars = new D3HistogramBars(root, this.configBuilder.getBarsConfig(), this.visualization.timeSeriesPageManager, this.visualization.reactCallbacks)
		this.legend = new D3ModalityGraphLegend(root, this.configBuilder.getLegendConfig(), this.visualization.reactCallbacks)
	}

	private createClipPath(config: HistogramConfig, index: number, nodes: ArrayLike<SVGSVGElement>) {
		const root = nodes[index]
		this.clipPath = new D3ClipPath(root, this.configBuilder.getClipPathConfig(), this.visualization.reactCallbacks)
	}

	protected update = (updatedSVG: Selection<any, any, any, any>): Selection<any, any, any, any> => {
		const svg = updatedSVG

		svg.select("." + this.timelineContainerClassName).attr(
			"transform",
			`translate(${this.visualization.boundingBox.x}, ${this.visualization.boundingBox.y + this.visualization.boundingBox.height + this.timelineSpacing})`
		)

		this.renderChildren()

		return svg
	}

	private createTimeline = (config: HistogramConfig, index: number, nodes: ArrayLike<SVGGElement>) => {
		const root = nodes[index]
		this.timeline = new D3Timeline(root, this.configBuilder.getTimelineConfig(), this.visualization.timeSeriesPageManager, this.visualization.reactCallbacks)
	}

	private renderChildren = () => {
		this.yAxis?.render()
		this.xAxis?.render()
		this.timeline?.render()
		this.horizontalLines?.render()
		this.renderBars()
	}

	private renderBars = throttledRender(() => this.bars?.render(), ANIMATION_TIME)
}
