import { useSetRecoilState } from "recoil"
import { hotkeysEnabledAtom } from "../../Pages/Data/Visualize/DataReview/Atoms/Hotkeys"
import { ChangeEvent, KeyboardEvent, forwardRef, useLayoutEffect, useState } from "react"
import { MobergInputLabel } from "./MobergInputHeader"
import React from "react"
import { MobergColumn } from "../MobergLayout/MobergColumn"
import { MobergRow } from "../MobergLayout/MobergRow"

type MobergShortTextInputProps = {
	limit: number
	label?: string
	placeholder?: string
	defaultValue?: string
	value?: string
	orientation?: "row" | "column"
	onChange?: (value: string) => void
    onSubmit?: () => void
}

export const MobergShortTextInput = forwardRef<HTMLInputElement, MobergShortTextInputProps>(({ limit, label, placeholder, orientation, defaultValue = "", value:controlledValue, onChange, onSubmit }, ref) => {
	const setHotkeysEnabled = useSetRecoilState(hotkeysEnabledAtom)
	const [value, setValue] = useState<string>(defaultValue)

	useLayoutEffect(() => {
		if (controlledValue && controlledValue !== value) {
			setValue(controlledValue)
		}
	}, [controlledValue, value])

	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value

		if (value.length > limit) {
			return
		}

		setValue(event.target.value)

		if (onChange) {
			onChange(event.target.value)
		}
	}

    const checkForSubmit = (event: KeyboardEvent<HTMLInputElement>) => { 
        if (event.key === 'Enter' && onSubmit) {
            onSubmit()
        }
    }

	const content = (
		<>
		{label && <MobergInputLabel text={label} style ={{ whiteSpace: "nowrap" }} />}

		<input
			ref={ref}
			value={value}
			placeholder={placeholder}
			onFocus={() => setHotkeysEnabled(false)}
			onBlur={() => setHotkeysEnabled(true)}
			onChange={handleChange}
			onKeyDown={checkForSubmit}
			type={"text"}
			style={{ padding: "8px 16px", borderRadius: "6px", border: "1px solid #cdcdcd" }}
		/>
		</>
	)

	return (
		<>
		{orientation === "row" 
			? <MobergRow gap="16px"> {content} </MobergRow>
			: <MobergColumn gap="8px"> {content} </MobergColumn>
		}
		</>
	)
})
