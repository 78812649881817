import { useRef, useState } from "react"
import Xarrow, { Xwrapper } from "react-xarrows";
import Draggable from "react-draggable";
import ConnectionManager from "../../../../../Managers/PipelineManager/ConnectionManager.ts"
import { DraggableNode } from "./DraggableNode"

const GRID_SIZE = 20
const BACKGROUND_WIDTH = 3000
const BACKGROUND_HEIGHT = 3000

const styles = {
    position: "relative", // This sets clipping for absolutely positioned nodes
    height: "80vh",
    width: "80vw",
    border: '1px solid black',
    borderRadius: "20px",
    overflow: "hidden",
    backgroundSize: `${GRID_SIZE}px ${GRID_SIZE}px`
}

export const Container = ({ props }) => {
  const { state, setState, setCanvasViewBoxPosition } = props
  const backgroundRef = useRef()
  const containerRef = useRef()
  const [backgroundDraggingEnabled, setBackgroundDraggingEnabled] = useState(true)

  function deleteConnection(connection) {
    setState(prevState => {
      const prevStateCopy = {...prevState}
      prevStateCopy.connections = ConnectionManager.deleteConnection(connection, prevStateCopy.connections)
      return prevStateCopy
    })
  }

  function onBackgroundDrag(event, draggableData) {
    if (backgroundRef.current?.style) {
      backgroundRef.current.style.cursor = "grabbing"
    }
  }

  function onBackgroundDragStop(event, draggableData) {
    const {x, y} = draggableData
    setCanvasViewBoxPosition({x: -x, y: -y})

    if (backgroundRef.current?.style) {
      backgroundRef.current.style.cursor = "grab"
    }
  }

  return (
      <div style={styles} ref={containerRef} onContextMenu={e => {e.preventDefault()}}>
        <Draggable nodeRef={backgroundRef} 
          onDrag={onBackgroundDrag} 
          onStop={onBackgroundDragStop} 
          disabled={!backgroundDraggingEnabled} 
          bounds={{
          right: 0,
          bottom: 0,
          top: containerRef.current?.clientHeight - BACKGROUND_HEIGHT, 
          left: containerRef.current?.clientWidth - BACKGROUND_WIDTH
          }}>

          <div ref={backgroundRef} 
            style={{
              position: "absolute", 
              width: `${BACKGROUND_WIDTH}px`, 
              height: `${BACKGROUND_HEIGHT}px`, 
              background: "repeating-linear-gradient(#ccc 0 1px, transparent 1px 100%), repeating-linear-gradient(90deg, #ccc 0 1px, transparent 1px 100%)",
              backgroundSize: `${GRID_SIZE}px ${GRID_SIZE}px`,
              zIndex: 0, 
              cursor: "grab"
            }}
            onContextMenu={e => {e.preventDefault()}}>
            
            <Xwrapper>
            {/* Nodes */}
            {Object.keys(state.nodes).map((key, index) => (
                <DraggableNode 
                    key={key}
                    props={{...state.nodes[key], 
                    id: key, 
                    state, 
                    setState, 
                    parentHeight: backgroundRef.current?.clientHeight, 
                    parentWidth: backgroundRef.current?.clientWidth,
                    setBackgroundDraggingEnabled}}>
                </DraggableNode>
            ))}

            {/* Connections */}
            {Object.values(state.connections).map(connectionList => 
                connectionList.map(connection => 
                <Xarrow 
                    key={connection.id}
                    start={connection.source.id} 
                    end={connection.target.id}
                    color={connection.color}
                    curveness={connection.curveness}
                    headSize={6}
                    strokeWidth={2.5}
                    startAnchor="right"
                    endAnchor="left"
                    passProps={{
                    cursor: "pointer",
                    onClick: () => deleteConnection(connection)}}>
                </Xarrow>
                ))
            }
            </Xwrapper>
          </div>
        </Draggable>
      </div>
  )
}
