import { useModalProvider } from '../../../../Providers/ModalProvider';
import * as MdIcons from 'react-icons/md';
import ConfirmationModalButton, { buttonColor } from '../../../../Components/ConfirmationModalButton/ConfirmationModalButton';
import { ConfirmationModalContainer, ConfirmationModalContent } from '../../../../Constants/StyledComponentsConfirmationModals';

const DeleteEditAnnotationModal = (props) => {

    const { close } = useModalProvider()

    async function handleDeleteAnnotation() {
        props.deleteAction()
    }

    return (
        <ConfirmationModalContainer>
            <ConfirmationModalContent>
                <MdIcons.MdOutlineError size={81} style={{color: "#AEB7C6", marginBottom: "20px"}}/>
                <h1 style={{fontFamily: 'Montserrat', fontStyle: "normal", fontWeight: "700", fontSize: "24px", lineHeight: "110%", color: "#000000"}}>Delete {props.isHotkeysModal ? 'shortcut' : 'annotation'}?</h1>
                <p>You are about to delete this {props.isHotkeysModal ? 'shortcut' : 'annotation'}.</p>
                <p>This action can not be undone.</p>
            </ConfirmationModalContent>
            <ConfirmationModalButton 
                buttonText={`Yes, delete ${props.isHotkeysModal ? 'shortcut' : 'annotation'}`}
                color={buttonColor.redFill}
                onClickFxn={() => {handleDeleteAnnotation()}}
            />

            <ConfirmationModalButton 
                buttonText="Cancel"
                color={buttonColor.blueOutline}
                onClickFxn={() => {close()}}
            />
        </ConfirmationModalContainer>
    )
}


export default DeleteEditAnnotationModal;