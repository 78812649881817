import { useState, useContext, useEffect, useCallback, useRef } from 'react';
import { TableContext } from './TableContext'
import styled from "styled-components";


// SearchBarComponent
export function SearchField({initialQuery="", ...props}) {
    // columns is not the column names displayed on the frontend but the corresponded attribute names
    // for example, "Patient ID" is wrong, it should be "patient_id", so we can use row["patient_id"]

    const { displayedColumns, searchQuery, setSearchQuery, updateTableTimeout } = useContext(TableContext)
    const [inputValue, setInputValue] = useState(initialQuery)
    
    useEffect(() => { // initialQuery might change later
        setInputValue(initialQuery)
        // updateSearch(initialQuery)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialQuery])

    const searchBarRef = useRef()

    useEffect(() => {
        let newSearch = {}

        if (inputValue.includes(":")) {
            let [columnString, ...keywords] = inputValue.split(":")

            // user can use "Columns:AB:CD" to search time AB:CD. however "AB:CD" won't work in the right way
            keywords = keywords.join(":").trim()

            let columns = columnString.split(",").map(col=>col.trim()) // "Column1, Column2" --> ["Column1", "Column2"]
            columns = displayedColumns.filter(col=>columns.includes(col.name)) // only keep the valid columns

            // use ":Xxxx" to search means use all the displayed columns as targets
            // also, if all columns provided by user are invalid ("Aaa:Bbb" while Aaa is not a column name)
            // we also use all the displayed columns as searching targets
            columns = columnString.trim().length > 0 ? columns.map(col => col.attr) :
                displayedColumns.map(col => col.attr).filter(attr=>attr!==undefined)
            
            newSearch = {columns, keywords}

        } else { // use default search.columns
            newSearch = { // non-column specified, use all the column
                columns: displayedColumns.map(col => col.attr).filter(attr=>attr!==undefined),
                keywords: inputValue.trim()
            }
            // console.log(newSearch)
        }
        // console.log("search!!", newSearch)

        // let [columnString, ...keywords] = inputValue.split(":")
        // keywords = keywords.join(":").trim()

        // let columns = columnString.split(",").map(col=>col.trim()) // "Column1, Column2" --> ["Column1", "Column2"]
        // columns = displayedColumns.filter(col=>columns.includes(col.name)) // only keep the valid columns

        // // use ":Xxxx" or "Xxx" to search means use all the displayed columns as targets
        // // also, if all columns provided by user are invalid ("Aaa:Bbb" while Aaa is not a column name)
        // // we also use all the displayed columns as searching targets
        // columns = columns.length > 0 ? columns.map(col => col.attr) : displayedColumns.map(col => col.attr).filter(attr=>attr!==undefined)
        
        // const newSearch = {columns, keywords}

        if (searchQuery.keywords!== newSearch.keywords || // check if Search Query does change or not
            searchQuery.columns.length!==newSearch.columns.length ||
            searchQuery.columns.filter(col => !newSearch.columns.includes(col)).length > 0) {
            setSearchQuery(newSearch)
            // setPageIndex(1)
        }
        
    }, [inputValue, displayedColumns, searchQuery, setSearchQuery])

    // const [isEditing, setIsEditing] = useState(false)
    // const timeoutID = useRef(null)

    // inputValue --> filterRules.search
    // useEffect(() => {
    //     if (!isEditing || timeoutID.current !== null) {
    //         // it could be triggered by onBlur(isEditing=false) or Timer
    //         clearTimeout(timeoutID.current) // both cases need us clear the Timer
    //         timeoutID.current = null
    //         // console.log("update search from input!")
    //         updateSearch(inputValue)
    //     }
    // }, [isEditing, inputValue, updateSearch])

    const onChange = useCallback((event) => {
        event.stopPropagation()
        setInputValue(event.target.value)
        // updateSearch(event.target.value)
        // if (timeoutID.current) {
        //     clearTimeout(timeoutID.current)
        // }
        
        // searchBarRef.current.value --> inputValue --> filterRules.search
        // timeoutID.current = setTimeout(() => {
        //     setInputValue(searchBarRef.current.value)
        // }, 1500)

    }, [])

    const onKeyDown = useCallback((event) => {
        event.stopPropagation()
        if (event.key === "Enter") {
            // searchBarRef.current.removeEventListener("input", inputHandler)
            // setInputValue(searchBarRef.current.value)
            // searchBarRef.current.removeEventListener("keydown", keydownHandler)
            searchBarRef.current.blur()
            // setIsEditing(false)
            updateTableTimeout(0) // update immediately
        } else {
            updateTableTimeout(500) // update the table content after 0.5 sec
        }
    }, [updateTableTimeout]) // only reconstruct when table updated

    const args = {
        type: "text",
        value: inputValue,
        placeholder: "Column1, Column2 ... : Keywords",
        ref: searchBarRef,
        // onFocus: () => searchBarRef.current.addEventListener("keydown", keydownHandler),
        // onBlur: () => searchBarRef.current.removeEventListener("keydown", keydownHandler),
        onChange,
        onKeyDown,
        // onFocus: () => {
        //     // setIsEditing(true)
        //     // searchBarRef.current.removeEventListener("input", inputHandler)
        //     // searchBarRef.current.removeEventListener("keydown", keydownHandler)

        //     // searchBarRef.current.addEventListener("input", inputHandler)
        //     searchBarRef.current.addEventListener("keydown", keydownHandler)
        // },
        // onBlur: () => {
        //     // searchBarRef.current.removeEventListener("input", inputHandler)
        //     searchBarRef.current.removeEventListener("keydown", keydownHandler)
        // },
        style: {width: "350px"},
        ...props,
     }
    return (
        <SearchInput {...args} />
    )
}

const SearchInput = styled.input`
    width: 350px;
    height: 30px;
    padding: 5px;
    border: solid;
    border-color: #e0e0e1;
    border-radius: 5px;
    border-width: 1px;
    margin: 5px;
`;
