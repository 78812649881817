import { select, Selection, EnterElement, ScaleTime, CountableTimeInterval } from "d3"
import { Renderable } from "../../Types/Renderable"

export type D3VerticalLinesConfig = {
	xScale: ScaleTime<any, any, any>
	height: number
	color?: string
	opacity?: number
	tickInterval?: CountableTimeInterval
}

export class D3VerticalLines implements Renderable {
	private root: SVGGElement
	private config: D3VerticalLinesConfig
	private wrapperClassName = "d3-vertical-lines"

	constructor(root: SVGGElement, config: D3VerticalLinesConfig) {
		this.root = root
		this.config = config

		const rootNode = select(root).append("g").attr("class", this.wrapperClassName).node()

		if (rootNode != null) {
			this.root = rootNode
		}

		this.render()
	}

	public updateConfig = (config: D3VerticalLinesConfig) => {
		this.config = config
		this.render()
	}

	public render = () => {
		select(this.root)
			.selectAll("line")
			.data(this.config.xScale.ticks(this.config.tickInterval as number | undefined))
			.join(this.enter.bind(this), this.update.bind(this), this.exit.bind(this))
	}

	private enter = (newElements: Selection<EnterElement, Date, any, any>): Selection<any, any, any, any> => {
		const lines = newElements
			.append("line")
			.attr("x1", this.scaleDate)
			.attr("x2", this.scaleDate)
			.attr("y2", this.config.height)
			.attr("stroke", this.config.color ?? "lightgray")
			.attr("stroke-opacity", this.config.opacity ?? 0.7)

		return lines
	}

	private update = (updatedVerticalLines: Selection<any, any, any, any>): Selection<any, any, any, any> => {
		return updatedVerticalLines
			.attr("x1", this.scaleDate)
			.attr("x2", this.scaleDate)
			.attr("y2", this.config.height)
	}

	private exit(exitedElements: Selection<any, any, any, any>) {
		exitedElements.remove()
	}

	private scaleDate = (date: Date) => {
		return this.config.xScale(date)
	}
}
