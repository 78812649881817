import * as MdIcons from 'react-icons/md';
import { useUploadProcessProvider } from "../../../../../Providers/UploadProcessProvider";
import {DataTypeContentContainer, TableHeaderText} from "../../../../../Constants/StyledComponents";
import MUITable from "../../../../../Components/MUITable/MUITable"

const getColumns = label => {
	const columnMappings = {
		"Moberg Data": [
			{ field: "directoryName", flex: 1, headerName: "Directory Name", visible: true },
			{ field: "fileSize", flex: 1, headerName: "Directory Size", visible: true },
			{ field: "modalities", flex: 1, headerName: "Modalities", visible: true },
		],
		Natus: [
			{ field: "directoryName", flex: 1, headerName: "Directory Name", visible: true },
			{ field: "fileSize", flex: 1, headerName: "Directory Size", visible: true },
		],
		Medications: [
			{ field: "directoryName", flex: 1, headerName: "File Name", visible: true },
			{ field: "fileSize", flex: 1, headerName: "File Size", visible: true },
			{ field: "nMedications", flex: 1, headerName: "Medications", visible: true },
		],
		Form: [
			{ field: 'formName', flex: 1, headerName: 'Form Name', visible: true }
		]
	}

	return columnMappings[label]
}


const UploadSummaryData = ({ label }) => {
	const uploadProcessProvider = useUploadProcessProvider()

	const columns = getColumns(label)

    const tableName = label === "Moberg Data" ? "cns" : label.toLowerCase()

	return (
		<DataTypeContentContainer>
			<div style={{ height: "300px", overflowY: "auto" }}>
				<TableHeaderText style={{ padding: '10px 20px 0px' }}>
					<MdIcons.MdCloudUpload size={18} style={{ marginTop: "-3px" }} /> Uploads
				</TableHeaderText>
				<MUITable columns={columns} tableData={label === 'Form' ? [uploadProcessProvider.submittedForm] : uploadProcessProvider[`${tableName}TableData`]} isCheckboxSelection={false} />
			</div>
		</DataTypeContentContainer>
	)
}

export default UploadSummaryData
