
// Check if any of the values are in the array as fast as possible.
export function anyValuesInArray(array: any[], values: any[]) {
    for (let i = 0; i < array.length; i++) {
        for (let j = 0; j < values.length; j++) {
            if (array[i] === values[j]) {
                return true
            }
        }
    }

    return false
}