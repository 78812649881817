import React from 'react';
import styled from 'styled-components';
import { MobergButton, MobergTheme, MobergButtonVariant } from '../../Moberg';

const TimeoutModal = ({ removeModal, logoutTime }) => {
    const date = new Date(logoutTime)
    return (
        <TimeoutModalContainer>
            <h5 style={{ margin: 0, fontSize: '1.25rem', padding: '15px 10px' }}>
                Session Timeout
            </h5>

            <div style={{ flex: 1, padding: '1rem', border: '1px solid rgba(0, 0, 0, 0.175)', borderLeft: 'none', borderRight: 'none' }}>
                Your session is about to expire at {date.toLocaleTimeString('en-US')} due to inactivity. You will be redirected to the login page.
            </div>

            <div style={{ display: 'flex', justifyContent: 'end' }}>
                <MobergButton
                    theme={MobergTheme.BLUE}
                    variant={MobergButtonVariant.FILLED}
                    onClick={removeModal}
                    style={{ margin: '10px' }}
                >
                    Stay Logged In
                </MobergButton>
            </div>
        </TimeoutModalContainer>
    )
}

export default TimeoutModal

const TimeoutModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`