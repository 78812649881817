import { useEffect } from "react";

/**
 * @name useHandler
 * @category Hooks
 * 
 * @description
 * Custom Hook that installs an event listener on the whole document
 * @param {string} event
 * @param {function} handler
 */
 export function useHandler(event, handler, options={}) {
    useEffect(() => {
		document.addEventListener(event, handler, options)
		return () => document.removeEventListener(event, handler, options)
	}, [event, handler, options])
}
