export default function UserInputWrapper(props) {
    return <div style={{
        display: "flex",
        justifyContent: "start",
        padding: "0 10px",
        width: "100%",
        margin: "5px",
        alignItems: "center"
    }}>
        {props.children}
    </div>
}