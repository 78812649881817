import { useController } from "react-hook-form"
import { Chip, Tooltip, FormHelperText } from "@mui/material"
import { alpha, styled } from "@mui/material/styles"
import { useTheme } from "@mui/material/styles"
import { useEffect, useRef, useState } from "react"
import InputWrapper from "./InputWrapper"
import { Popper } from "@material-ui/core"
import { MobergBoxShadow } from "../MobergThemes/MobergStyles"
import { Scrollbar } from "../../Constants/StyledComponents"

const divStyles = ({ theme, error, isFocused }) => ({
	display: "flex",
	flexWrap: "wrap", // Allow items to wrap to the next line
	alignItems: "flex-start", // Align items to the start
	gap: "5px",
	marginTop: theme.spacing(3),
	borderRadius: "4px",
	backgroundColor: theme.palette.mode === "light" ? "#F3F6F9" : "#1A2027",
	border: "1px solid",
	borderColor: isFocused ? theme.palette.primary.main : error ? "red" : theme.palette.mode === "light" ? "#E0E3E7" : "#2D3843",
	boxShadow: isFocused ? `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem` : "none",
	width: "100%",
	padding: "3px 14px",
	transition: theme.transitions.create(["border-color", "background-color", "box-shadow"]),
	
})

const StyledInput = styled("input")(({ theme }) => ({
	flex: 1,
	border: "none",
	outline: "none",
	backgroundColor: "transparent",
	padding: "10px 0",
	fontSize: 16,
}))

export default function MultivalueTextField(props) {
	const { name, control, label, tooltip, inputId, placeholder, autoFocus, disabled = false, numberColumn, errors, required = true, setterFunction, deleteFunction, preselect, styles = {} } = props
	const [isFocused, setIsFocused] = useState(false)
	const [inputValue, setInputValue] = useState("")
	const theme = useTheme()
	const anchorEl = useRef(null)

	const {
		field: { ref, value, onChange, ...inputProps },
		fieldState: { error },
	} = useController({
		name,
		control,
	})
	const [chips, setChips] = useState(preselect ? preselect : value)

	function handleEnterInput(value) {
		if (setterFunction) {
			setterFunction(value, setChips)
		} else {
			const cleanedInputValue = value.trim().toLowerCase().replace(/\s/g, "")
			if (cleanedInputValue && !chips.includes(cleanedInputValue)) {
				const newChips = [...chips, cleanedInputValue]
				setChips(newChips)
				onChange(newChips)
			}
			setInputValue("")
		}
	}

	const handleKeyDown = event => {
		if (event.key === "Enter") {
			handleEnterInput(event.target.value)
			setInputValue("")
			event.preventDefault()
		}
	}

	const handleDelete = chipToDelete => () => {
		const newChips = chips.filter(chip => chip !== chipToDelete)
		setChips(newChips)
		if (deleteFunction) {
			deleteFunction(chipToDelete)
		} else {
			onChange(newChips)
		}
	}

	useEffect(() => {
		if (!preselect || preselect === chips) return
		setChips(preselect)
	}, [chips, preselect])

	const regex = /^[^\s@]+@\w+\.\w+$/
	const open = setterFunction ? inputValue !== '' : regex.test(inputValue)
	const id = open ? 'email-popper' : undefined

	return (
		<InputWrapper inputId={inputId} label={label} tooltip={tooltip} error={errors} numberColumn={numberColumn} required={required}>
			<Scrollbar style={{...divStyles({ theme, error: !!error, isFocused }), ...styles}} tabIndex="-1" onBlur={() => setIsFocused(false)} ref={anchorEl}>
				{chips.map((chip, index) => {
					const chipError = error ? error[index]?.message || false : false
					return (
						<Tooltip title={chipError} placement="top">
							<Chip key={chip} label={chip} onDelete={handleDelete(chip)} color={"info"} style={{ margin: "6px 0px 4px", fontSize: '16px' }} />
						</Tooltip>
					)
				})}
				<StyledInput
					value={inputValue}
					onChange={e => setInputValue(e.target.value)}
					onKeyDown={handleKeyDown}
					placeholder={placeholder}
					id={inputId}
					autoFocus={autoFocus}
					disabled={disabled}
					onFocus={() => setIsFocused(true)}
					onBlur={() => setIsFocused(false)}
					{...inputProps}
				/>
				<Popper id={id} open={open} anchorEl={anchorEl.current} placement="bottom-start" disablePortal transition style={{ zIndex: 100, border: '0.5px solid #e0e3e7', borderRadius: '5px' }}>
					<div onClick={() => handleEnterInput(inputValue)} style={{ zIndex: 10000, boxShadow: MobergBoxShadow.LOW_DARK, height: '52px', background: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '5px', border: '1 solid #ccc', cursor: 'pointer' }}>
						<div style={{ padding: '10px', background: '#e0e3e7' }}>
							{inputValue}
						</div>
					</div>
				</Popper>
			</Scrollbar>
			
			<Popper id='ErrorMessage' open={errors !== ''} anchorEl={anchorEl.current} placement="bottom-start" disablePortal transition style={{ zIndex: 99 }}>
				<FormHelperText error>
					{errors ? errors : (error && error?.length !== 0) ? error[error?.length - 1]?.message : ''}
				</FormHelperText>
			</Popper>
		</InputWrapper>
	)
}
