import React from "react"
import styled from "styled-components"
import PageSizeIcon from "./PageSizeIcon.svg"
import { TimePreset } from "../../../../../../Managers/VisualizationManager/Viewport/Components/XAxis"
import { SetterOrUpdater, useRecoilValue } from "recoil"
import { TimeBasedVisualizationConfig } from "../../Types/TimeBasedVisualizationConfig"
import { MdEast, MdModeEdit } from "react-icons/md"
import { BiLink, BiUnlink } from "react-icons/bi"
import { MobergButton, MobergButtonShape } from "../../../../../../Components/MobergButton/MobergButton"
import { MobergIconSize } from "../../../../../../Components/MobergIcon/MobergIcon"
import { MobergDropdown, MobergDropdownSize, MobergDropdownValue } from "../../../../../../Components/MobergDropdown/MobergDropdown"
import { MobergBoxShadow } from "../../../../../../Components/MobergThemes/MobergStyles"
import { MobergTheme } from "../../../../../../Components/MobergThemes/MobergColors"
import { MobergFontSize } from "../../../../../../Components/MobergFont/MobergFont"
import TimeSelector from "../../../../../../Managers/VisualizationManager/ToolBar/Components/TimeSelector"
import { useModalProvider } from "../../../../../../Providers/ModalProvider"
import { AiOutlineFieldTime } from "react-icons/ai"
import { MobergColumn, MobergRow } from "../../../../../../Moberg"
import { MobergText } from "../../../../../../Components/MobergHeader/MobergHeader"
import { currentPatientFileInfoAtom } from "../../Atoms/PatientFile"
import { getClampedStartAndEnd } from "../Visualizations/clamping"

export function formatDuration(milliseconds: number) {
	if (isNaN(milliseconds)) {
		milliseconds = 0
	}

	const seconds = Math.floor(milliseconds / 1000)
	const minutes = Math.floor(seconds / 60)
	const hours = Math.floor(minutes / 60)
	const days = Math.floor(hours / 24)

	let formattedDays = ""
	if (days === 0) {
		formattedDays = ""
	} else if (days === 1) {
		formattedDays = `${days} Day `
	} else {
		formattedDays = `${days} Days `
	}
	const formattedHours = hours % 24
	const formattedMinutes = minutes % 60
	const formattedSeconds = seconds % 60

	return `${formattedDays}${String(formattedHours).padStart(2, "0")}:${String(formattedMinutes).padStart(2, "0")}:${String(formattedSeconds).padStart(2, "0")}`
}

type VisualizationToolbarProps = {
	setAtom: SetterOrUpdater<any>
	renderConfigureModal: () => void
	atomValue: TimeBasedVisualizationConfig
	viewDuration: number
	timePresetOptions: TimePreset[]
	visualizationSpecificSettings?: JSX.Element | JSX.Element[]
	actionButtons?: JSX.Element | JSX.Element[]
}

export const VisualizationToolbar = ({
	viewDuration,
	visualizationSpecificSettings,
	actionButtons,
	timePresetOptions,
	atomValue,
	setAtom,
	renderConfigureModal,
}: VisualizationToolbarProps) => {
	const currentWindowTimePreset = timePresetOptions.find(preset => preset.time === viewDuration) || timePresetOptions[0]
	const { isAdmitted } = useRecoilValue(currentPatientFileInfoAtom)
	const { createModal } = useModalProvider()

	const [fileStartDate, fileEndDate] = atomValue.fileScale.domain()

	const updateViewDuration = (value: MobergDropdownValue) => {
		const preset = timePresetOptions.find(preset => preset.time === value && preset.label !== "Page Size")

		if (!preset) {
			return
		}

		setAtom((previous: TimeBasedVisualizationConfig) => {
			const viewStart = previous.viewScale.domain()[0]
			const newEndTime = viewStart.getTime() + preset.time
			const newStartTime = newEndTime - preset.time

			const { start, end } = getClampedStartAndEnd(newStartTime, newEndTime, previous.fileScale)

			return {
				...previous,
				viewScale: previous.viewScale.domain([start, end]),
			}
		})
	}

	// Recoil callback allows us to read the live end date without subscribing to the value in this component.
	const handleLiveModeToggle = () => {
		// Update live mode enabled and disable linking on the current window only.
		setAtom((previous: TimeBasedVisualizationConfig) => (
			{ 
				...previous, 
				liveModeEnabled: !previous.liveModeEnabled,
				isLinked: false
			}
		))
	}

	function goToTime(start: number, end: number) {
        setAtom((previous: TimeBasedVisualizationConfig) => ({...previous, viewScale: previous.viewScale.domain([start, end])}))
    }

    function renderTimeSelector() {
		const [currentTime, currentEndTime] = atomValue.viewScale.domain()

		createModal(<TimeSelector
			style={{ width: '423px' }}
			calendar={true}
			currentTime={currentTime.getTime()}
			currentEndTime={currentEndTime.getTime()}
			minTime={fileStartDate}
			maxTime={fileEndDate}
			onChange={goToTime}
			interval={true}
            showX={true}
		/>)
	}

	return (
		<WindowSettingsBar>
			<div style={{ flex: 1, display: "flex", gap: "16px", justifyContent: "left" }}>
				<MobergRow gap="8px" expand={false}>
					<MobergColumn horizontalAlign="center" style={{ lineHeight: "90%" }}>
						<MobergText style={{ fontWeight: "bold" }}>
							{formatDuration(viewDuration)}
						</MobergText>
						<div>
							<img src={PageSizeIcon} height={8} alt="PageSizeIcon" />
						</div>
					</MobergColumn>

					<MobergDropdown
						size={MobergDropdownSize.SMALL}
						selectedValue={currentWindowTimePreset.time}
						onChange={updateViewDuration}
						options={timePresetOptions.map(preset => ({ label: preset.label, value: preset.time })).filter(preset => preset.label !== "Page Size")}
					/>
				</MobergRow>

				{visualizationSpecificSettings}
			</div>

			<div style={{ flex: 1, display: "flex", gap: "4px", alignItems: "center", justifyContent: "center"}}>
				{actionButtons}

				<MobergButton 
					onClick={renderTimeSelector}
					shape={MobergButtonShape.SQUARE}
					disabled={atomValue.liveModeEnabled}
					tooltip="Set the time range manually"
				>
					<AiOutlineFieldTime size={MobergIconSize.SMALL}/>
				</MobergButton>

				<MobergButton
					shape={MobergButtonShape.SQUARE}
					theme={atomValue.isLinked ? MobergTheme.BLUE : MobergTheme.BLACK}
					tooltip="Link the start time of this window to the start time of other linked windows"
					active={atomValue.isLinked}
					disabled={atomValue.liveModeEnabled}
					onClick={() => setAtom((previous: TimeBasedVisualizationConfig) => ({ ...previous, isLinked: !previous.isLinked }))}
				>
					{atomValue.isLinked ? <BiLink size={MobergIconSize.SMALL} /> : <BiUnlink size={MobergIconSize.SMALL} />}
				</MobergButton>

				<MobergButton
					theme={atomValue.liveModeEnabled ? MobergTheme.BLUE : MobergTheme.BLACK}
					tooltip={isAdmitted 
						? "Lock the window to the latest data" 
						: "Lock the window to the latest data (disabled because the patient is not being recorded)"
					}
					active={atomValue.liveModeEnabled}
					disabled={!isAdmitted}
					shape={MobergButtonShape.SQUARE}
					fontSize={MobergFontSize.SMALL}
					onClick={handleLiveModeToggle}
					style={{ gap: "4px" }}
				>
					<MdEast size={MobergIconSize.SMALL} viewBox={"0 0 25 25"}/>
				</MobergButton>
				
			</div>

			<div style={{ flex: 1, display: "flex", alignItems: "center", justifyContent: "right"}}>
				<MobergButton
					onClick={renderConfigureModal}
					shape={MobergButtonShape.SQUARE}
					theme={MobergTheme.BLUE}
					tooltip={"Edit window settings"}
				>
					<MdModeEdit size={MobergIconSize.SMALL} />
				</MobergButton>
			</div>


		</WindowSettingsBar>
	)
}

// TODO: at some point, these need to be replaced with standard form components
const WindowSettingsBar = styled.div`
	display: flex;
	gap: 32px;
	align-items: center;
	height: 50px;
	white-space: nowrap;
	border-radius: 6px 6px 0 0;
	box-shadow: ${MobergBoxShadow.LOW};
	padding: 5px;
	justify-content: space-between;
	overflow-x: auto;
`

export const SettingContainer = styled.div`
	display: flex;
	align-items: center;

	p {
		font-family: "Source Sans Pro";
		font-style: normal;
		font-weight: 700;
		font-size: 14px;
		line-height: 150%;
		text-align: center;
		color: #293241;
		margin: 0;
	}
`

export const SettingDropdown = styled.select`
	border-radius: 6px;
	display: flex;
	justify-content: center;
	text-align: center;
`
