import React, { useRef } from "react"
import { DimensionsProvider } from "../../../../../../../../Providers/DimensionsProvider"
import { selectedLayoutIdAtom } from "../../../../Atoms/Layout"
import { useRecoilValue } from "recoil"
import { histogramConfigAtom } from "../../../../Atoms/Histogram"
import { currentPatientFileInfoAtom } from "../../../../Atoms/PatientFile"
import { D3Histogram, HistogramConfig } from "../D3/D3Histogram"
import { HistogramJSON } from "../../../../Types/Histogram"
import { useD3KeyboardShortcuts } from "../../../../Hooks/useD3KeyboardShortcuts"
import { hotkeyActions } from "../../../../Types/KeyboardShortcut"
import { HistogramWindowSettings } from "./HistogramWindowSettings"
import { useD3CheckReloadData } from "../../../../Hooks/useD3CheckReloadData"
import { pageManagerRegistry } from "../../../../Data/PageManagerRegistry"
import { viewScaleRegistry } from "../../../../Data/ViewScaleRegistry"
import { VisualizationComponent } from "../../../../VisualizationComponentFactory"
import { fileScaleRegistry } from "../../../../Data/FileScaleRegistry"
import { ModalityPageManager } from "../../../../Data/ModalityPageManager"
import { useD3Controller } from "../../../../Hooks/useD3Controller"
import { useSocketProvider } from "../../../../../../../../Providers/SocketProvider"

type HistogramProps = {
	windowId: string
	json: HistogramJSON
}

export const Histogram = (props: HistogramProps) => {
	return (
		<div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
			<div style={{ flex: 0 }}>
				<HistogramWindowSettings windowId={props.windowId} />
			</div>
			<div style={{ flex: 1 }}>
				<DimensionsProvider>
					<HistogramVisualization {...props} />
				</DimensionsProvider>
			</div>
		</div>
	)
}

export const HistogramVisualization = (props: HistogramProps) => {
	const domNode = useRef<HTMLDivElement>(null)
	const { fileStartDate, fileEndDate } = useRecoilValue(currentPatientFileInfoAtom)
	const { getDataQuerySocket } = useSocketProvider()


	const selectedLayoutId = useRecoilValue(selectedLayoutIdAtom)
	const visualizationId = { layoutId: selectedLayoutId as string, windowId: props.windowId }
	const atom = histogramConfigAtom(visualizationId)
	const config = useRecoilValue(atom)

	const viewScale = viewScaleRegistry.get(visualizationId, VisualizationComponent.HISTOGRAM, { viewDuration: props.json.viewDuration ?? 60 * 1000, fileStartDate, fileEndDate })
    const [viewStart, viewEnd] = viewScale.domain()
	const viewDuration = viewEnd.getTime() - viewStart.getTime()

	const fileScale = fileScaleRegistry.get(visualizationId, VisualizationComponent.HISTOGRAM, fileStartDate, fileEndDate)

	const d3Controller = useD3Controller<D3Histogram, HistogramConfig, HistogramJSON, ModalityPageManager>({
		atom,
		initProps: props.json,
		nodeRef: domNode,
		pageManager: pageManagerRegistry.get(visualizationId, VisualizationComponent.HISTOGRAM, new ModalityPageManager(), getDataQuerySocket),
		props: {
			id: props.windowId,
			countMax: config.countMax,
			viewScale,
			fileScale
		},
		d3ControllerConstructor(node, config, pageManager, callbacks) {
			return new D3Histogram(node, config, pageManager, callbacks)
		}
	})

	useD3KeyboardShortcuts({
		d3Controller,
		windowId: props.windowId,
		shortcuts: [hotkeyActions.NEXT_PAGE, hotkeyActions.PREVIOUS_PAGE, hotkeyActions.PLAY_PAUSE],
	})

    useD3CheckReloadData({
        d3Controller,
        clearDataIfChanges: {
            viewDuration,
            modalities: [...new Set(config.modalityConfigs)].sort()
        }
    })

	return <div ref={domNode} style={{ height: "100%" }}></div>
}
