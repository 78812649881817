import React, { MouseEvent, useRef, useState } from "react"
import { MobergButton, MobergButtonShape } from "../MobergButton/MobergButton"
import { useMobergMenu } from "../../Hooks/useMobergMenu"
import { selectedLayoutGroupAtom } from "../../Pages/Data/Visualize/DataReview/Atoms/Layout"
import { useRecoilValue } from "recoil"
import { MdCircle, MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md"
import { MobergIconSize } from "../MobergIcon/MobergIcon"
import { DisplayGroupDropdownSection } from "./DisplayGroupSection"
import { useWorkspacesProvider } from "../../Providers/WorkspacesProvider"
import { MobergColumn } from "../MobergLayout/MobergColumn"
import { MobergText } from "../MobergHeader/MobergHeader"
import { MobergTheme, getColors } from "../MobergThemes/MobergColors"

export const DataReviewDisplayGroupsDropdown = () => {
	const menu = useMobergMenu({ placement: "top-start" })
	const selectedLayoutGroup = useRecoilValue(selectedLayoutGroupAtom)
	const { selectedWorkspace } = useWorkspacesProvider()
	const [activeSection, setActiveSection] = useState(selectedLayoutGroup?.type ?? "Personal")
	const scrollTop = useRef<number>(0)

	const changeSection = (event: MouseEvent, section: string) => {
		event.stopPropagation()
		scrollTop.current = 0
		setActiveSection(section)
	}

	const sections = [
		{ label: "Personal", value: "Personal" },
		{ label: `Shared (${selectedWorkspace})`, value: selectedWorkspace },
	]

	return (
		<div>
			<MobergButton onClick={menu.open} style={{ border: "1px solid #cdcdcd", paddingRight: "8px" }}>
				{selectedLayoutGroup?.name ?? "Select an option..."}
				<span style={{ pointerEvents: "none" }}>
					{menu.isOpen ? <MdKeyboardArrowUp size={MobergIconSize.REGULAR} /> : <MdKeyboardArrowDown size={MobergIconSize.REGULAR} />}
				</span>
			</MobergButton>

			<menu.MobergMenu style={{ padding: 0, marginBottom: "8px" }}>
				<div style={{ display: "flex", height: "500px" }}>
					<div style={{ padding: "16px 24px", paddingRight: "16px", backgroundColor: "#f8f8f8", borderRadius: "6px 0 0 6px" }}>
						<MobergColumn gap="16px">
							<MobergText style={{ color: "gray" }}>Access</MobergText>
							<MobergColumn>
								{sections.map(section => {
									const isActive = section.value.toLowerCase() === activeSection.toLowerCase()
									const sectionHasActiveDisplayGroup = selectedLayoutGroup?.type.toLowerCase() === section.value.toLowerCase()

									return (
										<MobergButton
											key={section.label}
											shape={MobergButtonShape.FULL_WIDTH}
											theme={MobergTheme.BLACK}
											active={isActive}
											onClick={event => changeSection(event, section.value)}
											style={{ padding: "8px 16px", justifyContent: "space-between" }}
										>
											{section.label}
											<MdCircle 
												color={getColors(MobergTheme.BLUE).main}
												size={"10px"} 
												opacity={sectionHasActiveDisplayGroup ? 1 : 0}
												title={`This indicator shows which section the current display group is in.`}
											/>
										</MobergButton>
									)
								})}
							</MobergColumn>
						</MobergColumn>
					</div>

					<MobergColumn>
						<MobergText style={{ color: "gray", paddingTop: "16px", paddingLeft: "24px" }}>Display groups</MobergText>
						<DisplayGroupDropdownSection section={activeSection} menu={menu} scrollTopRef={scrollTop} />
					</MobergColumn>
				</div>
			</menu.MobergMenu>
		</div>
	)
}
