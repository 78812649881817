import { useState, useEffect } from 'react';
import React from "react";
import styled from "styled-components";
import * as MdIcons from 'react-icons/md';


// function getNumberOfPages(rowCount, rowsPerPage) {
//     return Math.ceil(rowCount / rowsPerPage)
// }

export const PaginationController = ({
    rowsPerPage,
    rowCount,
    onChangePage,
    onChangeRowsPerPage,
    currentPage,
    paginationComponentOptions
}) => {
    // As we pass arguments to the paginationComponent and paginationComponentOptions
    // properties of DataTable, it will forward the paginationComponentOptions arg to
    // paginationComponent. This is the only way we can pass args to paginationComponent.
    const {rowsEachPage=10, currentPageNum=1, switchPage, maxPageNum} = paginationComponentOptions

    const [totalRows, setTotalRows] = useState(0) // do not use useState(rowCount)!

    useEffect(()=>{
         // overwrite the 'rows per page' setting
        if (rowsEachPage !== rowsPerPage){
            onChangeRowsPerPage(rowsEachPage)
        }
    }, []) // run only once
    
    useEffect(()=>{
        if (totalRows !== rowCount) {
            setTotalRows(rowCount)

            // Example usecase 1: table init, new data get loaded into this table
            // Example usecase 2: Some rows get deleted
            // Example usecase 3: the user is using search bar to query data from current table.
            // Initially the page number is 2, but as the user add more filter requirements, the number
            // of input rows become smaller, and less than rowsPerpage. Then currentPageNum become 1.

            // Like Example usecase 3, the change of rows raises the change of page number
            if (currentPageNum !== currentPage) {
                if (currentPageNum < maxPageNum) {
                    onChangePage(currentPageNum)
                } else {
                    switchPage(currentPage)
                }
            }

        } else {
            // Example usecase 4: the user try to switch to a new page by controlling pagination
            // Example usecase 5: the user sort the table based on one column from page other
            // than the first page(e.g. the second page), the table will automatically switch
            // back to the first page(but we don't want to switch the page). (However, here we
            // can't track the user sort the table from the first page, we track it from BuildTable)
            if (currentPageNum !== currentPage) {
                // unSelectAll() // we don't need , because when PaginationComponent called switchPage, it already clear the selection
                onChangePage(currentPageNum)
                // setDisplayedPageIndex(currentPageNum)
            }

        }
    }, [totalRows, rowCount, rowsEachPage, currentPage, currentPageNum, onChangePage, switchPage])

    // PaginationController render nothing, it is just a connection between DataTable and PaginationComponent
    return null
}


export const PaginationComponent = ({currentPageNum=1, maxPageNum=1, switchPage=()=>{}}) => {
    const [inputValue, setInputValue] = useState(currentPageNum)
    const [isEditing, setIsEditing] = useState(false)

    useEffect(()=>{
        // update the input value when currentPageNum get changed by somthing outside pagination
        // Example usecase: the user is using search bar to query data from current table.
        // Initially the page number is 2, but as the user add more filter requirements, the number
        // of input rows become smaller, and less than rowsPerpage. Then currentPageNum become 1.
        // But the inputValue is still 2, so we need to change it to 1.
        if ((!isEditing) && (inputValue !== currentPageNum)) { setInputValue(currentPageNum) }
    }, [isEditing, inputValue, currentPageNum])

    const goToPage = (index) => {
        // change the page number. After we call setCurrentPageNum in PaginationComponent,
        // currentPageNum will be updated. And it will trigger the corresponding PaginationController
        // to call clearSelection(), onChangePage(currentPageNum) and setDisplayedPageIndex(currentPageNum)
        switchPage(index)
        setInputValue(index)
    }

    const keydownListener = (event) => {
        // get triggered when the user focus the page number input field and press any key
        event.stopPropagation()
        if (event.key === "Enter") {
            setIsEditing(false)
            // do not directly use [inputValue] here, it won't refresh because of js closure
            const newPageNum = Number(event.currentTarget.value)
            if (0 < newPageNum && newPageNum <= maxPageNum) {
                if (newPageNum !== currentPageNum) {
                    switchPage(newPageNum)
                }
                event.currentTarget.blur()
            } else {
                setInputValue(currentPageNum) // change back to the last valid page number
                setIsEditing(true)
                event.currentTarget.select()
            }
        }
    }

    return ( // while there is only one page, we don't need Pagination
        maxPageNum <= 1 ? null :
        <nav>
            <ul className="pagination"
                style={{
                    alignItems: "center",
                    justifyContent: "center",
                    margin: "20px 0px 0px 0px",
                    padding: "0px",
                    width: "auto",
                    textAlign: "center"
                }}
            >
                <li className="page-item">
                    <PageNavigateButton
                        style={{marginLeft: "25px", marginRight:"3px"}}
                        className="page-link"
                        onClick={()=>goToPage(currentPageNum - 1)}
                        disabled={currentPageNum === 1}
                        aria-disabled={currentPageNum === 1}
                        aria-label="previous page"
                    >
                    <MdIcons.MdArrowBackIosNew/>
                </PageNavigateButton>
                </li>
                <li>
                    <PageNumInput
                        type="text"
                        value={inputValue}
                        className="page-link"
                        title="Press Enter after edited"
                        onChange={(e) => setInputValue(e.target.value)}
                        onFocus={(e)=>{
                            // select the content in the input field, save user's time
                            e.target.select()

                            // enter editing state, add event listener for pressing ENTER
                            setIsEditing(true)
                            e.target.addEventListener("keydown", keydownListener)

                            // when the user unfocus the input field, remove the added listener
                            e.target.addEventListener('focusout', ()=>{
                                e.target.removeEventListener("keydown", keydownListener)
                                setIsEditing(false)
                            })
                        }}
                    />
                </li>
                <li
                    style={{
                        fontFamily: "Source Sans Pro",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "17px",
                        margin: "0 10px 0 4px",
                        padding: "6px 3.5px",
                        lineHeight: "120%",
                        color: "#293241"
                    }}
                >
                <h5 style={{
                    left: "58.46%",
                    right: "30.77%",
                    top: "16.67%",
                    bottom: "30.56%",
                }}>of {maxPageNum}</h5>
                </li>
                <li className="page-item">
                    <PageNavigateButton
                        className="page-link"
                        onClick={()=>goToPage(currentPageNum + 1)}
                        disabled={currentPageNum === maxPageNum}
                        aria-disabled={currentPageNum === maxPageNum}
                        aria-label="next page"
                    >
                    <MdIcons.MdArrowForwardIos/>
                    </PageNavigateButton>
                </li>
            </ul>
        </nav>
    );
}


const PageNumInput = styled.input`
    border-radius: 6px;
    font-family: Source Sans Pro;
    color: #293241 !important;
    background-color: #FFFFF !important;
    font-style: normal;
    height: 40px;
    width: 42px;
    margin: 0 0 0 35px;
    padding: 6px 6px;
    text-align: center;
    line-height: 150%;
    font-size: 15px;
    :focus {
        font-size: 17px;
    }
`
const PageNavigateButton = styled.button`
    font-weight: 600;
    color: #207DEA;
    font-family: Source Sans Pro;
    font-style: normal;
    height: 40px;
    width: 42px;
    margin: 0 8px;
    padding: 0px;
    border-radius: 6px;
    border: solid 0.5px #d9d9d9;
    line-height: 150%;
    :disabled {
        opacity: 0;
    }
`