import React from "react"

export type VisualizationPlaceholderProps = {
    color: string
}

export const VisualizationPlaceholder = (props: VisualizationPlaceholderProps) => {
    return (
        <div style={{ height: "100%", width: "100%", borderRadius: "3px", background: props.color ?? "lightgray", display: "flex", alignItems: "center", justifyContent: "center" }}> </div>
    )
}