import { useSetRecoilState } from "recoil"
import { EEGChannel, EEGMontage, MontageType } from "../Pages/Data/Visualize/DataReview/Types/EEG"
import { useEndpointProvider } from "../Providers/EndpointProvider"
import { eegMontagesAtom } from "../Pages/Data/Visualize/DataReview/Atoms/EEGMontage"
import { useBackendLinksProvider } from "../Providers/BackendLinksProvider"

type EEGMontageServiceAPI = {
    createEEGMontage: (eegMontage: EEGMontage) => Promise<EEGMontage>
    modifyEEGMontage: (eegMontage: EEGMontage) => Promise<any>
    deleteEEGMontage: (eegMontageId: number) => Promise<any>
    deserializeQueryMontage: (queryMontage: QueryMontage) => EEGMontage
}

export type QueryMontage = {
    id: number,
    channels: EEGChannel[],
    name: string,
    type: MontageType
    user_id: number
}

export const useEEGMontageService = (): EEGMontageServiceAPI => {
    const { LINKS } = useBackendLinksProvider()
    const endpointProvider = useEndpointProvider()
    const setEEGMontages = useSetRecoilState(eegMontagesAtom)
    
    async function createEEGMontage(eegMontage: EEGMontage): Promise<EEGMontage> {
        const body = {
            eeg_montage_id: eegMontage.id,
            name: eegMontage.name,
            channels: eegMontage.channels,
            type: eegMontage.type,
            user_id: eegMontage.userId,
        }

        return endpointProvider
            .post(LINKS.VISUALIZE.EEG.CREATE_EEG_MONTAGE, body)
            .then((queryMontage: QueryMontage) => {
                const newMontage = deserializeQueryMontage(queryMontage)
                setEEGMontages(previous => [...previous, newMontage])
                return newMontage
            })
            .catch(error => {
                alert(error)
                return eegMontage
            })
    }

    async function modifyEEGMontage(eegMontage: EEGMontage): Promise<any> {
        const body = {
            eeg_montage_id: eegMontage.id,
            name: eegMontage.name,
            channels: eegMontage.channels,
            type: eegMontage.type
        }

        return endpointProvider
            .post(LINKS.VISUALIZE.EEG.MODIFY_EEG_MONTAGE, body)
            .then(() =>
                setEEGMontages(previous =>
                    previous.map(montage => {
                        if (montage.id === eegMontage.id) {
                            return {
                                ...montage,
                                ...eegMontage,
                            }
                        }
                        return montage
                    })
                )
            )
            .catch(error => alert(error))
    }

    async function deleteEEGMontage(eegMontageId: number): Promise<any> {
        let body = {
            eeg_montage_id: eegMontageId,
        }

        return endpointProvider
            .post(LINKS.VISUALIZE.EEG.DELETE_EEG_MONTAGE, body)
            .then(() => {
                setEEGMontages(previous => [...previous].filter(montage => montage.id !== eegMontageId))
            })
            .catch(error => alert(error))
    }

    const deserializeQueryMontage = (queryMontage: QueryMontage): EEGMontage => {
        return {
            id: queryMontage.id,
            channels: queryMontage.channels,
            name: queryMontage.name,
            userId: queryMontage.user_id,
            type: queryMontage.type
        }
    }

    return {
        createEEGMontage,
        modifyEEGMontage,
        deleteEEGMontage,
        deserializeQueryMontage
    }
}