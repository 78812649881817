import Modal from 'react-bootstrap/Modal';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import styled from 'styled-components'
import * as MdIcons from 'react-icons/md'
import { useState, useEffect } from 'react';
import ModalFooterButton, { buttonColor } from '../../../../Components/ModalFooterButton/ModalFooterButton';

function ModifyElementModal({show, onHide, data, modifyData, selectedComponentKey, refresh}) {
    const headingStyle = {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "700",
        lineHeight: "150%",
        color: "#293241"
    }

    

    const getInitialData = (subcomponentObj, input_type) => {
        if (!subcomponentObj || subcomponentObj.input_type !== input_type) {
            switch (input_type) {
                case 'HTMLRadioField':
                    return {"requiredValues": ["", ""], "optionalValues": []}
                case 'HTMLCheckboxField':
                    return {'requiredValues': [""], 'optionalValues': []}
                case 'HTMLTableField':
                    return {'requiredValues': {numRow: 0, numCol: 0, isHeader: "No"}, 'optionalValues': {}}
                case 'HTMLRangeField':
                    return {'requiredValues': {"initialMin": 10, "initialMax": 20, "rangeMin": 0, "rangeMax": 100, "step": 1}, 'optionalValues': {}}
                case 'HTMLDropdownField':
                    return {'requiredValues': ["--Select an option--"], 'optionalValues': []}
                case 'HTMLDatetimeLocalField':
                    return {'requiredValues': {"timezone": "UTC"}, 'optionalValues': {}}
                case 'HTMLNumberField':
                    return {'requiredValues': {"minLimit": 0, "maxLimit": 100, "type": "Integer", "decimalPoint": 0}, 'optionalValues': {}}
                default:
            }
        } 
        return subcomponentObj.option
    }

    const getInitialCheckboxGridData = (subcomponentObj) => {
        if (!subcomponentObj || subcomponentObj.input_type !== 'HTMLCheckboxFieldGrid') return ["", []]
        const checkboxGridLabels = subcomponentObj.option.answer.labels
        return [checkboxGridLabels[0], checkboxGridLabels.slice(1)]
    }

    const [gData, setGData] = useState([])
    const [elementName, setElementName] = useState("");
    const [selectedOption, setSelectedOption] = useState("--Select an option--");
    const [currentSelectedSubcomponent, setCurrentSelectedSubcomponent] = useState({})
    const [checkboxValues, setCheckboxValues] = useState(getInitialData(currentSelectedSubcomponent, 'HTMLCheckboxField'))
    const [firstCheckboxGridLabel, setFirstCheckboxGridLabel] = useState(getInitialCheckboxGridData()[0])
    const [optionalCheckboxGridLabels, setOptionalCheckboxGridLabels] = useState(getInitialCheckboxGridData()[1])
    const [datetimeLocalValues, setDatetimeLocalValues] = useState(getInitialData(currentSelectedSubcomponent, 'HTMLDatetimeLocalField')) 
    const [numberValues, setNumberValues] = useState(getInitialData(currentSelectedSubcomponent, 'HTMLNumberField')) 
    const [dropdownValues, setDropdownValues] = useState(getInitialData(currentSelectedSubcomponent, 'HTMLDropdownField')) 
    const [radioValues, setRadioValues] = useState(getInitialData(currentSelectedSubcomponent, 'HTMLRadioField'))
    const [rangeValues, setRangeValues] = useState(getInitialData(currentSelectedSubcomponent, 'HTMLRangeField'))
    const [tableValues, setTableValues] = useState(getInitialData(currentSelectedSubcomponent, 'HTMLTableField'))
    const availableOptions = {
        "default": {"label": "--Select an option--", "answer": {}},
        "HTMLInputField": {"label": 'Input Field', "answer": {"answer": {"x": " "}}},
        "HTMLCheckboxField": {"label": 'Checkbox Field', "answer": {"options": {"Box1": null, "Box2": null}}},
        "HTMLCheckboxFieldGrid": {"label": 'Checkbox Field Grid', "answer": {"answer": {"labels": ["ABP", "CPP", "PbtO2e", "ETCO2", "SpO2/FiO2", "rCBF", "PRx", "Not reported"], "checkbox_fields": {"Not reported 1": null, "Not reported 2": null, "Not reported 3": null}}}},
        "HTMLPlot": {"label": 'Plot', "answer": {"answer": {"x": " "}}},
        "HTMLDatetimeLocalField": {"label": 'Datetime Local Field', "answer": {"answer": ["Option 1", "Option 2", "Option 3"]}},
        "HTMLDropdownField": {"label": 'Dropdown Field', "answer": {"answer": ["Option 1", "Option 2"]}},
        "HTMLNumberField": {"label": 'Number Field', "answer": {"answer": {"x": " "}}},
        "HTMLRadioField": {"label": 'Radio Field', "answer": {"answer": ["Value 1", "Value 2", "Value 3"]}},
        "HTMLRangeField": {"label": 'Range Field', "answer": {"answer": {"x": " "}}},
        "HTMLTimeField": {"label": 'Time Field', "answer": {"answer": {"name": "Time"}}},
        "HTMLTextAreaField": {"label": 'Text Area Field', "answer": {"answer": {"x": " "}}},
        "HTMLTableField": {"label": 'Table Field', "answer": {"answer": {"header": true, "numRow": 3, "numCol": 4}}},
    }

    useEffect(() => {
        setGData(data)
    }, [data])

    
    useEffect(() => {
        if (selectedComponentKey) {
            const primaryKey = parseInt(selectedComponentKey?.split("-")[0])
            const secondaryKey = parseInt(selectedComponentKey?.split("-")[1])
            if (secondaryKey > 0) {
                console.log({gData, primaryKey, secondaryKey})
                setElementName(gData[primaryKey]?.children[secondaryKey-1]?.title)
                setSelectedOption(gData[primaryKey]?.children[secondaryKey-1]?.input_type)
                setCurrentSelectedSubcomponent(gData[primaryKey]?.children[secondaryKey-1])
            }
        }
    }, [gData, selectedComponentKey])

    const popoverTopText = {
        'subcomponentName': 'The name of the subcomponent.',
        'bucketID': 'A four digit unique code corresponding to each bucket.',
        'studyID': 'The site ID that would be assigned to the bucket.'
    }

    function getPopoverTop(labelname) {
        return (
            <Popover id="Popover-trigger-hover-focus" style={{width: "228px"}} positionleft={75}>
                <p style={{fontFamily: "Source Sans Pro", fontStyle: "normal", fontWeight: "400", fontSize: "12px", color: "#5F6775", paddingLeft: "3px"}}>{popoverTopText[labelname]}</p>
            </Popover>
        )
    }

    const handleChangeValues = (subcomponentValues, setValue, type, value, index) => {
        const copyObj = JSON.parse(JSON.stringify(subcomponentValues))
        copyObj[type][index] = value
        setValue(copyObj)
    }

    const addInputs = (subcomponentValues, setValues) => {
        const copyObj = JSON.parse(JSON.stringify(subcomponentValues))
        copyObj.optionalValues.push("")
        setValues(copyObj)
    }

    const removeInputs = (subcomponentValues, setValues, index) => {
        const copyObj = JSON.parse(JSON.stringify(subcomponentValues))
        copyObj.optionalValues.splice(index, 1)
        setValues(copyObj)
    }

    const addCheckboxGridLabel = () => {
      const copyOptionalCheckboxGridLabels = [...optionalCheckboxGridLabels]
      copyOptionalCheckboxGridLabels.push("")
      setOptionalCheckboxGridLabels(copyOptionalCheckboxGridLabels)
  }

    const renderCheckboxSettings = () => {
        return (
            <Scrollbar style={{display: "block", padding: "15px 0px", maxHeight: "300px", overflowY: "scroll"}}>
                <div style={{display: "flex"}}>
                    <ModalTextinput placeholder="Enter checkbox option here" name="requiredCheckbox" onChange={e => handleChangeValues(checkboxValues, setCheckboxValues, 'requiredValues', e.target.value, 0)} value={checkboxValues.requiredValues[0]}/>
                    <MdIcons.MdAdd size={24} style={{cursor: "pointer", marginTop: "8px", marginLeft: "4px"}} color="#B6B6B6" onClick={() => {addInputs(checkboxValues, setCheckboxValues)}} />
                </div>

                {checkboxValues.optionalValues.map(function(value, index) {
                    return (
                    <div style={{display: "flex"}} key={`optionalCheckbox-${index}`}>
                        <ModalTextinput id={`optionalCheckbox-${index}`} placeholder="Enter checkbox option here" name={`optionalCheckbox-${index}`} value={value} onChange={e => handleChangeValues(checkboxValues, setCheckboxValues, 'optionalValues', e.target.value, index)}/>
                        <MdIcons.MdClose size={24} style={{cursor: "pointer", marginTop: "8px", marginLeft: "4px"}} color="#B6B6B6" onClick={() => {removeInputs(checkboxValues, setCheckboxValues, index)}}/>
                    </div>
                    )
                })}
            </Scrollbar>
        )
    }

    const handleChangeOptionalCheckboxGridLabels = (value, index) => {
        const copyOptionalCheckboxGridLabels = [...optionalCheckboxGridLabels]
        copyOptionalCheckboxGridLabels[index] = value
        setOptionalCheckboxGridLabels(copyOptionalCheckboxGridLabels)
    }

  const removeCheckboxGridLabel = (index) => {
      const copyOptionalCheckboxGridLabels = [...optionalCheckboxGridLabels]
      copyOptionalCheckboxGridLabels.splice(index, 1)
      setOptionalCheckboxGridLabels(copyOptionalCheckboxGridLabels)
  }

    const renderCheckboxGridSettings = () => {
        return (
            <Scrollbar style={{display: "block", padding: "15px 0px", maxHeight: "360px", overflowY: "scroll"}}>
                <div style={{display: "flex"}}>
                    <ModalTextinput placeholder="Enter a label here" name="checkboxLabel-1" onChange={e => setFirstCheckboxGridLabel(e.target.value)} value={firstCheckboxGridLabel}/>
                    <MdIcons.MdAdd size={24} style={{cursor: "pointer", marginTop: "8px", marginLeft: "4px"}} color="#B6B6B6" onClick={() => {addCheckboxGridLabel()}} />
                </div>

                {optionalCheckboxGridLabels.map(function(value, index) {
                    return (
                    <div style={{display: "flex"}} key={`optionalCheckboxGridLabel-${index}`}>
                        <ModalTextinput id={`optionalCheckboxGridLabel-${index}`} placeholder="Enter a label here" name={`optionalCheckboxGridLabel-${index}`} value={value} onChange={e => handleChangeOptionalCheckboxGridLabels(e.target.value, index)}/>
                        <MdIcons.MdClose size={24} style={{cursor: "pointer", marginTop: "8px", marginLeft: "4px"}} color="#B6B6B6" onClick={() => {removeCheckboxGridLabel(index)}}/>
                    </div>
                    )
                })}
            </Scrollbar>
        )
    }

    const renderDatetimeLocalSettings = () => (
        <div>
            <div style={{display:"flex", alignItems:"center", gap:"50px", marginBottom: "20px"}}>
                <p style={{fontSize: "16px", maxWidth: "40%", marginBottom: "0", width: "90px"}}>{"Timezone"}</p>
                <CustomInput 
                    type="text" 
                    name="timezone" 
                    placeholder="Enter timezone here"
                    value={datetimeLocalValues.requiredValues.timezone}
                    onChange={e => handleChangeValues(datetimeLocalValues, setDatetimeLocalValues, 'requiredValues', e.target.value, 'timezone')}
                />
            </div>
        </div>
    )

    const renderNumberSettings = () => (
        <div>
            <div style={{display:"flex", alignItems:"center", justifyContent:"space-between", gap:"20px", marginBottom: "20px"}}>
                <p style={{fontSize: "16px", maxWidth: "40%", marginBottom: "0"}}>{"Number Type"}</p>
                <ModalDropdown style={{width: "2.65in", marginRight: "20.25%"}} id="numberType" value={numberValues.requiredValues.type} onChange={e => handleChangeValues(numberValues, setNumberValues, 'requiredValues', e.target.value, 'type')}>
                    {['Integer', 'Floating-point'].map(o => (
                        <option key={`numberType-${o}`} value={o}>{o}</option>
                    ))}
                </ModalDropdown>
            </div>
            <div style={{display:"flex", alignItems:"center", justifyContent:"space-between", gap:"20px", marginBottom: "20px"}}>
                <p style={{fontSize: "16px", maxWidth: "40%", marginBottom: "0"}}>{"Decimal Point"}</p>
                <CustomInput style={{width: "2.65in", marginRight: "20.25%"}} type="number" name="number-decimal-point" placeholder="Enter a decimal point here" value={numberValues.requiredValues.decimalPoint} onChange={e => handleChangeValues(numberValues, setNumberValues, 'requiredValues', e.target.value, 'decimalPoint')}/>
            </div>
            <div style={{display:"flex", alignItems:"center", justifyContent:"space-between", gap:"20px", marginBottom: "20px"}}>
                <p style={{fontSize: "16px", maxWidth: "40%", marginBottom: "0"}}>{"Minimum Limit"}</p>
                <CustomInput style={{width: "2.65in", marginRight: "20.25%"}} type="number" name="number-min-limit" placeholder="Enter minimum limit here" value={numberValues.requiredValues.minLimit} onChange={e => handleChangeValues(numberValues, setNumberValues, 'requiredValues', e.target.value, 'minLimit')}/>
            </div>
            <div style={{display:"flex", alignItems:"center", justifyContent:"space-between", gap:"20px", marginBottom: "20px"}}>
                <p style={{fontSize: "16px", maxWidth: "40%", marginBottom: "0"}}>{"Maximum Limit"}</p>
                <CustomInput style={{width: "2.65in", marginRight: "20.25%"}} type="number" name="number-max-limit" placeholder="Enter maximum limit here" value={numberValues.requiredValues.maxLimit} onChange={e => handleChangeValues(numberValues, setNumberValues, 'requiredValues', e.target.value, 'maxLimit')}/>
            </div>
        </div>
    )

    const renderDropdownSettings = () => {
        return (
            <Scrollbar style={{display: "block", padding: "15px 0px", maxHeight: "360px", overflowY: "scroll"}}>
                <div style={{display: "flex"}}>
                    <ModalTextinput placeholder="Enter dropdown option here" name="dropdownOption-1" onChange={e => handleChangeValues(dropdownValues, setDropdownValues, 'requiredValues', e.target.value, 0)} value={dropdownValues.requiredValues[0]}/>
                    <MdIcons.MdAdd size={24} style={{cursor: "pointer", marginTop: "8px", marginLeft: "4px"}} color="#B6B6B6" onClick={() => {addInputs(dropdownValues, setDropdownValues)}} />
                </div>

                {dropdownValues.optionalValues.map((value, index) => (
                    <div style={{display: "flex"}} key={`dropdownOption-${index}`}>
                    <ModalTextinput id={`dropdownOption-${index}`} placeholder="Enter dropdown option here" name={`dropdownOption-${index}`} value={value} onChange={e => handleChangeValues(dropdownValues, setDropdownValues, 'optionalValues', e.target.value, index)}/>
                    <MdIcons.MdClose size={24} style={{cursor: "pointer", marginTop: "8px", marginLeft: "4px"}} color="#B6B6B6" onClick={() => {removeInputs(dropdownValues, setDropdownValues, index)}}/>
                </div>
                ))}
            </Scrollbar>
        )
    }

    const renderRadioSettings = () => {
        return (
            <Scrollbar style={{display: "block", padding: "15px 0px", maxHeight: "360px", overflowY: "scroll"}}>
                {radioValues.requiredValues.map((value, index) => 
                    (
                        <div style={{display: "flex"}} key={`requiredRadio-${index}`}>
                            <ModalTextinput id={`requiredRadio-${index}`} placeholder="Enter a value here" name={`requiredRadio-${index}`} value={value} onChange={e => handleChangeValues(radioValues, setRadioValues, 'requiredValues', e.target.value, index)}/>
                            <MdIcons.MdAdd size={24} style={{cursor: "pointer", marginTop: "8px", marginLeft: "4px"}} color="#B6B6B6" onClick={() => addInputs(radioValues, setRadioValues)}/>
                        </div>
                    )
                )}

                {radioValues.optionalValues.map((value, index) =>
                    (
                        <div style={{display: "flex"}} key={`optionalRadio-${index}`}>
                            <ModalTextinput id={`optionalRadio-${index}`} placeholder="Enter a value here" name={`optionalRadio-${index}`} value={value} onChange={e => handleChangeValues(radioValues, setRadioValues, 'optionalValues', e.target.value, index)}/>
                            <MdIcons.MdClose size={24} style={{cursor: "pointer", marginTop: "8px", marginLeft: "4px"}} color="#B6B6B6" onClick={() => removeInputs(radioValues, setRadioValues, index)}/>
                        </div>
                    )
                )}
            </Scrollbar>
        )
    }

    const renderRangeSettings = () => (
        <div>
            <div style={{display:"flex", alignItems:"center", gap:"50px", marginBottom: "20px"}}>
                <p style={{fontSize: "16px", maxWidth: "40%", marginBottom: "0", width: "90px"}}>{"Range"}</p>
                <div style={{display:"flex", alignItems:"center", gap:"5px"}}>
                    <CustomInput style={{width: "1in"}} type="number" name="range" placeholder="Enter a number here" value={rangeValues.requiredValues.rangeMin} onChange={e => handleChangeValues(rangeValues, setRangeValues, 'requiredValues', parseInt(e.target.value), 'rangeMin')}/>
                    <CustomInput style={{width: "1in"}} type="number" name="range" placeholder="Enter a number here" value={rangeValues.requiredValues.rangeMax} onChange={e => handleChangeValues(rangeValues, setRangeValues, 'requiredValues', parseInt(e.target.value), 'rangeMax')}/>
                </div>
            </div>

            <div style={{display:"flex", alignItems:"center", gap:"50px", marginBottom: "20px"}}>
                <p style={{fontSize: "16px", maxWidth: "40%", marginBottom: "0", width: "90px"}}>{"Initial Range"}</p>
                <div style={{display:"flex", alignItems:"center", gap:"5px"}}>
                    <CustomInput style={{width: "1in"}} type="number" name="initialRange" placeholder="Enter a number here" value={rangeValues.requiredValues.initialMin} onChange={e => handleChangeValues(rangeValues, setRangeValues, 'requiredValues', parseInt(e.target.value), 'initialMin')}/>
                    <CustomInput style={{width: "1in"}} type="number" name="initialRange" placeholder="Enter a number here" value={rangeValues.requiredValues.initialMax} onChange={e => handleChangeValues(rangeValues, setRangeValues, 'requiredValues', parseInt(e.target.value), 'initialMax')}/>
                </div>
            </div>

            <div style={{display:"flex", alignItems:"center", gap:"50px", marginBottom: "20px"}}>
                <p style={{fontSize: "16px", maxWidth: "40%", marginBottom: "0", width: "90px"}}>{"Step"}</p>
                <CustomInput style={{width: "1in"}} type="number" name="step" placeholder="Enter a number here" value={rangeValues.requiredValues.step} onChange={e => handleChangeValues(rangeValues, setRangeValues, 'requiredValues', parseInt(e.target.value), 'step')}/>
            </div>

        </div>
    )

    const renderTableSettings = () => (
      <div>
          <div style={{display:"flex", alignItems:"center", justifyContent:"space-between", gap:"20px", marginBottom: "20px"}}>
              <p style={{fontSize: "16px", maxWidth: "40%", marginBottom: "0"}}>{"Number of rows"}</p>
              <CustomInput style={{width: "2.65in", marginRight: "20.25%"}} type="number" name="row-number" placeholder="Enter number of rows here" value={tableValues.requiredValues.numRow} onChange={e => handleChangeValues(tableValues, setTableValues, 'requiredValues', e.target.value, 'numRow')}/>
          </div>
          <div style={{display:"flex", alignItems:"center", justifyContent:"space-between", gap:"20px", marginBottom: "20px"}}>
              <p style={{fontSize: "16px", maxWidth: "40%", marginBottom: "0"}}>{"Number of columns"}</p>
              <CustomInput style={{width: "2.65in", marginRight: "20.25%"}} type="number" name="col-number" placeholder="Enter number of columns here" value={tableValues.requiredValues.numCol} onChange={e => handleChangeValues(tableValues, setTableValues, 'requiredValues', e.target.value, 'numCol')}/>
          </div>
          <div style={{display:"flex", alignItems:"center", justifyContent:"space-between", gap:"20px", marginBottom: "20px"}}>
              <p style={{fontSize: "16px", maxWidth: "40%", marginBottom: "0"}}>{"Do you want to have header"}</p>
              <CustomSelect style={{width: "2.65in", height: "50px", marginRight: "20.25%"}} name="input-options" value={tableValues.requiredValues.isHeader} onChange={e => handleChangeValues(tableValues, setTableValues, 'requiredValues', e.target.value, 'isHeader')}>
                  {["No", "Yes"].map((element) => (<option value={element.toLowerCase()} key={element}>{element}</option>))}
              </CustomSelect>
          </div>
      </div>
  )

    const renderSettings = (inputType) => {
        switch (inputType) {
            case 'HTMLInputField':
                return (<div>None</div>)
            case 'HTMLCheckboxField':
                return renderCheckboxSettings()
            case 'HTMLCheckboxFieldGrid':
                return renderCheckboxGridSettings()
            case 'HTMLPlot':
                return (<div>None</div>)
            case 'HTMLScatterPlot':
                return (<div>None</div>)
            case 'HTMLHistogramPlot':
                return (<div>None</div>)
            case 'HTMLDatetimeLocalField':
                return renderDatetimeLocalSettings()
            case 'HTMLDropdownField':
                return renderDropdownSettings()
            case 'HTMLNumberField':
                return renderNumberSettings()
            case 'HTMLRadioField':
                return renderRadioSettings()
            case 'HTMLRangeField':
                return renderRangeSettings()
            case 'HTMLTimeField':
                return (<div>None</div>)
            case 'HTMLTextAreaField':
                return (<div>None</div>)
            case 'HTMLTableField':
                return renderTableSettings()
            default:
        }   
    }

    const getSubcomponentSettings = (inputType) => {
        switch (inputType) {
            case 'HTMLInputField':
                return {"answer": {"x": " "}}
            case 'HTMLCheckboxField':
                return checkboxValues
            case 'HTMLCheckboxFieldGrid':
                return {"answer": {"labels": [firstCheckboxGridLabel, ...optionalCheckboxGridLabels], "checkbox_fields": {"Not reported 1": null, "Not reported 2": null, "Not reported 3": null}}}
            case 'HTMLRadioField':
                return radioValues
            case 'HTMLDatetimeLocalField':
                return datetimeLocalValues
            case 'HTMLDropdownField':
                return dropdownValues
            case 'HTMLRangeField':
                return rangeValues
            case 'HTMLNumberField':
                return numberValues
            case 'HTMLTableField':
                return tableValues
            case 'HTMLTimeField':
                return {"answer": {"name": "Time"}}
            default:
        }
    }

    const getSubcomponentHeight = (inputType, subcomponentAnswer) => {
        switch(inputType) {
            case 'HTMLInputField':
                return 39.39
            case 'HTMLCheckboxField':
                return 54.6
            case 'HTMLCheckboxFieldGrid':
                return 196.93
            case 'HTMLPlot':
                return 440.72
            case 'HTMLScatterPlot':
                return 39.39
            case 'HTMLHistogramPlot':
                return 39.39
            case 'HTMLDatetimeLocalField':
                return 39.39
            case 'HTMLDropdownField':
                return 36.06
            case 'HTMLNumberField':
                return 39.39
            case 'HTMLRadioField':
                return 93.39
            case 'HTMLRangeField':
                return 71.39
            case 'HTMLTimeField':
                return 39.39
            case 'HTMLTextAreaField':
                return 68.39
            case 'HTMLTableField':
                let height = 0
                const rowHeight = 96
                height += subcomponentAnswer.requiredValues.numCol * rowHeight
                if (subcomponentAnswer.requiredValues.isHeader === true) height += rowHeight
                height += 0.89
                return height
            default:
        }
    }

    const updateSubcomponent = (subcomponentObj, newTitle, newInputType, newAnswer) => {
        subcomponentObj.title = newTitle
        subcomponentObj.input_type = newInputType
        subcomponentObj.height = getSubcomponentHeight(newInputType, newAnswer)
        subcomponentObj.option = newAnswer
    }

    const handleSaveModifyElementModal = () => {
        const newElement = JSON.parse(JSON.stringify(currentSelectedSubcomponent))
        updateSubcomponent(newElement, elementName, selectedOption, getSubcomponentSettings(selectedOption))
        setCurrentSelectedSubcomponent(newElement)
        const key = newElement.key
        const primaryKey = parseInt(key.split('-')[0])
        const secondaryKey = parseInt(key.split('-')[1])
        const newGData = JSON.parse(JSON.stringify(gData))
        newGData[primaryKey].children[secondaryKey-1] = newElement
        modifyData(newGData)
        refresh(prev => !prev)
        onHide()
    }
    
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
        <Modal.Header 
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
        }}
        >
            <Modal.Title>
                <h3 style={headingStyle}>{"Modify Element"}</h3>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div style={{display: 'flex'}}>
                <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={getPopoverTop('subcomponentName')}> 
                <InfoButton><MdIcons.MdInfoOutline size={18} style={{float: "right"}}/></InfoButton>
                </OverlayTrigger>
                <p 
                    style={{
                        marginTop: "-4px",
                        fontWeight: "600",
                        fontSize: "16px",
                        lineHeight: "150%",
                        color: "#293241",
                      }}
                >
                    Name<span style={{color:"red"}}>*</span>
                </p>
            </div>
            <ModalTextinput placeholder="Enter element name here" id="elementName" value={elementName} onChange={e => setElementName(e.target.value)}/>

            <div style={{display: 'flex'}}>
                <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={getPopoverTop('description')}> 
                <InfoButton><MdIcons.MdInfoOutline size={18} style={{float: "right"}}/></InfoButton>
                </OverlayTrigger>
                <p
                    style={{
                        marginTop: "-4px",
                        fontWeight: "600",
                        fontSize: "16px",
                        lineHeight: "150%",
                        color: "#293241",
                      }}
                >
                    Type
                </p>
            </div>
            <ModalDropdown id="subcomponentType" value={selectedOption} onChange={e => setSelectedOption(e.target.value)}>
                {Object.entries(availableOptions).map(o => (
                <option key={o.at(0)} value={o.at(0)}>{o.at(1).label}</option>
                ))}
            </ModalDropdown>

            <div style={{display: "inline-flex"}}>
                <p
                    style={{
                        fontWeight: "600",
                        fontSize: "16px",
                        lineHeight: "150%",
                        color: "#293241",
                    }}
                >
                    Settings
                </p>
                
                <div style={{right: "0px", display: "inline-flex", marginLeft: "600px"}}>
                    <p
                        style={{
                            fontWeight: "600",
                            fontSize: "16px",
                            lineHeight: "150%",
                            color: "#293241",
                        }}
                    >
                        Preview
                    </p>
                    <MdIcons.MdOutlineToggleOff size={36} color="#B3B3B3" style={{marginTop: "-6px", marginLeft: "6px", cursor: "pointer"}}/>
                </div>
            </div>

            {renderSettings(selectedOption)}

      </Modal.Body>
      <Modal.Footer>
            <div
                style={{
                  paddingLeft: "35px",
                  paddingBottom: "15px",
                }}
            >
                <ModalFooterButton
                    buttonText="Save"
                    color={buttonColor.blueFill}
                    buttonStyle={{width: "114px", marginRight: "5px"}}
                    onClickFxn={handleSaveModifyElementModal}
                />
                <ModalFooterButton
                    buttonText="Cancel"
                    color={buttonColor.blueOutline}
                    buttonStyle={{width: "114px", marginRight: "5px"}}
                    onClickFxn={onHide}
                />
          </div>
      </Modal.Footer>
    </Modal>
  );
}

const InfoButton = styled.button`
    border: none;
    background: none;
    border: none;
    color: #207dea;
    cursor: pointer;
    margin-top: 18px;
    width: fit-content;
    margin-right: 5px;
    margin-top: 0px;
    margin-bottom: 7px;
`;

const ModalTextinput = styled.input`
	background:  ${({ disabled }) => (disabled ? "#F2F2F2" : "#ffffff")};
    border: 2px solid #CCCCCC;
    box-sizing: border-box;
    border-radius: 5px;
    width: 464px;
    height: 56px;
    padding: 16px;
	color: ${({ disabled }) => (disabled ? "#737373": "#000000")};
	margin-top: -5px;
	margin-bottom: 20px;
    ::placeholder {
        font-family: 'Source Sans Pro';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: "gray";
    }
`;

const ModalDropdown = styled.select`
    background: #FFFFFF;
    border: 2px solid #CCCCCC;
    box-sizing: border-box;
    border-radius: 5px;
    width: 464px;
    height: 56px;
    padding: 16px;
	color: #000000;
	margin-top: -5px;
	margin-bottom: 25px;
    ::placeholder {
        font-family: 'Source Sans Pro';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: "gray";
    }
`;

const Scrollbar = styled.div`
		scrollbar-width: thin;
		::-webkit-scrollbar {
  			display: block;
			width: 5px;
			color: #313A4A;
		}
		::-webkit-scrollbar-track {
			background: #BEC4CF;
			width: 5px;
			border-radius: 2px;
		}
`;

const CustomInput = styled.input`
    border: 1px solid #ABABAB;
    border-radius: 6px;
    color: #000000;
    width: 2.65in;
    height: 50px;
    padding: 8px 16px;
    ::placeholder {
        top: calc(50% - 19px/2);
        color: #9f9f9f;
        font-size: 16px;
        font-weight: 400;
        font-family: "Source Sans Pro";
        line-height: 120%;
    }
`;

const CustomSelect = styled.select`
    border: 1px solid #ABABAB;
    border-radius: 6px;
    color: #000000;
    width: 2.65in;
    height: 50px;
    padding: 8px 16px;
`;



export default ModifyElementModal