
import { useState, useEffect, useCallback, useRef } from 'react';
import { DEFAULTS } from '../../../Constants/Formatting';
import CreateReports from './Subpages/CreateReports';
import _ from "lodash";
import { LINKS } from '../../../Constants/BackendLinks'
import { FRONTEND_LINKS } from '../../../Constants/FrontendLinks';
import styled from 'styled-components';
import * as MdIcons from "react-icons/md";
import { MainTable } from '../../../Components/DataTable/Table';
import { Link } from "react-router-dom"
import CreateReportModal from "./SubComponents/CreateReportModal"
import { studyUpdates, useStudiesProvider } from '../../../Providers/StudiesProvider';
import { useEndpointProvider } from '../../../Providers/EndpointProvider';
import CreateReportModalBootstrap from './SubComponents/CreateReportModalBootstrap';
import CreateReportDetailsModalBootstrap from './SubComponents/CreateReportDetailsModalBootstrap';
import TrashButton from '../../../Components/TrashButton/TrashButton';


const MultimodalReports = (props) => {
	const studiesProvider = useStudiesProvider()
    const endpointProvider = useEndpointProvider()
	
    // Update Providers to see if data changed remotely.
    // Only run once, because each update makes a network call.
    useEffect(() => {
        studiesProvider.update(studyUpdates.STUDIES_FILTERS)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []) // THIS MUST BE EMPTY. DO NOT CHANGE IT.


	const [multimodalReportsUpdate, setMultimodalReportsUpdate] = useState([])
	const [searchQuery, setSearchQuery] = useState("");
	const [SubheaderValues, setSubheaderValues] = useState([]);
	const [SubheaderOrder, setSubheaderOrder] = useState([]);
	const [tableConfig, setTableConfig] = useState([]);
	const [reportTablePending, setReportTablePending] = useState(true)
	const [reportTableColumns, setReportTableColumns] = useState([])
	const [reportPDFTableColumns, setReportPDFTableColumns] = useState([])
	const [reportTableData, setReportTableData] = useState([])
	const [reportPDFTableData, setReportPDFTableData] = useState([])
	const reportEditButtonRef = useRef()
	const [reportsUpdate, setReportsUpdate] = useState([])
	const [reportTemplateData, setReportTemplateData] = useState([])
	const [selectedRows, setSelectedRows] = useState(false)
	const [toggleCleared, setToggleCleared] = useState(false)
	const [studyTableColumns, setStudyTableColumns] = useState([])
	const [studyTableData, setStudyTableData] = useState([])
	const [studyPending, setStudyPending] = useState(false)
	const [studyTableSelected, setStudyTableSelected] = useState(false);
	const [studyUpdate, setStudyUpdate] = useState([])
	const [studyPreselect, setStudyPreselect] = useState([])
	const [studySearchQuery, setStudySearchQuery] = useState("");
	const [createReportModalShow, setCreateReportModalShow] = useState(false);
	const [createReportDetailsModalShow, setCreateReportDetailsModalShow] = useState(false);
	const [currentReportId, setCurrentReportId] = useState();

	
    const getReports = useCallback(async (study_id) =>  {
        let body = {
            study_id: study_id
        }
        
        return endpointProvider.post(LINKS.REPORTING.MULTIMODAL_REPORTS.REPORT_TEMPLATES.GET_REPORT_TEMPLATES, body)
    }, [endpointProvider])

	const removeReport = useCallback(async (report_name, study_id) => {
		let body = {
            report_name: report_name,
			study_id: study_id
        }
		return endpointProvider.post(LINKS.REPORTING.MULTIMODAL_REPORTS.REPORT_TEMPLATES.DELETE_REPORT_TEMPLATE, body)
	}, [endpointProvider])

	function updateReports() {
		setReportsUpdate([])
	}

	const[reportID, setReportID]=useState("")
	const[creatorID, setCreatorID]=useState("")

	// page update hook
	function updateMultimodalReports() {
		setMultimodalReportsUpdate([])
	}

    const enterDefault = useCallback((event) => {
		if (event.keyCode === 13) {
			event.preventDefault();
		}
	}, []);

	useEffect(() => {
		document.addEventListener("keydown", enterDefault);
		return () => {
			document.removeEventListener("keydown", enterDefault);
		};
	}, [enterDefault]);    

	const showMore = (
		<MdIcons.MdExpandMore
			size={20}
			style={{marginLeft: "10px", marginRight: "-20px", color: "#B6B6B6"}}
		/>
	);
	const showLess = (
		<MdIcons.MdExpandLess
			size={20}
			style={{marginLeft: "10px", marginRight: "-20px", color: "#B6B6B6"}}
		/>
	);

	const [assignReportStudiesIcon, setAssignReportStudiesIcon] = useState(showMore);

	const [reportTemplateName, setReportTemplateName] = useState("");
	const [reportTemplateId, setReportTemplateId] = useState("");

	function renderEditMultimodalModal(reportTemplateName, reportTemplateId) {
		if (reportTemplateName !== undefined) {
			setReportTemplateName(reportTemplateName)
		}

		if (reportTemplateId !== undefined) {
			setReportTemplateId(reportTemplateId)
		}
		
		if (document.getElementById("editMultimodalReportModal").style.display !== "none") {
			document.getElementById("editMultimodalReportModal").style.display = "none";
			document.getElementById("multimodalFilterGrayOverlay").style.display = "none";
		} else {
			document.getElementById("editMultimodalReportModal").style.display = "block";
			document.getElementById("multimodalFilterGrayOverlay").style.display = "block";
		}
	}

	function createTableConfig () {
        const data_variables_list = ["Site", "Modalities", "Days"];
        const time_options_list = ["last week", "last month"];
        const modality_return_list = ['ICP', 'PbtO2', 'MAP', 'Temp']

        let data_variable = Object.assign(
            {},
            ...data_variables_list.map((data_variable) => {
                return { [data_variable]: false };
            })
        );
        data_variable.Site = true

        let time_option = Object.assign(
            {},
            ...time_options_list.map((time_option, i) => {
                if (i === 0) {
                    return { [time_option]: true };
                } else {
                    return { [time_option]: false };
                }
            })
        );

        return {
            data_variable: data_variable,
            time_option: time_option,
        }
    }
	
	function closeEditMultimodalReportModal() {
		if (document.getElementById("editMultimodalReportModal").style.display !== "none") {
			(document.getElementById("editMultimodalReportModal").style.display = "none");
			(document.getElementById("multimodalFilterGrayOverlay").style.display = "none");
		}
	}

	function handleEditReport(id) {
		setCurrentReportId(id)
		setCreateReportDetailsModalShow(true)
	}
	
	
	useEffect(() => {
		if (!studiesProvider.selectedStudyId) return
		getReports(studiesProvider.selectedStudyId).then((data) => {
			setReportTemplateData(data)
		})
	}, [studiesProvider.selectedStudyId, reportsUpdate, getReports])

	useEffect(() => {
		if (studiesProvider.selectedStudyId === undefined || studiesProvider.selectedStudyId === '') return
        setReportTablePending(true)

		let tables = ["Create", "Run", "Track"]

		setSubheaderValues(
			Object.assign(
				{},
				...tables.map((table, i) => {
					if (i === 0) {
						return { [table]: true }
					} else {
						return { [table]: false }
					}
				})
			)
		)

		setSubheaderOrder(tables)

		let columns2labels = {
			name: { label: "Report Name", type: "string", center: false },
			created_by: { label: "Created By", type: "string", center: false },
			version: { label: "Versions", type: "string", center: false }
		}

		let columnData = []
		let pdfColumnData = []

		for (let elem of Object.keys(columns2labels)) {
			columnData.push({
				name: columns2labels[elem]['label'],
				selector: elem,
				sortable: true,
                center: columns2labels[elem]['center']
			})
            pdfColumnData.push({
                name: columns2labels[elem]['label'],
				selector: elem,
            })
		}
		columnData.push({
			name: "Edit",
			selector: "edit",
			sortable: false,
            // compact: true,
            width: '100px'
		})
		setReportTableColumns(columnData)
        setReportPDFTableColumns(pdfColumnData)

		let tableData = reportTemplateData.map(row => {
			let entry = {
				'edit': <EditUserButton onClick={() => handleEditReport(row.id)} ref={reportEditButtonRef}><MdIcons.MdEdit size={25} /></EditUserButton>
			}
			let pdfEntry = {}
			for (let column of Object.keys(columns2labels)) {
				if (column === 'created_by' && row[column]) continue;
				if (columns2labels[column]["type"] === "checkbox") {
					if (row[column]) {
						entry[column] = (<MdIcons.MdOutlineCheckBox size={18} />)
					} else {
						entry[column] = (<MdIcons.MdOutlineCheckBoxOutlineBlank size={18}/>)
					}
					pdfEntry[column] = row[column]
				} else if (columns2labels[column]["type"] === 'string') {
					if (row[column] === null) {
						entry[column] = ""
                        pdfEntry[column] = `${row[column]}`
					} else if (row[column] === undefined) {
						continue
					} else {
						entry[column] = `${row[column]}`
						pdfEntry[column] = `${row[column]}`
					}
				}
			}
			return entry
			
	})
		const sortedData = [...tableData].sort((a, b) => (a.name < b.name ? -1 : 1))
		setReportTableData(sortedData)
		setReportPDFTableData(sortedData)
		if (tableData.length || !reportTemplateData.length) {
			setReportTablePending(false)
		}
		

		window.addEventListener("resize", updateReports)
		return () => {
			window.removeEventListener("resize", updateReports)
		}
	}, [studiesProvider.selectedStudyId, reportsUpdate, reportTemplateData])

	

	function updateSubheaderValues(table, value) {
		if (SubheaderValues[table]) {
			return;
		}
		let newSubheaderValues = Object.assign(
			{},
			...Object.keys(SubheaderValues).map((table) => {
				return { [table]: false };
			})
		);
		setSubheaderValues({ ...newSubheaderValues, [table]: value })

		if (!SubheaderOrder.includes(table)) {
			setSubheaderOrder(
				Array.prototype.concat(SubheaderOrder, table)
			);

			let new_table = {};
			for (let group of Object.keys(
				tableConfig[SubheaderOrder[0]]
			)) {
				new_table[group] = {};
				for (let e of Object.keys(
					tableConfig[SubheaderOrder[0]][group]
				)) {
					new_table[group][e] = false;
				}
			}

			setTableConfig({ ...tableConfig, [table]: new_table })
		}
	}
	
	function updateRows({selectedRows}) {
		setSelectedRows(selectedRows)
	}

	async function handleRemoveReports() {    
		if (!studiesProvider.selectedStudyId) alert('Fail to remove selected reports. Try again!')
		else{
			if (selectedRows.length) {
				let report_names = selectedRows.map(r => r.name)
				await Promise.all(report_names.map(report_name => removeReport(report_name, studiesProvider.selectedStudyId))).then((text) => {console.log(text)})
				updateReports()
				setSelectedRows(false)
				setToggleCleared(!toggleCleared)	
			} 
		}
    }

	function checkMultimodalSearch(val) {
		if (searchQuery === "") {
			return val
		} else if (val.report_name.toLowerCase().includes(searchQuery.toLowerCase())) {
			return val
		} else if (val.created_by.toLowerCase().includes(searchQuery.toLowerCase())) {
			return val
		} else if (val.version.toLowerCase().includes(searchQuery.toLowerCase())) {
			return val
		}
	}

	const [studyToggleCleared, setStudyToggleCleared] = useState(false);
	function updateStudies() {
		setStudyUpdate([])
	}

	function renderReportStudies() {
		if (document.getElementById("assignReportStudiesTable").style.display !== "none") {
			document.getElementById("assignReportStudiesTable").style.display = "none";
			setAssignReportStudiesIcon(showMore)
		} else {
			document.getElementById("assignReportStudiesTable").style.display = "block";
			setAssignReportStudiesIcon(showLess)
		}
	}

	function updateStudiesTableSelected({selectedRows}) {
        setStudyTableSelected(selectedRows)
    }

	const [reportTemplateNames, setReportTemplateNames] = useState([])
	useEffect(() => {
		let arr = []
		reportTemplateData.forEach((elem) => {
			arr.push(elem.name)
		})
		setReportTemplateNames(arr)
	}, [reportTemplateData])
		

	useEffect(() => {
		if (studiesProvider.studies?.length === 0) return

		let columns2labels = {
			name: { label: "Name", type: "string" },
			study_id: { label: "Study ID", type: "string" },
			start_date: { label: "Start Date", type: "string" },
			study_type: { label: "Study Type", type: "string" }
		}

		let columnData = []

		for (let elem of Object.keys(columns2labels)) {
			columnData.push({
				name: columns2labels[elem]['label'],
				selector: elem,
				sortable: true,
			})
		}

		setStudyTableColumns(columnData)
		setStudyPending(true)
		// const tmpStudyData = studyData ? studyData : {'data': []}
		// console.log('tmpStudyData', tmpStudyData)
		let tableData = studiesProvider.studies?.map(row => {
			let entry = {}
			for (let column of Object.keys(columns2labels)) {
				if (columns2labels[column]["type"] === "checkbox") {
					if (row[column]) {
						entry[column] = (<MdIcons.MdOutlineCheckBox />)
					} else {
						entry[column] = (<MdIcons.MdOutlineCheckBoxOutlineBlank />)
					}
				} else if (columns2labels[column]["type"] === 'string') {
					entry[column] = row[column]
				}
			}
			entry.selected = studyPreselect.includes(row.study_id)
			entry.study_id = row.study_id
			return entry
		})
			
        tableData = tableData.filter((row) => {
			if (studySearchQuery === "") {
				return true
			} else if (row.name.toLowerCase().includes(studySearchQuery.toLowerCase())) {
				return true
			} else if (row.study_id.toLowerCase().includes(studySearchQuery.toLowerCase())) {
				return true
			} else if (row.start_date.toLowerCase().includes(studySearchQuery.toLowerCase())) {
				return true
			} else if (row.study_type.toLowerCase().includes(studySearchQuery.toLowerCase())) {
				return true
			}
			return false
		})

		setStudyTableData(tableData)
        setStudyPending(false)
	
	}, [studiesProvider.studies, studyPreselect, studySearchQuery, studyUpdate])

	const pageSubHeader = (
		<div
			style={{
				marginLeft: "-15px",
				background: "#FFFFFF",
				zIndex: "-9999"
			}}
		>
			<PageSubHeader
				style={{
					width: props.sidebar
						? `${window.innerWidth - 250}px`
						: `${window.innerWidth - 30}px`,
				}}
			>
				{SubheaderOrder.map((table, i) => (
					<TableButton
						id={`TableButton_${table}`}
						colorchange={
							SubheaderValues[table]
						}
						underlined={SubheaderValues[table]}
						onClick={() => {
							updateSubheaderValues(
								table,
								!SubheaderValues[table]
							);
						}}
						
					>
					{table === "Run" ? <Link to={FRONTEND_LINKS.SUBPAGES.REPORTING.SUBPAGES.MULTIMODAL_REPORTS.SUBPAGES.RUN.MAIN} style={{textDecoration: "none",color:"#6C757C",}}> <p>{table}</p></Link> :
					<p>{table}</p> }
					</TableButton>
				))}
			</PageSubHeader>
		</div>
	)

	return (
		<div style={{background: "#F8F8F8", maxHeight: `${window.innerHeight - 74}px`}}>
			{pageSubHeader}

			<div style={{
				width: "100%",
				height: "100%",
				color: DEFAULTS.TEXT_COLOR,
				background: "#F8F8F8",
				paddingLeft: "35px",
				paddingRight: "35px",
				display: "block",
				}}>
				<ButtonHeader style={{marginTop: "20px"}}>
					<CreateReportButton onClick={() => setCreateReportModalShow(true)}><MdIcons.MdModeEdit size={18} style={{marginTop: "-3px"}}/> Create Report</CreateReportButton>
					<TrashButton 
						onClickFxn={handleRemoveReports()}
						disabled={!selectedRows.length > 0}
						buttonStyle={{marginRight: "25%", flex: "0.1"}}
					/>
					<Searchbar className="ui left icon input">
						<input type="text" placeholder="Search" onChange={event => {setSearchQuery(event.target.value)}}/> <i aria-hidden="true" class="search icon" />
						<button onClick={()=> {setSearchQuery(searchQuery)}}>Search</button>
					</Searchbar>
				</ButtonHeader>

				<div>
					<MainTable
					columns={reportTableColumns}
					data={reportTableData}
                    // selectRows={false}
					selectableRows
					progressPending={reportTablePending}
                    onRowClicked={undefined}
					onSelectedRowsChange={updateRows}
					clearSelectedRows={toggleCleared}
					pagination/>
                </div>

				<div id="createMultimodalReportModal" style={{display: "none", position: "fixed", top: "0px", left: "5px", right: "5px", width: `${window.innerWidth - 10}px`, zIndex: "9999", background: "#FFFFFF", border: "1px solid black", height: `${window.innerHeight - 10}px`}}>
					<div>
						<div style={{ background: "none", display: "inline-flex", marginLeft: "40px", marginTop: "14px", marginBottom: "-40px"}}>
							<PageSubHeader style={{ width: "100%",paddingLeft: "0px",}}>
							</PageSubHeader>
							</div>
						<MdIcons.MdCloseFullscreen onClick={() => {closeEditMultimodalReportModal()}} size={22} style={{right: "14px", marginTop: "14px", position: "absolute", cursor: "pointer"}}/>

						<hr style={{border: "1px solid #DDDDDD", marginTop: "-16px"}}/>

						{/* <div id="multimodalReportIconButtonHeader" style={{display: "inline-flex", marginLeft: "40px", marginTop: "-12px", marginBottom: "12px"}}>
							{Object.entries(displayHeaderIconButtons).map(([key, group]) => (
								Object.entries(group).map(([label, icon]) => {
									return (
										<div style={{display: "inline-flex", marginRight: "16px"}}> */}
											{/* <p style={{marginRight: "6px", display: 'none' ? icon['label'] === '' : 'block'}}>{icon['label']}</p> */}
											{/* {(icon['icons'].map((button) => (
												<IconButton><p>{button}</p></IconButton>
											)))}
										</div>
									)
								})
							))}
						</div> */}

						<div id="multimodalReportModalContent" style={{display: "block", background: "#F8F8F8", maxHeight: `${window.innerHeight - 125}px`, overflowY: "scroll", paddingLeft: "40px", paddingRight: "40px", paddingTop: "20px"}}>
							
							<CreateReports props={props}/>
						</div>


					</div>
				</div>

				<div id="editMultimodalReportModal" style={{display: "none", position: "absolute", top: "20px", left: "15px", right: "0px", width: `${window.innerWidth - 25}px`, zIndex: "9999", background: "#FFFFFF", border: "1px solid black", height: `${window.innerHeight - 25}px`}}>
					<div>
						<PageSubHeader style={{ width: "100%",paddingLeft: "0px",}}>
							<MdIcons.MdCloseFullscreen onClick={() => {closeEditMultimodalReportModal()}} size={22} style={{right: "14px", marginTop: "14px", position: "absolute", cursor: "pointer"}}/>
						</PageSubHeader>

						<hr style={{border: "1px solid #DDDDDD", marginTop: "0px"}}/>

						<div id="multimodalReportModalContent" style={{display: "block", background: "#F8F8F8", maxHeight: `${window.innerHeight - 125}px`, overflowY: "scroll", paddingLeft: "40px", paddingRight: "40px", paddingTop: "10px"}}>
							<CreateReports props={props} name={reportTemplateName} id={reportTemplateId}/>
						</div>
					</div>
				</div>

                </div>
				<CreateReportModalBootstrap 
					show={createReportModalShow}
        			onHide={() => setCreateReportModalShow(false)}
					refreshTable={updateReports}
					reportTemplateNames={reportTemplateNames}
				/>

				<CreateReportDetailsModalBootstrap
					show={createReportDetailsModalShow}
					onHide={() => setCreateReportDetailsModalShow(false)}
					reportId={currentReportId}
					createReport={false}
				/>
				</div>)
}

const ButtonHeader = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding-bottom: 10px;
	position: relative;
`;

const CreateReportButton = styled.button`
	width: 115px;
	height: 34px;
	background: #207dea;
	border-radius: 6px;
	font-family: 'Source Sans Pro';
	font-style: normal;
	font-weight: 600;
	line-height: 150%;
	color: #FFFFFF;
	border: none;
	padding: 4px 8px;
	margin-right: 8px;
	white-space: nowrap;
	flex: 0.25;
`;

const Searchbar = styled.div`
	width: 40%;
	height: 34px;
	background: #FFFFFF;
	border: 0.5px solid #d9d9d9;
	box-sizing: border-box;
	box-shadow: 0px 0px 3px rgba(99, 191, 244, 0.15);
	border-radius: 6px;
	overflow-y: hidden;
	overflow-x: hidden;
	flex: 2;
	button {
		width: 80px;
		height: 34px;
		background: #207dea;
		border-radius: 0px 6px 6px 0px;
		border: none;
		font-family: 'Source Sans Pro';
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 150%;
		color: #FFFFFF;
		cursor: pointer;
		padding-bottom: 3.5px;
	}
`;

const PageSubHeader = styled.div`
	display: flex;
	width: 100%;
	height: 47px;
	padding-left: 50px;
	padding-right: 50px;
	overflow-x: auto;
	overflow-y: hidden;
	white-space: nowrap;
	background: #ffffff;
	// padding-top: 10px;
	// margin-bottom: 25px;
	button {
		font-family: "Source Sans Pro";
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		display: inline-flex;
		border: solid 0.5px #ffffff;
		background: #ffffff;
		cursor: pointer;
	}
`;

const TableButton = styled.button`
	width: auto;
	height: 34px;
    background: #ffffff;
	border: solid 0.5px #ffffff;
	font-size: 16px;
	display: inline-flex;
	margin-right: 28px;
	color: ${({colorchange}) => (colorchange ? "#0097ee" : "#6C757C")};
	p {
		padding-bottom: 10px;
		border-bottom: ${({underlined}) =>
			underlined ? "2px solid #0097ee" : "none"};
	}
`;

const EditUserButton = styled.button`
	width: auto;
	height: 34px;
	background: transparent;
	border-radius: 6px;
	font-family: 'Source Sans Pro';
	font-style: normal;
	font-weight: 600;
	line-height: 150%;
	color: #000000;
	border: none;
	padding: 4px 8px;
	margin-right: 8px;
	white-space: nowrap;
	flex: 0.25;
`;

export default MultimodalReports;

