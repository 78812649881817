import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import * as MdIcons from 'react-icons/md'
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import EyeIcon from '../Icons/EyeIcon';
import { useModalProvider } from '../../Providers/ModalProvider';
import { useEndpointProvider } from '../../Providers/EndpointProvider';
import ViewFileModal from './Modals/ViewFileModal';
import { useBackendLinksProvider } from '../../Providers/BackendLinksProvider';

function TableBody({ columns = [], rows = [], childLevelData = {}, navigateIntoDirectory = () => { }, getChildData = () => { }, numUnfetchedChildData = {}, isFetching, sortModel, setSortModel = () => { }, prevID, setPrevID = () => { }, totalFiles, visibleColumns, patient = {} }) {
    const endpointProvider = useEndpointProvider()
    const { createModal } = useModalProvider()
    const { LINKS } = useBackendLinksProvider()

    const [dataRows, setDataRows] = useState(rows)

    const containerRefs = useRef({})
    const TableBodyRef = useRef()
    const EmptyCellRef = useRef({})
    const SpinnerRef = useRef({})

    function addNewEmptyCellRef(ref, path) {
        EmptyCellRef.current[path] = ref
    }

    function addNewSpinnerRef(ref, path) {
        SpinnerRef.current[path] = ref
    }

    function addNewContainerRef(ref, path, containerIndex) {
        if (Object.keys(containerRefs.current).includes(path)) {
            containerRefs.current[path][containerIndex] = ref
        } else {
            containerRefs.current[path] = [ref]
        }
    }

    const handleContainerScroll = (event, path, containerIndex) => {
        if (SpinnerRef.current[path] && EmptyCellRef.current[path] && TableBodyRef.current) {
            const emptyCellRect = EmptyCellRef.current[path].getBoundingClientRect()
            const containerRect = containerRefs.current[path][containerIndex].getBoundingClientRect()
            const spinnerRect = SpinnerRef.current[path].getBoundingClientRect()
            const tableBodyRect = TableBodyRef.current.getBoundingClientRect()

            // Calculate the available space for the spinner within the container
            const availableSpace = containerRect.bottom - emptyCellRect.top - 10

            // Calculate the new max height based on available space
            let newMaxHeight = availableSpace

            // Check if the spinner is taller than the available space
            if (spinnerRect.height > availableSpace) {
                // Calculate how much the spinner goes beyond the available space
                const overflowAmount = spinnerRect.bottom - containerRect.bottom

                // Decrease the new max height to accommodate the overflow
                newMaxHeight -= overflowAmount
            }

            // Update the spinner's max height
            if (SpinnerRef.current[path]) {
                SpinnerRef.current[path].style.maxHeight = `${newMaxHeight - 3 < 0 ? 0 : newMaxHeight - 3}px`

                // Position the spinner
                SpinnerRef.current[path].style.top = `${emptyCellRect.top + 10}px`
                SpinnerRef.current[path].style.left = `${(tableBodyRect.right + tableBodyRect.left) / 2 - (SpinnerRef.current[path].offsetWidth / 2)}px`
            }
        }
    }

    const handleGetMoreChildData = (event, path) => {
        const { scrollTop, scrollHeight, clientHeight } = event.target
        const marginOfError = 1 // Adjust this margin as needed

        if (!isFetching[path] && scrollHeight - scrollTop <= clientHeight + marginOfError && numUnfetchedChildData[path] > 0) {
            // Scrolled to the bottom, load more data
            getChildData(path, true);
        }
    }

    useEffect(() => {
        setDataRows(rows)
    }, [rows])

    function expandDirectory(row, id) {
        setPrevID(id)

        const newDataRows = structuredClone(dataRows)
        let isCollapse = false
        newDataRows.forEach(row => {
            if (row.id === id) {
                isCollapse = row.expanded
                row.expanded = !row.expanded
            }
        })
        setDataRows(newDataRows)

        if (isCollapse || prevID === id) return

        getChildData(row.path)
    }

    function handleDoubleClick(row) {
        if (row['type'] === 'directory') {
            // Call navigateIntoDirectory when double-clicking on a directory row
            navigateIntoDirectory(row)
        }
    }

    const cellRefs = useRef({})

    function addNewCellRef(ref, id) {
        // Check if the id already has a ref array
        if (cellRefs.current[id]) {
            // Add the new ref to the array if it's not already included
            if (!cellRefs.current[id].includes(ref)) {
                cellRefs.current[id].push(ref)
            }
        } else {
            // Initialize a new array with the ref
            cellRefs.current[id] = [ref]
        }
    }

    function handleMouseEnter(id) {
        if (cellRefs.current && cellRefs.current[id]) {
            const elements = cellRefs.current[id]

            if (elements) {
                for (let i = 0; i < elements.length; i++) {
                    if (elements[i]) {
                        elements[i].style.background = '#EEEEEE'
                    }
                }
            }
        }
    }

    const handleMouseLeave = (id) => {
        if (cellRefs.current && cellRefs.current[id]) {
            const elements = cellRefs.current[id]

            if (elements) {
                for (let i = 0; i < elements.length; i++) {
                    if (elements[i]) {
                        elements[i].style.background = ''
                    }
                }
            }
        }
    }

    function truncateString(str) {
        if (str?.length > 70) {
            return (
                <span title={str}>
                    {str?.slice(0, 70) + '...'}
                </span>
            )
        }
        return str
    }

    function renderSortIcon(column) {
        const foundSort = sortModel.find(state => state.field === column.field)

        if (!foundSort) {
            return null
        } else if (foundSort.sort === 'asc') {
            const element = document.getElementById(`ArrowOnHover-${column.field}`)
            if (element) {
                element.style.display = 'none'
            }

            return <IconButton size='small'>
                <MdIcons.MdArrowUpward size={16} />
            </IconButton>
        } else {
            const element = document.getElementById(`ArrowOnHover-${column.field}`)
            if (element) {
                element.style.display = 'none'
            }

            return <IconButton size='small'>
                <MdIcons.MdArrowDownward size={16} />
            </IconButton>
        }
    }

    function enableHoverArrow(column, enable) {
        const element = document.getElementById(`ArrowOnHover-${column.field}`)
        if (element) {
            if (enable) {
                element.style.display = 'flex'
            } else {
                element.style.display = 'none'
            }
        }
    }

    useEffect(() => {
        const fetching = Object.keys(isFetching).some(path => isFetching[path])
        if (fetching && TableBodyRef.current && EmptyCellRef.current && SpinnerRef.current) {
            const tableBodyRect = TableBodyRef.current.getBoundingClientRect()

            Object.keys(SpinnerRef.current).forEach(path => {
                if (SpinnerRef.current[path]) {
                    const emptyCellRect = EmptyCellRef.current[path].getBoundingClientRect()
                    if (SpinnerRef.current[path].id === 'TotalResults') {
                        SpinnerRef.current[path].style.top = `${emptyCellRect.top + 10}px`
                    } else {
                        SpinnerRef.current[path].style.top = `${emptyCellRect.top - 30}px`
                    }

                    SpinnerRef.current[path].style.left = `${(tableBodyRect.right + tableBodyRect.left) / 2 - (SpinnerRef.current[path].offsetWidth / 2)}px`
                }
            })
        }
    }, [isFetching])

    const handleScroll = () => {
        if (
            TableBodyRef.current &&
            EmptyCellRef.current &&
            SpinnerRef.current
        ) {
            const tableBodyRect = TableBodyRef.current.getBoundingClientRect()

            Object.keys(SpinnerRef.current).forEach(path => {
                if (SpinnerRef.current[path]) {
                    const emptyCellRect = EmptyCellRef.current[path].getBoundingClientRect()
                    SpinnerRef.current[path].style.top = `${emptyCellRect.top + 10}px`
                    SpinnerRef.current[path].style.left = `${(tableBodyRect.right + tableBodyRect.left) / 2 - (SpinnerRef.current[path].offsetWidth / 2)}px`
                }
            })
        }
    }

    useEffect(() => {
        // Attach scroll event listener to the window
        window.addEventListener('scroll', handleScroll)

        return () => {
            // Remove the scroll event listener when the component unmounts
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    const isCellInView = (cell, container) => {
        if (!cell || !container) return false

        const containerRect = container.getBoundingClientRect()

        return cell.top >= containerRect.top && cell.bottom <= containerRect.bottom
    }

    const handleTableBodyResize = (entries) => {
        for (const entry of entries) {
            if (!TableBodyRef.current) return
            const tableBodyRect = TableBodyRef.current.getBoundingClientRect()

            Object.keys(SpinnerRef.current).forEach(path => {
                if (SpinnerRef.current[path]) {
                    const emptyCellRect = EmptyCellRef.current[path].getBoundingClientRect()
                    SpinnerRef.current[path].style.top = `${emptyCellRect.top + 10}px`
                    SpinnerRef.current[path].style.left = `${(tableBodyRect.right + tableBodyRect.left) / 2 - (SpinnerRef.current[path].offsetWidth / 2)}px`
                    const isVisible = isCellInView(emptyCellRect, TableBodyRef.current)
                    SpinnerRef.current[path].style.maxHeight = isVisible ? '25px' : '0'
                }
            })
        }
    }

    useEffect(() => {
        // Check if the table body ref exists
        if (TableBodyRef.current) {
            const TableBodyNode = TableBodyRef.current

            // Create a new ResizeObserver instance
            const resizeObserver = new ResizeObserver(handleTableBodyResize)

            // Start observing the table body for size changes
            resizeObserver.observe(TableBodyNode)

            // Clean up the observer when the component unmounts
            return () => {
                resizeObserver.unobserve(TableBodyNode)
                resizeObserver.disconnect()
            }
        }
    }, [])

    useEffect(() => {
        handleScroll()
    }, [visibleColumns])

    const togglePopper = (event, id) => {
        const button = event.currentTarget

        if (popperPosition.isOpen && popperPosition.id === id) {
            setPopperPosition({ ...popperPosition, isOpen: false })
        } else {
            const rect = button.getBoundingClientRect()

            // Calculate the right position based on the button's left position
            const rightPosition = document.body.offsetWidth - rect.right

            setPopperPosition({
                top: rect.bottom + window.scrollY,
                right: rightPosition,
                isOpen: true,
                id: id,
            })
        }
    }

    const [popperPosition, setPopperPosition] = useState({ top: 0, left: 0, isOpen: false })
    const popperRefs = useRef({})

    // Function to get or create a ref for a given item
    const getPopperRef = (item) => {
        if (!popperRefs.current[item]) {
            popperRefs.current[item] = React.createRef()
        }
        return popperRefs.current[item]
    };

    const handleClickOutside = (event) => {
        if (popperPosition.isOpen) {
            const isOutside = Object.keys(popperRefs.current).every((key) => {
                const currentRef = popperRefs.current[key];
                return currentRef.current && !currentRef.current.contains(event.target)
            })
            if (isOutside) {
                setPopperPosition({ ...popperPosition, isOpen: false })
            }
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        };
    }, [popperPosition])

    async function handleViewFile(file_path) {
        createModal(<ViewFileModal
            style={{ maxWidth: '90%', minWidth: '50%', width: '100%' }}
            escClose={false}
            clickOutsideClose={false}
            patient={patient}
            file_path={file_path}
        />)
    }

    const [isDownloading, setIsDownloading] = useState(false)

    async function handleDownloadFile(file_path) {
        const body = {
            'project_id': patient?.project_id,
            'patient_id': patient?.patient_key,
            'file_path': file_path
        }

        setIsDownloading(true)
        await endpointProvider.post(LINKS.DATA.UPLOAD.DOWNLOAD_FILE, body).then(async (response) => {
            const url = window.URL.createObjectURL(response)
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', file_path.split('/').at(-1))
            document.body.appendChild(link)
            setIsDownloading(false)
            link.click()
            link.parentNode.removeChild(link)
            window.URL.revokeObjectURL(url)
        }).catch(error => {
            setIsDownloading(false)
            alert(error)
        })
    }

    return (
        <TableBodyContainer ref={TableBodyRef} style={{ flexDirection: 'row', border: '1px solid #ccc' }}>
            {dataRows.length === 0 ? (
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <div style={{ display: 'flex' }}>
                        {columns.filter(col => visibleColumns.includes(col.field)).map((column, index) => {
                            return (
                                <HeaderRowCell
                                    key={column.field}
                                    className='TableCell'
                                    style={{
                                        borderRight: index === columns.length - 1 ? 'none' : '1px solid #ccc',
                                        justifyContent: column.field === "actions" ? 'center' : 'normal'
                                    }}
                                    id={`${!sortModel.find(state => state.field === column.field)} - ${column.field}`}
                                    onMouseEnter={() => {
                                        if (column.field !== "actions" && !sortModel.find(state => state.field === column.field)) {
                                            enableHoverArrow(column, true)
                                        }
                                    }}
                                    onMouseLeave={() => {
                                        if (column.field !== "actions" && !sortModel.find(state => state.field === column.field)) {
                                            enableHoverArrow(column, false)
                                        }
                                    }}
                                    onClick={() => {
                                        if (column.field === "actions") return

                                        const foundColumn = sortModel[0]
                                        if (!foundColumn || foundColumn.field !== column.field) {
                                            setSortModel([{ field: column.field, sort: 'asc' }])
                                            return
                                        }

                                        if (foundColumn && foundColumn.sort === 'asc') {
                                            setSortModel([{ field: column.field, sort: 'desc' }])
                                        } else if (foundColumn && foundColumn.sort === 'desc') {
                                            setSortModel([])
                                        } else {
                                            alert('Error')
                                            return
                                        }
                                    }}
                                >
                                    <HeaderRowCellText style={{ margin: 0 }}>{column.headerName}</HeaderRowCellText>

                                    {renderSortIcon(column)}

                                    <IconButton id={`ArrowOnHover-${column.field}`} size='small' sx={{ display: 'none' }}>
                                        <MdIcons.MdArrowUpward size={16} style={{ opacity: 0.5 }} />
                                    </IconButton>

                                </HeaderRowCell>
                            )
                        })}
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px', fontSize: '16px', fontFamily: 'Source Sans Pro' }}>
                        No files found
                    </div>
                </div>
            ) : <ScrollSync>
                <>
                    {columns.filter(col => visibleColumns.includes(col.field)).map((column, index) => (
                        <TableColumn key={column.field} style={{ width: column.field === "actions" ? 'min-content' : '100%' }}>
                            <HeaderRowCell className='TableCell'
                                style={{
                                    borderRight: index === columns.length - 1 ? 'none' : '1px solid #ccc',
                                    justifyContent: column.field === "actions" ? 'center' : 'normal',
                                    padding: column.field === "actions" ? '0px 20px 0px 10px' : '0px'
                                }}
                                id={`${!sortModel.find(state => state.field === column.field)}`}
                                onMouseEnter={() => {
                                    if (column.field !== "actions" && !sortModel.find(state => state.field === column.field)) {
                                        enableHoverArrow(column, true)
                                    }
                                }}
                                onMouseLeave={() => {
                                    if (column.field !== "actions" && !sortModel.find(state => state.field === column.field)) {
                                        enableHoverArrow(column, false)
                                    }
                                }}
                                onClick={() => {
                                    if (column.field === "actions") return

                                    const foundColumn = sortModel[0]
                                    if (!foundColumn || foundColumn.field !== column.field) {
                                        setSortModel([{ field: column.field, sort: 'asc' }])
                                        return
                                    }

                                    if (foundColumn && foundColumn.sort === 'asc') {
                                        setSortModel([{ field: column.field, sort: 'desc' }])
                                    } else if (foundColumn && foundColumn.sort === 'desc') {
                                        setSortModel([])
                                    } else {
                                        alert('Error')
                                        return
                                    }
                                }}
                            >
                                <HeaderRowCellText style={{ margin: 0 }}>{column.headerName}</HeaderRowCellText>

                                {renderSortIcon(column)}

                                <IconButton id={`ArrowOnHover-${column.field}`} size='small' sx={{ display: 'none' }}>
                                    <MdIcons.MdArrowUpward size={16} style={{ opacity: 0.5 }} />
                                </IconButton>

                            </HeaderRowCell>

                            {dataRows.map((row, rowIndex) => {
                                if (column.field === 'actions' && row.type === 'file' && (['.csv', '.xlsx', 'xls'].some(char => row.name.endsWith(char)))) {
                                    return (
                                        <TableRowCell ref={(ref) => addNewCellRef(ref, `None-${rowIndex}`)} style={{ justifyContent: 'center', padding: column.field === "actions" ? '0px 20px 0px 10px' : '0px' }} onMouseOver={() => handleMouseEnter(`None-${rowIndex}`)} onMouseLeave={() => handleMouseLeave(`None-${rowIndex}`)} className={`TableCell-${rowIndex}`} isDirectory={row['type'] === 'directory'} onDoubleClick={() => handleDoubleClick(row)}>
                                            <IconButton
                                                size='small'
                                                onClick={(event) => {
                                                    togglePopper(event, `None-${rowIndex}`)
                                                }}
                                                sx={{ minWidth: '30px' }}
                                            >
                                                <MoreVertIcon />
                                            </IconButton>

                                            <PopperContainer
                                                ref={getPopperRef(row.id)}
                                                style={{
                                                    top: `${popperPosition.top}px`,
                                                    right: `${popperPosition.right}px`,
                                                    display: popperPosition.isOpen && popperPosition.id === `None-${rowIndex}` ? 'flex' : 'none',
                                                }}
                                            >
                                                <List>
                                                    <ListItem disablePadding title={!row.path.endsWith('.csv') ? 'File type not supported' : ''}>
                                                        <ListItemButton disabled={!row.path.endsWith('.csv')} onClick={() => handleViewFile(row.path)}>
                                                            <ListItemIcon sx={{ minWidth: '30px' }}>
                                                                <EyeIcon />
                                                            </ListItemIcon>
                                                            <ListItemText primary="View" />
                                                        </ListItemButton>
                                                    </ListItem>

                                                    <ListItem disablePadding sx={{ justifyContent: !isDownloading ? 'flex-start' : 'center' }}>
                                                        {!isDownloading ?
                                                            <ListItemButton onClick={() => handleDownloadFile(row.path)}>
                                                                <ListItemIcon sx={{ minWidth: '30px' }}>
                                                                    <MdIcons.MdDownload size={20} />
                                                                </ListItemIcon>
                                                                <ListItemText primary="Download" />
                                                            </ListItemButton>
                                                            :
                                                            <CircularProgress />
                                                        }
                                                    </ListItem>
                                                </List>
                                            </PopperContainer>
                                        </TableRowCell>
                                    )
                                } else {
                                    return (
                                        <>
                                            <TableRowCell ref={(ref) => addNewCellRef(ref, `None-${rowIndex}`)} title={row.path} id={`${row.path}`} onMouseOver={() => handleMouseEnter(`None-${rowIndex}`)} onMouseLeave={() => handleMouseLeave(`None-${rowIndex}`)} className={`TableCell-${rowIndex}`} isDirectory={row['type'] === 'directory'} onDoubleClick={() => handleDoubleClick(row)}>
                                                {column.field === 'name' ? (
                                                    <TableRowCellText style={{ display: 'flex', alignItems: 'center', paddingLeft: '5px' }}>
                                                        {row['type'] === 'directory' ? (
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                {row['expanded'] ? (
                                                                    <MdIcons.MdExpandMore size={22} style={{ marginRight: '-1px' }} onClick={() => expandDirectory(row, row.id)} />
                                                                ) : (
                                                                    <MdIcons.MdArrowForwardIos size={16} style={{ marginRight: '5px' }} onClick={() => expandDirectory(row, row.id)} />
                                                                )}

                                                                <MdIcons.MdOutlineFolder size={18} style={{ marginRight: '8px' }} />
                                                            </div>
                                                        ) : (
                                                            <MdIcons.MdInsertDriveFile size={18} style={{ marginRight: '8px', marginLeft: '20px' }} />
                                                        )}
                                                        {truncateString(row[column.field])}
                                                    </TableRowCellText>
                                                ) : (
                                                    <TableRowCellText>
                                                        {truncateString(row[column.field])}
                                                    </TableRowCellText>
                                                )}
                                            </TableRowCell>

                                            {row.expanded && childLevelData[row.path] && (
                                                <ScrollSyncPane group={row.path}>
                                                    <ChildRowContainer
                                                        className='ChildRowContainer'
                                                        ref={(ref) => { addNewContainerRef(ref, row.path, index) }}
                                                        onScroll={(e) => { handleContainerScroll(e, row.path, index); handleGetMoreChildData(e, row.path) }}
                                                        id={`ShowScrollbar-${index}-${columns.filter(col => visibleColumns.includes(col.field)).length - 1}`}
                                                        showScrollbar={index === columns.filter(col => visibleColumns.includes(col.field)).length - 1}
                                                        style={{ borderBottom: rowIndex === dataRows.length - 1 ? 'none' : '1px solid #ccc' }}
                                                    >
                                                        {childLevelData[row.path].map((childRow, childRowIndex) => {
                                                            if (column.field === 'actions' && childRow.type === 'file' && (['.csv', '.xlsx', 'xls'].some(char => childRow.name.endsWith(char)))) {
                                                                return (
                                                                    <>
                                                                        <TableRowCell ref={(ref) => addNewCellRef(ref, `${rowIndex}-${childRowIndex}`)} style={{ justifyContent: 'center' }} onMouseOver={() => handleMouseEnter(`${rowIndex}-${childRowIndex}`)} onMouseLeave={() => handleMouseLeave(`${rowIndex}-${childRowIndex}`)} className={`ChildTableCell-${rowIndex}-${childRowIndex}`} isDirectory={childRow['type'] === 'directory'} onDoubleClick={() => handleDoubleClick(childRow)} title={childRow.path}>
                                                                            <IconButton
                                                                                size='small'
                                                                                onClick={(event) => {
                                                                                    togglePopper(event, `${rowIndex}-${childRowIndex}`)
                                                                                }}
                                                                                sx={{ minWidth: '30px' }}
                                                                            >
                                                                                <MoreVertIcon />
                                                                            </IconButton>

                                                                            <PopperContainer
                                                                                ref={getPopperRef(childRow.id)}
                                                                                style={{
                                                                                    top: `${popperPosition.top}px`,
                                                                                    right: `${popperPosition.right}px`,
                                                                                    display: popperPosition.isOpen && popperPosition.id === `${rowIndex}-${childRowIndex}` ? 'flex' : 'none',
                                                                                }}
                                                                            >
                                                                                <List>
                                                                                    <ListItem id={childRow.path} disablePadding title={!childRow.path.endsWith('.csv') ? 'File type not supported' : ''}>
                                                                                        <ListItemButton disabled={!childRow.path.endsWith('.csv')} onClick={() => handleViewFile(childRow.path)}>
                                                                                            <ListItemIcon sx={{ minWidth: '30px' }}>
                                                                                                <EyeIcon />
                                                                                            </ListItemIcon>
                                                                                            <ListItemText primary="View" />
                                                                                        </ListItemButton>
                                                                                    </ListItem>

                                                                                    <ListItem title={childRow.path} disablePadding sx={{ justifyContent: !isDownloading ? 'flex-start' : 'center' }}>
                                                                                        {!isDownloading ?
                                                                                            <ListItemButton onClick={() => handleDownloadFile(childRow.path)}>
                                                                                                <ListItemIcon sx={{ minWidth: '30px' }}>
                                                                                                    <MdIcons.MdDownload size={20} />
                                                                                                </ListItemIcon>
                                                                                                <ListItemText primary="Download" />
                                                                                            </ListItemButton>
                                                                                            :
                                                                                            <CircularProgress />
                                                                                        }
                                                                                    </ListItem>
                                                                                </List>
                                                                            </PopperContainer>
                                                                        </TableRowCell>

                                                                        {isFetching[row.path] && childRowIndex === childLevelData[row.path].length - 1 &&
                                                                            <>
                                                                                <TableRowCell id={`EmptyCell-${row.path}`} ref={(ref) => { addNewEmptyCellRef(ref, row.path) }}>
                                                                                    {index === 0 &&
                                                                                        <div ref={(ref) => { addNewSpinnerRef(ref, row.path) }} style={{ position: 'fixed', overflow: 'hidden', maxHeight: '25px' }}>
                                                                                            <CircularProgress size={25} sx={{}} />
                                                                                        </div>
                                                                                    }
                                                                                </TableRowCell>
                                                                            </>
                                                                        }

                                                                        {!isFetching[row.path] && childRowIndex === childLevelData[row.path].length - 1 && numUnfetchedChildData[row.path] === 0 &&
                                                                            <>
                                                                                <TableRowCell id={`EmptyCell-${row.path}`} ref={(ref) => { addNewEmptyCellRef(ref, row.path) }}>
                                                                                    {index === 0 &&
                                                                                        <div id='TotalResults' ref={(ref) => { addNewSpinnerRef(ref, row.path) }} style={{ position: 'fixed', overflow: 'hidden', maxHeight: '25px' }}>
                                                                                            {totalFiles[row.path]} Result{totalFiles[row.path] === 1 ? '' : 's'} Shown
                                                                                        </div>
                                                                                    }
                                                                                </TableRowCell>
                                                                            </>
                                                                        }
                                                                    </>
                                                                )
                                                            } else {
                                                                return (
                                                                    <>
                                                                        <TableRowCell ref={(ref) => addNewCellRef(ref, `${rowIndex}-${childRowIndex}`)} title={childRow.path} onMouseOver={() => handleMouseEnter(`${rowIndex}-${childRowIndex}`)} onMouseLeave={() => handleMouseLeave(`${rowIndex}-${childRowIndex}`)} className={`ChildTableCell-${rowIndex}-${childRowIndex}`} isDirectory={childRow['type'] === 'directory'} onDoubleClick={() => handleDoubleClick(childRow)}>
                                                                            {column.field === 'name' ? (
                                                                                <TableRowCellText style={{ display: 'flex', alignItems: 'center', paddingLeft: '5px' }}>
                                                                                    {childRow['type'] === 'directory' ? (
                                                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                            <MdIcons.MdOutlineFolder size={18} style={{ marginRight: '8px', marginLeft: '45px' }} />
                                                                                        </div>
                                                                                    ) : (
                                                                                        <MdIcons.MdInsertDriveFile size={18} style={{ marginRight: '8px', marginLeft: '45px' }} />
                                                                                    )}
                                                                                    {childRow[column.field]}
                                                                                </TableRowCellText>
                                                                            ) : (
                                                                                <TableRowCellText>
                                                                                    {childRow[column.field]}
                                                                                </TableRowCellText>
                                                                            )}
                                                                        </TableRowCell>

                                                                        {isFetching[row.path] && childRowIndex === childLevelData[row.path].length - 1 &&
                                                                            <>
                                                                                <TableRowCell id={`EmptyCell-${row.path}`} ref={(ref) => { addNewEmptyCellRef(ref, row.path) }}>
                                                                                    {index === 0 &&
                                                                                        <div ref={(ref) => { addNewSpinnerRef(ref, row.path) }} style={{ position: 'fixed', overflow: 'hidden', maxHeight: '25px' }}>
                                                                                            <CircularProgress size={25} sx={{}} />
                                                                                        </div>
                                                                                    }
                                                                                </TableRowCell>
                                                                            </>
                                                                        }

                                                                        {!isFetching[row.path] && childRowIndex === childLevelData[row.path].length - 1 && numUnfetchedChildData[row.path] === 0 &&
                                                                            <>
                                                                                <TableRowCell id={`EmptyCell-${row.path}`} ref={(ref) => { addNewEmptyCellRef(ref, row.path) }}>
                                                                                    {index === 0 &&
                                                                                        <div id='TotalResults' ref={(ref) => { addNewSpinnerRef(ref, row.path) }} style={{ position: 'fixed', overflow: 'hidden', maxHeight: '25px' }}>
                                                                                            {totalFiles[row.path]} Result{totalFiles[row.path] === 1 ? '' : 's'} Shown
                                                                                        </div>
                                                                                    }
                                                                                </TableRowCell>
                                                                            </>
                                                                        }
                                                                    </>
                                                                )
                                                            }
                                                        })}
                                                    </ChildRowContainer>
                                                </ScrollSyncPane>
                                            )}
                                        </>
                                    )
                                }

                            })}
                        </TableColumn>
                    ))}
                </>
            </ScrollSync>}
        </TableBodyContainer>
    )
}

export default TableBody

const TableBodyContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const TableRowCell = styled.div`
    display: flex;
    // border-right: 1px solid #ccc;
    flex: 1;
    background: ${({ isChild }) => isChild ? '#f5f5f5' : 'white'};
    height: 40px;
    align-items: center;
    :first-of-type {
        // border-left: 1px solid #ccc;
    }
    :nth-of-type(even) {
        background: #F8F8F8;
    }
    // border-bottom: 1px solid #ccc; // remove later
    ${({ isDirectory }) =>
        isDirectory &&
        `
        cursor: pointer;
        color: blue;
    `}
`;

const TableRowCellText = styled.div`
    padding-left: 10px;
    padding-right: 10px;
    overflow: hidden; /* Prevent text from pushing the border */
    white-space: nowrap; /* Prevent text from wrapping */
    text-overflow: ellipsis;
`;

const TableColumn = styled.div`
    width: 100%;
`

const HeaderRowCell = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;
    font-family: Source Sans Pro;
    font-weight: 600;
    font-size: 16px;
    margin: 0;
    height: 40px;
    align-items: center;
    // border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    border-right: 1px solid #ccc;
    background: white;
    cursor: pointer;
`

const HeaderRowCellText = styled.p`
    padding-left: 10px;
`

const ChildRowContainer = styled.div`
    max-height: 200px;
    overflow-y: auto;
    border-bottom: 1px solid #ccc;

    ${({ showScrollbar }) =>
        showScrollbar &&
        `
        ::-webkit-scrollbar {
            display: block;
            width: 5px;
            color: #313A4A;
            position: absolute;
        }
        ::-webkit-scrollbar-track {
            background: #BEC4CF;
            width: 5px;
            border-radius: 2px;
        }
    `}
`

const PopperContainer = styled.div`
    position: absolute;
    background: white;
    border-radius: 4px;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
    z-index: 2;
`;
