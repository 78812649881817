import { useEffect, useCallback, useRef } from 'react';
import * as MdIcons from 'react-icons/md';
import styled from 'styled-components';

function FilterButton (functionality) {

    let filterButtonRef = useRef();

    const enterDefault = useCallback((event) => {
		if (event.keyCode === 13) {
			event.preventDefault()
		}
	}, [])

	useEffect(() => {
		document.addEventListener("keydown", enterDefault);
		return () => {
			document.removeEventListener("keydown", enterDefault);
		};
	}, [enterDefault]);

    return (
        <div>
        <ButtonFilter ref={filterButtonRef} id="FilterButton" onClick={functionality} class="MdIcons.MdFilterAlt" ><FilterIcon><MdIcons.MdFilterListAlt style={{color:"#207DEA", marginBottom: "5px", marginRight: "2px"}}/></FilterIcon>Filter</ButtonFilter>
        </div>
    )
};

const ButtonFilter = styled.button`
  		width: 90.3px;
		height: 34px;
		display: inline-flex;
		position: relative;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		padding: 0px 0px;
		border-radius: 6px;
		box-shadow: 0 0 3px 0 rgba(99, 191, 244, 0.15);
		border: solid 0.5px #B6B6B6;
		background-color: #fff;
		font-family: 'Source Sans Pro';
		font-weight: 600;
		font-size: 16px;
		color: #293241;
		cursor: pointer;
		margin-bottom: 0px;
	
	`;

const FilterIcon = styled.div`
	font-size:1.3rem;`


export default FilterButton;

