import { useState } from 'react'
import * as MdIcons from "react-icons/md";

function SelectionBox ({ value, selected, update }) {

	const [hover, setHover] = useState(false)

	function toggleHover() {
		setHover(!hover)
	}

	return (
		<div onClick={update} style={{cursor: "pointer", display: "flex", justifyContent: "space-between", alignItems: "center", padding: "5px", background: hover ? "#efefef" : "white", maxHeight: "200px", overflow: "auto"}} onMouseEnter={toggleHover} onMouseLeave={toggleHover}>
			<div>{value}</div>
			<MdIcons.MdCheck style={{color: "#000", opacity: selected ? 1 : 0}}></MdIcons.MdCheck>
		</div>
	)
}


export default function MultiSelectionDropdownInput({ nodeId, config, initialValue, onChange }) {

	if (!config.OPTIONS) {
		config.OPTIONS = ["--"]
	}

	const [value, setValue] = useState(initialValue ?? config.OPTIONS[0] ?? [])
	const idealWidth = Math.max(config.OPTIONS.map(option => option.length)) * 0.4 + "rem"

    function toggleOption(option) {
		setValue(prevValue => {
			let newValue;

			if (prevValue.includes(option)) {
				newValue = prevValue.filter(val => val !== option)
			} else {
				newValue = [...prevValue, option]
			}
			
			onChange({nodeId, arg: config.VARIABLE_NAME, value: newValue})
			return newValue
		})
    }

    return (
		<div style={{display: "flex"}}>

			<div style={{paddingRight: "8px"}}>
				{config.DISPLAY_NAME}
			</div>

			<div style={{border: "1px solid gray", minWidth: "100px", maxWidth: "380px", maxHeight: 		"100px", width: idealWidth, overflow: "auto"}}>
            
			{config.OPTIONS.map((option, index) => 
                <SelectionBox 
					key={option + "_" + index} 
					update={() => toggleOption(option)}
					value={option} 
					selected={value.includes(option)}>
						{option}
				</SelectionBox>
			)}

			</div>
		</div>
    )
}