import { EnterElement, Selection, select } from "d3"
import { D3ModalityGraphGroup } from "./D3ModalityGraphGroup"
import { D3ModalityGraphGroupConfigurationBuilder } from "./D3ModalityGraphGroupConfigurationBuilder"
import { D3ModalityGraphsWrapper } from "./D3GraphsWrapper"
import { D3GraphsOverlay } from "./D3GraphsOverlay"
import { D3VisualizationRenderer } from "../../D3VisualizationRenderer"
import { ModalityGraphGroupConfig } from "../../../../Types/ModalityGraphGroup"
import { D3UTCAxis } from "../../../D3/D3UTCAxis"
import { D3AnnotationsWrapper } from "../../../D3/D3Annotations"
import { D3Timeline } from "../../../D3/Timeline/D3Timeline"
import { requestAutoScale } from "../../autoScale"
import { D3ClipPath } from "../../../D3/D3ClipPath"

export class D3ModalityGraphGroupRenderer extends D3VisualizationRenderer<D3ModalityGraphGroup, D3ModalityGraphGroupConfigurationBuilder> {
	private boundingBoxClassName: string = "d3-graphs-bounding-box"
	private boundingBoxBackgroundClassName: string = "d3-graphs-bounding-box-background"
	private boundingBoxForegroundClassName: string = "d3-graphs-bounding-box-foreground"
	private timelineContainerClassName: string = "d3-timeline-container"

	// Children
	public xAxis?: D3UTCAxis
	public graphsWrapper?: D3ModalityGraphsWrapper
	public graphsOverlay?: D3GraphsOverlay
	public annotationsWrapper?: D3AnnotationsWrapper
	public graphsClipPath?: D3ClipPath
	public overlayClipPath?: D3ClipPath

	viewTimesChanged = () => {
		this.xAxis?.render()
		this.timeline?.viewTimesChanged()
		this.graphsWrapper?.viewTimesChanged()
		this.graphsOverlay?.render()
		this.annotationsWrapper?.render()
	}

	onTimeAxisDrag = () => {
		this.timeline?.viewTimesChanged()
		this.graphsWrapper?.rescale()
		this.annotationsWrapper?.render()
	}

	onTimelineSliderDrag = () => {
		requestAnimationFrame(() => {
			this.xAxis?.render()
			this.graphsWrapper?.viewTimesChanged()
			this.annotationsWrapper?.render()
		})
	}

	autoScaleGraphs = () => {
		const autoScale = () => this.graphsWrapper?.autoScaleGraphs()
		requestAutoScale(this.visualization.timeSeriesPageManager, autoScale)
	}

    protected canRender(): boolean {
        return this.visualization.graphsBoundingBox.width > 0 && this.visualization.graphsBoundingBox.height > 0
    } 
    
    // What to do when adding a new SVG to the DOM
	protected enter = (newGroups: Selection<EnterElement, any, any, any>): Selection<SVGSVGElement, any, any, any> => {
		const svg = newGroups
			.append("svg")
			.attr("class", this.className)
			.attr("width", "100%")
			.attr("height", "100%")

		const boundingBox = svg
			.append("g")
			.attr("class", this.boundingBoxClassName)
			.attr("transform", `translate(${this.visualization.graphsBoundingBox.x}, ${this.visualization.graphsBoundingBox.y})`)

		const boundingBoxBackground = boundingBox
			.append("g")
			.attr("class", this.boundingBoxBackgroundClassName)

		const boundingBoxForeground = boundingBox
			.append("g")
			.attr("class", this.boundingBoxForegroundClassName)

		const timelineContainer = svg
			.append("g")
			.attr("class", this.timelineContainerClassName)
			.attr("transform", `translate(${this.visualization.graphsBoundingBox.x}, ${this.visualization.graphsBoundingBox.y + this.visualization.graphsBoundingBox.height})`)

		svg.each((config, index, nodes) => this.createClipPath(config, index, nodes))
		boundingBoxBackground.each((config, index, nodes) => this.createBoundingBoxBackgroundChildren(config, index, nodes, svg.node()))
		boundingBoxForeground.each((config, index, nodes) => this.createBoundingBoxForegroundChildren(config, index, nodes, svg.node()))
		timelineContainer.each(this.createTimeline)

		return svg
	}

	// What to do when updating an existing SVG
	protected update = (updatedSVG: Selection<any, any, any, any>): Selection<any, any, any, any> => {
		const svg = updatedSVG

		const timelineContainer = svg.select("." + this.timelineContainerClassName)

		timelineContainer.attr("transform", `translate(${this.visualization.graphsBoundingBox.x}, ${this.visualization.graphsBoundingBox.y + this.visualization.graphsBoundingBox.height})`)

		return updatedSVG
	}

	private createClipPath = (config: ModalityGraphGroupConfig, i: number, nodes: ArrayLike<SVGSVGElement>) => {
		const root = nodes[i]

		// There are two clip paths because of CSS transforms and the graphs bounding box and overlay bounding box do not line up.
		// It seems like we should be able to get away with only using one, but because of CSS transforms, we need two.
		this.graphsClipPath = new D3ClipPath(root, this.configBuilder.getGraphsClipPathConfig(), this.visualization.reactCallbacks)
		this.overlayClipPath = new D3ClipPath(root, this.configBuilder.getOverlayClipPathConfig(), this.visualization.reactCallbacks)
	}

	private createBoundingBoxBackgroundChildren = (config: ModalityGraphGroupConfig, i: number, nodes: ArrayLike<SVGGElement>, svg: SVGSVGElement | null) => {
		const root = nodes[i]
		this.xAxis = new D3UTCAxis(root, this.configBuilder.getUTCAxisConfig(), this.visualization.reactCallbacks)
		this.graphsWrapper = new D3ModalityGraphsWrapper(root, this.configBuilder.getGraphsConfig(), this.visualization.timeSeriesPageManager, this.visualization.reactCallbacks)
	}

	private createBoundingBoxForegroundChildren = (config: ModalityGraphGroupConfig, i: number, nodes: ArrayLike<SVGGElement>, svg: SVGSVGElement | null) => {
		const root = nodes[i]
		this.graphsOverlay = new D3GraphsOverlay(root, this.configBuilder.getOverlayConfig(svg), this.visualization.reactCallbacks)
		this.annotationsWrapper = new D3AnnotationsWrapper(root, this.configBuilder.getAnnotationsConfig(), this.visualization.reactCallbacks)
	}

	private createTimeline = (config: ModalityGraphGroupConfig, i: number, nodes: ArrayLike<SVGGElement>) => {
		const root = nodes[i]
		this.timeline = new D3Timeline(root, this.configBuilder.getTimelineConfig(), this.visualization.timeSeriesPageManager, this.visualization.reactCallbacks)
	}

	public updateChildren = () => {
		const svg = select(this.visualization.root).select("svg").node() as SVGSVGElement

		this.xAxis?.updateConfig(this.configBuilder.getUTCAxisConfig())
		this.graphsWrapper?.updateConfig(this.configBuilder.getGraphsConfig())
		this.timeline?.updateConfig(this.configBuilder.getTimelineConfig())
		this.graphsOverlay?.updateConfig(this.configBuilder.getOverlayConfig(svg))
		this.annotationsWrapper?.updateConfig(this.configBuilder.getAnnotationsConfig())
		this.graphsClipPath?.updateConfig(this.configBuilder.getGraphsClipPathConfig())
		this.overlayClipPath?.updateConfig(this.configBuilder.getOverlayClipPathConfig())
	}
}