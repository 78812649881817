export const columns = [
    {
        name: "Analysis Name",
        selector: (row) => row.analysis_name,
        sortable: true
    },
    {
        name: "Created By",
        selector: (row) => row.created_by,
        sortable: true
    },
    {
        name: "Versions",
        selector: (row) => row.version,
        sortable: true
    },
    {
        name: "Public",
        selector: (row) => row.isPublic,
        sortable: false
    }
];

export const rows = [
      {
    analysis_name: "EEG Preprocessing Pipeline",
    created_by: "Alisha Augustin",
    version: "5",
    isPublic: true
    },
        {
        analysis_name: "ECG Preprocessing Pipeline",
        created_by: "Alisha Augustin",
        version: "2",
        isPublic: false
        },
        {
        analysis_name: "Respiratory Preprocessing Pipeline",
        created_by: "Alisha Augustin",
        version: "3",
        isPublic: false
        },
        {
        analysis_name: "Pipeline Name",
        created_by: "Alisha Augustin",
        version: "1",
        isPublic: true
        },
        {
        analysis_name: "Pipeline Name",
        created_by: "Alisha Augustin",
        version: "1",
        isPublic: true
        },
        {
        analysis_name: "Pipeline Name",
        created_by: "Alisha Augustin",
        version: "1",
        isPublic: false
        }
    
];