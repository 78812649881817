import React, { useEffect } from "react"
import { useRecoilState, useSetRecoilState } from "recoil"
import { ModalConfig, grayOverlayStyles, modalStyles, openTransition } from "../Types/Modals"
import { modalsAtom } from "../Atoms/Modals"
import styled from "styled-components"
import { useModalProvider } from "./ModalProvider"
import { useLocation } from "react-router-dom"
import { hotkeysEnabledAtom } from "../Pages/Data/Visualize/DataReview/Atoms/Hotkeys"

export const ModalRenderer = () => {
	const [modals, setModals] = useRecoilState<ModalConfig[]>(modalsAtom)
	const setHotkeysEnabled = useSetRecoilState(hotkeysEnabledAtom)
	const location = useLocation()
	const { close } = useModalProvider()

	const outsideClick = () => {
		if (!modals[modals.length - 1].component.props.clickOutsideClose) {
			return
		}

		close()
	}

	// Close all modals when you navigate away from the page.
	useEffect(() => {
		setModals([])
	}, [location, setModals])

	// Look for open modals and disable hotkeys if they don't have it enabled
	useEffect(() => {
		if (modals.length === 0) {
			return
		}

		const topModal = modals[modals.length - 1]
		setHotkeysEnabled(topModal.options?.dataReviewHotkeysEnabled ?? true)

	}, [modals, setHotkeysEnabled])

	// Handle Animation for Opening Modals
	useEffect(() => {
		if (modals.length === 0) {
			return
		}

		// Timeout makes sure we definitely wait until after the render has finished.
		// This does not cause a delay because it is set to 0, but it pushes the execution of the animation
		// until immediately after the render has finished.
		setTimeout(() => {
			const config = modals[modals.length - 1]
			const modal = config.modalRef.current
			const overlay = config.overlayRef.current

			if (!modal) {
				return
			}

			modal.style.opacity = "1"
			modal.style.marginTop = "0"

			if (overlay) {
				overlay.style.transition = openTransition
				overlay.style.opacity = "0.4"
			}
		}, 0)
	}, [modals])

	// Prevent the page from scrolling when modals are open
	useEffect(() => {
		if (modals.length === 0) {
			return
		}

		const nonModalContent = document.getElementById("HeaderAndPage")

		if (nonModalContent) {
			nonModalContent.style.overflow = "hidden"
		}

		return () => {
			if (nonModalContent) {
				nonModalContent.style.overflow = "auto"
			}
		}
		
	}, [modals])

	const defaultStackingBehaviorModals = modals.filter(({ options }) => options.zIndex === undefined)
	const controlledStackingBehaviorModals = modals.filter(({ options }) => options.zIndex !== undefined)

	const getModalAndOverlay = (modalConfig: ModalConfig, index: number) => {
		const modalId = `Modal ${index}`
		const overlayId = modalId + " GrayOverlay"

		const { component, modalRef, overlayRef, options } = modalConfig

		return [
			<Modal
				id={modalId}
				key={modalId}
				ref={modalRef}
				style={{ ...modalStyles, ...component.props.style, transition: options?.animate ? openTransition : "none", zIndex: options.zIndex ? options.zIndex + 1 : index + 1 }}
			>
				{component}
			</Modal>,
			<div 
				id={overlayId}
				key={overlayId}
				ref={overlayRef}
				style={{ ...grayOverlayStyles, zIndex: options.zIndex ?? index }} onClick={outsideClick} />,
		]
	}

	return (
		<>
			{ defaultStackingBehaviorModals.length > 0 && (
				<div id="ModalsContainer" style={{ position: "fixed", zIndex: 9999, inset: 0, display: "flex", justifyContent: "center", alignItems: "center" }}>
					{modals.map((modalConfig, index) => getModalAndOverlay(modalConfig, index))}
				</div>
			)}

			{ controlledStackingBehaviorModals.length > 0 && (
				controlledStackingBehaviorModals.map((modalConfig, index) => (
					<div style={{ position: "fixed", zIndex: modalConfig.options.zIndex, inset: 0, display: "flex", justifyContent: "center", alignItems: "center" }}>
						{getModalAndOverlay(modalConfig, index)}
					</div>
				))
			)}
		</>
	)
}

const Modal = styled.div`
    width: 820px;
	height: auto;
    background: #FFFFFF;
    border-radius: 6px;
    h3 {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 150%;
        color: #293241;
        text-align: center;
        margin-top: 8px;
        margin-bottom: -4px;
    }
    h2 {
        font-family: 'Source Sans Pro';
        font-style: normal;
        font-weight: 700;
        font-size: 21px;
        line-height: 150%;
        color: #293241;
        margin-top: 5px;
    }
    h1 {
        font-family: 'Source Sans Pro';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        color: #293241;
    }
`;
