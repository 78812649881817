import { useState, useEffect, useRef, useCallback } from 'react';
import styled from 'styled-components';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { LINKS } from '../../../Constants/BackendLinks';
import { FRONTEND_LINKS } from '../../../Constants/FrontendLinks';
import { MainTable } from "../../../Components/DataTable/Table";
import * as MdIcons from "react-icons/md";
import { Link } from "react-router-dom";
import { useStudiesProvider } from '../../../Providers/StudiesProvider';
import { authUpdates, useAuthProvider } from '../../../Providers/AuthProvider';
import { useEndpointProvider } from '../../../Providers/EndpointProvider';
import { patientUpdates, usePatientsProvider } from '../../../Providers/PatientsProvider';
import { patientGroupsUpdates, usePatientGroupsProvider } from '../../../Providers/PatientGroupsProvider';
import TrashButton from '../../../Components/TrashButton/TrashButton';
import ModalFooterButton, { buttonColor } from '../../../Components/ModalFooterButton/ModalFooterButton';
import { WarningMessage } from '../../../Constants/StyledComponents'

const PatientGroup = (props) => {
	const studiesProvider = useStudiesProvider()
    const endpointProvider = useEndpointProvider()
	const authProvider = useAuthProvider()
    const patientsProvider = usePatientsProvider()
	const patientGroupsProvider = usePatientGroupsProvider()

    // Update Providers to see if data changed remotely.
    // Only run once, because each update makes a network call.
    useEffect(() => {
		authProvider.update(authUpdates.CURRENT_USER)
		patientGroupsProvider.update(patientGroupsUpdates.PATIENT_GROUPS_FILTERS)
        patientsProvider.update(patientUpdates.PATIENTS_FILTERS)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []) // THIS MUST BE EMPTY. DO NOT CHANGE IT.

	//***************//
    // API hook ups  //
    //***************//

    function createPatientGroup(name, user_email, study_id, patient_uids) {
        let body = {
            name: name,
            user_email: user_email,
            study_id: study_id,
            patient_uids: patient_uids
        }

        return endpointProvider.post(LINKS.DATA.PROFILING.CREATE_PATIENT_GROUP, body)
    }

    function deletePatientGroup(group_name) {
        let body = {
            group_name: group_name
        }

        return endpointProvider.post(LINKS.DATA.PROFILING.DELETE_PATIENT_GROUP, body)
    }

    function modifyPatientGroup(group_name, new_name) {
        let body = {
            group_name: group_name,
            name: new_name
        }

        return endpointProvider.post(LINKS.DATA.PROFILING.MODIFY_PATIENT_GROUP, body)
    }

    function addPatientToGroup(group_name, site_id, patient_id) {
        let body = {
            group_name: group_name,
            patient_id: patient_id,
            site_id: site_id
        }

        return endpointProvider.post(LINKS.DATA.PROFILING.ADD_PATIENT_TO_GROUP, body)
    }
    
    function removePatientFromGroup(group_name, site_id, patient_id) {
        let body = {
            group_name: group_name,
            patient_id: patient_id,
            site_id: site_id
        }

        return endpointProvider.post(LINKS.DATA.PROFILING.REMOVE_PATIENT_FROM_GROUP, body)
    }

	// page update hook
	const [groupsUpdate, setGroupsUpdate] = useState([])
	function updateGroups() {
		 setGroupsUpdate([])
	}

    // Page Functionalities
    const [searchQuery, setSearchQuery] = useState("");

	const [toggleGroupTableCleared, setToggleGroupTableCleared] = useState(false)
    const [togglePatientsCleared, setTogglePatientsCleared] = useState(false)
    const [selectedGroupRows, setSelectedGroupRows] = useState(false)
    const [selectedPatientsTableRows, setSelectedPatientsTableRows] = useState(false)

	function updateGroupRows({selectedRows}) {
		setSelectedGroupRows(selectedRows)
	}

    function updatePatientsTableRows({selectedRows}) {
        setSelectedPatientsTableRows(selectedRows)
    }

	const [patientSearchQuery, setPatientSearchQuery] = useState("");
	const [siteSearchQuery, setSiteSearchQuery] = useState("");
	const [doubleSearchQuery, setDoubleSearchQuery] = useState(false);
	const [groupExistSearchQuery, setGroupExistSearchQuery] = useState("")
	const [groupAlreadyExisted, setGroupAlreadyExisted] = useState(false)
	const [specialDisplay ,setSpecialDisplay] = useState("none")
	const setCheckGroupSearch = useCallback((event) => {
		if (event.target.value === undefined || event.target.value === null) return
		setGroupExistSearchQuery(event.target.value)
	},[])
	
	const checkAvailability = useCallback(()=> {
		if (patientGroupsProvider.patientGroups?.map(row => row.group_name).includes(groupExistSearchQuery)) {
			setGroupAlreadyExisted(true)
			setSpecialDisplay("block")
		} else {
			setGroupAlreadyExisted(false)
			setSpecialDisplay("none")
		}
	},[groupExistSearchQuery, patientGroupsProvider.patientGroups])

	useEffect(()=> {
		checkAvailability()
	},[patientGroupsProvider.patientGroups, patientGroupsProvider.filters, groupExistSearchQuery,checkAvailability])

	function setSearchQueryStates(doubleSearchBoolean, siteSearch, patientSearch) {
		setDoubleSearchQuery(doubleSearchBoolean)
		setSiteSearchQuery(siteSearch)
		setPatientSearchQuery(patientSearch)
	}

	function handlePatientSearch(event) {
		if (event.target.value === undefined || event.target.value === null) return
		if (event.target.value.includes("/")) {
			// if double search is true, set site search value to the left of the "/" and patient search value to the right of the "/"
			setSearchQueryStates(true, event.target.value.split("/")[0], event.target.value.split("/")[1])
		} else {
			setSearchQueryStates(false, event.target.value, event.target.value)
		}
	}
	
    const showMore = useCallback(()=>(
		<MdIcons.MdExpandMore
			size={20}
			style={{
				marginLeft: "10px",
				marginRight: "-20px",
				color: "#B6B6B6",
			}}
		/>
	),[]);
	const showLess = (
		<MdIcons.MdExpandLess
			size={20}
			style={{
				marginLeft: "10px",
				marginRight: "-20px",
				color: "#B6B6B6",
			}}
		/>
	);

	const setInitialSearchQueryStates = useCallback(() => {
		setPatientSearchQuery("")
		setSiteSearchQuery("")
		setDoubleSearchQuery(false)
		document.getElementById("createGroupSearch").value = ""
		document.getElementById("modifyGroupSearch").value = ""
	},[])

	const setInitialPatientTableStates = useCallback(() => {
		setSelectedPatientsTableRows(false)
		setTogglePatientsCleared(!togglePatientsCleared)
		setPatientsPreselect([])
	},[togglePatientsCleared])

    function renderGroupsModal() {
		setInitialPatientTableStates()
		setInitialSearchQueryStates()
		if (document.getElementById("GroupsButtonModal").style.display !== "none") {
			document.getElementById("GroupsButtonModal").style.display = "none";
            document.getElementById("grayOverlay").style.display = "none";
			document.getElementById("sideBarNav").style.overflow = "auto";
			document.body.style.overflow="auto";

		} else {
			document.getElementById("GroupsButtonModal").style.display = "table";
			document.getElementById("GroupsButtonModal").style.zIndex = "999";
			document.getElementById("grayOverlay").style.display = "block";
			document.getElementById("sideBarNav").style.overflow = "none";
			document.body.style.overflow="hidden";
		}
	}

    function cancelGroupsModal() {
		setInitialSearchQueryStates()
        let groupName = document.getElementById("GroupInput")
        if (groupName === undefined || groupName === null) { return }
        groupName.value = ""
		if (document.getElementById("GroupsButtonModal").style.display !== "none") {
			document.getElementById("GroupsButtonModal").style.display = "none";
			document.getElementById("grayOverlay").style.display = "none";
			document.getElementById("sideBarNav").style.overflow = "auto";
			document.body.style.overflow="auto";
		}
	}

    function renderGroupsModifyModal() {
		setInitialPatientTableStates()
		setInitialSearchQueryStates()
		if (document.getElementById("GroupsButtonModifyModal").style.display !== "none") {
			document.getElementById("GroupsButtonModifyModal").style.display = "none";
            document.getElementById("grayOverlayModify").style.display = "none";
			document.getElementById("sideBarNav").style.overflow = "auto";
			document.body.style.overflow="auto";

		} else {
			document.getElementById("GroupsButtonModifyModal").style.display = "table";
			document.getElementById("GroupsButtonModifyModal").style.zIndex = "999";
			document.getElementById("grayOverlayModify").style.display = "block";
			document.getElementById("sideBarNav").style.overflow = "none";
			document.body.style.overflow="hidden";
		}
	}

    function cancelGroupsModifyModal() {
		setInitialSearchQueryStates()
		if (document.getElementById("GroupsButtonModifyModal").style.display !== "none") {
			document.getElementById("GroupsButtonModifyModal").style.display = "none";
			document.getElementById("grayOverlayModify").style.display = "none";
			document.getElementById("sideBarNav").style.overflow = "auto";
			document.body.style.overflow="auto";
		}
	}
    
    let groupsButtonRef = useRef();
	let groupsModalRef = useRef();
    let groupsModifyButtonRef = useRef();
	let groupsModifyModalRef = useRef();

	let DeleteMultipleGroupsModalRef = useRef();


	useEffect(() => {
		let handler = (event) => {
			if ((!DeleteMultipleGroupsModalRef.current.contains(event.target)) && (!DeleteMultipleGroupsModalRef.current.contains(event.target))) {
				document.getElementById('RemoveMultipleGroups').style.display = 'none'
			}}
		document.addEventListener("mousedown", handler);
		return () => {
			document.removeEventListener("mousedown", handler)
		}
	}, [])

	useEffect(() => {
		let handler = (event) => {
			if (!groupsButtonRef.current.contains(event.target) &&
				!groupsModalRef.current.contains(event.target) ) {
				document.getElementById("GroupsButtonModal").style.display = "none";
				document.getElementById("grayOverlay").style.display = "none";
				document.getElementById("sideBarNav").style.overflow = "auto";
				document.body.style.overflow="auto";
			}
		};
		document.addEventListener("mousedown", handler);
		return () => {
			document.removeEventListener("mousedown", handler);
		};
	}, [setInitialSearchQueryStates]);

    useEffect(() => {
		let handler = (event) => {
			if (!groupsModifyModalRef.current.contains(event.target) ) {
				document.getElementById("GroupsButtonModifyModal").style.display = "none";
				document.getElementById("grayOverlayModify").style.display = "none";
				document.getElementById("ConfirmGrayOverlay").style.display = "none";
				document.getElementById("SaveGroupConfirmationModal").style.display = "none";
				document.getElementById("sideBarNav").style.overflow = "auto";
				document.body.style.overflow="auto";
			}
		};
		document.addEventListener("mousedown", handler);
		return () => {
			document.removeEventListener("mousedown", handler);
		};
	}, [setInitialSearchQueryStates]);

	const escPatientsModalClose = useCallback((event) => {
		if (event.keyCode === 27) {
			setInitialSearchQueryStates()
			document.getElementById("GroupsButtonModal").style.display = "none";
			document.getElementById("grayOverlay").style.display = "none";
			document.getElementById("ConfirmGrayOverlay").style.display = "none";
			document.getElementById("SaveGroupConfirmationModal").style.display = "none";
			document.getElementById("RemoveMultipleGroups").style.display = "none";
			document.getElementById("sideBarNav").style.overflow = "auto";
			document.body.style.overflow="auto";
		}
	}, [setInitialSearchQueryStates]);

	useEffect(() => {
		document.addEventListener("keydown", escPatientsModalClose);
		return () => {
			document.removeEventListener("keydown", escPatientsModalClose);
		};
	}, [escPatientsModalClose]);

	const escModifyPatientsModalClose = useCallback((event) => {
		if (event.keyCode === 27) {
			setInitialSearchQueryStates()
			document.getElementById("GroupsButtonModifyModal").style.display = "none";
			document.getElementById("grayOverlayModify").style.display = "none";
			document.getElementById("sideBarNav").style.overflow = "auto";
			document.body.style.overflow="auto";
		}
	}, [setInitialSearchQueryStates]);

	useEffect(() => {
		document.addEventListener("keydown", escModifyPatientsModalClose);
		return () => {
			document.removeEventListener("keydown", escModifyPatientsModalClose);
		};
	}, [escModifyPatientsModalClose]);

    const [createGroupPatientsIcon, setCreateGroupPatientsIcon] = useState(showMore);
    function renderPatientsTableCreateGroup() {
		if (document.getElementById("createGroupPatientsTable").style.display !== "none") {
			document.getElementById("createGroupPatientsTable").style.display = "none";
			setCreateGroupPatientsIcon(showMore);
		} else {
			document.getElementById("createGroupPatientsTable").style.display = "block";
			setCreateGroupPatientsIcon(showLess);
		}
	}

    const [modifyGroupPatientsIcon, setModifyGroupPatientsIcon] = useState(showMore);
    function renderPatientsTableModifyGroup() {
		if (document.getElementById("modifyGroupPatientsTable").style.display !== "none") {
			document.getElementById("modifyGroupPatientsTable").style.display = "none";
			setModifyGroupPatientsIcon(showMore);
		} else {
			document.getElementById("modifyGroupPatientsTable").style.display = "block";
			setModifyGroupPatientsIcon(showLess);
		}
	}
	
    const [groupTableColumn, setGroupTableColumn] = useState([])
    const [groupTableData, setGroupTableData] = useState([])
    const [dataProfilingTableData, setDataProfilingTableData] = useState([])
	const [dataProfilingTableColumns, setDataProfilingTableColumns] = useState([])

	// subheader tab
	const [tableSubheaderValues, setTableSubheaderValues] = useState({"Patient": false, "Patient Groups": true})
	const [tableSubheaderOrder, setTableSubheaderOrder] = useState(["Patient", "Patient Groups"])
	const [tableConfig, setTableConfig] = useState([])

	function updateTableSubheaderValues(table, value) {
		if (tableSubheaderValues[table]) {
			return;
		}
		let newTableSubheaderValues = Object.assign(
			{},
			...Object.keys(tableSubheaderValues).map((table) => {
				return {[table]: false};
			})
		);
		setTableSubheaderValues({...newTableSubheaderValues, [table]: value});

		if (!tableSubheaderOrder.includes(table)) {
			setTableSubheaderOrder(
				Array.prototype.concat(tableSubheaderOrder, table)
			);

			let new_table = {};
			for (let group of Object.keys(
				tableConfig[tableSubheaderOrder[0]]
			)) {
				new_table[group] = {};
				for (let e of Object.keys(
					tableConfig[tableSubheaderOrder[0]][group]
				)) {
					new_table[group][e] = false;
				}
			}

			setTableConfig({...tableConfig, [table]: new_table});
		}
	}

	function convertMinutesToDaysHoursMinutes(totalMinutes) {
		let days = Math.floor(parseFloat(totalMinutes)/1440);
		let hours = Math.floor(parseFloat(2348.79103195)/60);
		let minutes = Math.round((parseFloat(2348.79103195)%60 + Number.EPSILON)*100)/100;
		if (minutes > 60) {
			hours += Math.floor(minutes / 60)
			minutes -= 60 * (Math.floor(minutes / 60))
		}

		if (hours > 24) {
			days += Math.floor(hours / 24)
			hours -= 24 * (Math.floor(hours / 24))
		}
		
		return `${days} days, ${hours} hours, ${minutes} minutes`;
	}

	const [patientGroupPending, setPatientGroupPending] = useState(true)
    // get data
	useEffect(() => {
		if (!studiesProvider.selectedStudyId || patientGroupsProvider.patientGroups.length === 0) {
			return
		}

		setPatientGroupPending(true)

        let columns2labels = {
			group_name: { label: "Name", type: "string" },
			// description: { label: "Description", type: "string" }		
            // patients: { label: "Patient IDs", type: "string[]" }
			npatients: { label: "No. Patients", type: "string" },
			total_patient_monitoring_time: { label: "Total Monitoring Time", type: "string", width: '350px' },
		}
		
        let columnData = []

		for (let elem of Object.keys(columns2labels)) {
			columnData.push({
				name: columns2labels[elem]['label'],
				selector: (row) => row[elem],
				sortable: true,
			})
		}
		columnData.push({
			name: "Edit",
			selector: (row) => row["edit"],
			sortable: false,
            width: '100px'
		})
		setGroupTableColumn(columnData)

		let tableData = patientGroupsProvider.patientGroups.map(row => {	
			let entry = {
				'edit': <EditGroupButton onClick={() => {handleEditClick(row); document.getElementById("modifyPatientGroupsModalScrollableContentRef").scrollTop = 0
                }} ref={groupsModifyButtonRef}><MdIcons.MdEdit size={25} /></EditGroupButton>
			}
			for (let column of Object.keys(columns2labels)) {
				if (columns2labels[column]["type"] === 'string') {
					if (column === 'total_patient_monitoring_time') {
						entry[column] = convertMinutesToDaysHoursMinutes(row[column])
					} else {
						entry[column] = row[column]
					}
				} else {
					entry[column] = String(row[column]).replace(/,/g, ', ')
				}
			}
			entry.group_id = row.group_id
			return entry
		})
		
		const sortedData = [...tableData].sort((a, b) => (a.group_name < b.group_name ? -1 : 1))
		setGroupTableData(sortedData)
		setPatientGroupPending(false)

	}, [groupsUpdate, patientGroupsProvider.patientGroups, searchQuery, studiesProvider.selectedStudyId])

    useEffect(() => {
		if (selectedGroupRows.length) {
			document.getElementById("trashcan").style.backgroundColor = "#e8000d";
			document.getElementById("trashcan").disabled = false;
		} else {
			document.getElementById("trashcan").style.backgroundColor = "pink";
			document.getElementById("trashcan").disabled = true;
		}
	}, [selectedGroupRows]);

    const [patientsPreselect, setPatientsPreselect] = useState([])
	const [patientPending, setPatientPending] = useState([])
	const [currentSelectedRowGroupName, setCurrentSelectedRowGroupName] = useState("")

	// get Patients data 
    useEffect(() => {
		if (patientsProvider.patients?.length === 0) return

		let columns2labels = {
			site_id: { label: "Site ID", type: "string" },
			patient_id: { label: "Patient ID", type: "string" },
		}

		let columnData = []

		for (let elem of Object.keys(columns2labels)) {
			columnData.push({
				name: columns2labels[elem]['label'],
				selector: (row) => row[elem],
				sortable: true,
                width: columns2labels[elem]['width']
			})
		}
		setDataProfilingTableColumns(columnData)
		setPatientPending(true)

        let currentSelectedRows = []
        if (selectedPatientsTableRows) {
            currentSelectedRows = selectedPatientsTableRows.map(row => `${row.site_id}/${row.patient_id}`)
        }

		let tableData = patientsProvider.patients?.map(row => {
            let entry = {}
            for (let column of Object.keys(columns2labels)) {
                if (columns2labels[column]["type"] === 'string') {
                    if (row[column] === null) {
                        entry[column] = ""
                    } else {
                        entry[column] = `${row[column]}`
                    }
                }
            }
            entry.selected = patientsPreselect.includes(`${row.site_id}/${row.patient_id}`) || currentSelectedRows.includes(`${row.site_id}/${row.patient_id}`)
            return entry
		})
	
		tableData = tableData.filter((val) => {
			if (patientsPreselect.includes(`${val.site_id}/${val.patient_id}`) || currentSelectedRows.includes(`${val.site_id}/${val.patient_id}`)) {
				return val
			}
			if (doubleSearchQuery) {
				if (patientSearchQuery === "" && val.site_id.includes(siteSearchQuery)) {
					return val
				} else if (siteSearchQuery === "" && val.patient_id.includes(patientSearchQuery)) {
					return val
				} else if (val.site_id === siteSearchQuery && val.patient_id.startsWith(patientSearchQuery)) {
					return val
				}  else {
					return false
				}
			} else {
				if (patientSearchQuery === "" && siteSearchQuery === "") {
					return val
				} else if (val.patient_id.includes(patientSearchQuery)) {
					return val
				} else if (val.site_id.includes(siteSearchQuery)) {
					return val
				} else {
					return false
				} 
			}
		})

		setDataProfilingTableData(tableData)

        setPatientPending(false)

	}, [patientsProvider.patients, patientsPreselect, doubleSearchQuery, patientSearchQuery, siteSearchQuery])

    // Button handlers
    function handleCreatePatientGroup() {	
		if (groupAlreadyExisted) { return }
        let groupName = document.getElementById("GroupInput")
        if (groupName === undefined || groupName === null) { return }
        if (groupName.value === "") {
            alert("Group name is required!")
            return
        }
		let group_name = groupName.value
        
        let patient_uids = selectedPatientsTableRows.map((row) => {return `${row.site_id}/${row.patient_id}`})

		createPatientGroup(group_name, authProvider.currentUser.email, studiesProvider.selectedStudyId, patient_uids).then(async () => {
			updateGroups()
		}).catch((e)=>{
			alert(e)
		})	

        cancelGroupsModal()
    }

    function handleEditClick(row) {
        let groupName = document.getElementById("GroupModifyInput")
        if (groupName === undefined || groupName === null) { return }
        groupName.value = row.group_name
        setCurrentSelectedRowGroupName(row.group_name)
        renderGroupsModifyModal()

		let selectedGroupPatients = patientGroupsProvider.patientGroups
			.filter(responseRow => responseRow.group_name === row.group_name)
			.map(responseRow => responseRow.patients)

		setPatientsPreselect(selectedGroupPatients[0])
    }

	function renderDeleteMultipleGroups() {
		document.getElementById('RemoveMultipleGroups').style.display = 'block'
		document.getElementById('ConfirmGrayOverlay').style.display = 'block'
	}

	function cancelDeleteMultipleGroups() {
		document.getElementById('RemoveMultipleGroups').style.display = 'none'
		document.getElementById('ConfirmGrayOverlay').style.display = 'none'
	}

    async function handleRemoveGroups() {     
	    if (selectedGroupRows.length) {
			let group_names = selectedGroupRows.map(r => r.group_name)	
			await Promise.all(group_names.map(group_name => deletePatientGroup(group_name))).then((res) => {console.log(res)}).catch((e)=>alert(e))		
			updateGroups()
			setSelectedGroupRows(false)
			setToggleGroupTableCleared(!toggleGroupTableCleared)			
		} 
    }

	function renderSaveGroupConfirmationModal() {
		document.getElementById('SaveGroupConfirmationModal').style.display = 'block'	
		document.getElementById('ConfirmGrayOverlay').style.display = 'block'	
	}

	function handleCancelSave() {
		document.getElementById('SaveGroupConfirmationModal').style.display = 'none'	
		document.getElementById('ConfirmGrayOverlay').style.display = 'none'
	}

    async function handleModifyGroup() {
		handleCancelSave()
		
        let newGroupName = document.getElementById("GroupModifyInput")
        if (newGroupName === undefined || newGroupName === null) { return }
        if (newGroupName.value === "") {
            alert("Group name is required!")
            return
        }

		let selectedPatient = selectedPatientsTableRows.map(row => `${row.site_id}/${row.patient_id}`)

		let patientsToAdd = selectedPatient.filter(x => !patientsPreselect.includes(x))
		let patientsToRemove = patientsPreselect.filter(x => !selectedPatient.includes(x)) 

		if (patientsToAdd.length) {
			await Promise.all(patientsToAdd.map(row => addPatientToGroup(currentSelectedRowGroupName, row.split("/")[0], row.split("/")[1]))).then((res) => {console.log(res)}).catch((e)=>alert(e))	
		}
		if (patientsToRemove.length) {
			await Promise.all(patientsToRemove.map(row => removePatientFromGroup(currentSelectedRowGroupName, row.split("/")[0], row.split("/")[1]))).then((res) => {console.log(res)}).catch((e)=>alert(e))
		}
		if (newGroupName.value !== currentSelectedRowGroupName) {
			modifyPatientGroup(currentSelectedRowGroupName, newGroupName.value).then((res) => {          
				console.log(res)
				updateGroups()
			}).catch((e) => {
				alert(e)
			})
		}
		setSelectedGroupRows(false)
		setToggleGroupTableCleared(!toggleGroupTableCleared)
		updateGroups()
        cancelGroupsModifyModal()
    }

	function handleRemoveSingleGroup() {
		console.log(currentSelectedRowGroupName)
		deletePatientGroup(currentSelectedRowGroupName).then((res) => {
			updateGroups()
			cancelGroupsModifyModal()
		}).catch((e) => {
			alert(e)
		})
	}

    const popoverTopText = {
        'name': 'Group Name.',
        'patients': 'Current Existing Patients List.',
    }
    
    function getPopoverTop(labelname) {
        return (
            <Popover id="Popover-trigger-hover-focus" style={{width: "228px"}} positionleft={75}>
                <p style={{fontFamily: "Source Sans Pro", fontStyle: "normal", fontWeight: "400", fontSize: "12px", color: "#5F6775", paddingLeft: "3px"}}>{popoverTopText[labelname]}</p>
            </Popover>
        )
    }

    return (<div style={{background: "#F8F8F8", height: `${window.innerHeight - 74}px`}}>

		<div style={{
			marginLeft: "-15px",
			background: "#FFFFFF",
			zIndex: "-9999"
		}}>
			<PageSubHeader style={{
			paddingLeft: "0px"}}>
			{tableSubheaderOrder.map((table, i) => {
				return (
					<>
						<TableButton
							id={`TableButton_${table}`}
							colorchange={
								tableSubheaderValues[table]
							}
							underlined={tableSubheaderValues[table]}
							onClick={() => {
								updateTableSubheaderValues(
									table,
									!tableSubheaderValues[table]
								);
							}}
						>
							{table === "Patient" ? <Link to={FRONTEND_LINKS.SUBPAGES.DATA.SUBPAGES.VISUALIZE.MAIN} style={{textDecoration: "none",color:"#6C757C",}}> <p>{table}</p></Link> :
							<p>{table}</p> }
						</TableButton>
					</>
				);
			})}
			</PageSubHeader>
		</div>

        <div style={{paddingLeft: "35px", paddingRight: "35px", paddingTop: "20px", display: 'block'}}>
			
			<ConfirmationModal id='RemoveMultipleGroups' ref={DeleteMultipleGroupsModalRef} style={{display: 'none', zIndex: '1001'}}>
				<p>Are you sure you want to delete these patient groups?</p>
				<div style={{display: 'flex', justifyContent: 'space-between'}}>
					<CancelConfirmationButton onClick={() => cancelDeleteMultipleGroups()}>
						Cancel
					</CancelConfirmationButton>

					<ConfirmButton onClick={() => handleRemoveGroups()}>
						Delete
					</ConfirmButton>
				</div>
			</ConfirmationModal>
            <ButtonHeader>
				<GroupButton
					ref={groupsButtonRef}
					onClick={() => {
						renderGroupsModal();
						document.getElementById("createPatientGroupsModalScrollableContentRef").scrollTop = 0
					}}
				>
					<MdIcons.MdModeEdit
						size={20}
						style={{ marginTop: "-3px" }}
					/>{" "}
					Create Group
				</GroupButton>
				<TrashButton
					onClickFxn={() => {renderDeleteMultipleGroups()}}
					disabled={!(selectedGroupRows.length > 0)}
					buttonStyle={{marginRight: "10%", flex: "0.1"}}
				/>
                <Searchbar className="ui left icon input">
                    <input
                        type="text"
                        onChange={(event) => {
                            setSearchQuery(event.target.value);
                        }}
                        placeholder="Search"
                    />{" "}
                    <i aria-hidden="true" class="search icon" />
                </Searchbar>
				</ButtonHeader>

            <div style={{position: "relative"}}>
                <MainTable
                    columns={groupTableColumn}
                    data={groupTableData} 
                    selectableRows
					progressPending={patientGroupPending}
                    onSelectedRowsChange={updateGroupRows}
                    clearSelectedRows={toggleGroupTableCleared}
                    pagination
                 />
                <GrayOverlay id="grayOverlay"/>
                <ModifyGrayOverlay id="grayOverlayModify"/>
                <ModifyGrayOverlay id="ConfirmGrayOverlay" style={{zIndex: '1000'}}/>
                <VizGrayOverlay id="vizGrayOverlay" style={{display: "none"}}/>
                </div>
			<div>
            <div>
            		<CreateGroupModal
						id="GroupsButtonModal"
						ref={groupsModalRef}
						style={{
							left: props.sidebar
								? `${(window.innerWidth - 1070) / 2 + 250}px`
								: `${(window.innerWidth - 850) / 2 + 40}px`,
							display: "none",
							top: `${(window.innerHeight - 776)/3}px`,
							position: "fixed",
                            maxHeight: `${window.innerHeight - 170}px`
						}}
					>
						<h3>Create Group</h3>
						<hr
							style={{
								border: "1px solid #B3B3B3",
								width: "820px",
							}}
						/>
						<Scrollbar id="createPatientGroupsModalScrollableContentRef"
							style={{
								paddingLeft: "32px",
								paddingRight: "32px",
								maxHeight: "615px",
								overflowY: "scroll",
								overflowX: "hidden",
							}}
						>
							{/* <h2>Group Details</h2> */}
							<div id="specialCheck" style={{marginBottom: '5px', display: specialDisplay}}>
								<WarningMessage ><p><strong>Warning!</strong> This group name is already taken.</p></WarningMessage>
							</div>

                            <div style={{display: 'flex'}}>  
                                <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={getPopoverTop('name')}> 
                                    <InfoButton><MdIcons.MdInfoOutline size={18} style={{float: "right"}}/></InfoButton>
                                    </OverlayTrigger>
                                <h1 style={{ marginTop: "-4px" }}>Group Name</h1>
                            </div> 
	
							<GroupNameInput onChange={e=>{setCheckGroupSearch(e)}}
							 id={'GroupInput'} placeholder="Example Group Name" />

                            <div style={{display: 'flex'}}>
                                <h1 style={{marginTop: "16px", marginBottom: "10px"}}>
                                <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={getPopoverTop('patients')}> 
                                <InfoButton><MdIcons.MdInfoOutline size={18} style={{float: "right", marginBottom: "-3px"}}/></InfoButton>
                                </OverlayTrigger>
                                <ModalDropdownButton onClick={renderPatientsTableCreateGroup}>Patients{createGroupPatientsIcon}</ModalDropdownButton>
                                </h1>
                            </div>

							<ModalDropdownTableContainer
								id="createGroupPatientsTable"
								style={{display: "none"}}
                            >

							<ModalSearchBar className="ui left icon input" style={{flex: "1"}}>
								<input id="createGroupSearch" type="text" onChange={handlePatientSearch} placeholder="Search"/> <i aria-hidden="true" class="search icon"/>
							</ModalSearchBar>

                            <MainTable
								columns={dataProfilingTableColumns}
                                data={dataProfilingTableData}
								progressPending={patientPending}
								selectableRows
                                onSelectedRowsChange={updatePatientsTableRows}
								selectableRowSelected={rowSelectCriteria}
								clearSelectedRows={togglePatientsCleared}
							/>
                            </ModalDropdownTableContainer>
						</Scrollbar>
						<hr
							style={{
								border: "1px solid #B3B3B3",
								width: "724px",
								marginLeft: "auto",
								marginRight: "auto",
							}}
						/>
						<div
							style={{
								paddingLeft: "35px",
								paddingBottom: "15px",
							}}
						>
							<ModalFooterButton
								buttonText="Create"
								disabled={groupAlreadyExisted}
								color={buttonColor.blueFill}
								buttonStyle={{width: "114px", marginRight: "5px"}}
								onClickFxn={(e)=> handleCreatePatientGroup()} 
							/>
							<ModalFooterButton
								buttonText="Cancel"
								onClickFxn={cancelGroupsModal}
								color={buttonColor.blueOutline}
								buttonStyle={{width: "114px", marginRight: "5px"}}
							/>
						</div>
					</CreateGroupModal>
					<div ref={groupsModifyModalRef}>

					<ConfirmationModal id='SaveGroupConfirmationModal' style={{display: 'none', zIndex: '1001'}}>
						<p>Are You Sure You Want To Save These Changes?</p>
						<div style={{display: 'flex', justifyContent: 'space-between'}}>
							<CancelConfirmationButton onClick={() => handleCancelSave()}>
								Cancel
							</CancelConfirmationButton>

							<ConfirmButton onClick={() => handleModifyGroup()}>
								Save
							</ConfirmButton>
						</div>
					</ConfirmationModal>
					
					<ModifyGroupModal
						id="GroupsButtonModifyModal"
						style={{
							left: props.sidebar
								? `${(window.innerWidth - 1070) / 2 + 250}px`
								: `${(window.innerWidth - 850) / 2 + 40}px`,
							display: "none",
							top: '30%',
							position: "fixed",
                            maxHeight: `${window.innerHeight - 170}px`
						}}
					>
							<h3>Modify Group</h3>
							<hr
								style={{
									border: "1px solid #B3B3B3",
									width: "820px",
								}}
							/>
							<Scrollbar id="modifyPatientGroupsModalScrollableContentRef"
								style={{
									paddingLeft: "32px",
									paddingRight: "32px",
									maxHeight: "615px",
									overflowY: "scroll",
									overflowX: "hidden",
								}}
							>
								{/* <h2>Group Details</h2> */}
								<div style={{display: 'flex'}}>
								<OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={getPopoverTop('name')}> 
									<InfoButton><MdIcons.MdInfoOutline size={18} style={{float: "right"}}/></InfoButton>
									</OverlayTrigger>
								<h1 style={{ marginTop: "-4px" }}>Group Name</h1>
								</div>
								<GroupNameInput id={'GroupModifyInput'} placeholder="Example Group Name" />
								
								<div style={{display: 'flex'}}>
									<h1 style={{marginTop: "16px", marginBottom: "10px"}}>
									<OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={getPopoverTop('patients')}> 
									<InfoButton><MdIcons.MdInfoOutline size={18} style={{float: "right", marginBottom: "-3px"}}/></InfoButton>
									</OverlayTrigger>
									<ModalDropdownButton onClick={renderPatientsTableModifyGroup}>Patients{modifyGroupPatientsIcon}</ModalDropdownButton>
									</h1>
								</div>

								<ModalDropdownTableContainer
									id="modifyGroupPatientsTable"
									style={{display: "none"}}
								>
								<ModalSearchBar className="ui left icon input" style={{flex: "1"}}>
									<input id="modifyGroupSearch" type="text" onChange={handlePatientSearch} placeholder="Search"/> <i aria-hidden="true" class="search icon"/>
								</ModalSearchBar>

								<MainTable
									columns={dataProfilingTableColumns}
									data={dataProfilingTableData}
									selectableRows
									onSelectedRowsChange={updatePatientsTableRows}
									selectableRowSelected={rowSelectCriteria}
									clearSelectedRows={togglePatientsCleared}
								/>
								</ModalDropdownTableContainer>

							</Scrollbar>
							<hr
								style={{
									border: "1px solid #B3B3B3",
									width: "724px",
									marginLeft: "auto",
									marginRight: "auto",
								}}
							/>
							<div
								style={{
									paddingLeft: "35px",
									paddingBottom: "15px",
								}}
							>
								<ModalFooterButton
									buttonText="Save"
									onClickFxn={(e)=> renderSaveGroupConfirmationModal()} 
									color={buttonColor.blueFill}
									buttonStyle={{width: "114px", marginRight: "5px"}}
								/>
								<ModalFooterButton
									buttonText="Cancel"
									color={buttonColor.blueOutline}
									onClickFxn={cancelGroupsModifyModal}
									buttonStyle={{width: "114px", marginRight: "5px"}}
								/>
								<ModalFooterButton 
									buttonText="Delete"
									onClickFxn={(e) => {e.preventDefault(); handleRemoveSingleGroup()}}
									buttonStyle={{width: "114px", marginRight: "30px", float: "right"}}
									color={buttonColor.redOutline}
								/>
							</div>
						</ModifyGroupModal>
					</div>
            </div>
			</div>
        </div>

    </div>)
};

const rowSelectCriteria = (row) => {
	return row.selected;
};

const InfoButton = styled.button`
    border: none;
    background: none;
    border: none;
    color: #207dea;
    cursor: pointer;
    margin-top: 18px;
    width: fit-content;
    margin-right: 5px;
    margin-top: 0px;
    margin-bottom: 7px;
`;
const ModalDropdownButton = styled.button`
	cursor: pointer;
	display: inline;
	flex-direction: row;
	border: solid 0.5px #FFFFFF;
	background-color: #FFFFFF;
	font-family: "Source Sans Pro";
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 150%;
	color: #293241;
`;

const ModalSearchBar = styled.div`
	width: 60%;
	height: 30px;
	background: #FFFFFF;
	border-radius: 6;
	box-sizing: border-box;
	overflow-y: hidden;
	overflow-x: hidden;
	margin-bottom: 10px;
`;

const Scrollbar = styled.div`
	height: auto;
	max-height: 615px;
	scrollbar-width: thin;
	::-webkit-scrollbar {
		display: block;
		width: 5px;
		color: #313a4a;
	}
	::-webkit-scrollbar-track {
		background: #bec4cf;
		width: 5px;
		border-radius: 2px;
	}
`;
const GroupNameInput = styled.input`
	background:  ${({ disabled }) => (disabled ? "#F2F2F2" : "#ffffff")};
	border: 2px solid #cccccc;
	box-sizing: border-box;
	border-radius: 5px;
	width: 464px;
	height: 56px;
	padding: 16px;
	margin-top: 5px;
	margin-bottom: 10px;
	color: ${({ disabled }) => (disabled ? "#737373": "#000000")};
	::placeholder {
		font-family: "Source Sans Pro";
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 150%;
		color: #9f9f9f;
	}
`;

const CreateGroupModal = styled.div`
	width: 820px;
	// max-height: 776px;
	height: auto;
	background: #ffffff;
	border-radius: 6px;
	// top: 20px;
	z-index: 100;
	h3 {
		font-family: "Montserrat";
		font-style: normal;
		font-weight: 700;
		font-size: 20px;
		line-height: 150%;
		color: #293241;
		text-align: center;
		margin-top: 8px;
		margin-bottom: -4px;
	}
	h2 {
		font-family: "Source Sans Pro";
		font-style: normal;
		font-weight: 700;
		font-size: 21px;
		line-height: 150%;
		color: #293241;
		margin-top: 5px;
	}
	h1 {
		font-family: "Source Sans Pro";
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 150%;
		color: #293241;
	}
	textarea {
		background: #ffffff;
		border: 1px solid #ababab;
		box-sizing: border-box;
		border-radius: 6px;
		padding: 16px;
		width: 756px;
		height: 100px;
		margin-top: -5px;
		::placeholder {
			font-family: "Source Sans Pro";
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 150%;
			color: #9f9f9f;
			margin-top: -200px !important;
		}
	}
`;

const ModifyGroupModal = styled.div`
	width: 820px;
	// max-height: 776px;
	height: auto;
	background: #ffffff;
	border-radius: 6px;
	// top: 20px;
	z-index: 100;
	h3 {
		font-family: "Montserrat";
		font-style: normal;
		font-weight: 700;
		font-size: 20px;
		line-height: 150%;
		color: #293241;
		text-align: center;
		margin-top: 8px;
		margin-bottom: -4px;
	}
	h2 {
		font-family: "Source Sans Pro";
		font-style: normal;
		font-weight: 700;
		font-size: 21px;
		line-height: 150%;
		color: #293241;
		margin-top: 5px;
	}
	h1 {
		font-family: "Source Sans Pro";
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 150%;
		color: #293241;
	}
	textarea {
		background: #ffffff;
		border: 1px solid #ababab;
		box-sizing: border-box;
		border-radius: 6px;
		padding: 16px;
		width: 756px;
		height: 100px;
		margin-top: -5px;
		::placeholder {
			font-family: "Source Sans Pro";
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 150%;
			color: #9f9f9f;
			margin-top: -200px !important;
		}
	}
`;

const VizGrayOverlay = styled.div`
	background: #808080;
	opacity: .8;
	position: fixed;
	top: 0;
    left: 0;
	z-index: 99;
	width: 100%;
	height: 100%;
	display: none;
`;

const ButtonHeader = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding-bottom: 15px;
	position: relative;
	padding-top: 20px;
`;

const Searchbar = styled.div`
	width: 40%;
	margin-left: 5%;
	height: 34px;
	background: #ffffff;
	border: 0.5px solid #d9d9d9;
	box-sizing: border-box;
	box-shadow: 0px 0px 3px rgba(99, 191, 244, 0.15);
	border-radius: 6px;
	overflow-y: hidden;
	overflow-x: hidden;
	flex: 2;
	button {
		width: 80px;
		height: 34px;
		background: #207dea;
		border-radius: 0px 6px 6px 0px;
		border: none;
		font-family: "Source Sans Pro";
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 150%;
		color: #ffffff;
		cursor: pointer;
        padding-bottom: 3.5px;
	}
`;

const GrayOverlay = styled.div`
	background: #808080;
	opacity: 0.8;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 10;
	width: 100%;
	height: 100%;
	display: none;
`;

const ModifyGrayOverlay = styled.div`
	background: #808080;
	opacity: 0.8;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 10;
	width: 100%;
	height: 100%;
	display: none;
`;

const GroupButton = styled.button`
	width: auto;
	height: 34px;
	background: #207dea;
	border-radius: 6px;
	font-family: "Source Sans Pro";
	font-style: normal;
	font-weight: 600;
	line-height: 150%;
	color: #ffffff;
	border: none;
	padding: 4px 8px;
	margin-right: 8px;
	white-space: nowrap;
	flex: 0.25;
`;

const PageSubHeader = styled.div`
	display: flex;
	width: 100%;
	height: 47px;
	overflow-x: auto;
	overflow-y: hidden;
	white-space: nowrap;
	background: #ffffff;
	padding-top: 10px;
	z-index: -9999;
	button {
		font-family: "Source Sans Pro";
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		display: inline-flex;
		margin-left: 45px;
		border: solid 0.5px #ffffff;
		background: #ffffff;
		cursor: pointer;
	}
`;

const TableButton = styled.button`
	width: auto;
	height: 34px;
	background: #ffffff;
	border: solid 0.5px #ffffff;
	font-size: 16px;
	display: inline-flex;
	margin-right: -25px;
	color: ${({colorchange}) => (colorchange ? "#0097ee" : "#6C757C")};
	p {
		padding-bottom: 10px;
		border-bottom: ${({underlined}) =>
			underlined ? "2px solid #0097ee" : "none"};
	}
`;

const EditGroupButton = styled.button`
	width: auto;
	height: 34px;
	background: transparent;
	border-radius: 6px;
	font-family: 'Source Sans Pro';
	font-style: normal;
	font-weight: 600;
	line-height: 150%;
	color: #000000;
	border: none;
	padding: 4px 8px;
	margin-right: 8px;
	white-space: nowrap;
	flex: 0.25;
`;

const ModalDropdownTableContainer = styled.div`
	border: 2px solid #cccccc;
	box-sizing: border-box;
	border-radius: 5px;
	background: #ffffff;
	width: 97%;
	height: auto;
	padding: 10px;
`;

const ConfirmationModal = styled.div`
	z-index: 1000;
	width: 336px;
	height: auto;
	background: rgb(255, 255, 255);
	border: 0.5px solid rgb(182, 182, 182);
	padding: 8px 16px;
	box-sizing: border-box;
	border-radius: 6px;
	position: absolute;
	font-family: "Source Sans Pro";
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 150%;
	color: rgb(0, 0, 0);
	margin-left: 210px;
	margin-top: -420px;
	top: 75%;
	left: 45%;
	transform: translate(-50%, -50%);
`;

const ConfirmButton = styled.div`
	display: inline-flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 6px 12px;
	margin-right: 5px;
	margin-top: 0px;
	width: fit-content;
	height: 40px;
	border-radius: 6px;
	font-family: "Source Sans Pro";
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 150%;
	border: 2px solid rgb(234, 61, 61);
	color: rgb(234, 61, 61);
	cursor: pointer;
	-webkit-box-pack: center;
	justify-content: center;
	white-space: nowrap;
`;

const CancelConfirmationButton= styled.div`
	display: inline-flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 6px 12px;
	margin-right: 5px;
	margin-top: 0px;
	width: fit-content;
	height: 40px;
	border-radius: 6px;
	font-family: "Source Sans Pro";
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 150%;
	border: 2px solid rgb(32, 125, 234);
	cursor: pointer;
	-webkit-box-pack: center;
	justify-content: center;
	white-space: nowrap;
`;


export default PatientGroup;