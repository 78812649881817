import { atom } from "recoil"
import { DataSource } from "../Types/DataSource"

type PatientFileInfo = {
    fileStartDate: Date
    fileEndDate: Date
    patientId: string
    dataSourceMap: Map<DataSource, number>
    patientModalities: string[],
    timeZone: string
    montageMapping: Map<string, string> // If the patient has EEG, the mapping of physical inputs on the EEG amp to their labels.
    isAdmitted: boolean
}

export const currentPatientFileInfoAtom = atom<PatientFileInfo>({
    key: "currentPatientFileInfo",
    default: {
        fileStartDate: new Date(0), // file times are local to the time zone of the patient file, NOT UTC
		fileEndDate: new Date(0),
        patientId: "?",
        dataSourceMap: new Map(),
        patientModalities: [],
        montageMapping: new Map(),
        isAdmitted: true,
        timeZone: "America/New_York"
    }
})

export const livePatientFileEndTimeAtom = atom<any>({
    key: "livePatientFileEndTime",
    default: new Date(0)
})