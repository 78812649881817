import { useSetRecoilState } from "recoil"
import { hotkeysEnabledAtom } from "../../Pages/Data/Visualize/DataReview/Atoms/Hotkeys"
import { CSSProperties, ChangeEvent, KeyboardEvent, forwardRef, useCallback, useLayoutEffect, useState } from "react"
import { MobergInputLabel } from "./MobergInputHeader"
import React from "react"
import { MobergColumn } from "../MobergLayout/MobergColumn"
import { MobergRow } from "../MobergLayout/MobergRow"
import { MdOutlineErrorOutline } from "react-icons/md"
import { MobergIconSize } from "../MobergIcon/MobergIcon"
import { MobergTheme, getColors } from "../MobergThemes/MobergColors"
import { isNaN } from "lodash"
import { useOnMount } from "../../Hooks/useOnMount"

type MobergNumberInputProps = {
	label?: string
	placeholder?: string
	defaultValue?: number
	value?: number
	limit?: number
	orientation?: "row" | "column"
	style?: CSSProperties
	onChange?: (value: number) => void
	onSubmit?: () => void
	onValidationErrorChange?: (error: string | undefined) => void
	validationFunction?: (value: number) => string | undefined
	fallbackValue?: number
}

export const MobergNumberInput = forwardRef<HTMLInputElement, MobergNumberInputProps>(({ label, placeholder, orientation, defaultValue = 0, value: controlledValue, style, limit=25, onChange, onSubmit, onValidationErrorChange, validationFunction, fallbackValue }, ref) => {
		const setHotkeysEnabled = useSetRecoilState(hotkeysEnabledAtom)
		const [value, setValue] = useState<string>(defaultValue.toString())
		const [validationError, setValidationError] = useState<string>()

		const handleValidation = useCallback((value: string) => {
			const isNumber = !isNaN(Number(value))

			let error: string | undefined = undefined

			if (!isNumber) {
				error = "Please enter a number."
			} else {
				if (validationFunction) {
					const validationError = validationFunction(Number(value))
					if (validationError !== undefined) {
						error = validationError
					}
				}
			}

			if (validationError !== error) {
				setValidationError(error)

				if (onValidationErrorChange) {
					onValidationErrorChange(error)
				}
			}

			if (error) {
				return error
			}

			return undefined

		}, [onValidationErrorChange, validationError, validationFunction])

		useLayoutEffect(() => {
			if (controlledValue && controlledValue.toString() !== value) {
				setValue(controlledValue.toString())
			}
		}, [controlledValue, value])

		useOnMount(() => {
			handleValidation(value)
		})

		const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
			const value = event.target.value.trim()

			if (value.length > limit) {
				return
			}

			setValue(value)

			const hasError = handleValidation(value)

			if (onChange) {
				if (isNaN(value) || value.endsWith(".")) {
					onChange(value as any)
				} else {
					if ((value === '' && fallbackValue !== undefined) || hasError) {
						onChange(Number(fallbackValue))
					} else {
						onChange(Number(value))
					}
				}
			}
		}

		const checkForSubmit = (event: KeyboardEvent<HTMLInputElement>) => {
			if (event.key === "Enter" && onSubmit) {
				onSubmit()
			}
		}

		const getContent = () => {
			const red = getColors(MobergTheme.RED).main

			const inputStyle: CSSProperties = { 
				padding: "8px 16px",
				paddingRight: validationError ? "32px" : "16px", 
				borderRadius: "6px", 
				borderWidth: "1px",
				borderStyle: "solid",
				borderColor: validationError ? "transparent" : "#cdcdcd", 
				width: "90px",
				...style
			}

			if (validationError) {
				inputStyle.outline = `2px solid ${red}`
				inputStyle.outlineOffset = "-2px"
			}

			return (
			<>
				{label && <MobergInputLabel text={label} style={{ whiteSpace: "nowrap" }} />}

				<div style={{ position: "relative" }}>
					<input
						ref={ref}
						value={value}
						placeholder={placeholder}
						onFocus={() => setHotkeysEnabled(false)}
						onBlur={() => setHotkeysEnabled(true)}
						onChange={handleChange}
						onKeyDown={checkForSubmit}
						type={"text"}
						style={inputStyle}
					/>

					{validationError && (
						<MdOutlineErrorOutline 
							size={MobergIconSize.REGULAR}
							title={validationError}
							color={red}
							style={{ position: "absolute", right: "16px", top: "25%" }}
						/>
					)}
				</div>
			</>
		)}

		return (<>
			{orientation === "row" 
				? <MobergRow gap="16px"> {getContent()} </MobergRow> 
				: <MobergColumn gap="8px"> {getContent()} </MobergColumn>
			}
		</>)
	}
)
