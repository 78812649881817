import React from 'react'
import { DEFAULTS } from '../../Constants/Formatting';

/**
 * Function used to make a default alert
 * @param {Array} pages - An array containing the number of pages
 * @param {Function} change_state - A function that will change the state when the page button is clicked
 * @param {Variable} state_parameter - A variable that apsses into the state function
 */
function Pagination({pages=[], change_state, state_parameter}) {

    function mouseOver(e) { e.currentTarget.style.backgroundColor = "#C7E4FF" }
    function mouseOut(e) { e.currentTarget.style.backgroundColor = DEFAULTS.TABLE_HEAD_BACKGROUND }
    function mouseDown(e) { e.currentTarget.style.backgroundColor = "#91C9FF" }
    function mouseUp(e) { e.currentTarget.style.backgroundColor = DEFAULTS.TABLE_HEAD_BACKGROUND }

    function returnPageButtons() {
        return (<>{pages.map((page, index) => {
            return (<>
                <li style={{display: "inline", backgroundColor: DEFAULTS.TABLE_HEAD_BACKGROUND, border: "2px solid #000", padding: "0.25em"}} onMouseOver={mouseOver} onMouseOut={mouseOut} onMouseDown={mouseDown} onMouseUp={mouseUp}>
                    {/* <p style={{padding: "0.35em", cursor: "pointer"}} onClick={() => {change_state(state_parameter)}}>{index + 1} </p> */}
                    <p style={{padding: "0.35em", cursor: "pointer"}} onClick={() => {change_state(index + 1)}}>{index + 1} </p>
                </li>
            </>)
        })}</>)
    }
	// JSX Object containing the button group
	return (
        <>
            {/* PAGINATION START */}
            <ul style={{listStyle: "none", display: "flex", justifyContent: "center", alignItems: "center"}}>
                {returnPageButtons()}
            </ul>
            {/* PAGINATION END */}
        </>
    )
}

export default Pagination;