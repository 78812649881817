import styled from 'styled-components';
import * as MdIcons from 'react-icons/md';
import { useModalProvider } from "../../Providers/ModalProvider";
import ModalHeader from "../../Components/ModalHeader/ModalHeader";
import ChangeEmail from "../../Pages/Account/AccountSettingsModalSubpages/ChangeEmail"
import ChangePassword from '../../Pages/Account/AccountSettingsModalSubpages/ChangePassword';
import DeleteAccount from '../../Pages/Account/AccountSettingsModalSubpages/DeleteAccount';
import { useAuthProvider } from '../../Providers/AuthProvider';

const AccountSettingsModal = (props) => {
    const { openNext } = useModalProvider()
    const authProvider = useAuthProvider()

    function renderChangeEmailModal() {
        openNext(<ChangeEmail
			escClose={false}
			clickOutsideClose={false}
		/>)
    }

    function renderChangePasswordModal() {
        openNext(<ChangePassword
			escClose={false}
			clickOutsideClose={false}
		/>)
    }

    function renderDeleteAccountModal() {
        openNext(<DeleteAccount
            escClose={false}
            clickOutsideClose={false}
        />)
    }

    return (
        <div style={{background: "#FFFFFF", width: "820px", height: "776px", display: "block", paddingTop: "20px", paddingBottom: "250px"}}>
            <ModalHeader headerText='Account Settings' />
            <div id="AccountSettingsModalContent" style={{display: "inline-grid", padding: "20px"}}>
                <SubheaderText style={{marginBottom: "15px"}}>Account Access</SubheaderText>
                <SettingsContainer>
                    <NormalTextBold>Email Address</NormalTextBold>
                    <NormalText style={{right: "20px"}}>
                        {authProvider.currentUser?.email}
                        <MdIcons.MdOutlineArrowRightAlt size={32} style={{marginLeft: "10px", cursor: "pointer"}} onClick={() => {renderChangeEmailModal()}}/>
                    </NormalText>
                </SettingsContainer>
                <hr style={{border: "1px solid #293241", width: "780px", marginLeft: "auto", marginRight: "auto", marginTop: "8px", marginBottom: "8px"}}/>

                <SettingsContainer>
                    <NormalTextBold>Change Password</NormalTextBold>
                    <NormalText style={{right: "20px"}}>
                        <MdIcons.MdOutlineArrowRightAlt size={32} style={{marginLeft: "10px", cursor: "pointer"}} onClick={() => {renderChangePasswordModal()}}/>
                    </NormalText>
                </SettingsContainer>
                <hr style={{border: "1px solid #293241", width: "780px", marginLeft: "auto", marginRight: "auto", marginTop: "8px", marginBottom: "8px"}}/>

                 <SettingsContainer>
                    <NormalTextBold>Delete Account</NormalTextBold>
                    <NormalText style={{right: "20px"}}>
                        <MdIcons.MdOutlineArrowRightAlt size={32} style={{marginLeft: "10px", cursor: "pointer"}} onClick={() => {renderDeleteAccountModal()}}/>
                    </NormalText>
                </SettingsContainer>
                <hr style={{border: "1px solid #293241", width: "780px", marginLeft: "auto", marginRight: "auto", marginTop: "8px", marginBottom: "8px"}}/>
            </div>

        </div>
    )
}

const SubheaderText = styled.p`
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-size: 21px;
    font-weight: 700;
    color: #293241;
    line-height: 150%;
    margin: 0;
`;

const NormalTextBold = styled.p`
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #293241;
    line-height: 150%;
    margin: 0;
`;

const NormalText = styled.p`
    position: absolute;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400px;
    font-size: 16px;
    color: #293241;
    line-height: 150%;
    margin: 0;
`;

const SettingsContainer = styled.div`
    width: 760px;
    display: inline-flex;
    padding-left: 5px;
`;

export default AccountSettingsModal;