import React from "react"
import { Grid } from "../../../../Pages/Data/Visualize/DataReview/Components/CSSGrid/Grid"
import { GridArea } from "../../../../Pages/Data/Visualize/DataReview/Components/CSSGrid/GridArea"
import { Layout } from "../../../../Pages/Data/Visualize/DataReview/Types/Layout"
import { VisualizationComponent } from "../../../../Pages/Data/Visualize/DataReview/VisualizationComponentFactory"
import { VisualizationSelectorContext } from "../../../../Pages/Data/Visualize/DataReview/Components/Visualizations/VisualizationSelector/VisualizationSelector"
import { useRecoilState } from "recoil"
import { editedLayoutGroupAtom } from "../../../../Pages/Data/Visualize/DataReview/Atoms/Layout"
import { getDefaultVisualizationProps } from "../../../../Pages/Data/Visualize/DataReview/Atoms/Visualizations"
import { MobergTheme, MobergThemeColors, getColors } from "../../../../Components/MobergThemes/MobergColors"
import styled from "styled-components"
import { MobergAnimationCurve, MobergAnimationTiming } from "../../../../Components/MobergAnimation/MobergAnimation"
import { MobergColumn } from "../../../../Moberg"

type LayoutTemplateSelectorProps = {
	currentLayout: Layout | undefined
}

type LayoutButtonProps = {
	isSelected: boolean
	themeColors: MobergThemeColors
}

const templates = [
	[["a"]],
	[["a", "b"]],
	[["a"], ["b"]],
	[["a", "b", "b"]],
	[["a", "a", "b"]],
	[["a", "b", "c"]],
	[
		["a", "b"],
		["a", "c"],
	],
	[
		["a", "b"],
		["c", "d"],
	],
]

export const layoutOptions: Layout[] = templates.map((template, index) => ({
	id: index.toString(),
	name: "placeholder",
	cssGridTemplate: template,
	areas: [...new Set(template.flat())].map(uniqueArea => ({
		area: uniqueArea,
		componentId: VisualizationComponent.PLACEHOLDER,
	})),
}))

function createVisualization(layout: Layout, componentId: string, props?: {}, shadow?: boolean) {
	return (
		<Grid template={layout.cssGridTemplate}>
			{layout.areas.map(config => (
				<GridArea key={`${layout.id}-${config.area}`} {...config} props={props} shadow={shadow} componentId={componentId} />
			))}
		</Grid>
	)
}

export const LayoutTemplateSelector = (props: LayoutTemplateSelectorProps) => {
	const [editedLayoutGroup, setEditedLayoutGroup] = useRecoilState(editedLayoutGroupAtom)
	const selectedLayout = editedLayoutGroup?.layouts.find(layout => layout.id === props.currentLayout?.id) ?? props.currentLayout

	const isLayoutSelected = (layout: Layout) => JSON.stringify(layout.cssGridTemplate) === JSON.stringify(selectedLayout?.cssGridTemplate)

	const updateSelectedLayout = (newLayout: Layout) => {
		setEditedLayoutGroup(previous => {
			if (previous === null) {
				return previous
			}

			const newLayoutGroup = {
				...previous,
				layouts: previous.layouts.map(layout => {
					if (layout.id === props.currentLayout?.id) {
						return {
							...props.currentLayout,
							areas: newLayout.areas.map(area => ({
								...area,
								props: { ...getDefaultVisualizationProps(area.componentId), ...area.props },
							})),
							cssGridTemplate: newLayout.cssGridTemplate,
						}
					}

					return layout
				}),
			}

			return newLayoutGroup
		})
	}

	return (
		<div style={{ height: "100%", width: "100%", padding: "32px" }}>
			<MobergColumn gap="16px" expand={true}>
				<div style={{ flex: 1 }}>
					<h1> Choose a layout: </h1>
					<div id="options" style={{ display: "flex", gap: "32px", flexWrap: "wrap" }}>
						{layoutOptions.map(layout => {
							return (
								<LayoutButton
									key={layout.id}
									themeColors={getColors(MobergTheme.BLUE)}
									isSelected={isLayoutSelected(layout)}
									onClick={() => updateSelectedLayout(layout)}
								>
									{createVisualization(layout, VisualizationComponent.PLACEHOLDER, {
										color: isLayoutSelected(layout) ? getColors(MobergTheme.BLUE).main : "#dedede",
									})}
								</LayoutButton>
							)
						})}
					</div>
				</div>

				<div style={{ flex: 3, padding: "16px 0" }}>
					<h1> Choose window types: </h1>
					<VisualizationSelectorContext.Provider value={{ currentLayoutId: props.currentLayout?.id ?? "", selectedLayout, updateSelectedLayout }}>
						{selectedLayout && createVisualization(selectedLayout, VisualizationComponent.VISUALIZATION_SELECTOR)}
					</VisualizationSelectorContext.Provider>
				</div>
			</MobergColumn>
		</div>
	)
}

const LayoutButton = styled.button<LayoutButtonProps>`
	margin: 0;
	border: none;
	border-radius: 6px;
	background: #fff;
	min-width: 100px;
	max-width: 150px;
	min-height: 100px;
	max-height: 100px;
	flex: 1;
	transition: all ${MobergAnimationTiming.FAST} ${MobergAnimationCurve.EASE_IN};

	&:hover {
		transform: scale(1.05);
	}
`
