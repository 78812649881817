import ModalHeader from "../../../../../Components/ModalHeader/ModalHeader";
import { useModalProvider } from "../../../../../Providers/ModalProvider";
import { useUploadQueueProvider } from "../../../../../Providers/UploadQueueProvider";
import UploadProgressBar from "./Components/UploadProgressBar";
import loadingBrainBlue from "../../../../../Constants/Graphics/loadingbrain-blue.svg"
import loadingBrainPurple from "../../../../../Constants/Graphics/loadingbrain-purple.svg"
import VerticalTimeline from "../../../../../Components/VerticalTimeline/VerticalTimeline";
import styled from "styled-components";
import { UploadFilesModal, PercentUploaded, UploadDescription, ModalFooter } from "../../../../../Constants/StyledComponents";
import { useRecoilValue } from "recoil"
import { percentUploaded, timeRemaining, fileUploaded, totalUploadFiles, uploadFailed, currentUpload } from "../../../../../../src/recoil_state"
import { MobergButton } from "../../../../../Components/MobergButton/MobergButton";
import { MobergTheme } from "../../../../../Components/MobergThemes/MobergColors";
import { MobergButtonVariant } from "../../../../../Components/MobergButton/MobergButton";
import { MobergButtonShape } from "../../../../../Components/MobergButton/MobergButton";
import ConfirmationModal from "../../../../../Components/ConfirmationModal/ConfirmationModal"
import { useEndpointProvider } from "../../../../../Providers/EndpointProvider";
import { useUploadProcessProvider } from "../../../../../Providers/UploadProcessProvider";
import { useBackendLinksProvider } from "../../../../../Providers/BackendLinksProvider"

const UploadLoading = () => {

	/**
	 * Recoil is used because we dont want to re-render the percentage of upload and time remaining every time these 2 variables change
	 */

	const loadingPercentUploaded = useRecoilValue(percentUploaded)

	const loadingTimeRemaining = useRecoilValue(timeRemaining)

	const loadingFileUploaded = useRecoilValue(fileUploaded)

	const loadingTotalUploadFiles = useRecoilValue(totalUploadFiles)

	const loadingUploadFailed = useRecoilValue(uploadFailed)

	const currentUploadData = useRecoilValue(currentUpload)

	const uploadQueueProvider = useUploadQueueProvider()

	const uploadProcessProvider = useUploadProcessProvider()

	const endpointProvider = useEndpointProvider()

	const { LINKS } = useBackendLinksProvider()

	//handles color change for PercentUploaded text and brain svg when upload reaches 100%

	const { createModal, close } = useModalProvider()
	const handleHide = () => {
		close()
	}

	async function cancelUpload() {
		let uploadData = {
			patient_id: uploadQueueProvider.patientID,
			site_id: uploadQueueProvider.selectedSiteID,
			project_id: uploadQueueProvider.projectPrimaryKey,
			cns_directory_names: currentUploadData?.cns_directory_names,
			natus_directory_names: currentUploadData?.natus_directory_names,
			medications_file_names: currentUploadData?.medications_file_names
		}

		uploadProcessProvider.updateCurrentUploads(uploadData)

		uploadQueueProvider.setCancelGUI(true);
		let body = {
			upload_id: uploadQueueProvider.uploadID,
		}

		endpointProvider.post(LINKS.DATA.UPLOAD.CANCEL_UPLOAD, body)

		// Closes the confirmation modal and then close the upload window
		await close()
		await close()
	}

	function renderStopUploadModal() {
		createModal(<ConfirmationModal
			escClose={false}
			clickOutClose={false}
			title={"Stop upload?"}
			description={'Would you like to stop the current upload? Stopping it will end this process and remove any data that has been sent to the cloud.'}
			confirmButton={{ text: "Yes, cancel upload", theme: MobergTheme.RED, onClick: cancelUpload }}
			cancelButton={{ text: "No, continue upload" }}
		/>)
	}

	function handleCancel() {
		renderStopUploadModal()
	}

	return (<>
		<div>
			<UploadFilesModal>
				<ModalHeader headerText='Upload in Progress' />
				<div style={{ paddingTop: "108px", paddingLeft: "63px", paddingRight: "63px", paddingBottom: "146px", height: "600px", marginTop: "18px", flex: 1 }}>
					<div style={{ display: "inline-flex", marginLeft: "145px", marginRight: "auto", textAlign: "center", width: "600px" }}>
						<img src={loadingFileUploaded === loadingTotalUploadFiles ? loadingBrainPurple : loadingBrainBlue} alt="uploadInProgress" style={{ width: "133px", marginRight: "-90px" }} />
						<PercentUploaded style={{ color: loadingUploadFailed ? "#E54E58" : (loadingFileUploaded === loadingTotalUploadFiles ? "#4632DA" : "#207DEA") }}>{loadingPercentUploaded}%</PercentUploaded>
						<div style={{ marginLeft: "10px", marginRight: "auto", textAlign: "left", width: "315px", paddingTop: "15px" }}>
							<UploadDescription style={{ display: loadingUploadFailed ? "block" : "none", color: "#E54E58" }}>Upload failed for</UploadDescription>
							<UploadDescription style={{ display: loadingFileUploaded !== loadingTotalUploadFiles && !loadingUploadFailed ? "block" : "none" }}>Uploading data for:</UploadDescription>
							<UploadDescription style={{ display: loadingFileUploaded === loadingTotalUploadFiles && !loadingUploadFailed ? "block" : "none", color: "#4632DA" }}>Upload Complete for:</UploadDescription>
							<UploadDescription style={{ fontWeight: "700", color: loadingUploadFailed ? "#E54E58" : (loadingFileUploaded !== loadingTotalUploadFiles ? "#4632DA" : "#293241") }}>Patient {uploadQueueProvider.patientID} at {uploadQueueProvider.selectedSiteName}</UploadDescription>
						</div>
					</div>

					<div style={{ marginLeft: "150px", marginTop: "-10px" }}>
						<VerticalTimeline timelineData={uploadQueueProvider.verticalTimelineData} />
					</div>

					{!loadingUploadFailed && <UploadProgressBar completed={loadingPercentUploaded} time={loadingTimeRemaining} />}

					{uploadQueueProvider.verticalTimelineData[1].complete && <UploadSuccess>
						Your upload was successful! You will receive an email when your data is ready for review.
					</UploadSuccess>}

					{loadingFileUploaded !== loadingTotalUploadFiles && !loadingUploadFailed && <UploadAlert>
						Data is uploading. Do not close your browser. You can close this pop-up and upload another patient while you're waiting.
					</UploadAlert>}

					{loadingUploadFailed ?
						<UploadFailed>
							Your upload has failed. We apologize for the inconvenience. A message has been sent to the Moberg Analytics Support team. We will investigate this issue.
						</UploadFailed>
						:
						null
					}
				</div>
				<hr style={{ border: "1px solid #B3B3B3", width: "100%", margin: '0px auto', display: loadingUploadFailed ? 'none' : 'block' }} />
				<ModalFooter>
					{(loadingFileUploaded !== loadingTotalUploadFiles) && !loadingUploadFailed &&
						<MobergButton
							theme={MobergTheme.RED}
							variant={MobergButtonVariant.OUTLINE}
							shape={MobergButtonShape.WIDE}
							onClick={handleCancel}
						>
							Cancel
						</MobergButton>
					}
					{!loadingUploadFailed &&
						<MobergButton
							theme={MobergTheme.BLUE}
							variant={MobergButtonVariant.OUTLINE}
							shape={MobergButtonShape.WIDE}
							onClick={handleHide}
						>
							Close
						</MobergButton>
					}
				</ModalFooter>
			</UploadFilesModal>
		</div>
	</>
	)
}

const UploadSuccess = styled.div`
    font-size: 18px;
    font-weight: 700;
    color: #4632DA;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
`

const UploadFailed = styled.div`
    font-size: 18px;
    font-weight: 700;
    color: #E54E58;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
`

const UploadAlert = styled.div`
    font-size: 18px;
    font-weight: 700;
    color: #4632DA;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
`

export default UploadLoading;