import { useContext, useEffect, useState, useRef, useCallback } from 'react';
import styled from 'styled-components';
import * as MdIcons from 'react-icons/md';
import Checkbox from "react-custom-checkbox";
import { TableContext } from './TableContext';
import { filterType, CompareOperator, datetimeToTimestamp } from './Static';

const timestampToUTC = (timestamp) => { // 1511898300000 --> '2017-11-28T19:45:00.000'
    return (new Date(timestamp)).toISOString().slice(0, -1)
}

const minuteToInputValues = (minutes) => 
    minutes === null ? {Days: "", Hours: "", Minutes: ""} :
    ({
        Days: String(Math.floor(minutes / 1440)),
        Hours: String(Math.floor((minutes % 1440) / 60)),
        Minutes: String(Math.floor((minutes % 60) / 1))
    })

const parseDate = (utcTimeString) => { // 1511898300000 --> '2017-11-28T14:45:00.00'
    const date = new Date(utcTimeString)
    const year = date.getFullYear()
    const month = formatValue(date.getMonth()+1)
    const day = formatValue(date.getDate())
    const hour = formatValue(date.getHours())
    const minute = formatValue(date.getMinutes())
    const second = formatValue(date.getSeconds())
    const millisecond = formatValue(date.getMilliseconds())
    return `${year}-${month}-${day}T${hour}:${minute}:${second}.${millisecond}`
}

const formatValue = (value) => (value <= 9 ? `0${value}` : `${value}`)

const InputValuesToMinutes = ({Days, Hours, Minutes}) => {
    if (Days === "" && Hours === "" && Minutes === "") {
        return null
    } else {
        const conversedDays = Days ? 1440*parseFloat(Days) : 0
        const conversedHours = Hours ? 60*parseFloat(Hours) : 0
        const conversedMinutes = Minutes ? parseFloat(Minutes) : 0
        const totalMinutes = conversedDays + conversedHours + conversedMinutes
        return totalMinutes
    }
}



const DownArrow = (props) => (
    <MdIcons.MdExpandMore
        size={20}
        style={{
            marginLeft: "-6px",
            color: "#207dea",
        }}
        {...props}
    />
)

const UpArrow = (props) => (
    <MdIcons.MdExpandLess
        size={20}
        style={{
            marginLeft: "-6px",
            color: "#B6B6B6",
        }}
        {...props}
    />
)

const checkboxIcon = (
    <div
        style={{
            display: "flex",
            flex: 1,
            backgroundColor:
                "#207DEA",
            alignSelf:
                "stretch",
        }}
    >
        <MdIcons.MdCheck
            color="#FFFFFF"
            size={14}
        />
    </div>
)

const UniqueValueFilter = (config, setConfig, maxDisplayedRows=4) => {
    const displayedValues = Object.keys(config.values).filter(key => key.includes(config.searchQuery))
    return (
        <>
            <div
                className="ui left icon input"
                style={{
                width: "100%",
                height: "30px",
                background: "#FFFFFF",
                borderRadius: 6,
                border: "1px solid #D9D9D9",
                boxSizing: "border-box",
                marginLeft: "0px",
                marginTop: "3px",
                marginBottom: "8px",
                }}
            >
                <input
                    type="text"
                    value={config.searchQuery}
                    onChange={(event) => setConfig({...config, searchQuery: event.target.value})}
                    placeholder="Search"
                />
                <i aria-hidden="true" class="search icon" />
            </div>

            <VerticalScrollContainer
            style={{
                height: displayedValues.length <= maxDisplayedRows ? "fit-content" : `${31 * maxDisplayedRows}px`,
                overflowY: displayedValues.length <= maxDisplayedRows ? "auto": "scroll"
            }}>
                {displayedValues.map(
                    key => (
                        <Checkbox
                            label={key}
                            icon={checkboxIcon}
                            name="filter-checkboxes"
                            checked={config.values[key]}
                            borderColor="#AEB7C6"
                            style={{
                                cursor: "pointer",
                                userSelect: "none",
                                marginBottom: 10,
                            }}
                            labelStyle={{
                                marginLeft: 10,
                                marginBottom: 10,
                                color: "#293241",
                                fontSize: 14,
                                fontFamily:
                                    "Source Sans Pro",
                            }}
                            onChange={
                                () => setConfig({
                                    ...config,
                                    values: {
                                        ...config.values,
                                        [key]: !config.values[key]
                                    }
                                })
                            }
                        />
                    ))
                }
            </VerticalScrollContainer>
        </>
    )
}

const TimestampFilter = (config, setConfig) => {
    const lastValidInputValue = parseDate(
        config.timestamp ? 
        config.timestamp : 
        (config.type === filterType.timestampBefore ?
            config.max : config.min
        )
    )

    return (<>
        <Columns gap="6px" alignItems="center">
            <div type="text">
                {(config.type === filterType.timestampBefore) ? "Before" : "After"}:
            </div>
            <TimestampInput
                lang={'en'}
                type={"datetime-local"} 
                step={"60"}
                value={lastValidInputValue}
                min={config.minValue}
                max={config.maxValue}
                onChange={(event) => {
                    if (event.target.value === '') { // user click "clear"
                        setConfig({
                            ...config,
                            timestamp: 
                                (config.type === filterType.timestampBefore)
                                ? config.max
                                : config.min
                        })
                        event.target.blur()
                    } else {
                        const newTimestamp = (new Date(event.target.value)).getTime()
                        // if (true) {
                        if (newTimestamp >= config.min && newTimestamp <= config.max) {
                            if (newTimestamp !== config.timestamp) {
                                setConfig({...config, timestamp: newTimestamp})
                            }
                            event.target.blur()
                        } else {
                            event.target.value = lastValidInputValue
                            // ↓ only for testing, we should give user extra reminder for invalid input
                            console.log("invalid time input", event.target.value)
                            event.target.blur()
                        }
                    }
                }}
            />
                            
        </Columns>
    </>)
}

const TimerangeFilter = (config, setConfig) => (
    <>
        <Columns gap="6px" alignItems="center">
            {Object.keys(config.inputs).map(key => 
                (<div style={{
                    display: "flex",
                    flexDirection: "column",
                    margin: "0px 4px"
                }}>
                    <p style={{margin: "0px 4px"}}>{key}:</p>
                    <NumInput 
                        type="text"
                        placeholder={key}
                        value={config.inputs[key]}
                        onChange={(event) => {
                            const inputNumber = Number(event.target.value)
                            if (isNaN(inputNumber) || inputNumber < 0) { // invalid input
                                event.target.value = config.inputs[key]
                                return
                            }
                            const newInputs = {...config.inputs, [key]: event.target.value}
                            
                            // _operator is the displayed one, but only operator will be used for filtering
                            // update timerange with null operator is meaningless, vice versa
                            // so we only update timerange and operator when necessary
                            // const necessaryUpdate = config.operator === null ? ( // previously no opeartor
                            //     config._operator !== null
                            //     && InputValuesToMinutes(config.inputs) !== null 
                            // ) : ( // previously has a filter rule, config.operator != null and config.timerange != null
                            //     InputValuesToMinutes(config.inputs) !== config.timerange
                            //     || config._operator !== config.operator
                            // )

                            if (config.operator !== null) { // necessary Update Timerange and Operator
                                const timerange = InputValuesToMinutes(newInputs)
                                setConfig({...config, inputs: newInputs, timerange})
                            } else {
                                setConfig({...config, inputs: newInputs})
                            }
                                                        
                            // setConfig({...config, inputs: newInputs})

                            // const timerange = InputValuesToMinutes(inputs)
                            // setConfig({...config, inputs, timerange})
                            // updateTableTimeout(1500)
                            // const updateConfig = () => setConfig({
                            //     ...config,
                            //     timerange: newTimerange
                            // })
                            // if (self.timeoutID !== null) {
                            //     clearTimeout(self.timeoutID)
                            // }
                            // self.timeoutID = setTimeout(updateConfig, 3000)
                            // console.log({
                            //     totalMinutes: config.timerange,
                            //     ...self.inputs
                            // })
                            
                        }}
                    />
                </div>)
            )}
        </Columns>

        <div style={{position: "relative", display: "inline-flex", margin: "5px 12px 0px 12px"}}>
            {Object.values(CompareOperator).map(operator => 
                (<>
                    <Checkbox
                        label={operator}
                        icon={checkboxIcon}
                        name="filter-checkboxes"
                        checked={config.operator === operator}
                        onChange={() => {
                            const newOperator = config.operator === operator ? null : operator
                            if (newOperator !== config.operator) {
                                setConfig({...config, operator: newOperator,
                                    timerange: InputValuesToMinutes(config.inputs)})
                            } else {
                                setConfig({...config, operator: newOperator})
                            }
                        }}
                        borderColor="#AEB7C6"
                        style={{
                            cursor: "pointer",
                            userSelect: "none",
                            marginBottom: 10,
                        
                        }}
                        labelStyle={{
                            marginLeft: 10,
                            marginBottom: 10,
                            marginRight: 10,
                            color: "#293241",
                            fontSize: 14,
                            fontFamily:
                                "Source Sans Pro",
                        }}
                        />
                </>)
            )}
            {/* <MenuButton
                disabled={
                    config.operator === null ? ( // previously no filter rule
                        config._operator === null
                        || InputValuesToMinutes(config.inputs) === null 
                    ) : ( // previously has a filter rule, config.operator != null and config.timerange != null
                        InputValuesToMinutes(config.inputs) === config.timerange
                        && config._operator === config.operator
                    )
                }
                onClick={() => {
                    const timerange = InputValuesToMinutes(config.inputs)
                    setConfig({...config, timerange, operator: config._operator})
                }}
                style={{
                    marginLeft: "40px"
                }}
            >Apply</MenuButton> */}
        </div>
    </>
)

const DropdownContent = (config, setConfig, header=false) => { // the style of the same section in menu/header can be different
    switch (config.type) {
        case filterType.uniqueValues:
            return UniqueValueFilter(config, setConfig, header ? 10 : 4) // header dropdown can display more rows
        case filterType.timestampBefore:
            return TimestampFilter(config, setConfig, "Before")
        case filterType.timestampAfter:
            return TimestampFilter(config, setConfig, "After")
        case filterType.timerangeCompare:
            return TimerangeFilter(config, setConfig)
        default:
            return null;
    }
}

const MenuDropdown = (config, setConfig) => (<>
    <ToggleDropdownButton
        style={{
            marginLeft: "4px",
            height: "25px",
            padding: "2px 8px"
        }}
        onClick={() => 
            setConfig({...config, menuDisplay: !config.menuDisplay})
        }
    >
        {config.menuDisplay ? <DownArrow/> : <UpArrow/>}
        <p>{config.name}</p>
    </ToggleDropdownButton>

    <DropdownSection style={{display: config.menuDisplay ? "block" : "none"}}>
        {DropdownContent(config, setConfig)}
    </DropdownSection>

</>)


const HeaderDropdown = ({currentConfig, setCurrentConfig, applyChange, cancelChange, clearSection,
    disableApply, disableCancel, disableClear}) => (
    <div style={{position: "relative"}}>
        <ToggleDropdownButton
            style={{
                border: "0.5px solid #B6B6B6",
                boxShadow: "0px 0px 3px rgba(99, 191, 244, 0.15)"
            }}
            onClick={(event) => {
                // console.log("onClick: ToggleDropdownButton")
                setCurrentConfig({...currentConfig, headerDisplay: !currentConfig.headerDisplay})

                if (!currentConfig.headerDisplay) {
                    // do not use useRef because this component is rendered inside loop
                    // const toggleButton = event.currentTarget // ToggleDropdownButton
                    // const dropdownMenu = event.currentTarget.parentElement.children[1] // FilterModalContainer
                    // const clickOutsideClose = (_event) => {
                    //     if (
                    //         toggleButton.contains(_event.target) // when click the toggle button, also remove the listener
                    //         || dropdownMenu.style.display === "none" // when the menu has been close by clicking "reset"
                    //     ) {
                    //         document.removeEventListener("mousedown", clickOutsideClose, true)
                    //     } else if ( // regular case
                    //         (!toggleButton.contains(_event.target))
                    //         && (!dropdownMenu.contains(_event.target))
                    //     ) {
                    //         console.log("Listener: clickOutsideClose", config)
                    //         setConfig({...config, headerDisplay: false})
                    //         document.removeEventListener("mousedown", clickOutsideClose, true)
                    //     }
                    //     // else, click inside the FilterModalContainer
                    // }
                    // // Here we have to set useCaptue=true, otherwise the listener will be triggered immediately!
                    // document.addEventListener("mousedown", clickOutsideClose, true)
                }                
            }}
        >
            {currentConfig.headerDisplay ? <DownArrow/> : <UpArrow/>}
            <p>{currentConfig.name}</p>
        </ToggleDropdownButton>

        <DropdownContainer style={{
            display: currentConfig.headerDisplay ? "flex" : "none",
            zIndex: 100,
            flexDirection: "column",
            top: "40px"
        }}>
            <DropdownSection>
                {DropdownContent(currentConfig, setCurrentConfig, true)}
            </DropdownSection>
            <Columns>
                <FooterButton
                    disabled={disableApply}
                    onClick={applyChange}
                >Apply</FooterButton>
                
                <FooterButton
                    disabled={disableCancel}
                    onClick={cancelChange}
                >Cancel Change</FooterButton>
                
                <FooterButton
                    disabled={disableClear}
                    onClick={clearSection}
                >Clear</FooterButton>
            </Columns>
        </DropdownContainer>
    </div>
)

export function Filter ({configs:_configs=[]}) {
    const { useLocal, getRows, filterRules, setFilterRules } = useContext(TableContext)
    const [localData, setLocalData] = useState([]) // localData will always be [] if using remote endpoint

    useEffect(() => {
        if (useLocal) {
            getRows({}).then(res => setLocalData(res.data))
        }
    }, [useLocal, getRows])

    const [blankConfigs, setBlankConfigs] = useState({})
    const [currentConfigs, setCurrentConfigs] = useState({})
    const [previousConfigs, setPreviousConfigs] = useState({})

    useEffect(() => { // use remote endpoint
        if (typeof _configs === 'function') {
            setBlankConfigs(_configs) // call the endpoint directly
            setCurrentConfigs(_configs)
            setPreviousConfigs(_configs)
        }
    }, [_configs])

    useEffect(() => { // use local data
        if (_configs.length > 0 && localData.length > 0) {
            const calculatedConfigs = _configs.map((config, index) => {
                switch (config.type) {
                    case filterType.uniqueValues:
                        // console.log(filterType.uniqueValues, col)
                        // const uniValues = [8000, 8011, 8012, 8013, 8014, 8020].reduce((a, v) => ({ ...a, [v]: false}), {}) // for testing
                        const uniValues = [...new Set(
                                localData.map(row=>row[config.attr])
                                // [null, undefined]
                            )]
                            .filter(v => v != null) // remove undefined and null
                            .reduce((a, v) => ({ ...a, [v]: false}), {})
                        return {...config, index, values: uniValues, searchQuery: ""}
                    case filterType.timestampBefore:
                    case filterType.timestampAfter:
                        // console.log(filterType.timestampBefore, col)
                        const dtype = typeof(localData[0][config.attr])
                        if (["number", "string"].includes(dtype)) {
                            const allTimestamp = (dtype === "number")
                                ? localData.map(row => row[config.attr])
                                : localData.map(row => datetimeToTimestamp(row[config.attr]))
                            const min = Math.min(...allTimestamp)
                            const max = Math.max(...allTimestamp)
                            const minValue = parseDate(min)
                            const maxValue = parseDate(max)
                            return { ...config, index, min, max, minValue, maxValue,
                                timestamp: config.type === filterType.timestampBefore ? max : min}
                        } else {
                            return null
                        }
                        
                    case filterType.timerangeCompare:
                        // console.log(filterType.timerangeCompare, col)
                        return { ...config, index,
                            timerange: null, // unit: minute
                            operator: null, // Greater/Less/Equal
                            // _operator: null, // temp attibute for frontend display
                            inputs: {Days: "", Hours: "", Minutes: ""}
                        }
                    default:
                        // console.log("unknown filterType", col)
                        return null
                }
            })
            .filter(c => c !== null)
            .reduce((a, config) => ({
                ...a,
                [config.index]: {
                    ...config,
                    menuDisplay: true,
                    headerDisplay: false
                }
            }), {})

            setBlankConfigs(calculatedConfigs)
            setCurrentConfigs(calculatedConfigs)
            setPreviousConfigs(calculatedConfigs)
            
        }
    }, [_configs, localData])

    const [newFilterRules, setNewFilterRules] = useState([])
    
    useEffect(() => {
        const _newFilterRules = Object.values(currentConfigs).map(config => {
            switch (config.type) {
                case filterType.uniqueValues:
                    const selectedValues = Object.keys(config.values).filter(key => config.values[key])
                    return selectedValues.length > 0
                        ? {name: config.name, index: config.index,
                            type: filterType.uniqueValues, attr: config.attr, values: selectedValues}
                        : null
                case filterType.timestampBefore:
                    if (config.timestamp === config.max) {
                        return null
                    } else {
                        return {name: config.name, index: config.index,
                            type: filterType.timestampBefore, attr: config.attr, timestamp: config.timestamp}
                    }
                case filterType.timestampAfter:
                    if (config.timestamp === config.min) {
                        return null
                    } else {
                        return {name: config.name, index: config.index,
                            type: filterType.timestampAfter, attr: config.attr, timestamp: config.timestamp}
                    }
                case filterType.timerangeCompare:
                    if (config.timerange === null || config.operator === null) {
                        return null // if the operator doesn't change, we don't need to update 
                    } else {
                        return {name: config.name, index: config.index,
                            type: filterType.timerangeCompare, attr: config.attr,
                            operator: config.operator, timerange: config.timerange}
                    }
                default:
                    return null
            }
        }).filter(c => c !== null)
        
        setNewFilterRules(_newFilterRules)

        // if (JSON.stringify(_newFilterRules) !== JSON.stringify(filterRules)) {
        //     setFilterRules(_newFilterRules)
        // }
    }, [currentConfigs])
    
    const [mainMenuDisplay, setMainMenuDisplay] = useState(false)
    const filterButtonRef = useRef()
    const mainMenuRef = useRef()

    const activeSections = Object.values(currentConfigs).filter(config => config.headerDisplay)
    const activeSectionIndex = activeSections.length === 1 ? activeSections[0].index : null

    useEffect(() => {
        if (activeSectionIndex !== null) {
            const displayedHeaderSections = [...filterButtonRef.current.parentElement.children].slice(1)
            const displayedIndex = filterRules
                .map((rule, index) => ({active: rule.index === activeSectionIndex, index}))
                .filter(section => section.active)
                [0].index

            const activeHeaderDropdownSection = displayedHeaderSections[displayedIndex]
            const toggleButton = activeHeaderDropdownSection.children[0]
            const dropdownMenu = activeHeaderDropdownSection.children[1]
            
            // console.log("add listener", activeHeaderDropdownSection)

            const clickOutsideClose = (_event) => {
                if ( (!toggleButton.contains(_event.target))
                    && (!dropdownMenu.contains(_event.target))
                ) {
                    // console.log("click outside", )
                    setCurrentConfigs({
                        ...currentConfigs,
                        [activeSectionIndex]: {
                            ...currentConfigs[activeSectionIndex],
                            headerDisplay: false
                        }
                    })
                }
            }

            document.addEventListener("mousedown", clickOutsideClose, false)
            return () => {
                // console.log("remove listener: clickOutsideClose")
                document.removeEventListener("mousedown", clickOutsideClose, false)
            }
        }
    }, [activeSectionIndex, currentConfigs, filterRules])

    useEffect(() => {
        if (mainMenuDisplay) {
            const clickOutsideClose = (event) => {
                if (
                    (!filterButtonRef.current.contains(event.target))
                    && (!mainMenuRef.current.contains(event.target))
                ) {
                    setMainMenuDisplay(false) // Then the EventListener will be removed
                }
            }

            document.addEventListener("mousedown", clickOutsideClose, false)
            return () => document.removeEventListener("mousedown", clickOutsideClose, false)
        }
    }, [mainMenuDisplay])
    
    // console.log(filterRules)
    const activeFilterRuleIndexes = filterRules.map(rule => rule.index)
    const newActiveFilterRuleIndexes = newFilterRules.map(rule => rule.index)

    const createHeaderDropdown = useCallback((currentConfig) => {

        const setCurrentConfig = (newConfig) => {
            setCurrentConfigs({...currentConfigs, [currentConfig.index]: newConfig})
        }

        const applyChange = () => {
            setCurrentConfigs({...currentConfigs, [currentConfig.index]: {...currentConfig, headerDisplay: false}})
            setPreviousConfigs({...previousConfigs, [currentConfig.index]: {...currentConfig, headerDisplay: false}})
            if (newActiveFilterRuleIndexes.includes(currentConfig.index)) {
                // setPageIndex(1)
                setFilterRules(
                    filterRules.map(rule => {
                        if (rule.index === currentConfig.index) {
                            return newFilterRules[currentConfig.index]
                        } else {
                            return rule
                        }
                    })
                )
            } else {
                // setPageIndex(1)
                setFilterRules(
                    filterRules.filter(rule => rule.index !== currentConfig.index)
                )
            }
        }

        const cancelChange = () => {
            setCurrentConfigs({...currentConfigs, [currentConfig.index]: {...previousConfigs[currentConfig.index], headerDisplay: true}})
        }

        const clearSection = () => {
            setCurrentConfigs({...currentConfigs, [currentConfig.index]: {...blankConfigs[currentConfig.index], headerDisplay: false}})
            setPreviousConfigs({...previousConfigs, [currentConfig.index]: {...blankConfigs[currentConfig.index], headerDisplay: false}})
            if (activeFilterRuleIndexes.includes(currentConfig.index)) {
                // setPageIndex(1)
                setFilterRules(
                    filterRules.filter(rule => rule.index !== currentConfig.index)
                )
            }
            // console.log(previousConfigs[currentConfig.index], {...previousConfigs[currentConfig.index], headerDisplay: true})
        }
        // console.log(JSON.stringify(previousConfigs[currentConfig.index]), JSON.stringify(currentConfig))
        return HeaderDropdown({
            currentConfig,
            setCurrentConfig,
            applyChange,
            cancelChange,
            clearSection,
            disableApply: JSON.stringify({...previousConfigs[currentConfig.index], "headerDisplay": true}) === JSON.stringify(currentConfig),
            disableCancel: JSON.stringify({...previousConfigs[currentConfig.index], "headerDisplay": true}) === JSON.stringify(currentConfig),
            disableClear: JSON.stringify({...blankConfigs[currentConfig.index], "headerDisplay": true}) === JSON.stringify(currentConfig)
        })
    }, [
        activeFilterRuleIndexes,
        blankConfigs,
        currentConfigs,
        filterRules,
        newActiveFilterRuleIndexes,
        newFilterRules,
        previousConfigs,
        setFilterRules,
        // setPageIndex
    ])

    return  Object.keys(currentConfigs).length === 0 ? null : <>
        <FilterHeader>
            <MenuToggleButton
                ref={filterButtonRef}
                onClick={() => setMainMenuDisplay(prev => !prev)}
            >
                <div>
                    <MdIcons.MdFilterListAlt style={{color:"#207dea", marginBottom: "2px", marginRight: "2px", fontSize: "1.2rem"}}/>
                </div>
                Filter
            </MenuToggleButton>

            {Object.values(currentConfigs)
                .filter(config => activeFilterRuleIndexes.includes(config.index))
                .map(createHeaderDropdown
                    // config => HeaderDropdown(
                    //     config,
                    //     (newConfig) => { // setConfig
                    //         setCurrentConfigs({...currentConfigs, [config.index]: newConfig})
                    //     },

                    //     () => { // applyChange
                    //         setPreviousConfigs({...previousConfigs, [config.index]: currentConfigs})
                    //         if (newActiveFilterRuleIndexes.includes(config.index)) {
                    //             setFilterRules(
                    //                 filterRules.map(rule => {
                    //                     if (rule.index === config.index) {
                    //                         return newFilterRules[config.index]
                    //                     } else {
                    //                         return rule
                    //                     }
                    //                 })
                    //             )
                    //         }
                    //     },

                    //     () => { // cancelChange
                    //         setCurrentConfigs({...currentConfigs, [config.index]: previousConfigs[config.index]})
                    //     },

                    //     () => { // clearSection
                    //         setCurrentConfigs({...currentConfigs, [config.index]: blankConfigs[config.index]})
                    //         setPreviousConfigs({...previousConfigs, [config.index]: blankConfigs[config.index]})
                    //         if (activeFilterRuleIndexes.includes(config.index)) {
                    //             setFilterRules(
                    //                 filterRules.filter(rule => rule.index !== config.index)
                    //             )
                    //         }
                    //     }

                    // )
                )
            }
        </FilterHeader>

        <DropdownContainer
            ref={mainMenuRef}
            style={{
                display: mainMenuDisplay ? "flex" : "none",
                zIndex: 100,
                flexDirection: "column",
                width: "300px",
                marginTop: "-10px"
            }}
        >
            {Object.keys(currentConfigs).map(
                index => MenuDropdown(
                    currentConfigs[index],
                    (newConfig) => setCurrentConfigs(prev => ({...prev, [index]: newConfig}))
                )
            )}

            <Columns>
                <FooterButton
                    disabled={JSON.stringify(newFilterRules) === JSON.stringify(filterRules)}
                    onClick={() => {
                        setPreviousConfigs(currentConfigs)
                        setFilterRules(newFilterRules)
                        // setPageIndex(1)
                    }}
                >Apply</FooterButton>
                
                <FooterButton
                    disabled={JSON.stringify(previousConfigs) === JSON.stringify(currentConfigs)}
                    onClick={() => {
                        setCurrentConfigs(previousConfigs)
                    }}
                >Cancel Change</FooterButton>
                
                <FooterButton
                    disabled={JSON.stringify(blankConfigs) === JSON.stringify(currentConfigs)}
                    onClick={() => {
                        setCurrentConfigs(blankConfigs)
                        setPreviousConfigs(blankConfigs)
                        // setNewFilterRules([]) // useEffect will automatically do it
                        setFilterRules([])
                        // setPageIndex(1)
                    }}
                >Clear All</FooterButton>
            </Columns>
        </DropdownContainer>
    </>
}

const FilterHeader = styled.div`
    width: fit-content;
	display: flex;
	flex-direction: row;
	align-items: center;
	padding-bottom: 15px;
	position: relative;
`;

const MenuToggleButton = styled.button`
  		width: 90.3px;
		height: 34px;
		display: inline-flex;
		position: relative;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		padding: 0px 0px;
		border-radius: 6px;
		box-shadow: 0 0 3px 0 rgba(99, 191, 244, 0.15);
		border: solid 0.5px #B6B6B6;
		background-color: #fff;
		font-family: 'Source Sans Pro';
		font-weight: 600;
		font-size: 16px;
		color: #293241;
		cursor: pointer;
		margin-bottom: 0px;
        .div {
            font-size:1.3rem;
        }
	`;

const DropdownSection = styled.div`
	background: #f8f8f8;
	height: auto;
    margin: 3px;
    border-radius: 5px;
    padding: 4px;
`;


const VerticalScrollContainer = styled.div`
	height: auto;
	max-height: 615px;
	scrollbar-width: thin;
	::-webkit-scrollbar {
		display: block;
		width: 5px;
		color: #313a4a;
	}
	::-webkit-scrollbar-track {
		background: #bec4cf;
		width: 5px;
		border-radius: 2px;
	}
`;


const ToggleDropdownButton = styled.div`
    display: flex;
    flex-direction: row;
	align-items: center;
	padding: 5px 16px;
	width: fit-content;
	max-width: 210px;
	height: 34px;
	background: #FFFFFF;
	border-radius: 6px;
	white-space: nowrap;
	color: #293241;
	margin-left: 12px;
	cursor: pointer;
	p {
		font-family: 'Source Sans Pro';
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		overflow: hidden;
		max-width: 20ch;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
`;

const Columns = styled.div`
    display: flex;
	flex-direction: row;
	width: 100%;
    height: ${props => props.height ?? 'auto'};
    column-gap: ${props => props.spacing ?? props.gap ?? '0'};
    padding: ${props => props.spacing ?? '0'};
    justify-content: ${props => props.justifyContent ?? 'center'};
    align-items: ${props => props.alignItems ?? 'normal'};

    flex-grow: ${props => props.grow ?? '0'};
    flex-shrink: ${props => props.shrink ?? '1'};
    flex-basis: ${props => props.basis ?? 'auto'};
`

const TimestampInput = styled.input`
    margin: 2px 0px 2px 0px;
	min-width: 0;
	width: 0;
	flex: 0 1 100%;
	background: #ffffff;
	border: 2px solid #cccccc;
	border-radius: 5px;
	height: 22px;
	padding: 16px;
	::placeholder {
		font-family: "Source Sans Pro";
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 150%;
		color: "gray";
	}
	:disabled {
		background-color: #DEDEDE;
	}
	:read-only {
		background-color: #DEDEDE;
	}
`;


const NumInput = styled.input`
	background: #ffffff;
	border: 2px solid #cccccc;
	border-radius: 5px;
	height: 35px;
    width: 80px;
    padding: 2px 12px;
	::placeholder {
		font-family: "Source Sans Pro";
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 150%;
		color: "gray";
	}
`;

const FooterButton = styled.button`
    margin: 0px 6px;
    display: flex;
    align-self: flex-end;
    align-items: center;
    padding: 2px 12px;
    width: fit-content;
    max-width: 210px;
    height: 30px;
    background: #FFFFFF;
    border: 0.5px solid #B6B6B6;
    box-shadow: 0px 0px 3px rgba(99, 191, 244, 0.15);
    border-radius: 6px;
    white-space: nowrap;
    color: #293241;
    cursor: pointer;
    :disabled {
        opacity: 0.5;
		background-color: #DEDEDE;
        cursor: auto;
	}
`;

export const DropdownContainer = styled.div`
	width: 300px;
	height: auto;
	background: #ffffff;
	border: 0.5px solid #b6b6b6;
	box-sizing: border-box;
	box-shadow: 0px 0px 3px rgba(99, 191, 244, 0.15);
	border-radius: 6px;
    padding: 6px 2px;
	position: absolute;
	h2 {
		font-family: "Source Sans Pro";
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 150%;
		color: #000000;
		display: inline;
	}
`;