import { useState, useRef, useEffect, useCallback } from 'react'
import { ModalController } from "../../../../../Controllers/ModalController"
import styled from "styled-components";
import * as MdIcons from "react-icons/md";
import { CreatePipelineTableColumns, CreatePipelineTableRows } from './CreatePipelineModalTableData';
import { MainTable } from '../../../../../Components/DataTable/Table';
import { useStudiesProvider } from '../../../../../Providers/StudiesProvider';
import { useEndpointProvider } from '../../../../../Providers/EndpointProvider';
import { LINKS } from '../../../../../Constants/BackendLinks';
import { CreatePipelineModal } from '../CreatePipelineModal/CreatePipelineModal';
// import { CreatePipelineModalNew } from '../CreatePipelineModal/CreatePipelineModalNew';
import ModalFooterButton, { buttonColor } from '../../../../../Components/ModalFooterButton/ModalFooterButton';
import { ButtonHeader } from '../../../../../Constants/StyledComponents';
import TrashButton from '../../../../../Components/TrashButton/TrashButton';

export const CreatePipelineModalRedesign = ({ openRef }) => {

    const contentRef = useRef()
    const closeButtonRef = useRef()

	const studiesProvider = useStudiesProvider()
	const endpointProvider = useEndpointProvider()

    const [stepSubheaderValues, setStepSubheaderValues] = useState([]);
    const [stepSubheaderOrder, setStepSubheaderOrder] = useState([]);
    const [newSubheaderVal, setNewSubheaderVal] = useState(0)
    const [metricSearchQuery, setMetricSearchQuery] = useState('')

	const [analysisSteps, setAnalysisSteps] = useState([])
	const [analysisID, setAnalysisID] = useState('')
	const [currentStep, setCurrentStep] = useState('')

	async function getSteps() {
		let categoryHeaders = analysisSteps
		console.log('headers', categoryHeaders)

		setStepSubheaderOrder(categoryHeaders)

		let categoryHeadersValues = Object.assign({},
			...categoryHeaders.map((header) => {
				return { [header]: false}
			}))
		setStepSubheaderValues({...categoryHeadersValues, ["Step 1"]: true})
	}

	useEffect(() => {
		if (analysisSteps.length > 0) {
			let categoryHeaders = []

			for (const step in analysisSteps) {
				categoryHeaders.push(analysisSteps[step]['name'])
			}

			console.log('headers', categoryHeaders)
			setCurrentStep(categoryHeaders[0])
			setStepSubheaderOrder(categoryHeaders)

			let categoryHeadersValues = Object.assign({},
				...categoryHeaders.map((header) => {
					return { [header]: false}
				}))
			setStepSubheaderValues({...categoryHeadersValues, ["Step 1"]: true})
		}
	}, [analysisSteps, openRef])

    // useEffect(() => {
	// 	let categoryHeaders = ["Step 1", "Step 2", "Step 3", "Step 4"]
	// 	setStepSubheaderOrder(categoryHeaders)

	// 	let categoryHeadersValues = Object.assign({},
	// 		...categoryHeaders.map((header) => {
	// 			return { [header]: false}
	// 		}))
	// 	setStepSubheaderValues({...categoryHeadersValues, ["Step 1"]: true})

	// }, [])

    async function updateStepSubheaderValues(step, value) {
        if (stepSubheaderValues[step]) {
            return;
        }

		let body = {
			analysis: analysisID,
			name: step,
			description: value
		}

		await endpointProvider.post(LINKS.PIPELINES.CREATE.CREATE_ANALYSIS_STEP, body)

        let newStepSubheaderValues = Object.assign(
            {},
            ...Object.keys(stepSubheaderValues).map((step) => {
                return { [step]: false };
            })
        );
        setStepSubheaderValues({ ...newStepSubheaderValues, [step]: value });
		console.log(stepSubheaderValues)

        if (!stepSubheaderOrder.includes(step)) {
            setStepSubheaderOrder(
                Array.prototype.concat(stepSubheaderOrder, step)
            );

        }
    }

    async function deleteStepSubheaderValues(step) {
        if (stepSubheaderOrder.length === 1) {
            alert("Please create a new step before deleting this one.");
            return;
        }
        let indx = stepSubheaderOrder.indexOf(step)

		let body = {
			analysis: analysisID,
			step: step
		}

		endpointProvider.post(LINKS.PIPELINES.CREATE.DELETE_ANALYSIS_STEP, body)

        setStepSubheaderOrder(
            Array.prototype.concat(
                stepSubheaderOrder.slice(0, indx),
                stepSubheaderOrder.slice(indx + 1)
            )
        )

        setStepSubheaderValues(
            Object.assign(
                {},
                ...Object.keys(stepSubheaderValues).map((step_, i) => {
					console.log(step_)
					console.log(step)
					console.log(step_ === step)
                    if (step_ !== step) {
                        return {
                            [step_]:
                            (i === 0 &&
                                indx !== 0 &&
                                stepSubheaderValues[stepSubheaderOrder[indx]]
                            ) ||
                            (i === 1 && indx === 0 && stepSubheaderValues[stepSubheaderOrder[indx]]) ||
                            stepSubheaderValues[stepSubheaderOrder[i]]
                        }
                    }
                })
            )
        )

    }

    function checkCreatePipelineSearch(val) {
        if (metricSearchQuery === "") {
            return val
        } else if (val.metric.toLowerCase().includes(metricSearchQuery.toLowerCase())) {
            return val
        } else if (val.created_by.toLowerCase().includes(metricSearchQuery.toLowerCase())) {
            return val
        } else if (val.last_modified.toLowerCase().includes(metricSearchQuery.toLowerCase())) {
            return val
        } else if (val.versions.toLowerCase().includes(metricSearchQuery.toLowerCase())) {
            return val
        }
    }

    const enterDefault = useCallback((event) => {
		if (event.keyCode === 13) {
			event.preventDefault();
		}
	}, []);

	useEffect(() => {
		document.addEventListener("keydown", enterDefault);
		return () => {
			document.removeEventListener("keydown", enterDefault);
		};
	}, [enterDefault]);

	const [analysisName, setAnalysisName] = useState('')
	const [userName, setUserName] = useState('')
	const [version, setVersion] = useState('')
	const [isPublic, setIsPublic] = useState(false)

	const [metricName, setMetricName] = useState('')
	const [metricDescription, setMetricDescription] = useState('')

	async function createMetrics(analysis_step, metrics) {
		let body = {
			analysis_step: analysis_step,
			metrics: metrics
		}
		console.log('body', body)

		return endpointProvider.post(LINKS.PIPELINES.CREATE.CREATE_METRICS, body)
	}

	const [screen, setScreen] = useState('small')

	function renderCreateMetricModal() {
		document.getElementById('CreateMetricModal').style.display = 'block'
		document.getElementById('CreatePipelinePageContent').style.display = 'none'
		setScreen('small')
	}

	function handleCancel() {
		document.getElementById('CreateMetricModal').style.display = 'none'
		document.getElementById('CreatePipelinePageContent').style.display = 'block'
		setScreen('fullscreen')
	}

	async function getCurrentUser() {
		let user = await endpointProvider.post(LINKS.ACCOUNT.GET_CURRENT_USER)
		setUserName(`${user.first_name} ${user.last_name}`)
	}

	useEffect(() => {
		getCurrentUser()
	}, [])

	// useEffect(() => {
	// 	console.log(analysisName)
	// 	console.log(version)
	// }, [analysisName, version])

	async function createAnalysis() {
		let body = {
			study_id: studiesProvider.selectedStudyId,
			analysis_name: analysisName,
			user_name: userName,
			version: version,
			public: isPublic
		}

		// body = {
		// 	study_id: studiesProvider.selectedStudyId,
		// 	analysis_name: 'Test Analysis 2',
		// 	user_name: 'Something',
		// 	version: '4.0',
		// 	public: false
		// }
		console.log('body', body)

		let new_analysis = await endpointProvider.post(LINKS.PIPELINES.CREATE.CREATE_ANALYSIS, body)
		console.log('newAnalysis', new_analysis)
		setAnalysisID(new_analysis)

		body = {
			analysis_id: new_analysis
		}

		let analysis_Steps = await endpointProvider.post(LINKS.PIPELINES.CREATE.GET_ANALYSIS_STEPS, body)
		setAnalysisSteps(analysis_Steps)
		setNewSubheaderVal(analysis_Steps.length + 1)
		console.log(analysisSteps)
		console.log('analysis_steps', analysis_Steps)

		setScreen('fullscreen')
		document.getElementById('CreateAnalysisModal').style.display = 'none'
		document.getElementById('CreatePipelinePageContent').style.display = 'block'

	}

	let analysisCreateRef = useRef()

    return (<>
        <div ref={contentRef} style={{display: "none"}}>

			{/* <CreatePipelineModalNew 
				openRef={analysisCreateRef}
			/> */}

			<CreateAnalysisModal id='CreateAnalysisModal'>
				<h3>Create Analysis</h3>
				<hr
					style={{
						border: "1px solid #B3B3B3",
						width: "564px",
					}}
				/>

				<h1 style={{marginLeft: '32px'}} >Analysis Name</h1>

				
				<form>
					<input id='AnalysisName' type="text"
					onChange={(e) => setAnalysisName((v) => (e.target.validity.valid ? e.target.value : v))} placeholder="" style={{ 
						background: "#FFFFFF", border: "2px solid #CCCCCC", borderRadius: "5px", width: '505px', height: '56px', marginLeft: '32px' }} />

					<h1 style={{marginLeft: '32px'}} >Version</h1>
					
					<input id='Version' type="number" pattern="[0-9]*"
					onChange={(e) => setVersion((v) => (e.target.validity.valid ? e.target.value : v))} placeholder="" style={{ 
						background: "#FFFFFF", border: "2px solid #CCCCCC", borderRadius: "5px", width: '505px', height: '56px', marginLeft: '32px' }} />

					<h1 style={{marginLeft: '32px'}} >Public?</h1>
					<input style={{marginLeft: '25px', height: '16px', width: '32px'}} type="checkbox" onChange={() => setIsPublic(!isPublic)} ></input>
				</form>
				<div
					style={{
						// paddingLeft: "35px",
						paddingBottom: "15px",
						paddingTop: "160px",
						display: 'flex',
						justifyContent: 'center'
					}}
				>
					<ModalFooterButton
						buttonText="Continue"
						color={buttonColor.blueFill}
						buttonStyle={{width: "114px"}}
						ref={analysisCreateRef}
						onClickFxn={() => {
							createAnalysis()
						}} 
					/>

					</div>
			</CreateAnalysisModal>

			<CreateMetricModal id='CreateMetricModal'>
				<h3>Create Metric</h3>
				<hr
					style={{
						border: "1px solid #B3B3B3",
						width: "564px",
					}}
				/>

				<h1 style={{marginLeft: '32px'}} >Name</h1>
				
				<input id='MetricName' type="text"
				onChange={(e) => setMetricName((v) => (e.target.validity.valid ? e.target.value : v))} placeholder="" style={{ 
					background: "#FFFFFF", border: "2px solid #CCCCCC", borderRadius: "5px", width: '505px', height: '56px', marginLeft: '32px' }} />

				<h1 style={{marginLeft: '32px'}} >Description</h1>
				
				<textarea id='MetricDescription' type="text"
				onChange={(e) => setMetricDescription((v) => (e.target.validity.valid ? e.target.value : v))} placeholder="" style={{ 
					background: "#FFFFFF", border: "2px solid #CCCCCC", borderRadius: "5px", width: '505px', height: '147px', marginLeft: '32px' }} />

				<div
					style={{
						paddingLeft: "35px",
						paddingBottom: "15px",
						paddingTop: "120px"
					}}
				>
					<ModalFooterButton
						buttonText="Continue"
						color={buttonColor.blueFill}
						buttonStyle={{width: "114px"}}
						onClickFxn={() => {createMetrics(currentStep, metricName); handleCancel()}}
					/>

					<ModalFooterButton
						buttonText="Cancel"
						color={buttonColor.blueOutline}
						buttonStyle={{width: "114px"}}
						onClickFxn={() => {handleCancel()}}
					/>

					<ModalFooterButton
						// TODO: disable if the email has been changed
						// disabled={inviteUserDisabled}
						buttonText="Delete"
						color={buttonColor.redFill}
						buttonStyle={{
							float: "right",
							marginRight: "25px",
							width: "114px"
						}}
					/>

					</div>
			</CreateMetricModal>

            <div id="CreatePipelinePageContent"
                style={{
                    width: "100%",
                    height: "100%",
                    background: "#F8F8F8",
                    display: "none",
                    paddingLeft: "35px",
                    paddingRight: "35px"}}>
                <div id="CreatePipelineSubheader" style={{background: "#FFFFFF", position: "sticky", paddingTop: "30px",  width: "106%", display: "inline-flex", marginLeft: "-35px"}}>
                    <HorizontalScrollBar style={{
                        width: "100%",
                        display: "inline-flex",
                        overflowX: "auto",
                        overflowY: "hidden",
                        marginLeft: "35px",
                        whiteSpace: "nowrap"
                    }}>
                        {stepSubheaderOrder.map((step, i) => {
                            return (
                                <StepTab style={{background: "none"}}>
                                    <StepButton id={`StepButton_${step}`} colorchange={stepSubheaderValues[step]} underlined={stepSubheaderValues[step]} style={{fontSize: "16px", fontStyle: "normal", fontWeight: "600"}}
                                    onClick={(e) => {
                                        switch (e.detail) {
                                            case 1:
												setCurrentStep(step)
												console.log(step)
                                                updateStepSubheaderValues(step, !stepSubheaderValues[step]);
                                                break;
                                            default:
                                                return;
                                        }
                                    }}>
                                        <p>{step}</p>
                                    </StepButton>

                                    <button onClick={() => {deleteStepSubheaderValues(step)}} style={{color: "#6C757C", paddingLeft: "0px", cursor: "pointer", border: "none", background: "none", marginTop: "-8px"}}>
                                        <MdIcons.MdClose/>
                                    </button>
                                </StepTab>
                            )
                        })}
                    </HorizontalScrollBar>
                    <button
                        style={{
                            color: "#0097ee",
                            width: "20px",
                            marginRight: "35px",
                            fontSize: 24,
                            display: "flex",
                            background: "none",
                            border: "none",
                            cursor: "pointer",
                            marginTop: "-5px"
                        }}
                        onClick={(e) => {
                            updateStepSubheaderValues(
                                `Step ${newSubheaderVal}`,
                                !stepSubheaderValues[
                                `Step ${newSubheaderVal}`
                                ]
                            );
                            setNewSubheaderVal(newSubheaderVal + 1);
                        }}
                    >
                        +
                    </button>
                    <button ref={closeButtonRef} style={{right: "35px", marginTop: "-20px", position: "absolute", cursor: "pointer", border: "none", background: "none"}}>
                        <MdIcons.MdCloseFullscreen size={18} />
                    </button>
                </div> {/* end CreatePipelineSubheader */}

                <ButtonHeader style={{marginTop: "20px", paddingBottom: "10px", paddingTop: "0px"}}>
                    <CreateMetricButton onClick={() => { renderCreateMetricModal() }} >
                        <MdIcons.MdModeEdit size={18} style={{marginTop: "-3px"}}/>{" "}Create Metric
                    </CreateMetricButton>

                    <TrashButton disabled={true} buttonStyle={{flex: "0.1", marginRight: "22%"}}/>

                    <Searchbar className="ui left icon input">
                        <input type="text" onChange={event => {setMetricSearchQuery(event.target.value)}} placeholder="Search"/> <i aria-hidden="true" class="search icon" />
                        <button onClick={() => {setMetricSearchQuery(metricSearchQuery)}}>Search</button>
                    </Searchbar>

                </ButtonHeader>
                <div>
                    <MainTable
                        columns={CreatePipelineTableColumns}
                        data={CreatePipelineTableRows}
                        selectableRows
                        pagination
                        onSelectedRowsChange={undefined}
                        clearSelectedRows={undefined} />
                </div>


             </div>

        </div>

        <ModalController 
			modalStyle={screen} 
			contentRef={contentRef} 
			openRef={openRef} 
			closeRef={closeButtonRef}
        />
    </>)
}

const StepTab = styled.div`
	width: auto;
	height: 34px;
	background: none;
	border: solid 0.5px #ffffff;
	font-size: 16px;
	display: inline-flex;
	margin-right: 20px;
	/* padding-left: -35px; */
	
`;

const StepButton = styled.button`
    z-index: 1000;
	width: auto;
	height: 34px;
    background: none;
	border: solid 0.5px #ffffff;
	font-size: 16px;
	display: inline-flex;
	margin-right: 8px;
	color: ${({colorchange}) => (colorchange ? "#0097ee" : "#6C757C")};
	p {
		padding-bottom: 6px;
		border-bottom: ${({underlined}) => underlined ? "4px solid #0097ee" : "none"};
	}
`;

const HorizontalScrollBar = styled.div`
	::-webkit-scrollbar {
		display: block;
		height: 4px;
		color: #313a4a;
		border-radius: 2px;
	}
	::-webkit-scrollbar-track {
		background: #bec4cf;
		height: 4px;
		border-radius: 2px;
	}
`;

const CreateMetricButton = styled.button`
	width: auto;
	height: 34px;
	background: #207dea;
	border-radius: 6px;
	font-family: 'Source Sans Pro';
	font-style: normal;
	font-weight: 600;
	line-height: 150%;
	color: #FFFFFF;
	border: none;
	padding: 4px 8px;
	margin-right: 8px;
	white-space: nowrap;
	flex: 0.25;
`;

const Searchbar = styled.div`
	width: 40%;
	height: 34px;
	background: #FFFFFF;
	border: 0.5px solid #d9d9d9;
	box-sizing: border-box;
	box-shadow: 0px 0px 3px rgba(99, 191, 244, 0.15);
	border-radius: 6px;
	overflow-y: hidden;
	overflow-x: hidden;
	flex: 2;
	button {
		width: 80px;
		height: 34px;
		background: #207dea;
		border-radius: 0px 6px 6px 0px;
		border: none;
		font-family: 'Source Sans Pro';
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 150%;
		color: #FFFFFF;
		cursor: pointer;
		padding-bottom: 3.5px;
	}
`;

const CreateMetricModal = styled.div`
	width: 564px;
	height: auto;
	background: #ffffff;
	border-radius: 6px;
	z-index: 100;
	display: none;
	h3 {
		font-family: "Montserrat";
		font-style: normal;
		font-weight: 700;
		font-size: 20px;
		line-height: 150%;
		color: #293241;
		text-align: center;
		margin-top: 8px;
		margin-bottom: -4px;
	}
	h2 {
		font-family: "Source Sans Pro";
		font-style: normal;
		font-weight: 700;
		font-size: 21px;
		line-height: 150%;
		color: #293241;
		margin-top: 5px;
	}
	h1 {
		font-family: "Source Sans Pro";
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 150%;
		color: #293241;
	}
	textarea {
		background: #ffffff;
		border: 1px solid #ababab;
		box-sizing: border-box;
		border-radius: 6px;
		padding: 16px;
		width: 756px;
		height: 100px;
		margin-top: -5px;
		::placeholder {
			font-family: "Source Sans Pro";
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 150%;
			color: #9f9f9f;
			margin-top: -200px !important;
		}
	}
`;

const CreateAnalysisModal = styled.div`
	width: 564px;
	height: auto;
	background: #ffffff;
	border-radius: 6px;
	z-index: 100;
	display: block;
	h3 {
		font-family: "Montserrat";
		font-style: normal;
		font-weight: 700;
		font-size: 20px;
		line-height: 150%;
		color: #293241;
		text-align: center;
		margin-top: 8px;
		margin-bottom: -4px;
	}
	h2 {
		font-family: "Source Sans Pro";
		font-style: normal;
		font-weight: 700;
		font-size: 21px;
		line-height: 150%;
		color: #293241;
		margin-top: 5px;
	}
	h1 {
		font-family: "Source Sans Pro";
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 150%;
		color: #293241;
	}
	textarea {
		background: #ffffff;
		border: 1px solid #ababab;
		box-sizing: border-box;
		border-radius: 6px;
		padding: 16px;
		width: 756px;
		height: 100px;
		margin-top: -5px;
		::placeholder {
			font-family: "Source Sans Pro";
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 150%;
			color: #9f9f9f;
			margin-top: -200px !important;
		}
	}
`;