import { Selection, EnterElement, axisLeft, ScaleLinear, DragBehavior, drag, D3DragEvent } from "d3"
import { HistogramReactCallbacks } from "../../../../Types/ReactCallbacks"
import { D3OneToOneRenderable } from "../../../D3/D3OneToOneRenderable"
import { D3DragOverlay } from "../../../D3/D3DragOverlay"

export type HistogramYAxisConfig = {
	scale: ScaleLinear<any, any, any>
	onDoubleClick: () => void
	onDrag: () => void
}

export class D3HistogramYAxis extends D3OneToOneRenderable<SVGGElement, SVGGElement, HistogramYAxisConfig, HistogramReactCallbacks> {
    private axisClassName: string = "d3-histogram-y-axis"
	private dragOverlay?: D3DragOverlay
	private width: number = 30
	private verticalExtension: number = 10
	private dragBehavior: DragBehavior<any, any, any>

	constructor(root: SVGGElement, config: HistogramYAxisConfig, reactCallbacks: HistogramReactCallbacks) {
		super(root, config, "d3-histogram-y-axis-container", reactCallbacks)

		this.dragBehavior = this.dragBehavior = drag()
			.on("drag", this.onDrag)
			.on("end", this.onDragEnd)

		this.render()
	}

	protected enter(newElements: Selection<EnterElement, HistogramYAxisConfig, any, any>): Selection<SVGGElement, HistogramYAxisConfig, SVGGElement, any> {
		const axisGroup = newElements
            .append("g")
            .attr("class", this.className)

		axisGroup
            .append("g")
            .attr("class", this.axisClassName)
            .call(axisLeft(this.config.scale).ticks(4))
            .style("user-select", "none") // disables highlighting the ticks

		axisGroup.each((config, i, nodes) => this.createChildren(config, i, nodes))

		return axisGroup
	}

	protected update(updatedElements: Selection<SVGGElement, HistogramYAxisConfig, any, any>): Selection<SVGGElement, HistogramYAxisConfig, SVGGElement, any> {
        const d3AxisLeft = updatedElements.select("." + this.axisClassName) as Selection<SVGGElement, any, any, any>

		d3AxisLeft.call(axisLeft(this.config.scale).ticks(4))
        
		return updatedElements
	}

	protected createChildren(config: HistogramYAxisConfig, index: number, nodes: ArrayLike<SVGGElement>): void {
		const root = nodes[index]
		const height = config.scale.range()[0]
		const boundingBox = { x: -this.width, y: -this.verticalExtension, width: this.width, height: height + 2 * this.verticalExtension }
		this.dragOverlay = new D3DragOverlay(root, { boundingBox, dragBehavior: this.dragBehavior, cursor: "ns-resize" }, this.reactCallbacks, { onDoubleClick: this.config.onDoubleClick })
	}

	protected updateChildren = () => {
		const height = this.config.scale.range()[0]
		const boundingBox = { x: -this.width, y: -this.verticalExtension, width: this.width, height: height + 2 * this.verticalExtension }
		this.dragOverlay?.updateConfig({boundingBox, dragBehavior: this.dragBehavior })
	}

	protected renderChildren = () => {
		this.dragOverlay?.render()
	}

	private onDrag = (dragEvent: D3DragEvent<SVGRectElement, HistogramYAxisConfig, any>) => {
		const [min, max] = this.config.scale.domain()
		const height = this.config.scale.range()[0]

		const range = max - min
		const sensitivity = range / height
		let { dy } = dragEvent

		const newMaxY = max + dy * sensitivity

		this.config.scale.domain([0, newMaxY])

		this.config.onDrag()

		requestAnimationFrame(() => this.render())
	}

	private onDragEnd = () => {
		const max = this.config.scale.domain()[1]
		this.reactCallbacks.setRootConfig(previous => ({...previous, countMax: max}))
	}
}
