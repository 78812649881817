import React from "react";
import { useController } from "react-hook-form";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateField as MuiDateField } from '@mui/x-date-pickers/DateField';
import { TextField } from "@mui/material";
import { alpha, styled as MuiStyled } from "@mui/material/styles"
import styled from "styled-components";
import dayjs from "dayjs";


// You can add custom styles here
const CustomDateField = MuiStyled(MuiDateField)(({ theme, error, changeDisabledTheme }) => ({
	"label + &": {
		marginTop: theme.spacing(3),
	},
	"& .MuiInputBase-input": {
		borderRadius: 4,
		position: "relative",
		backgroundColor: theme.palette.mode === "light" ? "#F3F6F9" : "#1A2027",
		border: "1px solid",
		borderColor: error ? "red" : theme.palette.mode === "light" ? "#E0E3E7" : "#2D3843",
		fontSize: 16,
		width: "100%",
		padding: "12px 14px",
		transition: theme.transitions.create(["border-color", "background-color", "box-shadow"]),
		"&:focus": {
			boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
			borderColor: theme.palette.primary.main,
		},
	},
	".Mui-disabled": {
		borderColor: changeDisabledTheme ? "#000000" : undefined,
	},
	'& input:disabled': {
		WebkitTextFillColor: changeDisabledTheme ? "#000000" : undefined
	},
}));

const DateTimePickerContainer = styled.div({
});

export function DateTimePicker(props) {
	const { name, control, label, inputId, disabled = false, changeDisabledTheme } = props;
	const {
		field: { ref, value, ...inputProps },
		fieldState: { error },
	} = useController({
		name,
		control,
	});

	return (
		<DateTimePickerContainer>
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<h1>{label}</h1>
				<CustomDateField
					id={inputId}
					disabled={disabled}
					{...inputProps}
					error={!!error}
					ref={ref}
					value={value ? dayjs(value) : props.value}
					renderInput={(params) => <TextField {...params} />}
					changeDisabledTheme={changeDisabledTheme}
				/>
			</LocalizationProvider>
		</DateTimePickerContainer>
	);
}

