import React, { useState, useRef } from 'react'

import ToolBar from './ToolBar/ToolBar'
import Footer from './Footer/Footer'
import ActionsBar from './ActionsBar/ActionsBar'
import styled from 'styled-components'
import { CollapseContainer } from './Viewport/Components/CollapseContainer'
import { CreateVizState } from './VisualizationManager'
import { Visualizations } from "../../Pages/Data/Visualize/DataReview/Visualizations"

function VisualizationArea () {
	const [enableSideBar, setEnableSideBar] = useState(false)

	const actionsBarRef = useRef()
	if (actionsBarRef.current) {
		// When the CSS animation completes
		actionsBarRef.current.ontransitionend = () => window.dispatchEvent(new Event('resize'))
	}

	const VisualizationAreaRef = useRef()
	const ResizeBarRef = useRef()

	function setSidebar(state) {
		if (!state) {
			setEnableSideBar(!enableSideBar)
		} else {
			setEnableSideBar(state)
		}
	}

    const [sidebarWidth, setSidebarWidth] = useState('30%')
	
	return (
		<div style={{background: "#F8F8F8", minHeight: "100%", height: '100%', width: '100%'}}>
			<div style={{display: "flex", position: "relative", zIndex: 2, width: "100%", height: '100%'}}>
					<div id='VisualizationContent' ref={VisualizationAreaRef} style={{height: "100vh", width: "100vw", display: 'flex', flexDirection: 'column', overscrollBehavior: "contain"}}>
						<ToolBar enableSidebar={enableSideBar} setEnableSideBar={() => setSidebar()}/>

						<div style={{display: 'flex', height: '100%'}}>
							<div style={{flex: 1}}>
								<Visualizations />
							</div>

							<CollapseContainer onMouseDown={null} onCollapse={(collapsed) => {setSidebar(!collapsed)}} collapsed={true} resize={enableSideBar} ResizeBarRef={ResizeBarRef} BarID='ResizableBar'/>
							
							<ActionsBar
								actionsBarRef={actionsBarRef}
								enableSidebar={enableSideBar} 
								setEnableSideBar={() => setSidebar()}
								setEnableOverlay={() => {}}
								sidebarWidth={sidebarWidth}
								setSidebarWidth={() => setSidebarWidth()}
								isResizing={false}
							/>

						</div>

						<Footer />
					</div>
			</div>
		</div>
	)
}

const GrayOverlay = styled.div`
	background: #808080;
	opacity: .8;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 10;
	width: 100%;
	height: 100%;
	display: none;
`;

export default VisualizationArea;
export const useLayouts = CreateVizState('layouts', [])
export const useCurrentDisplay = CreateVizState('currentDisplay', '')
export const useSelectedLayout = CreateVizState('selectedLayout', {})
export const useToolBarSettings = CreateVizState('toolbarSettings', {})
export const usePageSize = CreateVizState('pageSize', {})