import { useQuery } from "@tanstack/react-query"
import { useEndpointProvider } from "../Providers/EndpointProvider"

export const useFetchTableData = props => {
	const endpointProvider = useEndpointProvider()
	const { queryKey, endpoint, body, optionalParams, queryOptions, paginationModel, sortModel } = props

	const queryKeyObject = {
		...body,
		...optionalParams,
		page: paginationModel.page,
		filters: queryOptions,
		sort: sortModel,
	}

	const getFn = async body => {
		return endpointProvider.post(endpoint, body, optionalParams)
	}

	return useQuery({
		queryKey: [queryKey, { ...queryKeyObject }],
		queryFn: () => getFn(queryKeyObject),
		keepPreviousData: true,
		enabled: endpoint !== undefined
	})
}
