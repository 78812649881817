import { useRef, useState } from "react"
import { useOnMount } from "../../../../../../../../Hooks/useOnMount"
import { EEGChannel } from "../../../../Types/EEG"
import React from "react"
import { MobergColumn, MobergShortTextInput, MobergRow, MobergButton, MobergTheme, MobergButtonVariant } from "../../../../../../../../Moberg"

type ChannelRenameProps = {
	handleConfirmRename: (newName: string) => void
	channel: EEGChannel
}

export const ChannelRename: React.FC<ChannelRenameProps> = ({ handleConfirmRename, channel }) => {
	const [renameValue, setRenameValue] = useState<string>(channel.alias ?? "")
	const inputRef = useRef<HTMLInputElement>(null)

	useOnMount(() => inputRef.current?.focus())

	return (
		<MobergColumn gap="16px">
			<MobergShortTextInput
				ref={inputRef}
				label={"Channel alias (optional)"}
				defaultValue={renameValue}
				onChange={channelName => setRenameValue(channelName)}
				onSubmit={() => handleConfirmRename(renameValue)}
				placeholder={`${channel.first} - ${channel.second}`}
				limit={20}
			/>
			<MobergRow horizontalAlign="right">
				<MobergButton 
                    onClick={() => handleConfirmRename(renameValue)}
                    theme={MobergTheme.BLUE}
                    variant={MobergButtonVariant.FILLED}>
					OK
				</MobergButton>
			</MobergRow>
		</MobergColumn>
	)
}
