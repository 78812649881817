import { Selection, EnterElement } from "d3";
import { MarginedBoundingBox } from "../../Types/MarginedBoundingBox";
import { D3OneToOneRenderable } from "./D3OneToOneRenderable";
import { ReactCallbacks } from "../../Types/ReactCallbacks";

export type D3ClipPathConfig = {
    id: string
    boundingBox: MarginedBoundingBox
}

export class D3ClipPath extends D3OneToOneRenderable<SVGSVGElement, SVGRectElement, D3ClipPathConfig> {

    constructor(root: SVGSVGElement, config: D3ClipPathConfig, reactCallbacks: ReactCallbacks<any>) {
        super(root, config, config.id, reactCallbacks)
        this.render()
    }

    protected enter(newElements: Selection<EnterElement, D3ClipPathConfig, any, any>): Selection<SVGRectElement, D3ClipPathConfig, SVGSVGElement, any> {
        const clipPath = newElements
            .append("defs")
                .attr("class", this.className)
            .append("clipPath")
                .attr("id", this.config.id)
            .append("rect")
                .attr("width", this.config.boundingBox.width)
                .attr("height", this.config.boundingBox.height)

        return clipPath
    }

    protected update(updatedElements: Selection<SVGRectElement, D3ClipPathConfig, any, any>): Selection<SVGRectElement, D3ClipPathConfig, SVGSVGElement, any> {
        const clipPathRect = updatedElements
            .select("clipPath")
            .select("rect") as Selection<SVGRectElement, any, any, any>
            
        clipPathRect
            .attr("width", this.config.boundingBox.width)
            .attr("height", this.config.boundingBox.height)

        return clipPathRect
    }

}