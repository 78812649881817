import React, { MouseEvent, MutableRefObject, createContext, useContext, useRef, useState } from "react"
import { MobergButton, MobergButtonShape } from "../MobergButton/MobergButton"
import { MobergMenuController, useMobergMenu } from "../../Hooks/useMobergMenu"
import { MdCircle, MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md"
import { MobergIconSize } from "../MobergIcon/MobergIcon"
import { MobergColumn } from "../MobergLayout/MobergColumn"
import { MobergText } from "../MobergHeader/MobergHeader"
import { MobergTheme, getColors } from "../MobergThemes/MobergColors"
import { useWorkspacesProvider } from "../../Providers/WorkspacesProvider"
import { EEGMontageDropdownSection } from "./EEGMontageDropdownSection"
import { EEGMontage, MontageType } from "../../Pages/Data/Visualize/DataReview/Types/EEG"
import { MobergDropdownSize, getSpacingBySize } from "../MobergDropdown/MobergDropdown"
import { Placement } from "@floating-ui/react"
import { MobergFontFamily } from "../MobergFont/MobergFont"

type EEGDropdownContextType = {
	menu: MobergMenuController
	currentMontage: EEGMontage | null
	scrollTopRef: MutableRefObject<number>
	currentSection: MontageType
	changeSection: (montageType: MontageType) => void
	updateCurrentMontage: (montage: EEGMontage) => void
}

export const EEGDropdownContext = createContext<EEGDropdownContextType>({} as any)

type DataReviewEEGMontageDropdownProps = {
	size?: MobergDropdownSize
	label?: string
	placement?: Placement
	currentMontage: EEGMontage | null
	updateCurrentMontage: (montage: EEGMontage) => void
}

export const DataReviewEEGMontageDropdown: React.FC<DataReviewEEGMontageDropdownProps> = ({
	size = MobergDropdownSize.SMALL,
	label = "",
	placement = "bottom-start",
	currentMontage,
	updateCurrentMontage,
}) => {
	// Because of state change timing and render timing, this menu sometimes becomes invisible when it shouldn't be.
	// tryToKeepMenuOpen resolves this by checking if the menu is open, and correcting the visual state.
	// It was made into a parameter because this behavior can break other menus
	const menu = useMobergMenu({ placement, tryToKeepMenuOpen: true })
	const scrollTopRef = useRef<number>(0)
	const [currentSection, setSection] = useState<MontageType>(currentMontage?.type ?? MontageType.STANDARD)

	const changeSection = (montageType: MontageType) => {
		scrollTopRef.current = 0
		setSection(montageType)
	}

	return (
		<EEGDropdownContext.Provider value={{ menu, currentMontage, updateCurrentMontage, scrollTopRef, currentSection, changeSection }}>
			<span style={{ fontWeight: "bold", fontFamily: MobergFontFamily.REGULAR, whiteSpace: "nowrap", display: "flex", alignItems: "center" }}>{label}</span>

			<MobergButton onClick={menu.open} style={{ border: "1px solid #cdcdcd", fontWeight: "normal", ...getSpacingBySize(size), justifyContent: "space-between" }}>
				{currentMontage?.name ?? "Select a montage..."}

				<span style={{ pointerEvents: "none" }}>
					{menu.isOpen ? <MdKeyboardArrowUp size={MobergIconSize.REGULAR} /> : <MdKeyboardArrowDown size={MobergIconSize.REGULAR} />}
				</span>
			</MobergButton>

			<menu.MobergMenu style={{ padding: 0, marginBottom: "8px" }}>
				<MenuContent />
			</menu.MobergMenu>
		</EEGDropdownContext.Provider>
	)
}

const MenuContent: React.FC = () => {
	const { currentMontage, currentSection, changeSection } = useContext(EEGDropdownContext)
	const { selectedWorkspace } = useWorkspacesProvider()

	const sections = [
		{ label: "Standard", value: MontageType.STANDARD },
		{ label: "Personal", value: MontageType.PERSONAL },
		{ label: `Shared (${selectedWorkspace})`, value: MontageType.SHARED },
	]

	const handleChangeSection = (event: MouseEvent, section: MontageType) => {
		event.stopPropagation()
		changeSection(section)
	}

	return (
		<div style={{ display: "flex", minHeight: "300px" }}>
			<div style={{ padding: "16px 24px", paddingRight: "16px", backgroundColor: "#f8f8f8", borderRadius: "6px 0 0 6px" }}>
				<MobergColumn gap="16px">
					<MobergText style={{ color: "gray" }}>Access</MobergText>
					<MobergColumn>
						{sections.map(section => {
							const isActive = section.value === currentSection
							const sectionHasActiveEEGMontage = currentMontage?.type?.toLowerCase() === section.value.toLowerCase()

							return (
								<MobergButton
									key={section.label}
									shape={MobergButtonShape.FULL_WIDTH}
									theme={MobergTheme.BLACK}
									active={isActive}
									onClick={event => handleChangeSection(event, section.value)}
									style={{ padding: "8px 16px", justifyContent: "space-between" }}
								>
									{section.label}
									<MdCircle
										color={getColors(MobergTheme.BLUE).main}
										size={"10px"}
										opacity={sectionHasActiveEEGMontage ? 1 : 0}
										title={`This indicator shows which section the current eeg montage is in.`}
									/>
								</MobergButton>
							)
						})}
					</MobergColumn>
				</MobergColumn>
			</div>

			<MobergColumn>
				<MobergText style={{ color: "gray", paddingTop: "16px", paddingLeft: "24px" }}>EEG montages</MobergText>
				<EEGMontageDropdownSection />
			</MobergColumn>
		</div>
	)
}
