import { useState, useEffect, useRef, useCallback } from "react"
import { MobergAnimationCurve, MobergAnimationTiming } from "../../Moberg"

export const usePopover = () => {
	const [isOpen, setIsOpen] = useState(false)
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>()
	const popoverRef = useRef<HTMLElement>()

	const open = useCallback((event?: React.MouseEvent<HTMLButtonElement>) => {
		if (isOpen) {
			close()
			return
		}

		setIsOpen(previous => {
			const newOpenState = !previous

			if (newOpenState) {
				setTimeout(() => {
					const popover = popoverRef.current

					if (popover) {
						popover.style.transition = `all ${MobergAnimationTiming.FAST} ${MobergAnimationCurve.EASE_IN}`
						popover.style.opacity = "1"
						popover.style.transform = "scale(1)"
					}
				})
			}

			return newOpenState
		})
		setAnchorEl(event?.currentTarget)
	}, [isOpen])

	const close = async () => {
		const popover = popoverRef.current

		if (!popover) {
			return
		}

		popover.style.transition = `all ${MobergAnimationTiming.FAST} ${MobergAnimationCurve.EASE_OUT}`
		popover.style.opacity = "0"
		popover.style.transform = "scale(0.9)"

		await new Promise<void>(resolve => {
			popover.ontransitionend = () => {
				// Prevent this from being called more than once.
				popover.ontransitionend = null

				// By waiting a little bit after the animation to finishes, we can make sure the animation is smooth.
				setTimeout(() => {
					setIsOpen(false)
					setTimeout(resolve) // wait for the Recoil State to update
				})
			}
		})

		setIsOpen(false)
	}

	const setPopoverRef = useCallback((node: HTMLElement) => {
		popoverRef.current = node
	}, [])

	useEffect(() => {
		const clickOutsideClose = (event: MouseEvent) => {
			const clickedEl = event.target as Node

			if (popoverRef.current && !popoverRef.current.contains(clickedEl) && anchorEl && !anchorEl.contains(clickedEl)) {
				close()
			}
		}

		if (isOpen) {
			document.addEventListener("click", clickOutsideClose)
		}

		return () => document.removeEventListener("click", clickOutsideClose)
	}, [anchorEl, isOpen, open])

	return { open, close, isOpen, anchorEl, setPopoverRef }
}
