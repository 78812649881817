import * as XLSX from 'xlsx'

const MedicationsFilterFunction = async (_, file) => {
    const readData = (file) => {
        return new Promise((resolve, reject) => {
            let reader = new FileReader()

            reader.onload = function (e) {
                try {
                    if (file.name.endsWith('.xlsx') || file.name.endsWith('.xls')) {
                        const data = new Uint8Array(e.target.result)
                        const workbook = XLSX.read(data, { type: 'array' })

                        const sheetName = workbook.SheetNames[0]
                        const sheet = workbook.Sheets[sheetName]
                        const jsonData = XLSX.utils.sheet_to_json(sheet)

                        resolve(jsonData.length - 3)
                    } else {
                        let data = reader.result
                        let rows = data.split('\n')
                        let headers = rows[0].split(',')
                        let nMedications = headers.length - 1
                        resolve(nMedications)
                    }
                } catch (error) {
                    if (error.message === 'File is password-protected') {
                        reject('The file is password-protected.')
                    } else {
                        reject('Unable to read ' + file.name)
                    }
                }
            }

            reader.onerror = function () {
                reject('Unable to read ' + file.name)
            }

            if (file.name.endsWith('.xlsx') || file.name.endsWith('.xls')) {
                reader.readAsArrayBuffer(file)
            } else {
                reader.readAsText(file)
            }
        })
    }

    try {
        const nMedications = await readData(file)

        let filteredInputData = {
            nMedications: nMedications,
            allFiles: file
        }

        return filteredInputData
    } catch (error) {
        console.log(error)
        alert(error)
        return null
    }
}

export default MedicationsFilterFunction
