import React from "react";
import { MobergIconSize } from "../Components/MobergIcon/MobergIcon";

type OptionsIconProps = {
    size?: string
    color?: string
}

export const OptionsIcon = ({ size=MobergIconSize.REGULAR, color="currentColor" }: OptionsIconProps) => {
    return (
        <svg stroke={color} fill={color} viewBox="0 0 100 100" style={{ height: size, width: size, pointerEvents: 'none' }}>
            <path d="M87,33.5c0-5.4-3.9-9.9-9-10.8v-8.2c0-1.1-0.9-2-2-2s-2,0.9-2,2v8.2c-5.1,0.9-9,5.4-9,10.8s3.9,9.9,9,10.8v41.2  c0,1.1,0.9,2,2,2s2-0.9,2-2V44.3C83.1,43.4,87,38.9,87,33.5z M76,40.5c-3.9,0-7-3.1-7-7s3.1-7,7-7s7,3.1,7,7S79.9,40.5,76,40.5z" />
            <path d="M52,54.7V14.5c0-1.1-0.9-2-2-2s-2,0.9-2,2v40.2c-5.1,0.9-9,5.4-9,10.8s3.9,9.9,9,10.8v9.2c0,1.1,0.9,2,2,2s2-0.9,2-2v-9.2  c5.1-0.9,9-5.4,9-10.8S57.1,55.6,52,54.7z M50,72.5c-3.9,0-7-3.1-7-7s3.1-7,7-7c3.9,0,7,3.1,7,7S53.9,72.5,50,72.5z" />
            <path d="M26,34.7V14.5c0-1.1-0.9-2-2-2s-2,0.9-2,2v20.2c-5.1,0.9-9,5.4-9,10.8s3.9,9.9,9,10.8v29.2c0,1.1,0.9,2,2,2s2-0.9,2-2V56.3  c5.1-0.9,9-5.4,9-10.8S31.1,35.6,26,34.7z M24,52.5c-3.9,0-7-3.1-7-7s3.1-7,7-7s7,3.1,7,7S27.9,52.5,24,52.5z" />
        </svg>
    )
}
