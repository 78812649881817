import React, { useState } from "react"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import { useModalProvider } from "../../../../../../../../Providers/ModalProvider"
import { editedLayoutGroupAtom, selectedLayoutAtom, selectedLayoutGroupAtom, uneditedLayoutGroupAtom } from "../../../../Atoms/Layout"
import { WAVEFORM_WINDOW_TIME_PRESETS } from "../../../../../../../../Managers/VisualizationManager/Viewport/Components/XAxis"
import { persystEEGAtom } from "../../../../Atoms/PersystWindow"
import { VisualizationToolbar } from "../../../React/VisualizationToolbar"
import { ConfigurePersystEEGModal } from "./ConfigurePersystEEGModal"
import { useOnMount } from "../../../../../../../../Hooks/useOnMount"
import { useEndpointProvider } from "../../../../../../../../Providers/EndpointProvider"
import { currentPatientFileInfoAtom } from "../../../../Atoms/PatientFile"
import { PersystMontageDefinition, PersystMontageGroup } from "../../../../Types/PersystWindow"
import { MobergDropdown, MobergDropdownSize } from "../../../../../../../../Components/MobergDropdown/MobergDropdown"
import { DataSource } from "../../../../Types/DataSource"
import { useBackendLinksProvider } from "../../../../../../../../Providers/BackendLinksProvider"

type PersystWindowSettingsProps = {
	windowId: string
}

const placeholderMontageDefinition: PersystMontageDefinition = {
	name: "Waiting For Persyst...",
	patientID: "?",
	editable: false,
	channels: [],
	averageReferences: []
}

export function PersystEEGWindowSettings(props: PersystWindowSettingsProps) {
	const [montageDefinitions, setMontageDefinitions] = useState<PersystMontageDefinition[]>([placeholderMontageDefinition])
	const { createModal } = useModalProvider()
	const { get } = useEndpointProvider()
	const { dataSourceMap } = useRecoilValue(currentPatientFileInfoAtom)
	const setUneditedLayoutGroup = useSetRecoilState(uneditedLayoutGroupAtom)
	const setEditedLayoutGroup = useSetRecoilState(editedLayoutGroupAtom)
	const selectedLayoutGroup = useRecoilValue(selectedLayoutGroupAtom)
	const selectedLayout = useRecoilValue(selectedLayoutAtom)
	const { LINKS } = useBackendLinksProvider()
	const [persystConfig, setPersystConfig] = useRecoilState(persystEEGAtom({ layoutId: selectedLayout?.id as string, windowId: props.windowId }))
	const [viewStart, viewEnd] = persystConfig.viewScale.domain()
	const viewDuration = viewEnd.getTime() - viewStart.getTime()

	function renderConfigureModal() {
		if (!selectedLayout) {
			console.error("Couldn't render the modal because a layout was not selected")
			return
		}

		setUneditedLayoutGroup(selectedLayoutGroup)
		setEditedLayoutGroup(selectedLayoutGroup)
		createModal(<ConfigurePersystEEGModal windowId={props.windowId} layoutId={selectedLayout.id} />)
	}

	useOnMount(() => {
		get(LINKS.DATA.PROFILING.PERSYST.EEG_MONTAGES, { data_object_id: dataSourceMap.get(DataSource.CURRENT_PATIENT) })
			.then((montageGroups: PersystMontageGroup[]) => {

				// TODO: We get User, Shared, and System montages
				const system = montageGroups.find(group => group.groupName === "System")

				if (!system) {
					return
				}

				const montages = system.definitions

				setMontageDefinitions(montages)

				if (!persystConfig.montage) {
					setPersystConfig(previous => ({ ...previous, montage: montages[0] }))
				}
			})
	})

	const visualizationSpecificSettings = (
		<MobergDropdown
			label={"Montage"}
			size={MobergDropdownSize.SMALL}
			selectedValue={JSON.stringify(persystConfig.montage)}
			onChange={value => setPersystConfig(previous => ({ ...previous, montage: JSON.parse(value as string) as PersystMontageDefinition }))}
			options={montageDefinitions.map(montage => ({ label: montage.name, value: JSON.stringify(montage) }))} />

	)

	return (
		<VisualizationToolbar
			atomValue={persystConfig}
			setAtom={setPersystConfig}
			renderConfigureModal={renderConfigureModal}
			viewDuration={viewDuration}
			timePresetOptions={WAVEFORM_WINDOW_TIME_PRESETS}
			visualizationSpecificSettings={visualizationSpecificSettings}
		/>
	)
}
