import MUITable from "../../../Components/MUITable/MUITable"
import { useModalProvider } from "../../../Providers/ModalProvider"
import CreateRoleModal from "./Subcomponents/CreateRoleModal"
import { useEndpointProvider } from "../../../Providers/EndpointProvider"
import { GridActionsCellItem } from "@mui/x-data-grid"
import EyeIcon from "../../../Components/Icons/EyeIcon"
import { MdOutlineModeEdit } from "react-icons/md"
import { useCallback } from "react"
import ModifyRoleModal from "./Subcomponents/ModifyRoleModal"
import { useBackendLinksProvider } from "../../../Providers/BackendLinksProvider"

export default function RolesAndPermissions() {
	const { createModal, close } = useModalProvider()
	const endpointProvider = useEndpointProvider()
	const { LINKS } = useBackendLinksProvider()

	function createRoleModal() {
		createModal(<CreateRoleModal style={{ height: "80%" }} close={close} escClose={false} clickOutsideClose={false} />)
	}

	const handleClick = useCallback((row, viewMode) => {
		createModal(<ModifyRoleModal style={{ height: "80%" }} close={close} row={row} escClose={false} clickOutsideClose={false} viewMode={viewMode} />)
	}, [])

	const columns = [
		{ field: "name", flex: 1, headerName: "Name", minWidth: 80, visible: true },
		{ field: "description", flex: 1, headerName: "Description", minWidth: 100, visible: true },
		{ field: "user_count", flex: 1, headerName: "No. users", valueGetter: params => params.row.user_count, minWidth: 100, visible: true },
		{ field: "permission_count", flex: 1, headerName: "No. permissions", valueGetter: params => params.row.permission_count, minWidth: 150, visible: true },
		{
			field: "action",
			flex: 0.4,
			headerName: "Actions",
			type: "actions",
			getActions: params => [
				<GridActionsCellItem icon={<EyeIcon />} label="View" onClick={() => handleClick(params.row, true)} showInMenu />,
				<GridActionsCellItem icon={<MdOutlineModeEdit size={20} />} label="Edit" onClick={() => handleClick(params.row)} showInMenu />,
			],
			minWidth: 80,
			visible: true
		},
	]

	const deleteRoles = async role_ids => endpointProvider.post(LINKS.ADMIN.USER_MANAGEMENT.ROLES_AND_PERMISSIONS.DELETE_ROLES, { role_ids })
	const roleTableToolbarProps = {
		createButton: { title: "Create role", isShow: true, onClick: createRoleModal },
		deleteButton: {
			title: "Delete",
			isShow: true,
			onSuccessMessage: "Role deleted successfully.",
			invalidateQueryKey: "roles",
			onClick: role_ids => deleteRoles(role_ids),
		},
		filterButton: { title: "Filter", isShow: false },
		exportButton: { isShow: false },
		searchInput: { isShow: true },
		columnButton: { isShow: true },
		nameForMultipleRows: "roles"
	}

	const queryProps = {
		queryKey: "roles",
		endpoint: LINKS.ADMIN.USER_MANAGEMENT.USERS.GET_ROLES,
	}

	return (<>
		<MUITable columns={columns} tableToolbarProps={roleTableToolbarProps} {...queryProps} />
	</>
	)
}