import { memo } from "react"
import { DragHandle } from './DragHandle'
import Node from "../../../../../Managers/PipelineManager/Node"
import UserInput from '../Inputs/UserInput.js'

const styles = {
    fontSize: "11px",
    display: "flex",
    background: "white",
    border: '1px solid gray',
    borderRadius: "10px",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    minHeight: 128,
    height: "100%",
    minWidth: 128,
    maxWidth: 512,
    whiteSpace: 'pre-wrap',
    overflowWrap: 'break-word',
}

export const NodeComponent = memo(({ nodeId, type, subtype, coords, state, setState }) => {
    const node = new Node(nodeId, type, subtype, coords)

    const updateNodeArgs = ({nodeId, arg, value}) => {
        setState(prevState => {
            const prevStateCopy = {...prevState}
            prevStateCopy.nodes[nodeId].args[arg] = value
            return prevStateCopy
        })
    }

    return (
        <div style={{...styles, display: "flex", flexDirection: "column", justifyContent: "start", alignItems: "start", paddingBottom: "10px"}}>
            <DragHandle />

            <h3 style={{padding: "10px", fontSize: "15px"}}>{node.display_name}</h3>

            {node.inputs.map((config, index) => {
                const initialValue = state.nodes[nodeId].args[config.VARIABLE_NAME]
                return UserInput.fromConfig(
                    config,
                    `${config.TYPE}_${index}`,
                    {nodeId, initialValue, onChange: (change) => updateNodeArgs(change)})
            })}
        </div>
    )
})
