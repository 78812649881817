import { Page } from "./Page"
import { throttle } from "lodash"

export type TimeSeriesPageRequest = {
	pageId: number
	studyId: string
	patientId: string
	modalities: string[]
	startDate: Date | number
	endDate: Date | number
	numPoints: number
	resolution: string
}

export class TimeSeriesPageQueue<PageType extends Page<any>> {
	public loadQueue: PageType[] = []
	public unloadQueue: PageType[] = []
	public onSuccess = (page: PageType) => {}
	public db?: IDBDatabase

	private isRunning = false
	public allLoadedPages = new Map<number, PageType>()

	push = (pages: PageType[]) => {
		const nonDuplicates = pages.filter(page => !page.loading && !this.loadQueue.find(p => page.id === p.id))
		this.loadQueue.push(...nonDuplicates)
		this.pop()
	}

	unloadAllLoadedPages() {
		this.allLoadedPages.forEach(page => page.unload())
	}

	unloadOutside = throttle((neighborhood: PageType[]) => {
		const neighborhoodPages = new Set(neighborhood)

		this.unloadQueue.length = 0

		// Iterate over the keys of the Map
		for (const page of this.allLoadedPages.values()) {
			if (!neighborhoodPages.has(page)) {
				this.unloadQueue.push(page)
			}
		}

		// Delete the keys outside the loop to avoid modifying the Map while iterating
		for (const page of this.unloadQueue) {
			page.unload()
			this.allLoadedPages.delete(page.id)
		}
	}, 500)

	pop = () => {
		if (this.loadQueue.length === 0 || this.isRunning) {
			return
		}

		const pageRequest = this.loadQueue.shift()
		if (!pageRequest) {
			return
		}

		this.isRunning = true
		
		pageRequest.load()
			.then((loadedPage: Page<any>) => {
				this.onSuccess(loadedPage as PageType)
				this.allLoadedPages.set(loadedPage.id, loadedPage as PageType)
			})
			.catch(error => {
				// If something went wrong loading the page, that's fine, we'll try again.
				pageRequest.unload()
				this.loadQueue.push(pageRequest)
				console.warn(error)
			})
			.finally(() => {
				this.isRunning = false
				this.pop()
			})
	}

	clear = () => {
		this.loadQueue.length = 0
	}
}
