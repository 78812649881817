import * as MdIcons from "react-icons/md"
import { useState } from "react"

export function CollapseContainer({ children, onCollapse=() => {}, collapsed=true, onMouseDown={}, ResizeBarRef=undefined, BarID='' }) {
    const [isCollapsed, setIsCollapsed] = useState(collapsed)

    const iconSize = 18
    const iconViewBox = "10 7 5 10" // A tighter box around the svg so it takes up less space.
    const containerStyle = {
        height: "100%",
        display: "flex",
        alignItems: "center",
        border: "1px solid black",
        borderRadius: "6px 0 0 6px",
        borderRight: "none",
        cursor: "pointer"
    }

    return <div style={containerStyle}
        id={BarID}
        onClick={() => {
            setIsCollapsed(isCollapsed => {
                if (onCollapse) {
                    onCollapse(!isCollapsed)
                }
                return !isCollapsed
            })
        }}
        onMouseDown={onMouseDown}
        ResizeBarRef={ResizeBarRef}
        >

        <span> 
            {isCollapsed 
                ? <MdIcons.MdArrowLeft size={iconSize} viewBox={iconViewBox} /> 
                : <MdIcons.MdArrowRight size={iconSize} viewBox={iconViewBox} />} 
        </span>

        {!isCollapsed && children}

    </div>
}