import React, {useState, useRef, useEffect, useCallback, createRef} from 'react'
import Modal from 'react-bootstrap/Modal';
import { HTMLInputField, HTMLCheckboxField, HTMLCheckboxFieldGrid, HTMLSectionTitle, InputField, HTMLDatetimeLocalField, HTMLDropdownField, HTMLNumberField, HTMLRadioField, HTMLRangeField, HTMLTimeField, HTMLTextAreaField, HTMLTableField} from "../../../../Constants/MMReportStructures/MMReportStructures";
import CPPOpt from '../../../../Components/CPPOpt/CPPOpt';
import ScatterPlot from '../../../../Components/ScatterPlot/ScatterPlot'
import Histogram from '../../../../Components/Histogram/Histogram'
import ModifyElementModal from './ModifyElementModal'
import ModifyGroupModal from './ModifyGroupModal'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
// import ReportDisplay from './ReportDisplay'
import styled from 'styled-components';
import { LINKS } from '../../../../Constants/BackendLinks'
import {useStudiesProvider} from '../../../../Providers/StudiesProvider';
import { useEndpointProvider } from '../../../../Providers/EndpointProvider';
import * as MdIcons from "react-icons/md";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Tree, Tooltip } from 'antd';
import { DownOutlined } from '@ant-design/icons'
import "./Tree.css"
import ModalFooterButton, { buttonColor } from '../../../../Components/ModalFooterButton/ModalFooterButton';
const { TreeNode } = Tree

function CreateReportDetailsModalBootstrap({show, onHide, reportId, createReport}) {

    const headingStyle = {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "700",
        lineHeight: "150%",
        color: "#293241"
    }

    const endpointProvider = useEndpointProvider()
    const studiesProvider = useStudiesProvider()

    const [gData, setGData] = useState([])
    const [selectedKey, setSelectedKey] = useState()

    const [x, setX] = useState(250)
    const [y, setY] = useState(0)
    const [isHoverItem, setIsHoverItem] = useState({});
    const [JSXElements, setJSXElements] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [pageMappings, setPageMappings] = useState([])
    const [modifyElementModalShow, setModifyElementModalShow] = useState(false);
    const [modifyGroupModalShow, setModifyGroupModalShow] = useState(false);
    const [isCardOpen, setIsCardOpen] = useState(false)
    const [isHoverLeft, setIsHoverLeft] = useState(false)
    const [isHoverRight, setIsHoverRight] = useState(false)
    const [isHoverPage, setIsHoverPage] = useState(false)
    const [elRefs, setElRefs] = useState([])
    const cardRef = useRef()


    const getComponentsByReportTemplateId = useCallback(async (reportId) => {
        let body = {
            report_id: reportId
        }
        
        return endpointProvider.post(LINKS.REPORTING.MULTIMODAL_REPORTS.COMPONENTS.GET_COMPONENTS_BY_REPORT_TEMPLATE_ID, body)
    }, [endpointProvider])

    const createComponent = useCallback(async (component) => {
        let body = component

        return endpointProvider.post(LINKS.REPORTING.MULTIMODAL_REPORTS.COMPONENTS.CREATE_COMPONENT, body)
    }, [endpointProvider])

    const modifyComponents = useCallback(
        async (componentList, reportTemplateId) => {
            let body = {
                component_list : componentList,
                report_template_id : reportTemplateId
            }
        
            return endpointProvider.post(LINKS.REPORTING.MULTIMODAL_REPORTS.COMPONENTS.MODIFY_COMPONENTS, body)
        },
        [endpointProvider]  
    )

    useEffect(() => {
        if (createReport === false && reportId) {
            getComponentsByReportTemplateId(reportId)
                .then(res => {
                    setGData(res)
                })
                .catch(err => {
                    console.log({err})
                })
        } else if (createReport === true && reportId) {
            setGData([])
        }
    }, [getComponentsByReportTemplateId, reportId, createReport])

    const returnJSXElements = useCallback((data) => {
        const reportHeadingRef = createRef()
        const reportRecordRef = createRef()
        setElRefs([{ref: reportHeadingRef, page: currentPage}, {ref: reportRecordRef, page: currentPage}])

		const elementArr = [
			[
				<h1 id='report-heading' ref={reportHeadingRef} style={{marginBottom: "0.5in", borderBottom: "1px solid #000"}}>REPORT</h1>, 
				<div ref={reportRecordRef}>
                    <HTMLInputField id='report-record' ref={reportRecordRef} question={"Record ID"} answer={{x: ''}} />
                </div>
			]
		]

		data.forEach(component => {
            const htmlComponentRef = createRef()
            setElRefs(elRefs => [...elRefs, {ref: htmlComponentRef, page: currentPage}])
			const HTMLComponent = <div ref={htmlComponentRef}><HTMLSectionTitle componentKey={component.key} title={component.title}/></div>
			component.page_break ? elementArr.push([<div className="pagebreak" />, HTMLComponent]) : elementArr.at(-1).push(HTMLComponent)
			component.children.forEach(subcomponent => {
                const htmlSubcomponentRef = createRef()
                setElRefs(elRefs => [...elRefs, {ref: htmlSubcomponentRef, page: currentPage}])
				const HTMLSubcomponent = <div ref={htmlSubcomponentRef}>{renderComponentHTML(subcomponent.key, subcomponent.input_type, subcomponent.title, subcomponent.option)}</div>
				subcomponent.page_break ? elementArr.push([<div className="pagebreak" />, HTMLSubcomponent]) : elementArr.at(-1).push(HTMLSubcomponent)
			})
		})

		return elementArr
	}, [])


    const returnMappings = useCallback((data) => {
		let currentPage = 1
		const mappingObj = {}
		data.forEach(component => {
			if (component.page_break) currentPage += 1
			mappingObj[component.key] = currentPage
			component.children.forEach(subcomponent => {
				if (subcomponent.page_break) currentPage += 1
				mappingObj[subcomponent.key] = currentPage
			})
		})
		return mappingObj
	}, [])

    const handleAddPageBreak = useCallback((data) => {
		const fixHeight = 520
		let currentHeight = 140
		data.forEach(component => {
			if (component.height < fixHeight - currentHeight) {
				currentHeight += component.height
				component['page_break'] = false
			} else {
				currentHeight = component.height
				component['page_break'] = true
			}

			if (component.children) {
				component.children.forEach(subcomponent => {
					if (subcomponent.height < fixHeight - currentHeight) {
						currentHeight += subcomponent.height
						subcomponent['page_break'] = false
					} else {
						currentHeight = subcomponent.height
						subcomponent['page_break'] = true
					}
				})
			}
		})
	}, [])



    useEffect(() => {
        const deepCopyGData = JSON.parse(JSON.stringify(gData))
        updateKey(deepCopyGData, reportId)
        handleAddPageBreak(deepCopyGData)
        setJSXElements(returnJSXElements(deepCopyGData))
        setPageMappings(returnMappings(deepCopyGData))
    }, [gData, returnJSXElements, returnMappings, handleAddPageBreak, reportId])

    function addNewComponent() {
        const newGData = JSON.parse(JSON.stringify(gData))
        let highestKey = -1
        Object.entries(newGData).map(([key, value]) => {
            highestKey = Math.max(parseInt(value['key'].split("-")[0],10), highestKey)
        })
        let newKey = `${highestKey +1}-0`
        let newComponentData = {"key": newKey, "title": `New Group ${highestKey +1}`, "input_type": "HTMLSectionTitle", "children": [], "category": "Category", "height": 31}
        newGData.push(newComponentData)
        setGData(newGData)
        setIsHoverItem({...isHoverItem, [newKey]: false})
    }

    function addNewSubcomponent(componentKey) {
        const newGData = JSON.parse(JSON.stringify(gData))
        const selectedComponent = newGData.find(({key}) => key === componentKey)
        const newSecondaryKey = selectedComponent.children.length +1
        const newSubcomponentKey = `${componentKey.at(0)}-${newSecondaryKey}`
        const newSubcomponent = {"category": "HTMLInputField", "description": "", "height": 48,  "input_type": "HTMLInputField", "key": newSubcomponentKey, "option": {"answer": {"x": " "}}, "page_break": false, "title": "New element"}
        selectedComponent.children.push(newSubcomponent)
        setGData(newGData)
    }

    function handleDelete(data, componentKey) {
		if (data.length === 0) return
		if (!componentKey) return
		const copyData = JSON.parse(JSON.stringify(data))
		const primaryKey = parseInt(componentKey.split('-').at(0))
		const secondaryKey = parseInt(componentKey.split('-').at(-1))
		secondaryKey === 0 ? copyData.splice(primaryKey, 1) : copyData[primaryKey].children.splice(secondaryKey -1, 1)
		updateKey(copyData, reportId)
		setGData(copyData)
	}

    function handleDuplicate(data, key) {
        if (data.length === 0) return
        if (!key) return
        const copyData = JSON.parse(JSON.stringify(data))
        const primaryKey = parseInt(key.split('-').at(0))
        const secondaryKey = parseInt(key.split('-').at(-1))
        if (secondaryKey === 0) {
            const newComponent = JSON.parse(JSON.stringify(copyData[primaryKey]))
            copyData.splice(primaryKey +1, 0, newComponent)
        } else {
            const newComponent = JSON.parse(JSON.stringify(copyData[primaryKey].children[secondaryKey -1]))
            copyData[primaryKey].children.splice(secondaryKey, 0, newComponent)
        }
        updateKey(copyData, reportId)
        setGData(copyData)
    }

    function handleModify(data, key) {
        if (data.length === 0) return
        if (!key) return
        const secondaryKey = parseInt(key.split('-').at(-1))
        secondaryKey === 0 ? setModifyGroupModalShow(true) : setModifyElementModalShow(true)
    }

    useEffect(() => {
        const closeCard = e => {
            setIsCardOpen(false)
        }
        document.body.addEventListener('click', closeCard)

        return () => document.body.removeEventListener('click', closeCard)
    }, [])

    // https://stackoverflow.com/questions/41679957/how-to-add-a-icon-to-tree-treenode
    // https://tutorialdeep.com/knowhow/display-button-on-hover-html-css/
    const onThreeDotButtonClick = (item) => {
        const tooltipY = document.getElementById(`tree-node-container-${item.key}`).getBoundingClientRect().top
        const tooltipX = componentsListRef.current.getBoundingClientRect().right
        cardRef.current.style.top = `${tooltipY}px`
        cardRef.current.style.left = `${tooltipX}px`
        cardRef.current.style.width = "fit-content"
        // setSelectedKey(item.key)
        setIsCardOpen(prev => !prev)
        console.log(selectedKey)
    }

    const [isHoverDeleteItem, setIsHoverDeleteItem] = useState(false)
    const [isHoverDuplicateItem, setIsHoverDuplicateItem] = useState(false)
    const [isHoverRenameItem, setIsHoverRenameItem] = useState(false)
    const [refresh, setRefresh] = useState(false)

    const getHeight = (id) => {
        return document.getElementById(id).getBoundingClientRect().height + parseFloat(window.getComputedStyle(document.getElementById(id)).marginTop.replace("px", "")) + parseFloat(window.getComputedStyle(document.getElementById(id)).marginBottom.replace("px", ""))
    }

    useEffect(() => {

        const newGData = gData.map((item) => {
            const height = getHeight(item.key)
            item.height = height
            if (item.children.length > 0) {
                item.children.forEach((child) => {
                    const childHeight = getHeight(child.key)
                    child.height = childHeight
                })
            }
            return item
        }
        )
        setGData(newGData)
    }, [refresh])


    const renderTreeNodes = (data) => 
        data.map((item) => {
            if (item.children) {
                return (
                    <TreeNode 
                        title={
                            <div id={`tree-node-container-${item.key}`}
                                className='tree-node-container'
                                style={{
                                    display: "flex", 
                                    justifyContent: "space-between", 
                                    alignItems: "center",
                                    padding: 0,
                                    width: "100%",
                                    }}
                            >
                                <div>{item.title}</div>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <Tooltip 
                                        title="Delete, duplicate, and more..." 
                                        placement="bottom" 
                                    >
                                        
                                        <button 
                                            className='tree-node-button'
                                            style={{
                                                justifyContent: "center", 
                                                alignItems: "center", 
                                                border: "none", 
                                                }}
                                            onClick={() => onThreeDotButtonClick(item)}
                                        >
                                            <BsThreeDotsVertical size={20}/> 
                                        </button>
                                    </Tooltip>
                                    <Tooltip title="Quickly add a subcomponent" placement="bottom">
                                        <button 
                                            className='tree-node-button'
                                            style={{
                                                justifyContent: "center", 
                                                alignItems: "center", 
                                                border: "none", 
                                                }}
                                            onClick={() => {addNewSubcomponent(item.key)}}
                                        >
                                            <MdIcons.MdAdd size={20}/> 
                                        </button>
                                    </Tooltip>
                                    
                                </div>
                            </div>
                            } 
                        key={item.key}
                    >
                        {renderTreeNodes(item.children)}
                    </TreeNode>
                );
            }
            return <TreeNode 
                        title={
                            <div id={`tree-node-container-${item.key}`}
                                className='tree-node-container'
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    padding: 0,
                                    width: "100%",
                                    }}
                            >
                                <div>{item.title}</div>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <Tooltip
                                        title="Delete, duplicate, and more..."
                                        placement="bottom"
                                    >
                                        <button
                                            className='tree-node-button'
                                            style={{
                                                justifyContent: "center",
                                                alignItems: "center",
                                                border: "none",
                                            }}
                                            onClick={() => onThreeDotButtonClick(item)}
                                        >
                                            <BsThreeDotsVertical size={20} />
                                        </button>
                                    </Tooltip>
                                </div>
                            </div>
                        } 
                        key={item.key}
                    />
        });

    const onDragEnter = (info) => {
        console.log(info)
    }

    const onDrop = (info) => {
        const dropKey = info.node.key;
        const dragKey = info.dragNode.key;
        const data = [...gData];

        const dragParsedKey = parseInt(dragKey.split("-")[1])
        const dropParsedKey = parseInt(dropKey.split("-")[1])

        const dragObj = dragParsedKey ? data.at(parseInt(dragKey.split("-")[0])).children.splice(dragParsedKey-1, 1)[0] : data.splice(parseInt(dragKey.split("-")[0]), 1)[0]

        const changeGroupOrder = () => {
            data.splice(info.dropPosition-1, 0, dragObj)
            updateKey(data, reportId)
            setGData(data)
        }

        const changeElementOrder = () => {
            data.at(parseInt(dragObj.key.split("-")[0])).children.splice(info.dropPosition-1, 0, dragObj)
            updateKey(data, reportId)
            setGData(data)
        }

        const moveElementToGroup = () => {
            data.at(parseInt(dropKey.split("-")[0])).children.push(dragObj)
            updateKey(data, reportId)
            setGData(data)
        }

        const handleGroupError = () => {
            data.splice(parseInt(dragKey.split("-")[0]), 0, dragObj)
            updateKey(data, reportId)
            setGData(data)
            alert("Error: Groups cannot be placed inside groups or elements.")
        }

        const handleElementError = (dropToGap) => {
            data.at(parseInt(dragKey.split("-")[0])).children.splice(dragParsedKey-1, 0, dragObj)
            updateKey(data, reportId)
            setGData(data)
            dropToGap ? alert("Error: Cannot convert element to group.") : alert("Error: Elements cannot be placed inside elements.")
        }
        
        if (dragParsedKey === 0) {
            info.dropToGap ? changeGroupOrder() : handleGroupError()
            return;
        } else {
            if (info.dropToGap) {
                dropParsedKey === 0 ? handleElementError(info.dropToGap) : changeElementOrder()
                return;
            } else {
                dropParsedKey === 0 ? moveElementToGroup() : handleElementError(info.dropToGap)
                return;
            }
        }
        };
    
    const onSelect = (component) => {
        setSelectedKey(component[0])
      }

    const [currentComponents, setCurrentComponents] = useState([])

    useEffect(() => {
        console.log(currentComponents)
    }, [currentComponents])

    useEffect(() => {
        setCurrentComponents(JSXElements.at(currentPage -1))
    }, [currentPage, JSXElements])

    const handleArrowLeft = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1)
        }
    }

    const handleArrowRight = () => {
        if (currentPage < JSXElements.length) {
            setCurrentPage(currentPage + 1)
        }
    }

    const componentsListRef = useRef(null)
    const containerRef = useRef(null);

    const modalBody = (
        <Container fluid style={{height: "100%"}}>
            <Row style={{height: "75vh"}}>
                <Col ref={componentsListRef} md={2} style={{display: "flex", flexDirection: "column", position: "relative", maxHeight: "75vh"}}>
                    <button
                        style={{
                            display: "flex", 
                            alignItems: "center", 
                            width: "100%", 
                            border: "none", 
                            background: "none", 
                            gap: "1rem",
                            marginBottom: "1rem"
                        }}
                        onClick={addNewComponent}
                    >
                        <MdIcons.MdAdd size={20}/> 
                        <div>Add a group</div>
                    </button>
                    <Scrollbar 
                        style={{
                            display: gData.length > 0 ? "block" : "none", 
                            overflowY: "scroll",
                            }}>
                        <Tree
                            switcherIcon={<DownOutlined />}
                            blockNode={true}
                            draggable
                            onDragEnter={onDragEnter}
                            onDrop={onDrop}
                            onSelect={onSelect}
                        >
                            {renderTreeNodes(gData)}
                        </Tree>
                    </Scrollbar>
                </Col>
                <Col md={10}>
                    {/* <div style={{display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%"}}>
                        <div style={{height: "68vh"}} ref={containerRef}>
                            {currentComponents}
                        </div>
                        <div>
                            <nav>
                                <ul
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        margin: "0px",
                                        width: "auto",
                                        textAlign: "center",
                                        listStyle: "none",
                                    }}
                                >
                                    <li>
                                        <button
                                            style={{
                                                fontWeight: 600,
                                                color: "rgb(32, 125, 234)",
                                                fontFamily: "Source Sans Pro",
                                                fontStyle: "normal",
                                                height: "36px",
                                                margin: "0px 0px 0px 16px",
                                                padding: "6px 16px",
                                                borderRadius: "6px",
                                                border: "0.5px solid rgb(217, 217, 217)",
                                                lineHeight: "150%",
                                                backgroundColor: isHoverLeft ? "#e9ecef" : "#fff",
                                            }}
                                            onMouseEnter={() => {setIsHoverLeft(true)}}
                                            onMouseLeave={() => {setIsHoverLeft(false)}}
                                            onClick={handleArrowLeft}
                                        >
                                            <MdIcons.MdKeyboardArrowLeft size={20}/>
                                        </button>
                                    </li>
                                    <li>
                                        <span
                                            style={{
                                                fontFamily: "Source Sans Pro",
                                                fontStyle: "normal",
                                                height: "36px",
                                                margin: "0px 0px 0px 16px",
                                                padding: "6px 16px",
                                                border: "1px solid rgb(217, 217, 217)",
                                                borderRadius: "6px",
                                                lineHeight: "150%",
                                                backgroundColor: isHoverPage ? "#e9ecef" : "#fff",
                                            }}
                                            onMouseEnter={() => {setIsHoverPage(true)}}
                                            onMouseLeave={() => {setIsHoverPage(false)}}
                                        >
                                            {currentPage}
                                        </span>
                                    </li>
                                    <li
                                        style={{
                                            fontFamily: "Source Sans Pro",
                                            fontStyle: "normal",
                                            fontWeight: 400,
                                            fontSize: "16px",
                                            margin: "0px 0px 0px 7.5px",
                                            padding: "6px 3.5px",
                                            lineHeight: "120%",
                                            color: "rgb(41, 50, 65)",
                                        }}
                                    >
                                        <h5
                                            style={{
                                                inset: "16.67% 30.77% 30.56% 58.46%",
                                                marginBottom: "0px",
                                                marginTop: "0px",
                                                fontSize: "1.25rem",
                                                fontWeight: 500,
                                                lineheight: "1.2",
                                                color: "rgb(0, 0, 0, 0.85)",
                                                padding: "0px",
                                            }}
                                        >
                                            of {JSXElements.length}
                                        </h5>
                                    </li>
                                    <li>
                                        <button
                                            style={{
                                                fontWeight: 600,
                                                color: "rgb(32, 125, 234)",
                                                fontFamily: "Source Sans Pro",
                                                fontStyle: "normal",
                                                height: "36px",
                                                margin: "0px 0px 0px 16px",
                                                padding: "6px 16px",
                                                borderRadius: "6px",
                                                border: "0.5px solid rgb(217, 217, 217)",
                                                lineHeight: "150%",
                                                backgroundColor: isHoverRight ? "#e9ecef" : "#fff",
                                            }}
                                                onMouseEnter={() => {setIsHoverRight(true)}}
                                                onMouseLeave={() => {setIsHoverRight(false)}}
                                                onClick={handleArrowRight}
                                        >
                                            <MdIcons.MdKeyboardArrowRight size={20}/>
                                        </button>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                        
                    </div> */}
                    {/* <ReportDisplay/> */}
                </Col>
            </Row>
        </Container>
    )

    function renderComponentHTML(subcomponentKey, input_type, title=null, option={}, modality=null) {
		switch(input_type) {
			case 'HTMLInputField':
				return <HTMLInputField subcomponentKey={subcomponentKey} question={title} answer={option.answer}/>
			case 'HTMLCheckboxField':
				return <HTMLCheckboxField subcomponentKey={subcomponentKey} question={title} answer={option}/>
			case 'HTMLCheckboxFieldGrid':
				return <HTMLCheckboxFieldGrid subcomponentKey={subcomponentKey} grid_dictionary={option.answer}/>
			case 'HTMLPlot':
				return <CPPOpt subcomponentKey={subcomponentKey} />
			case 'HTMLScatterPlot':
				return <ScatterPlot subcomponentKey={subcomponentKey} />
			case 'HTMLHistogramPlot':
				return <Histogram subcomponentKey={subcomponentKey} />
			case 'HTMLDatetimeLocalField':
				return <HTMLDatetimeLocalField subcomponentKey={subcomponentKey} question={title} answer={option}/>
			case 'HTMLDropdownField':
				return <HTMLDropdownField subcomponentKey={subcomponentKey} question={title} answer={option}/>
			case 'HTMLNumberField':
				return <HTMLNumberField subcomponentKey={subcomponentKey} question={title} answer={option}/>
			case 'HTMLRadioField':
				return <HTMLRadioField subcomponentKey={subcomponentKey} question={title} answer={option}/>
			case 'HTMLRangeField':
				return <HTMLRangeField subcomponentKey={subcomponentKey} question={title} answer={option}/>
			case 'HTMLTimeField':
				return <HTMLTimeField subcomponentKey={subcomponentKey} question={title} answer={option.answer}/>
			case 'HTMLTextAreaField':
				return <HTMLTextAreaField subcomponentKey={subcomponentKey} question={title}/>
			case 'HTMLTableField':
				return <HTMLTableField subcomponentKey={subcomponentKey} question={title} answer={option}/>
			default:
		}
	}

	useEffect(() => {
		if (selectedKey === undefined) {
			setCurrentPage(1)
		} else {
			setCurrentPage(pageMappings[selectedKey])
		}

	}, [selectedKey])

    function updateKey(data, currentReportId) {
		data.forEach((val, key) => {
			let secondaryKey = 0
			// update key
			val['key'] = `${key}-${secondaryKey}`

			// add report_template_id
			val["_report_template_id"] = currentReportId;

			// process subcomponent
			if (val.children.length > 0) {
				const subcomponents = [...val.children]
				subcomponents.forEach((element, index) => {
					element['key'] = `${key}-${index +1}`
				})
			}
		})
	}    

    async function handleCreateComponents(componentList) {
        const promiseList = []
        componentList.forEach(component => {
            promiseList.push(createComponent(component))
        })
        await Promise.all(promiseList)
    }

    async function handleSave() {
        const copyGData = JSON.parse(JSON.stringify(gData))
        updateKey(copyGData, reportId)
        handleAddPageBreak(copyGData)
        if (createReport === true) {
            handleCreateComponents(copyGData) 
            
        } else if (createReport === false) {
            await modifyComponents(copyGData, reportId)
        }
        onHide()
    }

  return (
    <>
        <Modal
        show={show}
        onHide={onHide}
        centered
        dialogClassName="my-modal"
        >
            <Modal.Header 
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
            }}
            >
                <Modal.Title>
                    <h3 style={headingStyle}>Configure Report</h3>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {modalBody}
                <Card
                    ref={cardRef}
                    style={{
                        display: isCardOpen ? "block" : "none",
                        position: "fixed",
                        minWidth: "200px",
                        zIndex: 1,
                        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                    }}
                >
                    <Card.Body>
                        <div
                            style={{
                                display: "flex",
                                gap: "10px",
                                backgroundColor: isHoverDeleteItem ? "#ebebea" : "#FFFFFF",
                            }}
                            onMouseEnter={() => setIsHoverDeleteItem(true)}
                            onMouseLeave={() => setIsHoverDeleteItem(false)}
                            onClick={() => handleDelete(gData, selectedKey)}
                        >
                            <MdIcons.MdOutlineDelete size={20} />
                            <p style={{fontWeight: 400}}>Delete</p>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                gap: "10px",
                                backgroundColor: isHoverDuplicateItem ? "#ebebea" : "#FFFFFF",
                            }}
                            onMouseEnter={() => setIsHoverDuplicateItem(true)}
                            onMouseLeave={() => setIsHoverDuplicateItem(false)}
                            onClick={() => handleDuplicate(gData, selectedKey)}
                        >
                            <MdIcons.MdContentCopy size={20}/>
                            <p style={{fontWeight: 400}}>Duplicate</p>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                gap: "10px",
                                backgroundColor: isHoverRenameItem ? "#ebebea" : "#FFFFFF",
                            }}
                            onMouseEnter={() => setIsHoverRenameItem(true)}
                            onMouseLeave={() => setIsHoverRenameItem(false)}
                            onClick={() => handleModify(gData, selectedKey)}
                        >
                            <MdIcons.MdOutlineEditNote size={20}/>
                            <p style={{fontWeight: 400}}>Modify</p>
                        </div>
                    </Card.Body>
                </Card>
            </Modal.Body>
            <Modal.Footer>
                <div
                    style={{
                        paddingLeft: "35px",
                        paddingBottom: "15px",
                    }}
                >
                    <ModalFooterButton
                        buttonText="Save"
                        color={buttonColor.blueFill}
                        buttonStyle={{width: "114px", marginRight: "5px"}}
                        onClickFxn={
                            async () => {await handleSave();}
                        }
                    />
                    <ModalFooterButton
                        buttonText="Cancel"
                        buttonStyle={{width: "114px", marginRight: "5px"}}
                        color={buttonColor.blueOutline}
                        onClickFxn={onHide}
                    />
                </div>
            </Modal.Footer>
        </Modal>
        <ModifyElementModal
            show={modifyElementModalShow}
            onHide={() => setModifyElementModalShow(false)}
            data={gData}
            modifyData={setGData}
            selectedComponentKey={selectedKey}
            refresh={setRefresh}
        />
        <ModifyGroupModal
            show={modifyGroupModalShow}
            onHide={() => setModifyGroupModalShow(false)}
            data={gData}
            modifyData={setGData}
            selectedComponentKey={selectedKey}
        />
    </>
  )
}

const Scrollbar = styled.div`
    scrollbar-width: thin;
    ::-webkit-scrollbar {
        display: block;
        width: 5px;
        color: #313A4A;
    }
    ::-webkit-scrollbar-track {
        background: #BEC4CF;
        width: 5px;
        border-radius: 2px;
    }
`;

export default CreateReportDetailsModalBootstrap