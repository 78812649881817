import { useCallback, useEffect } from "react"
import { D3TimeBasedVisualization } from "../Components/Visualizations/D3TimeBasedVisualization"
import { useSocketProvider } from "../../../../../Providers/SocketProvider"

type SyncTimeSocketResponse = {
	milliseconds: number
	timeZone: string
}

type useD3LiveRecordingTimesProps = {
	d3Controller?: D3TimeBasedVisualization<any, any, any, any>
}

export const useD3SyncTime = ({ d3Controller }: useD3LiveRecordingTimesProps) => {
	const { getDataQuerySocket } = useSocketProvider()

	const handleSocketResponse = useCallback((socketResponse: SyncTimeSocketResponse) => {
		d3Controller?.liveEndDateUpdated(socketResponse.milliseconds)
	}, [d3Controller])

	useEffect(() => {
		const socket = getDataQuerySocket()
		socket.on("sync_time", handleSocketResponse)

		return () => {
			socket.off("sync_time", handleSocketResponse)
		}
	}, [getDataQuerySocket, handleSocketResponse])
}
