import { useState } from "react";

export default function CheckboxInput({ nodeId, config, initialValue, onChange}) {

    const [value, setValue] = useState(initialValue ?? config.DEFAULT_VALUE ?? false)

    return (
        <div style={{display: "flex", alignItems: "center"}}>
            <div style={{paddingRight: "8px"}}>
                {config.DISPLAY_NAME}
            </div>

            <input
                style={{verticalAlign: "middle"}}
                type="checkbox"
                checked={value}
                onChange={(e) => {
                    setValue(e.target.checked)
                    if (onChange) {
                        onChange({nodeId, arg: config.VARIABLE_NAME, value: e.target.checked})
                    }
                }}
            />
        </div>
    )
}