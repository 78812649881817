import { atomFamily } from "recoil";
import { LayoutWindowId } from "../Types/LayoutWindowId";
import { ScatterPlotConfig } from "../Types/ScatterPlot";
import { getDefaultVisualizationAtom } from "./Visualizations";

export const ScatterPlotConfigsAtom = atomFamily<ScatterPlotConfig, LayoutWindowId>({
    key: "ScatterPlotConfigs",
    default: () => ({
        ...getDefaultVisualizationAtom(),
        xAxisConfig: {
            modality: "ABP_na",
            minValue: 0,
            maxValue: 300,
            stepSize: 20,
        },
        yAxisConfig: {
            modality: "ICP_na",
            minValue: 0,
            maxValue: 200,
            stepSize: 20,
        },
        color: '#000000',
        shape: 'circle',
        size: 10,
        autoScale: true,
        resamplingPeriodSeconds: 60
    })
})