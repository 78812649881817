import { Dimensions } from "../../../../../Providers/DimensionsProvider"
import { Offset } from "./Offset"

export class MarginedBoundingBox {
	// Inner Dimensions - the bounding box of the inner rectangle
	public x: number = 0
	public y: number = 0
	public width: number = 0
	public height: number = 0

	// Outer Dimensions (purposely hidden from the user) - the outer rectangle
	private fullHeight: number = 0
	private fullWidth: number = 0
	private margins: Offset = { top: 0, left: 0, bottom: 0, right: 0 }

	constructor(dimensions: Dimensions, margins: Offset) {
		this.setDimensions(dimensions)
        this.setMargins(margins)
	}

	public setMargins = (margins: Offset) => {
		this.margins = margins
		this.x = margins.left
		this.y = margins.top
		this.updateBoundingBox()
	}

	public setDimensions = (dimensions: Dimensions) => {
		this.fullWidth = dimensions.width
		this.fullHeight = dimensions.height
		this.updateBoundingBox()
	}

	private updateBoundingBox = () => {
		this.height = this.fullHeight - (this.margins.top + this.margins.bottom)
		this.width = this.fullWidth - (this.margins.left + this.margins.right)
	}
}
