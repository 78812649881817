import React, { useEffect, useState } from 'react'
import { useRef } from 'react'
import * as MdIcons from 'react-icons/md'
import styled from 'styled-components'

export default function Calendar({
	show = false,
	selectedOption,
	currentDate,
	currentEndDate,
	minTime,
	maxTime,
	months,
	updateTime,
	isMonthInRange,
	interval,
}) {
	const daysOfWeek = ['S', 'M', 'T', 'W', 'T', 'F', 'S']
	const [numDays, setNumDays] = useState()
	const [tempDate, setTempDate] = useState(currentDate)

	function getDaysInMonth(month, year) {
		const days = new Date(year, month, 0).getDate()

		return days
	}

	useEffect(() => {
		setNumDays(getDaysInMonth(tempDate.getMonth() + 1, tempDate.getFullYear()))
	}, [tempDate])

	function changeMonth(validate, direction) {
		let newDate = new Date(tempDate.getTime())

		if (validate) {
			if (direction === 'forward') {
				return isMonthInRange(newDate.getMonth() + 2 > 12 ? (newDate.getMonth() + 2) % 12 : newDate.getMonth() + 2)
			} else if (direction === 'backward') {
				if (newDate.getMonth() === 0) {
					newDate.setFullYear(newDate.getFullYear() - 1) // Adjust year for January
					newDate.setMonth(11) // Set to December
				} else {
					newDate.setMonth(newDate.getMonth() - 1)
				}
				return isMonthInRange(newDate.getMonth() + 1)
			}
		} else {
			if (direction === 'forward') {
				newDate.setMonth(newDate.getMonth() + 1)
			} else if (direction === 'backward') {
				newDate.setMonth(newDate.getMonth() - 1)
			}

			if (newDate.getTime() < new Date(minTime).getTime()) {
				// If the updated month is outside the minimum range, set the date to the earliest valid day in range.
				newDate = new Date(minTime)
			} else if (newDate.getTime() > new Date(maxTime).getTime()) {
				// If the updated month is outside the maximum range, set the date to the latest valid day in range.
				newDate = new Date(maxTime)
			}

			setTempDate(newDate)
		}
	}

	function isSelectedDay(day, month) {
		if (interval) {
			return (day === currentDate.getDate() && month === currentDate.getMonth()) || (day === currentEndDate.getDate() && month === currentEndDate.getMonth())
		} else {
			return day === currentDate.getDate() && month === currentDate.getMonth()
		}
	}

	const startDateCalendarRef = useRef()
	const endDateCalendarRef = useRef()

	useEffect(() => {
		const inSameMonth = currentDate.getMonth() === currentEndDate.getMonth()
		const inSameDay = currentDate.getDate() === currentEndDate.getDate()

		if (inSameDay) {
			if (startDateCalendarRef.current) {
				startDateCalendarRef.current.style.backgroundColor = '#207dea'
				startDateCalendarRef.current.style.zIndex = -1
				startDateCalendarRef.current.style.color = 'white'
			}
			return
		}

		if (selectedOption === 'start') {
			if (startDateCalendarRef.current) {
				startDateCalendarRef.current.style.backgroundColor = '#207dea'
				startDateCalendarRef.current.style.zIndex = -1
				startDateCalendarRef.current.style.color = 'white'
			}
			if (endDateCalendarRef.current) {
				if (!startDateCalendarRef.current && inSameMonth) return
				endDateCalendarRef.current.style.backgroundColor = '#AEB7C6'
				endDateCalendarRef.current.style.zIndex = 1
				endDateCalendarRef.current.style.color = 'black'
			}
		} else {
			if (endDateCalendarRef.current) {
				endDateCalendarRef.current.style.backgroundColor = '#207dea'
				endDateCalendarRef.current.style.zIndex = -1
				endDateCalendarRef.current.style.color = 'white'
			}
			if (startDateCalendarRef.current) {
				if (!endDateCalendarRef.current && inSameMonth) return
				startDateCalendarRef.current.style.backgroundColor = '#AEB7C6'
				startDateCalendarRef.current.style.zIndex = 1
				startDateCalendarRef.current.style.color = 'black'
			}
		}
	}, [selectedOption, tempDate, currentDate, currentEndDate, startDateCalendarRef, endDateCalendarRef])

	function isInBetweenDay(day) {
		const date = new Date(tempDate.getTime())

		date.setDate(day)

		const dateValue = date.getTime()
		let start = currentDate.getTime()
		let end = currentEndDate.getTime()

		if (start > end) {
			[start, end] = [end, start] // Swap dates if start is later than end
		}

		return dateValue >= start && dateValue <= end
	}

	return (
		<div style={{ display: show ? 'flex' : 'none', width: '100%', justifyContent: 'center', flexDirection: 'column' }}>
			<div id='Calendar-Controls' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '60px' }}>
				<MdIcons.MdOutlineArrowLeft
					size={45}
					style={{ color: changeMonth(true, 'backward') ? '#207DEA' : '#AEB7C6', pointerEvents: changeMonth(true, 'backward') ? 'auto' : 'none' }}
					onClick={() => {
						changeMonth(false, 'backward')
					}}
				/>

				<p style={{ color: '#207DEA', margin: '0px', fontSize: '20px', fontWeight: 600, fontFamily: 'Source Sans Pro' }}>
					{`${Object.keys(months)[tempDate.getMonth()]} ${tempDate.getFullYear()}`}
				</p>

				<MdIcons.MdOutlineArrowRight
					size={45}
					style={{ color: changeMonth(true, 'forward') ? '#207DEA' : '#AEB7C6', pointerEvents: changeMonth(true, 'forward') ? 'auto' : 'none' }}
					onClick={() => {
						changeMonth(false, 'forward')
					}}
				/>
			</div>

			<CalendarWrapper>
				{daysOfWeek.map(day => {
					return <DayOfWeekCell>{day}</DayOfWeekCell>
				})}
				{Array.from({ length: numDays }, (_, i) => i + 1).map(day => {
					const isStartOrEnd = isSelectedDay(day, tempDate.getMonth())
					const date = new Date(tempDate.getTime())
					date.setHours(0, 0, 0, 0)
					date.setDate(day)
					const tempStartDate = new Date(currentDate.getTime())
					tempStartDate.setHours(0, 0, 0, 0)
					const isStartDate = date.getTime() === tempStartDate.getTime()

					const inBetween = isInBetweenDay(day)

					const tempEndDate = new Date(currentEndDate.getTime())
					tempEndDate.setHours(0, 0, 0, 0)

					const isFirstSelected = new Date(Math.min(tempStartDate.getTime(), tempEndDate.getTime())).getTime() === date.getTime()

					const sameDay = tempStartDate.getTime() === tempEndDate.getTime()

					return (
						<DayCell
							key={day}
							selected={isStartOrEnd}
							inBetween={interval ? inBetween : false}
							isStartDate={isStartOrEnd && isStartDate}
							sameDay={sameDay}
							disabled={updateTime(true, 'day', day, tempDate.getMonth(), tempDate.getFullYear())}
							onClick={() => { updateTime(false, 'day', day, tempDate.getMonth(), tempDate.getFullYear()) }}
							id={isStartOrEnd ? (isStartDate ? 'startDate' : 'endDate') : ''}
							isInterval={interval}
							isFirstSelected={isFirstSelected}
						>
							{day}
							{isStartOrEnd && (
								<DayCellSelectedEffect
									selected={isStartOrEnd}
									isStartDate={isStartDate && isStartOrEnd}
									selectedOption={selectedOption}
									style={{
										backgroundColor: isStartOrEnd ? (isStartDate ? '#207dea' : '#AEB7C6') : 'white',
										color: isStartOrEnd ? (isStartDate ? 'white' : 'black') : 'white'
									}}
									id={isStartOrEnd ? (isStartDate ? 'startEffect' : 'endEffect') : 'null'}
									ref={isStartOrEnd ? (isStartDate ? startDateCalendarRef : endDateCalendarRef) : null}
								>
									{day}
								</DayCellSelectedEffect>
							)}
						</DayCell>
					)
				})}
			</CalendarWrapper>
		</div>
	)
}

const CalendarWrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(7, 50px);
	grid-template-rows: 40px repeat(5, 1fr);
	row-gap: 8px;
	column-gap: 0px;
	margin: auto;
`

const DayOfWeekCell = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	color: #293241;
	text-align: center;
	font-family: Source Sans Pro;
	font-size: 20px;
	font-style: normal;
	font-weight: 600;
	line-height: 18.137px;
`

const DayCell = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	color: #293241;
	text-align: center;
	font-family: Source Sans Pro;
	font-size: 20px;
	font-style: normal;
	font-weight: 600;
	line-height: 18.137px;
	height: 50px;
	padding: 0px;
	cursor: pointer;
	${({ disabled }) =>
		disabled &&
		`
        color: #AEB7C6;
        pointer-events: none;
        cursor: auto;
    `}
	${({ selected, isFirstSelected, sameDay, isInterval }) =>
		selected &&
		`
        position: relative;
        color: white;
        background-color: ${isInterval ? '#f0f5ff' : 'white'};
        display: flex;
        justify-content: center;
        align-items: center;
        filter: drop-shadow(0px 0px 0px rgba(9, 44, 76, 0.24));
        border: none;
        border-radius: ${sameDay ? '2rem' : isFirstSelected ? '2rem 0 0 2rem' : '0 2rem 2rem 0'};
    `}
    /* Days between start and end date */
    ${({ inBetween }) =>
		inBetween &&
		`
        background-color: #f0f5ff; /* Light blue background color */
        padding: 0px !important; /* Remove padding */
        margin: 0px; /* Remove margin */
    `}
	${({ inRangeDay, isInterval }) =>
		inRangeDay &&
		isInterval &&
		`
        color: black;
		pointer-events: auto;
		cursor: not-allowed;
    `}
`

const DayCellSelectedEffect = styled.div`
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #207dea; /* Light blue background color */
	border-radius: 50%;
	z-index: -1;
	display: flex;
	justify-content: center;
	align-items: center;
	${({ selected }) => !selected && 'display: none;'}
`