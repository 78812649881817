import { createContext, useContext, useState, useEffect } from "react";
import { useEndpointProvider } from "./EndpointProvider";
import { LINKS } from '../Constants/BackendLinks'
import { useStudiesProvider } from "./StudiesProvider";
import { useAuthProvider } from "./AuthProvider";
import { UpdateDispatcher } from "./UpdateDispatcher";


const RolesContext = createContext()

export const roleUpdates = {
    ROLES_FILTERS: "roles_filters"
}

export const RolesProvider = ({ children }) => {
    console.log("PROVIDER RENDER: ROLES")

    const [roles, setRoles] = useState([])
    const [filters, setFilters] = useState([])

    const endpointProvider = useEndpointProvider()
    const studiesProvider = useStudiesProvider()
    const authProvider = useAuthProvider()

    async function update(...updates) {
        const initState = {
            [roleUpdates.ROLES_FILTERS]: { data: roles, filters }
        }

        const dispatcher = new UpdateDispatcher(updates, initState, roleUpdates)

        dispatcher.dispatch(roleUpdates.ROLES_FILTERS,
            () => endpointProvider.post(LINKS.ADMIN.USER_MANAGEMENT.USERS.GET_ROLES, {study_id: studiesProvider.selectedStudyId}),
            data => {
                setRoles(data?.data)
                setFilters(data?.filters)
            })
    }

    // Make sure we don't persist any data between logins.
    useEffect(() => {
        if (!authProvider.userIsLoggedIn && roles.length > 0) {
            setRoles([])
        }
    }, [authProvider, roles.length])

    return (
        <RolesContext.Provider value={{ roles, filters, update }}>
            {children}
        </RolesContext.Provider>
    )
}

export const useRolesProvider = () => useContext(RolesContext)