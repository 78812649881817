import React, { useState } from "react";
import { useController } from "react-hook-form";
import { Checkbox as MuiCheckbox, FormControlLabel, FormGroup, FormControl, FormLabel } from "@mui/material";
import { alpha, styled as MuiStyled } from "@mui/material/styles"
import styled from "styled-components";
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import Typography from '@mui/material/Typography';

const CustomCheckbox = MuiStyled(MuiCheckbox)(({ theme, error, changeDisabledTheme }) => ({
	"label + &": {
		marginTop: theme.spacing(3),
	},
	"& .MuiInputBase-input": {
		borderRadius: 4,
		position: "relative",
		backgroundColor: theme.palette.mode === "light" ? "#F3F6F9" : "#1A2027",
		border: "1px solid",
		borderColor: error ? "red" : theme.palette.mode === "light" ? "#E0E3E7" : "#2D3843",
		fontSize: 16,
		width: "100%", 
		padding: "12px 14px",
		transition: theme.transitions.create(["border-color", "background-color", "box-shadow"]),
		"&:focus": {
			boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
			borderColor: theme.palette.primary.main,
		},
	},
    " &.Mui-disabled": {
        color: changeDisabledTheme ? "#207DEA" : undefined,
      },
}))

const CheckboxContainer = styled.div({
    marginTop: "20px",
  });


export function CheckboxField(props) {
    const { name, control, inputId, disabled = false, options, label, changeDisabledTheme, required } = props;
  
    const {
      field: { ref, ...inputProps },
      fieldState: { error },
    } = useController({
      name,
      control,
    });

    const [selectedValue, setSelectedValue] = useState(inputProps.value);
  
    const handleCheckboxChange = (value) => {
      setSelectedValue(value);
      inputProps.onChange(value);
    };
  
    return (
      <>
        <CheckboxContainer>
            <FormControl>
                <FormLabel required={required} component="legend" sx={{
                    '.MuiFormLabel-asterisk': {
                        color: '#E54E58'
                    }
                }}>
                    {label} {required && <span style={{ color: "#E54E58", fontStyle: 'italic', marginLeft: '10px' }}>Required</span>}
                </FormLabel>

                <div style={{ display: "flex", flexDirection: "row" }}>
                    {options.map((option) => (
                    <FormControlLabel
                        key={option}
                        control={
                        <CustomCheckbox
                            id={`${inputId}-${option}`}
                            disabled={disabled}
                            icon={<CircleUnchecked />}
                            checkedIcon={<CircleCheckedFilled />}
                            checked={selectedValue === option}
                            onChange={() => handleCheckboxChange(option)}
                            error={!!error}
                            changeDisabledTheme={changeDisabledTheme}
                            ref={ref}
                            style={error ? {color: 'red'} : {}}
                        />
                        }
                        label={option}
                        sx={{ "& .Mui-disabled": {
                            WebkitTextFillColor: changeDisabledTheme ?  "#000000" : undefined,
                            }, }}
                    />
                    ))}
                </div>
                {error && (
                    <Typography variant="caption" color="error">
                    {"This field is required"}
                    </Typography>
                )}
            </FormControl>
        </CheckboxContainer>
      </>
    );
  }
  

export function GroupCheckboxField(props){
    const { name, control, inputId, disabled = false, options, label, value } = props;
    
    const {
        field: { ref, ...inputProps },
        fieldState: { error },
    } = useController({
        name,
        control,
    });
    
    const [selectedValues, setSelectedValues] = useState(value);

    const handleCheckboxChange = (option) => {
        const isSelected = selectedValues.includes(option);

        if (isSelected) {
            setSelectedValues(selectedValues.filter(value => value !== option));
        } else {
            setSelectedValues([...selectedValues, option]);
        }

        setSelectedValues((updatedSelectedValues) => {
            inputProps.onChange(updatedSelectedValues);
            return updatedSelectedValues;
        });;
    }
    
    return (
        <>
        <FormControl sx={{ m: options.length > 1 ? 1 : 0 }} component="fieldset" error={!!error}>
            <FormLabel component="legend">{label}</FormLabel>
            {options.map((option) => (
            <FormGroup>
                <FormControlLabel
                key={option}
                control={
                    <CustomCheckbox
                    id={`${inputId}-${option}`}
                    disabled={disabled}
                    checked={selectedValues.includes(option)}
                    onChange={() => handleCheckboxChange(option)}
                    error={!!error}
                    ref={ref}
                    />
                }
                label={option}
                />
            </FormGroup>
            ))}
        </FormControl>
        </>
    );
}