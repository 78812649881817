import { useState, useEffect, useLayoutEffect } from 'react'
import "@fontsource/source-sans-pro"
import { useLocation, useNavigate } from "react-router-dom"
import { useEndpointProvider } from '../../../../Providers/EndpointProvider'
import { FRONTEND_LINKS } from '../../../../Constants/FrontendLinks'
import FileDirectoryTable from '../../../../Components/FileDirectoryTable/FileDirectoryTable'
import { localizeDate } from '../../../../Computation/utilFunctions'
import SubpageTabs from '../../../../Components/SubpageTabs/SubpageTabs'
import { SubHeader } from "./Components/SubHeader"
import { useBackendLinksProvider } from '../../../../Providers/BackendLinksProvider'

const PatientFiles = () => {
    function useWindowSize() {
        const [size, setSize] = useState(window.innerHeight)

        useLayoutEffect(() => {
            const updateSize = () => setSize(window.innerHeight)
            const observer = new ResizeObserver(updateSize)

            observer.observe(document.body)

            return () => observer.disconnect()
        }, [])

        return size
    }

    const size = useWindowSize()
    let navigate = useNavigate()
    const location = useLocation()
    const endpointProvider = useEndpointProvider()
    const { LINKS } = useBackendLinksProvider()
    const query = new URLSearchParams(useLocation().search)
    const UID = query.get("patient_id")

    const [patient, setPatient] = useState(location.state)

    useEffect(() => {
        async function getPatient() {
            let body = {
                patient_primary_key: UID,
            }

            if (UID === undefined) {
                alert(`patient_id === undefined`)
                navigate(FRONTEND_LINKS.SUBPAGES.DATA.SUBPAGES.VISUALIZE.MAIN)
            }

            try {
                const patientData = await endpointProvider.post(LINKS.DATA.PROFILING.GET_PATIENT, body)
                return patientData
            } catch (error) {
                alert(`Patient id=${UID} does not exist.`)
                navigate(FRONTEND_LINKS.SUBPAGES.DATA.SUBPAGES.VISUALIZE.MAIN)
            }
        }

        if (!patient || Object.keys(patient).length === 0) {
            getPatient().then(patientData => {
                setPatient(patientData)
            })
        }
    }, [UID])

    const dataStructureColumns = [
        { field: "name", flex: 1, minWidth: 250, headerName: "Name", visible: true },
        { field: "createtime", flex: 1, headerName: "Time created", visible: true, valueGetter: (params) => localizeDate(params.row.createtime) },
        { field: "modtime", flex: 1, headerName: "Time modified", visible: true, valueGetter: (params) => localizeDate(params.row.modtime) },
        { field: "size", flex: 1, headerName: "Size", visible: true },
        {
            field: "actions",
            flex: 0.4,
            headerName: "Actions",
            minWidth: 120,
            type: "actions",
            visible: true,
        },
    ]

    const dataStructureQueryProps = {
        queryKey: "dataStructure",
        endpoint: LINKS.DATA.UPLOAD.GET_DIRECTORY_STRUCTURE,
        body: { patient_path: '', patient_id: UID, timezone: Intl.DateTimeFormat().resolvedOptions().timeZone },
    }

    return (
        <div style={{ background: "#F8F8F8", height: `${size - 74}px` }}>
            <SubpageTabs UID={UID} patient={patient} currentTab={'Files'} />

            <div style={{ padding: "0 32px", display: "block" }}>
                <SubHeader patient={patient} />
                <FileDirectoryTable columns={dataStructureColumns} {...dataStructureQueryProps} patient={patient} />
            </div>
        </div>
    )
}

export default PatientFiles