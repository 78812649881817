import styled from "styled-components"

export const ModalHeader = styled.div`
    position: sticky;
    background: #FFF;
    top: 0;
    z-index: 1000;
    text-align: center;
    width: 100%;
    padding: 15px;
    border-bottom: 1px solid #B3B3B3;
`;

export const GrayOverlay = styled.div`
	background: #808080;
    z-index: 999;
	opacity: .8;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
`;

export const GrayOverlayAlt = styled.div`
	background: #808080;
    z-index: 1;
	opacity: .8;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
`;

export const ModalDiv = styled.div`
    z-index: 999;
    position: fixed;
    width: ${props => props.width ?? '1300px'};
    left: 50%;
    top: ${props => props.top ?? '100px'};
    transform: translate(-50%, 0);
    background: #FFFFFF;
    border-radius: 6px;
    overflow-y: auto;
    overscroll-behavior: contain;
`

export const PopupDiv = styled.div`
    z-index: 999;
    position: absolute;
    padding: 15px;
    width: ${props => props.width ?? '300px'};
    height: ${props => props.height ?? '300px'};
    left: 0px;
    top: 120%;
    background: #FFFFFF;
    border: solid 1px #B3B3B3;
    border-radius: 6px;
`
