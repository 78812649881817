import DataQuery from "../Networking/DataQuery"
import VisualizationManager, { CreateVizReducer, CreateVizState, VizEffect } from "../VisualizationManager"
import { renderAnnotations } from "../Viewport/Components/Timeline"

export const DEFAULT_ANNOTATION_PROPERTIES = {
    type: '',
    text: 'New annotation',
    description: '',
    start_time: 0,
    end_time: 0,
    modalities: [],
    color: "#FF0000",
    opacity: 0.2,
    tags: []
}

export const useNewAnnotationProperties = CreateVizState("newAnnotationProperties", DEFAULT_ANNOTATION_PROPERTIES)


/**
 * @name Annotation
 * 
 * @description Container for annotation data
 */
export class Annotation { 

    /**
     * @param {string} id
     * @param {string} user_id 
     * @param {string} group_id 
     * @param {string} type 
     * @param {string} text 
     * @param {string} description
     * @param {Timestamp} start_time 
     * @param {Timestamp} end_time 
     * @param {Timestamp} timestamp 
     * @param {Array<String>} modalities 
     * @param {bool} loaded 
     * @param {Array<String>} tags
     */
    constructor (id=null, user_id=null, group_id=null, type=null, text=null, description=null, start_time=null, end_time=null, modalities=null, color=null, opacity=null, tags=null, timestamp=null, user_email=null, user_full_name=null, loaded=true) {
        this.id = id
        this.user_id = user_id
        this.group_id = group_id
        this.loaded = loaded

        // deep object copy
        const NEW_ANNOTATION_PROPS = JSON.parse(JSON.stringify(VisualizationManager.newAnnotationProperties))

        this.type = type ?? NEW_ANNOTATION_PROPS.type ?? DEFAULT_ANNOTATION_PROPERTIES.type
        this.text = text ?? NEW_ANNOTATION_PROPS.text ?? DEFAULT_ANNOTATION_PROPERTIES.text
        this.description = description ?? NEW_ANNOTATION_PROPS.description ?? DEFAULT_ANNOTATION_PROPERTIES.description
        this.start_time = start_time ?? NEW_ANNOTATION_PROPS.start_time ?? DEFAULT_ANNOTATION_PROPERTIES.start_time
        this.end_time = end_time ?? NEW_ANNOTATION_PROPS.end_time ?? DEFAULT_ANNOTATION_PROPERTIES.end_time
        this.modalities = modalities ?? NEW_ANNOTATION_PROPS.modalities ?? DEFAULT_ANNOTATION_PROPERTIES.modalities

        this.color = color ?? NEW_ANNOTATION_PROPS.color ?? DEFAULT_ANNOTATION_PROPERTIES.color
        this.opacity = opacity ?? NEW_ANNOTATION_PROPS.opacity ?? DEFAULT_ANNOTATION_PROPERTIES.opacity
        this.tags = tags ?? NEW_ANNOTATION_PROPS.tags ?? DEFAULT_ANNOTATION_PROPERTIES.tags
        this.timestamp = timestamp ?? Date.now()
        this.user_email = user_email ?? ""
        this.user_full_name = !user_full_name || user_full_name?.trim() === "" ? "Unknown" : user_full_name
    }

    static fromConfig(config) {

        const { user, group_id, type, text, description, start_time, end_time, modalities, color, opacity, tags, loaded, id } = {} 
        console.log("Loaded Annotation from config", {config})
        let configType = config.type ? config.type : config.type === '' ? "" : config.data?.type ? config.data?.type : ""
        let configText = config.text ? config.text : config.text === '' ? "" : config.data?.text ? config.data?.text : ""
        let configDescription = config.description ? config.description : config.description === '' ? "" : config.data?.description ? config.data?.description : ""
        
        return new Annotation(config.id ? config.id : `TEMP-${Math.random()}-${Date.now()}`, user, group_id, configType, configText, configDescription, config.start_time ? config.start_time : start_time, config.end_time ? config.end_time : end_time, config.modalities ? config.modalities : config.data?.modalities, config.color ? config.color : config.data?.color, config.opacity ? config.opacity : config.data?.opacity, config.tags ? config.tags : config.data?.tags, loaded)
    }

    static fromJSON(json) {
        return ResponseMetaToAnnotation(json)
    }

    toJSON() {
        return AnnotationToRequestJSON(this)
    }

    /**
     * Returns a copy of this annotation
     */
    copy () {
        const newAnnotation = new Annotation(this.id, this.user_id, this.group_id, this.type, this.text, this.description, this.start_time, this.end_time, this.modalities, this.color, this.opacity, this.tags)
        newAnnotation.timestamp = this.timestamp

        newAnnotation.color = this.color
        newAnnotation.opacity = this.opacity
        return newAnnotation
    }

    /**
     * An annotation is considered modality-specific if its modality field contains any modalities. Otherwise if its modality array is empty it is a whole annotation
     */
    isModalitySpecific() {
        return this.modalities?.length > 0
    }
     

    /**
     * An annotation is a point annotation if its start time equals its end time
     */
    isPointAnnotation() {
        return this.start_time === this.end_time
    }
}



/**
 * @name Annotations
 * @category Visualization
 * @class
 * 
 * @description Top level interface for Annotations
 * 
 */
export default class Annotations {

    static init (USER_EMAIL) {
        this.USER_EMAIL = USER_EMAIL
        const DEFAULT_GROUP_NAME = 'Default Session'

        DataQuery.GET_ANNOTATION_GROUPS()
        .then(groups => { 
            // If file has no annotation groups, then create one
            if (groups.length === 0) {
                DataQuery.CREATE_ANNOTATION_GROUP(DEFAULT_GROUP_NAME).then(() => {
                    DataQuery.GET_ANNOTATION_GROUPS()
                    .then(groups => {
                        VisualizationManager.setAnnotationGroups(groups)
                        VisualizationManager.setCurrentAnnotationGroup(VisualizationManager.annotationGroups[0])
                    })
                })
            } else {
                VisualizationManager.setAnnotationGroups(groups)
                VisualizationManager.setCurrentAnnotationGroup(VisualizationManager.annotationGroups[0])
            }

            // Then get all annotations
            // return DataQuery.GET_ANNOTATIONS(groups.map(group => group.group_name))
        })
        // .then(annotations => VisualizationManager.annotationsDispatch({type:'addAnnotations', annotations: Object.values(annotations).flat().map(ResponseMetaToAnnotation)}))
        .catch(e => {
            console.log('ERROR: Unable to load file annotation groups')
        })
    }


    static loadAnnotations(annotationsJSON) {
        VisualizationManager.annotationsDispatch({
            type:'addAnnotations',
            annotations: annotationsJSON.map(annotationJSON => Annotation.fromConfig({...annotationJSON, loaded: true}))
        })
    }


    static unloadAnnotations(annotations) {
        VisualizationManager.annotationsDispatch({
            type:'deleteAnnotations',
            annotations
        })
    }


    /**
     * @name createAnnotation
     * 
     * @description Creates a new Annotation 
     * 
     * @param {Object} annotation 
     */
    static createAnnotation (annotation) {
        // Get current annotation group
        const group_id = VisualizationManager.currentAnnotationGroup?.id

        // Temporary Annotation with id "TEMP"

        const newAnnotation = new Annotation(`TEMP-${Math.random()}`)
        Object.assign(newAnnotation, annotation)
        newAnnotation.group_id = group_id

        // Add temporary annotation
        VisualizationManager.annotationsDispatch({type:'addAnnotation', annotation: newAnnotation})

        // Add annotation in data query
        DataQuery.ADD_ANNOTATION(
            group_id,
            AnnotationToRequestJSON(newAnnotation),
            this.USER_EMAIL //currentUserEmail
            ).then(res => {
                // If successful
                const createdAnnotation = newAnnotation.copy()
                createdAnnotation.id = res.id.toString()
                VisualizationManager.annotationsDispatch({type:'addAnnotation', annotation: createdAnnotation})
                console.log('Adding annotation to data query successful.')
            })
            .then(DataQuery.GET_ANNOTATION_GROUPS)
            .then(res => VisualizationManager.setAnnotationGroups(res))
            .catch(err => {
                // If unsuccessful
                VisualizationManager.annotationsDispatch({type:'deleteAnnotation', annotation: newAnnotation})
                console.error('Adding annotation to data query unsuccessful.')
                console.error(err)
            })
            .finally(() => {
                // Delete temp annotation regardless if successful or unsuccessful
                VisualizationManager.annotationsDispatch({type:'deleteAnnotation', annotation: newAnnotation})
                console.log("Finished updating annotation!")
            })

        // VisualizationManager.annotationGroupsDispatch({type:'update'})
    }

    static cloneAnnotation(annotation) {
        alert('Implement me!')
    }

    static deleteAnnotation (annotation) {
        // Delete annotation from VisualizationManager
        VisualizationManager.annotationsDispatch({type:'deleteAnnotation', annotation: annotation})

        // Delete annotation from data query
        DataQuery.DELETE_ANNOTATIONS([Number(annotation.id)])
        .then(res => {
            console.log('Deleting annotation successful')
        })
        .then(DataQuery.GET_ANNOTATION_GROUPS)
        .then(res => VisualizationManager.setAnnotationGroups(res))
        .catch(res => {
            // If unsuccessful, readd annotation to VisualizationManager
            VisualizationManager.annotationsDispatch({type:'addAnnotation', annotation: annotation})
            console.error('Deleting annotation unsuccessful')
        })
    }

    static deleteAnnotations (annotations) {
        // Delete annotation from VisualizationManager
        VisualizationManager.annotationsDispatch({type:'deleteAnnotations', annotations: annotations})

        // Delete annotation from data query
        DataQuery.DELETE_ANNOTATIONS(annotations.map(annotation => Number(annotation.id)))
        .then(res => {
            console.log('Deleting annotations successful')
        })
        .then(DataQuery.GET_ANNOTATION_GROUPS)
        .then(res => VisualizationManager.setAnnotationGroups(res))
        .catch(res => {
            // If unsuccessful, readd annotation to VisualizationManager
            VisualizationManager.annotationsDispatch({type:'addAnnotations', annotations: annotations})
            console.error('Deleting annotations unsuccessful')
        })
    }
    


    static modifyAnnotation (annotation, data) {
        // Cache unmodified annotation
        const saved_annotation = annotation.copy()

        // Modify annotation
        Object.assign(annotation, data)
    
        const group_id = annotation.group_id
        const request_json = AnnotationToRequestJSON(annotation)
        request_json.id = annotation.id
    
        // Rerender annotations with empty update
        VisualizationManager.annotationsDispatch({})

        // Modify annotation in data query
        DataQuery.MODIFY_ANNOTATION(group_id, request_json)
        .then(res => {
            console.log('Modifying annotation successful')
        })
        .then(DataQuery.GET_ANNOTATION_GROUPS)
        .then(res => VisualizationManager.setAnnotationGroups(res))
        .catch(res => {
            VisualizationManager.annotationsDispatch({type:'addAnnotation', annotation: saved_annotation})
            console.error('Modifying annotation unsuccessful')
        })
    }


    static updateAnnotationGroups () {
        DataQuery.GET_ANNOTATION_GROUPS().then(res => VisualizationManager.setAnnotationGroups(res))
    }

    static getGroupAnnotations (group) {
        return DataQuery.GET_ANNOTATIONS([group.id]).then(annotations => Object.values(annotations).flat().map(ResponseMetaToAnnotation))
    }

    static deleteGroupAnnotations (group) {
        DataQuery.GET_ANNOTATIONS([group.id])
        .then(annotations => Object.values(annotations).flat().map(ResponseMetaToAnnotation))
        .then(annotations => annotations.map(ann => Number(ann.id)))
        .then(annotation_ids => DataQuery.DELETE_ANNOTATIONS(annotation_ids))
        .then(DataQuery.GET_ANNOTATION_GROUPS)
        .then(res => {
            VisualizationManager.setAnnotationGroups(res)
            VisualizationManager.annotationsDispatch({type:'deleteAnnotations', annotations: Object.values(VisualizationManager.annotations).filter(annotation => annotation.group_id === group.id)})
        })
        .catch (res => {
            console.log('Unsuccessful')
        })
    }

    static loadAnnotationGroups (group_ids) {
        return DataQuery.GET_ANNOTATIONS(group_ids)
        .then(groups => 
            VisualizationManager.annotationsDispatch({
                type:'addAnnotations',
                annotations: Object.values(groups).flat().map(json => {
                    const annotation = ResponseMetaToAnnotation(json)
                    annotation.loaded = true
                    return annotation
                })
            })
        )
    }

    static unloadAnnotationGroups (group_ids) {
        VisualizationManager.annotationsDispatch({
            type: 'deleteAnnotations', 
            annotations:
                Object.values(VisualizationManager.annotations)
                .filter(annotation => group_ids.includes(annotation.group_id))
        })
    }


    static createGroup = (group_name) => 
        DataQuery.CREATE_ANNOTATION_GROUP(group_name)
            .then(DataQuery.GET_ANNOTATION_GROUPS)
            .then(res =>{
                VisualizationManager.setAnnotationGroups(res)
                return res
            })//.then(res => callback ? callback(res) : res)
            .catch(res => console.log('Create Annotation Group Failed', res))
    
    static renameGroup = (group_id, new_group_name) => 
        DataQuery.RENAME_ANNOTATION_GROUP(group_id, new_group_name)
            .then(DataQuery.GET_ANNOTATION_GROUPS)
            .then(groups =>{
                VisualizationManager.setAnnotationGroups(groups)
                return groups.filter(g=>g.group_name === new_group_name)[0]
            })
            .catch(error => console.log('Rename Annotation Group Failed', error))


    static validateGroupName = (group_name) => // if the group name is not used and not empty, then it is valid
        DataQuery.GET_ANNOTATION_GROUPS()
        .then(res => !res.map(g=>g.group_name).includes(group_name) && group_name!=="")
        .then(res => {
            if (!res) {window.alert("Invalid annotation session name! Annotation session name can not be empty or repeated.")}
            return res
        })

    static deleteGroup (group) {
        DataQuery.DELETE_ANNOTATION_GROUP(group.id)
        .then(DataQuery.GET_ANNOTATION_GROUPS)
        .then(res => {
            VisualizationManager.setAnnotationGroups(res)
            VisualizationManager.setSelectedAnnotationGroup(null)
        })
        .catch(res => console.log('Deleting Annotation Group Failed'))
    }
}


// VisualizationManager states

// {annotationGroupId: {anntationId: *displayed/loaded annotation, ...}, ...}

/**
 * @name AnnotationsReducer
 * 
 * 
 * @param {*} state 
 * @param {*} action 
 * @returns {any} new state
 */
 function AnnotationsReducer (state, action) {
    const nextState = {...state}
    switch(action.type) {
        case 'addAnnotation':
            nextState[action.annotation.id] = action.annotation
            break
        case 'addAnnotations':
            for (const annotation of action.annotations) {
                nextState[annotation.id] = annotation
            }
            break
        case 'deleteAnnotation':
            delete nextState[action.annotation.id]
            break
        case 'deleteAnnotations':
            for (const annotation of action.annotations) {
                delete nextState[annotation.id]
            }
            break
        default:
    }
    return nextState
}
// All the annotations displayed/loaded on the Visualization Page
export const useAnnotations = CreateVizReducer('annotations', AnnotationsReducer, [])
VizEffect(annotations => {
    VisualizationManager.displays.forEach(display => {
        display.graphs.renderAnnotations()
        renderAnnotations(display)
        display.graphs.render()
    })
}, 'annotations')

export const useAnnotationGroups = CreateVizState('annotationGroups', [])

VizEffect(annotationGroups => {
    const selected = VisualizationManager.selectedAnnotationGroup
    if (selected) {
        const indx = annotationGroups.map(group => group.group_name).indexOf(selected.group_name)
        VisualizationManager.setSelectedAnnotationGroup(indx > -1 ? annotationGroups[indx] : null)
    }
}, 'annotationGroups')

export const useSelectedAnnotationGroup = CreateVizState('selectedAnnotationGroup', null)
export const useCurrentAnnotationGroup = CreateVizState('currentAnnotationGroup', null)
export const useSelectedAnnotation = CreateVizState('selectedAnnotation', null)
export const useAnnotationModalOpen = CreateVizState('annotationModalOpen', false) 
export const useHoveredAnnotation = CreateVizState('hoveredAnnotationId', null)



// Data Query helpers

function AnnotationToRequestJSON (annotation) {
    return {
        id: annotation.id,
        user_id: annotation.user_id,
        annotation_text: annotation.text,
        annotation_type: annotation.type,
        timestamp: annotation.timestamp,
        measurements: annotation.modalities,
        _start_time: annotation.start_time,
        _end_time: annotation.end_time,
        color: annotation.color,
        opacity: annotation.opacity,
        annotation_description: annotation.description,
        tags: annotation.tags
    }
}

function ResponseMetaToAnnotation (meta) {
    const {
        id,
        annotation_text, 
        annotation_type,
        annotation_description,
        start_time,
        end_time,
        timestamp,
        modalities,
        group_id,
        color,
        tags,
        opacity,
        user_id,
        user_email,
        user_full_name
    } = meta

    const loaded = false

    const annotation = new Annotation(
        id,
        user_id,
        group_id,
        annotation_type,
        annotation_text,
        annotation_description,
        (new Date(start_time)).getTime(),
        (new Date(end_time)).getTime(),
        modalities,
        color,
        opacity,
        tags,
        timestamp,
        user_email,
        user_full_name,
        loaded   
    )

    annotation.timestamp = new Date(timestamp).getTime()

    return annotation
}
