import { Container } from './Container.js'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'
import { useEffect, useState } from 'react'


export const DragAndDropCanvas = ({ props }) => {
  const [,windowResize] = useState()
  const { state, setState, setCanvasViewBoxPosition } = props 

  useEffect(() => {
		window.addEventListener("resize", windowResize())
		return () => {
			window.removeEventListener("resize",  windowResize())
		}
	}, [])

  return (<DndProvider backend={HTML5Backend}>
    <div style={{display: "flex", justifyContent: "center", padding: "20px"}}>
      <Container props={{state, setState, setCanvasViewBoxPosition}} />
    </div>
    
  </DndProvider>)
}
