import { useRef, useState, MouseEvent } from "react"
import { MobergMenuController, useMobergMenu } from "../../Hooks/useMobergMenu"
import React from "react"
import { MdDelete, MdDriveFileRenameOutline, MdOutlineContentCopy } from "react-icons/md"
import styled from "styled-components"
import { MobergAnimationTiming, MobergAnimationCurve } from "../MobergAnimation/MobergAnimation"
import { MobergIconSize } from "../MobergIcon/MobergIcon"
import { MobergRow } from "../MobergLayout/MobergRow"
import { MobergTheme } from "../MobergThemes/MobergColors"
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal"
import { LayoutGroup } from "../../Pages/Data/Visualize/DataReview/Types/Layout"
import { useLayoutService } from "../../Hooks/useLayoutService"
import { useModalProvider } from "../../Providers/ModalProvider"
import { MobergButton, MobergButtonShape, MobergHoverRow, MobergSubMenuBoundary } from "../../Moberg"
import { selectedLayoutGroupAtom } from "../../Pages/Data/Visualize/DataReview/Atoms/Layout"
import { useRecoilState } from "recoil"
import { RenameDisplayGroup } from "./RenameDisplayGroup"
import { CopyDisplayGroup } from "./CopyDisplayGroup"

type DisplayGroupDropdownRowProps = {
	parentMenu: MobergMenuController
	displayGroup: LayoutGroup
}

type DisappearProps = {
	isEditing: boolean
}

export const DisplayGroupDropdownRow: React.FC<DisplayGroupDropdownRowProps> = ({ displayGroup, parentMenu }) => {
	const rowRef = useRef<HTMLDivElement>(null)
	const { deleteLayoutGroup } = useLayoutService()
	const { createModal } = useModalProvider()
	const [selectedDisplayGroup, setSelectedDisplayGroup] = useRecoilState(selectedLayoutGroupAtom)
    const [menuContent, setMenuContent] = useState<string>()
    const menu = useMobergMenu({ placement: "right-end", anchorRef: rowRef })
	const isActive = selectedDisplayGroup?.id === displayGroup.id
	const isEditing = menu.isOpen

	const handleDelete = () => {
		parentMenu.setClickOutsideClose(false)

		createModal(
			<ConfirmationModal
				title={"Delete display group?"}
				description={`Are you sure you want to permanently delete ${displayGroup.name}? This action cannot be undone.`}
				confirmButton={{
					text: "Yes, delete",
					theme: MobergTheme.RED,
					onClick: () => deleteLayoutGroup(displayGroup.id)
				}}
				afterClose={() => {
					parentMenu.setClickOutsideClose(true)
				}}
			/>,
			{ zIndex: 10001 }
		)
	}

    const handleMenuOpen = (content: string) => {
        menu.open()
        setMenuContent(content)
    }

    const getMenuContent = () => {
        switch (menuContent) {
            case "rename": return <RenameDisplayGroup displayGroup={displayGroup} menu={menu} />
            case "copy": return <CopyDisplayGroup displayGroup={displayGroup} menu={menu} />
            default: 
                return null
        }
    }

	const handleSwitchDisplayGroup = (event: MouseEvent<HTMLDivElement>) => {
		event.stopPropagation() // prevents the click from closing the menu. Recoil state updates differently than other React state.
		setSelectedDisplayGroup(displayGroup)
	}

	const buildActionButton = (icon: JSX.Element, action: () => any, key: string) => {
		return (
			<MobergButton 
				onClick={action}
				shape={MobergButtonShape.SQUARE}
				theme={isActive ? MobergTheme.ON_BLUE : MobergTheme.ON_GRAY}
				active={isEditing && menuContent?.toLowerCase() === key.toLowerCase()}
				tooltip={key}
				style={{ padding: "5px" }}
			>
				{icon}
			</MobergButton>
		)
	}

	return (
		<Disappear ref={rowRef} isEditing={isEditing}>
			<div style={{ paddingRight: "8px" }}>
				<MobergHoverRow active={isActive || isEditing} theme={isActive ? MobergTheme.BLUE : MobergTheme.BLACK}>
					<MobergRow gap="16px" horizontalAlign="space-between">
						<div style={{ display: "flex", flex: 1, alignItems: "center", height: "48px" }} onClick={handleSwitchDisplayGroup}>
							{displayGroup.name}
						</div>

						<div className="disappear">
							<MobergRow horizontalAlign="right">
								{buildActionButton(<MdDriveFileRenameOutline size={MobergIconSize.REGULAR} />, () => handleMenuOpen("rename"), "Rename")}
								{buildActionButton(<MdOutlineContentCopy size={MobergIconSize.REGULAR} />, () => handleMenuOpen("copy"), "Copy")}
								{buildActionButton(<MdDelete size={MobergIconSize.REGULAR} />, handleDelete, "Delete")}
							</MobergRow>
						</div>
					</MobergRow>
				</MobergHoverRow>
			</div>

            <MobergSubMenuBoundary>
                <menu.MobergMenu style={{ padding: "16px", marginLeft: "10px"}}>
                    {getMenuContent()}
                </menu.MobergMenu>
            </MobergSubMenuBoundary>
		</Disappear>
	)
}

const Disappear = styled.div<DisappearProps>`
	.disappear {
		transition: opacity ${MobergAnimationTiming.VERY_FAST} ${MobergAnimationCurve.EASE_IN};
		opacity: ${({ isEditing }) => isEditing ? 1 : 0};
	}

	&:hover .disappear {
		opacity: 1;
	}
`
