import { PEARSON } from "./PearsonNode"
import { POLYNOMIAL_FIT } from "./PolynomialFitNode"
import { POLYNOMIAL_MINIMIZE } from "./PolynomialMinimizeNode"
import { RMSD } from "./RMSDNode"
import { SUBTRACT_TIME_SERIES } from "./SubtractTimeSeriesNode"
import { USER_INPUT_TYPES } from "../../UserInputTypes"
import { ARGMIN } from "./ArgminNode"
import { WEIGHTED_MEAN } from "./WeightedMeanNode"


export const ANALYTIC_NODES = {
    DESCRIPTION: "These modules are responsible for calculating basic statistics, analytics, and inferences on the given data.",
    MEAN: {
        INPUTS: [
            {
                DISPLAY_NAME: "Resampling rate",
                VARIABLE_NAME: "resample",
                TYPE: USER_INPUT_TYPES.DURATION_INPUT,
                DEFAULT_VALUE: {"seconds": 5},
                DESCRIPTION: "The time series will be compressed into chunks of this size. The mean of the chunk will represent the compressed data point.",
            },
        ],
        DISPLAY_NAME: "Mean",
        PRODUCT_LEVEL: "Standard",
        DESCRIPTION: "This module is responsible for calculating the mean of a given input modality.",
        KEYWORDS: ["mean", "average", "METRICS"],
        TYPE: 'METRICS',
        DATA_INPUT_TYPES: ["time_series"],
        DATA_OUTPUT_TYPE: "value"
    },
    // "WEIGHTEDMEAN": {
    // 	"INPUTS": [
    // 		{
    // 			DISPLAY_NAME: "Resampling rate",
    // 			VARIABLE_NAME: "resample",
    // 			TYPE: USER_INPUT_TYPES.DURATION_INPUT,
    // 			DEFAULT_VALUE: {"seconds": 0},
    // 			DESCRIPTION: "The time series will be compressed into chunks of this size. The mean of the chunk will represent the compressed data point.",
    // 			ADVANCED_INPUT: true
    // 		},	
    // 		{
    // 			DISPLAY_NAME: "Rolling",
    // 			VARIABLE_NAME: "rolling",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: false,
    // 			DESCRIPTION: "This field specifies the metric will be taken on a rolling basis. If this is set to true, then the resampling rate must also be specified. In other words, the metric will be calculated on a rolling basis of the frequency identified by the resampling rate.",
    // 			ADVANCED_INPUT: true
    // 		},
    // 		{
    // 			DISPLAY_NAME: "Verbose",
    // 			VARIABLE_NAME: "verbose",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: false,
    // 			DESCRIPTION: "This field specifies whether activity of this module will be logged to on the server's file system.",
    // 			ADVANCED_INPUT: true
    // 		}	
    // 	],
    // 	"OUTPUTS": [

    // 	],
    // 	DISPLAY_NAME: "Calculate weighted mean",
    // 	PRODUCT_LEVEL: "Standard",
    // 	DESCRIPTION: "This module is responsible for calculating the weighted mean of a given input modality.",
    // 	KEYWORDS: [
    // 		"METRICS"
    // 		],
    // 	TYPE: 'METRICS'
    // },
    // "HARMONICMEAN": {
    // 	"INPUTS": [
    // 		{
    // 			DISPLAY_NAME: "Resampling rate",
    // 			VARIABLE_NAME: "resample",
    // 			TYPE: USER_INPUT_TYPES.DURATION_INPUT,
    // 			DEFAULT_VALUE: {"seconds": 0},
    // 			DESCRIPTION: "This field indicates with that frequency the following metric will be calculated at. The default none identified by an empty field. However, it may be set to parameters like `1 m` to specify that this metric will be calculated for each minute.",
    // 			ADVANCED_INPUT: true
    // 		},	
    // 		{
    // 			DISPLAY_NAME: "Rolling",
    // 			VARIABLE_NAME: "rolling",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: false,
    // 			DESCRIPTION: "This field specifies the metric will be taken on a rolling basis. If this is set to true, then the resampling rate must also be specified. In other words, the metric will be calculated on a rolling basis of the frequency identified by the resampling rate.",
    // 			ADVANCED_INPUT: true
    // 		},
    // 		{
    // 			DISPLAY_NAME: "Verbose",
    // 			VARIABLE_NAME: "verbose",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: false,
    // 			DESCRIPTION: "This field specifies whether activity of this module will be logged to on the server's file system.",
    // 			ADVANCED_INPUT: true
    // 		}	
    // 	],
    // 	"OUTPUTS": [

    // 	],
    // 	DISPLAY_NAME: "Calculate harmonic mean",
    // 	PRODUCT_LEVEL: "Standard",
    // 	DESCRIPTION: "This module is responsible for calculating the harmonic mean of a given input modality.",
    // 	KEYWORDS: [
    // 		"METRICS"
    // 		],
    // 	TYPE: 'METRICS'
    // },
    // "GEOMETRICMEAN": {
    // 	"INPUTS": [
    // 		{
    // 			DISPLAY_NAME: "Resampling rate",
    // 			VARIABLE_NAME: "resample",
    // 			TYPE: USER_INPUT_TYPES.DURATION_INPUT,
    // 			DEFAULT_VALUE: {"seconds": 0},
    // 			DESCRIPTION: "This field indicates with that frequency the following metric will be calculated at. The default none identified by an empty field. However, it may be set to parameters like `1 m` to specify that this metric will be calculated for each minute.",
    // 			ADVANCED_INPUT: true
    // 		},	
    // 		{
    // 			DISPLAY_NAME: "Rolling",
    // 			VARIABLE_NAME: "rolling",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: false,
    // 			DESCRIPTION: "This field specifies the metric will be taken on a rolling basis. If this is set to true, then the resampling rate must also be specified. In other words, the metric will be calculated on a rolling basis of the frequency identified by the resampling rate.",
    // 			ADVANCED_INPUT: true
    // 		},
    // 		{
    // 			DISPLAY_NAME: "Verbose",
    // 			VARIABLE_NAME: "verbose",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: false,
    // 			DESCRIPTION: "This field specifies whether activity of this module will be logged to on the server's file system.",
    // 			ADVANCED_INPUT: true
    // 		}	
    // 	],
    // 	"OUTPUTS": [

    // 	],
    // 	DISPLAY_NAME: "Calculate geometric mean",
    // 	PRODUCT_LEVEL: "Standard",
    // 	DESCRIPTION: "This module is responsible for calculating the geometric of a given input modality.",
    // 	KEYWORDS: [
    // 		"METRICS"
    // 		],
    // 	TYPE: 'METRICS'
    // },
    MEDIAN: {
        DATA_INPUT_TYPES: ["time_series"],
        DATA_OUTPUT_TYPE: "value",
        INPUTS: [
            {
                DISPLAY_NAME: "Resampling rate",
                VARIABLE_NAME: "resample",
                TYPE: USER_INPUT_TYPES.DURATION_INPUT,
                DEFAULT_VALUE: {"seconds": 5},
                DESCRIPTION: "The desired resampling rate. If the resampling rate is larger than adjacent data, the average of that data.",
                ADVANCED_INPUT: true
            },
        ],
        DISPLAY_NAME: "Median",
        PRODUCT_LEVEL: "Standard",
        DESCRIPTION: "This module is responsible for calculating the median of a given input modality.",
        KEYWORDS: ["median", "med", "METRICS"],
        TYPE: 'METRICS'
    },
    MODE: {
        DATA_INPUT_TYPES: ["time_series"],
        DATA_OUTPUT_TYPE: "value",
        INPUTS: [
            {
                DISPLAY_NAME: "Resampling rate",
                VARIABLE_NAME: "resample",
                TYPE: USER_INPUT_TYPES.DURATION_INPUT,
                DEFAULT_VALUE: {"seconds": 5},
                DESCRIPTION: "The desired resampling rate. If the resampling rate is larger than adjacent data, the average of that data.",
                ADVANCED_INPUT: true
            },	
        ],
        DISPLAY_NAME: "Mode",
        PRODUCT_LEVEL: "Standard",
        DESCRIPTION: "This module is responsible for calculating the mode of a given input modality.",
        KEYWORDS: ["mode", "METRICS"],
        TYPE: 'METRICS'
    },
    VARIANCE: {
        DATA_INPUT_TYPES: ["time_series"],
        DATA_OUTPUT_TYPE: "value",
        INPUTS: [
            {
                DISPLAY_NAME: "Resampling rate",
                VARIABLE_NAME: "resample",
                TYPE: USER_INPUT_TYPES.DURATION_INPUT,
                DEFAULT_VALUE: {"seconds": 5},
                DESCRIPTION: "This field indicates with that frequency the following metric will be calculated at. The default none identified by an empty field. However, it may be set to parameters like `1 m` to specify that this metric will be calculated for each minute.",
                ADVANCED_INPUT: true
            },	
            {
                DISPLAY_NAME: "Delta degrees of freedom",
                VARIABLE_NAME: "delta_degrees_of_freedom",
                TYPE: "NUMBER_INPUT",
                DEFAULT_VALUE: 1,
                DESCRIPTION: "The number of degrees of freedom away from N. IF the degrees of freedom is N - 1, 1 is the delta degrees of freedom. DDOF of 1 indicates population variance.",
                ADVANCED_INPUT: true
            },
        ],
        DISPLAY_NAME: "Variance",
        PRODUCT_LEVEL: "Standard",
        DESCRIPTION: "This module is responsible for calculating the variance of a given input modality.",
        KEYWORDS: ["variance", "stats", "METRICS"],
        TYPE: 'METRICS'
    },
    SKEW: {
        DATA_INPUT_TYPES: ["time_series"],
        DATA_OUTPUT_TYPE: "value",
        INPUTS: [
            {
                DISPLAY_NAME: "Resampling rate",
                VARIABLE_NAME: "resample",
                TYPE: USER_INPUT_TYPES.DURATION_INPUT,
                DEFAULT_VALUE: {"seconds": 5},
                DESCRIPTION: "This field indicates with that frequency the following metric will be calculated at. The default none identified by an empty field. However, it may be set to parameters like `1 m` to specify that this metric will be calculated for each minute.",
                ADVANCED_INPUT: true
            },	
            {
                DISPLAY_NAME: "Account for bias",
                VARIABLE_NAME: "bias",
                TYPE: "CHECKBOX_INPUT",
                DEFAULT_VALUE: false,
                DESCRIPTION: "This field indicates whether the calculations are corrected for statistical bias.",
                ADVANCED_INPUT: true
            },
        ],
        DISPLAY_NAME: "Skew",
        PRODUCT_LEVEL: "Standard",
        DESCRIPTION: "This module is responsible for calculating the skew of a given input modality.",
        KEYWORDS: ["skew", "METRICS"],
        TYPE: 'METRICS'
    },
    QUANTILES: {
        DATA_INPUT_TYPES: ["time_series"],
        DATA_OUTPUT_TYPE: "list_of_type_value",
        INPUTS: [
            {
                DISPLAY_NAME: "Resampling rate",
                VARIABLE_NAME: "resample",
                TYPE: USER_INPUT_TYPES.DURATION_INPUT,
                DEFAULT_VALUE: {"seconds": 5},
                DESCRIPTION: "This field indicates with that frequency the following metric will be calculated at. The default none identified by an empty field. However, it may be set to parameters like `1 m` to specify that this metric will be calculated for each minute.",
                ADVANCED_INPUT: true
            },
            {
                DISPLAY_NAME: "Number of quantiles",
                VARIABLE_NAME: "n",
                TYPE: "NUMBER_INPUT",
                DEFAULT_VALUE: 4,
                DESCRIPTION: "This field specifies how many quartiles will be calculated from the data.",
                ADVANCED_INPUT: false
            },
            {
                DISPLAY_NAME: "Method",
                VARIABLE_NAME: "method",
                TYPE: "DROPDOWN_INPUT",
                OPTIONS: ["inclusive", "exclusive"],
                DEFAULT_VALUE: "inclusive",
                DESCRIPTION: "This field specifies whether activity of this module will be logged to on the server's file system.",
                ADVANCED_INPUT: true
            },	
        ],
        DISPLAY_NAME: "Quantiles",
        PRODUCT_LEVEL: "Standard",
        DESCRIPTION: "This module is responsible for calculating the quartile of a given input modality.",
        KEYWORDS: ["quartile", "quantile", "METRICS"],
        TYPE: 'METRICS',
    },
    INTERQUARTILE_RANGE: {
        INPUTS: [
            {
                DISPLAY_NAME: "Resampling rate",
                VARIABLE_NAME: "resample",
                TYPE: USER_INPUT_TYPES.DURATION_INPUT,
                DEFAULT_VALUE: {"seconds": 5},
                DESCRIPTION: "This field indicates with that frequency the following metric will be calculated at. The default none identified by an empty field. However, it may be set to parameters like `1 m` to specify that this metric will be calculated for each minute.",
                ADVANCED_INPUT: true
            },		
        ],
        DATA_INPUT_TYPES: ["time_series"],
        DATA_OUTPUT_TYPE: "value",
        DISPLAY_NAME: "Interquartile range",
        PRODUCT_LEVEL: "Standard",
        DESCRIPTION: "This module is responsible for calculating the interquartile range of a given input modality.",
        KEYWORDS: ["METRICS", "range", "quartile"],
        TYPE: 'METRICS'
    },
    RANGE: {
        DATA_INPUT_TYPES: ["time_series"],
        DATA_OUTPUT_TYPE: "value",
        INPUTS: [
            {
                DISPLAY_NAME: "Resampling rate",
                VARIABLE_NAME: "resample",
                TYPE: USER_INPUT_TYPES.DURATION_INPUT,
                DEFAULT_VALUE: {"seconds": 5},
                DESCRIPTION: "This field indicates with that frequency the following metric will be calculated at. The default none identified by an empty field. However, it may be set to parameters like `1 m` to specify that this metric will be calculated for each minute.",
                ADVANCED_INPUT: true
            },	
        ],
        DISPLAY_NAME: "Range",
        PRODUCT_LEVEL: "Standard",
        DESCRIPTION: "This module is responsible for calculating the range of a given input modality.",
        KEYWORDS: ["range", "METRICS"],
        TYPE: 'METRICS'
    },
    // "COVARIANCE": {
    // 	"INPUTS": [
    // 		{
    // 			DISPLAY_NAME: "Verbose",
    // 			VARIABLE_NAME: "verbose",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: false,
    // 			DESCRIPTION: "This field specifies whether activity of this module will be logged to on the server's file system.",
    // 			ADVANCED_INPUT: true
    // 		}	
    // 	],
    // 	"OUTPUTS": [

    // 	],
    // 	DISPLAY_NAME: "Calculate covariance",
    // 	PRODUCT_LEVEL: "Standard",
    // 	DESCRIPTION: "This module is responsible for calculating the covariance of a given input modality.",
    // 	KEYWORDS: [
    // 		"METRICS"
    // 		],
    // 	TYPE: 'METRICS'
    // },
    // "SHAPIRO": {
    // 	"INPUTS": [
    // 		{
    // 			DISPLAY_NAME: "Verbose",
    // 			VARIABLE_NAME: "verbose",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: false,
    // 			DESCRIPTION: "This field specifies whether activity of this module will be logged to on the server's file system.",
    // 			ADVANCED_INPUT: true
    // 		}	
    // 	],
    // 	"OUTPUTS": [

    // 	],
    // 	DISPLAY_NAME: "Run Shapiro test",
    // 	PRODUCT_LEVEL: "Standard",
    // 	DESCRIPTION: "This module is responsible for calculating the Shapiro test statistic of a given input modality.",
    // 	KEYWORDS: [
    // 		"STATISTIC TEST"
    // 		]	
    // },
    // "NORMALTTEST": {
    // 	"INPUTS": [
    // 		{
    // 			DISPLAY_NAME: "Verbose",
    // 			VARIABLE_NAME: "verbose",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: false,
    // 			DESCRIPTION: "This field specifies whether activity of this module will be logged to on the server's file system.",
    // 			ADVANCED_INPUT: true
    // 		}	
    // 	],
    // 	"OUTPUTS": [

    // 	],
    // 	DISPLAY_NAME: "Run Normal test",
    // 	PRODUCT_LEVEL: "Standard",
    // 	DESCRIPTION: "This module is responsible for calculating the Normal Test test statistic of a given input modality.",
    // 	KEYWORDS: [
    // 		"STATISTIC TEST"
    // 		]	
    // },
    // "ANDERSON": {
    // 	"INPUTS": [
    // 		{
    // 			DISPLAY_NAME: "Verbose",
    // 			VARIABLE_NAME: "verbose",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: false,
    // 			DESCRIPTION: "This field specifies whether activity of this module will be logged to on the server's file system.",
    // 			ADVANCED_INPUT: true
    // 		}	
    // 	],
    // 	"OUTPUTS": [

    // 	],
    // 	DISPLAY_NAME: "Run Anderson test",
    // 	PRODUCT_LEVEL: "Standard",
    // 	DESCRIPTION: "This module is responsible for calculating the Anderson test statistic of a given input modality.",
    // 	KEYWORDS: [
    // 		"STATISTIC TEST"
    // 		]	
    // },		
    // "SPEARMAN": {
    // 	"INPUTS": [
    // 		{
    // 			DISPLAY_NAME: "Verbose",
    // 			VARIABLE_NAME: "verbose",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: false,
    // 			DESCRIPTION: "This field specifies whether activity of this module will be logged to on the server's file system.",
    // 			ADVANCED_INPUT: true
    // 		}	
    // 	],
    // 	"OUTPUTS": [

    // 	],
    // 	DISPLAY_NAME: "Run Spearman test",
    // 	PRODUCT_LEVEL: "Standard",
    // 	DESCRIPTION: "This module is responsible for calculating the Spearman test statistic of a given input modality.",
    // 	KEYWORDS: [
    // 		"STATISTIC TEST"
    // 		]	
    // },
    // "KENDAL": {
    // 	"INPUTS": [
    // 		{
    // 			DISPLAY_NAME: "Verbose",
    // 			VARIABLE_NAME: "verbose",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: false,
    // 			DESCRIPTION: "This field specifies whether activity of this module will be logged to on the server's file system.",
    // 			ADVANCED_INPUT: true
    // 		}	
    // 	],
    // 	"OUTPUTS": [

    // 	],
    // 	DISPLAY_NAME: "Run Kendal test",
    // 	PRODUCT_LEVEL: "Standard",
    // 	DESCRIPTION: "This module is responsible for calculating the Kendal test statistic of a given input modality.",
    // 	KEYWORDS: [
    // 		"STATISTIC TEST"
    // 		]	
    // },
    // "CHI": {
    // 	"INPUTS": [
    // 		{
    // 			DISPLAY_NAME: "Verbose",
    // 			VARIABLE_NAME: "verbose",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: false,
    // 			DESCRIPTION: "This field specifies whether activity of this module will be logged to on the server's file system.",
    // 			ADVANCED_INPUT: true
    // 		}	
    // 	],
    // 	"OUTPUTS": [

    // 	],
    // 	DISPLAY_NAME: "Run Chi Squared test",
    // 	PRODUCT_LEVEL: "Standard",
    // 	DESCRIPTION: "This module is responsible for calculating the Chi Squared test statistic of a given input modality.",
    // 	KEYWORDS: [
    // 		"STATISTIC TEST"
    // 		]	
    // },
    // "FULLER": {
    // 	"INPUTS": [
    // 		{
    // 			DISPLAY_NAME: "Verbose",
    // 			VARIABLE_NAME: "verbose",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: false,
    // 			DESCRIPTION: "This field specifies whether activity of this module will be logged to on the server's file system.",
    // 			ADVANCED_INPUT: true
    // 		}	
    // 	],
    // 	"OUTPUTS": [

    // 	],
    // 	DISPLAY_NAME: "Run Fuller test",
    // 	PRODUCT_LEVEL: "Standard",
    // 	DESCRIPTION: "This module is responsible for calculating the Fuller test statistic of a given input modality.",
    // 	KEYWORDS: [
    // 		"STATISTIC TEST"
    // 		]	
    // },
    // "KPSS": {
    // 	"INPUTS": [
    // 		{
    // 			DISPLAY_NAME: "Verbose",
    // 			VARIABLE_NAME: "verbose",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: false,
    // 			DESCRIPTION: "This field specifies whether activity of this module will be logged to on the server's file system.",
    // 			ADVANCED_INPUT: true
    // 		}	
    // 	],
    // 	"OUTPUTS": [

    // 	],
    // 	DISPLAY_NAME: "Run Kwiatkowski–Phillips–Schmidt–Shin test",
    // 	PRODUCT_LEVEL: "Standard",
    // 	DESCRIPTION: "This module is responsible for calculating the  Kwiatkowski–Phillips–Schmidt–Shin test statistic of a given input modality.",
    // 	KEYWORDS: [
    // 		"STATISTIC TEST"
    // 		]	
    // },
    // "TTESTIND": {
    // 	"INPUTS": [
    // 		{
    // 			DISPLAY_NAME: "Verbose",
    // 			VARIABLE_NAME: "verbose",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: false,
    // 			DESCRIPTION: "This field specifies whether activity of this module will be logged to on the server's file system.",
    // 			ADVANCED_INPUT: true
    // 		}	
    // 	],
    // 	"OUTPUTS": [

    // 	],
    // 	DISPLAY_NAME: "Run independent t-test",
    // 	PRODUCT_LEVEL: "Standard",
    // 	DESCRIPTION: "This module is responsible for calculating the independent t-test statistic of a given input modality.",
    // 	KEYWORDS: [
    // 		"STATISTIC TEST"
    // 		]	
    // },
    // "TTESTPAIRED": {
    // 	"INPUTS": [
    // 		{
    // 			DISPLAY_NAME: "Verbose",
    // 			VARIABLE_NAME: "verbose",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: false,
    // 			DESCRIPTION: "This field specifies whether activity of this module will be logged to on the server's file system.",
    // 			ADVANCED_INPUT: true
    // 		}	
    // 	],
    // 	"OUTPUTS": [

    // 	],
    // 	DISPLAY_NAME: "Run paired t-test",
    // 	PRODUCT_LEVEL: "Standard",
    // 	DESCRIPTION: "This module is responsible for calculating the paired t-test statistic of a given input modality.",
    // 	KEYWORDS: [
    // 		"STATISTIC TEST"
    // 		]	
    // },
    // "ANOVA": {
    // 	"INPUTS": [
    // 		{
    // 			DISPLAY_NAME: "Verbose",
    // 			VARIABLE_NAME: "verbose",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: false,
    // 			DESCRIPTION: "This field specifies whether activity of this module will be logged to on the server's file system.",
    // 			ADVANCED_INPUT: true
    // 		}	
    // 	],
    // 	"OUTPUTS": [

    // 	],
    // 	DISPLAY_NAME: "Run ANOVA test",
    // 	PRODUCT_LEVEL: "Standard",
    // 	DESCRIPTION: "This module is responsible for calculating the ANOVA test statistic of a given input modality.",
    // 	KEYWORDS: [
    // 		"STATISTIC TEST"
    // 		]	
    // },
    // "MANNWHITNEYU": {
    // 	"INPUTS": [
    // 		{
    // 			DISPLAY_NAME: "Verbose",
    // 			VARIABLE_NAME: "verbose",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: false,
    // 			DESCRIPTION: "This field specifies whether activity of this module will be logged to on the server's file system.",
    // 			ADVANCED_INPUT: true
    // 		}	
    // 	],
    // 	"OUTPUTS": [

    // 	],
    // 	DISPLAY_NAME: "Run Mann-Whitney U test",
    // 	PRODUCT_LEVEL: "Standard",
    // 	DESCRIPTION: "This module is responsible for calculating the Mann Whitney U test statistic of a given input modality.",
    // 	KEYWORDS: [
    // 		"STATISTIC TEST"
    // 		]	
    // },
    // "WILCOXON": {
    // 	"INPUTS": [
    // 		{
    // 			DISPLAY_NAME: "Verbose",
    // 			VARIABLE_NAME: "verbose",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: false,
    // 			DESCRIPTION: "This field specifies whether activity of this module will be logged to on the server's file system.",
    // 			ADVANCED_INPUT: true
    // 		}	
    // 	],
    // 	"OUTPUTS": [

    // 	],
    // 	DISPLAY_NAME: "Run Wilcoxon Rank Sums test",
    // 	PRODUCT_LEVEL: "Standard",
    // 	DESCRIPTION: "This module is responsible for calculating the Wilcoxon Rank Sums test statistic of a given input modality.",
    // 	KEYWORDS: [
    // 		"STATISTIC TEST"
    // 		]	
    // },
    // "KRUSKAL": {
    // 	"INPUTS": [
    // 		{
    // 			DISPLAY_NAME: "Verbose",
    // 			VARIABLE_NAME: "verbose",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: false,
    // 			DESCRIPTION: "This field specifies whether activity of this module will be logged to on the server's file system.",
    // 			ADVANCED_INPUT: true
    // 		}	
    // 	],
    // 	"OUTPUTS": [

    // 	],
    // 	DISPLAY_NAME: "Run Kruskal Wallis statistical test",
    // 	PRODUCT_LEVEL: "Standard",
    // 	DESCRIPTION: "This module is responsible for calculating the Kruskal Wallis test statistic of a given input modality.",
    // 	KEYWORDS: [
    // 		"STATISTIC TEST"
    // 		]	
    // },
    // "CPPOPT": {
    // 	"INPUTS": [
    // 		{
    // 			DISPLAY_NAME: "Point Length",
    // 			VARIABLE_NAME: "point_length",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 10,
    // 			ADVANCED_INPUT: true
    // 		},
    // 		{
    // 			DISPLAY_NAME: "Length of PRx Calculation",
    // 			VARIABLE_NAME: "PRx_length",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 300,
    // 			ADVANCED_INPUT: true
    // 		},
    // 		{
    // 			DISPLAY_NAME: "Overlap of PRx Calculation",
    // 			VARIABLE_NAME: "overlap",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 0.80,
    // 			DESCRIPTION: "This field specifies the percent of overlap between each pressure reactivity (PRx) calculation. For example, an overlap of 0.80 (the default value) implies that 80% of the data included in one calculation of PRx will be included in the subsequent PRx calculation.",
    // 			ADVANCED_INPUT: true
    // 		},
    // 		{
    // 			DISPLAY_NAME: "Minimum CPP",
    // 			VARIABLE_NAME: "x_min",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 40, 
    // 			DESCRIPTION: "This field specifies the minimum CPP value to display when plotting PRx against CPP.",
    // 			ADVANCED_INPUT: true
    // 		},
    // 		{
    // 			DISPLAY_NAME: "Maximum CPP",
    // 			VARIABLE_NAME: "x_max",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 120,
    // 			DESCRIPTION: "This field specifies the maximum CPP value to display when plotting PRx against CPP.",
    // 			ADVANCED_INPUT: true
    // 		},
    // 		{
    // 			DISPLAY_NAME: "Minimum PRx",
    // 			VARIABLE_NAME: "y_min",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: null, 
    // 			DESCRIPTION: "This field specifies the minimum PRx value to display when plotting PRx against CPP.",
    // 			ADVANCED_INPUT: true
    // 		},
    // 		{
    // 			DISPLAY_NAME: "Maximum PRx",
    // 			VARIABLE_NAME: "y_max",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: null,
    // 			DESCRIPTION: "This field specifies the maximum PRx value to display when plotting PRx against CPP.",
    // 			ADVANCED_INPUT: true
    // 		},	
    // 		{
    // 			DISPLAY_NAME: "Number of Bins",
    // 			VARIABLE_NAME: "nsubsets",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 16,
    // 			DESCRIPTION: "This field specifies the number of bins to use when plotting PRx against CPP to find CPPopt.",
    // 			ADVANCED_INPUT: true
    // 		},		
    // 		{
    // 			DISPLAY_NAME: "Verbose",
    // 			VARIABLE_NAME: "verbose",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: false,
    // 			DESCRIPTION: "This field specifies whether activity of this module will be logged to on the server's file system.",
    // 			ADVANCED_INPUT: true
    // 		}		
    // 	],
    // 	"OUTPUTS": [

    // 	],
    // 	DISPLAY_NAME: "Calculate optimal cerebral perfusion pressure",
    // 	PRODUCT_LEVEL: "Standard",
    // 	DESCRIPTION: "This module is responsible for determining the optimal CPP (CPPopt) for a patient in order to encourage auto regulation.",
    // 	KEYWORDS: [
    // 		"METRICS",
    // 		"CEREBRAL PERFUSION PRESSURE"
    // 		]	
    // },
    // "ABP": {
    // 	"INPUTS": [
    // 		{
    // 			DISPLAY_NAME: "Verbose",
    // 			VARIABLE_NAME: "verbose",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: false,
    // 			DESCRIPTION: "This field specifies whether activity of this module will be logged to on the server's file system.",
    // 			ADVANCED_INPUT: true
    // 		}	
    // 	],
    // 	"OUTPUTS": [

    // 	],
    // 	DISPLAY_NAME: "Calculate mean arterial blood pressure",
    // 	PRODUCT_LEVEL: "Standard",
    // 	DESCRIPTION: "This module is responsible for calculating mean arterial blood pressure (ABP) from systolic ABP and diastolic ABP.",
    // 	KEYWORDS: [
    // 		"METRICS",
    // 		"ARTERIAL BLOOD PRESSURE"
    // 		]	
    // },
    // "PRX": {
    // 	"INPUTS": [
    // 		{
    // 			DISPLAY_NAME: "Point Length",
    // 			VARIABLE_NAME: "point_length",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 10,
    // 			ADVANCED_INPUT: true
    // 		},
    // 		{
    // 			DISPLAY_NAME: "Length of PRx Calculation",
    // 			VARIABLE_NAME: "PRx_length",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 300,
    // 			ADVANCED_INPUT: true
    // 		},
    // 		{
    // 			DISPLAY_NAME: "Overlap of PRx Calculation",
    // 			VARIABLE_NAME: "overlap",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 0.80,
    // 			DESCRIPTION: "This field specifies the percent of overlap between each pressure reactivity (PRx) calculation. For example, an overlap of 0.80 (the default value) implies that 80% of the data included in one calculation of PRx will be included in the subsequent PRx calculation.",
    // 			ADVANCED_INPUT: true
    // 		},
    // 		{
    // 			DISPLAY_NAME: "Verbose",
    // 			VARIABLE_NAME: "verbose",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: false,
    // 			DESCRIPTION: "This field specifies whether activity of this module will be logged to on the server's file system.",
    // 			ADVANCED_INPUT: true
    // 		}
    //     ],
    // 	"OUTPUTS": [

    // 	],
    // 	DISPLAY_NAME: "Calculate pressure reactivity index",
    // 	PRODUCT_LEVEL: "Standard",
    // 	DESCRIPTION: "This module is responsible for calculating the pressure reactivity index from mean arterial blood pressure (ABP), mean intercranial pressure (ICP), and (optionally) brain oxygen (PbtO2).",
    // 	KEYWORDS: [
    // 		"METRICS",
    // 		"PRESSURE REACTIVITY INDEX"
    // 		]	
    // },
    // "PROPORTION": {
    // 	"INPUTS": [
    // 		{
    // 			DISPLAY_NAME: "Thresholds",
    // 			DEFAULT_NAME: "thresholds",
    // 			TYPE: "MAX_MIN_INPUT",
    // 			DEFAULT_VALUE: [["<", 120]],
    // 			DESCRIPTION: "This field specifies the direction and threshold for the proportion calculation. The proportion of the region based on the direction and the threshold will be returned.\nThe number of threshold definitions must match the number of modalities that are given to this module. In other words, if one threshold is defined, this module will expect a single modality to be given to it as input. Otherwise, the module will use the fewer of the two.",
    // 			ADVANCED_INPUT: false
    // 		},
    // 		{
    // 			DISPLAY_NAME: "Resampling rate",
    // 			VARIABLE_NAME: "resample",
    // 			TYPE: USER_INPUT_TYPES.DURATION_INPUT,
    // 			DEFAULT_VALUE: {"seconds": 5},
    // 			DESCRIPTION: "This field indicates with that frequency the following metric will be calculated at. The default none identified by an empty field. However, it may be set to parameters like `1 m` to specify that this metric will be calculated for each minute.",
    // 			ADVANCED_INPUT: true
    // 		},	
    // 		{
    // 			DISPLAY_NAME: "Rolling",
    // 			VARIABLE_NAME: "rolling",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: false,
    // 			DESCRIPTION: "This field specifies the metric will be taken on a rolling basis. If this is set to true, then the resampling rate must also be specified. In other words, the metric will be calculated on a rolling basis of the frequency identified by the resampling rate.",
    // 			ADVANCED_INPUT: true
    // 		},
    // 		{
    // 			DISPLAY_NAME: "Verbose",
    // 			VARIABLE_NAME: "verbose",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: false,
    // 			DESCRIPTION: "This field specifies whether activity of this module will be logged to on the server's file system.",
    // 			ADVANCED_INPUT: true
    // 		}	
    // 	],
    // 	"OUTPUTS": [

    // 	],
    // 	DISPLAY_NAME: "Calculate proportion over threshold",
    // 	PRODUCT_LEVEL: "Standard",
    // 	DESCRIPTION: "This module is responsible for calculating the proportion of a modality over a given threshold.",
    // 	KEYWORDS: [
    // 		"METRICS",
    // 		"SCORES"
    // 		]	
    // },
    // "AREA": {
    // 	"INPUTS": [
    // 		{
    // 			DISPLAY_NAME: "Acceptable Range",
    // 			DEFAULT_NAME: "acceptable_range",
    // 			TYPE: "MAX_MIN_INPUT",
    // 			DEFAULT_VALUE: [15, 22],
    // 			ADVANCED_INPUT: false
    // 		},
    // 		{
    // 			DISPLAY_NAME: "Resampling rate",
    // 			VARIABLE_NAME: "resample",
    // 			TYPE: USER_INPUT_TYPES.DURATION_INPUT,
    // 			DEFAULT_VALUE: {"seconds": 5},
    // 			DESCRIPTION: "This field indicates with that frequency the following metric will be calculated at. The default none identified by an empty field. However, it may be set to parameters like `1 m` to specify that this metric will be calculated for each minute.",
    // 			ADVANCED_INPUT: true
    // 		},	
    // 		{
    // 			DISPLAY_NAME: "Rolling",
    // 			VARIABLE_NAME: "rolling",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: false,
    // 			DESCRIPTION: "This field specifies the metric will be taken on a rolling basis. If this is set to true, then the resampling rate must also be specified. In other words, the metric will be calculated on a rolling basis of the frequency identified by the resampling rate.",
    // 			ADVANCED_INPUT: true
    // 		},
    // 		{
    // 			DISPLAY_NAME: "Verbose",
    // 			VARIABLE_NAME: "verbose",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: false,
    // 			DESCRIPTION: "This field specifies whether activity of this module will be logged to on the server's file system.",
    // 			ADVANCED_INPUT: true
    // 		}	
    // 	],
    // 	"OUTPUTS": [

    // 	],
    // 	DISPLAY_NAME: "Calculate area scores over threshold",
    // 	PRODUCT_LEVEL: "Standard",
    // 	DESCRIPTION: "This module is responsible for calculating area scores given a modality and a respective threshold range.",
    // 	KEYWORDS: [
    // 		"METRICS",
    // 		"SCORES"
    // 		]	
    // },
    // "BURDEN": {
    // 	"INPUTS": [
    // 		{
    // 			DISPLAY_NAME: "Magnitude(s)",
    // 			DEFAULT_NAME: "magnitude",
    // 			TYPE: "MAX_MIN_INPUT",
    // 			DEFAULT_VALUE: [15, 22],
    // 			DESCRIPTION: "This field indicates the magnitude(s) required for a region to be considered a burden incident.",
    // 			ADVANCED_INPUT: false
    // 		},
    // 		{
    // 			DISPLAY_NAME: "Direction",
    // 			VARIABLE_NAME: "direction",
    // 			TYPE: "DROPDOWN_INPUT",
    // 			DEFAULT_VALUE: {
    // 				"<": true,
    // 				"<=": false,
    // 				">": false,
    // 				">=": false,
    // 				"<<": false,
    // 				"<=<=": false,
    // 				"><": false,
    // 				">=<=": false
    // 			},
    // 			DESCRIPTION: "This field indicates the direction relative to the magnitude(s) for a region to be considered a burden incident.",
    // 			ADVANCED_INPUT: false
    // 		},	
    // 		{
    // 			DISPLAY_NAME: "Duration",
    // 			DEFAULT_NAME: "duration",
    // 			TYPE: "MUTLINUMBERINPUT",
    // 			DEFAULT_VALUE: {"seconds": 120},
    // 			DESCRIPTION: "This field indicates the length of time (in seconds) required for a region to be considered a burden incident.",
    // 			ADVANCED_INPUT: false
    // 		},
    // 		{
    // 			DISPLAY_NAME: "Resampling rate",
    // 			VARIABLE_NAME: "resample",
    // 			TYPE: USER_INPUT_TYPES.DURATION_INPUT,
    // 			DEFAULT_VALUE: {"seconds": 5},
    // 			DESCRIPTION: "This field indicates at which frequency the following metric will be calculated. The default none identified by an empty field. However, it may be set to parameters like `1 m` to specify that this metric will be calculated for each minute.",
    // 			ADVANCED_INPUT: true
    // 		},	
    // 		{
    // 			DISPLAY_NAME: "Rolling",
    // 			VARIABLE_NAME: "rolling",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: true,
    // 			DESCRIPTION: "This field specifies the metric will be taken on a rolling basis. If this is set to true, then the resampling rate must also be specified. In other words, the metric will be calculated on a rolling basis of the frequency identified by the resampling rate.",
    // 			ADVANCED_INPUT: true
    // 		},
    // 		{
    // 			DISPLAY_NAME: "Verbose",
    // 			VARIABLE_NAME: "verbose",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: false,
    // 			DESCRIPTION: "This field specifies whether activity of this module will be logged to on the server's file system.",
    // 			ADVANCED_INPUT: true
    // 		}	
    // 	],
    // 	"OUTPUTS": [

    // 	],
    // 	DISPLAY_NAME: "Calculate burden scores over threshold",
    // 	PRODUCT_LEVEL: "Standard",
    // 	DESCRIPTION: "This module is responsible for calculating burden scores given a modality and a respective threshold range.",
    // 	KEYWORDS: [
    // 		"METRICS",
    // 		"SCORES"
    // 		]	
    // },
    // "INCIDENT": {
    // 	"INPUTS": [
    // 		{
    // 			DISPLAY_NAME: "Acceptable Range",
    // 			DEFAULT_NAME: "acceptable_range",
    // 			TYPE: "MAX_MIN_INPUT",
    // 			DEFAULT_VALUE: [15, 22],
    // 			ADVANCED_INPUT: false
    // 		},
    // 		{
    // 			DISPLAY_NAME: "Resampling rate",
    // 			VARIABLE_NAME: "resample",
    // 			TYPE: USER_INPUT_TYPES.DURATION_INPUT,
    // 			DEFAULT_VALUE: {"seconds": 5},
    // 			DESCRIPTION: "This field indicates with that frequency the following metric will be calculated at. The default none identified by an empty field. However, it may be set to parameters like `1 m` to specify that this metric will be calculated for each minute.",
    // 			ADVANCED_INPUT: true
    // 		},	
    // 		{
    // 			DISPLAY_NAME: "Rolling",
    // 			VARIABLE_NAME: "rolling",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: false,
    // 			DESCRIPTION: "This field specifies the metric will be taken on a rolling basis. If this is set to true, then the resampling rate must also be specified. In other words, the metric will be calculated on a rolling basis of the frequency identified by the resampling rate.",
    // 			ADVANCED_INPUT: true
    // 		},
    // 		{
    // 			DISPLAY_NAME: "Verbose",
    // 			VARIABLE_NAME: "verbose",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: false,
    // 			DESCRIPTION: "This field specifies whether activity of this module will be logged to on the server's file system.",
    // 			ADVANCED_INPUT: true
    // 		}	
    // 	],
    // 	"OUTPUTS": [

    // 	],
    // 	DISPLAY_NAME: "Labels incidents over threshold",
    // 	PRODUCT_LEVEL: "Standard",
    // 	DESCRIPTION: "This module is responsible for labeling points with incident classes given a modality and a respective threshold range.",
    // 	KEYWORDS: [
    // 		"METRICS",
    // 		"SCORES"
    // 		]	
    // },
    // "PAV": {
    // 	"INPUTS": [
    // 		{
    // 			DISPLAY_NAME: "Lower Frequency Cutoff",
    // 			DEFAULT_NAME: "lowcut",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 0.5,
    // 			DESCRIPTION: "This field specifies the lower cut off frequency for an IIR (infinite impulse response) bandpass filter. Frequencies below this value will be attenuated.",
    // 			ADVANCED_INPUT: false
    // 		},
    // 		{
    // 			DISPLAY_NAME: "Higher Frequency Cutoff",
    // 			DEFAULT_NAME: "highcut",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 35,
    // 			DESCRIPTION: "This field specifies the higher cut off frequency for an IIR (infinite impulse response) bandpass filter. Frequencies above this value will be attenuated.",
    // 			ADVANCED_INPUT: false
    // 		},	
    // 		{
    // 			DISPLAY_NAME: "Filter Order",
    // 			DEFAULT_NAME: "filter_order",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 2,
    // 			DESCRIPTION: "This field specifies the order of the bandpass filter.",
    // 			ADVANCED_INPUT: true
    // 		},			
    // 		{
    // 			DISPLAY_NAME: "Length of a PAV Calculation",
    // 			DEFAULT_NAME: "nhour",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 25,
    // 			DESCRIPTION: "This field specifies the duration of each PAV calculation in seconds.",
    // 			ADVANCED_INPUT: true
    // 		},								
    // 		{
    // 			DISPLAY_NAME: "Verbose",
    // 			VARIABLE_NAME: "verbose",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: false,
    // 			DESCRIPTION: "This field specifies whether activity of this module will be logged to on the server's file system.",
    // 			ADVANCED_INPUT: true
    // 		}	
    // 	],
    // 	"OUTPUTS": [

    // 	],
    // 	DISPLAY_NAME: "Calculate percent alpha variability",
    // 	PRODUCT_LEVEL: "Standard",
    // 	DESCRIPTION: "This module is responsible for calculating the percent alpha variability of a EEG signal.",
    // 	KEYWORDS: [
    // 		"METRICS",
    // 		"PERCENT ALPHA VARIABILITY",
    // 		"EEG"
    // 		]	
    // },
    "FFT": {
        DATA_INPUT_TYPES: ["time_series"],
        DATA_OUTPUT_TYPE: "coordinates",
        INPUTS: [],
        DISPLAY_NAME: "Fast Fourier transform",
        PRODUCT_LEVEL: "Standard",
        DESCRIPTION: "This module is responsible for applying the fast Fourier transform on waveform data (often EEG).",
        KEYWORDS: [
            "METRICS",
            "FAST FOURIER TRANSFORM",
            "EEG"
            ]	
    },
    // "CWT": {
    // 	"INPUTS": [
    // 		{
    // 			DISPLAY_NAME: "Scale(s)",
    // 			DEFAULT_NAME: "scales",
    // 			TYPE: "MAX_MIN_INPUT",
    // 			DEFAULT_VALUE: [1, 128],
    // 			ADVANCED_INPUT: true
    // 		},		
    // 		{
    // 			DISPLAY_NAME: "Wavelet(s)",
    // 			DEFAULT_NAME: "wavelets",
    // 			TYPE: "DROPDOWN_INPUT",
    // 			DEFAULT_VALUE: {
    // 				'cgau1': true,
    // 				'cgau2': false,
    // 				'cgau3': false,
    // 				'cgau4': false,
    // 				'cgau5': false,
    // 				'cgau6': false,
    // 				'cgau7': false,
    // 				'cgau8': false,
    // 				'cmor': false,
    // 				'fbsp': false,
    // 				'gaus1': false,
    // 				'gaus2': false,
    // 				'gaus3': false,
    // 				'gaus4': false,
    // 				'gaus5': false,
    // 				'gaus6': false,
    // 				'gaus7': false,
    // 				'gaus8': false,
    // 				'mexh': false,
    // 				'morl': false,
    // 				'shan': false
    // 			},
    // 			DESCRIPTION: "This field specifies the wavelet(s) to use for the continuous wavelet transform (CWT).",
    // 			ADVANCED_INPUT: false
    // 		},	
    // 		{
    // 			DISPLAY_NAME: "Verbose",
    // 			VARIABLE_NAME: "verbose",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: false,
    // 			DESCRIPTION: "This field specifies whether activity of this module will be logged to on the server's file system.",
    // 			ADVANCED_INPUT: true
    // 		}	
    // 	],
    // 	"OUTPUTS": [

    // 	],
    // 	DISPLAY_NAME: "Calculate continuous wavelet transform",
    // 	PRODUCT_LEVEL: "Standard",
    // 	DESCRIPTION: "This module is responsible for calculating the continuous wavelet transform (CWT) of a waveform.",
    // 	KEYWORDS: [
    // 		"METRICS",
    // 		"CONTINOUS WAVELET TRANSFORM",
    // 		"EEG"
    // 		]	
    // },
    // "DWT": {
    // 	"INPUTS": [
    // 		{
    // 			DISPLAY_NAME: "Number of Levels",
    // 			DEFAULT_NAME: "level",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 2,
    // 			ADVANCED_INPUT: true
    // 		},		
    // 		{
    // 			DISPLAY_NAME: "Wavelet(s)",
    // 			DEFAULT_NAME: "wavelets",
    // 			TYPE: "DROPDOWN_INPUT",
    // 			DEFAULT_VALUE: {
    // 				'bior1.1': true,
    // 				'bior1.3': false,
    // 				'bior1.5': false,
    // 				'bior2.2': false,
    // 				'bior2.4': false,
    // 				'bior2.6': false,
    // 				'bior2.8': false,
    // 				'bior3.1': false,
    // 				'bior3.3': false,
    // 				'bior3.5': false,
    // 				'bior3.7': false,
    // 				'bior3.9': false,
    // 				'bior4.4': false,
    // 				'bior5.5': false,
    // 				'bior6.8': false,
    // 				'coif1': false,
    // 				'coif2': false,
    // 				'coif3': false,
    // 				'coif4': false,
    // 				'coif5': false,
    // 				'coif6': false,
    // 				'coif7': false,
    // 				'coif8': false,
    // 				'coif9': false,
    // 				'coif10': false,
    // 				'coif11': false,
    // 				'coif12': false,
    // 				'coif13': false,
    // 				'coif14': false,
    // 				'coif15': false,
    // 				'coif16': false,
    // 				'coif17': false,
    // 				'db1': false,
    // 				'db2': false,
    // 				'db3': false,
    // 				'db4': false,
    // 				'db5': false,
    // 				'db6': false,
    // 				'db7': false,
    // 				'db8': false,
    // 				'db9': false,
    // 				'db10': false,
    // 				'db11': false,
    // 				'db12': false,
    // 				'db13': false,
    // 				'db14': false,
    // 				'db15': false,
    // 				'db16': false,
    // 				'db17': false,
    // 				'db18': false,
    // 				'db19': false,
    // 				'db20': false,
    // 				'db21': false,
    // 				'db22': false,
    // 				'db23': false,
    // 				'db24': false,
    // 				'db25': false,
    // 				'db26': false,
    // 				'db27': false,
    // 				'db28': false,
    // 				'db29': false,
    // 				'db30': false,
    // 				'db31': false,
    // 				'db32': false,
    // 				'db33': false,
    // 				'db34': false,
    // 				'db35': false,
    // 				'db36': false,
    // 				'db37': false,
    // 				'db38': false,
    // 				'dmey': false,
    // 				'haar': false,
    // 				'rbio1.1': false,
    // 				'rbio1.3': false,
    // 				'rbio1.5': false,
    // 				'rbio2.2': false,
    // 				'rbio2.4': false,
    // 				'rbio2.6': false,
    // 				'rbio2.8': false,
    // 				'rbio3.1': false,
    // 				'rbio3.3': false,
    // 				'rbio3.5': false,
    // 				'rbio3.7': false,
    // 				'rbio3.9': false,
    // 				'rbio4.4': false,
    // 				'rbio5.5': false,
    // 				'rbio6.8': false,
    // 				'sym2': false,
    // 				'sym3': false,
    // 				'sym4': false,
    // 				'sym5': false,
    // 				'sym6': false,
    // 				'sym7': false,
    // 				'sym8': false,
    // 				'sym9': false,
    // 				'sym10': false,
    // 				'sym11': false,
    // 				'sym12': false,
    // 				'sym13': false,
    // 				'sym14': false,
    // 				'sym15': false,
    // 				'sym16': false,
    // 				'sym17': false,
    // 				'sym18': false,
    // 				'sym19': false,
    // 				'sym20': false
    // 			},
    // 			DESCRIPTION: "This field specifies the wavelet(s) to use for the discrete wavelet transform (DWT).",
    // 			ADVANCED_INPUT: false
    // 		},	
    // 		{
    // 			DISPLAY_NAME: "Verbose",
    // 			VARIABLE_NAME: "verbose",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: false,
    // 			DESCRIPTION: "This field specifies whether activity of this module will be logged to on the server's file system.",
    // 			ADVANCED_INPUT: true
    // 		}	
    // 	],
    // 	"OUTPUTS": [

    // 	],
    // 	DISPLAY_NAME: "Calculate discrete wavelet transform",
    // 	PRODUCT_LEVEL: "Standard",
    // 	DESCRIPTION: "This module is responsible for calculating the discrete wavelet transform of a waveform.",
    // 	KEYWORDS: [
    // 		"METRICS",
    // 		"DISCRETE WAVELET TRANSFORM",
    // 		"EEG"
    // 		]	
    // },
    // "POWER" : {
    // 	"INPUTS": [
    // 		{
    // 			DISPLAY_NAME: "Sampling Rate",
    // 			VARIABLE_NAME: "sampling_rate",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 256,
    // 			DESCRIPTION: "This field specifies the sampling rate of the signal.",
    // 			ADVANCED_INPUT: false
    // 		},
    // 		{
    // 			DISPLAY_NAME: "Bands",
    // 			VARIABLE_NAME: "Bands",
    // 			TYPE: "LIST_INPUT",
    // 			DEFAULT_VALUE: [0.5, 4, 7, 12, 25, 45],
    // 			DESCRIPTION: "This field specifies the bands for which the power frequency contributions will be reported.",
    // 			ADVANCED_INPUT: false
    // 		},
    // 		{
    // 			DISPLAY_NAME: "Verbose",
    // 			VARIABLE_NAME: "verbose",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: false,
    // 			DESCRIPTION: "This field specifies whether activity of this module will be logged to on the server's file system.",
    // 			ADVANCED_INPUT: true
    // 		}	
    // 	],
    // 	"OUTPUTS": [

    // 	],
    // 	DISPLAY_NAME: "Calculate power band spectrum",
    // 	PRODUCT_LEVEL: "Standard",
    // 	DESCRIPTION: "This module is responsible for calculating the power spectrum of different frequency bands for a waveform signal (often EEG).",
    // 	KEYWORDS: [
    // 		"METRICS",
    // 		"SPECTRUM",
    // 		"EEG"
    // 		]	
    // },
    // "NOTCHFILTERIIR" : {
    // 	"INPUTS": [
    // 		{
    // 			DISPLAY_NAME: "Frequency",
    // 			VARIABLE_NAME: "frequency",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 60,
    // 			DESCRIPTION: "This field specifies the harmonic frequency that will be attenuated by the notch filter.",
    // 			ADVANCED_INPUT: false
    // 		},
    // 		{
    // 			DISPLAY_NAME: "Sampling Rate",
    // 			VARIABLE_NAME: "sampling_rate",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 256,
    // 			DESCRIPTION: "This field specifies the sampling rate of the signal being filtered.",
    // 			ADVANCED_INPUT: false
    // 		},
    // 		{
    // 			DISPLAY_NAME: "Filter Quality",
    // 			VARIABLE_NAME: "quality",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 60,
    // 			DESCRIPTION: "This field specifies the quality of the filter.",
    // 			ADVANCED_INPUT: true
    // 		},
    // 		{
    // 			DISPLAY_NAME: "Verbose",
    // 			VARIABLE_NAME: "verbose",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: false,
    // 			DESCRIPTION: "This field specifies whether activity of this module will be logged to on the server's file system.",
    // 			ADVANCED_INPUT: true
    // 		}	
    // 	],
    // 	"OUTPUTS": [

    // 	],
    // 	DISPLAY_NAME: "Apply infinite impulse response notch filter",
    // 	PRODUCT_LEVEL: "Standard",
    // 	DESCRIPTION: "This module is responsible for filtering harmonics from waveform data (often EEG) using an infinite impulse response (IIR) notch filter.",
    // 	KEYWORDS: [
    // 		"EEG"
    // 		]	
    // },
    // "BANDPASSIIR": {
    // 	"INPUTS": [
    // 		{
    // 			DISPLAY_NAME: "Sampling Rate",
    // 			VARIABLE_NAME: "sampling_rate",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 256,
    // 			DESCRIPTION: "This field specifies the sampling rate of the signal being filtered.",
    // 			ADVANCED_INPUT: false
    // 		},
    // 		{
    // 			DISPLAY_NAME: "Low Frequency Cutoff",
    // 			DEFAULT_NAME: "lowcut",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 0.5,
    // 			DESCRIPTION: "This field specifies the lower cut off frequency for an IIR (infinite impulse response) bandpass filter. Frequencies below this value will be attenuated.",
    // 			ADVANCED_INPUT: false
    // 		},
    // 		{
    // 			DISPLAY_NAME: "High Frequency Cutoff",
    // 			DEFAULT_NAME: "highcut",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 35,
    // 			DESCRIPTION: "This field specifies the higher cut off frequency for an IIR (infinite impulse response) bandpass filter. Frequencies above this value will be attenuated.",
    // 			ADVANCED_INPUT: false
    // 		},	
    // 		{
    // 			DISPLAY_NAME: "Filter Order",
    // 			DEFAULT_NAME: "filter_order",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 5,
    // 			DESCRIPTION: "This field specifies the order of the bandpass filter.",
    // 			ADVANCED_INPUT: true
    // 		},	
    // 		{
    // 			DISPLAY_NAME: "Verbose",
    // 			VARIABLE_NAME: "verbose",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: false,
    // 			DESCRIPTION: "This field specifies whether activity of this module will be logged to on the server's file system.",
    // 			ADVANCED_INPUT: true
    // 		}					
    // 	],
    // 	"OUTPUTS": [

    // 	],
    // 	DISPLAY_NAME: "Apply infinite impulse response bandpass filter",
    // 	PRODUCT_LEVEL: "Standard",
    // 	DESCRIPTION: "This module is responsible for filtering waveform data (often EEG) using an infinite impulse response (IIR) bandpass filter.",
    // 	KEYWORDS: [
    // 		"EEG"
    // 		]	
    // },
    // "LOWPASSIIR": {
    // 	"INPUTS": [
    // 		{
    // 			DISPLAY_NAME: "Sampling Rate",
    // 			VARIABLE_NAME: "sampling_rate",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 256,
    // 			DESCRIPTION: "This field specifies the sampling rate of the signal being filtered.",
    // 			ADVANCED_INPUT: false
    // 		},
    // 		{
    // 			DISPLAY_NAME: "High Frequency Cutoff",
    // 			DEFAULT_NAME: "cutoff",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 0.5,
    // 			DESCRIPTION: "This field specifies the higher cut off frequency for an IIR (infinite impulse response) lowpass filter. Frequencies above this value will be attenuated.",
    // 			ADVANCED_INPUT: false
    // 		},	
    // 		{
    // 			DISPLAY_NAME: "Filter Order",
    // 			DEFAULT_NAME: "filter_order",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 5,
    // 			DESCRIPTION: "This field specifies the order of the lowpass filter.",
    // 			ADVANCED_INPUT: true
    // 		},	
    // 		{
    // 			DISPLAY_NAME: "Verbose",
    // 			VARIABLE_NAME: "verbose",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: false,
    // 			DESCRIPTION: "This field specifies whether activity of this module will be logged to on the server's file system.",
    // 			ADVANCED_INPUT: true
    // 		}					
    // 	],
    // 	"OUTPUTS": [

    // 	],
    // 	DISPLAY_NAME: "Apply infinite impulse response lowpass filter",
    // 	PRODUCT_LEVEL: "Standard",
    // 	DESCRIPTION: "This module is responsible for filtering waveform data (often EEG) using an infinite impulse response (IIR) lowpass filter.",
    // 	KEYWORDS: [
    // 		"EEG"
    // 		]	
    // },
    // "HIGHPASSIIR": {
    // 	"INPUTS": [
    // 		{
    // 			DISPLAY_NAME: "Sampling Rate",
    // 			VARIABLE_NAME: "sampling_rate",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 256,
    // 			DESCRIPTION: "This field specifies the sampling rate of the signal being filtered.",
    // 			ADVANCED_INPUT: false
    // 		},
    // 		{
    // 			DISPLAY_NAME: "Low Frequency Cutoff",
    // 			DEFAULT_NAME: "cutoff",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 35,
    // 			DESCRIPTION: "This field specifies the lower cut off frequency for an IIR (infinite impulse response) highpass filter. Frequencies above this value will be attenuated.",
    // 			ADVANCED_INPUT: false
    // 		},	
    // 		{
    // 			DISPLAY_NAME: "Filter Order",
    // 			DEFAULT_NAME: "filter_order",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 5,
    // 			DESCRIPTION: "This field specifies the order of the highpass filter.",
    // 			ADVANCED_INPUT: true
    // 		},	
    // 		{
    // 			DISPLAY_NAME: "Verbose",
    // 			VARIABLE_NAME: "verbose",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: false,
    // 			DESCRIPTION: "This field specifies whether activity of this module will be logged to on the server's file system.",
    // 			ADVANCED_INPUT: true
    // 		}					
    // 	],
    // 	"OUTPUTS": [

    // 	],
    // 	DISPLAY_NAME: "Apply infinite impulse response highpass filter",
    // 	PRODUCT_LEVEL: "Standard",
    // 	DESCRIPTION: "This module is responsible for filtering waveform data (often EEG) using an infinite impulse response (IIR) highpass filter.",
    // 	KEYWORDS: [
    // 		"EEG"
    // 		]	
    // },
    // "BANDSTOPIIR": {
    // 	"INPUTS": [
    // 		{
    // 			DISPLAY_NAME: "Sampling Rate",
    // 			VARIABLE_NAME: "sampling_rate",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 256,
    // 			DESCRIPTION: "This field specifies the sampling rate of the signal being filtered.",
    // 			ADVANCED_INPUT: false
    // 		},
    // 		{
    // 			DISPLAY_NAME: "Low Frequency Cutoff",
    // 			DEFAULT_NAME: "lowcut",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 0.5,
    // 			DESCRIPTION: "This field specifies the lower cut off frequency for an IIR (infinite impulse response) bandstop filter. Frequencies above this value will be attenuated.",
    // 			ADVANCED_INPUT: false
    // 		},
    // 		{
    // 			DISPLAY_NAME: "High Frequency Cutoff",
    // 			DEFAULT_NAME: "highcut",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 35,
    // 			DESCRIPTION: "This field specifies the higher cut off frequency for an IIR (infinite impulse response) bandstop filter. Frequencies below this value will be attenuated.",
    // 			ADVANCED_INPUT: false
    // 		},	
    // 		{
    // 			DISPLAY_NAME: "Filter Order",
    // 			DEFAULT_NAME: "filter_order",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 5,
    // 			DESCRIPTION: "This field specifies the order of the bandstop filter.",
    // 			ADVANCED_INPUT: true
    // 		},	
    // 		{
    // 			DISPLAY_NAME: "Verbose",
    // 			VARIABLE_NAME: "verbose",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: false,
    // 			DESCRIPTION: "This field specifies whether activity of this module will be logged to on the server's file system.",
    // 			ADVANCED_INPUT: true
    // 		}					
    // 	],
    // 	"OUTPUTS": [

    // 	],
    // 	DISPLAY_NAME: "Apply infinite impulse response bandstop filter",
    // 	PRODUCT_LEVEL: "Standard",
    // 	DESCRIPTION: "This module is responsible for filtering waveform data (often EEG) using an infinite impulse response (IIR) bandstop filter.",
    // 	KEYWORDS: [
    // 		"EEG"
    // 		]	
    // },
    // "BANDPASSFIR": {
    // 	"INPUTS": [
    // 		{
    // 			DISPLAY_NAME: "Sampling Rate",
    // 			VARIABLE_NAME: "sampling_rate",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 256,
    // 			DESCRIPTION: "This field specifies the sampling rate of the signal being filtered.",
    // 			ADVANCED_INPUT: false
    // 		},			
    // 		{
    // 			DISPLAY_NAME: "Lower Frequency Cutoff",
    // 			DEFAULT_NAME: "lowcut",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 0.5,
    // 			DESCRIPTION: "This field specifies the lower cut off frequency for an FIR (finite impulse response) bandpass filter. Frequencies below this value will be attenuated.",
    // 			ADVANCED_INPUT: false
    // 		},	
    // 		{
    // 			DISPLAY_NAME: "Higher Frequency Cutoff",
    // 			DEFAULT_NAME: "highcut",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 35,
    // 			DESCRIPTION: "This field specifies the higher cut off frequency for an FIR (finite impulse response) bandpass filter. Frequencies above this value will be attenuated.",
    // 			ADVANCED_INPUT: false
    // 		},	
    // 		{
    // 			DISPLAY_NAME: "Filter Window",
    // 			DEFAULT_NAME: "fir_window",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 81,
    // 			DESCRIPTION: "This field specifies the size of the window for the filter.",
    // 			ADVANCED_INPUT: true
    // 		},	
    // 		{
    // 			DISPLAY_NAME: "Verbose",
    // 			VARIABLE_NAME: "verbose",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: false,
    // 			DESCRIPTION: "This field specifies whether activity of this module will be logged to on the server's file system.",
    // 			ADVANCED_INPUT: true
    // 		}	
    // 	],
    // 	"OUTPUTS": [

    // 	],
    // 	DISPLAY_NAME: "Apply finite impulse response bandpass filter",
    // 	PRODUCT_LEVEL: "Standard",
    // 	DESCRIPTION: "This module is responsible for filtering waveform data (often EEG) using an finite impulse response (FIR) bandpass filter.",
    // 	KEYWORDS: [
    // 		"EEG"
    // 		]	
    // },
    // "SQUAREDTRANSFORMATION": {
    // 	"INPUTS": [			
    // 		{
    // 			DISPLAY_NAME: "Verbose",
    // 			VARIABLE_NAME: "verbose",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: false,
    // 			DESCRIPTION: "This field specifies whether activity of this module will be logged to on the server's file system.",
    // 			ADVANCED_INPUT: true
    // 		}	
    // 	],
    // 	"OUTPUTS": [

    // 	],
    // 	DISPLAY_NAME: "Apply squared transformation",
    // 	PRODUCT_LEVEL: "Standard",
    // 	DESCRIPTION: "This module is responsible transforming the data such that all of the values are squared.",
    // 	KEYWORDS: []	
    // },
    // "EXPONENTIALTRANSFORMATION": {
    // 	"INPUTS": [		
    // 		{
    // 			DISPLAY_NAME: "Power",
    // 			DEFAULT_NAME: "exp",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 2,
    // 			DESCRIPTION: "This field specifies the power to which each value is raised when the given data is sent through this module.",
    // 			ADVANCED_INPUT: false
    // 		},	
    // 		{
    // 			DISPLAY_NAME: "Verbose",
    // 			VARIABLE_NAME: "verbose",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: false,
    // 			DESCRIPTION: "This field specifies whether activity of this module will be logged to on the server's file system.",
    // 			ADVANCED_INPUT: true
    // 		}	
    // 	],
    // 	"OUTPUTS": [

    // 	],
    // 	DISPLAY_NAME: "Apply power transformation",
    // 	PRODUCT_LEVEL: "Standard",
    // 	DESCRIPTION: "This module is responsible transforming the data such that all of the values are raised to a given power.",
    // 	KEYWORDS: []	
    // },
    // "SQUAREROOTTRANSFORMATION": {
    // 	"INPUTS": [		
    // 		{
    // 			DISPLAY_NAME: "Verbose",
    // 			VARIABLE_NAME: "verbose",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: false,
    // 			DESCRIPTION: "This field specifies whether activity of this module will be logged to on the server's file system.",
    // 			ADVANCED_INPUT: true
    // 		}	
    // 	],
    // 	"OUTPUTS": [

    // 	],
    // 	DISPLAY_NAME: "Apply square root transformation",
    // 	PRODUCT_LEVEL: "Standard",
    // 	DESCRIPTION: "This module is responsible transforming the data such that all of the values are square rooted."
    // },
    // "LOGTRANSFORMATIONATION": {
    // 	"INPUTS": [		
    // 		{
    // 			DISPLAY_NAME: "Base",
    // 			VARIABLE_NAME: "base",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 10,
    // 			DESCRIPTION: "This field specifies the base of the logarithmic function to be applied on the data.",
    // 			ADVANCED_INPUT: false
    // 		},	
    // 		{
    // 			DISPLAY_NAME: "Verbose",
    // 			VARIABLE_NAME: "verbose",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: false,
    // 			DESCRIPTION: "This field specifies whether activity of this module will be logged to on the server's file system.",
    // 			ADVANCED_INPUT: true
    // 		}	
    // 	],
    // 	"OUTPUTS": [

    // 	],
    // 	DISPLAY_NAME: "Apply logarithm transformation",
    // 	PRODUCT_LEVEL: "Standard",
    // 	DESCRIPTION: "This module is responsible transforming the data such that a logarithmic function is applied onto all of the values.",
    // 	KEYWORDS: []
    // },
    // "MUTLIPLIERTRANSFORMATION": {
    // 	"INPUTS": [			
    // 		{
    // 			DISPLAY_NAME: "Multiplier",
    // 			VARIABLE_NAME: "multiplier",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 0.5,
    // 			DESCRIPTION: "This field specifies the multiplier to be applied on the data.",
    // 			ADVANCED_INPUT: false
    // 		},	
    // 		{
    // 			DISPLAY_NAME: "Verbose",
    // 			VARIABLE_NAME: "verbose",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: false,
    // 			DESCRIPTION: "This field specifies whether activity of this module will be logged to on the server's file system.",
    // 			ADVANCED_INPUT: true
    // 		}	
    // 	],
    // 	"OUTPUTS": [

    // 	],
    // 	DISPLAY_NAME: "Apply multiplier transformation",
    // 	PRODUCT_LEVEL: "Standard",
    // 	DESCRIPTION: "This module is responsible transforming the data such that all of the values are multiplied by some given constant.",
    // 	KEYWORDS: []
    // },
    // "FUNCTIONTRANSFORMATION": {
    // 	"INPUTS": [			
    // 		{
    // 			DISPLAY_NAME: "Function",
    // 			VARIABLE_NAME: "function",
    // 			TYPE: "TEXT_INPUT",
    // 			DEFAULT_VALUE: "x^2",
    // 			DESCRIPTION: "This field specifies the function to be applied on the data.",
    // 			ADVANCED_INPUT: false
    // 		},	
    // 		{
    // 			DISPLAY_NAME: "Verbose",
    // 			VARIABLE_NAME: "verbose",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: false,
    // 			DESCRIPTION: "This field specifies whether activity of this module will be logged to on the server's file system.",
    // 			ADVANCED_INPUT: true
    // 		}	
    // 	],
    // 	"OUTPUTS": [

    // 	],
    // 	DISPLAY_NAME: "Apply multiplier transformation",
    // 	PRODUCT_LEVEL: "Standard",
    // 	DESCRIPTION: "This module is responsible transforming the data such a function is applied onto all of the values.",
    // 	KEYWORDS: []
    // },
    // "PCA": {
    // 	"INPUTS": [
    // 		{
    // 			DISPLAY_NAME: "Number of Principal Components",
    // 			VARIABLE_NAME: "n",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 2,
    // 			DESCRIPTION: "This field specifies the number of principal components to report from the principal component analysis (PCA).",
    // 			ADVANCED_INPUT: false
    // 		},			
    // 		{
    // 			DISPLAY_NAME: "Whiten",
    // 			VARIABLE_NAME: "whiten",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: false,
    // 			DESCRIPTION: "This field indicates whether to multiply principal components by the square root of the number of samples and then divided by the singular values to ensure uncorrelated outputs with unit component-wise variances.",
    // 			ADVANCED_INPUT: true
    // 		},
    // 		{
    // 			DISPLAY_NAME: "SVD Solver",
    // 			VARIABLE_NAME: "svd_solver",
    // 			TYPE: "DROPDOWN_INPUT",
    // 			DEFAULT_VALUE: {
    // 				"auto": true,
    // 				"full": false,
    // 				"arpack": false,
    // 				"randomized": false
    // 			},
    // 			DESCRIPTION: "This field specifics the method to use in order to solve for the Singular Value Decomposition (SVD).",
    // 			ADVANCED_INPUT: true
    // 		},
    // 		{
    // 			DISPLAY_NAME: "Tolerance",
    // 			VARIABLE_NAME: "tol",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 0,
    // 			DESCRIPTION: "This field specifies the tolerance for singular values computed by the arpack SVD Solver.",
    // 			ADVANCED_INPUT: true
    // 		},
    // 		{
    // 			DISPLAY_NAME: "Iterated Power",
    // 			VARIABLE_NAME: "iterated_power",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: -1,
    // 			DESCRIPTION: "This field specifies the number of iterations for the power method computed by the randomized SVD Solver. A value of negative -1 implies that it will automatically select it for the user.",
    // 			ADVANCED_INPUT: true
    // 		},	
    // 		{
    // 			DISPLAY_NAME: "Seed",
    // 			VARIABLE_NAME: "seed",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: null,
    // 			DESCRIPTION: "This field specifies whether the random assignment of data into training and testing data should be seeded with a default value so that results are reproducible.",
    // 			ADVANCED_INPUT: true
    // 		},						
    // 		{
    // 			DISPLAY_NAME: "Verbose",
    // 			VARIABLE_NAME: "verbose",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: false,
    // 			DESCRIPTION: "This field specifies whether activity of this module will be logged to on the server's file system.",
    // 			ADVANCED_INPUT: true
    // 		}	
    // 	],
    // 	"OUTPUTS": [

    // 	],
    // 	DISPLAY_NAME: "Apply principal component analysis",
    // 	PRODUCT_LEVEL: "Standard",
    // 	DESCRIPTION: "This module is responsible for applying principal component analysis (PCA) which is an unsupervised dimensionality reduction technique which attempts to describe data in an orthogonally represented set of features called principal components.",
    // 	KEYWORDS: [
    // 		"MACHINE LEARNING",
    // 		"UNSPERVISED",
    // 		"PRINCIPAL COMPONENT ANALYSIS"
    // 	]
    // },
    // "ICA": {
    // 	"INPUTS": [
    // 		{
    // 			DISPLAY_NAME: "Number of Independent Components",
    // 			VARIABLE_NAME: "n",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 2,
    // 			DESCRIPTION: "This field specifies the number of independent components to report from the independent component analysis (ICA).",
    // 			ADVANCED_INPUT: false
    // 		},			
    // 		{
    // 			DISPLAY_NAME: "ICA Algorithm",
    // 			VARIABLE_NAME: "algorithm",
    // 			TYPE: "DROPDOWN_INPUT",
    // 			DEFAULT_VALUE: {
    // 				"parallel": true,
    // 				"deflation": false
    // 			},
    // 			DESCRIPTION: "This field specifies the algorithm for FastICA",
    // 			ADVANCED_INPUT: true
    // 		},
    // 		{
    // 			DISPLAY_NAME: "Whiten",
    // 			VARIABLE_NAME: "whiten",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: true,
    // 			DESCRIPTION: "This field indicates whether to multiply principal components by the square root of the number of samples and then divided by the singular values to ensure uncorrelated outputs with unit component-wise variances.",
    // 			ADVANCED_INPUT: true
    // 		},
    // 		{
    // 			DISPLAY_NAME: "G Function",
    // 			VARIABLE_NAME: "fun",
    // 			TYPE: "DROPDOWN_INPUT",
    // 			DEFAULT_VALUE: {
    // 				"logcosh": true,
    // 				"exp": false,
    // 				"cube": false
    // 			},
    // 			DESCRIPTION: "This field specifies the functional form of the G function used in the approximation to neg-entropy.",
    // 			ADVANCED_INPUT: true
    // 		},
    // 		{
    // 			DISPLAY_NAME: "G Function Arguments",
    // 			VARIABLE_NAME: "fun_args",
    // 			TYPE: "DICTIONARY_INPUT",
    // 			DEFAULT_VALUE: {},
    // 			DESCRIPTION: "This field specifies the arguments that will be passed into the function for G.",
    // 			ADVANCED_INPUT: true
    // 		},	
    // 		{
    // 			DISPLAY_NAME: "Maximum Number of Iterations",
    // 			VARIABLE_NAME: "max_iter",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 200,
    // 			DESCRIPTION: "This field specifies the maximum number of iterations for the ICA algorithm..",
    // 			ADVANCED_INPUT: true
    // 		},	
    // 		{
    // 			DISPLAY_NAME: "Tolerance",
    // 			VARIABLE_NAME: "tol",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 1e-4,
    // 			DESCRIPTION: "This field specifies the tolerance for each iteration of the algorithm.",
    // 			ADVANCED_INPUT: true
    // 		},	
    // 		{
    // 			DISPLAY_NAME: "Seed",
    // 			VARIABLE_NAME: "seed",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: null,
    // 			DESCRIPTION: "This field specifies whether the random assignment of data into training and testing data should be seeded with a default value so that results are reproducible.",
    // 			ADVANCED_INPUT: true
    // 		},						
    // 		{
    // 			DISPLAY_NAME: "Verbose",
    // 			VARIABLE_NAME: "verbose",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: false,
    // 			DESCRIPTION: "This field specifies whether activity of this module will be logged to on the server's file system.",
    // 			ADVANCED_INPUT: true
    // 		}	
    // 	],
    // 	"OUTPUTS": [

    // 	],
    // 	DISPLAY_NAME: "Independant component analysis",
    // 	PRODUCT_LEVEL: "Standard",
    // 	DESCRIPTION: "This module is responsible for applying principal component analysis (PCA) which is an unsupervised dimensionality reduction technique which attempts to describe data in an orthogonally represented set of features called principal components.",
    // 	KEYWORDS: [
    // 		"MACHINE LEARNING",
    // 		"UNSPERVISED",
    // 		"INDPEDENT COMPONENT ANALYSIS"
    // 	]
    // },
    // "TSNE": {
    // 	"INPUTS": [
    // 		{
    // 			DISPLAY_NAME: "Number of Dimensions",
    // 			VARIABLE_NAME: "n",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 2,
    // 			DESCRIPTION: "This field specifies the number of principal components to report from the t-distributed stochastic neighbor embedding algorithm (t-SNE).",
    // 			ADVANCED_INPUT: false
    // 		},	
    // 		{
    // 			DISPLAY_NAME: "Perplexity",
    // 			VARIABLE_NAME: "perplexity",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 30,
    // 			DESCRIPTION: "This field specifies the number of nearest neighbors used in manifold learning.",
    // 			ADVANCED_INPUT: true
    // 		},		
    // 		{
    // 			DISPLAY_NAME: "Early Exaggeration",
    // 			VARIABLE_NAME: "early_exaggeration",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 12,
    // 			DESCRIPTION: "This field specifies how tight natural clusters in the original data space are embedded in space and how much space will be put between them.",
    // 			ADVANCED_INPUT: true
    // 		},		
    // 		{
    // 			DISPLAY_NAME: "Learning Rate",
    // 			VARIABLE_NAME: "learning_rate",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 200,
    // 			DESCRIPTION: "This field specifies the learning rate for t-SNE is usually in the range [10.0, 1000.0],",
    // 			ADVANCED_INPUT: true
    // 		},		
    // 		{
    // 			DISPLAY_NAME: "Number of Iterations",
    // 			VARIABLE_NAME: "n_iter",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 1000,
    // 			DESCRIPTION: "This field specifies the maximum number of iterations for the optimization.",
    // 			ADVANCED_INPUT: true
    // 		},			
    // 		{
    // 			DISPLAY_NAME: "Number of Iterations without Progress",
    // 			VARIABLE_NAME: "n_iter_without_progress",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 3000,
    // 			DESCRIPTION: "This field specifies the maximum number of iterations without progress before we abort the optimization. This is used after 250 initial iterations with early exaggeration.",
    // 			ADVANCED_INPUT: true
    // 		},			
    // 		{
    // 			DISPLAY_NAME: "Minimum Gradient Norm",
    // 			VARIABLE_NAME: "min_grad_norm",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 1e-7,
    // 			DESCRIPTION: "This field specifies the maximum number of iterations for the optimization.",
    // 			ADVANCED_INPUT: true
    // 		},			
    // 		{
    // 			DISPLAY_NAME: "Number of Iterations",
    // 			VARIABLE_NAME: "n_iter",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: null,
    // 			DESCRIPTION: "This field specifies the threshold value of the gradient norm for which the optimization will stop.",
    // 			ADVANCED_INPUT: true
    // 		},		
    // 		{
    // 			DISPLAY_NAME: "Distance Metric",
    // 			VARIABLE_NAME: "metric",
    // 			TYPE: "DROPDOWN_INPUT",
    // 			DEFAULT_VALUE: {
    // 				"euclidean": true
    // 			},
    // 			DESCRIPTION: "This field specifies the metric to use when calculating distance between instances in a feature array.",
    // 			ADVANCED_INPUT: true
    // 		},	
    // 		{
    // 			DISPLAY_NAME: "Initialized Embedding",
    // 			VARIABLE_NAME: "init",
    // 			TYPE: "DROPDOWN_INPUT",
    // 			DEFAULT_VALUE: {
    // 				"random": true,
    // 				"pca": false
    // 			},
    // 			DESCRIPTION: "This field specifies the type of embedding initialization.",
    // 			ADVANCED_INPUT: true
    // 		},	
    // 		{
    // 			DISPLAY_NAME: "Method",
    // 			VARIABLE_NAME: "method",
    // 			TYPE: "DROPDOWN_INPUT",
    // 			DEFAULT_VALUE: {
    // 				"barnes_hut": true,
    // 				"exact": false
    // 			},
    // 			DESCRIPTION: "This field specifies the gradient calculation algorithm used.",
    // 			ADVANCED_INPUT: true
    // 		},		
    // 		{
    // 			DISPLAY_NAME: "Angular Size of Distant Node",
    // 			VARIABLE_NAME: "angle",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 0.5,
    // 			DESCRIPTION: "This field specifies the angular size of a distant node as measured from a point. There is an inherent trade-off between speed and accuracy for Barnes-Hut T-SNE.",
    // 			ADVANCED_INPUT: true
    // 		},		
    // 		{
    // 			DISPLAY_NAME: "Number of Jobs",
    // 			VARIABLE_NAME: "angle",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 1,
    // 			DESCRIPTION: "This field specifies the number of parallel jobs to run for neighbors search.",
    // 			ADVANCED_INPUT: true
    // 		},		
    // 		{
    // 			DISPLAY_NAME: "Square Distances",
    // 			VARIABLE_NAME: "square_distances",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: true,
    // 			DESCRIPTION: "This field specifies whether tSNE should square its values.",
    // 			ADVANCED_INPUT: true
    // 		},							
    // 		{
    // 			DISPLAY_NAME: "Seed",
    // 			VARIABLE_NAME: "seed",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: null,
    // 			DESCRIPTION: "This field specifies whether the random assignment of data into training and testing data should be seeded with a default value so that results are reproducible.",
    // 			ADVANCED_INPUT: true
    // 		},								
    // 		{
    // 			DISPLAY_NAME: "Verbose",
    // 			VARIABLE_NAME: "verbose",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: false,
    // 			DESCRIPTION: "This field specifies whether activity of this module will be logged to on the server's file system.",
    // 			ADVANCED_INPUT: true
    // 		}	
    // 	],
    // 	"OUTPUTS": [

    // 	],
    // 	DISPLAY_NAME: "Apply t-distributed stochastic neighbor embedding",
    // 	PRODUCT_LEVEL: "Standard",
    // 	DESCRIPTION: "This module is responsible for applying the t-distributed stochastic neighbor embedding (t-SNE) algorithm which is a unsupervised dimensionality reduction technique that attempts to retain local distances from a high dimensional space to a lower dimensional space.",
    // 	KEYWORDS: [
    // 		"MACHINE LEARNING",
    // 		"UNSPERVISED",
    // 		"T-DISTRIBUTED STOCHASTIC NEIGHBOR EMBEDDING"
    // 	]
    // },
    // "NAIVEBAYES": {
    // 	"INPUTS": [
    // 		{
    // 			DISPLAY_NAME: "Variable Smoothing",
    // 			VARIABLE_NAME: "var_smoothing",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 1e-9,
    // 			DESCRIPTION: "This field specifies the portion of the largest variance of all features that is added to variances for calculation stability.",
    // 			ADVANCED_INPUT: true
    // 		},
    // 		{
    // 			DISPLAY_NAME: "Seed",
    // 			VARIABLE_NAME: "seed",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: null,
    // 			DESCRIPTION: "This field specifies whether the random assignment of data into training and testing data should be seeded with a default value so that results are reproducible.",
    // 			ADVANCED_INPUT: true
    // 		},				
    // 		{
    // 			DISPLAY_NAME: "Verbose",
    // 			VARIABLE_NAME: "verbose",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: false,
    // 			DESCRIPTION: "This field specifies whether activity of this module will be logged to on the server's file system.",
    // 			ADVANCED_INPUT: true
    // 		}	
    // 	],
    // 	"OUTPUTS": [

    // 	],
    // 	DISPLAY_NAME: "Apply naive Bayes",
    // 	PRODUCT_LEVEL: "Standard",
    // 	DESCRIPTION: "This module is responsible for applying the naive Bayes algorithm which is a supervised machine learning method that uses the probability of feature occurrences in order to describe the assignment of different classes.",
    // 	KEYWORDS: [
    // 		"MACHINE LEARNING",
    // 		"SUPERVISED"
    // 	]
    // },
    // "KNNCLASSIFIER": {
    // 	"INPUTS": [
    // 		{
    // 			DISPLAY_NAME: "Number of K-Nearest Neighbors",
    // 			VARIABLE_NAME: "k",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 3,
    // 			DESCRIPTION: "This field specifies the number of neatest neighbors to use in making a prediction for a given sample.",
    // 			ADVANCED_INPUT: true
    // 		}, 
    // 		{
    // 			DISPLAY_NAME: "Weights",
    // 			VARIABLE_NAME: "weights",
    // 			TYPE: "DROPDOWN_INPUT",
    // 			DEFAULT_VALUE: {
    // 				"uniform": true,
    // 				"distance": false
    // 			},
    // 			DESCRIPTION: "This field specifies the weight function used in prediction.",
    // 			ADVANCED_INPUT: true
    // 		},		
    // 		{
    // 			DISPLAY_NAME: "Algorithm",
    // 			VARIABLE_NAME: "algorithm",
    // 			TYPE: "DROPDOWN_INPUT",
    // 			DEFAULT_VALUE: {
    // 				"ball_tree": true,
    // 				"kd_tree": false,
    // 				"brute": false,
    // 				"auto": false
    // 			},
    // 			DESCRIPTION: "This field specifies the algorithm used to compute the nearest neighbors.",
    // 			ADVANCED_INPUT: true
    // 		},		
    // 		{
    // 			DISPLAY_NAME: "Leaf Size",
    // 			VARIABLE_NAME: "leaf_size",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 30,
    // 			DESCRIPTION: "This field specifies the leaf sized passed to BallTree or KDTree algorithm.",
    // 			ADVANCED_INPUT: true
    // 		}, 
    // 		{
    // 			DISPLAY_NAME: "Power",
    // 			VARIABLE_NAME: "p",
    // 			TYPE: "DROPDOWN_INPUT",
    // 			DEFAULT_VALUE: {
    // 				"minkowski": true
    // 			},
    // 			DESCRIPTION: "This field specifies the distance metric to use for the tree.",
    // 			ADVANCED_INPUT: true
    // 		}, 
    // 		{
    // 			DISPLAY_NAME: "Metric",
    // 			VARIABLE_NAME: "metric",
    // 			TYPE: "DROPDOWN_INPUT",
    // 			DEFAULT_VALUE: {
    // 				"ball_tree": true,
    // 				"kd_tree": false,
    // 				"brute": false,
    // 				"auto": false
    // 			},
    // 			DESCRIPTION: "This field specifies the algorithm used to compute the nearest neighbors.",
    // 			ADVANCED_INPUT: true
    // 		},		
    // 		{
    // 			DISPLAY_NAME: "Number of Jobs",
    // 			VARIABLE_NAME: "n_jobs",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: null,
    // 			DESCRIPTION: "This field specifies the number of parallel jobs to run for neighbors search.",
    // 			ADVANCED_INPUT: true
    // 		}, 			
    // 		{
    // 			DISPLAY_NAME: "Seed",
    // 			VARIABLE_NAME: "seed",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: null,
    // 			DESCRIPTION: "This field specifies whether the random assignment of data into training and testing data should be seeded with a default value so that results are reproducible.",
    // 			ADVANCED_INPUT: true
    // 		},					
    // 		{
    // 			DISPLAY_NAME: "Verbose",
    // 			VARIABLE_NAME: "verbose",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: false,
    // 			DESCRIPTION: "This field specifies whether activity of this module will be logged to on the server's file system.",
    // 			ADVANCED_INPUT: true
    // 		}					
    // 	],
    // 	"OUTPUTS": [

    // 	],
    // 	DISPLAY_NAME: "Apply k-nearest neighbor classification",
    // 	PRODUCT_LEVEL: "Standard",
    // 	DESCRIPTION: "This module is responsible for applying the k-nearest neighbor (KNN) algorithm which is a supervised machine learning method that .",
    // 	KEYWORDS: [
    // 		"MACHINE LEARNING",
    // 		"SUPERVISED"
    // 	]
    // },	
    // "KNNREGRESSOR": {
    // 	"INPUTS": [
    // 		{
    // 			DISPLAY_NAME: "Number of K-Nearest Neighbors",
    // 			VARIABLE_NAME: "k",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 3,
    // 			DESCRIPTION: "This field specifies the number of neatest neighbors to use in making a prediction for a given sample.",
    // 			ADVANCED_INPUT: true
    // 		}, 
    // 		{
    // 			DISPLAY_NAME: "Weights",
    // 			VARIABLE_NAME: "weights",
    // 			TYPE: "DROPDOWN_INPUT",
    // 			DEFAULT_VALUE: {
    // 				"uniform": true,
    // 				"distance": false
    // 			},
    // 			DESCRIPTION: "This field specifies the weight function used in prediction.",
    // 			ADVANCED_INPUT: true
    // 		},		
    // 		{
    // 			DISPLAY_NAME: "Algorithm",
    // 			VARIABLE_NAME: "algorithm",
    // 			TYPE: "DROPDOWN_INPUT",
    // 			DEFAULT_VALUE: {
    // 				"ball_tree": true,
    // 				"kd_tree": false,
    // 				"brute": false,
    // 				"auto": false
    // 			},
    // 			DESCRIPTION: "This field specifies the algorithm used to compute the nearest neighbors.",
    // 			ADVANCED_INPUT: true
    // 		},		
    // 		{
    // 			DISPLAY_NAME: "Leaf Size",
    // 			VARIABLE_NAME: "leaf_size",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 30,
    // 			DESCRIPTION: "This field specifies the leaf sized passed to BallTree or KDTree algorithm.",
    // 			ADVANCED_INPUT: true
    // 		}, 
    // 		{
    // 			DISPLAY_NAME: "Power",
    // 			VARIABLE_NAME: "p",
    // 			TYPE: "DROPDOWN_INPUT",
    // 			DEFAULT_VALUE: {
    // 				"minkowski": true
    // 			},
    // 			DESCRIPTION: "This field specifies the distance metric to use for the tree.",
    // 			ADVANCED_INPUT: true
    // 		}, 
    // 		{
    // 			DISPLAY_NAME: "Metric",
    // 			VARIABLE_NAME: "metric",
    // 			TYPE: "DROPDOWN_INPUT",
    // 			DEFAULT_VALUE: {
    // 				"ball_tree": true,
    // 				"kd_tree": false,
    // 				"brute": false,
    // 				"auto": false
    // 			},
    // 			DESCRIPTION: "This field specifies the algorithm used to compute the nearest neighbors.",
    // 			ADVANCED_INPUT: true
    // 		},		
    // 		{
    // 			DISPLAY_NAME: "Number of Jobs",
    // 			VARIABLE_NAME: "n_jobs",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: null,
    // 			DESCRIPTION: "This field specifies the number of parallel jobs to run for neighbors search.",
    // 			ADVANCED_INPUT: true
    // 		}, 			
    // 		{
    // 			DISPLAY_NAME: "Seed",
    // 			VARIABLE_NAME: "seed",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: null,
    // 			DESCRIPTION: "This field specifies whether the random assignment of data into training and testing data should be seeded with a default value so that results are reproducible.",
    // 			ADVANCED_INPUT: true
    // 		},					
    // 		{
    // 			DISPLAY_NAME: "Verbose",
    // 			VARIABLE_NAME: "verbose",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: false,
    // 			DESCRIPTION: "This field specifies whether activity of this module will be logged to on the server's file system.",
    // 			ADVANCED_INPUT: true
    // 		}					
    // 	],
    // 	"OUTPUTS": [

    // 	],
    // 	DISPLAY_NAME: "Apply k-nearest neighbor regression",
    // 	PRODUCT_LEVEL: "Standard",
    // 	DESCRIPTION: "This module is responsible for applying the k-nearest neighbor (KNN) algorithm which is a supervised machine learning method that .",
    // 	KEYWORDS: [
    // 		"MACHINE LEARNING",
    // 		"SUPERVISED"
    // 	]
    // },
    // "DECISIONTREECLASSIFIER": {
    // 	"INPUTS": [
    // 		{
    // 			DISPLAY_NAME: "Criterion",
    // 			VARIABLE_NAME: "criterion",
    // 			TYPE: "DROPDOWN_INPUT",
    // 			DEFAULT_VALUE: {
    // 				"gini": true,
    // 				"entropy": false
    // 			},
    // 			DESCRIPTION: "This field specifies the function to measure the quality of a split.",
    // 			ADVANCED_INPUT: true
    // 		},	
    // 		{
    // 			DISPLAY_NAME: "Splitter",
    // 			VARIABLE_NAME: "splitter",
    // 			TYPE: "DROPDOWN_INPUT",
    // 			DEFAULT_VALUE: {
    // 				"best": true,
    // 				"random": false
    // 			},
    // 			DESCRIPTION: "This field specifies the strategy used to choose the split at each node.",
    // 			ADVANCED_INPUT: true
    // 		},	
    // 		{
    // 			DISPLAY_NAME: "Maximum Depth",
    // 			VARIABLE_NAME: "max_depth",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: null,
    // 			DESCRIPTION: "This field specifies the maximum depth of the tree.",
    // 			ADVANCED_INPUT: true
    // 		},	
    // 		{
    // 			DISPLAY_NAME: "Minimum Number of Samples to Split",
    // 			VARIABLE_NAME: "min_samples_split",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 2,
    // 			DESCRIPTION: "This field specifies the minimum number of samples required to split an internal node. If it is an int, then it is considered as an absolute number. If it is a float, then consider it as a fraction of all samples.",
    // 			ADVANCED_INPUT: true
    // 		},		
    // 		{
    // 			DISPLAY_NAME: "Minimum Number of Samples per Leaf",
    // 			VARIABLE_NAME: "min_samples_leaf",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 1,
    // 			DESCRIPTION: "This field specifies the minimum number of samples required to be at a leaf node. If it is an int, then it is considered as an absolute number. If it is a float, then consider it as a fraction of all samples.",
    // 			ADVANCED_INPUT: true
    // 		},	
    // 		{
    // 			DISPLAY_NAME: "Minimum Weight Fraction per Leaf",
    // 			VARIABLE_NAME: "min_weight_fraction_leaf",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 0,
    // 			DESCRIPTION: "This field specifies the minimum weighted fraction of the sum total of weights (of all the input samples) required to be at a leaf node.",
    // 			ADVANCED_INPUT: true
    // 		},		
    // 		{
    // 			DISPLAY_NAME: "Maximum Number of Features",
    // 			VARIABLE_NAME: "max_features",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: null,
    // 			DESCRIPTION: "This field specifies the number of features to consider when looking for the best split.",
    // 			ADVANCED_INPUT: true
    // 		},	
    // 		{
    // 			DISPLAY_NAME: "Maximum Number of Leaf Nodes",
    // 			VARIABLE_NAME: "max_leaf_nodes",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: null,
    // 			DESCRIPTION: "This field specifies the maximum number of leaf nodes.",
    // 			ADVANCED_INPUT: true
    // 		},	
    // 		{
    // 			DISPLAY_NAME: "Minimum Purity Decrease",
    // 			VARIABLE_NAME: "min_impurity_decrease",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 0,
    // 			DESCRIPTION: "This field specifies the value at which a node will be split if this split induces a decrease of the impurity greater than or equal to this value..",
    // 			ADVANCED_INPUT: true
    // 		},
    // 		{
    // 			DISPLAY_NAME: "Minimum Purity Split",
    // 			VARIABLE_NAME: "min_impurity_split",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 0,
    // 			DESCRIPTION: "This field specifies the threshold for early stopping in tree growth. A node will split if its impurity is above the threshold, otherwise it is a leaf.",
    // 			ADVANCED_INPUT: true
    // 		},
    // 		{
    // 			DISPLAY_NAME: "Complexity",
    // 			VARIABLE_NAME: "ccp_alpha",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 0,
    // 			DESCRIPTION: "This field specifies the complexity parameter used for Minimal Cost-Complexity Pruning. The subtree with the largest cost complexity that is smaller than ccp_alpha will be chosen.",
    // 			ADVANCED_INPUT: true
    // 		},						
    // 		{
    // 			DISPLAY_NAME: "Seed",
    // 			VARIABLE_NAME: "seed",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: null,
    // 			DESCRIPTION: "This field specifies whether the random assignment of data into training and testing data should be seeded with a default value so that results are reproducible.",
    // 			ADVANCED_INPUT: true
    // 		},				
    // 		{
    // 			DISPLAY_NAME: "Verbose",
    // 			VARIABLE_NAME: "verbose",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: false,
    // 			DESCRIPTION: "This field specifies whether activity of this module will be logged to on the server's file system.",
    // 			ADVANCED_INPUT: true
    // 		}	
    // 	],
    // 	"OUTPUTS": [

    // 	],
    // 	DISPLAY_NAME: "Apply decision tree classification",
    // 	PRODUCT_LEVEL: "Standard",
    // 	DESCRIPTION: "This module is responsible for applying the decision tree algorithm which is a supervised machine learning method that .",
    // 	KEYWORDS: [
    // 		"MACHINE LEARNING",
    // 		"SUPERVISED"
    // 	]
    // },
    // "DECISIONTREEREGRESSOR": {
    // 	"INPUTS": [
    // 		{
    // 			DISPLAY_NAME: "Criterion",
    // 			VARIABLE_NAME: "criterion",
    // 			TYPE: "DROPDOWN_INPUT",
    // 			DEFAULT_VALUE: {
    // 				"mse": true,
    // 				"mae": false
    // 			},
    // 			DESCRIPTION: "This field specifies the function to measure the quality of a split.",
    // 			ADVANCED_INPUT: true
    // 		},	
    // 		{
    // 			DISPLAY_NAME: "Splitter",
    // 			VARIABLE_NAME: "splitter",
    // 			TYPE: "DROPDOWN_INPUT",
    // 			DEFAULT_VALUE: {
    // 				"best": true,
    // 				"random": false
    // 			},
    // 			DESCRIPTION: "This field specifies the strategy used to choose the split at each node.",
    // 			ADVANCED_INPUT: true
    // 		},	
    // 		{
    // 			DISPLAY_NAME: "Maximum Depth",
    // 			VARIABLE_NAME: "max_depth",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: null,
    // 			DESCRIPTION: "This field specifies the maximum depth of the tree.",
    // 			ADVANCED_INPUT: true
    // 		},	
    // 		{
    // 			DISPLAY_NAME: "Minimum Number of Samples to Split",
    // 			VARIABLE_NAME: "min_samples_split",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 2,
    // 			DESCRIPTION: "This field specifies the minimum number of samples required to split an internal node. If it is an int, then it is considered as an absolute number. If it is a float, then consider it as a fraction of all samples.",
    // 			ADVANCED_INPUT: true
    // 		},		
    // 		{
    // 			DISPLAY_NAME: "Minimum Number of Samples per Leaf",
    // 			VARIABLE_NAME: "min_samples_leaf",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 1,
    // 			DESCRIPTION: "This field specifies the minimum number of samples required to be at a leaf node. If it is an int, then it is considered as an absolute number. If it is a float, then consider it as a fraction of all samples.",
    // 			ADVANCED_INPUT: true
    // 		},	
    // 		{
    // 			DISPLAY_NAME: "Minimum Weight Fraction per Leaf",
    // 			VARIABLE_NAME: "min_weight_fraction_leaf",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 0,
    // 			DESCRIPTION: "This field specifies the minimum weighted fraction of the sum total of weights (of all the input samples) required to be at a leaf node.",
    // 			ADVANCED_INPUT: true
    // 		},		
    // 		{
    // 			DISPLAY_NAME: "Maximum Number of Features",
    // 			VARIABLE_NAME: "max_features",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: null,
    // 			DESCRIPTION: "This field specifies the number of features to consider when looking for the best split.",
    // 			ADVANCED_INPUT: true
    // 		},	
    // 		{
    // 			DISPLAY_NAME: "Maximum Number of Leaf Nodes",
    // 			VARIABLE_NAME: "max_leaf_nodes",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: null,
    // 			DESCRIPTION: "This field specifies the maximum number of leaf nodes.",
    // 			ADVANCED_INPUT: true
    // 		},	
    // 		{
    // 			DISPLAY_NAME: "Minimum Purity Decrease",
    // 			VARIABLE_NAME: "min_impurity_decrease",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 0,
    // 			DESCRIPTION: "This field specifies the value at which a node will be split if this split induces a decrease of the impurity greater than or equal to this value..",
    // 			ADVANCED_INPUT: true
    // 		},
    // 		{
    // 			DISPLAY_NAME: "Minimum Purity Split",
    // 			VARIABLE_NAME: "min_impurity_split",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 0,
    // 			DESCRIPTION: "This field specifies the threshold for early stopping in tree growth. A node will split if its impurity is above the threshold, otherwise it is a leaf.",
    // 			ADVANCED_INPUT: true
    // 		},
    // 		{
    // 			DISPLAY_NAME: "Complexity",
    // 			VARIABLE_NAME: "ccp_alpha",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 0,
    // 			DESCRIPTION: "This field specifies the complexity parameter used for Minimal Cost-Complexity Pruning. The subtree with the largest cost complexity that is smaller than ccp_alpha will be chosen.",
    // 			ADVANCED_INPUT: true
    // 		},						
    // 		{
    // 			DISPLAY_NAME: "Seed",
    // 			VARIABLE_NAME: "seed",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: null,
    // 			DESCRIPTION: "This field specifies whether the random assignment of data into training and testing data should be seeded with a default value so that results are reproducible.",
    // 			ADVANCED_INPUT: true
    // 		},				
    // 		{
    // 			DISPLAY_NAME: "Verbose",
    // 			VARIABLE_NAME: "verbose",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: false,
    // 			DESCRIPTION: "This field specifies whether activity of this module will be logged to on the server's file system.",
    // 			ADVANCED_INPUT: true
    // 		}	
    // 	],
    // 	"OUTPUTS": [

    // 	],
    // 	DISPLAY_NAME: "Apply decision tree regression",
    // 	PRODUCT_LEVEL: "Standard",
    // 	DESCRIPTION: "This module is responsible for applying the decision tree algorithm which is a supervised machine learning method that .",
    // 	KEYWORDS: [
    // 		"MACHINE LEARNING",
    // 		"SUPERVISED"
    // 	]
    // },
    // "RADNOMFORESTCLASSIFIER": {
    // 	"INPUTS": [
    // 		{
    // 			DISPLAY_NAME: "Criterion",
    // 			VARIABLE_NAME: "criterion",
    // 			TYPE: "DROPDOWN_INPUT",
    // 			DEFAULT_VALUE: {
    // 				"gini": true,
    // 				"entropy": false
    // 			},
    // 			DESCRIPTION: "This field specifies the function to measure the quality of a split.",
    // 			ADVANCED_INPUT: true
    // 		},	
    // 		{
    // 			DISPLAY_NAME: "Splitter",
    // 			VARIABLE_NAME: "splitter",
    // 			TYPE: "DROPDOWN_INPUT",
    // 			DEFAULT_VALUE: {
    // 				"best": true,
    // 				"random": false
    // 			},
    // 			DESCRIPTION: "This field specifies the strategy used to choose the split at each node.",
    // 			ADVANCED_INPUT: true
    // 		},	
    // 		{
    // 			DISPLAY_NAME: "Maximum Depth",
    // 			VARIABLE_NAME: "max_depth",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: null,
    // 			DESCRIPTION: "This field specifies the maximum depth of the tree.",
    // 			ADVANCED_INPUT: true
    // 		},	
    // 		{
    // 			DISPLAY_NAME: "Minimum Number of Samples to Split",
    // 			VARIABLE_NAME: "min_samples_split",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 2,
    // 			DESCRIPTION: "This field specifies the minimum number of samples required to split an internal node. If it is an int, then it is considered as an absolute number. If it is a float, then consider it as a fraction of all samples.",
    // 			ADVANCED_INPUT: true
    // 		},		
    // 		{
    // 			DISPLAY_NAME: "Minimum Number of Samples per Leaf",
    // 			VARIABLE_NAME: "min_samples_leaf",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 1,
    // 			DESCRIPTION: "This field specifies the minimum number of samples required to be at a leaf node. If it is an int, then it is considered as an absolute number. If it is a float, then consider it as a fraction of all samples.",
    // 			ADVANCED_INPUT: true
    // 		},	
    // 		{
    // 			DISPLAY_NAME: "Minimum Weight Fraction per Leaf",
    // 			VARIABLE_NAME: "min_weight_fraction_leaf",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 0,
    // 			DESCRIPTION: "This field specifies the minimum weighted fraction of the sum total of weights (of all the input samples) required to be at a leaf node.",
    // 			ADVANCED_INPUT: true
    // 		},		
    // 		{
    // 			DISPLAY_NAME: "Maximum Number of Features",
    // 			VARIABLE_NAME: "max_features",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: null,
    // 			DESCRIPTION: "This field specifies the number of features to consider when looking for the best split.",
    // 			ADVANCED_INPUT: true
    // 		},	
    // 		{
    // 			DISPLAY_NAME: "Maximum Number of Leaf Nodes",
    // 			VARIABLE_NAME: "max_leaf_nodes",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: null,
    // 			DESCRIPTION: "This field specifies the maximum number of leaf nodes.",
    // 			ADVANCED_INPUT: true
    // 		},	
    // 		{
    // 			DISPLAY_NAME: "Minimum Purity Decrease",
    // 			VARIABLE_NAME: "min_impurity_decrease",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 0,
    // 			DESCRIPTION: "This field specifies the value at which a node will be split if this split induces a decrease of the impurity greater than or equal to this value..",
    // 			ADVANCED_INPUT: true
    // 		},
    // 		{
    // 			DISPLAY_NAME: "Minimum Purity Split",
    // 			VARIABLE_NAME: "min_impurity_split",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 0,
    // 			DESCRIPTION: "This field specifies the threshold for early stopping in tree growth. A node will split if its impurity is above the threshold, otherwise it is a leaf.",
    // 			ADVANCED_INPUT: true
    // 		},
    // 		{
    // 			DISPLAY_NAME: "Bootstrap",
    // 			VARIABLE_NAME: "bootstrap",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: true,
    // 			DESCRIPTION: "This field specifies whether bootstrap samples are used when building the trees. If false, the whole dataset is used for constructing the tree.",
    // 			ADVANCED_INPUT: true
    // 		},
    // 		{
    // 			DISPLAY_NAME: "Out-of-bag Score",
    // 			VARIABLE_NAME: "oob_score",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: false,
    // 			DESCRIPTION: "This field specifies whether to use out-of-bag samples to estimate the generalization score. Only available for bootstrapping.",
    // 			ADVANCED_INPUT: true
    // 		},
    // 		{
    // 			DISPLAY_NAME: "Complexity",
    // 			VARIABLE_NAME: "ccp_alpha",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 0,
    // 			DESCRIPTION: "This field specifies the complexity parameter used for Minimal Cost-Complexity Pruning. The subtree with the largest cost complexity that is smaller than ccp_alpha will be chosen.",
    // 			ADVANCED_INPUT: true
    // 		},						
    // 		{
    // 			DISPLAY_NAME: "Seed",
    // 			VARIABLE_NAME: "seed",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: null,
    // 			DESCRIPTION: "This field specifies whether the random assignment of data into training and testing data should be seeded with a default value so that results are reproducible.",
    // 			ADVANCED_INPUT: true
    // 		},				
    // 		{
    // 			DISPLAY_NAME: "Verbose",
    // 			VARIABLE_NAME: "verbose",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: false,
    // 			DESCRIPTION: "This field specifies whether activity of this module will be logged to on the server's file system.",
    // 			ADVANCED_INPUT: true
    // 		}	
    // 	],
    // 	"OUTPUTS": [

    // 	],
    // 	DISPLAY_NAME: "Apply random forest classification",
    // 	PRODUCT_LEVEL: "Standard",
    // 	DESCRIPTION: "This module is responsible for applying the random forest algorithm which is a supervised machine learning method that .",
    // 	KEYWORDS: [
    // 		"MACHINE LEARNING",
    // 		"SUPERVISED"
    // 	]
    // },
    // "RANDOMFOESTREGRESSOR": {
    // 	"INPUTS": [
    // 		{
    // 			DISPLAY_NAME: "Criterion",
    // 			VARIABLE_NAME: "criterion",
    // 			TYPE: "DROPDOWN_INPUT",
    // 			DEFAULT_VALUE: {
    // 				"mse": true,
    // 				"mae": false
    // 			},
    // 			DESCRIPTION: "This field specifies the function to measure the quality of a split.",
    // 			ADVANCED_INPUT: true
    // 		},	
    // 		{
    // 			DISPLAY_NAME: "Splitter",
    // 			VARIABLE_NAME: "splitter",
    // 			TYPE: "DROPDOWN_INPUT",
    // 			DEFAULT_VALUE: {
    // 				"best": true,
    // 				"random": false
    // 			},
    // 			DESCRIPTION: "This field specifies the strategy used to choose the split at each node.",
    // 			ADVANCED_INPUT: true
    // 		},	
    // 		{
    // 			DISPLAY_NAME: "Maximum Depth",
    // 			VARIABLE_NAME: "max_depth",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: null,
    // 			DESCRIPTION: "This field specifies the maximum depth of the tree.",
    // 			ADVANCED_INPUT: true
    // 		},	
    // 		{
    // 			DISPLAY_NAME: "Minimum Number of Samples to Split",
    // 			VARIABLE_NAME: "min_samples_split",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 2,
    // 			DESCRIPTION: "This field specifies the minimum number of samples required to split an internal node. If it is an int, then it is considered as an absolute number. If it is a float, then consider it as a fraction of all samples.",
    // 			ADVANCED_INPUT: true
    // 		},		
    // 		{
    // 			DISPLAY_NAME: "Minimum Number of Samples per Leaf",
    // 			VARIABLE_NAME: "min_samples_leaf",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 1,
    // 			DESCRIPTION: "This field specifies the minimum number of samples required to be at a leaf node. If it is an int, then it is considered as an absolute number. If it is a float, then consider it as a fraction of all samples.",
    // 			ADVANCED_INPUT: true
    // 		},	
    // 		{
    // 			DISPLAY_NAME: "Minimum Weight Fraction per Leaf",
    // 			VARIABLE_NAME: "min_weight_fraction_leaf",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 0,
    // 			DESCRIPTION: "This field specifies the minimum weighted fraction of the sum total of weights (of all the input samples) required to be at a leaf node.",
    // 			ADVANCED_INPUT: true
    // 		},		
    // 		{
    // 			DISPLAY_NAME: "Maximum Number of Features",
    // 			VARIABLE_NAME: "max_features",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: null,
    // 			DESCRIPTION: "This field specifies the number of features to consider when looking for the best split.",
    // 			ADVANCED_INPUT: true
    // 		},	
    // 		{
    // 			DISPLAY_NAME: "Maximum Number of Leaf Nodes",
    // 			VARIABLE_NAME: "max_leaf_nodes",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: null,
    // 			DESCRIPTION: "This field specifies the maximum number of leaf nodes.",
    // 			ADVANCED_INPUT: true
    // 		},	
    // 		{
    // 			DISPLAY_NAME: "Minimum Purity Decrease",
    // 			VARIABLE_NAME: "min_impurity_decrease",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 0,
    // 			DESCRIPTION: "This field specifies the value at which a node will be split if this split induces a decrease of the impurity greater than or equal to this value..",
    // 			ADVANCED_INPUT: true
    // 		},
    // 		{
    // 			DISPLAY_NAME: "Minimum Purity Split",
    // 			VARIABLE_NAME: "min_impurity_split",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 0,
    // 			DESCRIPTION: "This field specifies the threshold for early stopping in tree growth. A node will split if its impurity is above the threshold, otherwise it is a leaf.",
    // 			ADVANCED_INPUT: true
    // 		},
    // 		{
    // 			DISPLAY_NAME: "Bootstrap",
    // 			VARIABLE_NAME: "bootstrap",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: true,
    // 			DESCRIPTION: "This field specifies whether bootstrap samples are used when building the trees. If false, the whole dataset is used for constructing the tree.",
    // 			ADVANCED_INPUT: true
    // 		},
    // 		{
    // 			DISPLAY_NAME: "Out-of-bag Score",
    // 			VARIABLE_NAME: "oob_score",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: false,
    // 			DESCRIPTION: "This field specifies whether to use out-of-bag samples to estimate the generalization score. Only available for bootstrapping.",
    // 			ADVANCED_INPUT: true
    // 		},
    // 		{
    // 			DISPLAY_NAME: "Complexity",
    // 			VARIABLE_NAME: "ccp_alpha",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 0,
    // 			DESCRIPTION: "This field specifies the complexity parameter used for Minimal Cost-Complexity Pruning. The subtree with the largest cost complexity that is smaller than ccp_alpha will be chosen.",
    // 			ADVANCED_INPUT: true
    // 		},						
    // 		{
    // 			DISPLAY_NAME: "Seed",
    // 			VARIABLE_NAME: "seed",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: null,
    // 			DESCRIPTION: "This field specifies whether the random assignment of data into training and testing data should be seeded with a default value so that results are reproducible.",
    // 			ADVANCED_INPUT: true
    // 		},				
    // 		{
    // 			DISPLAY_NAME: "Verbose",
    // 			VARIABLE_NAME: "verbose",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: false,
    // 			DESCRIPTION: "This field specifies whether activity of this module will be logged to on the server's file system.",
    // 			ADVANCED_INPUT: true
    // 		}	
    // 	],
    // 	"OUTPUTS": [

    // 	],
    // 	DISPLAY_NAME: "Apply random forest regression",
    // 	PRODUCT_LEVEL: "Standard",
    // 	DESCRIPTION: "This module is responsible for applying the random forest algorithm which is a supervised machine learning method that .",
    // 	KEYWORDS: [
    // 		"MACHINE LEARNING",
    // 		"SUPERVISED"
    // 	]
    // },
    // "SVM": {
    // 	"INPUTS": [
    // 		{
    // 			DISPLAY_NAME: "Regularization Parameter C",
    // 			VARIABLE_NAME: "C",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 1,
    // 			DESCRIPTION: "This field specifies the regularization parameter. The strength of the regularization is inversely proportional to C. Must be strictly positive. The penalty is a squared l2 penalty.",
    // 			ADVANCED_INPUT: true
    // 		},					
    // 		{
    // 			DISPLAY_NAME: "Kernel",
    // 			DEFAULT_NAME: "kernel",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: {
    // 				'poly': true,
    // 				'rbf': false,
    // 				'sigmoid': false,
    // 				'linear': false
    // 			},
    // 			DESCRIPTION: "This field specifies which kernel will be used for the support vector machine (SVM).",
    // 			ADVANCED_INPUT: false
    // 		},			
    // 		{
    // 			DISPLAY_NAME: "Polynomial Kernel Degree",
    // 			VARIABLE_NAME: "degree",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 3,
    // 			DESCRIPTION: "This field specifies the degree of the Polynomial kernel if it is used.",
    // 			ADVANCED_INPUT: true
    // 		},		
    // 		{
    // 			DISPLAY_NAME: "Gamma",
    // 			VARIABLE_NAME: "gamma",
    // 			TYPE: "DROPDOWN_INPUT",
    // 			DEFAULT_VALUE: {
    // 				"scale": true,
    // 				"auto": false
    // 			},
    // 			DESCRIPTION: "This field specifies how the kernel coefficient is calculated for the RBF, Polynomial and Sigmoid kernels.",
    // 			ADVANCED_INPUT: true
    // 		},	
    // 		{
    // 			DISPLAY_NAME: "Independent Coefficient",
    // 			VARIABLE_NAME: "coef0",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 0,
    // 			DESCRIPTION: "This field specifies and independent term in the kernel function for Polynomial and Sigmoid.",
    // 			ADVANCED_INPUT: true
    // 		},			
    // 		{
    // 			DISPLAY_NAME: "Shrinking",
    // 			VARIABLE_NAME: "shrinking",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: true,
    // 			DESCRIPTION: "This field specifies whether to use the shrinking heuristic.",
    // 			ADVANCED_INPUT: true
    // 		},
    // 		{
    // 			DISPLAY_NAME: "Report Probability",
    // 			VARIABLE_NAME: "probability",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: true,
    // 			DESCRIPTION: "This field specifies whether to enable probability estimates.",
    // 			ADVANCED_INPUT: true
    // 		},				
    // 		{
    // 			DISPLAY_NAME: "Tolerance",
    // 			VARIABLE_NAME: "tolerance",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 1e-3,
    // 			DESCRIPTION: "This field specifies the tolerance for the stopping criteria.",
    // 			ADVANCED_INPUT: true
    // 		},	
    // 		{
    // 			DISPLAY_NAME: "Cache Size",
    // 			VARIABLE_NAME: "cache_size",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 200,
    // 			DESCRIPTION: "This field specifies the size of the kernel cache (in MB).",
    // 			ADVANCED_INPUT: true
    // 		},					
    // 		{
    // 			DISPLAY_NAME: "Maximum Number of Iterations",
    // 			VARIABLE_NAME: "max_iter",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: -1,
    // 			DESCRIPTION: "This field specifies the hard limit on iterations within solver, or -1 for no limit.",
    // 			ADVANCED_INPUT: true
    // 		},			
    // 		{
    // 			DISPLAY_NAME: "Decision Function",
    // 			VARIABLE_NAME: "decision_function_shape",
    // 			TYPE: "DROPDOWN_INPUT",
    // 			DEFAULT_VALUE: {
    // 				"ovo": true,
    // 				"ovr": false
    // 			},
    // 			DESCRIPTION: "This field specifies whether to return a one-vs-rest (‘ovr’) decision function as all other classifiers, or the original one-vs-one (‘ovo’) decision function.",
    // 			ADVANCED_INPUT: true
    // 		},		
    // 		{
    // 			DISPLAY_NAME: "Break Ties",
    // 			VARIABLE_NAME: "break_ties",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: false,
    // 			DESCRIPTION: "This field specifies whether the model will break ties according to the confidence values of decision_function.",
    // 			ADVANCED_INPUT: true
    // 		},						
    // 		{
    // 			DISPLAY_NAME: "Seed",
    // 			VARIABLE_NAME: "seed",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: null,
    // 			DESCRIPTION: "This field specifies whether the random assignment of data into training and testing data should be seeded with a default value so that results are reproducible.",
    // 			ADVANCED_INPUT: true
    // 		},					
    // 		{
    // 			DISPLAY_NAME: "Verbose",
    // 			VARIABLE_NAME: "verbose",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: false,
    // 			DESCRIPTION: "This field specifies whether activity of this module will be logged to on the server's file system.",
    // 			ADVANCED_INPUT: true
    // 		}	
    // 	],
    // 	"OUTPUTS": [

    // 	],
    // 	DISPLAY_NAME: "Apply support vector machine",
    // 	PRODUCT_LEVEL: "Standard",
    // 	DESCRIPTION: "This module is responsible for applying the support vector machine algorithm which is a supervised machine learning method that attempts to classify data transformed by a kernel function using a hyperplane, serving as a classification barrier at one less dimension than that of the data.",
    // 	KEYWORDS: [
    // 		"MACHINE LEARNING",
    // 		"SUPERVISED"
    // 	]
    // },
    // "LOGISTICREGRESSION": {
    // 	"INPUTS": [
    // 		{
    // 			DISPLAY_NAME: "Penalty",
    // 			VARIABLE_NAME: "penalty",
    // 			TYPE: "DROPDOWN_INPUT",
    // 			DEFAULT_VALUE: {
    // 				"l1": true,
    // 				"l2": false,
    // 				"elasticnet": false,
    // 				"none": false
    // 			},
    // 			DESCRIPTION: "This field specifies the norm used in the penalization. The ‘newton-cg’, ‘sag’ and ‘lbfgs’ solvers support only l2 penalties. ‘elasticnet’ is only supported by the ‘saga’ solver. If ‘none’ (not supported by the liblinear solver), no regularization is applied.",
    // 			ADVANCED_INPUT: true
    // 		},		
    // 		{
    // 			DISPLAY_NAME: "Dual",
    // 			VARIABLE_NAME: "dual",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: false,
    // 			DESCRIPTION: "This field specifies whether to use dual formulation is only implemented for l2 penalty with liblinear solver. It is preferred to be false when the number of samples is greater than the number of features.",
    // 			ADVANCED_INPUT: true
    // 		},		
    // 		{
    // 			DISPLAY_NAME: "Tolerance",
    // 			VARIABLE_NAME: "tolerance",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 1e-4,
    // 			DESCRIPTION: "This field specifies the tolerance for the stopping criteria.",
    // 			ADVANCED_INPUT: true
    // 		},		
    // 		{
    // 			DISPLAY_NAME: "Regularization Parameter C",
    // 			VARIABLE_NAME: "C",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 1,
    // 			DESCRIPTION: "This field specifies the regularization parameter, which is the inverse of regularization strength; must be a positive float. Like in support vector machines, smaller values specify stronger regularization.",
    // 			ADVANCED_INPUT: true
    // 		},			
    // 		{
    // 			DISPLAY_NAME: "Fit Intercept",
    // 			VARIABLE_NAME: "fit_intercept",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: true,
    // 			DESCRIPTION: "This field specifies whether a constant (a.k.a. bias or intercept) should be added to the decision function.",
    // 			ADVANCED_INPUT: true
    // 		},			
    // 		{
    // 			DISPLAY_NAME: "Intercept Scaling",
    // 			VARIABLE_NAME: "intercept_scaling",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 1,
    // 			DESCRIPTION: "This field specifies a “synthetic” feature with constant value equal to intercept_scaling is appended to the instance vector. Useful only when the solver ‘liblinear’ is used and self.fit_intercept is set to True.",
    // 			ADVANCED_INPUT: true
    // 		},		
    // 		{
    // 			DISPLAY_NAME: "Solver",
    // 			VARIABLE_NAME: "solver",
    // 			TYPE: "DROPDOWN_INPUT",
    // 			DEFAULT_VALUE: {
    // 				"newton-cg": false,
    // 				"lbfgs": true,
    // 				"liblinear": false,
    // 				"sag": false,
    // 				"saga": false
    // 			},
    // 			DESCRIPTION: "This field specifies the algorithm used in solving the optimization problem.",
    // 			ADVANCED_INPUT: true
    // 		},	
    // 		{
    // 			DISPLAY_NAME: "Maximum Number of Iterations",
    // 			VARIABLE_NAME: "max_iter",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 100,
    // 			DESCRIPTION: "This field specifies the maximum number of iterations taken for the solvers to converge.",
    // 			ADVANCED_INPUT: true
    // 		},		
    // 		{
    // 			DISPLAY_NAME: "Multiple Class Strategy",
    // 			VARIABLE_NAME: "multi_class",
    // 			TYPE: "DROPDOWN_INPUT",
    // 			DEFAULT_VALUE: {
    // 				"auto": true,
    // 				"ovr": true,
    // 				"multinomial": false
    // 			},
    // 			DESCRIPTION: "This field specifies how to fit the labels. If the option chosen is ‘ovr’, then a binary problem is fit for each label. For ‘multinomial’ the loss minimised is the multinomial loss fit across the entire probability distribution, even when the data is binary.",
    // 			ADVANCED_INPUT: true
    // 		},
    // 		{
    // 			DISPLAY_NAME: "Warm Start",
    // 			VARIABLE_NAME: "warm_start",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: false,
    // 			DESCRIPTION: "This field specifieswhether to reuse the solution of the previous call to fit as initialization, otherwise, just erase the previous solution. Useless for liblinear solver.",
    // 			ADVANCED_INPUT: true
    // 		},
    // 		{
    // 			DISPLAY_NAME: "Number of Jobs",
    // 			VARIABLE_NAME: "n_jobs",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: null,
    // 			DESCRIPTION: "This field specifies the number of CPU cores used when parallelizing over classes if multi_class=’ovr’",
    // 			ADVANCED_INPUT: true
    // 		},		
    // 		{
    // 			DISPLAY_NAME: "L1 Regularization Ratio",
    // 			VARIABLE_NAME: "l1_ratio",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: null,
    // 			DESCRIPTION: "This field specifies the Elastic-Net mixing parameter, with 0 <= l1_ratio <= 1.",
    // 			ADVANCED_INPUT: true
    // 		},		
    // 		{
    // 			DISPLAY_NAME: "Seed",
    // 			VARIABLE_NAME: "seed",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: null,
    // 			DESCRIPTION: "This field specifies whether the random assignment of data into training and testing data should be seeded with a default value so that results are reproducible.",
    // 			ADVANCED_INPUT: true
    // 		},				
    // 		{
    // 			DISPLAY_NAME: "Verbose",
    // 			VARIABLE_NAME: "verbose",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: false,
    // 			DESCRIPTION: "This field specifies whether activity of this module will be logged to on the server's file system.",
    // 			ADVANCED_INPUT: true
    // 		}	
    // 	],
    // 	"OUTPUTS": [

    // 	],
    // 	DISPLAY_NAME: "Apply logistic regression",
    // 	PRODUCT_LEVEL: "Standard",
    // 	DESCRIPTION: "This module is responsible for applying the logistic regression algorithm which is a supervised machine learning method that attempts to regress data transformed by a logit linker function.",
    // 	KEYWORDS: [
    // 		"MACHINE LEARNING",
    // 		"SUPERVISED"
    // 	]
    // },
    // "LINEARREGRESSION": {
    // 	"INPUTS": [
    // 		{
    // 			DISPLAY_NAME: "Fit Intercept",
    // 			VARIABLE_NAME: "fit_intercept",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: true,
    // 			DESCRIPTION: "This field specifies whether a constant (a.k.a. bias or intercept) should be added to the decision function.",
    // 			ADVANCED_INPUT: true
    // 		},
    // 		{
    // 			DISPLAY_NAME: "Normalize Predictors",
    // 			VARIABLE_NAME: "normalize",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: false,
    // 			DESCRIPTION: "This field specifies whether to normalize regressors in X.",
    // 			ADVANCED_INPUT: true
    // 		},		
    // 		{
    // 			DISPLAY_NAME: "Number of Jobs",
    // 			VARIABLE_NAME: "n_jobs",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: null,
    // 			DESCRIPTION: "This field specifies the number of jobs to use for the computation..",
    // 			ADVANCED_INPUT: true
    // 		},		
    // 		{
    // 			DISPLAY_NAME: "Positive Coefficients",
    // 			VARIABLE_NAME: "positive",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: false,
    // 			DESCRIPTION: "This field specifies whether to force only positive coefficients.",
    // 			ADVANCED_INPUT: true
    // 		},
    // 		{
    // 			DISPLAY_NAME: "Seed",
    // 			VARIABLE_NAME: "seed",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: null,
    // 			DESCRIPTION: "This field specifies whether the random assignment of data into training and testing data should be seeded with a default value so that results are reproducible.",
    // 			ADVANCED_INPUT: true
    // 		},				
    // 		{
    // 			DISPLAY_NAME: "Verbose",
    // 			VARIABLE_NAME: "verbose",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: false,
    // 			DESCRIPTION: "This field specifies whether activity of this module will be logged to on the server's file system.",
    // 			ADVANCED_INPUT: true
    // 		}	
    // 	],
    // 	"OUTPUTS": [

    // 	],
    // 	DISPLAY_NAME: "Apply linear regression",
    // 	PRODUCT_LEVEL: "Standard",
    // 	DESCRIPTION: "This module is responsible for applying the linear regression algorithm which is a supervised machine learning method that attempts to regress data.",
    // 	KEYWORDS: [
    // 		"MACHINE LEARNING",
    // 		"SUPERVISED"
    // 	]
    // },
    // "DOWNSAMPLE": {
    // 	"INPUTS": [
    // 		{
    // 			DISPLAY_NAME: "Sampling Rate",
    // 			VARIABLE_NAME: "old_sampling_rate",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 128,
    // 			DESCRIPTION: "This field specifies the sampling rate of the signal.",
    // 			ADVANCED_INPUT: false
    // 		},	
    // 		{
    // 			DISPLAY_NAME: "New Sampling Rate",
    // 			VARIABLE_NAME: "new_sampling_rate",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 1,
    // 			DESCRIPTION: "This field specifies the sampling rate to which the data will be downsampled.",
    // 			ADVANCED_INPUT: false
    // 		},						
    // 		{
    // 			DISPLAY_NAME: "Verbose",
    // 			VARIABLE_NAME: "verbose",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: false,
    // 			DESCRIPTION: "This field specifies whether activity of this module will be logged to on the server's file system.",
    // 			ADVANCED_INPUT: true
    // 		}	
    // 	],
    // 	"OUTPUTS": [

    // 	],
    // 	DISPLAY_NAME: "Perform downsampling",
    // 	PRODUCT_LEVEL: "Standard",
    // 	DESCRIPTION: "This module is responsible for downsampling input data.",
    // 	KEYWORDS: [
    // 		"TRANSFORMATION"
    // 	]
    // },
    // "UPSAMPLE": {
    // 	"INPUTS": [
    // 		{
    // 			DISPLAY_NAME: "Sampling Rate",
    // 			VARIABLE_NAME: "old_sampling_rate",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 1,
    // 			DESCRIPTION: "This field specifies the sampling rate of the signal.",
    // 			ADVANCED_INPUT: false
    // 		},	
    // 		{
    // 			DISPLAY_NAME: "New Sampling Rate",
    // 			VARIABLE_NAME: "new_sampling_rate",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 60,
    // 			DESCRIPTION: "This field specifies the sampling rate to which the data will be upsampled.",
    // 			ADVANCED_INPUT: false
    // 		},						
    // 		{
    // 			DISPLAY_NAME: "Verbose",
    // 			VARIABLE_NAME: "verbose",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: false,
    // 			DESCRIPTION: "This field specifies whether activity of this module will be logged to on the server's file system.",
    // 			ADVANCED_INPUT: true
    // 		}	
    // 	],
    // 	"OUTPUTS": [

    // 	],
    // 	DISPLAY_NAME: "Perform upsampling",
    // 	PRODUCT_LEVEL: "Standard",
    // 	DESCRIPTION: "This module is responsible for upsampling input data.",
    // 	KEYWORDS: [
    // 		"TRANSFORMATION"
    // 	]
    // },		
    // "RFE": {
    // 	"INPUTS": [
    // 		{
    // 			DISPLAY_NAME: "Number of Features",
    // 			VARIABLE_NAME: "k",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 10,
    // 			DESCRIPTION: "This field specifies the number of top features (k) that will be reported from the total number of features in the data (n). The 'best' features will be objectively determined using a model.",
    // 			ADVANCED_INPUT: false
    // 		},
    // 		{
    // 			DISPLAY_NAME: "Seed",
    // 			VARIABLE_NAME: "seed",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: null,
    // 			DESCRIPTION: "This field specifies whether the random assignment of data into training and testing data should be seeded with a default value so that results are reproducible.",
    // 			ADVANCED_INPUT: true
    // 		},					
    // 		{
    // 			DISPLAY_NAME: "Verbose",
    // 			VARIABLE_NAME: "verbose",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: false,
    // 			DESCRIPTION: "This field specifies whether activity of this module will be logged to on the server's file system.",
    // 			ADVANCED_INPUT: true
    // 		}	
    // 	],
    // 	"OUTPUTS": [

    // 	],
    // 	DISPLAY_NAME: "Apply recursive feature elimination",
    // 	PRODUCT_LEVEL: "Standard",
    // 	DESCRIPTION: "This module is responsible for selecting the k-best features based on a model.",
    // 	KEYWORDS: [
    // 		"MACHINE LEARNING",
    // 		"FEATURE ENGINEERING"
    // 	]
    // },
    // "KBEST": {
    // 	"INPUTS": [
    // 		{
    // 			DISPLAY_NAME: "Number of Features",
    // 			VARIABLE_NAME: "k",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 10,
    // 			DESCRIPTION: "This field specifies the number of top features (k) that will be reported from the total number of features in the data (n). The 'best' features will be objectively determined using a scoring function.",
    // 			ADVANCED_INPUT: false
    // 		},
    // 		{
    // 		DISPLAY_NAME: "Score function",
    // 			VARIABLE_NAME: "score_function",
    // 			TYPE: "DROPDOWN_INPUT",
    // 			DEFAULT_VALUE: {
    // 				"chi": true,
    // 				"mse": false
    // 			},
    // 			DESCRIPTION: "This field specifies which score function will be used for determining the best k features.",
    // 			ADVANCED_INPUT: false
    // 		},
    // 		{
    // 			DISPLAY_NAME: "Seed",
    // 			VARIABLE_NAME: "seed",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: null,
    // 			DESCRIPTION: "This field specifies whether the random assignment of data into training and testing data should be seeded with a default value so that results are reproducible.",
    // 			ADVANCED_INPUT: true
    // 		},					
    // 		{
    // 			DISPLAY_NAME: "Verbose",
    // 			VARIABLE_NAME: "verbose",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: false,
    // 			DESCRIPTION: "This field specifies whether activity of this module will be logged to on the server's file system.",
    // 			ADVANCED_INPUT: true
    // 		}	
    // 	],
    // 	"OUTPUTS": [

    // 	],
    // 	DISPLAY_NAME: "Apply k-best feature search",
    // 	PRODUCT_LEVEL: "Standard",
    // 	DESCRIPTION: "This module is responsible for selecting the k-best features based on a score function.",
    // 	KEYWORDS: [
    // 		"MACHINE LEARNING",
    // 		"FEATURE ENGINEERING"
    // 	]
    // },
    // "TRAINTESTSPLIT": {
    // 	"INPUTS": [   
    // 		{
    // 			DISPLAY_NAME: "Test Proportion",
    // 			VARIABLE_NAME: "test_size",
    // 			TYPE: "NUMBER_INPUT",
    // 			DEFAULT_VALUE: 0.2, 
    // 			DESCRIPTION: "This field specifies the proportion of data that will be assigned to the test set. The remaining proportion of data will be assigned to the training set.",
    // 			ADVANCED_INPUT: false
    // 		},					
    // 		{
    // 			DISPLAY_NAME: "Seed",
    // 			VARIABLE_NAME: "seed",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: null,
    // 			DESCRIPTION: "This field specifies whether the random assignment of data into training and testing data should be seeded with a default value so that results are reproducible.",
    // 			ADVANCED_INPUT: true
    // 		},				
    // 		{
    // 			DISPLAY_NAME: "Verbose",
    // 			VARIABLE_NAME: "verbose",
    // 			TYPE: "CHECKBOX_INPUT",
    // 			DEFAULT_VALUE: false,
    // 			DESCRIPTION: "This field specifies whether activity of this module will be logged to on the server's file system.",
    // 			ADVANCED_INPUT: true
    // 		}	
    // 	],
    // 	"OUTPUTS": [

    // 	],
    // 	DISPLAY_NAME: "RUN TRAINTESTSPLIT",
    // 	PRODUCT_LEVEL: "Standard",
    // 	DESCRIPTION: "This module is responsible for separating data features and data targets for supervised learning into a training and testing set according to the test size proportion parameter.",
    // 	KEYWORDS: [
    // 		"MACHINE LEARNING",
    // 		"FEATURE ENGINEERING"
    // 	]
    // },
    AEEG: {
        DATA_INPUT_TYPES: ["time_series"],
        DATA_OUTPUT_TYPE: "time_series",
        INPUTS: [   				
            {
                DISPLAY_NAME: "Sampling Frequency (hz)",
                VARIABLE_NAME: "sampling_frequency_hz",
                TYPE: "NUMBER_INPUT",
                DEFAULT_VALUE: 256,
                DESCRIPTION: "The sampling rate of the data",
            },				
        ],
        DISPLAY_NAME: "AEEG",
        PRODUCT_LEVEL: "Standard",
        DESCRIPTION: "Returns a filtered signal between 2 and 20 Hz given a set of input eeg channels.",
        KEYWORDS: ["EEG", "AEEG"]
    },
    ENVELOPE: {
        DATA_INPUT_TYPES: ["time_series"],
        DATA_OUTPUT_TYPE: "time_series",
        DESCRIPTION: "Finds the envelope of a signal, which is the apparent signal if the peaks of the original signal were connected.",
        DISPLAY_NAME: "Envelope",
        INPUTS: [   				
            {
                DISPLAY_NAME: "Segment size",
                VARIABLE_NAME: "segment_size",
                TYPE: USER_INPUT_TYPES.DURATION_INPUT,
                DEFAULT_VALUE: {"seconds": 5},
                DESCRIPTION: "The time series is broken into segments of this duration.",
            },
            {
                DISPLAY_NAME: "Smoothing (samples)",
                VARIABLE_NAME: "num_smoothing_samples",
                TYPE: "NUMBER_INPUT",
                DEFAULT_VALUE: 15,
                DESCRIPTION: "The number of samples to smooth over. Lower = higher precision.",
            },
            {
                DISPLAY_NAME: "Target standard deviation",
                VARIABLE_NAME: "target_standard_deviation",
                TYPE: "NUMBER_INPUT",
                DEFAULT_VALUE: 2,
                DESCRIPTION: "The z-score or number of standard deviations away from the mean to detect the envelope. Should be positive for an upper envelope, and negative for a lower one 99.7% of normally distributed data is found within 3 standard deviations above and below the mean.",
            },			
        ],
        KEYWORDS: ["env", "envelope"],
        PRODUCT_LEVEL: "Standard",
    },
    FLAT_INTERVAL: {
        DATA_INPUT_TYPES: ["time_series"],
        DATA_OUTPUT_TYPE: "intervals",
        INPUTS: [   				
            {
                DISPLAY_NAME: "Resampling rate",
                VARIABLE_NAME: "resample",
                TYPE: USER_INPUT_TYPES.DURATION_INPUT,
                DEFAULT_VALUE: {"seconds": 5},
                DESCRIPTION: "(e.g. '1s', '2s', '3s') - the desired resample rate",
                ADVANCED_INPUT: true,
            },
            {
                DISPLAY_NAME: "Rolling",
                VARIABLE_NAME: "rolling",
                TYPE: "CHECKBOX_INPUT",
                DEFAULT_VALUE: false,
                DESCRIPTION: "Whether the resample should use the rolling median, or just resample with the mean.",
                ADVANCED_INPUT: true
            },
            {
                DISPLAY_NAME: "Cutoff Duration",
                VARIABLE_NAME: "cutoff_duration",
                TYPE: USER_INPUT_TYPES.DURATION_INPUT,
                DEFAULT_VALUE: {"seconds": 1},
                DESCRIPTION: "the minimum duration for a flat interval to be detected.",
            },				
        ],
        DISPLAY_NAME: "Flat Intervals",
        PRODUCT_LEVEL: "Standard",
        DESCRIPTION: "Calculate regions of a signal where it is exactly flat. Output a list of Tuples of timestamps",
        KEYWORDS: ["flat", "interval"]
    },
    GAP_INTERVAL: {
        DATA_INPUT_TYPES: ["time_series"],
        DATA_OUTPUT_TYPE: "intervals",
        INPUTS: [   				
            {
                DISPLAY_NAME: "Resampling rate",
                VARIABLE_NAME: "resample",
                TYPE: USER_INPUT_TYPES.DURATION_INPUT,
                DEFAULT_VALUE: {"seconds": 5},
                DESCRIPTION: "(e.g. '1s', '2s', '3s') - the desired resample rate",
                ADVANCED_INPUT: true,
            },
            {
                DISPLAY_NAME: "Rolling",
                VARIABLE_NAME: "rolling",
                TYPE: "CHECKBOX_INPUT",
                DEFAULT_VALUE: false,
                DESCRIPTION: "Whether the resample should use the rolling median, or just resample with the mean.",
                ADVANCED_INPUT: true
            },
            {
                DISPLAY_NAME: "Cutoff Duration",
                VARIABLE_NAME: "cutoff_duration",
                TYPE: USER_INPUT_TYPES.DURATION_INPUT,
                DEFAULT_VALUE: {"seconds": 1},
                DESCRIPTION: "the minimum duration for a flat interval to be detected.",
            },				
        ],
        DISPLAY_NAME: "Find Gap Intervals",
        PRODUCT_LEVEL: "Standard",
        DESCRIPTION: "Calculate regions of a signal where there is no data. Output those intervals.",
        KEYWORDS: ["gap", "interval"]
    },
    THRESHOLD_INTERVAL: {
        DATA_INPUT_TYPES: ["time_series"],
        DATA_OUTPUT_TYPE: "intervals",
        INPUTS: [   				
            {
                DISPLAY_NAME: "Magnitude",
                VARIABLE_NAME: "magnitude",
                TYPE: "NUMBER_INPUT",
                DEFAULT_VALUE: 0,
                DESCRIPTION: "Magnitude of the threshold.",
                ADVANCED_INPUT: true,
            },
            {
                DISPLAY_NAME: "Direction",
                VARIABLE_NAME: "direction",
                TYPE: "DROPDOWN_INPUT",
                DEFAULT_VALUE: "<",
                OPTIONS: ["<", "<=", ">=", ">"],
                DESCRIPTION: "The direction of the applied magnitude for the defined threshold.",
                ADVANCED_INPUT: true
            },
            {
                DISPLAY_NAME: "Minimum Interval Size",
                VARIABLE_NAME: "minimum_interval_size",
                TYPE: USER_INPUT_TYPES.DURATION_INPUT,
                DEFAULT_VALUE: {"seconds": 1},
                DESCRIPTION: "The minimum length interval that will be detected.",
            },
            {
                DISPLAY_NAME: "Minimum Gap Size",
                VARIABLE_NAME: "minimum_gap_size",
                TYPE: USER_INPUT_TYPES.DURATION_INPUT,
                DEFAULT_VALUE: {"seconds": 5},
                DESCRIPTION: "The minimum length gap that will be detected.",
            },	
            {
                DISPLAY_NAME: "Resampling rate",
                VARIABLE_NAME: "resample",
                TYPE: USER_INPUT_TYPES.DURATION_INPUT,
                DEFAULT_VALUE: {"seconds": 5},
                DESCRIPTION: "The resampling that will be applied on the data, if any.",
            },
            {
                DISPLAY_NAME: "Rolling",
                VARIABLE_NAME: "rolling",
                TYPE: "CHECKBOX_INPUT",
                DEFAULT_VALUE: false,
                DESCRIPTION: "Whether the values will be binned on a rolling basis or not.",
            },			
        ],
        DISPLAY_NAME: "Threshold Intervals",
        PRODUCT_LEVEL: "Standard",
        DESCRIPTION: "Calculate regions of a signal where there is no data. Output those intervals.",
        KEYWORDS: ["threshold", "interval"]
    },
    HEART_RATE_VARIABILITY: {
        DATA_INPUT_TYPES: ["time_series"],
        DATA_OUTPUT_TYPE: "dictionary",
        INPUTS: [   				
            {
                DISPLAY_NAME: "Sampling Frequency (hz)",
                VARIABLE_NAME: "sampling_frequency_hz",
                TYPE: "NUMBER_INPUT",
                DEFAULT_VALUE: 256,
                DESCRIPTION: "The sampling rate of the data",
            },				
        ],
        DISPLAY_NAME: "Heart Rate Variability",
        PRODUCT_LEVEL: "Standard",
        DESCRIPTION: "Returns heart rate variability information from an Electrocardiogram (ECG) signal.",
        KEYWORDS: ["ECG", "HRV", "heart", "rate", "variability"]
    },
    INTEGRAL: {
        DATA_INPUT_TYPES: ["time_series"],
        DATA_OUTPUT_TYPE: "value",
        INPUTS: [   				
            {
                DISPLAY_NAME: "Minimum Gap Size",
                VARIABLE_NAME: "minimum_gap_size",
                TYPE: USER_INPUT_TYPES.DURATION_INPUT,
                DEFAULT_VALUE: {"seconds": 1},
                DESCRIPTION: "The minimum size gap to exclude from the integral.",
            },				
        ],
        DISPLAY_NAME: "Area Under Curve",
        PRODUCT_LEVEL: "Standard",
        DESCRIPTION: "Calculates the area under the series, excluding gaps of a specified size.",
        KEYWORDS: ["area", "integral", "under", "curve"]
    },
    PEAK_SLOPE: {
        DATA_INPUT_TYPES: ["time_series"],
        DATA_OUTPUT_TYPE: "value",
        INPUTS: [],
        DISPLAY_NAME: "Peak Slope",
        PRODUCT_LEVEL: "Standard",
        DESCRIPTION: "Calculates the maximum gradient of the signal.",
        KEYWORDS: ["slope", "max", "gradient"]
    },
    QRS_TO_ABP_PEAK_DIFFERENCES: {
        DATA_INPUT_TYPES: ["time_series", "time_series"],
        DATA_OUTPUT_TYPE: "list_of_type_value",
        INPUTS: [
            {
                DISPLAY_NAME: "ECG Sampling Frequency (hz)",
                VARIABLE_NAME: "ecg_sampling_frequency_hz",
                TYPE: "NUMBER_INPUT",
                DEFAULT_VALUE: 128,
                DESCRIPTION: "The sampling frequency of the ECG signal.",
            },
        ],
        DISPLAY_NAME: "QRS to ABP Peak Differences",
        PRODUCT_LEVEL: "Standard",
        DESCRIPTION: "Find the differences between the peaks of ECG_II and ABP_na in milliseconds. Uses the ECG peaks and matches them to the nearest ABP peaks. Sampling frequency helps prevent some innacuracies in peak detection.",
        KEYWORDS: ["ecg", "qrs", "abp", "peak", "diff"]
    },
    RESPIRATORY_RATE_VARIABILITY: {
        DATA_INPUT_TYPES: ["time_series"],
        DATA_OUTPUT_TYPE: "dictionary",
        INPUTS: [   				
            {
                DISPLAY_NAME: "Sampling Frequency (hz)",
                VARIABLE_NAME: "sampling_frequency_hz",
                TYPE: "NUMBER_INPUT",
                DEFAULT_VALUE: 256,
                DESCRIPTION: "The sampling rate of the data",
            },				
        ],
        DISPLAY_NAME: "Get Heart Rate Variability Stats",
        PRODUCT_LEVEL: "Standard",
        DESCRIPTION: `Returns Respiratory Rate Variability Stats from a respiration signal.
            RRV_RMSSD		RRV_MeanBB
            RRV_SDBB		RRV_SDSD
            RRV_CVBB		RRV_CVSD
            RRV_MedianBB	RRV_MadBB
            RRV_MCVBB		RRV_VLF
            RRV_LF			RRV_HF
            RRV_LFHF		RRV_LFn
            RRV_HFn			RRV_SD1
            RRV_SD2			RRV_SD2SD1
            RRV_ApEn		RRV_SampEn`,
        KEYWORDS: ["RRV", "Resp", "rate", "variability"]
    },
    SPECTROGRAM: {
        DATA_INPUT_TYPES: ["time_series"],
        DATA_OUTPUT_TYPE: "coordinates",
        INPUTS: [   				
            {
                DISPLAY_NAME: "Sampling Frequency (hz)",
                VARIABLE_NAME: "sampling_frequency_hz",
                TYPE: "NUMBER_INPUT",
                DEFAULT_VALUE: 256,
                DESCRIPTION: "The sampling rate of the data",
            },
            {
                DISPLAY_NAME: "Segment Length",
                VARIABLE_NAME: "segment_length",
                TYPE: "NUMBER_INPUT",
                DEFAULT_VALUE: 256,
                DESCRIPTION: "The size of each Fourier transform",
            },			
        ],
        DISPLAY_NAME: "Spectrogram",
        PRODUCT_LEVEL: "Standard",
        DESCRIPTION: "Returns the intensity of a signal at different frequencies over time as (x, y, z) coordinates.",
        KEYWORDS: ["spectrogram", "spectrum"]
    },
    SHORT_TERM_AVERAGE_LONG_TERM_AVERAGE: {
        DATA_INPUT_TYPES: ["time_series"],
        DATA_OUTPUT_TYPE: "time_series",
        INPUTS: [   				
            {
                DISPLAY_NAME: "Short term window size (samples)",
                VARIABLE_NAME: "short_term_window_size",
                TYPE: "NUMBER_INPUT",
                DEFAULT_VALUE: 10,
                DESCRIPTION: "The number of data points to use for the short-term average.",
            },
            {
                DISPLAY_NAME: "Long term window size (samples)",
                VARIABLE_NAME: "long_term_window_size",
                TYPE: "NUMBER_INPUT",
                DEFAULT_VALUE: 100,
                DESCRIPTION: "The number of data points to use for the long-term average.",
            },			
        ],
        DISPLAY_NAME: "Short Term Avg Over Long Term Avg",
        PRODUCT_LEVEL: "Standard",
        DESCRIPTION: "Calculate Short-Term-Average / Long-Term-Average for a given input signal.",
        KEYWORDS: ["short", "long", "term", "average"]
    },
    STANDARD_DEVIATION: {
        DATA_INPUT_TYPES: ["time_series"],
        DATA_OUTPUT_TYPE: "value",
        INPUTS: [   				
            {
                DISPLAY_NAME: "Resample",
                VARIABLE_NAME: "resample",
                TYPE: USER_INPUT_TYPES.DURATION_INPUT,
                DEFAULT_VALUE: {"seconds": 5},
                DESCRIPTION: "The desired resampling rate. If the resampling rate is larger than adjacent data, the average of that data will be used to create the new data point.",
            },
            {
                DISPLAY_NAME: "Delta degrees of freedom",
                VARIABLE_NAME: "delta_degrees_of_freedom",
                TYPE: "NUMBER_INPUT",
                DEFAULT_VALUE: 1,
                DESCRIPTION: "The number of degrees of freedom away from N. If the degrees of freedom are N - 1, 1 is the delta degrees of freedom. DDOF of 1 indicates a 'population' standard deviation.",
            },			
        ],
        DISPLAY_NAME: "Standard Deviation",
        PRODUCT_LEVEL: "Standard",
        DESCRIPTION: "Find the standard deviation of a time series.",
        KEYWORDS: ["std", "dev", "stdev", "standard", "deviation"]
    },
    SUPPRESSION_RATIO: {
        DATA_INPUT_TYPES: ["time_series"],
        DATA_OUTPUT_TYPE: "time_series",
        INPUTS: [   				
            {
                DISPLAY_NAME: "Threshold (volts)",
                VARIABLE_NAME: "threshold_volts",
                TYPE: "NUMBER_INPUT",
                DEFAULT_VALUE: 0,
                DESCRIPTION: "The upper bound which decides whether the signal is suppressed or not.",
            },	
        ],
        DISPLAY_NAME: "Suppression Ratio",
        PRODUCT_LEVEL: "Standard",
        DESCRIPTION: `Determines the percentage of time that a signal is at or below a user-specified threshold.
        The suppression ratio is the number of 0.5s chunks that do not exceed the threshold within a 10s epoch.
        These percentages are then combined into a 60s moving average.
        This algorithm is consistent with Persyst 12.`,
        KEYWORDS: ["suppression", "ratio", "EEG", "Persyst"]
    },
    WELCH: {
        DATA_INPUT_TYPES: ["time_series"],
        DATA_OUTPUT_TYPE: "coordinates",
        INPUTS: [   				
            {
                DISPLAY_NAME: "Sampling Frequency (hz)",
                VARIABLE_NAME: "sampling_frequency_hz",
                TYPE: "NUMBER_INPUT",
                DEFAULT_VALUE: 256,
                DESCRIPTION: "The sample rate of the signal, used to calculate the frequency axis.",
            },
            {
                DISPLAY_NAME: "Segment Length (samples)",
                VARIABLE_NAME: "segment_length",
                TYPE: "NUMBER_INPUT",
                DEFAULT_VALUE: 256,
                DESCRIPTION: "The size of each segment when computing the Welch transform.",
            },
            {
                DISPLAY_NAME: "Window",
                VARIABLE_NAME: "window",
                TYPE: "DROPDOWN_INPUT",
                OPTIONS: ["hann", "boxcar", "triang", "hamming"],
                DEFAULT_VALUE: "hann",
                DESCRIPTION: "the clamping method that will be used on the ends of each segment to prevent artifacts in the resulting power spectrum.",
            },	
        ],
        DISPLAY_NAME: "Welch",
        PRODUCT_LEVEL: "Standard",
        DESCRIPTION: `Performs a Welch transform on a signal. Returns the power spectrum, or the (x, y) coordinates that show the relationship between frequency and intensity.`,
        KEYWORDS: ["welch", "fft", "hann"]
    },
    // Start putting these in their own files, and make this list alphabetical.
    ARGMIN,
    PEARSON,
    POLYNOMIAL_FIT,
    POLYNOMIAL_MINIMIZE,
    RMSD,
    SUBTRACT_TIME_SERIES,
    WEIGHTED_MEAN
}