import { createContext, useContext, useState, useEffect } from "react";
import { useEndpointProvider } from "./EndpointProvider";
import { LINKS } from '../Constants/BackendLinks'
import { useStudiesProvider } from "./StudiesProvider";
import { useAuthProvider } from "./AuthProvider";
import { UpdateDispatcher } from "./UpdateDispatcher";


const PatientGroupsContext = createContext()

export const patientGroupsUpdates = {
    PATIENT_GROUPS_FILTERS: "patient_groups_filters",
}

export const PatientGroupsProvider = ({ children }) => { 

    const [patientGroups, setPatientGroups] = useState([])
    const [filters, setFilters] = useState({})

    console.log("PROVIDER RENDER: PATIENT GROUPS", {patientGroups, filters})

    const endpointProvider = useEndpointProvider()
    const studiesProvider = useStudiesProvider()
    const authProvider = useAuthProvider()

    async function update(...updates) {

        const initState = {
            [patientGroupsUpdates.PATIENT_GROUPS_FILTERS]: {data: patientGroups, filters}
        }

        const dispatcher = new UpdateDispatcher(updates, initState, patientGroupsUpdates)

        dispatcher.dispatch(patientGroupsUpdates.PATIENT_GROUPS_FILTERS,
            () => endpointProvider.post(LINKS.DATA.PROFILING.GET_PATIENT_GROUPS, {study_ids: [studiesProvider.selectedStudyId]}),
            data => {
                setPatientGroups(data?.data)
                setFilters(data?.filters)
            }
        )
    }

    // Make sure we don't persist any data between logins.
    useEffect(() => {
        if (!authProvider.userIsLoggedIn && (patientGroups.length > 0 || filters.length > 0)) {
            setPatientGroups([])
            setFilters({})
        }
    }, [authProvider, filters.length, patientGroups.length])

    return (
        <PatientGroupsContext.Provider value={{ patientGroups, filters, update }}>
            {children}
        </PatientGroupsContext.Provider>
    )
}

export const usePatientGroupsProvider = () => useContext(PatientGroupsContext)