import { useEffect, useState } from "react"
import ModalHeader from "../../../../../Components/ModalHeader/ModalHeader";
import { ModalFooter } from "../../../../../Constants/StyledComponents";
import { useUploadProcessProvider } from "../../../../../Providers/UploadProcessProvider";
import { UploadStepHeader } from "../../../../../Constants/StyledComponents";
import { UploadFilesModal } from "../../../../../Constants/StyledComponents";
import FormConstructor from "../../../../../Components/Form/FormConstructor"
import Accordion from "../../../../../Components/Accordion/Accordion"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm } from "react-hook-form"
import * as yup from "yup"
import { Scrollbar } from "../../../../../Constants/StyledComponents";
import { MobergButton } from "../../../../../Components/MobergButton/MobergButton";
import { MobergTheme } from "../../../../../Components/MobergThemes/MobergColors";
import { MobergButtonVariant } from "../../../../../Components/MobergButton/MobergButton";
import { MobergButtonShape } from "../../../../../Components/MobergButton/MobergButton";
import { formJson, admissionDischargeQuestions, GCSQuestions, bestGCSQuestions, worstGCSQuestions } from "../../../../../Constants/FormQuestions";

const UploadFormEntry = ({
    setToPrevWindow,
    setToNextWindow,
}) => {
    const uploadProcessProvider = useUploadProcessProvider();
    let uploadHeaderText = `Form Entry for Patient ${uploadProcessProvider.patientID} at ${uploadProcessProvider.selectedSiteName}`

    let handleOverflowUploadHeaderText = uploadHeaderText.length > 75 ? `Form Entry for Patient ${uploadProcessProvider.patientID.length > 15 ? uploadProcessProvider.patientID.slice(0, 15) + "..." : uploadProcessProvider.patientID} at ${uploadProcessProvider.selectedSiteName.length > 40 ? uploadProcessProvider.selectedSiteName.slice(0, 40) + "..." : uploadProcessProvider.selectedSiteName}` : uploadHeaderText

    const handleBack = () => {
        setToPrevWindow(true);
    }

    const [errorAccordion, setErrorAccordion] = useState([])
    const [errorQuestions, setErrorQuestions] = useState([])

    useEffect(() => {
        const updatedErrorAccordion = [];

        for (const question of errorQuestions) {
            updatedErrorAccordion.push(question.groupLabel);
        }

        setErrorAccordion(updatedErrorAccordion);
    }, [errorQuestions]);

    function highlightError(condition, question) {
        if (condition) {
            setErrorQuestions((prevErrorQuestions) => {
                const updateErrorQuestions = [...prevErrorQuestions, question];
                return updateErrorQuestions;
            });

            return false;
        } else {
            setErrorQuestions((prevErrorQuestions) => {
                const updateErrorQuestions = prevErrorQuestions.filter(q => q !== question);
                return updateErrorQuestions;
            })
            return true;
        };
    }

    const generateValidationSchema = (questions) => {
        const schemaObject = {};


        for (const question of questions) {
            let questionSchema;

            switch (question.type) {
                case 'number':
                    const isNotNumber = (value) => {
                        try {
                            parseInt(value)
                            return false
                        }
                        catch {
                            return true
                        }
                    }
                    const smallerThan0 = (value) => {
                        return (value < 0)
                    }
                    questionSchema = yup.mixed().test({
                        test: (value) => highlightError(isNotNumber(value) && (value !== question.default), question),
                        message: "Value must be number"
                    }).test({
                        test: (value) => highlightError(smallerThan0(value) && (value !== question.default), question),
                        message: "Value must be positive"
                    })
                    break;
                case 'dropdown':
                    questionSchema = yup.mixed()
                    break;
                case 'datepicker':
                    const isNotDate = (value) => {
                        return (isNaN(new Date(value)))
                    }
                    questionSchema = yup.mixed().nullable()
                        .test({
                            test: (value) => highlightError(isNotDate(value) && (value !== question.default), question),
                            message: "Invalid Date"
                        })
                    break;
                case 'checkbox':
                    questionSchema = yup.mixed()
                    break;
                case 'text':
                    questionSchema = yup.string()
                    break;
                default:
                    questionSchema = yup.mixed()
            }

            if (question.required) {
                questionSchema = questionSchema.test({
                    test: value => highlightError(value === question.default, question),
                    message: `${question.prompt} is required.`
                });
            }

            schemaObject[question.questionType] = questionSchema;
        }
        return yup.object().shape(schemaObject);
    };

    const generateValues = (questions) => {
        const defaultValues = {}
        for (const question of questions) {
            if (question.type === 'datepicker') {
                defaultValues[question.questionType] = uploadProcessProvider.submittedForm[question.questionType] ? uploadProcessProvider.submittedForm[question.questionType] : question.default
            } else {
                defaultValues[question.questionType] = uploadProcessProvider.submittedForm[question.questionType] ? uploadProcessProvider.submittedForm[question.questionType].value : question.default
            }
        }

        return defaultValues
    }

    const { control, handleSubmit } = useForm({
        resolver: async (data, context, options) => {
            console.log('validation result', await yupResolver(generateValidationSchema(formJson.Questions.flat(Infinity)))(data, context, options))
            return yupResolver(generateValidationSchema(formJson.Questions.flat(Infinity)))(data, context, options)
        },
        values: generateValues(formJson.Questions.flat(Infinity)),
        resetOptions: {
            keepDirtyValues: true,
            keepErrors: true,
        }
    })

    const onSubmit = (data) => {
        let formWithIdentifiers = data;
        Object.entries(data).forEach(([key, value]) => {
            const question = Object.values(formJson.Questions).reduce((accumulator, currentQuestionObj) => {
                const matchingQuestion = Object.values(currentQuestionObj).flat().find(question => question.questionType === key);

                if (matchingQuestion) {
                    return matchingQuestion;
                }

                return accumulator;
            }, null);
            if (question) {
                formWithIdentifiers[key] = {
                    identifier: question.identifier.NINDS ? { NINDS: question.identifier.NINDS } : {},
                    value: value,
                };
            }
        });
        formWithIdentifiers.dateFilled = new Date();
        formWithIdentifiers.formName = formJson.formName;
        formWithIdentifiers.formID = new Date().getTime();
        setErrorQuestions([])
        setErrorAccordion([])
        uploadProcessProvider.setSubmittedForm(formWithIdentifiers)
        setToNextWindow(true);
        uploadProcessProvider.setChangeUploadData(!uploadProcessProvider.changeUploadData)
    };

    const [enableNextButton, setEnableNextButton] = useState(false)

    const accordionData = {
        'Admission & Discharge': {
            'label': "Admission & Discharge",
            'innerContent': <FormConstructor control={control} questions={admissionDischargeQuestions} />,
        },
        'GCS on admission': {
            'label': "GCS on admission",
            'innerContent': <FormConstructor control={control} questions={GCSQuestions} />
        },
        'Best GCS during admission': {
            'label': "Best GCS during admission",
            'innerContent': <FormConstructor control={control} questions={bestGCSQuestions} />
        },
        'Worst GCS during admission': {
            'label': "Worst GCS during admission",
            'innerContent': <FormConstructor control={control} questions={worstGCSQuestions} />
        },
    }

    return (
        <div>
            <UploadFilesModal>
                <ModalHeader headerText={handleOverflowUploadHeaderText} resetCache={uploadProcessProvider.resetCache} />
                <Scrollbar style={{ paddingTop: "15px", paddingLeft: "47px", paddingRight: "47px", paddingBottom: "0px", height: "600px", overflowY: "scroll", overflowX: "hidden", flex: 1, maxHeight: '650px' }}>
                    <div style={{ marginBottom: '24px' }} >
                        <UploadStepHeader>3. Patient Information</UploadStepHeader>
                    </div>
                    <div style={{ display: 'flex' }}>
                        <h1 style={{ marginBottom: "10px", color: "#207DEA" }}>Demographics</h1>
                    </div>

                    <Accordion data={accordionData} itemError={errorAccordion} />

                </Scrollbar>

                <hr style={{ border: "1px solid #B3B3B3", width: "100%", margin: '0px auto' }} />
                <ModalFooter>
                    <MobergButton
                        theme={MobergTheme.BLUE}
                        variant={MobergButtonVariant.OUTLINE}
                        shape={MobergButtonShape.WIDE}
                        onClick={handleBack}
                    >
                        Back
                    </MobergButton>
                    {errorQuestions.length > 0 && <h1 style={{ color: "#E54E58" }}>
                        Please complete all required fields.
                    </h1>}
                    <MobergButton
                        theme={MobergTheme.BLUE}
                        variant={MobergButtonVariant.FILLED}
                        shape={MobergButtonShape.WIDE}
                        onClick={handleSubmit(onSubmit)}
                    >
                        Next
                    </MobergButton>
                </ModalFooter>
            </UploadFilesModal>
        </div>
    )
}

export default UploadFormEntry;