export const SD_LOW_FILTERS = { "0.005Hz": 0.005, Off: 0 }

export const SD_HIGH_FILTERS = { "0.5Hz": 0.5, Off: 0 }

export const SD_NOTCH_FILTERS = {"60Hz": 60.0, "50Hz": 50.0, Off: 0 }

export const SD_SENSITIVITY = {
	"1000 uV": 1000 * 1e-6,
	"700 uV": 700  * 1e-6,
	"500 uV": 500 * 1e-6,
	"440 uV": 440 * 1e-6,
	"300 uV": 300 * 1e-6,
	"200 uV": 200 * 1e-6,
	"150 uV": 150 * 1e-6,
	"100 uV": 100 * 1e-6,
	"70 uV": 70 * 1e-6,
	"50 uV": 50 * 1e-6,
	"30 uV": 30 * 1e-6,
	"20 uV": 20 * 1e-6,
	"15 uV": 15 * 1e-6,
	"10 uV": 10 * 1e-6,
	"7 uV": 7 * 1e-6,
	"5 uV": 5 * 1e-6,
	"3 uV": 3 * 1e-6,
	"2 uV": 2 * 1e-6,
	"1 uV": 1 * 1e-6,
}