import { RefObject, useCallback, useRef } from "react"
import { useOnResize } from "../../../../../Hooks/useOnResize"
import { debounce } from "lodash"
import { Dimensions } from "../../../../../Providers/DimensionsProvider"

type useResizeObserverProps = {
	ref: RefObject<HTMLDivElement>
	onResize?: (dimensions: Dimensions) => void
	afterResize?: () => void
}

export const useResizeObserver = ({ ref, onResize, afterResize }: useResizeObserverProps) => {
	const previousResizeDimensions = useRef({ height: 0, width: 0 })
	const dimensions = useRef({ height: 0, width: 0 })

	const checkAfterResize = debounce(() => {
		if (previousResizeDimensions.current.height === dimensions.current.height && previousResizeDimensions.current.width === dimensions.current.width) {
			return
		}

		if (afterResize) {
			afterResize()
			previousResizeDimensions.current.height = dimensions.current.height
			previousResizeDimensions.current.width = dimensions.current.width
		}
	}, 50)

	const callback = useCallback(
		(entry: ResizeObserverEntry) => {
			if (dimensions.current.height === entry.contentRect.height && dimensions.current.width === entry.contentRect.width) {
				return
			}

			dimensions.current.height = entry.contentRect.height
			dimensions.current.width = entry.contentRect.width

			if (dimensions.current.height > 0 && dimensions.current.width > 0) {

				if (onResize) {
					onResize(dimensions.current)
				}

				if (afterResize) {
					checkAfterResize()
				}
			}
		},
		[afterResize, checkAfterResize, onResize]
	)

	useOnResize(ref, callback)

	return dimensions.current
}
