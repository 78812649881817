import { Selection, EnterElement, scaleBand } from "d3"
import { D3TracesWrapper } from "./D3TracesWrapper"
import { ModalityGraphGroupReactCallbacks, } from "../../../../Types/ReactCallbacks"
import { TimeSeriesPageManager } from "../../../../Data/TimeSeriesPageManager"
import { ModalityGraphConfig, ModalityGraphConfigWithDerivedTraces } from "../../../../Types/Graph"
import { D3OneToOneRenderable } from "../../../D3/D3OneToOneRenderable"
import { ModalityPage } from "../../../../Data/ModalityPage"
import { HeatmapTraceConfigJSON, getHeatmapTraceId } from "../../../../Types/Trace"
import { D3HeatmapGraphLegend } from "./D3HeatmapGraphLegend"

export class D3ModalityHeatmapGraph extends D3OneToOneRenderable<SVGGElement, SVGGElement, ModalityGraphConfig, ModalityGraphGroupReactCallbacks> {
	private yScale = scaleBand()
	private pageManager: TimeSeriesPageManager<ModalityPage>
	private graph: ModalityGraphConfigWithDerivedTraces 

	// Children
	private tracesWrapper?: D3TracesWrapper
	private legend?: D3HeatmapGraphLegend

	constructor(root: SVGGElement, config: ModalityGraphConfig, pageManager: TimeSeriesPageManager<ModalityPage>, reactCallbacks: ModalityGraphGroupReactCallbacks) {
		super(root, config, "d3-modality-heatmap-graph", reactCallbacks)
		this.pageManager = pageManager
		
		this.graph = {} as ModalityGraphConfigWithDerivedTraces

		this.updateDerivedState()
		this.render()
	}

	protected updateDerivedState(): void {
		this.yScale
			.domain([...new Set(this.config.traces.map(trace => getHeatmapTraceId(trace as HeatmapTraceConfigJSON)))])
			.range([0, this.config.height])

		// D3 does not allow us to use absolute padding. It is percentage based
		if (this.config.traces.length > 3) {
			this.yScale.paddingInner(0.3)
		} else if (this.config.traces.length > 1) {
			this.yScale.paddingInner(0.2)
		} else {
			this.yScale.paddingInner(0)
		}

		this.graph = this.getDerivedGraph() as any
		this.updateChildren()
	}

	public renderPage = (page: ModalityPage) => {
		this.tracesWrapper?.renderPage(page)
	}

	public viewTimesChanged = () => {
		this.tracesWrapper?.render()
	}

	public rescale = () => {
		this.tracesWrapper?.rescale()
	}

	public takeSnapshot = () => {
		this.tracesWrapper?.takeSnapshot()
	}

	public clearSnapshot = () => {
		this.tracesWrapper?.clearSnapshot()
	}

	public isRescaling = (): boolean => {
		return this.tracesWrapper?.isRescaling() ?? false
	}

	public getDerivedGraph = () => ({
		...this.config, 
		traces: this.config.traces.map(traceConfig => ({ 
			...traceConfig, 
			id: JSON.stringify(traceConfig), 
			graphId: this.config.id, 
			xScale: this.config.xScale, 
			yScale: this.yScale 
		}))}
	)

    public autoScale = () => {} // required

	protected enter = (newGraph: Selection<EnterElement, any, any, any>): Selection<SVGGElement, any, any, any> => {
		const graphGroup = newGraph.append("g").attr("class", this.className).attr("transform", `translate(0, ${this.config.offset})`)
		graphGroup.each(this.createChildren)
		return graphGroup
	}

	protected update = (updatedGraphs: Selection<any, any, any, any>): Selection<any, any, any, any> => {
		updatedGraphs.attr("transform", `translate(0, ${this.config.offset})`)
		this.renderChildren()
		return updatedGraphs
	}

	protected createChildren = (config: ModalityGraphConfig, index: number, nodes: ArrayLike<SVGGElement>) => {
		const root = nodes[index]
		this.tracesWrapper = new D3TracesWrapper(root, { traces: this.graph.traces }, this.pageManager, this.reactCallbacks)
		this.legend = new D3HeatmapGraphLegend(root, { traces: this.config.traces as HeatmapTraceConfigJSON[], yScale: this.yScale, xOffset: this.config.width }, "d3-heatmap-graph-legend", this.reactCallbacks)
	}

	protected updateChildren = () => {
		this.tracesWrapper?.updateConfig({ traces: this.graph.traces })
		this.legend?.updateConfig({ traces: this.config.traces as HeatmapTraceConfigJSON[], yScale: this.yScale, xOffset: this.config.width })
	}

	private renderChildren = () => {
		this.tracesWrapper?.render()
		this.legend?.render()
	}
}
