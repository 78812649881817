import React, { useRef } from "react"
import { ScatterPlotConfig, ScatterPlotJSON } from "../../../../Types/ScatterPlot"
import { DimensionsProvider } from "../../../../../../../../Providers/DimensionsProvider"
import { useRecoilValue } from "recoil"
import { ScatterPlotConfigsAtom } from "../../../../Atoms/ScatterPlot"
import { selectedLayoutIdAtom } from "../../../../Atoms/Layout"
import { viewScaleRegistry } from "../../../../Data/ViewScaleRegistry"
import { VisualizationComponent } from "../../../../VisualizationComponentFactory"
import { currentPatientFileInfoAtom } from "../../../../Atoms/PatientFile"
import { pageManagerRegistry } from "../../../../Data/PageManagerRegistry"
import { D3ScatterPlot } from "../D3/D3ScatterPlot"
import { useD3KeyboardShortcuts } from "../../../../Hooks/useD3KeyboardShortcuts"
import { hotkeyActions } from "../../../../Types/KeyboardShortcut"
import { ScatterPlotWindowSettings } from "./ScatterPlotWindowSettings"
import { fileScaleRegistry } from "../../../../Data/FileScaleRegistry"
import { useD3CheckReloadData } from "../../../../Hooks/useD3CheckReloadData"
import { ManualDownsampledPageManager } from "../../../../Data/ManualDownsampledPageManager"
import { useD3Controller } from "../../../../Hooks/useD3Controller"
import { useSocketProvider } from "../../../../../../../../Providers/SocketProvider"

type ScatterPlotProps = {
    windowId: string,
    json: ScatterPlotJSON
}

export const ScatterPlot = (props: ScatterPlotProps) => {

    return (
        <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
            <div style={{ flex: 0 }}>
                <ScatterPlotWindowSettings windowId={props.windowId} />
            </div>
            <div style={{ flex: 1 }}>
                <DimensionsProvider>
                    <ScatterPlotVisualization {...props} />
                </DimensionsProvider>
            </div>
        </div>
    )
}

export const ScatterPlotVisualization = (props: ScatterPlotProps) => {
    const containerRef = useRef<HTMLDivElement>(null)
    const { getDataQuerySocket } = useSocketProvider()

    const { fileStartDate, fileEndDate } = useRecoilValue(currentPatientFileInfoAtom)

    const selectedLayoutId = useRecoilValue(selectedLayoutIdAtom)
    const visualization_id = { layoutId: selectedLayoutId as string, windowId: props.windowId }

    const atom = ScatterPlotConfigsAtom(visualization_id)
    const config = useRecoilValue(atom)

    const viewScale = viewScaleRegistry.get(visualization_id, VisualizationComponent.SCATTER_PLOT, { fileStartDate, fileEndDate, viewDuration: props.json.viewDuration })

    const [viewStart, viewEnd] = viewScale.domain()
    const viewDuration = viewEnd.getTime() - viewStart.getTime()

    const d3Controller = useD3Controller<D3ScatterPlot, ScatterPlotConfig, ScatterPlotJSON, ManualDownsampledPageManager>({
        atom,
        pageManager: pageManagerRegistry.get(visualization_id, VisualizationComponent.SCATTER_PLOT, new ManualDownsampledPageManager(), getDataQuerySocket),
        nodeRef: containerRef,
        initProps: props.json,
        props: {
            id: props.windowId,
            autoScale: config.autoScale,
            viewScale: viewScaleRegistry.get(visualization_id, VisualizationComponent.SCATTER_PLOT, { fileStartDate, fileEndDate, viewDuration: props.json.viewDuration ?? 60 * 1000 }),
            fileScale: fileScaleRegistry.get(visualization_id, VisualizationComponent.SCATTER_PLOT, fileStartDate, fileEndDate)
        },
        d3ControllerConstructor(node, config, pageManager, callbacks) {
            return new D3ScatterPlot(node, config, pageManager, callbacks)
        }
    })

    useD3KeyboardShortcuts({
        d3Controller,
        windowId: props.windowId,
        shortcuts: [hotkeyActions.NEXT_PAGE, hotkeyActions.PREVIOUS_PAGE, hotkeyActions.PLAY_PAUSE]
    })

    useD3CheckReloadData({
        d3Controller,
        clearDataIfChanges: {
            viewDuration,
            resamplingPeriodSeconds: config.resamplingPeriodSeconds,
            modalities: [config.xAxisConfig.modality, config.yAxisConfig.modality],
        }
    })

    return (
        <div ref={containerRef} style={{ height: "100%" }} />
    )
}