import React from 'react';
import { Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Scatter, ComposedChart } from 'recharts';
import Label from '../Label/Label'


function ScatterPlot ({subcomponentKey}) {

    let modalityx = 'ABP'
    let modalityy = 'ICP'

    let modalityx_label = 'Arterial Blood Pressure'
    let modalityy_label = 'Intracranial Pressure'

    let coeffs = [0.2034, -4.540]

    let data = []

    for (let i=50; i<=120; i++) {
        data.push({
            [modalityx]: i,
            [modalityy]: 4 * (Math.random() - 0.5) + i * coeffs[0] + coeffs[1],
            'Fitted Curve': i * coeffs[0] + coeffs[1]
        })
    }

    // return (<div style={{backgroundColor: "white", padding: 40, justifyContent: 'center', alignItems: 'center', textAlign: 'center', display: 'flex'}}>
    //   {/* <ResponsiveContainer width={'99%'} height={"100%"}> */}
    //     <div>
    //         <ComposedChart 
    //             width={480}
    //             height={480}
    //             data={data}
    //             margin={{
    //                 top: 20,
    //                 right: 100,
    //                 left: 100,
    //                 bottom: 50,
    //             }}
    //         >
    //             <CartesianGrid strokeDasharray="3 3" />
                
    //             <XAxis label={{ value: modalityx_label, angle: 0, position: 'bottom', offset: 30 }} type="number" dataKey={modalityx} name="modalityx" unit="mmHg" domain={[50, 120]} ticks={[40, 60, 80, 100, 120]} />
    //             <YAxis label={{ value: modalityy_label, angle: -90, position: 'left', offset: 60, style: { textAnchor: 'middle' }}} type="number" dataKey={modalityy} name="modalityy" unit="mmHg" domain={[4, 20]} ticks={[0, 5, 10, 15, 20, 25]} />

    //             <Tooltip labelFormatter={value => {
    //                 return `${modalityx} : ${value}`;
    //             }}  />
    //             <Legend payload={[{ value: `${modalityx}-${modalityy}`, type: 'scatter', id: 'modalityx-modalityy'}, { value: 'Fitted Curve', type: 'line', id: 'Fitted Curve', color: '#8884d8' }]}  />

    //             <Scatter type="monotone" dataKey={modalityy} stroke="#82ca9d" />
    //             <Line type="dashed" dataKey="Fitted Curve" stroke='#8884d8' dot={false} />
    //         </ComposedChart>
    //     </div>
    //     {/* </ResponsiveContainer> */}
    //     <div>
    //         <Label size={3} text={"R " + 0.6802}/>
    //         <Label size={3} text={"Slope " + 0.2034}/>
    //         <Label size={3} text={"Intercept " + -4.540}/>
            
    //     </div>
    // </div>
    // )

    return (
        <div id={subcomponentKey} style={{borderBottom: "1px solid #000", fontSize: "12", marginTop: "0.1in", width: "650px"}}>
            <p>Scatter</p>
            <div style={{ marginBottom: "0.15in"}}>
                <div>
                    <div style={{display: "inline-block"}}>
                        <ComposedChart 
                        width={480}
                        height={480}
                        data={data}
                        margin={{
                            top: 20,
                            right: 100,
                            // right: 20,
                            left: 100,
                            // left: 40,
                            bottom: 50,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        
                        <XAxis label={{ value: modalityx_label, angle: 0, position: 'bottom', offset: 30 }} type="number" dataKey={modalityx} name="modalityx" unit="mmHg" domain={[50, 120]} ticks={[40, 60, 80, 100, 120]} />
                        <YAxis label={{ value: modalityy_label, angle: -90, position: 'left', offset: 60, style: { textAnchor: 'middle' }}} type="number" dataKey={modalityy} name="modalityy" unit="mmHg" domain={[4, 20]} ticks={[0, 5, 10, 15, 20, 25]} />

                        <Tooltip labelFormatter={value => {
                            return `${modalityx} : ${value}`;
                        }}  />
                        <Legend payload={[{ value: `${modalityx}-${modalityy}`, type: 'scatter', id: 'modalityx-modalityy'}, { value: 'Fitted Curve', type: 'line', id: 'Fitted Curve', color: '#8884d8' }]}  />

                        <Scatter type="monotone" dataKey={modalityy} stroke="#82ca9d" />
                        <Line type="dashed" dataKey="Fitted Curve" stroke='#8884d8' dot={false} />
                        </ComposedChart>
                    </div>
                    <div style={{display: "inline-block"}}>
                        <Label size={5} text={"R " + 0.6802}/>
                        <Label size={5} text={"Slope " + 0.2034}/>
                        <Label size={5} text={"Intercept " + -4.540}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ScatterPlot

