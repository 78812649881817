import React from "react"
import { MdClose } from "react-icons/md"
import { MobergButton, MobergButtonShape, MobergButtonVariant } from "../../../../../../Components/MobergButton/MobergButton"
import { MobergTheme } from "../../../../../../Components/MobergThemes/MobergColors"
import { MobergIconSize } from "../../../../../../Components/MobergIcon/MobergIcon"

type ModalTitleAndCloseButtonProps = {
	title: string
	closeFunction: () => void
}

export const ModalTitleAndCloseButton = (props: ModalTitleAndCloseButtonProps) => {

	return (
		<div style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: "5px" }}>
			<div style={{width: "40px"}} />
			
			<h3 style={{ flex: 1, margin: 0, fontFamily: "Montserrat", fontSize: "20px", fontStyle: "normal", color: "#293241", padding: "0 20px" }}> {props.title} </h3>

			<MobergButton 
				theme={MobergTheme.BLACK} 
				variant={MobergButtonVariant.CLEAR} 
				shape={MobergButtonShape.SQUARE}
				onClick={props.closeFunction}>
				<MdClose size={MobergIconSize.LARGE} />
			</MobergButton>
		</div>
	)
}
