import styled from "styled-components";

export const ConfirmationModalContainer = styled.div`
    width: 440px;
    height: 415px;
    background: #FFFFFF;
    border-radius: 8px;
    padding: 30px;
`; // the container of the confirmation modal

export const ConfirmationModalContent = styled.div`
    width: 300px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-bottom: 8px;
    p {
        font-family: 'Source Sans Pro';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 120%;
        color: #293241;
        margin-bottom: 0px;
    } // plain text for the confirmation modal content

    h1 {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 110%;
        color: #000000;
        margin-bottom: 20px;
    } // header text for the confirmation modal content
`;