import { useState, useContext, useEffect} from 'react';
import { TableContext } from './TableContext'
import styled from "styled-components";
import * as IoIcons from 'react-icons/io5';

// PaginationComponent
export function Pagination () {
    const {
        pageIndex,
        totalPages,
        setPageIndex
    } = useContext(TableContext)

    const hasPrev = pageIndex > 1
    const hasNext = pageIndex < totalPages

    const [inputValue, setInputValue] = useState(pageIndex)
    const [isEditing, setIsEditing] = useState(false)

    useEffect(() => {
        // update the input value when currentPageNum get changed by somthing outside pagination
        // Example usecase: the user is using search bar to query data from current table.
        // Initially the page number is 2, but as the user add more filter requirements, the number
        // of input rows become smaller, and less than rowsPerpage. Then currentPageNum become 1.
        // But the inputValue is still 2, so we need to change it to 1.
        if ((!isEditing) && (inputValue !== pageIndex)) { setInputValue(pageIndex) }
    }, [isEditing, inputValue, pageIndex])

    useEffect(() => {
        // handle that if pageIndex larger than totalPages, set pageIndex to totalPages
        if (pageIndex > totalPages) {
            setPageIndex(totalPages)
        }
    }, [pageIndex, setPageIndex, totalPages])

    const goToPage = (newPageIndex) => {
        // change the page number. After we call setCurrentPageNum in PaginationComponent,
        // currentPageNum will be updated. And it will trigger the corresponding PaginationController
        // to call clearSelection(), onChangePage(currentPageNum) and setDisplayedPageIndex(currentPageNum)
        setPageIndex(newPageIndex)
        setInputValue(newPageIndex)
    }

    const keydownListener = (event) => {
        // get triggered when the user focus the page number input field and press any key
        event.stopPropagation()
        if (event.key === "Enter") {
            setIsEditing(false)
            // do not directly use [inputValue] here, it won't refresh because of js closure
            const newPageNum = Number(event.currentTarget.value)
            if (0 < newPageNum && newPageNum <= totalPages) {
                if (newPageNum !== pageIndex) {
                    setPageIndex(newPageNum)
                }
                event.currentTarget.blur()
            } else {
                // console.log("invalid page number:", event.currentTarget.value)
                setInputValue(pageIndex) // change back to the last valid page number
                setIsEditing(true)
                event.currentTarget.select()
            }
        }
    }

    return ( // while there is only one page, we don't need Pagination
        totalPages <= 1 ? null :
            <nav>
                <ul className="pagination"
                    style={{
                        alignItems: "center",
                        justifyContent: "center",
                        margin: "20px 0px 0px 0px",
                        paddingTop: "0px",
                        paddingBottom: "20px",
                        width: "auto",
                        textAlign: "center",
                    }}
                >
                    <li className="page-item">
                        <PageNaviButton
                            className="page-link"
                            onClick={() => goToPage(pageIndex - 1)}
                            disabled={!hasPrev}
                            aria-disabled={!hasPrev}
                            aria-label="previous page"
                            style={{marginRight: "-20px"}}
                        >
                            <IoIcons.IoCaretBackSharp />
                        </PageNaviButton>
                    </li>
                    <li>
                        <PageNumInput
                            type="text"
                            value={inputValue}
                            className="page-link"
                            title="Press Enter after edited"
                            onChange={(e) => setInputValue(e.target.value)}
                            onFocus={(e) => {
                                // select the content in the input field, save user's time
                                e.target.select()

                                // enter editing state, add event listener for pressing ENTER
                                setIsEditing(true)
                                e.target.addEventListener("keydown", keydownListener)

                                // when the user unfocus the input field, remove the added listener
                                e.target.addEventListener('focusout', () => {
                                    e.target.removeEventListener("keydown", keydownListener)
                                    setIsEditing(false)
                                })
                            }}
                        />
                    </li>
                    <li
                        style={{
                            fontFamily: "Source Sans Pro",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "17px",
                            margin: "0 10px 0 4px",
                            padding: "6px 3.5px",
                            lineHeight: "120%",
                            color: "#293241"
                        }}
                    >
                        <h5 style={{
                            left: "58.46%",
                            right: "30.77%",
                            top: "16.67%",
                            bottom: "30.56%",
                        }}> of  {totalPages} </h5>
                    </li>
                    <li className="page-item">
                        <PageNaviButton
                            className="page-link"
                            onClick={() => goToPage(pageIndex + 1)}
                            disabled={!hasNext}
                            aria-disabled={!hasNext}
                            aria-label="next page"
                        >
                            <IoIcons.IoCaretForwardSharp />
                        </PageNaviButton>
                    </li>
                </ul>
            </nav>
    );
}

const PageNumInput = styled.input`
    border-radius: 6px;
    font-family: Source Sans Pro;
    color: #293241 !important;
    border: solid 0.5px #d3d2d2;
    background-color: #FFFFF !important;
    font-style: normal;
    height: 40px;
    width: 42px;
    margin: 0 0 0 35px;
    padding: 6px 6px;
    text-align: center;
    line-height: 150%;
    font-size: 16px;
    :hover {
        font-size: 17px;
    };
`

const PageNaviButton = styled.button`
    font-weight: 600;
    border: none;
    color: #207DEA;
    font-family: Source Sans Pro;
    font-style: normal;
    height: 40px;
    width: 42px;
    margin: 0 8px;
    padding: 0px;
    line-height: 150%;
    :disabled {
        opacity: 0.25;
    }
`